@import "./base/colors.pcss";
@import "./base/typography.pcss";
@import "./base/animation.pcss";

@import "./layout/header.pcss";
@import "./layout/base.pcss";
@import "./layout/footer.pcss";
@import "./layout/grid.pcss";
@import "./layout/horizontal-rule.pcss";
@import "./layout/signup.pcss";
@import "./layout/account-menu.pcss";
@import "./layout/sidebar.pcss";
@import "./layout/google-recaptcha.pcss";

@import "./components/alerts.pcss";
@import "./components/icon.pcss";
@import "./components/image.pcss";
@import "./components/menu.pcss";
@import "./components/button.pcss";
@import "./components/select.pcss";
@import "./components/checkbox.pcss";
@import "./components/radio.pcss";
@import "./components/input.pcss";
@import "./components/input-color.pcss";
@import "./components/tag.pcss";
@import "./components/toggle.pcss";
@import "./components/tooltip.pcss";
@import "./components/form-group.pcss";
@import "./components/link.pcss";
@import "./components/table.pcss";
@import "./components/spinner.pcss";
@import "./components/tabs.pcss";
@import "./components/modal.pcss";
@import "./components/list.pcss";
@import "./components/stepper.pcss";
@import "./components/responsive-embed.pcss";
@import "./components/ribbon.pcss";
@import "./components/phone-input.pcss";
@import "./components/pagination.pcss";
@import "./components/datepicker.pcss";
@import "./components/calendar-datepicker.pcss";
@import "./components/badge.pcss";
@import "./components/message-box.pcss";
@import "./components/quote-info.pcss";
@import "./components/collapser.pcss";
@import "./components/scroll.pcss";
@import "./components/debug.pcss";
@import "./components/plan-quote.pcss";
@import "./components/radio-card.pcss";
@import "./components/card.pcss";
@import "./components/data-display.pcss";
@import "./components/clinical-note.pcss";
@import "./components/textarea.pcss";

@import "./pages/stripe.pcss";
@import "./pages/contacts.pcss";
@import "./pages/members.pcss";
@import "./pages/sidebar.pcss";
@import "./pages/email-result.pcss";
@import "./pages/thanks.pcss";
@import "./pages/card-input.pcss";
@import "./pages/invoices.pcss";
@import "./pages/booking/enter-info.pcss";
@import "./pages/booking/navbar.pcss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Wix Madefor";
    src:
      url("./fonts/wix-madefor/variable-font.woff2") format("woff2"),
      url("./fonts/wix-madefor/variable-font.woff") format("woff");
  }
}

body {
  font-weight: normal;
  font-family: "Wix Madefor";
  min-height: 100%;
}

.section {
  @apply px-4;
}

.section + .section {
  @apply mt-4;
}

.panel {
  @apply rounded p-6;
  background-color: white;
}

@screen sm {
  .panel {
    @apply p-8;
  }
}

.__PrivateStripeElement {
  height: 100%;
}

:root {
  --color-primary-40: var(--custom-color-primary-40, #1d233466);
  --color-primary-100: var(--custom-color-primary-100, #f1f6fc);
  --color-primary-200: var(--custom-color-primary-200, #c4dcf8);
  --color-primary-250: var(--custom-color-primary-250, #81afe4);
  --color-primary-300: var(--custom-color-primary-300, #006cd1);
  --color-primary-400: var(--custom-color-primary-400, #0056a6);
  --color-primary-500: var(--custom-color-primary-500, #497aa5);
  --color-primary-600: var(--custom-color-primary-600, #1d2334);
  --color-success-100: var(--custom-color-success-100, #f6ffed);
  --color-success-200: var(--custom-color-success-200, #b7eb8f);
  --color-success-300: var(--custom-color-success-300, #52c41a);
  --color-success-400: var(--custom-color-success-400, #329502);
  --color-warning-100: var(--custom-color-warning-100, #fffbe6);
  --color-warning-200: var(--custom-color-warning-200, #ffe58f);
  --color-warning-300: var(--custom-color-warning-300, #ae5400);
  --color-warning-400: var(--custom-color-warning-400, #d4910c);
  --color-danger-100: var(--custom-color-danger-100, #fff1f0);
  --color-danger-200: var(--custom-color-danger-200, #ffa39e);
  --color-danger-300: var(--custom-color-danger-300, #f5222d);
  --color-danger-400: var(--custom-color-danger-400, #c8000a);
  --color-info-100: var(--custom-color-info-100, #f1f6fc);
  --color-info-200: var(--custom-color-info-200, #c4dcf8);
  --color-info-300: var(--custom-color-info-300, #006cd1);
  --color-info-400: var(--custom-color-info-400, #0056a6);
  --color-white: var(--custom-color-white, #fff);
  --color-black: var(--custom-color-black, #000);
  --color-gray: var(--custom-color-black, #999);
  --color-divider: var(--custom-color-divider, #e6ecf4);
}

.text-l {
  font-size: 1.5rem;
}

.text-support {
  color: var(--color-primary-500);
  font-size: .875rem;
}

.text-ellipsis {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

@media (width >= 640px) {
  .text-support {
    font-size: 1rem;
    line-height: 1.375rem;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header {
  background-color: var(--color-white);
  height: 4rem;
  margin-bottom: 2rem;
}

.header__container {
  align-items: center;
  height: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
}

.header__container--shaded {
  background-color: var(--color-primary-100);
  background-clip: border-box;
}

.header__logo > img {
  height: 3rem;
}

@media (width >= 768px) {
  .header__container {
    padding-left: 0;
    padding-right: 0;
  }
}

.layout {
  flex-direction: column;
  min-height: 100vh;
  max-height: 1000vh;
  display: flex;
}

.layout--app {
  background: #f1f6fc;
}

.app-container {
  max-width: 100%;
}

@media (width >= 768px) {
  .app-container {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }

  .app-account-container {
    max-width: 65rem;
  }
}

@media print {
  .layout--app.layout--healthInformation {
    background-color: #fff;
    min-height: 99vh;
  }

  .layout--app.layout--healthInformation header, .layout--app.layout--healthInformation div.footer, .layout--app.layout--healthInformation h1 {
    display: none;
  }
}

.footer {
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  display: flex;
}

.footer > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.footer {
  text-align: center;
  padding: 1rem .5rem;
}

@media (width >= 768px) {
  .footer {
    width: 83.3333%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.row {
  flex-wrap: wrap;
  flex-grow: 1;
  margin-bottom: 1.25rem;
  margin-left: -.5rem;
  margin-right: -.5rem;
  display: flex;
}

.row.row--tweak {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.column {
  width: 100%;
  padding-left: .25rem;
  padding-right: .25rem;
}

.column--offset {
  margin-bottom: 0;
}

.column.column--topSpace:not(:first-child) {
  margin-top: 1.25rem;
}

.column.column--topSpaceMd:not(:first-child) {
  margin-top: .5rem;
}

.list-row {
  background-color: var(--color-white) !important;
  margin-top: -.25rem !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

@media (width >= 768px) {
  .row {
    margin-bottom: 1.5rem;
  }

  .column.column--topSpace:not(:first-child), .column.column--topSpaceMd:not(:first-child) {
    margin-top: 0;
  }
}

.horizontal-rule {
  border-color: var(--color-primary-200);
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.panel > .horizontal-rule {
  margin-left: -2rem;
  margin-right: -2rem;
}

@media (width >= 768px) {
  .horizontal-rule {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.main.main--signup {
  flex-grow: 1;
  display: flex;
}

.signup--thanks {
  background: #f1f6fc;
}

.signup__body {
  width: 100%;
  padding: 2rem 1.25rem 1rem;
  display: flex;
}

.signup__body--contents {
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  display: flex;
}

.header {
  border-style: solid;
  border-bottom-width: 1px;
  border-color: var(--color-primary-200);
  height: auto;
  min-height: 65px;
  margin-bottom: 0;
}

.header__container {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.header__container.header__container--shaded {
  border-style: solid;
  border-top-width: 1px;
  border-color: var(--color-primary-200);
  padding-top: 0;
  padding-bottom: 0;
}

.header .tabs {
  margin-bottom: -1px;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.header .tabs .tabs__item:not(:last-child) {
  margin-right: .75rem;
}

.header .tabs .tabs__item {
  color: var(--color-primary-500);
  font-size: .875rem;
  line-height: .875rem;
}

.header .tabs .tabs__item.tabs__item--active .tabs-member-name {
  color: var(--color-primary-300);
}

.header .tabs .tabs__item.tabs__item--active .tabs-member-age {
  color: var(--color-black);
}

.header .tabs .tabs__item.tabs__item--previous {
  color: var(--color-black);
  text-decoration-line: underline;
}

.signup__page-border {
  width: 0;
}

.signup__page-center {
  width: 100%;
  display: flex;
}

.signup__footer {
  opacity: 0;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  animation: .3s linear 1s forwards fadeIn;
  display: flex;
}

.signup__subtitle {
  margin-top: .5rem;
}

@media (width >= 640px) {
  .signup__subtitle {
    margin-top: .75rem;
  }
}

@media (width >= 768px) {
  .signup__body {
    padding: 4rem 0 1.5rem;
  }

  .signup__page-border {
    width: 8.33333%;
  }

  .signup__page-center {
    width: 83.3333%;
  }

  .signup__footer {
    margin-left: 0;
    margin-right: 0;
  }

  .header .tabs {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .header .tabs .tabs__item {
    font-size: 1rem;
    line-height: 1rem;
  }

  .header .tabs .tabs__item:not(:last-child) {
    margin-right: 2rem;
  }
}

@media (width >= 1024px) {
  .signup__body {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.account-menu {
  border-right: 1px solid #f0f0f0;
  flex-direction: column;
  display: flex;
}

.account-menu__item {
  align-items: center;
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration-line: underline;
  transition: all .3s ease-out;
  display: flex;
  position: relative;
}

.account-menu__item:after {
  background-color: var(--color-primary-300);
  content: "";
  width: 120%;
  height: .25rem;
  transition: all .3s ease-out;
  position: absolute;
  bottom: -1.5rem;
  right: -10%;
  transform: scale(0);
}

.account-menu__item--active {
  color: var(--color-primary-300);
  text-decoration-line: none;
}

.account-menu__item--active:after {
  transform: scale(1);
}

.account-menu__item:hover {
  color: var(--color-primary-400);
}

.sidebar {
  -webkit-user-select: none;
  user-select: none;
  color: var(--color-white);
  z-index: 1;
  display: block;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
}

.sidebar a {
  color: var(--color-white);
  text-decoration-line: none;
  transition: color .3s;
}

.sidebar a:hover {
  color: #fff9;
}

.sidebar .sidebar__burger:focus {
  pointer-events: none;
}

.sidebar .sidebar__burger {
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
  width: 2.5rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: -7px;
  left: -5px;
}

.sidebar span {
  background-color: var(--color-black);
  z-index: 1;
  transform-origin: 4px -2px;
  border-radius: .25rem;
  width: 2rem;
  height: .25rem;
  margin-bottom: .25rem;
  transition: transform .5s cubic-bezier(.77, .2, .05, 1), background .5s cubic-bezier(.77, .2, .05, 1), opacity .55s;
  display: block;
  position: relative;
}

.sidebar span:first-child {
  transform-origin: 0 0;
}

.sidebar span:nth-last-child(2) {
  transform-origin: 0 100%;
}

.sidebar .sidebar__burger:focus ~ span {
  opacity: 1;
  background: #fff;
  transform: rotate(45deg)translate(-2px, -1px);
}

.sidebar .sidebar__burger:focus ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0)scale(.2);
}

.sidebar .sidebar__burger:focus ~ span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg)translate(0, -1px);
}

.sidebar__links {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-primary-300);
  transform-origin: 0 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  padding-left: 2rem;
  transition: transform .5s cubic-bezier(.77, .2, .05, 1);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(100%);
}

.sidebar__links a {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1.875rem;
  display: flex;
}

.sidebar__links a:last-child {
  margin-top: auto;
}

.sidebar .sidebar__burger:focus ~ .sidebar__links {
  opacity: 1;
  transform: none;
}

@media (width >= 768px) {
  .sidebar {
    display: none;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.alerts-wrapper {
  z-index: 50;
  width: calc(100% - 3rem);
  margin: 1.5rem;
  position: fixed;
  right: 0;
}

.alert {
  --tw-bg-opacity: .75;
  vertical-align: middle;
  border-width: 1px;
  border-color: currentColor;
  border-radius: .375rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: .5rem;
  transition: all .3s ease-out;
  animation: .3s linear fadeInRight;
  display: flex;
}

@media (width >= 768px) {
  .alerts-wrapper {
    width: 24rem;
  }
}

.alert--closing {
  animation: .3s linear forwards fadeOutRight;
}

.alert--info {
  background-color: var(--color-info-100);
  color: var(--color-info-400);
}

.alert--info:hover {
  color: var(--color-info-400);
}

.alert--error {
  background-color: var(--color-danger-100);
  color: var(--color-danger-300);
}

.alert--error:hover {
  color: var(--color-danger-400);
}

.alert--warning {
  background-color: var(--color-warning-100);
  color: var(--color-warning-300);
}

.alert--warning:hover {
  color: var(--color-warning-400);
}

.alert--success {
  background-color: var(--color-success-100);
  color: var(--color-success-300);
}

.alert--success:hover {
  color: var(--color-success-400);
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50%)scale(.5);
  }

  100% {
    opacity: 1;
    transform: translateX(0%)scale(1);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(50%);
  }
}

.icon {
  fill: currentColor;
  height: 1.25rem;
  display: inline-block;
}

.icon--xs {
  height: .75rem;
}

.icon--xs.icon--square {
  width: .75rem;
}

.icon--sm {
  height: 1rem;
}

.icon--sm.icon--square {
  width: 1rem;
}

.icon--md {
  height: 1.25rem;
}

.icon--md.icon--square {
  width: 1.25rem;
}

.icon--lg {
  height: 1.5rem;
}

.icon--lg.icon--square {
  width: 1.5rem;
}

.icon--xl {
  height: 2rem;
}

.icon--xl.icon--square {
  width: 2rem;
}

.icon:not(:last-child) {
  margin-right: .5rem;
}

.icon:not(:first-child) {
  margin-left: .5rem;
}

.image-portrait {
  border-radius: 3px;
  width: 58px;
  height: 58px;
}

.image-portrait--modal {
  width: 72px;
  height: 72px;
}

@media (width >= 640px) {
  .image-portrait {
    width: 110px;
    height: 110px;
  }

  .image-portrait--modal {
    width: 90px;
    height: 90px;
  }
}

.menu {
  border-right: 1px solid #f0f0f0;
  flex-direction: column;
  display: flex;
}

.menu__item {
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: .5rem .5rem .5rem 1.5rem;
  transition: all .3s ease-out;
  position: relative;
}

.menu__item:after {
  background-color: var(--color-primary-300);
  content: "";
  width: 4px;
  height: 40px;
  transition: all .3s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(0);
}

.menu__item--active {
  background-color: var(--color-primary-100);
  color: var(--color-primary-300);
}

.menu__item--active:after {
  transform: scale(1);
}

.menu__item:hover {
  color: var(--color-primary-400);
}

.button {
  cursor: pointer;
  text-align: center;
  outline-offset: 2px;
  outline: 2px solid #0000;
  padding: .5rem .75rem;
  font-size: .875rem;
  line-height: 1.375rem;
  transition: all .3s ease-out;
}

.button + .button {
  margin-left: .5rem;
}

.button:focus {
  outline: none;
}

.button:disabled {
  cursor: not-allowed;
}

.button--primary, .button--secondary, .button--danger--filled, .button--danger--outline {
  border-width: 1px;
  border-color: var(--color-primary-300);
  border-radius: 9999px;
  padding: .5rem .65rem;
}

.button--primary {
  background-color: var(--color-primary-300);
  color: var(--color-white);
}

.button--primary:hover, .button--primary:focus {
  border-color: var(--color-primary-400);
  background-color: var(--color-primary-400);
}

.button--primary:disabled {
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  color: var(--color-primary-500);
}

.button--danger--filled {
  background-color: var(--color-danger-300);
  color: var(--color-white);
  border-width: 1px;
  border-color: var(--color-danger-300);
  border-radius: 9999px;
}

.button--danger--filled:hover, .button--danger--filled:focus {
  border-color: var(--color-danger-400);
  background-color: var(--color-danger-400);
}

.button--danger--outline {
  border-width: 1px;
  border-color: var(--color-danger-300);
  background-color: var(--color-white);
  color: var(--color-danger-300);
}

.button--danger--filled:disabled, .button--danger--outline:hover, .button--danger--outline:focus, .button--danger--outline:disabled {
  border-color: var(--color-danger-200);
  background-color: var(--color-danger-100);
  color: var(--color-danger-400);
}

.button--secondary {
  color: var(--color-primary-300);
}

.button--secondary:focus, .button--secondary:hover {
  border-color: var(--color-primary-400);
  color: var(--color-primary-400);
}

.button--secondary:disabled {
  border-color: var(--color-primary-200);
  color: var(--color-primary-500);
}

.button--link {
  color: var(--color-primary-300);
  padding: 0;
  text-decoration-line: underline;
}

.button--link:focus, .button--link:hover {
  color: var(--color-primary-400);
}

.button--profile {
  background-color: #d9dfe7;
  border-color: #d9dfe7;
  line-height: .875rem;
  width: 2.5rem !important;
  height: 2.5rem !important;
  color: var(--color-primary-600) !important;
  font-weight: 500 !important;
}

.button--round {
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  display: flex;
}

.button--loading > .button__spinner {
  border-width: 2px;
  min-width: 1rem;
  margin-left: .5rem;
  animation: .8s linear infinite spin;
  display: inline-block;
}

.button__spinner {
  vertical-align: middle;
  border: 0 solid #0000;
  border-top-color: currentColor;
  border-radius: 9999px;
  min-width: 0;
  height: 1rem;
  transition: all .3s ease-out;
  display: none;
}

.icon + .button__spinner {
  margin-left: -1rem;
}

.button .icon {
  margin-top: -1px;
  margin-bottom: 1px;
}

.button--nowrap {
  white-space: nowrap;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (width >= 768px) {
  .button--primary, .button--secondary, .button--danger--filled, .button--danger--outline {
    border-radius: 40px;
    padding: 8px 1rem;
  }
}

.select {
  width: 100%;
  display: inline-block;
  position: relative;
}

.select__input {
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  width: 100%;
  color: var(--color-primary-500);
  text-align: left;
  caret-color: #0000;
  background-color: #fff;
  border-radius: .25rem;
  padding: .5rem .75rem;
  font-size: .875rem;
  line-height: 1.375rem;
  transition: all .3s cubic-bezier(.12, .4, .29, 1.05);
}

.select__input--selected, input.select__input {
  color: var(--color-primary-600);
}

.select__input::placeholder {
  color: var(--color-primary-500);
}

.select__input:hover, .select:focus-within > .select__input {
  border-color: var(--color-primary-250);
  outline: 0;
}

.select--open > .select__input {
  caret-color: currentColor;
}

.select__input:disabled {
  cursor: not-allowed;
  border-color: var(--color-primary-100);
  background-color: var(--color-primary-100);
  box-shadow: none;
  color: #00000040;
}

.select__input:hover:disabled, .select:focus-within > .select__input:disabled {
  box-shadow: none;
}

.select--invalid {
  border-color: var(--color-danger-300);
}

.select--invalid:hover, .select:focus-within > .select--invalid {
  border-color: var(--color-danger-400);
}

.select__items {
  z-index: 20;
  background-color: var(--color-white);
  transform-origin: top;
  border-radius: .25rem;
  min-width: 100%;
  max-height: 40vh;
  margin-top: 3px;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .875rem;
  animation: .3s cubic-bezier(.12, .4, .29, 1.05) growOut;
  position: absolute;
  overflow-y: auto;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.select__items--top {
  bottom: 100%;
}

.select__items--right {
  right: 0;
}

.select__items--nowrap {
  white-space: nowrap;
}

@keyframes growOut {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.select__item {
  cursor: pointer;
  color: var(--color-primary-600);
  align-items: center;
  padding: .5rem .75rem;
  transition: all .3s ease-out;
  display: flex;
}

.select__item:focus-within {
  background-color: var(--color-primary-200);
}

.select__item--disabled {
  color: #00000040;
  cursor: not-allowed;
}

.select__item:hover {
  background-color: var(--color-primary-100);
}

.select__item--active, .select__item--active:focus-within {
  background-color: var(--color-primary-100);
  color: var(--color-primary-300);
}

.select__item--disabled:hover {
  background-color: var(--color-white);
}

.select__item__input {
  opacity: 0;
  z-index: -1;
  width: 0;
  height: 0;
}

.select__arrow {
  border-style: solid;
  border-color: var(--color-primary-500);
  transform-origin: 6px 6px;
  border-width: 0 1px 1px 0;
  width: .5rem;
  height: .5rem;
  transition: all .3s ease-out;
  display: inline-block;
  position: absolute;
  top: 36%;
  right: 1rem;
  transform: rotate(45deg);
}

.select__input:disabled > .select__arrow {
  opacity: 0;
}

.select__item--divider {
  border-style: solid;
  border-bottom-width: 1px;
  border-color: var(--color-primary-200);
  margin: .25rem .5rem;
  display: block;
}

.select--loading > .select__spinner {
  border-width: 1px;
  min-width: 1rem;
  margin-left: .5rem;
  animation: .6s linear infinite spin;
  position: absolute;
  top: 30%;
  right: 1rem;
}

.select__spinner {
  vertical-align: middle;
  border: 0 solid #0000;
  border-top: 0 solid var(--color-primary-500);
  border-radius: 50%;
  min-width: 0;
  height: 1rem;
  transition: all .3s ease-out;
  display: inline-block;
}

.checkbox {
  cursor: pointer;
  line-height: 1.375rem;
  display: flex;
}

.checkbox--disabled {
  cursor: not-allowed;
  color: var(--color-primary-500);
}

.checkbox--invalid {
  color: var(--color-danger-400);
}

.checkbox + .checkbox {
  margin-top: 10px;
}

.checkbox__input {
  opacity: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
  position: absolute;
}

.checkbox__icon {
  vertical-align: middle;
  color: var(--color-white);
  border: 1px solid var(--color-primary-200);
  background-color: #0000;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  display: inline-block;
  position: relative;
}

.checkbox__icon:after {
  opacity: 0;
  content: "";
  border: 2px solid;
  border-top: 0;
  border-left: 0;
  width: 5px;
  height: 9px;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  display: table;
  position: absolute;
  top: 50%;
  left: 22%;
}

.checkbox__input:checked + .checkbox__icon {
  border-color: var(--color-primary-300);
  background-color: var(--color-primary-300);
}

.checkbox__input:not(:disabled):hover + .checkbox__icon, .checkbox__input:not(:disabled):focus + .checkbox__icon {
  border-color: var(--color-primary-250);
}

.checkbox__input:disabled + .checkbox__icon {
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  color: var(--color-primary-500);
}

.checkbox__input--indeterminate + .checkbox__icon:after, .checkbox__input--indeterminate:disabled + .checkbox__icon:after, .checkbox__input--indeterminate:checked + .checkbox__icon:after {
  content: none;
}

.checkbox__input--indeterminate + .checkbox__icon, .checkbox__input--indeterminate:disabled + .checkbox__icon, .checkbox__input--indeterminate:checked + .checkbox__icon {
  border-color: var(--color-primary-300);
  background-color: var(--color-primary-300);
  box-shadow: inset 0px 0px 0px 2px var(--color-white);
}

.checkbox__input--indeterminate:focus + .checkbox__icon, .checkbox__input--indeterminate:hover + .checkbox__icon {
  box-shadow: inset 0px 0px 0px 2px var(--color-white), 0 0 0 1px #1890ff33;
}

.checkbox__input--invalid + .checkbox__icon, .checkbox__input--invalid:not(:disabled):hover + .checkbox__icon, .checkbox__input--invalid:not(:disabled):focus + .checkbox__icon, .checkbox__input--invalid:focus + .checkbox__icon, .checkbox__input--invalid:hover + .checkbox__icon {
  border-color: var(--color-danger-400);
}

.checkbox__input:checked + .checkbox__icon:after {
  opacity: 1;
  transform: rotate(45deg)scale(1)translate(-50%, -50%);
}

.checkbox__text {
  vertical-align: top;
  margin-top: 2px;
  margin-left: 8px;
  padding-right: .25rem;
  font-size: .875rem;
}

.checkbox__text--required:after {
  color: var(--color-danger-300);
  content: "*";
  margin-left: .25rem;
}

.radio {
  z-index: 0;
  cursor: pointer;
  width: 100%;
  transition: all .3s ease-out;
  display: inline-block;
  position: relative;
}

.radio--card {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  background-color: var(--color-white);
  border-radius: .25rem;
  padding: .4rem .75rem;
}

.radio--card:not(.radio--disabled):hover {
  z-index: 10;
  border-color: var(--color-primary-250);
}

.radio--card.radio--checked, .radio--card.radio--checked:hover {
  border-color: var(--color-primary-300);
  background-color: var(--color-primary-100);
  color: var(--color-primary-300);
}

.radio--disabled {
  cursor: not-allowed;
  color: var(--color-primary-500);
}

.radio--card.radio--disabled {
  background-color: var(--color-primary-100);
}

.radio__input {
  opacity: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
  position: absolute;
}

.radio__icon {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  color: var(--color-primary-300);
  background-color: #0000;
  border-radius: 9999px;
  transition: all .3s ease-out;
  display: inline-block;
  position: relative;
}

.radio--icon-hidden .radio__icon {
  display: none;
}

.radio__icon:after {
  content: "";
  background-color: #0000;
  border-radius: 9999px;
  width: .5rem;
  height: .5rem;
  transition: all .2s cubic-bezier(.78, .14, .15, .86) .1s;
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  transform: scale(0);
}

.radio__input:checked + .radio__icon {
  border-color: var(--color-primary-300);
}

.radio__input:checked + .radio__icon:after {
  background-color: currentColor;
  transform: scale(1);
}

.radio__input:not(:disabled):hover + .radio__icon, .radio__input:not(:disabled):focus + .radio__icon {
  border-color: var(--color-primary-250);
}

.radio__input:disabled + .radio__icon {
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  color: var(--color-primary-500);
}

.radio__text {
  vertical-align: middle;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
  line-height: 1.5rem;
  transition: all .3s ease-out;
  display: inline-block;
}

.radio-group > .radio--card:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.radio-group > .radio--card:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.radio-group {
  display: flex;
}

.radio__content {
  align-items: center;
  height: 100%;
  display: flex;
}

.input {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  width: 100%;
  color: var(--color-primary-600);
  border-radius: .25rem;
  padding: .5rem .75rem;
  font-size: .875rem;
  line-height: 1.375rem;
  transition: all .3s ease-out;
}

.input::placeholder {
  color: var(--color-primary-500);
}

.input:focus, .input:hover {
  border-color: var(--color-primary-250);
  outline: none;
}

.input:disabled:hover, .input:disabled:focus, .input:disabled {
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  color: var(--color-primary-250);
  box-shadow: none;
}

.input--invalid {
  border-color: var(--color-danger-300);
  color: var(--color-danger-300);
}

.input--invalid:hover, .input--invalid:focus {
  border-color: var(--color-danger-400);
}

.input-group {
  display: flex;
}

.input-group > :not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > :not(:last-child) {
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-addon {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  color: var(--color-primary-500);
  border-radius: .25rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 1.375rem;
}

.input.input-with-icon {
  padding-right: 1.75rem;
}

.input-icon {
  width: 14px;
  height: 14px;
  margin-left: -24px !important;
}

.input-color {
  -webkit-appearance: none;
  border: none;
  width: 2rem;
  height: 2rem;
}

.input-color::-webkit-color-swatch-wrapper {
  padding: 0;
}

.input-color::-webkit-color-swatch {
  border: none;
}

.tag {
  white-space: nowrap;
  border-width: 1px;
  border-color: var(--color-primary-200);
  color: var(--color-primary-500);
  border-radius: 1.5rem;
  margin: .25rem .5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  display: inline-block;
}

.tag:before {
  content: "• ";
}

.tag--success {
  background-color: var(--color-success-100);
  color: var(--color-success-300);
  border-color: #0000;
}

.tag--info {
  background-color: var(--color-info-100);
  color: var(--color-info-300);
  border-color: #0000;
}

.tag--danger {
  background-color: var(--color-danger-100);
  color: var(--color-danger-300);
  border-color: #0000;
}

.tag--warning {
  background-color: var(--color-warning-100);
  color: var(--color-warning-300);
  border-color: #0000;
}

.tag.tag--subdued {
  background-color: var(--color-info-100);
  border-color: #0000;
}

.tag.tag--subdued:before {
  content: "";
}

.tag.tag--small {
  font-size: .75rem;
}

.c-table .tag {
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .75rem;
}

@media (width >= 640px) {
  .c-table .tag {
    margin: .25rem .5rem;
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: 1rem;
  }

  .tag.tag--small {
    font-size: .875rem;
  }
}

.toggle {
  cursor: pointer;
  transition: all .3s ease-out;
  display: inline-block;
}

.toggle__input {
  opacity: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
  position: absolute;
}

.toggle__input:checked + .toggle__icon {
  border-color: var(--color-primary-300);
}

.toggle__input:checked {
  background-color: currentColor;
  transform: scale(1);
}

.toggle__input:hover + .toggle__icon, .toggle__input:focus + .toggle__icon {
  border-color: var(--color-primary-300);
  box-shadow: 0 0 0 1px #1890ff33;
}

.toggle__text {
  vertical-align: middle;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
  line-height: 1.5rem;
  transition: all .3s ease-out;
  display: inline-block;
}

.toggle--card {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  border-radius: .25rem;
  padding: .4rem .75rem;
}

.toggle--card.toggle--checked {
  border-color: var(--color-primary-300);
  color: var(--color-primary-300);
}

.toggle--card:first-child {
  border-radius: .25rem 0 0 .25rem;
}

.toggle--card:nth-child(2) {
  border-radius: 0 .25rem .25rem 0;
}

.tooltip__text {
  padding: 5px;
  display: none;
}

.tooltip:hover > .tooltip__text {
  background-color: var(--color-primary-300);
  color: var(--color-white);
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .125rem;
  margin-top: -2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: block;
  position: absolute;
}

.form-group {
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

.form-group__label {
  font-size: .875rem;
}

.form-group__label:after {
  color: var(--color-danger-300);
  margin-left: .25rem;
}

.form-group__label--required:after {
  content: "*";
}

.form-error {
  color: var(--color-danger-300);
  margin-top: .5rem;
  font-size: .875rem;
}

@media (width >= 768px) {
  .form-group__label {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  .form-error {
    color: var(--color-danger-300);
    margin-top: .75rem;
  }
}

.link {
  color: var(--color-primary-300);
  text-decoration-line: underline;
}

.link:focus, .link:hover {
  color: var(--color-primary-400);
}

.link.button {
  text-decoration-line: none;
  display: inline-block;
}

.link.button.button--round {
  display: flex;
}

.link--mobile-hidden {
  display: none !important;
}

@media (width >= 768px) {
  .link--mobile-hidden {
    display: inline-block !important;
  }
}

.c-table {
  table-layout: auto;
  background-color: var(--color-white);
  width: 100%;
  overflow-x: scroll;
}

.c-table__header {
  border-bottom-width: 1px;
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  text-align: left;
  color: var(--color-primary-500);
  padding: 1rem .75rem;
  font-weight: 400;
}

.c-table__row {
  border-bottom-width: 1px;
  border-color: var(--color-primary-200);
}

.c-table__cell {
  padding: 1rem .5rem;
}

@media (width >= 640px) {
  .c-table__cell {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

.spinner {
  width: 3em;
  height: 3em;
  font-size: 10px;
  animation: 1.2s linear infinite spinAnimation;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
}

.spinner--xs {
  font-size: 4px;
}

.spinner--sm {
  font-size: 8px;
}

.spinner--lg {
  font-size: 12px;
}

.spinner__dot {
  background-color: var(--color-primary-300);
  opacity: .3;
  border-radius: 9999px;
  width: 1em;
  height: 1em;
  animation: 1s linear infinite alternate dotAnimation;
  position: absolute;
}

.spinner__dot:first-child {
  top: 0;
  right: 0;
}

.spinner__dot:nth-child(2) {
  animation-delay: .4s;
  bottom: 0;
  right: 0;
}

.spinner__dot:nth-child(3) {
  animation-delay: .8s;
  bottom: 0;
  left: 0;
}

.spinner__dot:nth-child(4) {
  animation-delay: .12s;
  top: 0;
  left: 0;
}

@keyframes dotAnimation {
  100% {
    opacity: 1;
  }
}

@keyframes spinAnimation {
  100% {
    transform: rotate(405deg);
  }
}

.tabs {
  border-style: solid;
  border-bottom-width: 1px;
  border-color: var(--color-primary-200);
  margin-bottom: 1rem;
  display: flex;
  position: relative;
  overflow-x: scroll;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tabs__underline {
  transform-origin: 0;
  background-color: var(--color-primary-300);
  width: 1px;
  height: 2px;
  transition: all .3s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.tabs__item {
  pointer-events: none;
  white-space: nowrap;
  padding: .5rem .25rem;
}

.tabs__item:not(:last-child) {
  margin-right: 2rem;
}

.tabs__item:focus, .tabs__item:active {
  color: var(--color-primary-300);
  outline-offset: 2px;
  outline: 2px solid #0000;
  transition: color .3s ease-out;
}

.tabs__item--active, .tabs__item--active:focus, .tabs__item--active:active {
  cursor: auto;
  color: var(--color-primary-300);
}

.tabs__item.tabs__item--previous {
  pointer-events: auto;
}

.modal-wrap {
  z-index: 50;
  background-color: #00000073;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal {
  transform-origin: top;
  width: 91.6667%;
  margin-top: auto;
  margin-bottom: auto;
  animation: .3s cubic-bezier(.12, .4, .29, 1.05) growOut;
}

.modal__content {
  background-color: var(--color-white);
  border-width: 0;
  border-radius: .375rem;
  flex-direction: column;
  display: flex;
}

.modal__header {
  vertical-align: middle;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
}

.modal__header .icon {
  color: var(--color-primary-500);
}

.modal__body {
  max-height: 45vh;
  padding: 2rem;
  overflow: hidden auto;
}

.modal__footer {
  text-align: right;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (width >= 640px) {
  .modal {
    width: 80%;
  }

  .modal__body {
    max-height: 55vh;
  }
}

@media (width >= 768px) {
  .modal {
    width: 60%;
  }
}

@media (width >= 1024px) {
  .modal {
    width: 40%;
  }
}

.list {
  flex-wrap: wrap;
  margin-left: -.5rem;
  margin-right: -.5rem;
  display: flex;
}

.list__item {
  width: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
  display: flex;
}

.list--bulleted {
  margin-left: 1.5rem;
  list-style-type: disc;
}

.list--bulleted li, .list--bulleted .list__item {
  display: list-item;
}

.list__item:not(:last-child) {
  margin-bottom: .5rem;
}

.list--bordered {
  border-width: 1px;
  border-color: var(--color-primary-200);
  border-radius: .25rem;
  margin-left: 0;
  margin-right: 0;
}

.list--bordered > .list__item {
  border-bottom-width: 1px;
  border-color: var(--color-primary-200);
  padding: 1rem;
}

.list--bordered > .list__item:last-child {
  border-bottom-width: 0;
}

.stepper {
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: .25rem;
  margin-bottom: .25rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.provider-selection-stepper .stepper__step--current .stepper__text {
  display: inline-flex;
}

.stepper__text {
  text-transform: capitalize;
}

.provider-selection-stepper .stepper__step {
  margin-bottom: .5rem;
}

.provider-selection-stepper {
  flex-wrap: wrap;
  margin-top: .25rem;
  margin-bottom: .25rem;
  display: flex;
  overflow: visible;
}

.stepper::-webkit-scrollbar {
  display: none;
}

.stepper__step {
  align-items: center;
  display: flex;
}

.stepper__step:last-child {
  margin-right: 0;
}

.stepper__circle {
  border-color: var(--color-primary-400);
  vertical-align: middle;
  border-width: 1px;
  border-radius: 9999px;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  font-size: .75rem;
  line-height: 1.5rem;
  display: flex;
}

.stepper__circle .icon {
  border-color: var(--color-primary-400);
  width: .75rem;
  height: .75rem;
  margin-top: .3rem;
}

.stepper__circle svg {
  fill: var(--color-primary-400);
}

.stepper__step--previous {
  cursor: pointer;
}

.stepper__step--current .stepper__circle {
  background-color: var(--color-primary-400);
  color: var(--color-white);
}

.stepper__step--previous .stepper__circle {
  color: var(--color-primary-300);
}

.stepper__step--next .stepper__circle, .stepper__step--disabled .stepper__circle {
  border-color: var(--color-primary-200);
  color: var(--color-primary-500);
}

.stepper__text {
  white-space: nowrap;
  height: 100%;
  color: var(--color-primary-500);
  align-items: center;
  padding-left: 6px;
  font-size: .875rem;
  display: none;
}

.stepper__step--current, .stepper__step--label {
  font-weight: 500;
}

.stepper__step--current .stepper__text, .stepper__step--previous, .stepper__text {
  color: var(--color-primary-600);
}

.stepper__step--previous .stepper__text a {
  text-decoration-line: underline;
}

.stepper__step--next, .stepper__step--disabled, .stepper__step--current {
  pointer-events: none;
}

.stepper__line {
  width: 1rem;
  margin-left: .25rem;
  margin-right: .25rem;
}

.stepper__line hr {
  border-color: var(--color-primary-300);
}

.stepper__step--current .stepper__line hr, .stepper__step--next .stepper__line hr, .stepper__step--disabled .stepper__line hr {
  border-color: var(--color-primary-200);
}

@media (width >= 768px) {
  .stepper, .provider-selection-stepper {
    padding-left: 0;
    overflow-x: scroll;
  }

  .stepper__step {
    margin-right: 1rem;
  }

  .stepper__circle {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    line-height: 2rem;
  }

  .stepper__circle .icon {
    width: 1rem;
    height: 1rem;
    margin-top: .45rem;
  }

  .stepper__text {
    padding-left: .5rem;
    font-size: 1rem;
    display: none;
  }

  .stepper__step--current .stepper__text {
    display: inline-flex;
  }

  .stepper__line {
    width: 2rem;
    margin-left: 1rem;
    margin-right: 0;
  }
}

.embed-responsive {
  height: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive--small {
  padding-bottom: 40vh;
}

.embed-responsive--large {
  padding-bottom: 70vh;
}

@media (width >= 640px) {
  .embed-responsive--large {
    padding-bottom: 50vh;
  }
}

.embed-responsive__item {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.ribbon {
  white-space: nowrap;
  background-color: var(--color-success-100);
  text-align: center;
  width: 75%;
  color: var(--color-success-300);
  border-color: #0000;
  margin: 1rem auto 1.5rem;
  padding: .5rem 3rem;
  font-size: .875rem;
  position: relative;
}

.ribbon:before {
  content: "";
  border-right-width: 8px;
  border: 1.3em solid var(--color-success-100);
  border-left-color: #0000;
  display: block;
  position: absolute;
  bottom: .01em;
  left: -2em;
}

.ribbon .ribbon-content:before {
  border-width: 1em 0 0 1em;
  left: 0;
}

@media (width >= 640px) {
  .ribbon {
    margin: 2rem auto 3rem;
    padding: 1rem 2rem;
    font-size: 1rem;
  }

  .ribbon:before {
    border-right-width: 8px;
    border: 1.72em solid var(--color-success-100);
    border-left-color: #0000;
  }
}

.phone-input {
  width: 100%;
  display: flex;
}

.phone-input__type {
  width: 6rem;
}

.phone-input__region {
  width: 4rem;
  margin-right: .5rem;
}

.phone-input__number {
  width: 100%;
  margin-right: .5rem;
}

.pagination {
  margin-top: 1.25rem;
  display: flex;
}

.pagination li {
  cursor: pointer;
  border: 1px solid var(--color-primary-200);
  border-radius: .125rem;
  width: 2rem;
  height: 2rem;
  margin-left: .25rem;
  margin-right: .25rem;
  font-size: .75rem;
  line-height: 2rem;
  display: inline-block;
}

.pagination li.active {
  background: var(--color-primary-300);
  color: #fff;
}

.pagination li.disabled {
  pointer-events: none;
}

@media (width >= 768px) {
  .pagination li {
    font-size: 1rem;
  }
}

.elm-datepicker--container {
  position: relative;
}

.elm-datepicker--picker {
  z-index: 10;
  background-color: var(--color-white);
  border: 1px solid #ccc;
  flex-direction: column;
  min-width: 275px;
  display: flex;
  position: absolute;
}

.elm-datepicker--picker-header {
  align-items: center;
  display: flex;
}

.elm-datepicker--prev-container, .elm-datepicker--next-container {
  cursor: pointer;
  flex: 0 auto;
}

.elm-datepicker--month-container {
  flex-direction: column;
  flex: auto;
  padding: .5rem;
  display: flex;
}

.elm-datepicker--month, .elm-datepicker--year {
  cursor: pointer;
  background-color: var(--color-white);
  text-align: center;
  text-transform: uppercase;
  border-style: none;
  flex: auto;
  font-size: 1rem;
  font-weight: 700;
}

.elm-datepicker--year:hover {
  opacity: .7;
}

.elm-datepicker--year {
  font-size: .875rem;
}

.elm-datepicker--prev, .elm-datepicker--next {
  background-color: inherit;
  border: 6px solid #0000;
  width: 0;
  height: 0;
  padding: 0 .2em;
  display: block;
}

.elm-datepicker--prev {
  border-right-color: #000;
}

.elm-datepicker--prev:hover {
  border-right-color: #0009;
}

.elm-datepicker--next {
  border-left-color: #000;
}

.elm-datepicker--next:hover {
  border-left-color: #0009;
}

.elm-datepicker--table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: .875rem;
}

.elm-datepicker--table > td {
  text-align: center;
  width: 2rem;
  height: 2rem;
}

.elm-datepicker--row {
  border-top: 1px solid #f2f2f2;
}

.elm-datepicker--dow {
  cursor: default;
  background-color: var(--color-white);
  text-transform: uppercase;
  color: #8b8989;
  border-bottom: 1px solid #ccc;
}

.elm-datepicker--day {
  cursor: pointer;
  padding: .6em .8em;
}

.elm-datepicker--day:hover {
  color: #fff;
  background: #4680bf;
  border-radius: 5px;
}

.elm-datepicker--disabled {
  cursor: default;
  color: #ddd;
}

.elm-datepicker--disabled:hover {
  background: inherit;
}

.elm-datepicker--picked {
  color: #fff;
  background: #337ab7;
  border-radius: 7px;
}

.elm-datepicker--picked:hover {
  background: #4680bf;
}

.elm-datepicker--today {
  font-weight: bold;
}

.elm-datepicker--other-month {
  color: #aaa;
}

.elm-datepicker--other-month .elm-datepicker--disabled {
  color: #eee;
}

.elm-datepicker--other-month .elm-datepicker--picked {
  color: #fff;
}

.elm-datepicker--months > td, .elm-datepicker--years > td {
  padding: 1em 1.5em;
  font-size: 1.1em;
}

.calendar-datepicker--container {
  align-items: center;
  position: relative;
}

.calendar-datepicker--calendar-grid {
  text-align: center;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  align-items: center;
  gap: .5em;
  margin-top: .5em;
  display: grid;
}

.calendar-datepicker--picker {
  background-color: var(--color-white);
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.calendar-datepicker--picker-header {
  border-bottom: 1px solid var(--color-divider);
  justify-content: space-between;
  align-items: center;
  padding: .5em;
  display: flex;
}

.calendar-datepicker--month-container {
  flex-grow: 1;
  display: flex;
}

.calendar-datepicker--arrows-container {
  gap: 2em;
  display: flex;
}

.calendar-datepicker--dow {
  cursor: default;
  background-color: var(--color-white);
  text-align: center;
  text-transform: uppercase;
  aspect-ratio: 1;
  width: 100%;
  max-width: 40px;
  height: auto;
  max-height: 40px;
  color: var(--color-primary-600);
  justify-content: center;
  align-items: center;
  margin: auto;
  font-weight: 800;
  display: flex;
}

.calendar-datepicker--day {
  cursor: pointer;
  text-align: center;
  color: var(--color-info-300);
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 40px;
  height: auto;
  max-height: 40px;
  margin: auto;
  display: flex;
}

.calendar-datepicker--disabled {
  cursor: default;
  color: var(--color-primary-600);
  opacity: .4;
}

.calendar-datepicker--disabled:hover {
  background: inherit;
}

.calendar-datepicker--picked {
  border: 1px solid var(--color-info-300);
  color: var(--color-info-300);
  border-radius: 10%;
}

.calendar-datepicker--today {
  color: #fff;
  background-color: var(--color-info-300);
  border-radius: 10%;
}

.calendar-datepicker--today.calendar-datepicker--picked {
  color: #fff;
}

.calendar-datepicker--day.calendar-datepicker--today.calendar-datepicker--disabled {
  background-color: var(--color-gray);
  color: #fff;
  border-radius: 10%;
}

.calendar-datepicker--other-month {
  color: #aaa;
}

.calendar-datepicker--other-month .calendar-datepicker--disabled {
  color: #eee;
}

.calendar-datepicker--other-month .calendar-datepicker--picked {
  color: #fff;
}

.badge {
  border-color: var(--color-primary-300);
  background-color: var(--color-primary-200);
  color: var(--color-primary-500);
  border-radius: 40px;
  justify-content: center;
  padding: .25rem;
  font-size: .875rem;
  display: flex;
}

.message-box {
  border-width: 1px;
  border-radius: .25rem;
  padding: .75rem;
}

.message-box--error {
  border-color: var(--color-danger-200);
  background-color: var(--color-danger-100);
}

.message-box--info {
  border-color: var(--color-info-200);
  background-color: var(--color-info-100);
}

.message-box .icon {
  width: 1.25rem;
  height: 1.25rem;
}

.message-box--info .icon {
  color: var(--color-info-300);
}

.message-box--error .icon {
  color: var(--color-danger-300);
}

.message-box__title {
  margin-bottom: .125rem;
  font-size: .875rem;
}

.message-box__content {
  font-size: .875rem;
}

.message-box__close {
  color: var(--color-primary-500);
  margin-left: auto;
}

.message-box__close .icon {
  color: var(--color-primary-500);
}

.message-box .list__item:not(:last-child) {
  margin-bottom: .25rem;
}

@media (width >= 768px) {
  .message-box {
    padding: 1rem 1.5rem;
  }

  .message-box__title {
    margin-top: -.125rem;
    margin-bottom: .25rem;
    font-size: 1rem;
  }
}

.quote-info {
  margin-bottom: 1rem;
}

.quote-info__bill, .quote-info__bill .text-support {
  font-size: .875rem;
}

.quote-info__rate--dollars {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.quote-info__rate--sign {
  margin-top: 2px;
  font-size: .625rem;
  line-height: .625rem;
}

.quote-info__rate--cents {
  margin-top: 1px;
  font-size: .625rem;
  line-height: .625rem;
}

.quote-info__rate--period {
  color: var(--color-primary-500);
  margin-bottom: 3px;
  margin-left: -.75rem;
  font-size: .625rem;
  line-height: .625rem;
}

@media (width >= 768px) {
  .quote-info__rate--dollars {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }

  .quote-info__rate--sign {
    margin-top: .2rem;
    font-size: .75rem;
    line-height: .75rem;
  }

  .quote-info__rate--cents {
    margin-top: .1rem;
    font-size: .75rem;
    line-height: .75rem;
  }

  .quote-info__rate--period {
    margin-left: -1rem;
    font-size: .75rem;
    line-height: .75rem;
  }
}

.collapser {
  color: var(--color-primary-500);
  margin-left: 1rem;
  margin-right: .75rem;
}

.collapser-open svg {
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(-90deg);
}

.collapser-open button, .collapser-closed button {
  padding: 0;
}

.collapser-closed svg {
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(90deg);
}

.scroll-container {
  position: relative;
}

.scroll-shadow {
  pointer-events: none;
  z-index: 50;
  transition: all .2s ease-out;
  position: absolute;
  inset: 0;
}

.scroll-shadow.scroll-shadow-top {
  box-shadow: inset 0 1em 1em -1em #000;
}

.scroll-shadow.scroll-shadow-bottom {
  box-shadow: inset 0 -1em 1em -1em #000;
}

.scroll-shadow.scroll-fade-top {
  box-shadow: inset 0 3em 2em -1em #fff;
}

.scroll-shadow.scroll-fade-bottom {
  box-shadow: inset 0 -3em 2em -1em #fff;
}

.debug__box {
  position: fixed;
  top: 5px;
  right: 15px;
}

.debug__text {
  padding-left: .5rem;
  font-size: 1rem;
  display: inline-flex;
}

.plan-quote .radio-card__content .radio-card__row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.plan-quote .radio-card__content .radio-card__row .message-box {
  margin-top: 1rem;
  margin-bottom: 0;
}

.plan-quote .radio-card__content .radio-card__row .message-box .link {
  font-size: .875rem;
}

.plan-quote__name {
  font-size: 1.125rem;
  line-height: 1.125rem;
}

.plan-quote__amount .quote-info__rate--sign {
  margin-top: 0;
  font-size: 1.75rem;
  line-height: 1.75rem;
}

.plan-quote.plan-quote--selected .plan-quote__amount {
  color: var(--color-primary-400);
}

.radio-card {
  cursor: pointer;
  border-width: 1px;
  border-color: var(--color-primary-200);
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: 1.5rem 1rem;
  transition: all .3s ease-out;
  display: flex;
}

.radio-card.radio-card--selected {
  border-color: var(--color-primary-400);
  background-color: var(--color-primary-100);
}

.radio-card.radio-card--disabled {
  pointer-events: none;
  background-color: var(--color-primary-100);
}

.radio-card.radio-card--top {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.radio-card.radio-card--bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.radio-card__radio {
  margin-right: 1rem;
  display: flex;
}

.radio-card__radio .radio__text {
  display: none;
}

.radio-card__content {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.radio-card__row {
  flex-direction: row;
  width: 100%;
  display: flex;
}

.radio-card:not(.radio-card--disabled):hover {
  z-index: 10;
  border-color: var(--color-primary-250);
}

.radio-card.radio-card--selected:hover {
  border-color: var(--color-primary-300);
  background-color: var(--color-primary-100);
}

.radio-card:not(.radio-card--disabled):hover .radio-card__radio .radio .radio__icon {
  border-color: var(--color-primary-250);
}

.card {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  border-radius: .25rem;
  padding: 1rem;
}

.card--success {
  border-width: 1px !important;
  border-color: var(--color-success-200) !important;
  background-color: var(--color-success-100) !important;
  border-radius: .25rem !important;
  margin: .5rem .5rem 0 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: .5rem !important;
}

.card--warning {
  border-width: 1px !important;
  border-color: var(--color-warning-200) !important;
  background-color: var(--color-warning-100) !important;
  border-radius: .25rem !important;
  margin: .5rem .5rem 0 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: .5rem !important;
}

.card--selected {
  border-color: var(--color-primary-400);
}

@keyframes slideDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.card.card--slide-down {
  transform-origin: top;
  animation: .3s cubic-bezier(.12, .4, .29, 1.05) slideDown;
}

.data-display {
  border-width: 1px;
  border-color: var(--color-divider);
  border-radius: .5rem;
  width: 100%;
  font-size: .875rem;
  overflow: hidden;
}

.data-display__table {
  table-layout: fixed;
  background-color: var(--color-white);
  width: 100%;
}

.data-display__row {
  width: 100%;
}

.data-display__row:not(:last-child) {
  border-bottom-width: 1px;
  border-color: var(--color-divider);
}

.data-display__label {
  border-right-width: 1px;
  border-color: var(--color-divider);
  background-color: var(--color-primary-100);
  width: 40%;
  padding: .75rem .625rem;
  font-weight: 800;
}

.data-display__value {
  padding: .75rem .625rem;
}

.clinical-note .formatted-text-data ul {
  margin-left: 2.5rem;
  list-style-type: disc;
}

.clinical-note .formatted-text-data ol {
  margin-left: 2.5rem;
  list-style-type: decimal;
}

.clinical-note .formatted-text-data h3 {
  margin-top: .25rem;
  margin-bottom: .25rem;
  font-size: 1.125rem;
  font-weight: 700;
}

.clinical-note-files {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  display: flex;
}

.clinical-note-files > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.clinical-note-files .file-button {
  border-width: 1px;
  border-color: var(--color-primary-200);
  border-radius: .5rem;
  margin: 0;
}

.clinical-note-files .file-button svg {
  fill: var(--color-primary-500);
}

.clinical-note-files .file-button button {
  padding: .5rem 1rem;
}

.clinical-note-files .file-button:hover {
  background-color: var(--color-primary-100);
}

@media print {
  .clinical-note .note-breadcrumbs {
    display: none;
  }

  .clinical-note .data-display {
    width: 50%;
  }
}

.textarea {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  width: 100%;
  color: var(--color-primary-600);
  border-radius: .25rem;
  padding: .5rem .75rem;
  font-size: .875rem;
  line-height: 1.375rem;
  transition: all .3s ease-out;
}

.textarea::placeholder {
  color: var(--color-primary-500);
}

.textarea:focus, .textarea:hover {
  border-color: var(--color-primary-250);
  outline: none;
}

.textarea:disabled:hover, .textarea:disabled:focus, .textarea:disabled {
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  color: var(--color-primary-250);
  box-shadow: none;
}

.textarea--invalid {
  border-color: var(--color-danger-300);
  color: var(--color-danger-300);
}

.textarea--invalid:hover, .textarea--invalid:focus {
  border-color: var(--color-danger-400);
}

.StripeElement {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  color: var(--color-primary-500);
  border-radius: .25rem;
  height: 42px;
  padding: 12px 8px 8px;
  transition: all .3s ease-out;
}

.stripe-card > .StripeElement {
  border: 0;
  height: auto;
  min-height: 14rem;
  padding: 0;
  transition: none;
}

@media (width >= 640px) {
  .stripe-card > .StripeElement {
    min-height: 10rem;
  }
}

.StripeElement--focus, .StripeElement:hover {
  border-width: 1px;
  border-color: var(--color-primary-250);
  outline: none;
}

.StripeElement--invalid {
  border-width: 1px;
  border-color: var(--color-danger-300);
  color: var(--color-danger-300);
}

.StripeElement--invalid:hover, .StripeElement--invalid.StripeElement--focus {
  border-color: var(--color-danger-400);
}

.checkout-item-wrap, .member-wrap {
  border-width: 1px;
  border-color: var(--color-primary-200);
  border-radius: .25rem;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
}

.member-label h2 {
  font-size: 1.125rem;
}

.member-name {
  font-size: .875rem;
}

@media (width >= 768px) {
  .member-wrap {
    margin-bottom: 1.5rem;
    padding: 1.5rem;
  }

  .member-label h2 {
    font-size: 1.5rem;
  }

  .member-name {
    font-size: 1rem;
  }
}

.member-wrap--remove {
  margin-left: 1rem;
}

.member-wrap--remove button {
  width: auto;
  padding: 0;
}

.member-wrap--remove button.button--link {
  color: var(--color-primary-500);
}

.member-buttons {
  border-width: 1px;
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  border-radius: .25rem;
  margin-left: 1rem;
  padding: 1.5rem;
  transition: all .2s ease-out;
}

.sidebar-wrap {
  border-width: 1px;
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  border-radius: 0;
  padding: 1rem 1.5rem;
  transition: all .2s ease-out;
}

.sidebar-mobile {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  position: sticky;
  bottom: 0;
}

@media (width >= 768px) {
  .sidebar-wrap {
    border-radius: .25rem;
    min-width: 18rem;
    margin-left: 1rem;
    padding: 1.5rem;
  }
}

@media (width >= 1024px) {
  .sidebar-wrap {
    min-width: 20rem;
  }
}

@media (width >= 1280px) {
  .sidebar-wrap {
    min-width: 24rem;
  }
}

.email-result {
  box-sizing: border-box;
  background-color: var(--color-primary-100);
  border: 1px solid #d0e6ff;
  border-radius: .125rem;
  padding: 3rem;
}

.email-result__logo {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-500);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.white-circle {
  background-color: var(--color-white);
  vertical-align: middle;
  border-radius: 9999px;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 19px;
  display: flex;
}

.addons-container {
  background-color: var(--color-white);
  vertical-align: middle;
  border-radius: .25rem;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem 1.5rem 1.5rem;
  display: flex;
}

.addon-category__description {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.addon-category {
  cursor: pointer;
  background-color: var(--color-primary-100);
  vertical-align: middle;
  border-radius: .25rem;
  flex-direction: column;
  justify-content: center;
  height: 160px;
  margin-right: 2rem;
  padding: 1rem;
  display: flex;
}

.addon-category:after {
  content: " ";
  border-top: 78px solid #0000;
  border-bottom: 78px solid #0000;
  border-left: 20px solid #f1f6fc;
  width: 0;
  height: 0;
  margin-left: -2rem;
  position: absolute;
  left: 100%;
}

.addon-category:not(:last-child) {
  margin-bottom: 1rem;
}

.addon-category.addon-category--selected {
  pointer-events: none;
  background-color: var(--color-primary-300);
}

.addon-category.addon-category--selected:after {
  border-left: 20px solid #006cd1;
}

.addon-category__addon {
  border-width: 1px;
  border-color: var(--color-primary-300);
  background-color: var(--color-white);
  border-radius: .25rem;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  display: flex;
}

.addon-category__addon:not(:last-child) {
  margin-bottom: 1rem;
}

.ach-text-fade-in {
  animation: .5s .5s both fadeIn;
}

.invoices-list .horizontal-rule {
  border-color: var(--color-divider);
}

.invoices-list .horizontal-rule:last-child {
  display: none;
}

.booking-enter-info-form .enter-info-date-of-birth.field--invalid .elm-datepicker--input.input {
  border-color: var(--color-danger-300);
  color: var(--color-danger-300);
}

.booking-appointment-not-available {
  z-index: 50;
  background: #374058d9;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.booking-navbar {
  width: 100%;
  max-width: 42rem;
  display: flex;
}

.booking-navbar .sidebar {
  top: 1.5rem;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

@font-face {
  font-family: Wix Madefor;
  src: url("variable-font.8768ca74.woff2") format("woff2"), url("variable-font.8b67e11c.woff") format("woff");
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.not-sr-only {
  clip: auto;
  white-space: normal;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  position: static;
  overflow: visible;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.-inset-0 {
  inset: 0;
}

.-inset-0\.5 {
  inset: -.125rem;
}

.-inset-1 {
  inset: -.25rem;
}

.-inset-1\.5 {
  inset: -.375rem;
}

.-inset-1\/2 {
  inset: -50%;
}

.-inset-1\/3 {
  inset: -33.3333%;
}

.-inset-1\/4 {
  inset: -25%;
}

.-inset-10 {
  inset: -2.5rem;
}

.-inset-11 {
  inset: -2.75rem;
}

.-inset-12 {
  inset: -3rem;
}

.-inset-14 {
  inset: -3.5rem;
}

.-inset-16 {
  inset: -4rem;
}

.-inset-2 {
  inset: -.5rem;
}

.-inset-2\.5 {
  inset: -.625rem;
}

.-inset-2\/3 {
  inset: -66.6667%;
}

.-inset-2\/4 {
  inset: -50%;
}

.-inset-20 {
  inset: -5rem;
}

.-inset-24 {
  inset: -6rem;
}

.-inset-28 {
  inset: -7rem;
}

.-inset-3 {
  inset: -.75rem;
}

.-inset-3\.5 {
  inset: -.875rem;
}

.-inset-3\/4 {
  inset: -75%;
}

.-inset-32 {
  inset: -8rem;
}

.-inset-36 {
  inset: -9rem;
}

.-inset-4 {
  inset: -1rem;
}

.-inset-40 {
  inset: -10rem;
}

.-inset-44 {
  inset: -11rem;
}

.-inset-48 {
  inset: -12rem;
}

.-inset-5 {
  inset: -1.25rem;
}

.-inset-52 {
  inset: -13rem;
}

.-inset-56 {
  inset: -14rem;
}

.-inset-6 {
  inset: -1.5rem;
}

.-inset-60 {
  inset: -15rem;
}

.-inset-64 {
  inset: -16rem;
}

.-inset-7 {
  inset: -1.75rem;
}

.-inset-72 {
  inset: -18rem;
}

.-inset-8 {
  inset: -2rem;
}

.-inset-80 {
  inset: -20rem;
}

.-inset-9 {
  inset: -2.25rem;
}

.-inset-96 {
  inset: -24rem;
}

.-inset-full {
  inset: -100%;
}

.-inset-px {
  inset: -1px;
}

.inset-0 {
  inset: 0;
}

.inset-0\.5 {
  inset: .125rem;
}

.inset-1 {
  inset: .25rem;
}

.inset-1\.5 {
  inset: .375rem;
}

.inset-1\/2 {
  inset: 50%;
}

.inset-1\/3 {
  inset: 33.3333%;
}

.inset-1\/4 {
  inset: 25%;
}

.inset-10 {
  inset: 2.5rem;
}

.inset-11 {
  inset: 2.75rem;
}

.inset-12 {
  inset: 3rem;
}

.inset-14 {
  inset: 3.5rem;
}

.inset-16 {
  inset: 4rem;
}

.inset-2 {
  inset: .5rem;
}

.inset-2\.5 {
  inset: .625rem;
}

.inset-2\/3 {
  inset: 66.6667%;
}

.inset-2\/4 {
  inset: 50%;
}

.inset-20 {
  inset: 5rem;
}

.inset-24 {
  inset: 6rem;
}

.inset-28 {
  inset: 7rem;
}

.inset-3 {
  inset: .75rem;
}

.inset-3\.5 {
  inset: .875rem;
}

.inset-3\/4 {
  inset: 75%;
}

.inset-32 {
  inset: 8rem;
}

.inset-36 {
  inset: 9rem;
}

.inset-4 {
  inset: 1rem;
}

.inset-40 {
  inset: 10rem;
}

.inset-44 {
  inset: 11rem;
}

.inset-48 {
  inset: 12rem;
}

.inset-5 {
  inset: 1.25rem;
}

.inset-52 {
  inset: 13rem;
}

.inset-56 {
  inset: 14rem;
}

.inset-6 {
  inset: 1.5rem;
}

.inset-60 {
  inset: 15rem;
}

.inset-64 {
  inset: 16rem;
}

.inset-7 {
  inset: 1.75rem;
}

.inset-72 {
  inset: 18rem;
}

.inset-8 {
  inset: 2rem;
}

.inset-80 {
  inset: 20rem;
}

.inset-9 {
  inset: 2.25rem;
}

.inset-96 {
  inset: 24rem;
}

.inset-auto {
  inset: auto;
}

.inset-full {
  inset: 100%;
}

.inset-px {
  inset: 1px;
}

.-inset-x-0 {
  left: 0;
  right: 0;
}

.-inset-x-0\.5 {
  left: -.125rem;
  right: -.125rem;
}

.-inset-x-1 {
  left: -.25rem;
  right: -.25rem;
}

.-inset-x-1\.5 {
  left: -.375rem;
  right: -.375rem;
}

.-inset-x-1\/2 {
  left: -50%;
  right: -50%;
}

.-inset-x-1\/3 {
  left: -33.3333%;
  right: -33.3333%;
}

.-inset-x-1\/4 {
  left: -25%;
  right: -25%;
}

.-inset-x-10 {
  left: -2.5rem;
  right: -2.5rem;
}

.-inset-x-11 {
  left: -2.75rem;
  right: -2.75rem;
}

.-inset-x-12 {
  left: -3rem;
  right: -3rem;
}

.-inset-x-14 {
  left: -3.5rem;
  right: -3.5rem;
}

.-inset-x-16 {
  left: -4rem;
  right: -4rem;
}

.-inset-x-2 {
  left: -.5rem;
  right: -.5rem;
}

.-inset-x-2\.5 {
  left: -.625rem;
  right: -.625rem;
}

.-inset-x-2\/3 {
  left: -66.6667%;
  right: -66.6667%;
}

.-inset-x-2\/4 {
  left: -50%;
  right: -50%;
}

.-inset-x-20 {
  left: -5rem;
  right: -5rem;
}

.-inset-x-24 {
  left: -6rem;
  right: -6rem;
}

.-inset-x-28 {
  left: -7rem;
  right: -7rem;
}

.-inset-x-3 {
  left: -.75rem;
  right: -.75rem;
}

.-inset-x-3\.5 {
  left: -.875rem;
  right: -.875rem;
}

.-inset-x-3\/4 {
  left: -75%;
  right: -75%;
}

.-inset-x-32 {
  left: -8rem;
  right: -8rem;
}

.-inset-x-36 {
  left: -9rem;
  right: -9rem;
}

.-inset-x-4 {
  left: -1rem;
  right: -1rem;
}

.-inset-x-40 {
  left: -10rem;
  right: -10rem;
}

.-inset-x-44 {
  left: -11rem;
  right: -11rem;
}

.-inset-x-48 {
  left: -12rem;
  right: -12rem;
}

.-inset-x-5 {
  left: -1.25rem;
  right: -1.25rem;
}

.-inset-x-52 {
  left: -13rem;
  right: -13rem;
}

.-inset-x-56 {
  left: -14rem;
  right: -14rem;
}

.-inset-x-6 {
  left: -1.5rem;
  right: -1.5rem;
}

.-inset-x-60 {
  left: -15rem;
  right: -15rem;
}

.-inset-x-64 {
  left: -16rem;
  right: -16rem;
}

.-inset-x-7 {
  left: -1.75rem;
  right: -1.75rem;
}

.-inset-x-72 {
  left: -18rem;
  right: -18rem;
}

.-inset-x-8 {
  left: -2rem;
  right: -2rem;
}

.-inset-x-80 {
  left: -20rem;
  right: -20rem;
}

.-inset-x-9 {
  left: -2.25rem;
  right: -2.25rem;
}

.-inset-x-96 {
  left: -24rem;
  right: -24rem;
}

.-inset-x-full {
  left: -100%;
  right: -100%;
}

.-inset-x-px {
  left: -1px;
  right: -1px;
}

.-inset-y-0 {
  top: 0;
  bottom: 0;
}

.-inset-y-0\.5 {
  top: -.125rem;
  bottom: -.125rem;
}

.-inset-y-1 {
  top: -.25rem;
  bottom: -.25rem;
}

.-inset-y-1\.5 {
  top: -.375rem;
  bottom: -.375rem;
}

.-inset-y-1\/2 {
  top: -50%;
  bottom: -50%;
}

.-inset-y-1\/3 {
  top: -33.3333%;
  bottom: -33.3333%;
}

.-inset-y-1\/4 {
  top: -25%;
  bottom: -25%;
}

.-inset-y-10 {
  top: -2.5rem;
  bottom: -2.5rem;
}

.-inset-y-11 {
  top: -2.75rem;
  bottom: -2.75rem;
}

.-inset-y-12 {
  top: -3rem;
  bottom: -3rem;
}

.-inset-y-14 {
  top: -3.5rem;
  bottom: -3.5rem;
}

.-inset-y-16 {
  top: -4rem;
  bottom: -4rem;
}

.-inset-y-2 {
  top: -.5rem;
  bottom: -.5rem;
}

.-inset-y-2\.5 {
  top: -.625rem;
  bottom: -.625rem;
}

.-inset-y-2\/3 {
  top: -66.6667%;
  bottom: -66.6667%;
}

.-inset-y-2\/4 {
  top: -50%;
  bottom: -50%;
}

.-inset-y-20 {
  top: -5rem;
  bottom: -5rem;
}

.-inset-y-24 {
  top: -6rem;
  bottom: -6rem;
}

.-inset-y-28 {
  top: -7rem;
  bottom: -7rem;
}

.-inset-y-3 {
  top: -.75rem;
  bottom: -.75rem;
}

.-inset-y-3\.5 {
  top: -.875rem;
  bottom: -.875rem;
}

.-inset-y-3\/4 {
  top: -75%;
  bottom: -75%;
}

.-inset-y-32 {
  top: -8rem;
  bottom: -8rem;
}

.-inset-y-36 {
  top: -9rem;
  bottom: -9rem;
}

.-inset-y-4 {
  top: -1rem;
  bottom: -1rem;
}

.-inset-y-40 {
  top: -10rem;
  bottom: -10rem;
}

.-inset-y-44 {
  top: -11rem;
  bottom: -11rem;
}

.-inset-y-48 {
  top: -12rem;
  bottom: -12rem;
}

.-inset-y-5 {
  top: -1.25rem;
  bottom: -1.25rem;
}

.-inset-y-52 {
  top: -13rem;
  bottom: -13rem;
}

.-inset-y-56 {
  top: -14rem;
  bottom: -14rem;
}

.-inset-y-6 {
  top: -1.5rem;
  bottom: -1.5rem;
}

.-inset-y-60 {
  top: -15rem;
  bottom: -15rem;
}

.-inset-y-64 {
  top: -16rem;
  bottom: -16rem;
}

.-inset-y-7 {
  top: -1.75rem;
  bottom: -1.75rem;
}

.-inset-y-72 {
  top: -18rem;
  bottom: -18rem;
}

.-inset-y-8 {
  top: -2rem;
  bottom: -2rem;
}

.-inset-y-80 {
  top: -20rem;
  bottom: -20rem;
}

.-inset-y-9 {
  top: -2.25rem;
  bottom: -2.25rem;
}

.-inset-y-96 {
  top: -24rem;
  bottom: -24rem;
}

.-inset-y-full {
  top: -100%;
  bottom: -100%;
}

.-inset-y-px {
  top: -1px;
  bottom: -1px;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-x-0\.5 {
  left: .125rem;
  right: .125rem;
}

.inset-x-1 {
  left: .25rem;
  right: .25rem;
}

.inset-x-1\.5 {
  left: .375rem;
  right: .375rem;
}

.inset-x-1\/2 {
  left: 50%;
  right: 50%;
}

.inset-x-1\/3 {
  left: 33.3333%;
  right: 33.3333%;
}

.inset-x-1\/4 {
  left: 25%;
  right: 25%;
}

.inset-x-10 {
  left: 2.5rem;
  right: 2.5rem;
}

.inset-x-11 {
  left: 2.75rem;
  right: 2.75rem;
}

.inset-x-12 {
  left: 3rem;
  right: 3rem;
}

.inset-x-14 {
  left: 3.5rem;
  right: 3.5rem;
}

.inset-x-16 {
  left: 4rem;
  right: 4rem;
}

.inset-x-2 {
  left: .5rem;
  right: .5rem;
}

.inset-x-2\.5 {
  left: .625rem;
  right: .625rem;
}

.inset-x-2\/3 {
  left: 66.6667%;
  right: 66.6667%;
}

.inset-x-2\/4 {
  left: 50%;
  right: 50%;
}

.inset-x-20 {
  left: 5rem;
  right: 5rem;
}

.inset-x-24 {
  left: 6rem;
  right: 6rem;
}

.inset-x-28 {
  left: 7rem;
  right: 7rem;
}

.inset-x-3 {
  left: .75rem;
  right: .75rem;
}

.inset-x-3\.5 {
  left: .875rem;
  right: .875rem;
}

.inset-x-3\/4 {
  left: 75%;
  right: 75%;
}

.inset-x-32 {
  left: 8rem;
  right: 8rem;
}

.inset-x-36 {
  left: 9rem;
  right: 9rem;
}

.inset-x-4 {
  left: 1rem;
  right: 1rem;
}

.inset-x-40 {
  left: 10rem;
  right: 10rem;
}

.inset-x-44 {
  left: 11rem;
  right: 11rem;
}

.inset-x-48 {
  left: 12rem;
  right: 12rem;
}

.inset-x-5 {
  left: 1.25rem;
  right: 1.25rem;
}

.inset-x-52 {
  left: 13rem;
  right: 13rem;
}

.inset-x-56 {
  left: 14rem;
  right: 14rem;
}

.inset-x-6 {
  left: 1.5rem;
  right: 1.5rem;
}

.inset-x-60 {
  left: 15rem;
  right: 15rem;
}

.inset-x-64 {
  left: 16rem;
  right: 16rem;
}

.inset-x-7 {
  left: 1.75rem;
  right: 1.75rem;
}

.inset-x-72 {
  left: 18rem;
  right: 18rem;
}

.inset-x-8 {
  left: 2rem;
  right: 2rem;
}

.inset-x-80 {
  left: 20rem;
  right: 20rem;
}

.inset-x-9 {
  left: 2.25rem;
  right: 2.25rem;
}

.inset-x-96 {
  left: 24rem;
  right: 24rem;
}

.inset-x-auto {
  left: auto;
  right: auto;
}

.inset-x-full {
  left: 100%;
  right: 100%;
}

.inset-x-px {
  left: 1px;
  right: 1px;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-y-0\.5 {
  top: .125rem;
  bottom: .125rem;
}

.inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.inset-y-1\.5 {
  top: .375rem;
  bottom: .375rem;
}

.inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}

.inset-y-1\/3 {
  top: 33.3333%;
  bottom: 33.3333%;
}

.inset-y-1\/4 {
  top: 25%;
  bottom: 25%;
}

.inset-y-10 {
  top: 2.5rem;
  bottom: 2.5rem;
}

.inset-y-11 {
  top: 2.75rem;
  bottom: 2.75rem;
}

.inset-y-12 {
  top: 3rem;
  bottom: 3rem;
}

.inset-y-14 {
  top: 3.5rem;
  bottom: 3.5rem;
}

.inset-y-16 {
  top: 4rem;
  bottom: 4rem;
}

.inset-y-2 {
  top: .5rem;
  bottom: .5rem;
}

.inset-y-2\.5 {
  top: .625rem;
  bottom: .625rem;
}

.inset-y-2\/3 {
  top: 66.6667%;
  bottom: 66.6667%;
}

.inset-y-2\/4 {
  top: 50%;
  bottom: 50%;
}

.inset-y-20 {
  top: 5rem;
  bottom: 5rem;
}

.inset-y-24 {
  top: 6rem;
  bottom: 6rem;
}

.inset-y-28 {
  top: 7rem;
  bottom: 7rem;
}

.inset-y-3 {
  top: .75rem;
  bottom: .75rem;
}

.inset-y-3\.5 {
  top: .875rem;
  bottom: .875rem;
}

.inset-y-3\/4 {
  top: 75%;
  bottom: 75%;
}

.inset-y-32 {
  top: 8rem;
  bottom: 8rem;
}

.inset-y-36 {
  top: 9rem;
  bottom: 9rem;
}

.inset-y-4 {
  top: 1rem;
  bottom: 1rem;
}

.inset-y-40 {
  top: 10rem;
  bottom: 10rem;
}

.inset-y-44 {
  top: 11rem;
  bottom: 11rem;
}

.inset-y-48 {
  top: 12rem;
  bottom: 12rem;
}

.inset-y-5 {
  top: 1.25rem;
  bottom: 1.25rem;
}

.inset-y-52 {
  top: 13rem;
  bottom: 13rem;
}

.inset-y-56 {
  top: 14rem;
  bottom: 14rem;
}

.inset-y-6 {
  top: 1.5rem;
  bottom: 1.5rem;
}

.inset-y-60 {
  top: 15rem;
  bottom: 15rem;
}

.inset-y-64 {
  top: 16rem;
  bottom: 16rem;
}

.inset-y-7 {
  top: 1.75rem;
  bottom: 1.75rem;
}

.inset-y-72 {
  top: 18rem;
  bottom: 18rem;
}

.inset-y-8 {
  top: 2rem;
  bottom: 2rem;
}

.inset-y-80 {
  top: 20rem;
  bottom: 20rem;
}

.inset-y-9 {
  top: 2.25rem;
  bottom: 2.25rem;
}

.inset-y-96 {
  top: 24rem;
  bottom: 24rem;
}

.inset-y-auto {
  top: auto;
  bottom: auto;
}

.inset-y-full {
  top: 100%;
  bottom: 100%;
}

.inset-y-px {
  top: 1px;
  bottom: 1px;
}

.-bottom-0 {
  bottom: 0;
}

.-bottom-0\.5 {
  bottom: -.125rem;
}

.-bottom-1 {
  bottom: -.25rem;
}

.-bottom-1\.5 {
  bottom: -.375rem;
}

.-bottom-1\/2 {
  bottom: -50%;
}

.-bottom-1\/3 {
  bottom: -33.3333%;
}

.-bottom-1\/4 {
  bottom: -25%;
}

.-bottom-10 {
  bottom: -2.5rem;
}

.-bottom-11 {
  bottom: -2.75rem;
}

.-bottom-12 {
  bottom: -3rem;
}

.-bottom-14 {
  bottom: -3.5rem;
}

.-bottom-16 {
  bottom: -4rem;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-bottom-2\.5 {
  bottom: -.625rem;
}

.-bottom-2\/3 {
  bottom: -66.6667%;
}

.-bottom-2\/4 {
  bottom: -50%;
}

.-bottom-20 {
  bottom: -5rem;
}

.-bottom-24 {
  bottom: -6rem;
}

.-bottom-28 {
  bottom: -7rem;
}

.-bottom-3 {
  bottom: -.75rem;
}

.-bottom-3\.5 {
  bottom: -.875rem;
}

.-bottom-3\/4 {
  bottom: -75%;
}

.-bottom-32 {
  bottom: -8rem;
}

.-bottom-36 {
  bottom: -9rem;
}

.-bottom-4 {
  bottom: -1rem;
}

.-bottom-40 {
  bottom: -10rem;
}

.-bottom-44 {
  bottom: -11rem;
}

.-bottom-48 {
  bottom: -12rem;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.-bottom-52 {
  bottom: -13rem;
}

.-bottom-56 {
  bottom: -14rem;
}

.-bottom-6 {
  bottom: -1.5rem;
}

.-bottom-60 {
  bottom: -15rem;
}

.-bottom-64 {
  bottom: -16rem;
}

.-bottom-7 {
  bottom: -1.75rem;
}

.-bottom-72 {
  bottom: -18rem;
}

.-bottom-8 {
  bottom: -2rem;
}

.-bottom-80 {
  bottom: -20rem;
}

.-bottom-9 {
  bottom: -2.25rem;
}

.-bottom-96 {
  bottom: -24rem;
}

.-bottom-full {
  bottom: -100%;
}

.-bottom-px {
  bottom: -1px;
}

.-end-0 {
  inset-inline-end: 0;
}

.-end-0\.5 {
  inset-inline-end: -.125rem;
}

.-end-1 {
  inset-inline-end: -.25rem;
}

.-end-1\.5 {
  inset-inline-end: -.375rem;
}

.-end-1\/2 {
  inset-inline-end: -50%;
}

.-end-1\/3 {
  inset-inline-end: -33.3333%;
}

.-end-1\/4 {
  inset-inline-end: -25%;
}

.-end-10 {
  inset-inline-end: -2.5rem;
}

.-end-11 {
  inset-inline-end: -2.75rem;
}

.-end-12 {
  inset-inline-end: -3rem;
}

.-end-14 {
  inset-inline-end: -3.5rem;
}

.-end-16 {
  inset-inline-end: -4rem;
}

.-end-2 {
  inset-inline-end: -.5rem;
}

.-end-2\.5 {
  inset-inline-end: -.625rem;
}

.-end-2\/3 {
  inset-inline-end: -66.6667%;
}

.-end-2\/4 {
  inset-inline-end: -50%;
}

.-end-20 {
  inset-inline-end: -5rem;
}

.-end-24 {
  inset-inline-end: -6rem;
}

.-end-28 {
  inset-inline-end: -7rem;
}

.-end-3 {
  inset-inline-end: -.75rem;
}

.-end-3\.5 {
  inset-inline-end: -.875rem;
}

.-end-3\/4 {
  inset-inline-end: -75%;
}

.-end-32 {
  inset-inline-end: -8rem;
}

.-end-36 {
  inset-inline-end: -9rem;
}

.-end-4 {
  inset-inline-end: -1rem;
}

.-end-40 {
  inset-inline-end: -10rem;
}

.-end-44 {
  inset-inline-end: -11rem;
}

.-end-48 {
  inset-inline-end: -12rem;
}

.-end-5 {
  inset-inline-end: -1.25rem;
}

.-end-52 {
  inset-inline-end: -13rem;
}

.-end-56 {
  inset-inline-end: -14rem;
}

.-end-6 {
  inset-inline-end: -1.5rem;
}

.-end-60 {
  inset-inline-end: -15rem;
}

.-end-64 {
  inset-inline-end: -16rem;
}

.-end-7 {
  inset-inline-end: -1.75rem;
}

.-end-72 {
  inset-inline-end: -18rem;
}

.-end-8 {
  inset-inline-end: -2rem;
}

.-end-80 {
  inset-inline-end: -20rem;
}

.-end-9 {
  inset-inline-end: -2.25rem;
}

.-end-96 {
  inset-inline-end: -24rem;
}

.-end-full {
  inset-inline-end: -100%;
}

.-end-px {
  inset-inline-end: -1px;
}

.-left-0 {
  left: 0;
}

.-left-0\.5 {
  left: -.125rem;
}

.-left-1 {
  left: -.25rem;
}

.-left-1\.5 {
  left: -.375rem;
}

.-left-1\/2 {
  left: -50%;
}

.-left-1\/3 {
  left: -33.3333%;
}

.-left-1\/4 {
  left: -25%;
}

.-left-10 {
  left: -2.5rem;
}

.-left-11 {
  left: -2.75rem;
}

.-left-12 {
  left: -3rem;
}

.-left-14 {
  left: -3.5rem;
}

.-left-16 {
  left: -4rem;
}

.-left-2 {
  left: -.5rem;
}

.-left-2\.5 {
  left: -.625rem;
}

.-left-2\/3 {
  left: -66.6667%;
}

.-left-2\/4 {
  left: -50%;
}

.-left-20 {
  left: -5rem;
}

.-left-24 {
  left: -6rem;
}

.-left-28 {
  left: -7rem;
}

.-left-3 {
  left: -.75rem;
}

.-left-3\.5 {
  left: -.875rem;
}

.-left-3\/4 {
  left: -75%;
}

.-left-32 {
  left: -8rem;
}

.-left-36 {
  left: -9rem;
}

.-left-4 {
  left: -1rem;
}

.-left-40 {
  left: -10rem;
}

.-left-44 {
  left: -11rem;
}

.-left-48 {
  left: -12rem;
}

.-left-5 {
  left: -1.25rem;
}

.-left-52 {
  left: -13rem;
}

.-left-56 {
  left: -14rem;
}

.-left-6 {
  left: -1.5rem;
}

.-left-60 {
  left: -15rem;
}

.-left-64 {
  left: -16rem;
}

.-left-7 {
  left: -1.75rem;
}

.-left-72 {
  left: -18rem;
}

.-left-8 {
  left: -2rem;
}

.-left-80 {
  left: -20rem;
}

.-left-9 {
  left: -2.25rem;
}

.-left-96 {
  left: -24rem;
}

.-left-full {
  left: -100%;
}

.-left-px {
  left: -1px;
}

.-right-0 {
  right: 0;
}

.-right-0\.5 {
  right: -.125rem;
}

.-right-1 {
  right: -.25rem;
}

.-right-1\.5 {
  right: -.375rem;
}

.-right-1\/2 {
  right: -50%;
}

.-right-1\/3 {
  right: -33.3333%;
}

.-right-1\/4 {
  right: -25%;
}

.-right-10 {
  right: -2.5rem;
}

.-right-11 {
  right: -2.75rem;
}

.-right-12 {
  right: -3rem;
}

.-right-14 {
  right: -3.5rem;
}

.-right-16 {
  right: -4rem;
}

.-right-2 {
  right: -.5rem;
}

.-right-2\.5 {
  right: -.625rem;
}

.-right-2\/3 {
  right: -66.6667%;
}

.-right-2\/4 {
  right: -50%;
}

.-right-20 {
  right: -5rem;
}

.-right-24 {
  right: -6rem;
}

.-right-28 {
  right: -7rem;
}

.-right-3 {
  right: -.75rem;
}

.-right-3\.5 {
  right: -.875rem;
}

.-right-3\/4 {
  right: -75%;
}

.-right-32 {
  right: -8rem;
}

.-right-36 {
  right: -9rem;
}

.-right-4 {
  right: -1rem;
}

.-right-40 {
  right: -10rem;
}

.-right-44 {
  right: -11rem;
}

.-right-48 {
  right: -12rem;
}

.-right-5 {
  right: -1.25rem;
}

.-right-52 {
  right: -13rem;
}

.-right-56 {
  right: -14rem;
}

.-right-6 {
  right: -1.5rem;
}

.-right-60 {
  right: -15rem;
}

.-right-64 {
  right: -16rem;
}

.-right-7 {
  right: -1.75rem;
}

.-right-72 {
  right: -18rem;
}

.-right-8 {
  right: -2rem;
}

.-right-80 {
  right: -20rem;
}

.-right-9 {
  right: -2.25rem;
}

.-right-96 {
  right: -24rem;
}

.-right-full {
  right: -100%;
}

.-right-px {
  right: -1px;
}

.-start-0 {
  inset-inline-start: 0;
}

.-start-0\.5 {
  inset-inline-start: -.125rem;
}

.-start-1 {
  inset-inline-start: -.25rem;
}

.-start-1\.5 {
  inset-inline-start: -.375rem;
}

.-start-1\/2 {
  inset-inline-start: -50%;
}

.-start-1\/3 {
  inset-inline-start: -33.3333%;
}

.-start-1\/4 {
  inset-inline-start: -25%;
}

.-start-10 {
  inset-inline-start: -2.5rem;
}

.-start-11 {
  inset-inline-start: -2.75rem;
}

.-start-12 {
  inset-inline-start: -3rem;
}

.-start-14 {
  inset-inline-start: -3.5rem;
}

.-start-16 {
  inset-inline-start: -4rem;
}

.-start-2 {
  inset-inline-start: -.5rem;
}

.-start-2\.5 {
  inset-inline-start: -.625rem;
}

.-start-2\/3 {
  inset-inline-start: -66.6667%;
}

.-start-2\/4 {
  inset-inline-start: -50%;
}

.-start-20 {
  inset-inline-start: -5rem;
}

.-start-24 {
  inset-inline-start: -6rem;
}

.-start-28 {
  inset-inline-start: -7rem;
}

.-start-3 {
  inset-inline-start: -.75rem;
}

.-start-3\.5 {
  inset-inline-start: -.875rem;
}

.-start-3\/4 {
  inset-inline-start: -75%;
}

.-start-32 {
  inset-inline-start: -8rem;
}

.-start-36 {
  inset-inline-start: -9rem;
}

.-start-4 {
  inset-inline-start: -1rem;
}

.-start-40 {
  inset-inline-start: -10rem;
}

.-start-44 {
  inset-inline-start: -11rem;
}

.-start-48 {
  inset-inline-start: -12rem;
}

.-start-5 {
  inset-inline-start: -1.25rem;
}

.-start-52 {
  inset-inline-start: -13rem;
}

.-start-56 {
  inset-inline-start: -14rem;
}

.-start-6 {
  inset-inline-start: -1.5rem;
}

.-start-60 {
  inset-inline-start: -15rem;
}

.-start-64 {
  inset-inline-start: -16rem;
}

.-start-7 {
  inset-inline-start: -1.75rem;
}

.-start-72 {
  inset-inline-start: -18rem;
}

.-start-8 {
  inset-inline-start: -2rem;
}

.-start-80 {
  inset-inline-start: -20rem;
}

.-start-9 {
  inset-inline-start: -2.25rem;
}

.-start-96 {
  inset-inline-start: -24rem;
}

.-start-full {
  inset-inline-start: -100%;
}

.-start-px {
  inset-inline-start: -1px;
}

.-top-0 {
  top: 0;
}

.-top-0\.5 {
  top: -.125rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-1\.5 {
  top: -.375rem;
}

.-top-1\/2 {
  top: -50%;
}

.-top-1\/3 {
  top: -33.3333%;
}

.-top-1\/4 {
  top: -25%;
}

.-top-10 {
  top: -2.5rem;
}

.-top-11 {
  top: -2.75rem;
}

.-top-12 {
  top: -3rem;
}

.-top-14 {
  top: -3.5rem;
}

.-top-16 {
  top: -4rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-2\.5 {
  top: -.625rem;
}

.-top-2\/3 {
  top: -66.6667%;
}

.-top-2\/4 {
  top: -50%;
}

.-top-20 {
  top: -5rem;
}

.-top-24 {
  top: -6rem;
}

.-top-28 {
  top: -7rem;
}

.-top-3 {
  top: -.75rem;
}

.-top-3\.5 {
  top: -.875rem;
}

.-top-3\/4 {
  top: -75%;
}

.-top-32 {
  top: -8rem;
}

.-top-36 {
  top: -9rem;
}

.-top-4 {
  top: -1rem;
}

.-top-40 {
  top: -10rem;
}

.-top-44 {
  top: -11rem;
}

.-top-48 {
  top: -12rem;
}

.-top-5 {
  top: -1.25rem;
}

.-top-52 {
  top: -13rem;
}

.-top-56 {
  top: -14rem;
}

.-top-6 {
  top: -1.5rem;
}

.-top-60 {
  top: -15rem;
}

.-top-64 {
  top: -16rem;
}

.-top-7 {
  top: -1.75rem;
}

.-top-72 {
  top: -18rem;
}

.-top-8 {
  top: -2rem;
}

.-top-80 {
  top: -20rem;
}

.-top-9 {
  top: -2.25rem;
}

.-top-96 {
  top: -24rem;
}

.-top-full {
  top: -100%;
}

.-top-px {
  top: -1px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-1\.5 {
  bottom: .375rem;
}

.bottom-1\/2 {
  bottom: 50%;
}

.bottom-1\/3 {
  bottom: 33.3333%;
}

.bottom-1\/4 {
  bottom: 25%;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-11 {
  bottom: 2.75rem;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-2\.5 {
  bottom: .625rem;
}

.bottom-2\/3 {
  bottom: 66.6667%;
}

.bottom-2\/4 {
  bottom: 50%;
}

.bottom-20 {
  bottom: 5rem;
}

.bottom-24 {
  bottom: 6rem;
}

.bottom-28 {
  bottom: 7rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-3\.5 {
  bottom: .875rem;
}

.bottom-3\/4 {
  bottom: 75%;
}

.bottom-32 {
  bottom: 8rem;
}

.bottom-36 {
  bottom: 9rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-40 {
  bottom: 10rem;
}

.bottom-44 {
  bottom: 11rem;
}

.bottom-48 {
  bottom: 12rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-52 {
  bottom: 13rem;
}

.bottom-56 {
  bottom: 14rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-60 {
  bottom: 15rem;
}

.bottom-64 {
  bottom: 16rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.bottom-72 {
  bottom: 18rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-80 {
  bottom: 20rem;
}

.bottom-9 {
  bottom: 2.25rem;
}

.bottom-96 {
  bottom: 24rem;
}

.bottom-auto {
  bottom: auto;
}

.bottom-full {
  bottom: 100%;
}

.bottom-px {
  bottom: 1px;
}

.end-0 {
  inset-inline-end: 0;
}

.end-0\.5 {
  inset-inline-end: .125rem;
}

.end-1 {
  inset-inline-end: .25rem;
}

.end-1\.5 {
  inset-inline-end: .375rem;
}

.end-1\/2 {
  inset-inline-end: 50%;
}

.end-1\/3 {
  inset-inline-end: 33.3333%;
}

.end-1\/4 {
  inset-inline-end: 25%;
}

.end-10 {
  inset-inline-end: 2.5rem;
}

.end-11 {
  inset-inline-end: 2.75rem;
}

.end-12 {
  inset-inline-end: 3rem;
}

.end-14 {
  inset-inline-end: 3.5rem;
}

.end-16 {
  inset-inline-end: 4rem;
}

.end-2 {
  inset-inline-end: .5rem;
}

.end-2\.5 {
  inset-inline-end: .625rem;
}

.end-2\/3 {
  inset-inline-end: 66.6667%;
}

.end-2\/4 {
  inset-inline-end: 50%;
}

.end-20 {
  inset-inline-end: 5rem;
}

.end-24 {
  inset-inline-end: 6rem;
}

.end-28 {
  inset-inline-end: 7rem;
}

.end-3 {
  inset-inline-end: .75rem;
}

.end-3\.5 {
  inset-inline-end: .875rem;
}

.end-3\/4 {
  inset-inline-end: 75%;
}

.end-32 {
  inset-inline-end: 8rem;
}

.end-36 {
  inset-inline-end: 9rem;
}

.end-4 {
  inset-inline-end: 1rem;
}

.end-40 {
  inset-inline-end: 10rem;
}

.end-44 {
  inset-inline-end: 11rem;
}

.end-48 {
  inset-inline-end: 12rem;
}

.end-5 {
  inset-inline-end: 1.25rem;
}

.end-52 {
  inset-inline-end: 13rem;
}

.end-56 {
  inset-inline-end: 14rem;
}

.end-6 {
  inset-inline-end: 1.5rem;
}

.end-60 {
  inset-inline-end: 15rem;
}

.end-64 {
  inset-inline-end: 16rem;
}

.end-7 {
  inset-inline-end: 1.75rem;
}

.end-72 {
  inset-inline-end: 18rem;
}

.end-8 {
  inset-inline-end: 2rem;
}

.end-80 {
  inset-inline-end: 20rem;
}

.end-9 {
  inset-inline-end: 2.25rem;
}

.end-96 {
  inset-inline-end: 24rem;
}

.end-auto {
  inset-inline-end: auto;
}

.end-full {
  inset-inline-end: 100%;
}

.end-px {
  inset-inline-end: 1px;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-1 {
  left: .25rem;
}

.left-1\.5 {
  left: .375rem;
}

.left-1\/2 {
  left: 50%;
}

.left-1\/3 {
  left: 33.3333%;
}

.left-1\/4 {
  left: 25%;
}

.left-10 {
  left: 2.5rem;
}

.left-11 {
  left: 2.75rem;
}

.left-12 {
  left: 3rem;
}

.left-14 {
  left: 3.5rem;
}

.left-16 {
  left: 4rem;
}

.left-2 {
  left: .5rem;
}

.left-2\.5 {
  left: .625rem;
}

.left-2\/3 {
  left: 66.6667%;
}

.left-2\/4 {
  left: 50%;
}

.left-20 {
  left: 5rem;
}

.left-24 {
  left: 6rem;
}

.left-28 {
  left: 7rem;
}

.left-3 {
  left: .75rem;
}

.left-3\.5 {
  left: .875rem;
}

.left-3\/4 {
  left: 75%;
}

.left-32 {
  left: 8rem;
}

.left-36 {
  left: 9rem;
}

.left-4 {
  left: 1rem;
}

.left-40 {
  left: 10rem;
}

.left-44 {
  left: 11rem;
}

.left-48 {
  left: 12rem;
}

.left-5 {
  left: 1.25rem;
}

.left-52 {
  left: 13rem;
}

.left-56 {
  left: 14rem;
}

.left-6 {
  left: 1.5rem;
}

.left-60 {
  left: 15rem;
}

.left-64 {
  left: 16rem;
}

.left-7 {
  left: 1.75rem;
}

.left-72 {
  left: 18rem;
}

.left-8 {
  left: 2rem;
}

.left-80 {
  left: 20rem;
}

.left-9 {
  left: 2.25rem;
}

.left-96 {
  left: 24rem;
}

.left-auto {
  left: auto;
}

.left-full {
  left: 100%;
}

.left-px {
  left: 1px;
}

.right-0 {
  right: 0;
}

.right-0\.5 {
  right: .125rem;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-1\/2 {
  right: 50%;
}

.right-1\/3 {
  right: 33.3333%;
}

.right-1\/4 {
  right: 25%;
}

.right-10 {
  right: 2.5rem;
}

.right-11 {
  right: 2.75rem;
}

.right-12 {
  right: 3rem;
}

.right-14 {
  right: 3.5rem;
}

.right-16 {
  right: 4rem;
}

.right-2 {
  right: .5rem;
}

.right-2\.5 {
  right: .625rem;
}

.right-2\/3 {
  right: 66.6667%;
}

.right-2\/4 {
  right: 50%;
}

.right-20 {
  right: 5rem;
}

.right-24 {
  right: 6rem;
}

.right-28 {
  right: 7rem;
}

.right-3 {
  right: .75rem;
}

.right-3\.5 {
  right: .875rem;
}

.right-3\/4 {
  right: 75%;
}

.right-32 {
  right: 8rem;
}

.right-36 {
  right: 9rem;
}

.right-4 {
  right: 1rem;
}

.right-40 {
  right: 10rem;
}

.right-44 {
  right: 11rem;
}

.right-48 {
  right: 12rem;
}

.right-5 {
  right: 1.25rem;
}

.right-52 {
  right: 13rem;
}

.right-56 {
  right: 14rem;
}

.right-6 {
  right: 1.5rem;
}

.right-60 {
  right: 15rem;
}

.right-64 {
  right: 16rem;
}

.right-7 {
  right: 1.75rem;
}

.right-72 {
  right: 18rem;
}

.right-8 {
  right: 2rem;
}

.right-80 {
  right: 20rem;
}

.right-9 {
  right: 2.25rem;
}

.right-96 {
  right: 24rem;
}

.right-auto {
  right: auto;
}

.right-full {
  right: 100%;
}

.right-px {
  right: 1px;
}

.start-0 {
  inset-inline-start: 0;
}

.start-0\.5 {
  inset-inline-start: .125rem;
}

.start-1 {
  inset-inline-start: .25rem;
}

.start-1\.5 {
  inset-inline-start: .375rem;
}

.start-1\/2 {
  inset-inline-start: 50%;
}

.start-1\/3 {
  inset-inline-start: 33.3333%;
}

.start-1\/4 {
  inset-inline-start: 25%;
}

.start-10 {
  inset-inline-start: 2.5rem;
}

.start-11 {
  inset-inline-start: 2.75rem;
}

.start-12 {
  inset-inline-start: 3rem;
}

.start-14 {
  inset-inline-start: 3.5rem;
}

.start-16 {
  inset-inline-start: 4rem;
}

.start-2 {
  inset-inline-start: .5rem;
}

.start-2\.5 {
  inset-inline-start: .625rem;
}

.start-2\/3 {
  inset-inline-start: 66.6667%;
}

.start-2\/4 {
  inset-inline-start: 50%;
}

.start-20 {
  inset-inline-start: 5rem;
}

.start-24 {
  inset-inline-start: 6rem;
}

.start-28 {
  inset-inline-start: 7rem;
}

.start-3 {
  inset-inline-start: .75rem;
}

.start-3\.5 {
  inset-inline-start: .875rem;
}

.start-3\/4 {
  inset-inline-start: 75%;
}

.start-32 {
  inset-inline-start: 8rem;
}

.start-36 {
  inset-inline-start: 9rem;
}

.start-4 {
  inset-inline-start: 1rem;
}

.start-40 {
  inset-inline-start: 10rem;
}

.start-44 {
  inset-inline-start: 11rem;
}

.start-48 {
  inset-inline-start: 12rem;
}

.start-5 {
  inset-inline-start: 1.25rem;
}

.start-52 {
  inset-inline-start: 13rem;
}

.start-56 {
  inset-inline-start: 14rem;
}

.start-6 {
  inset-inline-start: 1.5rem;
}

.start-60 {
  inset-inline-start: 15rem;
}

.start-64 {
  inset-inline-start: 16rem;
}

.start-7 {
  inset-inline-start: 1.75rem;
}

.start-72 {
  inset-inline-start: 18rem;
}

.start-8 {
  inset-inline-start: 2rem;
}

.start-80 {
  inset-inline-start: 20rem;
}

.start-9 {
  inset-inline-start: 2.25rem;
}

.start-96 {
  inset-inline-start: 24rem;
}

.start-auto {
  inset-inline-start: auto;
}

.start-full {
  inset-inline-start: 100%;
}

.start-px {
  inset-inline-start: 1px;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-1\/2 {
  top: 50%;
}

.top-1\/3 {
  top: 33.3333%;
}

.top-1\/4 {
  top: 25%;
}

.top-10 {
  top: 2.5rem;
}

.top-11 {
  top: 2.75rem;
}

.top-12 {
  top: 3rem;
}

.top-14 {
  top: 3.5rem;
}

.top-16 {
  top: 4rem;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-2\/3 {
  top: 66.6667%;
}

.top-2\/4 {
  top: 50%;
}

.top-20 {
  top: 5rem;
}

.top-24 {
  top: 6rem;
}

.top-28 {
  top: 7rem;
}

.top-3 {
  top: .75rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-3\/4 {
  top: 75%;
}

.top-32 {
  top: 8rem;
}

.top-36 {
  top: 9rem;
}

.top-4 {
  top: 1rem;
}

.top-40 {
  top: 10rem;
}

.top-44 {
  top: 11rem;
}

.top-48 {
  top: 12rem;
}

.top-5 {
  top: 1.25rem;
}

.top-52 {
  top: 13rem;
}

.top-56 {
  top: 14rem;
}

.top-6 {
  top: 1.5rem;
}

.top-60 {
  top: 15rem;
}

.top-64 {
  top: 16rem;
}

.top-7 {
  top: 1.75rem;
}

.top-72 {
  top: 18rem;
}

.top-8 {
  top: 2rem;
}

.top-80 {
  top: 20rem;
}

.top-9 {
  top: 2.25rem;
}

.top-96 {
  top: 24rem;
}

.top-auto {
  top: auto;
}

.top-full {
  top: 100%;
}

.top-px {
  top: 1px;
}

.isolate {
  isolation: isolate;
}

.isolation-auto {
  isolation: auto;
}

.-z-0 {
  z-index: 0;
}

.-z-10 {
  z-index: -10;
}

.-z-20 {
  z-index: -20;
}

.-z-30 {
  z-index: -30;
}

.-z-40 {
  z-index: -40;
}

.-z-50 {
  z-index: -50;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-auto {
  z-index: auto;
}

.-order-1 {
  order: -1;
}

.-order-10 {
  order: -10;
}

.-order-11 {
  order: -11;
}

.-order-12 {
  order: -12;
}

.-order-2 {
  order: -2;
}

.-order-3 {
  order: -3;
}

.-order-4 {
  order: -4;
}

.-order-5 {
  order: -5;
}

.-order-6 {
  order: -6;
}

.-order-7 {
  order: -7;
}

.-order-8 {
  order: -8;
}

.-order-9 {
  order: -9;
}

.-order-first {
  order: 9999;
}

.-order-last {
  order: -9999;
}

.-order-none {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.order-none {
  order: 0;
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-full {
  grid-column: 1 / -1;
}

.-col-start-1 {
  grid-column-start: -1;
}

.-col-start-10 {
  grid-column-start: -10;
}

.-col-start-11 {
  grid-column-start: -11;
}

.-col-start-12 {
  grid-column-start: -12;
}

.-col-start-13 {
  grid-column-start: -13;
}

.-col-start-2 {
  grid-column-start: -2;
}

.-col-start-3 {
  grid-column-start: -3;
}

.-col-start-4 {
  grid-column-start: -4;
}

.-col-start-5 {
  grid-column-start: -5;
}

.-col-start-6 {
  grid-column-start: -6;
}

.-col-start-7 {
  grid-column-start: -7;
}

.-col-start-8 {
  grid-column-start: -8;
}

.-col-start-9 {
  grid-column-start: -9;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-auto {
  grid-column-start: auto;
}

.-col-end-1 {
  grid-column-end: -1;
}

.-col-end-10 {
  grid-column-end: -10;
}

.-col-end-11 {
  grid-column-end: -11;
}

.-col-end-12 {
  grid-column-end: -12;
}

.-col-end-13 {
  grid-column-end: -13;
}

.-col-end-2 {
  grid-column-end: -2;
}

.-col-end-3 {
  grid-column-end: -3;
}

.-col-end-4 {
  grid-column-end: -4;
}

.-col-end-5 {
  grid-column-end: -5;
}

.-col-end-6 {
  grid-column-end: -6;
}

.-col-end-7 {
  grid-column-end: -7;
}

.-col-end-8 {
  grid-column-end: -8;
}

.-col-end-9 {
  grid-column-end: -9;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-auto {
  grid-column-end: auto;
}

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-10 {
  grid-row: span 10 / span 10;
}

.row-span-11 {
  grid-row: span 11 / span 11;
}

.row-span-12 {
  grid-row: span 12 / span 12;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-7 {
  grid-row: span 7 / span 7;
}

.row-span-8 {
  grid-row: span 8 / span 8;
}

.row-span-9 {
  grid-row: span 9 / span 9;
}

.row-span-full {
  grid-row: 1 / -1;
}

.-row-start-1 {
  grid-row-start: -1;
}

.-row-start-10 {
  grid-row-start: -10;
}

.-row-start-11 {
  grid-row-start: -11;
}

.-row-start-12 {
  grid-row-start: -12;
}

.-row-start-13 {
  grid-row-start: -13;
}

.-row-start-2 {
  grid-row-start: -2;
}

.-row-start-3 {
  grid-row-start: -3;
}

.-row-start-4 {
  grid-row-start: -4;
}

.-row-start-5 {
  grid-row-start: -5;
}

.-row-start-6 {
  grid-row-start: -6;
}

.-row-start-7 {
  grid-row-start: -7;
}

.-row-start-8 {
  grid-row-start: -8;
}

.-row-start-9 {
  grid-row-start: -9;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-10 {
  grid-row-start: 10;
}

.row-start-11 {
  grid-row-start: 11;
}

.row-start-12 {
  grid-row-start: 12;
}

.row-start-13 {
  grid-row-start: 13;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-8 {
  grid-row-start: 8;
}

.row-start-9 {
  grid-row-start: 9;
}

.row-start-auto {
  grid-row-start: auto;
}

.-row-end-1 {
  grid-row-end: -1;
}

.-row-end-10 {
  grid-row-end: -10;
}

.-row-end-11 {
  grid-row-end: -11;
}

.-row-end-12 {
  grid-row-end: -12;
}

.-row-end-13 {
  grid-row-end: -13;
}

.-row-end-2 {
  grid-row-end: -2;
}

.-row-end-3 {
  grid-row-end: -3;
}

.-row-end-4 {
  grid-row-end: -4;
}

.-row-end-5 {
  grid-row-end: -5;
}

.-row-end-6 {
  grid-row-end: -6;
}

.-row-end-7 {
  grid-row-end: -7;
}

.-row-end-8 {
  grid-row-end: -8;
}

.-row-end-9 {
  grid-row-end: -9;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-10 {
  grid-row-end: 10;
}

.row-end-11 {
  grid-row-end: 11;
}

.row-end-12 {
  grid-row-end: 12;
}

.row-end-13 {
  grid-row-end: 13;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-8 {
  grid-row-end: 8;
}

.row-end-9 {
  grid-row-end: 9;
}

.row-end-auto {
  grid-row-end: auto;
}

.float-start {
  float: inline-start;
}

.float-end {
  float: inline-end;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.clear-start {
  clear: inline-start;
}

.clear-end {
  clear: inline-end;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}

.clear-none {
  clear: none;
}

.-m-0 {
  margin: 0;
}

.-m-0\.5 {
  margin: -.125rem;
}

.-m-1 {
  margin: -.25rem;
}

.-m-1\.5 {
  margin: -.375rem;
}

.-m-10 {
  margin: -2.5rem;
}

.-m-11 {
  margin: -2.75rem;
}

.-m-12 {
  margin: -3rem;
}

.-m-14 {
  margin: -3.5rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-2 {
  margin: -.5rem;
}

.-m-2\.5 {
  margin: -.625rem;
}

.-m-20 {
  margin: -5rem;
}

.-m-24 {
  margin: -6rem;
}

.-m-28 {
  margin: -7rem;
}

.-m-3 {
  margin: -.75rem;
}

.-m-3\.5 {
  margin: -.875rem;
}

.-m-32 {
  margin: -8rem;
}

.-m-36 {
  margin: -9rem;
}

.-m-4 {
  margin: -1rem;
}

.-m-40 {
  margin: -10rem;
}

.-m-44 {
  margin: -11rem;
}

.-m-48 {
  margin: -12rem;
}

.-m-5 {
  margin: -1.25rem;
}

.-m-52 {
  margin: -13rem;
}

.-m-56 {
  margin: -14rem;
}

.-m-6 {
  margin: -1.5rem;
}

.-m-60 {
  margin: -15rem;
}

.-m-64 {
  margin: -16rem;
}

.-m-7 {
  margin: -1.75rem;
}

.-m-72 {
  margin: -18rem;
}

.-m-8 {
  margin: -2rem;
}

.-m-80 {
  margin: -20rem;
}

.-m-9 {
  margin: -2.25rem;
}

.-m-96 {
  margin: -24rem;
}

.-m-px {
  margin: -1px;
}

.m-0 {
  margin: 0;
}

.m-0\.5 {
  margin: .125rem;
}

.m-1 {
  margin: .25rem;
}

.m-1\.5 {
  margin: .375rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-11 {
  margin: 2.75rem;
}

.m-12 {
  margin: 3rem;
}

.m-14 {
  margin: 3.5rem;
}

.m-16 {
  margin: 4rem;
}

.m-2 {
  margin: .5rem;
}

.m-2\.5 {
  margin: .625rem;
}

.m-20 {
  margin: 5rem;
}

.m-24 {
  margin: 6rem;
}

.m-28 {
  margin: 7rem;
}

.m-3 {
  margin: .75rem;
}

.m-3\.5 {
  margin: .875rem;
}

.m-32 {
  margin: 8rem;
}

.m-36 {
  margin: 9rem;
}

.m-4 {
  margin: 1rem;
}

.m-40 {
  margin: 10rem;
}

.m-44 {
  margin: 11rem;
}

.m-48 {
  margin: 12rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-52 {
  margin: 13rem;
}

.m-56 {
  margin: 14rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-60 {
  margin: 15rem;
}

.m-64 {
  margin: 16rem;
}

.m-7 {
  margin: 1.75rem;
}

.m-72 {
  margin: 18rem;
}

.m-8 {
  margin: 2rem;
}

.m-80 {
  margin: 20rem;
}

.m-9 {
  margin: 2.25rem;
}

.m-96 {
  margin: 24rem;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.-mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.-mx-0\.5 {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-1\.5 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-mx-11 {
  margin-left: -2.75rem;
  margin-right: -2.75rem;
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.-mx-14 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-2\.5 {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.-mx-28 {
  margin-left: -7rem;
  margin-right: -7rem;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.-mx-3\.5 {
  margin-left: -.875rem;
  margin-right: -.875rem;
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.-mx-36 {
  margin-left: -9rem;
  margin-right: -9rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.-mx-44 {
  margin-left: -11rem;
  margin-right: -11rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-mx-52 {
  margin-left: -13rem;
  margin-right: -13rem;
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-mx-60 {
  margin-left: -15rem;
  margin-right: -15rem;
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.-mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}

.-mx-72 {
  margin-left: -18rem;
  margin-right: -18rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-mx-80 {
  margin-left: -20rem;
  margin-right: -20rem;
}

.-mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}

.-mx-96 {
  margin-left: -24rem;
  margin-right: -24rem;
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.-my-0\.5 {
  margin-top: -.125rem;
  margin-bottom: -.125rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.-my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.-my-14 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.-my-2\.5 {
  margin-top: -.625rem;
  margin-bottom: -.625rem;
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.-my-28 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}

.-my-3 {
  margin-top: -.75rem;
  margin-bottom: -.75rem;
}

.-my-3\.5 {
  margin-top: -.875rem;
  margin-bottom: -.875rem;
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.-my-36 {
  margin-top: -9rem;
  margin-bottom: -9rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.-my-44 {
  margin-top: -11rem;
  margin-bottom: -11rem;
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.-my-52 {
  margin-top: -13rem;
  margin-bottom: -13rem;
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.-my-60 {
  margin-top: -15rem;
  margin-bottom: -15rem;
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.-my-7 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}

.-my-72 {
  margin-top: -18rem;
  margin-bottom: -18rem;
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.-my-80 {
  margin-top: -20rem;
  margin-bottom: -20rem;
}

.-my-9 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}

.-my-96 {
  margin-top: -24rem;
  margin-bottom: -24rem;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-0\.5 {
  margin-left: .125rem;
  margin-right: .125rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-1\.5 {
  margin-left: .375rem;
  margin-right: .375rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-2\.5 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-3\.5 {
  margin-left: .875rem;
  margin-right: .875rem;
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mx-36 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.mx-44 {
  margin-left: 11rem;
  margin-right: 11rem;
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-52 {
  margin-left: 13rem;
  margin-right: 13rem;
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-60 {
  margin-left: 15rem;
  margin-right: 15rem;
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.mx-72 {
  margin-left: 18rem;
  margin-right: 18rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-80 {
  margin-left: 20rem;
  margin-right: 20rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.mx-96 {
  margin-left: 24rem;
  margin-right: 24rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-0\.5 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-1\.5 {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-2\.5 {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-3\.5 {
  margin-top: .875rem;
  margin-bottom: .875rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.my-44 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-52 {
  margin-top: 13rem;
  margin-bottom: 13rem;
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-60 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-72 {
  margin-top: 18rem;
  margin-bottom: 18rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-80 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.my-96 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.-mb-0 {
  margin-bottom: 0;
}

.-mb-0\.5 {
  margin-bottom: -.125rem;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-mb-1\.5 {
  margin-bottom: -.375rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-mb-11 {
  margin-bottom: -2.75rem;
}

.-mb-12 {
  margin-bottom: -3rem;
}

.-mb-14 {
  margin-bottom: -3.5rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-mb-2\.5 {
  margin-bottom: -.625rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-mb-24 {
  margin-bottom: -6rem;
}

.-mb-28 {
  margin-bottom: -7rem;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.-mb-3\.5 {
  margin-bottom: -.875rem;
}

.-mb-32 {
  margin-bottom: -8rem;
}

.-mb-36 {
  margin-bottom: -9rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mb-40 {
  margin-bottom: -10rem;
}

.-mb-44 {
  margin-bottom: -11rem;
}

.-mb-48 {
  margin-bottom: -12rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-mb-52 {
  margin-bottom: -13rem;
}

.-mb-56 {
  margin-bottom: -14rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-mb-60 {
  margin-bottom: -15rem;
}

.-mb-64 {
  margin-bottom: -16rem;
}

.-mb-7 {
  margin-bottom: -1.75rem;
}

.-mb-72 {
  margin-bottom: -18rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-mb-80 {
  margin-bottom: -20rem;
}

.-mb-9 {
  margin-bottom: -2.25rem;
}

.-mb-96 {
  margin-bottom: -24rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-me-0 {
  margin-inline-end: 0;
}

.-me-0\.5 {
  margin-inline-end: -.125rem;
}

.-me-1 {
  margin-inline-end: -.25rem;
}

.-me-1\.5 {
  margin-inline-end: -.375rem;
}

.-me-10 {
  margin-inline-end: -2.5rem;
}

.-me-11 {
  margin-inline-end: -2.75rem;
}

.-me-12 {
  margin-inline-end: -3rem;
}

.-me-14 {
  margin-inline-end: -3.5rem;
}

.-me-16 {
  margin-inline-end: -4rem;
}

.-me-2 {
  margin-inline-end: -.5rem;
}

.-me-2\.5 {
  margin-inline-end: -.625rem;
}

.-me-20 {
  margin-inline-end: -5rem;
}

.-me-24 {
  margin-inline-end: -6rem;
}

.-me-28 {
  margin-inline-end: -7rem;
}

.-me-3 {
  margin-inline-end: -.75rem;
}

.-me-3\.5 {
  margin-inline-end: -.875rem;
}

.-me-32 {
  margin-inline-end: -8rem;
}

.-me-36 {
  margin-inline-end: -9rem;
}

.-me-4 {
  margin-inline-end: -1rem;
}

.-me-40 {
  margin-inline-end: -10rem;
}

.-me-44 {
  margin-inline-end: -11rem;
}

.-me-48 {
  margin-inline-end: -12rem;
}

.-me-5 {
  margin-inline-end: -1.25rem;
}

.-me-52 {
  margin-inline-end: -13rem;
}

.-me-56 {
  margin-inline-end: -14rem;
}

.-me-6 {
  margin-inline-end: -1.5rem;
}

.-me-60 {
  margin-inline-end: -15rem;
}

.-me-64 {
  margin-inline-end: -16rem;
}

.-me-7 {
  margin-inline-end: -1.75rem;
}

.-me-72 {
  margin-inline-end: -18rem;
}

.-me-8 {
  margin-inline-end: -2rem;
}

.-me-80 {
  margin-inline-end: -20rem;
}

.-me-9 {
  margin-inline-end: -2.25rem;
}

.-me-96 {
  margin-inline-end: -24rem;
}

.-me-px {
  margin-inline-end: -1px;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-1\.5 {
  margin-left: -.375rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.-ml-11 {
  margin-left: -2.75rem;
}

.-ml-12 {
  margin-left: -3rem;
}

.-ml-14 {
  margin-left: -3.5rem;
}

.-ml-16 {
  margin-left: -4rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-2\.5 {
  margin-left: -.625rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.-ml-24 {
  margin-left: -6rem;
}

.-ml-28 {
  margin-left: -7rem;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-ml-3\.5 {
  margin-left: -.875rem;
}

.-ml-32 {
  margin-left: -8rem;
}

.-ml-36 {
  margin-left: -9rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-ml-40 {
  margin-left: -10rem;
}

.-ml-44 {
  margin-left: -11rem;
}

.-ml-48 {
  margin-left: -12rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-ml-52 {
  margin-left: -13rem;
}

.-ml-56 {
  margin-left: -14rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-ml-60 {
  margin-left: -15rem;
}

.-ml-64 {
  margin-left: -16rem;
}

.-ml-7 {
  margin-left: -1.75rem;
}

.-ml-72 {
  margin-left: -18rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-ml-80 {
  margin-left: -20rem;
}

.-ml-9 {
  margin-left: -2.25rem;
}

.-ml-96 {
  margin-left: -24rem;
}

.-ml-px {
  margin-left: -1px;
}

.-mr-0 {
  margin-right: 0;
}

.-mr-0\.5 {
  margin-right: -.125rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mr-1\.5 {
  margin-right: -.375rem;
}

.-mr-10 {
  margin-right: -2.5rem;
}

.-mr-11 {
  margin-right: -2.75rem;
}

.-mr-12 {
  margin-right: -3rem;
}

.-mr-14 {
  margin-right: -3.5rem;
}

.-mr-16 {
  margin-right: -4rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mr-2\.5 {
  margin-right: -.625rem;
}

.-mr-20 {
  margin-right: -5rem;
}

.-mr-24 {
  margin-right: -6rem;
}

.-mr-28 {
  margin-right: -7rem;
}

.-mr-3 {
  margin-right: -.75rem;
}

.-mr-3\.5 {
  margin-right: -.875rem;
}

.-mr-32 {
  margin-right: -8rem;
}

.-mr-36 {
  margin-right: -9rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mr-40 {
  margin-right: -10rem;
}

.-mr-44 {
  margin-right: -11rem;
}

.-mr-48 {
  margin-right: -12rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.-mr-52 {
  margin-right: -13rem;
}

.-mr-56 {
  margin-right: -14rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mr-60 {
  margin-right: -15rem;
}

.-mr-64 {
  margin-right: -16rem;
}

.-mr-7 {
  margin-right: -1.75rem;
}

.-mr-72 {
  margin-right: -18rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.-mr-80 {
  margin-right: -20rem;
}

.-mr-9 {
  margin-right: -2.25rem;
}

.-mr-96 {
  margin-right: -24rem;
}

.-mr-px {
  margin-right: -1px;
}

.-ms-0 {
  margin-inline-start: 0;
}

.-ms-0\.5 {
  margin-inline-start: -.125rem;
}

.-ms-1 {
  margin-inline-start: -.25rem;
}

.-ms-1\.5 {
  margin-inline-start: -.375rem;
}

.-ms-10 {
  margin-inline-start: -2.5rem;
}

.-ms-11 {
  margin-inline-start: -2.75rem;
}

.-ms-12 {
  margin-inline-start: -3rem;
}

.-ms-14 {
  margin-inline-start: -3.5rem;
}

.-ms-16 {
  margin-inline-start: -4rem;
}

.-ms-2 {
  margin-inline-start: -.5rem;
}

.-ms-2\.5 {
  margin-inline-start: -.625rem;
}

.-ms-20 {
  margin-inline-start: -5rem;
}

.-ms-24 {
  margin-inline-start: -6rem;
}

.-ms-28 {
  margin-inline-start: -7rem;
}

.-ms-3 {
  margin-inline-start: -.75rem;
}

.-ms-3\.5 {
  margin-inline-start: -.875rem;
}

.-ms-32 {
  margin-inline-start: -8rem;
}

.-ms-36 {
  margin-inline-start: -9rem;
}

.-ms-4 {
  margin-inline-start: -1rem;
}

.-ms-40 {
  margin-inline-start: -10rem;
}

.-ms-44 {
  margin-inline-start: -11rem;
}

.-ms-48 {
  margin-inline-start: -12rem;
}

.-ms-5 {
  margin-inline-start: -1.25rem;
}

.-ms-52 {
  margin-inline-start: -13rem;
}

.-ms-56 {
  margin-inline-start: -14rem;
}

.-ms-6 {
  margin-inline-start: -1.5rem;
}

.-ms-60 {
  margin-inline-start: -15rem;
}

.-ms-64 {
  margin-inline-start: -16rem;
}

.-ms-7 {
  margin-inline-start: -1.75rem;
}

.-ms-72 {
  margin-inline-start: -18rem;
}

.-ms-8 {
  margin-inline-start: -2rem;
}

.-ms-80 {
  margin-inline-start: -20rem;
}

.-ms-9 {
  margin-inline-start: -2.25rem;
}

.-ms-96 {
  margin-inline-start: -24rem;
}

.-ms-px {
  margin-inline-start: -1px;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-1\.5 {
  margin-top: -.375rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-11 {
  margin-top: -2.75rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-2\.5 {
  margin-top: -.625rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-28 {
  margin-top: -7rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.-mt-3\.5 {
  margin-top: -.875rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-36 {
  margin-top: -9rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-40 {
  margin-top: -10rem;
}

.-mt-44 {
  margin-top: -11rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mt-52 {
  margin-top: -13rem;
}

.-mt-56 {
  margin-top: -14rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-60 {
  margin-top: -15rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.-mt-7 {
  margin-top: -1.75rem;
}

.-mt-72 {
  margin-top: -18rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-80 {
  margin-top: -20rem;
}

.-mt-9 {
  margin-top: -2.25rem;
}

.-mt-96 {
  margin-top: -24rem;
}

.-mt-px {
  margin-top: -1px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-3\.5 {
  margin-bottom: .875rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-36 {
  margin-bottom: 9rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mb-44 {
  margin-bottom: 11rem;
}

.mb-48 {
  margin-bottom: 12rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-52 {
  margin-bottom: 13rem;
}

.mb-56 {
  margin-bottom: 14rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-60 {
  margin-bottom: 15rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-72 {
  margin-bottom: 18rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-80 {
  margin-bottom: 20rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-96 {
  margin-bottom: 24rem;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-px {
  margin-bottom: 1px;
}

.me-0 {
  margin-inline-end: 0;
}

.me-0\.5 {
  margin-inline-end: .125rem;
}

.me-1 {
  margin-inline-end: .25rem;
}

.me-1\.5 {
  margin-inline-end: .375rem;
}

.me-10 {
  margin-inline-end: 2.5rem;
}

.me-11 {
  margin-inline-end: 2.75rem;
}

.me-12 {
  margin-inline-end: 3rem;
}

.me-14 {
  margin-inline-end: 3.5rem;
}

.me-16 {
  margin-inline-end: 4rem;
}

.me-2 {
  margin-inline-end: .5rem;
}

.me-2\.5 {
  margin-inline-end: .625rem;
}

.me-20 {
  margin-inline-end: 5rem;
}

.me-24 {
  margin-inline-end: 6rem;
}

.me-28 {
  margin-inline-end: 7rem;
}

.me-3 {
  margin-inline-end: .75rem;
}

.me-3\.5 {
  margin-inline-end: .875rem;
}

.me-32 {
  margin-inline-end: 8rem;
}

.me-36 {
  margin-inline-end: 9rem;
}

.me-4 {
  margin-inline-end: 1rem;
}

.me-40 {
  margin-inline-end: 10rem;
}

.me-44 {
  margin-inline-end: 11rem;
}

.me-48 {
  margin-inline-end: 12rem;
}

.me-5 {
  margin-inline-end: 1.25rem;
}

.me-52 {
  margin-inline-end: 13rem;
}

.me-56 {
  margin-inline-end: 14rem;
}

.me-6 {
  margin-inline-end: 1.5rem;
}

.me-60 {
  margin-inline-end: 15rem;
}

.me-64 {
  margin-inline-end: 16rem;
}

.me-7 {
  margin-inline-end: 1.75rem;
}

.me-72 {
  margin-inline-end: 18rem;
}

.me-8 {
  margin-inline-end: 2rem;
}

.me-80 {
  margin-inline-end: 20rem;
}

.me-9 {
  margin-inline-end: 2.25rem;
}

.me-96 {
  margin-inline-end: 24rem;
}

.me-auto {
  margin-inline-end: auto;
}

.me-px {
  margin-inline-end: 1px;
}

.ml-0 {
  margin-left: 0;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-11 {
  margin-left: 2.75rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-24 {
  margin-left: 6rem;
}

.ml-28 {
  margin-left: 7rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-3\.5 {
  margin-left: .875rem;
}

.ml-32 {
  margin-left: 8rem;
}

.ml-36 {
  margin-left: 9rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-40 {
  margin-left: 10rem;
}

.ml-44 {
  margin-left: 11rem;
}

.ml-48 {
  margin-left: 12rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-52 {
  margin-left: 13rem;
}

.ml-56 {
  margin-left: 14rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-60 {
  margin-left: 15rem;
}

.ml-64 {
  margin-left: 16rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-72 {
  margin-left: 18rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-80 {
  margin-left: 20rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.ml-96 {
  margin-left: 24rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-px {
  margin-left: 1px;
}

.mr-0 {
  margin-right: 0;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-11 {
  margin-right: 2.75rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mr-28 {
  margin-right: 7rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-3\.5 {
  margin-right: .875rem;
}

.mr-32 {
  margin-right: 8rem;
}

.mr-36 {
  margin-right: 9rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-40 {
  margin-right: 10rem;
}

.mr-44 {
  margin-right: 11rem;
}

.mr-48 {
  margin-right: 12rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-52 {
  margin-right: 13rem;
}

.mr-56 {
  margin-right: 14rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-60 {
  margin-right: 15rem;
}

.mr-64 {
  margin-right: 16rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mr-72 {
  margin-right: 18rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-80 {
  margin-right: 20rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mr-96 {
  margin-right: 24rem;
}

.mr-auto {
  margin-right: auto;
}

.mr-px {
  margin-right: 1px;
}

.ms-0 {
  margin-inline-start: 0;
}

.ms-0\.5 {
  margin-inline-start: .125rem;
}

.ms-1 {
  margin-inline-start: .25rem;
}

.ms-1\.5 {
  margin-inline-start: .375rem;
}

.ms-10 {
  margin-inline-start: 2.5rem;
}

.ms-11 {
  margin-inline-start: 2.75rem;
}

.ms-12 {
  margin-inline-start: 3rem;
}

.ms-14 {
  margin-inline-start: 3.5rem;
}

.ms-16 {
  margin-inline-start: 4rem;
}

.ms-2 {
  margin-inline-start: .5rem;
}

.ms-2\.5 {
  margin-inline-start: .625rem;
}

.ms-20 {
  margin-inline-start: 5rem;
}

.ms-24 {
  margin-inline-start: 6rem;
}

.ms-28 {
  margin-inline-start: 7rem;
}

.ms-3 {
  margin-inline-start: .75rem;
}

.ms-3\.5 {
  margin-inline-start: .875rem;
}

.ms-32 {
  margin-inline-start: 8rem;
}

.ms-36 {
  margin-inline-start: 9rem;
}

.ms-4 {
  margin-inline-start: 1rem;
}

.ms-40 {
  margin-inline-start: 10rem;
}

.ms-44 {
  margin-inline-start: 11rem;
}

.ms-48 {
  margin-inline-start: 12rem;
}

.ms-5 {
  margin-inline-start: 1.25rem;
}

.ms-52 {
  margin-inline-start: 13rem;
}

.ms-56 {
  margin-inline-start: 14rem;
}

.ms-6 {
  margin-inline-start: 1.5rem;
}

.ms-60 {
  margin-inline-start: 15rem;
}

.ms-64 {
  margin-inline-start: 16rem;
}

.ms-7 {
  margin-inline-start: 1.75rem;
}

.ms-72 {
  margin-inline-start: 18rem;
}

.ms-8 {
  margin-inline-start: 2rem;
}

.ms-80 {
  margin-inline-start: 20rem;
}

.ms-9 {
  margin-inline-start: 2.25rem;
}

.ms-96 {
  margin-inline-start: 24rem;
}

.ms-auto {
  margin-inline-start: auto;
}

.ms-px {
  margin-inline-start: 1px;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mt-44 {
  margin-top: 11rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-52 {
  margin-top: 13rem;
}

.mt-56 {
  margin-top: 14rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-60 {
  margin-top: 15rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-72 {
  margin-top: 18rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-80 {
  margin-top: 20rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-96 {
  margin-top: 24rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-px {
  margin-top: 1px;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-6 {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-none {
  -webkit-line-clamp: none;
  -webkit-box-orient: horizontal;
  display: block;
  overflow: visible;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.inline-table {
  display: inline-table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.aspect-auto {
  aspect-ratio: auto;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.size-0 {
  width: 0;
  height: 0;
}

.size-0\.5 {
  width: .125rem;
  height: .125rem;
}

.size-1 {
  width: .25rem;
  height: .25rem;
}

.size-1\.5 {
  width: .375rem;
  height: .375rem;
}

.size-1\/12 {
  width: 8.33333%;
  height: 8.33333%;
}

.size-1\/2 {
  width: 50%;
  height: 50%;
}

.size-1\/3 {
  width: 33.3333%;
  height: 33.3333%;
}

.size-1\/4 {
  width: 25%;
  height: 25%;
}

.size-1\/5 {
  width: 20%;
  height: 20%;
}

.size-1\/6 {
  width: 16.6667%;
  height: 16.6667%;
}

.size-10 {
  width: 2.5rem;
  height: 2.5rem;
}

.size-10\/12 {
  width: 83.3333%;
  height: 83.3333%;
}

.size-11 {
  width: 2.75rem;
  height: 2.75rem;
}

.size-11\/12 {
  width: 91.6667%;
  height: 91.6667%;
}

.size-12 {
  width: 3rem;
  height: 3rem;
}

.size-14 {
  width: 3.5rem;
  height: 3.5rem;
}

.size-16 {
  width: 4rem;
  height: 4rem;
}

.size-2 {
  width: .5rem;
  height: .5rem;
}

.size-2\.5 {
  width: .625rem;
  height: .625rem;
}

.size-2\/12 {
  width: 16.6667%;
  height: 16.6667%;
}

.size-2\/3 {
  width: 66.6667%;
  height: 66.6667%;
}

.size-2\/4 {
  width: 50%;
  height: 50%;
}

.size-2\/5 {
  width: 40%;
  height: 40%;
}

.size-2\/6 {
  width: 33.3333%;
  height: 33.3333%;
}

.size-20 {
  width: 5rem;
  height: 5rem;
}

.size-24 {
  width: 6rem;
  height: 6rem;
}

.size-28 {
  width: 7rem;
  height: 7rem;
}

.size-3 {
  width: .75rem;
  height: .75rem;
}

.size-3\.5 {
  width: .875rem;
  height: .875rem;
}

.size-3\/12 {
  width: 25%;
  height: 25%;
}

.size-3\/4 {
  width: 75%;
  height: 75%;
}

.size-3\/5 {
  width: 60%;
  height: 60%;
}

.size-3\/6 {
  width: 50%;
  height: 50%;
}

.size-32 {
  width: 8rem;
  height: 8rem;
}

.size-36 {
  width: 9rem;
  height: 9rem;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.size-4\/12 {
  width: 33.3333%;
  height: 33.3333%;
}

.size-4\/5 {
  width: 80%;
  height: 80%;
}

.size-4\/6 {
  width: 66.6667%;
  height: 66.6667%;
}

.size-40 {
  width: 10rem;
  height: 10rem;
}

.size-44 {
  width: 11rem;
  height: 11rem;
}

.size-48 {
  width: 12rem;
  height: 12rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-5\/12 {
  width: 41.6667%;
  height: 41.6667%;
}

.size-5\/6 {
  width: 83.3333%;
  height: 83.3333%;
}

.size-52 {
  width: 13rem;
  height: 13rem;
}

.size-56 {
  width: 14rem;
  height: 14rem;
}

.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.size-6\/12 {
  width: 50%;
  height: 50%;
}

.size-60 {
  width: 15rem;
  height: 15rem;
}

.size-64 {
  width: 16rem;
  height: 16rem;
}

.size-7 {
  width: 1.75rem;
  height: 1.75rem;
}

.size-7\/12 {
  width: 58.3333%;
  height: 58.3333%;
}

.size-72 {
  width: 18rem;
  height: 18rem;
}

.size-8 {
  width: 2rem;
  height: 2rem;
}

.size-8\/12 {
  width: 66.6667%;
  height: 66.6667%;
}

.size-80 {
  width: 20rem;
  height: 20rem;
}

.size-9 {
  width: 2.25rem;
  height: 2.25rem;
}

.size-9\/12 {
  width: 75%;
  height: 75%;
}

.size-96 {
  width: 24rem;
  height: 24rem;
}

.size-auto {
  width: auto;
  height: auto;
}

.size-fit {
  width: fit-content;
  height: fit-content;
}

.size-full {
  width: 100%;
  height: 100%;
}

.size-max {
  width: max-content;
  height: max-content;
}

.size-min {
  width: min-content;
  height: min-content;
}

.size-px {
  width: 1px;
  height: 1px;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-1\/4 {
  height: 25%;
}

.h-1\/5 {
  height: 20%;
}

.h-1\/6 {
  height: 16.6667%;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-2\/4 {
  height: 50%;
}

.h-2\/5 {
  height: 40%;
}

.h-2\/6 {
  height: 33.3333%;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-3\/4 {
  height: 75%;
}

.h-3\/5 {
  height: 60%;
}

.h-3\/6 {
  height: 50%;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-4\/5 {
  height: 80%;
}

.h-4\/6 {
  height: 66.6667%;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.h-dvh {
  height: 100dvh;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-lvh {
  height: 100lvh;
}

.h-max {
  height: max-content;
}

.h-min {
  height: min-content;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.h-svh {
  height: 100svh;
}

.max-h-0 {
  max-height: 0;
}

.max-h-0\.5 {
  max-height: .125rem;
}

.max-h-1 {
  max-height: .25rem;
}

.max-h-1\.5 {
  max-height: .375rem;
}

.max-h-10 {
  max-height: 2.5rem;
}

.max-h-11 {
  max-height: 2.75rem;
}

.max-h-12 {
  max-height: 3rem;
}

.max-h-14 {
  max-height: 3.5rem;
}

.max-h-16 {
  max-height: 4rem;
}

.max-h-2 {
  max-height: .5rem;
}

.max-h-2\.5 {
  max-height: .625rem;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-28 {
  max-height: 7rem;
}

.max-h-3 {
  max-height: .75rem;
}

.max-h-3\.5 {
  max-height: .875rem;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-36 {
  max-height: 9rem;
}

.max-h-4 {
  max-height: 1rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-44 {
  max-height: 11rem;
}

.max-h-48 {
  max-height: 12rem;
}

.max-h-5 {
  max-height: 1.25rem;
}

.max-h-52 {
  max-height: 13rem;
}

.max-h-56 {
  max-height: 14rem;
}

.max-h-6 {
  max-height: 1.5rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-7 {
  max-height: 1.75rem;
}

.max-h-72 {
  max-height: 18rem;
}

.max-h-8 {
  max-height: 2rem;
}

.max-h-80 {
  max-height: 20rem;
}

.max-h-9 {
  max-height: 2.25rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-dvh {
  max-height: 100dvh;
}

.max-h-fit {
  max-height: fit-content;
}

.max-h-full {
  max-height: 100%;
}

.max-h-lvh {
  max-height: 100lvh;
}

.max-h-max {
  max-height: max-content;
}

.max-h-min {
  max-height: min-content;
}

.max-h-none {
  max-height: none;
}

.max-h-px {
  max-height: 1px;
}

.max-h-screen {
  max-height: 100vh;
}

.max-h-svh {
  max-height: 100svh;
}

.min-h-0 {
  min-height: 0;
}

.min-h-0\.5 {
  min-height: .125rem;
}

.min-h-1 {
  min-height: .25rem;
}

.min-h-1\.5 {
  min-height: .375rem;
}

.min-h-10 {
  min-height: 2.5rem;
}

.min-h-11 {
  min-height: 2.75rem;
}

.min-h-12 {
  min-height: 3rem;
}

.min-h-14 {
  min-height: 3.5rem;
}

.min-h-16 {
  min-height: 4rem;
}

.min-h-2 {
  min-height: .5rem;
}

.min-h-2\.5 {
  min-height: .625rem;
}

.min-h-20 {
  min-height: 5rem;
}

.min-h-24 {
  min-height: 6rem;
}

.min-h-28 {
  min-height: 7rem;
}

.min-h-3 {
  min-height: .75rem;
}

.min-h-3\.5 {
  min-height: .875rem;
}

.min-h-32 {
  min-height: 8rem;
}

.min-h-36 {
  min-height: 9rem;
}

.min-h-4 {
  min-height: 1rem;
}

.min-h-40 {
  min-height: 10rem;
}

.min-h-44 {
  min-height: 11rem;
}

.min-h-48 {
  min-height: 12rem;
}

.min-h-5 {
  min-height: 1.25rem;
}

.min-h-52 {
  min-height: 13rem;
}

.min-h-56 {
  min-height: 14rem;
}

.min-h-6 {
  min-height: 1.5rem;
}

.min-h-60 {
  min-height: 15rem;
}

.min-h-64 {
  min-height: 16rem;
}

.min-h-7 {
  min-height: 1.75rem;
}

.min-h-72 {
  min-height: 18rem;
}

.min-h-8 {
  min-height: 2rem;
}

.min-h-80 {
  min-height: 20rem;
}

.min-h-9 {
  min-height: 2.25rem;
}

.min-h-96 {
  min-height: 24rem;
}

.min-h-dvh {
  min-height: 100dvh;
}

.min-h-fit {
  min-height: fit-content;
}

.min-h-full {
  min-height: 100%;
}

.min-h-lvh {
  min-height: 100lvh;
}

.min-h-max {
  min-height: max-content;
}

.min-h-min {
  min-height: min-content;
}

.min-h-px {
  min-height: 1px;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-svh {
  min-height: 100svh;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11 {
  width: 2.75rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/4 {
  width: 50%;
}

.w-2\/5 {
  width: 40%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/12 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-3\/6 {
  width: 50%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-4\/5 {
  width: 80%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-6\/12 {
  width: 50%;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-9\/12 {
  width: 75%;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-dvw {
  width: 100dvw;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-lvw {
  width: 100lvw;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.w-svw {
  width: 100svw;
}

.min-w-0 {
  min-width: 0;
}

.min-w-0\.5 {
  min-width: .125rem;
}

.min-w-1 {
  min-width: .25rem;
}

.min-w-1\.5 {
  min-width: .375rem;
}

.min-w-10 {
  min-width: 2.5rem;
}

.min-w-11 {
  min-width: 2.75rem;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-14 {
  min-width: 3.5rem;
}

.min-w-16 {
  min-width: 4rem;
}

.min-w-2 {
  min-width: .5rem;
}

.min-w-2\.5 {
  min-width: .625rem;
}

.min-w-20 {
  min-width: 5rem;
}

.min-w-24 {
  min-width: 6rem;
}

.min-w-28 {
  min-width: 7rem;
}

.min-w-3 {
  min-width: .75rem;
}

.min-w-3\.5 {
  min-width: .875rem;
}

.min-w-32 {
  min-width: 8rem;
}

.min-w-36 {
  min-width: 9rem;
}

.min-w-4 {
  min-width: 1rem;
}

.min-w-40 {
  min-width: 10rem;
}

.min-w-44 {
  min-width: 11rem;
}

.min-w-48 {
  min-width: 12rem;
}

.min-w-5 {
  min-width: 1.25rem;
}

.min-w-52 {
  min-width: 13rem;
}

.min-w-56 {
  min-width: 14rem;
}

.min-w-6 {
  min-width: 1.5rem;
}

.min-w-60 {
  min-width: 15rem;
}

.min-w-64 {
  min-width: 16rem;
}

.min-w-7 {
  min-width: 1.75rem;
}

.min-w-72 {
  min-width: 18rem;
}

.min-w-8 {
  min-width: 2rem;
}

.min-w-80 {
  min-width: 20rem;
}

.min-w-9 {
  min-width: 2.25rem;
}

.min-w-96 {
  min-width: 24rem;
}

.min-w-fit {
  min-width: fit-content;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.min-w-min {
  min-width: min-content;
}

.min-w-px {
  min-width: 1px;
}

.max-w-0 {
  max-width: 0;
}

.max-w-0\.5 {
  max-width: .125rem;
}

.max-w-1 {
  max-width: .25rem;
}

.max-w-1\.5 {
  max-width: .375rem;
}

.max-w-10 {
  max-width: 2.5rem;
}

.max-w-11 {
  max-width: 2.75rem;
}

.max-w-12 {
  max-width: 3rem;
}

.max-w-14 {
  max-width: 3.5rem;
}

.max-w-16 {
  max-width: 4rem;
}

.max-w-2 {
  max-width: .5rem;
}

.max-w-2\.5 {
  max-width: .625rem;
}

.max-w-20 {
  max-width: 5rem;
}

.max-w-24 {
  max-width: 6rem;
}

.max-w-28 {
  max-width: 7rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3 {
  max-width: .75rem;
}

.max-w-3\.5 {
  max-width: .875rem;
}

.max-w-32 {
  max-width: 8rem;
}

.max-w-36 {
  max-width: 9rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4 {
  max-width: 1rem;
}

.max-w-40 {
  max-width: 10rem;
}

.max-w-44 {
  max-width: 11rem;
}

.max-w-48 {
  max-width: 12rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5 {
  max-width: 1.25rem;
}

.max-w-52 {
  max-width: 13rem;
}

.max-w-56 {
  max-width: 14rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6 {
  max-width: 1.5rem;
}

.max-w-60 {
  max-width: 15rem;
}

.max-w-64 {
  max-width: 16rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7 {
  max-width: 1.75rem;
}

.max-w-72 {
  max-width: 18rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-8 {
  max-width: 2rem;
}

.max-w-80 {
  max-width: 20rem;
}

.max-w-9 {
  max-width: 2.25rem;
}

.max-w-96 {
  max-width: 24rem;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-max {
  max-width: max-content;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-min {
  max-width: min-content;
}

.max-w-none {
  max-width: none;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-px {
  max-width: 1px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-0 {
  flex-basis: 0;
}

.basis-0\.5 {
  flex-basis: .125rem;
}

.basis-1 {
  flex-basis: .25rem;
}

.basis-1\.5 {
  flex-basis: .375rem;
}

.basis-1\/12 {
  flex-basis: 8.33333%;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.basis-1\/5 {
  flex-basis: 20%;
}

.basis-1\/6 {
  flex-basis: 16.6667%;
}

.basis-10 {
  flex-basis: 2.5rem;
}

.basis-10\/12 {
  flex-basis: 83.3333%;
}

.basis-11 {
  flex-basis: 2.75rem;
}

.basis-11\/12 {
  flex-basis: 91.6667%;
}

.basis-12 {
  flex-basis: 3rem;
}

.basis-14 {
  flex-basis: 3.5rem;
}

.basis-16 {
  flex-basis: 4rem;
}

.basis-2 {
  flex-basis: .5rem;
}

.basis-2\.5 {
  flex-basis: .625rem;
}

.basis-2\/12 {
  flex-basis: 16.6667%;
}

.basis-2\/3 {
  flex-basis: 66.6667%;
}

.basis-2\/4 {
  flex-basis: 50%;
}

.basis-2\/5 {
  flex-basis: 40%;
}

.basis-2\/6 {
  flex-basis: 33.3333%;
}

.basis-20 {
  flex-basis: 5rem;
}

.basis-24 {
  flex-basis: 6rem;
}

.basis-28 {
  flex-basis: 7rem;
}

.basis-3 {
  flex-basis: .75rem;
}

.basis-3\.5 {
  flex-basis: .875rem;
}

.basis-3\/12 {
  flex-basis: 25%;
}

.basis-3\/4 {
  flex-basis: 75%;
}

.basis-3\/5 {
  flex-basis: 60%;
}

.basis-3\/6 {
  flex-basis: 50%;
}

.basis-32 {
  flex-basis: 8rem;
}

.basis-36 {
  flex-basis: 9rem;
}

.basis-4 {
  flex-basis: 1rem;
}

.basis-4\/12 {
  flex-basis: 33.3333%;
}

.basis-4\/5 {
  flex-basis: 80%;
}

.basis-4\/6 {
  flex-basis: 66.6667%;
}

.basis-40 {
  flex-basis: 10rem;
}

.basis-44 {
  flex-basis: 11rem;
}

.basis-48 {
  flex-basis: 12rem;
}

.basis-5 {
  flex-basis: 1.25rem;
}

.basis-5\/12 {
  flex-basis: 41.6667%;
}

.basis-5\/6 {
  flex-basis: 83.3333%;
}

.basis-52 {
  flex-basis: 13rem;
}

.basis-56 {
  flex-basis: 14rem;
}

.basis-6 {
  flex-basis: 1.5rem;
}

.basis-6\/12 {
  flex-basis: 50%;
}

.basis-60 {
  flex-basis: 15rem;
}

.basis-64 {
  flex-basis: 16rem;
}

.basis-7 {
  flex-basis: 1.75rem;
}

.basis-7\/12 {
  flex-basis: 58.3333%;
}

.basis-72 {
  flex-basis: 18rem;
}

.basis-8 {
  flex-basis: 2rem;
}

.basis-8\/12 {
  flex-basis: 66.6667%;
}

.basis-80 {
  flex-basis: 20rem;
}

.basis-9 {
  flex-basis: 2.25rem;
}

.basis-9\/12 {
  flex-basis: 75%;
}

.basis-96 {
  flex-basis: 24rem;
}

.basis-auto {
  flex-basis: auto;
}

.basis-full {
  flex-basis: 100%;
}

.basis-px {
  flex-basis: 1px;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.caption-top {
  caption-side: top;
}

.caption-bottom {
  caption-side: bottom;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-0 {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-0\.5 {
  --tw-border-spacing-x: .125rem;
  --tw-border-spacing-y: .125rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-1 {
  --tw-border-spacing-x: .25rem;
  --tw-border-spacing-y: .25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-1\.5 {
  --tw-border-spacing-x: .375rem;
  --tw-border-spacing-y: .375rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-10 {
  --tw-border-spacing-x: 2.5rem;
  --tw-border-spacing-y: 2.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-11 {
  --tw-border-spacing-x: 2.75rem;
  --tw-border-spacing-y: 2.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-12 {
  --tw-border-spacing-x: 3rem;
  --tw-border-spacing-y: 3rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-14 {
  --tw-border-spacing-x: 3.5rem;
  --tw-border-spacing-y: 3.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-16 {
  --tw-border-spacing-x: 4rem;
  --tw-border-spacing-y: 4rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-2 {
  --tw-border-spacing-x: .5rem;
  --tw-border-spacing-y: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-2\.5 {
  --tw-border-spacing-x: .625rem;
  --tw-border-spacing-y: .625rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-20 {
  --tw-border-spacing-x: 5rem;
  --tw-border-spacing-y: 5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-24 {
  --tw-border-spacing-x: 6rem;
  --tw-border-spacing-y: 6rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-28 {
  --tw-border-spacing-x: 7rem;
  --tw-border-spacing-y: 7rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-3 {
  --tw-border-spacing-x: .75rem;
  --tw-border-spacing-y: .75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-3\.5 {
  --tw-border-spacing-x: .875rem;
  --tw-border-spacing-y: .875rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-32 {
  --tw-border-spacing-x: 8rem;
  --tw-border-spacing-y: 8rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-36 {
  --tw-border-spacing-x: 9rem;
  --tw-border-spacing-y: 9rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-4 {
  --tw-border-spacing-x: 1rem;
  --tw-border-spacing-y: 1rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-40 {
  --tw-border-spacing-x: 10rem;
  --tw-border-spacing-y: 10rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-44 {
  --tw-border-spacing-x: 11rem;
  --tw-border-spacing-y: 11rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-48 {
  --tw-border-spacing-x: 12rem;
  --tw-border-spacing-y: 12rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-5 {
  --tw-border-spacing-x: 1.25rem;
  --tw-border-spacing-y: 1.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-52 {
  --tw-border-spacing-x: 13rem;
  --tw-border-spacing-y: 13rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-56 {
  --tw-border-spacing-x: 14rem;
  --tw-border-spacing-y: 14rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-6 {
  --tw-border-spacing-x: 1.5rem;
  --tw-border-spacing-y: 1.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-60 {
  --tw-border-spacing-x: 15rem;
  --tw-border-spacing-y: 15rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-64 {
  --tw-border-spacing-x: 16rem;
  --tw-border-spacing-y: 16rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-7 {
  --tw-border-spacing-x: 1.75rem;
  --tw-border-spacing-y: 1.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-72 {
  --tw-border-spacing-x: 18rem;
  --tw-border-spacing-y: 18rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-8 {
  --tw-border-spacing-x: 2rem;
  --tw-border-spacing-y: 2rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-80 {
  --tw-border-spacing-x: 20rem;
  --tw-border-spacing-y: 20rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-9 {
  --tw-border-spacing-x: 2.25rem;
  --tw-border-spacing-y: 2.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-96 {
  --tw-border-spacing-x: 24rem;
  --tw-border-spacing-y: 24rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-px {
  --tw-border-spacing-x: 1px;
  --tw-border-spacing-y: 1px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-0 {
  --tw-border-spacing-x: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-0\.5 {
  --tw-border-spacing-x: .125rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-1 {
  --tw-border-spacing-x: .25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-1\.5 {
  --tw-border-spacing-x: .375rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-10 {
  --tw-border-spacing-x: 2.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-11 {
  --tw-border-spacing-x: 2.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-12 {
  --tw-border-spacing-x: 3rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-14 {
  --tw-border-spacing-x: 3.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-16 {
  --tw-border-spacing-x: 4rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-2 {
  --tw-border-spacing-x: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-2\.5 {
  --tw-border-spacing-x: .625rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-20 {
  --tw-border-spacing-x: 5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-24 {
  --tw-border-spacing-x: 6rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-28 {
  --tw-border-spacing-x: 7rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-3 {
  --tw-border-spacing-x: .75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-3\.5 {
  --tw-border-spacing-x: .875rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-32 {
  --tw-border-spacing-x: 8rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-36 {
  --tw-border-spacing-x: 9rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-4 {
  --tw-border-spacing-x: 1rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-40 {
  --tw-border-spacing-x: 10rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-44 {
  --tw-border-spacing-x: 11rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-48 {
  --tw-border-spacing-x: 12rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-5 {
  --tw-border-spacing-x: 1.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-52 {
  --tw-border-spacing-x: 13rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-56 {
  --tw-border-spacing-x: 14rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-6 {
  --tw-border-spacing-x: 1.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-60 {
  --tw-border-spacing-x: 15rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-64 {
  --tw-border-spacing-x: 16rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-7 {
  --tw-border-spacing-x: 1.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-72 {
  --tw-border-spacing-x: 18rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-8 {
  --tw-border-spacing-x: 2rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-80 {
  --tw-border-spacing-x: 20rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-9 {
  --tw-border-spacing-x: 2.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-96 {
  --tw-border-spacing-x: 24rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-px {
  --tw-border-spacing-x: 1px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-0 {
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-0\.5 {
  --tw-border-spacing-y: .125rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-1 {
  --tw-border-spacing-y: .25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-1\.5 {
  --tw-border-spacing-y: .375rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-10 {
  --tw-border-spacing-y: 2.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-11 {
  --tw-border-spacing-y: 2.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-12 {
  --tw-border-spacing-y: 3rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-14 {
  --tw-border-spacing-y: 3.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-16 {
  --tw-border-spacing-y: 4rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-2 {
  --tw-border-spacing-y: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-2\.5 {
  --tw-border-spacing-y: .625rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-20 {
  --tw-border-spacing-y: 5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-24 {
  --tw-border-spacing-y: 6rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-28 {
  --tw-border-spacing-y: 7rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-3 {
  --tw-border-spacing-y: .75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-3\.5 {
  --tw-border-spacing-y: .875rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-32 {
  --tw-border-spacing-y: 8rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-36 {
  --tw-border-spacing-y: 9rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-4 {
  --tw-border-spacing-y: 1rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-40 {
  --tw-border-spacing-y: 10rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-44 {
  --tw-border-spacing-y: 11rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-48 {
  --tw-border-spacing-y: 12rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-5 {
  --tw-border-spacing-y: 1.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-52 {
  --tw-border-spacing-y: 13rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-56 {
  --tw-border-spacing-y: 14rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-6 {
  --tw-border-spacing-y: 1.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-60 {
  --tw-border-spacing-y: 15rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-64 {
  --tw-border-spacing-y: 16rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-7 {
  --tw-border-spacing-y: 1.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-72 {
  --tw-border-spacing-y: 18rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-8 {
  --tw-border-spacing-y: 2rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-80 {
  --tw-border-spacing-y: 20rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-9 {
  --tw-border-spacing-y: 2.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-96 {
  --tw-border-spacing-y: 24rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-px {
  --tw-border-spacing-y: 1px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-bottom-left {
  transform-origin: 0 100%;
}

.origin-bottom-right {
  transform-origin: 100% 100%;
}

.origin-center {
  transform-origin: center;
}

.origin-left {
  transform-origin: 0;
}

.origin-right {
  transform-origin: 100%;
}

.origin-top {
  transform-origin: top;
}

.origin-top-left {
  transform-origin: 0 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-0 {
  --tw-translate-x: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-0\.5 {
  --tw-translate-x: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1 {
  --tw-translate-x: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\.5 {
  --tw-translate-x: -.375rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/3 {
  --tw-translate-x: -33.3333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/4 {
  --tw-translate-x: -25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-10 {
  --tw-translate-x: -2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-11 {
  --tw-translate-x: -2.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-12 {
  --tw-translate-x: -3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-14 {
  --tw-translate-x: -3.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-16 {
  --tw-translate-x: -4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-2 {
  --tw-translate-x: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-2\.5 {
  --tw-translate-x: -.625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-2\/3 {
  --tw-translate-x: -66.6667%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-2\/4 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-20 {
  --tw-translate-x: -5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-24 {
  --tw-translate-x: -6rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-28 {
  --tw-translate-x: -7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-3 {
  --tw-translate-x: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-3\.5 {
  --tw-translate-x: -.875rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-3\/4 {
  --tw-translate-x: -75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-32 {
  --tw-translate-x: -8rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-36 {
  --tw-translate-x: -9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-4 {
  --tw-translate-x: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-40 {
  --tw-translate-x: -10rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-44 {
  --tw-translate-x: -11rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-48 {
  --tw-translate-x: -12rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-5 {
  --tw-translate-x: -1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-52 {
  --tw-translate-x: -13rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-56 {
  --tw-translate-x: -14rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-6 {
  --tw-translate-x: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-60 {
  --tw-translate-x: -15rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-64 {
  --tw-translate-x: -16rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-7 {
  --tw-translate-x: -1.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-72 {
  --tw-translate-x: -18rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-8 {
  --tw-translate-x: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-80 {
  --tw-translate-x: -20rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-9 {
  --tw-translate-x: -2.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-96 {
  --tw-translate-x: -24rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-px {
  --tw-translate-x: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-0 {
  --tw-translate-y: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-0\.5 {
  --tw-translate-y: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1 {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\.5 {
  --tw-translate-y: -.375rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/3 {
  --tw-translate-y: -33.3333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/4 {
  --tw-translate-y: -25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-10 {
  --tw-translate-y: -2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-11 {
  --tw-translate-y: -2.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-12 {
  --tw-translate-y: -3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-14 {
  --tw-translate-y: -3.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-16 {
  --tw-translate-y: -4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2 {
  --tw-translate-y: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2\.5 {
  --tw-translate-y: -.625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2\/3 {
  --tw-translate-y: -66.6667%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2\/4 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-20 {
  --tw-translate-y: -5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-24 {
  --tw-translate-y: -6rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-28 {
  --tw-translate-y: -7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-3 {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-3\.5 {
  --tw-translate-y: -.875rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-3\/4 {
  --tw-translate-y: -75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-32 {
  --tw-translate-y: -8rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-36 {
  --tw-translate-y: -9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-4 {
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-40 {
  --tw-translate-y: -10rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-44 {
  --tw-translate-y: -11rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-48 {
  --tw-translate-y: -12rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-5 {
  --tw-translate-y: -1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-52 {
  --tw-translate-y: -13rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-56 {
  --tw-translate-y: -14rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-6 {
  --tw-translate-y: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-60 {
  --tw-translate-y: -15rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-64 {
  --tw-translate-y: -16rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-7 {
  --tw-translate-y: -1.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-72 {
  --tw-translate-y: -18rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-8 {
  --tw-translate-y: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-80 {
  --tw-translate-y: -20rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-9 {
  --tw-translate-y: -2.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-96 {
  --tw-translate-y: -24rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-px {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0\.5 {
  --tw-translate-x: .125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1 {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\.5 {
  --tw-translate-x: .375rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/3 {
  --tw-translate-x: 33.3333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/4 {
  --tw-translate-x: 25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-10 {
  --tw-translate-x: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-11 {
  --tw-translate-x: 2.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-12 {
  --tw-translate-x: 3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-14 {
  --tw-translate-x: 3.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-16 {
  --tw-translate-x: 4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-2 {
  --tw-translate-x: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-2\.5 {
  --tw-translate-x: .625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-2\/3 {
  --tw-translate-x: 66.6667%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-2\/4 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-20 {
  --tw-translate-x: 5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-24 {
  --tw-translate-x: 6rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-28 {
  --tw-translate-x: 7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-3 {
  --tw-translate-x: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-3\.5 {
  --tw-translate-x: .875rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-3\/4 {
  --tw-translate-x: 75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-32 {
  --tw-translate-x: 8rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-36 {
  --tw-translate-x: 9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-4 {
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-40 {
  --tw-translate-x: 10rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-44 {
  --tw-translate-x: 11rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-48 {
  --tw-translate-x: 12rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-52 {
  --tw-translate-x: 13rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-56 {
  --tw-translate-x: 14rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-60 {
  --tw-translate-x: 15rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-64 {
  --tw-translate-x: 16rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-7 {
  --tw-translate-x: 1.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-72 {
  --tw-translate-x: 18rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-8 {
  --tw-translate-x: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-80 {
  --tw-translate-x: 20rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-9 {
  --tw-translate-x: 2.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-96 {
  --tw-translate-x: 24rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-px {
  --tw-translate-x: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0\.5 {
  --tw-translate-y: .125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\.5 {
  --tw-translate-y: .375rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/3 {
  --tw-translate-y: 33.3333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/4 {
  --tw-translate-y: 25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-10 {
  --tw-translate-y: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-11 {
  --tw-translate-y: 2.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-12 {
  --tw-translate-y: 3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-14 {
  --tw-translate-y: 3.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-16 {
  --tw-translate-y: 4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2\.5 {
  --tw-translate-y: .625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2\/3 {
  --tw-translate-y: 66.6667%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2\/4 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-20 {
  --tw-translate-y: 5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-24 {
  --tw-translate-y: 6rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-28 {
  --tw-translate-y: 7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-3 {
  --tw-translate-y: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-3\.5 {
  --tw-translate-y: .875rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-3\/4 {
  --tw-translate-y: 75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-32 {
  --tw-translate-y: 8rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-36 {
  --tw-translate-y: 9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-40 {
  --tw-translate-y: 10rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-44 {
  --tw-translate-y: 11rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-48 {
  --tw-translate-y: 12rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-5 {
  --tw-translate-y: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-52 {
  --tw-translate-y: 13rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-56 {
  --tw-translate-y: 14rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-6 {
  --tw-translate-y: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-60 {
  --tw-translate-y: 15rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-64 {
  --tw-translate-y: 16rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-7 {
  --tw-translate-y: 1.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-72 {
  --tw-translate-y: 18rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-8 {
  --tw-translate-y: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-80 {
  --tw-translate-y: 20rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-9 {
  --tw-translate-y: 2.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-96 {
  --tw-translate-y: 24rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-px {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-0 {
  --tw-rotate: -0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-1 {
  --tw-rotate: -1deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-12 {
  --tw-rotate: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-180 {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-2 {
  --tw-rotate: -2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-3 {
  --tw-rotate: -3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-6 {
  --tw-rotate: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-1 {
  --tw-rotate: 1deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-12 {
  --tw-rotate: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-2 {
  --tw-rotate: 2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-3 {
  --tw-rotate: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-6 {
  --tw-rotate: 6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-0 {
  --tw-skew-x: -0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-1 {
  --tw-skew-x: -1deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-12 {
  --tw-skew-x: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-2 {
  --tw-skew-x: -2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-3 {
  --tw-skew-x: -3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-6 {
  --tw-skew-x: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-y-0 {
  --tw-skew-y: -0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-y-1 {
  --tw-skew-y: -1deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-y-12 {
  --tw-skew-y: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-y-2 {
  --tw-skew-y: -2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-y-3 {
  --tw-skew-y: -3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-y-6 {
  --tw-skew-y: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-0 {
  --tw-skew-x: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-1 {
  --tw-skew-x: 1deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-12 {
  --tw-skew-x: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-2 {
  --tw-skew-x: 2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-3 {
  --tw-skew-x: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-6 {
  --tw-skew-x: 6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-y-0 {
  --tw-skew-y: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-y-1 {
  --tw-skew-y: 1deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-y-12 {
  --tw-skew-y: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-y-2 {
  --tw-skew-y: 2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-y-3 {
  --tw-skew-y: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-y-6 {
  --tw-skew-y: 6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-100 {
  --tw-scale-x: -1;
  --tw-scale-y: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-105 {
  --tw-scale-x: -1.05;
  --tw-scale-y: -1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-110 {
  --tw-scale-x: -1.1;
  --tw-scale-y: -1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-125 {
  --tw-scale-x: -1.25;
  --tw-scale-y: -1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-150 {
  --tw-scale-x: -1.5;
  --tw-scale-y: -1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-50 {
  --tw-scale-x: -.5;
  --tw-scale-y: -.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-75 {
  --tw-scale-x: -.75;
  --tw-scale-y: -.75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-90 {
  --tw-scale-x: -.9;
  --tw-scale-y: -.9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-95 {
  --tw-scale-x: -.95;
  --tw-scale-y: -.95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[2\.66\] {
  --tw-scale-x: 2.66;
  --tw-scale-y: 2.66;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-0 {
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-100 {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-105 {
  --tw-scale-x: -1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-110 {
  --tw-scale-x: -1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-125 {
  --tw-scale-x: -1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-150 {
  --tw-scale-x: -1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-50 {
  --tw-scale-x: -.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-75 {
  --tw-scale-x: -.75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-90 {
  --tw-scale-x: -.9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-95 {
  --tw-scale-x: -.95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-0 {
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-100 {
  --tw-scale-y: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-105 {
  --tw-scale-y: -1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-110 {
  --tw-scale-y: -1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-125 {
  --tw-scale-y: -1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-150 {
  --tw-scale-y: -1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-50 {
  --tw-scale-y: -.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-75 {
  --tw-scale-y: -.75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-90 {
  --tw-scale-y: -.9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-95 {
  --tw-scale-y: -.95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-0 {
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-100 {
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-105 {
  --tw-scale-x: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-110 {
  --tw-scale-x: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-125 {
  --tw-scale-x: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-150 {
  --tw-scale-x: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-50 {
  --tw-scale-x: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-75 {
  --tw-scale-x: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-90 {
  --tw-scale-x: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-95 {
  --tw-scale-x: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-0 {
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-100 {
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-105 {
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-110 {
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-125 {
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-150 {
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-50 {
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-75 {
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-90 {
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-95 {
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform, .transform-cpu {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.animate-bounce {
  animation: 1s infinite bounce;
}

.animate-none {
  animation: none;
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-alias {
  cursor: alias;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.cursor-auto {
  cursor: auto;
}

.cursor-cell {
  cursor: cell;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-copy {
  cursor: copy;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-default {
  cursor: default;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-help {
  cursor: help;
}

.cursor-move {
  cursor: move;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-none {
  cursor: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-text {
  cursor: text;
}

.cursor-vertical-text {
  cursor: vertical-text;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-wait {
  cursor: wait;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.touch-auto {
  touch-action: auto;
}

.touch-none {
  touch-action: none;
}

.touch-pan-x {
  --tw-pan-x: pan-x;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pan-left {
  --tw-pan-x: pan-left;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pan-right {
  --tw-pan-x: pan-right;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pan-y {
  --tw-pan-y: pan-y;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pan-up {
  --tw-pan-y: pan-up;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pan-down {
  --tw-pan-y: pan-down;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pinch-zoom {
  --tw-pinch-zoom: pinch-zoom;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-manipulation {
  touch-action: manipulation;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-user-select: text;
  user-select: text;
}

.select-all {
  -webkit-user-select: all;
  user-select: all;
}

.select-auto {
  -webkit-user-select: auto;
  user-select: auto;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.resize-x {
  resize: horizontal;
}

.resize {
  resize: both;
}

.snap-none {
  scroll-snap-type: none;
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
}

.snap-both {
  scroll-snap-type: both var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-proximity {
  --tw-scroll-snap-strictness: proximity;
}

.snap-start {
  scroll-snap-align: start;
}

.snap-end {
  scroll-snap-align: end;
}

.snap-center {
  scroll-snap-align: center;
}

.snap-align-none {
  scroll-snap-align: none;
}

.snap-normal {
  scroll-snap-stop: normal;
}

.snap-always {
  scroll-snap-stop: always;
}

.-scroll-m-0 {
  scroll-margin: 0;
}

.-scroll-m-0\.5 {
  scroll-margin: -.125rem;
}

.-scroll-m-1 {
  scroll-margin: -.25rem;
}

.-scroll-m-1\.5 {
  scroll-margin: -.375rem;
}

.-scroll-m-10 {
  scroll-margin: -2.5rem;
}

.-scroll-m-11 {
  scroll-margin: -2.75rem;
}

.-scroll-m-12 {
  scroll-margin: -3rem;
}

.-scroll-m-14 {
  scroll-margin: -3.5rem;
}

.-scroll-m-16 {
  scroll-margin: -4rem;
}

.-scroll-m-2 {
  scroll-margin: -.5rem;
}

.-scroll-m-2\.5 {
  scroll-margin: -.625rem;
}

.-scroll-m-20 {
  scroll-margin: -5rem;
}

.-scroll-m-24 {
  scroll-margin: -6rem;
}

.-scroll-m-28 {
  scroll-margin: -7rem;
}

.-scroll-m-3 {
  scroll-margin: -.75rem;
}

.-scroll-m-3\.5 {
  scroll-margin: -.875rem;
}

.-scroll-m-32 {
  scroll-margin: -8rem;
}

.-scroll-m-36 {
  scroll-margin: -9rem;
}

.-scroll-m-4 {
  scroll-margin: -1rem;
}

.-scroll-m-40 {
  scroll-margin: -10rem;
}

.-scroll-m-44 {
  scroll-margin: -11rem;
}

.-scroll-m-48 {
  scroll-margin: -12rem;
}

.-scroll-m-5 {
  scroll-margin: -1.25rem;
}

.-scroll-m-52 {
  scroll-margin: -13rem;
}

.-scroll-m-56 {
  scroll-margin: -14rem;
}

.-scroll-m-6 {
  scroll-margin: -1.5rem;
}

.-scroll-m-60 {
  scroll-margin: -15rem;
}

.-scroll-m-64 {
  scroll-margin: -16rem;
}

.-scroll-m-7 {
  scroll-margin: -1.75rem;
}

.-scroll-m-72 {
  scroll-margin: -18rem;
}

.-scroll-m-8 {
  scroll-margin: -2rem;
}

.-scroll-m-80 {
  scroll-margin: -20rem;
}

.-scroll-m-9 {
  scroll-margin: -2.25rem;
}

.-scroll-m-96 {
  scroll-margin: -24rem;
}

.-scroll-m-px {
  scroll-margin: -1px;
}

.scroll-m-0 {
  scroll-margin: 0;
}

.scroll-m-0\.5 {
  scroll-margin: .125rem;
}

.scroll-m-1 {
  scroll-margin: .25rem;
}

.scroll-m-1\.5 {
  scroll-margin: .375rem;
}

.scroll-m-10 {
  scroll-margin: 2.5rem;
}

.scroll-m-11 {
  scroll-margin: 2.75rem;
}

.scroll-m-12 {
  scroll-margin: 3rem;
}

.scroll-m-14 {
  scroll-margin: 3.5rem;
}

.scroll-m-16 {
  scroll-margin: 4rem;
}

.scroll-m-2 {
  scroll-margin: .5rem;
}

.scroll-m-2\.5 {
  scroll-margin: .625rem;
}

.scroll-m-20 {
  scroll-margin: 5rem;
}

.scroll-m-24 {
  scroll-margin: 6rem;
}

.scroll-m-28 {
  scroll-margin: 7rem;
}

.scroll-m-3 {
  scroll-margin: .75rem;
}

.scroll-m-3\.5 {
  scroll-margin: .875rem;
}

.scroll-m-32 {
  scroll-margin: 8rem;
}

.scroll-m-36 {
  scroll-margin: 9rem;
}

.scroll-m-4 {
  scroll-margin: 1rem;
}

.scroll-m-40 {
  scroll-margin: 10rem;
}

.scroll-m-44 {
  scroll-margin: 11rem;
}

.scroll-m-48 {
  scroll-margin: 12rem;
}

.scroll-m-5 {
  scroll-margin: 1.25rem;
}

.scroll-m-52 {
  scroll-margin: 13rem;
}

.scroll-m-56 {
  scroll-margin: 14rem;
}

.scroll-m-6 {
  scroll-margin: 1.5rem;
}

.scroll-m-60 {
  scroll-margin: 15rem;
}

.scroll-m-64 {
  scroll-margin: 16rem;
}

.scroll-m-7 {
  scroll-margin: 1.75rem;
}

.scroll-m-72 {
  scroll-margin: 18rem;
}

.scroll-m-8 {
  scroll-margin: 2rem;
}

.scroll-m-80 {
  scroll-margin: 20rem;
}

.scroll-m-9 {
  scroll-margin: 2.25rem;
}

.scroll-m-96 {
  scroll-margin: 24rem;
}

.scroll-m-px {
  scroll-margin: 1px;
}

.-scroll-mx-0 {
  scroll-margin-left: 0;
  scroll-margin-right: 0;
}

.-scroll-mx-0\.5 {
  scroll-margin-left: -.125rem;
  scroll-margin-right: -.125rem;
}

.-scroll-mx-1 {
  scroll-margin-left: -.25rem;
  scroll-margin-right: -.25rem;
}

.-scroll-mx-1\.5 {
  scroll-margin-left: -.375rem;
  scroll-margin-right: -.375rem;
}

.-scroll-mx-10 {
  scroll-margin-left: -2.5rem;
  scroll-margin-right: -2.5rem;
}

.-scroll-mx-11 {
  scroll-margin-left: -2.75rem;
  scroll-margin-right: -2.75rem;
}

.-scroll-mx-12 {
  scroll-margin-left: -3rem;
  scroll-margin-right: -3rem;
}

.-scroll-mx-14 {
  scroll-margin-left: -3.5rem;
  scroll-margin-right: -3.5rem;
}

.-scroll-mx-16 {
  scroll-margin-left: -4rem;
  scroll-margin-right: -4rem;
}

.-scroll-mx-2 {
  scroll-margin-left: -.5rem;
  scroll-margin-right: -.5rem;
}

.-scroll-mx-2\.5 {
  scroll-margin-left: -.625rem;
  scroll-margin-right: -.625rem;
}

.-scroll-mx-20 {
  scroll-margin-left: -5rem;
  scroll-margin-right: -5rem;
}

.-scroll-mx-24 {
  scroll-margin-left: -6rem;
  scroll-margin-right: -6rem;
}

.-scroll-mx-28 {
  scroll-margin-left: -7rem;
  scroll-margin-right: -7rem;
}

.-scroll-mx-3 {
  scroll-margin-left: -.75rem;
  scroll-margin-right: -.75rem;
}

.-scroll-mx-3\.5 {
  scroll-margin-left: -.875rem;
  scroll-margin-right: -.875rem;
}

.-scroll-mx-32 {
  scroll-margin-left: -8rem;
  scroll-margin-right: -8rem;
}

.-scroll-mx-36 {
  scroll-margin-left: -9rem;
  scroll-margin-right: -9rem;
}

.-scroll-mx-4 {
  scroll-margin-left: -1rem;
  scroll-margin-right: -1rem;
}

.-scroll-mx-40 {
  scroll-margin-left: -10rem;
  scroll-margin-right: -10rem;
}

.-scroll-mx-44 {
  scroll-margin-left: -11rem;
  scroll-margin-right: -11rem;
}

.-scroll-mx-48 {
  scroll-margin-left: -12rem;
  scroll-margin-right: -12rem;
}

.-scroll-mx-5 {
  scroll-margin-left: -1.25rem;
  scroll-margin-right: -1.25rem;
}

.-scroll-mx-52 {
  scroll-margin-left: -13rem;
  scroll-margin-right: -13rem;
}

.-scroll-mx-56 {
  scroll-margin-left: -14rem;
  scroll-margin-right: -14rem;
}

.-scroll-mx-6 {
  scroll-margin-left: -1.5rem;
  scroll-margin-right: -1.5rem;
}

.-scroll-mx-60 {
  scroll-margin-left: -15rem;
  scroll-margin-right: -15rem;
}

.-scroll-mx-64 {
  scroll-margin-left: -16rem;
  scroll-margin-right: -16rem;
}

.-scroll-mx-7 {
  scroll-margin-left: -1.75rem;
  scroll-margin-right: -1.75rem;
}

.-scroll-mx-72 {
  scroll-margin-left: -18rem;
  scroll-margin-right: -18rem;
}

.-scroll-mx-8 {
  scroll-margin-left: -2rem;
  scroll-margin-right: -2rem;
}

.-scroll-mx-80 {
  scroll-margin-left: -20rem;
  scroll-margin-right: -20rem;
}

.-scroll-mx-9 {
  scroll-margin-left: -2.25rem;
  scroll-margin-right: -2.25rem;
}

.-scroll-mx-96 {
  scroll-margin-left: -24rem;
  scroll-margin-right: -24rem;
}

.-scroll-mx-px {
  scroll-margin-left: -1px;
  scroll-margin-right: -1px;
}

.-scroll-my-0 {
  scroll-margin-top: 0;
  scroll-margin-bottom: 0;
}

.-scroll-my-0\.5 {
  scroll-margin-top: -.125rem;
  scroll-margin-bottom: -.125rem;
}

.-scroll-my-1 {
  scroll-margin-top: -.25rem;
  scroll-margin-bottom: -.25rem;
}

.-scroll-my-1\.5 {
  scroll-margin-top: -.375rem;
  scroll-margin-bottom: -.375rem;
}

.-scroll-my-10 {
  scroll-margin-top: -2.5rem;
  scroll-margin-bottom: -2.5rem;
}

.-scroll-my-11 {
  scroll-margin-top: -2.75rem;
  scroll-margin-bottom: -2.75rem;
}

.-scroll-my-12 {
  scroll-margin-top: -3rem;
  scroll-margin-bottom: -3rem;
}

.-scroll-my-14 {
  scroll-margin-top: -3.5rem;
  scroll-margin-bottom: -3.5rem;
}

.-scroll-my-16 {
  scroll-margin-top: -4rem;
  scroll-margin-bottom: -4rem;
}

.-scroll-my-2 {
  scroll-margin-top: -.5rem;
  scroll-margin-bottom: -.5rem;
}

.-scroll-my-2\.5 {
  scroll-margin-top: -.625rem;
  scroll-margin-bottom: -.625rem;
}

.-scroll-my-20 {
  scroll-margin-top: -5rem;
  scroll-margin-bottom: -5rem;
}

.-scroll-my-24 {
  scroll-margin-top: -6rem;
  scroll-margin-bottom: -6rem;
}

.-scroll-my-28 {
  scroll-margin-top: -7rem;
  scroll-margin-bottom: -7rem;
}

.-scroll-my-3 {
  scroll-margin-top: -.75rem;
  scroll-margin-bottom: -.75rem;
}

.-scroll-my-3\.5 {
  scroll-margin-top: -.875rem;
  scroll-margin-bottom: -.875rem;
}

.-scroll-my-32 {
  scroll-margin-top: -8rem;
  scroll-margin-bottom: -8rem;
}

.-scroll-my-36 {
  scroll-margin-top: -9rem;
  scroll-margin-bottom: -9rem;
}

.-scroll-my-4 {
  scroll-margin-top: -1rem;
  scroll-margin-bottom: -1rem;
}

.-scroll-my-40 {
  scroll-margin-top: -10rem;
  scroll-margin-bottom: -10rem;
}

.-scroll-my-44 {
  scroll-margin-top: -11rem;
  scroll-margin-bottom: -11rem;
}

.-scroll-my-48 {
  scroll-margin-top: -12rem;
  scroll-margin-bottom: -12rem;
}

.-scroll-my-5 {
  scroll-margin-top: -1.25rem;
  scroll-margin-bottom: -1.25rem;
}

.-scroll-my-52 {
  scroll-margin-top: -13rem;
  scroll-margin-bottom: -13rem;
}

.-scroll-my-56 {
  scroll-margin-top: -14rem;
  scroll-margin-bottom: -14rem;
}

.-scroll-my-6 {
  scroll-margin-top: -1.5rem;
  scroll-margin-bottom: -1.5rem;
}

.-scroll-my-60 {
  scroll-margin-top: -15rem;
  scroll-margin-bottom: -15rem;
}

.-scroll-my-64 {
  scroll-margin-top: -16rem;
  scroll-margin-bottom: -16rem;
}

.-scroll-my-7 {
  scroll-margin-top: -1.75rem;
  scroll-margin-bottom: -1.75rem;
}

.-scroll-my-72 {
  scroll-margin-top: -18rem;
  scroll-margin-bottom: -18rem;
}

.-scroll-my-8 {
  scroll-margin-top: -2rem;
  scroll-margin-bottom: -2rem;
}

.-scroll-my-80 {
  scroll-margin-top: -20rem;
  scroll-margin-bottom: -20rem;
}

.-scroll-my-9 {
  scroll-margin-top: -2.25rem;
  scroll-margin-bottom: -2.25rem;
}

.-scroll-my-96 {
  scroll-margin-top: -24rem;
  scroll-margin-bottom: -24rem;
}

.-scroll-my-px {
  scroll-margin-top: -1px;
  scroll-margin-bottom: -1px;
}

.scroll-mx-0 {
  scroll-margin-left: 0;
  scroll-margin-right: 0;
}

.scroll-mx-0\.5 {
  scroll-margin-left: .125rem;
  scroll-margin-right: .125rem;
}

.scroll-mx-1 {
  scroll-margin-left: .25rem;
  scroll-margin-right: .25rem;
}

.scroll-mx-1\.5 {
  scroll-margin-left: .375rem;
  scroll-margin-right: .375rem;
}

.scroll-mx-10 {
  scroll-margin-left: 2.5rem;
  scroll-margin-right: 2.5rem;
}

.scroll-mx-11 {
  scroll-margin-left: 2.75rem;
  scroll-margin-right: 2.75rem;
}

.scroll-mx-12 {
  scroll-margin-left: 3rem;
  scroll-margin-right: 3rem;
}

.scroll-mx-14 {
  scroll-margin-left: 3.5rem;
  scroll-margin-right: 3.5rem;
}

.scroll-mx-16 {
  scroll-margin-left: 4rem;
  scroll-margin-right: 4rem;
}

.scroll-mx-2 {
  scroll-margin-left: .5rem;
  scroll-margin-right: .5rem;
}

.scroll-mx-2\.5 {
  scroll-margin-left: .625rem;
  scroll-margin-right: .625rem;
}

.scroll-mx-20 {
  scroll-margin-left: 5rem;
  scroll-margin-right: 5rem;
}

.scroll-mx-24 {
  scroll-margin-left: 6rem;
  scroll-margin-right: 6rem;
}

.scroll-mx-28 {
  scroll-margin-left: 7rem;
  scroll-margin-right: 7rem;
}

.scroll-mx-3 {
  scroll-margin-left: .75rem;
  scroll-margin-right: .75rem;
}

.scroll-mx-3\.5 {
  scroll-margin-left: .875rem;
  scroll-margin-right: .875rem;
}

.scroll-mx-32 {
  scroll-margin-left: 8rem;
  scroll-margin-right: 8rem;
}

.scroll-mx-36 {
  scroll-margin-left: 9rem;
  scroll-margin-right: 9rem;
}

.scroll-mx-4 {
  scroll-margin-left: 1rem;
  scroll-margin-right: 1rem;
}

.scroll-mx-40 {
  scroll-margin-left: 10rem;
  scroll-margin-right: 10rem;
}

.scroll-mx-44 {
  scroll-margin-left: 11rem;
  scroll-margin-right: 11rem;
}

.scroll-mx-48 {
  scroll-margin-left: 12rem;
  scroll-margin-right: 12rem;
}

.scroll-mx-5 {
  scroll-margin-left: 1.25rem;
  scroll-margin-right: 1.25rem;
}

.scroll-mx-52 {
  scroll-margin-left: 13rem;
  scroll-margin-right: 13rem;
}

.scroll-mx-56 {
  scroll-margin-left: 14rem;
  scroll-margin-right: 14rem;
}

.scroll-mx-6 {
  scroll-margin-left: 1.5rem;
  scroll-margin-right: 1.5rem;
}

.scroll-mx-60 {
  scroll-margin-left: 15rem;
  scroll-margin-right: 15rem;
}

.scroll-mx-64 {
  scroll-margin-left: 16rem;
  scroll-margin-right: 16rem;
}

.scroll-mx-7 {
  scroll-margin-left: 1.75rem;
  scroll-margin-right: 1.75rem;
}

.scroll-mx-72 {
  scroll-margin-left: 18rem;
  scroll-margin-right: 18rem;
}

.scroll-mx-8 {
  scroll-margin-left: 2rem;
  scroll-margin-right: 2rem;
}

.scroll-mx-80 {
  scroll-margin-left: 20rem;
  scroll-margin-right: 20rem;
}

.scroll-mx-9 {
  scroll-margin-left: 2.25rem;
  scroll-margin-right: 2.25rem;
}

.scroll-mx-96 {
  scroll-margin-left: 24rem;
  scroll-margin-right: 24rem;
}

.scroll-mx-px {
  scroll-margin-left: 1px;
  scroll-margin-right: 1px;
}

.scroll-my-0 {
  scroll-margin-top: 0;
  scroll-margin-bottom: 0;
}

.scroll-my-0\.5 {
  scroll-margin-top: .125rem;
  scroll-margin-bottom: .125rem;
}

.scroll-my-1 {
  scroll-margin-top: .25rem;
  scroll-margin-bottom: .25rem;
}

.scroll-my-1\.5 {
  scroll-margin-top: .375rem;
  scroll-margin-bottom: .375rem;
}

.scroll-my-10 {
  scroll-margin-top: 2.5rem;
  scroll-margin-bottom: 2.5rem;
}

.scroll-my-11 {
  scroll-margin-top: 2.75rem;
  scroll-margin-bottom: 2.75rem;
}

.scroll-my-12 {
  scroll-margin-top: 3rem;
  scroll-margin-bottom: 3rem;
}

.scroll-my-14 {
  scroll-margin-top: 3.5rem;
  scroll-margin-bottom: 3.5rem;
}

.scroll-my-16 {
  scroll-margin-top: 4rem;
  scroll-margin-bottom: 4rem;
}

.scroll-my-2 {
  scroll-margin-top: .5rem;
  scroll-margin-bottom: .5rem;
}

.scroll-my-2\.5 {
  scroll-margin-top: .625rem;
  scroll-margin-bottom: .625rem;
}

.scroll-my-20 {
  scroll-margin-top: 5rem;
  scroll-margin-bottom: 5rem;
}

.scroll-my-24 {
  scroll-margin-top: 6rem;
  scroll-margin-bottom: 6rem;
}

.scroll-my-28 {
  scroll-margin-top: 7rem;
  scroll-margin-bottom: 7rem;
}

.scroll-my-3 {
  scroll-margin-top: .75rem;
  scroll-margin-bottom: .75rem;
}

.scroll-my-3\.5 {
  scroll-margin-top: .875rem;
  scroll-margin-bottom: .875rem;
}

.scroll-my-32 {
  scroll-margin-top: 8rem;
  scroll-margin-bottom: 8rem;
}

.scroll-my-36 {
  scroll-margin-top: 9rem;
  scroll-margin-bottom: 9rem;
}

.scroll-my-4 {
  scroll-margin-top: 1rem;
  scroll-margin-bottom: 1rem;
}

.scroll-my-40 {
  scroll-margin-top: 10rem;
  scroll-margin-bottom: 10rem;
}

.scroll-my-44 {
  scroll-margin-top: 11rem;
  scroll-margin-bottom: 11rem;
}

.scroll-my-48 {
  scroll-margin-top: 12rem;
  scroll-margin-bottom: 12rem;
}

.scroll-my-5 {
  scroll-margin-top: 1.25rem;
  scroll-margin-bottom: 1.25rem;
}

.scroll-my-52 {
  scroll-margin-top: 13rem;
  scroll-margin-bottom: 13rem;
}

.scroll-my-56 {
  scroll-margin-top: 14rem;
  scroll-margin-bottom: 14rem;
}

.scroll-my-6 {
  scroll-margin-top: 1.5rem;
  scroll-margin-bottom: 1.5rem;
}

.scroll-my-60 {
  scroll-margin-top: 15rem;
  scroll-margin-bottom: 15rem;
}

.scroll-my-64 {
  scroll-margin-top: 16rem;
  scroll-margin-bottom: 16rem;
}

.scroll-my-7 {
  scroll-margin-top: 1.75rem;
  scroll-margin-bottom: 1.75rem;
}

.scroll-my-72 {
  scroll-margin-top: 18rem;
  scroll-margin-bottom: 18rem;
}

.scroll-my-8 {
  scroll-margin-top: 2rem;
  scroll-margin-bottom: 2rem;
}

.scroll-my-80 {
  scroll-margin-top: 20rem;
  scroll-margin-bottom: 20rem;
}

.scroll-my-9 {
  scroll-margin-top: 2.25rem;
  scroll-margin-bottom: 2.25rem;
}

.scroll-my-96 {
  scroll-margin-top: 24rem;
  scroll-margin-bottom: 24rem;
}

.scroll-my-px {
  scroll-margin-top: 1px;
  scroll-margin-bottom: 1px;
}

.-scroll-mb-0 {
  scroll-margin-bottom: 0;
}

.-scroll-mb-0\.5 {
  scroll-margin-bottom: -.125rem;
}

.-scroll-mb-1 {
  scroll-margin-bottom: -.25rem;
}

.-scroll-mb-1\.5 {
  scroll-margin-bottom: -.375rem;
}

.-scroll-mb-10 {
  scroll-margin-bottom: -2.5rem;
}

.-scroll-mb-11 {
  scroll-margin-bottom: -2.75rem;
}

.-scroll-mb-12 {
  scroll-margin-bottom: -3rem;
}

.-scroll-mb-14 {
  scroll-margin-bottom: -3.5rem;
}

.-scroll-mb-16 {
  scroll-margin-bottom: -4rem;
}

.-scroll-mb-2 {
  scroll-margin-bottom: -.5rem;
}

.-scroll-mb-2\.5 {
  scroll-margin-bottom: -.625rem;
}

.-scroll-mb-20 {
  scroll-margin-bottom: -5rem;
}

.-scroll-mb-24 {
  scroll-margin-bottom: -6rem;
}

.-scroll-mb-28 {
  scroll-margin-bottom: -7rem;
}

.-scroll-mb-3 {
  scroll-margin-bottom: -.75rem;
}

.-scroll-mb-3\.5 {
  scroll-margin-bottom: -.875rem;
}

.-scroll-mb-32 {
  scroll-margin-bottom: -8rem;
}

.-scroll-mb-36 {
  scroll-margin-bottom: -9rem;
}

.-scroll-mb-4 {
  scroll-margin-bottom: -1rem;
}

.-scroll-mb-40 {
  scroll-margin-bottom: -10rem;
}

.-scroll-mb-44 {
  scroll-margin-bottom: -11rem;
}

.-scroll-mb-48 {
  scroll-margin-bottom: -12rem;
}

.-scroll-mb-5 {
  scroll-margin-bottom: -1.25rem;
}

.-scroll-mb-52 {
  scroll-margin-bottom: -13rem;
}

.-scroll-mb-56 {
  scroll-margin-bottom: -14rem;
}

.-scroll-mb-6 {
  scroll-margin-bottom: -1.5rem;
}

.-scroll-mb-60 {
  scroll-margin-bottom: -15rem;
}

.-scroll-mb-64 {
  scroll-margin-bottom: -16rem;
}

.-scroll-mb-7 {
  scroll-margin-bottom: -1.75rem;
}

.-scroll-mb-72 {
  scroll-margin-bottom: -18rem;
}

.-scroll-mb-8 {
  scroll-margin-bottom: -2rem;
}

.-scroll-mb-80 {
  scroll-margin-bottom: -20rem;
}

.-scroll-mb-9 {
  scroll-margin-bottom: -2.25rem;
}

.-scroll-mb-96 {
  scroll-margin-bottom: -24rem;
}

.-scroll-mb-px {
  scroll-margin-bottom: -1px;
}

.-scroll-me-0 {
  scroll-margin-inline-end: 0;
}

.-scroll-me-0\.5 {
  scroll-margin-inline-end: -.125rem;
}

.-scroll-me-1 {
  scroll-margin-inline-end: -.25rem;
}

.-scroll-me-1\.5 {
  scroll-margin-inline-end: -.375rem;
}

.-scroll-me-10 {
  scroll-margin-inline-end: -2.5rem;
}

.-scroll-me-11 {
  scroll-margin-inline-end: -2.75rem;
}

.-scroll-me-12 {
  scroll-margin-inline-end: -3rem;
}

.-scroll-me-14 {
  scroll-margin-inline-end: -3.5rem;
}

.-scroll-me-16 {
  scroll-margin-inline-end: -4rem;
}

.-scroll-me-2 {
  scroll-margin-inline-end: -.5rem;
}

.-scroll-me-2\.5 {
  scroll-margin-inline-end: -.625rem;
}

.-scroll-me-20 {
  scroll-margin-inline-end: -5rem;
}

.-scroll-me-24 {
  scroll-margin-inline-end: -6rem;
}

.-scroll-me-28 {
  scroll-margin-inline-end: -7rem;
}

.-scroll-me-3 {
  scroll-margin-inline-end: -.75rem;
}

.-scroll-me-3\.5 {
  scroll-margin-inline-end: -.875rem;
}

.-scroll-me-32 {
  scroll-margin-inline-end: -8rem;
}

.-scroll-me-36 {
  scroll-margin-inline-end: -9rem;
}

.-scroll-me-4 {
  scroll-margin-inline-end: -1rem;
}

.-scroll-me-40 {
  scroll-margin-inline-end: -10rem;
}

.-scroll-me-44 {
  scroll-margin-inline-end: -11rem;
}

.-scroll-me-48 {
  scroll-margin-inline-end: -12rem;
}

.-scroll-me-5 {
  scroll-margin-inline-end: -1.25rem;
}

.-scroll-me-52 {
  scroll-margin-inline-end: -13rem;
}

.-scroll-me-56 {
  scroll-margin-inline-end: -14rem;
}

.-scroll-me-6 {
  scroll-margin-inline-end: -1.5rem;
}

.-scroll-me-60 {
  scroll-margin-inline-end: -15rem;
}

.-scroll-me-64 {
  scroll-margin-inline-end: -16rem;
}

.-scroll-me-7 {
  scroll-margin-inline-end: -1.75rem;
}

.-scroll-me-72 {
  scroll-margin-inline-end: -18rem;
}

.-scroll-me-8 {
  scroll-margin-inline-end: -2rem;
}

.-scroll-me-80 {
  scroll-margin-inline-end: -20rem;
}

.-scroll-me-9 {
  scroll-margin-inline-end: -2.25rem;
}

.-scroll-me-96 {
  scroll-margin-inline-end: -24rem;
}

.-scroll-me-px {
  scroll-margin-inline-end: -1px;
}

.-scroll-ml-0 {
  scroll-margin-left: 0;
}

.-scroll-ml-0\.5 {
  scroll-margin-left: -.125rem;
}

.-scroll-ml-1 {
  scroll-margin-left: -.25rem;
}

.-scroll-ml-1\.5 {
  scroll-margin-left: -.375rem;
}

.-scroll-ml-10 {
  scroll-margin-left: -2.5rem;
}

.-scroll-ml-11 {
  scroll-margin-left: -2.75rem;
}

.-scroll-ml-12 {
  scroll-margin-left: -3rem;
}

.-scroll-ml-14 {
  scroll-margin-left: -3.5rem;
}

.-scroll-ml-16 {
  scroll-margin-left: -4rem;
}

.-scroll-ml-2 {
  scroll-margin-left: -.5rem;
}

.-scroll-ml-2\.5 {
  scroll-margin-left: -.625rem;
}

.-scroll-ml-20 {
  scroll-margin-left: -5rem;
}

.-scroll-ml-24 {
  scroll-margin-left: -6rem;
}

.-scroll-ml-28 {
  scroll-margin-left: -7rem;
}

.-scroll-ml-3 {
  scroll-margin-left: -.75rem;
}

.-scroll-ml-3\.5 {
  scroll-margin-left: -.875rem;
}

.-scroll-ml-32 {
  scroll-margin-left: -8rem;
}

.-scroll-ml-36 {
  scroll-margin-left: -9rem;
}

.-scroll-ml-4 {
  scroll-margin-left: -1rem;
}

.-scroll-ml-40 {
  scroll-margin-left: -10rem;
}

.-scroll-ml-44 {
  scroll-margin-left: -11rem;
}

.-scroll-ml-48 {
  scroll-margin-left: -12rem;
}

.-scroll-ml-5 {
  scroll-margin-left: -1.25rem;
}

.-scroll-ml-52 {
  scroll-margin-left: -13rem;
}

.-scroll-ml-56 {
  scroll-margin-left: -14rem;
}

.-scroll-ml-6 {
  scroll-margin-left: -1.5rem;
}

.-scroll-ml-60 {
  scroll-margin-left: -15rem;
}

.-scroll-ml-64 {
  scroll-margin-left: -16rem;
}

.-scroll-ml-7 {
  scroll-margin-left: -1.75rem;
}

.-scroll-ml-72 {
  scroll-margin-left: -18rem;
}

.-scroll-ml-8 {
  scroll-margin-left: -2rem;
}

.-scroll-ml-80 {
  scroll-margin-left: -20rem;
}

.-scroll-ml-9 {
  scroll-margin-left: -2.25rem;
}

.-scroll-ml-96 {
  scroll-margin-left: -24rem;
}

.-scroll-ml-px {
  scroll-margin-left: -1px;
}

.-scroll-mr-0 {
  scroll-margin-right: 0;
}

.-scroll-mr-0\.5 {
  scroll-margin-right: -.125rem;
}

.-scroll-mr-1 {
  scroll-margin-right: -.25rem;
}

.-scroll-mr-1\.5 {
  scroll-margin-right: -.375rem;
}

.-scroll-mr-10 {
  scroll-margin-right: -2.5rem;
}

.-scroll-mr-11 {
  scroll-margin-right: -2.75rem;
}

.-scroll-mr-12 {
  scroll-margin-right: -3rem;
}

.-scroll-mr-14 {
  scroll-margin-right: -3.5rem;
}

.-scroll-mr-16 {
  scroll-margin-right: -4rem;
}

.-scroll-mr-2 {
  scroll-margin-right: -.5rem;
}

.-scroll-mr-2\.5 {
  scroll-margin-right: -.625rem;
}

.-scroll-mr-20 {
  scroll-margin-right: -5rem;
}

.-scroll-mr-24 {
  scroll-margin-right: -6rem;
}

.-scroll-mr-28 {
  scroll-margin-right: -7rem;
}

.-scroll-mr-3 {
  scroll-margin-right: -.75rem;
}

.-scroll-mr-3\.5 {
  scroll-margin-right: -.875rem;
}

.-scroll-mr-32 {
  scroll-margin-right: -8rem;
}

.-scroll-mr-36 {
  scroll-margin-right: -9rem;
}

.-scroll-mr-4 {
  scroll-margin-right: -1rem;
}

.-scroll-mr-40 {
  scroll-margin-right: -10rem;
}

.-scroll-mr-44 {
  scroll-margin-right: -11rem;
}

.-scroll-mr-48 {
  scroll-margin-right: -12rem;
}

.-scroll-mr-5 {
  scroll-margin-right: -1.25rem;
}

.-scroll-mr-52 {
  scroll-margin-right: -13rem;
}

.-scroll-mr-56 {
  scroll-margin-right: -14rem;
}

.-scroll-mr-6 {
  scroll-margin-right: -1.5rem;
}

.-scroll-mr-60 {
  scroll-margin-right: -15rem;
}

.-scroll-mr-64 {
  scroll-margin-right: -16rem;
}

.-scroll-mr-7 {
  scroll-margin-right: -1.75rem;
}

.-scroll-mr-72 {
  scroll-margin-right: -18rem;
}

.-scroll-mr-8 {
  scroll-margin-right: -2rem;
}

.-scroll-mr-80 {
  scroll-margin-right: -20rem;
}

.-scroll-mr-9 {
  scroll-margin-right: -2.25rem;
}

.-scroll-mr-96 {
  scroll-margin-right: -24rem;
}

.-scroll-mr-px {
  scroll-margin-right: -1px;
}

.-scroll-ms-0 {
  scroll-margin-inline-start: 0;
}

.-scroll-ms-0\.5 {
  scroll-margin-inline-start: -.125rem;
}

.-scroll-ms-1 {
  scroll-margin-inline-start: -.25rem;
}

.-scroll-ms-1\.5 {
  scroll-margin-inline-start: -.375rem;
}

.-scroll-ms-10 {
  scroll-margin-inline-start: -2.5rem;
}

.-scroll-ms-11 {
  scroll-margin-inline-start: -2.75rem;
}

.-scroll-ms-12 {
  scroll-margin-inline-start: -3rem;
}

.-scroll-ms-14 {
  scroll-margin-inline-start: -3.5rem;
}

.-scroll-ms-16 {
  scroll-margin-inline-start: -4rem;
}

.-scroll-ms-2 {
  scroll-margin-inline-start: -.5rem;
}

.-scroll-ms-2\.5 {
  scroll-margin-inline-start: -.625rem;
}

.-scroll-ms-20 {
  scroll-margin-inline-start: -5rem;
}

.-scroll-ms-24 {
  scroll-margin-inline-start: -6rem;
}

.-scroll-ms-28 {
  scroll-margin-inline-start: -7rem;
}

.-scroll-ms-3 {
  scroll-margin-inline-start: -.75rem;
}

.-scroll-ms-3\.5 {
  scroll-margin-inline-start: -.875rem;
}

.-scroll-ms-32 {
  scroll-margin-inline-start: -8rem;
}

.-scroll-ms-36 {
  scroll-margin-inline-start: -9rem;
}

.-scroll-ms-4 {
  scroll-margin-inline-start: -1rem;
}

.-scroll-ms-40 {
  scroll-margin-inline-start: -10rem;
}

.-scroll-ms-44 {
  scroll-margin-inline-start: -11rem;
}

.-scroll-ms-48 {
  scroll-margin-inline-start: -12rem;
}

.-scroll-ms-5 {
  scroll-margin-inline-start: -1.25rem;
}

.-scroll-ms-52 {
  scroll-margin-inline-start: -13rem;
}

.-scroll-ms-56 {
  scroll-margin-inline-start: -14rem;
}

.-scroll-ms-6 {
  scroll-margin-inline-start: -1.5rem;
}

.-scroll-ms-60 {
  scroll-margin-inline-start: -15rem;
}

.-scroll-ms-64 {
  scroll-margin-inline-start: -16rem;
}

.-scroll-ms-7 {
  scroll-margin-inline-start: -1.75rem;
}

.-scroll-ms-72 {
  scroll-margin-inline-start: -18rem;
}

.-scroll-ms-8 {
  scroll-margin-inline-start: -2rem;
}

.-scroll-ms-80 {
  scroll-margin-inline-start: -20rem;
}

.-scroll-ms-9 {
  scroll-margin-inline-start: -2.25rem;
}

.-scroll-ms-96 {
  scroll-margin-inline-start: -24rem;
}

.-scroll-ms-px {
  scroll-margin-inline-start: -1px;
}

.-scroll-mt-0 {
  scroll-margin-top: 0;
}

.-scroll-mt-0\.5 {
  scroll-margin-top: -.125rem;
}

.-scroll-mt-1 {
  scroll-margin-top: -.25rem;
}

.-scroll-mt-1\.5 {
  scroll-margin-top: -.375rem;
}

.-scroll-mt-10 {
  scroll-margin-top: -2.5rem;
}

.-scroll-mt-11 {
  scroll-margin-top: -2.75rem;
}

.-scroll-mt-12 {
  scroll-margin-top: -3rem;
}

.-scroll-mt-14 {
  scroll-margin-top: -3.5rem;
}

.-scroll-mt-16 {
  scroll-margin-top: -4rem;
}

.-scroll-mt-2 {
  scroll-margin-top: -.5rem;
}

.-scroll-mt-2\.5 {
  scroll-margin-top: -.625rem;
}

.-scroll-mt-20 {
  scroll-margin-top: -5rem;
}

.-scroll-mt-24 {
  scroll-margin-top: -6rem;
}

.-scroll-mt-28 {
  scroll-margin-top: -7rem;
}

.-scroll-mt-3 {
  scroll-margin-top: -.75rem;
}

.-scroll-mt-3\.5 {
  scroll-margin-top: -.875rem;
}

.-scroll-mt-32 {
  scroll-margin-top: -8rem;
}

.-scroll-mt-36 {
  scroll-margin-top: -9rem;
}

.-scroll-mt-4 {
  scroll-margin-top: -1rem;
}

.-scroll-mt-40 {
  scroll-margin-top: -10rem;
}

.-scroll-mt-44 {
  scroll-margin-top: -11rem;
}

.-scroll-mt-48 {
  scroll-margin-top: -12rem;
}

.-scroll-mt-5 {
  scroll-margin-top: -1.25rem;
}

.-scroll-mt-52 {
  scroll-margin-top: -13rem;
}

.-scroll-mt-56 {
  scroll-margin-top: -14rem;
}

.-scroll-mt-6 {
  scroll-margin-top: -1.5rem;
}

.-scroll-mt-60 {
  scroll-margin-top: -15rem;
}

.-scroll-mt-64 {
  scroll-margin-top: -16rem;
}

.-scroll-mt-7 {
  scroll-margin-top: -1.75rem;
}

.-scroll-mt-72 {
  scroll-margin-top: -18rem;
}

.-scroll-mt-8 {
  scroll-margin-top: -2rem;
}

.-scroll-mt-80 {
  scroll-margin-top: -20rem;
}

.-scroll-mt-9 {
  scroll-margin-top: -2.25rem;
}

.-scroll-mt-96 {
  scroll-margin-top: -24rem;
}

.-scroll-mt-px {
  scroll-margin-top: -1px;
}

.scroll-mb-0 {
  scroll-margin-bottom: 0;
}

.scroll-mb-0\.5 {
  scroll-margin-bottom: .125rem;
}

.scroll-mb-1 {
  scroll-margin-bottom: .25rem;
}

.scroll-mb-1\.5 {
  scroll-margin-bottom: .375rem;
}

.scroll-mb-10 {
  scroll-margin-bottom: 2.5rem;
}

.scroll-mb-11 {
  scroll-margin-bottom: 2.75rem;
}

.scroll-mb-12 {
  scroll-margin-bottom: 3rem;
}

.scroll-mb-14 {
  scroll-margin-bottom: 3.5rem;
}

.scroll-mb-16 {
  scroll-margin-bottom: 4rem;
}

.scroll-mb-2 {
  scroll-margin-bottom: .5rem;
}

.scroll-mb-2\.5 {
  scroll-margin-bottom: .625rem;
}

.scroll-mb-20 {
  scroll-margin-bottom: 5rem;
}

.scroll-mb-24 {
  scroll-margin-bottom: 6rem;
}

.scroll-mb-28 {
  scroll-margin-bottom: 7rem;
}

.scroll-mb-3 {
  scroll-margin-bottom: .75rem;
}

.scroll-mb-3\.5 {
  scroll-margin-bottom: .875rem;
}

.scroll-mb-32 {
  scroll-margin-bottom: 8rem;
}

.scroll-mb-36 {
  scroll-margin-bottom: 9rem;
}

.scroll-mb-4 {
  scroll-margin-bottom: 1rem;
}

.scroll-mb-40 {
  scroll-margin-bottom: 10rem;
}

.scroll-mb-44 {
  scroll-margin-bottom: 11rem;
}

.scroll-mb-48 {
  scroll-margin-bottom: 12rem;
}

.scroll-mb-5 {
  scroll-margin-bottom: 1.25rem;
}

.scroll-mb-52 {
  scroll-margin-bottom: 13rem;
}

.scroll-mb-56 {
  scroll-margin-bottom: 14rem;
}

.scroll-mb-6 {
  scroll-margin-bottom: 1.5rem;
}

.scroll-mb-60 {
  scroll-margin-bottom: 15rem;
}

.scroll-mb-64 {
  scroll-margin-bottom: 16rem;
}

.scroll-mb-7 {
  scroll-margin-bottom: 1.75rem;
}

.scroll-mb-72 {
  scroll-margin-bottom: 18rem;
}

.scroll-mb-8 {
  scroll-margin-bottom: 2rem;
}

.scroll-mb-80 {
  scroll-margin-bottom: 20rem;
}

.scroll-mb-9 {
  scroll-margin-bottom: 2.25rem;
}

.scroll-mb-96 {
  scroll-margin-bottom: 24rem;
}

.scroll-mb-px {
  scroll-margin-bottom: 1px;
}

.scroll-me-0 {
  scroll-margin-inline-end: 0;
}

.scroll-me-0\.5 {
  scroll-margin-inline-end: .125rem;
}

.scroll-me-1 {
  scroll-margin-inline-end: .25rem;
}

.scroll-me-1\.5 {
  scroll-margin-inline-end: .375rem;
}

.scroll-me-10 {
  scroll-margin-inline-end: 2.5rem;
}

.scroll-me-11 {
  scroll-margin-inline-end: 2.75rem;
}

.scroll-me-12 {
  scroll-margin-inline-end: 3rem;
}

.scroll-me-14 {
  scroll-margin-inline-end: 3.5rem;
}

.scroll-me-16 {
  scroll-margin-inline-end: 4rem;
}

.scroll-me-2 {
  scroll-margin-inline-end: .5rem;
}

.scroll-me-2\.5 {
  scroll-margin-inline-end: .625rem;
}

.scroll-me-20 {
  scroll-margin-inline-end: 5rem;
}

.scroll-me-24 {
  scroll-margin-inline-end: 6rem;
}

.scroll-me-28 {
  scroll-margin-inline-end: 7rem;
}

.scroll-me-3 {
  scroll-margin-inline-end: .75rem;
}

.scroll-me-3\.5 {
  scroll-margin-inline-end: .875rem;
}

.scroll-me-32 {
  scroll-margin-inline-end: 8rem;
}

.scroll-me-36 {
  scroll-margin-inline-end: 9rem;
}

.scroll-me-4 {
  scroll-margin-inline-end: 1rem;
}

.scroll-me-40 {
  scroll-margin-inline-end: 10rem;
}

.scroll-me-44 {
  scroll-margin-inline-end: 11rem;
}

.scroll-me-48 {
  scroll-margin-inline-end: 12rem;
}

.scroll-me-5 {
  scroll-margin-inline-end: 1.25rem;
}

.scroll-me-52 {
  scroll-margin-inline-end: 13rem;
}

.scroll-me-56 {
  scroll-margin-inline-end: 14rem;
}

.scroll-me-6 {
  scroll-margin-inline-end: 1.5rem;
}

.scroll-me-60 {
  scroll-margin-inline-end: 15rem;
}

.scroll-me-64 {
  scroll-margin-inline-end: 16rem;
}

.scroll-me-7 {
  scroll-margin-inline-end: 1.75rem;
}

.scroll-me-72 {
  scroll-margin-inline-end: 18rem;
}

.scroll-me-8 {
  scroll-margin-inline-end: 2rem;
}

.scroll-me-80 {
  scroll-margin-inline-end: 20rem;
}

.scroll-me-9 {
  scroll-margin-inline-end: 2.25rem;
}

.scroll-me-96 {
  scroll-margin-inline-end: 24rem;
}

.scroll-me-px {
  scroll-margin-inline-end: 1px;
}

.scroll-ml-0 {
  scroll-margin-left: 0;
}

.scroll-ml-0\.5 {
  scroll-margin-left: .125rem;
}

.scroll-ml-1 {
  scroll-margin-left: .25rem;
}

.scroll-ml-1\.5 {
  scroll-margin-left: .375rem;
}

.scroll-ml-10 {
  scroll-margin-left: 2.5rem;
}

.scroll-ml-11 {
  scroll-margin-left: 2.75rem;
}

.scroll-ml-12 {
  scroll-margin-left: 3rem;
}

.scroll-ml-14 {
  scroll-margin-left: 3.5rem;
}

.scroll-ml-16 {
  scroll-margin-left: 4rem;
}

.scroll-ml-2 {
  scroll-margin-left: .5rem;
}

.scroll-ml-2\.5 {
  scroll-margin-left: .625rem;
}

.scroll-ml-20 {
  scroll-margin-left: 5rem;
}

.scroll-ml-24 {
  scroll-margin-left: 6rem;
}

.scroll-ml-28 {
  scroll-margin-left: 7rem;
}

.scroll-ml-3 {
  scroll-margin-left: .75rem;
}

.scroll-ml-3\.5 {
  scroll-margin-left: .875rem;
}

.scroll-ml-32 {
  scroll-margin-left: 8rem;
}

.scroll-ml-36 {
  scroll-margin-left: 9rem;
}

.scroll-ml-4 {
  scroll-margin-left: 1rem;
}

.scroll-ml-40 {
  scroll-margin-left: 10rem;
}

.scroll-ml-44 {
  scroll-margin-left: 11rem;
}

.scroll-ml-48 {
  scroll-margin-left: 12rem;
}

.scroll-ml-5 {
  scroll-margin-left: 1.25rem;
}

.scroll-ml-52 {
  scroll-margin-left: 13rem;
}

.scroll-ml-56 {
  scroll-margin-left: 14rem;
}

.scroll-ml-6 {
  scroll-margin-left: 1.5rem;
}

.scroll-ml-60 {
  scroll-margin-left: 15rem;
}

.scroll-ml-64 {
  scroll-margin-left: 16rem;
}

.scroll-ml-7 {
  scroll-margin-left: 1.75rem;
}

.scroll-ml-72 {
  scroll-margin-left: 18rem;
}

.scroll-ml-8 {
  scroll-margin-left: 2rem;
}

.scroll-ml-80 {
  scroll-margin-left: 20rem;
}

.scroll-ml-9 {
  scroll-margin-left: 2.25rem;
}

.scroll-ml-96 {
  scroll-margin-left: 24rem;
}

.scroll-ml-px {
  scroll-margin-left: 1px;
}

.scroll-mr-0 {
  scroll-margin-right: 0;
}

.scroll-mr-0\.5 {
  scroll-margin-right: .125rem;
}

.scroll-mr-1 {
  scroll-margin-right: .25rem;
}

.scroll-mr-1\.5 {
  scroll-margin-right: .375rem;
}

.scroll-mr-10 {
  scroll-margin-right: 2.5rem;
}

.scroll-mr-11 {
  scroll-margin-right: 2.75rem;
}

.scroll-mr-12 {
  scroll-margin-right: 3rem;
}

.scroll-mr-14 {
  scroll-margin-right: 3.5rem;
}

.scroll-mr-16 {
  scroll-margin-right: 4rem;
}

.scroll-mr-2 {
  scroll-margin-right: .5rem;
}

.scroll-mr-2\.5 {
  scroll-margin-right: .625rem;
}

.scroll-mr-20 {
  scroll-margin-right: 5rem;
}

.scroll-mr-24 {
  scroll-margin-right: 6rem;
}

.scroll-mr-28 {
  scroll-margin-right: 7rem;
}

.scroll-mr-3 {
  scroll-margin-right: .75rem;
}

.scroll-mr-3\.5 {
  scroll-margin-right: .875rem;
}

.scroll-mr-32 {
  scroll-margin-right: 8rem;
}

.scroll-mr-36 {
  scroll-margin-right: 9rem;
}

.scroll-mr-4 {
  scroll-margin-right: 1rem;
}

.scroll-mr-40 {
  scroll-margin-right: 10rem;
}

.scroll-mr-44 {
  scroll-margin-right: 11rem;
}

.scroll-mr-48 {
  scroll-margin-right: 12rem;
}

.scroll-mr-5 {
  scroll-margin-right: 1.25rem;
}

.scroll-mr-52 {
  scroll-margin-right: 13rem;
}

.scroll-mr-56 {
  scroll-margin-right: 14rem;
}

.scroll-mr-6 {
  scroll-margin-right: 1.5rem;
}

.scroll-mr-60 {
  scroll-margin-right: 15rem;
}

.scroll-mr-64 {
  scroll-margin-right: 16rem;
}

.scroll-mr-7 {
  scroll-margin-right: 1.75rem;
}

.scroll-mr-72 {
  scroll-margin-right: 18rem;
}

.scroll-mr-8 {
  scroll-margin-right: 2rem;
}

.scroll-mr-80 {
  scroll-margin-right: 20rem;
}

.scroll-mr-9 {
  scroll-margin-right: 2.25rem;
}

.scroll-mr-96 {
  scroll-margin-right: 24rem;
}

.scroll-mr-px {
  scroll-margin-right: 1px;
}

.scroll-ms-0 {
  scroll-margin-inline-start: 0;
}

.scroll-ms-0\.5 {
  scroll-margin-inline-start: .125rem;
}

.scroll-ms-1 {
  scroll-margin-inline-start: .25rem;
}

.scroll-ms-1\.5 {
  scroll-margin-inline-start: .375rem;
}

.scroll-ms-10 {
  scroll-margin-inline-start: 2.5rem;
}

.scroll-ms-11 {
  scroll-margin-inline-start: 2.75rem;
}

.scroll-ms-12 {
  scroll-margin-inline-start: 3rem;
}

.scroll-ms-14 {
  scroll-margin-inline-start: 3.5rem;
}

.scroll-ms-16 {
  scroll-margin-inline-start: 4rem;
}

.scroll-ms-2 {
  scroll-margin-inline-start: .5rem;
}

.scroll-ms-2\.5 {
  scroll-margin-inline-start: .625rem;
}

.scroll-ms-20 {
  scroll-margin-inline-start: 5rem;
}

.scroll-ms-24 {
  scroll-margin-inline-start: 6rem;
}

.scroll-ms-28 {
  scroll-margin-inline-start: 7rem;
}

.scroll-ms-3 {
  scroll-margin-inline-start: .75rem;
}

.scroll-ms-3\.5 {
  scroll-margin-inline-start: .875rem;
}

.scroll-ms-32 {
  scroll-margin-inline-start: 8rem;
}

.scroll-ms-36 {
  scroll-margin-inline-start: 9rem;
}

.scroll-ms-4 {
  scroll-margin-inline-start: 1rem;
}

.scroll-ms-40 {
  scroll-margin-inline-start: 10rem;
}

.scroll-ms-44 {
  scroll-margin-inline-start: 11rem;
}

.scroll-ms-48 {
  scroll-margin-inline-start: 12rem;
}

.scroll-ms-5 {
  scroll-margin-inline-start: 1.25rem;
}

.scroll-ms-52 {
  scroll-margin-inline-start: 13rem;
}

.scroll-ms-56 {
  scroll-margin-inline-start: 14rem;
}

.scroll-ms-6 {
  scroll-margin-inline-start: 1.5rem;
}

.scroll-ms-60 {
  scroll-margin-inline-start: 15rem;
}

.scroll-ms-64 {
  scroll-margin-inline-start: 16rem;
}

.scroll-ms-7 {
  scroll-margin-inline-start: 1.75rem;
}

.scroll-ms-72 {
  scroll-margin-inline-start: 18rem;
}

.scroll-ms-8 {
  scroll-margin-inline-start: 2rem;
}

.scroll-ms-80 {
  scroll-margin-inline-start: 20rem;
}

.scroll-ms-9 {
  scroll-margin-inline-start: 2.25rem;
}

.scroll-ms-96 {
  scroll-margin-inline-start: 24rem;
}

.scroll-ms-px {
  scroll-margin-inline-start: 1px;
}

.scroll-mt-0 {
  scroll-margin-top: 0;
}

.scroll-mt-0\.5 {
  scroll-margin-top: .125rem;
}

.scroll-mt-1 {
  scroll-margin-top: .25rem;
}

.scroll-mt-1\.5 {
  scroll-margin-top: .375rem;
}

.scroll-mt-10 {
  scroll-margin-top: 2.5rem;
}

.scroll-mt-11 {
  scroll-margin-top: 2.75rem;
}

.scroll-mt-12 {
  scroll-margin-top: 3rem;
}

.scroll-mt-14 {
  scroll-margin-top: 3.5rem;
}

.scroll-mt-16 {
  scroll-margin-top: 4rem;
}

.scroll-mt-2 {
  scroll-margin-top: .5rem;
}

.scroll-mt-2\.5 {
  scroll-margin-top: .625rem;
}

.scroll-mt-20 {
  scroll-margin-top: 5rem;
}

.scroll-mt-24 {
  scroll-margin-top: 6rem;
}

.scroll-mt-28 {
  scroll-margin-top: 7rem;
}

.scroll-mt-3 {
  scroll-margin-top: .75rem;
}

.scroll-mt-3\.5 {
  scroll-margin-top: .875rem;
}

.scroll-mt-32 {
  scroll-margin-top: 8rem;
}

.scroll-mt-36 {
  scroll-margin-top: 9rem;
}

.scroll-mt-4 {
  scroll-margin-top: 1rem;
}

.scroll-mt-40 {
  scroll-margin-top: 10rem;
}

.scroll-mt-44 {
  scroll-margin-top: 11rem;
}

.scroll-mt-48 {
  scroll-margin-top: 12rem;
}

.scroll-mt-5 {
  scroll-margin-top: 1.25rem;
}

.scroll-mt-52 {
  scroll-margin-top: 13rem;
}

.scroll-mt-56 {
  scroll-margin-top: 14rem;
}

.scroll-mt-6 {
  scroll-margin-top: 1.5rem;
}

.scroll-mt-60 {
  scroll-margin-top: 15rem;
}

.scroll-mt-64 {
  scroll-margin-top: 16rem;
}

.scroll-mt-7 {
  scroll-margin-top: 1.75rem;
}

.scroll-mt-72 {
  scroll-margin-top: 18rem;
}

.scroll-mt-8 {
  scroll-margin-top: 2rem;
}

.scroll-mt-80 {
  scroll-margin-top: 20rem;
}

.scroll-mt-9 {
  scroll-margin-top: 2.25rem;
}

.scroll-mt-96 {
  scroll-margin-top: 24rem;
}

.scroll-mt-px {
  scroll-margin-top: 1px;
}

.scroll-p-0 {
  scroll-padding: 0;
}

.scroll-p-0\.5 {
  scroll-padding: .125rem;
}

.scroll-p-1 {
  scroll-padding: .25rem;
}

.scroll-p-1\.5 {
  scroll-padding: .375rem;
}

.scroll-p-10 {
  scroll-padding: 2.5rem;
}

.scroll-p-11 {
  scroll-padding: 2.75rem;
}

.scroll-p-12 {
  scroll-padding: 3rem;
}

.scroll-p-14 {
  scroll-padding: 3.5rem;
}

.scroll-p-16 {
  scroll-padding: 4rem;
}

.scroll-p-2 {
  scroll-padding: .5rem;
}

.scroll-p-2\.5 {
  scroll-padding: .625rem;
}

.scroll-p-20 {
  scroll-padding: 5rem;
}

.scroll-p-24 {
  scroll-padding: 6rem;
}

.scroll-p-28 {
  scroll-padding: 7rem;
}

.scroll-p-3 {
  scroll-padding: .75rem;
}

.scroll-p-3\.5 {
  scroll-padding: .875rem;
}

.scroll-p-32 {
  scroll-padding: 8rem;
}

.scroll-p-36 {
  scroll-padding: 9rem;
}

.scroll-p-4 {
  scroll-padding: 1rem;
}

.scroll-p-40 {
  scroll-padding: 10rem;
}

.scroll-p-44 {
  scroll-padding: 11rem;
}

.scroll-p-48 {
  scroll-padding: 12rem;
}

.scroll-p-5 {
  scroll-padding: 1.25rem;
}

.scroll-p-52 {
  scroll-padding: 13rem;
}

.scroll-p-56 {
  scroll-padding: 14rem;
}

.scroll-p-6 {
  scroll-padding: 1.5rem;
}

.scroll-p-60 {
  scroll-padding: 15rem;
}

.scroll-p-64 {
  scroll-padding: 16rem;
}

.scroll-p-7 {
  scroll-padding: 1.75rem;
}

.scroll-p-72 {
  scroll-padding: 18rem;
}

.scroll-p-8 {
  scroll-padding: 2rem;
}

.scroll-p-80 {
  scroll-padding: 20rem;
}

.scroll-p-9 {
  scroll-padding: 2.25rem;
}

.scroll-p-96 {
  scroll-padding: 24rem;
}

.scroll-p-px {
  scroll-padding: 1px;
}

.scroll-px-0 {
  scroll-padding-left: 0;
  scroll-padding-right: 0;
}

.scroll-px-0\.5 {
  scroll-padding-left: .125rem;
  scroll-padding-right: .125rem;
}

.scroll-px-1 {
  scroll-padding-left: .25rem;
  scroll-padding-right: .25rem;
}

.scroll-px-1\.5 {
  scroll-padding-left: .375rem;
  scroll-padding-right: .375rem;
}

.scroll-px-10 {
  scroll-padding-left: 2.5rem;
  scroll-padding-right: 2.5rem;
}

.scroll-px-11 {
  scroll-padding-left: 2.75rem;
  scroll-padding-right: 2.75rem;
}

.scroll-px-12 {
  scroll-padding-left: 3rem;
  scroll-padding-right: 3rem;
}

.scroll-px-14 {
  scroll-padding-left: 3.5rem;
  scroll-padding-right: 3.5rem;
}

.scroll-px-16 {
  scroll-padding-left: 4rem;
  scroll-padding-right: 4rem;
}

.scroll-px-2 {
  scroll-padding-left: .5rem;
  scroll-padding-right: .5rem;
}

.scroll-px-2\.5 {
  scroll-padding-left: .625rem;
  scroll-padding-right: .625rem;
}

.scroll-px-20 {
  scroll-padding-left: 5rem;
  scroll-padding-right: 5rem;
}

.scroll-px-24 {
  scroll-padding-left: 6rem;
  scroll-padding-right: 6rem;
}

.scroll-px-28 {
  scroll-padding-left: 7rem;
  scroll-padding-right: 7rem;
}

.scroll-px-3 {
  scroll-padding-left: .75rem;
  scroll-padding-right: .75rem;
}

.scroll-px-3\.5 {
  scroll-padding-left: .875rem;
  scroll-padding-right: .875rem;
}

.scroll-px-32 {
  scroll-padding-left: 8rem;
  scroll-padding-right: 8rem;
}

.scroll-px-36 {
  scroll-padding-left: 9rem;
  scroll-padding-right: 9rem;
}

.scroll-px-4 {
  scroll-padding-left: 1rem;
  scroll-padding-right: 1rem;
}

.scroll-px-40 {
  scroll-padding-left: 10rem;
  scroll-padding-right: 10rem;
}

.scroll-px-44 {
  scroll-padding-left: 11rem;
  scroll-padding-right: 11rem;
}

.scroll-px-48 {
  scroll-padding-left: 12rem;
  scroll-padding-right: 12rem;
}

.scroll-px-5 {
  scroll-padding-left: 1.25rem;
  scroll-padding-right: 1.25rem;
}

.scroll-px-52 {
  scroll-padding-left: 13rem;
  scroll-padding-right: 13rem;
}

.scroll-px-56 {
  scroll-padding-left: 14rem;
  scroll-padding-right: 14rem;
}

.scroll-px-6 {
  scroll-padding-left: 1.5rem;
  scroll-padding-right: 1.5rem;
}

.scroll-px-60 {
  scroll-padding-left: 15rem;
  scroll-padding-right: 15rem;
}

.scroll-px-64 {
  scroll-padding-left: 16rem;
  scroll-padding-right: 16rem;
}

.scroll-px-7 {
  scroll-padding-left: 1.75rem;
  scroll-padding-right: 1.75rem;
}

.scroll-px-72 {
  scroll-padding-left: 18rem;
  scroll-padding-right: 18rem;
}

.scroll-px-8 {
  scroll-padding-left: 2rem;
  scroll-padding-right: 2rem;
}

.scroll-px-80 {
  scroll-padding-left: 20rem;
  scroll-padding-right: 20rem;
}

.scroll-px-9 {
  scroll-padding-left: 2.25rem;
  scroll-padding-right: 2.25rem;
}

.scroll-px-96 {
  scroll-padding-left: 24rem;
  scroll-padding-right: 24rem;
}

.scroll-px-px {
  scroll-padding-left: 1px;
  scroll-padding-right: 1px;
}

.scroll-py-0 {
  scroll-padding-top: 0;
  scroll-padding-bottom: 0;
}

.scroll-py-0\.5 {
  scroll-padding-top: .125rem;
  scroll-padding-bottom: .125rem;
}

.scroll-py-1 {
  scroll-padding-top: .25rem;
  scroll-padding-bottom: .25rem;
}

.scroll-py-1\.5 {
  scroll-padding-top: .375rem;
  scroll-padding-bottom: .375rem;
}

.scroll-py-10 {
  scroll-padding-top: 2.5rem;
  scroll-padding-bottom: 2.5rem;
}

.scroll-py-11 {
  scroll-padding-top: 2.75rem;
  scroll-padding-bottom: 2.75rem;
}

.scroll-py-12 {
  scroll-padding-top: 3rem;
  scroll-padding-bottom: 3rem;
}

.scroll-py-14 {
  scroll-padding-top: 3.5rem;
  scroll-padding-bottom: 3.5rem;
}

.scroll-py-16 {
  scroll-padding-top: 4rem;
  scroll-padding-bottom: 4rem;
}

.scroll-py-2 {
  scroll-padding-top: .5rem;
  scroll-padding-bottom: .5rem;
}

.scroll-py-2\.5 {
  scroll-padding-top: .625rem;
  scroll-padding-bottom: .625rem;
}

.scroll-py-20 {
  scroll-padding-top: 5rem;
  scroll-padding-bottom: 5rem;
}

.scroll-py-24 {
  scroll-padding-top: 6rem;
  scroll-padding-bottom: 6rem;
}

.scroll-py-28 {
  scroll-padding-top: 7rem;
  scroll-padding-bottom: 7rem;
}

.scroll-py-3 {
  scroll-padding-top: .75rem;
  scroll-padding-bottom: .75rem;
}

.scroll-py-3\.5 {
  scroll-padding-top: .875rem;
  scroll-padding-bottom: .875rem;
}

.scroll-py-32 {
  scroll-padding-top: 8rem;
  scroll-padding-bottom: 8rem;
}

.scroll-py-36 {
  scroll-padding-top: 9rem;
  scroll-padding-bottom: 9rem;
}

.scroll-py-4 {
  scroll-padding-top: 1rem;
  scroll-padding-bottom: 1rem;
}

.scroll-py-40 {
  scroll-padding-top: 10rem;
  scroll-padding-bottom: 10rem;
}

.scroll-py-44 {
  scroll-padding-top: 11rem;
  scroll-padding-bottom: 11rem;
}

.scroll-py-48 {
  scroll-padding-top: 12rem;
  scroll-padding-bottom: 12rem;
}

.scroll-py-5 {
  scroll-padding-top: 1.25rem;
  scroll-padding-bottom: 1.25rem;
}

.scroll-py-52 {
  scroll-padding-top: 13rem;
  scroll-padding-bottom: 13rem;
}

.scroll-py-56 {
  scroll-padding-top: 14rem;
  scroll-padding-bottom: 14rem;
}

.scroll-py-6 {
  scroll-padding-top: 1.5rem;
  scroll-padding-bottom: 1.5rem;
}

.scroll-py-60 {
  scroll-padding-top: 15rem;
  scroll-padding-bottom: 15rem;
}

.scroll-py-64 {
  scroll-padding-top: 16rem;
  scroll-padding-bottom: 16rem;
}

.scroll-py-7 {
  scroll-padding-top: 1.75rem;
  scroll-padding-bottom: 1.75rem;
}

.scroll-py-72 {
  scroll-padding-top: 18rem;
  scroll-padding-bottom: 18rem;
}

.scroll-py-8 {
  scroll-padding-top: 2rem;
  scroll-padding-bottom: 2rem;
}

.scroll-py-80 {
  scroll-padding-top: 20rem;
  scroll-padding-bottom: 20rem;
}

.scroll-py-9 {
  scroll-padding-top: 2.25rem;
  scroll-padding-bottom: 2.25rem;
}

.scroll-py-96 {
  scroll-padding-top: 24rem;
  scroll-padding-bottom: 24rem;
}

.scroll-py-px {
  scroll-padding-top: 1px;
  scroll-padding-bottom: 1px;
}

.scroll-pb-0 {
  scroll-padding-bottom: 0;
}

.scroll-pb-0\.5 {
  scroll-padding-bottom: .125rem;
}

.scroll-pb-1 {
  scroll-padding-bottom: .25rem;
}

.scroll-pb-1\.5 {
  scroll-padding-bottom: .375rem;
}

.scroll-pb-10 {
  scroll-padding-bottom: 2.5rem;
}

.scroll-pb-11 {
  scroll-padding-bottom: 2.75rem;
}

.scroll-pb-12 {
  scroll-padding-bottom: 3rem;
}

.scroll-pb-14 {
  scroll-padding-bottom: 3.5rem;
}

.scroll-pb-16 {
  scroll-padding-bottom: 4rem;
}

.scroll-pb-2 {
  scroll-padding-bottom: .5rem;
}

.scroll-pb-2\.5 {
  scroll-padding-bottom: .625rem;
}

.scroll-pb-20 {
  scroll-padding-bottom: 5rem;
}

.scroll-pb-24 {
  scroll-padding-bottom: 6rem;
}

.scroll-pb-28 {
  scroll-padding-bottom: 7rem;
}

.scroll-pb-3 {
  scroll-padding-bottom: .75rem;
}

.scroll-pb-3\.5 {
  scroll-padding-bottom: .875rem;
}

.scroll-pb-32 {
  scroll-padding-bottom: 8rem;
}

.scroll-pb-36 {
  scroll-padding-bottom: 9rem;
}

.scroll-pb-4 {
  scroll-padding-bottom: 1rem;
}

.scroll-pb-40 {
  scroll-padding-bottom: 10rem;
}

.scroll-pb-44 {
  scroll-padding-bottom: 11rem;
}

.scroll-pb-48 {
  scroll-padding-bottom: 12rem;
}

.scroll-pb-5 {
  scroll-padding-bottom: 1.25rem;
}

.scroll-pb-52 {
  scroll-padding-bottom: 13rem;
}

.scroll-pb-56 {
  scroll-padding-bottom: 14rem;
}

.scroll-pb-6 {
  scroll-padding-bottom: 1.5rem;
}

.scroll-pb-60 {
  scroll-padding-bottom: 15rem;
}

.scroll-pb-64 {
  scroll-padding-bottom: 16rem;
}

.scroll-pb-7 {
  scroll-padding-bottom: 1.75rem;
}

.scroll-pb-72 {
  scroll-padding-bottom: 18rem;
}

.scroll-pb-8 {
  scroll-padding-bottom: 2rem;
}

.scroll-pb-80 {
  scroll-padding-bottom: 20rem;
}

.scroll-pb-9 {
  scroll-padding-bottom: 2.25rem;
}

.scroll-pb-96 {
  scroll-padding-bottom: 24rem;
}

.scroll-pb-px {
  scroll-padding-bottom: 1px;
}

.scroll-pe-0 {
  scroll-padding-inline-end: 0;
}

.scroll-pe-0\.5 {
  scroll-padding-inline-end: .125rem;
}

.scroll-pe-1 {
  scroll-padding-inline-end: .25rem;
}

.scroll-pe-1\.5 {
  scroll-padding-inline-end: .375rem;
}

.scroll-pe-10 {
  scroll-padding-inline-end: 2.5rem;
}

.scroll-pe-11 {
  scroll-padding-inline-end: 2.75rem;
}

.scroll-pe-12 {
  scroll-padding-inline-end: 3rem;
}

.scroll-pe-14 {
  scroll-padding-inline-end: 3.5rem;
}

.scroll-pe-16 {
  scroll-padding-inline-end: 4rem;
}

.scroll-pe-2 {
  scroll-padding-inline-end: .5rem;
}

.scroll-pe-2\.5 {
  scroll-padding-inline-end: .625rem;
}

.scroll-pe-20 {
  scroll-padding-inline-end: 5rem;
}

.scroll-pe-24 {
  scroll-padding-inline-end: 6rem;
}

.scroll-pe-28 {
  scroll-padding-inline-end: 7rem;
}

.scroll-pe-3 {
  scroll-padding-inline-end: .75rem;
}

.scroll-pe-3\.5 {
  scroll-padding-inline-end: .875rem;
}

.scroll-pe-32 {
  scroll-padding-inline-end: 8rem;
}

.scroll-pe-36 {
  scroll-padding-inline-end: 9rem;
}

.scroll-pe-4 {
  scroll-padding-inline-end: 1rem;
}

.scroll-pe-40 {
  scroll-padding-inline-end: 10rem;
}

.scroll-pe-44 {
  scroll-padding-inline-end: 11rem;
}

.scroll-pe-48 {
  scroll-padding-inline-end: 12rem;
}

.scroll-pe-5 {
  scroll-padding-inline-end: 1.25rem;
}

.scroll-pe-52 {
  scroll-padding-inline-end: 13rem;
}

.scroll-pe-56 {
  scroll-padding-inline-end: 14rem;
}

.scroll-pe-6 {
  scroll-padding-inline-end: 1.5rem;
}

.scroll-pe-60 {
  scroll-padding-inline-end: 15rem;
}

.scroll-pe-64 {
  scroll-padding-inline-end: 16rem;
}

.scroll-pe-7 {
  scroll-padding-inline-end: 1.75rem;
}

.scroll-pe-72 {
  scroll-padding-inline-end: 18rem;
}

.scroll-pe-8 {
  scroll-padding-inline-end: 2rem;
}

.scroll-pe-80 {
  scroll-padding-inline-end: 20rem;
}

.scroll-pe-9 {
  scroll-padding-inline-end: 2.25rem;
}

.scroll-pe-96 {
  scroll-padding-inline-end: 24rem;
}

.scroll-pe-px {
  scroll-padding-inline-end: 1px;
}

.scroll-pl-0 {
  scroll-padding-left: 0;
}

.scroll-pl-0\.5 {
  scroll-padding-left: .125rem;
}

.scroll-pl-1 {
  scroll-padding-left: .25rem;
}

.scroll-pl-1\.5 {
  scroll-padding-left: .375rem;
}

.scroll-pl-10 {
  scroll-padding-left: 2.5rem;
}

.scroll-pl-11 {
  scroll-padding-left: 2.75rem;
}

.scroll-pl-12 {
  scroll-padding-left: 3rem;
}

.scroll-pl-14 {
  scroll-padding-left: 3.5rem;
}

.scroll-pl-16 {
  scroll-padding-left: 4rem;
}

.scroll-pl-2 {
  scroll-padding-left: .5rem;
}

.scroll-pl-2\.5 {
  scroll-padding-left: .625rem;
}

.scroll-pl-20 {
  scroll-padding-left: 5rem;
}

.scroll-pl-24 {
  scroll-padding-left: 6rem;
}

.scroll-pl-28 {
  scroll-padding-left: 7rem;
}

.scroll-pl-3 {
  scroll-padding-left: .75rem;
}

.scroll-pl-3\.5 {
  scroll-padding-left: .875rem;
}

.scroll-pl-32 {
  scroll-padding-left: 8rem;
}

.scroll-pl-36 {
  scroll-padding-left: 9rem;
}

.scroll-pl-4 {
  scroll-padding-left: 1rem;
}

.scroll-pl-40 {
  scroll-padding-left: 10rem;
}

.scroll-pl-44 {
  scroll-padding-left: 11rem;
}

.scroll-pl-48 {
  scroll-padding-left: 12rem;
}

.scroll-pl-5 {
  scroll-padding-left: 1.25rem;
}

.scroll-pl-52 {
  scroll-padding-left: 13rem;
}

.scroll-pl-56 {
  scroll-padding-left: 14rem;
}

.scroll-pl-6 {
  scroll-padding-left: 1.5rem;
}

.scroll-pl-60 {
  scroll-padding-left: 15rem;
}

.scroll-pl-64 {
  scroll-padding-left: 16rem;
}

.scroll-pl-7 {
  scroll-padding-left: 1.75rem;
}

.scroll-pl-72 {
  scroll-padding-left: 18rem;
}

.scroll-pl-8 {
  scroll-padding-left: 2rem;
}

.scroll-pl-80 {
  scroll-padding-left: 20rem;
}

.scroll-pl-9 {
  scroll-padding-left: 2.25rem;
}

.scroll-pl-96 {
  scroll-padding-left: 24rem;
}

.scroll-pl-px {
  scroll-padding-left: 1px;
}

.scroll-pr-0 {
  scroll-padding-right: 0;
}

.scroll-pr-0\.5 {
  scroll-padding-right: .125rem;
}

.scroll-pr-1 {
  scroll-padding-right: .25rem;
}

.scroll-pr-1\.5 {
  scroll-padding-right: .375rem;
}

.scroll-pr-10 {
  scroll-padding-right: 2.5rem;
}

.scroll-pr-11 {
  scroll-padding-right: 2.75rem;
}

.scroll-pr-12 {
  scroll-padding-right: 3rem;
}

.scroll-pr-14 {
  scroll-padding-right: 3.5rem;
}

.scroll-pr-16 {
  scroll-padding-right: 4rem;
}

.scroll-pr-2 {
  scroll-padding-right: .5rem;
}

.scroll-pr-2\.5 {
  scroll-padding-right: .625rem;
}

.scroll-pr-20 {
  scroll-padding-right: 5rem;
}

.scroll-pr-24 {
  scroll-padding-right: 6rem;
}

.scroll-pr-28 {
  scroll-padding-right: 7rem;
}

.scroll-pr-3 {
  scroll-padding-right: .75rem;
}

.scroll-pr-3\.5 {
  scroll-padding-right: .875rem;
}

.scroll-pr-32 {
  scroll-padding-right: 8rem;
}

.scroll-pr-36 {
  scroll-padding-right: 9rem;
}

.scroll-pr-4 {
  scroll-padding-right: 1rem;
}

.scroll-pr-40 {
  scroll-padding-right: 10rem;
}

.scroll-pr-44 {
  scroll-padding-right: 11rem;
}

.scroll-pr-48 {
  scroll-padding-right: 12rem;
}

.scroll-pr-5 {
  scroll-padding-right: 1.25rem;
}

.scroll-pr-52 {
  scroll-padding-right: 13rem;
}

.scroll-pr-56 {
  scroll-padding-right: 14rem;
}

.scroll-pr-6 {
  scroll-padding-right: 1.5rem;
}

.scroll-pr-60 {
  scroll-padding-right: 15rem;
}

.scroll-pr-64 {
  scroll-padding-right: 16rem;
}

.scroll-pr-7 {
  scroll-padding-right: 1.75rem;
}

.scroll-pr-72 {
  scroll-padding-right: 18rem;
}

.scroll-pr-8 {
  scroll-padding-right: 2rem;
}

.scroll-pr-80 {
  scroll-padding-right: 20rem;
}

.scroll-pr-9 {
  scroll-padding-right: 2.25rem;
}

.scroll-pr-96 {
  scroll-padding-right: 24rem;
}

.scroll-pr-px {
  scroll-padding-right: 1px;
}

.scroll-ps-0 {
  scroll-padding-inline-start: 0;
}

.scroll-ps-0\.5 {
  scroll-padding-inline-start: .125rem;
}

.scroll-ps-1 {
  scroll-padding-inline-start: .25rem;
}

.scroll-ps-1\.5 {
  scroll-padding-inline-start: .375rem;
}

.scroll-ps-10 {
  scroll-padding-inline-start: 2.5rem;
}

.scroll-ps-11 {
  scroll-padding-inline-start: 2.75rem;
}

.scroll-ps-12 {
  scroll-padding-inline-start: 3rem;
}

.scroll-ps-14 {
  scroll-padding-inline-start: 3.5rem;
}

.scroll-ps-16 {
  scroll-padding-inline-start: 4rem;
}

.scroll-ps-2 {
  scroll-padding-inline-start: .5rem;
}

.scroll-ps-2\.5 {
  scroll-padding-inline-start: .625rem;
}

.scroll-ps-20 {
  scroll-padding-inline-start: 5rem;
}

.scroll-ps-24 {
  scroll-padding-inline-start: 6rem;
}

.scroll-ps-28 {
  scroll-padding-inline-start: 7rem;
}

.scroll-ps-3 {
  scroll-padding-inline-start: .75rem;
}

.scroll-ps-3\.5 {
  scroll-padding-inline-start: .875rem;
}

.scroll-ps-32 {
  scroll-padding-inline-start: 8rem;
}

.scroll-ps-36 {
  scroll-padding-inline-start: 9rem;
}

.scroll-ps-4 {
  scroll-padding-inline-start: 1rem;
}

.scroll-ps-40 {
  scroll-padding-inline-start: 10rem;
}

.scroll-ps-44 {
  scroll-padding-inline-start: 11rem;
}

.scroll-ps-48 {
  scroll-padding-inline-start: 12rem;
}

.scroll-ps-5 {
  scroll-padding-inline-start: 1.25rem;
}

.scroll-ps-52 {
  scroll-padding-inline-start: 13rem;
}

.scroll-ps-56 {
  scroll-padding-inline-start: 14rem;
}

.scroll-ps-6 {
  scroll-padding-inline-start: 1.5rem;
}

.scroll-ps-60 {
  scroll-padding-inline-start: 15rem;
}

.scroll-ps-64 {
  scroll-padding-inline-start: 16rem;
}

.scroll-ps-7 {
  scroll-padding-inline-start: 1.75rem;
}

.scroll-ps-72 {
  scroll-padding-inline-start: 18rem;
}

.scroll-ps-8 {
  scroll-padding-inline-start: 2rem;
}

.scroll-ps-80 {
  scroll-padding-inline-start: 20rem;
}

.scroll-ps-9 {
  scroll-padding-inline-start: 2.25rem;
}

.scroll-ps-96 {
  scroll-padding-inline-start: 24rem;
}

.scroll-ps-px {
  scroll-padding-inline-start: 1px;
}

.scroll-pt-0 {
  scroll-padding-top: 0;
}

.scroll-pt-0\.5 {
  scroll-padding-top: .125rem;
}

.scroll-pt-1 {
  scroll-padding-top: .25rem;
}

.scroll-pt-1\.5 {
  scroll-padding-top: .375rem;
}

.scroll-pt-10 {
  scroll-padding-top: 2.5rem;
}

.scroll-pt-11 {
  scroll-padding-top: 2.75rem;
}

.scroll-pt-12 {
  scroll-padding-top: 3rem;
}

.scroll-pt-14 {
  scroll-padding-top: 3.5rem;
}

.scroll-pt-16 {
  scroll-padding-top: 4rem;
}

.scroll-pt-2 {
  scroll-padding-top: .5rem;
}

.scroll-pt-2\.5 {
  scroll-padding-top: .625rem;
}

.scroll-pt-20 {
  scroll-padding-top: 5rem;
}

.scroll-pt-24 {
  scroll-padding-top: 6rem;
}

.scroll-pt-28 {
  scroll-padding-top: 7rem;
}

.scroll-pt-3 {
  scroll-padding-top: .75rem;
}

.scroll-pt-3\.5 {
  scroll-padding-top: .875rem;
}

.scroll-pt-32 {
  scroll-padding-top: 8rem;
}

.scroll-pt-36 {
  scroll-padding-top: 9rem;
}

.scroll-pt-4 {
  scroll-padding-top: 1rem;
}

.scroll-pt-40 {
  scroll-padding-top: 10rem;
}

.scroll-pt-44 {
  scroll-padding-top: 11rem;
}

.scroll-pt-48 {
  scroll-padding-top: 12rem;
}

.scroll-pt-5 {
  scroll-padding-top: 1.25rem;
}

.scroll-pt-52 {
  scroll-padding-top: 13rem;
}

.scroll-pt-56 {
  scroll-padding-top: 14rem;
}

.scroll-pt-6 {
  scroll-padding-top: 1.5rem;
}

.scroll-pt-60 {
  scroll-padding-top: 15rem;
}

.scroll-pt-64 {
  scroll-padding-top: 16rem;
}

.scroll-pt-7 {
  scroll-padding-top: 1.75rem;
}

.scroll-pt-72 {
  scroll-padding-top: 18rem;
}

.scroll-pt-8 {
  scroll-padding-top: 2rem;
}

.scroll-pt-80 {
  scroll-padding-top: 20rem;
}

.scroll-pt-9 {
  scroll-padding-top: 2.25rem;
}

.scroll-pt-96 {
  scroll-padding-top: 24rem;
}

.scroll-pt-px {
  scroll-padding-top: 1px;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.list-image-none {
  list-style-image: none;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.appearance-auto {
  -webkit-appearance: auto;
  appearance: auto;
}

.columns-1 {
  columns: 1;
}

.columns-10 {
  columns: 10;
}

.columns-11 {
  columns: 11;
}

.columns-12 {
  columns: 12;
}

.columns-2 {
  columns: 2;
}

.columns-2xl {
  columns: 42rem;
}

.columns-2xs {
  columns: 18rem;
}

.columns-3 {
  columns: 3;
}

.columns-3xl {
  columns: 48rem;
}

.columns-3xs {
  columns: 16rem;
}

.columns-4 {
  columns: 4;
}

.columns-4xl {
  columns: 56rem;
}

.columns-5 {
  columns: 5;
}

.columns-5xl {
  columns: 64rem;
}

.columns-6 {
  columns: 6;
}

.columns-6xl {
  columns: 72rem;
}

.columns-7 {
  columns: 7;
}

.columns-7xl {
  columns: 80rem;
}

.columns-8 {
  columns: 8;
}

.columns-9 {
  columns: 9;
}

.columns-auto {
  columns: auto;
}

.columns-lg {
  columns: 32rem;
}

.columns-md {
  columns: 28rem;
}

.columns-sm {
  columns: 24rem;
}

.columns-xl {
  columns: 36rem;
}

.columns-xs {
  columns: 20rem;
}

.break-before-auto {
  break-before: auto;
}

.break-before-avoid {
  break-before: avoid;
}

.break-before-all {
  break-before: all;
}

.break-before-avoid-page {
  break-before: avoid-page;
}

.break-before-page {
  break-before: page;
}

.break-before-left {
  break-before: left;
}

.break-before-right {
  break-before: right;
}

.break-before-column {
  break-before: column;
}

.break-inside-auto {
  break-inside: auto;
}

.break-inside-avoid {
  break-inside: avoid;
}

.break-inside-avoid-page {
  break-inside: avoid-page;
}

.break-inside-avoid-column {
  break-inside: avoid-column;
}

.break-after-auto {
  break-after: auto;
}

.break-after-avoid {
  break-after: avoid;
}

.break-after-all {
  break-after: all;
}

.break-after-avoid-page {
  break-after: avoid-page;
}

.break-after-page {
  break-after: page;
}

.break-after-left {
  break-after: left;
}

.break-after-right {
  break-after: right;
}

.break-after-column {
  break-after: column;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.auto-cols-min {
  grid-auto-columns: min-content;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-dense {
  grid-auto-flow: dense;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.auto-rows-auto {
  grid-auto-rows: auto;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.auto-rows-max {
  grid-auto-rows: max-content;
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.grid-cols-subgrid {
  grid-template-columns: subgrid;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr));
}

.grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr));
}

.grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

.grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}

.grid-rows-none {
  grid-template-rows: none;
}

.grid-rows-subgrid {
  grid-template-rows: subgrid;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.place-content-start {
  place-content: start;
}

.place-content-end {
  place-content: end;
}

.place-content-between {
  place-content: space-between;
}

.place-content-around {
  place-content: space-around;
}

.place-content-evenly {
  place-content: space-evenly;
}

.place-content-baseline {
  place-content: baseline start;
}

.place-content-stretch {
  place-content: stretch;
}

.place-items-start {
  place-items: start;
}

.place-items-end {
  place-items: end;
}

.place-items-center {
  place-items: center;
}

.place-items-baseline {
  place-items: baseline;
}

.place-items-stretch {
  place-items: stretch stretch;
}

.content-normal {
  align-content: normal;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.content-baseline {
  align-content: baseline;
}

.content-stretch {
  align-content: stretch;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-28 {
  gap: 7rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-36 {
  gap: 9rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-40 {
  gap: 10rem;
}

.gap-44 {
  gap: 11rem;
}

.gap-48 {
  gap: 12rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-52 {
  gap: 13rem;
}

.gap-56 {
  gap: 14rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-60 {
  gap: 15rem;
}

.gap-64 {
  gap: 16rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-72 {
  gap: 18rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-80 {
  gap: 20rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-96 {
  gap: 24rem;
}

.gap-px {
  gap: 1px;
}

.gap-x-0 {
  column-gap: 0;
}

.gap-x-0\.5 {
  column-gap: .125rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-1\.5 {
  column-gap: .375rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-11 {
  column-gap: 2.75rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-14 {
  column-gap: 3.5rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-2\.5 {
  column-gap: .625rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-x-28 {
  column-gap: 7rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-3\.5 {
  column-gap: .875rem;
}

.gap-x-32 {
  column-gap: 8rem;
}

.gap-x-36 {
  column-gap: 9rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-40 {
  column-gap: 10rem;
}

.gap-x-44 {
  column-gap: 11rem;
}

.gap-x-48 {
  column-gap: 12rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-52 {
  column-gap: 13rem;
}

.gap-x-56 {
  column-gap: 14rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-60 {
  column-gap: 15rem;
}

.gap-x-64 {
  column-gap: 16rem;
}

.gap-x-7 {
  column-gap: 1.75rem;
}

.gap-x-72 {
  column-gap: 18rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-x-80 {
  column-gap: 20rem;
}

.gap-x-9 {
  column-gap: 2.25rem;
}

.gap-x-96 {
  column-gap: 24rem;
}

.gap-x-px {
  column-gap: 1px;
}

.gap-y-0 {
  row-gap: 0;
}

.gap-y-0\.5 {
  row-gap: .125rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-1\.5 {
  row-gap: .375rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-11 {
  row-gap: 2.75rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-2\.5 {
  row-gap: .625rem;
}

.gap-y-20 {
  row-gap: 5rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.gap-y-28 {
  row-gap: 7rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-3\.5 {
  row-gap: .875rem;
}

.gap-y-32 {
  row-gap: 8rem;
}

.gap-y-36 {
  row-gap: 9rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-40 {
  row-gap: 10rem;
}

.gap-y-44 {
  row-gap: 11rem;
}

.gap-y-48 {
  row-gap: 12rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-52 {
  row-gap: 13rem;
}

.gap-y-56 {
  row-gap: 14rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-60 {
  row-gap: 15rem;
}

.gap-y-64 {
  row-gap: 16rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-y-72 {
  row-gap: 18rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-80 {
  row-gap: 20rem;
}

.gap-y-9 {
  row-gap: 2.25rem;
}

.gap-y-96 {
  row-gap: 24rem;
}

.gap-y-px {
  row-gap: 1px;
}

.-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0px * var(--tw-space-x-reverse));
  margin-left: calc(-0px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.125rem * var(--tw-space-x-reverse));
  margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.375rem * var(--tw-space-x-reverse));
  margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3rem * var(--tw-space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.625rem * var(--tw-space-x-reverse));
  margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5rem * var(--tw-space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-7rem * var(--tw-space-x-reverse));
  margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.875rem * var(--tw-space-x-reverse));
  margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-9rem * var(--tw-space-x-reverse));
  margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-10rem * var(--tw-space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-11rem * var(--tw-space-x-reverse));
  margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-12rem * var(--tw-space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-13rem * var(--tw-space-x-reverse));
  margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-14rem * var(--tw-space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-15rem * var(--tw-space-x-reverse));
  margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-18rem * var(--tw-space-x-reverse));
  margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-20rem * var(--tw-space-x-reverse));
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-24rem * var(--tw-space-x-reverse));
  margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0px * var(--tw-space-y-reverse));
}

.-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
}

.-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
}

.-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
}

.-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
}

.-space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
}

.-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
}

.-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
}

.-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
}

.-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
}

.-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
}

.-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
}

.-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
}

.-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
}

.-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
}

.-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
}

.-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
}

.-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
}

.-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}

.-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
}

.-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
}

.-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
}

.-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}

.-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
}

.-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
}

.-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
}

.-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
}

.-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
}

.-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
}

.-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
}

.-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
}

.-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
}

.-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
}

.-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
}

.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.375rem * var(--tw-space-x-reverse));
  margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.625rem * var(--tw-space-x-reverse));
  margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5rem * var(--tw-space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse));
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.875rem * var(--tw-space-x-reverse));
  margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(9rem * var(--tw-space-x-reverse));
  margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(11rem * var(--tw-space-x-reverse));
  margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12rem * var(--tw-space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13rem * var(--tw-space-x-reverse));
  margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(14rem * var(--tw-space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(15rem * var(--tw-space-x-reverse));
  margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(18rem * var(--tw-space-x-reverse));
  margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20rem * var(--tw-space-x-reverse));
  margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24rem * var(--tw-space-x-reverse));
  margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}

.space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(7rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
}

.space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}

.space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10rem * var(--tw-space-y-reverse));
}

.space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(11rem * var(--tw-space-y-reverse));
}

.space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13rem * var(--tw-space-y-reverse));
}

.space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15rem * var(--tw-space-y-reverse));
}

.space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}

.space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(18rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20rem * var(--tw-space-y-reverse));
}

.space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}

.space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24rem * var(--tw-space-y-reverse));
}

.space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse));
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}

.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(4px * var(--tw-divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(8px * var(--tw-divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
}

.divide-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
}

.divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1;
}

.divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1;
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed;
}

.divide-dotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted;
}

.divide-double > :not([hidden]) ~ :not([hidden]) {
  border-style: double;
}

.divide-none > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}

.divide-black > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-black);
}

.divide-current > :not([hidden]) ~ :not([hidden]) {
  border-color: currentColor;
}

.divide-danger > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-danger-300);
}

.divide-danger-100 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-danger-100);
}

.divide-danger-200 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-danger-200);
}

.divide-danger-300 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-danger-300);
}

.divide-danger-400 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-danger-400);
}

.divide-divider > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-divider);
}

.divide-gray > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-gray);
}

.divide-info > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-info-300);
}

.divide-info-100 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-info-100);
}

.divide-info-200 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-info-200);
}

.divide-info-300 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-info-300);
}

.divide-info-400 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-info-400);
}

.divide-primary > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-primary-300);
}

.divide-primary-100 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-primary-100);
}

.divide-primary-200 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-primary-200);
}

.divide-primary-250 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-primary-250);
}

.divide-primary-300 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-primary-300);
}

.divide-primary-400 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-primary-400);
}

.divide-primary-500 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-primary-500);
}

.divide-primary-600 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-primary-600);
}

.divide-success > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-success-300);
}

.divide-success-100 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-success-100);
}

.divide-success-200 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-success-200);
}

.divide-success-300 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-success-300);
}

.divide-success-400 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-success-400);
}

.divide-transparent > :not([hidden]) ~ :not([hidden]), .divide-transparent\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000;
}

.divide-transparent\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000001a;
}

.divide-transparent\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000;
}

.divide-transparent\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00000026;
}

.divide-transparent\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0003;
}

.divide-transparent\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00000040;
}

.divide-transparent\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000004d;
}

.divide-transparent\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00000059;
}

.divide-transparent\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0006;
}

.divide-transparent\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00000073;
}

.divide-transparent\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000000d;
}

.divide-transparent\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00000080;
}

.divide-transparent\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000008c;
}

.divide-transparent\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0009;
}

.divide-transparent\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000a6;
}

.divide-transparent\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000b3;
}

.divide-transparent\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000bf;
}

.divide-transparent\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000c;
}

.divide-transparent\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000d9;
}

.divide-transparent\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000e6;
}

.divide-transparent\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000f2;
}

.divide-warning > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-warning-300);
}

.divide-warning-100 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-warning-100);
}

.divide-warning-200 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-warning-200);
}

.divide-warning-300 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-warning-300);
}

.divide-warning-400 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-warning-400);
}

.divide-white > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-white);
}

.divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0;
}

.divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .1;
}

.divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
}

.divide-opacity-15 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .15;
}

.divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .2;
}

.divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .25;
}

.divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .3;
}

.divide-opacity-35 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .35;
}

.divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .4;
}

.divide-opacity-45 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .45;
}

.divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .05;
}

.divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .5;
}

.divide-opacity-55 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .55;
}

.divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .6;
}

.divide-opacity-65 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .65;
}

.divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .7;
}

.divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .75;
}

.divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .8;
}

.divide-opacity-85 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .85;
}

.divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .9;
}

.divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .95;
}

.place-self-auto {
  place-self: auto;
}

.place-self-start {
  place-self: start;
}

.place-self-end {
  place-self: end;
}

.place-self-center {
  place-self: center;
}

.place-self-stretch {
  place-self: stretch stretch;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.self-baseline {
  align-self: baseline;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-clip {
  overflow-x: clip;
}

.overflow-y-clip {
  overflow-y: clip;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overscroll-auto {
  overscroll-behavior: auto;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.overscroll-none {
  overscroll-behavior: none;
}

.overscroll-y-auto {
  overscroll-behavior-y: auto;
}

.overscroll-y-contain {
  overscroll-behavior-y: contain;
}

.overscroll-y-none {
  overscroll-behavior-y: none;
}

.overscroll-x-auto {
  overscroll-behavior-x: auto;
}

.overscroll-x-contain {
  overscroll-behavior-x: contain;
}

.overscroll-x-none {
  overscroll-behavior-x: none;
}

.scroll-auto {
  scroll-behavior: auto;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.text-clip {
  text-overflow: clip;
}

.hyphens-none {
  -webkit-hyphens: none;
  hyphens: none;
}

.hyphens-manual {
  -webkit-hyphens: manual;
  hyphens: manual;
}

.hyphens-auto {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.whitespace-break-spaces {
  white-space: break-spaces;
}

.text-wrap {
  text-wrap: wrap;
}

.text-nowrap {
  text-wrap: nowrap;
}

.text-balance {
  text-wrap: balance;
}

.text-pretty {
  text-wrap: pretty;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.break-keep {
  word-break: keep-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-b-sm {
  border-bottom-right-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-e {
  border-start-end-radius: .25rem;
  border-end-end-radius: .25rem;
}

.rounded-e-2xl {
  border-start-end-radius: 1rem;
  border-end-end-radius: 1rem;
}

.rounded-e-3xl {
  border-start-end-radius: 1.5rem;
  border-end-end-radius: 1.5rem;
}

.rounded-e-full {
  border-start-end-radius: 9999px;
  border-end-end-radius: 9999px;
}

.rounded-e-lg {
  border-start-end-radius: .5rem;
  border-end-end-radius: .5rem;
}

.rounded-e-md {
  border-start-end-radius: .375rem;
  border-end-end-radius: .375rem;
}

.rounded-e-none {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.rounded-e-sm {
  border-start-end-radius: .125rem;
  border-end-end-radius: .125rem;
}

.rounded-e-xl {
  border-start-end-radius: .75rem;
  border-end-end-radius: .75rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-sm {
  border-top-left-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.rounded-l-xl {
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-r-sm {
  border-top-right-radius: .125rem;
  border-bottom-right-radius: .125rem;
}

.rounded-r-xl {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.rounded-s {
  border-start-start-radius: .25rem;
  border-end-start-radius: .25rem;
}

.rounded-s-2xl {
  border-start-start-radius: 1rem;
  border-end-start-radius: 1rem;
}

.rounded-s-3xl {
  border-start-start-radius: 1.5rem;
  border-end-start-radius: 1.5rem;
}

.rounded-s-full {
  border-start-start-radius: 9999px;
  border-end-start-radius: 9999px;
}

.rounded-s-lg {
  border-start-start-radius: .5rem;
  border-end-start-radius: .5rem;
}

.rounded-s-md {
  border-start-start-radius: .375rem;
  border-end-start-radius: .375rem;
}

.rounded-s-none {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.rounded-s-sm {
  border-start-start-radius: .125rem;
  border-end-start-radius: .125rem;
}

.rounded-s-xl {
  border-start-start-radius: .75rem;
  border-end-start-radius: .75rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-t-sm {
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-bl {
  border-bottom-left-radius: .25rem;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-bl-sm {
  border-bottom-left-radius: .125rem;
}

.rounded-bl-xl {
  border-bottom-left-radius: .75rem;
}

.rounded-br {
  border-bottom-right-radius: .25rem;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-br-sm {
  border-bottom-right-radius: .125rem;
}

.rounded-br-xl {
  border-bottom-right-radius: .75rem;
}

.rounded-ee {
  border-end-end-radius: .25rem;
}

.rounded-ee-2xl {
  border-end-end-radius: 1rem;
}

.rounded-ee-3xl {
  border-end-end-radius: 1.5rem;
}

.rounded-ee-full {
  border-end-end-radius: 9999px;
}

.rounded-ee-lg {
  border-end-end-radius: .5rem;
}

.rounded-ee-md {
  border-end-end-radius: .375rem;
}

.rounded-ee-none {
  border-end-end-radius: 0;
}

.rounded-ee-sm {
  border-end-end-radius: .125rem;
}

.rounded-ee-xl {
  border-end-end-radius: .75rem;
}

.rounded-es {
  border-end-start-radius: .25rem;
}

.rounded-es-2xl {
  border-end-start-radius: 1rem;
}

.rounded-es-3xl {
  border-end-start-radius: 1.5rem;
}

.rounded-es-full {
  border-end-start-radius: 9999px;
}

.rounded-es-lg {
  border-end-start-radius: .5rem;
}

.rounded-es-md {
  border-end-start-radius: .375rem;
}

.rounded-es-none {
  border-end-start-radius: 0;
}

.rounded-es-sm {
  border-end-start-radius: .125rem;
}

.rounded-es-xl {
  border-end-start-radius: .75rem;
}

.rounded-se {
  border-start-end-radius: .25rem;
}

.rounded-se-2xl {
  border-start-end-radius: 1rem;
}

.rounded-se-3xl {
  border-start-end-radius: 1.5rem;
}

.rounded-se-full {
  border-start-end-radius: 9999px;
}

.rounded-se-lg {
  border-start-end-radius: .5rem;
}

.rounded-se-md {
  border-start-end-radius: .375rem;
}

.rounded-se-none {
  border-start-end-radius: 0;
}

.rounded-se-sm {
  border-start-end-radius: .125rem;
}

.rounded-se-xl {
  border-start-end-radius: .75rem;
}

.rounded-ss {
  border-start-start-radius: .25rem;
}

.rounded-ss-2xl {
  border-start-start-radius: 1rem;
}

.rounded-ss-3xl {
  border-start-start-radius: 1.5rem;
}

.rounded-ss-full {
  border-start-start-radius: 9999px;
}

.rounded-ss-lg {
  border-start-start-radius: .5rem;
}

.rounded-ss-md {
  border-start-start-radius: .375rem;
}

.rounded-ss-none {
  border-start-start-radius: 0;
}

.rounded-ss-sm {
  border-start-start-radius: .125rem;
}

.rounded-ss-xl {
  border-start-start-radius: .75rem;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem;
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.rounded-tl-full {
  border-top-left-radius: 9999px;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tl-sm {
  border-top-left-radius: .125rem;
}

.rounded-tl-xl {
  border-top-left-radius: .75rem;
}

.rounded-tr {
  border-top-right-radius: .25rem;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}

.rounded-tr-full {
  border-top-right-radius: 9999px;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.rounded-tr-sm {
  border-top-right-radius: .125rem;
}

.rounded-tr-xl {
  border-top-right-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.border-x-2 {
  border-left-width: 2px;
  border-right-width: 2px;
}

.border-x-4 {
  border-left-width: 4px;
  border-right-width: 4px;
}

.border-x-8 {
  border-left-width: 8px;
  border-right-width: 8px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-y-0 {
  border-top-width: 0;
  border-bottom-width: 0;
}

.border-y-2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.border-y-4 {
  border-top-width: 4px;
  border-bottom-width: 4px;
}

.border-y-8 {
  border-top-width: 8px;
  border-bottom-width: 8px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-b-8 {
  border-bottom-width: 8px;
}

.border-e {
  border-inline-end-width: 1px;
}

.border-e-0 {
  border-inline-end-width: 0;
}

.border-e-2 {
  border-inline-end-width: 2px;
}

.border-e-4 {
  border-inline-end-width: 4px;
}

.border-e-8 {
  border-inline-end-width: 8px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-r-8 {
  border-right-width: 8px;
}

.border-s {
  border-inline-start-width: 1px;
}

.border-s-0 {
  border-inline-start-width: 0;
}

.border-s-2 {
  border-inline-start-width: 2px;
}

.border-s-4 {
  border-inline-start-width: 4px;
}

.border-s-8 {
  border-inline-start-width: 8px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-hidden {
  border-style: hidden;
}

.border-none {
  border-style: none;
}

.border-black {
  border-color: var(--color-black);
}

.border-current {
  border-color: currentColor;
}

.border-danger {
  border-color: var(--color-danger-300);
}

.border-danger-100 {
  border-color: var(--color-danger-100);
}

.border-danger-200 {
  border-color: var(--color-danger-200);
}

.border-danger-300 {
  border-color: var(--color-danger-300);
}

.border-danger-400 {
  border-color: var(--color-danger-400);
}

.border-divider {
  border-color: var(--color-divider);
}

.border-gray {
  border-color: var(--color-gray);
}

.border-info {
  border-color: var(--color-info-300);
}

.border-info-100 {
  border-color: var(--color-info-100);
}

.border-info-200 {
  border-color: var(--color-info-200);
}

.border-info-300 {
  border-color: var(--color-info-300);
}

.border-info-400 {
  border-color: var(--color-info-400);
}

.border-primary {
  border-color: var(--color-primary-300);
}

.border-primary-100 {
  border-color: var(--color-primary-100);
}

.border-primary-200 {
  border-color: var(--color-primary-200);
}

.border-primary-250 {
  border-color: var(--color-primary-250);
}

.border-primary-300 {
  border-color: var(--color-primary-300);
}

.border-primary-400 {
  border-color: var(--color-primary-400);
}

.border-primary-500 {
  border-color: var(--color-primary-500);
}

.border-primary-600 {
  border-color: var(--color-primary-600);
}

.border-success {
  border-color: var(--color-success-300);
}

.border-success-100 {
  border-color: var(--color-success-100);
}

.border-success-200 {
  border-color: var(--color-success-200);
}

.border-success-300 {
  border-color: var(--color-success-300);
}

.border-success-400 {
  border-color: var(--color-success-400);
}

.border-transparent, .border-transparent\/0 {
  border-color: #0000;
}

.border-transparent\/10 {
  border-color: #0000001a;
}

.border-transparent\/100 {
  border-color: #000;
}

.border-transparent\/15 {
  border-color: #00000026;
}

.border-transparent\/20 {
  border-color: #0003;
}

.border-transparent\/25 {
  border-color: #00000040;
}

.border-transparent\/30 {
  border-color: #0000004d;
}

.border-transparent\/35 {
  border-color: #00000059;
}

.border-transparent\/40 {
  border-color: #0006;
}

.border-transparent\/45 {
  border-color: #00000073;
}

.border-transparent\/5 {
  border-color: #0000000d;
}

.border-transparent\/50 {
  border-color: #00000080;
}

.border-transparent\/55 {
  border-color: #0000008c;
}

.border-transparent\/60 {
  border-color: #0009;
}

.border-transparent\/65 {
  border-color: #000000a6;
}

.border-transparent\/70 {
  border-color: #000000b3;
}

.border-transparent\/75 {
  border-color: #000000bf;
}

.border-transparent\/80 {
  border-color: #000c;
}

.border-transparent\/85 {
  border-color: #000000d9;
}

.border-transparent\/90 {
  border-color: #000000e6;
}

.border-transparent\/95 {
  border-color: #000000f2;
}

.border-warning {
  border-color: var(--color-warning-300);
}

.border-warning-100 {
  border-color: var(--color-warning-100);
}

.border-warning-200 {
  border-color: var(--color-warning-200);
}

.border-warning-300 {
  border-color: var(--color-warning-300);
}

.border-warning-400 {
  border-color: var(--color-warning-400);
}

.border-white {
  border-color: var(--color-white);
}

.border-x-black {
  border-left-color: var(--color-black);
  border-right-color: var(--color-black);
}

.border-x-current {
  border-left-color: currentColor;
  border-right-color: currentColor;
}

.border-x-danger {
  border-left-color: var(--color-danger-300);
  border-right-color: var(--color-danger-300);
}

.border-x-danger-100 {
  border-left-color: var(--color-danger-100);
  border-right-color: var(--color-danger-100);
}

.border-x-danger-200 {
  border-left-color: var(--color-danger-200);
  border-right-color: var(--color-danger-200);
}

.border-x-danger-300 {
  border-left-color: var(--color-danger-300);
  border-right-color: var(--color-danger-300);
}

.border-x-danger-400 {
  border-left-color: var(--color-danger-400);
  border-right-color: var(--color-danger-400);
}

.border-x-divider {
  border-left-color: var(--color-divider);
  border-right-color: var(--color-divider);
}

.border-x-gray {
  border-left-color: var(--color-gray);
  border-right-color: var(--color-gray);
}

.border-x-info {
  border-left-color: var(--color-info-300);
  border-right-color: var(--color-info-300);
}

.border-x-info-100 {
  border-left-color: var(--color-info-100);
  border-right-color: var(--color-info-100);
}

.border-x-info-200 {
  border-left-color: var(--color-info-200);
  border-right-color: var(--color-info-200);
}

.border-x-info-300 {
  border-left-color: var(--color-info-300);
  border-right-color: var(--color-info-300);
}

.border-x-info-400 {
  border-left-color: var(--color-info-400);
  border-right-color: var(--color-info-400);
}

.border-x-primary {
  border-left-color: var(--color-primary-300);
  border-right-color: var(--color-primary-300);
}

.border-x-primary-100 {
  border-left-color: var(--color-primary-100);
  border-right-color: var(--color-primary-100);
}

.border-x-primary-200 {
  border-left-color: var(--color-primary-200);
  border-right-color: var(--color-primary-200);
}

.border-x-primary-250 {
  border-left-color: var(--color-primary-250);
  border-right-color: var(--color-primary-250);
}

.border-x-primary-300 {
  border-left-color: var(--color-primary-300);
  border-right-color: var(--color-primary-300);
}

.border-x-primary-400 {
  border-left-color: var(--color-primary-400);
  border-right-color: var(--color-primary-400);
}

.border-x-primary-500 {
  border-left-color: var(--color-primary-500);
  border-right-color: var(--color-primary-500);
}

.border-x-primary-600 {
  border-left-color: var(--color-primary-600);
  border-right-color: var(--color-primary-600);
}

.border-x-success {
  border-left-color: var(--color-success-300);
  border-right-color: var(--color-success-300);
}

.border-x-success-100 {
  border-left-color: var(--color-success-100);
  border-right-color: var(--color-success-100);
}

.border-x-success-200 {
  border-left-color: var(--color-success-200);
  border-right-color: var(--color-success-200);
}

.border-x-success-300 {
  border-left-color: var(--color-success-300);
  border-right-color: var(--color-success-300);
}

.border-x-success-400 {
  border-left-color: var(--color-success-400);
  border-right-color: var(--color-success-400);
}

.border-x-transparent, .border-x-transparent\/0 {
  border-left-color: #0000;
  border-right-color: #0000;
}

.border-x-transparent\/10 {
  border-left-color: #0000001a;
  border-right-color: #0000001a;
}

.border-x-transparent\/100 {
  border-left-color: #000;
  border-right-color: #000;
}

.border-x-transparent\/15 {
  border-left-color: #00000026;
  border-right-color: #00000026;
}

.border-x-transparent\/20 {
  border-left-color: #0003;
  border-right-color: #0003;
}

.border-x-transparent\/25 {
  border-left-color: #00000040;
  border-right-color: #00000040;
}

.border-x-transparent\/30 {
  border-left-color: #0000004d;
  border-right-color: #0000004d;
}

.border-x-transparent\/35 {
  border-left-color: #00000059;
  border-right-color: #00000059;
}

.border-x-transparent\/40 {
  border-left-color: #0006;
  border-right-color: #0006;
}

.border-x-transparent\/45 {
  border-left-color: #00000073;
  border-right-color: #00000073;
}

.border-x-transparent\/5 {
  border-left-color: #0000000d;
  border-right-color: #0000000d;
}

.border-x-transparent\/50 {
  border-left-color: #00000080;
  border-right-color: #00000080;
}

.border-x-transparent\/55 {
  border-left-color: #0000008c;
  border-right-color: #0000008c;
}

.border-x-transparent\/60 {
  border-left-color: #0009;
  border-right-color: #0009;
}

.border-x-transparent\/65 {
  border-left-color: #000000a6;
  border-right-color: #000000a6;
}

.border-x-transparent\/70 {
  border-left-color: #000000b3;
  border-right-color: #000000b3;
}

.border-x-transparent\/75 {
  border-left-color: #000000bf;
  border-right-color: #000000bf;
}

.border-x-transparent\/80 {
  border-left-color: #000c;
  border-right-color: #000c;
}

.border-x-transparent\/85 {
  border-left-color: #000000d9;
  border-right-color: #000000d9;
}

.border-x-transparent\/90 {
  border-left-color: #000000e6;
  border-right-color: #000000e6;
}

.border-x-transparent\/95 {
  border-left-color: #000000f2;
  border-right-color: #000000f2;
}

.border-x-warning {
  border-left-color: var(--color-warning-300);
  border-right-color: var(--color-warning-300);
}

.border-x-warning-100 {
  border-left-color: var(--color-warning-100);
  border-right-color: var(--color-warning-100);
}

.border-x-warning-200 {
  border-left-color: var(--color-warning-200);
  border-right-color: var(--color-warning-200);
}

.border-x-warning-300 {
  border-left-color: var(--color-warning-300);
  border-right-color: var(--color-warning-300);
}

.border-x-warning-400 {
  border-left-color: var(--color-warning-400);
  border-right-color: var(--color-warning-400);
}

.border-x-white {
  border-left-color: var(--color-white);
  border-right-color: var(--color-white);
}

.border-y-black {
  border-top-color: var(--color-black);
  border-bottom-color: var(--color-black);
}

.border-y-current {
  border-top-color: currentColor;
  border-bottom-color: currentColor;
}

.border-y-danger {
  border-top-color: var(--color-danger-300);
  border-bottom-color: var(--color-danger-300);
}

.border-y-danger-100 {
  border-top-color: var(--color-danger-100);
  border-bottom-color: var(--color-danger-100);
}

.border-y-danger-200 {
  border-top-color: var(--color-danger-200);
  border-bottom-color: var(--color-danger-200);
}

.border-y-danger-300 {
  border-top-color: var(--color-danger-300);
  border-bottom-color: var(--color-danger-300);
}

.border-y-danger-400 {
  border-top-color: var(--color-danger-400);
  border-bottom-color: var(--color-danger-400);
}

.border-y-divider {
  border-top-color: var(--color-divider);
  border-bottom-color: var(--color-divider);
}

.border-y-gray {
  border-top-color: var(--color-gray);
  border-bottom-color: var(--color-gray);
}

.border-y-info {
  border-top-color: var(--color-info-300);
  border-bottom-color: var(--color-info-300);
}

.border-y-info-100 {
  border-top-color: var(--color-info-100);
  border-bottom-color: var(--color-info-100);
}

.border-y-info-200 {
  border-top-color: var(--color-info-200);
  border-bottom-color: var(--color-info-200);
}

.border-y-info-300 {
  border-top-color: var(--color-info-300);
  border-bottom-color: var(--color-info-300);
}

.border-y-info-400 {
  border-top-color: var(--color-info-400);
  border-bottom-color: var(--color-info-400);
}

.border-y-primary {
  border-top-color: var(--color-primary-300);
  border-bottom-color: var(--color-primary-300);
}

.border-y-primary-100 {
  border-top-color: var(--color-primary-100);
  border-bottom-color: var(--color-primary-100);
}

.border-y-primary-200 {
  border-top-color: var(--color-primary-200);
  border-bottom-color: var(--color-primary-200);
}

.border-y-primary-250 {
  border-top-color: var(--color-primary-250);
  border-bottom-color: var(--color-primary-250);
}

.border-y-primary-300 {
  border-top-color: var(--color-primary-300);
  border-bottom-color: var(--color-primary-300);
}

.border-y-primary-400 {
  border-top-color: var(--color-primary-400);
  border-bottom-color: var(--color-primary-400);
}

.border-y-primary-500 {
  border-top-color: var(--color-primary-500);
  border-bottom-color: var(--color-primary-500);
}

.border-y-primary-600 {
  border-top-color: var(--color-primary-600);
  border-bottom-color: var(--color-primary-600);
}

.border-y-success {
  border-top-color: var(--color-success-300);
  border-bottom-color: var(--color-success-300);
}

.border-y-success-100 {
  border-top-color: var(--color-success-100);
  border-bottom-color: var(--color-success-100);
}

.border-y-success-200 {
  border-top-color: var(--color-success-200);
  border-bottom-color: var(--color-success-200);
}

.border-y-success-300 {
  border-top-color: var(--color-success-300);
  border-bottom-color: var(--color-success-300);
}

.border-y-success-400 {
  border-top-color: var(--color-success-400);
  border-bottom-color: var(--color-success-400);
}

.border-y-transparent, .border-y-transparent\/0 {
  border-top-color: #0000;
  border-bottom-color: #0000;
}

.border-y-transparent\/10 {
  border-top-color: #0000001a;
  border-bottom-color: #0000001a;
}

.border-y-transparent\/100 {
  border-top-color: #000;
  border-bottom-color: #000;
}

.border-y-transparent\/15 {
  border-top-color: #00000026;
  border-bottom-color: #00000026;
}

.border-y-transparent\/20 {
  border-top-color: #0003;
  border-bottom-color: #0003;
}

.border-y-transparent\/25 {
  border-top-color: #00000040;
  border-bottom-color: #00000040;
}

.border-y-transparent\/30 {
  border-top-color: #0000004d;
  border-bottom-color: #0000004d;
}

.border-y-transparent\/35 {
  border-top-color: #00000059;
  border-bottom-color: #00000059;
}

.border-y-transparent\/40 {
  border-top-color: #0006;
  border-bottom-color: #0006;
}

.border-y-transparent\/45 {
  border-top-color: #00000073;
  border-bottom-color: #00000073;
}

.border-y-transparent\/5 {
  border-top-color: #0000000d;
  border-bottom-color: #0000000d;
}

.border-y-transparent\/50 {
  border-top-color: #00000080;
  border-bottom-color: #00000080;
}

.border-y-transparent\/55 {
  border-top-color: #0000008c;
  border-bottom-color: #0000008c;
}

.border-y-transparent\/60 {
  border-top-color: #0009;
  border-bottom-color: #0009;
}

.border-y-transparent\/65 {
  border-top-color: #000000a6;
  border-bottom-color: #000000a6;
}

.border-y-transparent\/70 {
  border-top-color: #000000b3;
  border-bottom-color: #000000b3;
}

.border-y-transparent\/75 {
  border-top-color: #000000bf;
  border-bottom-color: #000000bf;
}

.border-y-transparent\/80 {
  border-top-color: #000c;
  border-bottom-color: #000c;
}

.border-y-transparent\/85 {
  border-top-color: #000000d9;
  border-bottom-color: #000000d9;
}

.border-y-transparent\/90 {
  border-top-color: #000000e6;
  border-bottom-color: #000000e6;
}

.border-y-transparent\/95 {
  border-top-color: #000000f2;
  border-bottom-color: #000000f2;
}

.border-y-warning {
  border-top-color: var(--color-warning-300);
  border-bottom-color: var(--color-warning-300);
}

.border-y-warning-100 {
  border-top-color: var(--color-warning-100);
  border-bottom-color: var(--color-warning-100);
}

.border-y-warning-200 {
  border-top-color: var(--color-warning-200);
  border-bottom-color: var(--color-warning-200);
}

.border-y-warning-300 {
  border-top-color: var(--color-warning-300);
  border-bottom-color: var(--color-warning-300);
}

.border-y-warning-400 {
  border-top-color: var(--color-warning-400);
  border-bottom-color: var(--color-warning-400);
}

.border-y-white {
  border-top-color: var(--color-white);
  border-bottom-color: var(--color-white);
}

.border-b-black {
  border-bottom-color: var(--color-black);
}

.border-b-current {
  border-bottom-color: currentColor;
}

.border-b-danger {
  border-bottom-color: var(--color-danger-300);
}

.border-b-danger-100 {
  border-bottom-color: var(--color-danger-100);
}

.border-b-danger-200 {
  border-bottom-color: var(--color-danger-200);
}

.border-b-danger-300 {
  border-bottom-color: var(--color-danger-300);
}

.border-b-danger-400 {
  border-bottom-color: var(--color-danger-400);
}

.border-b-divider {
  border-bottom-color: var(--color-divider);
}

.border-b-gray {
  border-bottom-color: var(--color-gray);
}

.border-b-info {
  border-bottom-color: var(--color-info-300);
}

.border-b-info-100 {
  border-bottom-color: var(--color-info-100);
}

.border-b-info-200 {
  border-bottom-color: var(--color-info-200);
}

.border-b-info-300 {
  border-bottom-color: var(--color-info-300);
}

.border-b-info-400 {
  border-bottom-color: var(--color-info-400);
}

.border-b-primary {
  border-bottom-color: var(--color-primary-300);
}

.border-b-primary-100 {
  border-bottom-color: var(--color-primary-100);
}

.border-b-primary-200 {
  border-bottom-color: var(--color-primary-200);
}

.border-b-primary-250 {
  border-bottom-color: var(--color-primary-250);
}

.border-b-primary-300 {
  border-bottom-color: var(--color-primary-300);
}

.border-b-primary-400 {
  border-bottom-color: var(--color-primary-400);
}

.border-b-primary-500 {
  border-bottom-color: var(--color-primary-500);
}

.border-b-primary-600 {
  border-bottom-color: var(--color-primary-600);
}

.border-b-success {
  border-bottom-color: var(--color-success-300);
}

.border-b-success-100 {
  border-bottom-color: var(--color-success-100);
}

.border-b-success-200 {
  border-bottom-color: var(--color-success-200);
}

.border-b-success-300 {
  border-bottom-color: var(--color-success-300);
}

.border-b-success-400 {
  border-bottom-color: var(--color-success-400);
}

.border-b-transparent, .border-b-transparent\/0 {
  border-bottom-color: #0000;
}

.border-b-transparent\/10 {
  border-bottom-color: #0000001a;
}

.border-b-transparent\/100 {
  border-bottom-color: #000;
}

.border-b-transparent\/15 {
  border-bottom-color: #00000026;
}

.border-b-transparent\/20 {
  border-bottom-color: #0003;
}

.border-b-transparent\/25 {
  border-bottom-color: #00000040;
}

.border-b-transparent\/30 {
  border-bottom-color: #0000004d;
}

.border-b-transparent\/35 {
  border-bottom-color: #00000059;
}

.border-b-transparent\/40 {
  border-bottom-color: #0006;
}

.border-b-transparent\/45 {
  border-bottom-color: #00000073;
}

.border-b-transparent\/5 {
  border-bottom-color: #0000000d;
}

.border-b-transparent\/50 {
  border-bottom-color: #00000080;
}

.border-b-transparent\/55 {
  border-bottom-color: #0000008c;
}

.border-b-transparent\/60 {
  border-bottom-color: #0009;
}

.border-b-transparent\/65 {
  border-bottom-color: #000000a6;
}

.border-b-transparent\/70 {
  border-bottom-color: #000000b3;
}

.border-b-transparent\/75 {
  border-bottom-color: #000000bf;
}

.border-b-transparent\/80 {
  border-bottom-color: #000c;
}

.border-b-transparent\/85 {
  border-bottom-color: #000000d9;
}

.border-b-transparent\/90 {
  border-bottom-color: #000000e6;
}

.border-b-transparent\/95 {
  border-bottom-color: #000000f2;
}

.border-b-warning {
  border-bottom-color: var(--color-warning-300);
}

.border-b-warning-100 {
  border-bottom-color: var(--color-warning-100);
}

.border-b-warning-200 {
  border-bottom-color: var(--color-warning-200);
}

.border-b-warning-300 {
  border-bottom-color: var(--color-warning-300);
}

.border-b-warning-400 {
  border-bottom-color: var(--color-warning-400);
}

.border-b-white {
  border-bottom-color: var(--color-white);
}

.border-e-black {
  border-inline-end-color: var(--color-black);
}

.border-e-current {
  border-inline-end-color: currentColor;
}

.border-e-danger {
  border-inline-end-color: var(--color-danger-300);
}

.border-e-danger-100 {
  border-inline-end-color: var(--color-danger-100);
}

.border-e-danger-200 {
  border-inline-end-color: var(--color-danger-200);
}

.border-e-danger-300 {
  border-inline-end-color: var(--color-danger-300);
}

.border-e-danger-400 {
  border-inline-end-color: var(--color-danger-400);
}

.border-e-divider {
  border-inline-end-color: var(--color-divider);
}

.border-e-gray {
  border-inline-end-color: var(--color-gray);
}

.border-e-info {
  border-inline-end-color: var(--color-info-300);
}

.border-e-info-100 {
  border-inline-end-color: var(--color-info-100);
}

.border-e-info-200 {
  border-inline-end-color: var(--color-info-200);
}

.border-e-info-300 {
  border-inline-end-color: var(--color-info-300);
}

.border-e-info-400 {
  border-inline-end-color: var(--color-info-400);
}

.border-e-primary {
  border-inline-end-color: var(--color-primary-300);
}

.border-e-primary-100 {
  border-inline-end-color: var(--color-primary-100);
}

.border-e-primary-200 {
  border-inline-end-color: var(--color-primary-200);
}

.border-e-primary-250 {
  border-inline-end-color: var(--color-primary-250);
}

.border-e-primary-300 {
  border-inline-end-color: var(--color-primary-300);
}

.border-e-primary-400 {
  border-inline-end-color: var(--color-primary-400);
}

.border-e-primary-500 {
  border-inline-end-color: var(--color-primary-500);
}

.border-e-primary-600 {
  border-inline-end-color: var(--color-primary-600);
}

.border-e-success {
  border-inline-end-color: var(--color-success-300);
}

.border-e-success-100 {
  border-inline-end-color: var(--color-success-100);
}

.border-e-success-200 {
  border-inline-end-color: var(--color-success-200);
}

.border-e-success-300 {
  border-inline-end-color: var(--color-success-300);
}

.border-e-success-400 {
  border-inline-end-color: var(--color-success-400);
}

.border-e-transparent, .border-e-transparent\/0 {
  border-inline-end-color: #0000;
}

.border-e-transparent\/10 {
  border-inline-end-color: #0000001a;
}

.border-e-transparent\/100 {
  border-inline-end-color: #000;
}

.border-e-transparent\/15 {
  border-inline-end-color: #00000026;
}

.border-e-transparent\/20 {
  border-inline-end-color: #0003;
}

.border-e-transparent\/25 {
  border-inline-end-color: #00000040;
}

.border-e-transparent\/30 {
  border-inline-end-color: #0000004d;
}

.border-e-transparent\/35 {
  border-inline-end-color: #00000059;
}

.border-e-transparent\/40 {
  border-inline-end-color: #0006;
}

.border-e-transparent\/45 {
  border-inline-end-color: #00000073;
}

.border-e-transparent\/5 {
  border-inline-end-color: #0000000d;
}

.border-e-transparent\/50 {
  border-inline-end-color: #00000080;
}

.border-e-transparent\/55 {
  border-inline-end-color: #0000008c;
}

.border-e-transparent\/60 {
  border-inline-end-color: #0009;
}

.border-e-transparent\/65 {
  border-inline-end-color: #000000a6;
}

.border-e-transparent\/70 {
  border-inline-end-color: #000000b3;
}

.border-e-transparent\/75 {
  border-inline-end-color: #000000bf;
}

.border-e-transparent\/80 {
  border-inline-end-color: #000c;
}

.border-e-transparent\/85 {
  border-inline-end-color: #000000d9;
}

.border-e-transparent\/90 {
  border-inline-end-color: #000000e6;
}

.border-e-transparent\/95 {
  border-inline-end-color: #000000f2;
}

.border-e-warning {
  border-inline-end-color: var(--color-warning-300);
}

.border-e-warning-100 {
  border-inline-end-color: var(--color-warning-100);
}

.border-e-warning-200 {
  border-inline-end-color: var(--color-warning-200);
}

.border-e-warning-300 {
  border-inline-end-color: var(--color-warning-300);
}

.border-e-warning-400 {
  border-inline-end-color: var(--color-warning-400);
}

.border-e-white {
  border-inline-end-color: var(--color-white);
}

.border-l-black {
  border-left-color: var(--color-black);
}

.border-l-current {
  border-left-color: currentColor;
}

.border-l-danger {
  border-left-color: var(--color-danger-300);
}

.border-l-danger-100 {
  border-left-color: var(--color-danger-100);
}

.border-l-danger-200 {
  border-left-color: var(--color-danger-200);
}

.border-l-danger-300 {
  border-left-color: var(--color-danger-300);
}

.border-l-danger-400 {
  border-left-color: var(--color-danger-400);
}

.border-l-divider {
  border-left-color: var(--color-divider);
}

.border-l-gray {
  border-left-color: var(--color-gray);
}

.border-l-info {
  border-left-color: var(--color-info-300);
}

.border-l-info-100 {
  border-left-color: var(--color-info-100);
}

.border-l-info-200 {
  border-left-color: var(--color-info-200);
}

.border-l-info-300 {
  border-left-color: var(--color-info-300);
}

.border-l-info-400 {
  border-left-color: var(--color-info-400);
}

.border-l-primary {
  border-left-color: var(--color-primary-300);
}

.border-l-primary-100 {
  border-left-color: var(--color-primary-100);
}

.border-l-primary-200 {
  border-left-color: var(--color-primary-200);
}

.border-l-primary-250 {
  border-left-color: var(--color-primary-250);
}

.border-l-primary-300 {
  border-left-color: var(--color-primary-300);
}

.border-l-primary-400 {
  border-left-color: var(--color-primary-400);
}

.border-l-primary-500 {
  border-left-color: var(--color-primary-500);
}

.border-l-primary-600 {
  border-left-color: var(--color-primary-600);
}

.border-l-success {
  border-left-color: var(--color-success-300);
}

.border-l-success-100 {
  border-left-color: var(--color-success-100);
}

.border-l-success-200 {
  border-left-color: var(--color-success-200);
}

.border-l-success-300 {
  border-left-color: var(--color-success-300);
}

.border-l-success-400 {
  border-left-color: var(--color-success-400);
}

.border-l-transparent, .border-l-transparent\/0 {
  border-left-color: #0000;
}

.border-l-transparent\/10 {
  border-left-color: #0000001a;
}

.border-l-transparent\/100 {
  border-left-color: #000;
}

.border-l-transparent\/15 {
  border-left-color: #00000026;
}

.border-l-transparent\/20 {
  border-left-color: #0003;
}

.border-l-transparent\/25 {
  border-left-color: #00000040;
}

.border-l-transparent\/30 {
  border-left-color: #0000004d;
}

.border-l-transparent\/35 {
  border-left-color: #00000059;
}

.border-l-transparent\/40 {
  border-left-color: #0006;
}

.border-l-transparent\/45 {
  border-left-color: #00000073;
}

.border-l-transparent\/5 {
  border-left-color: #0000000d;
}

.border-l-transparent\/50 {
  border-left-color: #00000080;
}

.border-l-transparent\/55 {
  border-left-color: #0000008c;
}

.border-l-transparent\/60 {
  border-left-color: #0009;
}

.border-l-transparent\/65 {
  border-left-color: #000000a6;
}

.border-l-transparent\/70 {
  border-left-color: #000000b3;
}

.border-l-transparent\/75 {
  border-left-color: #000000bf;
}

.border-l-transparent\/80 {
  border-left-color: #000c;
}

.border-l-transparent\/85 {
  border-left-color: #000000d9;
}

.border-l-transparent\/90 {
  border-left-color: #000000e6;
}

.border-l-transparent\/95 {
  border-left-color: #000000f2;
}

.border-l-warning {
  border-left-color: var(--color-warning-300);
}

.border-l-warning-100 {
  border-left-color: var(--color-warning-100);
}

.border-l-warning-200 {
  border-left-color: var(--color-warning-200);
}

.border-l-warning-300 {
  border-left-color: var(--color-warning-300);
}

.border-l-warning-400 {
  border-left-color: var(--color-warning-400);
}

.border-l-white {
  border-left-color: var(--color-white);
}

.border-r-black {
  border-right-color: var(--color-black);
}

.border-r-current {
  border-right-color: currentColor;
}

.border-r-danger {
  border-right-color: var(--color-danger-300);
}

.border-r-danger-100 {
  border-right-color: var(--color-danger-100);
}

.border-r-danger-200 {
  border-right-color: var(--color-danger-200);
}

.border-r-danger-300 {
  border-right-color: var(--color-danger-300);
}

.border-r-danger-400 {
  border-right-color: var(--color-danger-400);
}

.border-r-divider {
  border-right-color: var(--color-divider);
}

.border-r-gray {
  border-right-color: var(--color-gray);
}

.border-r-info {
  border-right-color: var(--color-info-300);
}

.border-r-info-100 {
  border-right-color: var(--color-info-100);
}

.border-r-info-200 {
  border-right-color: var(--color-info-200);
}

.border-r-info-300 {
  border-right-color: var(--color-info-300);
}

.border-r-info-400 {
  border-right-color: var(--color-info-400);
}

.border-r-primary {
  border-right-color: var(--color-primary-300);
}

.border-r-primary-100 {
  border-right-color: var(--color-primary-100);
}

.border-r-primary-200 {
  border-right-color: var(--color-primary-200);
}

.border-r-primary-250 {
  border-right-color: var(--color-primary-250);
}

.border-r-primary-300 {
  border-right-color: var(--color-primary-300);
}

.border-r-primary-400 {
  border-right-color: var(--color-primary-400);
}

.border-r-primary-500 {
  border-right-color: var(--color-primary-500);
}

.border-r-primary-600 {
  border-right-color: var(--color-primary-600);
}

.border-r-success {
  border-right-color: var(--color-success-300);
}

.border-r-success-100 {
  border-right-color: var(--color-success-100);
}

.border-r-success-200 {
  border-right-color: var(--color-success-200);
}

.border-r-success-300 {
  border-right-color: var(--color-success-300);
}

.border-r-success-400 {
  border-right-color: var(--color-success-400);
}

.border-r-transparent, .border-r-transparent\/0 {
  border-right-color: #0000;
}

.border-r-transparent\/10 {
  border-right-color: #0000001a;
}

.border-r-transparent\/100 {
  border-right-color: #000;
}

.border-r-transparent\/15 {
  border-right-color: #00000026;
}

.border-r-transparent\/20 {
  border-right-color: #0003;
}

.border-r-transparent\/25 {
  border-right-color: #00000040;
}

.border-r-transparent\/30 {
  border-right-color: #0000004d;
}

.border-r-transparent\/35 {
  border-right-color: #00000059;
}

.border-r-transparent\/40 {
  border-right-color: #0006;
}

.border-r-transparent\/45 {
  border-right-color: #00000073;
}

.border-r-transparent\/5 {
  border-right-color: #0000000d;
}

.border-r-transparent\/50 {
  border-right-color: #00000080;
}

.border-r-transparent\/55 {
  border-right-color: #0000008c;
}

.border-r-transparent\/60 {
  border-right-color: #0009;
}

.border-r-transparent\/65 {
  border-right-color: #000000a6;
}

.border-r-transparent\/70 {
  border-right-color: #000000b3;
}

.border-r-transparent\/75 {
  border-right-color: #000000bf;
}

.border-r-transparent\/80 {
  border-right-color: #000c;
}

.border-r-transparent\/85 {
  border-right-color: #000000d9;
}

.border-r-transparent\/90 {
  border-right-color: #000000e6;
}

.border-r-transparent\/95 {
  border-right-color: #000000f2;
}

.border-r-warning {
  border-right-color: var(--color-warning-300);
}

.border-r-warning-100 {
  border-right-color: var(--color-warning-100);
}

.border-r-warning-200 {
  border-right-color: var(--color-warning-200);
}

.border-r-warning-300 {
  border-right-color: var(--color-warning-300);
}

.border-r-warning-400 {
  border-right-color: var(--color-warning-400);
}

.border-r-white {
  border-right-color: var(--color-white);
}

.border-s-black {
  border-inline-start-color: var(--color-black);
}

.border-s-current {
  border-inline-start-color: currentColor;
}

.border-s-danger {
  border-inline-start-color: var(--color-danger-300);
}

.border-s-danger-100 {
  border-inline-start-color: var(--color-danger-100);
}

.border-s-danger-200 {
  border-inline-start-color: var(--color-danger-200);
}

.border-s-danger-300 {
  border-inline-start-color: var(--color-danger-300);
}

.border-s-danger-400 {
  border-inline-start-color: var(--color-danger-400);
}

.border-s-divider {
  border-inline-start-color: var(--color-divider);
}

.border-s-gray {
  border-inline-start-color: var(--color-gray);
}

.border-s-info {
  border-inline-start-color: var(--color-info-300);
}

.border-s-info-100 {
  border-inline-start-color: var(--color-info-100);
}

.border-s-info-200 {
  border-inline-start-color: var(--color-info-200);
}

.border-s-info-300 {
  border-inline-start-color: var(--color-info-300);
}

.border-s-info-400 {
  border-inline-start-color: var(--color-info-400);
}

.border-s-primary {
  border-inline-start-color: var(--color-primary-300);
}

.border-s-primary-100 {
  border-inline-start-color: var(--color-primary-100);
}

.border-s-primary-200 {
  border-inline-start-color: var(--color-primary-200);
}

.border-s-primary-250 {
  border-inline-start-color: var(--color-primary-250);
}

.border-s-primary-300 {
  border-inline-start-color: var(--color-primary-300);
}

.border-s-primary-400 {
  border-inline-start-color: var(--color-primary-400);
}

.border-s-primary-500 {
  border-inline-start-color: var(--color-primary-500);
}

.border-s-primary-600 {
  border-inline-start-color: var(--color-primary-600);
}

.border-s-success {
  border-inline-start-color: var(--color-success-300);
}

.border-s-success-100 {
  border-inline-start-color: var(--color-success-100);
}

.border-s-success-200 {
  border-inline-start-color: var(--color-success-200);
}

.border-s-success-300 {
  border-inline-start-color: var(--color-success-300);
}

.border-s-success-400 {
  border-inline-start-color: var(--color-success-400);
}

.border-s-transparent, .border-s-transparent\/0 {
  border-inline-start-color: #0000;
}

.border-s-transparent\/10 {
  border-inline-start-color: #0000001a;
}

.border-s-transparent\/100 {
  border-inline-start-color: #000;
}

.border-s-transparent\/15 {
  border-inline-start-color: #00000026;
}

.border-s-transparent\/20 {
  border-inline-start-color: #0003;
}

.border-s-transparent\/25 {
  border-inline-start-color: #00000040;
}

.border-s-transparent\/30 {
  border-inline-start-color: #0000004d;
}

.border-s-transparent\/35 {
  border-inline-start-color: #00000059;
}

.border-s-transparent\/40 {
  border-inline-start-color: #0006;
}

.border-s-transparent\/45 {
  border-inline-start-color: #00000073;
}

.border-s-transparent\/5 {
  border-inline-start-color: #0000000d;
}

.border-s-transparent\/50 {
  border-inline-start-color: #00000080;
}

.border-s-transparent\/55 {
  border-inline-start-color: #0000008c;
}

.border-s-transparent\/60 {
  border-inline-start-color: #0009;
}

.border-s-transparent\/65 {
  border-inline-start-color: #000000a6;
}

.border-s-transparent\/70 {
  border-inline-start-color: #000000b3;
}

.border-s-transparent\/75 {
  border-inline-start-color: #000000bf;
}

.border-s-transparent\/80 {
  border-inline-start-color: #000c;
}

.border-s-transparent\/85 {
  border-inline-start-color: #000000d9;
}

.border-s-transparent\/90 {
  border-inline-start-color: #000000e6;
}

.border-s-transparent\/95 {
  border-inline-start-color: #000000f2;
}

.border-s-warning {
  border-inline-start-color: var(--color-warning-300);
}

.border-s-warning-100 {
  border-inline-start-color: var(--color-warning-100);
}

.border-s-warning-200 {
  border-inline-start-color: var(--color-warning-200);
}

.border-s-warning-300 {
  border-inline-start-color: var(--color-warning-300);
}

.border-s-warning-400 {
  border-inline-start-color: var(--color-warning-400);
}

.border-s-white {
  border-inline-start-color: var(--color-white);
}

.border-t-black {
  border-top-color: var(--color-black);
}

.border-t-current {
  border-top-color: currentColor;
}

.border-t-danger {
  border-top-color: var(--color-danger-300);
}

.border-t-danger-100 {
  border-top-color: var(--color-danger-100);
}

.border-t-danger-200 {
  border-top-color: var(--color-danger-200);
}

.border-t-danger-300 {
  border-top-color: var(--color-danger-300);
}

.border-t-danger-400 {
  border-top-color: var(--color-danger-400);
}

.border-t-divider {
  border-top-color: var(--color-divider);
}

.border-t-gray {
  border-top-color: var(--color-gray);
}

.border-t-info {
  border-top-color: var(--color-info-300);
}

.border-t-info-100 {
  border-top-color: var(--color-info-100);
}

.border-t-info-200 {
  border-top-color: var(--color-info-200);
}

.border-t-info-300 {
  border-top-color: var(--color-info-300);
}

.border-t-info-400 {
  border-top-color: var(--color-info-400);
}

.border-t-primary {
  border-top-color: var(--color-primary-300);
}

.border-t-primary-100 {
  border-top-color: var(--color-primary-100);
}

.border-t-primary-200 {
  border-top-color: var(--color-primary-200);
}

.border-t-primary-250 {
  border-top-color: var(--color-primary-250);
}

.border-t-primary-300 {
  border-top-color: var(--color-primary-300);
}

.border-t-primary-400 {
  border-top-color: var(--color-primary-400);
}

.border-t-primary-500 {
  border-top-color: var(--color-primary-500);
}

.border-t-primary-600 {
  border-top-color: var(--color-primary-600);
}

.border-t-success {
  border-top-color: var(--color-success-300);
}

.border-t-success-100 {
  border-top-color: var(--color-success-100);
}

.border-t-success-200 {
  border-top-color: var(--color-success-200);
}

.border-t-success-300 {
  border-top-color: var(--color-success-300);
}

.border-t-success-400 {
  border-top-color: var(--color-success-400);
}

.border-t-transparent, .border-t-transparent\/0 {
  border-top-color: #0000;
}

.border-t-transparent\/10 {
  border-top-color: #0000001a;
}

.border-t-transparent\/100 {
  border-top-color: #000;
}

.border-t-transparent\/15 {
  border-top-color: #00000026;
}

.border-t-transparent\/20 {
  border-top-color: #0003;
}

.border-t-transparent\/25 {
  border-top-color: #00000040;
}

.border-t-transparent\/30 {
  border-top-color: #0000004d;
}

.border-t-transparent\/35 {
  border-top-color: #00000059;
}

.border-t-transparent\/40 {
  border-top-color: #0006;
}

.border-t-transparent\/45 {
  border-top-color: #00000073;
}

.border-t-transparent\/5 {
  border-top-color: #0000000d;
}

.border-t-transparent\/50 {
  border-top-color: #00000080;
}

.border-t-transparent\/55 {
  border-top-color: #0000008c;
}

.border-t-transparent\/60 {
  border-top-color: #0009;
}

.border-t-transparent\/65 {
  border-top-color: #000000a6;
}

.border-t-transparent\/70 {
  border-top-color: #000000b3;
}

.border-t-transparent\/75 {
  border-top-color: #000000bf;
}

.border-t-transparent\/80 {
  border-top-color: #000c;
}

.border-t-transparent\/85 {
  border-top-color: #000000d9;
}

.border-t-transparent\/90 {
  border-top-color: #000000e6;
}

.border-t-transparent\/95 {
  border-top-color: #000000f2;
}

.border-t-warning {
  border-top-color: var(--color-warning-300);
}

.border-t-warning-100 {
  border-top-color: var(--color-warning-100);
}

.border-t-warning-200 {
  border-top-color: var(--color-warning-200);
}

.border-t-warning-300 {
  border-top-color: var(--color-warning-300);
}

.border-t-warning-400 {
  border-top-color: var(--color-warning-400);
}

.border-t-white {
  border-top-color: var(--color-white);
}

.border-opacity-0 {
  --tw-border-opacity: 0;
}

.border-opacity-10 {
  --tw-border-opacity: .1;
}

.border-opacity-100 {
  --tw-border-opacity: 1;
}

.border-opacity-15 {
  --tw-border-opacity: .15;
}

.border-opacity-20 {
  --tw-border-opacity: .2;
}

.border-opacity-25 {
  --tw-border-opacity: .25;
}

.border-opacity-30 {
  --tw-border-opacity: .3;
}

.border-opacity-35 {
  --tw-border-opacity: .35;
}

.border-opacity-40 {
  --tw-border-opacity: .4;
}

.border-opacity-45 {
  --tw-border-opacity: .45;
}

.border-opacity-5 {
  --tw-border-opacity: .05;
}

.border-opacity-50 {
  --tw-border-opacity: .5;
}

.border-opacity-55 {
  --tw-border-opacity: .55;
}

.border-opacity-60 {
  --tw-border-opacity: .6;
}

.border-opacity-65 {
  --tw-border-opacity: .65;
}

.border-opacity-70 {
  --tw-border-opacity: .7;
}

.border-opacity-75 {
  --tw-border-opacity: .75;
}

.border-opacity-80 {
  --tw-border-opacity: .8;
}

.border-opacity-85 {
  --tw-border-opacity: .85;
}

.border-opacity-90 {
  --tw-border-opacity: .9;
}

.border-opacity-95 {
  --tw-border-opacity: .95;
}

.bg-black {
  background-color: var(--color-black);
}

.bg-current {
  background-color: currentColor;
}

.bg-danger {
  background-color: var(--color-danger-300);
}

.bg-danger-100 {
  background-color: var(--color-danger-100);
}

.bg-danger-200 {
  background-color: var(--color-danger-200);
}

.bg-danger-300 {
  background-color: var(--color-danger-300);
}

.bg-danger-400 {
  background-color: var(--color-danger-400);
}

.bg-divider {
  background-color: var(--color-divider);
}

.bg-gray {
  background-color: var(--color-gray);
}

.bg-info {
  background-color: var(--color-info-300);
}

.bg-info-100 {
  background-color: var(--color-info-100);
}

.bg-info-200 {
  background-color: var(--color-info-200);
}

.bg-info-300 {
  background-color: var(--color-info-300);
}

.bg-info-400 {
  background-color: var(--color-info-400);
}

.bg-primary {
  background-color: var(--color-primary-300);
}

.bg-primary-100 {
  background-color: var(--color-primary-100);
}

.bg-primary-200 {
  background-color: var(--color-primary-200);
}

.bg-primary-250 {
  background-color: var(--color-primary-250);
}

.bg-primary-300 {
  background-color: var(--color-primary-300);
}

.bg-primary-400 {
  background-color: var(--color-primary-400);
}

.bg-primary-500 {
  background-color: var(--color-primary-500);
}

.bg-primary-600 {
  background-color: var(--color-primary-600);
}

.bg-success {
  background-color: var(--color-success-300);
}

.bg-success-100 {
  background-color: var(--color-success-100);
}

.bg-success-200 {
  background-color: var(--color-success-200);
}

.bg-success-300 {
  background-color: var(--color-success-300);
}

.bg-success-400 {
  background-color: var(--color-success-400);
}

.bg-transparent, .bg-transparent\/0 {
  background-color: #0000;
}

.bg-transparent\/10 {
  background-color: #0000001a;
}

.bg-transparent\/100 {
  background-color: #000;
}

.bg-transparent\/15 {
  background-color: #00000026;
}

.bg-transparent\/20 {
  background-color: #0003;
}

.bg-transparent\/25 {
  background-color: #00000040;
}

.bg-transparent\/30 {
  background-color: #0000004d;
}

.bg-transparent\/35 {
  background-color: #00000059;
}

.bg-transparent\/40 {
  background-color: #0006;
}

.bg-transparent\/45 {
  background-color: #00000073;
}

.bg-transparent\/5 {
  background-color: #0000000d;
}

.bg-transparent\/50 {
  background-color: #00000080;
}

.bg-transparent\/55 {
  background-color: #0000008c;
}

.bg-transparent\/60 {
  background-color: #0009;
}

.bg-transparent\/65 {
  background-color: #000000a6;
}

.bg-transparent\/70 {
  background-color: #000000b3;
}

.bg-transparent\/75 {
  background-color: #000000bf;
}

.bg-transparent\/80 {
  background-color: #000c;
}

.bg-transparent\/85 {
  background-color: #000000d9;
}

.bg-transparent\/90 {
  background-color: #000000e6;
}

.bg-transparent\/95 {
  background-color: #000000f2;
}

.bg-warning {
  background-color: var(--color-warning-300);
}

.bg-warning-100 {
  background-color: var(--color-warning-100);
}

.bg-warning-200 {
  background-color: var(--color-warning-200);
}

.bg-warning-300 {
  background-color: var(--color-warning-300);
}

.bg-warning-400 {
  background-color: var(--color-warning-400);
}

.bg-white {
  background-color: var(--color-white);
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.bg-opacity-15 {
  --tw-bg-opacity: .15;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-opacity-35 {
  --tw-bg-opacity: .35;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-opacity-45 {
  --tw-bg-opacity: .45;
}

.bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-55 {
  --tw-bg-opacity: .55;
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-opacity-65 {
  --tw-bg-opacity: .65;
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-opacity-85 {
  --tw-bg-opacity: .85;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-opacity-95 {
  --tw-bg-opacity: .95;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.from-black {
  --tw-gradient-from: var(--color-black) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-current {
  --tw-gradient-from: currentColor var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-danger {
  --tw-gradient-from: var(--color-danger-300) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-danger-100 {
  --tw-gradient-from: var(--color-danger-100) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-danger-200 {
  --tw-gradient-from: var(--color-danger-200) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-danger-300 {
  --tw-gradient-from: var(--color-danger-300) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-danger-400 {
  --tw-gradient-from: var(--color-danger-400) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-divider {
  --tw-gradient-from: var(--color-divider) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray {
  --tw-gradient-from: var(--color-gray) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-info {
  --tw-gradient-from: var(--color-info-300) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-info-100 {
  --tw-gradient-from: var(--color-info-100) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-info-200 {
  --tw-gradient-from: var(--color-info-200) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-info-300 {
  --tw-gradient-from: var(--color-info-300) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-info-400 {
  --tw-gradient-from: var(--color-info-400) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary {
  --tw-gradient-from: var(--color-primary-300) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary-100 {
  --tw-gradient-from: var(--color-primary-100) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary-200 {
  --tw-gradient-from: var(--color-primary-200) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary-250 {
  --tw-gradient-from: var(--color-primary-250) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary-300 {
  --tw-gradient-from: var(--color-primary-300) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary-400 {
  --tw-gradient-from: var(--color-primary-400) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary-500 {
  --tw-gradient-from: var(--color-primary-500) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary-600 {
  --tw-gradient-from: var(--color-primary-600) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-success {
  --tw-gradient-from: var(--color-success-300) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-success-100 {
  --tw-gradient-from: var(--color-success-100) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-success-200 {
  --tw-gradient-from: var(--color-success-200) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-success-300 {
  --tw-gradient-from: var(--color-success-300) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-success-400 {
  --tw-gradient-from: var(--color-success-400) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/0 {
  --tw-gradient-from: #0000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/10 {
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/100 {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/15 {
  --tw-gradient-from: #00000026 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/20 {
  --tw-gradient-from: #0003 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/25 {
  --tw-gradient-from: #00000040 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/30 {
  --tw-gradient-from: #0000004d var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/35 {
  --tw-gradient-from: #00000059 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/40 {
  --tw-gradient-from: #0006 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/45 {
  --tw-gradient-from: #00000073 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/5 {
  --tw-gradient-from: #0000000d var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/50 {
  --tw-gradient-from: #00000080 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/55 {
  --tw-gradient-from: #0000008c var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/60 {
  --tw-gradient-from: #0009 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/65 {
  --tw-gradient-from: #000000a6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/70 {
  --tw-gradient-from: #000000b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/75 {
  --tw-gradient-from: #000000bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/80 {
  --tw-gradient-from: #000c var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/85 {
  --tw-gradient-from: #000000d9 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/90 {
  --tw-gradient-from: #000000e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/95 {
  --tw-gradient-from: #000000f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-warning {
  --tw-gradient-from: var(--color-warning-300) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-warning-100 {
  --tw-gradient-from: var(--color-warning-100) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-warning-200 {
  --tw-gradient-from: var(--color-warning-200) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-warning-300 {
  --tw-gradient-from: var(--color-warning-300) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-warning-400 {
  --tw-gradient-from: var(--color-warning-400) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: var(--color-white) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-0\% {
  --tw-gradient-from-position: 0%;
}

.from-10\% {
  --tw-gradient-from-position: 10%;
}

.from-100\% {
  --tw-gradient-from-position: 100%;
}

.from-15\% {
  --tw-gradient-from-position: 15%;
}

.from-20\% {
  --tw-gradient-from-position: 20%;
}

.from-25\% {
  --tw-gradient-from-position: 25%;
}

.from-30\% {
  --tw-gradient-from-position: 30%;
}

.from-35\% {
  --tw-gradient-from-position: 35%;
}

.from-40\% {
  --tw-gradient-from-position: 40%;
}

.from-45\% {
  --tw-gradient-from-position: 45%;
}

.from-5\% {
  --tw-gradient-from-position: 5%;
}

.from-50\% {
  --tw-gradient-from-position: 50%;
}

.from-55\% {
  --tw-gradient-from-position: 55%;
}

.from-60\% {
  --tw-gradient-from-position: 60%;
}

.from-65\% {
  --tw-gradient-from-position: 65%;
}

.from-70\% {
  --tw-gradient-from-position: 70%;
}

.from-75\% {
  --tw-gradient-from-position: 75%;
}

.from-80\% {
  --tw-gradient-from-position: 80%;
}

.from-85\% {
  --tw-gradient-from-position: 85%;
}

.from-90\% {
  --tw-gradient-from-position: 90%;
}

.from-95\% {
  --tw-gradient-from-position: 95%;
}

.via-black {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-black) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-current {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), currentColor var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-danger {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-danger-100 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-danger-200 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-danger-300 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-danger-400 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-divider {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-divider) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-gray {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-gray) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-info {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-info-100 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-info-200 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-info-300 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-info-400 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary-100 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary-200 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary-250 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-250) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary-300 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary-400 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary-500 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-500) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary-600 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-600) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-success {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-success-100 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-success-200 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-success-300 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-success-400 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/0 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0000 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/10 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0000001a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/100 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/15 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #00000026 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/20 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0003 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/25 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #00000040 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/30 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0000004d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/35 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #00000059 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/40 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0006 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/45 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #00000073 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/5 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0000000d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/50 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #00000080 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/55 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0000008c var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/60 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0009 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/65 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000000a6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/70 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000000b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/75 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000000bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/80 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000c var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/85 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000000d9 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/90 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000000e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/95 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000000f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-warning {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-warning-100 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-warning-200 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-warning-300 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-warning-400 {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-white {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--color-white) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-0\% {
  --tw-gradient-via-position: 0%;
}

.via-10\% {
  --tw-gradient-via-position: 10%;
}

.via-100\% {
  --tw-gradient-via-position: 100%;
}

.via-15\% {
  --tw-gradient-via-position: 15%;
}

.via-20\% {
  --tw-gradient-via-position: 20%;
}

.via-25\% {
  --tw-gradient-via-position: 25%;
}

.via-30\% {
  --tw-gradient-via-position: 30%;
}

.via-35\% {
  --tw-gradient-via-position: 35%;
}

.via-40\% {
  --tw-gradient-via-position: 40%;
}

.via-45\% {
  --tw-gradient-via-position: 45%;
}

.via-5\% {
  --tw-gradient-via-position: 5%;
}

.via-50\% {
  --tw-gradient-via-position: 50%;
}

.via-55\% {
  --tw-gradient-via-position: 55%;
}

.via-60\% {
  --tw-gradient-via-position: 60%;
}

.via-65\% {
  --tw-gradient-via-position: 65%;
}

.via-70\% {
  --tw-gradient-via-position: 70%;
}

.via-75\% {
  --tw-gradient-via-position: 75%;
}

.via-80\% {
  --tw-gradient-via-position: 80%;
}

.via-85\% {
  --tw-gradient-via-position: 85%;
}

.via-90\% {
  --tw-gradient-via-position: 90%;
}

.via-95\% {
  --tw-gradient-via-position: 95%;
}

.to-black {
  --tw-gradient-to: var(--color-black) var(--tw-gradient-to-position);
}

.to-current {
  --tw-gradient-to: currentColor var(--tw-gradient-to-position);
}

.to-danger {
  --tw-gradient-to: var(--color-danger-300) var(--tw-gradient-to-position);
}

.to-danger-100 {
  --tw-gradient-to: var(--color-danger-100) var(--tw-gradient-to-position);
}

.to-danger-200 {
  --tw-gradient-to: var(--color-danger-200) var(--tw-gradient-to-position);
}

.to-danger-300 {
  --tw-gradient-to: var(--color-danger-300) var(--tw-gradient-to-position);
}

.to-danger-400 {
  --tw-gradient-to: var(--color-danger-400) var(--tw-gradient-to-position);
}

.to-divider {
  --tw-gradient-to: var(--color-divider) var(--tw-gradient-to-position);
}

.to-gray {
  --tw-gradient-to: var(--color-gray) var(--tw-gradient-to-position);
}

.to-info {
  --tw-gradient-to: var(--color-info-300) var(--tw-gradient-to-position);
}

.to-info-100 {
  --tw-gradient-to: var(--color-info-100) var(--tw-gradient-to-position);
}

.to-info-200 {
  --tw-gradient-to: var(--color-info-200) var(--tw-gradient-to-position);
}

.to-info-300 {
  --tw-gradient-to: var(--color-info-300) var(--tw-gradient-to-position);
}

.to-info-400 {
  --tw-gradient-to: var(--color-info-400) var(--tw-gradient-to-position);
}

.to-primary {
  --tw-gradient-to: var(--color-primary-300) var(--tw-gradient-to-position);
}

.to-primary-100 {
  --tw-gradient-to: var(--color-primary-100) var(--tw-gradient-to-position);
}

.to-primary-200 {
  --tw-gradient-to: var(--color-primary-200) var(--tw-gradient-to-position);
}

.to-primary-250 {
  --tw-gradient-to: var(--color-primary-250) var(--tw-gradient-to-position);
}

.to-primary-300 {
  --tw-gradient-to: var(--color-primary-300) var(--tw-gradient-to-position);
}

.to-primary-400 {
  --tw-gradient-to: var(--color-primary-400) var(--tw-gradient-to-position);
}

.to-primary-500 {
  --tw-gradient-to: var(--color-primary-500) var(--tw-gradient-to-position);
}

.to-primary-600 {
  --tw-gradient-to: var(--color-primary-600) var(--tw-gradient-to-position);
}

.to-success {
  --tw-gradient-to: var(--color-success-300) var(--tw-gradient-to-position);
}

.to-success-100 {
  --tw-gradient-to: var(--color-success-100) var(--tw-gradient-to-position);
}

.to-success-200 {
  --tw-gradient-to: var(--color-success-200) var(--tw-gradient-to-position);
}

.to-success-300 {
  --tw-gradient-to: var(--color-success-300) var(--tw-gradient-to-position);
}

.to-success-400 {
  --tw-gradient-to: var(--color-success-400) var(--tw-gradient-to-position);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.to-transparent\/0 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
}

.to-transparent\/10 {
  --tw-gradient-to: #0000001a var(--tw-gradient-to-position);
}

.to-transparent\/100 {
  --tw-gradient-to: #000 var(--tw-gradient-to-position);
}

.to-transparent\/15 {
  --tw-gradient-to: #00000026 var(--tw-gradient-to-position);
}

.to-transparent\/20 {
  --tw-gradient-to: #0003 var(--tw-gradient-to-position);
}

.to-transparent\/25 {
  --tw-gradient-to: #00000040 var(--tw-gradient-to-position);
}

.to-transparent\/30 {
  --tw-gradient-to: #0000004d var(--tw-gradient-to-position);
}

.to-transparent\/35 {
  --tw-gradient-to: #00000059 var(--tw-gradient-to-position);
}

.to-transparent\/40 {
  --tw-gradient-to: #0006 var(--tw-gradient-to-position);
}

.to-transparent\/45 {
  --tw-gradient-to: #00000073 var(--tw-gradient-to-position);
}

.to-transparent\/5 {
  --tw-gradient-to: #0000000d var(--tw-gradient-to-position);
}

.to-transparent\/50 {
  --tw-gradient-to: #00000080 var(--tw-gradient-to-position);
}

.to-transparent\/55 {
  --tw-gradient-to: #0000008c var(--tw-gradient-to-position);
}

.to-transparent\/60 {
  --tw-gradient-to: #0009 var(--tw-gradient-to-position);
}

.to-transparent\/65 {
  --tw-gradient-to: #000000a6 var(--tw-gradient-to-position);
}

.to-transparent\/70 {
  --tw-gradient-to: #000000b3 var(--tw-gradient-to-position);
}

.to-transparent\/75 {
  --tw-gradient-to: #000000bf var(--tw-gradient-to-position);
}

.to-transparent\/80 {
  --tw-gradient-to: #000c var(--tw-gradient-to-position);
}

.to-transparent\/85 {
  --tw-gradient-to: #000000d9 var(--tw-gradient-to-position);
}

.to-transparent\/90 {
  --tw-gradient-to: #000000e6 var(--tw-gradient-to-position);
}

.to-transparent\/95 {
  --tw-gradient-to: #000000f2 var(--tw-gradient-to-position);
}

.to-warning {
  --tw-gradient-to: var(--color-warning-300) var(--tw-gradient-to-position);
}

.to-warning-100 {
  --tw-gradient-to: var(--color-warning-100) var(--tw-gradient-to-position);
}

.to-warning-200 {
  --tw-gradient-to: var(--color-warning-200) var(--tw-gradient-to-position);
}

.to-warning-300 {
  --tw-gradient-to: var(--color-warning-300) var(--tw-gradient-to-position);
}

.to-warning-400 {
  --tw-gradient-to: var(--color-warning-400) var(--tw-gradient-to-position);
}

.to-white {
  --tw-gradient-to: var(--color-white) var(--tw-gradient-to-position);
}

.to-0\% {
  --tw-gradient-to-position: 0%;
}

.to-10\% {
  --tw-gradient-to-position: 10%;
}

.to-100\% {
  --tw-gradient-to-position: 100%;
}

.to-15\% {
  --tw-gradient-to-position: 15%;
}

.to-20\% {
  --tw-gradient-to-position: 20%;
}

.to-25\% {
  --tw-gradient-to-position: 25%;
}

.to-30\% {
  --tw-gradient-to-position: 30%;
}

.to-35\% {
  --tw-gradient-to-position: 35%;
}

.to-40\% {
  --tw-gradient-to-position: 40%;
}

.to-45\% {
  --tw-gradient-to-position: 45%;
}

.to-5\% {
  --tw-gradient-to-position: 5%;
}

.to-50\% {
  --tw-gradient-to-position: 50%;
}

.to-55\% {
  --tw-gradient-to-position: 55%;
}

.to-60\% {
  --tw-gradient-to-position: 60%;
}

.to-65\% {
  --tw-gradient-to-position: 65%;
}

.to-70\% {
  --tw-gradient-to-position: 70%;
}

.to-75\% {
  --tw-gradient-to-position: 75%;
}

.to-80\% {
  --tw-gradient-to-position: 80%;
}

.to-85\% {
  --tw-gradient-to-position: 85%;
}

.to-90\% {
  --tw-gradient-to-position: 90%;
}

.to-95\% {
  --tw-gradient-to-position: 95%;
}

.decoration-slice {
  -webkit-box-decoration-break: slice;
  box-decoration-break: slice;
}

.decoration-clone {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.box-decoration-slice {
  -webkit-box-decoration-break: slice;
  box-decoration-break: slice;
}

.box-decoration-clone {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.bg-auto {
  background-size: auto;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-clip-content {
  background-clip: content-box;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: 0;
}

.bg-left-bottom {
  background-position: 0 100%;
}

.bg-left-top {
  background-position: 0 0;
}

.bg-right {
  background-position: 100%;
}

.bg-right-bottom {
  background-position: 100% 100%;
}

.bg-right-top {
  background-position: 100% 0;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-origin-border {
  background-origin: border-box;
}

.bg-origin-padding {
  background-origin: padding-box;
}

.bg-origin-content {
  background-origin: content-box;
}

.fill-black {
  fill: var(--color-black);
}

.fill-current {
  fill: currentColor;
}

.fill-danger {
  fill: var(--color-danger-300);
}

.fill-danger-100 {
  fill: var(--color-danger-100);
}

.fill-danger-200 {
  fill: var(--color-danger-200);
}

.fill-danger-300 {
  fill: var(--color-danger-300);
}

.fill-danger-400 {
  fill: var(--color-danger-400);
}

.fill-divider {
  fill: var(--color-divider);
}

.fill-gray {
  fill: var(--color-gray);
}

.fill-info {
  fill: var(--color-info-300);
}

.fill-info-100 {
  fill: var(--color-info-100);
}

.fill-info-200 {
  fill: var(--color-info-200);
}

.fill-info-300 {
  fill: var(--color-info-300);
}

.fill-info-400 {
  fill: var(--color-info-400);
}

.fill-none {
  fill: none;
}

.fill-primary {
  fill: var(--color-primary-300);
}

.fill-primary-100 {
  fill: var(--color-primary-100);
}

.fill-primary-200 {
  fill: var(--color-primary-200);
}

.fill-primary-250 {
  fill: var(--color-primary-250);
}

.fill-primary-300 {
  fill: var(--color-primary-300);
}

.fill-primary-400 {
  fill: var(--color-primary-400);
}

.fill-primary-500 {
  fill: var(--color-primary-500);
}

.fill-primary-600 {
  fill: var(--color-primary-600);
}

.fill-success {
  fill: var(--color-success-300);
}

.fill-success-100 {
  fill: var(--color-success-100);
}

.fill-success-200 {
  fill: var(--color-success-200);
}

.fill-success-300 {
  fill: var(--color-success-300);
}

.fill-success-400 {
  fill: var(--color-success-400);
}

.fill-transparent, .fill-transparent\/0 {
  fill: #0000;
}

.fill-transparent\/10 {
  fill: #0000001a;
}

.fill-transparent\/100 {
  fill: #000;
}

.fill-transparent\/15 {
  fill: #00000026;
}

.fill-transparent\/20 {
  fill: #0003;
}

.fill-transparent\/25 {
  fill: #00000040;
}

.fill-transparent\/30 {
  fill: #0000004d;
}

.fill-transparent\/35 {
  fill: #00000059;
}

.fill-transparent\/40 {
  fill: #0006;
}

.fill-transparent\/45 {
  fill: #00000073;
}

.fill-transparent\/5 {
  fill: #0000000d;
}

.fill-transparent\/50 {
  fill: #00000080;
}

.fill-transparent\/55 {
  fill: #0000008c;
}

.fill-transparent\/60 {
  fill: #0009;
}

.fill-transparent\/65 {
  fill: #000000a6;
}

.fill-transparent\/70 {
  fill: #000000b3;
}

.fill-transparent\/75 {
  fill: #000000bf;
}

.fill-transparent\/80 {
  fill: #000c;
}

.fill-transparent\/85 {
  fill: #000000d9;
}

.fill-transparent\/90 {
  fill: #000000e6;
}

.fill-transparent\/95 {
  fill: #000000f2;
}

.fill-warning {
  fill: var(--color-warning-300);
}

.fill-warning-100 {
  fill: var(--color-warning-100);
}

.fill-warning-200 {
  fill: var(--color-warning-200);
}

.fill-warning-300 {
  fill: var(--color-warning-300);
}

.fill-warning-400 {
  fill: var(--color-warning-400);
}

.fill-white {
  fill: var(--color-white);
}

.stroke-black {
  stroke: var(--color-black);
}

.stroke-current {
  stroke: currentColor;
}

.stroke-danger {
  stroke: var(--color-danger-300);
}

.stroke-danger-100 {
  stroke: var(--color-danger-100);
}

.stroke-danger-200 {
  stroke: var(--color-danger-200);
}

.stroke-danger-300 {
  stroke: var(--color-danger-300);
}

.stroke-danger-400 {
  stroke: var(--color-danger-400);
}

.stroke-divider {
  stroke: var(--color-divider);
}

.stroke-gray {
  stroke: var(--color-gray);
}

.stroke-info {
  stroke: var(--color-info-300);
}

.stroke-info-100 {
  stroke: var(--color-info-100);
}

.stroke-info-200 {
  stroke: var(--color-info-200);
}

.stroke-info-300 {
  stroke: var(--color-info-300);
}

.stroke-info-400 {
  stroke: var(--color-info-400);
}

.stroke-none {
  stroke: none;
}

.stroke-primary {
  stroke: var(--color-primary-300);
}

.stroke-primary-100 {
  stroke: var(--color-primary-100);
}

.stroke-primary-200 {
  stroke: var(--color-primary-200);
}

.stroke-primary-250 {
  stroke: var(--color-primary-250);
}

.stroke-primary-300 {
  stroke: var(--color-primary-300);
}

.stroke-primary-400 {
  stroke: var(--color-primary-400);
}

.stroke-primary-500 {
  stroke: var(--color-primary-500);
}

.stroke-primary-600 {
  stroke: var(--color-primary-600);
}

.stroke-success {
  stroke: var(--color-success-300);
}

.stroke-success-100 {
  stroke: var(--color-success-100);
}

.stroke-success-200 {
  stroke: var(--color-success-200);
}

.stroke-success-300 {
  stroke: var(--color-success-300);
}

.stroke-success-400 {
  stroke: var(--color-success-400);
}

.stroke-transparent, .stroke-transparent\/0 {
  stroke: #0000;
}

.stroke-transparent\/10 {
  stroke: #0000001a;
}

.stroke-transparent\/100 {
  stroke: #000;
}

.stroke-transparent\/15 {
  stroke: #00000026;
}

.stroke-transparent\/20 {
  stroke: #0003;
}

.stroke-transparent\/25 {
  stroke: #00000040;
}

.stroke-transparent\/30 {
  stroke: #0000004d;
}

.stroke-transparent\/35 {
  stroke: #00000059;
}

.stroke-transparent\/40 {
  stroke: #0006;
}

.stroke-transparent\/45 {
  stroke: #00000073;
}

.stroke-transparent\/5 {
  stroke: #0000000d;
}

.stroke-transparent\/50 {
  stroke: #00000080;
}

.stroke-transparent\/55 {
  stroke: #0000008c;
}

.stroke-transparent\/60 {
  stroke: #0009;
}

.stroke-transparent\/65 {
  stroke: #000000a6;
}

.stroke-transparent\/70 {
  stroke: #000000b3;
}

.stroke-transparent\/75 {
  stroke: #000000bf;
}

.stroke-transparent\/80 {
  stroke: #000c;
}

.stroke-transparent\/85 {
  stroke: #000000d9;
}

.stroke-transparent\/90 {
  stroke: #000000e6;
}

.stroke-transparent\/95 {
  stroke: #000000f2;
}

.stroke-warning {
  stroke: var(--color-warning-300);
}

.stroke-warning-100 {
  stroke: var(--color-warning-100);
}

.stroke-warning-200 {
  stroke: var(--color-warning-200);
}

.stroke-warning-300 {
  stroke: var(--color-warning-300);
}

.stroke-warning-400 {
  stroke: var(--color-warning-400);
}

.stroke-white {
  stroke: var(--color-white);
}

.stroke-0 {
  stroke-width: 0;
}

.stroke-1 {
  stroke-width: 1px;
}

.stroke-2 {
  stroke-width: 2px;
}

.stroke-\[32px\] {
  stroke-width: 32px;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-none {
  object-fit: none;
}

.object-scale-down {
  object-fit: scale-down;
}

.object-bottom {
  object-position: bottom;
}

.object-center {
  object-position: center;
}

.object-left {
  object-position: left;
}

.object-left-bottom {
  object-position: left bottom;
}

.object-left-top {
  object-position: left top;
}

.object-right {
  object-position: right;
}

.object-right-bottom {
  object-position: right bottom;
}

.object-right-top {
  object-position: right top;
}

.object-top {
  object-position: top;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-16 {
  padding: 4rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-28 {
  padding: 7rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-32 {
  padding: 8rem;
}

.p-36 {
  padding: 9rem;
}

.p-4 {
  padding: 1rem;
}

.p-40 {
  padding: 10rem;
}

.p-44 {
  padding: 11rem;
}

.p-48 {
  padding: 12rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-52 {
  padding: 13rem;
}

.p-56 {
  padding: 14rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-60 {
  padding: 15rem;
}

.p-64 {
  padding: 16rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-72 {
  padding: 18rem;
}

.p-8 {
  padding: 2rem;
}

.p-80 {
  padding: 20rem;
}

.p-9 {
  padding: 2.25rem;
}

.p-96 {
  padding: 24rem;
}

.p-px {
  padding: 1px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-3\.5 {
  padding-bottom: .875rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-52 {
  padding-bottom: 13rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-72 {
  padding-bottom: 18rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-80 {
  padding-bottom: 20rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pb-96 {
  padding-bottom: 24rem;
}

.pb-px {
  padding-bottom: 1px;
}

.pe-0 {
  padding-inline-end: 0;
}

.pe-0\.5 {
  padding-inline-end: .125rem;
}

.pe-1 {
  padding-inline-end: .25rem;
}

.pe-1\.5 {
  padding-inline-end: .375rem;
}

.pe-10 {
  padding-inline-end: 2.5rem;
}

.pe-11 {
  padding-inline-end: 2.75rem;
}

.pe-12 {
  padding-inline-end: 3rem;
}

.pe-14 {
  padding-inline-end: 3.5rem;
}

.pe-16 {
  padding-inline-end: 4rem;
}

.pe-2 {
  padding-inline-end: .5rem;
}

.pe-2\.5 {
  padding-inline-end: .625rem;
}

.pe-20 {
  padding-inline-end: 5rem;
}

.pe-24 {
  padding-inline-end: 6rem;
}

.pe-28 {
  padding-inline-end: 7rem;
}

.pe-3 {
  padding-inline-end: .75rem;
}

.pe-3\.5 {
  padding-inline-end: .875rem;
}

.pe-32 {
  padding-inline-end: 8rem;
}

.pe-36 {
  padding-inline-end: 9rem;
}

.pe-4 {
  padding-inline-end: 1rem;
}

.pe-40 {
  padding-inline-end: 10rem;
}

.pe-44 {
  padding-inline-end: 11rem;
}

.pe-48 {
  padding-inline-end: 12rem;
}

.pe-5 {
  padding-inline-end: 1.25rem;
}

.pe-52 {
  padding-inline-end: 13rem;
}

.pe-56 {
  padding-inline-end: 14rem;
}

.pe-6 {
  padding-inline-end: 1.5rem;
}

.pe-60 {
  padding-inline-end: 15rem;
}

.pe-64 {
  padding-inline-end: 16rem;
}

.pe-7 {
  padding-inline-end: 1.75rem;
}

.pe-72 {
  padding-inline-end: 18rem;
}

.pe-8 {
  padding-inline-end: 2rem;
}

.pe-80 {
  padding-inline-end: 20rem;
}

.pe-9 {
  padding-inline-end: 2.25rem;
}

.pe-96 {
  padding-inline-end: 24rem;
}

.pe-px {
  padding-inline-end: 1px;
}

.pl-0 {
  padding-left: 0;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pl-28 {
  padding-left: 7rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-3\.5 {
  padding-left: .875rem;
}

.pl-32 {
  padding-left: 8rem;
}

.pl-36 {
  padding-left: 9rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pl-44 {
  padding-left: 11rem;
}

.pl-48 {
  padding-left: 12rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-52 {
  padding-left: 13rem;
}

.pl-56 {
  padding-left: 14rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-60 {
  padding-left: 15rem;
}

.pl-64 {
  padding-left: 16rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-72 {
  padding-left: 18rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-80 {
  padding-left: 20rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pl-96 {
  padding-left: 24rem;
}

.pl-px {
  padding-left: 1px;
}

.pr-0 {
  padding-right: 0;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-1\.5 {
  padding-right: .375rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pr-28 {
  padding-right: 7rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pr-36 {
  padding-right: 9rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pr-44 {
  padding-right: 11rem;
}

.pr-48 {
  padding-right: 12rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-52 {
  padding-right: 13rem;
}

.pr-56 {
  padding-right: 14rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-60 {
  padding-right: 15rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-72 {
  padding-right: 18rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-80 {
  padding-right: 20rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pr-96 {
  padding-right: 24rem;
}

.pr-px {
  padding-right: 1px;
}

.ps-0 {
  padding-inline-start: 0;
}

.ps-0\.5 {
  padding-inline-start: .125rem;
}

.ps-1 {
  padding-inline-start: .25rem;
}

.ps-1\.5 {
  padding-inline-start: .375rem;
}

.ps-10 {
  padding-inline-start: 2.5rem;
}

.ps-11 {
  padding-inline-start: 2.75rem;
}

.ps-12 {
  padding-inline-start: 3rem;
}

.ps-14 {
  padding-inline-start: 3.5rem;
}

.ps-16 {
  padding-inline-start: 4rem;
}

.ps-2 {
  padding-inline-start: .5rem;
}

.ps-2\.5 {
  padding-inline-start: .625rem;
}

.ps-20 {
  padding-inline-start: 5rem;
}

.ps-24 {
  padding-inline-start: 6rem;
}

.ps-28 {
  padding-inline-start: 7rem;
}

.ps-3 {
  padding-inline-start: .75rem;
}

.ps-3\.5 {
  padding-inline-start: .875rem;
}

.ps-32 {
  padding-inline-start: 8rem;
}

.ps-36 {
  padding-inline-start: 9rem;
}

.ps-4 {
  padding-inline-start: 1rem;
}

.ps-40 {
  padding-inline-start: 10rem;
}

.ps-44 {
  padding-inline-start: 11rem;
}

.ps-48 {
  padding-inline-start: 12rem;
}

.ps-5 {
  padding-inline-start: 1.25rem;
}

.ps-52 {
  padding-inline-start: 13rem;
}

.ps-56 {
  padding-inline-start: 14rem;
}

.ps-6 {
  padding-inline-start: 1.5rem;
}

.ps-60 {
  padding-inline-start: 15rem;
}

.ps-64 {
  padding-inline-start: 16rem;
}

.ps-7 {
  padding-inline-start: 1.75rem;
}

.ps-72 {
  padding-inline-start: 18rem;
}

.ps-8 {
  padding-inline-start: 2rem;
}

.ps-80 {
  padding-inline-start: 20rem;
}

.ps-9 {
  padding-inline-start: 2.25rem;
}

.ps-96 {
  padding-inline-start: 24rem;
}

.ps-px {
  padding-inline-start: 1px;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-3\.5 {
  padding-top: .875rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-52 {
  padding-top: 13rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-60 {
  padding-top: 15rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-72 {
  padding-top: 18rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-80 {
  padding-top: 20rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pt-96 {
  padding-top: 24rem;
}

.pt-px {
  padding-top: 1px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.-indent-0 {
  text-indent: 0;
}

.-indent-0\.5 {
  text-indent: -.125rem;
}

.-indent-1 {
  text-indent: -.25rem;
}

.-indent-1\.5 {
  text-indent: -.375rem;
}

.-indent-10 {
  text-indent: -2.5rem;
}

.-indent-11 {
  text-indent: -2.75rem;
}

.-indent-12 {
  text-indent: -3rem;
}

.-indent-14 {
  text-indent: -3.5rem;
}

.-indent-16 {
  text-indent: -4rem;
}

.-indent-2 {
  text-indent: -.5rem;
}

.-indent-2\.5 {
  text-indent: -.625rem;
}

.-indent-20 {
  text-indent: -5rem;
}

.-indent-24 {
  text-indent: -6rem;
}

.-indent-28 {
  text-indent: -7rem;
}

.-indent-3 {
  text-indent: -.75rem;
}

.-indent-3\.5 {
  text-indent: -.875rem;
}

.-indent-32 {
  text-indent: -8rem;
}

.-indent-36 {
  text-indent: -9rem;
}

.-indent-4 {
  text-indent: -1rem;
}

.-indent-40 {
  text-indent: -10rem;
}

.-indent-44 {
  text-indent: -11rem;
}

.-indent-48 {
  text-indent: -12rem;
}

.-indent-5 {
  text-indent: -1.25rem;
}

.-indent-52 {
  text-indent: -13rem;
}

.-indent-56 {
  text-indent: -14rem;
}

.-indent-6 {
  text-indent: -1.5rem;
}

.-indent-60 {
  text-indent: -15rem;
}

.-indent-64 {
  text-indent: -16rem;
}

.-indent-7 {
  text-indent: -1.75rem;
}

.-indent-72 {
  text-indent: -18rem;
}

.-indent-8 {
  text-indent: -2rem;
}

.-indent-80 {
  text-indent: -20rem;
}

.-indent-9 {
  text-indent: -2.25rem;
}

.-indent-96 {
  text-indent: -24rem;
}

.-indent-px {
  text-indent: -1px;
}

.indent-0 {
  text-indent: 0;
}

.indent-0\.5 {
  text-indent: .125rem;
}

.indent-1 {
  text-indent: .25rem;
}

.indent-1\.5 {
  text-indent: .375rem;
}

.indent-10 {
  text-indent: 2.5rem;
}

.indent-11 {
  text-indent: 2.75rem;
}

.indent-12 {
  text-indent: 3rem;
}

.indent-14 {
  text-indent: 3.5rem;
}

.indent-16 {
  text-indent: 4rem;
}

.indent-2 {
  text-indent: .5rem;
}

.indent-2\.5 {
  text-indent: .625rem;
}

.indent-20 {
  text-indent: 5rem;
}

.indent-24 {
  text-indent: 6rem;
}

.indent-28 {
  text-indent: 7rem;
}

.indent-3 {
  text-indent: .75rem;
}

.indent-3\.5 {
  text-indent: .875rem;
}

.indent-32 {
  text-indent: 8rem;
}

.indent-36 {
  text-indent: 9rem;
}

.indent-4 {
  text-indent: 1rem;
}

.indent-40 {
  text-indent: 10rem;
}

.indent-44 {
  text-indent: 11rem;
}

.indent-48 {
  text-indent: 12rem;
}

.indent-5 {
  text-indent: 1.25rem;
}

.indent-52 {
  text-indent: 13rem;
}

.indent-56 {
  text-indent: 14rem;
}

.indent-6 {
  text-indent: 1.5rem;
}

.indent-60 {
  text-indent: 15rem;
}

.indent-64 {
  text-indent: 16rem;
}

.indent-7 {
  text-indent: 1.75rem;
}

.indent-72 {
  text-indent: 18rem;
}

.indent-8 {
  text-indent: 2rem;
}

.indent-80 {
  text-indent: 20rem;
}

.indent-9 {
  text-indent: 2.25rem;
}

.indent-96 {
  text-indent: 24rem;
}

.indent-px {
  text-indent: 1px;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-sub {
  vertical-align: sub;
}

.align-super {
  vertical-align: super;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-serif {
  font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-6xl {
  font-size: 4rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-sm {
  font-size: .875rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-xs {
  font-size: .75rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.normal-nums {
  font-variant-numeric: normal;
}

.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.slashed-zero {
  --tw-slashed-zero: slashed-zero;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.lining-nums {
  --tw-numeric-figure: lining-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.proportional-nums {
  --tw-numeric-spacing: proportional-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.stacked-fractions {
  --tw-numeric-fraction: stacked-fractions;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-loose {
  line-height: 2;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.-tracking-normal {
  letter-spacing: 0;
}

.-tracking-tight {
  letter-spacing: .025em;
}

.-tracking-tighter {
  letter-spacing: .05em;
}

.-tracking-wide {
  letter-spacing: -.025em;
}

.-tracking-wider {
  letter-spacing: -.05em;
}

.-tracking-widest {
  letter-spacing: -.1em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-black {
  color: var(--color-black);
}

.text-current {
  color: currentColor;
}

.text-danger {
  color: var(--color-danger-300);
}

.text-danger-100 {
  color: var(--color-danger-100);
}

.text-danger-200 {
  color: var(--color-danger-200);
}

.text-danger-300 {
  color: var(--color-danger-300);
}

.text-danger-400 {
  color: var(--color-danger-400);
}

.text-divider {
  color: var(--color-divider);
}

.text-gray {
  color: var(--color-gray);
}

.text-info {
  color: var(--color-info-300);
}

.text-info-100 {
  color: var(--color-info-100);
}

.text-info-200 {
  color: var(--color-info-200);
}

.text-info-300 {
  color: var(--color-info-300);
}

.text-info-400 {
  color: var(--color-info-400);
}

.text-primary {
  color: var(--color-primary-300);
}

.text-primary-100 {
  color: var(--color-primary-100);
}

.text-primary-200 {
  color: var(--color-primary-200);
}

.text-primary-250 {
  color: var(--color-primary-250);
}

.text-primary-300 {
  color: var(--color-primary-300);
}

.text-primary-400 {
  color: var(--color-primary-400);
}

.text-primary-500 {
  color: var(--color-primary-500);
}

.text-primary-600 {
  color: var(--color-primary-600);
}

.text-success {
  color: var(--color-success-300);
}

.text-success-100 {
  color: var(--color-success-100);
}

.text-success-200 {
  color: var(--color-success-200);
}

.text-success-300 {
  color: var(--color-success-300);
}

.text-success-400 {
  color: var(--color-success-400);
}

.text-transparent, .text-transparent\/0 {
  color: #0000;
}

.text-transparent\/10 {
  color: #0000001a;
}

.text-transparent\/100 {
  color: #000;
}

.text-transparent\/15 {
  color: #00000026;
}

.text-transparent\/20 {
  color: #0003;
}

.text-transparent\/25 {
  color: #00000040;
}

.text-transparent\/30 {
  color: #0000004d;
}

.text-transparent\/35 {
  color: #00000059;
}

.text-transparent\/40 {
  color: #0006;
}

.text-transparent\/45 {
  color: #00000073;
}

.text-transparent\/5 {
  color: #0000000d;
}

.text-transparent\/50 {
  color: #00000080;
}

.text-transparent\/55 {
  color: #0000008c;
}

.text-transparent\/60 {
  color: #0009;
}

.text-transparent\/65 {
  color: #000000a6;
}

.text-transparent\/70 {
  color: #000000b3;
}

.text-transparent\/75 {
  color: #000000bf;
}

.text-transparent\/80 {
  color: #000c;
}

.text-transparent\/85 {
  color: #000000d9;
}

.text-transparent\/90 {
  color: #000000e6;
}

.text-transparent\/95 {
  color: #000000f2;
}

.text-warning {
  color: var(--color-warning-300);
}

.text-warning-100 {
  color: var(--color-warning-100);
}

.text-warning-200 {
  color: var(--color-warning-200);
}

.text-warning-300 {
  color: var(--color-warning-300);
}

.text-warning-400 {
  color: var(--color-warning-400);
}

.text-white {
  color: var(--color-white);
}

.text-opacity-0 {
  --tw-text-opacity: 0;
}

.text-opacity-10 {
  --tw-text-opacity: .1;
}

.text-opacity-100 {
  --tw-text-opacity: 1;
}

.text-opacity-15 {
  --tw-text-opacity: .15;
}

.text-opacity-20 {
  --tw-text-opacity: .2;
}

.text-opacity-25 {
  --tw-text-opacity: .25;
}

.text-opacity-30 {
  --tw-text-opacity: .3;
}

.text-opacity-35 {
  --tw-text-opacity: .35;
}

.text-opacity-40 {
  --tw-text-opacity: .4;
}

.text-opacity-45 {
  --tw-text-opacity: .45;
}

.text-opacity-5 {
  --tw-text-opacity: .05;
}

.text-opacity-50 {
  --tw-text-opacity: .5;
}

.text-opacity-55 {
  --tw-text-opacity: .55;
}

.text-opacity-60 {
  --tw-text-opacity: .6;
}

.text-opacity-65 {
  --tw-text-opacity: .65;
}

.text-opacity-70 {
  --tw-text-opacity: .7;
}

.text-opacity-75 {
  --tw-text-opacity: .75;
}

.text-opacity-80 {
  --tw-text-opacity: .8;
}

.text-opacity-85 {
  --tw-text-opacity: .85;
}

.text-opacity-90 {
  --tw-text-opacity: .9;
}

.text-opacity-95 {
  --tw-text-opacity: .95;
}

.underline {
  text-decoration-line: underline;
}

.overline {
  text-decoration-line: overline;
}

.line-through {
  text-decoration-line: line-through;
}

.no-underline {
  text-decoration-line: none;
}

.decoration-black {
  -webkit-text-decoration-color: var(--color-black);
  text-decoration-color: var(--color-black);
}

.decoration-current {
  text-decoration-color: currentColor;
}

.decoration-danger {
  -webkit-text-decoration-color: var(--color-danger-300);
  text-decoration-color: var(--color-danger-300);
}

.decoration-danger-100 {
  -webkit-text-decoration-color: var(--color-danger-100);
  text-decoration-color: var(--color-danger-100);
}

.decoration-danger-200 {
  -webkit-text-decoration-color: var(--color-danger-200);
  text-decoration-color: var(--color-danger-200);
}

.decoration-danger-300 {
  -webkit-text-decoration-color: var(--color-danger-300);
  text-decoration-color: var(--color-danger-300);
}

.decoration-danger-400 {
  -webkit-text-decoration-color: var(--color-danger-400);
  text-decoration-color: var(--color-danger-400);
}

.decoration-divider {
  -webkit-text-decoration-color: var(--color-divider);
  text-decoration-color: var(--color-divider);
}

.decoration-gray {
  -webkit-text-decoration-color: var(--color-gray);
  text-decoration-color: var(--color-gray);
}

.decoration-info {
  -webkit-text-decoration-color: var(--color-info-300);
  text-decoration-color: var(--color-info-300);
}

.decoration-info-100 {
  -webkit-text-decoration-color: var(--color-info-100);
  text-decoration-color: var(--color-info-100);
}

.decoration-info-200 {
  -webkit-text-decoration-color: var(--color-info-200);
  text-decoration-color: var(--color-info-200);
}

.decoration-info-300 {
  -webkit-text-decoration-color: var(--color-info-300);
  text-decoration-color: var(--color-info-300);
}

.decoration-info-400 {
  -webkit-text-decoration-color: var(--color-info-400);
  text-decoration-color: var(--color-info-400);
}

.decoration-primary {
  -webkit-text-decoration-color: var(--color-primary-300);
  text-decoration-color: var(--color-primary-300);
}

.decoration-primary-100 {
  -webkit-text-decoration-color: var(--color-primary-100);
  text-decoration-color: var(--color-primary-100);
}

.decoration-primary-200 {
  -webkit-text-decoration-color: var(--color-primary-200);
  text-decoration-color: var(--color-primary-200);
}

.decoration-primary-250 {
  -webkit-text-decoration-color: var(--color-primary-250);
  text-decoration-color: var(--color-primary-250);
}

.decoration-primary-300 {
  -webkit-text-decoration-color: var(--color-primary-300);
  text-decoration-color: var(--color-primary-300);
}

.decoration-primary-400 {
  -webkit-text-decoration-color: var(--color-primary-400);
  text-decoration-color: var(--color-primary-400);
}

.decoration-primary-500 {
  -webkit-text-decoration-color: var(--color-primary-500);
  text-decoration-color: var(--color-primary-500);
}

.decoration-primary-600 {
  -webkit-text-decoration-color: var(--color-primary-600);
  text-decoration-color: var(--color-primary-600);
}

.decoration-success {
  -webkit-text-decoration-color: var(--color-success-300);
  text-decoration-color: var(--color-success-300);
}

.decoration-success-100 {
  -webkit-text-decoration-color: var(--color-success-100);
  text-decoration-color: var(--color-success-100);
}

.decoration-success-200 {
  -webkit-text-decoration-color: var(--color-success-200);
  text-decoration-color: var(--color-success-200);
}

.decoration-success-300 {
  -webkit-text-decoration-color: var(--color-success-300);
  text-decoration-color: var(--color-success-300);
}

.decoration-success-400 {
  -webkit-text-decoration-color: var(--color-success-400);
  text-decoration-color: var(--color-success-400);
}

.decoration-transparent, .decoration-transparent\/0 {
  text-decoration-color: #0000;
}

.decoration-transparent\/10 {
  text-decoration-color: #0000001a;
}

.decoration-transparent\/100 {
  text-decoration-color: #000;
}

.decoration-transparent\/15 {
  text-decoration-color: #00000026;
}

.decoration-transparent\/20 {
  text-decoration-color: #0003;
}

.decoration-transparent\/25 {
  text-decoration-color: #00000040;
}

.decoration-transparent\/30 {
  text-decoration-color: #0000004d;
}

.decoration-transparent\/35 {
  text-decoration-color: #00000059;
}

.decoration-transparent\/40 {
  text-decoration-color: #0006;
}

.decoration-transparent\/45 {
  text-decoration-color: #00000073;
}

.decoration-transparent\/5 {
  text-decoration-color: #0000000d;
}

.decoration-transparent\/50 {
  text-decoration-color: #00000080;
}

.decoration-transparent\/55 {
  text-decoration-color: #0000008c;
}

.decoration-transparent\/60 {
  text-decoration-color: #0009;
}

.decoration-transparent\/65 {
  text-decoration-color: #000000a6;
}

.decoration-transparent\/70 {
  text-decoration-color: #000000b3;
}

.decoration-transparent\/75 {
  text-decoration-color: #000000bf;
}

.decoration-transparent\/80 {
  text-decoration-color: #000c;
}

.decoration-transparent\/85 {
  text-decoration-color: #000000d9;
}

.decoration-transparent\/90 {
  text-decoration-color: #000000e6;
}

.decoration-transparent\/95 {
  text-decoration-color: #000000f2;
}

.decoration-warning {
  -webkit-text-decoration-color: var(--color-warning-300);
  text-decoration-color: var(--color-warning-300);
}

.decoration-warning-100 {
  -webkit-text-decoration-color: var(--color-warning-100);
  text-decoration-color: var(--color-warning-100);
}

.decoration-warning-200 {
  -webkit-text-decoration-color: var(--color-warning-200);
  text-decoration-color: var(--color-warning-200);
}

.decoration-warning-300 {
  -webkit-text-decoration-color: var(--color-warning-300);
  text-decoration-color: var(--color-warning-300);
}

.decoration-warning-400 {
  -webkit-text-decoration-color: var(--color-warning-400);
  text-decoration-color: var(--color-warning-400);
}

.decoration-white {
  -webkit-text-decoration-color: var(--color-white);
  text-decoration-color: var(--color-white);
}

.decoration-solid {
  text-decoration-style: solid;
}

.decoration-double {
  text-decoration-style: double;
}

.decoration-dotted {
  text-decoration-style: dotted;
}

.decoration-dashed {
  text-decoration-style: dashed;
}

.decoration-wavy {
  text-decoration-style: wavy;
}

.decoration-0 {
  text-decoration-thickness: 0;
}

.decoration-1 {
  text-decoration-thickness: 1px;
}

.decoration-2 {
  text-decoration-thickness: 2px;
}

.decoration-4 {
  text-decoration-thickness: 4px;
}

.decoration-8 {
  text-decoration-thickness: 8px;
}

.decoration-auto {
  text-decoration-thickness: auto;
}

.decoration-from-font {
  text-decoration-thickness: from-font;
}

.underline-offset-0 {
  text-underline-offset: 0px;
}

.underline-offset-1 {
  text-underline-offset: 1px;
}

.underline-offset-2 {
  text-underline-offset: 2px;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.underline-offset-8 {
  text-underline-offset: 8px;
}

.underline-offset-auto {
  text-underline-offset: auto;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.placeholder-black::placeholder {
  color: var(--color-black);
}

.placeholder-current::placeholder {
  color: currentColor;
}

.placeholder-danger::placeholder {
  color: var(--color-danger-300);
}

.placeholder-danger-100::placeholder {
  color: var(--color-danger-100);
}

.placeholder-danger-200::placeholder {
  color: var(--color-danger-200);
}

.placeholder-danger-300::placeholder {
  color: var(--color-danger-300);
}

.placeholder-danger-400::placeholder {
  color: var(--color-danger-400);
}

.placeholder-divider::placeholder {
  color: var(--color-divider);
}

.placeholder-gray::placeholder {
  color: var(--color-gray);
}

.placeholder-info::placeholder {
  color: var(--color-info-300);
}

.placeholder-info-100::placeholder {
  color: var(--color-info-100);
}

.placeholder-info-200::placeholder {
  color: var(--color-info-200);
}

.placeholder-info-300::placeholder {
  color: var(--color-info-300);
}

.placeholder-info-400::placeholder {
  color: var(--color-info-400);
}

.placeholder-primary::placeholder {
  color: var(--color-primary-300);
}

.placeholder-primary-100::placeholder {
  color: var(--color-primary-100);
}

.placeholder-primary-200::placeholder {
  color: var(--color-primary-200);
}

.placeholder-primary-250::placeholder {
  color: var(--color-primary-250);
}

.placeholder-primary-300::placeholder {
  color: var(--color-primary-300);
}

.placeholder-primary-400::placeholder {
  color: var(--color-primary-400);
}

.placeholder-primary-500::placeholder {
  color: var(--color-primary-500);
}

.placeholder-primary-600::placeholder {
  color: var(--color-primary-600);
}

.placeholder-success::placeholder {
  color: var(--color-success-300);
}

.placeholder-success-100::placeholder {
  color: var(--color-success-100);
}

.placeholder-success-200::placeholder {
  color: var(--color-success-200);
}

.placeholder-success-300::placeholder {
  color: var(--color-success-300);
}

.placeholder-success-400::placeholder {
  color: var(--color-success-400);
}

.placeholder-transparent::placeholder, .placeholder-transparent\/0::placeholder {
  color: #0000;
}

.placeholder-transparent\/10::placeholder {
  color: #0000001a;
}

.placeholder-transparent\/100::placeholder {
  color: #000;
}

.placeholder-transparent\/15::placeholder {
  color: #00000026;
}

.placeholder-transparent\/20::placeholder {
  color: #0003;
}

.placeholder-transparent\/25::placeholder {
  color: #00000040;
}

.placeholder-transparent\/30::placeholder {
  color: #0000004d;
}

.placeholder-transparent\/35::placeholder {
  color: #00000059;
}

.placeholder-transparent\/40::placeholder {
  color: #0006;
}

.placeholder-transparent\/45::placeholder {
  color: #00000073;
}

.placeholder-transparent\/5::placeholder {
  color: #0000000d;
}

.placeholder-transparent\/50::placeholder {
  color: #00000080;
}

.placeholder-transparent\/55::placeholder {
  color: #0000008c;
}

.placeholder-transparent\/60::placeholder {
  color: #0009;
}

.placeholder-transparent\/65::placeholder {
  color: #000000a6;
}

.placeholder-transparent\/70::placeholder {
  color: #000000b3;
}

.placeholder-transparent\/75::placeholder {
  color: #000000bf;
}

.placeholder-transparent\/80::placeholder {
  color: #000c;
}

.placeholder-transparent\/85::placeholder {
  color: #000000d9;
}

.placeholder-transparent\/90::placeholder {
  color: #000000e6;
}

.placeholder-transparent\/95::placeholder {
  color: #000000f2;
}

.placeholder-warning::placeholder {
  color: var(--color-warning-300);
}

.placeholder-warning-100::placeholder {
  color: var(--color-warning-100);
}

.placeholder-warning-200::placeholder {
  color: var(--color-warning-200);
}

.placeholder-warning-300::placeholder {
  color: var(--color-warning-300);
}

.placeholder-warning-400::placeholder {
  color: var(--color-warning-400);
}

.placeholder-white::placeholder {
  color: var(--color-white);
}

.placeholder-opacity-0::placeholder {
  --tw-placeholder-opacity: 0;
}

.placeholder-opacity-10::placeholder {
  --tw-placeholder-opacity: .1;
}

.placeholder-opacity-100::placeholder {
  --tw-placeholder-opacity: 1;
}

.placeholder-opacity-15::placeholder {
  --tw-placeholder-opacity: .15;
}

.placeholder-opacity-20::placeholder {
  --tw-placeholder-opacity: .2;
}

.placeholder-opacity-25::placeholder {
  --tw-placeholder-opacity: .25;
}

.placeholder-opacity-30::placeholder {
  --tw-placeholder-opacity: .3;
}

.placeholder-opacity-35::placeholder {
  --tw-placeholder-opacity: .35;
}

.placeholder-opacity-40::placeholder {
  --tw-placeholder-opacity: .4;
}

.placeholder-opacity-45::placeholder {
  --tw-placeholder-opacity: .45;
}

.placeholder-opacity-5::placeholder {
  --tw-placeholder-opacity: .05;
}

.placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: .5;
}

.placeholder-opacity-55::placeholder {
  --tw-placeholder-opacity: .55;
}

.placeholder-opacity-60::placeholder {
  --tw-placeholder-opacity: .6;
}

.placeholder-opacity-65::placeholder {
  --tw-placeholder-opacity: .65;
}

.placeholder-opacity-70::placeholder {
  --tw-placeholder-opacity: .7;
}

.placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: .75;
}

.placeholder-opacity-80::placeholder {
  --tw-placeholder-opacity: .8;
}

.placeholder-opacity-85::placeholder {
  --tw-placeholder-opacity: .85;
}

.placeholder-opacity-90::placeholder {
  --tw-placeholder-opacity: .9;
}

.placeholder-opacity-95::placeholder {
  --tw-placeholder-opacity: .95;
}

.caret-black {
  caret-color: var(--color-black);
}

.caret-current {
  caret-color: currentColor;
}

.caret-danger {
  caret-color: var(--color-danger-300);
}

.caret-danger-100 {
  caret-color: var(--color-danger-100);
}

.caret-danger-200 {
  caret-color: var(--color-danger-200);
}

.caret-danger-300 {
  caret-color: var(--color-danger-300);
}

.caret-danger-400 {
  caret-color: var(--color-danger-400);
}

.caret-divider {
  caret-color: var(--color-divider);
}

.caret-gray {
  caret-color: var(--color-gray);
}

.caret-info {
  caret-color: var(--color-info-300);
}

.caret-info-100 {
  caret-color: var(--color-info-100);
}

.caret-info-200 {
  caret-color: var(--color-info-200);
}

.caret-info-300 {
  caret-color: var(--color-info-300);
}

.caret-info-400 {
  caret-color: var(--color-info-400);
}

.caret-primary {
  caret-color: var(--color-primary-300);
}

.caret-primary-100 {
  caret-color: var(--color-primary-100);
}

.caret-primary-200 {
  caret-color: var(--color-primary-200);
}

.caret-primary-250 {
  caret-color: var(--color-primary-250);
}

.caret-primary-300 {
  caret-color: var(--color-primary-300);
}

.caret-primary-400 {
  caret-color: var(--color-primary-400);
}

.caret-primary-500 {
  caret-color: var(--color-primary-500);
}

.caret-primary-600 {
  caret-color: var(--color-primary-600);
}

.caret-success {
  caret-color: var(--color-success-300);
}

.caret-success-100 {
  caret-color: var(--color-success-100);
}

.caret-success-200 {
  caret-color: var(--color-success-200);
}

.caret-success-300 {
  caret-color: var(--color-success-300);
}

.caret-success-400 {
  caret-color: var(--color-success-400);
}

.caret-transparent, .caret-transparent\/0 {
  caret-color: #0000;
}

.caret-transparent\/10 {
  caret-color: #0000001a;
}

.caret-transparent\/100 {
  caret-color: #000;
}

.caret-transparent\/15 {
  caret-color: #00000026;
}

.caret-transparent\/20 {
  caret-color: #0003;
}

.caret-transparent\/25 {
  caret-color: #00000040;
}

.caret-transparent\/30 {
  caret-color: #0000004d;
}

.caret-transparent\/35 {
  caret-color: #00000059;
}

.caret-transparent\/40 {
  caret-color: #0006;
}

.caret-transparent\/45 {
  caret-color: #00000073;
}

.caret-transparent\/5 {
  caret-color: #0000000d;
}

.caret-transparent\/50 {
  caret-color: #00000080;
}

.caret-transparent\/55 {
  caret-color: #0000008c;
}

.caret-transparent\/60 {
  caret-color: #0009;
}

.caret-transparent\/65 {
  caret-color: #000000a6;
}

.caret-transparent\/70 {
  caret-color: #000000b3;
}

.caret-transparent\/75 {
  caret-color: #000000bf;
}

.caret-transparent\/80 {
  caret-color: #000c;
}

.caret-transparent\/85 {
  caret-color: #000000d9;
}

.caret-transparent\/90 {
  caret-color: #000000e6;
}

.caret-transparent\/95 {
  caret-color: #000000f2;
}

.caret-warning {
  caret-color: var(--color-warning-300);
}

.caret-warning-100 {
  caret-color: var(--color-warning-100);
}

.caret-warning-200 {
  caret-color: var(--color-warning-200);
}

.caret-warning-300 {
  caret-color: var(--color-warning-300);
}

.caret-warning-400 {
  caret-color: var(--color-warning-400);
}

.caret-white {
  caret-color: var(--color-white);
}

.accent-auto {
  accent-color: auto;
}

.accent-black {
  accent-color: var(--color-black);
}

.accent-current {
  accent-color: currentColor;
}

.accent-danger {
  accent-color: var(--color-danger-300);
}

.accent-danger-100 {
  accent-color: var(--color-danger-100);
}

.accent-danger-200 {
  accent-color: var(--color-danger-200);
}

.accent-danger-300 {
  accent-color: var(--color-danger-300);
}

.accent-danger-400 {
  accent-color: var(--color-danger-400);
}

.accent-divider {
  accent-color: var(--color-divider);
}

.accent-gray {
  accent-color: var(--color-gray);
}

.accent-info {
  accent-color: var(--color-info-300);
}

.accent-info-100 {
  accent-color: var(--color-info-100);
}

.accent-info-200 {
  accent-color: var(--color-info-200);
}

.accent-info-300 {
  accent-color: var(--color-info-300);
}

.accent-info-400 {
  accent-color: var(--color-info-400);
}

.accent-primary {
  accent-color: var(--color-primary-300);
}

.accent-primary-100 {
  accent-color: var(--color-primary-100);
}

.accent-primary-200 {
  accent-color: var(--color-primary-200);
}

.accent-primary-250 {
  accent-color: var(--color-primary-250);
}

.accent-primary-300 {
  accent-color: var(--color-primary-300);
}

.accent-primary-400 {
  accent-color: var(--color-primary-400);
}

.accent-primary-500 {
  accent-color: var(--color-primary-500);
}

.accent-primary-600 {
  accent-color: var(--color-primary-600);
}

.accent-success {
  accent-color: var(--color-success-300);
}

.accent-success-100 {
  accent-color: var(--color-success-100);
}

.accent-success-200 {
  accent-color: var(--color-success-200);
}

.accent-success-300 {
  accent-color: var(--color-success-300);
}

.accent-success-400 {
  accent-color: var(--color-success-400);
}

.accent-transparent, .accent-transparent\/0 {
  accent-color: #0000;
}

.accent-transparent\/10 {
  accent-color: #0000001a;
}

.accent-transparent\/100 {
  accent-color: #000;
}

.accent-transparent\/15 {
  accent-color: #00000026;
}

.accent-transparent\/20 {
  accent-color: #0003;
}

.accent-transparent\/25 {
  accent-color: #00000040;
}

.accent-transparent\/30 {
  accent-color: #0000004d;
}

.accent-transparent\/35 {
  accent-color: #00000059;
}

.accent-transparent\/40 {
  accent-color: #0006;
}

.accent-transparent\/45 {
  accent-color: #00000073;
}

.accent-transparent\/5 {
  accent-color: #0000000d;
}

.accent-transparent\/50 {
  accent-color: #00000080;
}

.accent-transparent\/55 {
  accent-color: #0000008c;
}

.accent-transparent\/60 {
  accent-color: #0009;
}

.accent-transparent\/65 {
  accent-color: #000000a6;
}

.accent-transparent\/70 {
  accent-color: #000000b3;
}

.accent-transparent\/75 {
  accent-color: #000000bf;
}

.accent-transparent\/80 {
  accent-color: #000c;
}

.accent-transparent\/85 {
  accent-color: #000000d9;
}

.accent-transparent\/90 {
  accent-color: #000000e6;
}

.accent-transparent\/95 {
  accent-color: #000000f2;
}

.accent-warning {
  accent-color: var(--color-warning-300);
}

.accent-warning-100 {
  accent-color: var(--color-warning-100);
}

.accent-warning-200 {
  accent-color: var(--color-warning-200);
}

.accent-warning-300 {
  accent-color: var(--color-warning-300);
}

.accent-warning-400 {
  accent-color: var(--color-warning-400);
}

.accent-white {
  accent-color: var(--color-white);
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-100 {
  opacity: 1;
}

.opacity-15 {
  opacity: .15;
}

.opacity-20 {
  opacity: .2;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-35 {
  opacity: .35;
}

.opacity-40 {
  opacity: .4;
}

.opacity-45 {
  opacity: .45;
}

.opacity-5 {
  opacity: .05;
}

.opacity-50 {
  opacity: .5;
}

.opacity-55 {
  opacity: .55;
}

.opacity-60 {
  opacity: .6;
}

.opacity-65 {
  opacity: .65;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-80 {
  opacity: .8;
}

.opacity-85 {
  opacity: .85;
}

.opacity-90 {
  opacity: .9;
}

.opacity-95 {
  opacity: .95;
}

.bg-blend-normal {
  background-blend-mode: normal;
}

.bg-blend-multiply {
  background-blend-mode: multiply;
}

.bg-blend-screen {
  background-blend-mode: screen;
}

.bg-blend-overlay {
  background-blend-mode: overlay;
}

.bg-blend-darken {
  background-blend-mode: darken;
}

.bg-blend-lighten {
  background-blend-mode: lighten;
}

.bg-blend-color-dodge {
  background-blend-mode: color-dodge;
}

.bg-blend-color-burn {
  background-blend-mode: color-burn;
}

.bg-blend-hard-light {
  background-blend-mode: hard-light;
}

.bg-blend-soft-light {
  background-blend-mode: soft-light;
}

.bg-blend-difference {
  background-blend-mode: difference;
}

.bg-blend-exclusion {
  background-blend-mode: exclusion;
}

.bg-blend-hue {
  background-blend-mode: hue;
}

.bg-blend-saturation {
  background-blend-mode: saturation;
}

.bg-blend-color {
  background-blend-mode: color;
}

.bg-blend-luminosity {
  background-blend-mode: luminosity;
}

.mix-blend-normal {
  mix-blend-mode: normal;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.mix-blend-screen {
  mix-blend-mode: screen;
}

.mix-blend-overlay {
  mix-blend-mode: overlay;
}

.mix-blend-darken {
  mix-blend-mode: darken;
}

.mix-blend-lighten {
  mix-blend-mode: lighten;
}

.mix-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.mix-blend-color-burn {
  mix-blend-mode: color-burn;
}

.mix-blend-hard-light {
  mix-blend-mode: hard-light;
}

.mix-blend-soft-light {
  mix-blend-mode: soft-light;
}

.mix-blend-difference {
  mix-blend-mode: difference;
}

.mix-blend-exclusion {
  mix-blend-mode: exclusion;
}

.mix-blend-hue {
  mix-blend-mode: hue;
}

.mix-blend-saturation {
  mix-blend-mode: saturation;
}

.mix-blend-color {
  mix-blend-mode: color;
}

.mix-blend-luminosity {
  mix-blend-mode: luminosity;
}

.mix-blend-plus-darker {
  mix-blend-mode: plus-darker;
}

.mix-blend-plus-lighter {
  mix-blend-mode: plus-lighter;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black {
  --tw-shadow-color: var(--color-black);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-current {
  --tw-shadow-color: currentColor;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-danger {
  --tw-shadow-color: var(--color-danger-300);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-danger-100 {
  --tw-shadow-color: var(--color-danger-100);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-danger-200 {
  --tw-shadow-color: var(--color-danger-200);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-danger-300 {
  --tw-shadow-color: var(--color-danger-300);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-danger-400 {
  --tw-shadow-color: var(--color-danger-400);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-divider {
  --tw-shadow-color: var(--color-divider);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray {
  --tw-shadow-color: var(--color-gray);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-info {
  --tw-shadow-color: var(--color-info-300);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-info-100 {
  --tw-shadow-color: var(--color-info-100);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-info-200 {
  --tw-shadow-color: var(--color-info-200);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-info-300 {
  --tw-shadow-color: var(--color-info-300);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-info-400 {
  --tw-shadow-color: var(--color-info-400);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary {
  --tw-shadow-color: var(--color-primary-300);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-100 {
  --tw-shadow-color: var(--color-primary-100);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-200 {
  --tw-shadow-color: var(--color-primary-200);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-250 {
  --tw-shadow-color: var(--color-primary-250);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-300 {
  --tw-shadow-color: var(--color-primary-300);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-400 {
  --tw-shadow-color: var(--color-primary-400);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-500 {
  --tw-shadow-color: var(--color-primary-500);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-600 {
  --tw-shadow-color: var(--color-primary-600);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-success {
  --tw-shadow-color: var(--color-success-300);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-success-100 {
  --tw-shadow-color: var(--color-success-100);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-success-200 {
  --tw-shadow-color: var(--color-success-200);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-success-300 {
  --tw-shadow-color: var(--color-success-300);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-success-400 {
  --tw-shadow-color: var(--color-success-400);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent {
  --tw-shadow-color: transparent;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/0 {
  --tw-shadow-color: #0000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/100 {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/15 {
  --tw-shadow-color: #00000026;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/25 {
  --tw-shadow-color: #00000040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/30 {
  --tw-shadow-color: #0000004d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/35 {
  --tw-shadow-color: #00000059;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/40 {
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/45 {
  --tw-shadow-color: #00000073;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/50 {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/55 {
  --tw-shadow-color: #0000008c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/60 {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/65 {
  --tw-shadow-color: #000000a6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/70 {
  --tw-shadow-color: #000000b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/75 {
  --tw-shadow-color: #000000bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/80 {
  --tw-shadow-color: #000c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/85 {
  --tw-shadow-color: #000000d9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/90 {
  --tw-shadow-color: #000000e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/95 {
  --tw-shadow-color: #000000f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-warning {
  --tw-shadow-color: var(--color-warning-300);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-warning-100 {
  --tw-shadow-color: var(--color-warning-100);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-warning-200 {
  --tw-shadow-color: var(--color-warning-200);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-warning-300 {
  --tw-shadow-color: var(--color-warning-300);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-warning-400 {
  --tw-shadow-color: var(--color-warning-400);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white {
  --tw-shadow-color: var(--color-white);
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-dashed {
  outline-style: dashed;
}

.outline-dotted {
  outline-style: dotted;
}

.outline-double {
  outline-style: double;
}

.outline-0 {
  outline-width: 0;
}

.outline-1 {
  outline-width: 1px;
}

.outline-2 {
  outline-width: 2px;
}

.outline-4 {
  outline-width: 4px;
}

.outline-8 {
  outline-width: 8px;
}

.-outline-offset-0 {
  outline-offset: -0px;
}

.-outline-offset-1 {
  outline-offset: -1px;
}

.-outline-offset-2 {
  outline-offset: -2px;
}

.-outline-offset-4 {
  outline-offset: -4px;
}

.-outline-offset-8 {
  outline-offset: -8px;
}

.outline-offset-0 {
  outline-offset: 0px;
}

.outline-offset-1 {
  outline-offset: 1px;
}

.outline-offset-2 {
  outline-offset: 2px;
}

.outline-offset-4 {
  outline-offset: 4px;
}

.outline-offset-8 {
  outline-offset: 8px;
}

.outline-black {
  outline-color: var(--color-black);
}

.outline-current {
  outline-color: currentColor;
}

.outline-danger {
  outline-color: var(--color-danger-300);
}

.outline-danger-100 {
  outline-color: var(--color-danger-100);
}

.outline-danger-200 {
  outline-color: var(--color-danger-200);
}

.outline-danger-300 {
  outline-color: var(--color-danger-300);
}

.outline-danger-400 {
  outline-color: var(--color-danger-400);
}

.outline-divider {
  outline-color: var(--color-divider);
}

.outline-gray {
  outline-color: var(--color-gray);
}

.outline-info {
  outline-color: var(--color-info-300);
}

.outline-info-100 {
  outline-color: var(--color-info-100);
}

.outline-info-200 {
  outline-color: var(--color-info-200);
}

.outline-info-300 {
  outline-color: var(--color-info-300);
}

.outline-info-400 {
  outline-color: var(--color-info-400);
}

.outline-primary {
  outline-color: var(--color-primary-300);
}

.outline-primary-100 {
  outline-color: var(--color-primary-100);
}

.outline-primary-200 {
  outline-color: var(--color-primary-200);
}

.outline-primary-250 {
  outline-color: var(--color-primary-250);
}

.outline-primary-300 {
  outline-color: var(--color-primary-300);
}

.outline-primary-400 {
  outline-color: var(--color-primary-400);
}

.outline-primary-500 {
  outline-color: var(--color-primary-500);
}

.outline-primary-600 {
  outline-color: var(--color-primary-600);
}

.outline-success {
  outline-color: var(--color-success-300);
}

.outline-success-100 {
  outline-color: var(--color-success-100);
}

.outline-success-200 {
  outline-color: var(--color-success-200);
}

.outline-success-300 {
  outline-color: var(--color-success-300);
}

.outline-success-400 {
  outline-color: var(--color-success-400);
}

.outline-transparent, .outline-transparent\/0 {
  outline-color: #0000;
}

.outline-transparent\/10 {
  outline-color: #0000001a;
}

.outline-transparent\/100 {
  outline-color: #000;
}

.outline-transparent\/15 {
  outline-color: #00000026;
}

.outline-transparent\/20 {
  outline-color: #0003;
}

.outline-transparent\/25 {
  outline-color: #00000040;
}

.outline-transparent\/30 {
  outline-color: #0000004d;
}

.outline-transparent\/35 {
  outline-color: #00000059;
}

.outline-transparent\/40 {
  outline-color: #0006;
}

.outline-transparent\/45 {
  outline-color: #00000073;
}

.outline-transparent\/5 {
  outline-color: #0000000d;
}

.outline-transparent\/50 {
  outline-color: #00000080;
}

.outline-transparent\/55 {
  outline-color: #0000008c;
}

.outline-transparent\/60 {
  outline-color: #0009;
}

.outline-transparent\/65 {
  outline-color: #000000a6;
}

.outline-transparent\/70 {
  outline-color: #000000b3;
}

.outline-transparent\/75 {
  outline-color: #000000bf;
}

.outline-transparent\/80 {
  outline-color: #000c;
}

.outline-transparent\/85 {
  outline-color: #000000d9;
}

.outline-transparent\/90 {
  outline-color: #000000e6;
}

.outline-transparent\/95 {
  outline-color: #000000f2;
}

.outline-warning {
  outline-color: var(--color-warning-300);
}

.outline-warning-100 {
  outline-color: var(--color-warning-100);
}

.outline-warning-200 {
  outline-color: var(--color-warning-200);
}

.outline-warning-300 {
  outline-color: var(--color-warning-300);
}

.outline-warning-400 {
  outline-color: var(--color-warning-400);
}

.outline-white {
  outline-color: var(--color-white);
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-black {
  --tw-ring-color: var(--color-black);
}

.ring-current {
  --tw-ring-color: currentColor;
}

.ring-danger {
  --tw-ring-color: var(--color-danger-300);
}

.ring-danger-100 {
  --tw-ring-color: var(--color-danger-100);
}

.ring-danger-200 {
  --tw-ring-color: var(--color-danger-200);
}

.ring-danger-300 {
  --tw-ring-color: var(--color-danger-300);
}

.ring-danger-400 {
  --tw-ring-color: var(--color-danger-400);
}

.ring-divider {
  --tw-ring-color: var(--color-divider);
}

.ring-gray {
  --tw-ring-color: var(--color-gray);
}

.ring-info {
  --tw-ring-color: var(--color-info-300);
}

.ring-info-100 {
  --tw-ring-color: var(--color-info-100);
}

.ring-info-200 {
  --tw-ring-color: var(--color-info-200);
}

.ring-info-300 {
  --tw-ring-color: var(--color-info-300);
}

.ring-info-400 {
  --tw-ring-color: var(--color-info-400);
}

.ring-primary {
  --tw-ring-color: var(--color-primary-300);
}

.ring-primary-100 {
  --tw-ring-color: var(--color-primary-100);
}

.ring-primary-200 {
  --tw-ring-color: var(--color-primary-200);
}

.ring-primary-250 {
  --tw-ring-color: var(--color-primary-250);
}

.ring-primary-300 {
  --tw-ring-color: var(--color-primary-300);
}

.ring-primary-400 {
  --tw-ring-color: var(--color-primary-400);
}

.ring-primary-500 {
  --tw-ring-color: var(--color-primary-500);
}

.ring-primary-600 {
  --tw-ring-color: var(--color-primary-600);
}

.ring-success {
  --tw-ring-color: var(--color-success-300);
}

.ring-success-100 {
  --tw-ring-color: var(--color-success-100);
}

.ring-success-200 {
  --tw-ring-color: var(--color-success-200);
}

.ring-success-300 {
  --tw-ring-color: var(--color-success-300);
}

.ring-success-400 {
  --tw-ring-color: var(--color-success-400);
}

.ring-transparent {
  --tw-ring-color: transparent;
}

.ring-transparent\/0 {
  --tw-ring-color: #0000;
}

.ring-transparent\/10 {
  --tw-ring-color: #0000001a;
}

.ring-transparent\/100 {
  --tw-ring-color: #000;
}

.ring-transparent\/15 {
  --tw-ring-color: #00000026;
}

.ring-transparent\/20 {
  --tw-ring-color: #0003;
}

.ring-transparent\/25 {
  --tw-ring-color: #00000040;
}

.ring-transparent\/30 {
  --tw-ring-color: #0000004d;
}

.ring-transparent\/35 {
  --tw-ring-color: #00000059;
}

.ring-transparent\/40 {
  --tw-ring-color: #0006;
}

.ring-transparent\/45 {
  --tw-ring-color: #00000073;
}

.ring-transparent\/5 {
  --tw-ring-color: #0000000d;
}

.ring-transparent\/50 {
  --tw-ring-color: #00000080;
}

.ring-transparent\/55 {
  --tw-ring-color: #0000008c;
}

.ring-transparent\/60 {
  --tw-ring-color: #0009;
}

.ring-transparent\/65 {
  --tw-ring-color: #000000a6;
}

.ring-transparent\/70 {
  --tw-ring-color: #000000b3;
}

.ring-transparent\/75 {
  --tw-ring-color: #000000bf;
}

.ring-transparent\/80 {
  --tw-ring-color: #000c;
}

.ring-transparent\/85 {
  --tw-ring-color: #000000d9;
}

.ring-transparent\/90 {
  --tw-ring-color: #000000e6;
}

.ring-transparent\/95 {
  --tw-ring-color: #000000f2;
}

.ring-warning {
  --tw-ring-color: var(--color-warning-300);
}

.ring-warning-100 {
  --tw-ring-color: var(--color-warning-100);
}

.ring-warning-200 {
  --tw-ring-color: var(--color-warning-200);
}

.ring-warning-300 {
  --tw-ring-color: var(--color-warning-300);
}

.ring-warning-400 {
  --tw-ring-color: var(--color-warning-400);
}

.ring-white {
  --tw-ring-color: var(--color-white);
}

.ring-opacity-0 {
  --tw-ring-opacity: 0;
}

.ring-opacity-10 {
  --tw-ring-opacity: .1;
}

.ring-opacity-100 {
  --tw-ring-opacity: 1;
}

.ring-opacity-15 {
  --tw-ring-opacity: .15;
}

.ring-opacity-20 {
  --tw-ring-opacity: .2;
}

.ring-opacity-25 {
  --tw-ring-opacity: .25;
}

.ring-opacity-30 {
  --tw-ring-opacity: .3;
}

.ring-opacity-35 {
  --tw-ring-opacity: .35;
}

.ring-opacity-40 {
  --tw-ring-opacity: .4;
}

.ring-opacity-45 {
  --tw-ring-opacity: .45;
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.ring-opacity-50 {
  --tw-ring-opacity: .5;
}

.ring-opacity-55 {
  --tw-ring-opacity: .55;
}

.ring-opacity-60 {
  --tw-ring-opacity: .6;
}

.ring-opacity-65 {
  --tw-ring-opacity: .65;
}

.ring-opacity-70 {
  --tw-ring-opacity: .7;
}

.ring-opacity-75 {
  --tw-ring-opacity: .75;
}

.ring-opacity-80 {
  --tw-ring-opacity: .8;
}

.ring-opacity-85 {
  --tw-ring-opacity: .85;
}

.ring-opacity-90 {
  --tw-ring-opacity: .9;
}

.ring-opacity-95 {
  --tw-ring-opacity: .95;
}

.ring-offset-0 {
  --tw-ring-offset-width: 0px;
}

.ring-offset-1 {
  --tw-ring-offset-width: 1px;
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-4 {
  --tw-ring-offset-width: 4px;
}

.ring-offset-8 {
  --tw-ring-offset-width: 8px;
}

.ring-offset-black {
  --tw-ring-offset-color: var(--color-black);
}

.ring-offset-current {
  --tw-ring-offset-color: currentColor;
}

.ring-offset-danger {
  --tw-ring-offset-color: var(--color-danger-300);
}

.ring-offset-danger-100 {
  --tw-ring-offset-color: var(--color-danger-100);
}

.ring-offset-danger-200 {
  --tw-ring-offset-color: var(--color-danger-200);
}

.ring-offset-danger-300 {
  --tw-ring-offset-color: var(--color-danger-300);
}

.ring-offset-danger-400 {
  --tw-ring-offset-color: var(--color-danger-400);
}

.ring-offset-divider {
  --tw-ring-offset-color: var(--color-divider);
}

.ring-offset-gray {
  --tw-ring-offset-color: var(--color-gray);
}

.ring-offset-info {
  --tw-ring-offset-color: var(--color-info-300);
}

.ring-offset-info-100 {
  --tw-ring-offset-color: var(--color-info-100);
}

.ring-offset-info-200 {
  --tw-ring-offset-color: var(--color-info-200);
}

.ring-offset-info-300 {
  --tw-ring-offset-color: var(--color-info-300);
}

.ring-offset-info-400 {
  --tw-ring-offset-color: var(--color-info-400);
}

.ring-offset-primary {
  --tw-ring-offset-color: var(--color-primary-300);
}

.ring-offset-primary-100 {
  --tw-ring-offset-color: var(--color-primary-100);
}

.ring-offset-primary-200 {
  --tw-ring-offset-color: var(--color-primary-200);
}

.ring-offset-primary-250 {
  --tw-ring-offset-color: var(--color-primary-250);
}

.ring-offset-primary-300 {
  --tw-ring-offset-color: var(--color-primary-300);
}

.ring-offset-primary-400 {
  --tw-ring-offset-color: var(--color-primary-400);
}

.ring-offset-primary-500 {
  --tw-ring-offset-color: var(--color-primary-500);
}

.ring-offset-primary-600 {
  --tw-ring-offset-color: var(--color-primary-600);
}

.ring-offset-success {
  --tw-ring-offset-color: var(--color-success-300);
}

.ring-offset-success-100 {
  --tw-ring-offset-color: var(--color-success-100);
}

.ring-offset-success-200 {
  --tw-ring-offset-color: var(--color-success-200);
}

.ring-offset-success-300 {
  --tw-ring-offset-color: var(--color-success-300);
}

.ring-offset-success-400 {
  --tw-ring-offset-color: var(--color-success-400);
}

.ring-offset-transparent {
  --tw-ring-offset-color: transparent;
}

.ring-offset-transparent\/0 {
  --tw-ring-offset-color: #0000;
}

.ring-offset-transparent\/10 {
  --tw-ring-offset-color: #0000001a;
}

.ring-offset-transparent\/100 {
  --tw-ring-offset-color: #000;
}

.ring-offset-transparent\/15 {
  --tw-ring-offset-color: #00000026;
}

.ring-offset-transparent\/20 {
  --tw-ring-offset-color: #0003;
}

.ring-offset-transparent\/25 {
  --tw-ring-offset-color: #00000040;
}

.ring-offset-transparent\/30 {
  --tw-ring-offset-color: #0000004d;
}

.ring-offset-transparent\/35 {
  --tw-ring-offset-color: #00000059;
}

.ring-offset-transparent\/40 {
  --tw-ring-offset-color: #0006;
}

.ring-offset-transparent\/45 {
  --tw-ring-offset-color: #00000073;
}

.ring-offset-transparent\/5 {
  --tw-ring-offset-color: #0000000d;
}

.ring-offset-transparent\/50 {
  --tw-ring-offset-color: #00000080;
}

.ring-offset-transparent\/55 {
  --tw-ring-offset-color: #0000008c;
}

.ring-offset-transparent\/60 {
  --tw-ring-offset-color: #0009;
}

.ring-offset-transparent\/65 {
  --tw-ring-offset-color: #000000a6;
}

.ring-offset-transparent\/70 {
  --tw-ring-offset-color: #000000b3;
}

.ring-offset-transparent\/75 {
  --tw-ring-offset-color: #000000bf;
}

.ring-offset-transparent\/80 {
  --tw-ring-offset-color: #000c;
}

.ring-offset-transparent\/85 {
  --tw-ring-offset-color: #000000d9;
}

.ring-offset-transparent\/90 {
  --tw-ring-offset-color: #000000e6;
}

.ring-offset-transparent\/95 {
  --tw-ring-offset-color: #000000f2;
}

.ring-offset-warning {
  --tw-ring-offset-color: var(--color-warning-300);
}

.ring-offset-warning-100 {
  --tw-ring-offset-color: var(--color-warning-100);
}

.ring-offset-warning-200 {
  --tw-ring-offset-color: var(--color-warning-200);
}

.ring-offset-warning-300 {
  --tw-ring-offset-color: var(--color-warning-300);
}

.ring-offset-warning-400 {
  --tw-ring-offset-color: var(--color-warning-400);
}

.ring-offset-white {
  --tw-ring-offset-color: var(--color-white);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-0 {
  --tw-blur: blur(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-2xl {
  --tw-blur: blur(40px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-3xl {
  --tw-blur: blur(64px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-lg {
  --tw-blur: blur(16px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-md {
  --tw-blur: blur(12px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-none {
  --tw-blur: ;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-sm {
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-xl {
  --tw-blur: blur(24px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-0 {
  --tw-brightness: brightness(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-100 {
  --tw-brightness: brightness(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-105 {
  --tw-brightness: brightness(1.05);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-110 {
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-125 {
  --tw-brightness: brightness(1.25);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-150 {
  --tw-brightness: brightness(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-200 {
  --tw-brightness: brightness(2);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-50 {
  --tw-brightness: brightness(.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-75 {
  --tw-brightness: brightness(.75);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-90 {
  --tw-brightness: brightness(.9);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-95 {
  --tw-brightness: brightness(.95);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.contrast-0 {
  --tw-contrast: contrast(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.contrast-100 {
  --tw-contrast: contrast(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.contrast-125 {
  --tw-contrast: contrast(1.25);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.contrast-150 {
  --tw-contrast: contrast(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.contrast-200 {
  --tw-contrast: contrast(2);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.contrast-50 {
  --tw-contrast: contrast(.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.contrast-75 {
  --tw-contrast: contrast(.75);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-none {
  --tw-drop-shadow: drop-shadow(0 0 #0000);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale-0 {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.-hue-rotate-0 {
  --tw-hue-rotate: hue-rotate(-0deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.-hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(-15deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.-hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(-180deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.-hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(-30deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.-hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(-60deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.-hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(-90deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hue-rotate-0 {
  --tw-hue-rotate: hue-rotate(0deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(15deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(180deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(30deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(60deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(90deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert-0 {
  --tw-invert: invert(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-0 {
  --tw-saturate: saturate(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-100 {
  --tw-saturate: saturate(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-150 {
  --tw-saturate: saturate(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-200 {
  --tw-saturate: saturate(2);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-50 {
  --tw-saturate: saturate(.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.sepia {
  --tw-sepia: sepia(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.sepia-0 {
  --tw-sepia: sepia(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter-none {
  filter: none;
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-0 {
  --tw-backdrop-blur: blur(0);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-3xl {
  --tw-backdrop-blur: blur(64px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-none {
  --tw-backdrop-blur: ;
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-0 {
  --tw-backdrop-brightness: brightness(0);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-100 {
  --tw-backdrop-brightness: brightness(1);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-105 {
  --tw-backdrop-brightness: brightness(1.05);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-110 {
  --tw-backdrop-brightness: brightness(1.1);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-125 {
  --tw-backdrop-brightness: brightness(1.25);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-150 {
  --tw-backdrop-brightness: brightness(1.5);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-200 {
  --tw-backdrop-brightness: brightness(2);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-50 {
  --tw-backdrop-brightness: brightness(.5);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-75 {
  --tw-backdrop-brightness: brightness(.75);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-90 {
  --tw-backdrop-brightness: brightness(.9);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-95 {
  --tw-backdrop-brightness: brightness(.95);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-0 {
  --tw-backdrop-contrast: contrast(0);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-100 {
  --tw-backdrop-contrast: contrast(1);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-125 {
  --tw-backdrop-contrast: contrast(1.25);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-150 {
  --tw-backdrop-contrast: contrast(1.5);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-200 {
  --tw-backdrop-contrast: contrast(2);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-50 {
  --tw-backdrop-contrast: contrast(.5);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-75 {
  --tw-backdrop-contrast: contrast(.75);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-grayscale {
  --tw-backdrop-grayscale: grayscale(100%);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-grayscale-0 {
  --tw-backdrop-grayscale: grayscale(0);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(-0deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(0deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(15deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(180deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(30deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(60deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(90deg);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-invert {
  --tw-backdrop-invert: invert(100%);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-invert-0 {
  --tw-backdrop-invert: invert(0);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-0 {
  --tw-backdrop-opacity: opacity(0);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-10 {
  --tw-backdrop-opacity: opacity(.1);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-100 {
  --tw-backdrop-opacity: opacity(1);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-15 {
  --tw-backdrop-opacity: opacity(.15);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-20 {
  --tw-backdrop-opacity: opacity(.2);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-25 {
  --tw-backdrop-opacity: opacity(.25);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-30 {
  --tw-backdrop-opacity: opacity(.3);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-35 {
  --tw-backdrop-opacity: opacity(.35);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-40 {
  --tw-backdrop-opacity: opacity(.4);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-45 {
  --tw-backdrop-opacity: opacity(.45);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-5 {
  --tw-backdrop-opacity: opacity(.05);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-50 {
  --tw-backdrop-opacity: opacity(.5);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-55 {
  --tw-backdrop-opacity: opacity(.55);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-60 {
  --tw-backdrop-opacity: opacity(.6);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-65 {
  --tw-backdrop-opacity: opacity(.65);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-70 {
  --tw-backdrop-opacity: opacity(.7);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-75 {
  --tw-backdrop-opacity: opacity(.75);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-80 {
  --tw-backdrop-opacity: opacity(.8);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-85 {
  --tw-backdrop-opacity: opacity(.85);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-90 {
  --tw-backdrop-opacity: opacity(.9);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-95 {
  --tw-backdrop-opacity: opacity(.95);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-0 {
  --tw-backdrop-saturate: saturate(0);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-100 {
  --tw-backdrop-saturate: saturate(1);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-150 {
  --tw-backdrop-saturate: saturate(1.5);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-200 {
  --tw-backdrop-saturate: saturate(2);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-50 {
  --tw-backdrop-saturate: saturate(.5);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-sepia {
  --tw-backdrop-sepia: sepia(100%);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-sepia-0 {
  --tw-backdrop-sepia: sepia(0);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter {
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter-none {
  backdrop-filter: none;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-none {
  transition-property: none;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-0 {
  transition-delay: 0s;
}

.delay-100 {
  transition-delay: .1s;
}

.delay-1000 {
  transition-delay: 1s;
}

.delay-150 {
  transition-delay: .15s;
}

.delay-200 {
  transition-delay: .2s;
}

.delay-300 {
  transition-delay: .3s;
}

.delay-500 {
  transition-delay: .5s;
}

.delay-700 {
  transition-delay: .7s;
}

.delay-75 {
  transition-delay: 75ms;
}

.duration-0 {
  transition-duration: 0s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.will-change-auto {
  will-change: auto;
}

.will-change-contents {
  will-change: contents;
}

.will-change-scroll {
  will-change: scroll-position;
}

.will-change-transform {
  will-change: transform;
}

.contain-none {
  contain: none;
}

.contain-content {
  contain: content;
}

.contain-strict {
  contain: strict;
}

.contain-size {
  --tw-contain-size: size;
  contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
}

.contain-inline-size {
  --tw-contain-size: inline-size;
  contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
}

.contain-layout {
  --tw-contain-layout: layout;
  contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
}

.contain-paint {
  --tw-contain-paint: paint;
  contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
}

.contain-style {
  --tw-contain-style: style;
  contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
}

.content-none {
  --tw-content: none;
  content: var(--tw-content);
}

.forced-color-adjust-auto {
  forced-color-adjust: auto;
}

.forced-color-adjust-none {
  forced-color-adjust: none;
}

body {
  min-height: 100%;
  font-family: Wix Madefor;
  font-weight: normal;
}

.section {
  padding-left: 1rem;
  padding-right: 1rem;
}

.section + .section {
  margin-top: 1rem;
}

.panel {
  background-color: #fff;
  border-radius: .25rem;
  padding: 1.5rem;
}

@media (width >= 640px) {
  .panel {
    padding: 2rem;
  }
}

.__PrivateStripeElement {
  height: 100%;
}

@media (width >= 640px) {
  .sm\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .sm\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .sm\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .sm\:container {
      max-width: 1536px;
    }
  }
}

@media (width >= 768px) {
  .md\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .md\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .md\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .md\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .md\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .md\:container {
      max-width: 1536px;
    }
  }
}

@media (width >= 1024px) {
  .lg\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .lg\:container {
      max-width: 1536px;
    }
  }
}

@media (width >= 1280px) {
  .xl\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .xl\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .xl\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .xl\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .xl\:container {
      max-width: 1536px;
    }
  }
}

.hover\:border-primary-300:hover {
  border-color: var(--color-primary-300);
}

.hover\:text-primary-300:hover {
  color: var(--color-primary-300);
}

.hover\:text-white:hover {
  color: var(--color-white);
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

@media (width >= 640px) {
  .sm\:sr-only {
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .sm\:not-sr-only {
    clip: auto;
    white-space: normal;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .sm\:pointer-events-none {
    pointer-events: none;
  }

  .sm\:pointer-events-auto {
    pointer-events: auto;
  }

  .sm\:visible {
    visibility: visible;
  }

  .sm\:invisible {
    visibility: hidden;
  }

  .sm\:collapse {
    visibility: collapse;
  }

  .sm\:static {
    position: static;
  }

  .sm\:fixed {
    position: fixed;
  }

  .sm\:absolute {
    position: absolute;
  }

  .sm\:relative {
    position: relative;
  }

  .sm\:sticky {
    position: sticky;
  }

  .sm\:-inset-0 {
    inset: 0;
  }

  .sm\:-inset-0\.5 {
    inset: -.125rem;
  }

  .sm\:-inset-1 {
    inset: -.25rem;
  }

  .sm\:-inset-1\.5 {
    inset: -.375rem;
  }

  .sm\:-inset-1\/2 {
    inset: -50%;
  }

  .sm\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .sm\:-inset-1\/4 {
    inset: -25%;
  }

  .sm\:-inset-10 {
    inset: -2.5rem;
  }

  .sm\:-inset-11 {
    inset: -2.75rem;
  }

  .sm\:-inset-12 {
    inset: -3rem;
  }

  .sm\:-inset-14 {
    inset: -3.5rem;
  }

  .sm\:-inset-16 {
    inset: -4rem;
  }

  .sm\:-inset-2 {
    inset: -.5rem;
  }

  .sm\:-inset-2\.5 {
    inset: -.625rem;
  }

  .sm\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .sm\:-inset-2\/4 {
    inset: -50%;
  }

  .sm\:-inset-20 {
    inset: -5rem;
  }

  .sm\:-inset-24 {
    inset: -6rem;
  }

  .sm\:-inset-28 {
    inset: -7rem;
  }

  .sm\:-inset-3 {
    inset: -.75rem;
  }

  .sm\:-inset-3\.5 {
    inset: -.875rem;
  }

  .sm\:-inset-3\/4 {
    inset: -75%;
  }

  .sm\:-inset-32 {
    inset: -8rem;
  }

  .sm\:-inset-36 {
    inset: -9rem;
  }

  .sm\:-inset-4 {
    inset: -1rem;
  }

  .sm\:-inset-40 {
    inset: -10rem;
  }

  .sm\:-inset-44 {
    inset: -11rem;
  }

  .sm\:-inset-48 {
    inset: -12rem;
  }

  .sm\:-inset-5 {
    inset: -1.25rem;
  }

  .sm\:-inset-52 {
    inset: -13rem;
  }

  .sm\:-inset-56 {
    inset: -14rem;
  }

  .sm\:-inset-6 {
    inset: -1.5rem;
  }

  .sm\:-inset-60 {
    inset: -15rem;
  }

  .sm\:-inset-64 {
    inset: -16rem;
  }

  .sm\:-inset-7 {
    inset: -1.75rem;
  }

  .sm\:-inset-72 {
    inset: -18rem;
  }

  .sm\:-inset-8 {
    inset: -2rem;
  }

  .sm\:-inset-80 {
    inset: -20rem;
  }

  .sm\:-inset-9 {
    inset: -2.25rem;
  }

  .sm\:-inset-96 {
    inset: -24rem;
  }

  .sm\:-inset-full {
    inset: -100%;
  }

  .sm\:-inset-px {
    inset: -1px;
  }

  .sm\:inset-0 {
    inset: 0;
  }

  .sm\:inset-0\.5 {
    inset: .125rem;
  }

  .sm\:inset-1 {
    inset: .25rem;
  }

  .sm\:inset-1\.5 {
    inset: .375rem;
  }

  .sm\:inset-1\/2 {
    inset: 50%;
  }

  .sm\:inset-1\/3 {
    inset: 33.3333%;
  }

  .sm\:inset-1\/4 {
    inset: 25%;
  }

  .sm\:inset-10 {
    inset: 2.5rem;
  }

  .sm\:inset-11 {
    inset: 2.75rem;
  }

  .sm\:inset-12 {
    inset: 3rem;
  }

  .sm\:inset-14 {
    inset: 3.5rem;
  }

  .sm\:inset-16 {
    inset: 4rem;
  }

  .sm\:inset-2 {
    inset: .5rem;
  }

  .sm\:inset-2\.5 {
    inset: .625rem;
  }

  .sm\:inset-2\/3 {
    inset: 66.6667%;
  }

  .sm\:inset-2\/4 {
    inset: 50%;
  }

  .sm\:inset-20 {
    inset: 5rem;
  }

  .sm\:inset-24 {
    inset: 6rem;
  }

  .sm\:inset-28 {
    inset: 7rem;
  }

  .sm\:inset-3 {
    inset: .75rem;
  }

  .sm\:inset-3\.5 {
    inset: .875rem;
  }

  .sm\:inset-3\/4 {
    inset: 75%;
  }

  .sm\:inset-32 {
    inset: 8rem;
  }

  .sm\:inset-36 {
    inset: 9rem;
  }

  .sm\:inset-4 {
    inset: 1rem;
  }

  .sm\:inset-40 {
    inset: 10rem;
  }

  .sm\:inset-44 {
    inset: 11rem;
  }

  .sm\:inset-48 {
    inset: 12rem;
  }

  .sm\:inset-5 {
    inset: 1.25rem;
  }

  .sm\:inset-52 {
    inset: 13rem;
  }

  .sm\:inset-56 {
    inset: 14rem;
  }

  .sm\:inset-6 {
    inset: 1.5rem;
  }

  .sm\:inset-60 {
    inset: 15rem;
  }

  .sm\:inset-64 {
    inset: 16rem;
  }

  .sm\:inset-7 {
    inset: 1.75rem;
  }

  .sm\:inset-72 {
    inset: 18rem;
  }

  .sm\:inset-8 {
    inset: 2rem;
  }

  .sm\:inset-80 {
    inset: 20rem;
  }

  .sm\:inset-9 {
    inset: 2.25rem;
  }

  .sm\:inset-96 {
    inset: 24rem;
  }

  .sm\:inset-auto {
    inset: auto;
  }

  .sm\:inset-full {
    inset: 100%;
  }

  .sm\:inset-px {
    inset: 1px;
  }

  .sm\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .sm\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .sm\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .sm\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .sm\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .sm\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .sm\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .sm\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .sm\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .sm\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .sm\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .sm\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .sm\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .sm\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .sm\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .sm\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .sm\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .sm\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .sm\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .sm\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .sm\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .sm\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .sm\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .sm\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .sm\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .sm\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .sm\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .sm\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .sm\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .sm\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .sm\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .sm\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .sm\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .sm\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .sm\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .sm\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .sm\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .sm\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .sm\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .sm\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .sm\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .sm\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .sm\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .sm\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .sm\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .sm\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .sm\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .sm\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .sm\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .sm\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .sm\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .sm\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .sm\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .sm\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .sm\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .sm\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .sm\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .sm\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .sm\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .sm\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .sm\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .sm\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .sm\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .sm\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .sm\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .sm\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .sm\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .sm\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .sm\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .sm\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .sm\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .sm\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .sm\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .sm\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .sm\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .sm\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .sm\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .sm\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .sm\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .sm\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .sm\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .sm\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .sm\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .sm\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .sm\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .sm\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .sm\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .sm\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .sm\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .sm\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .sm\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .sm\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .sm\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .sm\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .sm\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .sm\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .sm\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .sm\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .sm\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .sm\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .sm\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .sm\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .sm\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .sm\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .sm\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .sm\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .sm\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .sm\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .sm\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .sm\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .sm\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .sm\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .sm\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .sm\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .sm\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .sm\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .sm\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .sm\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .sm\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .sm\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .sm\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .sm\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .sm\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .sm\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .sm\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .sm\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .sm\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .sm\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .sm\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .sm\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .sm\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .sm\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .sm\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .sm\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .sm\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .sm\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .sm\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .sm\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .sm\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .sm\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .sm\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .sm\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .sm\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .sm\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .sm\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .sm\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .sm\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .sm\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .sm\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .sm\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .sm\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .sm\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .sm\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .sm\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .sm\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .sm\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .sm\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .sm\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .sm\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .sm\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .sm\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .sm\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .sm\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .sm\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .sm\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .sm\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .sm\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .sm\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .sm\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .sm\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .sm\:-bottom-0 {
    bottom: 0;
  }

  .sm\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .sm\:-bottom-1 {
    bottom: -.25rem;
  }

  .sm\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .sm\:-bottom-1\/2 {
    bottom: -50%;
  }

  .sm\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .sm\:-bottom-1\/4 {
    bottom: -25%;
  }

  .sm\:-bottom-10 {
    bottom: -2.5rem;
  }

  .sm\:-bottom-11 {
    bottom: -2.75rem;
  }

  .sm\:-bottom-12 {
    bottom: -3rem;
  }

  .sm\:-bottom-14 {
    bottom: -3.5rem;
  }

  .sm\:-bottom-16 {
    bottom: -4rem;
  }

  .sm\:-bottom-2 {
    bottom: -.5rem;
  }

  .sm\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .sm\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .sm\:-bottom-2\/4 {
    bottom: -50%;
  }

  .sm\:-bottom-20 {
    bottom: -5rem;
  }

  .sm\:-bottom-24 {
    bottom: -6rem;
  }

  .sm\:-bottom-28 {
    bottom: -7rem;
  }

  .sm\:-bottom-3 {
    bottom: -.75rem;
  }

  .sm\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .sm\:-bottom-3\/4 {
    bottom: -75%;
  }

  .sm\:-bottom-32 {
    bottom: -8rem;
  }

  .sm\:-bottom-36 {
    bottom: -9rem;
  }

  .sm\:-bottom-4 {
    bottom: -1rem;
  }

  .sm\:-bottom-40 {
    bottom: -10rem;
  }

  .sm\:-bottom-44 {
    bottom: -11rem;
  }

  .sm\:-bottom-48 {
    bottom: -12rem;
  }

  .sm\:-bottom-5 {
    bottom: -1.25rem;
  }

  .sm\:-bottom-52 {
    bottom: -13rem;
  }

  .sm\:-bottom-56 {
    bottom: -14rem;
  }

  .sm\:-bottom-6 {
    bottom: -1.5rem;
  }

  .sm\:-bottom-60 {
    bottom: -15rem;
  }

  .sm\:-bottom-64 {
    bottom: -16rem;
  }

  .sm\:-bottom-7 {
    bottom: -1.75rem;
  }

  .sm\:-bottom-72 {
    bottom: -18rem;
  }

  .sm\:-bottom-8 {
    bottom: -2rem;
  }

  .sm\:-bottom-80 {
    bottom: -20rem;
  }

  .sm\:-bottom-9 {
    bottom: -2.25rem;
  }

  .sm\:-bottom-96 {
    bottom: -24rem;
  }

  .sm\:-bottom-full {
    bottom: -100%;
  }

  .sm\:-bottom-px {
    bottom: -1px;
  }

  .sm\:-end-0 {
    inset-inline-end: 0;
  }

  .sm\:-end-0\.5 {
    inset-inline-end: -.125rem;
  }

  .sm\:-end-1 {
    inset-inline-end: -.25rem;
  }

  .sm\:-end-1\.5 {
    inset-inline-end: -.375rem;
  }

  .sm\:-end-1\/2 {
    inset-inline-end: -50%;
  }

  .sm\:-end-1\/3 {
    inset-inline-end: -33.3333%;
  }

  .sm\:-end-1\/4 {
    inset-inline-end: -25%;
  }

  .sm\:-end-10 {
    inset-inline-end: -2.5rem;
  }

  .sm\:-end-11 {
    inset-inline-end: -2.75rem;
  }

  .sm\:-end-12 {
    inset-inline-end: -3rem;
  }

  .sm\:-end-14 {
    inset-inline-end: -3.5rem;
  }

  .sm\:-end-16 {
    inset-inline-end: -4rem;
  }

  .sm\:-end-2 {
    inset-inline-end: -.5rem;
  }

  .sm\:-end-2\.5 {
    inset-inline-end: -.625rem;
  }

  .sm\:-end-2\/3 {
    inset-inline-end: -66.6667%;
  }

  .sm\:-end-2\/4 {
    inset-inline-end: -50%;
  }

  .sm\:-end-20 {
    inset-inline-end: -5rem;
  }

  .sm\:-end-24 {
    inset-inline-end: -6rem;
  }

  .sm\:-end-28 {
    inset-inline-end: -7rem;
  }

  .sm\:-end-3 {
    inset-inline-end: -.75rem;
  }

  .sm\:-end-3\.5 {
    inset-inline-end: -.875rem;
  }

  .sm\:-end-3\/4 {
    inset-inline-end: -75%;
  }

  .sm\:-end-32 {
    inset-inline-end: -8rem;
  }

  .sm\:-end-36 {
    inset-inline-end: -9rem;
  }

  .sm\:-end-4 {
    inset-inline-end: -1rem;
  }

  .sm\:-end-40 {
    inset-inline-end: -10rem;
  }

  .sm\:-end-44 {
    inset-inline-end: -11rem;
  }

  .sm\:-end-48 {
    inset-inline-end: -12rem;
  }

  .sm\:-end-5 {
    inset-inline-end: -1.25rem;
  }

  .sm\:-end-52 {
    inset-inline-end: -13rem;
  }

  .sm\:-end-56 {
    inset-inline-end: -14rem;
  }

  .sm\:-end-6 {
    inset-inline-end: -1.5rem;
  }

  .sm\:-end-60 {
    inset-inline-end: -15rem;
  }

  .sm\:-end-64 {
    inset-inline-end: -16rem;
  }

  .sm\:-end-7 {
    inset-inline-end: -1.75rem;
  }

  .sm\:-end-72 {
    inset-inline-end: -18rem;
  }

  .sm\:-end-8 {
    inset-inline-end: -2rem;
  }

  .sm\:-end-80 {
    inset-inline-end: -20rem;
  }

  .sm\:-end-9 {
    inset-inline-end: -2.25rem;
  }

  .sm\:-end-96 {
    inset-inline-end: -24rem;
  }

  .sm\:-end-full {
    inset-inline-end: -100%;
  }

  .sm\:-end-px {
    inset-inline-end: -1px;
  }

  .sm\:-left-0 {
    left: 0;
  }

  .sm\:-left-0\.5 {
    left: -.125rem;
  }

  .sm\:-left-1 {
    left: -.25rem;
  }

  .sm\:-left-1\.5 {
    left: -.375rem;
  }

  .sm\:-left-1\/2 {
    left: -50%;
  }

  .sm\:-left-1\/3 {
    left: -33.3333%;
  }

  .sm\:-left-1\/4 {
    left: -25%;
  }

  .sm\:-left-10 {
    left: -2.5rem;
  }

  .sm\:-left-11 {
    left: -2.75rem;
  }

  .sm\:-left-12 {
    left: -3rem;
  }

  .sm\:-left-14 {
    left: -3.5rem;
  }

  .sm\:-left-16 {
    left: -4rem;
  }

  .sm\:-left-2 {
    left: -.5rem;
  }

  .sm\:-left-2\.5 {
    left: -.625rem;
  }

  .sm\:-left-2\/3 {
    left: -66.6667%;
  }

  .sm\:-left-2\/4 {
    left: -50%;
  }

  .sm\:-left-20 {
    left: -5rem;
  }

  .sm\:-left-24 {
    left: -6rem;
  }

  .sm\:-left-28 {
    left: -7rem;
  }

  .sm\:-left-3 {
    left: -.75rem;
  }

  .sm\:-left-3\.5 {
    left: -.875rem;
  }

  .sm\:-left-3\/4 {
    left: -75%;
  }

  .sm\:-left-32 {
    left: -8rem;
  }

  .sm\:-left-36 {
    left: -9rem;
  }

  .sm\:-left-4 {
    left: -1rem;
  }

  .sm\:-left-40 {
    left: -10rem;
  }

  .sm\:-left-44 {
    left: -11rem;
  }

  .sm\:-left-48 {
    left: -12rem;
  }

  .sm\:-left-5 {
    left: -1.25rem;
  }

  .sm\:-left-52 {
    left: -13rem;
  }

  .sm\:-left-56 {
    left: -14rem;
  }

  .sm\:-left-6 {
    left: -1.5rem;
  }

  .sm\:-left-60 {
    left: -15rem;
  }

  .sm\:-left-64 {
    left: -16rem;
  }

  .sm\:-left-7 {
    left: -1.75rem;
  }

  .sm\:-left-72 {
    left: -18rem;
  }

  .sm\:-left-8 {
    left: -2rem;
  }

  .sm\:-left-80 {
    left: -20rem;
  }

  .sm\:-left-9 {
    left: -2.25rem;
  }

  .sm\:-left-96 {
    left: -24rem;
  }

  .sm\:-left-full {
    left: -100%;
  }

  .sm\:-left-px {
    left: -1px;
  }

  .sm\:-right-0 {
    right: 0;
  }

  .sm\:-right-0\.5 {
    right: -.125rem;
  }

  .sm\:-right-1 {
    right: -.25rem;
  }

  .sm\:-right-1\.5 {
    right: -.375rem;
  }

  .sm\:-right-1\/2 {
    right: -50%;
  }

  .sm\:-right-1\/3 {
    right: -33.3333%;
  }

  .sm\:-right-1\/4 {
    right: -25%;
  }

  .sm\:-right-10 {
    right: -2.5rem;
  }

  .sm\:-right-11 {
    right: -2.75rem;
  }

  .sm\:-right-12 {
    right: -3rem;
  }

  .sm\:-right-14 {
    right: -3.5rem;
  }

  .sm\:-right-16 {
    right: -4rem;
  }

  .sm\:-right-2 {
    right: -.5rem;
  }

  .sm\:-right-2\.5 {
    right: -.625rem;
  }

  .sm\:-right-2\/3 {
    right: -66.6667%;
  }

  .sm\:-right-2\/4 {
    right: -50%;
  }

  .sm\:-right-20 {
    right: -5rem;
  }

  .sm\:-right-24 {
    right: -6rem;
  }

  .sm\:-right-28 {
    right: -7rem;
  }

  .sm\:-right-3 {
    right: -.75rem;
  }

  .sm\:-right-3\.5 {
    right: -.875rem;
  }

  .sm\:-right-3\/4 {
    right: -75%;
  }

  .sm\:-right-32 {
    right: -8rem;
  }

  .sm\:-right-36 {
    right: -9rem;
  }

  .sm\:-right-4 {
    right: -1rem;
  }

  .sm\:-right-40 {
    right: -10rem;
  }

  .sm\:-right-44 {
    right: -11rem;
  }

  .sm\:-right-48 {
    right: -12rem;
  }

  .sm\:-right-5 {
    right: -1.25rem;
  }

  .sm\:-right-52 {
    right: -13rem;
  }

  .sm\:-right-56 {
    right: -14rem;
  }

  .sm\:-right-6 {
    right: -1.5rem;
  }

  .sm\:-right-60 {
    right: -15rem;
  }

  .sm\:-right-64 {
    right: -16rem;
  }

  .sm\:-right-7 {
    right: -1.75rem;
  }

  .sm\:-right-72 {
    right: -18rem;
  }

  .sm\:-right-8 {
    right: -2rem;
  }

  .sm\:-right-80 {
    right: -20rem;
  }

  .sm\:-right-9 {
    right: -2.25rem;
  }

  .sm\:-right-96 {
    right: -24rem;
  }

  .sm\:-right-full {
    right: -100%;
  }

  .sm\:-right-px {
    right: -1px;
  }

  .sm\:-start-0 {
    inset-inline-start: 0;
  }

  .sm\:-start-0\.5 {
    inset-inline-start: -.125rem;
  }

  .sm\:-start-1 {
    inset-inline-start: -.25rem;
  }

  .sm\:-start-1\.5 {
    inset-inline-start: -.375rem;
  }

  .sm\:-start-1\/2 {
    inset-inline-start: -50%;
  }

  .sm\:-start-1\/3 {
    inset-inline-start: -33.3333%;
  }

  .sm\:-start-1\/4 {
    inset-inline-start: -25%;
  }

  .sm\:-start-10 {
    inset-inline-start: -2.5rem;
  }

  .sm\:-start-11 {
    inset-inline-start: -2.75rem;
  }

  .sm\:-start-12 {
    inset-inline-start: -3rem;
  }

  .sm\:-start-14 {
    inset-inline-start: -3.5rem;
  }

  .sm\:-start-16 {
    inset-inline-start: -4rem;
  }

  .sm\:-start-2 {
    inset-inline-start: -.5rem;
  }

  .sm\:-start-2\.5 {
    inset-inline-start: -.625rem;
  }

  .sm\:-start-2\/3 {
    inset-inline-start: -66.6667%;
  }

  .sm\:-start-2\/4 {
    inset-inline-start: -50%;
  }

  .sm\:-start-20 {
    inset-inline-start: -5rem;
  }

  .sm\:-start-24 {
    inset-inline-start: -6rem;
  }

  .sm\:-start-28 {
    inset-inline-start: -7rem;
  }

  .sm\:-start-3 {
    inset-inline-start: -.75rem;
  }

  .sm\:-start-3\.5 {
    inset-inline-start: -.875rem;
  }

  .sm\:-start-3\/4 {
    inset-inline-start: -75%;
  }

  .sm\:-start-32 {
    inset-inline-start: -8rem;
  }

  .sm\:-start-36 {
    inset-inline-start: -9rem;
  }

  .sm\:-start-4 {
    inset-inline-start: -1rem;
  }

  .sm\:-start-40 {
    inset-inline-start: -10rem;
  }

  .sm\:-start-44 {
    inset-inline-start: -11rem;
  }

  .sm\:-start-48 {
    inset-inline-start: -12rem;
  }

  .sm\:-start-5 {
    inset-inline-start: -1.25rem;
  }

  .sm\:-start-52 {
    inset-inline-start: -13rem;
  }

  .sm\:-start-56 {
    inset-inline-start: -14rem;
  }

  .sm\:-start-6 {
    inset-inline-start: -1.5rem;
  }

  .sm\:-start-60 {
    inset-inline-start: -15rem;
  }

  .sm\:-start-64 {
    inset-inline-start: -16rem;
  }

  .sm\:-start-7 {
    inset-inline-start: -1.75rem;
  }

  .sm\:-start-72 {
    inset-inline-start: -18rem;
  }

  .sm\:-start-8 {
    inset-inline-start: -2rem;
  }

  .sm\:-start-80 {
    inset-inline-start: -20rem;
  }

  .sm\:-start-9 {
    inset-inline-start: -2.25rem;
  }

  .sm\:-start-96 {
    inset-inline-start: -24rem;
  }

  .sm\:-start-full {
    inset-inline-start: -100%;
  }

  .sm\:-start-px {
    inset-inline-start: -1px;
  }

  .sm\:-top-0 {
    top: 0;
  }

  .sm\:-top-0\.5 {
    top: -.125rem;
  }

  .sm\:-top-1 {
    top: -.25rem;
  }

  .sm\:-top-1\.5 {
    top: -.375rem;
  }

  .sm\:-top-1\/2 {
    top: -50%;
  }

  .sm\:-top-1\/3 {
    top: -33.3333%;
  }

  .sm\:-top-1\/4 {
    top: -25%;
  }

  .sm\:-top-10 {
    top: -2.5rem;
  }

  .sm\:-top-11 {
    top: -2.75rem;
  }

  .sm\:-top-12 {
    top: -3rem;
  }

  .sm\:-top-14 {
    top: -3.5rem;
  }

  .sm\:-top-16 {
    top: -4rem;
  }

  .sm\:-top-2 {
    top: -.5rem;
  }

  .sm\:-top-2\.5 {
    top: -.625rem;
  }

  .sm\:-top-2\/3 {
    top: -66.6667%;
  }

  .sm\:-top-2\/4 {
    top: -50%;
  }

  .sm\:-top-20 {
    top: -5rem;
  }

  .sm\:-top-24 {
    top: -6rem;
  }

  .sm\:-top-28 {
    top: -7rem;
  }

  .sm\:-top-3 {
    top: -.75rem;
  }

  .sm\:-top-3\.5 {
    top: -.875rem;
  }

  .sm\:-top-3\/4 {
    top: -75%;
  }

  .sm\:-top-32 {
    top: -8rem;
  }

  .sm\:-top-36 {
    top: -9rem;
  }

  .sm\:-top-4 {
    top: -1rem;
  }

  .sm\:-top-40 {
    top: -10rem;
  }

  .sm\:-top-44 {
    top: -11rem;
  }

  .sm\:-top-48 {
    top: -12rem;
  }

  .sm\:-top-5 {
    top: -1.25rem;
  }

  .sm\:-top-52 {
    top: -13rem;
  }

  .sm\:-top-56 {
    top: -14rem;
  }

  .sm\:-top-6 {
    top: -1.5rem;
  }

  .sm\:-top-60 {
    top: -15rem;
  }

  .sm\:-top-64 {
    top: -16rem;
  }

  .sm\:-top-7 {
    top: -1.75rem;
  }

  .sm\:-top-72 {
    top: -18rem;
  }

  .sm\:-top-8 {
    top: -2rem;
  }

  .sm\:-top-80 {
    top: -20rem;
  }

  .sm\:-top-9 {
    top: -2.25rem;
  }

  .sm\:-top-96 {
    top: -24rem;
  }

  .sm\:-top-full {
    top: -100%;
  }

  .sm\:-top-px {
    top: -1px;
  }

  .sm\:bottom-0 {
    bottom: 0;
  }

  .sm\:bottom-0\.5 {
    bottom: .125rem;
  }

  .sm\:bottom-1 {
    bottom: .25rem;
  }

  .sm\:bottom-1\.5 {
    bottom: .375rem;
  }

  .sm\:bottom-1\/2 {
    bottom: 50%;
  }

  .sm\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .sm\:bottom-1\/4 {
    bottom: 25%;
  }

  .sm\:bottom-10 {
    bottom: 2.5rem;
  }

  .sm\:bottom-11 {
    bottom: 2.75rem;
  }

  .sm\:bottom-12 {
    bottom: 3rem;
  }

  .sm\:bottom-14 {
    bottom: 3.5rem;
  }

  .sm\:bottom-16 {
    bottom: 4rem;
  }

  .sm\:bottom-2 {
    bottom: .5rem;
  }

  .sm\:bottom-2\.5 {
    bottom: .625rem;
  }

  .sm\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .sm\:bottom-2\/4 {
    bottom: 50%;
  }

  .sm\:bottom-20 {
    bottom: 5rem;
  }

  .sm\:bottom-24 {
    bottom: 6rem;
  }

  .sm\:bottom-28 {
    bottom: 7rem;
  }

  .sm\:bottom-3 {
    bottom: .75rem;
  }

  .sm\:bottom-3\.5 {
    bottom: .875rem;
  }

  .sm\:bottom-3\/4 {
    bottom: 75%;
  }

  .sm\:bottom-32 {
    bottom: 8rem;
  }

  .sm\:bottom-36 {
    bottom: 9rem;
  }

  .sm\:bottom-4 {
    bottom: 1rem;
  }

  .sm\:bottom-40 {
    bottom: 10rem;
  }

  .sm\:bottom-44 {
    bottom: 11rem;
  }

  .sm\:bottom-48 {
    bottom: 12rem;
  }

  .sm\:bottom-5 {
    bottom: 1.25rem;
  }

  .sm\:bottom-52 {
    bottom: 13rem;
  }

  .sm\:bottom-56 {
    bottom: 14rem;
  }

  .sm\:bottom-6 {
    bottom: 1.5rem;
  }

  .sm\:bottom-60 {
    bottom: 15rem;
  }

  .sm\:bottom-64 {
    bottom: 16rem;
  }

  .sm\:bottom-7 {
    bottom: 1.75rem;
  }

  .sm\:bottom-72 {
    bottom: 18rem;
  }

  .sm\:bottom-8 {
    bottom: 2rem;
  }

  .sm\:bottom-80 {
    bottom: 20rem;
  }

  .sm\:bottom-9 {
    bottom: 2.25rem;
  }

  .sm\:bottom-96 {
    bottom: 24rem;
  }

  .sm\:bottom-auto {
    bottom: auto;
  }

  .sm\:bottom-full {
    bottom: 100%;
  }

  .sm\:bottom-px {
    bottom: 1px;
  }

  .sm\:end-0 {
    inset-inline-end: 0;
  }

  .sm\:end-0\.5 {
    inset-inline-end: .125rem;
  }

  .sm\:end-1 {
    inset-inline-end: .25rem;
  }

  .sm\:end-1\.5 {
    inset-inline-end: .375rem;
  }

  .sm\:end-1\/2 {
    inset-inline-end: 50%;
  }

  .sm\:end-1\/3 {
    inset-inline-end: 33.3333%;
  }

  .sm\:end-1\/4 {
    inset-inline-end: 25%;
  }

  .sm\:end-10 {
    inset-inline-end: 2.5rem;
  }

  .sm\:end-11 {
    inset-inline-end: 2.75rem;
  }

  .sm\:end-12 {
    inset-inline-end: 3rem;
  }

  .sm\:end-14 {
    inset-inline-end: 3.5rem;
  }

  .sm\:end-16 {
    inset-inline-end: 4rem;
  }

  .sm\:end-2 {
    inset-inline-end: .5rem;
  }

  .sm\:end-2\.5 {
    inset-inline-end: .625rem;
  }

  .sm\:end-2\/3 {
    inset-inline-end: 66.6667%;
  }

  .sm\:end-2\/4 {
    inset-inline-end: 50%;
  }

  .sm\:end-20 {
    inset-inline-end: 5rem;
  }

  .sm\:end-24 {
    inset-inline-end: 6rem;
  }

  .sm\:end-28 {
    inset-inline-end: 7rem;
  }

  .sm\:end-3 {
    inset-inline-end: .75rem;
  }

  .sm\:end-3\.5 {
    inset-inline-end: .875rem;
  }

  .sm\:end-3\/4 {
    inset-inline-end: 75%;
  }

  .sm\:end-32 {
    inset-inline-end: 8rem;
  }

  .sm\:end-36 {
    inset-inline-end: 9rem;
  }

  .sm\:end-4 {
    inset-inline-end: 1rem;
  }

  .sm\:end-40 {
    inset-inline-end: 10rem;
  }

  .sm\:end-44 {
    inset-inline-end: 11rem;
  }

  .sm\:end-48 {
    inset-inline-end: 12rem;
  }

  .sm\:end-5 {
    inset-inline-end: 1.25rem;
  }

  .sm\:end-52 {
    inset-inline-end: 13rem;
  }

  .sm\:end-56 {
    inset-inline-end: 14rem;
  }

  .sm\:end-6 {
    inset-inline-end: 1.5rem;
  }

  .sm\:end-60 {
    inset-inline-end: 15rem;
  }

  .sm\:end-64 {
    inset-inline-end: 16rem;
  }

  .sm\:end-7 {
    inset-inline-end: 1.75rem;
  }

  .sm\:end-72 {
    inset-inline-end: 18rem;
  }

  .sm\:end-8 {
    inset-inline-end: 2rem;
  }

  .sm\:end-80 {
    inset-inline-end: 20rem;
  }

  .sm\:end-9 {
    inset-inline-end: 2.25rem;
  }

  .sm\:end-96 {
    inset-inline-end: 24rem;
  }

  .sm\:end-auto {
    inset-inline-end: auto;
  }

  .sm\:end-full {
    inset-inline-end: 100%;
  }

  .sm\:end-px {
    inset-inline-end: 1px;
  }

  .sm\:left-0 {
    left: 0;
  }

  .sm\:left-0\.5 {
    left: .125rem;
  }

  .sm\:left-1 {
    left: .25rem;
  }

  .sm\:left-1\.5 {
    left: .375rem;
  }

  .sm\:left-1\/2 {
    left: 50%;
  }

  .sm\:left-1\/3 {
    left: 33.3333%;
  }

  .sm\:left-1\/4 {
    left: 25%;
  }

  .sm\:left-10 {
    left: 2.5rem;
  }

  .sm\:left-11 {
    left: 2.75rem;
  }

  .sm\:left-12 {
    left: 3rem;
  }

  .sm\:left-14 {
    left: 3.5rem;
  }

  .sm\:left-16 {
    left: 4rem;
  }

  .sm\:left-2 {
    left: .5rem;
  }

  .sm\:left-2\.5 {
    left: .625rem;
  }

  .sm\:left-2\/3 {
    left: 66.6667%;
  }

  .sm\:left-2\/4 {
    left: 50%;
  }

  .sm\:left-20 {
    left: 5rem;
  }

  .sm\:left-24 {
    left: 6rem;
  }

  .sm\:left-28 {
    left: 7rem;
  }

  .sm\:left-3 {
    left: .75rem;
  }

  .sm\:left-3\.5 {
    left: .875rem;
  }

  .sm\:left-3\/4 {
    left: 75%;
  }

  .sm\:left-32 {
    left: 8rem;
  }

  .sm\:left-36 {
    left: 9rem;
  }

  .sm\:left-4 {
    left: 1rem;
  }

  .sm\:left-40 {
    left: 10rem;
  }

  .sm\:left-44 {
    left: 11rem;
  }

  .sm\:left-48 {
    left: 12rem;
  }

  .sm\:left-5 {
    left: 1.25rem;
  }

  .sm\:left-52 {
    left: 13rem;
  }

  .sm\:left-56 {
    left: 14rem;
  }

  .sm\:left-6 {
    left: 1.5rem;
  }

  .sm\:left-60 {
    left: 15rem;
  }

  .sm\:left-64 {
    left: 16rem;
  }

  .sm\:left-7 {
    left: 1.75rem;
  }

  .sm\:left-72 {
    left: 18rem;
  }

  .sm\:left-8 {
    left: 2rem;
  }

  .sm\:left-80 {
    left: 20rem;
  }

  .sm\:left-9 {
    left: 2.25rem;
  }

  .sm\:left-96 {
    left: 24rem;
  }

  .sm\:left-auto {
    left: auto;
  }

  .sm\:left-full {
    left: 100%;
  }

  .sm\:left-px {
    left: 1px;
  }

  .sm\:right-0 {
    right: 0;
  }

  .sm\:right-0\.5 {
    right: .125rem;
  }

  .sm\:right-1 {
    right: .25rem;
  }

  .sm\:right-1\.5 {
    right: .375rem;
  }

  .sm\:right-1\/2 {
    right: 50%;
  }

  .sm\:right-1\/3 {
    right: 33.3333%;
  }

  .sm\:right-1\/4 {
    right: 25%;
  }

  .sm\:right-10 {
    right: 2.5rem;
  }

  .sm\:right-11 {
    right: 2.75rem;
  }

  .sm\:right-12 {
    right: 3rem;
  }

  .sm\:right-14 {
    right: 3.5rem;
  }

  .sm\:right-16 {
    right: 4rem;
  }

  .sm\:right-2 {
    right: .5rem;
  }

  .sm\:right-2\.5 {
    right: .625rem;
  }

  .sm\:right-2\/3 {
    right: 66.6667%;
  }

  .sm\:right-2\/4 {
    right: 50%;
  }

  .sm\:right-20 {
    right: 5rem;
  }

  .sm\:right-24 {
    right: 6rem;
  }

  .sm\:right-28 {
    right: 7rem;
  }

  .sm\:right-3 {
    right: .75rem;
  }

  .sm\:right-3\.5 {
    right: .875rem;
  }

  .sm\:right-3\/4 {
    right: 75%;
  }

  .sm\:right-32 {
    right: 8rem;
  }

  .sm\:right-36 {
    right: 9rem;
  }

  .sm\:right-4 {
    right: 1rem;
  }

  .sm\:right-40 {
    right: 10rem;
  }

  .sm\:right-44 {
    right: 11rem;
  }

  .sm\:right-48 {
    right: 12rem;
  }

  .sm\:right-5 {
    right: 1.25rem;
  }

  .sm\:right-52 {
    right: 13rem;
  }

  .sm\:right-56 {
    right: 14rem;
  }

  .sm\:right-6 {
    right: 1.5rem;
  }

  .sm\:right-60 {
    right: 15rem;
  }

  .sm\:right-64 {
    right: 16rem;
  }

  .sm\:right-7 {
    right: 1.75rem;
  }

  .sm\:right-72 {
    right: 18rem;
  }

  .sm\:right-8 {
    right: 2rem;
  }

  .sm\:right-80 {
    right: 20rem;
  }

  .sm\:right-9 {
    right: 2.25rem;
  }

  .sm\:right-96 {
    right: 24rem;
  }

  .sm\:right-auto {
    right: auto;
  }

  .sm\:right-full {
    right: 100%;
  }

  .sm\:right-px {
    right: 1px;
  }

  .sm\:start-0 {
    inset-inline-start: 0;
  }

  .sm\:start-0\.5 {
    inset-inline-start: .125rem;
  }

  .sm\:start-1 {
    inset-inline-start: .25rem;
  }

  .sm\:start-1\.5 {
    inset-inline-start: .375rem;
  }

  .sm\:start-1\/2 {
    inset-inline-start: 50%;
  }

  .sm\:start-1\/3 {
    inset-inline-start: 33.3333%;
  }

  .sm\:start-1\/4 {
    inset-inline-start: 25%;
  }

  .sm\:start-10 {
    inset-inline-start: 2.5rem;
  }

  .sm\:start-11 {
    inset-inline-start: 2.75rem;
  }

  .sm\:start-12 {
    inset-inline-start: 3rem;
  }

  .sm\:start-14 {
    inset-inline-start: 3.5rem;
  }

  .sm\:start-16 {
    inset-inline-start: 4rem;
  }

  .sm\:start-2 {
    inset-inline-start: .5rem;
  }

  .sm\:start-2\.5 {
    inset-inline-start: .625rem;
  }

  .sm\:start-2\/3 {
    inset-inline-start: 66.6667%;
  }

  .sm\:start-2\/4 {
    inset-inline-start: 50%;
  }

  .sm\:start-20 {
    inset-inline-start: 5rem;
  }

  .sm\:start-24 {
    inset-inline-start: 6rem;
  }

  .sm\:start-28 {
    inset-inline-start: 7rem;
  }

  .sm\:start-3 {
    inset-inline-start: .75rem;
  }

  .sm\:start-3\.5 {
    inset-inline-start: .875rem;
  }

  .sm\:start-3\/4 {
    inset-inline-start: 75%;
  }

  .sm\:start-32 {
    inset-inline-start: 8rem;
  }

  .sm\:start-36 {
    inset-inline-start: 9rem;
  }

  .sm\:start-4 {
    inset-inline-start: 1rem;
  }

  .sm\:start-40 {
    inset-inline-start: 10rem;
  }

  .sm\:start-44 {
    inset-inline-start: 11rem;
  }

  .sm\:start-48 {
    inset-inline-start: 12rem;
  }

  .sm\:start-5 {
    inset-inline-start: 1.25rem;
  }

  .sm\:start-52 {
    inset-inline-start: 13rem;
  }

  .sm\:start-56 {
    inset-inline-start: 14rem;
  }

  .sm\:start-6 {
    inset-inline-start: 1.5rem;
  }

  .sm\:start-60 {
    inset-inline-start: 15rem;
  }

  .sm\:start-64 {
    inset-inline-start: 16rem;
  }

  .sm\:start-7 {
    inset-inline-start: 1.75rem;
  }

  .sm\:start-72 {
    inset-inline-start: 18rem;
  }

  .sm\:start-8 {
    inset-inline-start: 2rem;
  }

  .sm\:start-80 {
    inset-inline-start: 20rem;
  }

  .sm\:start-9 {
    inset-inline-start: 2.25rem;
  }

  .sm\:start-96 {
    inset-inline-start: 24rem;
  }

  .sm\:start-auto {
    inset-inline-start: auto;
  }

  .sm\:start-full {
    inset-inline-start: 100%;
  }

  .sm\:start-px {
    inset-inline-start: 1px;
  }

  .sm\:top-0 {
    top: 0;
  }

  .sm\:top-0\.5 {
    top: .125rem;
  }

  .sm\:top-1 {
    top: .25rem;
  }

  .sm\:top-1\.5 {
    top: .375rem;
  }

  .sm\:top-1\/2 {
    top: 50%;
  }

  .sm\:top-1\/3 {
    top: 33.3333%;
  }

  .sm\:top-1\/4 {
    top: 25%;
  }

  .sm\:top-10 {
    top: 2.5rem;
  }

  .sm\:top-11 {
    top: 2.75rem;
  }

  .sm\:top-12 {
    top: 3rem;
  }

  .sm\:top-14 {
    top: 3.5rem;
  }

  .sm\:top-16 {
    top: 4rem;
  }

  .sm\:top-2 {
    top: .5rem;
  }

  .sm\:top-2\.5 {
    top: .625rem;
  }

  .sm\:top-2\/3 {
    top: 66.6667%;
  }

  .sm\:top-2\/4 {
    top: 50%;
  }

  .sm\:top-20 {
    top: 5rem;
  }

  .sm\:top-24 {
    top: 6rem;
  }

  .sm\:top-28 {
    top: 7rem;
  }

  .sm\:top-3 {
    top: .75rem;
  }

  .sm\:top-3\.5 {
    top: .875rem;
  }

  .sm\:top-3\/4 {
    top: 75%;
  }

  .sm\:top-32 {
    top: 8rem;
  }

  .sm\:top-36 {
    top: 9rem;
  }

  .sm\:top-4 {
    top: 1rem;
  }

  .sm\:top-40 {
    top: 10rem;
  }

  .sm\:top-44 {
    top: 11rem;
  }

  .sm\:top-48 {
    top: 12rem;
  }

  .sm\:top-5 {
    top: 1.25rem;
  }

  .sm\:top-52 {
    top: 13rem;
  }

  .sm\:top-56 {
    top: 14rem;
  }

  .sm\:top-6 {
    top: 1.5rem;
  }

  .sm\:top-60 {
    top: 15rem;
  }

  .sm\:top-64 {
    top: 16rem;
  }

  .sm\:top-7 {
    top: 1.75rem;
  }

  .sm\:top-72 {
    top: 18rem;
  }

  .sm\:top-8 {
    top: 2rem;
  }

  .sm\:top-80 {
    top: 20rem;
  }

  .sm\:top-9 {
    top: 2.25rem;
  }

  .sm\:top-96 {
    top: 24rem;
  }

  .sm\:top-auto {
    top: auto;
  }

  .sm\:top-full {
    top: 100%;
  }

  .sm\:top-px {
    top: 1px;
  }

  .sm\:isolate {
    isolation: isolate;
  }

  .sm\:isolation-auto {
    isolation: auto;
  }

  .sm\:-z-0 {
    z-index: 0;
  }

  .sm\:-z-10 {
    z-index: -10;
  }

  .sm\:-z-20 {
    z-index: -20;
  }

  .sm\:-z-30 {
    z-index: -30;
  }

  .sm\:-z-40 {
    z-index: -40;
  }

  .sm\:-z-50 {
    z-index: -50;
  }

  .sm\:z-0 {
    z-index: 0;
  }

  .sm\:z-10 {
    z-index: 10;
  }

  .sm\:z-20 {
    z-index: 20;
  }

  .sm\:z-30 {
    z-index: 30;
  }

  .sm\:z-40 {
    z-index: 40;
  }

  .sm\:z-50 {
    z-index: 50;
  }

  .sm\:z-auto {
    z-index: auto;
  }

  .sm\:-order-1 {
    order: -1;
  }

  .sm\:-order-10 {
    order: -10;
  }

  .sm\:-order-11 {
    order: -11;
  }

  .sm\:-order-12 {
    order: -12;
  }

  .sm\:-order-2 {
    order: -2;
  }

  .sm\:-order-3 {
    order: -3;
  }

  .sm\:-order-4 {
    order: -4;
  }

  .sm\:-order-5 {
    order: -5;
  }

  .sm\:-order-6 {
    order: -6;
  }

  .sm\:-order-7 {
    order: -7;
  }

  .sm\:-order-8 {
    order: -8;
  }

  .sm\:-order-9 {
    order: -9;
  }

  .sm\:-order-first {
    order: 9999;
  }

  .sm\:-order-last {
    order: -9999;
  }

  .sm\:-order-none {
    order: 0;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-10 {
    order: 10;
  }

  .sm\:order-11 {
    order: 11;
  }

  .sm\:order-12 {
    order: 12;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:order-3 {
    order: 3;
  }

  .sm\:order-4 {
    order: 4;
  }

  .sm\:order-5 {
    order: 5;
  }

  .sm\:order-6 {
    order: 6;
  }

  .sm\:order-7 {
    order: 7;
  }

  .sm\:order-8 {
    order: 8;
  }

  .sm\:order-9 {
    order: 9;
  }

  .sm\:order-first {
    order: -9999;
  }

  .sm\:order-last {
    order: 9999;
  }

  .sm\:order-none {
    order: 0;
  }

  .sm\:col-auto {
    grid-column: auto;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:col-span-full {
    grid-column: 1 / -1;
  }

  .sm\:-col-start-1 {
    grid-column-start: -1;
  }

  .sm\:-col-start-10 {
    grid-column-start: -10;
  }

  .sm\:-col-start-11 {
    grid-column-start: -11;
  }

  .sm\:-col-start-12 {
    grid-column-start: -12;
  }

  .sm\:-col-start-13 {
    grid-column-start: -13;
  }

  .sm\:-col-start-2 {
    grid-column-start: -2;
  }

  .sm\:-col-start-3 {
    grid-column-start: -3;
  }

  .sm\:-col-start-4 {
    grid-column-start: -4;
  }

  .sm\:-col-start-5 {
    grid-column-start: -5;
  }

  .sm\:-col-start-6 {
    grid-column-start: -6;
  }

  .sm\:-col-start-7 {
    grid-column-start: -7;
  }

  .sm\:-col-start-8 {
    grid-column-start: -8;
  }

  .sm\:-col-start-9 {
    grid-column-start: -9;
  }

  .sm\:col-start-1 {
    grid-column-start: 1;
  }

  .sm\:col-start-10 {
    grid-column-start: 10;
  }

  .sm\:col-start-11 {
    grid-column-start: 11;
  }

  .sm\:col-start-12 {
    grid-column-start: 12;
  }

  .sm\:col-start-13 {
    grid-column-start: 13;
  }

  .sm\:col-start-2 {
    grid-column-start: 2;
  }

  .sm\:col-start-3 {
    grid-column-start: 3;
  }

  .sm\:col-start-4 {
    grid-column-start: 4;
  }

  .sm\:col-start-5 {
    grid-column-start: 5;
  }

  .sm\:col-start-6 {
    grid-column-start: 6;
  }

  .sm\:col-start-7 {
    grid-column-start: 7;
  }

  .sm\:col-start-8 {
    grid-column-start: 8;
  }

  .sm\:col-start-9 {
    grid-column-start: 9;
  }

  .sm\:col-start-auto {
    grid-column-start: auto;
  }

  .sm\:-col-end-1 {
    grid-column-end: -1;
  }

  .sm\:-col-end-10 {
    grid-column-end: -10;
  }

  .sm\:-col-end-11 {
    grid-column-end: -11;
  }

  .sm\:-col-end-12 {
    grid-column-end: -12;
  }

  .sm\:-col-end-13 {
    grid-column-end: -13;
  }

  .sm\:-col-end-2 {
    grid-column-end: -2;
  }

  .sm\:-col-end-3 {
    grid-column-end: -3;
  }

  .sm\:-col-end-4 {
    grid-column-end: -4;
  }

  .sm\:-col-end-5 {
    grid-column-end: -5;
  }

  .sm\:-col-end-6 {
    grid-column-end: -6;
  }

  .sm\:-col-end-7 {
    grid-column-end: -7;
  }

  .sm\:-col-end-8 {
    grid-column-end: -8;
  }

  .sm\:-col-end-9 {
    grid-column-end: -9;
  }

  .sm\:col-end-1 {
    grid-column-end: 1;
  }

  .sm\:col-end-10 {
    grid-column-end: 10;
  }

  .sm\:col-end-11 {
    grid-column-end: 11;
  }

  .sm\:col-end-12 {
    grid-column-end: 12;
  }

  .sm\:col-end-13 {
    grid-column-end: 13;
  }

  .sm\:col-end-2 {
    grid-column-end: 2;
  }

  .sm\:col-end-3 {
    grid-column-end: 3;
  }

  .sm\:col-end-4 {
    grid-column-end: 4;
  }

  .sm\:col-end-5 {
    grid-column-end: 5;
  }

  .sm\:col-end-6 {
    grid-column-end: 6;
  }

  .sm\:col-end-7 {
    grid-column-end: 7;
  }

  .sm\:col-end-8 {
    grid-column-end: 8;
  }

  .sm\:col-end-9 {
    grid-column-end: 9;
  }

  .sm\:col-end-auto {
    grid-column-end: auto;
  }

  .sm\:row-auto {
    grid-row: auto;
  }

  .sm\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .sm\:row-span-10 {
    grid-row: span 10 / span 10;
  }

  .sm\:row-span-11 {
    grid-row: span 11 / span 11;
  }

  .sm\:row-span-12 {
    grid-row: span 12 / span 12;
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .sm\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .sm\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .sm\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .sm\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .sm\:row-span-7 {
    grid-row: span 7 / span 7;
  }

  .sm\:row-span-8 {
    grid-row: span 8 / span 8;
  }

  .sm\:row-span-9 {
    grid-row: span 9 / span 9;
  }

  .sm\:row-span-full {
    grid-row: 1 / -1;
  }

  .sm\:-row-start-1 {
    grid-row-start: -1;
  }

  .sm\:-row-start-10 {
    grid-row-start: -10;
  }

  .sm\:-row-start-11 {
    grid-row-start: -11;
  }

  .sm\:-row-start-12 {
    grid-row-start: -12;
  }

  .sm\:-row-start-13 {
    grid-row-start: -13;
  }

  .sm\:-row-start-2 {
    grid-row-start: -2;
  }

  .sm\:-row-start-3 {
    grid-row-start: -3;
  }

  .sm\:-row-start-4 {
    grid-row-start: -4;
  }

  .sm\:-row-start-5 {
    grid-row-start: -5;
  }

  .sm\:-row-start-6 {
    grid-row-start: -6;
  }

  .sm\:-row-start-7 {
    grid-row-start: -7;
  }

  .sm\:-row-start-8 {
    grid-row-start: -8;
  }

  .sm\:-row-start-9 {
    grid-row-start: -9;
  }

  .sm\:row-start-1 {
    grid-row-start: 1;
  }

  .sm\:row-start-10 {
    grid-row-start: 10;
  }

  .sm\:row-start-11 {
    grid-row-start: 11;
  }

  .sm\:row-start-12 {
    grid-row-start: 12;
  }

  .sm\:row-start-13 {
    grid-row-start: 13;
  }

  .sm\:row-start-2 {
    grid-row-start: 2;
  }

  .sm\:row-start-3 {
    grid-row-start: 3;
  }

  .sm\:row-start-4 {
    grid-row-start: 4;
  }

  .sm\:row-start-5 {
    grid-row-start: 5;
  }

  .sm\:row-start-6 {
    grid-row-start: 6;
  }

  .sm\:row-start-7 {
    grid-row-start: 7;
  }

  .sm\:row-start-8 {
    grid-row-start: 8;
  }

  .sm\:row-start-9 {
    grid-row-start: 9;
  }

  .sm\:row-start-auto {
    grid-row-start: auto;
  }

  .sm\:-row-end-1 {
    grid-row-end: -1;
  }

  .sm\:-row-end-10 {
    grid-row-end: -10;
  }

  .sm\:-row-end-11 {
    grid-row-end: -11;
  }

  .sm\:-row-end-12 {
    grid-row-end: -12;
  }

  .sm\:-row-end-13 {
    grid-row-end: -13;
  }

  .sm\:-row-end-2 {
    grid-row-end: -2;
  }

  .sm\:-row-end-3 {
    grid-row-end: -3;
  }

  .sm\:-row-end-4 {
    grid-row-end: -4;
  }

  .sm\:-row-end-5 {
    grid-row-end: -5;
  }

  .sm\:-row-end-6 {
    grid-row-end: -6;
  }

  .sm\:-row-end-7 {
    grid-row-end: -7;
  }

  .sm\:-row-end-8 {
    grid-row-end: -8;
  }

  .sm\:-row-end-9 {
    grid-row-end: -9;
  }

  .sm\:row-end-1 {
    grid-row-end: 1;
  }

  .sm\:row-end-10 {
    grid-row-end: 10;
  }

  .sm\:row-end-11 {
    grid-row-end: 11;
  }

  .sm\:row-end-12 {
    grid-row-end: 12;
  }

  .sm\:row-end-13 {
    grid-row-end: 13;
  }

  .sm\:row-end-2 {
    grid-row-end: 2;
  }

  .sm\:row-end-3 {
    grid-row-end: 3;
  }

  .sm\:row-end-4 {
    grid-row-end: 4;
  }

  .sm\:row-end-5 {
    grid-row-end: 5;
  }

  .sm\:row-end-6 {
    grid-row-end: 6;
  }

  .sm\:row-end-7 {
    grid-row-end: 7;
  }

  .sm\:row-end-8 {
    grid-row-end: 8;
  }

  .sm\:row-end-9 {
    grid-row-end: 9;
  }

  .sm\:row-end-auto {
    grid-row-end: auto;
  }

  .sm\:float-start {
    float: inline-start;
  }

  .sm\:float-end {
    float: inline-end;
  }

  .sm\:float-right {
    float: right;
  }

  .sm\:float-left {
    float: left;
  }

  .sm\:float-none {
    float: none;
  }

  .sm\:clear-start {
    clear: inline-start;
  }

  .sm\:clear-end {
    clear: inline-end;
  }

  .sm\:clear-left {
    clear: left;
  }

  .sm\:clear-right {
    clear: right;
  }

  .sm\:clear-both {
    clear: both;
  }

  .sm\:clear-none {
    clear: none;
  }

  .sm\:-m-0 {
    margin: 0;
  }

  .sm\:-m-0\.5 {
    margin: -.125rem;
  }

  .sm\:-m-1 {
    margin: -.25rem;
  }

  .sm\:-m-1\.5 {
    margin: -.375rem;
  }

  .sm\:-m-10 {
    margin: -2.5rem;
  }

  .sm\:-m-11 {
    margin: -2.75rem;
  }

  .sm\:-m-12 {
    margin: -3rem;
  }

  .sm\:-m-14 {
    margin: -3.5rem;
  }

  .sm\:-m-16 {
    margin: -4rem;
  }

  .sm\:-m-2 {
    margin: -.5rem;
  }

  .sm\:-m-2\.5 {
    margin: -.625rem;
  }

  .sm\:-m-20 {
    margin: -5rem;
  }

  .sm\:-m-24 {
    margin: -6rem;
  }

  .sm\:-m-28 {
    margin: -7rem;
  }

  .sm\:-m-3 {
    margin: -.75rem;
  }

  .sm\:-m-3\.5 {
    margin: -.875rem;
  }

  .sm\:-m-32 {
    margin: -8rem;
  }

  .sm\:-m-36 {
    margin: -9rem;
  }

  .sm\:-m-4 {
    margin: -1rem;
  }

  .sm\:-m-40 {
    margin: -10rem;
  }

  .sm\:-m-44 {
    margin: -11rem;
  }

  .sm\:-m-48 {
    margin: -12rem;
  }

  .sm\:-m-5 {
    margin: -1.25rem;
  }

  .sm\:-m-52 {
    margin: -13rem;
  }

  .sm\:-m-56 {
    margin: -14rem;
  }

  .sm\:-m-6 {
    margin: -1.5rem;
  }

  .sm\:-m-60 {
    margin: -15rem;
  }

  .sm\:-m-64 {
    margin: -16rem;
  }

  .sm\:-m-7 {
    margin: -1.75rem;
  }

  .sm\:-m-72 {
    margin: -18rem;
  }

  .sm\:-m-8 {
    margin: -2rem;
  }

  .sm\:-m-80 {
    margin: -20rem;
  }

  .sm\:-m-9 {
    margin: -2.25rem;
  }

  .sm\:-m-96 {
    margin: -24rem;
  }

  .sm\:-m-px {
    margin: -1px;
  }

  .sm\:m-0 {
    margin: 0;
  }

  .sm\:m-0\.5 {
    margin: .125rem;
  }

  .sm\:m-1 {
    margin: .25rem;
  }

  .sm\:m-1\.5 {
    margin: .375rem;
  }

  .sm\:m-10 {
    margin: 2.5rem;
  }

  .sm\:m-11 {
    margin: 2.75rem;
  }

  .sm\:m-12 {
    margin: 3rem;
  }

  .sm\:m-14 {
    margin: 3.5rem;
  }

  .sm\:m-16 {
    margin: 4rem;
  }

  .sm\:m-2 {
    margin: .5rem;
  }

  .sm\:m-2\.5 {
    margin: .625rem;
  }

  .sm\:m-20 {
    margin: 5rem;
  }

  .sm\:m-24 {
    margin: 6rem;
  }

  .sm\:m-28 {
    margin: 7rem;
  }

  .sm\:m-3 {
    margin: .75rem;
  }

  .sm\:m-3\.5 {
    margin: .875rem;
  }

  .sm\:m-32 {
    margin: 8rem;
  }

  .sm\:m-36 {
    margin: 9rem;
  }

  .sm\:m-4 {
    margin: 1rem;
  }

  .sm\:m-40 {
    margin: 10rem;
  }

  .sm\:m-44 {
    margin: 11rem;
  }

  .sm\:m-48 {
    margin: 12rem;
  }

  .sm\:m-5 {
    margin: 1.25rem;
  }

  .sm\:m-52 {
    margin: 13rem;
  }

  .sm\:m-56 {
    margin: 14rem;
  }

  .sm\:m-6 {
    margin: 1.5rem;
  }

  .sm\:m-60 {
    margin: 15rem;
  }

  .sm\:m-64 {
    margin: 16rem;
  }

  .sm\:m-7 {
    margin: 1.75rem;
  }

  .sm\:m-72 {
    margin: 18rem;
  }

  .sm\:m-8 {
    margin: 2rem;
  }

  .sm\:m-80 {
    margin: 20rem;
  }

  .sm\:m-9 {
    margin: 2.25rem;
  }

  .sm\:m-96 {
    margin: 24rem;
  }

  .sm\:m-auto {
    margin: auto;
  }

  .sm\:m-px {
    margin: 1px;
  }

  .sm\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .sm\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .sm\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .sm\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .sm\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .sm\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .sm\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .sm\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .sm\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .sm\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .sm\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .sm\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .sm\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .sm\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .sm\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .sm\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .sm\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .sm\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .sm\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .sm\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .sm\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .sm\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .sm\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .sm\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .sm\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .sm\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .sm\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .sm\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .sm\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .sm\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .sm\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .sm\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .sm\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .sm\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .sm\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .sm\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .sm\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .sm\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .sm\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .sm\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .sm\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .sm\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .sm\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .sm\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .sm\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .sm\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .sm\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .sm\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .sm\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .sm\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .sm\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .sm\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .sm\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .sm\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .sm\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .sm\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .sm\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .sm\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .sm\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .sm\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .sm\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .sm\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .sm\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .sm\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .sm\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .sm\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .sm\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .sm\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .sm\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .sm\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .sm\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .sm\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .sm\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .sm\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .sm\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .sm\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .sm\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .sm\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .sm\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .sm\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .sm\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .sm\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .sm\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .sm\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .sm\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .sm\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .sm\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .sm\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .sm\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .sm\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .sm\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .sm\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .sm\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sm\:-mb-0 {
    margin-bottom: 0;
  }

  .sm\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .sm\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .sm\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .sm\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .sm\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .sm\:-mb-12 {
    margin-bottom: -3rem;
  }

  .sm\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .sm\:-mb-16 {
    margin-bottom: -4rem;
  }

  .sm\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .sm\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .sm\:-mb-20 {
    margin-bottom: -5rem;
  }

  .sm\:-mb-24 {
    margin-bottom: -6rem;
  }

  .sm\:-mb-28 {
    margin-bottom: -7rem;
  }

  .sm\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .sm\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .sm\:-mb-32 {
    margin-bottom: -8rem;
  }

  .sm\:-mb-36 {
    margin-bottom: -9rem;
  }

  .sm\:-mb-4 {
    margin-bottom: -1rem;
  }

  .sm\:-mb-40 {
    margin-bottom: -10rem;
  }

  .sm\:-mb-44 {
    margin-bottom: -11rem;
  }

  .sm\:-mb-48 {
    margin-bottom: -12rem;
  }

  .sm\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .sm\:-mb-52 {
    margin-bottom: -13rem;
  }

  .sm\:-mb-56 {
    margin-bottom: -14rem;
  }

  .sm\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .sm\:-mb-60 {
    margin-bottom: -15rem;
  }

  .sm\:-mb-64 {
    margin-bottom: -16rem;
  }

  .sm\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .sm\:-mb-72 {
    margin-bottom: -18rem;
  }

  .sm\:-mb-8 {
    margin-bottom: -2rem;
  }

  .sm\:-mb-80 {
    margin-bottom: -20rem;
  }

  .sm\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .sm\:-mb-96 {
    margin-bottom: -24rem;
  }

  .sm\:-mb-px {
    margin-bottom: -1px;
  }

  .sm\:-me-0 {
    margin-inline-end: 0;
  }

  .sm\:-me-0\.5 {
    margin-inline-end: -.125rem;
  }

  .sm\:-me-1 {
    margin-inline-end: -.25rem;
  }

  .sm\:-me-1\.5 {
    margin-inline-end: -.375rem;
  }

  .sm\:-me-10 {
    margin-inline-end: -2.5rem;
  }

  .sm\:-me-11 {
    margin-inline-end: -2.75rem;
  }

  .sm\:-me-12 {
    margin-inline-end: -3rem;
  }

  .sm\:-me-14 {
    margin-inline-end: -3.5rem;
  }

  .sm\:-me-16 {
    margin-inline-end: -4rem;
  }

  .sm\:-me-2 {
    margin-inline-end: -.5rem;
  }

  .sm\:-me-2\.5 {
    margin-inline-end: -.625rem;
  }

  .sm\:-me-20 {
    margin-inline-end: -5rem;
  }

  .sm\:-me-24 {
    margin-inline-end: -6rem;
  }

  .sm\:-me-28 {
    margin-inline-end: -7rem;
  }

  .sm\:-me-3 {
    margin-inline-end: -.75rem;
  }

  .sm\:-me-3\.5 {
    margin-inline-end: -.875rem;
  }

  .sm\:-me-32 {
    margin-inline-end: -8rem;
  }

  .sm\:-me-36 {
    margin-inline-end: -9rem;
  }

  .sm\:-me-4 {
    margin-inline-end: -1rem;
  }

  .sm\:-me-40 {
    margin-inline-end: -10rem;
  }

  .sm\:-me-44 {
    margin-inline-end: -11rem;
  }

  .sm\:-me-48 {
    margin-inline-end: -12rem;
  }

  .sm\:-me-5 {
    margin-inline-end: -1.25rem;
  }

  .sm\:-me-52 {
    margin-inline-end: -13rem;
  }

  .sm\:-me-56 {
    margin-inline-end: -14rem;
  }

  .sm\:-me-6 {
    margin-inline-end: -1.5rem;
  }

  .sm\:-me-60 {
    margin-inline-end: -15rem;
  }

  .sm\:-me-64 {
    margin-inline-end: -16rem;
  }

  .sm\:-me-7 {
    margin-inline-end: -1.75rem;
  }

  .sm\:-me-72 {
    margin-inline-end: -18rem;
  }

  .sm\:-me-8 {
    margin-inline-end: -2rem;
  }

  .sm\:-me-80 {
    margin-inline-end: -20rem;
  }

  .sm\:-me-9 {
    margin-inline-end: -2.25rem;
  }

  .sm\:-me-96 {
    margin-inline-end: -24rem;
  }

  .sm\:-me-px {
    margin-inline-end: -1px;
  }

  .sm\:-ml-0 {
    margin-left: 0;
  }

  .sm\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .sm\:-ml-1 {
    margin-left: -.25rem;
  }

  .sm\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .sm\:-ml-10 {
    margin-left: -2.5rem;
  }

  .sm\:-ml-11 {
    margin-left: -2.75rem;
  }

  .sm\:-ml-12 {
    margin-left: -3rem;
  }

  .sm\:-ml-14 {
    margin-left: -3.5rem;
  }

  .sm\:-ml-16 {
    margin-left: -4rem;
  }

  .sm\:-ml-2 {
    margin-left: -.5rem;
  }

  .sm\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .sm\:-ml-20 {
    margin-left: -5rem;
  }

  .sm\:-ml-24 {
    margin-left: -6rem;
  }

  .sm\:-ml-28 {
    margin-left: -7rem;
  }

  .sm\:-ml-3 {
    margin-left: -.75rem;
  }

  .sm\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .sm\:-ml-32 {
    margin-left: -8rem;
  }

  .sm\:-ml-36 {
    margin-left: -9rem;
  }

  .sm\:-ml-4 {
    margin-left: -1rem;
  }

  .sm\:-ml-40 {
    margin-left: -10rem;
  }

  .sm\:-ml-44 {
    margin-left: -11rem;
  }

  .sm\:-ml-48 {
    margin-left: -12rem;
  }

  .sm\:-ml-5 {
    margin-left: -1.25rem;
  }

  .sm\:-ml-52 {
    margin-left: -13rem;
  }

  .sm\:-ml-56 {
    margin-left: -14rem;
  }

  .sm\:-ml-6 {
    margin-left: -1.5rem;
  }

  .sm\:-ml-60 {
    margin-left: -15rem;
  }

  .sm\:-ml-64 {
    margin-left: -16rem;
  }

  .sm\:-ml-7 {
    margin-left: -1.75rem;
  }

  .sm\:-ml-72 {
    margin-left: -18rem;
  }

  .sm\:-ml-8 {
    margin-left: -2rem;
  }

  .sm\:-ml-80 {
    margin-left: -20rem;
  }

  .sm\:-ml-9 {
    margin-left: -2.25rem;
  }

  .sm\:-ml-96 {
    margin-left: -24rem;
  }

  .sm\:-ml-px {
    margin-left: -1px;
  }

  .sm\:-mr-0 {
    margin-right: 0;
  }

  .sm\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .sm\:-mr-1 {
    margin-right: -.25rem;
  }

  .sm\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .sm\:-mr-10 {
    margin-right: -2.5rem;
  }

  .sm\:-mr-11 {
    margin-right: -2.75rem;
  }

  .sm\:-mr-12 {
    margin-right: -3rem;
  }

  .sm\:-mr-14 {
    margin-right: -3.5rem;
  }

  .sm\:-mr-16 {
    margin-right: -4rem;
  }

  .sm\:-mr-2 {
    margin-right: -.5rem;
  }

  .sm\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .sm\:-mr-20 {
    margin-right: -5rem;
  }

  .sm\:-mr-24 {
    margin-right: -6rem;
  }

  .sm\:-mr-28 {
    margin-right: -7rem;
  }

  .sm\:-mr-3 {
    margin-right: -.75rem;
  }

  .sm\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .sm\:-mr-32 {
    margin-right: -8rem;
  }

  .sm\:-mr-36 {
    margin-right: -9rem;
  }

  .sm\:-mr-4 {
    margin-right: -1rem;
  }

  .sm\:-mr-40 {
    margin-right: -10rem;
  }

  .sm\:-mr-44 {
    margin-right: -11rem;
  }

  .sm\:-mr-48 {
    margin-right: -12rem;
  }

  .sm\:-mr-5 {
    margin-right: -1.25rem;
  }

  .sm\:-mr-52 {
    margin-right: -13rem;
  }

  .sm\:-mr-56 {
    margin-right: -14rem;
  }

  .sm\:-mr-6 {
    margin-right: -1.5rem;
  }

  .sm\:-mr-60 {
    margin-right: -15rem;
  }

  .sm\:-mr-64 {
    margin-right: -16rem;
  }

  .sm\:-mr-7 {
    margin-right: -1.75rem;
  }

  .sm\:-mr-72 {
    margin-right: -18rem;
  }

  .sm\:-mr-8 {
    margin-right: -2rem;
  }

  .sm\:-mr-80 {
    margin-right: -20rem;
  }

  .sm\:-mr-9 {
    margin-right: -2.25rem;
  }

  .sm\:-mr-96 {
    margin-right: -24rem;
  }

  .sm\:-mr-px {
    margin-right: -1px;
  }

  .sm\:-ms-0 {
    margin-inline-start: 0;
  }

  .sm\:-ms-0\.5 {
    margin-inline-start: -.125rem;
  }

  .sm\:-ms-1 {
    margin-inline-start: -.25rem;
  }

  .sm\:-ms-1\.5 {
    margin-inline-start: -.375rem;
  }

  .sm\:-ms-10 {
    margin-inline-start: -2.5rem;
  }

  .sm\:-ms-11 {
    margin-inline-start: -2.75rem;
  }

  .sm\:-ms-12 {
    margin-inline-start: -3rem;
  }

  .sm\:-ms-14 {
    margin-inline-start: -3.5rem;
  }

  .sm\:-ms-16 {
    margin-inline-start: -4rem;
  }

  .sm\:-ms-2 {
    margin-inline-start: -.5rem;
  }

  .sm\:-ms-2\.5 {
    margin-inline-start: -.625rem;
  }

  .sm\:-ms-20 {
    margin-inline-start: -5rem;
  }

  .sm\:-ms-24 {
    margin-inline-start: -6rem;
  }

  .sm\:-ms-28 {
    margin-inline-start: -7rem;
  }

  .sm\:-ms-3 {
    margin-inline-start: -.75rem;
  }

  .sm\:-ms-3\.5 {
    margin-inline-start: -.875rem;
  }

  .sm\:-ms-32 {
    margin-inline-start: -8rem;
  }

  .sm\:-ms-36 {
    margin-inline-start: -9rem;
  }

  .sm\:-ms-4 {
    margin-inline-start: -1rem;
  }

  .sm\:-ms-40 {
    margin-inline-start: -10rem;
  }

  .sm\:-ms-44 {
    margin-inline-start: -11rem;
  }

  .sm\:-ms-48 {
    margin-inline-start: -12rem;
  }

  .sm\:-ms-5 {
    margin-inline-start: -1.25rem;
  }

  .sm\:-ms-52 {
    margin-inline-start: -13rem;
  }

  .sm\:-ms-56 {
    margin-inline-start: -14rem;
  }

  .sm\:-ms-6 {
    margin-inline-start: -1.5rem;
  }

  .sm\:-ms-60 {
    margin-inline-start: -15rem;
  }

  .sm\:-ms-64 {
    margin-inline-start: -16rem;
  }

  .sm\:-ms-7 {
    margin-inline-start: -1.75rem;
  }

  .sm\:-ms-72 {
    margin-inline-start: -18rem;
  }

  .sm\:-ms-8 {
    margin-inline-start: -2rem;
  }

  .sm\:-ms-80 {
    margin-inline-start: -20rem;
  }

  .sm\:-ms-9 {
    margin-inline-start: -2.25rem;
  }

  .sm\:-ms-96 {
    margin-inline-start: -24rem;
  }

  .sm\:-ms-px {
    margin-inline-start: -1px;
  }

  .sm\:-mt-0 {
    margin-top: 0;
  }

  .sm\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .sm\:-mt-1 {
    margin-top: -.25rem;
  }

  .sm\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .sm\:-mt-10 {
    margin-top: -2.5rem;
  }

  .sm\:-mt-11 {
    margin-top: -2.75rem;
  }

  .sm\:-mt-12 {
    margin-top: -3rem;
  }

  .sm\:-mt-14 {
    margin-top: -3.5rem;
  }

  .sm\:-mt-16 {
    margin-top: -4rem;
  }

  .sm\:-mt-2 {
    margin-top: -.5rem;
  }

  .sm\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .sm\:-mt-20 {
    margin-top: -5rem;
  }

  .sm\:-mt-24 {
    margin-top: -6rem;
  }

  .sm\:-mt-28 {
    margin-top: -7rem;
  }

  .sm\:-mt-3 {
    margin-top: -.75rem;
  }

  .sm\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .sm\:-mt-32 {
    margin-top: -8rem;
  }

  .sm\:-mt-36 {
    margin-top: -9rem;
  }

  .sm\:-mt-4 {
    margin-top: -1rem;
  }

  .sm\:-mt-40 {
    margin-top: -10rem;
  }

  .sm\:-mt-44 {
    margin-top: -11rem;
  }

  .sm\:-mt-48 {
    margin-top: -12rem;
  }

  .sm\:-mt-5 {
    margin-top: -1.25rem;
  }

  .sm\:-mt-52 {
    margin-top: -13rem;
  }

  .sm\:-mt-56 {
    margin-top: -14rem;
  }

  .sm\:-mt-6 {
    margin-top: -1.5rem;
  }

  .sm\:-mt-60 {
    margin-top: -15rem;
  }

  .sm\:-mt-64 {
    margin-top: -16rem;
  }

  .sm\:-mt-7 {
    margin-top: -1.75rem;
  }

  .sm\:-mt-72 {
    margin-top: -18rem;
  }

  .sm\:-mt-8 {
    margin-top: -2rem;
  }

  .sm\:-mt-80 {
    margin-top: -20rem;
  }

  .sm\:-mt-9 {
    margin-top: -2.25rem;
  }

  .sm\:-mt-96 {
    margin-top: -24rem;
  }

  .sm\:-mt-px {
    margin-top: -1px;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .sm\:mb-1 {
    margin-bottom: .25rem;
  }

  .sm\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .sm\:mb-16 {
    margin-bottom: 4rem;
  }

  .sm\:mb-2 {
    margin-bottom: .5rem;
  }

  .sm\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .sm\:mb-20 {
    margin-bottom: 5rem;
  }

  .sm\:mb-24 {
    margin-bottom: 6rem;
  }

  .sm\:mb-28 {
    margin-bottom: 7rem;
  }

  .sm\:mb-3 {
    margin-bottom: .75rem;
  }

  .sm\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .sm\:mb-32 {
    margin-bottom: 8rem;
  }

  .sm\:mb-36 {
    margin-bottom: 9rem;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:mb-40 {
    margin-bottom: 10rem;
  }

  .sm\:mb-44 {
    margin-bottom: 11rem;
  }

  .sm\:mb-48 {
    margin-bottom: 12rem;
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm\:mb-52 {
    margin-bottom: 13rem;
  }

  .sm\:mb-56 {
    margin-bottom: 14rem;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:mb-60 {
    margin-bottom: 15rem;
  }

  .sm\:mb-64 {
    margin-bottom: 16rem;
  }

  .sm\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .sm\:mb-72 {
    margin-bottom: 18rem;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:mb-80 {
    margin-bottom: 20rem;
  }

  .sm\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .sm\:mb-96 {
    margin-bottom: 24rem;
  }

  .sm\:mb-auto {
    margin-bottom: auto;
  }

  .sm\:mb-px {
    margin-bottom: 1px;
  }

  .sm\:me-0 {
    margin-inline-end: 0;
  }

  .sm\:me-0\.5 {
    margin-inline-end: .125rem;
  }

  .sm\:me-1 {
    margin-inline-end: .25rem;
  }

  .sm\:me-1\.5 {
    margin-inline-end: .375rem;
  }

  .sm\:me-10 {
    margin-inline-end: 2.5rem;
  }

  .sm\:me-11 {
    margin-inline-end: 2.75rem;
  }

  .sm\:me-12 {
    margin-inline-end: 3rem;
  }

  .sm\:me-14 {
    margin-inline-end: 3.5rem;
  }

  .sm\:me-16 {
    margin-inline-end: 4rem;
  }

  .sm\:me-2 {
    margin-inline-end: .5rem;
  }

  .sm\:me-2\.5 {
    margin-inline-end: .625rem;
  }

  .sm\:me-20 {
    margin-inline-end: 5rem;
  }

  .sm\:me-24 {
    margin-inline-end: 6rem;
  }

  .sm\:me-28 {
    margin-inline-end: 7rem;
  }

  .sm\:me-3 {
    margin-inline-end: .75rem;
  }

  .sm\:me-3\.5 {
    margin-inline-end: .875rem;
  }

  .sm\:me-32 {
    margin-inline-end: 8rem;
  }

  .sm\:me-36 {
    margin-inline-end: 9rem;
  }

  .sm\:me-4 {
    margin-inline-end: 1rem;
  }

  .sm\:me-40 {
    margin-inline-end: 10rem;
  }

  .sm\:me-44 {
    margin-inline-end: 11rem;
  }

  .sm\:me-48 {
    margin-inline-end: 12rem;
  }

  .sm\:me-5 {
    margin-inline-end: 1.25rem;
  }

  .sm\:me-52 {
    margin-inline-end: 13rem;
  }

  .sm\:me-56 {
    margin-inline-end: 14rem;
  }

  .sm\:me-6 {
    margin-inline-end: 1.5rem;
  }

  .sm\:me-60 {
    margin-inline-end: 15rem;
  }

  .sm\:me-64 {
    margin-inline-end: 16rem;
  }

  .sm\:me-7 {
    margin-inline-end: 1.75rem;
  }

  .sm\:me-72 {
    margin-inline-end: 18rem;
  }

  .sm\:me-8 {
    margin-inline-end: 2rem;
  }

  .sm\:me-80 {
    margin-inline-end: 20rem;
  }

  .sm\:me-9 {
    margin-inline-end: 2.25rem;
  }

  .sm\:me-96 {
    margin-inline-end: 24rem;
  }

  .sm\:me-auto {
    margin-inline-end: auto;
  }

  .sm\:me-px {
    margin-inline-end: 1px;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-0\.5 {
    margin-left: .125rem;
  }

  .sm\:ml-1 {
    margin-left: .25rem;
  }

  .sm\:ml-1\.5 {
    margin-left: .375rem;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:ml-11 {
    margin-left: 2.75rem;
  }

  .sm\:ml-12 {
    margin-left: 3rem;
  }

  .sm\:ml-14 {
    margin-left: 3.5rem;
  }

  .sm\:ml-16 {
    margin-left: 4rem;
  }

  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:ml-2\.5 {
    margin-left: .625rem;
  }

  .sm\:ml-20 {
    margin-left: 5rem;
  }

  .sm\:ml-24 {
    margin-left: 6rem;
  }

  .sm\:ml-28 {
    margin-left: 7rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:ml-3\.5 {
    margin-left: .875rem;
  }

  .sm\:ml-32 {
    margin-left: 8rem;
  }

  .sm\:ml-36 {
    margin-left: 9rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-40 {
    margin-left: 10rem;
  }

  .sm\:ml-44 {
    margin-left: 11rem;
  }

  .sm\:ml-48 {
    margin-left: 12rem;
  }

  .sm\:ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:ml-52 {
    margin-left: 13rem;
  }

  .sm\:ml-56 {
    margin-left: 14rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:ml-60 {
    margin-left: 15rem;
  }

  .sm\:ml-64 {
    margin-left: 16rem;
  }

  .sm\:ml-7 {
    margin-left: 1.75rem;
  }

  .sm\:ml-72 {
    margin-left: 18rem;
  }

  .sm\:ml-8 {
    margin-left: 2rem;
  }

  .sm\:ml-80 {
    margin-left: 20rem;
  }

  .sm\:ml-9 {
    margin-left: 2.25rem;
  }

  .sm\:ml-96 {
    margin-left: 24rem;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:ml-px {
    margin-left: 1px;
  }

  .sm\:mr-0 {
    margin-right: 0;
  }

  .sm\:mr-0\.5 {
    margin-right: .125rem;
  }

  .sm\:mr-1 {
    margin-right: .25rem;
  }

  .sm\:mr-1\.5 {
    margin-right: .375rem;
  }

  .sm\:mr-10 {
    margin-right: 2.5rem;
  }

  .sm\:mr-11 {
    margin-right: 2.75rem;
  }

  .sm\:mr-12 {
    margin-right: 3rem;
  }

  .sm\:mr-14 {
    margin-right: 3.5rem;
  }

  .sm\:mr-16 {
    margin-right: 4rem;
  }

  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:mr-2\.5 {
    margin-right: .625rem;
  }

  .sm\:mr-20 {
    margin-right: 5rem;
  }

  .sm\:mr-24 {
    margin-right: 6rem;
  }

  .sm\:mr-28 {
    margin-right: 7rem;
  }

  .sm\:mr-3 {
    margin-right: .75rem;
  }

  .sm\:mr-3\.5 {
    margin-right: .875rem;
  }

  .sm\:mr-32 {
    margin-right: 8rem;
  }

  .sm\:mr-36 {
    margin-right: 9rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:mr-40 {
    margin-right: 10rem;
  }

  .sm\:mr-44 {
    margin-right: 11rem;
  }

  .sm\:mr-48 {
    margin-right: 12rem;
  }

  .sm\:mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:mr-52 {
    margin-right: 13rem;
  }

  .sm\:mr-56 {
    margin-right: 14rem;
  }

  .sm\:mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:mr-60 {
    margin-right: 15rem;
  }

  .sm\:mr-64 {
    margin-right: 16rem;
  }

  .sm\:mr-7 {
    margin-right: 1.75rem;
  }

  .sm\:mr-72 {
    margin-right: 18rem;
  }

  .sm\:mr-8 {
    margin-right: 2rem;
  }

  .sm\:mr-80 {
    margin-right: 20rem;
  }

  .sm\:mr-9 {
    margin-right: 2.25rem;
  }

  .sm\:mr-96 {
    margin-right: 24rem;
  }

  .sm\:mr-auto {
    margin-right: auto;
  }

  .sm\:mr-px {
    margin-right: 1px;
  }

  .sm\:ms-0 {
    margin-inline-start: 0;
  }

  .sm\:ms-0\.5 {
    margin-inline-start: .125rem;
  }

  .sm\:ms-1 {
    margin-inline-start: .25rem;
  }

  .sm\:ms-1\.5 {
    margin-inline-start: .375rem;
  }

  .sm\:ms-10 {
    margin-inline-start: 2.5rem;
  }

  .sm\:ms-11 {
    margin-inline-start: 2.75rem;
  }

  .sm\:ms-12 {
    margin-inline-start: 3rem;
  }

  .sm\:ms-14 {
    margin-inline-start: 3.5rem;
  }

  .sm\:ms-16 {
    margin-inline-start: 4rem;
  }

  .sm\:ms-2 {
    margin-inline-start: .5rem;
  }

  .sm\:ms-2\.5 {
    margin-inline-start: .625rem;
  }

  .sm\:ms-20 {
    margin-inline-start: 5rem;
  }

  .sm\:ms-24 {
    margin-inline-start: 6rem;
  }

  .sm\:ms-28 {
    margin-inline-start: 7rem;
  }

  .sm\:ms-3 {
    margin-inline-start: .75rem;
  }

  .sm\:ms-3\.5 {
    margin-inline-start: .875rem;
  }

  .sm\:ms-32 {
    margin-inline-start: 8rem;
  }

  .sm\:ms-36 {
    margin-inline-start: 9rem;
  }

  .sm\:ms-4 {
    margin-inline-start: 1rem;
  }

  .sm\:ms-40 {
    margin-inline-start: 10rem;
  }

  .sm\:ms-44 {
    margin-inline-start: 11rem;
  }

  .sm\:ms-48 {
    margin-inline-start: 12rem;
  }

  .sm\:ms-5 {
    margin-inline-start: 1.25rem;
  }

  .sm\:ms-52 {
    margin-inline-start: 13rem;
  }

  .sm\:ms-56 {
    margin-inline-start: 14rem;
  }

  .sm\:ms-6 {
    margin-inline-start: 1.5rem;
  }

  .sm\:ms-60 {
    margin-inline-start: 15rem;
  }

  .sm\:ms-64 {
    margin-inline-start: 16rem;
  }

  .sm\:ms-7 {
    margin-inline-start: 1.75rem;
  }

  .sm\:ms-72 {
    margin-inline-start: 18rem;
  }

  .sm\:ms-8 {
    margin-inline-start: 2rem;
  }

  .sm\:ms-80 {
    margin-inline-start: 20rem;
  }

  .sm\:ms-9 {
    margin-inline-start: 2.25rem;
  }

  .sm\:ms-96 {
    margin-inline-start: 24rem;
  }

  .sm\:ms-auto {
    margin-inline-start: auto;
  }

  .sm\:ms-px {
    margin-inline-start: 1px;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-0\.5 {
    margin-top: .125rem;
  }

  .sm\:mt-1 {
    margin-top: .25rem;
  }

  .sm\:mt-1\.5 {
    margin-top: .375rem;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-11 {
    margin-top: 2.75rem;
  }

  .sm\:mt-12 {
    margin-top: 3rem;
  }

  .sm\:mt-14 {
    margin-top: 3.5rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:mt-2 {
    margin-top: .5rem;
  }

  .sm\:mt-2\.5 {
    margin-top: .625rem;
  }

  .sm\:mt-20 {
    margin-top: 5rem;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:mt-28 {
    margin-top: 7rem;
  }

  .sm\:mt-3 {
    margin-top: .75rem;
  }

  .sm\:mt-3\.5 {
    margin-top: .875rem;
  }

  .sm\:mt-32 {
    margin-top: 8rem;
  }

  .sm\:mt-36 {
    margin-top: 9rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mt-40 {
    margin-top: 10rem;
  }

  .sm\:mt-44 {
    margin-top: 11rem;
  }

  .sm\:mt-48 {
    margin-top: 12rem;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:mt-52 {
    margin-top: 13rem;
  }

  .sm\:mt-56 {
    margin-top: 14rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:mt-60 {
    margin-top: 15rem;
  }

  .sm\:mt-64 {
    margin-top: 16rem;
  }

  .sm\:mt-7 {
    margin-top: 1.75rem;
  }

  .sm\:mt-72 {
    margin-top: 18rem;
  }

  .sm\:mt-8 {
    margin-top: 2rem;
  }

  .sm\:mt-80 {
    margin-top: 20rem;
  }

  .sm\:mt-9 {
    margin-top: 2.25rem;
  }

  .sm\:mt-96 {
    margin-top: 24rem;
  }

  .sm\:mt-auto {
    margin-top: auto;
  }

  .sm\:mt-px {
    margin-top: 1px;
  }

  .sm\:box-border {
    box-sizing: border-box;
  }

  .sm\:box-content {
    box-sizing: content-box;
  }

  .sm\:line-clamp-1 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .sm\:line-clamp-2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .sm\:line-clamp-3 {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .sm\:line-clamp-4 {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .sm\:line-clamp-5 {
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .sm\:line-clamp-6 {
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .sm\:line-clamp-none {
    -webkit-line-clamp: none;
    -webkit-box-orient: horizontal;
    display: block;
    overflow: visible;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:table {
    display: table;
  }

  .sm\:inline-table {
    display: inline-table;
  }

  .sm\:table-caption {
    display: table-caption;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:table-column {
    display: table-column;
  }

  .sm\:table-column-group {
    display: table-column-group;
  }

  .sm\:table-footer-group {
    display: table-footer-group;
  }

  .sm\:table-header-group {
    display: table-header-group;
  }

  .sm\:table-row-group {
    display: table-row-group;
  }

  .sm\:table-row {
    display: table-row;
  }

  .sm\:flow-root {
    display: flow-root;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:inline-grid {
    display: inline-grid;
  }

  .sm\:contents {
    display: contents;
  }

  .sm\:list-item {
    display: list-item;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:aspect-auto {
    aspect-ratio: auto;
  }

  .sm\:aspect-square {
    aspect-ratio: 1;
  }

  .sm\:aspect-video {
    aspect-ratio: 16 / 9;
  }

  .sm\:size-0 {
    width: 0;
    height: 0;
  }

  .sm\:size-0\.5 {
    width: .125rem;
    height: .125rem;
  }

  .sm\:size-1 {
    width: .25rem;
    height: .25rem;
  }

  .sm\:size-1\.5 {
    width: .375rem;
    height: .375rem;
  }

  .sm\:size-1\/12 {
    width: 8.33333%;
    height: 8.33333%;
  }

  .sm\:size-1\/2 {
    width: 50%;
    height: 50%;
  }

  .sm\:size-1\/3 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .sm\:size-1\/4 {
    width: 25%;
    height: 25%;
  }

  .sm\:size-1\/5 {
    width: 20%;
    height: 20%;
  }

  .sm\:size-1\/6 {
    width: 16.6667%;
    height: 16.6667%;
  }

  .sm\:size-10 {
    width: 2.5rem;
    height: 2.5rem;
  }

  .sm\:size-10\/12 {
    width: 83.3333%;
    height: 83.3333%;
  }

  .sm\:size-11 {
    width: 2.75rem;
    height: 2.75rem;
  }

  .sm\:size-11\/12 {
    width: 91.6667%;
    height: 91.6667%;
  }

  .sm\:size-12 {
    width: 3rem;
    height: 3rem;
  }

  .sm\:size-14 {
    width: 3.5rem;
    height: 3.5rem;
  }

  .sm\:size-16 {
    width: 4rem;
    height: 4rem;
  }

  .sm\:size-2 {
    width: .5rem;
    height: .5rem;
  }

  .sm\:size-2\.5 {
    width: .625rem;
    height: .625rem;
  }

  .sm\:size-2\/12 {
    width: 16.6667%;
    height: 16.6667%;
  }

  .sm\:size-2\/3 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .sm\:size-2\/4 {
    width: 50%;
    height: 50%;
  }

  .sm\:size-2\/5 {
    width: 40%;
    height: 40%;
  }

  .sm\:size-2\/6 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .sm\:size-20 {
    width: 5rem;
    height: 5rem;
  }

  .sm\:size-24 {
    width: 6rem;
    height: 6rem;
  }

  .sm\:size-28 {
    width: 7rem;
    height: 7rem;
  }

  .sm\:size-3 {
    width: .75rem;
    height: .75rem;
  }

  .sm\:size-3\.5 {
    width: .875rem;
    height: .875rem;
  }

  .sm\:size-3\/12 {
    width: 25%;
    height: 25%;
  }

  .sm\:size-3\/4 {
    width: 75%;
    height: 75%;
  }

  .sm\:size-3\/5 {
    width: 60%;
    height: 60%;
  }

  .sm\:size-3\/6 {
    width: 50%;
    height: 50%;
  }

  .sm\:size-32 {
    width: 8rem;
    height: 8rem;
  }

  .sm\:size-36 {
    width: 9rem;
    height: 9rem;
  }

  .sm\:size-4 {
    width: 1rem;
    height: 1rem;
  }

  .sm\:size-4\/12 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .sm\:size-4\/5 {
    width: 80%;
    height: 80%;
  }

  .sm\:size-4\/6 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .sm\:size-40 {
    width: 10rem;
    height: 10rem;
  }

  .sm\:size-44 {
    width: 11rem;
    height: 11rem;
  }

  .sm\:size-48 {
    width: 12rem;
    height: 12rem;
  }

  .sm\:size-5 {
    width: 1.25rem;
    height: 1.25rem;
  }

  .sm\:size-5\/12 {
    width: 41.6667%;
    height: 41.6667%;
  }

  .sm\:size-5\/6 {
    width: 83.3333%;
    height: 83.3333%;
  }

  .sm\:size-52 {
    width: 13rem;
    height: 13rem;
  }

  .sm\:size-56 {
    width: 14rem;
    height: 14rem;
  }

  .sm\:size-6 {
    width: 1.5rem;
    height: 1.5rem;
  }

  .sm\:size-6\/12 {
    width: 50%;
    height: 50%;
  }

  .sm\:size-60 {
    width: 15rem;
    height: 15rem;
  }

  .sm\:size-64 {
    width: 16rem;
    height: 16rem;
  }

  .sm\:size-7 {
    width: 1.75rem;
    height: 1.75rem;
  }

  .sm\:size-7\/12 {
    width: 58.3333%;
    height: 58.3333%;
  }

  .sm\:size-72 {
    width: 18rem;
    height: 18rem;
  }

  .sm\:size-8 {
    width: 2rem;
    height: 2rem;
  }

  .sm\:size-8\/12 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .sm\:size-80 {
    width: 20rem;
    height: 20rem;
  }

  .sm\:size-9 {
    width: 2.25rem;
    height: 2.25rem;
  }

  .sm\:size-9\/12 {
    width: 75%;
    height: 75%;
  }

  .sm\:size-96 {
    width: 24rem;
    height: 24rem;
  }

  .sm\:size-auto {
    width: auto;
    height: auto;
  }

  .sm\:size-fit {
    width: fit-content;
    height: fit-content;
  }

  .sm\:size-full {
    width: 100%;
    height: 100%;
  }

  .sm\:size-max {
    width: max-content;
    height: max-content;
  }

  .sm\:size-min {
    width: min-content;
    height: min-content;
  }

  .sm\:size-px {
    width: 1px;
    height: 1px;
  }

  .sm\:h-0 {
    height: 0;
  }

  .sm\:h-0\.5 {
    height: .125rem;
  }

  .sm\:h-1 {
    height: .25rem;
  }

  .sm\:h-1\.5 {
    height: .375rem;
  }

  .sm\:h-1\/2 {
    height: 50%;
  }

  .sm\:h-1\/3 {
    height: 33.3333%;
  }

  .sm\:h-1\/4 {
    height: 25%;
  }

  .sm\:h-1\/5 {
    height: 20%;
  }

  .sm\:h-1\/6 {
    height: 16.6667%;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:h-11 {
    height: 2.75rem;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:h-14 {
    height: 3.5rem;
  }

  .sm\:h-16 {
    height: 4rem;
  }

  .sm\:h-2 {
    height: .5rem;
  }

  .sm\:h-2\.5 {
    height: .625rem;
  }

  .sm\:h-2\/3 {
    height: 66.6667%;
  }

  .sm\:h-2\/4 {
    height: 50%;
  }

  .sm\:h-2\/5 {
    height: 40%;
  }

  .sm\:h-2\/6 {
    height: 33.3333%;
  }

  .sm\:h-20 {
    height: 5rem;
  }

  .sm\:h-24 {
    height: 6rem;
  }

  .sm\:h-28 {
    height: 7rem;
  }

  .sm\:h-3 {
    height: .75rem;
  }

  .sm\:h-3\.5 {
    height: .875rem;
  }

  .sm\:h-3\/4 {
    height: 75%;
  }

  .sm\:h-3\/5 {
    height: 60%;
  }

  .sm\:h-3\/6 {
    height: 50%;
  }

  .sm\:h-32 {
    height: 8rem;
  }

  .sm\:h-36 {
    height: 9rem;
  }

  .sm\:h-4 {
    height: 1rem;
  }

  .sm\:h-4\/5 {
    height: 80%;
  }

  .sm\:h-4\/6 {
    height: 66.6667%;
  }

  .sm\:h-40 {
    height: 10rem;
  }

  .sm\:h-44 {
    height: 11rem;
  }

  .sm\:h-48 {
    height: 12rem;
  }

  .sm\:h-5 {
    height: 1.25rem;
  }

  .sm\:h-5\/6 {
    height: 83.3333%;
  }

  .sm\:h-52 {
    height: 13rem;
  }

  .sm\:h-56 {
    height: 14rem;
  }

  .sm\:h-6 {
    height: 1.5rem;
  }

  .sm\:h-60 {
    height: 15rem;
  }

  .sm\:h-64 {
    height: 16rem;
  }

  .sm\:h-7 {
    height: 1.75rem;
  }

  .sm\:h-72 {
    height: 18rem;
  }

  .sm\:h-8 {
    height: 2rem;
  }

  .sm\:h-80 {
    height: 20rem;
  }

  .sm\:h-9 {
    height: 2.25rem;
  }

  .sm\:h-96 {
    height: 24rem;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:h-dvh {
    height: 100dvh;
  }

  .sm\:h-fit {
    height: fit-content;
  }

  .sm\:h-full {
    height: 100%;
  }

  .sm\:h-lvh {
    height: 100lvh;
  }

  .sm\:h-max {
    height: max-content;
  }

  .sm\:h-min {
    height: min-content;
  }

  .sm\:h-px {
    height: 1px;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:h-svh {
    height: 100svh;
  }

  .sm\:max-h-0 {
    max-height: 0;
  }

  .sm\:max-h-0\.5 {
    max-height: .125rem;
  }

  .sm\:max-h-1 {
    max-height: .25rem;
  }

  .sm\:max-h-1\.5 {
    max-height: .375rem;
  }

  .sm\:max-h-10 {
    max-height: 2.5rem;
  }

  .sm\:max-h-11 {
    max-height: 2.75rem;
  }

  .sm\:max-h-12 {
    max-height: 3rem;
  }

  .sm\:max-h-14 {
    max-height: 3.5rem;
  }

  .sm\:max-h-16 {
    max-height: 4rem;
  }

  .sm\:max-h-2 {
    max-height: .5rem;
  }

  .sm\:max-h-2\.5 {
    max-height: .625rem;
  }

  .sm\:max-h-20 {
    max-height: 5rem;
  }

  .sm\:max-h-24 {
    max-height: 6rem;
  }

  .sm\:max-h-28 {
    max-height: 7rem;
  }

  .sm\:max-h-3 {
    max-height: .75rem;
  }

  .sm\:max-h-3\.5 {
    max-height: .875rem;
  }

  .sm\:max-h-32 {
    max-height: 8rem;
  }

  .sm\:max-h-36 {
    max-height: 9rem;
  }

  .sm\:max-h-4 {
    max-height: 1rem;
  }

  .sm\:max-h-40 {
    max-height: 10rem;
  }

  .sm\:max-h-44 {
    max-height: 11rem;
  }

  .sm\:max-h-48 {
    max-height: 12rem;
  }

  .sm\:max-h-5 {
    max-height: 1.25rem;
  }

  .sm\:max-h-52 {
    max-height: 13rem;
  }

  .sm\:max-h-56 {
    max-height: 14rem;
  }

  .sm\:max-h-6 {
    max-height: 1.5rem;
  }

  .sm\:max-h-60 {
    max-height: 15rem;
  }

  .sm\:max-h-64 {
    max-height: 16rem;
  }

  .sm\:max-h-7 {
    max-height: 1.75rem;
  }

  .sm\:max-h-72 {
    max-height: 18rem;
  }

  .sm\:max-h-8 {
    max-height: 2rem;
  }

  .sm\:max-h-80 {
    max-height: 20rem;
  }

  .sm\:max-h-9 {
    max-height: 2.25rem;
  }

  .sm\:max-h-96 {
    max-height: 24rem;
  }

  .sm\:max-h-dvh {
    max-height: 100dvh;
  }

  .sm\:max-h-fit {
    max-height: fit-content;
  }

  .sm\:max-h-full {
    max-height: 100%;
  }

  .sm\:max-h-lvh {
    max-height: 100lvh;
  }

  .sm\:max-h-max {
    max-height: max-content;
  }

  .sm\:max-h-min {
    max-height: min-content;
  }

  .sm\:max-h-none {
    max-height: none;
  }

  .sm\:max-h-px {
    max-height: 1px;
  }

  .sm\:max-h-screen {
    max-height: 100vh;
  }

  .sm\:max-h-svh {
    max-height: 100svh;
  }

  .sm\:min-h-0 {
    min-height: 0;
  }

  .sm\:min-h-0\.5 {
    min-height: .125rem;
  }

  .sm\:min-h-1 {
    min-height: .25rem;
  }

  .sm\:min-h-1\.5 {
    min-height: .375rem;
  }

  .sm\:min-h-10 {
    min-height: 2.5rem;
  }

  .sm\:min-h-11 {
    min-height: 2.75rem;
  }

  .sm\:min-h-12 {
    min-height: 3rem;
  }

  .sm\:min-h-14 {
    min-height: 3.5rem;
  }

  .sm\:min-h-16 {
    min-height: 4rem;
  }

  .sm\:min-h-2 {
    min-height: .5rem;
  }

  .sm\:min-h-2\.5 {
    min-height: .625rem;
  }

  .sm\:min-h-20 {
    min-height: 5rem;
  }

  .sm\:min-h-24 {
    min-height: 6rem;
  }

  .sm\:min-h-28 {
    min-height: 7rem;
  }

  .sm\:min-h-3 {
    min-height: .75rem;
  }

  .sm\:min-h-3\.5 {
    min-height: .875rem;
  }

  .sm\:min-h-32 {
    min-height: 8rem;
  }

  .sm\:min-h-36 {
    min-height: 9rem;
  }

  .sm\:min-h-4 {
    min-height: 1rem;
  }

  .sm\:min-h-40 {
    min-height: 10rem;
  }

  .sm\:min-h-44 {
    min-height: 11rem;
  }

  .sm\:min-h-48 {
    min-height: 12rem;
  }

  .sm\:min-h-5 {
    min-height: 1.25rem;
  }

  .sm\:min-h-52 {
    min-height: 13rem;
  }

  .sm\:min-h-56 {
    min-height: 14rem;
  }

  .sm\:min-h-6 {
    min-height: 1.5rem;
  }

  .sm\:min-h-60 {
    min-height: 15rem;
  }

  .sm\:min-h-64 {
    min-height: 16rem;
  }

  .sm\:min-h-7 {
    min-height: 1.75rem;
  }

  .sm\:min-h-72 {
    min-height: 18rem;
  }

  .sm\:min-h-8 {
    min-height: 2rem;
  }

  .sm\:min-h-80 {
    min-height: 20rem;
  }

  .sm\:min-h-9 {
    min-height: 2.25rem;
  }

  .sm\:min-h-96 {
    min-height: 24rem;
  }

  .sm\:min-h-dvh {
    min-height: 100dvh;
  }

  .sm\:min-h-fit {
    min-height: fit-content;
  }

  .sm\:min-h-full {
    min-height: 100%;
  }

  .sm\:min-h-lvh {
    min-height: 100lvh;
  }

  .sm\:min-h-max {
    min-height: max-content;
  }

  .sm\:min-h-min {
    min-height: min-content;
  }

  .sm\:min-h-px {
    min-height: 1px;
  }

  .sm\:min-h-screen {
    min-height: 100vh;
  }

  .sm\:min-h-svh {
    min-height: 100svh;
  }

  .sm\:w-0 {
    width: 0;
  }

  .sm\:w-0\.5 {
    width: .125rem;
  }

  .sm\:w-1 {
    width: .25rem;
  }

  .sm\:w-1\.5 {
    width: .375rem;
  }

  .sm\:w-1\/12 {
    width: 8.33333%;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }

  .sm\:w-1\/5 {
    width: 20%;
  }

  .sm\:w-1\/6 {
    width: 16.6667%;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-10\/12 {
    width: 83.3333%;
  }

  .sm\:w-11 {
    width: 2.75rem;
  }

  .sm\:w-11\/12 {
    width: 91.6667%;
  }

  .sm\:w-12 {
    width: 3rem;
  }

  .sm\:w-14 {
    width: 3.5rem;
  }

  .sm\:w-16 {
    width: 4rem;
  }

  .sm\:w-2 {
    width: .5rem;
  }

  .sm\:w-2\.5 {
    width: .625rem;
  }

  .sm\:w-2\/12 {
    width: 16.6667%;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:w-2\/4 {
    width: 50%;
  }

  .sm\:w-2\/5 {
    width: 40%;
  }

  .sm\:w-2\/6 {
    width: 33.3333%;
  }

  .sm\:w-20 {
    width: 5rem;
  }

  .sm\:w-24 {
    width: 6rem;
  }

  .sm\:w-28 {
    width: 7rem;
  }

  .sm\:w-3 {
    width: .75rem;
  }

  .sm\:w-3\.5 {
    width: .875rem;
  }

  .sm\:w-3\/12 {
    width: 25%;
  }

  .sm\:w-3\/4 {
    width: 75%;
  }

  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:w-3\/6 {
    width: 50%;
  }

  .sm\:w-32 {
    width: 8rem;
  }

  .sm\:w-36 {
    width: 9rem;
  }

  .sm\:w-4 {
    width: 1rem;
  }

  .sm\:w-4\/12 {
    width: 33.3333%;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:w-4\/6 {
    width: 66.6667%;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-44 {
    width: 11rem;
  }

  .sm\:w-48 {
    width: 12rem;
  }

  .sm\:w-5 {
    width: 1.25rem;
  }

  .sm\:w-5\/12 {
    width: 41.6667%;
  }

  .sm\:w-5\/6 {
    width: 83.3333%;
  }

  .sm\:w-52 {
    width: 13rem;
  }

  .sm\:w-56 {
    width: 14rem;
  }

  .sm\:w-6 {
    width: 1.5rem;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }

  .sm\:w-60 {
    width: 15rem;
  }

  .sm\:w-64 {
    width: 16rem;
  }

  .sm\:w-7 {
    width: 1.75rem;
  }

  .sm\:w-7\/12 {
    width: 58.3333%;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-8 {
    width: 2rem;
  }

  .sm\:w-8\/12 {
    width: 66.6667%;
  }

  .sm\:w-80 {
    width: 20rem;
  }

  .sm\:w-9 {
    width: 2.25rem;
  }

  .sm\:w-9\/12 {
    width: 75%;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-dvw {
    width: 100dvw;
  }

  .sm\:w-fit {
    width: fit-content;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:w-lvw {
    width: 100lvw;
  }

  .sm\:w-max {
    width: max-content;
  }

  .sm\:w-min {
    width: min-content;
  }

  .sm\:w-px {
    width: 1px;
  }

  .sm\:w-screen {
    width: 100vw;
  }

  .sm\:w-svw {
    width: 100svw;
  }

  .sm\:min-w-0 {
    min-width: 0;
  }

  .sm\:min-w-0\.5 {
    min-width: .125rem;
  }

  .sm\:min-w-1 {
    min-width: .25rem;
  }

  .sm\:min-w-1\.5 {
    min-width: .375rem;
  }

  .sm\:min-w-10 {
    min-width: 2.5rem;
  }

  .sm\:min-w-11 {
    min-width: 2.75rem;
  }

  .sm\:min-w-12 {
    min-width: 3rem;
  }

  .sm\:min-w-14 {
    min-width: 3.5rem;
  }

  .sm\:min-w-16 {
    min-width: 4rem;
  }

  .sm\:min-w-2 {
    min-width: .5rem;
  }

  .sm\:min-w-2\.5 {
    min-width: .625rem;
  }

  .sm\:min-w-20 {
    min-width: 5rem;
  }

  .sm\:min-w-24 {
    min-width: 6rem;
  }

  .sm\:min-w-28 {
    min-width: 7rem;
  }

  .sm\:min-w-3 {
    min-width: .75rem;
  }

  .sm\:min-w-3\.5 {
    min-width: .875rem;
  }

  .sm\:min-w-32 {
    min-width: 8rem;
  }

  .sm\:min-w-36 {
    min-width: 9rem;
  }

  .sm\:min-w-4 {
    min-width: 1rem;
  }

  .sm\:min-w-40 {
    min-width: 10rem;
  }

  .sm\:min-w-44 {
    min-width: 11rem;
  }

  .sm\:min-w-48 {
    min-width: 12rem;
  }

  .sm\:min-w-5 {
    min-width: 1.25rem;
  }

  .sm\:min-w-52 {
    min-width: 13rem;
  }

  .sm\:min-w-56 {
    min-width: 14rem;
  }

  .sm\:min-w-6 {
    min-width: 1.5rem;
  }

  .sm\:min-w-60 {
    min-width: 15rem;
  }

  .sm\:min-w-64 {
    min-width: 16rem;
  }

  .sm\:min-w-7 {
    min-width: 1.75rem;
  }

  .sm\:min-w-72 {
    min-width: 18rem;
  }

  .sm\:min-w-8 {
    min-width: 2rem;
  }

  .sm\:min-w-80 {
    min-width: 20rem;
  }

  .sm\:min-w-9 {
    min-width: 2.25rem;
  }

  .sm\:min-w-96 {
    min-width: 24rem;
  }

  .sm\:min-w-fit {
    min-width: fit-content;
  }

  .sm\:min-w-full {
    min-width: 100%;
  }

  .sm\:min-w-max {
    min-width: max-content;
  }

  .sm\:min-w-min {
    min-width: min-content;
  }

  .sm\:min-w-px {
    min-width: 1px;
  }

  .sm\:max-w-0 {
    max-width: 0;
  }

  .sm\:max-w-0\.5 {
    max-width: .125rem;
  }

  .sm\:max-w-1 {
    max-width: .25rem;
  }

  .sm\:max-w-1\.5 {
    max-width: .375rem;
  }

  .sm\:max-w-10 {
    max-width: 2.5rem;
  }

  .sm\:max-w-11 {
    max-width: 2.75rem;
  }

  .sm\:max-w-12 {
    max-width: 3rem;
  }

  .sm\:max-w-14 {
    max-width: 3.5rem;
  }

  .sm\:max-w-16 {
    max-width: 4rem;
  }

  .sm\:max-w-2 {
    max-width: .5rem;
  }

  .sm\:max-w-2\.5 {
    max-width: .625rem;
  }

  .sm\:max-w-20 {
    max-width: 5rem;
  }

  .sm\:max-w-24 {
    max-width: 6rem;
  }

  .sm\:max-w-28 {
    max-width: 7rem;
  }

  .sm\:max-w-2xl {
    max-width: 42rem;
  }

  .sm\:max-w-3 {
    max-width: .75rem;
  }

  .sm\:max-w-3\.5 {
    max-width: .875rem;
  }

  .sm\:max-w-32 {
    max-width: 8rem;
  }

  .sm\:max-w-36 {
    max-width: 9rem;
  }

  .sm\:max-w-3xl {
    max-width: 48rem;
  }

  .sm\:max-w-4 {
    max-width: 1rem;
  }

  .sm\:max-w-40 {
    max-width: 10rem;
  }

  .sm\:max-w-44 {
    max-width: 11rem;
  }

  .sm\:max-w-48 {
    max-width: 12rem;
  }

  .sm\:max-w-4xl {
    max-width: 56rem;
  }

  .sm\:max-w-5 {
    max-width: 1.25rem;
  }

  .sm\:max-w-52 {
    max-width: 13rem;
  }

  .sm\:max-w-56 {
    max-width: 14rem;
  }

  .sm\:max-w-5xl {
    max-width: 64rem;
  }

  .sm\:max-w-6 {
    max-width: 1.5rem;
  }

  .sm\:max-w-60 {
    max-width: 15rem;
  }

  .sm\:max-w-64 {
    max-width: 16rem;
  }

  .sm\:max-w-6xl {
    max-width: 72rem;
  }

  .sm\:max-w-7 {
    max-width: 1.75rem;
  }

  .sm\:max-w-72 {
    max-width: 18rem;
  }

  .sm\:max-w-7xl {
    max-width: 80rem;
  }

  .sm\:max-w-8 {
    max-width: 2rem;
  }

  .sm\:max-w-80 {
    max-width: 20rem;
  }

  .sm\:max-w-9 {
    max-width: 2.25rem;
  }

  .sm\:max-w-96 {
    max-width: 24rem;
  }

  .sm\:max-w-fit {
    max-width: fit-content;
  }

  .sm\:max-w-full {
    max-width: 100%;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-max {
    max-width: max-content;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:max-w-min {
    max-width: min-content;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:max-w-prose {
    max-width: 65ch;
  }

  .sm\:max-w-px {
    max-width: 1px;
  }

  .sm\:max-w-screen-2xl {
    max-width: 1536px;
  }

  .sm\:max-w-screen-lg {
    max-width: 1024px;
  }

  .sm\:max-w-screen-md {
    max-width: 768px;
  }

  .sm\:max-w-screen-sm {
    max-width: 640px;
  }

  .sm\:max-w-screen-xl {
    max-width: 1280px;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:max-w-xs {
    max-width: 20rem;
  }

  .sm\:flex-1 {
    flex: 1;
  }

  .sm\:flex-auto {
    flex: auto;
  }

  .sm\:flex-initial {
    flex: 0 auto;
  }

  .sm\:flex-none {
    flex: none;
  }

  .sm\:flex-shrink {
    flex-shrink: 1;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:shrink {
    flex-shrink: 1;
  }

  .sm\:shrink-0 {
    flex-shrink: 0;
  }

  .sm\:flex-grow {
    flex-grow: 1;
  }

  .sm\:flex-grow-0 {
    flex-grow: 0;
  }

  .sm\:grow {
    flex-grow: 1;
  }

  .sm\:grow-0 {
    flex-grow: 0;
  }

  .sm\:basis-0 {
    flex-basis: 0;
  }

  .sm\:basis-0\.5 {
    flex-basis: .125rem;
  }

  .sm\:basis-1 {
    flex-basis: .25rem;
  }

  .sm\:basis-1\.5 {
    flex-basis: .375rem;
  }

  .sm\:basis-1\/12 {
    flex-basis: 8.33333%;
  }

  .sm\:basis-1\/2 {
    flex-basis: 50%;
  }

  .sm\:basis-1\/3 {
    flex-basis: 33.3333%;
  }

  .sm\:basis-1\/4 {
    flex-basis: 25%;
  }

  .sm\:basis-1\/5 {
    flex-basis: 20%;
  }

  .sm\:basis-1\/6 {
    flex-basis: 16.6667%;
  }

  .sm\:basis-10 {
    flex-basis: 2.5rem;
  }

  .sm\:basis-10\/12 {
    flex-basis: 83.3333%;
  }

  .sm\:basis-11 {
    flex-basis: 2.75rem;
  }

  .sm\:basis-11\/12 {
    flex-basis: 91.6667%;
  }

  .sm\:basis-12 {
    flex-basis: 3rem;
  }

  .sm\:basis-14 {
    flex-basis: 3.5rem;
  }

  .sm\:basis-16 {
    flex-basis: 4rem;
  }

  .sm\:basis-2 {
    flex-basis: .5rem;
  }

  .sm\:basis-2\.5 {
    flex-basis: .625rem;
  }

  .sm\:basis-2\/12 {
    flex-basis: 16.6667%;
  }

  .sm\:basis-2\/3 {
    flex-basis: 66.6667%;
  }

  .sm\:basis-2\/4 {
    flex-basis: 50%;
  }

  .sm\:basis-2\/5 {
    flex-basis: 40%;
  }

  .sm\:basis-2\/6 {
    flex-basis: 33.3333%;
  }

  .sm\:basis-20 {
    flex-basis: 5rem;
  }

  .sm\:basis-24 {
    flex-basis: 6rem;
  }

  .sm\:basis-28 {
    flex-basis: 7rem;
  }

  .sm\:basis-3 {
    flex-basis: .75rem;
  }

  .sm\:basis-3\.5 {
    flex-basis: .875rem;
  }

  .sm\:basis-3\/12 {
    flex-basis: 25%;
  }

  .sm\:basis-3\/4 {
    flex-basis: 75%;
  }

  .sm\:basis-3\/5 {
    flex-basis: 60%;
  }

  .sm\:basis-3\/6 {
    flex-basis: 50%;
  }

  .sm\:basis-32 {
    flex-basis: 8rem;
  }

  .sm\:basis-36 {
    flex-basis: 9rem;
  }

  .sm\:basis-4 {
    flex-basis: 1rem;
  }

  .sm\:basis-4\/12 {
    flex-basis: 33.3333%;
  }

  .sm\:basis-4\/5 {
    flex-basis: 80%;
  }

  .sm\:basis-4\/6 {
    flex-basis: 66.6667%;
  }

  .sm\:basis-40 {
    flex-basis: 10rem;
  }

  .sm\:basis-44 {
    flex-basis: 11rem;
  }

  .sm\:basis-48 {
    flex-basis: 12rem;
  }

  .sm\:basis-5 {
    flex-basis: 1.25rem;
  }

  .sm\:basis-5\/12 {
    flex-basis: 41.6667%;
  }

  .sm\:basis-5\/6 {
    flex-basis: 83.3333%;
  }

  .sm\:basis-52 {
    flex-basis: 13rem;
  }

  .sm\:basis-56 {
    flex-basis: 14rem;
  }

  .sm\:basis-6 {
    flex-basis: 1.5rem;
  }

  .sm\:basis-6\/12 {
    flex-basis: 50%;
  }

  .sm\:basis-60 {
    flex-basis: 15rem;
  }

  .sm\:basis-64 {
    flex-basis: 16rem;
  }

  .sm\:basis-7 {
    flex-basis: 1.75rem;
  }

  .sm\:basis-7\/12 {
    flex-basis: 58.3333%;
  }

  .sm\:basis-72 {
    flex-basis: 18rem;
  }

  .sm\:basis-8 {
    flex-basis: 2rem;
  }

  .sm\:basis-8\/12 {
    flex-basis: 66.6667%;
  }

  .sm\:basis-80 {
    flex-basis: 20rem;
  }

  .sm\:basis-9 {
    flex-basis: 2.25rem;
  }

  .sm\:basis-9\/12 {
    flex-basis: 75%;
  }

  .sm\:basis-96 {
    flex-basis: 24rem;
  }

  .sm\:basis-auto {
    flex-basis: auto;
  }

  .sm\:basis-full {
    flex-basis: 100%;
  }

  .sm\:basis-px {
    flex-basis: 1px;
  }

  .sm\:table-auto {
    table-layout: auto;
  }

  .sm\:table-fixed {
    table-layout: fixed;
  }

  .sm\:caption-top {
    caption-side: top;
  }

  .sm\:caption-bottom {
    caption-side: bottom;
  }

  .sm\:border-collapse {
    border-collapse: collapse;
  }

  .sm\:border-separate {
    border-collapse: separate;
  }

  .sm\:border-spacing-0 {
    --tw-border-spacing-x: 0px;
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-0\.5 {
    --tw-border-spacing-x: .125rem;
    --tw-border-spacing-y: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-1 {
    --tw-border-spacing-x: .25rem;
    --tw-border-spacing-y: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-1\.5 {
    --tw-border-spacing-x: .375rem;
    --tw-border-spacing-y: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-10 {
    --tw-border-spacing-x: 2.5rem;
    --tw-border-spacing-y: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-11 {
    --tw-border-spacing-x: 2.75rem;
    --tw-border-spacing-y: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-12 {
    --tw-border-spacing-x: 3rem;
    --tw-border-spacing-y: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-14 {
    --tw-border-spacing-x: 3.5rem;
    --tw-border-spacing-y: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-16 {
    --tw-border-spacing-x: 4rem;
    --tw-border-spacing-y: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-2 {
    --tw-border-spacing-x: .5rem;
    --tw-border-spacing-y: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-2\.5 {
    --tw-border-spacing-x: .625rem;
    --tw-border-spacing-y: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-20 {
    --tw-border-spacing-x: 5rem;
    --tw-border-spacing-y: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-24 {
    --tw-border-spacing-x: 6rem;
    --tw-border-spacing-y: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-28 {
    --tw-border-spacing-x: 7rem;
    --tw-border-spacing-y: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-3 {
    --tw-border-spacing-x: .75rem;
    --tw-border-spacing-y: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-3\.5 {
    --tw-border-spacing-x: .875rem;
    --tw-border-spacing-y: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-32 {
    --tw-border-spacing-x: 8rem;
    --tw-border-spacing-y: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-36 {
    --tw-border-spacing-x: 9rem;
    --tw-border-spacing-y: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-4 {
    --tw-border-spacing-x: 1rem;
    --tw-border-spacing-y: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-40 {
    --tw-border-spacing-x: 10rem;
    --tw-border-spacing-y: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-44 {
    --tw-border-spacing-x: 11rem;
    --tw-border-spacing-y: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-48 {
    --tw-border-spacing-x: 12rem;
    --tw-border-spacing-y: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-5 {
    --tw-border-spacing-x: 1.25rem;
    --tw-border-spacing-y: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-52 {
    --tw-border-spacing-x: 13rem;
    --tw-border-spacing-y: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-56 {
    --tw-border-spacing-x: 14rem;
    --tw-border-spacing-y: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-6 {
    --tw-border-spacing-x: 1.5rem;
    --tw-border-spacing-y: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-60 {
    --tw-border-spacing-x: 15rem;
    --tw-border-spacing-y: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-64 {
    --tw-border-spacing-x: 16rem;
    --tw-border-spacing-y: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-7 {
    --tw-border-spacing-x: 1.75rem;
    --tw-border-spacing-y: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-72 {
    --tw-border-spacing-x: 18rem;
    --tw-border-spacing-y: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-8 {
    --tw-border-spacing-x: 2rem;
    --tw-border-spacing-y: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-80 {
    --tw-border-spacing-x: 20rem;
    --tw-border-spacing-y: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-9 {
    --tw-border-spacing-x: 2.25rem;
    --tw-border-spacing-y: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-96 {
    --tw-border-spacing-x: 24rem;
    --tw-border-spacing-y: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-px {
    --tw-border-spacing-x: 1px;
    --tw-border-spacing-y: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-0 {
    --tw-border-spacing-x: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-0\.5 {
    --tw-border-spacing-x: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-1 {
    --tw-border-spacing-x: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-1\.5 {
    --tw-border-spacing-x: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-10 {
    --tw-border-spacing-x: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-11 {
    --tw-border-spacing-x: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-12 {
    --tw-border-spacing-x: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-14 {
    --tw-border-spacing-x: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-16 {
    --tw-border-spacing-x: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-2 {
    --tw-border-spacing-x: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-2\.5 {
    --tw-border-spacing-x: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-20 {
    --tw-border-spacing-x: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-24 {
    --tw-border-spacing-x: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-28 {
    --tw-border-spacing-x: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-3 {
    --tw-border-spacing-x: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-3\.5 {
    --tw-border-spacing-x: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-32 {
    --tw-border-spacing-x: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-36 {
    --tw-border-spacing-x: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-4 {
    --tw-border-spacing-x: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-40 {
    --tw-border-spacing-x: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-44 {
    --tw-border-spacing-x: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-48 {
    --tw-border-spacing-x: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-5 {
    --tw-border-spacing-x: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-52 {
    --tw-border-spacing-x: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-56 {
    --tw-border-spacing-x: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-6 {
    --tw-border-spacing-x: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-60 {
    --tw-border-spacing-x: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-64 {
    --tw-border-spacing-x: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-7 {
    --tw-border-spacing-x: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-72 {
    --tw-border-spacing-x: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-8 {
    --tw-border-spacing-x: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-80 {
    --tw-border-spacing-x: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-9 {
    --tw-border-spacing-x: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-96 {
    --tw-border-spacing-x: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-x-px {
    --tw-border-spacing-x: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-0 {
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-0\.5 {
    --tw-border-spacing-y: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-1 {
    --tw-border-spacing-y: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-1\.5 {
    --tw-border-spacing-y: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-10 {
    --tw-border-spacing-y: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-11 {
    --tw-border-spacing-y: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-12 {
    --tw-border-spacing-y: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-14 {
    --tw-border-spacing-y: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-16 {
    --tw-border-spacing-y: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-2 {
    --tw-border-spacing-y: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-2\.5 {
    --tw-border-spacing-y: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-20 {
    --tw-border-spacing-y: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-24 {
    --tw-border-spacing-y: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-28 {
    --tw-border-spacing-y: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-3 {
    --tw-border-spacing-y: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-3\.5 {
    --tw-border-spacing-y: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-32 {
    --tw-border-spacing-y: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-36 {
    --tw-border-spacing-y: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-4 {
    --tw-border-spacing-y: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-40 {
    --tw-border-spacing-y: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-44 {
    --tw-border-spacing-y: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-48 {
    --tw-border-spacing-y: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-5 {
    --tw-border-spacing-y: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-52 {
    --tw-border-spacing-y: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-56 {
    --tw-border-spacing-y: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-6 {
    --tw-border-spacing-y: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-60 {
    --tw-border-spacing-y: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-64 {
    --tw-border-spacing-y: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-7 {
    --tw-border-spacing-y: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-72 {
    --tw-border-spacing-y: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-8 {
    --tw-border-spacing-y: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-80 {
    --tw-border-spacing-y: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-9 {
    --tw-border-spacing-y: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-96 {
    --tw-border-spacing-y: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:border-spacing-y-px {
    --tw-border-spacing-y: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .sm\:origin-bottom {
    transform-origin: bottom;
  }

  .sm\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .sm\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .sm\:origin-center {
    transform-origin: center;
  }

  .sm\:origin-left {
    transform-origin: 0;
  }

  .sm\:origin-right {
    transform-origin: 100%;
  }

  .sm\:origin-top {
    transform-origin: top;
  }

  .sm\:origin-top-left {
    transform-origin: 0 0;
  }

  .sm\:origin-top-right {
    transform-origin: 100% 0;
  }

  .sm\:-translate-x-0 {
    --tw-translate-x: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-1 {
    --tw-translate-x: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-12 {
    --tw-translate-x: -3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-16 {
    --tw-translate-x: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-2 {
    --tw-translate-x: -.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-20 {
    --tw-translate-x: -5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-24 {
    --tw-translate-x: -6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-28 {
    --tw-translate-x: -7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-3 {
    --tw-translate-x: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-32 {
    --tw-translate-x: -8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-36 {
    --tw-translate-x: -9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-4 {
    --tw-translate-x: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-40 {
    --tw-translate-x: -10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-44 {
    --tw-translate-x: -11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-48 {
    --tw-translate-x: -12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-52 {
    --tw-translate-x: -13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-56 {
    --tw-translate-x: -14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-60 {
    --tw-translate-x: -15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-64 {
    --tw-translate-x: -16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-72 {
    --tw-translate-x: -18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-8 {
    --tw-translate-x: -2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-80 {
    --tw-translate-x: -20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-96 {
    --tw-translate-x: -24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-x-px {
    --tw-translate-x: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-0 {
    --tw-translate-y: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-1 {
    --tw-translate-y: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-12 {
    --tw-translate-y: -3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-16 {
    --tw-translate-y: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-2 {
    --tw-translate-y: -.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-20 {
    --tw-translate-y: -5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-24 {
    --tw-translate-y: -6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-28 {
    --tw-translate-y: -7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-3 {
    --tw-translate-y: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-32 {
    --tw-translate-y: -8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-36 {
    --tw-translate-y: -9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-4 {
    --tw-translate-y: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-40 {
    --tw-translate-y: -10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-44 {
    --tw-translate-y: -11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-48 {
    --tw-translate-y: -12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-52 {
    --tw-translate-y: -13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-56 {
    --tw-translate-y: -14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-60 {
    --tw-translate-y: -15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-64 {
    --tw-translate-y: -16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-72 {
    --tw-translate-y: -18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-8 {
    --tw-translate-y: -2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-80 {
    --tw-translate-y: -20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-96 {
    --tw-translate-y: -24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-translate-y-px {
    --tw-translate-y: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-1 {
    --tw-translate-x: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-1\/4 {
    --tw-translate-x: 25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-10 {
    --tw-translate-x: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-11 {
    --tw-translate-x: 2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-12 {
    --tw-translate-x: 3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-14 {
    --tw-translate-x: 3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-16 {
    --tw-translate-x: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2\/4 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-20 {
    --tw-translate-x: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-24 {
    --tw-translate-x: 6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-28 {
    --tw-translate-x: 7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-3 {
    --tw-translate-x: .75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-3\/4 {
    --tw-translate-x: 75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-32 {
    --tw-translate-x: 8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-36 {
    --tw-translate-x: 9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-4 {
    --tw-translate-x: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-40 {
    --tw-translate-x: 10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-44 {
    --tw-translate-x: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-48 {
    --tw-translate-x: 12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-5 {
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-52 {
    --tw-translate-x: 13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-56 {
    --tw-translate-x: 14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-6 {
    --tw-translate-x: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-60 {
    --tw-translate-x: 15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-64 {
    --tw-translate-x: 16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-7 {
    --tw-translate-x: 1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-72 {
    --tw-translate-x: 18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-8 {
    --tw-translate-x: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-80 {
    --tw-translate-x: 20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-9 {
    --tw-translate-x: 2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-96 {
    --tw-translate-x: 24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-full {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-px {
    --tw-translate-x: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-1 {
    --tw-translate-y: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-1\/2 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-1\/4 {
    --tw-translate-y: 25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-10 {
    --tw-translate-y: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-11 {
    --tw-translate-y: 2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-12 {
    --tw-translate-y: 3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-14 {
    --tw-translate-y: 3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-16 {
    --tw-translate-y: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-2 {
    --tw-translate-y: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-2\/4 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-20 {
    --tw-translate-y: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-24 {
    --tw-translate-y: 6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-28 {
    --tw-translate-y: 7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-3 {
    --tw-translate-y: .75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-3\/4 {
    --tw-translate-y: 75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-32 {
    --tw-translate-y: 8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-36 {
    --tw-translate-y: 9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-4 {
    --tw-translate-y: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-40 {
    --tw-translate-y: 10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-44 {
    --tw-translate-y: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-48 {
    --tw-translate-y: 12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-5 {
    --tw-translate-y: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-52 {
    --tw-translate-y: 13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-56 {
    --tw-translate-y: 14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-6 {
    --tw-translate-y: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-60 {
    --tw-translate-y: 15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-64 {
    --tw-translate-y: 16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-7 {
    --tw-translate-y: 1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-72 {
    --tw-translate-y: 18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-8 {
    --tw-translate-y: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-80 {
    --tw-translate-y: 20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-9 {
    --tw-translate-y: 2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-96 {
    --tw-translate-y: 24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-full {
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-px {
    --tw-translate-y: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-rotate-0 {
    --tw-rotate: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-rotate-1 {
    --tw-rotate: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-rotate-12 {
    --tw-rotate: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-rotate-180 {
    --tw-rotate: -180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-rotate-2 {
    --tw-rotate: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-rotate-3 {
    --tw-rotate: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-rotate-45 {
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-rotate-6 {
    --tw-rotate: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:rotate-1 {
    --tw-rotate: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:rotate-12 {
    --tw-rotate: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:rotate-2 {
    --tw-rotate: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:rotate-3 {
    --tw-rotate: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:rotate-6 {
    --tw-rotate: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-x-0 {
    --tw-skew-x: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-x-1 {
    --tw-skew-x: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-x-12 {
    --tw-skew-x: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-x-2 {
    --tw-skew-x: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-x-3 {
    --tw-skew-x: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-x-6 {
    --tw-skew-x: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-y-0 {
    --tw-skew-y: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-y-1 {
    --tw-skew-y: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-y-12 {
    --tw-skew-y: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-y-2 {
    --tw-skew-y: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-y-3 {
    --tw-skew-y: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-skew-y-6 {
    --tw-skew-y: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-x-0 {
    --tw-skew-x: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-x-1 {
    --tw-skew-x: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-x-12 {
    --tw-skew-x: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-x-2 {
    --tw-skew-x: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-x-3 {
    --tw-skew-x: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-x-6 {
    --tw-skew-x: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-y-0 {
    --tw-skew-y: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-y-1 {
    --tw-skew-y: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-y-12 {
    --tw-skew-y: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-y-2 {
    --tw-skew-y: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-y-3 {
    --tw-skew-y: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:skew-y-6 {
    --tw-skew-y: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-100 {
    --tw-scale-x: -1;
    --tw-scale-y: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-105 {
    --tw-scale-x: -1.05;
    --tw-scale-y: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-110 {
    --tw-scale-x: -1.1;
    --tw-scale-y: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-125 {
    --tw-scale-x: -1.25;
    --tw-scale-y: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-150 {
    --tw-scale-x: -1.5;
    --tw-scale-y: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-50 {
    --tw-scale-x: -.5;
    --tw-scale-y: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-75 {
    --tw-scale-x: -.75;
    --tw-scale-y: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-90 {
    --tw-scale-x: -.9;
    --tw-scale-y: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-95 {
    --tw-scale-x: -.95;
    --tw-scale-y: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-x-0 {
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-x-100 {
    --tw-scale-x: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-x-105 {
    --tw-scale-x: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-x-110 {
    --tw-scale-x: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-x-125 {
    --tw-scale-x: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-x-150 {
    --tw-scale-x: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-x-50 {
    --tw-scale-x: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-x-75 {
    --tw-scale-x: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-x-90 {
    --tw-scale-x: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-x-95 {
    --tw-scale-x: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-y-0 {
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-y-100 {
    --tw-scale-y: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-y-105 {
    --tw-scale-y: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-y-110 {
    --tw-scale-y: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-y-125 {
    --tw-scale-y: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-y-150 {
    --tw-scale-y: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-y-50 {
    --tw-scale-y: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-y-75 {
    --tw-scale-y: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-y-90 {
    --tw-scale-y: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:-scale-y-95 {
    --tw-scale-y: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-x-0 {
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-x-100 {
    --tw-scale-x: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-x-105 {
    --tw-scale-x: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-x-110 {
    --tw-scale-x: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-x-125 {
    --tw-scale-x: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-x-150 {
    --tw-scale-x: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-x-50 {
    --tw-scale-x: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-x-75 {
    --tw-scale-x: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-x-90 {
    --tw-scale-x: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-x-95 {
    --tw-scale-x: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-y-0 {
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-y-100 {
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-y-105 {
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-y-110 {
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-y-125 {
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-y-150 {
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-y-50 {
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-y-75 {
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-y-90 {
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-y-95 {
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:transform, .sm\:transform-cpu {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:transform-gpu {
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:transform-none {
    transform: none;
  }

  @keyframes bounce {
    0%, 100% {
      animation-timing-function: cubic-bezier(.8, 0, 1, 1);
      transform: translateY(-25%);
    }

    50% {
      animation-timing-function: cubic-bezier(0, 0, .2, 1);
      transform: none;
    }
  }

  .sm\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .sm\:animate-none {
    animation: none;
  }

  @keyframes ping {
    75%, 100% {
      opacity: 0;
      transform: scale(2);
    }
  }

  .sm\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  @keyframes pulse {
    50% {
      opacity: .5;
    }
  }

  .sm\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .sm\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .sm\:cursor-alias {
    cursor: alias;
  }

  .sm\:cursor-all-scroll {
    cursor: all-scroll;
  }

  .sm\:cursor-auto {
    cursor: auto;
  }

  .sm\:cursor-cell {
    cursor: cell;
  }

  .sm\:cursor-col-resize {
    cursor: col-resize;
  }

  .sm\:cursor-context-menu {
    cursor: context-menu;
  }

  .sm\:cursor-copy {
    cursor: copy;
  }

  .sm\:cursor-crosshair {
    cursor: crosshair;
  }

  .sm\:cursor-default {
    cursor: default;
  }

  .sm\:cursor-e-resize {
    cursor: e-resize;
  }

  .sm\:cursor-ew-resize {
    cursor: ew-resize;
  }

  .sm\:cursor-grab {
    cursor: grab;
  }

  .sm\:cursor-grabbing {
    cursor: grabbing;
  }

  .sm\:cursor-help {
    cursor: help;
  }

  .sm\:cursor-move {
    cursor: move;
  }

  .sm\:cursor-n-resize {
    cursor: n-resize;
  }

  .sm\:cursor-ne-resize {
    cursor: ne-resize;
  }

  .sm\:cursor-nesw-resize {
    cursor: nesw-resize;
  }

  .sm\:cursor-no-drop {
    cursor: no-drop;
  }

  .sm\:cursor-none {
    cursor: none;
  }

  .sm\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .sm\:cursor-ns-resize {
    cursor: ns-resize;
  }

  .sm\:cursor-nw-resize {
    cursor: nw-resize;
  }

  .sm\:cursor-nwse-resize {
    cursor: nwse-resize;
  }

  .sm\:cursor-pointer {
    cursor: pointer;
  }

  .sm\:cursor-progress {
    cursor: progress;
  }

  .sm\:cursor-row-resize {
    cursor: row-resize;
  }

  .sm\:cursor-s-resize {
    cursor: s-resize;
  }

  .sm\:cursor-se-resize {
    cursor: se-resize;
  }

  .sm\:cursor-sw-resize {
    cursor: sw-resize;
  }

  .sm\:cursor-text {
    cursor: text;
  }

  .sm\:cursor-vertical-text {
    cursor: vertical-text;
  }

  .sm\:cursor-w-resize {
    cursor: w-resize;
  }

  .sm\:cursor-wait {
    cursor: wait;
  }

  .sm\:cursor-zoom-in {
    cursor: zoom-in;
  }

  .sm\:cursor-zoom-out {
    cursor: zoom-out;
  }

  .sm\:touch-auto {
    touch-action: auto;
  }

  .sm\:touch-none {
    touch-action: none;
  }

  .sm\:touch-pan-x {
    --tw-pan-x: pan-x;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .sm\:touch-pan-left {
    --tw-pan-x: pan-left;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .sm\:touch-pan-right {
    --tw-pan-x: pan-right;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .sm\:touch-pan-y {
    --tw-pan-y: pan-y;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .sm\:touch-pan-up {
    --tw-pan-y: pan-up;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .sm\:touch-pan-down {
    --tw-pan-y: pan-down;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .sm\:touch-pinch-zoom {
    --tw-pinch-zoom: pinch-zoom;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .sm\:touch-manipulation {
    touch-action: manipulation;
  }

  .sm\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .sm\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .sm\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .sm\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .sm\:resize-none {
    resize: none;
  }

  .sm\:resize-y {
    resize: vertical;
  }

  .sm\:resize-x {
    resize: horizontal;
  }

  .sm\:resize {
    resize: both;
  }

  .sm\:snap-none {
    scroll-snap-type: none;
  }

  .sm\:snap-x {
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
  }

  .sm\:snap-y {
    scroll-snap-type: y var(--tw-scroll-snap-strictness);
  }

  .sm\:snap-both {
    scroll-snap-type: both var(--tw-scroll-snap-strictness);
  }

  .sm\:snap-mandatory {
    --tw-scroll-snap-strictness: mandatory;
  }

  .sm\:snap-proximity {
    --tw-scroll-snap-strictness: proximity;
  }

  .sm\:snap-start {
    scroll-snap-align: start;
  }

  .sm\:snap-end {
    scroll-snap-align: end;
  }

  .sm\:snap-center {
    scroll-snap-align: center;
  }

  .sm\:snap-align-none {
    scroll-snap-align: none;
  }

  .sm\:snap-normal {
    scroll-snap-stop: normal;
  }

  .sm\:snap-always {
    scroll-snap-stop: always;
  }

  .sm\:-scroll-m-0 {
    scroll-margin: 0;
  }

  .sm\:-scroll-m-0\.5 {
    scroll-margin: -.125rem;
  }

  .sm\:-scroll-m-1 {
    scroll-margin: -.25rem;
  }

  .sm\:-scroll-m-1\.5 {
    scroll-margin: -.375rem;
  }

  .sm\:-scroll-m-10 {
    scroll-margin: -2.5rem;
  }

  .sm\:-scroll-m-11 {
    scroll-margin: -2.75rem;
  }

  .sm\:-scroll-m-12 {
    scroll-margin: -3rem;
  }

  .sm\:-scroll-m-14 {
    scroll-margin: -3.5rem;
  }

  .sm\:-scroll-m-16 {
    scroll-margin: -4rem;
  }

  .sm\:-scroll-m-2 {
    scroll-margin: -.5rem;
  }

  .sm\:-scroll-m-2\.5 {
    scroll-margin: -.625rem;
  }

  .sm\:-scroll-m-20 {
    scroll-margin: -5rem;
  }

  .sm\:-scroll-m-24 {
    scroll-margin: -6rem;
  }

  .sm\:-scroll-m-28 {
    scroll-margin: -7rem;
  }

  .sm\:-scroll-m-3 {
    scroll-margin: -.75rem;
  }

  .sm\:-scroll-m-3\.5 {
    scroll-margin: -.875rem;
  }

  .sm\:-scroll-m-32 {
    scroll-margin: -8rem;
  }

  .sm\:-scroll-m-36 {
    scroll-margin: -9rem;
  }

  .sm\:-scroll-m-4 {
    scroll-margin: -1rem;
  }

  .sm\:-scroll-m-40 {
    scroll-margin: -10rem;
  }

  .sm\:-scroll-m-44 {
    scroll-margin: -11rem;
  }

  .sm\:-scroll-m-48 {
    scroll-margin: -12rem;
  }

  .sm\:-scroll-m-5 {
    scroll-margin: -1.25rem;
  }

  .sm\:-scroll-m-52 {
    scroll-margin: -13rem;
  }

  .sm\:-scroll-m-56 {
    scroll-margin: -14rem;
  }

  .sm\:-scroll-m-6 {
    scroll-margin: -1.5rem;
  }

  .sm\:-scroll-m-60 {
    scroll-margin: -15rem;
  }

  .sm\:-scroll-m-64 {
    scroll-margin: -16rem;
  }

  .sm\:-scroll-m-7 {
    scroll-margin: -1.75rem;
  }

  .sm\:-scroll-m-72 {
    scroll-margin: -18rem;
  }

  .sm\:-scroll-m-8 {
    scroll-margin: -2rem;
  }

  .sm\:-scroll-m-80 {
    scroll-margin: -20rem;
  }

  .sm\:-scroll-m-9 {
    scroll-margin: -2.25rem;
  }

  .sm\:-scroll-m-96 {
    scroll-margin: -24rem;
  }

  .sm\:-scroll-m-px {
    scroll-margin: -1px;
  }

  .sm\:scroll-m-0 {
    scroll-margin: 0;
  }

  .sm\:scroll-m-0\.5 {
    scroll-margin: .125rem;
  }

  .sm\:scroll-m-1 {
    scroll-margin: .25rem;
  }

  .sm\:scroll-m-1\.5 {
    scroll-margin: .375rem;
  }

  .sm\:scroll-m-10 {
    scroll-margin: 2.5rem;
  }

  .sm\:scroll-m-11 {
    scroll-margin: 2.75rem;
  }

  .sm\:scroll-m-12 {
    scroll-margin: 3rem;
  }

  .sm\:scroll-m-14 {
    scroll-margin: 3.5rem;
  }

  .sm\:scroll-m-16 {
    scroll-margin: 4rem;
  }

  .sm\:scroll-m-2 {
    scroll-margin: .5rem;
  }

  .sm\:scroll-m-2\.5 {
    scroll-margin: .625rem;
  }

  .sm\:scroll-m-20 {
    scroll-margin: 5rem;
  }

  .sm\:scroll-m-24 {
    scroll-margin: 6rem;
  }

  .sm\:scroll-m-28 {
    scroll-margin: 7rem;
  }

  .sm\:scroll-m-3 {
    scroll-margin: .75rem;
  }

  .sm\:scroll-m-3\.5 {
    scroll-margin: .875rem;
  }

  .sm\:scroll-m-32 {
    scroll-margin: 8rem;
  }

  .sm\:scroll-m-36 {
    scroll-margin: 9rem;
  }

  .sm\:scroll-m-4 {
    scroll-margin: 1rem;
  }

  .sm\:scroll-m-40 {
    scroll-margin: 10rem;
  }

  .sm\:scroll-m-44 {
    scroll-margin: 11rem;
  }

  .sm\:scroll-m-48 {
    scroll-margin: 12rem;
  }

  .sm\:scroll-m-5 {
    scroll-margin: 1.25rem;
  }

  .sm\:scroll-m-52 {
    scroll-margin: 13rem;
  }

  .sm\:scroll-m-56 {
    scroll-margin: 14rem;
  }

  .sm\:scroll-m-6 {
    scroll-margin: 1.5rem;
  }

  .sm\:scroll-m-60 {
    scroll-margin: 15rem;
  }

  .sm\:scroll-m-64 {
    scroll-margin: 16rem;
  }

  .sm\:scroll-m-7 {
    scroll-margin: 1.75rem;
  }

  .sm\:scroll-m-72 {
    scroll-margin: 18rem;
  }

  .sm\:scroll-m-8 {
    scroll-margin: 2rem;
  }

  .sm\:scroll-m-80 {
    scroll-margin: 20rem;
  }

  .sm\:scroll-m-9 {
    scroll-margin: 2.25rem;
  }

  .sm\:scroll-m-96 {
    scroll-margin: 24rem;
  }

  .sm\:scroll-m-px {
    scroll-margin: 1px;
  }

  .sm\:-scroll-mx-0 {
    scroll-margin-left: 0;
    scroll-margin-right: 0;
  }

  .sm\:-scroll-mx-0\.5 {
    scroll-margin-left: -.125rem;
    scroll-margin-right: -.125rem;
  }

  .sm\:-scroll-mx-1 {
    scroll-margin-left: -.25rem;
    scroll-margin-right: -.25rem;
  }

  .sm\:-scroll-mx-1\.5 {
    scroll-margin-left: -.375rem;
    scroll-margin-right: -.375rem;
  }

  .sm\:-scroll-mx-10 {
    scroll-margin-left: -2.5rem;
    scroll-margin-right: -2.5rem;
  }

  .sm\:-scroll-mx-11 {
    scroll-margin-left: -2.75rem;
    scroll-margin-right: -2.75rem;
  }

  .sm\:-scroll-mx-12 {
    scroll-margin-left: -3rem;
    scroll-margin-right: -3rem;
  }

  .sm\:-scroll-mx-14 {
    scroll-margin-left: -3.5rem;
    scroll-margin-right: -3.5rem;
  }

  .sm\:-scroll-mx-16 {
    scroll-margin-left: -4rem;
    scroll-margin-right: -4rem;
  }

  .sm\:-scroll-mx-2 {
    scroll-margin-left: -.5rem;
    scroll-margin-right: -.5rem;
  }

  .sm\:-scroll-mx-2\.5 {
    scroll-margin-left: -.625rem;
    scroll-margin-right: -.625rem;
  }

  .sm\:-scroll-mx-20 {
    scroll-margin-left: -5rem;
    scroll-margin-right: -5rem;
  }

  .sm\:-scroll-mx-24 {
    scroll-margin-left: -6rem;
    scroll-margin-right: -6rem;
  }

  .sm\:-scroll-mx-28 {
    scroll-margin-left: -7rem;
    scroll-margin-right: -7rem;
  }

  .sm\:-scroll-mx-3 {
    scroll-margin-left: -.75rem;
    scroll-margin-right: -.75rem;
  }

  .sm\:-scroll-mx-3\.5 {
    scroll-margin-left: -.875rem;
    scroll-margin-right: -.875rem;
  }

  .sm\:-scroll-mx-32 {
    scroll-margin-left: -8rem;
    scroll-margin-right: -8rem;
  }

  .sm\:-scroll-mx-36 {
    scroll-margin-left: -9rem;
    scroll-margin-right: -9rem;
  }

  .sm\:-scroll-mx-4 {
    scroll-margin-left: -1rem;
    scroll-margin-right: -1rem;
  }

  .sm\:-scroll-mx-40 {
    scroll-margin-left: -10rem;
    scroll-margin-right: -10rem;
  }

  .sm\:-scroll-mx-44 {
    scroll-margin-left: -11rem;
    scroll-margin-right: -11rem;
  }

  .sm\:-scroll-mx-48 {
    scroll-margin-left: -12rem;
    scroll-margin-right: -12rem;
  }

  .sm\:-scroll-mx-5 {
    scroll-margin-left: -1.25rem;
    scroll-margin-right: -1.25rem;
  }

  .sm\:-scroll-mx-52 {
    scroll-margin-left: -13rem;
    scroll-margin-right: -13rem;
  }

  .sm\:-scroll-mx-56 {
    scroll-margin-left: -14rem;
    scroll-margin-right: -14rem;
  }

  .sm\:-scroll-mx-6 {
    scroll-margin-left: -1.5rem;
    scroll-margin-right: -1.5rem;
  }

  .sm\:-scroll-mx-60 {
    scroll-margin-left: -15rem;
    scroll-margin-right: -15rem;
  }

  .sm\:-scroll-mx-64 {
    scroll-margin-left: -16rem;
    scroll-margin-right: -16rem;
  }

  .sm\:-scroll-mx-7 {
    scroll-margin-left: -1.75rem;
    scroll-margin-right: -1.75rem;
  }

  .sm\:-scroll-mx-72 {
    scroll-margin-left: -18rem;
    scroll-margin-right: -18rem;
  }

  .sm\:-scroll-mx-8 {
    scroll-margin-left: -2rem;
    scroll-margin-right: -2rem;
  }

  .sm\:-scroll-mx-80 {
    scroll-margin-left: -20rem;
    scroll-margin-right: -20rem;
  }

  .sm\:-scroll-mx-9 {
    scroll-margin-left: -2.25rem;
    scroll-margin-right: -2.25rem;
  }

  .sm\:-scroll-mx-96 {
    scroll-margin-left: -24rem;
    scroll-margin-right: -24rem;
  }

  .sm\:-scroll-mx-px {
    scroll-margin-left: -1px;
    scroll-margin-right: -1px;
  }

  .sm\:-scroll-my-0 {
    scroll-margin-top: 0;
    scroll-margin-bottom: 0;
  }

  .sm\:-scroll-my-0\.5 {
    scroll-margin-top: -.125rem;
    scroll-margin-bottom: -.125rem;
  }

  .sm\:-scroll-my-1 {
    scroll-margin-top: -.25rem;
    scroll-margin-bottom: -.25rem;
  }

  .sm\:-scroll-my-1\.5 {
    scroll-margin-top: -.375rem;
    scroll-margin-bottom: -.375rem;
  }

  .sm\:-scroll-my-10 {
    scroll-margin-top: -2.5rem;
    scroll-margin-bottom: -2.5rem;
  }

  .sm\:-scroll-my-11 {
    scroll-margin-top: -2.75rem;
    scroll-margin-bottom: -2.75rem;
  }

  .sm\:-scroll-my-12 {
    scroll-margin-top: -3rem;
    scroll-margin-bottom: -3rem;
  }

  .sm\:-scroll-my-14 {
    scroll-margin-top: -3.5rem;
    scroll-margin-bottom: -3.5rem;
  }

  .sm\:-scroll-my-16 {
    scroll-margin-top: -4rem;
    scroll-margin-bottom: -4rem;
  }

  .sm\:-scroll-my-2 {
    scroll-margin-top: -.5rem;
    scroll-margin-bottom: -.5rem;
  }

  .sm\:-scroll-my-2\.5 {
    scroll-margin-top: -.625rem;
    scroll-margin-bottom: -.625rem;
  }

  .sm\:-scroll-my-20 {
    scroll-margin-top: -5rem;
    scroll-margin-bottom: -5rem;
  }

  .sm\:-scroll-my-24 {
    scroll-margin-top: -6rem;
    scroll-margin-bottom: -6rem;
  }

  .sm\:-scroll-my-28 {
    scroll-margin-top: -7rem;
    scroll-margin-bottom: -7rem;
  }

  .sm\:-scroll-my-3 {
    scroll-margin-top: -.75rem;
    scroll-margin-bottom: -.75rem;
  }

  .sm\:-scroll-my-3\.5 {
    scroll-margin-top: -.875rem;
    scroll-margin-bottom: -.875rem;
  }

  .sm\:-scroll-my-32 {
    scroll-margin-top: -8rem;
    scroll-margin-bottom: -8rem;
  }

  .sm\:-scroll-my-36 {
    scroll-margin-top: -9rem;
    scroll-margin-bottom: -9rem;
  }

  .sm\:-scroll-my-4 {
    scroll-margin-top: -1rem;
    scroll-margin-bottom: -1rem;
  }

  .sm\:-scroll-my-40 {
    scroll-margin-top: -10rem;
    scroll-margin-bottom: -10rem;
  }

  .sm\:-scroll-my-44 {
    scroll-margin-top: -11rem;
    scroll-margin-bottom: -11rem;
  }

  .sm\:-scroll-my-48 {
    scroll-margin-top: -12rem;
    scroll-margin-bottom: -12rem;
  }

  .sm\:-scroll-my-5 {
    scroll-margin-top: -1.25rem;
    scroll-margin-bottom: -1.25rem;
  }

  .sm\:-scroll-my-52 {
    scroll-margin-top: -13rem;
    scroll-margin-bottom: -13rem;
  }

  .sm\:-scroll-my-56 {
    scroll-margin-top: -14rem;
    scroll-margin-bottom: -14rem;
  }

  .sm\:-scroll-my-6 {
    scroll-margin-top: -1.5rem;
    scroll-margin-bottom: -1.5rem;
  }

  .sm\:-scroll-my-60 {
    scroll-margin-top: -15rem;
    scroll-margin-bottom: -15rem;
  }

  .sm\:-scroll-my-64 {
    scroll-margin-top: -16rem;
    scroll-margin-bottom: -16rem;
  }

  .sm\:-scroll-my-7 {
    scroll-margin-top: -1.75rem;
    scroll-margin-bottom: -1.75rem;
  }

  .sm\:-scroll-my-72 {
    scroll-margin-top: -18rem;
    scroll-margin-bottom: -18rem;
  }

  .sm\:-scroll-my-8 {
    scroll-margin-top: -2rem;
    scroll-margin-bottom: -2rem;
  }

  .sm\:-scroll-my-80 {
    scroll-margin-top: -20rem;
    scroll-margin-bottom: -20rem;
  }

  .sm\:-scroll-my-9 {
    scroll-margin-top: -2.25rem;
    scroll-margin-bottom: -2.25rem;
  }

  .sm\:-scroll-my-96 {
    scroll-margin-top: -24rem;
    scroll-margin-bottom: -24rem;
  }

  .sm\:-scroll-my-px {
    scroll-margin-top: -1px;
    scroll-margin-bottom: -1px;
  }

  .sm\:scroll-mx-0 {
    scroll-margin-left: 0;
    scroll-margin-right: 0;
  }

  .sm\:scroll-mx-0\.5 {
    scroll-margin-left: .125rem;
    scroll-margin-right: .125rem;
  }

  .sm\:scroll-mx-1 {
    scroll-margin-left: .25rem;
    scroll-margin-right: .25rem;
  }

  .sm\:scroll-mx-1\.5 {
    scroll-margin-left: .375rem;
    scroll-margin-right: .375rem;
  }

  .sm\:scroll-mx-10 {
    scroll-margin-left: 2.5rem;
    scroll-margin-right: 2.5rem;
  }

  .sm\:scroll-mx-11 {
    scroll-margin-left: 2.75rem;
    scroll-margin-right: 2.75rem;
  }

  .sm\:scroll-mx-12 {
    scroll-margin-left: 3rem;
    scroll-margin-right: 3rem;
  }

  .sm\:scroll-mx-14 {
    scroll-margin-left: 3.5rem;
    scroll-margin-right: 3.5rem;
  }

  .sm\:scroll-mx-16 {
    scroll-margin-left: 4rem;
    scroll-margin-right: 4rem;
  }

  .sm\:scroll-mx-2 {
    scroll-margin-left: .5rem;
    scroll-margin-right: .5rem;
  }

  .sm\:scroll-mx-2\.5 {
    scroll-margin-left: .625rem;
    scroll-margin-right: .625rem;
  }

  .sm\:scroll-mx-20 {
    scroll-margin-left: 5rem;
    scroll-margin-right: 5rem;
  }

  .sm\:scroll-mx-24 {
    scroll-margin-left: 6rem;
    scroll-margin-right: 6rem;
  }

  .sm\:scroll-mx-28 {
    scroll-margin-left: 7rem;
    scroll-margin-right: 7rem;
  }

  .sm\:scroll-mx-3 {
    scroll-margin-left: .75rem;
    scroll-margin-right: .75rem;
  }

  .sm\:scroll-mx-3\.5 {
    scroll-margin-left: .875rem;
    scroll-margin-right: .875rem;
  }

  .sm\:scroll-mx-32 {
    scroll-margin-left: 8rem;
    scroll-margin-right: 8rem;
  }

  .sm\:scroll-mx-36 {
    scroll-margin-left: 9rem;
    scroll-margin-right: 9rem;
  }

  .sm\:scroll-mx-4 {
    scroll-margin-left: 1rem;
    scroll-margin-right: 1rem;
  }

  .sm\:scroll-mx-40 {
    scroll-margin-left: 10rem;
    scroll-margin-right: 10rem;
  }

  .sm\:scroll-mx-44 {
    scroll-margin-left: 11rem;
    scroll-margin-right: 11rem;
  }

  .sm\:scroll-mx-48 {
    scroll-margin-left: 12rem;
    scroll-margin-right: 12rem;
  }

  .sm\:scroll-mx-5 {
    scroll-margin-left: 1.25rem;
    scroll-margin-right: 1.25rem;
  }

  .sm\:scroll-mx-52 {
    scroll-margin-left: 13rem;
    scroll-margin-right: 13rem;
  }

  .sm\:scroll-mx-56 {
    scroll-margin-left: 14rem;
    scroll-margin-right: 14rem;
  }

  .sm\:scroll-mx-6 {
    scroll-margin-left: 1.5rem;
    scroll-margin-right: 1.5rem;
  }

  .sm\:scroll-mx-60 {
    scroll-margin-left: 15rem;
    scroll-margin-right: 15rem;
  }

  .sm\:scroll-mx-64 {
    scroll-margin-left: 16rem;
    scroll-margin-right: 16rem;
  }

  .sm\:scroll-mx-7 {
    scroll-margin-left: 1.75rem;
    scroll-margin-right: 1.75rem;
  }

  .sm\:scroll-mx-72 {
    scroll-margin-left: 18rem;
    scroll-margin-right: 18rem;
  }

  .sm\:scroll-mx-8 {
    scroll-margin-left: 2rem;
    scroll-margin-right: 2rem;
  }

  .sm\:scroll-mx-80 {
    scroll-margin-left: 20rem;
    scroll-margin-right: 20rem;
  }

  .sm\:scroll-mx-9 {
    scroll-margin-left: 2.25rem;
    scroll-margin-right: 2.25rem;
  }

  .sm\:scroll-mx-96 {
    scroll-margin-left: 24rem;
    scroll-margin-right: 24rem;
  }

  .sm\:scroll-mx-px {
    scroll-margin-left: 1px;
    scroll-margin-right: 1px;
  }

  .sm\:scroll-my-0 {
    scroll-margin-top: 0;
    scroll-margin-bottom: 0;
  }

  .sm\:scroll-my-0\.5 {
    scroll-margin-top: .125rem;
    scroll-margin-bottom: .125rem;
  }

  .sm\:scroll-my-1 {
    scroll-margin-top: .25rem;
    scroll-margin-bottom: .25rem;
  }

  .sm\:scroll-my-1\.5 {
    scroll-margin-top: .375rem;
    scroll-margin-bottom: .375rem;
  }

  .sm\:scroll-my-10 {
    scroll-margin-top: 2.5rem;
    scroll-margin-bottom: 2.5rem;
  }

  .sm\:scroll-my-11 {
    scroll-margin-top: 2.75rem;
    scroll-margin-bottom: 2.75rem;
  }

  .sm\:scroll-my-12 {
    scroll-margin-top: 3rem;
    scroll-margin-bottom: 3rem;
  }

  .sm\:scroll-my-14 {
    scroll-margin-top: 3.5rem;
    scroll-margin-bottom: 3.5rem;
  }

  .sm\:scroll-my-16 {
    scroll-margin-top: 4rem;
    scroll-margin-bottom: 4rem;
  }

  .sm\:scroll-my-2 {
    scroll-margin-top: .5rem;
    scroll-margin-bottom: .5rem;
  }

  .sm\:scroll-my-2\.5 {
    scroll-margin-top: .625rem;
    scroll-margin-bottom: .625rem;
  }

  .sm\:scroll-my-20 {
    scroll-margin-top: 5rem;
    scroll-margin-bottom: 5rem;
  }

  .sm\:scroll-my-24 {
    scroll-margin-top: 6rem;
    scroll-margin-bottom: 6rem;
  }

  .sm\:scroll-my-28 {
    scroll-margin-top: 7rem;
    scroll-margin-bottom: 7rem;
  }

  .sm\:scroll-my-3 {
    scroll-margin-top: .75rem;
    scroll-margin-bottom: .75rem;
  }

  .sm\:scroll-my-3\.5 {
    scroll-margin-top: .875rem;
    scroll-margin-bottom: .875rem;
  }

  .sm\:scroll-my-32 {
    scroll-margin-top: 8rem;
    scroll-margin-bottom: 8rem;
  }

  .sm\:scroll-my-36 {
    scroll-margin-top: 9rem;
    scroll-margin-bottom: 9rem;
  }

  .sm\:scroll-my-4 {
    scroll-margin-top: 1rem;
    scroll-margin-bottom: 1rem;
  }

  .sm\:scroll-my-40 {
    scroll-margin-top: 10rem;
    scroll-margin-bottom: 10rem;
  }

  .sm\:scroll-my-44 {
    scroll-margin-top: 11rem;
    scroll-margin-bottom: 11rem;
  }

  .sm\:scroll-my-48 {
    scroll-margin-top: 12rem;
    scroll-margin-bottom: 12rem;
  }

  .sm\:scroll-my-5 {
    scroll-margin-top: 1.25rem;
    scroll-margin-bottom: 1.25rem;
  }

  .sm\:scroll-my-52 {
    scroll-margin-top: 13rem;
    scroll-margin-bottom: 13rem;
  }

  .sm\:scroll-my-56 {
    scroll-margin-top: 14rem;
    scroll-margin-bottom: 14rem;
  }

  .sm\:scroll-my-6 {
    scroll-margin-top: 1.5rem;
    scroll-margin-bottom: 1.5rem;
  }

  .sm\:scroll-my-60 {
    scroll-margin-top: 15rem;
    scroll-margin-bottom: 15rem;
  }

  .sm\:scroll-my-64 {
    scroll-margin-top: 16rem;
    scroll-margin-bottom: 16rem;
  }

  .sm\:scroll-my-7 {
    scroll-margin-top: 1.75rem;
    scroll-margin-bottom: 1.75rem;
  }

  .sm\:scroll-my-72 {
    scroll-margin-top: 18rem;
    scroll-margin-bottom: 18rem;
  }

  .sm\:scroll-my-8 {
    scroll-margin-top: 2rem;
    scroll-margin-bottom: 2rem;
  }

  .sm\:scroll-my-80 {
    scroll-margin-top: 20rem;
    scroll-margin-bottom: 20rem;
  }

  .sm\:scroll-my-9 {
    scroll-margin-top: 2.25rem;
    scroll-margin-bottom: 2.25rem;
  }

  .sm\:scroll-my-96 {
    scroll-margin-top: 24rem;
    scroll-margin-bottom: 24rem;
  }

  .sm\:scroll-my-px {
    scroll-margin-top: 1px;
    scroll-margin-bottom: 1px;
  }

  .sm\:-scroll-mb-0 {
    scroll-margin-bottom: 0;
  }

  .sm\:-scroll-mb-0\.5 {
    scroll-margin-bottom: -.125rem;
  }

  .sm\:-scroll-mb-1 {
    scroll-margin-bottom: -.25rem;
  }

  .sm\:-scroll-mb-1\.5 {
    scroll-margin-bottom: -.375rem;
  }

  .sm\:-scroll-mb-10 {
    scroll-margin-bottom: -2.5rem;
  }

  .sm\:-scroll-mb-11 {
    scroll-margin-bottom: -2.75rem;
  }

  .sm\:-scroll-mb-12 {
    scroll-margin-bottom: -3rem;
  }

  .sm\:-scroll-mb-14 {
    scroll-margin-bottom: -3.5rem;
  }

  .sm\:-scroll-mb-16 {
    scroll-margin-bottom: -4rem;
  }

  .sm\:-scroll-mb-2 {
    scroll-margin-bottom: -.5rem;
  }

  .sm\:-scroll-mb-2\.5 {
    scroll-margin-bottom: -.625rem;
  }

  .sm\:-scroll-mb-20 {
    scroll-margin-bottom: -5rem;
  }

  .sm\:-scroll-mb-24 {
    scroll-margin-bottom: -6rem;
  }

  .sm\:-scroll-mb-28 {
    scroll-margin-bottom: -7rem;
  }

  .sm\:-scroll-mb-3 {
    scroll-margin-bottom: -.75rem;
  }

  .sm\:-scroll-mb-3\.5 {
    scroll-margin-bottom: -.875rem;
  }

  .sm\:-scroll-mb-32 {
    scroll-margin-bottom: -8rem;
  }

  .sm\:-scroll-mb-36 {
    scroll-margin-bottom: -9rem;
  }

  .sm\:-scroll-mb-4 {
    scroll-margin-bottom: -1rem;
  }

  .sm\:-scroll-mb-40 {
    scroll-margin-bottom: -10rem;
  }

  .sm\:-scroll-mb-44 {
    scroll-margin-bottom: -11rem;
  }

  .sm\:-scroll-mb-48 {
    scroll-margin-bottom: -12rem;
  }

  .sm\:-scroll-mb-5 {
    scroll-margin-bottom: -1.25rem;
  }

  .sm\:-scroll-mb-52 {
    scroll-margin-bottom: -13rem;
  }

  .sm\:-scroll-mb-56 {
    scroll-margin-bottom: -14rem;
  }

  .sm\:-scroll-mb-6 {
    scroll-margin-bottom: -1.5rem;
  }

  .sm\:-scroll-mb-60 {
    scroll-margin-bottom: -15rem;
  }

  .sm\:-scroll-mb-64 {
    scroll-margin-bottom: -16rem;
  }

  .sm\:-scroll-mb-7 {
    scroll-margin-bottom: -1.75rem;
  }

  .sm\:-scroll-mb-72 {
    scroll-margin-bottom: -18rem;
  }

  .sm\:-scroll-mb-8 {
    scroll-margin-bottom: -2rem;
  }

  .sm\:-scroll-mb-80 {
    scroll-margin-bottom: -20rem;
  }

  .sm\:-scroll-mb-9 {
    scroll-margin-bottom: -2.25rem;
  }

  .sm\:-scroll-mb-96 {
    scroll-margin-bottom: -24rem;
  }

  .sm\:-scroll-mb-px {
    scroll-margin-bottom: -1px;
  }

  .sm\:-scroll-me-0 {
    scroll-margin-inline-end: 0;
  }

  .sm\:-scroll-me-0\.5 {
    scroll-margin-inline-end: -.125rem;
  }

  .sm\:-scroll-me-1 {
    scroll-margin-inline-end: -.25rem;
  }

  .sm\:-scroll-me-1\.5 {
    scroll-margin-inline-end: -.375rem;
  }

  .sm\:-scroll-me-10 {
    scroll-margin-inline-end: -2.5rem;
  }

  .sm\:-scroll-me-11 {
    scroll-margin-inline-end: -2.75rem;
  }

  .sm\:-scroll-me-12 {
    scroll-margin-inline-end: -3rem;
  }

  .sm\:-scroll-me-14 {
    scroll-margin-inline-end: -3.5rem;
  }

  .sm\:-scroll-me-16 {
    scroll-margin-inline-end: -4rem;
  }

  .sm\:-scroll-me-2 {
    scroll-margin-inline-end: -.5rem;
  }

  .sm\:-scroll-me-2\.5 {
    scroll-margin-inline-end: -.625rem;
  }

  .sm\:-scroll-me-20 {
    scroll-margin-inline-end: -5rem;
  }

  .sm\:-scroll-me-24 {
    scroll-margin-inline-end: -6rem;
  }

  .sm\:-scroll-me-28 {
    scroll-margin-inline-end: -7rem;
  }

  .sm\:-scroll-me-3 {
    scroll-margin-inline-end: -.75rem;
  }

  .sm\:-scroll-me-3\.5 {
    scroll-margin-inline-end: -.875rem;
  }

  .sm\:-scroll-me-32 {
    scroll-margin-inline-end: -8rem;
  }

  .sm\:-scroll-me-36 {
    scroll-margin-inline-end: -9rem;
  }

  .sm\:-scroll-me-4 {
    scroll-margin-inline-end: -1rem;
  }

  .sm\:-scroll-me-40 {
    scroll-margin-inline-end: -10rem;
  }

  .sm\:-scroll-me-44 {
    scroll-margin-inline-end: -11rem;
  }

  .sm\:-scroll-me-48 {
    scroll-margin-inline-end: -12rem;
  }

  .sm\:-scroll-me-5 {
    scroll-margin-inline-end: -1.25rem;
  }

  .sm\:-scroll-me-52 {
    scroll-margin-inline-end: -13rem;
  }

  .sm\:-scroll-me-56 {
    scroll-margin-inline-end: -14rem;
  }

  .sm\:-scroll-me-6 {
    scroll-margin-inline-end: -1.5rem;
  }

  .sm\:-scroll-me-60 {
    scroll-margin-inline-end: -15rem;
  }

  .sm\:-scroll-me-64 {
    scroll-margin-inline-end: -16rem;
  }

  .sm\:-scroll-me-7 {
    scroll-margin-inline-end: -1.75rem;
  }

  .sm\:-scroll-me-72 {
    scroll-margin-inline-end: -18rem;
  }

  .sm\:-scroll-me-8 {
    scroll-margin-inline-end: -2rem;
  }

  .sm\:-scroll-me-80 {
    scroll-margin-inline-end: -20rem;
  }

  .sm\:-scroll-me-9 {
    scroll-margin-inline-end: -2.25rem;
  }

  .sm\:-scroll-me-96 {
    scroll-margin-inline-end: -24rem;
  }

  .sm\:-scroll-me-px {
    scroll-margin-inline-end: -1px;
  }

  .sm\:-scroll-ml-0 {
    scroll-margin-left: 0;
  }

  .sm\:-scroll-ml-0\.5 {
    scroll-margin-left: -.125rem;
  }

  .sm\:-scroll-ml-1 {
    scroll-margin-left: -.25rem;
  }

  .sm\:-scroll-ml-1\.5 {
    scroll-margin-left: -.375rem;
  }

  .sm\:-scroll-ml-10 {
    scroll-margin-left: -2.5rem;
  }

  .sm\:-scroll-ml-11 {
    scroll-margin-left: -2.75rem;
  }

  .sm\:-scroll-ml-12 {
    scroll-margin-left: -3rem;
  }

  .sm\:-scroll-ml-14 {
    scroll-margin-left: -3.5rem;
  }

  .sm\:-scroll-ml-16 {
    scroll-margin-left: -4rem;
  }

  .sm\:-scroll-ml-2 {
    scroll-margin-left: -.5rem;
  }

  .sm\:-scroll-ml-2\.5 {
    scroll-margin-left: -.625rem;
  }

  .sm\:-scroll-ml-20 {
    scroll-margin-left: -5rem;
  }

  .sm\:-scroll-ml-24 {
    scroll-margin-left: -6rem;
  }

  .sm\:-scroll-ml-28 {
    scroll-margin-left: -7rem;
  }

  .sm\:-scroll-ml-3 {
    scroll-margin-left: -.75rem;
  }

  .sm\:-scroll-ml-3\.5 {
    scroll-margin-left: -.875rem;
  }

  .sm\:-scroll-ml-32 {
    scroll-margin-left: -8rem;
  }

  .sm\:-scroll-ml-36 {
    scroll-margin-left: -9rem;
  }

  .sm\:-scroll-ml-4 {
    scroll-margin-left: -1rem;
  }

  .sm\:-scroll-ml-40 {
    scroll-margin-left: -10rem;
  }

  .sm\:-scroll-ml-44 {
    scroll-margin-left: -11rem;
  }

  .sm\:-scroll-ml-48 {
    scroll-margin-left: -12rem;
  }

  .sm\:-scroll-ml-5 {
    scroll-margin-left: -1.25rem;
  }

  .sm\:-scroll-ml-52 {
    scroll-margin-left: -13rem;
  }

  .sm\:-scroll-ml-56 {
    scroll-margin-left: -14rem;
  }

  .sm\:-scroll-ml-6 {
    scroll-margin-left: -1.5rem;
  }

  .sm\:-scroll-ml-60 {
    scroll-margin-left: -15rem;
  }

  .sm\:-scroll-ml-64 {
    scroll-margin-left: -16rem;
  }

  .sm\:-scroll-ml-7 {
    scroll-margin-left: -1.75rem;
  }

  .sm\:-scroll-ml-72 {
    scroll-margin-left: -18rem;
  }

  .sm\:-scroll-ml-8 {
    scroll-margin-left: -2rem;
  }

  .sm\:-scroll-ml-80 {
    scroll-margin-left: -20rem;
  }

  .sm\:-scroll-ml-9 {
    scroll-margin-left: -2.25rem;
  }

  .sm\:-scroll-ml-96 {
    scroll-margin-left: -24rem;
  }

  .sm\:-scroll-ml-px {
    scroll-margin-left: -1px;
  }

  .sm\:-scroll-mr-0 {
    scroll-margin-right: 0;
  }

  .sm\:-scroll-mr-0\.5 {
    scroll-margin-right: -.125rem;
  }

  .sm\:-scroll-mr-1 {
    scroll-margin-right: -.25rem;
  }

  .sm\:-scroll-mr-1\.5 {
    scroll-margin-right: -.375rem;
  }

  .sm\:-scroll-mr-10 {
    scroll-margin-right: -2.5rem;
  }

  .sm\:-scroll-mr-11 {
    scroll-margin-right: -2.75rem;
  }

  .sm\:-scroll-mr-12 {
    scroll-margin-right: -3rem;
  }

  .sm\:-scroll-mr-14 {
    scroll-margin-right: -3.5rem;
  }

  .sm\:-scroll-mr-16 {
    scroll-margin-right: -4rem;
  }

  .sm\:-scroll-mr-2 {
    scroll-margin-right: -.5rem;
  }

  .sm\:-scroll-mr-2\.5 {
    scroll-margin-right: -.625rem;
  }

  .sm\:-scroll-mr-20 {
    scroll-margin-right: -5rem;
  }

  .sm\:-scroll-mr-24 {
    scroll-margin-right: -6rem;
  }

  .sm\:-scroll-mr-28 {
    scroll-margin-right: -7rem;
  }

  .sm\:-scroll-mr-3 {
    scroll-margin-right: -.75rem;
  }

  .sm\:-scroll-mr-3\.5 {
    scroll-margin-right: -.875rem;
  }

  .sm\:-scroll-mr-32 {
    scroll-margin-right: -8rem;
  }

  .sm\:-scroll-mr-36 {
    scroll-margin-right: -9rem;
  }

  .sm\:-scroll-mr-4 {
    scroll-margin-right: -1rem;
  }

  .sm\:-scroll-mr-40 {
    scroll-margin-right: -10rem;
  }

  .sm\:-scroll-mr-44 {
    scroll-margin-right: -11rem;
  }

  .sm\:-scroll-mr-48 {
    scroll-margin-right: -12rem;
  }

  .sm\:-scroll-mr-5 {
    scroll-margin-right: -1.25rem;
  }

  .sm\:-scroll-mr-52 {
    scroll-margin-right: -13rem;
  }

  .sm\:-scroll-mr-56 {
    scroll-margin-right: -14rem;
  }

  .sm\:-scroll-mr-6 {
    scroll-margin-right: -1.5rem;
  }

  .sm\:-scroll-mr-60 {
    scroll-margin-right: -15rem;
  }

  .sm\:-scroll-mr-64 {
    scroll-margin-right: -16rem;
  }

  .sm\:-scroll-mr-7 {
    scroll-margin-right: -1.75rem;
  }

  .sm\:-scroll-mr-72 {
    scroll-margin-right: -18rem;
  }

  .sm\:-scroll-mr-8 {
    scroll-margin-right: -2rem;
  }

  .sm\:-scroll-mr-80 {
    scroll-margin-right: -20rem;
  }

  .sm\:-scroll-mr-9 {
    scroll-margin-right: -2.25rem;
  }

  .sm\:-scroll-mr-96 {
    scroll-margin-right: -24rem;
  }

  .sm\:-scroll-mr-px {
    scroll-margin-right: -1px;
  }

  .sm\:-scroll-ms-0 {
    scroll-margin-inline-start: 0;
  }

  .sm\:-scroll-ms-0\.5 {
    scroll-margin-inline-start: -.125rem;
  }

  .sm\:-scroll-ms-1 {
    scroll-margin-inline-start: -.25rem;
  }

  .sm\:-scroll-ms-1\.5 {
    scroll-margin-inline-start: -.375rem;
  }

  .sm\:-scroll-ms-10 {
    scroll-margin-inline-start: -2.5rem;
  }

  .sm\:-scroll-ms-11 {
    scroll-margin-inline-start: -2.75rem;
  }

  .sm\:-scroll-ms-12 {
    scroll-margin-inline-start: -3rem;
  }

  .sm\:-scroll-ms-14 {
    scroll-margin-inline-start: -3.5rem;
  }

  .sm\:-scroll-ms-16 {
    scroll-margin-inline-start: -4rem;
  }

  .sm\:-scroll-ms-2 {
    scroll-margin-inline-start: -.5rem;
  }

  .sm\:-scroll-ms-2\.5 {
    scroll-margin-inline-start: -.625rem;
  }

  .sm\:-scroll-ms-20 {
    scroll-margin-inline-start: -5rem;
  }

  .sm\:-scroll-ms-24 {
    scroll-margin-inline-start: -6rem;
  }

  .sm\:-scroll-ms-28 {
    scroll-margin-inline-start: -7rem;
  }

  .sm\:-scroll-ms-3 {
    scroll-margin-inline-start: -.75rem;
  }

  .sm\:-scroll-ms-3\.5 {
    scroll-margin-inline-start: -.875rem;
  }

  .sm\:-scroll-ms-32 {
    scroll-margin-inline-start: -8rem;
  }

  .sm\:-scroll-ms-36 {
    scroll-margin-inline-start: -9rem;
  }

  .sm\:-scroll-ms-4 {
    scroll-margin-inline-start: -1rem;
  }

  .sm\:-scroll-ms-40 {
    scroll-margin-inline-start: -10rem;
  }

  .sm\:-scroll-ms-44 {
    scroll-margin-inline-start: -11rem;
  }

  .sm\:-scroll-ms-48 {
    scroll-margin-inline-start: -12rem;
  }

  .sm\:-scroll-ms-5 {
    scroll-margin-inline-start: -1.25rem;
  }

  .sm\:-scroll-ms-52 {
    scroll-margin-inline-start: -13rem;
  }

  .sm\:-scroll-ms-56 {
    scroll-margin-inline-start: -14rem;
  }

  .sm\:-scroll-ms-6 {
    scroll-margin-inline-start: -1.5rem;
  }

  .sm\:-scroll-ms-60 {
    scroll-margin-inline-start: -15rem;
  }

  .sm\:-scroll-ms-64 {
    scroll-margin-inline-start: -16rem;
  }

  .sm\:-scroll-ms-7 {
    scroll-margin-inline-start: -1.75rem;
  }

  .sm\:-scroll-ms-72 {
    scroll-margin-inline-start: -18rem;
  }

  .sm\:-scroll-ms-8 {
    scroll-margin-inline-start: -2rem;
  }

  .sm\:-scroll-ms-80 {
    scroll-margin-inline-start: -20rem;
  }

  .sm\:-scroll-ms-9 {
    scroll-margin-inline-start: -2.25rem;
  }

  .sm\:-scroll-ms-96 {
    scroll-margin-inline-start: -24rem;
  }

  .sm\:-scroll-ms-px {
    scroll-margin-inline-start: -1px;
  }

  .sm\:-scroll-mt-0 {
    scroll-margin-top: 0;
  }

  .sm\:-scroll-mt-0\.5 {
    scroll-margin-top: -.125rem;
  }

  .sm\:-scroll-mt-1 {
    scroll-margin-top: -.25rem;
  }

  .sm\:-scroll-mt-1\.5 {
    scroll-margin-top: -.375rem;
  }

  .sm\:-scroll-mt-10 {
    scroll-margin-top: -2.5rem;
  }

  .sm\:-scroll-mt-11 {
    scroll-margin-top: -2.75rem;
  }

  .sm\:-scroll-mt-12 {
    scroll-margin-top: -3rem;
  }

  .sm\:-scroll-mt-14 {
    scroll-margin-top: -3.5rem;
  }

  .sm\:-scroll-mt-16 {
    scroll-margin-top: -4rem;
  }

  .sm\:-scroll-mt-2 {
    scroll-margin-top: -.5rem;
  }

  .sm\:-scroll-mt-2\.5 {
    scroll-margin-top: -.625rem;
  }

  .sm\:-scroll-mt-20 {
    scroll-margin-top: -5rem;
  }

  .sm\:-scroll-mt-24 {
    scroll-margin-top: -6rem;
  }

  .sm\:-scroll-mt-28 {
    scroll-margin-top: -7rem;
  }

  .sm\:-scroll-mt-3 {
    scroll-margin-top: -.75rem;
  }

  .sm\:-scroll-mt-3\.5 {
    scroll-margin-top: -.875rem;
  }

  .sm\:-scroll-mt-32 {
    scroll-margin-top: -8rem;
  }

  .sm\:-scroll-mt-36 {
    scroll-margin-top: -9rem;
  }

  .sm\:-scroll-mt-4 {
    scroll-margin-top: -1rem;
  }

  .sm\:-scroll-mt-40 {
    scroll-margin-top: -10rem;
  }

  .sm\:-scroll-mt-44 {
    scroll-margin-top: -11rem;
  }

  .sm\:-scroll-mt-48 {
    scroll-margin-top: -12rem;
  }

  .sm\:-scroll-mt-5 {
    scroll-margin-top: -1.25rem;
  }

  .sm\:-scroll-mt-52 {
    scroll-margin-top: -13rem;
  }

  .sm\:-scroll-mt-56 {
    scroll-margin-top: -14rem;
  }

  .sm\:-scroll-mt-6 {
    scroll-margin-top: -1.5rem;
  }

  .sm\:-scroll-mt-60 {
    scroll-margin-top: -15rem;
  }

  .sm\:-scroll-mt-64 {
    scroll-margin-top: -16rem;
  }

  .sm\:-scroll-mt-7 {
    scroll-margin-top: -1.75rem;
  }

  .sm\:-scroll-mt-72 {
    scroll-margin-top: -18rem;
  }

  .sm\:-scroll-mt-8 {
    scroll-margin-top: -2rem;
  }

  .sm\:-scroll-mt-80 {
    scroll-margin-top: -20rem;
  }

  .sm\:-scroll-mt-9 {
    scroll-margin-top: -2.25rem;
  }

  .sm\:-scroll-mt-96 {
    scroll-margin-top: -24rem;
  }

  .sm\:-scroll-mt-px {
    scroll-margin-top: -1px;
  }

  .sm\:scroll-mb-0 {
    scroll-margin-bottom: 0;
  }

  .sm\:scroll-mb-0\.5 {
    scroll-margin-bottom: .125rem;
  }

  .sm\:scroll-mb-1 {
    scroll-margin-bottom: .25rem;
  }

  .sm\:scroll-mb-1\.5 {
    scroll-margin-bottom: .375rem;
  }

  .sm\:scroll-mb-10 {
    scroll-margin-bottom: 2.5rem;
  }

  .sm\:scroll-mb-11 {
    scroll-margin-bottom: 2.75rem;
  }

  .sm\:scroll-mb-12 {
    scroll-margin-bottom: 3rem;
  }

  .sm\:scroll-mb-14 {
    scroll-margin-bottom: 3.5rem;
  }

  .sm\:scroll-mb-16 {
    scroll-margin-bottom: 4rem;
  }

  .sm\:scroll-mb-2 {
    scroll-margin-bottom: .5rem;
  }

  .sm\:scroll-mb-2\.5 {
    scroll-margin-bottom: .625rem;
  }

  .sm\:scroll-mb-20 {
    scroll-margin-bottom: 5rem;
  }

  .sm\:scroll-mb-24 {
    scroll-margin-bottom: 6rem;
  }

  .sm\:scroll-mb-28 {
    scroll-margin-bottom: 7rem;
  }

  .sm\:scroll-mb-3 {
    scroll-margin-bottom: .75rem;
  }

  .sm\:scroll-mb-3\.5 {
    scroll-margin-bottom: .875rem;
  }

  .sm\:scroll-mb-32 {
    scroll-margin-bottom: 8rem;
  }

  .sm\:scroll-mb-36 {
    scroll-margin-bottom: 9rem;
  }

  .sm\:scroll-mb-4 {
    scroll-margin-bottom: 1rem;
  }

  .sm\:scroll-mb-40 {
    scroll-margin-bottom: 10rem;
  }

  .sm\:scroll-mb-44 {
    scroll-margin-bottom: 11rem;
  }

  .sm\:scroll-mb-48 {
    scroll-margin-bottom: 12rem;
  }

  .sm\:scroll-mb-5 {
    scroll-margin-bottom: 1.25rem;
  }

  .sm\:scroll-mb-52 {
    scroll-margin-bottom: 13rem;
  }

  .sm\:scroll-mb-56 {
    scroll-margin-bottom: 14rem;
  }

  .sm\:scroll-mb-6 {
    scroll-margin-bottom: 1.5rem;
  }

  .sm\:scroll-mb-60 {
    scroll-margin-bottom: 15rem;
  }

  .sm\:scroll-mb-64 {
    scroll-margin-bottom: 16rem;
  }

  .sm\:scroll-mb-7 {
    scroll-margin-bottom: 1.75rem;
  }

  .sm\:scroll-mb-72 {
    scroll-margin-bottom: 18rem;
  }

  .sm\:scroll-mb-8 {
    scroll-margin-bottom: 2rem;
  }

  .sm\:scroll-mb-80 {
    scroll-margin-bottom: 20rem;
  }

  .sm\:scroll-mb-9 {
    scroll-margin-bottom: 2.25rem;
  }

  .sm\:scroll-mb-96 {
    scroll-margin-bottom: 24rem;
  }

  .sm\:scroll-mb-px {
    scroll-margin-bottom: 1px;
  }

  .sm\:scroll-me-0 {
    scroll-margin-inline-end: 0;
  }

  .sm\:scroll-me-0\.5 {
    scroll-margin-inline-end: .125rem;
  }

  .sm\:scroll-me-1 {
    scroll-margin-inline-end: .25rem;
  }

  .sm\:scroll-me-1\.5 {
    scroll-margin-inline-end: .375rem;
  }

  .sm\:scroll-me-10 {
    scroll-margin-inline-end: 2.5rem;
  }

  .sm\:scroll-me-11 {
    scroll-margin-inline-end: 2.75rem;
  }

  .sm\:scroll-me-12 {
    scroll-margin-inline-end: 3rem;
  }

  .sm\:scroll-me-14 {
    scroll-margin-inline-end: 3.5rem;
  }

  .sm\:scroll-me-16 {
    scroll-margin-inline-end: 4rem;
  }

  .sm\:scroll-me-2 {
    scroll-margin-inline-end: .5rem;
  }

  .sm\:scroll-me-2\.5 {
    scroll-margin-inline-end: .625rem;
  }

  .sm\:scroll-me-20 {
    scroll-margin-inline-end: 5rem;
  }

  .sm\:scroll-me-24 {
    scroll-margin-inline-end: 6rem;
  }

  .sm\:scroll-me-28 {
    scroll-margin-inline-end: 7rem;
  }

  .sm\:scroll-me-3 {
    scroll-margin-inline-end: .75rem;
  }

  .sm\:scroll-me-3\.5 {
    scroll-margin-inline-end: .875rem;
  }

  .sm\:scroll-me-32 {
    scroll-margin-inline-end: 8rem;
  }

  .sm\:scroll-me-36 {
    scroll-margin-inline-end: 9rem;
  }

  .sm\:scroll-me-4 {
    scroll-margin-inline-end: 1rem;
  }

  .sm\:scroll-me-40 {
    scroll-margin-inline-end: 10rem;
  }

  .sm\:scroll-me-44 {
    scroll-margin-inline-end: 11rem;
  }

  .sm\:scroll-me-48 {
    scroll-margin-inline-end: 12rem;
  }

  .sm\:scroll-me-5 {
    scroll-margin-inline-end: 1.25rem;
  }

  .sm\:scroll-me-52 {
    scroll-margin-inline-end: 13rem;
  }

  .sm\:scroll-me-56 {
    scroll-margin-inline-end: 14rem;
  }

  .sm\:scroll-me-6 {
    scroll-margin-inline-end: 1.5rem;
  }

  .sm\:scroll-me-60 {
    scroll-margin-inline-end: 15rem;
  }

  .sm\:scroll-me-64 {
    scroll-margin-inline-end: 16rem;
  }

  .sm\:scroll-me-7 {
    scroll-margin-inline-end: 1.75rem;
  }

  .sm\:scroll-me-72 {
    scroll-margin-inline-end: 18rem;
  }

  .sm\:scroll-me-8 {
    scroll-margin-inline-end: 2rem;
  }

  .sm\:scroll-me-80 {
    scroll-margin-inline-end: 20rem;
  }

  .sm\:scroll-me-9 {
    scroll-margin-inline-end: 2.25rem;
  }

  .sm\:scroll-me-96 {
    scroll-margin-inline-end: 24rem;
  }

  .sm\:scroll-me-px {
    scroll-margin-inline-end: 1px;
  }

  .sm\:scroll-ml-0 {
    scroll-margin-left: 0;
  }

  .sm\:scroll-ml-0\.5 {
    scroll-margin-left: .125rem;
  }

  .sm\:scroll-ml-1 {
    scroll-margin-left: .25rem;
  }

  .sm\:scroll-ml-1\.5 {
    scroll-margin-left: .375rem;
  }

  .sm\:scroll-ml-10 {
    scroll-margin-left: 2.5rem;
  }

  .sm\:scroll-ml-11 {
    scroll-margin-left: 2.75rem;
  }

  .sm\:scroll-ml-12 {
    scroll-margin-left: 3rem;
  }

  .sm\:scroll-ml-14 {
    scroll-margin-left: 3.5rem;
  }

  .sm\:scroll-ml-16 {
    scroll-margin-left: 4rem;
  }

  .sm\:scroll-ml-2 {
    scroll-margin-left: .5rem;
  }

  .sm\:scroll-ml-2\.5 {
    scroll-margin-left: .625rem;
  }

  .sm\:scroll-ml-20 {
    scroll-margin-left: 5rem;
  }

  .sm\:scroll-ml-24 {
    scroll-margin-left: 6rem;
  }

  .sm\:scroll-ml-28 {
    scroll-margin-left: 7rem;
  }

  .sm\:scroll-ml-3 {
    scroll-margin-left: .75rem;
  }

  .sm\:scroll-ml-3\.5 {
    scroll-margin-left: .875rem;
  }

  .sm\:scroll-ml-32 {
    scroll-margin-left: 8rem;
  }

  .sm\:scroll-ml-36 {
    scroll-margin-left: 9rem;
  }

  .sm\:scroll-ml-4 {
    scroll-margin-left: 1rem;
  }

  .sm\:scroll-ml-40 {
    scroll-margin-left: 10rem;
  }

  .sm\:scroll-ml-44 {
    scroll-margin-left: 11rem;
  }

  .sm\:scroll-ml-48 {
    scroll-margin-left: 12rem;
  }

  .sm\:scroll-ml-5 {
    scroll-margin-left: 1.25rem;
  }

  .sm\:scroll-ml-52 {
    scroll-margin-left: 13rem;
  }

  .sm\:scroll-ml-56 {
    scroll-margin-left: 14rem;
  }

  .sm\:scroll-ml-6 {
    scroll-margin-left: 1.5rem;
  }

  .sm\:scroll-ml-60 {
    scroll-margin-left: 15rem;
  }

  .sm\:scroll-ml-64 {
    scroll-margin-left: 16rem;
  }

  .sm\:scroll-ml-7 {
    scroll-margin-left: 1.75rem;
  }

  .sm\:scroll-ml-72 {
    scroll-margin-left: 18rem;
  }

  .sm\:scroll-ml-8 {
    scroll-margin-left: 2rem;
  }

  .sm\:scroll-ml-80 {
    scroll-margin-left: 20rem;
  }

  .sm\:scroll-ml-9 {
    scroll-margin-left: 2.25rem;
  }

  .sm\:scroll-ml-96 {
    scroll-margin-left: 24rem;
  }

  .sm\:scroll-ml-px {
    scroll-margin-left: 1px;
  }

  .sm\:scroll-mr-0 {
    scroll-margin-right: 0;
  }

  .sm\:scroll-mr-0\.5 {
    scroll-margin-right: .125rem;
  }

  .sm\:scroll-mr-1 {
    scroll-margin-right: .25rem;
  }

  .sm\:scroll-mr-1\.5 {
    scroll-margin-right: .375rem;
  }

  .sm\:scroll-mr-10 {
    scroll-margin-right: 2.5rem;
  }

  .sm\:scroll-mr-11 {
    scroll-margin-right: 2.75rem;
  }

  .sm\:scroll-mr-12 {
    scroll-margin-right: 3rem;
  }

  .sm\:scroll-mr-14 {
    scroll-margin-right: 3.5rem;
  }

  .sm\:scroll-mr-16 {
    scroll-margin-right: 4rem;
  }

  .sm\:scroll-mr-2 {
    scroll-margin-right: .5rem;
  }

  .sm\:scroll-mr-2\.5 {
    scroll-margin-right: .625rem;
  }

  .sm\:scroll-mr-20 {
    scroll-margin-right: 5rem;
  }

  .sm\:scroll-mr-24 {
    scroll-margin-right: 6rem;
  }

  .sm\:scroll-mr-28 {
    scroll-margin-right: 7rem;
  }

  .sm\:scroll-mr-3 {
    scroll-margin-right: .75rem;
  }

  .sm\:scroll-mr-3\.5 {
    scroll-margin-right: .875rem;
  }

  .sm\:scroll-mr-32 {
    scroll-margin-right: 8rem;
  }

  .sm\:scroll-mr-36 {
    scroll-margin-right: 9rem;
  }

  .sm\:scroll-mr-4 {
    scroll-margin-right: 1rem;
  }

  .sm\:scroll-mr-40 {
    scroll-margin-right: 10rem;
  }

  .sm\:scroll-mr-44 {
    scroll-margin-right: 11rem;
  }

  .sm\:scroll-mr-48 {
    scroll-margin-right: 12rem;
  }

  .sm\:scroll-mr-5 {
    scroll-margin-right: 1.25rem;
  }

  .sm\:scroll-mr-52 {
    scroll-margin-right: 13rem;
  }

  .sm\:scroll-mr-56 {
    scroll-margin-right: 14rem;
  }

  .sm\:scroll-mr-6 {
    scroll-margin-right: 1.5rem;
  }

  .sm\:scroll-mr-60 {
    scroll-margin-right: 15rem;
  }

  .sm\:scroll-mr-64 {
    scroll-margin-right: 16rem;
  }

  .sm\:scroll-mr-7 {
    scroll-margin-right: 1.75rem;
  }

  .sm\:scroll-mr-72 {
    scroll-margin-right: 18rem;
  }

  .sm\:scroll-mr-8 {
    scroll-margin-right: 2rem;
  }

  .sm\:scroll-mr-80 {
    scroll-margin-right: 20rem;
  }

  .sm\:scroll-mr-9 {
    scroll-margin-right: 2.25rem;
  }

  .sm\:scroll-mr-96 {
    scroll-margin-right: 24rem;
  }

  .sm\:scroll-mr-px {
    scroll-margin-right: 1px;
  }

  .sm\:scroll-ms-0 {
    scroll-margin-inline-start: 0;
  }

  .sm\:scroll-ms-0\.5 {
    scroll-margin-inline-start: .125rem;
  }

  .sm\:scroll-ms-1 {
    scroll-margin-inline-start: .25rem;
  }

  .sm\:scroll-ms-1\.5 {
    scroll-margin-inline-start: .375rem;
  }

  .sm\:scroll-ms-10 {
    scroll-margin-inline-start: 2.5rem;
  }

  .sm\:scroll-ms-11 {
    scroll-margin-inline-start: 2.75rem;
  }

  .sm\:scroll-ms-12 {
    scroll-margin-inline-start: 3rem;
  }

  .sm\:scroll-ms-14 {
    scroll-margin-inline-start: 3.5rem;
  }

  .sm\:scroll-ms-16 {
    scroll-margin-inline-start: 4rem;
  }

  .sm\:scroll-ms-2 {
    scroll-margin-inline-start: .5rem;
  }

  .sm\:scroll-ms-2\.5 {
    scroll-margin-inline-start: .625rem;
  }

  .sm\:scroll-ms-20 {
    scroll-margin-inline-start: 5rem;
  }

  .sm\:scroll-ms-24 {
    scroll-margin-inline-start: 6rem;
  }

  .sm\:scroll-ms-28 {
    scroll-margin-inline-start: 7rem;
  }

  .sm\:scroll-ms-3 {
    scroll-margin-inline-start: .75rem;
  }

  .sm\:scroll-ms-3\.5 {
    scroll-margin-inline-start: .875rem;
  }

  .sm\:scroll-ms-32 {
    scroll-margin-inline-start: 8rem;
  }

  .sm\:scroll-ms-36 {
    scroll-margin-inline-start: 9rem;
  }

  .sm\:scroll-ms-4 {
    scroll-margin-inline-start: 1rem;
  }

  .sm\:scroll-ms-40 {
    scroll-margin-inline-start: 10rem;
  }

  .sm\:scroll-ms-44 {
    scroll-margin-inline-start: 11rem;
  }

  .sm\:scroll-ms-48 {
    scroll-margin-inline-start: 12rem;
  }

  .sm\:scroll-ms-5 {
    scroll-margin-inline-start: 1.25rem;
  }

  .sm\:scroll-ms-52 {
    scroll-margin-inline-start: 13rem;
  }

  .sm\:scroll-ms-56 {
    scroll-margin-inline-start: 14rem;
  }

  .sm\:scroll-ms-6 {
    scroll-margin-inline-start: 1.5rem;
  }

  .sm\:scroll-ms-60 {
    scroll-margin-inline-start: 15rem;
  }

  .sm\:scroll-ms-64 {
    scroll-margin-inline-start: 16rem;
  }

  .sm\:scroll-ms-7 {
    scroll-margin-inline-start: 1.75rem;
  }

  .sm\:scroll-ms-72 {
    scroll-margin-inline-start: 18rem;
  }

  .sm\:scroll-ms-8 {
    scroll-margin-inline-start: 2rem;
  }

  .sm\:scroll-ms-80 {
    scroll-margin-inline-start: 20rem;
  }

  .sm\:scroll-ms-9 {
    scroll-margin-inline-start: 2.25rem;
  }

  .sm\:scroll-ms-96 {
    scroll-margin-inline-start: 24rem;
  }

  .sm\:scroll-ms-px {
    scroll-margin-inline-start: 1px;
  }

  .sm\:scroll-mt-0 {
    scroll-margin-top: 0;
  }

  .sm\:scroll-mt-0\.5 {
    scroll-margin-top: .125rem;
  }

  .sm\:scroll-mt-1 {
    scroll-margin-top: .25rem;
  }

  .sm\:scroll-mt-1\.5 {
    scroll-margin-top: .375rem;
  }

  .sm\:scroll-mt-10 {
    scroll-margin-top: 2.5rem;
  }

  .sm\:scroll-mt-11 {
    scroll-margin-top: 2.75rem;
  }

  .sm\:scroll-mt-12 {
    scroll-margin-top: 3rem;
  }

  .sm\:scroll-mt-14 {
    scroll-margin-top: 3.5rem;
  }

  .sm\:scroll-mt-16 {
    scroll-margin-top: 4rem;
  }

  .sm\:scroll-mt-2 {
    scroll-margin-top: .5rem;
  }

  .sm\:scroll-mt-2\.5 {
    scroll-margin-top: .625rem;
  }

  .sm\:scroll-mt-20 {
    scroll-margin-top: 5rem;
  }

  .sm\:scroll-mt-24 {
    scroll-margin-top: 6rem;
  }

  .sm\:scroll-mt-28 {
    scroll-margin-top: 7rem;
  }

  .sm\:scroll-mt-3 {
    scroll-margin-top: .75rem;
  }

  .sm\:scroll-mt-3\.5 {
    scroll-margin-top: .875rem;
  }

  .sm\:scroll-mt-32 {
    scroll-margin-top: 8rem;
  }

  .sm\:scroll-mt-36 {
    scroll-margin-top: 9rem;
  }

  .sm\:scroll-mt-4 {
    scroll-margin-top: 1rem;
  }

  .sm\:scroll-mt-40 {
    scroll-margin-top: 10rem;
  }

  .sm\:scroll-mt-44 {
    scroll-margin-top: 11rem;
  }

  .sm\:scroll-mt-48 {
    scroll-margin-top: 12rem;
  }

  .sm\:scroll-mt-5 {
    scroll-margin-top: 1.25rem;
  }

  .sm\:scroll-mt-52 {
    scroll-margin-top: 13rem;
  }

  .sm\:scroll-mt-56 {
    scroll-margin-top: 14rem;
  }

  .sm\:scroll-mt-6 {
    scroll-margin-top: 1.5rem;
  }

  .sm\:scroll-mt-60 {
    scroll-margin-top: 15rem;
  }

  .sm\:scroll-mt-64 {
    scroll-margin-top: 16rem;
  }

  .sm\:scroll-mt-7 {
    scroll-margin-top: 1.75rem;
  }

  .sm\:scroll-mt-72 {
    scroll-margin-top: 18rem;
  }

  .sm\:scroll-mt-8 {
    scroll-margin-top: 2rem;
  }

  .sm\:scroll-mt-80 {
    scroll-margin-top: 20rem;
  }

  .sm\:scroll-mt-9 {
    scroll-margin-top: 2.25rem;
  }

  .sm\:scroll-mt-96 {
    scroll-margin-top: 24rem;
  }

  .sm\:scroll-mt-px {
    scroll-margin-top: 1px;
  }

  .sm\:scroll-p-0 {
    scroll-padding: 0;
  }

  .sm\:scroll-p-0\.5 {
    scroll-padding: .125rem;
  }

  .sm\:scroll-p-1 {
    scroll-padding: .25rem;
  }

  .sm\:scroll-p-1\.5 {
    scroll-padding: .375rem;
  }

  .sm\:scroll-p-10 {
    scroll-padding: 2.5rem;
  }

  .sm\:scroll-p-11 {
    scroll-padding: 2.75rem;
  }

  .sm\:scroll-p-12 {
    scroll-padding: 3rem;
  }

  .sm\:scroll-p-14 {
    scroll-padding: 3.5rem;
  }

  .sm\:scroll-p-16 {
    scroll-padding: 4rem;
  }

  .sm\:scroll-p-2 {
    scroll-padding: .5rem;
  }

  .sm\:scroll-p-2\.5 {
    scroll-padding: .625rem;
  }

  .sm\:scroll-p-20 {
    scroll-padding: 5rem;
  }

  .sm\:scroll-p-24 {
    scroll-padding: 6rem;
  }

  .sm\:scroll-p-28 {
    scroll-padding: 7rem;
  }

  .sm\:scroll-p-3 {
    scroll-padding: .75rem;
  }

  .sm\:scroll-p-3\.5 {
    scroll-padding: .875rem;
  }

  .sm\:scroll-p-32 {
    scroll-padding: 8rem;
  }

  .sm\:scroll-p-36 {
    scroll-padding: 9rem;
  }

  .sm\:scroll-p-4 {
    scroll-padding: 1rem;
  }

  .sm\:scroll-p-40 {
    scroll-padding: 10rem;
  }

  .sm\:scroll-p-44 {
    scroll-padding: 11rem;
  }

  .sm\:scroll-p-48 {
    scroll-padding: 12rem;
  }

  .sm\:scroll-p-5 {
    scroll-padding: 1.25rem;
  }

  .sm\:scroll-p-52 {
    scroll-padding: 13rem;
  }

  .sm\:scroll-p-56 {
    scroll-padding: 14rem;
  }

  .sm\:scroll-p-6 {
    scroll-padding: 1.5rem;
  }

  .sm\:scroll-p-60 {
    scroll-padding: 15rem;
  }

  .sm\:scroll-p-64 {
    scroll-padding: 16rem;
  }

  .sm\:scroll-p-7 {
    scroll-padding: 1.75rem;
  }

  .sm\:scroll-p-72 {
    scroll-padding: 18rem;
  }

  .sm\:scroll-p-8 {
    scroll-padding: 2rem;
  }

  .sm\:scroll-p-80 {
    scroll-padding: 20rem;
  }

  .sm\:scroll-p-9 {
    scroll-padding: 2.25rem;
  }

  .sm\:scroll-p-96 {
    scroll-padding: 24rem;
  }

  .sm\:scroll-p-px {
    scroll-padding: 1px;
  }

  .sm\:scroll-px-0 {
    scroll-padding-left: 0;
    scroll-padding-right: 0;
  }

  .sm\:scroll-px-0\.5 {
    scroll-padding-left: .125rem;
    scroll-padding-right: .125rem;
  }

  .sm\:scroll-px-1 {
    scroll-padding-left: .25rem;
    scroll-padding-right: .25rem;
  }

  .sm\:scroll-px-1\.5 {
    scroll-padding-left: .375rem;
    scroll-padding-right: .375rem;
  }

  .sm\:scroll-px-10 {
    scroll-padding-left: 2.5rem;
    scroll-padding-right: 2.5rem;
  }

  .sm\:scroll-px-11 {
    scroll-padding-left: 2.75rem;
    scroll-padding-right: 2.75rem;
  }

  .sm\:scroll-px-12 {
    scroll-padding-left: 3rem;
    scroll-padding-right: 3rem;
  }

  .sm\:scroll-px-14 {
    scroll-padding-left: 3.5rem;
    scroll-padding-right: 3.5rem;
  }

  .sm\:scroll-px-16 {
    scroll-padding-left: 4rem;
    scroll-padding-right: 4rem;
  }

  .sm\:scroll-px-2 {
    scroll-padding-left: .5rem;
    scroll-padding-right: .5rem;
  }

  .sm\:scroll-px-2\.5 {
    scroll-padding-left: .625rem;
    scroll-padding-right: .625rem;
  }

  .sm\:scroll-px-20 {
    scroll-padding-left: 5rem;
    scroll-padding-right: 5rem;
  }

  .sm\:scroll-px-24 {
    scroll-padding-left: 6rem;
    scroll-padding-right: 6rem;
  }

  .sm\:scroll-px-28 {
    scroll-padding-left: 7rem;
    scroll-padding-right: 7rem;
  }

  .sm\:scroll-px-3 {
    scroll-padding-left: .75rem;
    scroll-padding-right: .75rem;
  }

  .sm\:scroll-px-3\.5 {
    scroll-padding-left: .875rem;
    scroll-padding-right: .875rem;
  }

  .sm\:scroll-px-32 {
    scroll-padding-left: 8rem;
    scroll-padding-right: 8rem;
  }

  .sm\:scroll-px-36 {
    scroll-padding-left: 9rem;
    scroll-padding-right: 9rem;
  }

  .sm\:scroll-px-4 {
    scroll-padding-left: 1rem;
    scroll-padding-right: 1rem;
  }

  .sm\:scroll-px-40 {
    scroll-padding-left: 10rem;
    scroll-padding-right: 10rem;
  }

  .sm\:scroll-px-44 {
    scroll-padding-left: 11rem;
    scroll-padding-right: 11rem;
  }

  .sm\:scroll-px-48 {
    scroll-padding-left: 12rem;
    scroll-padding-right: 12rem;
  }

  .sm\:scroll-px-5 {
    scroll-padding-left: 1.25rem;
    scroll-padding-right: 1.25rem;
  }

  .sm\:scroll-px-52 {
    scroll-padding-left: 13rem;
    scroll-padding-right: 13rem;
  }

  .sm\:scroll-px-56 {
    scroll-padding-left: 14rem;
    scroll-padding-right: 14rem;
  }

  .sm\:scroll-px-6 {
    scroll-padding-left: 1.5rem;
    scroll-padding-right: 1.5rem;
  }

  .sm\:scroll-px-60 {
    scroll-padding-left: 15rem;
    scroll-padding-right: 15rem;
  }

  .sm\:scroll-px-64 {
    scroll-padding-left: 16rem;
    scroll-padding-right: 16rem;
  }

  .sm\:scroll-px-7 {
    scroll-padding-left: 1.75rem;
    scroll-padding-right: 1.75rem;
  }

  .sm\:scroll-px-72 {
    scroll-padding-left: 18rem;
    scroll-padding-right: 18rem;
  }

  .sm\:scroll-px-8 {
    scroll-padding-left: 2rem;
    scroll-padding-right: 2rem;
  }

  .sm\:scroll-px-80 {
    scroll-padding-left: 20rem;
    scroll-padding-right: 20rem;
  }

  .sm\:scroll-px-9 {
    scroll-padding-left: 2.25rem;
    scroll-padding-right: 2.25rem;
  }

  .sm\:scroll-px-96 {
    scroll-padding-left: 24rem;
    scroll-padding-right: 24rem;
  }

  .sm\:scroll-px-px {
    scroll-padding-left: 1px;
    scroll-padding-right: 1px;
  }

  .sm\:scroll-py-0 {
    scroll-padding-top: 0;
    scroll-padding-bottom: 0;
  }

  .sm\:scroll-py-0\.5 {
    scroll-padding-top: .125rem;
    scroll-padding-bottom: .125rem;
  }

  .sm\:scroll-py-1 {
    scroll-padding-top: .25rem;
    scroll-padding-bottom: .25rem;
  }

  .sm\:scroll-py-1\.5 {
    scroll-padding-top: .375rem;
    scroll-padding-bottom: .375rem;
  }

  .sm\:scroll-py-10 {
    scroll-padding-top: 2.5rem;
    scroll-padding-bottom: 2.5rem;
  }

  .sm\:scroll-py-11 {
    scroll-padding-top: 2.75rem;
    scroll-padding-bottom: 2.75rem;
  }

  .sm\:scroll-py-12 {
    scroll-padding-top: 3rem;
    scroll-padding-bottom: 3rem;
  }

  .sm\:scroll-py-14 {
    scroll-padding-top: 3.5rem;
    scroll-padding-bottom: 3.5rem;
  }

  .sm\:scroll-py-16 {
    scroll-padding-top: 4rem;
    scroll-padding-bottom: 4rem;
  }

  .sm\:scroll-py-2 {
    scroll-padding-top: .5rem;
    scroll-padding-bottom: .5rem;
  }

  .sm\:scroll-py-2\.5 {
    scroll-padding-top: .625rem;
    scroll-padding-bottom: .625rem;
  }

  .sm\:scroll-py-20 {
    scroll-padding-top: 5rem;
    scroll-padding-bottom: 5rem;
  }

  .sm\:scroll-py-24 {
    scroll-padding-top: 6rem;
    scroll-padding-bottom: 6rem;
  }

  .sm\:scroll-py-28 {
    scroll-padding-top: 7rem;
    scroll-padding-bottom: 7rem;
  }

  .sm\:scroll-py-3 {
    scroll-padding-top: .75rem;
    scroll-padding-bottom: .75rem;
  }

  .sm\:scroll-py-3\.5 {
    scroll-padding-top: .875rem;
    scroll-padding-bottom: .875rem;
  }

  .sm\:scroll-py-32 {
    scroll-padding-top: 8rem;
    scroll-padding-bottom: 8rem;
  }

  .sm\:scroll-py-36 {
    scroll-padding-top: 9rem;
    scroll-padding-bottom: 9rem;
  }

  .sm\:scroll-py-4 {
    scroll-padding-top: 1rem;
    scroll-padding-bottom: 1rem;
  }

  .sm\:scroll-py-40 {
    scroll-padding-top: 10rem;
    scroll-padding-bottom: 10rem;
  }

  .sm\:scroll-py-44 {
    scroll-padding-top: 11rem;
    scroll-padding-bottom: 11rem;
  }

  .sm\:scroll-py-48 {
    scroll-padding-top: 12rem;
    scroll-padding-bottom: 12rem;
  }

  .sm\:scroll-py-5 {
    scroll-padding-top: 1.25rem;
    scroll-padding-bottom: 1.25rem;
  }

  .sm\:scroll-py-52 {
    scroll-padding-top: 13rem;
    scroll-padding-bottom: 13rem;
  }

  .sm\:scroll-py-56 {
    scroll-padding-top: 14rem;
    scroll-padding-bottom: 14rem;
  }

  .sm\:scroll-py-6 {
    scroll-padding-top: 1.5rem;
    scroll-padding-bottom: 1.5rem;
  }

  .sm\:scroll-py-60 {
    scroll-padding-top: 15rem;
    scroll-padding-bottom: 15rem;
  }

  .sm\:scroll-py-64 {
    scroll-padding-top: 16rem;
    scroll-padding-bottom: 16rem;
  }

  .sm\:scroll-py-7 {
    scroll-padding-top: 1.75rem;
    scroll-padding-bottom: 1.75rem;
  }

  .sm\:scroll-py-72 {
    scroll-padding-top: 18rem;
    scroll-padding-bottom: 18rem;
  }

  .sm\:scroll-py-8 {
    scroll-padding-top: 2rem;
    scroll-padding-bottom: 2rem;
  }

  .sm\:scroll-py-80 {
    scroll-padding-top: 20rem;
    scroll-padding-bottom: 20rem;
  }

  .sm\:scroll-py-9 {
    scroll-padding-top: 2.25rem;
    scroll-padding-bottom: 2.25rem;
  }

  .sm\:scroll-py-96 {
    scroll-padding-top: 24rem;
    scroll-padding-bottom: 24rem;
  }

  .sm\:scroll-py-px {
    scroll-padding-top: 1px;
    scroll-padding-bottom: 1px;
  }

  .sm\:scroll-pb-0 {
    scroll-padding-bottom: 0;
  }

  .sm\:scroll-pb-0\.5 {
    scroll-padding-bottom: .125rem;
  }

  .sm\:scroll-pb-1 {
    scroll-padding-bottom: .25rem;
  }

  .sm\:scroll-pb-1\.5 {
    scroll-padding-bottom: .375rem;
  }

  .sm\:scroll-pb-10 {
    scroll-padding-bottom: 2.5rem;
  }

  .sm\:scroll-pb-11 {
    scroll-padding-bottom: 2.75rem;
  }

  .sm\:scroll-pb-12 {
    scroll-padding-bottom: 3rem;
  }

  .sm\:scroll-pb-14 {
    scroll-padding-bottom: 3.5rem;
  }

  .sm\:scroll-pb-16 {
    scroll-padding-bottom: 4rem;
  }

  .sm\:scroll-pb-2 {
    scroll-padding-bottom: .5rem;
  }

  .sm\:scroll-pb-2\.5 {
    scroll-padding-bottom: .625rem;
  }

  .sm\:scroll-pb-20 {
    scroll-padding-bottom: 5rem;
  }

  .sm\:scroll-pb-24 {
    scroll-padding-bottom: 6rem;
  }

  .sm\:scroll-pb-28 {
    scroll-padding-bottom: 7rem;
  }

  .sm\:scroll-pb-3 {
    scroll-padding-bottom: .75rem;
  }

  .sm\:scroll-pb-3\.5 {
    scroll-padding-bottom: .875rem;
  }

  .sm\:scroll-pb-32 {
    scroll-padding-bottom: 8rem;
  }

  .sm\:scroll-pb-36 {
    scroll-padding-bottom: 9rem;
  }

  .sm\:scroll-pb-4 {
    scroll-padding-bottom: 1rem;
  }

  .sm\:scroll-pb-40 {
    scroll-padding-bottom: 10rem;
  }

  .sm\:scroll-pb-44 {
    scroll-padding-bottom: 11rem;
  }

  .sm\:scroll-pb-48 {
    scroll-padding-bottom: 12rem;
  }

  .sm\:scroll-pb-5 {
    scroll-padding-bottom: 1.25rem;
  }

  .sm\:scroll-pb-52 {
    scroll-padding-bottom: 13rem;
  }

  .sm\:scroll-pb-56 {
    scroll-padding-bottom: 14rem;
  }

  .sm\:scroll-pb-6 {
    scroll-padding-bottom: 1.5rem;
  }

  .sm\:scroll-pb-60 {
    scroll-padding-bottom: 15rem;
  }

  .sm\:scroll-pb-64 {
    scroll-padding-bottom: 16rem;
  }

  .sm\:scroll-pb-7 {
    scroll-padding-bottom: 1.75rem;
  }

  .sm\:scroll-pb-72 {
    scroll-padding-bottom: 18rem;
  }

  .sm\:scroll-pb-8 {
    scroll-padding-bottom: 2rem;
  }

  .sm\:scroll-pb-80 {
    scroll-padding-bottom: 20rem;
  }

  .sm\:scroll-pb-9 {
    scroll-padding-bottom: 2.25rem;
  }

  .sm\:scroll-pb-96 {
    scroll-padding-bottom: 24rem;
  }

  .sm\:scroll-pb-px {
    scroll-padding-bottom: 1px;
  }

  .sm\:scroll-pe-0 {
    scroll-padding-inline-end: 0;
  }

  .sm\:scroll-pe-0\.5 {
    scroll-padding-inline-end: .125rem;
  }

  .sm\:scroll-pe-1 {
    scroll-padding-inline-end: .25rem;
  }

  .sm\:scroll-pe-1\.5 {
    scroll-padding-inline-end: .375rem;
  }

  .sm\:scroll-pe-10 {
    scroll-padding-inline-end: 2.5rem;
  }

  .sm\:scroll-pe-11 {
    scroll-padding-inline-end: 2.75rem;
  }

  .sm\:scroll-pe-12 {
    scroll-padding-inline-end: 3rem;
  }

  .sm\:scroll-pe-14 {
    scroll-padding-inline-end: 3.5rem;
  }

  .sm\:scroll-pe-16 {
    scroll-padding-inline-end: 4rem;
  }

  .sm\:scroll-pe-2 {
    scroll-padding-inline-end: .5rem;
  }

  .sm\:scroll-pe-2\.5 {
    scroll-padding-inline-end: .625rem;
  }

  .sm\:scroll-pe-20 {
    scroll-padding-inline-end: 5rem;
  }

  .sm\:scroll-pe-24 {
    scroll-padding-inline-end: 6rem;
  }

  .sm\:scroll-pe-28 {
    scroll-padding-inline-end: 7rem;
  }

  .sm\:scroll-pe-3 {
    scroll-padding-inline-end: .75rem;
  }

  .sm\:scroll-pe-3\.5 {
    scroll-padding-inline-end: .875rem;
  }

  .sm\:scroll-pe-32 {
    scroll-padding-inline-end: 8rem;
  }

  .sm\:scroll-pe-36 {
    scroll-padding-inline-end: 9rem;
  }

  .sm\:scroll-pe-4 {
    scroll-padding-inline-end: 1rem;
  }

  .sm\:scroll-pe-40 {
    scroll-padding-inline-end: 10rem;
  }

  .sm\:scroll-pe-44 {
    scroll-padding-inline-end: 11rem;
  }

  .sm\:scroll-pe-48 {
    scroll-padding-inline-end: 12rem;
  }

  .sm\:scroll-pe-5 {
    scroll-padding-inline-end: 1.25rem;
  }

  .sm\:scroll-pe-52 {
    scroll-padding-inline-end: 13rem;
  }

  .sm\:scroll-pe-56 {
    scroll-padding-inline-end: 14rem;
  }

  .sm\:scroll-pe-6 {
    scroll-padding-inline-end: 1.5rem;
  }

  .sm\:scroll-pe-60 {
    scroll-padding-inline-end: 15rem;
  }

  .sm\:scroll-pe-64 {
    scroll-padding-inline-end: 16rem;
  }

  .sm\:scroll-pe-7 {
    scroll-padding-inline-end: 1.75rem;
  }

  .sm\:scroll-pe-72 {
    scroll-padding-inline-end: 18rem;
  }

  .sm\:scroll-pe-8 {
    scroll-padding-inline-end: 2rem;
  }

  .sm\:scroll-pe-80 {
    scroll-padding-inline-end: 20rem;
  }

  .sm\:scroll-pe-9 {
    scroll-padding-inline-end: 2.25rem;
  }

  .sm\:scroll-pe-96 {
    scroll-padding-inline-end: 24rem;
  }

  .sm\:scroll-pe-px {
    scroll-padding-inline-end: 1px;
  }

  .sm\:scroll-pl-0 {
    scroll-padding-left: 0;
  }

  .sm\:scroll-pl-0\.5 {
    scroll-padding-left: .125rem;
  }

  .sm\:scroll-pl-1 {
    scroll-padding-left: .25rem;
  }

  .sm\:scroll-pl-1\.5 {
    scroll-padding-left: .375rem;
  }

  .sm\:scroll-pl-10 {
    scroll-padding-left: 2.5rem;
  }

  .sm\:scroll-pl-11 {
    scroll-padding-left: 2.75rem;
  }

  .sm\:scroll-pl-12 {
    scroll-padding-left: 3rem;
  }

  .sm\:scroll-pl-14 {
    scroll-padding-left: 3.5rem;
  }

  .sm\:scroll-pl-16 {
    scroll-padding-left: 4rem;
  }

  .sm\:scroll-pl-2 {
    scroll-padding-left: .5rem;
  }

  .sm\:scroll-pl-2\.5 {
    scroll-padding-left: .625rem;
  }

  .sm\:scroll-pl-20 {
    scroll-padding-left: 5rem;
  }

  .sm\:scroll-pl-24 {
    scroll-padding-left: 6rem;
  }

  .sm\:scroll-pl-28 {
    scroll-padding-left: 7rem;
  }

  .sm\:scroll-pl-3 {
    scroll-padding-left: .75rem;
  }

  .sm\:scroll-pl-3\.5 {
    scroll-padding-left: .875rem;
  }

  .sm\:scroll-pl-32 {
    scroll-padding-left: 8rem;
  }

  .sm\:scroll-pl-36 {
    scroll-padding-left: 9rem;
  }

  .sm\:scroll-pl-4 {
    scroll-padding-left: 1rem;
  }

  .sm\:scroll-pl-40 {
    scroll-padding-left: 10rem;
  }

  .sm\:scroll-pl-44 {
    scroll-padding-left: 11rem;
  }

  .sm\:scroll-pl-48 {
    scroll-padding-left: 12rem;
  }

  .sm\:scroll-pl-5 {
    scroll-padding-left: 1.25rem;
  }

  .sm\:scroll-pl-52 {
    scroll-padding-left: 13rem;
  }

  .sm\:scroll-pl-56 {
    scroll-padding-left: 14rem;
  }

  .sm\:scroll-pl-6 {
    scroll-padding-left: 1.5rem;
  }

  .sm\:scroll-pl-60 {
    scroll-padding-left: 15rem;
  }

  .sm\:scroll-pl-64 {
    scroll-padding-left: 16rem;
  }

  .sm\:scroll-pl-7 {
    scroll-padding-left: 1.75rem;
  }

  .sm\:scroll-pl-72 {
    scroll-padding-left: 18rem;
  }

  .sm\:scroll-pl-8 {
    scroll-padding-left: 2rem;
  }

  .sm\:scroll-pl-80 {
    scroll-padding-left: 20rem;
  }

  .sm\:scroll-pl-9 {
    scroll-padding-left: 2.25rem;
  }

  .sm\:scroll-pl-96 {
    scroll-padding-left: 24rem;
  }

  .sm\:scroll-pl-px {
    scroll-padding-left: 1px;
  }

  .sm\:scroll-pr-0 {
    scroll-padding-right: 0;
  }

  .sm\:scroll-pr-0\.5 {
    scroll-padding-right: .125rem;
  }

  .sm\:scroll-pr-1 {
    scroll-padding-right: .25rem;
  }

  .sm\:scroll-pr-1\.5 {
    scroll-padding-right: .375rem;
  }

  .sm\:scroll-pr-10 {
    scroll-padding-right: 2.5rem;
  }

  .sm\:scroll-pr-11 {
    scroll-padding-right: 2.75rem;
  }

  .sm\:scroll-pr-12 {
    scroll-padding-right: 3rem;
  }

  .sm\:scroll-pr-14 {
    scroll-padding-right: 3.5rem;
  }

  .sm\:scroll-pr-16 {
    scroll-padding-right: 4rem;
  }

  .sm\:scroll-pr-2 {
    scroll-padding-right: .5rem;
  }

  .sm\:scroll-pr-2\.5 {
    scroll-padding-right: .625rem;
  }

  .sm\:scroll-pr-20 {
    scroll-padding-right: 5rem;
  }

  .sm\:scroll-pr-24 {
    scroll-padding-right: 6rem;
  }

  .sm\:scroll-pr-28 {
    scroll-padding-right: 7rem;
  }

  .sm\:scroll-pr-3 {
    scroll-padding-right: .75rem;
  }

  .sm\:scroll-pr-3\.5 {
    scroll-padding-right: .875rem;
  }

  .sm\:scroll-pr-32 {
    scroll-padding-right: 8rem;
  }

  .sm\:scroll-pr-36 {
    scroll-padding-right: 9rem;
  }

  .sm\:scroll-pr-4 {
    scroll-padding-right: 1rem;
  }

  .sm\:scroll-pr-40 {
    scroll-padding-right: 10rem;
  }

  .sm\:scroll-pr-44 {
    scroll-padding-right: 11rem;
  }

  .sm\:scroll-pr-48 {
    scroll-padding-right: 12rem;
  }

  .sm\:scroll-pr-5 {
    scroll-padding-right: 1.25rem;
  }

  .sm\:scroll-pr-52 {
    scroll-padding-right: 13rem;
  }

  .sm\:scroll-pr-56 {
    scroll-padding-right: 14rem;
  }

  .sm\:scroll-pr-6 {
    scroll-padding-right: 1.5rem;
  }

  .sm\:scroll-pr-60 {
    scroll-padding-right: 15rem;
  }

  .sm\:scroll-pr-64 {
    scroll-padding-right: 16rem;
  }

  .sm\:scroll-pr-7 {
    scroll-padding-right: 1.75rem;
  }

  .sm\:scroll-pr-72 {
    scroll-padding-right: 18rem;
  }

  .sm\:scroll-pr-8 {
    scroll-padding-right: 2rem;
  }

  .sm\:scroll-pr-80 {
    scroll-padding-right: 20rem;
  }

  .sm\:scroll-pr-9 {
    scroll-padding-right: 2.25rem;
  }

  .sm\:scroll-pr-96 {
    scroll-padding-right: 24rem;
  }

  .sm\:scroll-pr-px {
    scroll-padding-right: 1px;
  }

  .sm\:scroll-ps-0 {
    scroll-padding-inline-start: 0;
  }

  .sm\:scroll-ps-0\.5 {
    scroll-padding-inline-start: .125rem;
  }

  .sm\:scroll-ps-1 {
    scroll-padding-inline-start: .25rem;
  }

  .sm\:scroll-ps-1\.5 {
    scroll-padding-inline-start: .375rem;
  }

  .sm\:scroll-ps-10 {
    scroll-padding-inline-start: 2.5rem;
  }

  .sm\:scroll-ps-11 {
    scroll-padding-inline-start: 2.75rem;
  }

  .sm\:scroll-ps-12 {
    scroll-padding-inline-start: 3rem;
  }

  .sm\:scroll-ps-14 {
    scroll-padding-inline-start: 3.5rem;
  }

  .sm\:scroll-ps-16 {
    scroll-padding-inline-start: 4rem;
  }

  .sm\:scroll-ps-2 {
    scroll-padding-inline-start: .5rem;
  }

  .sm\:scroll-ps-2\.5 {
    scroll-padding-inline-start: .625rem;
  }

  .sm\:scroll-ps-20 {
    scroll-padding-inline-start: 5rem;
  }

  .sm\:scroll-ps-24 {
    scroll-padding-inline-start: 6rem;
  }

  .sm\:scroll-ps-28 {
    scroll-padding-inline-start: 7rem;
  }

  .sm\:scroll-ps-3 {
    scroll-padding-inline-start: .75rem;
  }

  .sm\:scroll-ps-3\.5 {
    scroll-padding-inline-start: .875rem;
  }

  .sm\:scroll-ps-32 {
    scroll-padding-inline-start: 8rem;
  }

  .sm\:scroll-ps-36 {
    scroll-padding-inline-start: 9rem;
  }

  .sm\:scroll-ps-4 {
    scroll-padding-inline-start: 1rem;
  }

  .sm\:scroll-ps-40 {
    scroll-padding-inline-start: 10rem;
  }

  .sm\:scroll-ps-44 {
    scroll-padding-inline-start: 11rem;
  }

  .sm\:scroll-ps-48 {
    scroll-padding-inline-start: 12rem;
  }

  .sm\:scroll-ps-5 {
    scroll-padding-inline-start: 1.25rem;
  }

  .sm\:scroll-ps-52 {
    scroll-padding-inline-start: 13rem;
  }

  .sm\:scroll-ps-56 {
    scroll-padding-inline-start: 14rem;
  }

  .sm\:scroll-ps-6 {
    scroll-padding-inline-start: 1.5rem;
  }

  .sm\:scroll-ps-60 {
    scroll-padding-inline-start: 15rem;
  }

  .sm\:scroll-ps-64 {
    scroll-padding-inline-start: 16rem;
  }

  .sm\:scroll-ps-7 {
    scroll-padding-inline-start: 1.75rem;
  }

  .sm\:scroll-ps-72 {
    scroll-padding-inline-start: 18rem;
  }

  .sm\:scroll-ps-8 {
    scroll-padding-inline-start: 2rem;
  }

  .sm\:scroll-ps-80 {
    scroll-padding-inline-start: 20rem;
  }

  .sm\:scroll-ps-9 {
    scroll-padding-inline-start: 2.25rem;
  }

  .sm\:scroll-ps-96 {
    scroll-padding-inline-start: 24rem;
  }

  .sm\:scroll-ps-px {
    scroll-padding-inline-start: 1px;
  }

  .sm\:scroll-pt-0 {
    scroll-padding-top: 0;
  }

  .sm\:scroll-pt-0\.5 {
    scroll-padding-top: .125rem;
  }

  .sm\:scroll-pt-1 {
    scroll-padding-top: .25rem;
  }

  .sm\:scroll-pt-1\.5 {
    scroll-padding-top: .375rem;
  }

  .sm\:scroll-pt-10 {
    scroll-padding-top: 2.5rem;
  }

  .sm\:scroll-pt-11 {
    scroll-padding-top: 2.75rem;
  }

  .sm\:scroll-pt-12 {
    scroll-padding-top: 3rem;
  }

  .sm\:scroll-pt-14 {
    scroll-padding-top: 3.5rem;
  }

  .sm\:scroll-pt-16 {
    scroll-padding-top: 4rem;
  }

  .sm\:scroll-pt-2 {
    scroll-padding-top: .5rem;
  }

  .sm\:scroll-pt-2\.5 {
    scroll-padding-top: .625rem;
  }

  .sm\:scroll-pt-20 {
    scroll-padding-top: 5rem;
  }

  .sm\:scroll-pt-24 {
    scroll-padding-top: 6rem;
  }

  .sm\:scroll-pt-28 {
    scroll-padding-top: 7rem;
  }

  .sm\:scroll-pt-3 {
    scroll-padding-top: .75rem;
  }

  .sm\:scroll-pt-3\.5 {
    scroll-padding-top: .875rem;
  }

  .sm\:scroll-pt-32 {
    scroll-padding-top: 8rem;
  }

  .sm\:scroll-pt-36 {
    scroll-padding-top: 9rem;
  }

  .sm\:scroll-pt-4 {
    scroll-padding-top: 1rem;
  }

  .sm\:scroll-pt-40 {
    scroll-padding-top: 10rem;
  }

  .sm\:scroll-pt-44 {
    scroll-padding-top: 11rem;
  }

  .sm\:scroll-pt-48 {
    scroll-padding-top: 12rem;
  }

  .sm\:scroll-pt-5 {
    scroll-padding-top: 1.25rem;
  }

  .sm\:scroll-pt-52 {
    scroll-padding-top: 13rem;
  }

  .sm\:scroll-pt-56 {
    scroll-padding-top: 14rem;
  }

  .sm\:scroll-pt-6 {
    scroll-padding-top: 1.5rem;
  }

  .sm\:scroll-pt-60 {
    scroll-padding-top: 15rem;
  }

  .sm\:scroll-pt-64 {
    scroll-padding-top: 16rem;
  }

  .sm\:scroll-pt-7 {
    scroll-padding-top: 1.75rem;
  }

  .sm\:scroll-pt-72 {
    scroll-padding-top: 18rem;
  }

  .sm\:scroll-pt-8 {
    scroll-padding-top: 2rem;
  }

  .sm\:scroll-pt-80 {
    scroll-padding-top: 20rem;
  }

  .sm\:scroll-pt-9 {
    scroll-padding-top: 2.25rem;
  }

  .sm\:scroll-pt-96 {
    scroll-padding-top: 24rem;
  }

  .sm\:scroll-pt-px {
    scroll-padding-top: 1px;
  }

  .sm\:list-inside {
    list-style-position: inside;
  }

  .sm\:list-outside {
    list-style-position: outside;
  }

  .sm\:list-decimal {
    list-style-type: decimal;
  }

  .sm\:list-disc {
    list-style-type: disc;
  }

  .sm\:list-none {
    list-style-type: none;
  }

  .sm\:list-image-none {
    list-style-image: none;
  }

  .sm\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }

  .sm\:appearance-auto {
    -webkit-appearance: auto;
    appearance: auto;
  }

  .sm\:columns-1 {
    columns: 1;
  }

  .sm\:columns-10 {
    columns: 10;
  }

  .sm\:columns-11 {
    columns: 11;
  }

  .sm\:columns-12 {
    columns: 12;
  }

  .sm\:columns-2 {
    columns: 2;
  }

  .sm\:columns-2xl {
    columns: 42rem;
  }

  .sm\:columns-2xs {
    columns: 18rem;
  }

  .sm\:columns-3 {
    columns: 3;
  }

  .sm\:columns-3xl {
    columns: 48rem;
  }

  .sm\:columns-3xs {
    columns: 16rem;
  }

  .sm\:columns-4 {
    columns: 4;
  }

  .sm\:columns-4xl {
    columns: 56rem;
  }

  .sm\:columns-5 {
    columns: 5;
  }

  .sm\:columns-5xl {
    columns: 64rem;
  }

  .sm\:columns-6 {
    columns: 6;
  }

  .sm\:columns-6xl {
    columns: 72rem;
  }

  .sm\:columns-7 {
    columns: 7;
  }

  .sm\:columns-7xl {
    columns: 80rem;
  }

  .sm\:columns-8 {
    columns: 8;
  }

  .sm\:columns-9 {
    columns: 9;
  }

  .sm\:columns-auto {
    columns: auto;
  }

  .sm\:columns-lg {
    columns: 32rem;
  }

  .sm\:columns-md {
    columns: 28rem;
  }

  .sm\:columns-sm {
    columns: 24rem;
  }

  .sm\:columns-xl {
    columns: 36rem;
  }

  .sm\:columns-xs {
    columns: 20rem;
  }

  .sm\:break-before-auto {
    break-before: auto;
  }

  .sm\:break-before-avoid {
    break-before: avoid;
  }

  .sm\:break-before-all {
    break-before: all;
  }

  .sm\:break-before-avoid-page {
    break-before: avoid-page;
  }

  .sm\:break-before-page {
    break-before: page;
  }

  .sm\:break-before-left {
    break-before: left;
  }

  .sm\:break-before-right {
    break-before: right;
  }

  .sm\:break-before-column {
    break-before: column;
  }

  .sm\:break-inside-auto {
    break-inside: auto;
  }

  .sm\:break-inside-avoid {
    break-inside: avoid;
  }

  .sm\:break-inside-avoid-page {
    break-inside: avoid-page;
  }

  .sm\:break-inside-avoid-column {
    break-inside: avoid-column;
  }

  .sm\:break-after-auto {
    break-after: auto;
  }

  .sm\:break-after-avoid {
    break-after: avoid;
  }

  .sm\:break-after-all {
    break-after: all;
  }

  .sm\:break-after-avoid-page {
    break-after: avoid-page;
  }

  .sm\:break-after-page {
    break-after: page;
  }

  .sm\:break-after-left {
    break-after: left;
  }

  .sm\:break-after-right {
    break-after: right;
  }

  .sm\:break-after-column {
    break-after: column;
  }

  .sm\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .sm\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .sm\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row;
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column;
  }

  .sm\:grid-flow-dense {
    grid-auto-flow: dense;
  }

  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .sm\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .sm\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .sm\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:grid-cols-none {
    grid-template-columns: none;
  }

  .sm\:grid-cols-subgrid {
    grid-template-columns: subgrid;
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr));
  }

  .sm\:grid-rows-11 {
    grid-template-rows: repeat(11, minmax(0, 1fr));
  }

  .sm\:grid-rows-12 {
    grid-template-rows: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-rows-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .sm\:grid-rows-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .sm\:grid-rows-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }

  .sm\:grid-rows-none {
    grid-template-rows: none;
  }

  .sm\:grid-rows-subgrid {
    grid-template-rows: subgrid;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:place-content-center {
    place-content: center;
  }

  .sm\:place-content-start {
    place-content: start;
  }

  .sm\:place-content-end {
    place-content: end;
  }

  .sm\:place-content-between {
    place-content: space-between;
  }

  .sm\:place-content-around {
    place-content: space-around;
  }

  .sm\:place-content-evenly {
    place-content: space-evenly;
  }

  .sm\:place-content-baseline {
    place-content: baseline start;
  }

  .sm\:place-content-stretch {
    place-content: stretch;
  }

  .sm\:place-items-start {
    place-items: start;
  }

  .sm\:place-items-end {
    place-items: end;
  }

  .sm\:place-items-center {
    place-items: center;
  }

  .sm\:place-items-baseline {
    place-items: baseline;
  }

  .sm\:place-items-stretch {
    place-items: stretch stretch;
  }

  .sm\:content-normal {
    align-content: normal;
  }

  .sm\:content-center {
    align-content: center;
  }

  .sm\:content-start {
    align-content: flex-start;
  }

  .sm\:content-end {
    align-content: flex-end;
  }

  .sm\:content-between {
    align-content: space-between;
  }

  .sm\:content-around {
    align-content: space-around;
  }

  .sm\:content-evenly {
    align-content: space-evenly;
  }

  .sm\:content-baseline {
    align-content: baseline;
  }

  .sm\:content-stretch {
    align-content: stretch;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:items-baseline {
    align-items: baseline;
  }

  .sm\:items-stretch {
    align-items: stretch;
  }

  .sm\:justify-normal {
    justify-content: normal;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:justify-around {
    justify-content: space-around;
  }

  .sm\:justify-evenly {
    justify-content: space-evenly;
  }

  .sm\:justify-stretch {
    justify-content: stretch;
  }

  .sm\:justify-items-start {
    justify-items: start;
  }

  .sm\:justify-items-end {
    justify-items: end;
  }

  .sm\:justify-items-center {
    justify-items: center;
  }

  .sm\:justify-items-stretch {
    justify-items: stretch;
  }

  .sm\:gap-0 {
    gap: 0;
  }

  .sm\:gap-0\.5 {
    gap: .125rem;
  }

  .sm\:gap-1 {
    gap: .25rem;
  }

  .sm\:gap-1\.5 {
    gap: .375rem;
  }

  .sm\:gap-10 {
    gap: 2.5rem;
  }

  .sm\:gap-11 {
    gap: 2.75rem;
  }

  .sm\:gap-12 {
    gap: 3rem;
  }

  .sm\:gap-14 {
    gap: 3.5rem;
  }

  .sm\:gap-16 {
    gap: 4rem;
  }

  .sm\:gap-2 {
    gap: .5rem;
  }

  .sm\:gap-2\.5 {
    gap: .625rem;
  }

  .sm\:gap-20 {
    gap: 5rem;
  }

  .sm\:gap-24 {
    gap: 6rem;
  }

  .sm\:gap-28 {
    gap: 7rem;
  }

  .sm\:gap-3 {
    gap: .75rem;
  }

  .sm\:gap-3\.5 {
    gap: .875rem;
  }

  .sm\:gap-32 {
    gap: 8rem;
  }

  .sm\:gap-36 {
    gap: 9rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-40 {
    gap: 10rem;
  }

  .sm\:gap-44 {
    gap: 11rem;
  }

  .sm\:gap-48 {
    gap: 12rem;
  }

  .sm\:gap-5 {
    gap: 1.25rem;
  }

  .sm\:gap-52 {
    gap: 13rem;
  }

  .sm\:gap-56 {
    gap: 14rem;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-60 {
    gap: 15rem;
  }

  .sm\:gap-64 {
    gap: 16rem;
  }

  .sm\:gap-7 {
    gap: 1.75rem;
  }

  .sm\:gap-72 {
    gap: 18rem;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-80 {
    gap: 20rem;
  }

  .sm\:gap-9 {
    gap: 2.25rem;
  }

  .sm\:gap-96 {
    gap: 24rem;
  }

  .sm\:gap-px {
    gap: 1px;
  }

  .sm\:gap-x-0 {
    column-gap: 0;
  }

  .sm\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .sm\:gap-x-1 {
    column-gap: .25rem;
  }

  .sm\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .sm\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .sm\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .sm\:gap-x-12 {
    column-gap: 3rem;
  }

  .sm\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .sm\:gap-x-16 {
    column-gap: 4rem;
  }

  .sm\:gap-x-2 {
    column-gap: .5rem;
  }

  .sm\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .sm\:gap-x-20 {
    column-gap: 5rem;
  }

  .sm\:gap-x-24 {
    column-gap: 6rem;
  }

  .sm\:gap-x-28 {
    column-gap: 7rem;
  }

  .sm\:gap-x-3 {
    column-gap: .75rem;
  }

  .sm\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .sm\:gap-x-32 {
    column-gap: 8rem;
  }

  .sm\:gap-x-36 {
    column-gap: 9rem;
  }

  .sm\:gap-x-4 {
    column-gap: 1rem;
  }

  .sm\:gap-x-40 {
    column-gap: 10rem;
  }

  .sm\:gap-x-44 {
    column-gap: 11rem;
  }

  .sm\:gap-x-48 {
    column-gap: 12rem;
  }

  .sm\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .sm\:gap-x-52 {
    column-gap: 13rem;
  }

  .sm\:gap-x-56 {
    column-gap: 14rem;
  }

  .sm\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .sm\:gap-x-60 {
    column-gap: 15rem;
  }

  .sm\:gap-x-64 {
    column-gap: 16rem;
  }

  .sm\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .sm\:gap-x-72 {
    column-gap: 18rem;
  }

  .sm\:gap-x-8 {
    column-gap: 2rem;
  }

  .sm\:gap-x-80 {
    column-gap: 20rem;
  }

  .sm\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .sm\:gap-x-96 {
    column-gap: 24rem;
  }

  .sm\:gap-x-px {
    column-gap: 1px;
  }

  .sm\:gap-y-0 {
    row-gap: 0;
  }

  .sm\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .sm\:gap-y-1 {
    row-gap: .25rem;
  }

  .sm\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .sm\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .sm\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .sm\:gap-y-12 {
    row-gap: 3rem;
  }

  .sm\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .sm\:gap-y-16 {
    row-gap: 4rem;
  }

  .sm\:gap-y-2 {
    row-gap: .5rem;
  }

  .sm\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .sm\:gap-y-20 {
    row-gap: 5rem;
  }

  .sm\:gap-y-24 {
    row-gap: 6rem;
  }

  .sm\:gap-y-28 {
    row-gap: 7rem;
  }

  .sm\:gap-y-3 {
    row-gap: .75rem;
  }

  .sm\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .sm\:gap-y-32 {
    row-gap: 8rem;
  }

  .sm\:gap-y-36 {
    row-gap: 9rem;
  }

  .sm\:gap-y-4 {
    row-gap: 1rem;
  }

  .sm\:gap-y-40 {
    row-gap: 10rem;
  }

  .sm\:gap-y-44 {
    row-gap: 11rem;
  }

  .sm\:gap-y-48 {
    row-gap: 12rem;
  }

  .sm\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .sm\:gap-y-52 {
    row-gap: 13rem;
  }

  .sm\:gap-y-56 {
    row-gap: 14rem;
  }

  .sm\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .sm\:gap-y-60 {
    row-gap: 15rem;
  }

  .sm\:gap-y-64 {
    row-gap: 16rem;
  }

  .sm\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .sm\:gap-y-72 {
    row-gap: 18rem;
  }

  .sm\:gap-y-8 {
    row-gap: 2rem;
  }

  .sm\:gap-y-80 {
    row-gap: 20rem;
  }

  .sm\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .sm\:gap-y-96 {
    row-gap: 24rem;
  }

  .sm\:gap-y-px {
    row-gap: 1px;
  }

  .sm\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0px * var(--tw-space-x-reverse));
    margin-left: calc(-0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .sm\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .sm\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .sm\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .sm\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .sm\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .sm\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .sm\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .sm\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .sm\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .sm\:divide-black > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-black);
  }

  .sm\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .sm\:divide-danger > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-300);
  }

  .sm\:divide-danger-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-100);
  }

  .sm\:divide-danger-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-200);
  }

  .sm\:divide-danger-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-300);
  }

  .sm\:divide-danger-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-400);
  }

  .sm\:divide-divider > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-divider);
  }

  .sm\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-gray);
  }

  .sm\:divide-info > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-300);
  }

  .sm\:divide-info-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-100);
  }

  .sm\:divide-info-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-200);
  }

  .sm\:divide-info-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-300);
  }

  .sm\:divide-info-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-400);
  }

  .sm\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-300);
  }

  .sm\:divide-primary-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-100);
  }

  .sm\:divide-primary-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-200);
  }

  .sm\:divide-primary-250 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-250);
  }

  .sm\:divide-primary-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-300);
  }

  .sm\:divide-primary-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-400);
  }

  .sm\:divide-primary-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-500);
  }

  .sm\:divide-primary-600 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-600);
  }

  .sm\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-300);
  }

  .sm\:divide-success-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-100);
  }

  .sm\:divide-success-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-200);
  }

  .sm\:divide-success-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-300);
  }

  .sm\:divide-success-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-400);
  }

  .sm\:divide-transparent > :not([hidden]) ~ :not([hidden]), .sm\:divide-transparent\/0 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .sm\:divide-transparent\/10 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000001a;
  }

  .sm\:divide-transparent\/100 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000;
  }

  .sm\:divide-transparent\/15 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000026;
  }

  .sm\:divide-transparent\/20 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0003;
  }

  .sm\:divide-transparent\/25 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000040;
  }

  .sm\:divide-transparent\/30 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000004d;
  }

  .sm\:divide-transparent\/35 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000059;
  }

  .sm\:divide-transparent\/40 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0006;
  }

  .sm\:divide-transparent\/45 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000073;
  }

  .sm\:divide-transparent\/5 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000000d;
  }

  .sm\:divide-transparent\/50 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000080;
  }

  .sm\:divide-transparent\/55 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000008c;
  }

  .sm\:divide-transparent\/60 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0009;
  }

  .sm\:divide-transparent\/65 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000a6;
  }

  .sm\:divide-transparent\/70 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000b3;
  }

  .sm\:divide-transparent\/75 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000bf;
  }

  .sm\:divide-transparent\/80 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000c;
  }

  .sm\:divide-transparent\/85 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000d9;
  }

  .sm\:divide-transparent\/90 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000e6;
  }

  .sm\:divide-transparent\/95 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000f2;
  }

  .sm\:divide-warning > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-300);
  }

  .sm\:divide-warning-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-100);
  }

  .sm\:divide-warning-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-200);
  }

  .sm\:divide-warning-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-300);
  }

  .sm\:divide-warning-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-400);
  }

  .sm\:divide-white > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-white);
  }

  .sm\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .sm\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .sm\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .sm\:divide-opacity-15 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .15;
  }

  .sm\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .sm\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .sm\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .sm\:divide-opacity-35 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .35;
  }

  .sm\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .sm\:divide-opacity-45 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .45;
  }

  .sm\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .sm\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .sm\:divide-opacity-55 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .55;
  }

  .sm\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .sm\:divide-opacity-65 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .65;
  }

  .sm\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .sm\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .sm\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .sm\:divide-opacity-85 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .85;
  }

  .sm\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .sm\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .sm\:place-self-auto {
    place-self: auto;
  }

  .sm\:place-self-start {
    place-self: start;
  }

  .sm\:place-self-end {
    place-self: end;
  }

  .sm\:place-self-center {
    place-self: center;
  }

  .sm\:place-self-stretch {
    place-self: stretch stretch;
  }

  .sm\:self-auto {
    align-self: auto;
  }

  .sm\:self-start {
    align-self: flex-start;
  }

  .sm\:self-end {
    align-self: flex-end;
  }

  .sm\:self-center {
    align-self: center;
  }

  .sm\:self-stretch {
    align-self: stretch;
  }

  .sm\:self-baseline {
    align-self: baseline;
  }

  .sm\:justify-self-auto {
    justify-self: auto;
  }

  .sm\:justify-self-start {
    justify-self: start;
  }

  .sm\:justify-self-end {
    justify-self: end;
  }

  .sm\:justify-self-center {
    justify-self: center;
  }

  .sm\:justify-self-stretch {
    justify-self: stretch;
  }

  .sm\:overflow-auto {
    overflow: auto;
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:overflow-clip {
    overflow: clip;
  }

  .sm\:overflow-visible {
    overflow: visible;
  }

  .sm\:overflow-scroll {
    overflow: scroll;
  }

  .sm\:overflow-x-auto {
    overflow-x: auto;
  }

  .sm\:overflow-y-auto {
    overflow-y: auto;
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .sm\:overflow-x-clip {
    overflow-x: clip;
  }

  .sm\:overflow-y-clip {
    overflow-y: clip;
  }

  .sm\:overflow-x-visible {
    overflow-x: visible;
  }

  .sm\:overflow-y-visible {
    overflow-y: visible;
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .sm\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .sm\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .sm\:overscroll-none {
    overscroll-behavior: none;
  }

  .sm\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .sm\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .sm\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .sm\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .sm\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .sm\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .sm\:scroll-auto {
    scroll-behavior: auto;
  }

  .sm\:scroll-smooth {
    scroll-behavior: smooth;
  }

  .sm\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .sm\:overflow-ellipsis, .sm\:text-ellipsis {
    text-overflow: ellipsis;
  }

  .sm\:text-clip {
    text-overflow: clip;
  }

  .sm\:hyphens-none {
    -webkit-hyphens: none;
    hyphens: none;
  }

  .sm\:hyphens-manual {
    -webkit-hyphens: manual;
    hyphens: manual;
  }

  .sm\:hyphens-auto {
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .sm\:whitespace-normal {
    white-space: normal;
  }

  .sm\:whitespace-nowrap {
    white-space: nowrap;
  }

  .sm\:whitespace-pre {
    white-space: pre;
  }

  .sm\:whitespace-pre-line {
    white-space: pre-line;
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .sm\:whitespace-break-spaces {
    white-space: break-spaces;
  }

  .sm\:text-wrap {
    text-wrap: wrap;
  }

  .sm\:text-nowrap {
    text-wrap: nowrap;
  }

  .sm\:text-balance {
    text-wrap: balance;
  }

  .sm\:text-pretty {
    text-wrap: pretty;
  }

  .sm\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .sm\:break-words {
    overflow-wrap: break-word;
  }

  .sm\:break-all {
    word-break: break-all;
  }

  .sm\:break-keep {
    word-break: keep-all;
  }

  .sm\:rounded {
    border-radius: .25rem;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .sm\:rounded-full {
    border-radius: 9999px;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:rounded-none {
    border-radius: 0;
  }

  .sm\:rounded-sm {
    border-radius: .125rem;
  }

  .sm\:rounded-xl {
    border-radius: .75rem;
  }

  .sm\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .sm\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .sm\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .sm\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .sm\:rounded-e {
    border-start-end-radius: .25rem;
    border-end-end-radius: .25rem;
  }

  .sm\:rounded-e-2xl {
    border-start-end-radius: 1rem;
    border-end-end-radius: 1rem;
  }

  .sm\:rounded-e-3xl {
    border-start-end-radius: 1.5rem;
    border-end-end-radius: 1.5rem;
  }

  .sm\:rounded-e-full {
    border-start-end-radius: 9999px;
    border-end-end-radius: 9999px;
  }

  .sm\:rounded-e-lg {
    border-start-end-radius: .5rem;
    border-end-end-radius: .5rem;
  }

  .sm\:rounded-e-md {
    border-start-end-radius: .375rem;
    border-end-end-radius: .375rem;
  }

  .sm\:rounded-e-none {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }

  .sm\:rounded-e-sm {
    border-start-end-radius: .125rem;
    border-end-end-radius: .125rem;
  }

  .sm\:rounded-e-xl {
    border-start-end-radius: .75rem;
    border-end-end-radius: .75rem;
  }

  .sm\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .sm\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .sm\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .sm\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .sm\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .sm\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .sm\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .sm\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .sm\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sm\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .sm\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .sm\:rounded-s {
    border-start-start-radius: .25rem;
    border-end-start-radius: .25rem;
  }

  .sm\:rounded-s-2xl {
    border-start-start-radius: 1rem;
    border-end-start-radius: 1rem;
  }

  .sm\:rounded-s-3xl {
    border-start-start-radius: 1.5rem;
    border-end-start-radius: 1.5rem;
  }

  .sm\:rounded-s-full {
    border-start-start-radius: 9999px;
    border-end-start-radius: 9999px;
  }

  .sm\:rounded-s-lg {
    border-start-start-radius: .5rem;
    border-end-start-radius: .5rem;
  }

  .sm\:rounded-s-md {
    border-start-start-radius: .375rem;
    border-end-start-radius: .375rem;
  }

  .sm\:rounded-s-none {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }

  .sm\:rounded-s-sm {
    border-start-start-radius: .125rem;
    border-end-start-radius: .125rem;
  }

  .sm\:rounded-s-xl {
    border-start-start-radius: .75rem;
    border-end-start-radius: .75rem;
  }

  .sm\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .sm\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .sm\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .sm\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .sm\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .sm\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .sm\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .sm\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .sm\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .sm\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .sm\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .sm\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .sm\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .sm\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .sm\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .sm\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .sm\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .sm\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .sm\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .sm\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .sm\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .sm\:rounded-ee {
    border-end-end-radius: .25rem;
  }

  .sm\:rounded-ee-2xl {
    border-end-end-radius: 1rem;
  }

  .sm\:rounded-ee-3xl {
    border-end-end-radius: 1.5rem;
  }

  .sm\:rounded-ee-full {
    border-end-end-radius: 9999px;
  }

  .sm\:rounded-ee-lg {
    border-end-end-radius: .5rem;
  }

  .sm\:rounded-ee-md {
    border-end-end-radius: .375rem;
  }

  .sm\:rounded-ee-none {
    border-end-end-radius: 0;
  }

  .sm\:rounded-ee-sm {
    border-end-end-radius: .125rem;
  }

  .sm\:rounded-ee-xl {
    border-end-end-radius: .75rem;
  }

  .sm\:rounded-es {
    border-end-start-radius: .25rem;
  }

  .sm\:rounded-es-2xl {
    border-end-start-radius: 1rem;
  }

  .sm\:rounded-es-3xl {
    border-end-start-radius: 1.5rem;
  }

  .sm\:rounded-es-full {
    border-end-start-radius: 9999px;
  }

  .sm\:rounded-es-lg {
    border-end-start-radius: .5rem;
  }

  .sm\:rounded-es-md {
    border-end-start-radius: .375rem;
  }

  .sm\:rounded-es-none {
    border-end-start-radius: 0;
  }

  .sm\:rounded-es-sm {
    border-end-start-radius: .125rem;
  }

  .sm\:rounded-es-xl {
    border-end-start-radius: .75rem;
  }

  .sm\:rounded-se {
    border-start-end-radius: .25rem;
  }

  .sm\:rounded-se-2xl {
    border-start-end-radius: 1rem;
  }

  .sm\:rounded-se-3xl {
    border-start-end-radius: 1.5rem;
  }

  .sm\:rounded-se-full {
    border-start-end-radius: 9999px;
  }

  .sm\:rounded-se-lg {
    border-start-end-radius: .5rem;
  }

  .sm\:rounded-se-md {
    border-start-end-radius: .375rem;
  }

  .sm\:rounded-se-none {
    border-start-end-radius: 0;
  }

  .sm\:rounded-se-sm {
    border-start-end-radius: .125rem;
  }

  .sm\:rounded-se-xl {
    border-start-end-radius: .75rem;
  }

  .sm\:rounded-ss {
    border-start-start-radius: .25rem;
  }

  .sm\:rounded-ss-2xl {
    border-start-start-radius: 1rem;
  }

  .sm\:rounded-ss-3xl {
    border-start-start-radius: 1.5rem;
  }

  .sm\:rounded-ss-full {
    border-start-start-radius: 9999px;
  }

  .sm\:rounded-ss-lg {
    border-start-start-radius: .5rem;
  }

  .sm\:rounded-ss-md {
    border-start-start-radius: .375rem;
  }

  .sm\:rounded-ss-none {
    border-start-start-radius: 0;
  }

  .sm\:rounded-ss-sm {
    border-start-start-radius: .125rem;
  }

  .sm\:rounded-ss-xl {
    border-start-start-radius: .75rem;
  }

  .sm\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .sm\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .sm\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .sm\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .sm\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .sm\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .sm\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .sm\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .sm\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .sm\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .sm\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .sm\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .sm\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .sm\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .sm\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .sm\:border {
    border-width: 1px;
  }

  .sm\:border-0 {
    border-width: 0;
  }

  .sm\:border-2 {
    border-width: 2px;
  }

  .sm\:border-4 {
    border-width: 4px;
  }

  .sm\:border-8 {
    border-width: 8px;
  }

  .sm\:border-x {
    border-left-width: 1px;
    border-right-width: 1px;
  }

  .sm\:border-x-0 {
    border-left-width: 0;
    border-right-width: 0;
  }

  .sm\:border-x-2 {
    border-left-width: 2px;
    border-right-width: 2px;
  }

  .sm\:border-x-4 {
    border-left-width: 4px;
    border-right-width: 4px;
  }

  .sm\:border-x-8 {
    border-left-width: 8px;
    border-right-width: 8px;
  }

  .sm\:border-y {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .sm\:border-y-0 {
    border-top-width: 0;
    border-bottom-width: 0;
  }

  .sm\:border-y-2 {
    border-top-width: 2px;
    border-bottom-width: 2px;
  }

  .sm\:border-y-4 {
    border-top-width: 4px;
    border-bottom-width: 4px;
  }

  .sm\:border-y-8 {
    border-top-width: 8px;
    border-bottom-width: 8px;
  }

  .sm\:border-b {
    border-bottom-width: 1px;
  }

  .sm\:border-b-0 {
    border-bottom-width: 0;
  }

  .sm\:border-b-2 {
    border-bottom-width: 2px;
  }

  .sm\:border-b-4 {
    border-bottom-width: 4px;
  }

  .sm\:border-b-8 {
    border-bottom-width: 8px;
  }

  .sm\:border-e {
    border-inline-end-width: 1px;
  }

  .sm\:border-e-0 {
    border-inline-end-width: 0;
  }

  .sm\:border-e-2 {
    border-inline-end-width: 2px;
  }

  .sm\:border-e-4 {
    border-inline-end-width: 4px;
  }

  .sm\:border-e-8 {
    border-inline-end-width: 8px;
  }

  .sm\:border-l {
    border-left-width: 1px;
  }

  .sm\:border-l-0 {
    border-left-width: 0;
  }

  .sm\:border-l-2 {
    border-left-width: 2px;
  }

  .sm\:border-l-4 {
    border-left-width: 4px;
  }

  .sm\:border-l-8 {
    border-left-width: 8px;
  }

  .sm\:border-r {
    border-right-width: 1px;
  }

  .sm\:border-r-0 {
    border-right-width: 0;
  }

  .sm\:border-r-2 {
    border-right-width: 2px;
  }

  .sm\:border-r-4 {
    border-right-width: 4px;
  }

  .sm\:border-r-8 {
    border-right-width: 8px;
  }

  .sm\:border-s {
    border-inline-start-width: 1px;
  }

  .sm\:border-s-0 {
    border-inline-start-width: 0;
  }

  .sm\:border-s-2 {
    border-inline-start-width: 2px;
  }

  .sm\:border-s-4 {
    border-inline-start-width: 4px;
  }

  .sm\:border-s-8 {
    border-inline-start-width: 8px;
  }

  .sm\:border-t {
    border-top-width: 1px;
  }

  .sm\:border-t-0 {
    border-top-width: 0;
  }

  .sm\:border-t-2 {
    border-top-width: 2px;
  }

  .sm\:border-t-4 {
    border-top-width: 4px;
  }

  .sm\:border-t-8 {
    border-top-width: 8px;
  }

  .sm\:border-solid {
    border-style: solid;
  }

  .sm\:border-dashed {
    border-style: dashed;
  }

  .sm\:border-dotted {
    border-style: dotted;
  }

  .sm\:border-double {
    border-style: double;
  }

  .sm\:border-hidden {
    border-style: hidden;
  }

  .sm\:border-none {
    border-style: none;
  }

  .sm\:border-black {
    border-color: var(--color-black);
  }

  .sm\:border-current {
    border-color: currentColor;
  }

  .sm\:border-danger {
    border-color: var(--color-danger-300);
  }

  .sm\:border-danger-100 {
    border-color: var(--color-danger-100);
  }

  .sm\:border-danger-200 {
    border-color: var(--color-danger-200);
  }

  .sm\:border-danger-300 {
    border-color: var(--color-danger-300);
  }

  .sm\:border-danger-400 {
    border-color: var(--color-danger-400);
  }

  .sm\:border-divider {
    border-color: var(--color-divider);
  }

  .sm\:border-gray {
    border-color: var(--color-gray);
  }

  .sm\:border-info {
    border-color: var(--color-info-300);
  }

  .sm\:border-info-100 {
    border-color: var(--color-info-100);
  }

  .sm\:border-info-200 {
    border-color: var(--color-info-200);
  }

  .sm\:border-info-300 {
    border-color: var(--color-info-300);
  }

  .sm\:border-info-400 {
    border-color: var(--color-info-400);
  }

  .sm\:border-primary {
    border-color: var(--color-primary-300);
  }

  .sm\:border-primary-100 {
    border-color: var(--color-primary-100);
  }

  .sm\:border-primary-200 {
    border-color: var(--color-primary-200);
  }

  .sm\:border-primary-250 {
    border-color: var(--color-primary-250);
  }

  .sm\:border-primary-300 {
    border-color: var(--color-primary-300);
  }

  .sm\:border-primary-400 {
    border-color: var(--color-primary-400);
  }

  .sm\:border-primary-500 {
    border-color: var(--color-primary-500);
  }

  .sm\:border-primary-600 {
    border-color: var(--color-primary-600);
  }

  .sm\:border-success {
    border-color: var(--color-success-300);
  }

  .sm\:border-success-100 {
    border-color: var(--color-success-100);
  }

  .sm\:border-success-200 {
    border-color: var(--color-success-200);
  }

  .sm\:border-success-300 {
    border-color: var(--color-success-300);
  }

  .sm\:border-success-400 {
    border-color: var(--color-success-400);
  }

  .sm\:border-transparent, .sm\:border-transparent\/0 {
    border-color: #0000;
  }

  .sm\:border-transparent\/10 {
    border-color: #0000001a;
  }

  .sm\:border-transparent\/100 {
    border-color: #000;
  }

  .sm\:border-transparent\/15 {
    border-color: #00000026;
  }

  .sm\:border-transparent\/20 {
    border-color: #0003;
  }

  .sm\:border-transparent\/25 {
    border-color: #00000040;
  }

  .sm\:border-transparent\/30 {
    border-color: #0000004d;
  }

  .sm\:border-transparent\/35 {
    border-color: #00000059;
  }

  .sm\:border-transparent\/40 {
    border-color: #0006;
  }

  .sm\:border-transparent\/45 {
    border-color: #00000073;
  }

  .sm\:border-transparent\/5 {
    border-color: #0000000d;
  }

  .sm\:border-transparent\/50 {
    border-color: #00000080;
  }

  .sm\:border-transparent\/55 {
    border-color: #0000008c;
  }

  .sm\:border-transparent\/60 {
    border-color: #0009;
  }

  .sm\:border-transparent\/65 {
    border-color: #000000a6;
  }

  .sm\:border-transparent\/70 {
    border-color: #000000b3;
  }

  .sm\:border-transparent\/75 {
    border-color: #000000bf;
  }

  .sm\:border-transparent\/80 {
    border-color: #000c;
  }

  .sm\:border-transparent\/85 {
    border-color: #000000d9;
  }

  .sm\:border-transparent\/90 {
    border-color: #000000e6;
  }

  .sm\:border-transparent\/95 {
    border-color: #000000f2;
  }

  .sm\:border-warning {
    border-color: var(--color-warning-300);
  }

  .sm\:border-warning-100 {
    border-color: var(--color-warning-100);
  }

  .sm\:border-warning-200 {
    border-color: var(--color-warning-200);
  }

  .sm\:border-warning-300 {
    border-color: var(--color-warning-300);
  }

  .sm\:border-warning-400 {
    border-color: var(--color-warning-400);
  }

  .sm\:border-white {
    border-color: var(--color-white);
  }

  .sm\:border-x-black {
    border-left-color: var(--color-black);
    border-right-color: var(--color-black);
  }

  .sm\:border-x-current {
    border-left-color: currentColor;
    border-right-color: currentColor;
  }

  .sm\:border-x-danger {
    border-left-color: var(--color-danger-300);
    border-right-color: var(--color-danger-300);
  }

  .sm\:border-x-danger-100 {
    border-left-color: var(--color-danger-100);
    border-right-color: var(--color-danger-100);
  }

  .sm\:border-x-danger-200 {
    border-left-color: var(--color-danger-200);
    border-right-color: var(--color-danger-200);
  }

  .sm\:border-x-danger-300 {
    border-left-color: var(--color-danger-300);
    border-right-color: var(--color-danger-300);
  }

  .sm\:border-x-danger-400 {
    border-left-color: var(--color-danger-400);
    border-right-color: var(--color-danger-400);
  }

  .sm\:border-x-divider {
    border-left-color: var(--color-divider);
    border-right-color: var(--color-divider);
  }

  .sm\:border-x-gray {
    border-left-color: var(--color-gray);
    border-right-color: var(--color-gray);
  }

  .sm\:border-x-info {
    border-left-color: var(--color-info-300);
    border-right-color: var(--color-info-300);
  }

  .sm\:border-x-info-100 {
    border-left-color: var(--color-info-100);
    border-right-color: var(--color-info-100);
  }

  .sm\:border-x-info-200 {
    border-left-color: var(--color-info-200);
    border-right-color: var(--color-info-200);
  }

  .sm\:border-x-info-300 {
    border-left-color: var(--color-info-300);
    border-right-color: var(--color-info-300);
  }

  .sm\:border-x-info-400 {
    border-left-color: var(--color-info-400);
    border-right-color: var(--color-info-400);
  }

  .sm\:border-x-primary {
    border-left-color: var(--color-primary-300);
    border-right-color: var(--color-primary-300);
  }

  .sm\:border-x-primary-100 {
    border-left-color: var(--color-primary-100);
    border-right-color: var(--color-primary-100);
  }

  .sm\:border-x-primary-200 {
    border-left-color: var(--color-primary-200);
    border-right-color: var(--color-primary-200);
  }

  .sm\:border-x-primary-250 {
    border-left-color: var(--color-primary-250);
    border-right-color: var(--color-primary-250);
  }

  .sm\:border-x-primary-300 {
    border-left-color: var(--color-primary-300);
    border-right-color: var(--color-primary-300);
  }

  .sm\:border-x-primary-400 {
    border-left-color: var(--color-primary-400);
    border-right-color: var(--color-primary-400);
  }

  .sm\:border-x-primary-500 {
    border-left-color: var(--color-primary-500);
    border-right-color: var(--color-primary-500);
  }

  .sm\:border-x-primary-600 {
    border-left-color: var(--color-primary-600);
    border-right-color: var(--color-primary-600);
  }

  .sm\:border-x-success {
    border-left-color: var(--color-success-300);
    border-right-color: var(--color-success-300);
  }

  .sm\:border-x-success-100 {
    border-left-color: var(--color-success-100);
    border-right-color: var(--color-success-100);
  }

  .sm\:border-x-success-200 {
    border-left-color: var(--color-success-200);
    border-right-color: var(--color-success-200);
  }

  .sm\:border-x-success-300 {
    border-left-color: var(--color-success-300);
    border-right-color: var(--color-success-300);
  }

  .sm\:border-x-success-400 {
    border-left-color: var(--color-success-400);
    border-right-color: var(--color-success-400);
  }

  .sm\:border-x-transparent, .sm\:border-x-transparent\/0 {
    border-left-color: #0000;
    border-right-color: #0000;
  }

  .sm\:border-x-transparent\/10 {
    border-left-color: #0000001a;
    border-right-color: #0000001a;
  }

  .sm\:border-x-transparent\/100 {
    border-left-color: #000;
    border-right-color: #000;
  }

  .sm\:border-x-transparent\/15 {
    border-left-color: #00000026;
    border-right-color: #00000026;
  }

  .sm\:border-x-transparent\/20 {
    border-left-color: #0003;
    border-right-color: #0003;
  }

  .sm\:border-x-transparent\/25 {
    border-left-color: #00000040;
    border-right-color: #00000040;
  }

  .sm\:border-x-transparent\/30 {
    border-left-color: #0000004d;
    border-right-color: #0000004d;
  }

  .sm\:border-x-transparent\/35 {
    border-left-color: #00000059;
    border-right-color: #00000059;
  }

  .sm\:border-x-transparent\/40 {
    border-left-color: #0006;
    border-right-color: #0006;
  }

  .sm\:border-x-transparent\/45 {
    border-left-color: #00000073;
    border-right-color: #00000073;
  }

  .sm\:border-x-transparent\/5 {
    border-left-color: #0000000d;
    border-right-color: #0000000d;
  }

  .sm\:border-x-transparent\/50 {
    border-left-color: #00000080;
    border-right-color: #00000080;
  }

  .sm\:border-x-transparent\/55 {
    border-left-color: #0000008c;
    border-right-color: #0000008c;
  }

  .sm\:border-x-transparent\/60 {
    border-left-color: #0009;
    border-right-color: #0009;
  }

  .sm\:border-x-transparent\/65 {
    border-left-color: #000000a6;
    border-right-color: #000000a6;
  }

  .sm\:border-x-transparent\/70 {
    border-left-color: #000000b3;
    border-right-color: #000000b3;
  }

  .sm\:border-x-transparent\/75 {
    border-left-color: #000000bf;
    border-right-color: #000000bf;
  }

  .sm\:border-x-transparent\/80 {
    border-left-color: #000c;
    border-right-color: #000c;
  }

  .sm\:border-x-transparent\/85 {
    border-left-color: #000000d9;
    border-right-color: #000000d9;
  }

  .sm\:border-x-transparent\/90 {
    border-left-color: #000000e6;
    border-right-color: #000000e6;
  }

  .sm\:border-x-transparent\/95 {
    border-left-color: #000000f2;
    border-right-color: #000000f2;
  }

  .sm\:border-x-warning {
    border-left-color: var(--color-warning-300);
    border-right-color: var(--color-warning-300);
  }

  .sm\:border-x-warning-100 {
    border-left-color: var(--color-warning-100);
    border-right-color: var(--color-warning-100);
  }

  .sm\:border-x-warning-200 {
    border-left-color: var(--color-warning-200);
    border-right-color: var(--color-warning-200);
  }

  .sm\:border-x-warning-300 {
    border-left-color: var(--color-warning-300);
    border-right-color: var(--color-warning-300);
  }

  .sm\:border-x-warning-400 {
    border-left-color: var(--color-warning-400);
    border-right-color: var(--color-warning-400);
  }

  .sm\:border-x-white {
    border-left-color: var(--color-white);
    border-right-color: var(--color-white);
  }

  .sm\:border-y-black {
    border-top-color: var(--color-black);
    border-bottom-color: var(--color-black);
  }

  .sm\:border-y-current {
    border-top-color: currentColor;
    border-bottom-color: currentColor;
  }

  .sm\:border-y-danger {
    border-top-color: var(--color-danger-300);
    border-bottom-color: var(--color-danger-300);
  }

  .sm\:border-y-danger-100 {
    border-top-color: var(--color-danger-100);
    border-bottom-color: var(--color-danger-100);
  }

  .sm\:border-y-danger-200 {
    border-top-color: var(--color-danger-200);
    border-bottom-color: var(--color-danger-200);
  }

  .sm\:border-y-danger-300 {
    border-top-color: var(--color-danger-300);
    border-bottom-color: var(--color-danger-300);
  }

  .sm\:border-y-danger-400 {
    border-top-color: var(--color-danger-400);
    border-bottom-color: var(--color-danger-400);
  }

  .sm\:border-y-divider {
    border-top-color: var(--color-divider);
    border-bottom-color: var(--color-divider);
  }

  .sm\:border-y-gray {
    border-top-color: var(--color-gray);
    border-bottom-color: var(--color-gray);
  }

  .sm\:border-y-info {
    border-top-color: var(--color-info-300);
    border-bottom-color: var(--color-info-300);
  }

  .sm\:border-y-info-100 {
    border-top-color: var(--color-info-100);
    border-bottom-color: var(--color-info-100);
  }

  .sm\:border-y-info-200 {
    border-top-color: var(--color-info-200);
    border-bottom-color: var(--color-info-200);
  }

  .sm\:border-y-info-300 {
    border-top-color: var(--color-info-300);
    border-bottom-color: var(--color-info-300);
  }

  .sm\:border-y-info-400 {
    border-top-color: var(--color-info-400);
    border-bottom-color: var(--color-info-400);
  }

  .sm\:border-y-primary {
    border-top-color: var(--color-primary-300);
    border-bottom-color: var(--color-primary-300);
  }

  .sm\:border-y-primary-100 {
    border-top-color: var(--color-primary-100);
    border-bottom-color: var(--color-primary-100);
  }

  .sm\:border-y-primary-200 {
    border-top-color: var(--color-primary-200);
    border-bottom-color: var(--color-primary-200);
  }

  .sm\:border-y-primary-250 {
    border-top-color: var(--color-primary-250);
    border-bottom-color: var(--color-primary-250);
  }

  .sm\:border-y-primary-300 {
    border-top-color: var(--color-primary-300);
    border-bottom-color: var(--color-primary-300);
  }

  .sm\:border-y-primary-400 {
    border-top-color: var(--color-primary-400);
    border-bottom-color: var(--color-primary-400);
  }

  .sm\:border-y-primary-500 {
    border-top-color: var(--color-primary-500);
    border-bottom-color: var(--color-primary-500);
  }

  .sm\:border-y-primary-600 {
    border-top-color: var(--color-primary-600);
    border-bottom-color: var(--color-primary-600);
  }

  .sm\:border-y-success {
    border-top-color: var(--color-success-300);
    border-bottom-color: var(--color-success-300);
  }

  .sm\:border-y-success-100 {
    border-top-color: var(--color-success-100);
    border-bottom-color: var(--color-success-100);
  }

  .sm\:border-y-success-200 {
    border-top-color: var(--color-success-200);
    border-bottom-color: var(--color-success-200);
  }

  .sm\:border-y-success-300 {
    border-top-color: var(--color-success-300);
    border-bottom-color: var(--color-success-300);
  }

  .sm\:border-y-success-400 {
    border-top-color: var(--color-success-400);
    border-bottom-color: var(--color-success-400);
  }

  .sm\:border-y-transparent, .sm\:border-y-transparent\/0 {
    border-top-color: #0000;
    border-bottom-color: #0000;
  }

  .sm\:border-y-transparent\/10 {
    border-top-color: #0000001a;
    border-bottom-color: #0000001a;
  }

  .sm\:border-y-transparent\/100 {
    border-top-color: #000;
    border-bottom-color: #000;
  }

  .sm\:border-y-transparent\/15 {
    border-top-color: #00000026;
    border-bottom-color: #00000026;
  }

  .sm\:border-y-transparent\/20 {
    border-top-color: #0003;
    border-bottom-color: #0003;
  }

  .sm\:border-y-transparent\/25 {
    border-top-color: #00000040;
    border-bottom-color: #00000040;
  }

  .sm\:border-y-transparent\/30 {
    border-top-color: #0000004d;
    border-bottom-color: #0000004d;
  }

  .sm\:border-y-transparent\/35 {
    border-top-color: #00000059;
    border-bottom-color: #00000059;
  }

  .sm\:border-y-transparent\/40 {
    border-top-color: #0006;
    border-bottom-color: #0006;
  }

  .sm\:border-y-transparent\/45 {
    border-top-color: #00000073;
    border-bottom-color: #00000073;
  }

  .sm\:border-y-transparent\/5 {
    border-top-color: #0000000d;
    border-bottom-color: #0000000d;
  }

  .sm\:border-y-transparent\/50 {
    border-top-color: #00000080;
    border-bottom-color: #00000080;
  }

  .sm\:border-y-transparent\/55 {
    border-top-color: #0000008c;
    border-bottom-color: #0000008c;
  }

  .sm\:border-y-transparent\/60 {
    border-top-color: #0009;
    border-bottom-color: #0009;
  }

  .sm\:border-y-transparent\/65 {
    border-top-color: #000000a6;
    border-bottom-color: #000000a6;
  }

  .sm\:border-y-transparent\/70 {
    border-top-color: #000000b3;
    border-bottom-color: #000000b3;
  }

  .sm\:border-y-transparent\/75 {
    border-top-color: #000000bf;
    border-bottom-color: #000000bf;
  }

  .sm\:border-y-transparent\/80 {
    border-top-color: #000c;
    border-bottom-color: #000c;
  }

  .sm\:border-y-transparent\/85 {
    border-top-color: #000000d9;
    border-bottom-color: #000000d9;
  }

  .sm\:border-y-transparent\/90 {
    border-top-color: #000000e6;
    border-bottom-color: #000000e6;
  }

  .sm\:border-y-transparent\/95 {
    border-top-color: #000000f2;
    border-bottom-color: #000000f2;
  }

  .sm\:border-y-warning {
    border-top-color: var(--color-warning-300);
    border-bottom-color: var(--color-warning-300);
  }

  .sm\:border-y-warning-100 {
    border-top-color: var(--color-warning-100);
    border-bottom-color: var(--color-warning-100);
  }

  .sm\:border-y-warning-200 {
    border-top-color: var(--color-warning-200);
    border-bottom-color: var(--color-warning-200);
  }

  .sm\:border-y-warning-300 {
    border-top-color: var(--color-warning-300);
    border-bottom-color: var(--color-warning-300);
  }

  .sm\:border-y-warning-400 {
    border-top-color: var(--color-warning-400);
    border-bottom-color: var(--color-warning-400);
  }

  .sm\:border-y-white {
    border-top-color: var(--color-white);
    border-bottom-color: var(--color-white);
  }

  .sm\:border-b-black {
    border-bottom-color: var(--color-black);
  }

  .sm\:border-b-current {
    border-bottom-color: currentColor;
  }

  .sm\:border-b-danger {
    border-bottom-color: var(--color-danger-300);
  }

  .sm\:border-b-danger-100 {
    border-bottom-color: var(--color-danger-100);
  }

  .sm\:border-b-danger-200 {
    border-bottom-color: var(--color-danger-200);
  }

  .sm\:border-b-danger-300 {
    border-bottom-color: var(--color-danger-300);
  }

  .sm\:border-b-danger-400 {
    border-bottom-color: var(--color-danger-400);
  }

  .sm\:border-b-divider {
    border-bottom-color: var(--color-divider);
  }

  .sm\:border-b-gray {
    border-bottom-color: var(--color-gray);
  }

  .sm\:border-b-info {
    border-bottom-color: var(--color-info-300);
  }

  .sm\:border-b-info-100 {
    border-bottom-color: var(--color-info-100);
  }

  .sm\:border-b-info-200 {
    border-bottom-color: var(--color-info-200);
  }

  .sm\:border-b-info-300 {
    border-bottom-color: var(--color-info-300);
  }

  .sm\:border-b-info-400 {
    border-bottom-color: var(--color-info-400);
  }

  .sm\:border-b-primary {
    border-bottom-color: var(--color-primary-300);
  }

  .sm\:border-b-primary-100 {
    border-bottom-color: var(--color-primary-100);
  }

  .sm\:border-b-primary-200 {
    border-bottom-color: var(--color-primary-200);
  }

  .sm\:border-b-primary-250 {
    border-bottom-color: var(--color-primary-250);
  }

  .sm\:border-b-primary-300 {
    border-bottom-color: var(--color-primary-300);
  }

  .sm\:border-b-primary-400 {
    border-bottom-color: var(--color-primary-400);
  }

  .sm\:border-b-primary-500 {
    border-bottom-color: var(--color-primary-500);
  }

  .sm\:border-b-primary-600 {
    border-bottom-color: var(--color-primary-600);
  }

  .sm\:border-b-success {
    border-bottom-color: var(--color-success-300);
  }

  .sm\:border-b-success-100 {
    border-bottom-color: var(--color-success-100);
  }

  .sm\:border-b-success-200 {
    border-bottom-color: var(--color-success-200);
  }

  .sm\:border-b-success-300 {
    border-bottom-color: var(--color-success-300);
  }

  .sm\:border-b-success-400 {
    border-bottom-color: var(--color-success-400);
  }

  .sm\:border-b-transparent, .sm\:border-b-transparent\/0 {
    border-bottom-color: #0000;
  }

  .sm\:border-b-transparent\/10 {
    border-bottom-color: #0000001a;
  }

  .sm\:border-b-transparent\/100 {
    border-bottom-color: #000;
  }

  .sm\:border-b-transparent\/15 {
    border-bottom-color: #00000026;
  }

  .sm\:border-b-transparent\/20 {
    border-bottom-color: #0003;
  }

  .sm\:border-b-transparent\/25 {
    border-bottom-color: #00000040;
  }

  .sm\:border-b-transparent\/30 {
    border-bottom-color: #0000004d;
  }

  .sm\:border-b-transparent\/35 {
    border-bottom-color: #00000059;
  }

  .sm\:border-b-transparent\/40 {
    border-bottom-color: #0006;
  }

  .sm\:border-b-transparent\/45 {
    border-bottom-color: #00000073;
  }

  .sm\:border-b-transparent\/5 {
    border-bottom-color: #0000000d;
  }

  .sm\:border-b-transparent\/50 {
    border-bottom-color: #00000080;
  }

  .sm\:border-b-transparent\/55 {
    border-bottom-color: #0000008c;
  }

  .sm\:border-b-transparent\/60 {
    border-bottom-color: #0009;
  }

  .sm\:border-b-transparent\/65 {
    border-bottom-color: #000000a6;
  }

  .sm\:border-b-transparent\/70 {
    border-bottom-color: #000000b3;
  }

  .sm\:border-b-transparent\/75 {
    border-bottom-color: #000000bf;
  }

  .sm\:border-b-transparent\/80 {
    border-bottom-color: #000c;
  }

  .sm\:border-b-transparent\/85 {
    border-bottom-color: #000000d9;
  }

  .sm\:border-b-transparent\/90 {
    border-bottom-color: #000000e6;
  }

  .sm\:border-b-transparent\/95 {
    border-bottom-color: #000000f2;
  }

  .sm\:border-b-warning {
    border-bottom-color: var(--color-warning-300);
  }

  .sm\:border-b-warning-100 {
    border-bottom-color: var(--color-warning-100);
  }

  .sm\:border-b-warning-200 {
    border-bottom-color: var(--color-warning-200);
  }

  .sm\:border-b-warning-300 {
    border-bottom-color: var(--color-warning-300);
  }

  .sm\:border-b-warning-400 {
    border-bottom-color: var(--color-warning-400);
  }

  .sm\:border-b-white {
    border-bottom-color: var(--color-white);
  }

  .sm\:border-e-black {
    border-inline-end-color: var(--color-black);
  }

  .sm\:border-e-current {
    border-inline-end-color: currentColor;
  }

  .sm\:border-e-danger {
    border-inline-end-color: var(--color-danger-300);
  }

  .sm\:border-e-danger-100 {
    border-inline-end-color: var(--color-danger-100);
  }

  .sm\:border-e-danger-200 {
    border-inline-end-color: var(--color-danger-200);
  }

  .sm\:border-e-danger-300 {
    border-inline-end-color: var(--color-danger-300);
  }

  .sm\:border-e-danger-400 {
    border-inline-end-color: var(--color-danger-400);
  }

  .sm\:border-e-divider {
    border-inline-end-color: var(--color-divider);
  }

  .sm\:border-e-gray {
    border-inline-end-color: var(--color-gray);
  }

  .sm\:border-e-info {
    border-inline-end-color: var(--color-info-300);
  }

  .sm\:border-e-info-100 {
    border-inline-end-color: var(--color-info-100);
  }

  .sm\:border-e-info-200 {
    border-inline-end-color: var(--color-info-200);
  }

  .sm\:border-e-info-300 {
    border-inline-end-color: var(--color-info-300);
  }

  .sm\:border-e-info-400 {
    border-inline-end-color: var(--color-info-400);
  }

  .sm\:border-e-primary {
    border-inline-end-color: var(--color-primary-300);
  }

  .sm\:border-e-primary-100 {
    border-inline-end-color: var(--color-primary-100);
  }

  .sm\:border-e-primary-200 {
    border-inline-end-color: var(--color-primary-200);
  }

  .sm\:border-e-primary-250 {
    border-inline-end-color: var(--color-primary-250);
  }

  .sm\:border-e-primary-300 {
    border-inline-end-color: var(--color-primary-300);
  }

  .sm\:border-e-primary-400 {
    border-inline-end-color: var(--color-primary-400);
  }

  .sm\:border-e-primary-500 {
    border-inline-end-color: var(--color-primary-500);
  }

  .sm\:border-e-primary-600 {
    border-inline-end-color: var(--color-primary-600);
  }

  .sm\:border-e-success {
    border-inline-end-color: var(--color-success-300);
  }

  .sm\:border-e-success-100 {
    border-inline-end-color: var(--color-success-100);
  }

  .sm\:border-e-success-200 {
    border-inline-end-color: var(--color-success-200);
  }

  .sm\:border-e-success-300 {
    border-inline-end-color: var(--color-success-300);
  }

  .sm\:border-e-success-400 {
    border-inline-end-color: var(--color-success-400);
  }

  .sm\:border-e-transparent, .sm\:border-e-transparent\/0 {
    border-inline-end-color: #0000;
  }

  .sm\:border-e-transparent\/10 {
    border-inline-end-color: #0000001a;
  }

  .sm\:border-e-transparent\/100 {
    border-inline-end-color: #000;
  }

  .sm\:border-e-transparent\/15 {
    border-inline-end-color: #00000026;
  }

  .sm\:border-e-transparent\/20 {
    border-inline-end-color: #0003;
  }

  .sm\:border-e-transparent\/25 {
    border-inline-end-color: #00000040;
  }

  .sm\:border-e-transparent\/30 {
    border-inline-end-color: #0000004d;
  }

  .sm\:border-e-transparent\/35 {
    border-inline-end-color: #00000059;
  }

  .sm\:border-e-transparent\/40 {
    border-inline-end-color: #0006;
  }

  .sm\:border-e-transparent\/45 {
    border-inline-end-color: #00000073;
  }

  .sm\:border-e-transparent\/5 {
    border-inline-end-color: #0000000d;
  }

  .sm\:border-e-transparent\/50 {
    border-inline-end-color: #00000080;
  }

  .sm\:border-e-transparent\/55 {
    border-inline-end-color: #0000008c;
  }

  .sm\:border-e-transparent\/60 {
    border-inline-end-color: #0009;
  }

  .sm\:border-e-transparent\/65 {
    border-inline-end-color: #000000a6;
  }

  .sm\:border-e-transparent\/70 {
    border-inline-end-color: #000000b3;
  }

  .sm\:border-e-transparent\/75 {
    border-inline-end-color: #000000bf;
  }

  .sm\:border-e-transparent\/80 {
    border-inline-end-color: #000c;
  }

  .sm\:border-e-transparent\/85 {
    border-inline-end-color: #000000d9;
  }

  .sm\:border-e-transparent\/90 {
    border-inline-end-color: #000000e6;
  }

  .sm\:border-e-transparent\/95 {
    border-inline-end-color: #000000f2;
  }

  .sm\:border-e-warning {
    border-inline-end-color: var(--color-warning-300);
  }

  .sm\:border-e-warning-100 {
    border-inline-end-color: var(--color-warning-100);
  }

  .sm\:border-e-warning-200 {
    border-inline-end-color: var(--color-warning-200);
  }

  .sm\:border-e-warning-300 {
    border-inline-end-color: var(--color-warning-300);
  }

  .sm\:border-e-warning-400 {
    border-inline-end-color: var(--color-warning-400);
  }

  .sm\:border-e-white {
    border-inline-end-color: var(--color-white);
  }

  .sm\:border-l-black {
    border-left-color: var(--color-black);
  }

  .sm\:border-l-current {
    border-left-color: currentColor;
  }

  .sm\:border-l-danger {
    border-left-color: var(--color-danger-300);
  }

  .sm\:border-l-danger-100 {
    border-left-color: var(--color-danger-100);
  }

  .sm\:border-l-danger-200 {
    border-left-color: var(--color-danger-200);
  }

  .sm\:border-l-danger-300 {
    border-left-color: var(--color-danger-300);
  }

  .sm\:border-l-danger-400 {
    border-left-color: var(--color-danger-400);
  }

  .sm\:border-l-divider {
    border-left-color: var(--color-divider);
  }

  .sm\:border-l-gray {
    border-left-color: var(--color-gray);
  }

  .sm\:border-l-info {
    border-left-color: var(--color-info-300);
  }

  .sm\:border-l-info-100 {
    border-left-color: var(--color-info-100);
  }

  .sm\:border-l-info-200 {
    border-left-color: var(--color-info-200);
  }

  .sm\:border-l-info-300 {
    border-left-color: var(--color-info-300);
  }

  .sm\:border-l-info-400 {
    border-left-color: var(--color-info-400);
  }

  .sm\:border-l-primary {
    border-left-color: var(--color-primary-300);
  }

  .sm\:border-l-primary-100 {
    border-left-color: var(--color-primary-100);
  }

  .sm\:border-l-primary-200 {
    border-left-color: var(--color-primary-200);
  }

  .sm\:border-l-primary-250 {
    border-left-color: var(--color-primary-250);
  }

  .sm\:border-l-primary-300 {
    border-left-color: var(--color-primary-300);
  }

  .sm\:border-l-primary-400 {
    border-left-color: var(--color-primary-400);
  }

  .sm\:border-l-primary-500 {
    border-left-color: var(--color-primary-500);
  }

  .sm\:border-l-primary-600 {
    border-left-color: var(--color-primary-600);
  }

  .sm\:border-l-success {
    border-left-color: var(--color-success-300);
  }

  .sm\:border-l-success-100 {
    border-left-color: var(--color-success-100);
  }

  .sm\:border-l-success-200 {
    border-left-color: var(--color-success-200);
  }

  .sm\:border-l-success-300 {
    border-left-color: var(--color-success-300);
  }

  .sm\:border-l-success-400 {
    border-left-color: var(--color-success-400);
  }

  .sm\:border-l-transparent, .sm\:border-l-transparent\/0 {
    border-left-color: #0000;
  }

  .sm\:border-l-transparent\/10 {
    border-left-color: #0000001a;
  }

  .sm\:border-l-transparent\/100 {
    border-left-color: #000;
  }

  .sm\:border-l-transparent\/15 {
    border-left-color: #00000026;
  }

  .sm\:border-l-transparent\/20 {
    border-left-color: #0003;
  }

  .sm\:border-l-transparent\/25 {
    border-left-color: #00000040;
  }

  .sm\:border-l-transparent\/30 {
    border-left-color: #0000004d;
  }

  .sm\:border-l-transparent\/35 {
    border-left-color: #00000059;
  }

  .sm\:border-l-transparent\/40 {
    border-left-color: #0006;
  }

  .sm\:border-l-transparent\/45 {
    border-left-color: #00000073;
  }

  .sm\:border-l-transparent\/5 {
    border-left-color: #0000000d;
  }

  .sm\:border-l-transparent\/50 {
    border-left-color: #00000080;
  }

  .sm\:border-l-transparent\/55 {
    border-left-color: #0000008c;
  }

  .sm\:border-l-transparent\/60 {
    border-left-color: #0009;
  }

  .sm\:border-l-transparent\/65 {
    border-left-color: #000000a6;
  }

  .sm\:border-l-transparent\/70 {
    border-left-color: #000000b3;
  }

  .sm\:border-l-transparent\/75 {
    border-left-color: #000000bf;
  }

  .sm\:border-l-transparent\/80 {
    border-left-color: #000c;
  }

  .sm\:border-l-transparent\/85 {
    border-left-color: #000000d9;
  }

  .sm\:border-l-transparent\/90 {
    border-left-color: #000000e6;
  }

  .sm\:border-l-transparent\/95 {
    border-left-color: #000000f2;
  }

  .sm\:border-l-warning {
    border-left-color: var(--color-warning-300);
  }

  .sm\:border-l-warning-100 {
    border-left-color: var(--color-warning-100);
  }

  .sm\:border-l-warning-200 {
    border-left-color: var(--color-warning-200);
  }

  .sm\:border-l-warning-300 {
    border-left-color: var(--color-warning-300);
  }

  .sm\:border-l-warning-400 {
    border-left-color: var(--color-warning-400);
  }

  .sm\:border-l-white {
    border-left-color: var(--color-white);
  }

  .sm\:border-r-black {
    border-right-color: var(--color-black);
  }

  .sm\:border-r-current {
    border-right-color: currentColor;
  }

  .sm\:border-r-danger {
    border-right-color: var(--color-danger-300);
  }

  .sm\:border-r-danger-100 {
    border-right-color: var(--color-danger-100);
  }

  .sm\:border-r-danger-200 {
    border-right-color: var(--color-danger-200);
  }

  .sm\:border-r-danger-300 {
    border-right-color: var(--color-danger-300);
  }

  .sm\:border-r-danger-400 {
    border-right-color: var(--color-danger-400);
  }

  .sm\:border-r-divider {
    border-right-color: var(--color-divider);
  }

  .sm\:border-r-gray {
    border-right-color: var(--color-gray);
  }

  .sm\:border-r-info {
    border-right-color: var(--color-info-300);
  }

  .sm\:border-r-info-100 {
    border-right-color: var(--color-info-100);
  }

  .sm\:border-r-info-200 {
    border-right-color: var(--color-info-200);
  }

  .sm\:border-r-info-300 {
    border-right-color: var(--color-info-300);
  }

  .sm\:border-r-info-400 {
    border-right-color: var(--color-info-400);
  }

  .sm\:border-r-primary {
    border-right-color: var(--color-primary-300);
  }

  .sm\:border-r-primary-100 {
    border-right-color: var(--color-primary-100);
  }

  .sm\:border-r-primary-200 {
    border-right-color: var(--color-primary-200);
  }

  .sm\:border-r-primary-250 {
    border-right-color: var(--color-primary-250);
  }

  .sm\:border-r-primary-300 {
    border-right-color: var(--color-primary-300);
  }

  .sm\:border-r-primary-400 {
    border-right-color: var(--color-primary-400);
  }

  .sm\:border-r-primary-500 {
    border-right-color: var(--color-primary-500);
  }

  .sm\:border-r-primary-600 {
    border-right-color: var(--color-primary-600);
  }

  .sm\:border-r-success {
    border-right-color: var(--color-success-300);
  }

  .sm\:border-r-success-100 {
    border-right-color: var(--color-success-100);
  }

  .sm\:border-r-success-200 {
    border-right-color: var(--color-success-200);
  }

  .sm\:border-r-success-300 {
    border-right-color: var(--color-success-300);
  }

  .sm\:border-r-success-400 {
    border-right-color: var(--color-success-400);
  }

  .sm\:border-r-transparent, .sm\:border-r-transparent\/0 {
    border-right-color: #0000;
  }

  .sm\:border-r-transparent\/10 {
    border-right-color: #0000001a;
  }

  .sm\:border-r-transparent\/100 {
    border-right-color: #000;
  }

  .sm\:border-r-transparent\/15 {
    border-right-color: #00000026;
  }

  .sm\:border-r-transparent\/20 {
    border-right-color: #0003;
  }

  .sm\:border-r-transparent\/25 {
    border-right-color: #00000040;
  }

  .sm\:border-r-transparent\/30 {
    border-right-color: #0000004d;
  }

  .sm\:border-r-transparent\/35 {
    border-right-color: #00000059;
  }

  .sm\:border-r-transparent\/40 {
    border-right-color: #0006;
  }

  .sm\:border-r-transparent\/45 {
    border-right-color: #00000073;
  }

  .sm\:border-r-transparent\/5 {
    border-right-color: #0000000d;
  }

  .sm\:border-r-transparent\/50 {
    border-right-color: #00000080;
  }

  .sm\:border-r-transparent\/55 {
    border-right-color: #0000008c;
  }

  .sm\:border-r-transparent\/60 {
    border-right-color: #0009;
  }

  .sm\:border-r-transparent\/65 {
    border-right-color: #000000a6;
  }

  .sm\:border-r-transparent\/70 {
    border-right-color: #000000b3;
  }

  .sm\:border-r-transparent\/75 {
    border-right-color: #000000bf;
  }

  .sm\:border-r-transparent\/80 {
    border-right-color: #000c;
  }

  .sm\:border-r-transparent\/85 {
    border-right-color: #000000d9;
  }

  .sm\:border-r-transparent\/90 {
    border-right-color: #000000e6;
  }

  .sm\:border-r-transparent\/95 {
    border-right-color: #000000f2;
  }

  .sm\:border-r-warning {
    border-right-color: var(--color-warning-300);
  }

  .sm\:border-r-warning-100 {
    border-right-color: var(--color-warning-100);
  }

  .sm\:border-r-warning-200 {
    border-right-color: var(--color-warning-200);
  }

  .sm\:border-r-warning-300 {
    border-right-color: var(--color-warning-300);
  }

  .sm\:border-r-warning-400 {
    border-right-color: var(--color-warning-400);
  }

  .sm\:border-r-white {
    border-right-color: var(--color-white);
  }

  .sm\:border-s-black {
    border-inline-start-color: var(--color-black);
  }

  .sm\:border-s-current {
    border-inline-start-color: currentColor;
  }

  .sm\:border-s-danger {
    border-inline-start-color: var(--color-danger-300);
  }

  .sm\:border-s-danger-100 {
    border-inline-start-color: var(--color-danger-100);
  }

  .sm\:border-s-danger-200 {
    border-inline-start-color: var(--color-danger-200);
  }

  .sm\:border-s-danger-300 {
    border-inline-start-color: var(--color-danger-300);
  }

  .sm\:border-s-danger-400 {
    border-inline-start-color: var(--color-danger-400);
  }

  .sm\:border-s-divider {
    border-inline-start-color: var(--color-divider);
  }

  .sm\:border-s-gray {
    border-inline-start-color: var(--color-gray);
  }

  .sm\:border-s-info {
    border-inline-start-color: var(--color-info-300);
  }

  .sm\:border-s-info-100 {
    border-inline-start-color: var(--color-info-100);
  }

  .sm\:border-s-info-200 {
    border-inline-start-color: var(--color-info-200);
  }

  .sm\:border-s-info-300 {
    border-inline-start-color: var(--color-info-300);
  }

  .sm\:border-s-info-400 {
    border-inline-start-color: var(--color-info-400);
  }

  .sm\:border-s-primary {
    border-inline-start-color: var(--color-primary-300);
  }

  .sm\:border-s-primary-100 {
    border-inline-start-color: var(--color-primary-100);
  }

  .sm\:border-s-primary-200 {
    border-inline-start-color: var(--color-primary-200);
  }

  .sm\:border-s-primary-250 {
    border-inline-start-color: var(--color-primary-250);
  }

  .sm\:border-s-primary-300 {
    border-inline-start-color: var(--color-primary-300);
  }

  .sm\:border-s-primary-400 {
    border-inline-start-color: var(--color-primary-400);
  }

  .sm\:border-s-primary-500 {
    border-inline-start-color: var(--color-primary-500);
  }

  .sm\:border-s-primary-600 {
    border-inline-start-color: var(--color-primary-600);
  }

  .sm\:border-s-success {
    border-inline-start-color: var(--color-success-300);
  }

  .sm\:border-s-success-100 {
    border-inline-start-color: var(--color-success-100);
  }

  .sm\:border-s-success-200 {
    border-inline-start-color: var(--color-success-200);
  }

  .sm\:border-s-success-300 {
    border-inline-start-color: var(--color-success-300);
  }

  .sm\:border-s-success-400 {
    border-inline-start-color: var(--color-success-400);
  }

  .sm\:border-s-transparent, .sm\:border-s-transparent\/0 {
    border-inline-start-color: #0000;
  }

  .sm\:border-s-transparent\/10 {
    border-inline-start-color: #0000001a;
  }

  .sm\:border-s-transparent\/100 {
    border-inline-start-color: #000;
  }

  .sm\:border-s-transparent\/15 {
    border-inline-start-color: #00000026;
  }

  .sm\:border-s-transparent\/20 {
    border-inline-start-color: #0003;
  }

  .sm\:border-s-transparent\/25 {
    border-inline-start-color: #00000040;
  }

  .sm\:border-s-transparent\/30 {
    border-inline-start-color: #0000004d;
  }

  .sm\:border-s-transparent\/35 {
    border-inline-start-color: #00000059;
  }

  .sm\:border-s-transparent\/40 {
    border-inline-start-color: #0006;
  }

  .sm\:border-s-transparent\/45 {
    border-inline-start-color: #00000073;
  }

  .sm\:border-s-transparent\/5 {
    border-inline-start-color: #0000000d;
  }

  .sm\:border-s-transparent\/50 {
    border-inline-start-color: #00000080;
  }

  .sm\:border-s-transparent\/55 {
    border-inline-start-color: #0000008c;
  }

  .sm\:border-s-transparent\/60 {
    border-inline-start-color: #0009;
  }

  .sm\:border-s-transparent\/65 {
    border-inline-start-color: #000000a6;
  }

  .sm\:border-s-transparent\/70 {
    border-inline-start-color: #000000b3;
  }

  .sm\:border-s-transparent\/75 {
    border-inline-start-color: #000000bf;
  }

  .sm\:border-s-transparent\/80 {
    border-inline-start-color: #000c;
  }

  .sm\:border-s-transparent\/85 {
    border-inline-start-color: #000000d9;
  }

  .sm\:border-s-transparent\/90 {
    border-inline-start-color: #000000e6;
  }

  .sm\:border-s-transparent\/95 {
    border-inline-start-color: #000000f2;
  }

  .sm\:border-s-warning {
    border-inline-start-color: var(--color-warning-300);
  }

  .sm\:border-s-warning-100 {
    border-inline-start-color: var(--color-warning-100);
  }

  .sm\:border-s-warning-200 {
    border-inline-start-color: var(--color-warning-200);
  }

  .sm\:border-s-warning-300 {
    border-inline-start-color: var(--color-warning-300);
  }

  .sm\:border-s-warning-400 {
    border-inline-start-color: var(--color-warning-400);
  }

  .sm\:border-s-white {
    border-inline-start-color: var(--color-white);
  }

  .sm\:border-t-black {
    border-top-color: var(--color-black);
  }

  .sm\:border-t-current {
    border-top-color: currentColor;
  }

  .sm\:border-t-danger {
    border-top-color: var(--color-danger-300);
  }

  .sm\:border-t-danger-100 {
    border-top-color: var(--color-danger-100);
  }

  .sm\:border-t-danger-200 {
    border-top-color: var(--color-danger-200);
  }

  .sm\:border-t-danger-300 {
    border-top-color: var(--color-danger-300);
  }

  .sm\:border-t-danger-400 {
    border-top-color: var(--color-danger-400);
  }

  .sm\:border-t-divider {
    border-top-color: var(--color-divider);
  }

  .sm\:border-t-gray {
    border-top-color: var(--color-gray);
  }

  .sm\:border-t-info {
    border-top-color: var(--color-info-300);
  }

  .sm\:border-t-info-100 {
    border-top-color: var(--color-info-100);
  }

  .sm\:border-t-info-200 {
    border-top-color: var(--color-info-200);
  }

  .sm\:border-t-info-300 {
    border-top-color: var(--color-info-300);
  }

  .sm\:border-t-info-400 {
    border-top-color: var(--color-info-400);
  }

  .sm\:border-t-primary {
    border-top-color: var(--color-primary-300);
  }

  .sm\:border-t-primary-100 {
    border-top-color: var(--color-primary-100);
  }

  .sm\:border-t-primary-200 {
    border-top-color: var(--color-primary-200);
  }

  .sm\:border-t-primary-250 {
    border-top-color: var(--color-primary-250);
  }

  .sm\:border-t-primary-300 {
    border-top-color: var(--color-primary-300);
  }

  .sm\:border-t-primary-400 {
    border-top-color: var(--color-primary-400);
  }

  .sm\:border-t-primary-500 {
    border-top-color: var(--color-primary-500);
  }

  .sm\:border-t-primary-600 {
    border-top-color: var(--color-primary-600);
  }

  .sm\:border-t-success {
    border-top-color: var(--color-success-300);
  }

  .sm\:border-t-success-100 {
    border-top-color: var(--color-success-100);
  }

  .sm\:border-t-success-200 {
    border-top-color: var(--color-success-200);
  }

  .sm\:border-t-success-300 {
    border-top-color: var(--color-success-300);
  }

  .sm\:border-t-success-400 {
    border-top-color: var(--color-success-400);
  }

  .sm\:border-t-transparent, .sm\:border-t-transparent\/0 {
    border-top-color: #0000;
  }

  .sm\:border-t-transparent\/10 {
    border-top-color: #0000001a;
  }

  .sm\:border-t-transparent\/100 {
    border-top-color: #000;
  }

  .sm\:border-t-transparent\/15 {
    border-top-color: #00000026;
  }

  .sm\:border-t-transparent\/20 {
    border-top-color: #0003;
  }

  .sm\:border-t-transparent\/25 {
    border-top-color: #00000040;
  }

  .sm\:border-t-transparent\/30 {
    border-top-color: #0000004d;
  }

  .sm\:border-t-transparent\/35 {
    border-top-color: #00000059;
  }

  .sm\:border-t-transparent\/40 {
    border-top-color: #0006;
  }

  .sm\:border-t-transparent\/45 {
    border-top-color: #00000073;
  }

  .sm\:border-t-transparent\/5 {
    border-top-color: #0000000d;
  }

  .sm\:border-t-transparent\/50 {
    border-top-color: #00000080;
  }

  .sm\:border-t-transparent\/55 {
    border-top-color: #0000008c;
  }

  .sm\:border-t-transparent\/60 {
    border-top-color: #0009;
  }

  .sm\:border-t-transparent\/65 {
    border-top-color: #000000a6;
  }

  .sm\:border-t-transparent\/70 {
    border-top-color: #000000b3;
  }

  .sm\:border-t-transparent\/75 {
    border-top-color: #000000bf;
  }

  .sm\:border-t-transparent\/80 {
    border-top-color: #000c;
  }

  .sm\:border-t-transparent\/85 {
    border-top-color: #000000d9;
  }

  .sm\:border-t-transparent\/90 {
    border-top-color: #000000e6;
  }

  .sm\:border-t-transparent\/95 {
    border-top-color: #000000f2;
  }

  .sm\:border-t-warning {
    border-top-color: var(--color-warning-300);
  }

  .sm\:border-t-warning-100 {
    border-top-color: var(--color-warning-100);
  }

  .sm\:border-t-warning-200 {
    border-top-color: var(--color-warning-200);
  }

  .sm\:border-t-warning-300 {
    border-top-color: var(--color-warning-300);
  }

  .sm\:border-t-warning-400 {
    border-top-color: var(--color-warning-400);
  }

  .sm\:border-t-white {
    border-top-color: var(--color-white);
  }

  .sm\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .sm\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .sm\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .sm\:border-opacity-15 {
    --tw-border-opacity: .15;
  }

  .sm\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .sm\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .sm\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .sm\:border-opacity-35 {
    --tw-border-opacity: .35;
  }

  .sm\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .sm\:border-opacity-45 {
    --tw-border-opacity: .45;
  }

  .sm\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .sm\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .sm\:border-opacity-55 {
    --tw-border-opacity: .55;
  }

  .sm\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .sm\:border-opacity-65 {
    --tw-border-opacity: .65;
  }

  .sm\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .sm\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .sm\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .sm\:border-opacity-85 {
    --tw-border-opacity: .85;
  }

  .sm\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .sm\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .sm\:bg-black {
    background-color: var(--color-black);
  }

  .sm\:bg-current {
    background-color: currentColor;
  }

  .sm\:bg-danger {
    background-color: var(--color-danger-300);
  }

  .sm\:bg-danger-100 {
    background-color: var(--color-danger-100);
  }

  .sm\:bg-danger-200 {
    background-color: var(--color-danger-200);
  }

  .sm\:bg-danger-300 {
    background-color: var(--color-danger-300);
  }

  .sm\:bg-danger-400 {
    background-color: var(--color-danger-400);
  }

  .sm\:bg-divider {
    background-color: var(--color-divider);
  }

  .sm\:bg-gray {
    background-color: var(--color-gray);
  }

  .sm\:bg-info {
    background-color: var(--color-info-300);
  }

  .sm\:bg-info-100 {
    background-color: var(--color-info-100);
  }

  .sm\:bg-info-200 {
    background-color: var(--color-info-200);
  }

  .sm\:bg-info-300 {
    background-color: var(--color-info-300);
  }

  .sm\:bg-info-400 {
    background-color: var(--color-info-400);
  }

  .sm\:bg-primary {
    background-color: var(--color-primary-300);
  }

  .sm\:bg-primary-100 {
    background-color: var(--color-primary-100);
  }

  .sm\:bg-primary-200 {
    background-color: var(--color-primary-200);
  }

  .sm\:bg-primary-250 {
    background-color: var(--color-primary-250);
  }

  .sm\:bg-primary-300 {
    background-color: var(--color-primary-300);
  }

  .sm\:bg-primary-400 {
    background-color: var(--color-primary-400);
  }

  .sm\:bg-primary-500 {
    background-color: var(--color-primary-500);
  }

  .sm\:bg-primary-600 {
    background-color: var(--color-primary-600);
  }

  .sm\:bg-success {
    background-color: var(--color-success-300);
  }

  .sm\:bg-success-100 {
    background-color: var(--color-success-100);
  }

  .sm\:bg-success-200 {
    background-color: var(--color-success-200);
  }

  .sm\:bg-success-300 {
    background-color: var(--color-success-300);
  }

  .sm\:bg-success-400 {
    background-color: var(--color-success-400);
  }

  .sm\:bg-transparent, .sm\:bg-transparent\/0 {
    background-color: #0000;
  }

  .sm\:bg-transparent\/10 {
    background-color: #0000001a;
  }

  .sm\:bg-transparent\/100 {
    background-color: #000;
  }

  .sm\:bg-transparent\/15 {
    background-color: #00000026;
  }

  .sm\:bg-transparent\/20 {
    background-color: #0003;
  }

  .sm\:bg-transparent\/25 {
    background-color: #00000040;
  }

  .sm\:bg-transparent\/30 {
    background-color: #0000004d;
  }

  .sm\:bg-transparent\/35 {
    background-color: #00000059;
  }

  .sm\:bg-transparent\/40 {
    background-color: #0006;
  }

  .sm\:bg-transparent\/45 {
    background-color: #00000073;
  }

  .sm\:bg-transparent\/5 {
    background-color: #0000000d;
  }

  .sm\:bg-transparent\/50 {
    background-color: #00000080;
  }

  .sm\:bg-transparent\/55 {
    background-color: #0000008c;
  }

  .sm\:bg-transparent\/60 {
    background-color: #0009;
  }

  .sm\:bg-transparent\/65 {
    background-color: #000000a6;
  }

  .sm\:bg-transparent\/70 {
    background-color: #000000b3;
  }

  .sm\:bg-transparent\/75 {
    background-color: #000000bf;
  }

  .sm\:bg-transparent\/80 {
    background-color: #000c;
  }

  .sm\:bg-transparent\/85 {
    background-color: #000000d9;
  }

  .sm\:bg-transparent\/90 {
    background-color: #000000e6;
  }

  .sm\:bg-transparent\/95 {
    background-color: #000000f2;
  }

  .sm\:bg-warning {
    background-color: var(--color-warning-300);
  }

  .sm\:bg-warning-100 {
    background-color: var(--color-warning-100);
  }

  .sm\:bg-warning-200 {
    background-color: var(--color-warning-200);
  }

  .sm\:bg-warning-300 {
    background-color: var(--color-warning-300);
  }

  .sm\:bg-warning-400 {
    background-color: var(--color-warning-400);
  }

  .sm\:bg-white {
    background-color: var(--color-white);
  }

  .sm\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .sm\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .sm\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .sm\:bg-opacity-15 {
    --tw-bg-opacity: .15;
  }

  .sm\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .sm\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .sm\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .sm\:bg-opacity-35 {
    --tw-bg-opacity: .35;
  }

  .sm\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .sm\:bg-opacity-45 {
    --tw-bg-opacity: .45;
  }

  .sm\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .sm\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .sm\:bg-opacity-55 {
    --tw-bg-opacity: .55;
  }

  .sm\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .sm\:bg-opacity-65 {
    --tw-bg-opacity: .65;
  }

  .sm\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .sm\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .sm\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .sm\:bg-opacity-85 {
    --tw-bg-opacity: .85;
  }

  .sm\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .sm\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .sm\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .sm\:bg-none {
    background-image: none;
  }

  .sm\:from-black {
    --tw-gradient-from: var(--color-black) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-current {
    --tw-gradient-from: currentColor var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-danger {
    --tw-gradient-from: var(--color-danger-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-danger-100 {
    --tw-gradient-from: var(--color-danger-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-danger-200 {
    --tw-gradient-from: var(--color-danger-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-danger-300 {
    --tw-gradient-from: var(--color-danger-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-danger-400 {
    --tw-gradient-from: var(--color-danger-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-divider {
    --tw-gradient-from: var(--color-divider) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-gray {
    --tw-gradient-from: var(--color-gray) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-info {
    --tw-gradient-from: var(--color-info-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-info-100 {
    --tw-gradient-from: var(--color-info-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-info-200 {
    --tw-gradient-from: var(--color-info-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-info-300 {
    --tw-gradient-from: var(--color-info-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-info-400 {
    --tw-gradient-from: var(--color-info-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-primary {
    --tw-gradient-from: var(--color-primary-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-primary-100 {
    --tw-gradient-from: var(--color-primary-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-primary-200 {
    --tw-gradient-from: var(--color-primary-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-primary-250 {
    --tw-gradient-from: var(--color-primary-250) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-primary-300 {
    --tw-gradient-from: var(--color-primary-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-primary-400 {
    --tw-gradient-from: var(--color-primary-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-primary-500 {
    --tw-gradient-from: var(--color-primary-500) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-primary-600 {
    --tw-gradient-from: var(--color-primary-600) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-success {
    --tw-gradient-from: var(--color-success-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-success-100 {
    --tw-gradient-from: var(--color-success-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-success-200 {
    --tw-gradient-from: var(--color-success-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-success-300 {
    --tw-gradient-from: var(--color-success-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-success-400 {
    --tw-gradient-from: var(--color-success-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent {
    --tw-gradient-from: transparent var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/0 {
    --tw-gradient-from: #0000 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/10 {
    --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/100 {
    --tw-gradient-from: #000 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/15 {
    --tw-gradient-from: #00000026 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/20 {
    --tw-gradient-from: #0003 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/25 {
    --tw-gradient-from: #00000040 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/30 {
    --tw-gradient-from: #0000004d var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/35 {
    --tw-gradient-from: #00000059 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/40 {
    --tw-gradient-from: #0006 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/45 {
    --tw-gradient-from: #00000073 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/5 {
    --tw-gradient-from: #0000000d var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/50 {
    --tw-gradient-from: #00000080 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/55 {
    --tw-gradient-from: #0000008c var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/60 {
    --tw-gradient-from: #0009 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/65 {
    --tw-gradient-from: #000000a6 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/70 {
    --tw-gradient-from: #000000b3 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/75 {
    --tw-gradient-from: #000000bf var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/80 {
    --tw-gradient-from: #000c var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/85 {
    --tw-gradient-from: #000000d9 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/90 {
    --tw-gradient-from: #000000e6 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-transparent\/95 {
    --tw-gradient-from: #000000f2 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-warning {
    --tw-gradient-from: var(--color-warning-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-warning-100 {
    --tw-gradient-from: var(--color-warning-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-warning-200 {
    --tw-gradient-from: var(--color-warning-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-warning-300 {
    --tw-gradient-from: var(--color-warning-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-warning-400 {
    --tw-gradient-from: var(--color-warning-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-white {
    --tw-gradient-from: var(--color-white) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:from-0\% {
    --tw-gradient-from-position: 0%;
  }

  .sm\:from-10\% {
    --tw-gradient-from-position: 10%;
  }

  .sm\:from-100\% {
    --tw-gradient-from-position: 100%;
  }

  .sm\:from-15\% {
    --tw-gradient-from-position: 15%;
  }

  .sm\:from-20\% {
    --tw-gradient-from-position: 20%;
  }

  .sm\:from-25\% {
    --tw-gradient-from-position: 25%;
  }

  .sm\:from-30\% {
    --tw-gradient-from-position: 30%;
  }

  .sm\:from-35\% {
    --tw-gradient-from-position: 35%;
  }

  .sm\:from-40\% {
    --tw-gradient-from-position: 40%;
  }

  .sm\:from-45\% {
    --tw-gradient-from-position: 45%;
  }

  .sm\:from-5\% {
    --tw-gradient-from-position: 5%;
  }

  .sm\:from-50\% {
    --tw-gradient-from-position: 50%;
  }

  .sm\:from-55\% {
    --tw-gradient-from-position: 55%;
  }

  .sm\:from-60\% {
    --tw-gradient-from-position: 60%;
  }

  .sm\:from-65\% {
    --tw-gradient-from-position: 65%;
  }

  .sm\:from-70\% {
    --tw-gradient-from-position: 70%;
  }

  .sm\:from-75\% {
    --tw-gradient-from-position: 75%;
  }

  .sm\:from-80\% {
    --tw-gradient-from-position: 80%;
  }

  .sm\:from-85\% {
    --tw-gradient-from-position: 85%;
  }

  .sm\:from-90\% {
    --tw-gradient-from-position: 90%;
  }

  .sm\:from-95\% {
    --tw-gradient-from-position: 95%;
  }

  .sm\:via-black {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-black) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-current {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), currentColor var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-danger {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-danger-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-danger-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-danger-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-danger-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-divider {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-divider) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-gray {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-gray) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-info {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-info-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-info-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-info-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-info-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-primary {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-primary-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-primary-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-primary-250 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-250) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-primary-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-primary-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-primary-500 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-500) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-primary-600 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-600) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-success {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-success-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-success-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-success-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-success-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/0 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/10 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000001a var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/100 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/15 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000026 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/20 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0003 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/25 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000040 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/30 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000004d var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/35 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000059 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/40 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0006 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/45 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000073 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/5 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000000d var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/50 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000080 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/55 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000008c var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/60 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0009 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/65 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000a6 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/70 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/75 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000bf var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/80 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000c var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/85 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000d9 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/90 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-transparent\/95 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-warning {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-warning-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-warning-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-warning-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-warning-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-white {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-white) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .sm\:via-0\% {
    --tw-gradient-via-position: 0%;
  }

  .sm\:via-10\% {
    --tw-gradient-via-position: 10%;
  }

  .sm\:via-100\% {
    --tw-gradient-via-position: 100%;
  }

  .sm\:via-15\% {
    --tw-gradient-via-position: 15%;
  }

  .sm\:via-20\% {
    --tw-gradient-via-position: 20%;
  }

  .sm\:via-25\% {
    --tw-gradient-via-position: 25%;
  }

  .sm\:via-30\% {
    --tw-gradient-via-position: 30%;
  }

  .sm\:via-35\% {
    --tw-gradient-via-position: 35%;
  }

  .sm\:via-40\% {
    --tw-gradient-via-position: 40%;
  }

  .sm\:via-45\% {
    --tw-gradient-via-position: 45%;
  }

  .sm\:via-5\% {
    --tw-gradient-via-position: 5%;
  }

  .sm\:via-50\% {
    --tw-gradient-via-position: 50%;
  }

  .sm\:via-55\% {
    --tw-gradient-via-position: 55%;
  }

  .sm\:via-60\% {
    --tw-gradient-via-position: 60%;
  }

  .sm\:via-65\% {
    --tw-gradient-via-position: 65%;
  }

  .sm\:via-70\% {
    --tw-gradient-via-position: 70%;
  }

  .sm\:via-75\% {
    --tw-gradient-via-position: 75%;
  }

  .sm\:via-80\% {
    --tw-gradient-via-position: 80%;
  }

  .sm\:via-85\% {
    --tw-gradient-via-position: 85%;
  }

  .sm\:via-90\% {
    --tw-gradient-via-position: 90%;
  }

  .sm\:via-95\% {
    --tw-gradient-via-position: 95%;
  }

  .sm\:to-black {
    --tw-gradient-to: var(--color-black) var(--tw-gradient-to-position);
  }

  .sm\:to-current {
    --tw-gradient-to: currentColor var(--tw-gradient-to-position);
  }

  .sm\:to-danger {
    --tw-gradient-to: var(--color-danger-300) var(--tw-gradient-to-position);
  }

  .sm\:to-danger-100 {
    --tw-gradient-to: var(--color-danger-100) var(--tw-gradient-to-position);
  }

  .sm\:to-danger-200 {
    --tw-gradient-to: var(--color-danger-200) var(--tw-gradient-to-position);
  }

  .sm\:to-danger-300 {
    --tw-gradient-to: var(--color-danger-300) var(--tw-gradient-to-position);
  }

  .sm\:to-danger-400 {
    --tw-gradient-to: var(--color-danger-400) var(--tw-gradient-to-position);
  }

  .sm\:to-divider {
    --tw-gradient-to: var(--color-divider) var(--tw-gradient-to-position);
  }

  .sm\:to-gray {
    --tw-gradient-to: var(--color-gray) var(--tw-gradient-to-position);
  }

  .sm\:to-info {
    --tw-gradient-to: var(--color-info-300) var(--tw-gradient-to-position);
  }

  .sm\:to-info-100 {
    --tw-gradient-to: var(--color-info-100) var(--tw-gradient-to-position);
  }

  .sm\:to-info-200 {
    --tw-gradient-to: var(--color-info-200) var(--tw-gradient-to-position);
  }

  .sm\:to-info-300 {
    --tw-gradient-to: var(--color-info-300) var(--tw-gradient-to-position);
  }

  .sm\:to-info-400 {
    --tw-gradient-to: var(--color-info-400) var(--tw-gradient-to-position);
  }

  .sm\:to-primary {
    --tw-gradient-to: var(--color-primary-300) var(--tw-gradient-to-position);
  }

  .sm\:to-primary-100 {
    --tw-gradient-to: var(--color-primary-100) var(--tw-gradient-to-position);
  }

  .sm\:to-primary-200 {
    --tw-gradient-to: var(--color-primary-200) var(--tw-gradient-to-position);
  }

  .sm\:to-primary-250 {
    --tw-gradient-to: var(--color-primary-250) var(--tw-gradient-to-position);
  }

  .sm\:to-primary-300 {
    --tw-gradient-to: var(--color-primary-300) var(--tw-gradient-to-position);
  }

  .sm\:to-primary-400 {
    --tw-gradient-to: var(--color-primary-400) var(--tw-gradient-to-position);
  }

  .sm\:to-primary-500 {
    --tw-gradient-to: var(--color-primary-500) var(--tw-gradient-to-position);
  }

  .sm\:to-primary-600 {
    --tw-gradient-to: var(--color-primary-600) var(--tw-gradient-to-position);
  }

  .sm\:to-success {
    --tw-gradient-to: var(--color-success-300) var(--tw-gradient-to-position);
  }

  .sm\:to-success-100 {
    --tw-gradient-to: var(--color-success-100) var(--tw-gradient-to-position);
  }

  .sm\:to-success-200 {
    --tw-gradient-to: var(--color-success-200) var(--tw-gradient-to-position);
  }

  .sm\:to-success-300 {
    --tw-gradient-to: var(--color-success-300) var(--tw-gradient-to-position);
  }

  .sm\:to-success-400 {
    --tw-gradient-to: var(--color-success-400) var(--tw-gradient-to-position);
  }

  .sm\:to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/0 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/10 {
    --tw-gradient-to: #0000001a var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/100 {
    --tw-gradient-to: #000 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/15 {
    --tw-gradient-to: #00000026 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/20 {
    --tw-gradient-to: #0003 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/25 {
    --tw-gradient-to: #00000040 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/30 {
    --tw-gradient-to: #0000004d var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/35 {
    --tw-gradient-to: #00000059 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/40 {
    --tw-gradient-to: #0006 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/45 {
    --tw-gradient-to: #00000073 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/5 {
    --tw-gradient-to: #0000000d var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/50 {
    --tw-gradient-to: #00000080 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/55 {
    --tw-gradient-to: #0000008c var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/60 {
    --tw-gradient-to: #0009 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/65 {
    --tw-gradient-to: #000000a6 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/70 {
    --tw-gradient-to: #000000b3 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/75 {
    --tw-gradient-to: #000000bf var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/80 {
    --tw-gradient-to: #000c var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/85 {
    --tw-gradient-to: #000000d9 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/90 {
    --tw-gradient-to: #000000e6 var(--tw-gradient-to-position);
  }

  .sm\:to-transparent\/95 {
    --tw-gradient-to: #000000f2 var(--tw-gradient-to-position);
  }

  .sm\:to-warning {
    --tw-gradient-to: var(--color-warning-300) var(--tw-gradient-to-position);
  }

  .sm\:to-warning-100 {
    --tw-gradient-to: var(--color-warning-100) var(--tw-gradient-to-position);
  }

  .sm\:to-warning-200 {
    --tw-gradient-to: var(--color-warning-200) var(--tw-gradient-to-position);
  }

  .sm\:to-warning-300 {
    --tw-gradient-to: var(--color-warning-300) var(--tw-gradient-to-position);
  }

  .sm\:to-warning-400 {
    --tw-gradient-to: var(--color-warning-400) var(--tw-gradient-to-position);
  }

  .sm\:to-white {
    --tw-gradient-to: var(--color-white) var(--tw-gradient-to-position);
  }

  .sm\:to-0\% {
    --tw-gradient-to-position: 0%;
  }

  .sm\:to-10\% {
    --tw-gradient-to-position: 10%;
  }

  .sm\:to-100\% {
    --tw-gradient-to-position: 100%;
  }

  .sm\:to-15\% {
    --tw-gradient-to-position: 15%;
  }

  .sm\:to-20\% {
    --tw-gradient-to-position: 20%;
  }

  .sm\:to-25\% {
    --tw-gradient-to-position: 25%;
  }

  .sm\:to-30\% {
    --tw-gradient-to-position: 30%;
  }

  .sm\:to-35\% {
    --tw-gradient-to-position: 35%;
  }

  .sm\:to-40\% {
    --tw-gradient-to-position: 40%;
  }

  .sm\:to-45\% {
    --tw-gradient-to-position: 45%;
  }

  .sm\:to-5\% {
    --tw-gradient-to-position: 5%;
  }

  .sm\:to-50\% {
    --tw-gradient-to-position: 50%;
  }

  .sm\:to-55\% {
    --tw-gradient-to-position: 55%;
  }

  .sm\:to-60\% {
    --tw-gradient-to-position: 60%;
  }

  .sm\:to-65\% {
    --tw-gradient-to-position: 65%;
  }

  .sm\:to-70\% {
    --tw-gradient-to-position: 70%;
  }

  .sm\:to-75\% {
    --tw-gradient-to-position: 75%;
  }

  .sm\:to-80\% {
    --tw-gradient-to-position: 80%;
  }

  .sm\:to-85\% {
    --tw-gradient-to-position: 85%;
  }

  .sm\:to-90\% {
    --tw-gradient-to-position: 90%;
  }

  .sm\:to-95\% {
    --tw-gradient-to-position: 95%;
  }

  .sm\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .sm\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .sm\:box-decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .sm\:box-decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .sm\:bg-auto {
    background-size: auto;
  }

  .sm\:bg-contain {
    background-size: contain;
  }

  .sm\:bg-cover {
    background-size: cover;
  }

  .sm\:bg-fixed {
    background-attachment: fixed;
  }

  .sm\:bg-local {
    background-attachment: local;
  }

  .sm\:bg-scroll {
    background-attachment: scroll;
  }

  .sm\:bg-clip-border {
    background-clip: border-box;
  }

  .sm\:bg-clip-padding {
    background-clip: padding-box;
  }

  .sm\:bg-clip-content {
    background-clip: content-box;
  }

  .sm\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .sm\:bg-bottom {
    background-position: bottom;
  }

  .sm\:bg-center {
    background-position: center;
  }

  .sm\:bg-left {
    background-position: 0;
  }

  .sm\:bg-left-bottom {
    background-position: 0 100%;
  }

  .sm\:bg-left-top {
    background-position: 0 0;
  }

  .sm\:bg-right {
    background-position: 100%;
  }

  .sm\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .sm\:bg-right-top {
    background-position: 100% 0;
  }

  .sm\:bg-top {
    background-position: top;
  }

  .sm\:bg-repeat {
    background-repeat: repeat;
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .sm\:bg-repeat-round {
    background-repeat: round;
  }

  .sm\:bg-repeat-space {
    background-repeat: space;
  }

  .sm\:bg-origin-border {
    background-origin: border-box;
  }

  .sm\:bg-origin-padding {
    background-origin: padding-box;
  }

  .sm\:bg-origin-content {
    background-origin: content-box;
  }

  .sm\:fill-black {
    fill: var(--color-black);
  }

  .sm\:fill-current {
    fill: currentColor;
  }

  .sm\:fill-danger {
    fill: var(--color-danger-300);
  }

  .sm\:fill-danger-100 {
    fill: var(--color-danger-100);
  }

  .sm\:fill-danger-200 {
    fill: var(--color-danger-200);
  }

  .sm\:fill-danger-300 {
    fill: var(--color-danger-300);
  }

  .sm\:fill-danger-400 {
    fill: var(--color-danger-400);
  }

  .sm\:fill-divider {
    fill: var(--color-divider);
  }

  .sm\:fill-gray {
    fill: var(--color-gray);
  }

  .sm\:fill-info {
    fill: var(--color-info-300);
  }

  .sm\:fill-info-100 {
    fill: var(--color-info-100);
  }

  .sm\:fill-info-200 {
    fill: var(--color-info-200);
  }

  .sm\:fill-info-300 {
    fill: var(--color-info-300);
  }

  .sm\:fill-info-400 {
    fill: var(--color-info-400);
  }

  .sm\:fill-none {
    fill: none;
  }

  .sm\:fill-primary {
    fill: var(--color-primary-300);
  }

  .sm\:fill-primary-100 {
    fill: var(--color-primary-100);
  }

  .sm\:fill-primary-200 {
    fill: var(--color-primary-200);
  }

  .sm\:fill-primary-250 {
    fill: var(--color-primary-250);
  }

  .sm\:fill-primary-300 {
    fill: var(--color-primary-300);
  }

  .sm\:fill-primary-400 {
    fill: var(--color-primary-400);
  }

  .sm\:fill-primary-500 {
    fill: var(--color-primary-500);
  }

  .sm\:fill-primary-600 {
    fill: var(--color-primary-600);
  }

  .sm\:fill-success {
    fill: var(--color-success-300);
  }

  .sm\:fill-success-100 {
    fill: var(--color-success-100);
  }

  .sm\:fill-success-200 {
    fill: var(--color-success-200);
  }

  .sm\:fill-success-300 {
    fill: var(--color-success-300);
  }

  .sm\:fill-success-400 {
    fill: var(--color-success-400);
  }

  .sm\:fill-transparent, .sm\:fill-transparent\/0 {
    fill: #0000;
  }

  .sm\:fill-transparent\/10 {
    fill: #0000001a;
  }

  .sm\:fill-transparent\/100 {
    fill: #000;
  }

  .sm\:fill-transparent\/15 {
    fill: #00000026;
  }

  .sm\:fill-transparent\/20 {
    fill: #0003;
  }

  .sm\:fill-transparent\/25 {
    fill: #00000040;
  }

  .sm\:fill-transparent\/30 {
    fill: #0000004d;
  }

  .sm\:fill-transparent\/35 {
    fill: #00000059;
  }

  .sm\:fill-transparent\/40 {
    fill: #0006;
  }

  .sm\:fill-transparent\/45 {
    fill: #00000073;
  }

  .sm\:fill-transparent\/5 {
    fill: #0000000d;
  }

  .sm\:fill-transparent\/50 {
    fill: #00000080;
  }

  .sm\:fill-transparent\/55 {
    fill: #0000008c;
  }

  .sm\:fill-transparent\/60 {
    fill: #0009;
  }

  .sm\:fill-transparent\/65 {
    fill: #000000a6;
  }

  .sm\:fill-transparent\/70 {
    fill: #000000b3;
  }

  .sm\:fill-transparent\/75 {
    fill: #000000bf;
  }

  .sm\:fill-transparent\/80 {
    fill: #000c;
  }

  .sm\:fill-transparent\/85 {
    fill: #000000d9;
  }

  .sm\:fill-transparent\/90 {
    fill: #000000e6;
  }

  .sm\:fill-transparent\/95 {
    fill: #000000f2;
  }

  .sm\:fill-warning {
    fill: var(--color-warning-300);
  }

  .sm\:fill-warning-100 {
    fill: var(--color-warning-100);
  }

  .sm\:fill-warning-200 {
    fill: var(--color-warning-200);
  }

  .sm\:fill-warning-300 {
    fill: var(--color-warning-300);
  }

  .sm\:fill-warning-400 {
    fill: var(--color-warning-400);
  }

  .sm\:fill-white {
    fill: var(--color-white);
  }

  .sm\:stroke-black {
    stroke: var(--color-black);
  }

  .sm\:stroke-current {
    stroke: currentColor;
  }

  .sm\:stroke-danger {
    stroke: var(--color-danger-300);
  }

  .sm\:stroke-danger-100 {
    stroke: var(--color-danger-100);
  }

  .sm\:stroke-danger-200 {
    stroke: var(--color-danger-200);
  }

  .sm\:stroke-danger-300 {
    stroke: var(--color-danger-300);
  }

  .sm\:stroke-danger-400 {
    stroke: var(--color-danger-400);
  }

  .sm\:stroke-divider {
    stroke: var(--color-divider);
  }

  .sm\:stroke-gray {
    stroke: var(--color-gray);
  }

  .sm\:stroke-info {
    stroke: var(--color-info-300);
  }

  .sm\:stroke-info-100 {
    stroke: var(--color-info-100);
  }

  .sm\:stroke-info-200 {
    stroke: var(--color-info-200);
  }

  .sm\:stroke-info-300 {
    stroke: var(--color-info-300);
  }

  .sm\:stroke-info-400 {
    stroke: var(--color-info-400);
  }

  .sm\:stroke-none {
    stroke: none;
  }

  .sm\:stroke-primary {
    stroke: var(--color-primary-300);
  }

  .sm\:stroke-primary-100 {
    stroke: var(--color-primary-100);
  }

  .sm\:stroke-primary-200 {
    stroke: var(--color-primary-200);
  }

  .sm\:stroke-primary-250 {
    stroke: var(--color-primary-250);
  }

  .sm\:stroke-primary-300 {
    stroke: var(--color-primary-300);
  }

  .sm\:stroke-primary-400 {
    stroke: var(--color-primary-400);
  }

  .sm\:stroke-primary-500 {
    stroke: var(--color-primary-500);
  }

  .sm\:stroke-primary-600 {
    stroke: var(--color-primary-600);
  }

  .sm\:stroke-success {
    stroke: var(--color-success-300);
  }

  .sm\:stroke-success-100 {
    stroke: var(--color-success-100);
  }

  .sm\:stroke-success-200 {
    stroke: var(--color-success-200);
  }

  .sm\:stroke-success-300 {
    stroke: var(--color-success-300);
  }

  .sm\:stroke-success-400 {
    stroke: var(--color-success-400);
  }

  .sm\:stroke-transparent, .sm\:stroke-transparent\/0 {
    stroke: #0000;
  }

  .sm\:stroke-transparent\/10 {
    stroke: #0000001a;
  }

  .sm\:stroke-transparent\/100 {
    stroke: #000;
  }

  .sm\:stroke-transparent\/15 {
    stroke: #00000026;
  }

  .sm\:stroke-transparent\/20 {
    stroke: #0003;
  }

  .sm\:stroke-transparent\/25 {
    stroke: #00000040;
  }

  .sm\:stroke-transparent\/30 {
    stroke: #0000004d;
  }

  .sm\:stroke-transparent\/35 {
    stroke: #00000059;
  }

  .sm\:stroke-transparent\/40 {
    stroke: #0006;
  }

  .sm\:stroke-transparent\/45 {
    stroke: #00000073;
  }

  .sm\:stroke-transparent\/5 {
    stroke: #0000000d;
  }

  .sm\:stroke-transparent\/50 {
    stroke: #00000080;
  }

  .sm\:stroke-transparent\/55 {
    stroke: #0000008c;
  }

  .sm\:stroke-transparent\/60 {
    stroke: #0009;
  }

  .sm\:stroke-transparent\/65 {
    stroke: #000000a6;
  }

  .sm\:stroke-transparent\/70 {
    stroke: #000000b3;
  }

  .sm\:stroke-transparent\/75 {
    stroke: #000000bf;
  }

  .sm\:stroke-transparent\/80 {
    stroke: #000c;
  }

  .sm\:stroke-transparent\/85 {
    stroke: #000000d9;
  }

  .sm\:stroke-transparent\/90 {
    stroke: #000000e6;
  }

  .sm\:stroke-transparent\/95 {
    stroke: #000000f2;
  }

  .sm\:stroke-warning {
    stroke: var(--color-warning-300);
  }

  .sm\:stroke-warning-100 {
    stroke: var(--color-warning-100);
  }

  .sm\:stroke-warning-200 {
    stroke: var(--color-warning-200);
  }

  .sm\:stroke-warning-300 {
    stroke: var(--color-warning-300);
  }

  .sm\:stroke-warning-400 {
    stroke: var(--color-warning-400);
  }

  .sm\:stroke-white {
    stroke: var(--color-white);
  }

  .sm\:stroke-0 {
    stroke-width: 0;
  }

  .sm\:stroke-1 {
    stroke-width: 1px;
  }

  .sm\:stroke-2 {
    stroke-width: 2px;
  }

  .sm\:object-contain {
    object-fit: contain;
  }

  .sm\:object-cover {
    object-fit: cover;
  }

  .sm\:object-fill {
    object-fit: fill;
  }

  .sm\:object-none {
    object-fit: none;
  }

  .sm\:object-scale-down {
    object-fit: scale-down;
  }

  .sm\:object-bottom {
    object-position: bottom;
  }

  .sm\:object-center {
    object-position: center;
  }

  .sm\:object-left {
    object-position: left;
  }

  .sm\:object-left-bottom {
    object-position: left bottom;
  }

  .sm\:object-left-top {
    object-position: left top;
  }

  .sm\:object-right {
    object-position: right;
  }

  .sm\:object-right-bottom {
    object-position: right bottom;
  }

  .sm\:object-right-top {
    object-position: right top;
  }

  .sm\:object-top {
    object-position: top;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-0\.5 {
    padding: .125rem;
  }

  .sm\:p-1 {
    padding: .25rem;
  }

  .sm\:p-1\.5 {
    padding: .375rem;
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }

  .sm\:p-11 {
    padding: 2.75rem;
  }

  .sm\:p-12 {
    padding: 3rem;
  }

  .sm\:p-14 {
    padding: 3.5rem;
  }

  .sm\:p-16 {
    padding: 4rem;
  }

  .sm\:p-2 {
    padding: .5rem;
  }

  .sm\:p-2\.5 {
    padding: .625rem;
  }

  .sm\:p-20 {
    padding: 5rem;
  }

  .sm\:p-24 {
    padding: 6rem;
  }

  .sm\:p-28 {
    padding: 7rem;
  }

  .sm\:p-3 {
    padding: .75rem;
  }

  .sm\:p-3\.5 {
    padding: .875rem;
  }

  .sm\:p-32 {
    padding: 8rem;
  }

  .sm\:p-36 {
    padding: 9rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-40 {
    padding: 10rem;
  }

  .sm\:p-44 {
    padding: 11rem;
  }

  .sm\:p-48 {
    padding: 12rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:p-52 {
    padding: 13rem;
  }

  .sm\:p-56 {
    padding: 14rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:p-60 {
    padding: 15rem;
  }

  .sm\:p-64 {
    padding: 16rem;
  }

  .sm\:p-7 {
    padding: 1.75rem;
  }

  .sm\:p-72 {
    padding: 18rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:p-80 {
    padding: 20rem;
  }

  .sm\:p-9 {
    padding: 2.25rem;
  }

  .sm\:p-96 {
    padding: 24rem;
  }

  .sm\:p-px {
    padding: 1px;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .sm\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .sm\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .sm\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .sm\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .sm\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .sm\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .sm\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .sm\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .sm\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .sm\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .sm\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .sm\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .sm\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .sm\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .sm\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .sm\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .sm\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .sm\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .sm\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .sm\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .sm\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .sm\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .sm\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .sm\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .sm\:pb-1 {
    padding-bottom: .25rem;
  }

  .sm\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .sm\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .sm\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .sm\:pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .sm\:pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:pb-2 {
    padding-bottom: .5rem;
  }

  .sm\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .sm\:pb-20 {
    padding-bottom: 5rem;
  }

  .sm\:pb-24 {
    padding-bottom: 6rem;
  }

  .sm\:pb-28 {
    padding-bottom: 7rem;
  }

  .sm\:pb-3 {
    padding-bottom: .75rem;
  }

  .sm\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .sm\:pb-32 {
    padding-bottom: 8rem;
  }

  .sm\:pb-36 {
    padding-bottom: 9rem;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:pb-40 {
    padding-bottom: 10rem;
  }

  .sm\:pb-44 {
    padding-bottom: 11rem;
  }

  .sm\:pb-48 {
    padding-bottom: 12rem;
  }

  .sm\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .sm\:pb-52 {
    padding-bottom: 13rem;
  }

  .sm\:pb-56 {
    padding-bottom: 14rem;
  }

  .sm\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .sm\:pb-60 {
    padding-bottom: 15rem;
  }

  .sm\:pb-64 {
    padding-bottom: 16rem;
  }

  .sm\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .sm\:pb-72 {
    padding-bottom: 18rem;
  }

  .sm\:pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:pb-80 {
    padding-bottom: 20rem;
  }

  .sm\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .sm\:pb-96 {
    padding-bottom: 24rem;
  }

  .sm\:pb-px {
    padding-bottom: 1px;
  }

  .sm\:pe-0 {
    padding-inline-end: 0;
  }

  .sm\:pe-0\.5 {
    padding-inline-end: .125rem;
  }

  .sm\:pe-1 {
    padding-inline-end: .25rem;
  }

  .sm\:pe-1\.5 {
    padding-inline-end: .375rem;
  }

  .sm\:pe-10 {
    padding-inline-end: 2.5rem;
  }

  .sm\:pe-11 {
    padding-inline-end: 2.75rem;
  }

  .sm\:pe-12 {
    padding-inline-end: 3rem;
  }

  .sm\:pe-14 {
    padding-inline-end: 3.5rem;
  }

  .sm\:pe-16 {
    padding-inline-end: 4rem;
  }

  .sm\:pe-2 {
    padding-inline-end: .5rem;
  }

  .sm\:pe-2\.5 {
    padding-inline-end: .625rem;
  }

  .sm\:pe-20 {
    padding-inline-end: 5rem;
  }

  .sm\:pe-24 {
    padding-inline-end: 6rem;
  }

  .sm\:pe-28 {
    padding-inline-end: 7rem;
  }

  .sm\:pe-3 {
    padding-inline-end: .75rem;
  }

  .sm\:pe-3\.5 {
    padding-inline-end: .875rem;
  }

  .sm\:pe-32 {
    padding-inline-end: 8rem;
  }

  .sm\:pe-36 {
    padding-inline-end: 9rem;
  }

  .sm\:pe-4 {
    padding-inline-end: 1rem;
  }

  .sm\:pe-40 {
    padding-inline-end: 10rem;
  }

  .sm\:pe-44 {
    padding-inline-end: 11rem;
  }

  .sm\:pe-48 {
    padding-inline-end: 12rem;
  }

  .sm\:pe-5 {
    padding-inline-end: 1.25rem;
  }

  .sm\:pe-52 {
    padding-inline-end: 13rem;
  }

  .sm\:pe-56 {
    padding-inline-end: 14rem;
  }

  .sm\:pe-6 {
    padding-inline-end: 1.5rem;
  }

  .sm\:pe-60 {
    padding-inline-end: 15rem;
  }

  .sm\:pe-64 {
    padding-inline-end: 16rem;
  }

  .sm\:pe-7 {
    padding-inline-end: 1.75rem;
  }

  .sm\:pe-72 {
    padding-inline-end: 18rem;
  }

  .sm\:pe-8 {
    padding-inline-end: 2rem;
  }

  .sm\:pe-80 {
    padding-inline-end: 20rem;
  }

  .sm\:pe-9 {
    padding-inline-end: 2.25rem;
  }

  .sm\:pe-96 {
    padding-inline-end: 24rem;
  }

  .sm\:pe-px {
    padding-inline-end: 1px;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:pl-0\.5 {
    padding-left: .125rem;
  }

  .sm\:pl-1 {
    padding-left: .25rem;
  }

  .sm\:pl-1\.5 {
    padding-left: .375rem;
  }

  .sm\:pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:pl-11 {
    padding-left: 2.75rem;
  }

  .sm\:pl-12 {
    padding-left: 3rem;
  }

  .sm\:pl-14 {
    padding-left: 3.5rem;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:pl-2 {
    padding-left: .5rem;
  }

  .sm\:pl-2\.5 {
    padding-left: .625rem;
  }

  .sm\:pl-20 {
    padding-left: 5rem;
  }

  .sm\:pl-24 {
    padding-left: 6rem;
  }

  .sm\:pl-28 {
    padding-left: 7rem;
  }

  .sm\:pl-3 {
    padding-left: .75rem;
  }

  .sm\:pl-3\.5 {
    padding-left: .875rem;
  }

  .sm\:pl-32 {
    padding-left: 8rem;
  }

  .sm\:pl-36 {
    padding-left: 9rem;
  }

  .sm\:pl-4 {
    padding-left: 1rem;
  }

  .sm\:pl-40 {
    padding-left: 10rem;
  }

  .sm\:pl-44 {
    padding-left: 11rem;
  }

  .sm\:pl-48 {
    padding-left: 12rem;
  }

  .sm\:pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:pl-52 {
    padding-left: 13rem;
  }

  .sm\:pl-56 {
    padding-left: 14rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pl-60 {
    padding-left: 15rem;
  }

  .sm\:pl-64 {
    padding-left: 16rem;
  }

  .sm\:pl-7 {
    padding-left: 1.75rem;
  }

  .sm\:pl-72 {
    padding-left: 18rem;
  }

  .sm\:pl-8 {
    padding-left: 2rem;
  }

  .sm\:pl-80 {
    padding-left: 20rem;
  }

  .sm\:pl-9 {
    padding-left: 2.25rem;
  }

  .sm\:pl-96 {
    padding-left: 24rem;
  }

  .sm\:pl-px {
    padding-left: 1px;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:pr-0\.5 {
    padding-right: .125rem;
  }

  .sm\:pr-1 {
    padding-right: .25rem;
  }

  .sm\:pr-1\.5 {
    padding-right: .375rem;
  }

  .sm\:pr-10 {
    padding-right: 2.5rem;
  }

  .sm\:pr-11 {
    padding-right: 2.75rem;
  }

  .sm\:pr-12 {
    padding-right: 3rem;
  }

  .sm\:pr-14 {
    padding-right: 3.5rem;
  }

  .sm\:pr-16 {
    padding-right: 4rem;
  }

  .sm\:pr-2 {
    padding-right: .5rem;
  }

  .sm\:pr-2\.5 {
    padding-right: .625rem;
  }

  .sm\:pr-20 {
    padding-right: 5rem;
  }

  .sm\:pr-24 {
    padding-right: 6rem;
  }

  .sm\:pr-28 {
    padding-right: 7rem;
  }

  .sm\:pr-3 {
    padding-right: .75rem;
  }

  .sm\:pr-3\.5 {
    padding-right: .875rem;
  }

  .sm\:pr-32 {
    padding-right: 8rem;
  }

  .sm\:pr-36 {
    padding-right: 9rem;
  }

  .sm\:pr-4 {
    padding-right: 1rem;
  }

  .sm\:pr-40 {
    padding-right: 10rem;
  }

  .sm\:pr-44 {
    padding-right: 11rem;
  }

  .sm\:pr-48 {
    padding-right: 12rem;
  }

  .sm\:pr-5 {
    padding-right: 1.25rem;
  }

  .sm\:pr-52 {
    padding-right: 13rem;
  }

  .sm\:pr-56 {
    padding-right: 14rem;
  }

  .sm\:pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:pr-60 {
    padding-right: 15rem;
  }

  .sm\:pr-64 {
    padding-right: 16rem;
  }

  .sm\:pr-7 {
    padding-right: 1.75rem;
  }

  .sm\:pr-72 {
    padding-right: 18rem;
  }

  .sm\:pr-8 {
    padding-right: 2rem;
  }

  .sm\:pr-80 {
    padding-right: 20rem;
  }

  .sm\:pr-9 {
    padding-right: 2.25rem;
  }

  .sm\:pr-96 {
    padding-right: 24rem;
  }

  .sm\:pr-px {
    padding-right: 1px;
  }

  .sm\:ps-0 {
    padding-inline-start: 0;
  }

  .sm\:ps-0\.5 {
    padding-inline-start: .125rem;
  }

  .sm\:ps-1 {
    padding-inline-start: .25rem;
  }

  .sm\:ps-1\.5 {
    padding-inline-start: .375rem;
  }

  .sm\:ps-10 {
    padding-inline-start: 2.5rem;
  }

  .sm\:ps-11 {
    padding-inline-start: 2.75rem;
  }

  .sm\:ps-12 {
    padding-inline-start: 3rem;
  }

  .sm\:ps-14 {
    padding-inline-start: 3.5rem;
  }

  .sm\:ps-16 {
    padding-inline-start: 4rem;
  }

  .sm\:ps-2 {
    padding-inline-start: .5rem;
  }

  .sm\:ps-2\.5 {
    padding-inline-start: .625rem;
  }

  .sm\:ps-20 {
    padding-inline-start: 5rem;
  }

  .sm\:ps-24 {
    padding-inline-start: 6rem;
  }

  .sm\:ps-28 {
    padding-inline-start: 7rem;
  }

  .sm\:ps-3 {
    padding-inline-start: .75rem;
  }

  .sm\:ps-3\.5 {
    padding-inline-start: .875rem;
  }

  .sm\:ps-32 {
    padding-inline-start: 8rem;
  }

  .sm\:ps-36 {
    padding-inline-start: 9rem;
  }

  .sm\:ps-4 {
    padding-inline-start: 1rem;
  }

  .sm\:ps-40 {
    padding-inline-start: 10rem;
  }

  .sm\:ps-44 {
    padding-inline-start: 11rem;
  }

  .sm\:ps-48 {
    padding-inline-start: 12rem;
  }

  .sm\:ps-5 {
    padding-inline-start: 1.25rem;
  }

  .sm\:ps-52 {
    padding-inline-start: 13rem;
  }

  .sm\:ps-56 {
    padding-inline-start: 14rem;
  }

  .sm\:ps-6 {
    padding-inline-start: 1.5rem;
  }

  .sm\:ps-60 {
    padding-inline-start: 15rem;
  }

  .sm\:ps-64 {
    padding-inline-start: 16rem;
  }

  .sm\:ps-7 {
    padding-inline-start: 1.75rem;
  }

  .sm\:ps-72 {
    padding-inline-start: 18rem;
  }

  .sm\:ps-8 {
    padding-inline-start: 2rem;
  }

  .sm\:ps-80 {
    padding-inline-start: 20rem;
  }

  .sm\:ps-9 {
    padding-inline-start: 2.25rem;
  }

  .sm\:ps-96 {
    padding-inline-start: 24rem;
  }

  .sm\:ps-px {
    padding-inline-start: 1px;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:pt-0\.5 {
    padding-top: .125rem;
  }

  .sm\:pt-1 {
    padding-top: .25rem;
  }

  .sm\:pt-1\.5 {
    padding-top: .375rem;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:pt-11 {
    padding-top: 2.75rem;
  }

  .sm\:pt-12 {
    padding-top: 3rem;
  }

  .sm\:pt-14 {
    padding-top: 3.5rem;
  }

  .sm\:pt-16 {
    padding-top: 4rem;
  }

  .sm\:pt-2 {
    padding-top: .5rem;
  }

  .sm\:pt-2\.5 {
    padding-top: .625rem;
  }

  .sm\:pt-20 {
    padding-top: 5rem;
  }

  .sm\:pt-24 {
    padding-top: 6rem;
  }

  .sm\:pt-28 {
    padding-top: 7rem;
  }

  .sm\:pt-3 {
    padding-top: .75rem;
  }

  .sm\:pt-3\.5 {
    padding-top: .875rem;
  }

  .sm\:pt-32 {
    padding-top: 8rem;
  }

  .sm\:pt-36 {
    padding-top: 9rem;
  }

  .sm\:pt-4 {
    padding-top: 1rem;
  }

  .sm\:pt-40 {
    padding-top: 10rem;
  }

  .sm\:pt-44 {
    padding-top: 11rem;
  }

  .sm\:pt-48 {
    padding-top: 12rem;
  }

  .sm\:pt-5 {
    padding-top: 1.25rem;
  }

  .sm\:pt-52 {
    padding-top: 13rem;
  }

  .sm\:pt-56 {
    padding-top: 14rem;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:pt-60 {
    padding-top: 15rem;
  }

  .sm\:pt-64 {
    padding-top: 16rem;
  }

  .sm\:pt-7 {
    padding-top: 1.75rem;
  }

  .sm\:pt-72 {
    padding-top: 18rem;
  }

  .sm\:pt-8 {
    padding-top: 2rem;
  }

  .sm\:pt-80 {
    padding-top: 20rem;
  }

  .sm\:pt-9 {
    padding-top: 2.25rem;
  }

  .sm\:pt-96 {
    padding-top: 24rem;
  }

  .sm\:pt-px {
    padding-top: 1px;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-justify {
    text-align: justify;
  }

  .sm\:text-start {
    text-align: start;
  }

  .sm\:text-end {
    text-align: end;
  }

  .sm\:-indent-0 {
    text-indent: 0;
  }

  .sm\:-indent-0\.5 {
    text-indent: -.125rem;
  }

  .sm\:-indent-1 {
    text-indent: -.25rem;
  }

  .sm\:-indent-1\.5 {
    text-indent: -.375rem;
  }

  .sm\:-indent-10 {
    text-indent: -2.5rem;
  }

  .sm\:-indent-11 {
    text-indent: -2.75rem;
  }

  .sm\:-indent-12 {
    text-indent: -3rem;
  }

  .sm\:-indent-14 {
    text-indent: -3.5rem;
  }

  .sm\:-indent-16 {
    text-indent: -4rem;
  }

  .sm\:-indent-2 {
    text-indent: -.5rem;
  }

  .sm\:-indent-2\.5 {
    text-indent: -.625rem;
  }

  .sm\:-indent-20 {
    text-indent: -5rem;
  }

  .sm\:-indent-24 {
    text-indent: -6rem;
  }

  .sm\:-indent-28 {
    text-indent: -7rem;
  }

  .sm\:-indent-3 {
    text-indent: -.75rem;
  }

  .sm\:-indent-3\.5 {
    text-indent: -.875rem;
  }

  .sm\:-indent-32 {
    text-indent: -8rem;
  }

  .sm\:-indent-36 {
    text-indent: -9rem;
  }

  .sm\:-indent-4 {
    text-indent: -1rem;
  }

  .sm\:-indent-40 {
    text-indent: -10rem;
  }

  .sm\:-indent-44 {
    text-indent: -11rem;
  }

  .sm\:-indent-48 {
    text-indent: -12rem;
  }

  .sm\:-indent-5 {
    text-indent: -1.25rem;
  }

  .sm\:-indent-52 {
    text-indent: -13rem;
  }

  .sm\:-indent-56 {
    text-indent: -14rem;
  }

  .sm\:-indent-6 {
    text-indent: -1.5rem;
  }

  .sm\:-indent-60 {
    text-indent: -15rem;
  }

  .sm\:-indent-64 {
    text-indent: -16rem;
  }

  .sm\:-indent-7 {
    text-indent: -1.75rem;
  }

  .sm\:-indent-72 {
    text-indent: -18rem;
  }

  .sm\:-indent-8 {
    text-indent: -2rem;
  }

  .sm\:-indent-80 {
    text-indent: -20rem;
  }

  .sm\:-indent-9 {
    text-indent: -2.25rem;
  }

  .sm\:-indent-96 {
    text-indent: -24rem;
  }

  .sm\:-indent-px {
    text-indent: -1px;
  }

  .sm\:indent-0 {
    text-indent: 0;
  }

  .sm\:indent-0\.5 {
    text-indent: .125rem;
  }

  .sm\:indent-1 {
    text-indent: .25rem;
  }

  .sm\:indent-1\.5 {
    text-indent: .375rem;
  }

  .sm\:indent-10 {
    text-indent: 2.5rem;
  }

  .sm\:indent-11 {
    text-indent: 2.75rem;
  }

  .sm\:indent-12 {
    text-indent: 3rem;
  }

  .sm\:indent-14 {
    text-indent: 3.5rem;
  }

  .sm\:indent-16 {
    text-indent: 4rem;
  }

  .sm\:indent-2 {
    text-indent: .5rem;
  }

  .sm\:indent-2\.5 {
    text-indent: .625rem;
  }

  .sm\:indent-20 {
    text-indent: 5rem;
  }

  .sm\:indent-24 {
    text-indent: 6rem;
  }

  .sm\:indent-28 {
    text-indent: 7rem;
  }

  .sm\:indent-3 {
    text-indent: .75rem;
  }

  .sm\:indent-3\.5 {
    text-indent: .875rem;
  }

  .sm\:indent-32 {
    text-indent: 8rem;
  }

  .sm\:indent-36 {
    text-indent: 9rem;
  }

  .sm\:indent-4 {
    text-indent: 1rem;
  }

  .sm\:indent-40 {
    text-indent: 10rem;
  }

  .sm\:indent-44 {
    text-indent: 11rem;
  }

  .sm\:indent-48 {
    text-indent: 12rem;
  }

  .sm\:indent-5 {
    text-indent: 1.25rem;
  }

  .sm\:indent-52 {
    text-indent: 13rem;
  }

  .sm\:indent-56 {
    text-indent: 14rem;
  }

  .sm\:indent-6 {
    text-indent: 1.5rem;
  }

  .sm\:indent-60 {
    text-indent: 15rem;
  }

  .sm\:indent-64 {
    text-indent: 16rem;
  }

  .sm\:indent-7 {
    text-indent: 1.75rem;
  }

  .sm\:indent-72 {
    text-indent: 18rem;
  }

  .sm\:indent-8 {
    text-indent: 2rem;
  }

  .sm\:indent-80 {
    text-indent: 20rem;
  }

  .sm\:indent-9 {
    text-indent: 2.25rem;
  }

  .sm\:indent-96 {
    text-indent: 24rem;
  }

  .sm\:indent-px {
    text-indent: 1px;
  }

  .sm\:align-baseline {
    vertical-align: baseline;
  }

  .sm\:align-top {
    vertical-align: top;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:align-bottom {
    vertical-align: bottom;
  }

  .sm\:align-text-top {
    vertical-align: text-top;
  }

  .sm\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .sm\:align-sub {
    vertical-align: sub;
  }

  .sm\:align-super {
    vertical-align: super;
  }

  .sm\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .sm\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .sm\:font-serif {
    font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
  }

  .sm\:text-6xl {
    font-size: 4rem;
  }

  .sm\:text-base {
    font-size: 1rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
  }

  .sm\:text-xs {
    font-size: .75rem;
  }

  .sm\:font-black {
    font-weight: 900;
  }

  .sm\:font-bold {
    font-weight: 700;
  }

  .sm\:font-extrabold {
    font-weight: 800;
  }

  .sm\:font-extralight {
    font-weight: 200;
  }

  .sm\:font-light {
    font-weight: 300;
  }

  .sm\:font-medium {
    font-weight: 500;
  }

  .sm\:font-normal {
    font-weight: 400;
  }

  .sm\:font-semibold {
    font-weight: 600;
  }

  .sm\:font-thin {
    font-weight: 100;
  }

  .sm\:uppercase {
    text-transform: uppercase;
  }

  .sm\:lowercase {
    text-transform: lowercase;
  }

  .sm\:capitalize {
    text-transform: capitalize;
  }

  .sm\:normal-case {
    text-transform: none;
  }

  .sm\:italic {
    font-style: italic;
  }

  .sm\:not-italic {
    font-style: normal;
  }

  .sm\:normal-nums {
    font-variant-numeric: normal;
  }

  .sm\:ordinal {
    --tw-ordinal: ordinal;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:lining-nums {
    --tw-numeric-figure: lining-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:leading-10 {
    line-height: 2.5rem;
  }

  .sm\:leading-3 {
    line-height: .75rem;
  }

  .sm\:leading-4 {
    line-height: 1rem;
  }

  .sm\:leading-5 {
    line-height: 1.25rem;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }

  .sm\:leading-7 {
    line-height: 1.75rem;
  }

  .sm\:leading-8 {
    line-height: 2rem;
  }

  .sm\:leading-9 {
    line-height: 2.25rem;
  }

  .sm\:leading-loose {
    line-height: 2;
  }

  .sm\:leading-none {
    line-height: 1;
  }

  .sm\:leading-normal {
    line-height: 1.5;
  }

  .sm\:leading-relaxed {
    line-height: 1.625;
  }

  .sm\:leading-snug {
    line-height: 1.375;
  }

  .sm\:leading-tight {
    line-height: 1.25;
  }

  .sm\:-tracking-normal {
    letter-spacing: 0;
  }

  .sm\:-tracking-tight {
    letter-spacing: .025em;
  }

  .sm\:-tracking-tighter {
    letter-spacing: .05em;
  }

  .sm\:-tracking-wide {
    letter-spacing: -.025em;
  }

  .sm\:-tracking-wider {
    letter-spacing: -.05em;
  }

  .sm\:-tracking-widest {
    letter-spacing: -.1em;
  }

  .sm\:tracking-normal {
    letter-spacing: 0;
  }

  .sm\:tracking-tight {
    letter-spacing: -.025em;
  }

  .sm\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .sm\:tracking-wide {
    letter-spacing: .025em;
  }

  .sm\:tracking-wider {
    letter-spacing: .05em;
  }

  .sm\:tracking-widest {
    letter-spacing: .1em;
  }

  .sm\:text-black {
    color: var(--color-black);
  }

  .sm\:text-current {
    color: currentColor;
  }

  .sm\:text-danger {
    color: var(--color-danger-300);
  }

  .sm\:text-danger-100 {
    color: var(--color-danger-100);
  }

  .sm\:text-danger-200 {
    color: var(--color-danger-200);
  }

  .sm\:text-danger-300 {
    color: var(--color-danger-300);
  }

  .sm\:text-danger-400 {
    color: var(--color-danger-400);
  }

  .sm\:text-divider {
    color: var(--color-divider);
  }

  .sm\:text-gray {
    color: var(--color-gray);
  }

  .sm\:text-info {
    color: var(--color-info-300);
  }

  .sm\:text-info-100 {
    color: var(--color-info-100);
  }

  .sm\:text-info-200 {
    color: var(--color-info-200);
  }

  .sm\:text-info-300 {
    color: var(--color-info-300);
  }

  .sm\:text-info-400 {
    color: var(--color-info-400);
  }

  .sm\:text-primary {
    color: var(--color-primary-300);
  }

  .sm\:text-primary-100 {
    color: var(--color-primary-100);
  }

  .sm\:text-primary-200 {
    color: var(--color-primary-200);
  }

  .sm\:text-primary-250 {
    color: var(--color-primary-250);
  }

  .sm\:text-primary-300 {
    color: var(--color-primary-300);
  }

  .sm\:text-primary-400 {
    color: var(--color-primary-400);
  }

  .sm\:text-primary-500 {
    color: var(--color-primary-500);
  }

  .sm\:text-primary-600 {
    color: var(--color-primary-600);
  }

  .sm\:text-success {
    color: var(--color-success-300);
  }

  .sm\:text-success-100 {
    color: var(--color-success-100);
  }

  .sm\:text-success-200 {
    color: var(--color-success-200);
  }

  .sm\:text-success-300 {
    color: var(--color-success-300);
  }

  .sm\:text-success-400 {
    color: var(--color-success-400);
  }

  .sm\:text-transparent, .sm\:text-transparent\/0 {
    color: #0000;
  }

  .sm\:text-transparent\/10 {
    color: #0000001a;
  }

  .sm\:text-transparent\/100 {
    color: #000;
  }

  .sm\:text-transparent\/15 {
    color: #00000026;
  }

  .sm\:text-transparent\/20 {
    color: #0003;
  }

  .sm\:text-transparent\/25 {
    color: #00000040;
  }

  .sm\:text-transparent\/30 {
    color: #0000004d;
  }

  .sm\:text-transparent\/35 {
    color: #00000059;
  }

  .sm\:text-transparent\/40 {
    color: #0006;
  }

  .sm\:text-transparent\/45 {
    color: #00000073;
  }

  .sm\:text-transparent\/5 {
    color: #0000000d;
  }

  .sm\:text-transparent\/50 {
    color: #00000080;
  }

  .sm\:text-transparent\/55 {
    color: #0000008c;
  }

  .sm\:text-transparent\/60 {
    color: #0009;
  }

  .sm\:text-transparent\/65 {
    color: #000000a6;
  }

  .sm\:text-transparent\/70 {
    color: #000000b3;
  }

  .sm\:text-transparent\/75 {
    color: #000000bf;
  }

  .sm\:text-transparent\/80 {
    color: #000c;
  }

  .sm\:text-transparent\/85 {
    color: #000000d9;
  }

  .sm\:text-transparent\/90 {
    color: #000000e6;
  }

  .sm\:text-transparent\/95 {
    color: #000000f2;
  }

  .sm\:text-warning {
    color: var(--color-warning-300);
  }

  .sm\:text-warning-100 {
    color: var(--color-warning-100);
  }

  .sm\:text-warning-200 {
    color: var(--color-warning-200);
  }

  .sm\:text-warning-300 {
    color: var(--color-warning-300);
  }

  .sm\:text-warning-400 {
    color: var(--color-warning-400);
  }

  .sm\:text-white {
    color: var(--color-white);
  }

  .sm\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .sm\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .sm\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .sm\:text-opacity-15 {
    --tw-text-opacity: .15;
  }

  .sm\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .sm\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .sm\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .sm\:text-opacity-35 {
    --tw-text-opacity: .35;
  }

  .sm\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .sm\:text-opacity-45 {
    --tw-text-opacity: .45;
  }

  .sm\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .sm\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .sm\:text-opacity-55 {
    --tw-text-opacity: .55;
  }

  .sm\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .sm\:text-opacity-65 {
    --tw-text-opacity: .65;
  }

  .sm\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .sm\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .sm\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .sm\:text-opacity-85 {
    --tw-text-opacity: .85;
  }

  .sm\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .sm\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .sm\:underline {
    text-decoration-line: underline;
  }

  .sm\:overline {
    text-decoration-line: overline;
  }

  .sm\:line-through {
    text-decoration-line: line-through;
  }

  .sm\:no-underline {
    text-decoration-line: none;
  }

  .sm\:decoration-black {
    -webkit-text-decoration-color: var(--color-black);
    text-decoration-color: var(--color-black);
  }

  .sm\:decoration-current {
    text-decoration-color: currentColor;
  }

  .sm\:decoration-danger {
    -webkit-text-decoration-color: var(--color-danger-300);
    text-decoration-color: var(--color-danger-300);
  }

  .sm\:decoration-danger-100 {
    -webkit-text-decoration-color: var(--color-danger-100);
    text-decoration-color: var(--color-danger-100);
  }

  .sm\:decoration-danger-200 {
    -webkit-text-decoration-color: var(--color-danger-200);
    text-decoration-color: var(--color-danger-200);
  }

  .sm\:decoration-danger-300 {
    -webkit-text-decoration-color: var(--color-danger-300);
    text-decoration-color: var(--color-danger-300);
  }

  .sm\:decoration-danger-400 {
    -webkit-text-decoration-color: var(--color-danger-400);
    text-decoration-color: var(--color-danger-400);
  }

  .sm\:decoration-divider {
    -webkit-text-decoration-color: var(--color-divider);
    text-decoration-color: var(--color-divider);
  }

  .sm\:decoration-gray {
    -webkit-text-decoration-color: var(--color-gray);
    text-decoration-color: var(--color-gray);
  }

  .sm\:decoration-info {
    -webkit-text-decoration-color: var(--color-info-300);
    text-decoration-color: var(--color-info-300);
  }

  .sm\:decoration-info-100 {
    -webkit-text-decoration-color: var(--color-info-100);
    text-decoration-color: var(--color-info-100);
  }

  .sm\:decoration-info-200 {
    -webkit-text-decoration-color: var(--color-info-200);
    text-decoration-color: var(--color-info-200);
  }

  .sm\:decoration-info-300 {
    -webkit-text-decoration-color: var(--color-info-300);
    text-decoration-color: var(--color-info-300);
  }

  .sm\:decoration-info-400 {
    -webkit-text-decoration-color: var(--color-info-400);
    text-decoration-color: var(--color-info-400);
  }

  .sm\:decoration-primary {
    -webkit-text-decoration-color: var(--color-primary-300);
    text-decoration-color: var(--color-primary-300);
  }

  .sm\:decoration-primary-100 {
    -webkit-text-decoration-color: var(--color-primary-100);
    text-decoration-color: var(--color-primary-100);
  }

  .sm\:decoration-primary-200 {
    -webkit-text-decoration-color: var(--color-primary-200);
    text-decoration-color: var(--color-primary-200);
  }

  .sm\:decoration-primary-250 {
    -webkit-text-decoration-color: var(--color-primary-250);
    text-decoration-color: var(--color-primary-250);
  }

  .sm\:decoration-primary-300 {
    -webkit-text-decoration-color: var(--color-primary-300);
    text-decoration-color: var(--color-primary-300);
  }

  .sm\:decoration-primary-400 {
    -webkit-text-decoration-color: var(--color-primary-400);
    text-decoration-color: var(--color-primary-400);
  }

  .sm\:decoration-primary-500 {
    -webkit-text-decoration-color: var(--color-primary-500);
    text-decoration-color: var(--color-primary-500);
  }

  .sm\:decoration-primary-600 {
    -webkit-text-decoration-color: var(--color-primary-600);
    text-decoration-color: var(--color-primary-600);
  }

  .sm\:decoration-success {
    -webkit-text-decoration-color: var(--color-success-300);
    text-decoration-color: var(--color-success-300);
  }

  .sm\:decoration-success-100 {
    -webkit-text-decoration-color: var(--color-success-100);
    text-decoration-color: var(--color-success-100);
  }

  .sm\:decoration-success-200 {
    -webkit-text-decoration-color: var(--color-success-200);
    text-decoration-color: var(--color-success-200);
  }

  .sm\:decoration-success-300 {
    -webkit-text-decoration-color: var(--color-success-300);
    text-decoration-color: var(--color-success-300);
  }

  .sm\:decoration-success-400 {
    -webkit-text-decoration-color: var(--color-success-400);
    text-decoration-color: var(--color-success-400);
  }

  .sm\:decoration-transparent, .sm\:decoration-transparent\/0 {
    text-decoration-color: #0000;
  }

  .sm\:decoration-transparent\/10 {
    text-decoration-color: #0000001a;
  }

  .sm\:decoration-transparent\/100 {
    text-decoration-color: #000;
  }

  .sm\:decoration-transparent\/15 {
    text-decoration-color: #00000026;
  }

  .sm\:decoration-transparent\/20 {
    text-decoration-color: #0003;
  }

  .sm\:decoration-transparent\/25 {
    text-decoration-color: #00000040;
  }

  .sm\:decoration-transparent\/30 {
    text-decoration-color: #0000004d;
  }

  .sm\:decoration-transparent\/35 {
    text-decoration-color: #00000059;
  }

  .sm\:decoration-transparent\/40 {
    text-decoration-color: #0006;
  }

  .sm\:decoration-transparent\/45 {
    text-decoration-color: #00000073;
  }

  .sm\:decoration-transparent\/5 {
    text-decoration-color: #0000000d;
  }

  .sm\:decoration-transparent\/50 {
    text-decoration-color: #00000080;
  }

  .sm\:decoration-transparent\/55 {
    text-decoration-color: #0000008c;
  }

  .sm\:decoration-transparent\/60 {
    text-decoration-color: #0009;
  }

  .sm\:decoration-transparent\/65 {
    text-decoration-color: #000000a6;
  }

  .sm\:decoration-transparent\/70 {
    text-decoration-color: #000000b3;
  }

  .sm\:decoration-transparent\/75 {
    text-decoration-color: #000000bf;
  }

  .sm\:decoration-transparent\/80 {
    text-decoration-color: #000c;
  }

  .sm\:decoration-transparent\/85 {
    text-decoration-color: #000000d9;
  }

  .sm\:decoration-transparent\/90 {
    text-decoration-color: #000000e6;
  }

  .sm\:decoration-transparent\/95 {
    text-decoration-color: #000000f2;
  }

  .sm\:decoration-warning {
    -webkit-text-decoration-color: var(--color-warning-300);
    text-decoration-color: var(--color-warning-300);
  }

  .sm\:decoration-warning-100 {
    -webkit-text-decoration-color: var(--color-warning-100);
    text-decoration-color: var(--color-warning-100);
  }

  .sm\:decoration-warning-200 {
    -webkit-text-decoration-color: var(--color-warning-200);
    text-decoration-color: var(--color-warning-200);
  }

  .sm\:decoration-warning-300 {
    -webkit-text-decoration-color: var(--color-warning-300);
    text-decoration-color: var(--color-warning-300);
  }

  .sm\:decoration-warning-400 {
    -webkit-text-decoration-color: var(--color-warning-400);
    text-decoration-color: var(--color-warning-400);
  }

  .sm\:decoration-white {
    -webkit-text-decoration-color: var(--color-white);
    text-decoration-color: var(--color-white);
  }

  .sm\:decoration-solid {
    text-decoration-style: solid;
  }

  .sm\:decoration-double {
    text-decoration-style: double;
  }

  .sm\:decoration-dotted {
    text-decoration-style: dotted;
  }

  .sm\:decoration-dashed {
    text-decoration-style: dashed;
  }

  .sm\:decoration-wavy {
    text-decoration-style: wavy;
  }

  .sm\:decoration-0 {
    text-decoration-thickness: 0;
  }

  .sm\:decoration-1 {
    text-decoration-thickness: 1px;
  }

  .sm\:decoration-2 {
    text-decoration-thickness: 2px;
  }

  .sm\:decoration-4 {
    text-decoration-thickness: 4px;
  }

  .sm\:decoration-8 {
    text-decoration-thickness: 8px;
  }

  .sm\:decoration-auto {
    text-decoration-thickness: auto;
  }

  .sm\:decoration-from-font {
    text-decoration-thickness: from-font;
  }

  .sm\:underline-offset-0 {
    text-underline-offset: 0px;
  }

  .sm\:underline-offset-1 {
    text-underline-offset: 1px;
  }

  .sm\:underline-offset-2 {
    text-underline-offset: 2px;
  }

  .sm\:underline-offset-4 {
    text-underline-offset: 4px;
  }

  .sm\:underline-offset-8 {
    text-underline-offset: 8px;
  }

  .sm\:underline-offset-auto {
    text-underline-offset: auto;
  }

  .sm\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm\:placeholder-black::placeholder {
    color: var(--color-black);
  }

  .sm\:placeholder-current::placeholder {
    color: currentColor;
  }

  .sm\:placeholder-danger::placeholder {
    color: var(--color-danger-300);
  }

  .sm\:placeholder-danger-100::placeholder {
    color: var(--color-danger-100);
  }

  .sm\:placeholder-danger-200::placeholder {
    color: var(--color-danger-200);
  }

  .sm\:placeholder-danger-300::placeholder {
    color: var(--color-danger-300);
  }

  .sm\:placeholder-danger-400::placeholder {
    color: var(--color-danger-400);
  }

  .sm\:placeholder-divider::placeholder {
    color: var(--color-divider);
  }

  .sm\:placeholder-gray::placeholder {
    color: var(--color-gray);
  }

  .sm\:placeholder-info::placeholder {
    color: var(--color-info-300);
  }

  .sm\:placeholder-info-100::placeholder {
    color: var(--color-info-100);
  }

  .sm\:placeholder-info-200::placeholder {
    color: var(--color-info-200);
  }

  .sm\:placeholder-info-300::placeholder {
    color: var(--color-info-300);
  }

  .sm\:placeholder-info-400::placeholder {
    color: var(--color-info-400);
  }

  .sm\:placeholder-primary::placeholder {
    color: var(--color-primary-300);
  }

  .sm\:placeholder-primary-100::placeholder {
    color: var(--color-primary-100);
  }

  .sm\:placeholder-primary-200::placeholder {
    color: var(--color-primary-200);
  }

  .sm\:placeholder-primary-250::placeholder {
    color: var(--color-primary-250);
  }

  .sm\:placeholder-primary-300::placeholder {
    color: var(--color-primary-300);
  }

  .sm\:placeholder-primary-400::placeholder {
    color: var(--color-primary-400);
  }

  .sm\:placeholder-primary-500::placeholder {
    color: var(--color-primary-500);
  }

  .sm\:placeholder-primary-600::placeholder {
    color: var(--color-primary-600);
  }

  .sm\:placeholder-success::placeholder {
    color: var(--color-success-300);
  }

  .sm\:placeholder-success-100::placeholder {
    color: var(--color-success-100);
  }

  .sm\:placeholder-success-200::placeholder {
    color: var(--color-success-200);
  }

  .sm\:placeholder-success-300::placeholder {
    color: var(--color-success-300);
  }

  .sm\:placeholder-success-400::placeholder {
    color: var(--color-success-400);
  }

  .sm\:placeholder-transparent::placeholder, .sm\:placeholder-transparent\/0::placeholder {
    color: #0000;
  }

  .sm\:placeholder-transparent\/10::placeholder {
    color: #0000001a;
  }

  .sm\:placeholder-transparent\/100::placeholder {
    color: #000;
  }

  .sm\:placeholder-transparent\/15::placeholder {
    color: #00000026;
  }

  .sm\:placeholder-transparent\/20::placeholder {
    color: #0003;
  }

  .sm\:placeholder-transparent\/25::placeholder {
    color: #00000040;
  }

  .sm\:placeholder-transparent\/30::placeholder {
    color: #0000004d;
  }

  .sm\:placeholder-transparent\/35::placeholder {
    color: #00000059;
  }

  .sm\:placeholder-transparent\/40::placeholder {
    color: #0006;
  }

  .sm\:placeholder-transparent\/45::placeholder {
    color: #00000073;
  }

  .sm\:placeholder-transparent\/5::placeholder {
    color: #0000000d;
  }

  .sm\:placeholder-transparent\/50::placeholder {
    color: #00000080;
  }

  .sm\:placeholder-transparent\/55::placeholder {
    color: #0000008c;
  }

  .sm\:placeholder-transparent\/60::placeholder {
    color: #0009;
  }

  .sm\:placeholder-transparent\/65::placeholder {
    color: #000000a6;
  }

  .sm\:placeholder-transparent\/70::placeholder {
    color: #000000b3;
  }

  .sm\:placeholder-transparent\/75::placeholder {
    color: #000000bf;
  }

  .sm\:placeholder-transparent\/80::placeholder {
    color: #000c;
  }

  .sm\:placeholder-transparent\/85::placeholder {
    color: #000000d9;
  }

  .sm\:placeholder-transparent\/90::placeholder {
    color: #000000e6;
  }

  .sm\:placeholder-transparent\/95::placeholder {
    color: #000000f2;
  }

  .sm\:placeholder-warning::placeholder {
    color: var(--color-warning-300);
  }

  .sm\:placeholder-warning-100::placeholder {
    color: var(--color-warning-100);
  }

  .sm\:placeholder-warning-200::placeholder {
    color: var(--color-warning-200);
  }

  .sm\:placeholder-warning-300::placeholder {
    color: var(--color-warning-300);
  }

  .sm\:placeholder-warning-400::placeholder {
    color: var(--color-warning-400);
  }

  .sm\:placeholder-white::placeholder {
    color: var(--color-white);
  }

  .sm\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .sm\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:placeholder-opacity-15::placeholder {
    --tw-placeholder-opacity: .15;
  }

  .sm\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .sm\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .sm\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .sm\:placeholder-opacity-35::placeholder {
    --tw-placeholder-opacity: .35;
  }

  .sm\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .sm\:placeholder-opacity-45::placeholder {
    --tw-placeholder-opacity: .45;
  }

  .sm\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .sm\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .sm\:placeholder-opacity-55::placeholder {
    --tw-placeholder-opacity: .55;
  }

  .sm\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .sm\:placeholder-opacity-65::placeholder {
    --tw-placeholder-opacity: .65;
  }

  .sm\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .sm\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .sm\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .sm\:placeholder-opacity-85::placeholder {
    --tw-placeholder-opacity: .85;
  }

  .sm\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .sm\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .sm\:caret-black {
    caret-color: var(--color-black);
  }

  .sm\:caret-current {
    caret-color: currentColor;
  }

  .sm\:caret-danger {
    caret-color: var(--color-danger-300);
  }

  .sm\:caret-danger-100 {
    caret-color: var(--color-danger-100);
  }

  .sm\:caret-danger-200 {
    caret-color: var(--color-danger-200);
  }

  .sm\:caret-danger-300 {
    caret-color: var(--color-danger-300);
  }

  .sm\:caret-danger-400 {
    caret-color: var(--color-danger-400);
  }

  .sm\:caret-divider {
    caret-color: var(--color-divider);
  }

  .sm\:caret-gray {
    caret-color: var(--color-gray);
  }

  .sm\:caret-info {
    caret-color: var(--color-info-300);
  }

  .sm\:caret-info-100 {
    caret-color: var(--color-info-100);
  }

  .sm\:caret-info-200 {
    caret-color: var(--color-info-200);
  }

  .sm\:caret-info-300 {
    caret-color: var(--color-info-300);
  }

  .sm\:caret-info-400 {
    caret-color: var(--color-info-400);
  }

  .sm\:caret-primary {
    caret-color: var(--color-primary-300);
  }

  .sm\:caret-primary-100 {
    caret-color: var(--color-primary-100);
  }

  .sm\:caret-primary-200 {
    caret-color: var(--color-primary-200);
  }

  .sm\:caret-primary-250 {
    caret-color: var(--color-primary-250);
  }

  .sm\:caret-primary-300 {
    caret-color: var(--color-primary-300);
  }

  .sm\:caret-primary-400 {
    caret-color: var(--color-primary-400);
  }

  .sm\:caret-primary-500 {
    caret-color: var(--color-primary-500);
  }

  .sm\:caret-primary-600 {
    caret-color: var(--color-primary-600);
  }

  .sm\:caret-success {
    caret-color: var(--color-success-300);
  }

  .sm\:caret-success-100 {
    caret-color: var(--color-success-100);
  }

  .sm\:caret-success-200 {
    caret-color: var(--color-success-200);
  }

  .sm\:caret-success-300 {
    caret-color: var(--color-success-300);
  }

  .sm\:caret-success-400 {
    caret-color: var(--color-success-400);
  }

  .sm\:caret-transparent, .sm\:caret-transparent\/0 {
    caret-color: #0000;
  }

  .sm\:caret-transparent\/10 {
    caret-color: #0000001a;
  }

  .sm\:caret-transparent\/100 {
    caret-color: #000;
  }

  .sm\:caret-transparent\/15 {
    caret-color: #00000026;
  }

  .sm\:caret-transparent\/20 {
    caret-color: #0003;
  }

  .sm\:caret-transparent\/25 {
    caret-color: #00000040;
  }

  .sm\:caret-transparent\/30 {
    caret-color: #0000004d;
  }

  .sm\:caret-transparent\/35 {
    caret-color: #00000059;
  }

  .sm\:caret-transparent\/40 {
    caret-color: #0006;
  }

  .sm\:caret-transparent\/45 {
    caret-color: #00000073;
  }

  .sm\:caret-transparent\/5 {
    caret-color: #0000000d;
  }

  .sm\:caret-transparent\/50 {
    caret-color: #00000080;
  }

  .sm\:caret-transparent\/55 {
    caret-color: #0000008c;
  }

  .sm\:caret-transparent\/60 {
    caret-color: #0009;
  }

  .sm\:caret-transparent\/65 {
    caret-color: #000000a6;
  }

  .sm\:caret-transparent\/70 {
    caret-color: #000000b3;
  }

  .sm\:caret-transparent\/75 {
    caret-color: #000000bf;
  }

  .sm\:caret-transparent\/80 {
    caret-color: #000c;
  }

  .sm\:caret-transparent\/85 {
    caret-color: #000000d9;
  }

  .sm\:caret-transparent\/90 {
    caret-color: #000000e6;
  }

  .sm\:caret-transparent\/95 {
    caret-color: #000000f2;
  }

  .sm\:caret-warning {
    caret-color: var(--color-warning-300);
  }

  .sm\:caret-warning-100 {
    caret-color: var(--color-warning-100);
  }

  .sm\:caret-warning-200 {
    caret-color: var(--color-warning-200);
  }

  .sm\:caret-warning-300 {
    caret-color: var(--color-warning-300);
  }

  .sm\:caret-warning-400 {
    caret-color: var(--color-warning-400);
  }

  .sm\:caret-white {
    caret-color: var(--color-white);
  }

  .sm\:accent-auto {
    accent-color: auto;
  }

  .sm\:accent-black {
    accent-color: var(--color-black);
  }

  .sm\:accent-current {
    accent-color: currentColor;
  }

  .sm\:accent-danger {
    accent-color: var(--color-danger-300);
  }

  .sm\:accent-danger-100 {
    accent-color: var(--color-danger-100);
  }

  .sm\:accent-danger-200 {
    accent-color: var(--color-danger-200);
  }

  .sm\:accent-danger-300 {
    accent-color: var(--color-danger-300);
  }

  .sm\:accent-danger-400 {
    accent-color: var(--color-danger-400);
  }

  .sm\:accent-divider {
    accent-color: var(--color-divider);
  }

  .sm\:accent-gray {
    accent-color: var(--color-gray);
  }

  .sm\:accent-info {
    accent-color: var(--color-info-300);
  }

  .sm\:accent-info-100 {
    accent-color: var(--color-info-100);
  }

  .sm\:accent-info-200 {
    accent-color: var(--color-info-200);
  }

  .sm\:accent-info-300 {
    accent-color: var(--color-info-300);
  }

  .sm\:accent-info-400 {
    accent-color: var(--color-info-400);
  }

  .sm\:accent-primary {
    accent-color: var(--color-primary-300);
  }

  .sm\:accent-primary-100 {
    accent-color: var(--color-primary-100);
  }

  .sm\:accent-primary-200 {
    accent-color: var(--color-primary-200);
  }

  .sm\:accent-primary-250 {
    accent-color: var(--color-primary-250);
  }

  .sm\:accent-primary-300 {
    accent-color: var(--color-primary-300);
  }

  .sm\:accent-primary-400 {
    accent-color: var(--color-primary-400);
  }

  .sm\:accent-primary-500 {
    accent-color: var(--color-primary-500);
  }

  .sm\:accent-primary-600 {
    accent-color: var(--color-primary-600);
  }

  .sm\:accent-success {
    accent-color: var(--color-success-300);
  }

  .sm\:accent-success-100 {
    accent-color: var(--color-success-100);
  }

  .sm\:accent-success-200 {
    accent-color: var(--color-success-200);
  }

  .sm\:accent-success-300 {
    accent-color: var(--color-success-300);
  }

  .sm\:accent-success-400 {
    accent-color: var(--color-success-400);
  }

  .sm\:accent-transparent, .sm\:accent-transparent\/0 {
    accent-color: #0000;
  }

  .sm\:accent-transparent\/10 {
    accent-color: #0000001a;
  }

  .sm\:accent-transparent\/100 {
    accent-color: #000;
  }

  .sm\:accent-transparent\/15 {
    accent-color: #00000026;
  }

  .sm\:accent-transparent\/20 {
    accent-color: #0003;
  }

  .sm\:accent-transparent\/25 {
    accent-color: #00000040;
  }

  .sm\:accent-transparent\/30 {
    accent-color: #0000004d;
  }

  .sm\:accent-transparent\/35 {
    accent-color: #00000059;
  }

  .sm\:accent-transparent\/40 {
    accent-color: #0006;
  }

  .sm\:accent-transparent\/45 {
    accent-color: #00000073;
  }

  .sm\:accent-transparent\/5 {
    accent-color: #0000000d;
  }

  .sm\:accent-transparent\/50 {
    accent-color: #00000080;
  }

  .sm\:accent-transparent\/55 {
    accent-color: #0000008c;
  }

  .sm\:accent-transparent\/60 {
    accent-color: #0009;
  }

  .sm\:accent-transparent\/65 {
    accent-color: #000000a6;
  }

  .sm\:accent-transparent\/70 {
    accent-color: #000000b3;
  }

  .sm\:accent-transparent\/75 {
    accent-color: #000000bf;
  }

  .sm\:accent-transparent\/80 {
    accent-color: #000c;
  }

  .sm\:accent-transparent\/85 {
    accent-color: #000000d9;
  }

  .sm\:accent-transparent\/90 {
    accent-color: #000000e6;
  }

  .sm\:accent-transparent\/95 {
    accent-color: #000000f2;
  }

  .sm\:accent-warning {
    accent-color: var(--color-warning-300);
  }

  .sm\:accent-warning-100 {
    accent-color: var(--color-warning-100);
  }

  .sm\:accent-warning-200 {
    accent-color: var(--color-warning-200);
  }

  .sm\:accent-warning-300 {
    accent-color: var(--color-warning-300);
  }

  .sm\:accent-warning-400 {
    accent-color: var(--color-warning-400);
  }

  .sm\:accent-white {
    accent-color: var(--color-white);
  }

  .sm\:opacity-0 {
    opacity: 0;
  }

  .sm\:opacity-10 {
    opacity: .1;
  }

  .sm\:opacity-100 {
    opacity: 1;
  }

  .sm\:opacity-15 {
    opacity: .15;
  }

  .sm\:opacity-20 {
    opacity: .2;
  }

  .sm\:opacity-25 {
    opacity: .25;
  }

  .sm\:opacity-30 {
    opacity: .3;
  }

  .sm\:opacity-35 {
    opacity: .35;
  }

  .sm\:opacity-40 {
    opacity: .4;
  }

  .sm\:opacity-45 {
    opacity: .45;
  }

  .sm\:opacity-5 {
    opacity: .05;
  }

  .sm\:opacity-50 {
    opacity: .5;
  }

  .sm\:opacity-55 {
    opacity: .55;
  }

  .sm\:opacity-60 {
    opacity: .6;
  }

  .sm\:opacity-65 {
    opacity: .65;
  }

  .sm\:opacity-70 {
    opacity: .7;
  }

  .sm\:opacity-75 {
    opacity: .75;
  }

  .sm\:opacity-80 {
    opacity: .8;
  }

  .sm\:opacity-85 {
    opacity: .85;
  }

  .sm\:opacity-90 {
    opacity: .9;
  }

  .sm\:opacity-95 {
    opacity: .95;
  }

  .sm\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .sm\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .sm\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .sm\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .sm\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .sm\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .sm\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .sm\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .sm\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .sm\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .sm\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .sm\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .sm\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .sm\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .sm\:bg-blend-color {
    background-blend-mode: color;
  }

  .sm\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .sm\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .sm\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .sm\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .sm\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .sm\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .sm\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .sm\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .sm\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .sm\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .sm\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .sm\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .sm\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .sm\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .sm\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .sm\:mix-blend-color {
    mix-blend-mode: color;
  }

  .sm\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .sm\:mix-blend-plus-darker {
    mix-blend-mode: plus-darker;
  }

  .sm\:mix-blend-plus-lighter {
    mix-blend-mode: plus-lighter;
  }

  .sm\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000d;
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-black {
    --tw-shadow-color: var(--color-black);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-current {
    --tw-shadow-color: currentColor;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-danger {
    --tw-shadow-color: var(--color-danger-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-danger-100 {
    --tw-shadow-color: var(--color-danger-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-danger-200 {
    --tw-shadow-color: var(--color-danger-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-danger-300 {
    --tw-shadow-color: var(--color-danger-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-danger-400 {
    --tw-shadow-color: var(--color-danger-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-divider {
    --tw-shadow-color: var(--color-divider);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-gray {
    --tw-shadow-color: var(--color-gray);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-info {
    --tw-shadow-color: var(--color-info-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-info-100 {
    --tw-shadow-color: var(--color-info-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-info-200 {
    --tw-shadow-color: var(--color-info-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-info-300 {
    --tw-shadow-color: var(--color-info-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-info-400 {
    --tw-shadow-color: var(--color-info-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-primary {
    --tw-shadow-color: var(--color-primary-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-primary-100 {
    --tw-shadow-color: var(--color-primary-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-primary-200 {
    --tw-shadow-color: var(--color-primary-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-primary-250 {
    --tw-shadow-color: var(--color-primary-250);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-primary-300 {
    --tw-shadow-color: var(--color-primary-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-primary-400 {
    --tw-shadow-color: var(--color-primary-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-primary-500 {
    --tw-shadow-color: var(--color-primary-500);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-primary-600 {
    --tw-shadow-color: var(--color-primary-600);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-success {
    --tw-shadow-color: var(--color-success-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-success-100 {
    --tw-shadow-color: var(--color-success-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-success-200 {
    --tw-shadow-color: var(--color-success-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-success-300 {
    --tw-shadow-color: var(--color-success-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-success-400 {
    --tw-shadow-color: var(--color-success-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent {
    --tw-shadow-color: transparent;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/0 {
    --tw-shadow-color: #0000;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/10 {
    --tw-shadow-color: #0000001a;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/100 {
    --tw-shadow-color: #000;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/15 {
    --tw-shadow-color: #00000026;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/20 {
    --tw-shadow-color: #0003;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/25 {
    --tw-shadow-color: #00000040;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/30 {
    --tw-shadow-color: #0000004d;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/35 {
    --tw-shadow-color: #00000059;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/40 {
    --tw-shadow-color: #0006;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/45 {
    --tw-shadow-color: #00000073;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/5 {
    --tw-shadow-color: #0000000d;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/50 {
    --tw-shadow-color: #00000080;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/55 {
    --tw-shadow-color: #0000008c;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/60 {
    --tw-shadow-color: #0009;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/65 {
    --tw-shadow-color: #000000a6;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/70 {
    --tw-shadow-color: #000000b3;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/75 {
    --tw-shadow-color: #000000bf;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/80 {
    --tw-shadow-color: #000c;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/85 {
    --tw-shadow-color: #000000d9;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/90 {
    --tw-shadow-color: #000000e6;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-transparent\/95 {
    --tw-shadow-color: #000000f2;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-warning {
    --tw-shadow-color: var(--color-warning-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-warning-100 {
    --tw-shadow-color: var(--color-warning-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-warning-200 {
    --tw-shadow-color: var(--color-warning-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-warning-300 {
    --tw-shadow-color: var(--color-warning-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-warning-400 {
    --tw-shadow-color: var(--color-warning-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:shadow-white {
    --tw-shadow-color: var(--color-white);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .sm\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .sm\:outline {
    outline-style: solid;
  }

  .sm\:outline-dashed {
    outline-style: dashed;
  }

  .sm\:outline-dotted {
    outline-style: dotted;
  }

  .sm\:outline-double {
    outline-style: double;
  }

  .sm\:outline-0 {
    outline-width: 0;
  }

  .sm\:outline-1 {
    outline-width: 1px;
  }

  .sm\:outline-2 {
    outline-width: 2px;
  }

  .sm\:outline-4 {
    outline-width: 4px;
  }

  .sm\:outline-8 {
    outline-width: 8px;
  }

  .sm\:-outline-offset-0 {
    outline-offset: -0px;
  }

  .sm\:-outline-offset-1 {
    outline-offset: -1px;
  }

  .sm\:-outline-offset-2 {
    outline-offset: -2px;
  }

  .sm\:-outline-offset-4 {
    outline-offset: -4px;
  }

  .sm\:-outline-offset-8 {
    outline-offset: -8px;
  }

  .sm\:outline-offset-0 {
    outline-offset: 0px;
  }

  .sm\:outline-offset-1 {
    outline-offset: 1px;
  }

  .sm\:outline-offset-2 {
    outline-offset: 2px;
  }

  .sm\:outline-offset-4 {
    outline-offset: 4px;
  }

  .sm\:outline-offset-8 {
    outline-offset: 8px;
  }

  .sm\:outline-black {
    outline-color: var(--color-black);
  }

  .sm\:outline-current {
    outline-color: currentColor;
  }

  .sm\:outline-danger {
    outline-color: var(--color-danger-300);
  }

  .sm\:outline-danger-100 {
    outline-color: var(--color-danger-100);
  }

  .sm\:outline-danger-200 {
    outline-color: var(--color-danger-200);
  }

  .sm\:outline-danger-300 {
    outline-color: var(--color-danger-300);
  }

  .sm\:outline-danger-400 {
    outline-color: var(--color-danger-400);
  }

  .sm\:outline-divider {
    outline-color: var(--color-divider);
  }

  .sm\:outline-gray {
    outline-color: var(--color-gray);
  }

  .sm\:outline-info {
    outline-color: var(--color-info-300);
  }

  .sm\:outline-info-100 {
    outline-color: var(--color-info-100);
  }

  .sm\:outline-info-200 {
    outline-color: var(--color-info-200);
  }

  .sm\:outline-info-300 {
    outline-color: var(--color-info-300);
  }

  .sm\:outline-info-400 {
    outline-color: var(--color-info-400);
  }

  .sm\:outline-primary {
    outline-color: var(--color-primary-300);
  }

  .sm\:outline-primary-100 {
    outline-color: var(--color-primary-100);
  }

  .sm\:outline-primary-200 {
    outline-color: var(--color-primary-200);
  }

  .sm\:outline-primary-250 {
    outline-color: var(--color-primary-250);
  }

  .sm\:outline-primary-300 {
    outline-color: var(--color-primary-300);
  }

  .sm\:outline-primary-400 {
    outline-color: var(--color-primary-400);
  }

  .sm\:outline-primary-500 {
    outline-color: var(--color-primary-500);
  }

  .sm\:outline-primary-600 {
    outline-color: var(--color-primary-600);
  }

  .sm\:outline-success {
    outline-color: var(--color-success-300);
  }

  .sm\:outline-success-100 {
    outline-color: var(--color-success-100);
  }

  .sm\:outline-success-200 {
    outline-color: var(--color-success-200);
  }

  .sm\:outline-success-300 {
    outline-color: var(--color-success-300);
  }

  .sm\:outline-success-400 {
    outline-color: var(--color-success-400);
  }

  .sm\:outline-transparent, .sm\:outline-transparent\/0 {
    outline-color: #0000;
  }

  .sm\:outline-transparent\/10 {
    outline-color: #0000001a;
  }

  .sm\:outline-transparent\/100 {
    outline-color: #000;
  }

  .sm\:outline-transparent\/15 {
    outline-color: #00000026;
  }

  .sm\:outline-transparent\/20 {
    outline-color: #0003;
  }

  .sm\:outline-transparent\/25 {
    outline-color: #00000040;
  }

  .sm\:outline-transparent\/30 {
    outline-color: #0000004d;
  }

  .sm\:outline-transparent\/35 {
    outline-color: #00000059;
  }

  .sm\:outline-transparent\/40 {
    outline-color: #0006;
  }

  .sm\:outline-transparent\/45 {
    outline-color: #00000073;
  }

  .sm\:outline-transparent\/5 {
    outline-color: #0000000d;
  }

  .sm\:outline-transparent\/50 {
    outline-color: #00000080;
  }

  .sm\:outline-transparent\/55 {
    outline-color: #0000008c;
  }

  .sm\:outline-transparent\/60 {
    outline-color: #0009;
  }

  .sm\:outline-transparent\/65 {
    outline-color: #000000a6;
  }

  .sm\:outline-transparent\/70 {
    outline-color: #000000b3;
  }

  .sm\:outline-transparent\/75 {
    outline-color: #000000bf;
  }

  .sm\:outline-transparent\/80 {
    outline-color: #000c;
  }

  .sm\:outline-transparent\/85 {
    outline-color: #000000d9;
  }

  .sm\:outline-transparent\/90 {
    outline-color: #000000e6;
  }

  .sm\:outline-transparent\/95 {
    outline-color: #000000f2;
  }

  .sm\:outline-warning {
    outline-color: var(--color-warning-300);
  }

  .sm\:outline-warning-100 {
    outline-color: var(--color-warning-100);
  }

  .sm\:outline-warning-200 {
    outline-color: var(--color-warning-200);
  }

  .sm\:outline-warning-300 {
    outline-color: var(--color-warning-300);
  }

  .sm\:outline-warning-400 {
    outline-color: var(--color-warning-400);
  }

  .sm\:outline-white {
    outline-color: var(--color-white);
  }

  .sm\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-inset {
    --tw-ring-inset: inset;
  }

  .sm\:ring-black {
    --tw-ring-color: var(--color-black);
  }

  .sm\:ring-current {
    --tw-ring-color: currentColor;
  }

  .sm\:ring-danger {
    --tw-ring-color: var(--color-danger-300);
  }

  .sm\:ring-danger-100 {
    --tw-ring-color: var(--color-danger-100);
  }

  .sm\:ring-danger-200 {
    --tw-ring-color: var(--color-danger-200);
  }

  .sm\:ring-danger-300 {
    --tw-ring-color: var(--color-danger-300);
  }

  .sm\:ring-danger-400 {
    --tw-ring-color: var(--color-danger-400);
  }

  .sm\:ring-divider {
    --tw-ring-color: var(--color-divider);
  }

  .sm\:ring-gray {
    --tw-ring-color: var(--color-gray);
  }

  .sm\:ring-info {
    --tw-ring-color: var(--color-info-300);
  }

  .sm\:ring-info-100 {
    --tw-ring-color: var(--color-info-100);
  }

  .sm\:ring-info-200 {
    --tw-ring-color: var(--color-info-200);
  }

  .sm\:ring-info-300 {
    --tw-ring-color: var(--color-info-300);
  }

  .sm\:ring-info-400 {
    --tw-ring-color: var(--color-info-400);
  }

  .sm\:ring-primary {
    --tw-ring-color: var(--color-primary-300);
  }

  .sm\:ring-primary-100 {
    --tw-ring-color: var(--color-primary-100);
  }

  .sm\:ring-primary-200 {
    --tw-ring-color: var(--color-primary-200);
  }

  .sm\:ring-primary-250 {
    --tw-ring-color: var(--color-primary-250);
  }

  .sm\:ring-primary-300 {
    --tw-ring-color: var(--color-primary-300);
  }

  .sm\:ring-primary-400 {
    --tw-ring-color: var(--color-primary-400);
  }

  .sm\:ring-primary-500 {
    --tw-ring-color: var(--color-primary-500);
  }

  .sm\:ring-primary-600 {
    --tw-ring-color: var(--color-primary-600);
  }

  .sm\:ring-success {
    --tw-ring-color: var(--color-success-300);
  }

  .sm\:ring-success-100 {
    --tw-ring-color: var(--color-success-100);
  }

  .sm\:ring-success-200 {
    --tw-ring-color: var(--color-success-200);
  }

  .sm\:ring-success-300 {
    --tw-ring-color: var(--color-success-300);
  }

  .sm\:ring-success-400 {
    --tw-ring-color: var(--color-success-400);
  }

  .sm\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .sm\:ring-transparent\/0 {
    --tw-ring-color: #0000;
  }

  .sm\:ring-transparent\/10 {
    --tw-ring-color: #0000001a;
  }

  .sm\:ring-transparent\/100 {
    --tw-ring-color: #000;
  }

  .sm\:ring-transparent\/15 {
    --tw-ring-color: #00000026;
  }

  .sm\:ring-transparent\/20 {
    --tw-ring-color: #0003;
  }

  .sm\:ring-transparent\/25 {
    --tw-ring-color: #00000040;
  }

  .sm\:ring-transparent\/30 {
    --tw-ring-color: #0000004d;
  }

  .sm\:ring-transparent\/35 {
    --tw-ring-color: #00000059;
  }

  .sm\:ring-transparent\/40 {
    --tw-ring-color: #0006;
  }

  .sm\:ring-transparent\/45 {
    --tw-ring-color: #00000073;
  }

  .sm\:ring-transparent\/5 {
    --tw-ring-color: #0000000d;
  }

  .sm\:ring-transparent\/50 {
    --tw-ring-color: #00000080;
  }

  .sm\:ring-transparent\/55 {
    --tw-ring-color: #0000008c;
  }

  .sm\:ring-transparent\/60 {
    --tw-ring-color: #0009;
  }

  .sm\:ring-transparent\/65 {
    --tw-ring-color: #000000a6;
  }

  .sm\:ring-transparent\/70 {
    --tw-ring-color: #000000b3;
  }

  .sm\:ring-transparent\/75 {
    --tw-ring-color: #000000bf;
  }

  .sm\:ring-transparent\/80 {
    --tw-ring-color: #000c;
  }

  .sm\:ring-transparent\/85 {
    --tw-ring-color: #000000d9;
  }

  .sm\:ring-transparent\/90 {
    --tw-ring-color: #000000e6;
  }

  .sm\:ring-transparent\/95 {
    --tw-ring-color: #000000f2;
  }

  .sm\:ring-warning {
    --tw-ring-color: var(--color-warning-300);
  }

  .sm\:ring-warning-100 {
    --tw-ring-color: var(--color-warning-100);
  }

  .sm\:ring-warning-200 {
    --tw-ring-color: var(--color-warning-200);
  }

  .sm\:ring-warning-300 {
    --tw-ring-color: var(--color-warning-300);
  }

  .sm\:ring-warning-400 {
    --tw-ring-color: var(--color-warning-400);
  }

  .sm\:ring-white {
    --tw-ring-color: var(--color-white);
  }

  .sm\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .sm\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .sm\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .sm\:ring-opacity-15 {
    --tw-ring-opacity: .15;
  }

  .sm\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .sm\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .sm\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .sm\:ring-opacity-35 {
    --tw-ring-opacity: .35;
  }

  .sm\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .sm\:ring-opacity-45 {
    --tw-ring-opacity: .45;
  }

  .sm\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .sm\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .sm\:ring-opacity-55 {
    --tw-ring-opacity: .55;
  }

  .sm\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .sm\:ring-opacity-65 {
    --tw-ring-opacity: .65;
  }

  .sm\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .sm\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .sm\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .sm\:ring-opacity-85 {
    --tw-ring-opacity: .85;
  }

  .sm\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .sm\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .sm\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .sm\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .sm\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .sm\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .sm\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .sm\:ring-offset-black {
    --tw-ring-offset-color: var(--color-black);
  }

  .sm\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .sm\:ring-offset-danger {
    --tw-ring-offset-color: var(--color-danger-300);
  }

  .sm\:ring-offset-danger-100 {
    --tw-ring-offset-color: var(--color-danger-100);
  }

  .sm\:ring-offset-danger-200 {
    --tw-ring-offset-color: var(--color-danger-200);
  }

  .sm\:ring-offset-danger-300 {
    --tw-ring-offset-color: var(--color-danger-300);
  }

  .sm\:ring-offset-danger-400 {
    --tw-ring-offset-color: var(--color-danger-400);
  }

  .sm\:ring-offset-divider {
    --tw-ring-offset-color: var(--color-divider);
  }

  .sm\:ring-offset-gray {
    --tw-ring-offset-color: var(--color-gray);
  }

  .sm\:ring-offset-info {
    --tw-ring-offset-color: var(--color-info-300);
  }

  .sm\:ring-offset-info-100 {
    --tw-ring-offset-color: var(--color-info-100);
  }

  .sm\:ring-offset-info-200 {
    --tw-ring-offset-color: var(--color-info-200);
  }

  .sm\:ring-offset-info-300 {
    --tw-ring-offset-color: var(--color-info-300);
  }

  .sm\:ring-offset-info-400 {
    --tw-ring-offset-color: var(--color-info-400);
  }

  .sm\:ring-offset-primary {
    --tw-ring-offset-color: var(--color-primary-300);
  }

  .sm\:ring-offset-primary-100 {
    --tw-ring-offset-color: var(--color-primary-100);
  }

  .sm\:ring-offset-primary-200 {
    --tw-ring-offset-color: var(--color-primary-200);
  }

  .sm\:ring-offset-primary-250 {
    --tw-ring-offset-color: var(--color-primary-250);
  }

  .sm\:ring-offset-primary-300 {
    --tw-ring-offset-color: var(--color-primary-300);
  }

  .sm\:ring-offset-primary-400 {
    --tw-ring-offset-color: var(--color-primary-400);
  }

  .sm\:ring-offset-primary-500 {
    --tw-ring-offset-color: var(--color-primary-500);
  }

  .sm\:ring-offset-primary-600 {
    --tw-ring-offset-color: var(--color-primary-600);
  }

  .sm\:ring-offset-success {
    --tw-ring-offset-color: var(--color-success-300);
  }

  .sm\:ring-offset-success-100 {
    --tw-ring-offset-color: var(--color-success-100);
  }

  .sm\:ring-offset-success-200 {
    --tw-ring-offset-color: var(--color-success-200);
  }

  .sm\:ring-offset-success-300 {
    --tw-ring-offset-color: var(--color-success-300);
  }

  .sm\:ring-offset-success-400 {
    --tw-ring-offset-color: var(--color-success-400);
  }

  .sm\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .sm\:ring-offset-transparent\/0 {
    --tw-ring-offset-color: #0000;
  }

  .sm\:ring-offset-transparent\/10 {
    --tw-ring-offset-color: #0000001a;
  }

  .sm\:ring-offset-transparent\/100 {
    --tw-ring-offset-color: #000;
  }

  .sm\:ring-offset-transparent\/15 {
    --tw-ring-offset-color: #00000026;
  }

  .sm\:ring-offset-transparent\/20 {
    --tw-ring-offset-color: #0003;
  }

  .sm\:ring-offset-transparent\/25 {
    --tw-ring-offset-color: #00000040;
  }

  .sm\:ring-offset-transparent\/30 {
    --tw-ring-offset-color: #0000004d;
  }

  .sm\:ring-offset-transparent\/35 {
    --tw-ring-offset-color: #00000059;
  }

  .sm\:ring-offset-transparent\/40 {
    --tw-ring-offset-color: #0006;
  }

  .sm\:ring-offset-transparent\/45 {
    --tw-ring-offset-color: #00000073;
  }

  .sm\:ring-offset-transparent\/5 {
    --tw-ring-offset-color: #0000000d;
  }

  .sm\:ring-offset-transparent\/50 {
    --tw-ring-offset-color: #00000080;
  }

  .sm\:ring-offset-transparent\/55 {
    --tw-ring-offset-color: #0000008c;
  }

  .sm\:ring-offset-transparent\/60 {
    --tw-ring-offset-color: #0009;
  }

  .sm\:ring-offset-transparent\/65 {
    --tw-ring-offset-color: #000000a6;
  }

  .sm\:ring-offset-transparent\/70 {
    --tw-ring-offset-color: #000000b3;
  }

  .sm\:ring-offset-transparent\/75 {
    --tw-ring-offset-color: #000000bf;
  }

  .sm\:ring-offset-transparent\/80 {
    --tw-ring-offset-color: #000c;
  }

  .sm\:ring-offset-transparent\/85 {
    --tw-ring-offset-color: #000000d9;
  }

  .sm\:ring-offset-transparent\/90 {
    --tw-ring-offset-color: #000000e6;
  }

  .sm\:ring-offset-transparent\/95 {
    --tw-ring-offset-color: #000000f2;
  }

  .sm\:ring-offset-warning {
    --tw-ring-offset-color: var(--color-warning-300);
  }

  .sm\:ring-offset-warning-100 {
    --tw-ring-offset-color: var(--color-warning-100);
  }

  .sm\:ring-offset-warning-200 {
    --tw-ring-offset-color: var(--color-warning-200);
  }

  .sm\:ring-offset-warning-300 {
    --tw-ring-offset-color: var(--color-warning-300);
  }

  .sm\:ring-offset-warning-400 {
    --tw-ring-offset-color: var(--color-warning-400);
  }

  .sm\:ring-offset-white {
    --tw-ring-offset-color: var(--color-white);
  }

  .sm\:blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:blur-0 {
    --tw-blur: blur(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:blur-2xl {
    --tw-blur: blur(40px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:blur-3xl {
    --tw-blur: blur(64px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:blur-lg {
    --tw-blur: blur(16px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:blur-md {
    --tw-blur: blur(12px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:blur-none {
    --tw-blur: ;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:blur-sm {
    --tw-blur: blur(4px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:blur-xl {
    --tw-blur: blur(24px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-0 {
    --tw-brightness: brightness(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-100 {
    --tw-brightness: brightness(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-105 {
    --tw-brightness: brightness(1.05);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-110 {
    --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-125 {
    --tw-brightness: brightness(1.25);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-150 {
    --tw-brightness: brightness(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-200 {
    --tw-brightness: brightness(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-50 {
    --tw-brightness: brightness(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-75 {
    --tw-brightness: brightness(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-90 {
    --tw-brightness: brightness(.9);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:brightness-95 {
    --tw-brightness: brightness(.95);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:contrast-0 {
    --tw-contrast: contrast(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:contrast-100 {
    --tw-contrast: contrast(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:contrast-125 {
    --tw-contrast: contrast(1.25);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:contrast-150 {
    --tw-contrast: contrast(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:contrast-200 {
    --tw-contrast: contrast(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:contrast-50 {
    --tw-contrast: contrast(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:contrast-75 {
    --tw-contrast: contrast(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:grayscale-0 {
    --tw-grayscale: grayscale(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:-hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(-0deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:invert-0 {
    --tw-invert: invert(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:saturate-0 {
    --tw-saturate: saturate(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:saturate-100 {
    --tw-saturate: saturate(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:saturate-150 {
    --tw-saturate: saturate(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:saturate-200 {
    --tw-saturate: saturate(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:saturate-50 {
    --tw-saturate: saturate(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:sepia {
    --tw-sepia: sepia(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:sepia-0 {
    --tw-sepia: sepia(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:filter-none {
    filter: none;
  }

  .sm\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-blur-none {
    --tw-backdrop-blur: ;
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:-backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(-0deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-15 {
    --tw-backdrop-opacity: opacity(.15);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-35 {
    --tw-backdrop-opacity: opacity(.35);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-45 {
    --tw-backdrop-opacity: opacity(.45);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-55 {
    --tw-backdrop-opacity: opacity(.55);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-65 {
    --tw-backdrop-opacity: opacity(.65);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-85 {
    --tw-backdrop-opacity: opacity(.85);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-filter {
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-filter-none {
    backdrop-filter: none;
  }

  .sm\:transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:transition-none {
    transition-property: none;
  }

  .sm\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:delay-0 {
    transition-delay: 0s;
  }

  .sm\:delay-100 {
    transition-delay: .1s;
  }

  .sm\:delay-1000 {
    transition-delay: 1s;
  }

  .sm\:delay-150 {
    transition-delay: .15s;
  }

  .sm\:delay-200 {
    transition-delay: .2s;
  }

  .sm\:delay-300 {
    transition-delay: .3s;
  }

  .sm\:delay-500 {
    transition-delay: .5s;
  }

  .sm\:delay-700 {
    transition-delay: .7s;
  }

  .sm\:delay-75 {
    transition-delay: 75ms;
  }

  .sm\:duration-0 {
    transition-duration: 0s;
  }

  .sm\:duration-100 {
    transition-duration: .1s;
  }

  .sm\:duration-1000 {
    transition-duration: 1s;
  }

  .sm\:duration-150 {
    transition-duration: .15s;
  }

  .sm\:duration-200 {
    transition-duration: .2s;
  }

  .sm\:duration-300 {
    transition-duration: .3s;
  }

  .sm\:duration-500 {
    transition-duration: .5s;
  }

  .sm\:duration-700 {
    transition-duration: .7s;
  }

  .sm\:duration-75 {
    transition-duration: 75ms;
  }

  .sm\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .sm\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:ease-linear {
    transition-timing-function: linear;
  }

  .sm\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .sm\:will-change-auto {
    will-change: auto;
  }

  .sm\:will-change-contents {
    will-change: contents;
  }

  .sm\:will-change-scroll {
    will-change: scroll-position;
  }

  .sm\:will-change-transform {
    will-change: transform;
  }

  .sm\:contain-none {
    contain: none;
  }

  .sm\:contain-content {
    contain: content;
  }

  .sm\:contain-strict {
    contain: strict;
  }

  .sm\:contain-size {
    --tw-contain-size: size;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .sm\:contain-inline-size {
    --tw-contain-size: inline-size;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .sm\:contain-layout {
    --tw-contain-layout: layout;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .sm\:contain-paint {
    --tw-contain-paint: paint;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .sm\:contain-style {
    --tw-contain-style: style;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .sm\:content-none {
    --tw-content: none;
    content: var(--tw-content);
  }

  .sm\:forced-color-adjust-auto {
    forced-color-adjust: auto;
  }

  .sm\:forced-color-adjust-none {
    forced-color-adjust: none;
  }
}

@media (width >= 768px) {
  .md\:sr-only {
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .md\:not-sr-only {
    clip: auto;
    white-space: normal;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .md\:pointer-events-none {
    pointer-events: none;
  }

  .md\:pointer-events-auto {
    pointer-events: auto;
  }

  .md\:visible {
    visibility: visible;
  }

  .md\:invisible {
    visibility: hidden;
  }

  .md\:collapse {
    visibility: collapse;
  }

  .md\:static {
    position: static;
  }

  .md\:fixed {
    position: fixed;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:sticky {
    position: sticky;
  }

  .md\:-inset-0 {
    inset: 0;
  }

  .md\:-inset-0\.5 {
    inset: -.125rem;
  }

  .md\:-inset-1 {
    inset: -.25rem;
  }

  .md\:-inset-1\.5 {
    inset: -.375rem;
  }

  .md\:-inset-1\/2 {
    inset: -50%;
  }

  .md\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .md\:-inset-1\/4 {
    inset: -25%;
  }

  .md\:-inset-10 {
    inset: -2.5rem;
  }

  .md\:-inset-11 {
    inset: -2.75rem;
  }

  .md\:-inset-12 {
    inset: -3rem;
  }

  .md\:-inset-14 {
    inset: -3.5rem;
  }

  .md\:-inset-16 {
    inset: -4rem;
  }

  .md\:-inset-2 {
    inset: -.5rem;
  }

  .md\:-inset-2\.5 {
    inset: -.625rem;
  }

  .md\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .md\:-inset-2\/4 {
    inset: -50%;
  }

  .md\:-inset-20 {
    inset: -5rem;
  }

  .md\:-inset-24 {
    inset: -6rem;
  }

  .md\:-inset-28 {
    inset: -7rem;
  }

  .md\:-inset-3 {
    inset: -.75rem;
  }

  .md\:-inset-3\.5 {
    inset: -.875rem;
  }

  .md\:-inset-3\/4 {
    inset: -75%;
  }

  .md\:-inset-32 {
    inset: -8rem;
  }

  .md\:-inset-36 {
    inset: -9rem;
  }

  .md\:-inset-4 {
    inset: -1rem;
  }

  .md\:-inset-40 {
    inset: -10rem;
  }

  .md\:-inset-44 {
    inset: -11rem;
  }

  .md\:-inset-48 {
    inset: -12rem;
  }

  .md\:-inset-5 {
    inset: -1.25rem;
  }

  .md\:-inset-52 {
    inset: -13rem;
  }

  .md\:-inset-56 {
    inset: -14rem;
  }

  .md\:-inset-6 {
    inset: -1.5rem;
  }

  .md\:-inset-60 {
    inset: -15rem;
  }

  .md\:-inset-64 {
    inset: -16rem;
  }

  .md\:-inset-7 {
    inset: -1.75rem;
  }

  .md\:-inset-72 {
    inset: -18rem;
  }

  .md\:-inset-8 {
    inset: -2rem;
  }

  .md\:-inset-80 {
    inset: -20rem;
  }

  .md\:-inset-9 {
    inset: -2.25rem;
  }

  .md\:-inset-96 {
    inset: -24rem;
  }

  .md\:-inset-full {
    inset: -100%;
  }

  .md\:-inset-px {
    inset: -1px;
  }

  .md\:inset-0 {
    inset: 0;
  }

  .md\:inset-0\.5 {
    inset: .125rem;
  }

  .md\:inset-1 {
    inset: .25rem;
  }

  .md\:inset-1\.5 {
    inset: .375rem;
  }

  .md\:inset-1\/2 {
    inset: 50%;
  }

  .md\:inset-1\/3 {
    inset: 33.3333%;
  }

  .md\:inset-1\/4 {
    inset: 25%;
  }

  .md\:inset-10 {
    inset: 2.5rem;
  }

  .md\:inset-11 {
    inset: 2.75rem;
  }

  .md\:inset-12 {
    inset: 3rem;
  }

  .md\:inset-14 {
    inset: 3.5rem;
  }

  .md\:inset-16 {
    inset: 4rem;
  }

  .md\:inset-2 {
    inset: .5rem;
  }

  .md\:inset-2\.5 {
    inset: .625rem;
  }

  .md\:inset-2\/3 {
    inset: 66.6667%;
  }

  .md\:inset-2\/4 {
    inset: 50%;
  }

  .md\:inset-20 {
    inset: 5rem;
  }

  .md\:inset-24 {
    inset: 6rem;
  }

  .md\:inset-28 {
    inset: 7rem;
  }

  .md\:inset-3 {
    inset: .75rem;
  }

  .md\:inset-3\.5 {
    inset: .875rem;
  }

  .md\:inset-3\/4 {
    inset: 75%;
  }

  .md\:inset-32 {
    inset: 8rem;
  }

  .md\:inset-36 {
    inset: 9rem;
  }

  .md\:inset-4 {
    inset: 1rem;
  }

  .md\:inset-40 {
    inset: 10rem;
  }

  .md\:inset-44 {
    inset: 11rem;
  }

  .md\:inset-48 {
    inset: 12rem;
  }

  .md\:inset-5 {
    inset: 1.25rem;
  }

  .md\:inset-52 {
    inset: 13rem;
  }

  .md\:inset-56 {
    inset: 14rem;
  }

  .md\:inset-6 {
    inset: 1.5rem;
  }

  .md\:inset-60 {
    inset: 15rem;
  }

  .md\:inset-64 {
    inset: 16rem;
  }

  .md\:inset-7 {
    inset: 1.75rem;
  }

  .md\:inset-72 {
    inset: 18rem;
  }

  .md\:inset-8 {
    inset: 2rem;
  }

  .md\:inset-80 {
    inset: 20rem;
  }

  .md\:inset-9 {
    inset: 2.25rem;
  }

  .md\:inset-96 {
    inset: 24rem;
  }

  .md\:inset-auto {
    inset: auto;
  }

  .md\:inset-full {
    inset: 100%;
  }

  .md\:inset-px {
    inset: 1px;
  }

  .md\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .md\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .md\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .md\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .md\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .md\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .md\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .md\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .md\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .md\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .md\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .md\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .md\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .md\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .md\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .md\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .md\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .md\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .md\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .md\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .md\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .md\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .md\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .md\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .md\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .md\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .md\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .md\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .md\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .md\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .md\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .md\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .md\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .md\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .md\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .md\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .md\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .md\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .md\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .md\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .md\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .md\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .md\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .md\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .md\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .md\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .md\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .md\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .md\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .md\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .md\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .md\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .md\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .md\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .md\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .md\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .md\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .md\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .md\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .md\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .md\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .md\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .md\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .md\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .md\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .md\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .md\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .md\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .md\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .md\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .md\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .md\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .md\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .md\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .md\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .md\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .md\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .md\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .md\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .md\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .md\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .md\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .md\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .md\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .md\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .md\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .md\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .md\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .md\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .md\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .md\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .md\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .md\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .md\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .md\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .md\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .md\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .md\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .md\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .md\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .md\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .md\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .md\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .md\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .md\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .md\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .md\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .md\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .md\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .md\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .md\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .md\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .md\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .md\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .md\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .md\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .md\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .md\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .md\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .md\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .md\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .md\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .md\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .md\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .md\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .md\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .md\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .md\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .md\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .md\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .md\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .md\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .md\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .md\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .md\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .md\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .md\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .md\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .md\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .md\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .md\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .md\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .md\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .md\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .md\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .md\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .md\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .md\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .md\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .md\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .md\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .md\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .md\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .md\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .md\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .md\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .md\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .md\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .md\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .md\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .md\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .md\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .md\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .md\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .md\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .md\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .md\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .md\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .md\:-bottom-0 {
    bottom: 0;
  }

  .md\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .md\:-bottom-1 {
    bottom: -.25rem;
  }

  .md\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .md\:-bottom-1\/2 {
    bottom: -50%;
  }

  .md\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .md\:-bottom-1\/4 {
    bottom: -25%;
  }

  .md\:-bottom-10 {
    bottom: -2.5rem;
  }

  .md\:-bottom-11 {
    bottom: -2.75rem;
  }

  .md\:-bottom-12 {
    bottom: -3rem;
  }

  .md\:-bottom-14 {
    bottom: -3.5rem;
  }

  .md\:-bottom-16 {
    bottom: -4rem;
  }

  .md\:-bottom-2 {
    bottom: -.5rem;
  }

  .md\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .md\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .md\:-bottom-2\/4 {
    bottom: -50%;
  }

  .md\:-bottom-20 {
    bottom: -5rem;
  }

  .md\:-bottom-24 {
    bottom: -6rem;
  }

  .md\:-bottom-28 {
    bottom: -7rem;
  }

  .md\:-bottom-3 {
    bottom: -.75rem;
  }

  .md\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .md\:-bottom-3\/4 {
    bottom: -75%;
  }

  .md\:-bottom-32 {
    bottom: -8rem;
  }

  .md\:-bottom-36 {
    bottom: -9rem;
  }

  .md\:-bottom-4 {
    bottom: -1rem;
  }

  .md\:-bottom-40 {
    bottom: -10rem;
  }

  .md\:-bottom-44 {
    bottom: -11rem;
  }

  .md\:-bottom-48 {
    bottom: -12rem;
  }

  .md\:-bottom-5 {
    bottom: -1.25rem;
  }

  .md\:-bottom-52 {
    bottom: -13rem;
  }

  .md\:-bottom-56 {
    bottom: -14rem;
  }

  .md\:-bottom-6 {
    bottom: -1.5rem;
  }

  .md\:-bottom-60 {
    bottom: -15rem;
  }

  .md\:-bottom-64 {
    bottom: -16rem;
  }

  .md\:-bottom-7 {
    bottom: -1.75rem;
  }

  .md\:-bottom-72 {
    bottom: -18rem;
  }

  .md\:-bottom-8 {
    bottom: -2rem;
  }

  .md\:-bottom-80 {
    bottom: -20rem;
  }

  .md\:-bottom-9 {
    bottom: -2.25rem;
  }

  .md\:-bottom-96 {
    bottom: -24rem;
  }

  .md\:-bottom-full {
    bottom: -100%;
  }

  .md\:-bottom-px {
    bottom: -1px;
  }

  .md\:-end-0 {
    inset-inline-end: 0;
  }

  .md\:-end-0\.5 {
    inset-inline-end: -.125rem;
  }

  .md\:-end-1 {
    inset-inline-end: -.25rem;
  }

  .md\:-end-1\.5 {
    inset-inline-end: -.375rem;
  }

  .md\:-end-1\/2 {
    inset-inline-end: -50%;
  }

  .md\:-end-1\/3 {
    inset-inline-end: -33.3333%;
  }

  .md\:-end-1\/4 {
    inset-inline-end: -25%;
  }

  .md\:-end-10 {
    inset-inline-end: -2.5rem;
  }

  .md\:-end-11 {
    inset-inline-end: -2.75rem;
  }

  .md\:-end-12 {
    inset-inline-end: -3rem;
  }

  .md\:-end-14 {
    inset-inline-end: -3.5rem;
  }

  .md\:-end-16 {
    inset-inline-end: -4rem;
  }

  .md\:-end-2 {
    inset-inline-end: -.5rem;
  }

  .md\:-end-2\.5 {
    inset-inline-end: -.625rem;
  }

  .md\:-end-2\/3 {
    inset-inline-end: -66.6667%;
  }

  .md\:-end-2\/4 {
    inset-inline-end: -50%;
  }

  .md\:-end-20 {
    inset-inline-end: -5rem;
  }

  .md\:-end-24 {
    inset-inline-end: -6rem;
  }

  .md\:-end-28 {
    inset-inline-end: -7rem;
  }

  .md\:-end-3 {
    inset-inline-end: -.75rem;
  }

  .md\:-end-3\.5 {
    inset-inline-end: -.875rem;
  }

  .md\:-end-3\/4 {
    inset-inline-end: -75%;
  }

  .md\:-end-32 {
    inset-inline-end: -8rem;
  }

  .md\:-end-36 {
    inset-inline-end: -9rem;
  }

  .md\:-end-4 {
    inset-inline-end: -1rem;
  }

  .md\:-end-40 {
    inset-inline-end: -10rem;
  }

  .md\:-end-44 {
    inset-inline-end: -11rem;
  }

  .md\:-end-48 {
    inset-inline-end: -12rem;
  }

  .md\:-end-5 {
    inset-inline-end: -1.25rem;
  }

  .md\:-end-52 {
    inset-inline-end: -13rem;
  }

  .md\:-end-56 {
    inset-inline-end: -14rem;
  }

  .md\:-end-6 {
    inset-inline-end: -1.5rem;
  }

  .md\:-end-60 {
    inset-inline-end: -15rem;
  }

  .md\:-end-64 {
    inset-inline-end: -16rem;
  }

  .md\:-end-7 {
    inset-inline-end: -1.75rem;
  }

  .md\:-end-72 {
    inset-inline-end: -18rem;
  }

  .md\:-end-8 {
    inset-inline-end: -2rem;
  }

  .md\:-end-80 {
    inset-inline-end: -20rem;
  }

  .md\:-end-9 {
    inset-inline-end: -2.25rem;
  }

  .md\:-end-96 {
    inset-inline-end: -24rem;
  }

  .md\:-end-full {
    inset-inline-end: -100%;
  }

  .md\:-end-px {
    inset-inline-end: -1px;
  }

  .md\:-left-0 {
    left: 0;
  }

  .md\:-left-0\.5 {
    left: -.125rem;
  }

  .md\:-left-1 {
    left: -.25rem;
  }

  .md\:-left-1\.5 {
    left: -.375rem;
  }

  .md\:-left-1\/2 {
    left: -50%;
  }

  .md\:-left-1\/3 {
    left: -33.3333%;
  }

  .md\:-left-1\/4 {
    left: -25%;
  }

  .md\:-left-10 {
    left: -2.5rem;
  }

  .md\:-left-11 {
    left: -2.75rem;
  }

  .md\:-left-12 {
    left: -3rem;
  }

  .md\:-left-14 {
    left: -3.5rem;
  }

  .md\:-left-16 {
    left: -4rem;
  }

  .md\:-left-2 {
    left: -.5rem;
  }

  .md\:-left-2\.5 {
    left: -.625rem;
  }

  .md\:-left-2\/3 {
    left: -66.6667%;
  }

  .md\:-left-2\/4 {
    left: -50%;
  }

  .md\:-left-20 {
    left: -5rem;
  }

  .md\:-left-24 {
    left: -6rem;
  }

  .md\:-left-28 {
    left: -7rem;
  }

  .md\:-left-3 {
    left: -.75rem;
  }

  .md\:-left-3\.5 {
    left: -.875rem;
  }

  .md\:-left-3\/4 {
    left: -75%;
  }

  .md\:-left-32 {
    left: -8rem;
  }

  .md\:-left-36 {
    left: -9rem;
  }

  .md\:-left-4 {
    left: -1rem;
  }

  .md\:-left-40 {
    left: -10rem;
  }

  .md\:-left-44 {
    left: -11rem;
  }

  .md\:-left-48 {
    left: -12rem;
  }

  .md\:-left-5 {
    left: -1.25rem;
  }

  .md\:-left-52 {
    left: -13rem;
  }

  .md\:-left-56 {
    left: -14rem;
  }

  .md\:-left-6 {
    left: -1.5rem;
  }

  .md\:-left-60 {
    left: -15rem;
  }

  .md\:-left-64 {
    left: -16rem;
  }

  .md\:-left-7 {
    left: -1.75rem;
  }

  .md\:-left-72 {
    left: -18rem;
  }

  .md\:-left-8 {
    left: -2rem;
  }

  .md\:-left-80 {
    left: -20rem;
  }

  .md\:-left-9 {
    left: -2.25rem;
  }

  .md\:-left-96 {
    left: -24rem;
  }

  .md\:-left-full {
    left: -100%;
  }

  .md\:-left-px {
    left: -1px;
  }

  .md\:-right-0 {
    right: 0;
  }

  .md\:-right-0\.5 {
    right: -.125rem;
  }

  .md\:-right-1 {
    right: -.25rem;
  }

  .md\:-right-1\.5 {
    right: -.375rem;
  }

  .md\:-right-1\/2 {
    right: -50%;
  }

  .md\:-right-1\/3 {
    right: -33.3333%;
  }

  .md\:-right-1\/4 {
    right: -25%;
  }

  .md\:-right-10 {
    right: -2.5rem;
  }

  .md\:-right-11 {
    right: -2.75rem;
  }

  .md\:-right-12 {
    right: -3rem;
  }

  .md\:-right-14 {
    right: -3.5rem;
  }

  .md\:-right-16 {
    right: -4rem;
  }

  .md\:-right-2 {
    right: -.5rem;
  }

  .md\:-right-2\.5 {
    right: -.625rem;
  }

  .md\:-right-2\/3 {
    right: -66.6667%;
  }

  .md\:-right-2\/4 {
    right: -50%;
  }

  .md\:-right-20 {
    right: -5rem;
  }

  .md\:-right-24 {
    right: -6rem;
  }

  .md\:-right-28 {
    right: -7rem;
  }

  .md\:-right-3 {
    right: -.75rem;
  }

  .md\:-right-3\.5 {
    right: -.875rem;
  }

  .md\:-right-3\/4 {
    right: -75%;
  }

  .md\:-right-32 {
    right: -8rem;
  }

  .md\:-right-36 {
    right: -9rem;
  }

  .md\:-right-4 {
    right: -1rem;
  }

  .md\:-right-40 {
    right: -10rem;
  }

  .md\:-right-44 {
    right: -11rem;
  }

  .md\:-right-48 {
    right: -12rem;
  }

  .md\:-right-5 {
    right: -1.25rem;
  }

  .md\:-right-52 {
    right: -13rem;
  }

  .md\:-right-56 {
    right: -14rem;
  }

  .md\:-right-6 {
    right: -1.5rem;
  }

  .md\:-right-60 {
    right: -15rem;
  }

  .md\:-right-64 {
    right: -16rem;
  }

  .md\:-right-7 {
    right: -1.75rem;
  }

  .md\:-right-72 {
    right: -18rem;
  }

  .md\:-right-8 {
    right: -2rem;
  }

  .md\:-right-80 {
    right: -20rem;
  }

  .md\:-right-9 {
    right: -2.25rem;
  }

  .md\:-right-96 {
    right: -24rem;
  }

  .md\:-right-full {
    right: -100%;
  }

  .md\:-right-px {
    right: -1px;
  }

  .md\:-start-0 {
    inset-inline-start: 0;
  }

  .md\:-start-0\.5 {
    inset-inline-start: -.125rem;
  }

  .md\:-start-1 {
    inset-inline-start: -.25rem;
  }

  .md\:-start-1\.5 {
    inset-inline-start: -.375rem;
  }

  .md\:-start-1\/2 {
    inset-inline-start: -50%;
  }

  .md\:-start-1\/3 {
    inset-inline-start: -33.3333%;
  }

  .md\:-start-1\/4 {
    inset-inline-start: -25%;
  }

  .md\:-start-10 {
    inset-inline-start: -2.5rem;
  }

  .md\:-start-11 {
    inset-inline-start: -2.75rem;
  }

  .md\:-start-12 {
    inset-inline-start: -3rem;
  }

  .md\:-start-14 {
    inset-inline-start: -3.5rem;
  }

  .md\:-start-16 {
    inset-inline-start: -4rem;
  }

  .md\:-start-2 {
    inset-inline-start: -.5rem;
  }

  .md\:-start-2\.5 {
    inset-inline-start: -.625rem;
  }

  .md\:-start-2\/3 {
    inset-inline-start: -66.6667%;
  }

  .md\:-start-2\/4 {
    inset-inline-start: -50%;
  }

  .md\:-start-20 {
    inset-inline-start: -5rem;
  }

  .md\:-start-24 {
    inset-inline-start: -6rem;
  }

  .md\:-start-28 {
    inset-inline-start: -7rem;
  }

  .md\:-start-3 {
    inset-inline-start: -.75rem;
  }

  .md\:-start-3\.5 {
    inset-inline-start: -.875rem;
  }

  .md\:-start-3\/4 {
    inset-inline-start: -75%;
  }

  .md\:-start-32 {
    inset-inline-start: -8rem;
  }

  .md\:-start-36 {
    inset-inline-start: -9rem;
  }

  .md\:-start-4 {
    inset-inline-start: -1rem;
  }

  .md\:-start-40 {
    inset-inline-start: -10rem;
  }

  .md\:-start-44 {
    inset-inline-start: -11rem;
  }

  .md\:-start-48 {
    inset-inline-start: -12rem;
  }

  .md\:-start-5 {
    inset-inline-start: -1.25rem;
  }

  .md\:-start-52 {
    inset-inline-start: -13rem;
  }

  .md\:-start-56 {
    inset-inline-start: -14rem;
  }

  .md\:-start-6 {
    inset-inline-start: -1.5rem;
  }

  .md\:-start-60 {
    inset-inline-start: -15rem;
  }

  .md\:-start-64 {
    inset-inline-start: -16rem;
  }

  .md\:-start-7 {
    inset-inline-start: -1.75rem;
  }

  .md\:-start-72 {
    inset-inline-start: -18rem;
  }

  .md\:-start-8 {
    inset-inline-start: -2rem;
  }

  .md\:-start-80 {
    inset-inline-start: -20rem;
  }

  .md\:-start-9 {
    inset-inline-start: -2.25rem;
  }

  .md\:-start-96 {
    inset-inline-start: -24rem;
  }

  .md\:-start-full {
    inset-inline-start: -100%;
  }

  .md\:-start-px {
    inset-inline-start: -1px;
  }

  .md\:-top-0 {
    top: 0;
  }

  .md\:-top-0\.5 {
    top: -.125rem;
  }

  .md\:-top-1 {
    top: -.25rem;
  }

  .md\:-top-1\.5 {
    top: -.375rem;
  }

  .md\:-top-1\/2 {
    top: -50%;
  }

  .md\:-top-1\/3 {
    top: -33.3333%;
  }

  .md\:-top-1\/4 {
    top: -25%;
  }

  .md\:-top-10 {
    top: -2.5rem;
  }

  .md\:-top-11 {
    top: -2.75rem;
  }

  .md\:-top-12 {
    top: -3rem;
  }

  .md\:-top-14 {
    top: -3.5rem;
  }

  .md\:-top-16 {
    top: -4rem;
  }

  .md\:-top-2 {
    top: -.5rem;
  }

  .md\:-top-2\.5 {
    top: -.625rem;
  }

  .md\:-top-2\/3 {
    top: -66.6667%;
  }

  .md\:-top-2\/4 {
    top: -50%;
  }

  .md\:-top-20 {
    top: -5rem;
  }

  .md\:-top-24 {
    top: -6rem;
  }

  .md\:-top-28 {
    top: -7rem;
  }

  .md\:-top-3 {
    top: -.75rem;
  }

  .md\:-top-3\.5 {
    top: -.875rem;
  }

  .md\:-top-3\/4 {
    top: -75%;
  }

  .md\:-top-32 {
    top: -8rem;
  }

  .md\:-top-36 {
    top: -9rem;
  }

  .md\:-top-4 {
    top: -1rem;
  }

  .md\:-top-40 {
    top: -10rem;
  }

  .md\:-top-44 {
    top: -11rem;
  }

  .md\:-top-48 {
    top: -12rem;
  }

  .md\:-top-5 {
    top: -1.25rem;
  }

  .md\:-top-52 {
    top: -13rem;
  }

  .md\:-top-56 {
    top: -14rem;
  }

  .md\:-top-6 {
    top: -1.5rem;
  }

  .md\:-top-60 {
    top: -15rem;
  }

  .md\:-top-64 {
    top: -16rem;
  }

  .md\:-top-7 {
    top: -1.75rem;
  }

  .md\:-top-72 {
    top: -18rem;
  }

  .md\:-top-8 {
    top: -2rem;
  }

  .md\:-top-80 {
    top: -20rem;
  }

  .md\:-top-9 {
    top: -2.25rem;
  }

  .md\:-top-96 {
    top: -24rem;
  }

  .md\:-top-full {
    top: -100%;
  }

  .md\:-top-px {
    top: -1px;
  }

  .md\:bottom-0 {
    bottom: 0;
  }

  .md\:bottom-0\.5 {
    bottom: .125rem;
  }

  .md\:bottom-1 {
    bottom: .25rem;
  }

  .md\:bottom-1\.5 {
    bottom: .375rem;
  }

  .md\:bottom-1\/2 {
    bottom: 50%;
  }

  .md\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .md\:bottom-1\/4 {
    bottom: 25%;
  }

  .md\:bottom-10 {
    bottom: 2.5rem;
  }

  .md\:bottom-11 {
    bottom: 2.75rem;
  }

  .md\:bottom-12 {
    bottom: 3rem;
  }

  .md\:bottom-14 {
    bottom: 3.5rem;
  }

  .md\:bottom-16 {
    bottom: 4rem;
  }

  .md\:bottom-2 {
    bottom: .5rem;
  }

  .md\:bottom-2\.5 {
    bottom: .625rem;
  }

  .md\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .md\:bottom-2\/4 {
    bottom: 50%;
  }

  .md\:bottom-20 {
    bottom: 5rem;
  }

  .md\:bottom-24 {
    bottom: 6rem;
  }

  .md\:bottom-28 {
    bottom: 7rem;
  }

  .md\:bottom-3 {
    bottom: .75rem;
  }

  .md\:bottom-3\.5 {
    bottom: .875rem;
  }

  .md\:bottom-3\/4 {
    bottom: 75%;
  }

  .md\:bottom-32 {
    bottom: 8rem;
  }

  .md\:bottom-36 {
    bottom: 9rem;
  }

  .md\:bottom-4 {
    bottom: 1rem;
  }

  .md\:bottom-40 {
    bottom: 10rem;
  }

  .md\:bottom-44 {
    bottom: 11rem;
  }

  .md\:bottom-48 {
    bottom: 12rem;
  }

  .md\:bottom-5 {
    bottom: 1.25rem;
  }

  .md\:bottom-52 {
    bottom: 13rem;
  }

  .md\:bottom-56 {
    bottom: 14rem;
  }

  .md\:bottom-6 {
    bottom: 1.5rem;
  }

  .md\:bottom-60 {
    bottom: 15rem;
  }

  .md\:bottom-64 {
    bottom: 16rem;
  }

  .md\:bottom-7 {
    bottom: 1.75rem;
  }

  .md\:bottom-72 {
    bottom: 18rem;
  }

  .md\:bottom-8 {
    bottom: 2rem;
  }

  .md\:bottom-80 {
    bottom: 20rem;
  }

  .md\:bottom-9 {
    bottom: 2.25rem;
  }

  .md\:bottom-96 {
    bottom: 24rem;
  }

  .md\:bottom-auto {
    bottom: auto;
  }

  .md\:bottom-full {
    bottom: 100%;
  }

  .md\:bottom-px {
    bottom: 1px;
  }

  .md\:end-0 {
    inset-inline-end: 0;
  }

  .md\:end-0\.5 {
    inset-inline-end: .125rem;
  }

  .md\:end-1 {
    inset-inline-end: .25rem;
  }

  .md\:end-1\.5 {
    inset-inline-end: .375rem;
  }

  .md\:end-1\/2 {
    inset-inline-end: 50%;
  }

  .md\:end-1\/3 {
    inset-inline-end: 33.3333%;
  }

  .md\:end-1\/4 {
    inset-inline-end: 25%;
  }

  .md\:end-10 {
    inset-inline-end: 2.5rem;
  }

  .md\:end-11 {
    inset-inline-end: 2.75rem;
  }

  .md\:end-12 {
    inset-inline-end: 3rem;
  }

  .md\:end-14 {
    inset-inline-end: 3.5rem;
  }

  .md\:end-16 {
    inset-inline-end: 4rem;
  }

  .md\:end-2 {
    inset-inline-end: .5rem;
  }

  .md\:end-2\.5 {
    inset-inline-end: .625rem;
  }

  .md\:end-2\/3 {
    inset-inline-end: 66.6667%;
  }

  .md\:end-2\/4 {
    inset-inline-end: 50%;
  }

  .md\:end-20 {
    inset-inline-end: 5rem;
  }

  .md\:end-24 {
    inset-inline-end: 6rem;
  }

  .md\:end-28 {
    inset-inline-end: 7rem;
  }

  .md\:end-3 {
    inset-inline-end: .75rem;
  }

  .md\:end-3\.5 {
    inset-inline-end: .875rem;
  }

  .md\:end-3\/4 {
    inset-inline-end: 75%;
  }

  .md\:end-32 {
    inset-inline-end: 8rem;
  }

  .md\:end-36 {
    inset-inline-end: 9rem;
  }

  .md\:end-4 {
    inset-inline-end: 1rem;
  }

  .md\:end-40 {
    inset-inline-end: 10rem;
  }

  .md\:end-44 {
    inset-inline-end: 11rem;
  }

  .md\:end-48 {
    inset-inline-end: 12rem;
  }

  .md\:end-5 {
    inset-inline-end: 1.25rem;
  }

  .md\:end-52 {
    inset-inline-end: 13rem;
  }

  .md\:end-56 {
    inset-inline-end: 14rem;
  }

  .md\:end-6 {
    inset-inline-end: 1.5rem;
  }

  .md\:end-60 {
    inset-inline-end: 15rem;
  }

  .md\:end-64 {
    inset-inline-end: 16rem;
  }

  .md\:end-7 {
    inset-inline-end: 1.75rem;
  }

  .md\:end-72 {
    inset-inline-end: 18rem;
  }

  .md\:end-8 {
    inset-inline-end: 2rem;
  }

  .md\:end-80 {
    inset-inline-end: 20rem;
  }

  .md\:end-9 {
    inset-inline-end: 2.25rem;
  }

  .md\:end-96 {
    inset-inline-end: 24rem;
  }

  .md\:end-auto {
    inset-inline-end: auto;
  }

  .md\:end-full {
    inset-inline-end: 100%;
  }

  .md\:end-px {
    inset-inline-end: 1px;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:left-0\.5 {
    left: .125rem;
  }

  .md\:left-1 {
    left: .25rem;
  }

  .md\:left-1\.5 {
    left: .375rem;
  }

  .md\:left-1\/2 {
    left: 50%;
  }

  .md\:left-1\/3 {
    left: 33.3333%;
  }

  .md\:left-1\/4 {
    left: 25%;
  }

  .md\:left-10 {
    left: 2.5rem;
  }

  .md\:left-11 {
    left: 2.75rem;
  }

  .md\:left-12 {
    left: 3rem;
  }

  .md\:left-14 {
    left: 3.5rem;
  }

  .md\:left-16 {
    left: 4rem;
  }

  .md\:left-2 {
    left: .5rem;
  }

  .md\:left-2\.5 {
    left: .625rem;
  }

  .md\:left-2\/3 {
    left: 66.6667%;
  }

  .md\:left-2\/4 {
    left: 50%;
  }

  .md\:left-20 {
    left: 5rem;
  }

  .md\:left-24 {
    left: 6rem;
  }

  .md\:left-28 {
    left: 7rem;
  }

  .md\:left-3 {
    left: .75rem;
  }

  .md\:left-3\.5 {
    left: .875rem;
  }

  .md\:left-3\/4 {
    left: 75%;
  }

  .md\:left-32 {
    left: 8rem;
  }

  .md\:left-36 {
    left: 9rem;
  }

  .md\:left-4 {
    left: 1rem;
  }

  .md\:left-40 {
    left: 10rem;
  }

  .md\:left-44 {
    left: 11rem;
  }

  .md\:left-48 {
    left: 12rem;
  }

  .md\:left-5 {
    left: 1.25rem;
  }

  .md\:left-52 {
    left: 13rem;
  }

  .md\:left-56 {
    left: 14rem;
  }

  .md\:left-6 {
    left: 1.5rem;
  }

  .md\:left-60 {
    left: 15rem;
  }

  .md\:left-64 {
    left: 16rem;
  }

  .md\:left-7 {
    left: 1.75rem;
  }

  .md\:left-72 {
    left: 18rem;
  }

  .md\:left-8 {
    left: 2rem;
  }

  .md\:left-80 {
    left: 20rem;
  }

  .md\:left-9 {
    left: 2.25rem;
  }

  .md\:left-96 {
    left: 24rem;
  }

  .md\:left-auto {
    left: auto;
  }

  .md\:left-full {
    left: 100%;
  }

  .md\:left-px {
    left: 1px;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:right-0\.5 {
    right: .125rem;
  }

  .md\:right-1 {
    right: .25rem;
  }

  .md\:right-1\.5 {
    right: .375rem;
  }

  .md\:right-1\/2 {
    right: 50%;
  }

  .md\:right-1\/3 {
    right: 33.3333%;
  }

  .md\:right-1\/4 {
    right: 25%;
  }

  .md\:right-10 {
    right: 2.5rem;
  }

  .md\:right-11 {
    right: 2.75rem;
  }

  .md\:right-12 {
    right: 3rem;
  }

  .md\:right-14 {
    right: 3.5rem;
  }

  .md\:right-16 {
    right: 4rem;
  }

  .md\:right-2 {
    right: .5rem;
  }

  .md\:right-2\.5 {
    right: .625rem;
  }

  .md\:right-2\/3 {
    right: 66.6667%;
  }

  .md\:right-2\/4 {
    right: 50%;
  }

  .md\:right-20 {
    right: 5rem;
  }

  .md\:right-24 {
    right: 6rem;
  }

  .md\:right-28 {
    right: 7rem;
  }

  .md\:right-3 {
    right: .75rem;
  }

  .md\:right-3\.5 {
    right: .875rem;
  }

  .md\:right-3\/4 {
    right: 75%;
  }

  .md\:right-32 {
    right: 8rem;
  }

  .md\:right-36 {
    right: 9rem;
  }

  .md\:right-4 {
    right: 1rem;
  }

  .md\:right-40 {
    right: 10rem;
  }

  .md\:right-44 {
    right: 11rem;
  }

  .md\:right-48 {
    right: 12rem;
  }

  .md\:right-5 {
    right: 1.25rem;
  }

  .md\:right-52 {
    right: 13rem;
  }

  .md\:right-56 {
    right: 14rem;
  }

  .md\:right-6 {
    right: 1.5rem;
  }

  .md\:right-60 {
    right: 15rem;
  }

  .md\:right-64 {
    right: 16rem;
  }

  .md\:right-7 {
    right: 1.75rem;
  }

  .md\:right-72 {
    right: 18rem;
  }

  .md\:right-8 {
    right: 2rem;
  }

  .md\:right-80 {
    right: 20rem;
  }

  .md\:right-9 {
    right: 2.25rem;
  }

  .md\:right-96 {
    right: 24rem;
  }

  .md\:right-auto {
    right: auto;
  }

  .md\:right-full {
    right: 100%;
  }

  .md\:right-px {
    right: 1px;
  }

  .md\:start-0 {
    inset-inline-start: 0;
  }

  .md\:start-0\.5 {
    inset-inline-start: .125rem;
  }

  .md\:start-1 {
    inset-inline-start: .25rem;
  }

  .md\:start-1\.5 {
    inset-inline-start: .375rem;
  }

  .md\:start-1\/2 {
    inset-inline-start: 50%;
  }

  .md\:start-1\/3 {
    inset-inline-start: 33.3333%;
  }

  .md\:start-1\/4 {
    inset-inline-start: 25%;
  }

  .md\:start-10 {
    inset-inline-start: 2.5rem;
  }

  .md\:start-11 {
    inset-inline-start: 2.75rem;
  }

  .md\:start-12 {
    inset-inline-start: 3rem;
  }

  .md\:start-14 {
    inset-inline-start: 3.5rem;
  }

  .md\:start-16 {
    inset-inline-start: 4rem;
  }

  .md\:start-2 {
    inset-inline-start: .5rem;
  }

  .md\:start-2\.5 {
    inset-inline-start: .625rem;
  }

  .md\:start-2\/3 {
    inset-inline-start: 66.6667%;
  }

  .md\:start-2\/4 {
    inset-inline-start: 50%;
  }

  .md\:start-20 {
    inset-inline-start: 5rem;
  }

  .md\:start-24 {
    inset-inline-start: 6rem;
  }

  .md\:start-28 {
    inset-inline-start: 7rem;
  }

  .md\:start-3 {
    inset-inline-start: .75rem;
  }

  .md\:start-3\.5 {
    inset-inline-start: .875rem;
  }

  .md\:start-3\/4 {
    inset-inline-start: 75%;
  }

  .md\:start-32 {
    inset-inline-start: 8rem;
  }

  .md\:start-36 {
    inset-inline-start: 9rem;
  }

  .md\:start-4 {
    inset-inline-start: 1rem;
  }

  .md\:start-40 {
    inset-inline-start: 10rem;
  }

  .md\:start-44 {
    inset-inline-start: 11rem;
  }

  .md\:start-48 {
    inset-inline-start: 12rem;
  }

  .md\:start-5 {
    inset-inline-start: 1.25rem;
  }

  .md\:start-52 {
    inset-inline-start: 13rem;
  }

  .md\:start-56 {
    inset-inline-start: 14rem;
  }

  .md\:start-6 {
    inset-inline-start: 1.5rem;
  }

  .md\:start-60 {
    inset-inline-start: 15rem;
  }

  .md\:start-64 {
    inset-inline-start: 16rem;
  }

  .md\:start-7 {
    inset-inline-start: 1.75rem;
  }

  .md\:start-72 {
    inset-inline-start: 18rem;
  }

  .md\:start-8 {
    inset-inline-start: 2rem;
  }

  .md\:start-80 {
    inset-inline-start: 20rem;
  }

  .md\:start-9 {
    inset-inline-start: 2.25rem;
  }

  .md\:start-96 {
    inset-inline-start: 24rem;
  }

  .md\:start-auto {
    inset-inline-start: auto;
  }

  .md\:start-full {
    inset-inline-start: 100%;
  }

  .md\:start-px {
    inset-inline-start: 1px;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-0\.5 {
    top: .125rem;
  }

  .md\:top-1 {
    top: .25rem;
  }

  .md\:top-1\.5 {
    top: .375rem;
  }

  .md\:top-1\/2 {
    top: 50%;
  }

  .md\:top-1\/3 {
    top: 33.3333%;
  }

  .md\:top-1\/4 {
    top: 25%;
  }

  .md\:top-10 {
    top: 2.5rem;
  }

  .md\:top-11 {
    top: 2.75rem;
  }

  .md\:top-12 {
    top: 3rem;
  }

  .md\:top-14 {
    top: 3.5rem;
  }

  .md\:top-16 {
    top: 4rem;
  }

  .md\:top-2 {
    top: .5rem;
  }

  .md\:top-2\.5 {
    top: .625rem;
  }

  .md\:top-2\/3 {
    top: 66.6667%;
  }

  .md\:top-2\/4 {
    top: 50%;
  }

  .md\:top-20 {
    top: 5rem;
  }

  .md\:top-24 {
    top: 6rem;
  }

  .md\:top-28 {
    top: 7rem;
  }

  .md\:top-3 {
    top: .75rem;
  }

  .md\:top-3\.5 {
    top: .875rem;
  }

  .md\:top-3\/4 {
    top: 75%;
  }

  .md\:top-32 {
    top: 8rem;
  }

  .md\:top-36 {
    top: 9rem;
  }

  .md\:top-4 {
    top: 1rem;
  }

  .md\:top-40 {
    top: 10rem;
  }

  .md\:top-44 {
    top: 11rem;
  }

  .md\:top-48 {
    top: 12rem;
  }

  .md\:top-5 {
    top: 1.25rem;
  }

  .md\:top-52 {
    top: 13rem;
  }

  .md\:top-56 {
    top: 14rem;
  }

  .md\:top-6 {
    top: 1.5rem;
  }

  .md\:top-60 {
    top: 15rem;
  }

  .md\:top-64 {
    top: 16rem;
  }

  .md\:top-7 {
    top: 1.75rem;
  }

  .md\:top-72 {
    top: 18rem;
  }

  .md\:top-8 {
    top: 2rem;
  }

  .md\:top-80 {
    top: 20rem;
  }

  .md\:top-9 {
    top: 2.25rem;
  }

  .md\:top-96 {
    top: 24rem;
  }

  .md\:top-auto {
    top: auto;
  }

  .md\:top-full {
    top: 100%;
  }

  .md\:top-px {
    top: 1px;
  }

  .md\:isolate {
    isolation: isolate;
  }

  .md\:isolation-auto {
    isolation: auto;
  }

  .md\:-z-0 {
    z-index: 0;
  }

  .md\:-z-10 {
    z-index: -10;
  }

  .md\:-z-20 {
    z-index: -20;
  }

  .md\:-z-30 {
    z-index: -30;
  }

  .md\:-z-40 {
    z-index: -40;
  }

  .md\:-z-50 {
    z-index: -50;
  }

  .md\:z-0 {
    z-index: 0;
  }

  .md\:z-10 {
    z-index: 10;
  }

  .md\:z-20 {
    z-index: 20;
  }

  .md\:z-30 {
    z-index: 30;
  }

  .md\:z-40 {
    z-index: 40;
  }

  .md\:z-50 {
    z-index: 50;
  }

  .md\:z-auto {
    z-index: auto;
  }

  .md\:-order-1 {
    order: -1;
  }

  .md\:-order-10 {
    order: -10;
  }

  .md\:-order-11 {
    order: -11;
  }

  .md\:-order-12 {
    order: -12;
  }

  .md\:-order-2 {
    order: -2;
  }

  .md\:-order-3 {
    order: -3;
  }

  .md\:-order-4 {
    order: -4;
  }

  .md\:-order-5 {
    order: -5;
  }

  .md\:-order-6 {
    order: -6;
  }

  .md\:-order-7 {
    order: -7;
  }

  .md\:-order-8 {
    order: -8;
  }

  .md\:-order-9 {
    order: -9;
  }

  .md\:-order-first {
    order: 9999;
  }

  .md\:-order-last {
    order: -9999;
  }

  .md\:-order-none {
    order: 0;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-10 {
    order: 10;
  }

  .md\:order-11 {
    order: 11;
  }

  .md\:order-12 {
    order: 12;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:order-3 {
    order: 3;
  }

  .md\:order-4 {
    order: 4;
  }

  .md\:order-5 {
    order: 5;
  }

  .md\:order-6 {
    order: 6;
  }

  .md\:order-7 {
    order: 7;
  }

  .md\:order-8 {
    order: 8;
  }

  .md\:order-9 {
    order: 9;
  }

  .md\:order-first {
    order: -9999;
  }

  .md\:order-last {
    order: 9999;
  }

  .md\:order-none {
    order: 0;
  }

  .md\:col-auto {
    grid-column: auto;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:col-span-full {
    grid-column: 1 / -1;
  }

  .md\:-col-start-1 {
    grid-column-start: -1;
  }

  .md\:-col-start-10 {
    grid-column-start: -10;
  }

  .md\:-col-start-11 {
    grid-column-start: -11;
  }

  .md\:-col-start-12 {
    grid-column-start: -12;
  }

  .md\:-col-start-13 {
    grid-column-start: -13;
  }

  .md\:-col-start-2 {
    grid-column-start: -2;
  }

  .md\:-col-start-3 {
    grid-column-start: -3;
  }

  .md\:-col-start-4 {
    grid-column-start: -4;
  }

  .md\:-col-start-5 {
    grid-column-start: -5;
  }

  .md\:-col-start-6 {
    grid-column-start: -6;
  }

  .md\:-col-start-7 {
    grid-column-start: -7;
  }

  .md\:-col-start-8 {
    grid-column-start: -8;
  }

  .md\:-col-start-9 {
    grid-column-start: -9;
  }

  .md\:col-start-1 {
    grid-column-start: 1;
  }

  .md\:col-start-10 {
    grid-column-start: 10;
  }

  .md\:col-start-11 {
    grid-column-start: 11;
  }

  .md\:col-start-12 {
    grid-column-start: 12;
  }

  .md\:col-start-13 {
    grid-column-start: 13;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-3 {
    grid-column-start: 3;
  }

  .md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-start-5 {
    grid-column-start: 5;
  }

  .md\:col-start-6 {
    grid-column-start: 6;
  }

  .md\:col-start-7 {
    grid-column-start: 7;
  }

  .md\:col-start-8 {
    grid-column-start: 8;
  }

  .md\:col-start-9 {
    grid-column-start: 9;
  }

  .md\:col-start-auto {
    grid-column-start: auto;
  }

  .md\:-col-end-1 {
    grid-column-end: -1;
  }

  .md\:-col-end-10 {
    grid-column-end: -10;
  }

  .md\:-col-end-11 {
    grid-column-end: -11;
  }

  .md\:-col-end-12 {
    grid-column-end: -12;
  }

  .md\:-col-end-13 {
    grid-column-end: -13;
  }

  .md\:-col-end-2 {
    grid-column-end: -2;
  }

  .md\:-col-end-3 {
    grid-column-end: -3;
  }

  .md\:-col-end-4 {
    grid-column-end: -4;
  }

  .md\:-col-end-5 {
    grid-column-end: -5;
  }

  .md\:-col-end-6 {
    grid-column-end: -6;
  }

  .md\:-col-end-7 {
    grid-column-end: -7;
  }

  .md\:-col-end-8 {
    grid-column-end: -8;
  }

  .md\:-col-end-9 {
    grid-column-end: -9;
  }

  .md\:col-end-1 {
    grid-column-end: 1;
  }

  .md\:col-end-10 {
    grid-column-end: 10;
  }

  .md\:col-end-11 {
    grid-column-end: 11;
  }

  .md\:col-end-12 {
    grid-column-end: 12;
  }

  .md\:col-end-13 {
    grid-column-end: 13;
  }

  .md\:col-end-2 {
    grid-column-end: 2;
  }

  .md\:col-end-3 {
    grid-column-end: 3;
  }

  .md\:col-end-4 {
    grid-column-end: 4;
  }

  .md\:col-end-5 {
    grid-column-end: 5;
  }

  .md\:col-end-6 {
    grid-column-end: 6;
  }

  .md\:col-end-7 {
    grid-column-end: 7;
  }

  .md\:col-end-8 {
    grid-column-end: 8;
  }

  .md\:col-end-9 {
    grid-column-end: 9;
  }

  .md\:col-end-auto {
    grid-column-end: auto;
  }

  .md\:row-auto {
    grid-row: auto;
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .md\:row-span-10 {
    grid-row: span 10 / span 10;
  }

  .md\:row-span-11 {
    grid-row: span 11 / span 11;
  }

  .md\:row-span-12 {
    grid-row: span 12 / span 12;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .md\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .md\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .md\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .md\:row-span-7 {
    grid-row: span 7 / span 7;
  }

  .md\:row-span-8 {
    grid-row: span 8 / span 8;
  }

  .md\:row-span-9 {
    grid-row: span 9 / span 9;
  }

  .md\:row-span-full {
    grid-row: 1 / -1;
  }

  .md\:-row-start-1 {
    grid-row-start: -1;
  }

  .md\:-row-start-10 {
    grid-row-start: -10;
  }

  .md\:-row-start-11 {
    grid-row-start: -11;
  }

  .md\:-row-start-12 {
    grid-row-start: -12;
  }

  .md\:-row-start-13 {
    grid-row-start: -13;
  }

  .md\:-row-start-2 {
    grid-row-start: -2;
  }

  .md\:-row-start-3 {
    grid-row-start: -3;
  }

  .md\:-row-start-4 {
    grid-row-start: -4;
  }

  .md\:-row-start-5 {
    grid-row-start: -5;
  }

  .md\:-row-start-6 {
    grid-row-start: -6;
  }

  .md\:-row-start-7 {
    grid-row-start: -7;
  }

  .md\:-row-start-8 {
    grid-row-start: -8;
  }

  .md\:-row-start-9 {
    grid-row-start: -9;
  }

  .md\:row-start-1 {
    grid-row-start: 1;
  }

  .md\:row-start-10 {
    grid-row-start: 10;
  }

  .md\:row-start-11 {
    grid-row-start: 11;
  }

  .md\:row-start-12 {
    grid-row-start: 12;
  }

  .md\:row-start-13 {
    grid-row-start: 13;
  }

  .md\:row-start-2 {
    grid-row-start: 2;
  }

  .md\:row-start-3 {
    grid-row-start: 3;
  }

  .md\:row-start-4 {
    grid-row-start: 4;
  }

  .md\:row-start-5 {
    grid-row-start: 5;
  }

  .md\:row-start-6 {
    grid-row-start: 6;
  }

  .md\:row-start-7 {
    grid-row-start: 7;
  }

  .md\:row-start-8 {
    grid-row-start: 8;
  }

  .md\:row-start-9 {
    grid-row-start: 9;
  }

  .md\:row-start-auto {
    grid-row-start: auto;
  }

  .md\:-row-end-1 {
    grid-row-end: -1;
  }

  .md\:-row-end-10 {
    grid-row-end: -10;
  }

  .md\:-row-end-11 {
    grid-row-end: -11;
  }

  .md\:-row-end-12 {
    grid-row-end: -12;
  }

  .md\:-row-end-13 {
    grid-row-end: -13;
  }

  .md\:-row-end-2 {
    grid-row-end: -2;
  }

  .md\:-row-end-3 {
    grid-row-end: -3;
  }

  .md\:-row-end-4 {
    grid-row-end: -4;
  }

  .md\:-row-end-5 {
    grid-row-end: -5;
  }

  .md\:-row-end-6 {
    grid-row-end: -6;
  }

  .md\:-row-end-7 {
    grid-row-end: -7;
  }

  .md\:-row-end-8 {
    grid-row-end: -8;
  }

  .md\:-row-end-9 {
    grid-row-end: -9;
  }

  .md\:row-end-1 {
    grid-row-end: 1;
  }

  .md\:row-end-10 {
    grid-row-end: 10;
  }

  .md\:row-end-11 {
    grid-row-end: 11;
  }

  .md\:row-end-12 {
    grid-row-end: 12;
  }

  .md\:row-end-13 {
    grid-row-end: 13;
  }

  .md\:row-end-2 {
    grid-row-end: 2;
  }

  .md\:row-end-3 {
    grid-row-end: 3;
  }

  .md\:row-end-4 {
    grid-row-end: 4;
  }

  .md\:row-end-5 {
    grid-row-end: 5;
  }

  .md\:row-end-6 {
    grid-row-end: 6;
  }

  .md\:row-end-7 {
    grid-row-end: 7;
  }

  .md\:row-end-8 {
    grid-row-end: 8;
  }

  .md\:row-end-9 {
    grid-row-end: 9;
  }

  .md\:row-end-auto {
    grid-row-end: auto;
  }

  .md\:float-start {
    float: inline-start;
  }

  .md\:float-end {
    float: inline-end;
  }

  .md\:float-right {
    float: right;
  }

  .md\:float-left {
    float: left;
  }

  .md\:float-none {
    float: none;
  }

  .md\:clear-start {
    clear: inline-start;
  }

  .md\:clear-end {
    clear: inline-end;
  }

  .md\:clear-left {
    clear: left;
  }

  .md\:clear-right {
    clear: right;
  }

  .md\:clear-both {
    clear: both;
  }

  .md\:clear-none {
    clear: none;
  }

  .md\:-m-0 {
    margin: 0;
  }

  .md\:-m-0\.5 {
    margin: -.125rem;
  }

  .md\:-m-1 {
    margin: -.25rem;
  }

  .md\:-m-1\.5 {
    margin: -.375rem;
  }

  .md\:-m-10 {
    margin: -2.5rem;
  }

  .md\:-m-11 {
    margin: -2.75rem;
  }

  .md\:-m-12 {
    margin: -3rem;
  }

  .md\:-m-14 {
    margin: -3.5rem;
  }

  .md\:-m-16 {
    margin: -4rem;
  }

  .md\:-m-2 {
    margin: -.5rem;
  }

  .md\:-m-2\.5 {
    margin: -.625rem;
  }

  .md\:-m-20 {
    margin: -5rem;
  }

  .md\:-m-24 {
    margin: -6rem;
  }

  .md\:-m-28 {
    margin: -7rem;
  }

  .md\:-m-3 {
    margin: -.75rem;
  }

  .md\:-m-3\.5 {
    margin: -.875rem;
  }

  .md\:-m-32 {
    margin: -8rem;
  }

  .md\:-m-36 {
    margin: -9rem;
  }

  .md\:-m-4 {
    margin: -1rem;
  }

  .md\:-m-40 {
    margin: -10rem;
  }

  .md\:-m-44 {
    margin: -11rem;
  }

  .md\:-m-48 {
    margin: -12rem;
  }

  .md\:-m-5 {
    margin: -1.25rem;
  }

  .md\:-m-52 {
    margin: -13rem;
  }

  .md\:-m-56 {
    margin: -14rem;
  }

  .md\:-m-6 {
    margin: -1.5rem;
  }

  .md\:-m-60 {
    margin: -15rem;
  }

  .md\:-m-64 {
    margin: -16rem;
  }

  .md\:-m-7 {
    margin: -1.75rem;
  }

  .md\:-m-72 {
    margin: -18rem;
  }

  .md\:-m-8 {
    margin: -2rem;
  }

  .md\:-m-80 {
    margin: -20rem;
  }

  .md\:-m-9 {
    margin: -2.25rem;
  }

  .md\:-m-96 {
    margin: -24rem;
  }

  .md\:-m-px {
    margin: -1px;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:m-0\.5 {
    margin: .125rem;
  }

  .md\:m-1 {
    margin: .25rem;
  }

  .md\:m-1\.5 {
    margin: .375rem;
  }

  .md\:m-10 {
    margin: 2.5rem;
  }

  .md\:m-11 {
    margin: 2.75rem;
  }

  .md\:m-12 {
    margin: 3rem;
  }

  .md\:m-14 {
    margin: 3.5rem;
  }

  .md\:m-16 {
    margin: 4rem;
  }

  .md\:m-2 {
    margin: .5rem;
  }

  .md\:m-2\.5 {
    margin: .625rem;
  }

  .md\:m-20 {
    margin: 5rem;
  }

  .md\:m-24 {
    margin: 6rem;
  }

  .md\:m-28 {
    margin: 7rem;
  }

  .md\:m-3 {
    margin: .75rem;
  }

  .md\:m-3\.5 {
    margin: .875rem;
  }

  .md\:m-32 {
    margin: 8rem;
  }

  .md\:m-36 {
    margin: 9rem;
  }

  .md\:m-4 {
    margin: 1rem;
  }

  .md\:m-40 {
    margin: 10rem;
  }

  .md\:m-44 {
    margin: 11rem;
  }

  .md\:m-48 {
    margin: 12rem;
  }

  .md\:m-5 {
    margin: 1.25rem;
  }

  .md\:m-52 {
    margin: 13rem;
  }

  .md\:m-56 {
    margin: 14rem;
  }

  .md\:m-6 {
    margin: 1.5rem;
  }

  .md\:m-60 {
    margin: 15rem;
  }

  .md\:m-64 {
    margin: 16rem;
  }

  .md\:m-7 {
    margin: 1.75rem;
  }

  .md\:m-72 {
    margin: 18rem;
  }

  .md\:m-8 {
    margin: 2rem;
  }

  .md\:m-80 {
    margin: 20rem;
  }

  .md\:m-9 {
    margin: 2.25rem;
  }

  .md\:m-96 {
    margin: 24rem;
  }

  .md\:m-auto {
    margin: auto;
  }

  .md\:m-px {
    margin: 1px;
  }

  .md\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .md\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .md\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .md\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .md\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .md\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .md\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .md\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .md\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .md\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .md\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .md\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .md\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .md\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .md\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .md\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .md\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .md\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .md\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .md\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .md\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .md\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .md\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .md\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .md\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .md\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .md\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .md\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .md\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .md\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .md\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .md\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .md\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .md\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .md\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .md\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .md\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .md\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .md\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .md\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .md\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .md\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .md\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .md\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .md\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .md\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .md\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .md\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .md\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .md\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .md\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .md\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .md\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .md\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .md\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .md\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .md\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .md\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .md\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .md\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .md\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .md\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .md\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .md\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .md\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .md\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .md\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .md\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .md\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .md\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .md\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .md\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .md\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .md\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .md\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .md\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .md\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .md\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .md\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .md\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .md\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .md\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .md\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .md\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .md\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .md\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .md\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .md\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .md\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .md\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .md\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .md\:-mb-0 {
    margin-bottom: 0;
  }

  .md\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .md\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .md\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .md\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .md\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .md\:-mb-12 {
    margin-bottom: -3rem;
  }

  .md\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .md\:-mb-16 {
    margin-bottom: -4rem;
  }

  .md\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .md\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .md\:-mb-20 {
    margin-bottom: -5rem;
  }

  .md\:-mb-24 {
    margin-bottom: -6rem;
  }

  .md\:-mb-28 {
    margin-bottom: -7rem;
  }

  .md\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .md\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .md\:-mb-32 {
    margin-bottom: -8rem;
  }

  .md\:-mb-36 {
    margin-bottom: -9rem;
  }

  .md\:-mb-4 {
    margin-bottom: -1rem;
  }

  .md\:-mb-40 {
    margin-bottom: -10rem;
  }

  .md\:-mb-44 {
    margin-bottom: -11rem;
  }

  .md\:-mb-48 {
    margin-bottom: -12rem;
  }

  .md\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .md\:-mb-52 {
    margin-bottom: -13rem;
  }

  .md\:-mb-56 {
    margin-bottom: -14rem;
  }

  .md\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .md\:-mb-60 {
    margin-bottom: -15rem;
  }

  .md\:-mb-64 {
    margin-bottom: -16rem;
  }

  .md\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .md\:-mb-72 {
    margin-bottom: -18rem;
  }

  .md\:-mb-8 {
    margin-bottom: -2rem;
  }

  .md\:-mb-80 {
    margin-bottom: -20rem;
  }

  .md\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .md\:-mb-96 {
    margin-bottom: -24rem;
  }

  .md\:-mb-px {
    margin-bottom: -1px;
  }

  .md\:-me-0 {
    margin-inline-end: 0;
  }

  .md\:-me-0\.5 {
    margin-inline-end: -.125rem;
  }

  .md\:-me-1 {
    margin-inline-end: -.25rem;
  }

  .md\:-me-1\.5 {
    margin-inline-end: -.375rem;
  }

  .md\:-me-10 {
    margin-inline-end: -2.5rem;
  }

  .md\:-me-11 {
    margin-inline-end: -2.75rem;
  }

  .md\:-me-12 {
    margin-inline-end: -3rem;
  }

  .md\:-me-14 {
    margin-inline-end: -3.5rem;
  }

  .md\:-me-16 {
    margin-inline-end: -4rem;
  }

  .md\:-me-2 {
    margin-inline-end: -.5rem;
  }

  .md\:-me-2\.5 {
    margin-inline-end: -.625rem;
  }

  .md\:-me-20 {
    margin-inline-end: -5rem;
  }

  .md\:-me-24 {
    margin-inline-end: -6rem;
  }

  .md\:-me-28 {
    margin-inline-end: -7rem;
  }

  .md\:-me-3 {
    margin-inline-end: -.75rem;
  }

  .md\:-me-3\.5 {
    margin-inline-end: -.875rem;
  }

  .md\:-me-32 {
    margin-inline-end: -8rem;
  }

  .md\:-me-36 {
    margin-inline-end: -9rem;
  }

  .md\:-me-4 {
    margin-inline-end: -1rem;
  }

  .md\:-me-40 {
    margin-inline-end: -10rem;
  }

  .md\:-me-44 {
    margin-inline-end: -11rem;
  }

  .md\:-me-48 {
    margin-inline-end: -12rem;
  }

  .md\:-me-5 {
    margin-inline-end: -1.25rem;
  }

  .md\:-me-52 {
    margin-inline-end: -13rem;
  }

  .md\:-me-56 {
    margin-inline-end: -14rem;
  }

  .md\:-me-6 {
    margin-inline-end: -1.5rem;
  }

  .md\:-me-60 {
    margin-inline-end: -15rem;
  }

  .md\:-me-64 {
    margin-inline-end: -16rem;
  }

  .md\:-me-7 {
    margin-inline-end: -1.75rem;
  }

  .md\:-me-72 {
    margin-inline-end: -18rem;
  }

  .md\:-me-8 {
    margin-inline-end: -2rem;
  }

  .md\:-me-80 {
    margin-inline-end: -20rem;
  }

  .md\:-me-9 {
    margin-inline-end: -2.25rem;
  }

  .md\:-me-96 {
    margin-inline-end: -24rem;
  }

  .md\:-me-px {
    margin-inline-end: -1px;
  }

  .md\:-ml-0 {
    margin-left: 0;
  }

  .md\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .md\:-ml-1 {
    margin-left: -.25rem;
  }

  .md\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .md\:-ml-10 {
    margin-left: -2.5rem;
  }

  .md\:-ml-11 {
    margin-left: -2.75rem;
  }

  .md\:-ml-12 {
    margin-left: -3rem;
  }

  .md\:-ml-14 {
    margin-left: -3.5rem;
  }

  .md\:-ml-16 {
    margin-left: -4rem;
  }

  .md\:-ml-2 {
    margin-left: -.5rem;
  }

  .md\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .md\:-ml-20 {
    margin-left: -5rem;
  }

  .md\:-ml-24 {
    margin-left: -6rem;
  }

  .md\:-ml-28 {
    margin-left: -7rem;
  }

  .md\:-ml-3 {
    margin-left: -.75rem;
  }

  .md\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .md\:-ml-32 {
    margin-left: -8rem;
  }

  .md\:-ml-36 {
    margin-left: -9rem;
  }

  .md\:-ml-4 {
    margin-left: -1rem;
  }

  .md\:-ml-40 {
    margin-left: -10rem;
  }

  .md\:-ml-44 {
    margin-left: -11rem;
  }

  .md\:-ml-48 {
    margin-left: -12rem;
  }

  .md\:-ml-5 {
    margin-left: -1.25rem;
  }

  .md\:-ml-52 {
    margin-left: -13rem;
  }

  .md\:-ml-56 {
    margin-left: -14rem;
  }

  .md\:-ml-6 {
    margin-left: -1.5rem;
  }

  .md\:-ml-60 {
    margin-left: -15rem;
  }

  .md\:-ml-64 {
    margin-left: -16rem;
  }

  .md\:-ml-7 {
    margin-left: -1.75rem;
  }

  .md\:-ml-72 {
    margin-left: -18rem;
  }

  .md\:-ml-8 {
    margin-left: -2rem;
  }

  .md\:-ml-80 {
    margin-left: -20rem;
  }

  .md\:-ml-9 {
    margin-left: -2.25rem;
  }

  .md\:-ml-96 {
    margin-left: -24rem;
  }

  .md\:-ml-px {
    margin-left: -1px;
  }

  .md\:-mr-0 {
    margin-right: 0;
  }

  .md\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .md\:-mr-1 {
    margin-right: -.25rem;
  }

  .md\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .md\:-mr-10 {
    margin-right: -2.5rem;
  }

  .md\:-mr-11 {
    margin-right: -2.75rem;
  }

  .md\:-mr-12 {
    margin-right: -3rem;
  }

  .md\:-mr-14 {
    margin-right: -3.5rem;
  }

  .md\:-mr-16 {
    margin-right: -4rem;
  }

  .md\:-mr-2 {
    margin-right: -.5rem;
  }

  .md\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .md\:-mr-20 {
    margin-right: -5rem;
  }

  .md\:-mr-24 {
    margin-right: -6rem;
  }

  .md\:-mr-28 {
    margin-right: -7rem;
  }

  .md\:-mr-3 {
    margin-right: -.75rem;
  }

  .md\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .md\:-mr-32 {
    margin-right: -8rem;
  }

  .md\:-mr-36 {
    margin-right: -9rem;
  }

  .md\:-mr-4 {
    margin-right: -1rem;
  }

  .md\:-mr-40 {
    margin-right: -10rem;
  }

  .md\:-mr-44 {
    margin-right: -11rem;
  }

  .md\:-mr-48 {
    margin-right: -12rem;
  }

  .md\:-mr-5 {
    margin-right: -1.25rem;
  }

  .md\:-mr-52 {
    margin-right: -13rem;
  }

  .md\:-mr-56 {
    margin-right: -14rem;
  }

  .md\:-mr-6 {
    margin-right: -1.5rem;
  }

  .md\:-mr-60 {
    margin-right: -15rem;
  }

  .md\:-mr-64 {
    margin-right: -16rem;
  }

  .md\:-mr-7 {
    margin-right: -1.75rem;
  }

  .md\:-mr-72 {
    margin-right: -18rem;
  }

  .md\:-mr-8 {
    margin-right: -2rem;
  }

  .md\:-mr-80 {
    margin-right: -20rem;
  }

  .md\:-mr-9 {
    margin-right: -2.25rem;
  }

  .md\:-mr-96 {
    margin-right: -24rem;
  }

  .md\:-mr-px {
    margin-right: -1px;
  }

  .md\:-ms-0 {
    margin-inline-start: 0;
  }

  .md\:-ms-0\.5 {
    margin-inline-start: -.125rem;
  }

  .md\:-ms-1 {
    margin-inline-start: -.25rem;
  }

  .md\:-ms-1\.5 {
    margin-inline-start: -.375rem;
  }

  .md\:-ms-10 {
    margin-inline-start: -2.5rem;
  }

  .md\:-ms-11 {
    margin-inline-start: -2.75rem;
  }

  .md\:-ms-12 {
    margin-inline-start: -3rem;
  }

  .md\:-ms-14 {
    margin-inline-start: -3.5rem;
  }

  .md\:-ms-16 {
    margin-inline-start: -4rem;
  }

  .md\:-ms-2 {
    margin-inline-start: -.5rem;
  }

  .md\:-ms-2\.5 {
    margin-inline-start: -.625rem;
  }

  .md\:-ms-20 {
    margin-inline-start: -5rem;
  }

  .md\:-ms-24 {
    margin-inline-start: -6rem;
  }

  .md\:-ms-28 {
    margin-inline-start: -7rem;
  }

  .md\:-ms-3 {
    margin-inline-start: -.75rem;
  }

  .md\:-ms-3\.5 {
    margin-inline-start: -.875rem;
  }

  .md\:-ms-32 {
    margin-inline-start: -8rem;
  }

  .md\:-ms-36 {
    margin-inline-start: -9rem;
  }

  .md\:-ms-4 {
    margin-inline-start: -1rem;
  }

  .md\:-ms-40 {
    margin-inline-start: -10rem;
  }

  .md\:-ms-44 {
    margin-inline-start: -11rem;
  }

  .md\:-ms-48 {
    margin-inline-start: -12rem;
  }

  .md\:-ms-5 {
    margin-inline-start: -1.25rem;
  }

  .md\:-ms-52 {
    margin-inline-start: -13rem;
  }

  .md\:-ms-56 {
    margin-inline-start: -14rem;
  }

  .md\:-ms-6 {
    margin-inline-start: -1.5rem;
  }

  .md\:-ms-60 {
    margin-inline-start: -15rem;
  }

  .md\:-ms-64 {
    margin-inline-start: -16rem;
  }

  .md\:-ms-7 {
    margin-inline-start: -1.75rem;
  }

  .md\:-ms-72 {
    margin-inline-start: -18rem;
  }

  .md\:-ms-8 {
    margin-inline-start: -2rem;
  }

  .md\:-ms-80 {
    margin-inline-start: -20rem;
  }

  .md\:-ms-9 {
    margin-inline-start: -2.25rem;
  }

  .md\:-ms-96 {
    margin-inline-start: -24rem;
  }

  .md\:-ms-px {
    margin-inline-start: -1px;
  }

  .md\:-mt-0 {
    margin-top: 0;
  }

  .md\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .md\:-mt-1 {
    margin-top: -.25rem;
  }

  .md\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .md\:-mt-10 {
    margin-top: -2.5rem;
  }

  .md\:-mt-11 {
    margin-top: -2.75rem;
  }

  .md\:-mt-12 {
    margin-top: -3rem;
  }

  .md\:-mt-14 {
    margin-top: -3.5rem;
  }

  .md\:-mt-16 {
    margin-top: -4rem;
  }

  .md\:-mt-2 {
    margin-top: -.5rem;
  }

  .md\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .md\:-mt-20 {
    margin-top: -5rem;
  }

  .md\:-mt-24 {
    margin-top: -6rem;
  }

  .md\:-mt-28 {
    margin-top: -7rem;
  }

  .md\:-mt-3 {
    margin-top: -.75rem;
  }

  .md\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .md\:-mt-32 {
    margin-top: -8rem;
  }

  .md\:-mt-36 {
    margin-top: -9rem;
  }

  .md\:-mt-4 {
    margin-top: -1rem;
  }

  .md\:-mt-40 {
    margin-top: -10rem;
  }

  .md\:-mt-44 {
    margin-top: -11rem;
  }

  .md\:-mt-48 {
    margin-top: -12rem;
  }

  .md\:-mt-5 {
    margin-top: -1.25rem;
  }

  .md\:-mt-52 {
    margin-top: -13rem;
  }

  .md\:-mt-56 {
    margin-top: -14rem;
  }

  .md\:-mt-6 {
    margin-top: -1.5rem;
  }

  .md\:-mt-60 {
    margin-top: -15rem;
  }

  .md\:-mt-64 {
    margin-top: -16rem;
  }

  .md\:-mt-7 {
    margin-top: -1.75rem;
  }

  .md\:-mt-72 {
    margin-top: -18rem;
  }

  .md\:-mt-8 {
    margin-top: -2rem;
  }

  .md\:-mt-80 {
    margin-top: -20rem;
  }

  .md\:-mt-9 {
    margin-top: -2.25rem;
  }

  .md\:-mt-96 {
    margin-top: -24rem;
  }

  .md\:-mt-px {
    margin-top: -1px;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .md\:mb-1 {
    margin-bottom: .25rem;
  }

  .md\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-2 {
    margin-bottom: .5rem;
  }

  .md\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:mb-24 {
    margin-bottom: 6rem;
  }

  .md\:mb-28 {
    margin-bottom: 7rem;
  }

  .md\:mb-3 {
    margin-bottom: .75rem;
  }

  .md\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .md\:mb-32 {
    margin-bottom: 8rem;
  }

  .md\:mb-36 {
    margin-bottom: 9rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mb-40 {
    margin-bottom: 10rem;
  }

  .md\:mb-44 {
    margin-bottom: 11rem;
  }

  .md\:mb-48 {
    margin-bottom: 12rem;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:mb-52 {
    margin-bottom: 13rem;
  }

  .md\:mb-56 {
    margin-bottom: 14rem;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:mb-60 {
    margin-bottom: 15rem;
  }

  .md\:mb-64 {
    margin-bottom: 16rem;
  }

  .md\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .md\:mb-72 {
    margin-bottom: 18rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mb-80 {
    margin-bottom: 20rem;
  }

  .md\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .md\:mb-96 {
    margin-bottom: 24rem;
  }

  .md\:mb-auto {
    margin-bottom: auto;
  }

  .md\:mb-px {
    margin-bottom: 1px;
  }

  .md\:me-0 {
    margin-inline-end: 0;
  }

  .md\:me-0\.5 {
    margin-inline-end: .125rem;
  }

  .md\:me-1 {
    margin-inline-end: .25rem;
  }

  .md\:me-1\.5 {
    margin-inline-end: .375rem;
  }

  .md\:me-10 {
    margin-inline-end: 2.5rem;
  }

  .md\:me-11 {
    margin-inline-end: 2.75rem;
  }

  .md\:me-12 {
    margin-inline-end: 3rem;
  }

  .md\:me-14 {
    margin-inline-end: 3.5rem;
  }

  .md\:me-16 {
    margin-inline-end: 4rem;
  }

  .md\:me-2 {
    margin-inline-end: .5rem;
  }

  .md\:me-2\.5 {
    margin-inline-end: .625rem;
  }

  .md\:me-20 {
    margin-inline-end: 5rem;
  }

  .md\:me-24 {
    margin-inline-end: 6rem;
  }

  .md\:me-28 {
    margin-inline-end: 7rem;
  }

  .md\:me-3 {
    margin-inline-end: .75rem;
  }

  .md\:me-3\.5 {
    margin-inline-end: .875rem;
  }

  .md\:me-32 {
    margin-inline-end: 8rem;
  }

  .md\:me-36 {
    margin-inline-end: 9rem;
  }

  .md\:me-4 {
    margin-inline-end: 1rem;
  }

  .md\:me-40 {
    margin-inline-end: 10rem;
  }

  .md\:me-44 {
    margin-inline-end: 11rem;
  }

  .md\:me-48 {
    margin-inline-end: 12rem;
  }

  .md\:me-5 {
    margin-inline-end: 1.25rem;
  }

  .md\:me-52 {
    margin-inline-end: 13rem;
  }

  .md\:me-56 {
    margin-inline-end: 14rem;
  }

  .md\:me-6 {
    margin-inline-end: 1.5rem;
  }

  .md\:me-60 {
    margin-inline-end: 15rem;
  }

  .md\:me-64 {
    margin-inline-end: 16rem;
  }

  .md\:me-7 {
    margin-inline-end: 1.75rem;
  }

  .md\:me-72 {
    margin-inline-end: 18rem;
  }

  .md\:me-8 {
    margin-inline-end: 2rem;
  }

  .md\:me-80 {
    margin-inline-end: 20rem;
  }

  .md\:me-9 {
    margin-inline-end: 2.25rem;
  }

  .md\:me-96 {
    margin-inline-end: 24rem;
  }

  .md\:me-auto {
    margin-inline-end: auto;
  }

  .md\:me-px {
    margin-inline-end: 1px;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-0\.5 {
    margin-left: .125rem;
  }

  .md\:ml-1 {
    margin-left: .25rem;
  }

  .md\:ml-1\.5 {
    margin-left: .375rem;
  }

  .md\:ml-10 {
    margin-left: 2.5rem;
  }

  .md\:ml-11 {
    margin-left: 2.75rem;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:ml-14 {
    margin-left: 3.5rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:ml-2\.5 {
    margin-left: .625rem;
  }

  .md\:ml-20 {
    margin-left: 5rem;
  }

  .md\:ml-24 {
    margin-left: 6rem;
  }

  .md\:ml-28 {
    margin-left: 7rem;
  }

  .md\:ml-3 {
    margin-left: .75rem;
  }

  .md\:ml-3\.5 {
    margin-left: .875rem;
  }

  .md\:ml-32 {
    margin-left: 8rem;
  }

  .md\:ml-36 {
    margin-left: 9rem;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:ml-40 {
    margin-left: 10rem;
  }

  .md\:ml-44 {
    margin-left: 11rem;
  }

  .md\:ml-48 {
    margin-left: 12rem;
  }

  .md\:ml-5 {
    margin-left: 1.25rem;
  }

  .md\:ml-52 {
    margin-left: 13rem;
  }

  .md\:ml-56 {
    margin-left: 14rem;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:ml-60 {
    margin-left: 15rem;
  }

  .md\:ml-64 {
    margin-left: 16rem;
  }

  .md\:ml-7 {
    margin-left: 1.75rem;
  }

  .md\:ml-72 {
    margin-left: 18rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:ml-80 {
    margin-left: 20rem;
  }

  .md\:ml-9 {
    margin-left: 2.25rem;
  }

  .md\:ml-96 {
    margin-left: 24rem;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:ml-px {
    margin-left: 1px;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mr-0\.5 {
    margin-right: .125rem;
  }

  .md\:mr-1 {
    margin-right: .25rem;
  }

  .md\:mr-1\.5 {
    margin-right: .375rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:mr-11 {
    margin-right: 2.75rem;
  }

  .md\:mr-12 {
    margin-right: 3rem;
  }

  .md\:mr-14 {
    margin-right: 3.5rem;
  }

  .md\:mr-16 {
    margin-right: 4rem;
  }

  .md\:mr-2 {
    margin-right: .5rem;
  }

  .md\:mr-2\.5 {
    margin-right: .625rem;
  }

  .md\:mr-20 {
    margin-right: 5rem;
  }

  .md\:mr-24 {
    margin-right: 6rem;
  }

  .md\:mr-28 {
    margin-right: 7rem;
  }

  .md\:mr-3 {
    margin-right: .75rem;
  }

  .md\:mr-3\.5 {
    margin-right: .875rem;
  }

  .md\:mr-32 {
    margin-right: 8rem;
  }

  .md\:mr-36 {
    margin-right: 9rem;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mr-40 {
    margin-right: 10rem;
  }

  .md\:mr-44 {
    margin-right: 11rem;
  }

  .md\:mr-48 {
    margin-right: 12rem;
  }

  .md\:mr-5 {
    margin-right: 1.25rem;
  }

  .md\:mr-52 {
    margin-right: 13rem;
  }

  .md\:mr-56 {
    margin-right: 14rem;
  }

  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:mr-60 {
    margin-right: 15rem;
  }

  .md\:mr-64 {
    margin-right: 16rem;
  }

  .md\:mr-7 {
    margin-right: 1.75rem;
  }

  .md\:mr-72 {
    margin-right: 18rem;
  }

  .md\:mr-8 {
    margin-right: 2rem;
  }

  .md\:mr-80 {
    margin-right: 20rem;
  }

  .md\:mr-9 {
    margin-right: 2.25rem;
  }

  .md\:mr-96 {
    margin-right: 24rem;
  }

  .md\:mr-auto {
    margin-right: auto;
  }

  .md\:mr-px {
    margin-right: 1px;
  }

  .md\:ms-0 {
    margin-inline-start: 0;
  }

  .md\:ms-0\.5 {
    margin-inline-start: .125rem;
  }

  .md\:ms-1 {
    margin-inline-start: .25rem;
  }

  .md\:ms-1\.5 {
    margin-inline-start: .375rem;
  }

  .md\:ms-10 {
    margin-inline-start: 2.5rem;
  }

  .md\:ms-11 {
    margin-inline-start: 2.75rem;
  }

  .md\:ms-12 {
    margin-inline-start: 3rem;
  }

  .md\:ms-14 {
    margin-inline-start: 3.5rem;
  }

  .md\:ms-16 {
    margin-inline-start: 4rem;
  }

  .md\:ms-2 {
    margin-inline-start: .5rem;
  }

  .md\:ms-2\.5 {
    margin-inline-start: .625rem;
  }

  .md\:ms-20 {
    margin-inline-start: 5rem;
  }

  .md\:ms-24 {
    margin-inline-start: 6rem;
  }

  .md\:ms-28 {
    margin-inline-start: 7rem;
  }

  .md\:ms-3 {
    margin-inline-start: .75rem;
  }

  .md\:ms-3\.5 {
    margin-inline-start: .875rem;
  }

  .md\:ms-32 {
    margin-inline-start: 8rem;
  }

  .md\:ms-36 {
    margin-inline-start: 9rem;
  }

  .md\:ms-4 {
    margin-inline-start: 1rem;
  }

  .md\:ms-40 {
    margin-inline-start: 10rem;
  }

  .md\:ms-44 {
    margin-inline-start: 11rem;
  }

  .md\:ms-48 {
    margin-inline-start: 12rem;
  }

  .md\:ms-5 {
    margin-inline-start: 1.25rem;
  }

  .md\:ms-52 {
    margin-inline-start: 13rem;
  }

  .md\:ms-56 {
    margin-inline-start: 14rem;
  }

  .md\:ms-6 {
    margin-inline-start: 1.5rem;
  }

  .md\:ms-60 {
    margin-inline-start: 15rem;
  }

  .md\:ms-64 {
    margin-inline-start: 16rem;
  }

  .md\:ms-7 {
    margin-inline-start: 1.75rem;
  }

  .md\:ms-72 {
    margin-inline-start: 18rem;
  }

  .md\:ms-8 {
    margin-inline-start: 2rem;
  }

  .md\:ms-80 {
    margin-inline-start: 20rem;
  }

  .md\:ms-9 {
    margin-inline-start: 2.25rem;
  }

  .md\:ms-96 {
    margin-inline-start: 24rem;
  }

  .md\:ms-auto {
    margin-inline-start: auto;
  }

  .md\:ms-px {
    margin-inline-start: 1px;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-0\.5 {
    margin-top: .125rem;
  }

  .md\:mt-1 {
    margin-top: .25rem;
  }

  .md\:mt-1\.5 {
    margin-top: .375rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-11 {
    margin-top: 2.75rem;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:mt-14 {
    margin-top: 3.5rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:mt-2\.5 {
    margin-top: .625rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mt-28 {
    margin-top: 7rem;
  }

  .md\:mt-3 {
    margin-top: .75rem;
  }

  .md\:mt-3\.5 {
    margin-top: .875rem;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:mt-36 {
    margin-top: 9rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mt-40 {
    margin-top: 10rem;
  }

  .md\:mt-44 {
    margin-top: 11rem;
  }

  .md\:mt-48 {
    margin-top: 12rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:mt-52 {
    margin-top: 13rem;
  }

  .md\:mt-56 {
    margin-top: 14rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:mt-60 {
    margin-top: 15rem;
  }

  .md\:mt-64 {
    margin-top: 16rem;
  }

  .md\:mt-7 {
    margin-top: 1.75rem;
  }

  .md\:mt-72 {
    margin-top: 18rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:mt-80 {
    margin-top: 20rem;
  }

  .md\:mt-9 {
    margin-top: 2.25rem;
  }

  .md\:mt-96 {
    margin-top: 24rem;
  }

  .md\:mt-auto {
    margin-top: auto;
  }

  .md\:mt-px {
    margin-top: 1px;
  }

  .md\:box-border {
    box-sizing: border-box;
  }

  .md\:box-content {
    box-sizing: content-box;
  }

  .md\:line-clamp-1 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .md\:line-clamp-2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .md\:line-clamp-3 {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .md\:line-clamp-4 {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .md\:line-clamp-5 {
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .md\:line-clamp-6 {
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .md\:line-clamp-none {
    -webkit-line-clamp: none;
    -webkit-box-orient: horizontal;
    display: block;
    overflow: visible;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:table {
    display: table;
  }

  .md\:inline-table {
    display: inline-table;
  }

  .md\:table-caption {
    display: table-caption;
  }

  .md\:table-cell {
    display: table-cell;
  }

  .md\:table-column {
    display: table-column;
  }

  .md\:table-column-group {
    display: table-column-group;
  }

  .md\:table-footer-group {
    display: table-footer-group;
  }

  .md\:table-header-group {
    display: table-header-group;
  }

  .md\:table-row-group {
    display: table-row-group;
  }

  .md\:table-row {
    display: table-row;
  }

  .md\:flow-root {
    display: flow-root;
  }

  .md\:grid {
    display: grid;
  }

  .md\:inline-grid {
    display: inline-grid;
  }

  .md\:contents {
    display: contents;
  }

  .md\:list-item {
    display: list-item;
  }

  .md\:hidden {
    display: none;
  }

  .md\:aspect-auto {
    aspect-ratio: auto;
  }

  .md\:aspect-square {
    aspect-ratio: 1;
  }

  .md\:aspect-video {
    aspect-ratio: 16 / 9;
  }

  .md\:size-0 {
    width: 0;
    height: 0;
  }

  .md\:size-0\.5 {
    width: .125rem;
    height: .125rem;
  }

  .md\:size-1 {
    width: .25rem;
    height: .25rem;
  }

  .md\:size-1\.5 {
    width: .375rem;
    height: .375rem;
  }

  .md\:size-1\/12 {
    width: 8.33333%;
    height: 8.33333%;
  }

  .md\:size-1\/2 {
    width: 50%;
    height: 50%;
  }

  .md\:size-1\/3 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .md\:size-1\/4 {
    width: 25%;
    height: 25%;
  }

  .md\:size-1\/5 {
    width: 20%;
    height: 20%;
  }

  .md\:size-1\/6 {
    width: 16.6667%;
    height: 16.6667%;
  }

  .md\:size-10 {
    width: 2.5rem;
    height: 2.5rem;
  }

  .md\:size-10\/12 {
    width: 83.3333%;
    height: 83.3333%;
  }

  .md\:size-11 {
    width: 2.75rem;
    height: 2.75rem;
  }

  .md\:size-11\/12 {
    width: 91.6667%;
    height: 91.6667%;
  }

  .md\:size-12 {
    width: 3rem;
    height: 3rem;
  }

  .md\:size-14 {
    width: 3.5rem;
    height: 3.5rem;
  }

  .md\:size-16 {
    width: 4rem;
    height: 4rem;
  }

  .md\:size-2 {
    width: .5rem;
    height: .5rem;
  }

  .md\:size-2\.5 {
    width: .625rem;
    height: .625rem;
  }

  .md\:size-2\/12 {
    width: 16.6667%;
    height: 16.6667%;
  }

  .md\:size-2\/3 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .md\:size-2\/4 {
    width: 50%;
    height: 50%;
  }

  .md\:size-2\/5 {
    width: 40%;
    height: 40%;
  }

  .md\:size-2\/6 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .md\:size-20 {
    width: 5rem;
    height: 5rem;
  }

  .md\:size-24 {
    width: 6rem;
    height: 6rem;
  }

  .md\:size-28 {
    width: 7rem;
    height: 7rem;
  }

  .md\:size-3 {
    width: .75rem;
    height: .75rem;
  }

  .md\:size-3\.5 {
    width: .875rem;
    height: .875rem;
  }

  .md\:size-3\/12 {
    width: 25%;
    height: 25%;
  }

  .md\:size-3\/4 {
    width: 75%;
    height: 75%;
  }

  .md\:size-3\/5 {
    width: 60%;
    height: 60%;
  }

  .md\:size-3\/6 {
    width: 50%;
    height: 50%;
  }

  .md\:size-32 {
    width: 8rem;
    height: 8rem;
  }

  .md\:size-36 {
    width: 9rem;
    height: 9rem;
  }

  .md\:size-4 {
    width: 1rem;
    height: 1rem;
  }

  .md\:size-4\/12 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .md\:size-4\/5 {
    width: 80%;
    height: 80%;
  }

  .md\:size-4\/6 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .md\:size-40 {
    width: 10rem;
    height: 10rem;
  }

  .md\:size-44 {
    width: 11rem;
    height: 11rem;
  }

  .md\:size-48 {
    width: 12rem;
    height: 12rem;
  }

  .md\:size-5 {
    width: 1.25rem;
    height: 1.25rem;
  }

  .md\:size-5\/12 {
    width: 41.6667%;
    height: 41.6667%;
  }

  .md\:size-5\/6 {
    width: 83.3333%;
    height: 83.3333%;
  }

  .md\:size-52 {
    width: 13rem;
    height: 13rem;
  }

  .md\:size-56 {
    width: 14rem;
    height: 14rem;
  }

  .md\:size-6 {
    width: 1.5rem;
    height: 1.5rem;
  }

  .md\:size-6\/12 {
    width: 50%;
    height: 50%;
  }

  .md\:size-60 {
    width: 15rem;
    height: 15rem;
  }

  .md\:size-64 {
    width: 16rem;
    height: 16rem;
  }

  .md\:size-7 {
    width: 1.75rem;
    height: 1.75rem;
  }

  .md\:size-7\/12 {
    width: 58.3333%;
    height: 58.3333%;
  }

  .md\:size-72 {
    width: 18rem;
    height: 18rem;
  }

  .md\:size-8 {
    width: 2rem;
    height: 2rem;
  }

  .md\:size-8\/12 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .md\:size-80 {
    width: 20rem;
    height: 20rem;
  }

  .md\:size-9 {
    width: 2.25rem;
    height: 2.25rem;
  }

  .md\:size-9\/12 {
    width: 75%;
    height: 75%;
  }

  .md\:size-96 {
    width: 24rem;
    height: 24rem;
  }

  .md\:size-auto {
    width: auto;
    height: auto;
  }

  .md\:size-fit {
    width: fit-content;
    height: fit-content;
  }

  .md\:size-full {
    width: 100%;
    height: 100%;
  }

  .md\:size-max {
    width: max-content;
    height: max-content;
  }

  .md\:size-min {
    width: min-content;
    height: min-content;
  }

  .md\:size-px {
    width: 1px;
    height: 1px;
  }

  .md\:h-0 {
    height: 0;
  }

  .md\:h-0\.5 {
    height: .125rem;
  }

  .md\:h-1 {
    height: .25rem;
  }

  .md\:h-1\.5 {
    height: .375rem;
  }

  .md\:h-1\/2 {
    height: 50%;
  }

  .md\:h-1\/3 {
    height: 33.3333%;
  }

  .md\:h-1\/4 {
    height: 25%;
  }

  .md\:h-1\/5 {
    height: 20%;
  }

  .md\:h-1\/6 {
    height: 16.6667%;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:h-11 {
    height: 2.75rem;
  }

  .md\:h-12 {
    height: 3rem;
  }

  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:h-16 {
    height: 4rem;
  }

  .md\:h-2 {
    height: .5rem;
  }

  .md\:h-2\.5 {
    height: .625rem;
  }

  .md\:h-2\/3 {
    height: 66.6667%;
  }

  .md\:h-2\/4 {
    height: 50%;
  }

  .md\:h-2\/5 {
    height: 40%;
  }

  .md\:h-2\/6 {
    height: 33.3333%;
  }

  .md\:h-20 {
    height: 5rem;
  }

  .md\:h-24 {
    height: 6rem;
  }

  .md\:h-28 {
    height: 7rem;
  }

  .md\:h-3 {
    height: .75rem;
  }

  .md\:h-3\.5 {
    height: .875rem;
  }

  .md\:h-3\/4 {
    height: 75%;
  }

  .md\:h-3\/5 {
    height: 60%;
  }

  .md\:h-3\/6 {
    height: 50%;
  }

  .md\:h-32 {
    height: 8rem;
  }

  .md\:h-36 {
    height: 9rem;
  }

  .md\:h-4 {
    height: 1rem;
  }

  .md\:h-4\/5 {
    height: 80%;
  }

  .md\:h-4\/6 {
    height: 66.6667%;
  }

  .md\:h-40 {
    height: 10rem;
  }

  .md\:h-44 {
    height: 11rem;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-5 {
    height: 1.25rem;
  }

  .md\:h-5\/6 {
    height: 83.3333%;
  }

  .md\:h-52 {
    height: 13rem;
  }

  .md\:h-56 {
    height: 14rem;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:h-60 {
    height: 15rem;
  }

  .md\:h-64 {
    height: 16rem;
  }

  .md\:h-7 {
    height: 1.75rem;
  }

  .md\:h-72 {
    height: 18rem;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:h-80 {
    height: 20rem;
  }

  .md\:h-9 {
    height: 2.25rem;
  }

  .md\:h-96 {
    height: 24rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-dvh {
    height: 100dvh;
  }

  .md\:h-fit {
    height: fit-content;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:h-lvh {
    height: 100lvh;
  }

  .md\:h-max {
    height: max-content;
  }

  .md\:h-min {
    height: min-content;
  }

  .md\:h-px {
    height: 1px;
  }

  .md\:h-screen {
    height: 100vh;
  }

  .md\:h-svh {
    height: 100svh;
  }

  .md\:max-h-0 {
    max-height: 0;
  }

  .md\:max-h-0\.5 {
    max-height: .125rem;
  }

  .md\:max-h-1 {
    max-height: .25rem;
  }

  .md\:max-h-1\.5 {
    max-height: .375rem;
  }

  .md\:max-h-10 {
    max-height: 2.5rem;
  }

  .md\:max-h-11 {
    max-height: 2.75rem;
  }

  .md\:max-h-12 {
    max-height: 3rem;
  }

  .md\:max-h-14 {
    max-height: 3.5rem;
  }

  .md\:max-h-16 {
    max-height: 4rem;
  }

  .md\:max-h-2 {
    max-height: .5rem;
  }

  .md\:max-h-2\.5 {
    max-height: .625rem;
  }

  .md\:max-h-20 {
    max-height: 5rem;
  }

  .md\:max-h-24 {
    max-height: 6rem;
  }

  .md\:max-h-28 {
    max-height: 7rem;
  }

  .md\:max-h-3 {
    max-height: .75rem;
  }

  .md\:max-h-3\.5 {
    max-height: .875rem;
  }

  .md\:max-h-32 {
    max-height: 8rem;
  }

  .md\:max-h-36 {
    max-height: 9rem;
  }

  .md\:max-h-4 {
    max-height: 1rem;
  }

  .md\:max-h-40 {
    max-height: 10rem;
  }

  .md\:max-h-44 {
    max-height: 11rem;
  }

  .md\:max-h-48 {
    max-height: 12rem;
  }

  .md\:max-h-5 {
    max-height: 1.25rem;
  }

  .md\:max-h-52 {
    max-height: 13rem;
  }

  .md\:max-h-56 {
    max-height: 14rem;
  }

  .md\:max-h-6 {
    max-height: 1.5rem;
  }

  .md\:max-h-60 {
    max-height: 15rem;
  }

  .md\:max-h-64 {
    max-height: 16rem;
  }

  .md\:max-h-7 {
    max-height: 1.75rem;
  }

  .md\:max-h-72 {
    max-height: 18rem;
  }

  .md\:max-h-8 {
    max-height: 2rem;
  }

  .md\:max-h-80 {
    max-height: 20rem;
  }

  .md\:max-h-9 {
    max-height: 2.25rem;
  }

  .md\:max-h-96 {
    max-height: 24rem;
  }

  .md\:max-h-dvh {
    max-height: 100dvh;
  }

  .md\:max-h-fit {
    max-height: fit-content;
  }

  .md\:max-h-full {
    max-height: 100%;
  }

  .md\:max-h-lvh {
    max-height: 100lvh;
  }

  .md\:max-h-max {
    max-height: max-content;
  }

  .md\:max-h-min {
    max-height: min-content;
  }

  .md\:max-h-none {
    max-height: none;
  }

  .md\:max-h-px {
    max-height: 1px;
  }

  .md\:max-h-screen {
    max-height: 100vh;
  }

  .md\:max-h-svh {
    max-height: 100svh;
  }

  .md\:min-h-0 {
    min-height: 0;
  }

  .md\:min-h-0\.5 {
    min-height: .125rem;
  }

  .md\:min-h-1 {
    min-height: .25rem;
  }

  .md\:min-h-1\.5 {
    min-height: .375rem;
  }

  .md\:min-h-10 {
    min-height: 2.5rem;
  }

  .md\:min-h-11 {
    min-height: 2.75rem;
  }

  .md\:min-h-12 {
    min-height: 3rem;
  }

  .md\:min-h-14 {
    min-height: 3.5rem;
  }

  .md\:min-h-16 {
    min-height: 4rem;
  }

  .md\:min-h-2 {
    min-height: .5rem;
  }

  .md\:min-h-2\.5 {
    min-height: .625rem;
  }

  .md\:min-h-20 {
    min-height: 5rem;
  }

  .md\:min-h-24 {
    min-height: 6rem;
  }

  .md\:min-h-28 {
    min-height: 7rem;
  }

  .md\:min-h-3 {
    min-height: .75rem;
  }

  .md\:min-h-3\.5 {
    min-height: .875rem;
  }

  .md\:min-h-32 {
    min-height: 8rem;
  }

  .md\:min-h-36 {
    min-height: 9rem;
  }

  .md\:min-h-4 {
    min-height: 1rem;
  }

  .md\:min-h-40 {
    min-height: 10rem;
  }

  .md\:min-h-44 {
    min-height: 11rem;
  }

  .md\:min-h-48 {
    min-height: 12rem;
  }

  .md\:min-h-5 {
    min-height: 1.25rem;
  }

  .md\:min-h-52 {
    min-height: 13rem;
  }

  .md\:min-h-56 {
    min-height: 14rem;
  }

  .md\:min-h-6 {
    min-height: 1.5rem;
  }

  .md\:min-h-60 {
    min-height: 15rem;
  }

  .md\:min-h-64 {
    min-height: 16rem;
  }

  .md\:min-h-7 {
    min-height: 1.75rem;
  }

  .md\:min-h-72 {
    min-height: 18rem;
  }

  .md\:min-h-8 {
    min-height: 2rem;
  }

  .md\:min-h-80 {
    min-height: 20rem;
  }

  .md\:min-h-9 {
    min-height: 2.25rem;
  }

  .md\:min-h-96 {
    min-height: 24rem;
  }

  .md\:min-h-dvh {
    min-height: 100dvh;
  }

  .md\:min-h-fit {
    min-height: fit-content;
  }

  .md\:min-h-full {
    min-height: 100%;
  }

  .md\:min-h-lvh {
    min-height: 100lvh;
  }

  .md\:min-h-max {
    min-height: max-content;
  }

  .md\:min-h-min {
    min-height: min-content;
  }

  .md\:min-h-px {
    min-height: 1px;
  }

  .md\:min-h-screen {
    min-height: 100vh;
  }

  .md\:min-h-svh {
    min-height: 100svh;
  }

  .md\:w-0 {
    width: 0;
  }

  .md\:w-0\.5 {
    width: .125rem;
  }

  .md\:w-1 {
    width: .25rem;
  }

  .md\:w-1\.5 {
    width: .375rem;
  }

  .md\:w-1\/12 {
    width: 8.33333%;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-1\/5 {
    width: 20%;
  }

  .md\:w-1\/6 {
    width: 16.6667%;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:w-10\/12 {
    width: 83.3333%;
  }

  .md\:w-11 {
    width: 2.75rem;
  }

  .md\:w-11\/12 {
    width: 91.6667%;
  }

  .md\:w-12 {
    width: 3rem;
  }

  .md\:w-14 {
    width: 3.5rem;
  }

  .md\:w-16 {
    width: 4rem;
  }

  .md\:w-2 {
    width: .5rem;
  }

  .md\:w-2\.5 {
    width: .625rem;
  }

  .md\:w-2\/12 {
    width: 16.6667%;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-2\/4 {
    width: 50%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-2\/6 {
    width: 33.3333%;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-28 {
    width: 7rem;
  }

  .md\:w-3 {
    width: .75rem;
  }

  .md\:w-3\.5 {
    width: .875rem;
  }

  .md\:w-3\/12 {
    width: 25%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:w-3\/6 {
    width: 50%;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-4 {
    width: 1rem;
  }

  .md\:w-4\/12 {
    width: 33.3333%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-4\/6 {
    width: 66.6667%;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-44 {
    width: 11rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-5 {
    width: 1.25rem;
  }

  .md\:w-5\/12 {
    width: 41.6667%;
  }

  .md\:w-5\/6 {
    width: 83.3333%;
  }

  .md\:w-52 {
    width: 13rem;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-7 {
    width: 1.75rem;
  }

  .md\:w-7\/12 {
    width: 58.3333%;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:w-8\/12 {
    width: 66.6667%;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-9 {
    width: 2.25rem;
  }

  .md\:w-9\/12 {
    width: 75%;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-dvw {
    width: 100dvw;
  }

  .md\:w-fit {
    width: fit-content;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:w-lvw {
    width: 100lvw;
  }

  .md\:w-max {
    width: max-content;
  }

  .md\:w-min {
    width: min-content;
  }

  .md\:w-px {
    width: 1px;
  }

  .md\:w-screen {
    width: 100vw;
  }

  .md\:w-svw {
    width: 100svw;
  }

  .md\:min-w-0 {
    min-width: 0;
  }

  .md\:min-w-0\.5 {
    min-width: .125rem;
  }

  .md\:min-w-1 {
    min-width: .25rem;
  }

  .md\:min-w-1\.5 {
    min-width: .375rem;
  }

  .md\:min-w-10 {
    min-width: 2.5rem;
  }

  .md\:min-w-11 {
    min-width: 2.75rem;
  }

  .md\:min-w-12 {
    min-width: 3rem;
  }

  .md\:min-w-14 {
    min-width: 3.5rem;
  }

  .md\:min-w-16 {
    min-width: 4rem;
  }

  .md\:min-w-2 {
    min-width: .5rem;
  }

  .md\:min-w-2\.5 {
    min-width: .625rem;
  }

  .md\:min-w-20 {
    min-width: 5rem;
  }

  .md\:min-w-24 {
    min-width: 6rem;
  }

  .md\:min-w-28 {
    min-width: 7rem;
  }

  .md\:min-w-3 {
    min-width: .75rem;
  }

  .md\:min-w-3\.5 {
    min-width: .875rem;
  }

  .md\:min-w-32 {
    min-width: 8rem;
  }

  .md\:min-w-36 {
    min-width: 9rem;
  }

  .md\:min-w-4 {
    min-width: 1rem;
  }

  .md\:min-w-40 {
    min-width: 10rem;
  }

  .md\:min-w-44 {
    min-width: 11rem;
  }

  .md\:min-w-48 {
    min-width: 12rem;
  }

  .md\:min-w-5 {
    min-width: 1.25rem;
  }

  .md\:min-w-52 {
    min-width: 13rem;
  }

  .md\:min-w-56 {
    min-width: 14rem;
  }

  .md\:min-w-6 {
    min-width: 1.5rem;
  }

  .md\:min-w-60 {
    min-width: 15rem;
  }

  .md\:min-w-64 {
    min-width: 16rem;
  }

  .md\:min-w-7 {
    min-width: 1.75rem;
  }

  .md\:min-w-72 {
    min-width: 18rem;
  }

  .md\:min-w-8 {
    min-width: 2rem;
  }

  .md\:min-w-80 {
    min-width: 20rem;
  }

  .md\:min-w-9 {
    min-width: 2.25rem;
  }

  .md\:min-w-96 {
    min-width: 24rem;
  }

  .md\:min-w-fit {
    min-width: fit-content;
  }

  .md\:min-w-full {
    min-width: 100%;
  }

  .md\:min-w-max {
    min-width: max-content;
  }

  .md\:min-w-min {
    min-width: min-content;
  }

  .md\:min-w-px {
    min-width: 1px;
  }

  .md\:max-w-0 {
    max-width: 0;
  }

  .md\:max-w-0\.5 {
    max-width: .125rem;
  }

  .md\:max-w-1 {
    max-width: .25rem;
  }

  .md\:max-w-1\.5 {
    max-width: .375rem;
  }

  .md\:max-w-10 {
    max-width: 2.5rem;
  }

  .md\:max-w-11 {
    max-width: 2.75rem;
  }

  .md\:max-w-12 {
    max-width: 3rem;
  }

  .md\:max-w-14 {
    max-width: 3.5rem;
  }

  .md\:max-w-16 {
    max-width: 4rem;
  }

  .md\:max-w-2 {
    max-width: .5rem;
  }

  .md\:max-w-2\.5 {
    max-width: .625rem;
  }

  .md\:max-w-20 {
    max-width: 5rem;
  }

  .md\:max-w-24 {
    max-width: 6rem;
  }

  .md\:max-w-28 {
    max-width: 7rem;
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:max-w-3 {
    max-width: .75rem;
  }

  .md\:max-w-3\.5 {
    max-width: .875rem;
  }

  .md\:max-w-32 {
    max-width: 8rem;
  }

  .md\:max-w-36 {
    max-width: 9rem;
  }

  .md\:max-w-3xl {
    max-width: 48rem;
  }

  .md\:max-w-4 {
    max-width: 1rem;
  }

  .md\:max-w-40 {
    max-width: 10rem;
  }

  .md\:max-w-44 {
    max-width: 11rem;
  }

  .md\:max-w-48 {
    max-width: 12rem;
  }

  .md\:max-w-4xl {
    max-width: 56rem;
  }

  .md\:max-w-5 {
    max-width: 1.25rem;
  }

  .md\:max-w-52 {
    max-width: 13rem;
  }

  .md\:max-w-56 {
    max-width: 14rem;
  }

  .md\:max-w-5xl {
    max-width: 64rem;
  }

  .md\:max-w-6 {
    max-width: 1.5rem;
  }

  .md\:max-w-60 {
    max-width: 15rem;
  }

  .md\:max-w-64 {
    max-width: 16rem;
  }

  .md\:max-w-6xl {
    max-width: 72rem;
  }

  .md\:max-w-7 {
    max-width: 1.75rem;
  }

  .md\:max-w-72 {
    max-width: 18rem;
  }

  .md\:max-w-7xl {
    max-width: 80rem;
  }

  .md\:max-w-8 {
    max-width: 2rem;
  }

  .md\:max-w-80 {
    max-width: 20rem;
  }

  .md\:max-w-9 {
    max-width: 2.25rem;
  }

  .md\:max-w-96 {
    max-width: 24rem;
  }

  .md\:max-w-fit {
    max-width: fit-content;
  }

  .md\:max-w-full {
    max-width: 100%;
  }

  .md\:max-w-lg {
    max-width: 32rem;
  }

  .md\:max-w-max {
    max-width: max-content;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:max-w-min {
    max-width: min-content;
  }

  .md\:max-w-none {
    max-width: none;
  }

  .md\:max-w-prose {
    max-width: 65ch;
  }

  .md\:max-w-px {
    max-width: 1px;
  }

  .md\:max-w-screen-2xl {
    max-width: 1536px;
  }

  .md\:max-w-screen-lg {
    max-width: 1024px;
  }

  .md\:max-w-screen-md {
    max-width: 768px;
  }

  .md\:max-w-screen-sm {
    max-width: 640px;
  }

  .md\:max-w-screen-xl {
    max-width: 1280px;
  }

  .md\:max-w-sm {
    max-width: 24rem;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:max-w-xs {
    max-width: 20rem;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:flex-auto {
    flex: auto;
  }

  .md\:flex-initial {
    flex: 0 auto;
  }

  .md\:flex-none {
    flex: none;
  }

  .md\:flex-shrink {
    flex-shrink: 1;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:shrink {
    flex-shrink: 1;
  }

  .md\:shrink-0 {
    flex-shrink: 0;
  }

  .md\:flex-grow {
    flex-grow: 1;
  }

  .md\:flex-grow-0 {
    flex-grow: 0;
  }

  .md\:grow {
    flex-grow: 1;
  }

  .md\:grow-0 {
    flex-grow: 0;
  }

  .md\:basis-0 {
    flex-basis: 0;
  }

  .md\:basis-0\.5 {
    flex-basis: .125rem;
  }

  .md\:basis-1 {
    flex-basis: .25rem;
  }

  .md\:basis-1\.5 {
    flex-basis: .375rem;
  }

  .md\:basis-1\/12 {
    flex-basis: 8.33333%;
  }

  .md\:basis-1\/2 {
    flex-basis: 50%;
  }

  .md\:basis-1\/3 {
    flex-basis: 33.3333%;
  }

  .md\:basis-1\/4 {
    flex-basis: 25%;
  }

  .md\:basis-1\/5 {
    flex-basis: 20%;
  }

  .md\:basis-1\/6 {
    flex-basis: 16.6667%;
  }

  .md\:basis-10 {
    flex-basis: 2.5rem;
  }

  .md\:basis-10\/12 {
    flex-basis: 83.3333%;
  }

  .md\:basis-11 {
    flex-basis: 2.75rem;
  }

  .md\:basis-11\/12 {
    flex-basis: 91.6667%;
  }

  .md\:basis-12 {
    flex-basis: 3rem;
  }

  .md\:basis-14 {
    flex-basis: 3.5rem;
  }

  .md\:basis-16 {
    flex-basis: 4rem;
  }

  .md\:basis-2 {
    flex-basis: .5rem;
  }

  .md\:basis-2\.5 {
    flex-basis: .625rem;
  }

  .md\:basis-2\/12 {
    flex-basis: 16.6667%;
  }

  .md\:basis-2\/3 {
    flex-basis: 66.6667%;
  }

  .md\:basis-2\/4 {
    flex-basis: 50%;
  }

  .md\:basis-2\/5 {
    flex-basis: 40%;
  }

  .md\:basis-2\/6 {
    flex-basis: 33.3333%;
  }

  .md\:basis-20 {
    flex-basis: 5rem;
  }

  .md\:basis-24 {
    flex-basis: 6rem;
  }

  .md\:basis-28 {
    flex-basis: 7rem;
  }

  .md\:basis-3 {
    flex-basis: .75rem;
  }

  .md\:basis-3\.5 {
    flex-basis: .875rem;
  }

  .md\:basis-3\/12 {
    flex-basis: 25%;
  }

  .md\:basis-3\/4 {
    flex-basis: 75%;
  }

  .md\:basis-3\/5 {
    flex-basis: 60%;
  }

  .md\:basis-3\/6 {
    flex-basis: 50%;
  }

  .md\:basis-32 {
    flex-basis: 8rem;
  }

  .md\:basis-36 {
    flex-basis: 9rem;
  }

  .md\:basis-4 {
    flex-basis: 1rem;
  }

  .md\:basis-4\/12 {
    flex-basis: 33.3333%;
  }

  .md\:basis-4\/5 {
    flex-basis: 80%;
  }

  .md\:basis-4\/6 {
    flex-basis: 66.6667%;
  }

  .md\:basis-40 {
    flex-basis: 10rem;
  }

  .md\:basis-44 {
    flex-basis: 11rem;
  }

  .md\:basis-48 {
    flex-basis: 12rem;
  }

  .md\:basis-5 {
    flex-basis: 1.25rem;
  }

  .md\:basis-5\/12 {
    flex-basis: 41.6667%;
  }

  .md\:basis-5\/6 {
    flex-basis: 83.3333%;
  }

  .md\:basis-52 {
    flex-basis: 13rem;
  }

  .md\:basis-56 {
    flex-basis: 14rem;
  }

  .md\:basis-6 {
    flex-basis: 1.5rem;
  }

  .md\:basis-6\/12 {
    flex-basis: 50%;
  }

  .md\:basis-60 {
    flex-basis: 15rem;
  }

  .md\:basis-64 {
    flex-basis: 16rem;
  }

  .md\:basis-7 {
    flex-basis: 1.75rem;
  }

  .md\:basis-7\/12 {
    flex-basis: 58.3333%;
  }

  .md\:basis-72 {
    flex-basis: 18rem;
  }

  .md\:basis-8 {
    flex-basis: 2rem;
  }

  .md\:basis-8\/12 {
    flex-basis: 66.6667%;
  }

  .md\:basis-80 {
    flex-basis: 20rem;
  }

  .md\:basis-9 {
    flex-basis: 2.25rem;
  }

  .md\:basis-9\/12 {
    flex-basis: 75%;
  }

  .md\:basis-96 {
    flex-basis: 24rem;
  }

  .md\:basis-auto {
    flex-basis: auto;
  }

  .md\:basis-full {
    flex-basis: 100%;
  }

  .md\:basis-px {
    flex-basis: 1px;
  }

  .md\:table-auto {
    table-layout: auto;
  }

  .md\:table-fixed {
    table-layout: fixed;
  }

  .md\:caption-top {
    caption-side: top;
  }

  .md\:caption-bottom {
    caption-side: bottom;
  }

  .md\:border-collapse {
    border-collapse: collapse;
  }

  .md\:border-separate {
    border-collapse: separate;
  }

  .md\:border-spacing-0 {
    --tw-border-spacing-x: 0px;
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-0\.5 {
    --tw-border-spacing-x: .125rem;
    --tw-border-spacing-y: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-1 {
    --tw-border-spacing-x: .25rem;
    --tw-border-spacing-y: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-1\.5 {
    --tw-border-spacing-x: .375rem;
    --tw-border-spacing-y: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-10 {
    --tw-border-spacing-x: 2.5rem;
    --tw-border-spacing-y: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-11 {
    --tw-border-spacing-x: 2.75rem;
    --tw-border-spacing-y: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-12 {
    --tw-border-spacing-x: 3rem;
    --tw-border-spacing-y: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-14 {
    --tw-border-spacing-x: 3.5rem;
    --tw-border-spacing-y: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-16 {
    --tw-border-spacing-x: 4rem;
    --tw-border-spacing-y: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-2 {
    --tw-border-spacing-x: .5rem;
    --tw-border-spacing-y: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-2\.5 {
    --tw-border-spacing-x: .625rem;
    --tw-border-spacing-y: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-20 {
    --tw-border-spacing-x: 5rem;
    --tw-border-spacing-y: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-24 {
    --tw-border-spacing-x: 6rem;
    --tw-border-spacing-y: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-28 {
    --tw-border-spacing-x: 7rem;
    --tw-border-spacing-y: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-3 {
    --tw-border-spacing-x: .75rem;
    --tw-border-spacing-y: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-3\.5 {
    --tw-border-spacing-x: .875rem;
    --tw-border-spacing-y: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-32 {
    --tw-border-spacing-x: 8rem;
    --tw-border-spacing-y: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-36 {
    --tw-border-spacing-x: 9rem;
    --tw-border-spacing-y: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-4 {
    --tw-border-spacing-x: 1rem;
    --tw-border-spacing-y: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-40 {
    --tw-border-spacing-x: 10rem;
    --tw-border-spacing-y: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-44 {
    --tw-border-spacing-x: 11rem;
    --tw-border-spacing-y: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-48 {
    --tw-border-spacing-x: 12rem;
    --tw-border-spacing-y: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-5 {
    --tw-border-spacing-x: 1.25rem;
    --tw-border-spacing-y: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-52 {
    --tw-border-spacing-x: 13rem;
    --tw-border-spacing-y: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-56 {
    --tw-border-spacing-x: 14rem;
    --tw-border-spacing-y: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-6 {
    --tw-border-spacing-x: 1.5rem;
    --tw-border-spacing-y: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-60 {
    --tw-border-spacing-x: 15rem;
    --tw-border-spacing-y: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-64 {
    --tw-border-spacing-x: 16rem;
    --tw-border-spacing-y: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-7 {
    --tw-border-spacing-x: 1.75rem;
    --tw-border-spacing-y: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-72 {
    --tw-border-spacing-x: 18rem;
    --tw-border-spacing-y: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-8 {
    --tw-border-spacing-x: 2rem;
    --tw-border-spacing-y: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-80 {
    --tw-border-spacing-x: 20rem;
    --tw-border-spacing-y: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-9 {
    --tw-border-spacing-x: 2.25rem;
    --tw-border-spacing-y: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-96 {
    --tw-border-spacing-x: 24rem;
    --tw-border-spacing-y: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-px {
    --tw-border-spacing-x: 1px;
    --tw-border-spacing-y: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-0 {
    --tw-border-spacing-x: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-0\.5 {
    --tw-border-spacing-x: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-1 {
    --tw-border-spacing-x: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-1\.5 {
    --tw-border-spacing-x: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-10 {
    --tw-border-spacing-x: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-11 {
    --tw-border-spacing-x: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-12 {
    --tw-border-spacing-x: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-14 {
    --tw-border-spacing-x: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-16 {
    --tw-border-spacing-x: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-2 {
    --tw-border-spacing-x: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-2\.5 {
    --tw-border-spacing-x: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-20 {
    --tw-border-spacing-x: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-24 {
    --tw-border-spacing-x: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-28 {
    --tw-border-spacing-x: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-3 {
    --tw-border-spacing-x: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-3\.5 {
    --tw-border-spacing-x: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-32 {
    --tw-border-spacing-x: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-36 {
    --tw-border-spacing-x: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-4 {
    --tw-border-spacing-x: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-40 {
    --tw-border-spacing-x: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-44 {
    --tw-border-spacing-x: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-48 {
    --tw-border-spacing-x: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-5 {
    --tw-border-spacing-x: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-52 {
    --tw-border-spacing-x: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-56 {
    --tw-border-spacing-x: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-6 {
    --tw-border-spacing-x: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-60 {
    --tw-border-spacing-x: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-64 {
    --tw-border-spacing-x: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-7 {
    --tw-border-spacing-x: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-72 {
    --tw-border-spacing-x: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-8 {
    --tw-border-spacing-x: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-80 {
    --tw-border-spacing-x: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-9 {
    --tw-border-spacing-x: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-96 {
    --tw-border-spacing-x: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-x-px {
    --tw-border-spacing-x: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-0 {
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-0\.5 {
    --tw-border-spacing-y: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-1 {
    --tw-border-spacing-y: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-1\.5 {
    --tw-border-spacing-y: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-10 {
    --tw-border-spacing-y: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-11 {
    --tw-border-spacing-y: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-12 {
    --tw-border-spacing-y: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-14 {
    --tw-border-spacing-y: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-16 {
    --tw-border-spacing-y: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-2 {
    --tw-border-spacing-y: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-2\.5 {
    --tw-border-spacing-y: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-20 {
    --tw-border-spacing-y: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-24 {
    --tw-border-spacing-y: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-28 {
    --tw-border-spacing-y: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-3 {
    --tw-border-spacing-y: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-3\.5 {
    --tw-border-spacing-y: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-32 {
    --tw-border-spacing-y: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-36 {
    --tw-border-spacing-y: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-4 {
    --tw-border-spacing-y: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-40 {
    --tw-border-spacing-y: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-44 {
    --tw-border-spacing-y: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-48 {
    --tw-border-spacing-y: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-5 {
    --tw-border-spacing-y: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-52 {
    --tw-border-spacing-y: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-56 {
    --tw-border-spacing-y: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-6 {
    --tw-border-spacing-y: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-60 {
    --tw-border-spacing-y: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-64 {
    --tw-border-spacing-y: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-7 {
    --tw-border-spacing-y: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-72 {
    --tw-border-spacing-y: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-8 {
    --tw-border-spacing-y: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-80 {
    --tw-border-spacing-y: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-9 {
    --tw-border-spacing-y: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-96 {
    --tw-border-spacing-y: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:border-spacing-y-px {
    --tw-border-spacing-y: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .md\:origin-bottom {
    transform-origin: bottom;
  }

  .md\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .md\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .md\:origin-center {
    transform-origin: center;
  }

  .md\:origin-left {
    transform-origin: 0;
  }

  .md\:origin-right {
    transform-origin: 100%;
  }

  .md\:origin-top {
    transform-origin: top;
  }

  .md\:origin-top-left {
    transform-origin: 0 0;
  }

  .md\:origin-top-right {
    transform-origin: 100% 0;
  }

  .md\:-translate-x-0 {
    --tw-translate-x: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-1 {
    --tw-translate-x: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-12 {
    --tw-translate-x: -3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-16 {
    --tw-translate-x: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-2 {
    --tw-translate-x: -.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-20 {
    --tw-translate-x: -5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-24 {
    --tw-translate-x: -6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-28 {
    --tw-translate-x: -7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-3 {
    --tw-translate-x: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-32 {
    --tw-translate-x: -8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-36 {
    --tw-translate-x: -9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-4 {
    --tw-translate-x: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-40 {
    --tw-translate-x: -10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-44 {
    --tw-translate-x: -11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-48 {
    --tw-translate-x: -12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-52 {
    --tw-translate-x: -13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-56 {
    --tw-translate-x: -14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-60 {
    --tw-translate-x: -15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-64 {
    --tw-translate-x: -16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-72 {
    --tw-translate-x: -18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-8 {
    --tw-translate-x: -2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-80 {
    --tw-translate-x: -20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-96 {
    --tw-translate-x: -24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-x-px {
    --tw-translate-x: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-0 {
    --tw-translate-y: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-1 {
    --tw-translate-y: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-12 {
    --tw-translate-y: -3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-16 {
    --tw-translate-y: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-2 {
    --tw-translate-y: -.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-20 {
    --tw-translate-y: -5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-24 {
    --tw-translate-y: -6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-28 {
    --tw-translate-y: -7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-3 {
    --tw-translate-y: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-32 {
    --tw-translate-y: -8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-36 {
    --tw-translate-y: -9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-4 {
    --tw-translate-y: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-40 {
    --tw-translate-y: -10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-44 {
    --tw-translate-y: -11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-48 {
    --tw-translate-y: -12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-52 {
    --tw-translate-y: -13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-56 {
    --tw-translate-y: -14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-60 {
    --tw-translate-y: -15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-64 {
    --tw-translate-y: -16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-72 {
    --tw-translate-y: -18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-8 {
    --tw-translate-y: -2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-80 {
    --tw-translate-y: -20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-96 {
    --tw-translate-y: -24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-translate-y-px {
    --tw-translate-y: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-1 {
    --tw-translate-x: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-1\/4 {
    --tw-translate-x: 25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-10 {
    --tw-translate-x: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-11 {
    --tw-translate-x: 2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-12 {
    --tw-translate-x: 3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-14 {
    --tw-translate-x: 3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-16 {
    --tw-translate-x: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-2\/4 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-20 {
    --tw-translate-x: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-24 {
    --tw-translate-x: 6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-28 {
    --tw-translate-x: 7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-3 {
    --tw-translate-x: .75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-3\/4 {
    --tw-translate-x: 75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-32 {
    --tw-translate-x: 8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-36 {
    --tw-translate-x: 9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-4 {
    --tw-translate-x: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-40 {
    --tw-translate-x: 10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-44 {
    --tw-translate-x: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-48 {
    --tw-translate-x: 12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-5 {
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-52 {
    --tw-translate-x: 13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-56 {
    --tw-translate-x: 14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-6 {
    --tw-translate-x: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-60 {
    --tw-translate-x: 15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-64 {
    --tw-translate-x: 16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-7 {
    --tw-translate-x: 1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-72 {
    --tw-translate-x: 18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-8 {
    --tw-translate-x: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-80 {
    --tw-translate-x: 20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-9 {
    --tw-translate-x: 2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-96 {
    --tw-translate-x: 24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-full {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-px {
    --tw-translate-x: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-1 {
    --tw-translate-y: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-1\/2 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-1\/4 {
    --tw-translate-y: 25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-10 {
    --tw-translate-y: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-11 {
    --tw-translate-y: 2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-12 {
    --tw-translate-y: 3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-14 {
    --tw-translate-y: 3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-16 {
    --tw-translate-y: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-2 {
    --tw-translate-y: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-2\/4 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-20 {
    --tw-translate-y: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-24 {
    --tw-translate-y: 6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-28 {
    --tw-translate-y: 7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-3 {
    --tw-translate-y: .75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-3\/4 {
    --tw-translate-y: 75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-32 {
    --tw-translate-y: 8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-36 {
    --tw-translate-y: 9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-4 {
    --tw-translate-y: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-40 {
    --tw-translate-y: 10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-44 {
    --tw-translate-y: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-48 {
    --tw-translate-y: 12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-5 {
    --tw-translate-y: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-52 {
    --tw-translate-y: 13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-56 {
    --tw-translate-y: 14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-6 {
    --tw-translate-y: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-60 {
    --tw-translate-y: 15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-64 {
    --tw-translate-y: 16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-7 {
    --tw-translate-y: 1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-72 {
    --tw-translate-y: 18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-8 {
    --tw-translate-y: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-80 {
    --tw-translate-y: 20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-9 {
    --tw-translate-y: 2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-96 {
    --tw-translate-y: 24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-full {
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-px {
    --tw-translate-y: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-rotate-0 {
    --tw-rotate: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-rotate-1 {
    --tw-rotate: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-rotate-12 {
    --tw-rotate: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-rotate-180 {
    --tw-rotate: -180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-rotate-2 {
    --tw-rotate: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-rotate-3 {
    --tw-rotate: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-rotate-45 {
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-rotate-6 {
    --tw-rotate: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-1 {
    --tw-rotate: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-12 {
    --tw-rotate: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-2 {
    --tw-rotate: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-3 {
    --tw-rotate: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-6 {
    --tw-rotate: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-x-0 {
    --tw-skew-x: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-x-1 {
    --tw-skew-x: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-x-12 {
    --tw-skew-x: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-x-2 {
    --tw-skew-x: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-x-3 {
    --tw-skew-x: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-x-6 {
    --tw-skew-x: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-y-0 {
    --tw-skew-y: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-y-1 {
    --tw-skew-y: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-y-12 {
    --tw-skew-y: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-y-2 {
    --tw-skew-y: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-y-3 {
    --tw-skew-y: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-skew-y-6 {
    --tw-skew-y: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-x-0 {
    --tw-skew-x: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-x-1 {
    --tw-skew-x: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-x-12 {
    --tw-skew-x: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-x-2 {
    --tw-skew-x: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-x-3 {
    --tw-skew-x: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-x-6 {
    --tw-skew-x: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-y-0 {
    --tw-skew-y: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-y-1 {
    --tw-skew-y: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-y-12 {
    --tw-skew-y: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-y-2 {
    --tw-skew-y: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-y-3 {
    --tw-skew-y: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:skew-y-6 {
    --tw-skew-y: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-100 {
    --tw-scale-x: -1;
    --tw-scale-y: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-105 {
    --tw-scale-x: -1.05;
    --tw-scale-y: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-110 {
    --tw-scale-x: -1.1;
    --tw-scale-y: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-125 {
    --tw-scale-x: -1.25;
    --tw-scale-y: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-150 {
    --tw-scale-x: -1.5;
    --tw-scale-y: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-50 {
    --tw-scale-x: -.5;
    --tw-scale-y: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-75 {
    --tw-scale-x: -.75;
    --tw-scale-y: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-90 {
    --tw-scale-x: -.9;
    --tw-scale-y: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-95 {
    --tw-scale-x: -.95;
    --tw-scale-y: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-x-0 {
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-x-100 {
    --tw-scale-x: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-x-105 {
    --tw-scale-x: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-x-110 {
    --tw-scale-x: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-x-125 {
    --tw-scale-x: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-x-150 {
    --tw-scale-x: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-x-50 {
    --tw-scale-x: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-x-75 {
    --tw-scale-x: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-x-90 {
    --tw-scale-x: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-x-95 {
    --tw-scale-x: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-y-0 {
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-y-100 {
    --tw-scale-y: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-y-105 {
    --tw-scale-y: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-y-110 {
    --tw-scale-y: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-y-125 {
    --tw-scale-y: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-y-150 {
    --tw-scale-y: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-y-50 {
    --tw-scale-y: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-y-75 {
    --tw-scale-y: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-y-90 {
    --tw-scale-y: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-scale-y-95 {
    --tw-scale-y: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-x-0 {
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-x-100 {
    --tw-scale-x: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-x-105 {
    --tw-scale-x: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-x-110 {
    --tw-scale-x: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-x-125 {
    --tw-scale-x: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-x-150 {
    --tw-scale-x: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-x-50 {
    --tw-scale-x: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-x-75 {
    --tw-scale-x: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-x-90 {
    --tw-scale-x: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-x-95 {
    --tw-scale-x: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-0 {
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-100 {
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-105 {
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-110 {
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-125 {
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-150 {
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-50 {
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-75 {
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-90 {
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-95 {
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:transform, .md\:transform-cpu {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:transform-gpu {
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:transform-none {
    transform: none;
  }

  @keyframes bounce {
    0%, 100% {
      animation-timing-function: cubic-bezier(.8, 0, 1, 1);
      transform: translateY(-25%);
    }

    50% {
      animation-timing-function: cubic-bezier(0, 0, .2, 1);
      transform: none;
    }
  }

  .md\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .md\:animate-none {
    animation: none;
  }

  @keyframes ping {
    75%, 100% {
      opacity: 0;
      transform: scale(2);
    }
  }

  .md\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  @keyframes pulse {
    50% {
      opacity: .5;
    }
  }

  .md\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .md\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .md\:cursor-alias {
    cursor: alias;
  }

  .md\:cursor-all-scroll {
    cursor: all-scroll;
  }

  .md\:cursor-auto {
    cursor: auto;
  }

  .md\:cursor-cell {
    cursor: cell;
  }

  .md\:cursor-col-resize {
    cursor: col-resize;
  }

  .md\:cursor-context-menu {
    cursor: context-menu;
  }

  .md\:cursor-copy {
    cursor: copy;
  }

  .md\:cursor-crosshair {
    cursor: crosshair;
  }

  .md\:cursor-default {
    cursor: default;
  }

  .md\:cursor-e-resize {
    cursor: e-resize;
  }

  .md\:cursor-ew-resize {
    cursor: ew-resize;
  }

  .md\:cursor-grab {
    cursor: grab;
  }

  .md\:cursor-grabbing {
    cursor: grabbing;
  }

  .md\:cursor-help {
    cursor: help;
  }

  .md\:cursor-move {
    cursor: move;
  }

  .md\:cursor-n-resize {
    cursor: n-resize;
  }

  .md\:cursor-ne-resize {
    cursor: ne-resize;
  }

  .md\:cursor-nesw-resize {
    cursor: nesw-resize;
  }

  .md\:cursor-no-drop {
    cursor: no-drop;
  }

  .md\:cursor-none {
    cursor: none;
  }

  .md\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .md\:cursor-ns-resize {
    cursor: ns-resize;
  }

  .md\:cursor-nw-resize {
    cursor: nw-resize;
  }

  .md\:cursor-nwse-resize {
    cursor: nwse-resize;
  }

  .md\:cursor-pointer {
    cursor: pointer;
  }

  .md\:cursor-progress {
    cursor: progress;
  }

  .md\:cursor-row-resize {
    cursor: row-resize;
  }

  .md\:cursor-s-resize {
    cursor: s-resize;
  }

  .md\:cursor-se-resize {
    cursor: se-resize;
  }

  .md\:cursor-sw-resize {
    cursor: sw-resize;
  }

  .md\:cursor-text {
    cursor: text;
  }

  .md\:cursor-vertical-text {
    cursor: vertical-text;
  }

  .md\:cursor-w-resize {
    cursor: w-resize;
  }

  .md\:cursor-wait {
    cursor: wait;
  }

  .md\:cursor-zoom-in {
    cursor: zoom-in;
  }

  .md\:cursor-zoom-out {
    cursor: zoom-out;
  }

  .md\:touch-auto {
    touch-action: auto;
  }

  .md\:touch-none {
    touch-action: none;
  }

  .md\:touch-pan-x {
    --tw-pan-x: pan-x;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .md\:touch-pan-left {
    --tw-pan-x: pan-left;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .md\:touch-pan-right {
    --tw-pan-x: pan-right;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .md\:touch-pan-y {
    --tw-pan-y: pan-y;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .md\:touch-pan-up {
    --tw-pan-y: pan-up;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .md\:touch-pan-down {
    --tw-pan-y: pan-down;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .md\:touch-pinch-zoom {
    --tw-pinch-zoom: pinch-zoom;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .md\:touch-manipulation {
    touch-action: manipulation;
  }

  .md\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .md\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .md\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .md\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .md\:resize-none {
    resize: none;
  }

  .md\:resize-y {
    resize: vertical;
  }

  .md\:resize-x {
    resize: horizontal;
  }

  .md\:resize {
    resize: both;
  }

  .md\:snap-none {
    scroll-snap-type: none;
  }

  .md\:snap-x {
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
  }

  .md\:snap-y {
    scroll-snap-type: y var(--tw-scroll-snap-strictness);
  }

  .md\:snap-both {
    scroll-snap-type: both var(--tw-scroll-snap-strictness);
  }

  .md\:snap-mandatory {
    --tw-scroll-snap-strictness: mandatory;
  }

  .md\:snap-proximity {
    --tw-scroll-snap-strictness: proximity;
  }

  .md\:snap-start {
    scroll-snap-align: start;
  }

  .md\:snap-end {
    scroll-snap-align: end;
  }

  .md\:snap-center {
    scroll-snap-align: center;
  }

  .md\:snap-align-none {
    scroll-snap-align: none;
  }

  .md\:snap-normal {
    scroll-snap-stop: normal;
  }

  .md\:snap-always {
    scroll-snap-stop: always;
  }

  .md\:-scroll-m-0 {
    scroll-margin: 0;
  }

  .md\:-scroll-m-0\.5 {
    scroll-margin: -.125rem;
  }

  .md\:-scroll-m-1 {
    scroll-margin: -.25rem;
  }

  .md\:-scroll-m-1\.5 {
    scroll-margin: -.375rem;
  }

  .md\:-scroll-m-10 {
    scroll-margin: -2.5rem;
  }

  .md\:-scroll-m-11 {
    scroll-margin: -2.75rem;
  }

  .md\:-scroll-m-12 {
    scroll-margin: -3rem;
  }

  .md\:-scroll-m-14 {
    scroll-margin: -3.5rem;
  }

  .md\:-scroll-m-16 {
    scroll-margin: -4rem;
  }

  .md\:-scroll-m-2 {
    scroll-margin: -.5rem;
  }

  .md\:-scroll-m-2\.5 {
    scroll-margin: -.625rem;
  }

  .md\:-scroll-m-20 {
    scroll-margin: -5rem;
  }

  .md\:-scroll-m-24 {
    scroll-margin: -6rem;
  }

  .md\:-scroll-m-28 {
    scroll-margin: -7rem;
  }

  .md\:-scroll-m-3 {
    scroll-margin: -.75rem;
  }

  .md\:-scroll-m-3\.5 {
    scroll-margin: -.875rem;
  }

  .md\:-scroll-m-32 {
    scroll-margin: -8rem;
  }

  .md\:-scroll-m-36 {
    scroll-margin: -9rem;
  }

  .md\:-scroll-m-4 {
    scroll-margin: -1rem;
  }

  .md\:-scroll-m-40 {
    scroll-margin: -10rem;
  }

  .md\:-scroll-m-44 {
    scroll-margin: -11rem;
  }

  .md\:-scroll-m-48 {
    scroll-margin: -12rem;
  }

  .md\:-scroll-m-5 {
    scroll-margin: -1.25rem;
  }

  .md\:-scroll-m-52 {
    scroll-margin: -13rem;
  }

  .md\:-scroll-m-56 {
    scroll-margin: -14rem;
  }

  .md\:-scroll-m-6 {
    scroll-margin: -1.5rem;
  }

  .md\:-scroll-m-60 {
    scroll-margin: -15rem;
  }

  .md\:-scroll-m-64 {
    scroll-margin: -16rem;
  }

  .md\:-scroll-m-7 {
    scroll-margin: -1.75rem;
  }

  .md\:-scroll-m-72 {
    scroll-margin: -18rem;
  }

  .md\:-scroll-m-8 {
    scroll-margin: -2rem;
  }

  .md\:-scroll-m-80 {
    scroll-margin: -20rem;
  }

  .md\:-scroll-m-9 {
    scroll-margin: -2.25rem;
  }

  .md\:-scroll-m-96 {
    scroll-margin: -24rem;
  }

  .md\:-scroll-m-px {
    scroll-margin: -1px;
  }

  .md\:scroll-m-0 {
    scroll-margin: 0;
  }

  .md\:scroll-m-0\.5 {
    scroll-margin: .125rem;
  }

  .md\:scroll-m-1 {
    scroll-margin: .25rem;
  }

  .md\:scroll-m-1\.5 {
    scroll-margin: .375rem;
  }

  .md\:scroll-m-10 {
    scroll-margin: 2.5rem;
  }

  .md\:scroll-m-11 {
    scroll-margin: 2.75rem;
  }

  .md\:scroll-m-12 {
    scroll-margin: 3rem;
  }

  .md\:scroll-m-14 {
    scroll-margin: 3.5rem;
  }

  .md\:scroll-m-16 {
    scroll-margin: 4rem;
  }

  .md\:scroll-m-2 {
    scroll-margin: .5rem;
  }

  .md\:scroll-m-2\.5 {
    scroll-margin: .625rem;
  }

  .md\:scroll-m-20 {
    scroll-margin: 5rem;
  }

  .md\:scroll-m-24 {
    scroll-margin: 6rem;
  }

  .md\:scroll-m-28 {
    scroll-margin: 7rem;
  }

  .md\:scroll-m-3 {
    scroll-margin: .75rem;
  }

  .md\:scroll-m-3\.5 {
    scroll-margin: .875rem;
  }

  .md\:scroll-m-32 {
    scroll-margin: 8rem;
  }

  .md\:scroll-m-36 {
    scroll-margin: 9rem;
  }

  .md\:scroll-m-4 {
    scroll-margin: 1rem;
  }

  .md\:scroll-m-40 {
    scroll-margin: 10rem;
  }

  .md\:scroll-m-44 {
    scroll-margin: 11rem;
  }

  .md\:scroll-m-48 {
    scroll-margin: 12rem;
  }

  .md\:scroll-m-5 {
    scroll-margin: 1.25rem;
  }

  .md\:scroll-m-52 {
    scroll-margin: 13rem;
  }

  .md\:scroll-m-56 {
    scroll-margin: 14rem;
  }

  .md\:scroll-m-6 {
    scroll-margin: 1.5rem;
  }

  .md\:scroll-m-60 {
    scroll-margin: 15rem;
  }

  .md\:scroll-m-64 {
    scroll-margin: 16rem;
  }

  .md\:scroll-m-7 {
    scroll-margin: 1.75rem;
  }

  .md\:scroll-m-72 {
    scroll-margin: 18rem;
  }

  .md\:scroll-m-8 {
    scroll-margin: 2rem;
  }

  .md\:scroll-m-80 {
    scroll-margin: 20rem;
  }

  .md\:scroll-m-9 {
    scroll-margin: 2.25rem;
  }

  .md\:scroll-m-96 {
    scroll-margin: 24rem;
  }

  .md\:scroll-m-px {
    scroll-margin: 1px;
  }

  .md\:-scroll-mx-0 {
    scroll-margin-left: 0;
    scroll-margin-right: 0;
  }

  .md\:-scroll-mx-0\.5 {
    scroll-margin-left: -.125rem;
    scroll-margin-right: -.125rem;
  }

  .md\:-scroll-mx-1 {
    scroll-margin-left: -.25rem;
    scroll-margin-right: -.25rem;
  }

  .md\:-scroll-mx-1\.5 {
    scroll-margin-left: -.375rem;
    scroll-margin-right: -.375rem;
  }

  .md\:-scroll-mx-10 {
    scroll-margin-left: -2.5rem;
    scroll-margin-right: -2.5rem;
  }

  .md\:-scroll-mx-11 {
    scroll-margin-left: -2.75rem;
    scroll-margin-right: -2.75rem;
  }

  .md\:-scroll-mx-12 {
    scroll-margin-left: -3rem;
    scroll-margin-right: -3rem;
  }

  .md\:-scroll-mx-14 {
    scroll-margin-left: -3.5rem;
    scroll-margin-right: -3.5rem;
  }

  .md\:-scroll-mx-16 {
    scroll-margin-left: -4rem;
    scroll-margin-right: -4rem;
  }

  .md\:-scroll-mx-2 {
    scroll-margin-left: -.5rem;
    scroll-margin-right: -.5rem;
  }

  .md\:-scroll-mx-2\.5 {
    scroll-margin-left: -.625rem;
    scroll-margin-right: -.625rem;
  }

  .md\:-scroll-mx-20 {
    scroll-margin-left: -5rem;
    scroll-margin-right: -5rem;
  }

  .md\:-scroll-mx-24 {
    scroll-margin-left: -6rem;
    scroll-margin-right: -6rem;
  }

  .md\:-scroll-mx-28 {
    scroll-margin-left: -7rem;
    scroll-margin-right: -7rem;
  }

  .md\:-scroll-mx-3 {
    scroll-margin-left: -.75rem;
    scroll-margin-right: -.75rem;
  }

  .md\:-scroll-mx-3\.5 {
    scroll-margin-left: -.875rem;
    scroll-margin-right: -.875rem;
  }

  .md\:-scroll-mx-32 {
    scroll-margin-left: -8rem;
    scroll-margin-right: -8rem;
  }

  .md\:-scroll-mx-36 {
    scroll-margin-left: -9rem;
    scroll-margin-right: -9rem;
  }

  .md\:-scroll-mx-4 {
    scroll-margin-left: -1rem;
    scroll-margin-right: -1rem;
  }

  .md\:-scroll-mx-40 {
    scroll-margin-left: -10rem;
    scroll-margin-right: -10rem;
  }

  .md\:-scroll-mx-44 {
    scroll-margin-left: -11rem;
    scroll-margin-right: -11rem;
  }

  .md\:-scroll-mx-48 {
    scroll-margin-left: -12rem;
    scroll-margin-right: -12rem;
  }

  .md\:-scroll-mx-5 {
    scroll-margin-left: -1.25rem;
    scroll-margin-right: -1.25rem;
  }

  .md\:-scroll-mx-52 {
    scroll-margin-left: -13rem;
    scroll-margin-right: -13rem;
  }

  .md\:-scroll-mx-56 {
    scroll-margin-left: -14rem;
    scroll-margin-right: -14rem;
  }

  .md\:-scroll-mx-6 {
    scroll-margin-left: -1.5rem;
    scroll-margin-right: -1.5rem;
  }

  .md\:-scroll-mx-60 {
    scroll-margin-left: -15rem;
    scroll-margin-right: -15rem;
  }

  .md\:-scroll-mx-64 {
    scroll-margin-left: -16rem;
    scroll-margin-right: -16rem;
  }

  .md\:-scroll-mx-7 {
    scroll-margin-left: -1.75rem;
    scroll-margin-right: -1.75rem;
  }

  .md\:-scroll-mx-72 {
    scroll-margin-left: -18rem;
    scroll-margin-right: -18rem;
  }

  .md\:-scroll-mx-8 {
    scroll-margin-left: -2rem;
    scroll-margin-right: -2rem;
  }

  .md\:-scroll-mx-80 {
    scroll-margin-left: -20rem;
    scroll-margin-right: -20rem;
  }

  .md\:-scroll-mx-9 {
    scroll-margin-left: -2.25rem;
    scroll-margin-right: -2.25rem;
  }

  .md\:-scroll-mx-96 {
    scroll-margin-left: -24rem;
    scroll-margin-right: -24rem;
  }

  .md\:-scroll-mx-px {
    scroll-margin-left: -1px;
    scroll-margin-right: -1px;
  }

  .md\:-scroll-my-0 {
    scroll-margin-top: 0;
    scroll-margin-bottom: 0;
  }

  .md\:-scroll-my-0\.5 {
    scroll-margin-top: -.125rem;
    scroll-margin-bottom: -.125rem;
  }

  .md\:-scroll-my-1 {
    scroll-margin-top: -.25rem;
    scroll-margin-bottom: -.25rem;
  }

  .md\:-scroll-my-1\.5 {
    scroll-margin-top: -.375rem;
    scroll-margin-bottom: -.375rem;
  }

  .md\:-scroll-my-10 {
    scroll-margin-top: -2.5rem;
    scroll-margin-bottom: -2.5rem;
  }

  .md\:-scroll-my-11 {
    scroll-margin-top: -2.75rem;
    scroll-margin-bottom: -2.75rem;
  }

  .md\:-scroll-my-12 {
    scroll-margin-top: -3rem;
    scroll-margin-bottom: -3rem;
  }

  .md\:-scroll-my-14 {
    scroll-margin-top: -3.5rem;
    scroll-margin-bottom: -3.5rem;
  }

  .md\:-scroll-my-16 {
    scroll-margin-top: -4rem;
    scroll-margin-bottom: -4rem;
  }

  .md\:-scroll-my-2 {
    scroll-margin-top: -.5rem;
    scroll-margin-bottom: -.5rem;
  }

  .md\:-scroll-my-2\.5 {
    scroll-margin-top: -.625rem;
    scroll-margin-bottom: -.625rem;
  }

  .md\:-scroll-my-20 {
    scroll-margin-top: -5rem;
    scroll-margin-bottom: -5rem;
  }

  .md\:-scroll-my-24 {
    scroll-margin-top: -6rem;
    scroll-margin-bottom: -6rem;
  }

  .md\:-scroll-my-28 {
    scroll-margin-top: -7rem;
    scroll-margin-bottom: -7rem;
  }

  .md\:-scroll-my-3 {
    scroll-margin-top: -.75rem;
    scroll-margin-bottom: -.75rem;
  }

  .md\:-scroll-my-3\.5 {
    scroll-margin-top: -.875rem;
    scroll-margin-bottom: -.875rem;
  }

  .md\:-scroll-my-32 {
    scroll-margin-top: -8rem;
    scroll-margin-bottom: -8rem;
  }

  .md\:-scroll-my-36 {
    scroll-margin-top: -9rem;
    scroll-margin-bottom: -9rem;
  }

  .md\:-scroll-my-4 {
    scroll-margin-top: -1rem;
    scroll-margin-bottom: -1rem;
  }

  .md\:-scroll-my-40 {
    scroll-margin-top: -10rem;
    scroll-margin-bottom: -10rem;
  }

  .md\:-scroll-my-44 {
    scroll-margin-top: -11rem;
    scroll-margin-bottom: -11rem;
  }

  .md\:-scroll-my-48 {
    scroll-margin-top: -12rem;
    scroll-margin-bottom: -12rem;
  }

  .md\:-scroll-my-5 {
    scroll-margin-top: -1.25rem;
    scroll-margin-bottom: -1.25rem;
  }

  .md\:-scroll-my-52 {
    scroll-margin-top: -13rem;
    scroll-margin-bottom: -13rem;
  }

  .md\:-scroll-my-56 {
    scroll-margin-top: -14rem;
    scroll-margin-bottom: -14rem;
  }

  .md\:-scroll-my-6 {
    scroll-margin-top: -1.5rem;
    scroll-margin-bottom: -1.5rem;
  }

  .md\:-scroll-my-60 {
    scroll-margin-top: -15rem;
    scroll-margin-bottom: -15rem;
  }

  .md\:-scroll-my-64 {
    scroll-margin-top: -16rem;
    scroll-margin-bottom: -16rem;
  }

  .md\:-scroll-my-7 {
    scroll-margin-top: -1.75rem;
    scroll-margin-bottom: -1.75rem;
  }

  .md\:-scroll-my-72 {
    scroll-margin-top: -18rem;
    scroll-margin-bottom: -18rem;
  }

  .md\:-scroll-my-8 {
    scroll-margin-top: -2rem;
    scroll-margin-bottom: -2rem;
  }

  .md\:-scroll-my-80 {
    scroll-margin-top: -20rem;
    scroll-margin-bottom: -20rem;
  }

  .md\:-scroll-my-9 {
    scroll-margin-top: -2.25rem;
    scroll-margin-bottom: -2.25rem;
  }

  .md\:-scroll-my-96 {
    scroll-margin-top: -24rem;
    scroll-margin-bottom: -24rem;
  }

  .md\:-scroll-my-px {
    scroll-margin-top: -1px;
    scroll-margin-bottom: -1px;
  }

  .md\:scroll-mx-0 {
    scroll-margin-left: 0;
    scroll-margin-right: 0;
  }

  .md\:scroll-mx-0\.5 {
    scroll-margin-left: .125rem;
    scroll-margin-right: .125rem;
  }

  .md\:scroll-mx-1 {
    scroll-margin-left: .25rem;
    scroll-margin-right: .25rem;
  }

  .md\:scroll-mx-1\.5 {
    scroll-margin-left: .375rem;
    scroll-margin-right: .375rem;
  }

  .md\:scroll-mx-10 {
    scroll-margin-left: 2.5rem;
    scroll-margin-right: 2.5rem;
  }

  .md\:scroll-mx-11 {
    scroll-margin-left: 2.75rem;
    scroll-margin-right: 2.75rem;
  }

  .md\:scroll-mx-12 {
    scroll-margin-left: 3rem;
    scroll-margin-right: 3rem;
  }

  .md\:scroll-mx-14 {
    scroll-margin-left: 3.5rem;
    scroll-margin-right: 3.5rem;
  }

  .md\:scroll-mx-16 {
    scroll-margin-left: 4rem;
    scroll-margin-right: 4rem;
  }

  .md\:scroll-mx-2 {
    scroll-margin-left: .5rem;
    scroll-margin-right: .5rem;
  }

  .md\:scroll-mx-2\.5 {
    scroll-margin-left: .625rem;
    scroll-margin-right: .625rem;
  }

  .md\:scroll-mx-20 {
    scroll-margin-left: 5rem;
    scroll-margin-right: 5rem;
  }

  .md\:scroll-mx-24 {
    scroll-margin-left: 6rem;
    scroll-margin-right: 6rem;
  }

  .md\:scroll-mx-28 {
    scroll-margin-left: 7rem;
    scroll-margin-right: 7rem;
  }

  .md\:scroll-mx-3 {
    scroll-margin-left: .75rem;
    scroll-margin-right: .75rem;
  }

  .md\:scroll-mx-3\.5 {
    scroll-margin-left: .875rem;
    scroll-margin-right: .875rem;
  }

  .md\:scroll-mx-32 {
    scroll-margin-left: 8rem;
    scroll-margin-right: 8rem;
  }

  .md\:scroll-mx-36 {
    scroll-margin-left: 9rem;
    scroll-margin-right: 9rem;
  }

  .md\:scroll-mx-4 {
    scroll-margin-left: 1rem;
    scroll-margin-right: 1rem;
  }

  .md\:scroll-mx-40 {
    scroll-margin-left: 10rem;
    scroll-margin-right: 10rem;
  }

  .md\:scroll-mx-44 {
    scroll-margin-left: 11rem;
    scroll-margin-right: 11rem;
  }

  .md\:scroll-mx-48 {
    scroll-margin-left: 12rem;
    scroll-margin-right: 12rem;
  }

  .md\:scroll-mx-5 {
    scroll-margin-left: 1.25rem;
    scroll-margin-right: 1.25rem;
  }

  .md\:scroll-mx-52 {
    scroll-margin-left: 13rem;
    scroll-margin-right: 13rem;
  }

  .md\:scroll-mx-56 {
    scroll-margin-left: 14rem;
    scroll-margin-right: 14rem;
  }

  .md\:scroll-mx-6 {
    scroll-margin-left: 1.5rem;
    scroll-margin-right: 1.5rem;
  }

  .md\:scroll-mx-60 {
    scroll-margin-left: 15rem;
    scroll-margin-right: 15rem;
  }

  .md\:scroll-mx-64 {
    scroll-margin-left: 16rem;
    scroll-margin-right: 16rem;
  }

  .md\:scroll-mx-7 {
    scroll-margin-left: 1.75rem;
    scroll-margin-right: 1.75rem;
  }

  .md\:scroll-mx-72 {
    scroll-margin-left: 18rem;
    scroll-margin-right: 18rem;
  }

  .md\:scroll-mx-8 {
    scroll-margin-left: 2rem;
    scroll-margin-right: 2rem;
  }

  .md\:scroll-mx-80 {
    scroll-margin-left: 20rem;
    scroll-margin-right: 20rem;
  }

  .md\:scroll-mx-9 {
    scroll-margin-left: 2.25rem;
    scroll-margin-right: 2.25rem;
  }

  .md\:scroll-mx-96 {
    scroll-margin-left: 24rem;
    scroll-margin-right: 24rem;
  }

  .md\:scroll-mx-px {
    scroll-margin-left: 1px;
    scroll-margin-right: 1px;
  }

  .md\:scroll-my-0 {
    scroll-margin-top: 0;
    scroll-margin-bottom: 0;
  }

  .md\:scroll-my-0\.5 {
    scroll-margin-top: .125rem;
    scroll-margin-bottom: .125rem;
  }

  .md\:scroll-my-1 {
    scroll-margin-top: .25rem;
    scroll-margin-bottom: .25rem;
  }

  .md\:scroll-my-1\.5 {
    scroll-margin-top: .375rem;
    scroll-margin-bottom: .375rem;
  }

  .md\:scroll-my-10 {
    scroll-margin-top: 2.5rem;
    scroll-margin-bottom: 2.5rem;
  }

  .md\:scroll-my-11 {
    scroll-margin-top: 2.75rem;
    scroll-margin-bottom: 2.75rem;
  }

  .md\:scroll-my-12 {
    scroll-margin-top: 3rem;
    scroll-margin-bottom: 3rem;
  }

  .md\:scroll-my-14 {
    scroll-margin-top: 3.5rem;
    scroll-margin-bottom: 3.5rem;
  }

  .md\:scroll-my-16 {
    scroll-margin-top: 4rem;
    scroll-margin-bottom: 4rem;
  }

  .md\:scroll-my-2 {
    scroll-margin-top: .5rem;
    scroll-margin-bottom: .5rem;
  }

  .md\:scroll-my-2\.5 {
    scroll-margin-top: .625rem;
    scroll-margin-bottom: .625rem;
  }

  .md\:scroll-my-20 {
    scroll-margin-top: 5rem;
    scroll-margin-bottom: 5rem;
  }

  .md\:scroll-my-24 {
    scroll-margin-top: 6rem;
    scroll-margin-bottom: 6rem;
  }

  .md\:scroll-my-28 {
    scroll-margin-top: 7rem;
    scroll-margin-bottom: 7rem;
  }

  .md\:scroll-my-3 {
    scroll-margin-top: .75rem;
    scroll-margin-bottom: .75rem;
  }

  .md\:scroll-my-3\.5 {
    scroll-margin-top: .875rem;
    scroll-margin-bottom: .875rem;
  }

  .md\:scroll-my-32 {
    scroll-margin-top: 8rem;
    scroll-margin-bottom: 8rem;
  }

  .md\:scroll-my-36 {
    scroll-margin-top: 9rem;
    scroll-margin-bottom: 9rem;
  }

  .md\:scroll-my-4 {
    scroll-margin-top: 1rem;
    scroll-margin-bottom: 1rem;
  }

  .md\:scroll-my-40 {
    scroll-margin-top: 10rem;
    scroll-margin-bottom: 10rem;
  }

  .md\:scroll-my-44 {
    scroll-margin-top: 11rem;
    scroll-margin-bottom: 11rem;
  }

  .md\:scroll-my-48 {
    scroll-margin-top: 12rem;
    scroll-margin-bottom: 12rem;
  }

  .md\:scroll-my-5 {
    scroll-margin-top: 1.25rem;
    scroll-margin-bottom: 1.25rem;
  }

  .md\:scroll-my-52 {
    scroll-margin-top: 13rem;
    scroll-margin-bottom: 13rem;
  }

  .md\:scroll-my-56 {
    scroll-margin-top: 14rem;
    scroll-margin-bottom: 14rem;
  }

  .md\:scroll-my-6 {
    scroll-margin-top: 1.5rem;
    scroll-margin-bottom: 1.5rem;
  }

  .md\:scroll-my-60 {
    scroll-margin-top: 15rem;
    scroll-margin-bottom: 15rem;
  }

  .md\:scroll-my-64 {
    scroll-margin-top: 16rem;
    scroll-margin-bottom: 16rem;
  }

  .md\:scroll-my-7 {
    scroll-margin-top: 1.75rem;
    scroll-margin-bottom: 1.75rem;
  }

  .md\:scroll-my-72 {
    scroll-margin-top: 18rem;
    scroll-margin-bottom: 18rem;
  }

  .md\:scroll-my-8 {
    scroll-margin-top: 2rem;
    scroll-margin-bottom: 2rem;
  }

  .md\:scroll-my-80 {
    scroll-margin-top: 20rem;
    scroll-margin-bottom: 20rem;
  }

  .md\:scroll-my-9 {
    scroll-margin-top: 2.25rem;
    scroll-margin-bottom: 2.25rem;
  }

  .md\:scroll-my-96 {
    scroll-margin-top: 24rem;
    scroll-margin-bottom: 24rem;
  }

  .md\:scroll-my-px {
    scroll-margin-top: 1px;
    scroll-margin-bottom: 1px;
  }

  .md\:-scroll-mb-0 {
    scroll-margin-bottom: 0;
  }

  .md\:-scroll-mb-0\.5 {
    scroll-margin-bottom: -.125rem;
  }

  .md\:-scroll-mb-1 {
    scroll-margin-bottom: -.25rem;
  }

  .md\:-scroll-mb-1\.5 {
    scroll-margin-bottom: -.375rem;
  }

  .md\:-scroll-mb-10 {
    scroll-margin-bottom: -2.5rem;
  }

  .md\:-scroll-mb-11 {
    scroll-margin-bottom: -2.75rem;
  }

  .md\:-scroll-mb-12 {
    scroll-margin-bottom: -3rem;
  }

  .md\:-scroll-mb-14 {
    scroll-margin-bottom: -3.5rem;
  }

  .md\:-scroll-mb-16 {
    scroll-margin-bottom: -4rem;
  }

  .md\:-scroll-mb-2 {
    scroll-margin-bottom: -.5rem;
  }

  .md\:-scroll-mb-2\.5 {
    scroll-margin-bottom: -.625rem;
  }

  .md\:-scroll-mb-20 {
    scroll-margin-bottom: -5rem;
  }

  .md\:-scroll-mb-24 {
    scroll-margin-bottom: -6rem;
  }

  .md\:-scroll-mb-28 {
    scroll-margin-bottom: -7rem;
  }

  .md\:-scroll-mb-3 {
    scroll-margin-bottom: -.75rem;
  }

  .md\:-scroll-mb-3\.5 {
    scroll-margin-bottom: -.875rem;
  }

  .md\:-scroll-mb-32 {
    scroll-margin-bottom: -8rem;
  }

  .md\:-scroll-mb-36 {
    scroll-margin-bottom: -9rem;
  }

  .md\:-scroll-mb-4 {
    scroll-margin-bottom: -1rem;
  }

  .md\:-scroll-mb-40 {
    scroll-margin-bottom: -10rem;
  }

  .md\:-scroll-mb-44 {
    scroll-margin-bottom: -11rem;
  }

  .md\:-scroll-mb-48 {
    scroll-margin-bottom: -12rem;
  }

  .md\:-scroll-mb-5 {
    scroll-margin-bottom: -1.25rem;
  }

  .md\:-scroll-mb-52 {
    scroll-margin-bottom: -13rem;
  }

  .md\:-scroll-mb-56 {
    scroll-margin-bottom: -14rem;
  }

  .md\:-scroll-mb-6 {
    scroll-margin-bottom: -1.5rem;
  }

  .md\:-scroll-mb-60 {
    scroll-margin-bottom: -15rem;
  }

  .md\:-scroll-mb-64 {
    scroll-margin-bottom: -16rem;
  }

  .md\:-scroll-mb-7 {
    scroll-margin-bottom: -1.75rem;
  }

  .md\:-scroll-mb-72 {
    scroll-margin-bottom: -18rem;
  }

  .md\:-scroll-mb-8 {
    scroll-margin-bottom: -2rem;
  }

  .md\:-scroll-mb-80 {
    scroll-margin-bottom: -20rem;
  }

  .md\:-scroll-mb-9 {
    scroll-margin-bottom: -2.25rem;
  }

  .md\:-scroll-mb-96 {
    scroll-margin-bottom: -24rem;
  }

  .md\:-scroll-mb-px {
    scroll-margin-bottom: -1px;
  }

  .md\:-scroll-me-0 {
    scroll-margin-inline-end: 0;
  }

  .md\:-scroll-me-0\.5 {
    scroll-margin-inline-end: -.125rem;
  }

  .md\:-scroll-me-1 {
    scroll-margin-inline-end: -.25rem;
  }

  .md\:-scroll-me-1\.5 {
    scroll-margin-inline-end: -.375rem;
  }

  .md\:-scroll-me-10 {
    scroll-margin-inline-end: -2.5rem;
  }

  .md\:-scroll-me-11 {
    scroll-margin-inline-end: -2.75rem;
  }

  .md\:-scroll-me-12 {
    scroll-margin-inline-end: -3rem;
  }

  .md\:-scroll-me-14 {
    scroll-margin-inline-end: -3.5rem;
  }

  .md\:-scroll-me-16 {
    scroll-margin-inline-end: -4rem;
  }

  .md\:-scroll-me-2 {
    scroll-margin-inline-end: -.5rem;
  }

  .md\:-scroll-me-2\.5 {
    scroll-margin-inline-end: -.625rem;
  }

  .md\:-scroll-me-20 {
    scroll-margin-inline-end: -5rem;
  }

  .md\:-scroll-me-24 {
    scroll-margin-inline-end: -6rem;
  }

  .md\:-scroll-me-28 {
    scroll-margin-inline-end: -7rem;
  }

  .md\:-scroll-me-3 {
    scroll-margin-inline-end: -.75rem;
  }

  .md\:-scroll-me-3\.5 {
    scroll-margin-inline-end: -.875rem;
  }

  .md\:-scroll-me-32 {
    scroll-margin-inline-end: -8rem;
  }

  .md\:-scroll-me-36 {
    scroll-margin-inline-end: -9rem;
  }

  .md\:-scroll-me-4 {
    scroll-margin-inline-end: -1rem;
  }

  .md\:-scroll-me-40 {
    scroll-margin-inline-end: -10rem;
  }

  .md\:-scroll-me-44 {
    scroll-margin-inline-end: -11rem;
  }

  .md\:-scroll-me-48 {
    scroll-margin-inline-end: -12rem;
  }

  .md\:-scroll-me-5 {
    scroll-margin-inline-end: -1.25rem;
  }

  .md\:-scroll-me-52 {
    scroll-margin-inline-end: -13rem;
  }

  .md\:-scroll-me-56 {
    scroll-margin-inline-end: -14rem;
  }

  .md\:-scroll-me-6 {
    scroll-margin-inline-end: -1.5rem;
  }

  .md\:-scroll-me-60 {
    scroll-margin-inline-end: -15rem;
  }

  .md\:-scroll-me-64 {
    scroll-margin-inline-end: -16rem;
  }

  .md\:-scroll-me-7 {
    scroll-margin-inline-end: -1.75rem;
  }

  .md\:-scroll-me-72 {
    scroll-margin-inline-end: -18rem;
  }

  .md\:-scroll-me-8 {
    scroll-margin-inline-end: -2rem;
  }

  .md\:-scroll-me-80 {
    scroll-margin-inline-end: -20rem;
  }

  .md\:-scroll-me-9 {
    scroll-margin-inline-end: -2.25rem;
  }

  .md\:-scroll-me-96 {
    scroll-margin-inline-end: -24rem;
  }

  .md\:-scroll-me-px {
    scroll-margin-inline-end: -1px;
  }

  .md\:-scroll-ml-0 {
    scroll-margin-left: 0;
  }

  .md\:-scroll-ml-0\.5 {
    scroll-margin-left: -.125rem;
  }

  .md\:-scroll-ml-1 {
    scroll-margin-left: -.25rem;
  }

  .md\:-scroll-ml-1\.5 {
    scroll-margin-left: -.375rem;
  }

  .md\:-scroll-ml-10 {
    scroll-margin-left: -2.5rem;
  }

  .md\:-scroll-ml-11 {
    scroll-margin-left: -2.75rem;
  }

  .md\:-scroll-ml-12 {
    scroll-margin-left: -3rem;
  }

  .md\:-scroll-ml-14 {
    scroll-margin-left: -3.5rem;
  }

  .md\:-scroll-ml-16 {
    scroll-margin-left: -4rem;
  }

  .md\:-scroll-ml-2 {
    scroll-margin-left: -.5rem;
  }

  .md\:-scroll-ml-2\.5 {
    scroll-margin-left: -.625rem;
  }

  .md\:-scroll-ml-20 {
    scroll-margin-left: -5rem;
  }

  .md\:-scroll-ml-24 {
    scroll-margin-left: -6rem;
  }

  .md\:-scroll-ml-28 {
    scroll-margin-left: -7rem;
  }

  .md\:-scroll-ml-3 {
    scroll-margin-left: -.75rem;
  }

  .md\:-scroll-ml-3\.5 {
    scroll-margin-left: -.875rem;
  }

  .md\:-scroll-ml-32 {
    scroll-margin-left: -8rem;
  }

  .md\:-scroll-ml-36 {
    scroll-margin-left: -9rem;
  }

  .md\:-scroll-ml-4 {
    scroll-margin-left: -1rem;
  }

  .md\:-scroll-ml-40 {
    scroll-margin-left: -10rem;
  }

  .md\:-scroll-ml-44 {
    scroll-margin-left: -11rem;
  }

  .md\:-scroll-ml-48 {
    scroll-margin-left: -12rem;
  }

  .md\:-scroll-ml-5 {
    scroll-margin-left: -1.25rem;
  }

  .md\:-scroll-ml-52 {
    scroll-margin-left: -13rem;
  }

  .md\:-scroll-ml-56 {
    scroll-margin-left: -14rem;
  }

  .md\:-scroll-ml-6 {
    scroll-margin-left: -1.5rem;
  }

  .md\:-scroll-ml-60 {
    scroll-margin-left: -15rem;
  }

  .md\:-scroll-ml-64 {
    scroll-margin-left: -16rem;
  }

  .md\:-scroll-ml-7 {
    scroll-margin-left: -1.75rem;
  }

  .md\:-scroll-ml-72 {
    scroll-margin-left: -18rem;
  }

  .md\:-scroll-ml-8 {
    scroll-margin-left: -2rem;
  }

  .md\:-scroll-ml-80 {
    scroll-margin-left: -20rem;
  }

  .md\:-scroll-ml-9 {
    scroll-margin-left: -2.25rem;
  }

  .md\:-scroll-ml-96 {
    scroll-margin-left: -24rem;
  }

  .md\:-scroll-ml-px {
    scroll-margin-left: -1px;
  }

  .md\:-scroll-mr-0 {
    scroll-margin-right: 0;
  }

  .md\:-scroll-mr-0\.5 {
    scroll-margin-right: -.125rem;
  }

  .md\:-scroll-mr-1 {
    scroll-margin-right: -.25rem;
  }

  .md\:-scroll-mr-1\.5 {
    scroll-margin-right: -.375rem;
  }

  .md\:-scroll-mr-10 {
    scroll-margin-right: -2.5rem;
  }

  .md\:-scroll-mr-11 {
    scroll-margin-right: -2.75rem;
  }

  .md\:-scroll-mr-12 {
    scroll-margin-right: -3rem;
  }

  .md\:-scroll-mr-14 {
    scroll-margin-right: -3.5rem;
  }

  .md\:-scroll-mr-16 {
    scroll-margin-right: -4rem;
  }

  .md\:-scroll-mr-2 {
    scroll-margin-right: -.5rem;
  }

  .md\:-scroll-mr-2\.5 {
    scroll-margin-right: -.625rem;
  }

  .md\:-scroll-mr-20 {
    scroll-margin-right: -5rem;
  }

  .md\:-scroll-mr-24 {
    scroll-margin-right: -6rem;
  }

  .md\:-scroll-mr-28 {
    scroll-margin-right: -7rem;
  }

  .md\:-scroll-mr-3 {
    scroll-margin-right: -.75rem;
  }

  .md\:-scroll-mr-3\.5 {
    scroll-margin-right: -.875rem;
  }

  .md\:-scroll-mr-32 {
    scroll-margin-right: -8rem;
  }

  .md\:-scroll-mr-36 {
    scroll-margin-right: -9rem;
  }

  .md\:-scroll-mr-4 {
    scroll-margin-right: -1rem;
  }

  .md\:-scroll-mr-40 {
    scroll-margin-right: -10rem;
  }

  .md\:-scroll-mr-44 {
    scroll-margin-right: -11rem;
  }

  .md\:-scroll-mr-48 {
    scroll-margin-right: -12rem;
  }

  .md\:-scroll-mr-5 {
    scroll-margin-right: -1.25rem;
  }

  .md\:-scroll-mr-52 {
    scroll-margin-right: -13rem;
  }

  .md\:-scroll-mr-56 {
    scroll-margin-right: -14rem;
  }

  .md\:-scroll-mr-6 {
    scroll-margin-right: -1.5rem;
  }

  .md\:-scroll-mr-60 {
    scroll-margin-right: -15rem;
  }

  .md\:-scroll-mr-64 {
    scroll-margin-right: -16rem;
  }

  .md\:-scroll-mr-7 {
    scroll-margin-right: -1.75rem;
  }

  .md\:-scroll-mr-72 {
    scroll-margin-right: -18rem;
  }

  .md\:-scroll-mr-8 {
    scroll-margin-right: -2rem;
  }

  .md\:-scroll-mr-80 {
    scroll-margin-right: -20rem;
  }

  .md\:-scroll-mr-9 {
    scroll-margin-right: -2.25rem;
  }

  .md\:-scroll-mr-96 {
    scroll-margin-right: -24rem;
  }

  .md\:-scroll-mr-px {
    scroll-margin-right: -1px;
  }

  .md\:-scroll-ms-0 {
    scroll-margin-inline-start: 0;
  }

  .md\:-scroll-ms-0\.5 {
    scroll-margin-inline-start: -.125rem;
  }

  .md\:-scroll-ms-1 {
    scroll-margin-inline-start: -.25rem;
  }

  .md\:-scroll-ms-1\.5 {
    scroll-margin-inline-start: -.375rem;
  }

  .md\:-scroll-ms-10 {
    scroll-margin-inline-start: -2.5rem;
  }

  .md\:-scroll-ms-11 {
    scroll-margin-inline-start: -2.75rem;
  }

  .md\:-scroll-ms-12 {
    scroll-margin-inline-start: -3rem;
  }

  .md\:-scroll-ms-14 {
    scroll-margin-inline-start: -3.5rem;
  }

  .md\:-scroll-ms-16 {
    scroll-margin-inline-start: -4rem;
  }

  .md\:-scroll-ms-2 {
    scroll-margin-inline-start: -.5rem;
  }

  .md\:-scroll-ms-2\.5 {
    scroll-margin-inline-start: -.625rem;
  }

  .md\:-scroll-ms-20 {
    scroll-margin-inline-start: -5rem;
  }

  .md\:-scroll-ms-24 {
    scroll-margin-inline-start: -6rem;
  }

  .md\:-scroll-ms-28 {
    scroll-margin-inline-start: -7rem;
  }

  .md\:-scroll-ms-3 {
    scroll-margin-inline-start: -.75rem;
  }

  .md\:-scroll-ms-3\.5 {
    scroll-margin-inline-start: -.875rem;
  }

  .md\:-scroll-ms-32 {
    scroll-margin-inline-start: -8rem;
  }

  .md\:-scroll-ms-36 {
    scroll-margin-inline-start: -9rem;
  }

  .md\:-scroll-ms-4 {
    scroll-margin-inline-start: -1rem;
  }

  .md\:-scroll-ms-40 {
    scroll-margin-inline-start: -10rem;
  }

  .md\:-scroll-ms-44 {
    scroll-margin-inline-start: -11rem;
  }

  .md\:-scroll-ms-48 {
    scroll-margin-inline-start: -12rem;
  }

  .md\:-scroll-ms-5 {
    scroll-margin-inline-start: -1.25rem;
  }

  .md\:-scroll-ms-52 {
    scroll-margin-inline-start: -13rem;
  }

  .md\:-scroll-ms-56 {
    scroll-margin-inline-start: -14rem;
  }

  .md\:-scroll-ms-6 {
    scroll-margin-inline-start: -1.5rem;
  }

  .md\:-scroll-ms-60 {
    scroll-margin-inline-start: -15rem;
  }

  .md\:-scroll-ms-64 {
    scroll-margin-inline-start: -16rem;
  }

  .md\:-scroll-ms-7 {
    scroll-margin-inline-start: -1.75rem;
  }

  .md\:-scroll-ms-72 {
    scroll-margin-inline-start: -18rem;
  }

  .md\:-scroll-ms-8 {
    scroll-margin-inline-start: -2rem;
  }

  .md\:-scroll-ms-80 {
    scroll-margin-inline-start: -20rem;
  }

  .md\:-scroll-ms-9 {
    scroll-margin-inline-start: -2.25rem;
  }

  .md\:-scroll-ms-96 {
    scroll-margin-inline-start: -24rem;
  }

  .md\:-scroll-ms-px {
    scroll-margin-inline-start: -1px;
  }

  .md\:-scroll-mt-0 {
    scroll-margin-top: 0;
  }

  .md\:-scroll-mt-0\.5 {
    scroll-margin-top: -.125rem;
  }

  .md\:-scroll-mt-1 {
    scroll-margin-top: -.25rem;
  }

  .md\:-scroll-mt-1\.5 {
    scroll-margin-top: -.375rem;
  }

  .md\:-scroll-mt-10 {
    scroll-margin-top: -2.5rem;
  }

  .md\:-scroll-mt-11 {
    scroll-margin-top: -2.75rem;
  }

  .md\:-scroll-mt-12 {
    scroll-margin-top: -3rem;
  }

  .md\:-scroll-mt-14 {
    scroll-margin-top: -3.5rem;
  }

  .md\:-scroll-mt-16 {
    scroll-margin-top: -4rem;
  }

  .md\:-scroll-mt-2 {
    scroll-margin-top: -.5rem;
  }

  .md\:-scroll-mt-2\.5 {
    scroll-margin-top: -.625rem;
  }

  .md\:-scroll-mt-20 {
    scroll-margin-top: -5rem;
  }

  .md\:-scroll-mt-24 {
    scroll-margin-top: -6rem;
  }

  .md\:-scroll-mt-28 {
    scroll-margin-top: -7rem;
  }

  .md\:-scroll-mt-3 {
    scroll-margin-top: -.75rem;
  }

  .md\:-scroll-mt-3\.5 {
    scroll-margin-top: -.875rem;
  }

  .md\:-scroll-mt-32 {
    scroll-margin-top: -8rem;
  }

  .md\:-scroll-mt-36 {
    scroll-margin-top: -9rem;
  }

  .md\:-scroll-mt-4 {
    scroll-margin-top: -1rem;
  }

  .md\:-scroll-mt-40 {
    scroll-margin-top: -10rem;
  }

  .md\:-scroll-mt-44 {
    scroll-margin-top: -11rem;
  }

  .md\:-scroll-mt-48 {
    scroll-margin-top: -12rem;
  }

  .md\:-scroll-mt-5 {
    scroll-margin-top: -1.25rem;
  }

  .md\:-scroll-mt-52 {
    scroll-margin-top: -13rem;
  }

  .md\:-scroll-mt-56 {
    scroll-margin-top: -14rem;
  }

  .md\:-scroll-mt-6 {
    scroll-margin-top: -1.5rem;
  }

  .md\:-scroll-mt-60 {
    scroll-margin-top: -15rem;
  }

  .md\:-scroll-mt-64 {
    scroll-margin-top: -16rem;
  }

  .md\:-scroll-mt-7 {
    scroll-margin-top: -1.75rem;
  }

  .md\:-scroll-mt-72 {
    scroll-margin-top: -18rem;
  }

  .md\:-scroll-mt-8 {
    scroll-margin-top: -2rem;
  }

  .md\:-scroll-mt-80 {
    scroll-margin-top: -20rem;
  }

  .md\:-scroll-mt-9 {
    scroll-margin-top: -2.25rem;
  }

  .md\:-scroll-mt-96 {
    scroll-margin-top: -24rem;
  }

  .md\:-scroll-mt-px {
    scroll-margin-top: -1px;
  }

  .md\:scroll-mb-0 {
    scroll-margin-bottom: 0;
  }

  .md\:scroll-mb-0\.5 {
    scroll-margin-bottom: .125rem;
  }

  .md\:scroll-mb-1 {
    scroll-margin-bottom: .25rem;
  }

  .md\:scroll-mb-1\.5 {
    scroll-margin-bottom: .375rem;
  }

  .md\:scroll-mb-10 {
    scroll-margin-bottom: 2.5rem;
  }

  .md\:scroll-mb-11 {
    scroll-margin-bottom: 2.75rem;
  }

  .md\:scroll-mb-12 {
    scroll-margin-bottom: 3rem;
  }

  .md\:scroll-mb-14 {
    scroll-margin-bottom: 3.5rem;
  }

  .md\:scroll-mb-16 {
    scroll-margin-bottom: 4rem;
  }

  .md\:scroll-mb-2 {
    scroll-margin-bottom: .5rem;
  }

  .md\:scroll-mb-2\.5 {
    scroll-margin-bottom: .625rem;
  }

  .md\:scroll-mb-20 {
    scroll-margin-bottom: 5rem;
  }

  .md\:scroll-mb-24 {
    scroll-margin-bottom: 6rem;
  }

  .md\:scroll-mb-28 {
    scroll-margin-bottom: 7rem;
  }

  .md\:scroll-mb-3 {
    scroll-margin-bottom: .75rem;
  }

  .md\:scroll-mb-3\.5 {
    scroll-margin-bottom: .875rem;
  }

  .md\:scroll-mb-32 {
    scroll-margin-bottom: 8rem;
  }

  .md\:scroll-mb-36 {
    scroll-margin-bottom: 9rem;
  }

  .md\:scroll-mb-4 {
    scroll-margin-bottom: 1rem;
  }

  .md\:scroll-mb-40 {
    scroll-margin-bottom: 10rem;
  }

  .md\:scroll-mb-44 {
    scroll-margin-bottom: 11rem;
  }

  .md\:scroll-mb-48 {
    scroll-margin-bottom: 12rem;
  }

  .md\:scroll-mb-5 {
    scroll-margin-bottom: 1.25rem;
  }

  .md\:scroll-mb-52 {
    scroll-margin-bottom: 13rem;
  }

  .md\:scroll-mb-56 {
    scroll-margin-bottom: 14rem;
  }

  .md\:scroll-mb-6 {
    scroll-margin-bottom: 1.5rem;
  }

  .md\:scroll-mb-60 {
    scroll-margin-bottom: 15rem;
  }

  .md\:scroll-mb-64 {
    scroll-margin-bottom: 16rem;
  }

  .md\:scroll-mb-7 {
    scroll-margin-bottom: 1.75rem;
  }

  .md\:scroll-mb-72 {
    scroll-margin-bottom: 18rem;
  }

  .md\:scroll-mb-8 {
    scroll-margin-bottom: 2rem;
  }

  .md\:scroll-mb-80 {
    scroll-margin-bottom: 20rem;
  }

  .md\:scroll-mb-9 {
    scroll-margin-bottom: 2.25rem;
  }

  .md\:scroll-mb-96 {
    scroll-margin-bottom: 24rem;
  }

  .md\:scroll-mb-px {
    scroll-margin-bottom: 1px;
  }

  .md\:scroll-me-0 {
    scroll-margin-inline-end: 0;
  }

  .md\:scroll-me-0\.5 {
    scroll-margin-inline-end: .125rem;
  }

  .md\:scroll-me-1 {
    scroll-margin-inline-end: .25rem;
  }

  .md\:scroll-me-1\.5 {
    scroll-margin-inline-end: .375rem;
  }

  .md\:scroll-me-10 {
    scroll-margin-inline-end: 2.5rem;
  }

  .md\:scroll-me-11 {
    scroll-margin-inline-end: 2.75rem;
  }

  .md\:scroll-me-12 {
    scroll-margin-inline-end: 3rem;
  }

  .md\:scroll-me-14 {
    scroll-margin-inline-end: 3.5rem;
  }

  .md\:scroll-me-16 {
    scroll-margin-inline-end: 4rem;
  }

  .md\:scroll-me-2 {
    scroll-margin-inline-end: .5rem;
  }

  .md\:scroll-me-2\.5 {
    scroll-margin-inline-end: .625rem;
  }

  .md\:scroll-me-20 {
    scroll-margin-inline-end: 5rem;
  }

  .md\:scroll-me-24 {
    scroll-margin-inline-end: 6rem;
  }

  .md\:scroll-me-28 {
    scroll-margin-inline-end: 7rem;
  }

  .md\:scroll-me-3 {
    scroll-margin-inline-end: .75rem;
  }

  .md\:scroll-me-3\.5 {
    scroll-margin-inline-end: .875rem;
  }

  .md\:scroll-me-32 {
    scroll-margin-inline-end: 8rem;
  }

  .md\:scroll-me-36 {
    scroll-margin-inline-end: 9rem;
  }

  .md\:scroll-me-4 {
    scroll-margin-inline-end: 1rem;
  }

  .md\:scroll-me-40 {
    scroll-margin-inline-end: 10rem;
  }

  .md\:scroll-me-44 {
    scroll-margin-inline-end: 11rem;
  }

  .md\:scroll-me-48 {
    scroll-margin-inline-end: 12rem;
  }

  .md\:scroll-me-5 {
    scroll-margin-inline-end: 1.25rem;
  }

  .md\:scroll-me-52 {
    scroll-margin-inline-end: 13rem;
  }

  .md\:scroll-me-56 {
    scroll-margin-inline-end: 14rem;
  }

  .md\:scroll-me-6 {
    scroll-margin-inline-end: 1.5rem;
  }

  .md\:scroll-me-60 {
    scroll-margin-inline-end: 15rem;
  }

  .md\:scroll-me-64 {
    scroll-margin-inline-end: 16rem;
  }

  .md\:scroll-me-7 {
    scroll-margin-inline-end: 1.75rem;
  }

  .md\:scroll-me-72 {
    scroll-margin-inline-end: 18rem;
  }

  .md\:scroll-me-8 {
    scroll-margin-inline-end: 2rem;
  }

  .md\:scroll-me-80 {
    scroll-margin-inline-end: 20rem;
  }

  .md\:scroll-me-9 {
    scroll-margin-inline-end: 2.25rem;
  }

  .md\:scroll-me-96 {
    scroll-margin-inline-end: 24rem;
  }

  .md\:scroll-me-px {
    scroll-margin-inline-end: 1px;
  }

  .md\:scroll-ml-0 {
    scroll-margin-left: 0;
  }

  .md\:scroll-ml-0\.5 {
    scroll-margin-left: .125rem;
  }

  .md\:scroll-ml-1 {
    scroll-margin-left: .25rem;
  }

  .md\:scroll-ml-1\.5 {
    scroll-margin-left: .375rem;
  }

  .md\:scroll-ml-10 {
    scroll-margin-left: 2.5rem;
  }

  .md\:scroll-ml-11 {
    scroll-margin-left: 2.75rem;
  }

  .md\:scroll-ml-12 {
    scroll-margin-left: 3rem;
  }

  .md\:scroll-ml-14 {
    scroll-margin-left: 3.5rem;
  }

  .md\:scroll-ml-16 {
    scroll-margin-left: 4rem;
  }

  .md\:scroll-ml-2 {
    scroll-margin-left: .5rem;
  }

  .md\:scroll-ml-2\.5 {
    scroll-margin-left: .625rem;
  }

  .md\:scroll-ml-20 {
    scroll-margin-left: 5rem;
  }

  .md\:scroll-ml-24 {
    scroll-margin-left: 6rem;
  }

  .md\:scroll-ml-28 {
    scroll-margin-left: 7rem;
  }

  .md\:scroll-ml-3 {
    scroll-margin-left: .75rem;
  }

  .md\:scroll-ml-3\.5 {
    scroll-margin-left: .875rem;
  }

  .md\:scroll-ml-32 {
    scroll-margin-left: 8rem;
  }

  .md\:scroll-ml-36 {
    scroll-margin-left: 9rem;
  }

  .md\:scroll-ml-4 {
    scroll-margin-left: 1rem;
  }

  .md\:scroll-ml-40 {
    scroll-margin-left: 10rem;
  }

  .md\:scroll-ml-44 {
    scroll-margin-left: 11rem;
  }

  .md\:scroll-ml-48 {
    scroll-margin-left: 12rem;
  }

  .md\:scroll-ml-5 {
    scroll-margin-left: 1.25rem;
  }

  .md\:scroll-ml-52 {
    scroll-margin-left: 13rem;
  }

  .md\:scroll-ml-56 {
    scroll-margin-left: 14rem;
  }

  .md\:scroll-ml-6 {
    scroll-margin-left: 1.5rem;
  }

  .md\:scroll-ml-60 {
    scroll-margin-left: 15rem;
  }

  .md\:scroll-ml-64 {
    scroll-margin-left: 16rem;
  }

  .md\:scroll-ml-7 {
    scroll-margin-left: 1.75rem;
  }

  .md\:scroll-ml-72 {
    scroll-margin-left: 18rem;
  }

  .md\:scroll-ml-8 {
    scroll-margin-left: 2rem;
  }

  .md\:scroll-ml-80 {
    scroll-margin-left: 20rem;
  }

  .md\:scroll-ml-9 {
    scroll-margin-left: 2.25rem;
  }

  .md\:scroll-ml-96 {
    scroll-margin-left: 24rem;
  }

  .md\:scroll-ml-px {
    scroll-margin-left: 1px;
  }

  .md\:scroll-mr-0 {
    scroll-margin-right: 0;
  }

  .md\:scroll-mr-0\.5 {
    scroll-margin-right: .125rem;
  }

  .md\:scroll-mr-1 {
    scroll-margin-right: .25rem;
  }

  .md\:scroll-mr-1\.5 {
    scroll-margin-right: .375rem;
  }

  .md\:scroll-mr-10 {
    scroll-margin-right: 2.5rem;
  }

  .md\:scroll-mr-11 {
    scroll-margin-right: 2.75rem;
  }

  .md\:scroll-mr-12 {
    scroll-margin-right: 3rem;
  }

  .md\:scroll-mr-14 {
    scroll-margin-right: 3.5rem;
  }

  .md\:scroll-mr-16 {
    scroll-margin-right: 4rem;
  }

  .md\:scroll-mr-2 {
    scroll-margin-right: .5rem;
  }

  .md\:scroll-mr-2\.5 {
    scroll-margin-right: .625rem;
  }

  .md\:scroll-mr-20 {
    scroll-margin-right: 5rem;
  }

  .md\:scroll-mr-24 {
    scroll-margin-right: 6rem;
  }

  .md\:scroll-mr-28 {
    scroll-margin-right: 7rem;
  }

  .md\:scroll-mr-3 {
    scroll-margin-right: .75rem;
  }

  .md\:scroll-mr-3\.5 {
    scroll-margin-right: .875rem;
  }

  .md\:scroll-mr-32 {
    scroll-margin-right: 8rem;
  }

  .md\:scroll-mr-36 {
    scroll-margin-right: 9rem;
  }

  .md\:scroll-mr-4 {
    scroll-margin-right: 1rem;
  }

  .md\:scroll-mr-40 {
    scroll-margin-right: 10rem;
  }

  .md\:scroll-mr-44 {
    scroll-margin-right: 11rem;
  }

  .md\:scroll-mr-48 {
    scroll-margin-right: 12rem;
  }

  .md\:scroll-mr-5 {
    scroll-margin-right: 1.25rem;
  }

  .md\:scroll-mr-52 {
    scroll-margin-right: 13rem;
  }

  .md\:scroll-mr-56 {
    scroll-margin-right: 14rem;
  }

  .md\:scroll-mr-6 {
    scroll-margin-right: 1.5rem;
  }

  .md\:scroll-mr-60 {
    scroll-margin-right: 15rem;
  }

  .md\:scroll-mr-64 {
    scroll-margin-right: 16rem;
  }

  .md\:scroll-mr-7 {
    scroll-margin-right: 1.75rem;
  }

  .md\:scroll-mr-72 {
    scroll-margin-right: 18rem;
  }

  .md\:scroll-mr-8 {
    scroll-margin-right: 2rem;
  }

  .md\:scroll-mr-80 {
    scroll-margin-right: 20rem;
  }

  .md\:scroll-mr-9 {
    scroll-margin-right: 2.25rem;
  }

  .md\:scroll-mr-96 {
    scroll-margin-right: 24rem;
  }

  .md\:scroll-mr-px {
    scroll-margin-right: 1px;
  }

  .md\:scroll-ms-0 {
    scroll-margin-inline-start: 0;
  }

  .md\:scroll-ms-0\.5 {
    scroll-margin-inline-start: .125rem;
  }

  .md\:scroll-ms-1 {
    scroll-margin-inline-start: .25rem;
  }

  .md\:scroll-ms-1\.5 {
    scroll-margin-inline-start: .375rem;
  }

  .md\:scroll-ms-10 {
    scroll-margin-inline-start: 2.5rem;
  }

  .md\:scroll-ms-11 {
    scroll-margin-inline-start: 2.75rem;
  }

  .md\:scroll-ms-12 {
    scroll-margin-inline-start: 3rem;
  }

  .md\:scroll-ms-14 {
    scroll-margin-inline-start: 3.5rem;
  }

  .md\:scroll-ms-16 {
    scroll-margin-inline-start: 4rem;
  }

  .md\:scroll-ms-2 {
    scroll-margin-inline-start: .5rem;
  }

  .md\:scroll-ms-2\.5 {
    scroll-margin-inline-start: .625rem;
  }

  .md\:scroll-ms-20 {
    scroll-margin-inline-start: 5rem;
  }

  .md\:scroll-ms-24 {
    scroll-margin-inline-start: 6rem;
  }

  .md\:scroll-ms-28 {
    scroll-margin-inline-start: 7rem;
  }

  .md\:scroll-ms-3 {
    scroll-margin-inline-start: .75rem;
  }

  .md\:scroll-ms-3\.5 {
    scroll-margin-inline-start: .875rem;
  }

  .md\:scroll-ms-32 {
    scroll-margin-inline-start: 8rem;
  }

  .md\:scroll-ms-36 {
    scroll-margin-inline-start: 9rem;
  }

  .md\:scroll-ms-4 {
    scroll-margin-inline-start: 1rem;
  }

  .md\:scroll-ms-40 {
    scroll-margin-inline-start: 10rem;
  }

  .md\:scroll-ms-44 {
    scroll-margin-inline-start: 11rem;
  }

  .md\:scroll-ms-48 {
    scroll-margin-inline-start: 12rem;
  }

  .md\:scroll-ms-5 {
    scroll-margin-inline-start: 1.25rem;
  }

  .md\:scroll-ms-52 {
    scroll-margin-inline-start: 13rem;
  }

  .md\:scroll-ms-56 {
    scroll-margin-inline-start: 14rem;
  }

  .md\:scroll-ms-6 {
    scroll-margin-inline-start: 1.5rem;
  }

  .md\:scroll-ms-60 {
    scroll-margin-inline-start: 15rem;
  }

  .md\:scroll-ms-64 {
    scroll-margin-inline-start: 16rem;
  }

  .md\:scroll-ms-7 {
    scroll-margin-inline-start: 1.75rem;
  }

  .md\:scroll-ms-72 {
    scroll-margin-inline-start: 18rem;
  }

  .md\:scroll-ms-8 {
    scroll-margin-inline-start: 2rem;
  }

  .md\:scroll-ms-80 {
    scroll-margin-inline-start: 20rem;
  }

  .md\:scroll-ms-9 {
    scroll-margin-inline-start: 2.25rem;
  }

  .md\:scroll-ms-96 {
    scroll-margin-inline-start: 24rem;
  }

  .md\:scroll-ms-px {
    scroll-margin-inline-start: 1px;
  }

  .md\:scroll-mt-0 {
    scroll-margin-top: 0;
  }

  .md\:scroll-mt-0\.5 {
    scroll-margin-top: .125rem;
  }

  .md\:scroll-mt-1 {
    scroll-margin-top: .25rem;
  }

  .md\:scroll-mt-1\.5 {
    scroll-margin-top: .375rem;
  }

  .md\:scroll-mt-10 {
    scroll-margin-top: 2.5rem;
  }

  .md\:scroll-mt-11 {
    scroll-margin-top: 2.75rem;
  }

  .md\:scroll-mt-12 {
    scroll-margin-top: 3rem;
  }

  .md\:scroll-mt-14 {
    scroll-margin-top: 3.5rem;
  }

  .md\:scroll-mt-16 {
    scroll-margin-top: 4rem;
  }

  .md\:scroll-mt-2 {
    scroll-margin-top: .5rem;
  }

  .md\:scroll-mt-2\.5 {
    scroll-margin-top: .625rem;
  }

  .md\:scroll-mt-20 {
    scroll-margin-top: 5rem;
  }

  .md\:scroll-mt-24 {
    scroll-margin-top: 6rem;
  }

  .md\:scroll-mt-28 {
    scroll-margin-top: 7rem;
  }

  .md\:scroll-mt-3 {
    scroll-margin-top: .75rem;
  }

  .md\:scroll-mt-3\.5 {
    scroll-margin-top: .875rem;
  }

  .md\:scroll-mt-32 {
    scroll-margin-top: 8rem;
  }

  .md\:scroll-mt-36 {
    scroll-margin-top: 9rem;
  }

  .md\:scroll-mt-4 {
    scroll-margin-top: 1rem;
  }

  .md\:scroll-mt-40 {
    scroll-margin-top: 10rem;
  }

  .md\:scroll-mt-44 {
    scroll-margin-top: 11rem;
  }

  .md\:scroll-mt-48 {
    scroll-margin-top: 12rem;
  }

  .md\:scroll-mt-5 {
    scroll-margin-top: 1.25rem;
  }

  .md\:scroll-mt-52 {
    scroll-margin-top: 13rem;
  }

  .md\:scroll-mt-56 {
    scroll-margin-top: 14rem;
  }

  .md\:scroll-mt-6 {
    scroll-margin-top: 1.5rem;
  }

  .md\:scroll-mt-60 {
    scroll-margin-top: 15rem;
  }

  .md\:scroll-mt-64 {
    scroll-margin-top: 16rem;
  }

  .md\:scroll-mt-7 {
    scroll-margin-top: 1.75rem;
  }

  .md\:scroll-mt-72 {
    scroll-margin-top: 18rem;
  }

  .md\:scroll-mt-8 {
    scroll-margin-top: 2rem;
  }

  .md\:scroll-mt-80 {
    scroll-margin-top: 20rem;
  }

  .md\:scroll-mt-9 {
    scroll-margin-top: 2.25rem;
  }

  .md\:scroll-mt-96 {
    scroll-margin-top: 24rem;
  }

  .md\:scroll-mt-px {
    scroll-margin-top: 1px;
  }

  .md\:scroll-p-0 {
    scroll-padding: 0;
  }

  .md\:scroll-p-0\.5 {
    scroll-padding: .125rem;
  }

  .md\:scroll-p-1 {
    scroll-padding: .25rem;
  }

  .md\:scroll-p-1\.5 {
    scroll-padding: .375rem;
  }

  .md\:scroll-p-10 {
    scroll-padding: 2.5rem;
  }

  .md\:scroll-p-11 {
    scroll-padding: 2.75rem;
  }

  .md\:scroll-p-12 {
    scroll-padding: 3rem;
  }

  .md\:scroll-p-14 {
    scroll-padding: 3.5rem;
  }

  .md\:scroll-p-16 {
    scroll-padding: 4rem;
  }

  .md\:scroll-p-2 {
    scroll-padding: .5rem;
  }

  .md\:scroll-p-2\.5 {
    scroll-padding: .625rem;
  }

  .md\:scroll-p-20 {
    scroll-padding: 5rem;
  }

  .md\:scroll-p-24 {
    scroll-padding: 6rem;
  }

  .md\:scroll-p-28 {
    scroll-padding: 7rem;
  }

  .md\:scroll-p-3 {
    scroll-padding: .75rem;
  }

  .md\:scroll-p-3\.5 {
    scroll-padding: .875rem;
  }

  .md\:scroll-p-32 {
    scroll-padding: 8rem;
  }

  .md\:scroll-p-36 {
    scroll-padding: 9rem;
  }

  .md\:scroll-p-4 {
    scroll-padding: 1rem;
  }

  .md\:scroll-p-40 {
    scroll-padding: 10rem;
  }

  .md\:scroll-p-44 {
    scroll-padding: 11rem;
  }

  .md\:scroll-p-48 {
    scroll-padding: 12rem;
  }

  .md\:scroll-p-5 {
    scroll-padding: 1.25rem;
  }

  .md\:scroll-p-52 {
    scroll-padding: 13rem;
  }

  .md\:scroll-p-56 {
    scroll-padding: 14rem;
  }

  .md\:scroll-p-6 {
    scroll-padding: 1.5rem;
  }

  .md\:scroll-p-60 {
    scroll-padding: 15rem;
  }

  .md\:scroll-p-64 {
    scroll-padding: 16rem;
  }

  .md\:scroll-p-7 {
    scroll-padding: 1.75rem;
  }

  .md\:scroll-p-72 {
    scroll-padding: 18rem;
  }

  .md\:scroll-p-8 {
    scroll-padding: 2rem;
  }

  .md\:scroll-p-80 {
    scroll-padding: 20rem;
  }

  .md\:scroll-p-9 {
    scroll-padding: 2.25rem;
  }

  .md\:scroll-p-96 {
    scroll-padding: 24rem;
  }

  .md\:scroll-p-px {
    scroll-padding: 1px;
  }

  .md\:scroll-px-0 {
    scroll-padding-left: 0;
    scroll-padding-right: 0;
  }

  .md\:scroll-px-0\.5 {
    scroll-padding-left: .125rem;
    scroll-padding-right: .125rem;
  }

  .md\:scroll-px-1 {
    scroll-padding-left: .25rem;
    scroll-padding-right: .25rem;
  }

  .md\:scroll-px-1\.5 {
    scroll-padding-left: .375rem;
    scroll-padding-right: .375rem;
  }

  .md\:scroll-px-10 {
    scroll-padding-left: 2.5rem;
    scroll-padding-right: 2.5rem;
  }

  .md\:scroll-px-11 {
    scroll-padding-left: 2.75rem;
    scroll-padding-right: 2.75rem;
  }

  .md\:scroll-px-12 {
    scroll-padding-left: 3rem;
    scroll-padding-right: 3rem;
  }

  .md\:scroll-px-14 {
    scroll-padding-left: 3.5rem;
    scroll-padding-right: 3.5rem;
  }

  .md\:scroll-px-16 {
    scroll-padding-left: 4rem;
    scroll-padding-right: 4rem;
  }

  .md\:scroll-px-2 {
    scroll-padding-left: .5rem;
    scroll-padding-right: .5rem;
  }

  .md\:scroll-px-2\.5 {
    scroll-padding-left: .625rem;
    scroll-padding-right: .625rem;
  }

  .md\:scroll-px-20 {
    scroll-padding-left: 5rem;
    scroll-padding-right: 5rem;
  }

  .md\:scroll-px-24 {
    scroll-padding-left: 6rem;
    scroll-padding-right: 6rem;
  }

  .md\:scroll-px-28 {
    scroll-padding-left: 7rem;
    scroll-padding-right: 7rem;
  }

  .md\:scroll-px-3 {
    scroll-padding-left: .75rem;
    scroll-padding-right: .75rem;
  }

  .md\:scroll-px-3\.5 {
    scroll-padding-left: .875rem;
    scroll-padding-right: .875rem;
  }

  .md\:scroll-px-32 {
    scroll-padding-left: 8rem;
    scroll-padding-right: 8rem;
  }

  .md\:scroll-px-36 {
    scroll-padding-left: 9rem;
    scroll-padding-right: 9rem;
  }

  .md\:scroll-px-4 {
    scroll-padding-left: 1rem;
    scroll-padding-right: 1rem;
  }

  .md\:scroll-px-40 {
    scroll-padding-left: 10rem;
    scroll-padding-right: 10rem;
  }

  .md\:scroll-px-44 {
    scroll-padding-left: 11rem;
    scroll-padding-right: 11rem;
  }

  .md\:scroll-px-48 {
    scroll-padding-left: 12rem;
    scroll-padding-right: 12rem;
  }

  .md\:scroll-px-5 {
    scroll-padding-left: 1.25rem;
    scroll-padding-right: 1.25rem;
  }

  .md\:scroll-px-52 {
    scroll-padding-left: 13rem;
    scroll-padding-right: 13rem;
  }

  .md\:scroll-px-56 {
    scroll-padding-left: 14rem;
    scroll-padding-right: 14rem;
  }

  .md\:scroll-px-6 {
    scroll-padding-left: 1.5rem;
    scroll-padding-right: 1.5rem;
  }

  .md\:scroll-px-60 {
    scroll-padding-left: 15rem;
    scroll-padding-right: 15rem;
  }

  .md\:scroll-px-64 {
    scroll-padding-left: 16rem;
    scroll-padding-right: 16rem;
  }

  .md\:scroll-px-7 {
    scroll-padding-left: 1.75rem;
    scroll-padding-right: 1.75rem;
  }

  .md\:scroll-px-72 {
    scroll-padding-left: 18rem;
    scroll-padding-right: 18rem;
  }

  .md\:scroll-px-8 {
    scroll-padding-left: 2rem;
    scroll-padding-right: 2rem;
  }

  .md\:scroll-px-80 {
    scroll-padding-left: 20rem;
    scroll-padding-right: 20rem;
  }

  .md\:scroll-px-9 {
    scroll-padding-left: 2.25rem;
    scroll-padding-right: 2.25rem;
  }

  .md\:scroll-px-96 {
    scroll-padding-left: 24rem;
    scroll-padding-right: 24rem;
  }

  .md\:scroll-px-px {
    scroll-padding-left: 1px;
    scroll-padding-right: 1px;
  }

  .md\:scroll-py-0 {
    scroll-padding-top: 0;
    scroll-padding-bottom: 0;
  }

  .md\:scroll-py-0\.5 {
    scroll-padding-top: .125rem;
    scroll-padding-bottom: .125rem;
  }

  .md\:scroll-py-1 {
    scroll-padding-top: .25rem;
    scroll-padding-bottom: .25rem;
  }

  .md\:scroll-py-1\.5 {
    scroll-padding-top: .375rem;
    scroll-padding-bottom: .375rem;
  }

  .md\:scroll-py-10 {
    scroll-padding-top: 2.5rem;
    scroll-padding-bottom: 2.5rem;
  }

  .md\:scroll-py-11 {
    scroll-padding-top: 2.75rem;
    scroll-padding-bottom: 2.75rem;
  }

  .md\:scroll-py-12 {
    scroll-padding-top: 3rem;
    scroll-padding-bottom: 3rem;
  }

  .md\:scroll-py-14 {
    scroll-padding-top: 3.5rem;
    scroll-padding-bottom: 3.5rem;
  }

  .md\:scroll-py-16 {
    scroll-padding-top: 4rem;
    scroll-padding-bottom: 4rem;
  }

  .md\:scroll-py-2 {
    scroll-padding-top: .5rem;
    scroll-padding-bottom: .5rem;
  }

  .md\:scroll-py-2\.5 {
    scroll-padding-top: .625rem;
    scroll-padding-bottom: .625rem;
  }

  .md\:scroll-py-20 {
    scroll-padding-top: 5rem;
    scroll-padding-bottom: 5rem;
  }

  .md\:scroll-py-24 {
    scroll-padding-top: 6rem;
    scroll-padding-bottom: 6rem;
  }

  .md\:scroll-py-28 {
    scroll-padding-top: 7rem;
    scroll-padding-bottom: 7rem;
  }

  .md\:scroll-py-3 {
    scroll-padding-top: .75rem;
    scroll-padding-bottom: .75rem;
  }

  .md\:scroll-py-3\.5 {
    scroll-padding-top: .875rem;
    scroll-padding-bottom: .875rem;
  }

  .md\:scroll-py-32 {
    scroll-padding-top: 8rem;
    scroll-padding-bottom: 8rem;
  }

  .md\:scroll-py-36 {
    scroll-padding-top: 9rem;
    scroll-padding-bottom: 9rem;
  }

  .md\:scroll-py-4 {
    scroll-padding-top: 1rem;
    scroll-padding-bottom: 1rem;
  }

  .md\:scroll-py-40 {
    scroll-padding-top: 10rem;
    scroll-padding-bottom: 10rem;
  }

  .md\:scroll-py-44 {
    scroll-padding-top: 11rem;
    scroll-padding-bottom: 11rem;
  }

  .md\:scroll-py-48 {
    scroll-padding-top: 12rem;
    scroll-padding-bottom: 12rem;
  }

  .md\:scroll-py-5 {
    scroll-padding-top: 1.25rem;
    scroll-padding-bottom: 1.25rem;
  }

  .md\:scroll-py-52 {
    scroll-padding-top: 13rem;
    scroll-padding-bottom: 13rem;
  }

  .md\:scroll-py-56 {
    scroll-padding-top: 14rem;
    scroll-padding-bottom: 14rem;
  }

  .md\:scroll-py-6 {
    scroll-padding-top: 1.5rem;
    scroll-padding-bottom: 1.5rem;
  }

  .md\:scroll-py-60 {
    scroll-padding-top: 15rem;
    scroll-padding-bottom: 15rem;
  }

  .md\:scroll-py-64 {
    scroll-padding-top: 16rem;
    scroll-padding-bottom: 16rem;
  }

  .md\:scroll-py-7 {
    scroll-padding-top: 1.75rem;
    scroll-padding-bottom: 1.75rem;
  }

  .md\:scroll-py-72 {
    scroll-padding-top: 18rem;
    scroll-padding-bottom: 18rem;
  }

  .md\:scroll-py-8 {
    scroll-padding-top: 2rem;
    scroll-padding-bottom: 2rem;
  }

  .md\:scroll-py-80 {
    scroll-padding-top: 20rem;
    scroll-padding-bottom: 20rem;
  }

  .md\:scroll-py-9 {
    scroll-padding-top: 2.25rem;
    scroll-padding-bottom: 2.25rem;
  }

  .md\:scroll-py-96 {
    scroll-padding-top: 24rem;
    scroll-padding-bottom: 24rem;
  }

  .md\:scroll-py-px {
    scroll-padding-top: 1px;
    scroll-padding-bottom: 1px;
  }

  .md\:scroll-pb-0 {
    scroll-padding-bottom: 0;
  }

  .md\:scroll-pb-0\.5 {
    scroll-padding-bottom: .125rem;
  }

  .md\:scroll-pb-1 {
    scroll-padding-bottom: .25rem;
  }

  .md\:scroll-pb-1\.5 {
    scroll-padding-bottom: .375rem;
  }

  .md\:scroll-pb-10 {
    scroll-padding-bottom: 2.5rem;
  }

  .md\:scroll-pb-11 {
    scroll-padding-bottom: 2.75rem;
  }

  .md\:scroll-pb-12 {
    scroll-padding-bottom: 3rem;
  }

  .md\:scroll-pb-14 {
    scroll-padding-bottom: 3.5rem;
  }

  .md\:scroll-pb-16 {
    scroll-padding-bottom: 4rem;
  }

  .md\:scroll-pb-2 {
    scroll-padding-bottom: .5rem;
  }

  .md\:scroll-pb-2\.5 {
    scroll-padding-bottom: .625rem;
  }

  .md\:scroll-pb-20 {
    scroll-padding-bottom: 5rem;
  }

  .md\:scroll-pb-24 {
    scroll-padding-bottom: 6rem;
  }

  .md\:scroll-pb-28 {
    scroll-padding-bottom: 7rem;
  }

  .md\:scroll-pb-3 {
    scroll-padding-bottom: .75rem;
  }

  .md\:scroll-pb-3\.5 {
    scroll-padding-bottom: .875rem;
  }

  .md\:scroll-pb-32 {
    scroll-padding-bottom: 8rem;
  }

  .md\:scroll-pb-36 {
    scroll-padding-bottom: 9rem;
  }

  .md\:scroll-pb-4 {
    scroll-padding-bottom: 1rem;
  }

  .md\:scroll-pb-40 {
    scroll-padding-bottom: 10rem;
  }

  .md\:scroll-pb-44 {
    scroll-padding-bottom: 11rem;
  }

  .md\:scroll-pb-48 {
    scroll-padding-bottom: 12rem;
  }

  .md\:scroll-pb-5 {
    scroll-padding-bottom: 1.25rem;
  }

  .md\:scroll-pb-52 {
    scroll-padding-bottom: 13rem;
  }

  .md\:scroll-pb-56 {
    scroll-padding-bottom: 14rem;
  }

  .md\:scroll-pb-6 {
    scroll-padding-bottom: 1.5rem;
  }

  .md\:scroll-pb-60 {
    scroll-padding-bottom: 15rem;
  }

  .md\:scroll-pb-64 {
    scroll-padding-bottom: 16rem;
  }

  .md\:scroll-pb-7 {
    scroll-padding-bottom: 1.75rem;
  }

  .md\:scroll-pb-72 {
    scroll-padding-bottom: 18rem;
  }

  .md\:scroll-pb-8 {
    scroll-padding-bottom: 2rem;
  }

  .md\:scroll-pb-80 {
    scroll-padding-bottom: 20rem;
  }

  .md\:scroll-pb-9 {
    scroll-padding-bottom: 2.25rem;
  }

  .md\:scroll-pb-96 {
    scroll-padding-bottom: 24rem;
  }

  .md\:scroll-pb-px {
    scroll-padding-bottom: 1px;
  }

  .md\:scroll-pe-0 {
    scroll-padding-inline-end: 0;
  }

  .md\:scroll-pe-0\.5 {
    scroll-padding-inline-end: .125rem;
  }

  .md\:scroll-pe-1 {
    scroll-padding-inline-end: .25rem;
  }

  .md\:scroll-pe-1\.5 {
    scroll-padding-inline-end: .375rem;
  }

  .md\:scroll-pe-10 {
    scroll-padding-inline-end: 2.5rem;
  }

  .md\:scroll-pe-11 {
    scroll-padding-inline-end: 2.75rem;
  }

  .md\:scroll-pe-12 {
    scroll-padding-inline-end: 3rem;
  }

  .md\:scroll-pe-14 {
    scroll-padding-inline-end: 3.5rem;
  }

  .md\:scroll-pe-16 {
    scroll-padding-inline-end: 4rem;
  }

  .md\:scroll-pe-2 {
    scroll-padding-inline-end: .5rem;
  }

  .md\:scroll-pe-2\.5 {
    scroll-padding-inline-end: .625rem;
  }

  .md\:scroll-pe-20 {
    scroll-padding-inline-end: 5rem;
  }

  .md\:scroll-pe-24 {
    scroll-padding-inline-end: 6rem;
  }

  .md\:scroll-pe-28 {
    scroll-padding-inline-end: 7rem;
  }

  .md\:scroll-pe-3 {
    scroll-padding-inline-end: .75rem;
  }

  .md\:scroll-pe-3\.5 {
    scroll-padding-inline-end: .875rem;
  }

  .md\:scroll-pe-32 {
    scroll-padding-inline-end: 8rem;
  }

  .md\:scroll-pe-36 {
    scroll-padding-inline-end: 9rem;
  }

  .md\:scroll-pe-4 {
    scroll-padding-inline-end: 1rem;
  }

  .md\:scroll-pe-40 {
    scroll-padding-inline-end: 10rem;
  }

  .md\:scroll-pe-44 {
    scroll-padding-inline-end: 11rem;
  }

  .md\:scroll-pe-48 {
    scroll-padding-inline-end: 12rem;
  }

  .md\:scroll-pe-5 {
    scroll-padding-inline-end: 1.25rem;
  }

  .md\:scroll-pe-52 {
    scroll-padding-inline-end: 13rem;
  }

  .md\:scroll-pe-56 {
    scroll-padding-inline-end: 14rem;
  }

  .md\:scroll-pe-6 {
    scroll-padding-inline-end: 1.5rem;
  }

  .md\:scroll-pe-60 {
    scroll-padding-inline-end: 15rem;
  }

  .md\:scroll-pe-64 {
    scroll-padding-inline-end: 16rem;
  }

  .md\:scroll-pe-7 {
    scroll-padding-inline-end: 1.75rem;
  }

  .md\:scroll-pe-72 {
    scroll-padding-inline-end: 18rem;
  }

  .md\:scroll-pe-8 {
    scroll-padding-inline-end: 2rem;
  }

  .md\:scroll-pe-80 {
    scroll-padding-inline-end: 20rem;
  }

  .md\:scroll-pe-9 {
    scroll-padding-inline-end: 2.25rem;
  }

  .md\:scroll-pe-96 {
    scroll-padding-inline-end: 24rem;
  }

  .md\:scroll-pe-px {
    scroll-padding-inline-end: 1px;
  }

  .md\:scroll-pl-0 {
    scroll-padding-left: 0;
  }

  .md\:scroll-pl-0\.5 {
    scroll-padding-left: .125rem;
  }

  .md\:scroll-pl-1 {
    scroll-padding-left: .25rem;
  }

  .md\:scroll-pl-1\.5 {
    scroll-padding-left: .375rem;
  }

  .md\:scroll-pl-10 {
    scroll-padding-left: 2.5rem;
  }

  .md\:scroll-pl-11 {
    scroll-padding-left: 2.75rem;
  }

  .md\:scroll-pl-12 {
    scroll-padding-left: 3rem;
  }

  .md\:scroll-pl-14 {
    scroll-padding-left: 3.5rem;
  }

  .md\:scroll-pl-16 {
    scroll-padding-left: 4rem;
  }

  .md\:scroll-pl-2 {
    scroll-padding-left: .5rem;
  }

  .md\:scroll-pl-2\.5 {
    scroll-padding-left: .625rem;
  }

  .md\:scroll-pl-20 {
    scroll-padding-left: 5rem;
  }

  .md\:scroll-pl-24 {
    scroll-padding-left: 6rem;
  }

  .md\:scroll-pl-28 {
    scroll-padding-left: 7rem;
  }

  .md\:scroll-pl-3 {
    scroll-padding-left: .75rem;
  }

  .md\:scroll-pl-3\.5 {
    scroll-padding-left: .875rem;
  }

  .md\:scroll-pl-32 {
    scroll-padding-left: 8rem;
  }

  .md\:scroll-pl-36 {
    scroll-padding-left: 9rem;
  }

  .md\:scroll-pl-4 {
    scroll-padding-left: 1rem;
  }

  .md\:scroll-pl-40 {
    scroll-padding-left: 10rem;
  }

  .md\:scroll-pl-44 {
    scroll-padding-left: 11rem;
  }

  .md\:scroll-pl-48 {
    scroll-padding-left: 12rem;
  }

  .md\:scroll-pl-5 {
    scroll-padding-left: 1.25rem;
  }

  .md\:scroll-pl-52 {
    scroll-padding-left: 13rem;
  }

  .md\:scroll-pl-56 {
    scroll-padding-left: 14rem;
  }

  .md\:scroll-pl-6 {
    scroll-padding-left: 1.5rem;
  }

  .md\:scroll-pl-60 {
    scroll-padding-left: 15rem;
  }

  .md\:scroll-pl-64 {
    scroll-padding-left: 16rem;
  }

  .md\:scroll-pl-7 {
    scroll-padding-left: 1.75rem;
  }

  .md\:scroll-pl-72 {
    scroll-padding-left: 18rem;
  }

  .md\:scroll-pl-8 {
    scroll-padding-left: 2rem;
  }

  .md\:scroll-pl-80 {
    scroll-padding-left: 20rem;
  }

  .md\:scroll-pl-9 {
    scroll-padding-left: 2.25rem;
  }

  .md\:scroll-pl-96 {
    scroll-padding-left: 24rem;
  }

  .md\:scroll-pl-px {
    scroll-padding-left: 1px;
  }

  .md\:scroll-pr-0 {
    scroll-padding-right: 0;
  }

  .md\:scroll-pr-0\.5 {
    scroll-padding-right: .125rem;
  }

  .md\:scroll-pr-1 {
    scroll-padding-right: .25rem;
  }

  .md\:scroll-pr-1\.5 {
    scroll-padding-right: .375rem;
  }

  .md\:scroll-pr-10 {
    scroll-padding-right: 2.5rem;
  }

  .md\:scroll-pr-11 {
    scroll-padding-right: 2.75rem;
  }

  .md\:scroll-pr-12 {
    scroll-padding-right: 3rem;
  }

  .md\:scroll-pr-14 {
    scroll-padding-right: 3.5rem;
  }

  .md\:scroll-pr-16 {
    scroll-padding-right: 4rem;
  }

  .md\:scroll-pr-2 {
    scroll-padding-right: .5rem;
  }

  .md\:scroll-pr-2\.5 {
    scroll-padding-right: .625rem;
  }

  .md\:scroll-pr-20 {
    scroll-padding-right: 5rem;
  }

  .md\:scroll-pr-24 {
    scroll-padding-right: 6rem;
  }

  .md\:scroll-pr-28 {
    scroll-padding-right: 7rem;
  }

  .md\:scroll-pr-3 {
    scroll-padding-right: .75rem;
  }

  .md\:scroll-pr-3\.5 {
    scroll-padding-right: .875rem;
  }

  .md\:scroll-pr-32 {
    scroll-padding-right: 8rem;
  }

  .md\:scroll-pr-36 {
    scroll-padding-right: 9rem;
  }

  .md\:scroll-pr-4 {
    scroll-padding-right: 1rem;
  }

  .md\:scroll-pr-40 {
    scroll-padding-right: 10rem;
  }

  .md\:scroll-pr-44 {
    scroll-padding-right: 11rem;
  }

  .md\:scroll-pr-48 {
    scroll-padding-right: 12rem;
  }

  .md\:scroll-pr-5 {
    scroll-padding-right: 1.25rem;
  }

  .md\:scroll-pr-52 {
    scroll-padding-right: 13rem;
  }

  .md\:scroll-pr-56 {
    scroll-padding-right: 14rem;
  }

  .md\:scroll-pr-6 {
    scroll-padding-right: 1.5rem;
  }

  .md\:scroll-pr-60 {
    scroll-padding-right: 15rem;
  }

  .md\:scroll-pr-64 {
    scroll-padding-right: 16rem;
  }

  .md\:scroll-pr-7 {
    scroll-padding-right: 1.75rem;
  }

  .md\:scroll-pr-72 {
    scroll-padding-right: 18rem;
  }

  .md\:scroll-pr-8 {
    scroll-padding-right: 2rem;
  }

  .md\:scroll-pr-80 {
    scroll-padding-right: 20rem;
  }

  .md\:scroll-pr-9 {
    scroll-padding-right: 2.25rem;
  }

  .md\:scroll-pr-96 {
    scroll-padding-right: 24rem;
  }

  .md\:scroll-pr-px {
    scroll-padding-right: 1px;
  }

  .md\:scroll-ps-0 {
    scroll-padding-inline-start: 0;
  }

  .md\:scroll-ps-0\.5 {
    scroll-padding-inline-start: .125rem;
  }

  .md\:scroll-ps-1 {
    scroll-padding-inline-start: .25rem;
  }

  .md\:scroll-ps-1\.5 {
    scroll-padding-inline-start: .375rem;
  }

  .md\:scroll-ps-10 {
    scroll-padding-inline-start: 2.5rem;
  }

  .md\:scroll-ps-11 {
    scroll-padding-inline-start: 2.75rem;
  }

  .md\:scroll-ps-12 {
    scroll-padding-inline-start: 3rem;
  }

  .md\:scroll-ps-14 {
    scroll-padding-inline-start: 3.5rem;
  }

  .md\:scroll-ps-16 {
    scroll-padding-inline-start: 4rem;
  }

  .md\:scroll-ps-2 {
    scroll-padding-inline-start: .5rem;
  }

  .md\:scroll-ps-2\.5 {
    scroll-padding-inline-start: .625rem;
  }

  .md\:scroll-ps-20 {
    scroll-padding-inline-start: 5rem;
  }

  .md\:scroll-ps-24 {
    scroll-padding-inline-start: 6rem;
  }

  .md\:scroll-ps-28 {
    scroll-padding-inline-start: 7rem;
  }

  .md\:scroll-ps-3 {
    scroll-padding-inline-start: .75rem;
  }

  .md\:scroll-ps-3\.5 {
    scroll-padding-inline-start: .875rem;
  }

  .md\:scroll-ps-32 {
    scroll-padding-inline-start: 8rem;
  }

  .md\:scroll-ps-36 {
    scroll-padding-inline-start: 9rem;
  }

  .md\:scroll-ps-4 {
    scroll-padding-inline-start: 1rem;
  }

  .md\:scroll-ps-40 {
    scroll-padding-inline-start: 10rem;
  }

  .md\:scroll-ps-44 {
    scroll-padding-inline-start: 11rem;
  }

  .md\:scroll-ps-48 {
    scroll-padding-inline-start: 12rem;
  }

  .md\:scroll-ps-5 {
    scroll-padding-inline-start: 1.25rem;
  }

  .md\:scroll-ps-52 {
    scroll-padding-inline-start: 13rem;
  }

  .md\:scroll-ps-56 {
    scroll-padding-inline-start: 14rem;
  }

  .md\:scroll-ps-6 {
    scroll-padding-inline-start: 1.5rem;
  }

  .md\:scroll-ps-60 {
    scroll-padding-inline-start: 15rem;
  }

  .md\:scroll-ps-64 {
    scroll-padding-inline-start: 16rem;
  }

  .md\:scroll-ps-7 {
    scroll-padding-inline-start: 1.75rem;
  }

  .md\:scroll-ps-72 {
    scroll-padding-inline-start: 18rem;
  }

  .md\:scroll-ps-8 {
    scroll-padding-inline-start: 2rem;
  }

  .md\:scroll-ps-80 {
    scroll-padding-inline-start: 20rem;
  }

  .md\:scroll-ps-9 {
    scroll-padding-inline-start: 2.25rem;
  }

  .md\:scroll-ps-96 {
    scroll-padding-inline-start: 24rem;
  }

  .md\:scroll-ps-px {
    scroll-padding-inline-start: 1px;
  }

  .md\:scroll-pt-0 {
    scroll-padding-top: 0;
  }

  .md\:scroll-pt-0\.5 {
    scroll-padding-top: .125rem;
  }

  .md\:scroll-pt-1 {
    scroll-padding-top: .25rem;
  }

  .md\:scroll-pt-1\.5 {
    scroll-padding-top: .375rem;
  }

  .md\:scroll-pt-10 {
    scroll-padding-top: 2.5rem;
  }

  .md\:scroll-pt-11 {
    scroll-padding-top: 2.75rem;
  }

  .md\:scroll-pt-12 {
    scroll-padding-top: 3rem;
  }

  .md\:scroll-pt-14 {
    scroll-padding-top: 3.5rem;
  }

  .md\:scroll-pt-16 {
    scroll-padding-top: 4rem;
  }

  .md\:scroll-pt-2 {
    scroll-padding-top: .5rem;
  }

  .md\:scroll-pt-2\.5 {
    scroll-padding-top: .625rem;
  }

  .md\:scroll-pt-20 {
    scroll-padding-top: 5rem;
  }

  .md\:scroll-pt-24 {
    scroll-padding-top: 6rem;
  }

  .md\:scroll-pt-28 {
    scroll-padding-top: 7rem;
  }

  .md\:scroll-pt-3 {
    scroll-padding-top: .75rem;
  }

  .md\:scroll-pt-3\.5 {
    scroll-padding-top: .875rem;
  }

  .md\:scroll-pt-32 {
    scroll-padding-top: 8rem;
  }

  .md\:scroll-pt-36 {
    scroll-padding-top: 9rem;
  }

  .md\:scroll-pt-4 {
    scroll-padding-top: 1rem;
  }

  .md\:scroll-pt-40 {
    scroll-padding-top: 10rem;
  }

  .md\:scroll-pt-44 {
    scroll-padding-top: 11rem;
  }

  .md\:scroll-pt-48 {
    scroll-padding-top: 12rem;
  }

  .md\:scroll-pt-5 {
    scroll-padding-top: 1.25rem;
  }

  .md\:scroll-pt-52 {
    scroll-padding-top: 13rem;
  }

  .md\:scroll-pt-56 {
    scroll-padding-top: 14rem;
  }

  .md\:scroll-pt-6 {
    scroll-padding-top: 1.5rem;
  }

  .md\:scroll-pt-60 {
    scroll-padding-top: 15rem;
  }

  .md\:scroll-pt-64 {
    scroll-padding-top: 16rem;
  }

  .md\:scroll-pt-7 {
    scroll-padding-top: 1.75rem;
  }

  .md\:scroll-pt-72 {
    scroll-padding-top: 18rem;
  }

  .md\:scroll-pt-8 {
    scroll-padding-top: 2rem;
  }

  .md\:scroll-pt-80 {
    scroll-padding-top: 20rem;
  }

  .md\:scroll-pt-9 {
    scroll-padding-top: 2.25rem;
  }

  .md\:scroll-pt-96 {
    scroll-padding-top: 24rem;
  }

  .md\:scroll-pt-px {
    scroll-padding-top: 1px;
  }

  .md\:list-inside {
    list-style-position: inside;
  }

  .md\:list-outside {
    list-style-position: outside;
  }

  .md\:list-decimal {
    list-style-type: decimal;
  }

  .md\:list-disc {
    list-style-type: disc;
  }

  .md\:list-none {
    list-style-type: none;
  }

  .md\:list-image-none {
    list-style-image: none;
  }

  .md\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }

  .md\:appearance-auto {
    -webkit-appearance: auto;
    appearance: auto;
  }

  .md\:columns-1 {
    columns: 1;
  }

  .md\:columns-10 {
    columns: 10;
  }

  .md\:columns-11 {
    columns: 11;
  }

  .md\:columns-12 {
    columns: 12;
  }

  .md\:columns-2 {
    columns: 2;
  }

  .md\:columns-2xl {
    columns: 42rem;
  }

  .md\:columns-2xs {
    columns: 18rem;
  }

  .md\:columns-3 {
    columns: 3;
  }

  .md\:columns-3xl {
    columns: 48rem;
  }

  .md\:columns-3xs {
    columns: 16rem;
  }

  .md\:columns-4 {
    columns: 4;
  }

  .md\:columns-4xl {
    columns: 56rem;
  }

  .md\:columns-5 {
    columns: 5;
  }

  .md\:columns-5xl {
    columns: 64rem;
  }

  .md\:columns-6 {
    columns: 6;
  }

  .md\:columns-6xl {
    columns: 72rem;
  }

  .md\:columns-7 {
    columns: 7;
  }

  .md\:columns-7xl {
    columns: 80rem;
  }

  .md\:columns-8 {
    columns: 8;
  }

  .md\:columns-9 {
    columns: 9;
  }

  .md\:columns-auto {
    columns: auto;
  }

  .md\:columns-lg {
    columns: 32rem;
  }

  .md\:columns-md {
    columns: 28rem;
  }

  .md\:columns-sm {
    columns: 24rem;
  }

  .md\:columns-xl {
    columns: 36rem;
  }

  .md\:columns-xs {
    columns: 20rem;
  }

  .md\:break-before-auto {
    break-before: auto;
  }

  .md\:break-before-avoid {
    break-before: avoid;
  }

  .md\:break-before-all {
    break-before: all;
  }

  .md\:break-before-avoid-page {
    break-before: avoid-page;
  }

  .md\:break-before-page {
    break-before: page;
  }

  .md\:break-before-left {
    break-before: left;
  }

  .md\:break-before-right {
    break-before: right;
  }

  .md\:break-before-column {
    break-before: column;
  }

  .md\:break-inside-auto {
    break-inside: auto;
  }

  .md\:break-inside-avoid {
    break-inside: avoid;
  }

  .md\:break-inside-avoid-page {
    break-inside: avoid-page;
  }

  .md\:break-inside-avoid-column {
    break-inside: avoid-column;
  }

  .md\:break-after-auto {
    break-after: auto;
  }

  .md\:break-after-avoid {
    break-after: avoid;
  }

  .md\:break-after-all {
    break-after: all;
  }

  .md\:break-after-avoid-page {
    break-after: avoid-page;
  }

  .md\:break-after-page {
    break-after: page;
  }

  .md\:break-after-left {
    break-after: left;
  }

  .md\:break-after-right {
    break-after: right;
  }

  .md\:break-after-column {
    break-after: column;
  }

  .md\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .md\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .md\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .md\:grid-flow-row {
    grid-auto-flow: row;
  }

  .md\:grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:grid-flow-dense {
    grid-auto-flow: dense;
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .md\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .md\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .md\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:grid-cols-none {
    grid-template-columns: none;
  }

  .md\:grid-cols-subgrid {
    grid-template-columns: subgrid;
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr));
  }

  .md\:grid-rows-11 {
    grid-template-rows: repeat(11, minmax(0, 1fr));
  }

  .md\:grid-rows-12 {
    grid-template-rows: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-rows-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .md\:grid-rows-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .md\:grid-rows-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }

  .md\:grid-rows-none {
    grid-template-rows: none;
  }

  .md\:grid-rows-subgrid {
    grid-template-rows: subgrid;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:place-content-center {
    place-content: center;
  }

  .md\:place-content-start {
    place-content: start;
  }

  .md\:place-content-end {
    place-content: end;
  }

  .md\:place-content-between {
    place-content: space-between;
  }

  .md\:place-content-around {
    place-content: space-around;
  }

  .md\:place-content-evenly {
    place-content: space-evenly;
  }

  .md\:place-content-baseline {
    place-content: baseline start;
  }

  .md\:place-content-stretch {
    place-content: stretch;
  }

  .md\:place-items-start {
    place-items: start;
  }

  .md\:place-items-end {
    place-items: end;
  }

  .md\:place-items-center {
    place-items: center;
  }

  .md\:place-items-baseline {
    place-items: baseline;
  }

  .md\:place-items-stretch {
    place-items: stretch stretch;
  }

  .md\:content-normal {
    align-content: normal;
  }

  .md\:content-center {
    align-content: center;
  }

  .md\:content-start {
    align-content: flex-start;
  }

  .md\:content-end {
    align-content: flex-end;
  }

  .md\:content-between {
    align-content: space-between;
  }

  .md\:content-around {
    align-content: space-around;
  }

  .md\:content-evenly {
    align-content: space-evenly;
  }

  .md\:content-baseline {
    align-content: baseline;
  }

  .md\:content-stretch {
    align-content: stretch;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:items-baseline {
    align-items: baseline;
  }

  .md\:items-stretch {
    align-items: stretch;
  }

  .md\:justify-normal {
    justify-content: normal;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-around {
    justify-content: space-around;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:justify-stretch {
    justify-content: stretch;
  }

  .md\:justify-items-start {
    justify-items: start;
  }

  .md\:justify-items-end {
    justify-items: end;
  }

  .md\:justify-items-center {
    justify-items: center;
  }

  .md\:justify-items-stretch {
    justify-items: stretch;
  }

  .md\:gap-0 {
    gap: 0;
  }

  .md\:gap-0\.5 {
    gap: .125rem;
  }

  .md\:gap-1 {
    gap: .25rem;
  }

  .md\:gap-1\.5 {
    gap: .375rem;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-11 {
    gap: 2.75rem;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:gap-14 {
    gap: 3.5rem;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-2\.5 {
    gap: .625rem;
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:gap-24 {
    gap: 6rem;
  }

  .md\:gap-28 {
    gap: 7rem;
  }

  .md\:gap-3 {
    gap: .75rem;
  }

  .md\:gap-3\.5 {
    gap: .875rem;
  }

  .md\:gap-32 {
    gap: 8rem;
  }

  .md\:gap-36 {
    gap: 9rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-40 {
    gap: 10rem;
  }

  .md\:gap-44 {
    gap: 11rem;
  }

  .md\:gap-48 {
    gap: 12rem;
  }

  .md\:gap-5 {
    gap: 1.25rem;
  }

  .md\:gap-52 {
    gap: 13rem;
  }

  .md\:gap-56 {
    gap: 14rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:gap-60 {
    gap: 15rem;
  }

  .md\:gap-64 {
    gap: 16rem;
  }

  .md\:gap-7 {
    gap: 1.75rem;
  }

  .md\:gap-72 {
    gap: 18rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-80 {
    gap: 20rem;
  }

  .md\:gap-9 {
    gap: 2.25rem;
  }

  .md\:gap-96 {
    gap: 24rem;
  }

  .md\:gap-px {
    gap: 1px;
  }

  .md\:gap-x-0 {
    column-gap: 0;
  }

  .md\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .md\:gap-x-1 {
    column-gap: .25rem;
  }

  .md\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .md\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .md\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .md\:gap-x-12 {
    column-gap: 3rem;
  }

  .md\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .md\:gap-x-16 {
    column-gap: 4rem;
  }

  .md\:gap-x-2 {
    column-gap: .5rem;
  }

  .md\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .md\:gap-x-20 {
    column-gap: 5rem;
  }

  .md\:gap-x-24 {
    column-gap: 6rem;
  }

  .md\:gap-x-28 {
    column-gap: 7rem;
  }

  .md\:gap-x-3 {
    column-gap: .75rem;
  }

  .md\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .md\:gap-x-32 {
    column-gap: 8rem;
  }

  .md\:gap-x-36 {
    column-gap: 9rem;
  }

  .md\:gap-x-4 {
    column-gap: 1rem;
  }

  .md\:gap-x-40 {
    column-gap: 10rem;
  }

  .md\:gap-x-44 {
    column-gap: 11rem;
  }

  .md\:gap-x-48 {
    column-gap: 12rem;
  }

  .md\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .md\:gap-x-52 {
    column-gap: 13rem;
  }

  .md\:gap-x-56 {
    column-gap: 14rem;
  }

  .md\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .md\:gap-x-60 {
    column-gap: 15rem;
  }

  .md\:gap-x-64 {
    column-gap: 16rem;
  }

  .md\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .md\:gap-x-72 {
    column-gap: 18rem;
  }

  .md\:gap-x-8 {
    column-gap: 2rem;
  }

  .md\:gap-x-80 {
    column-gap: 20rem;
  }

  .md\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .md\:gap-x-96 {
    column-gap: 24rem;
  }

  .md\:gap-x-px {
    column-gap: 1px;
  }

  .md\:gap-y-0 {
    row-gap: 0;
  }

  .md\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .md\:gap-y-1 {
    row-gap: .25rem;
  }

  .md\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .md\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .md\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .md\:gap-y-12 {
    row-gap: 3rem;
  }

  .md\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .md\:gap-y-16 {
    row-gap: 4rem;
  }

  .md\:gap-y-2 {
    row-gap: .5rem;
  }

  .md\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .md\:gap-y-20 {
    row-gap: 5rem;
  }

  .md\:gap-y-24 {
    row-gap: 6rem;
  }

  .md\:gap-y-28 {
    row-gap: 7rem;
  }

  .md\:gap-y-3 {
    row-gap: .75rem;
  }

  .md\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .md\:gap-y-32 {
    row-gap: 8rem;
  }

  .md\:gap-y-36 {
    row-gap: 9rem;
  }

  .md\:gap-y-4 {
    row-gap: 1rem;
  }

  .md\:gap-y-40 {
    row-gap: 10rem;
  }

  .md\:gap-y-44 {
    row-gap: 11rem;
  }

  .md\:gap-y-48 {
    row-gap: 12rem;
  }

  .md\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .md\:gap-y-52 {
    row-gap: 13rem;
  }

  .md\:gap-y-56 {
    row-gap: 14rem;
  }

  .md\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .md\:gap-y-60 {
    row-gap: 15rem;
  }

  .md\:gap-y-64 {
    row-gap: 16rem;
  }

  .md\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .md\:gap-y-72 {
    row-gap: 18rem;
  }

  .md\:gap-y-8 {
    row-gap: 2rem;
  }

  .md\:gap-y-80 {
    row-gap: 20rem;
  }

  .md\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .md\:gap-y-96 {
    row-gap: 24rem;
  }

  .md\:gap-y-px {
    row-gap: 1px;
  }

  .md\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0px * var(--tw-space-x-reverse));
    margin-left: calc(-0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .md\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .md\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .md\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .md\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .md\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .md\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .md\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .md\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .md\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .md\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .md\:divide-black > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-black);
  }

  .md\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .md\:divide-danger > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-300);
  }

  .md\:divide-danger-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-100);
  }

  .md\:divide-danger-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-200);
  }

  .md\:divide-danger-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-300);
  }

  .md\:divide-danger-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-400);
  }

  .md\:divide-divider > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-divider);
  }

  .md\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-gray);
  }

  .md\:divide-info > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-300);
  }

  .md\:divide-info-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-100);
  }

  .md\:divide-info-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-200);
  }

  .md\:divide-info-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-300);
  }

  .md\:divide-info-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-400);
  }

  .md\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-300);
  }

  .md\:divide-primary-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-100);
  }

  .md\:divide-primary-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-200);
  }

  .md\:divide-primary-250 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-250);
  }

  .md\:divide-primary-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-300);
  }

  .md\:divide-primary-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-400);
  }

  .md\:divide-primary-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-500);
  }

  .md\:divide-primary-600 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-600);
  }

  .md\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-300);
  }

  .md\:divide-success-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-100);
  }

  .md\:divide-success-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-200);
  }

  .md\:divide-success-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-300);
  }

  .md\:divide-success-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-400);
  }

  .md\:divide-transparent > :not([hidden]) ~ :not([hidden]), .md\:divide-transparent\/0 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .md\:divide-transparent\/10 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000001a;
  }

  .md\:divide-transparent\/100 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000;
  }

  .md\:divide-transparent\/15 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000026;
  }

  .md\:divide-transparent\/20 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0003;
  }

  .md\:divide-transparent\/25 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000040;
  }

  .md\:divide-transparent\/30 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000004d;
  }

  .md\:divide-transparent\/35 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000059;
  }

  .md\:divide-transparent\/40 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0006;
  }

  .md\:divide-transparent\/45 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000073;
  }

  .md\:divide-transparent\/5 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000000d;
  }

  .md\:divide-transparent\/50 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000080;
  }

  .md\:divide-transparent\/55 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000008c;
  }

  .md\:divide-transparent\/60 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0009;
  }

  .md\:divide-transparent\/65 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000a6;
  }

  .md\:divide-transparent\/70 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000b3;
  }

  .md\:divide-transparent\/75 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000bf;
  }

  .md\:divide-transparent\/80 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000c;
  }

  .md\:divide-transparent\/85 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000d9;
  }

  .md\:divide-transparent\/90 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000e6;
  }

  .md\:divide-transparent\/95 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000f2;
  }

  .md\:divide-warning > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-300);
  }

  .md\:divide-warning-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-100);
  }

  .md\:divide-warning-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-200);
  }

  .md\:divide-warning-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-300);
  }

  .md\:divide-warning-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-400);
  }

  .md\:divide-white > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-white);
  }

  .md\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .md\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .md\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .md\:divide-opacity-15 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .15;
  }

  .md\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .md\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .md\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .md\:divide-opacity-35 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .35;
  }

  .md\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .md\:divide-opacity-45 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .45;
  }

  .md\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .md\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .md\:divide-opacity-55 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .55;
  }

  .md\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .md\:divide-opacity-65 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .65;
  }

  .md\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .md\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .md\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .md\:divide-opacity-85 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .85;
  }

  .md\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .md\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .md\:place-self-auto {
    place-self: auto;
  }

  .md\:place-self-start {
    place-self: start;
  }

  .md\:place-self-end {
    place-self: end;
  }

  .md\:place-self-center {
    place-self: center;
  }

  .md\:place-self-stretch {
    place-self: stretch stretch;
  }

  .md\:self-auto {
    align-self: auto;
  }

  .md\:self-start {
    align-self: flex-start;
  }

  .md\:self-end {
    align-self: flex-end;
  }

  .md\:self-center {
    align-self: center;
  }

  .md\:self-stretch {
    align-self: stretch;
  }

  .md\:self-baseline {
    align-self: baseline;
  }

  .md\:justify-self-auto {
    justify-self: auto;
  }

  .md\:justify-self-start {
    justify-self: start;
  }

  .md\:justify-self-end {
    justify-self: end;
  }

  .md\:justify-self-center {
    justify-self: center;
  }

  .md\:justify-self-stretch {
    justify-self: stretch;
  }

  .md\:overflow-auto {
    overflow: auto;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:overflow-clip {
    overflow: clip;
  }

  .md\:overflow-visible {
    overflow: visible;
  }

  .md\:overflow-scroll {
    overflow: scroll;
  }

  .md\:overflow-x-auto {
    overflow-x: auto;
  }

  .md\:overflow-y-auto {
    overflow-y: auto;
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .md\:overflow-x-clip {
    overflow-x: clip;
  }

  .md\:overflow-y-clip {
    overflow-y: clip;
  }

  .md\:overflow-x-visible {
    overflow-x: visible;
  }

  .md\:overflow-y-visible {
    overflow-y: visible;
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .md\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .md\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .md\:overscroll-none {
    overscroll-behavior: none;
  }

  .md\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .md\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .md\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .md\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .md\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .md\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .md\:scroll-auto {
    scroll-behavior: auto;
  }

  .md\:scroll-smooth {
    scroll-behavior: smooth;
  }

  .md\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .md\:overflow-ellipsis, .md\:text-ellipsis {
    text-overflow: ellipsis;
  }

  .md\:text-clip {
    text-overflow: clip;
  }

  .md\:hyphens-none {
    -webkit-hyphens: none;
    hyphens: none;
  }

  .md\:hyphens-manual {
    -webkit-hyphens: manual;
    hyphens: manual;
  }

  .md\:hyphens-auto {
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .md\:whitespace-normal {
    white-space: normal;
  }

  .md\:whitespace-nowrap {
    white-space: nowrap;
  }

  .md\:whitespace-pre {
    white-space: pre;
  }

  .md\:whitespace-pre-line {
    white-space: pre-line;
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .md\:whitespace-break-spaces {
    white-space: break-spaces;
  }

  .md\:text-wrap {
    text-wrap: wrap;
  }

  .md\:text-nowrap {
    text-wrap: nowrap;
  }

  .md\:text-balance {
    text-wrap: balance;
  }

  .md\:text-pretty {
    text-wrap: pretty;
  }

  .md\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .md\:break-words {
    overflow-wrap: break-word;
  }

  .md\:break-all {
    word-break: break-all;
  }

  .md\:break-keep {
    word-break: keep-all;
  }

  .md\:rounded {
    border-radius: .25rem;
  }

  .md\:rounded-2xl {
    border-radius: 1rem;
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .md\:rounded-full {
    border-radius: 9999px;
  }

  .md\:rounded-lg {
    border-radius: .5rem;
  }

  .md\:rounded-md {
    border-radius: .375rem;
  }

  .md\:rounded-none {
    border-radius: 0;
  }

  .md\:rounded-sm {
    border-radius: .125rem;
  }

  .md\:rounded-xl {
    border-radius: .75rem;
  }

  .md\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .md\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .md\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .md\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .md\:rounded-e {
    border-start-end-radius: .25rem;
    border-end-end-radius: .25rem;
  }

  .md\:rounded-e-2xl {
    border-start-end-radius: 1rem;
    border-end-end-radius: 1rem;
  }

  .md\:rounded-e-3xl {
    border-start-end-radius: 1.5rem;
    border-end-end-radius: 1.5rem;
  }

  .md\:rounded-e-full {
    border-start-end-radius: 9999px;
    border-end-end-radius: 9999px;
  }

  .md\:rounded-e-lg {
    border-start-end-radius: .5rem;
    border-end-end-radius: .5rem;
  }

  .md\:rounded-e-md {
    border-start-end-radius: .375rem;
    border-end-end-radius: .375rem;
  }

  .md\:rounded-e-none {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }

  .md\:rounded-e-sm {
    border-start-end-radius: .125rem;
    border-end-end-radius: .125rem;
  }

  .md\:rounded-e-xl {
    border-start-end-radius: .75rem;
    border-end-end-radius: .75rem;
  }

  .md\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .md\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .md\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .md\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .md\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .md\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .md\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .md\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .md\:rounded-s {
    border-start-start-radius: .25rem;
    border-end-start-radius: .25rem;
  }

  .md\:rounded-s-2xl {
    border-start-start-radius: 1rem;
    border-end-start-radius: 1rem;
  }

  .md\:rounded-s-3xl {
    border-start-start-radius: 1.5rem;
    border-end-start-radius: 1.5rem;
  }

  .md\:rounded-s-full {
    border-start-start-radius: 9999px;
    border-end-start-radius: 9999px;
  }

  .md\:rounded-s-lg {
    border-start-start-radius: .5rem;
    border-end-start-radius: .5rem;
  }

  .md\:rounded-s-md {
    border-start-start-radius: .375rem;
    border-end-start-radius: .375rem;
  }

  .md\:rounded-s-none {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }

  .md\:rounded-s-sm {
    border-start-start-radius: .125rem;
    border-end-start-radius: .125rem;
  }

  .md\:rounded-s-xl {
    border-start-start-radius: .75rem;
    border-end-start-radius: .75rem;
  }

  .md\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .md\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .md\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .md\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .md\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .md\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .md\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .md\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .md\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .md\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .md\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .md\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .md\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .md\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .md\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .md\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .md\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .md\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .md\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .md\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .md\:rounded-ee {
    border-end-end-radius: .25rem;
  }

  .md\:rounded-ee-2xl {
    border-end-end-radius: 1rem;
  }

  .md\:rounded-ee-3xl {
    border-end-end-radius: 1.5rem;
  }

  .md\:rounded-ee-full {
    border-end-end-radius: 9999px;
  }

  .md\:rounded-ee-lg {
    border-end-end-radius: .5rem;
  }

  .md\:rounded-ee-md {
    border-end-end-radius: .375rem;
  }

  .md\:rounded-ee-none {
    border-end-end-radius: 0;
  }

  .md\:rounded-ee-sm {
    border-end-end-radius: .125rem;
  }

  .md\:rounded-ee-xl {
    border-end-end-radius: .75rem;
  }

  .md\:rounded-es {
    border-end-start-radius: .25rem;
  }

  .md\:rounded-es-2xl {
    border-end-start-radius: 1rem;
  }

  .md\:rounded-es-3xl {
    border-end-start-radius: 1.5rem;
  }

  .md\:rounded-es-full {
    border-end-start-radius: 9999px;
  }

  .md\:rounded-es-lg {
    border-end-start-radius: .5rem;
  }

  .md\:rounded-es-md {
    border-end-start-radius: .375rem;
  }

  .md\:rounded-es-none {
    border-end-start-radius: 0;
  }

  .md\:rounded-es-sm {
    border-end-start-radius: .125rem;
  }

  .md\:rounded-es-xl {
    border-end-start-radius: .75rem;
  }

  .md\:rounded-se {
    border-start-end-radius: .25rem;
  }

  .md\:rounded-se-2xl {
    border-start-end-radius: 1rem;
  }

  .md\:rounded-se-3xl {
    border-start-end-radius: 1.5rem;
  }

  .md\:rounded-se-full {
    border-start-end-radius: 9999px;
  }

  .md\:rounded-se-lg {
    border-start-end-radius: .5rem;
  }

  .md\:rounded-se-md {
    border-start-end-radius: .375rem;
  }

  .md\:rounded-se-none {
    border-start-end-radius: 0;
  }

  .md\:rounded-se-sm {
    border-start-end-radius: .125rem;
  }

  .md\:rounded-se-xl {
    border-start-end-radius: .75rem;
  }

  .md\:rounded-ss {
    border-start-start-radius: .25rem;
  }

  .md\:rounded-ss-2xl {
    border-start-start-radius: 1rem;
  }

  .md\:rounded-ss-3xl {
    border-start-start-radius: 1.5rem;
  }

  .md\:rounded-ss-full {
    border-start-start-radius: 9999px;
  }

  .md\:rounded-ss-lg {
    border-start-start-radius: .5rem;
  }

  .md\:rounded-ss-md {
    border-start-start-radius: .375rem;
  }

  .md\:rounded-ss-none {
    border-start-start-radius: 0;
  }

  .md\:rounded-ss-sm {
    border-start-start-radius: .125rem;
  }

  .md\:rounded-ss-xl {
    border-start-start-radius: .75rem;
  }

  .md\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .md\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .md\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .md\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .md\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .md\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .md\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .md\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .md\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .md\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .md\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .md\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .md\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .md\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .md\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .md\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .md\:border {
    border-width: 1px;
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:border-2 {
    border-width: 2px;
  }

  .md\:border-4 {
    border-width: 4px;
  }

  .md\:border-8 {
    border-width: 8px;
  }

  .md\:border-x {
    border-left-width: 1px;
    border-right-width: 1px;
  }

  .md\:border-x-0 {
    border-left-width: 0;
    border-right-width: 0;
  }

  .md\:border-x-2 {
    border-left-width: 2px;
    border-right-width: 2px;
  }

  .md\:border-x-4 {
    border-left-width: 4px;
    border-right-width: 4px;
  }

  .md\:border-x-8 {
    border-left-width: 8px;
    border-right-width: 8px;
  }

  .md\:border-y {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .md\:border-y-0 {
    border-top-width: 0;
    border-bottom-width: 0;
  }

  .md\:border-y-2 {
    border-top-width: 2px;
    border-bottom-width: 2px;
  }

  .md\:border-y-4 {
    border-top-width: 4px;
    border-bottom-width: 4px;
  }

  .md\:border-y-8 {
    border-top-width: 8px;
    border-bottom-width: 8px;
  }

  .md\:border-b {
    border-bottom-width: 1px;
  }

  .md\:border-b-0 {
    border-bottom-width: 0;
  }

  .md\:border-b-2 {
    border-bottom-width: 2px;
  }

  .md\:border-b-4 {
    border-bottom-width: 4px;
  }

  .md\:border-b-8 {
    border-bottom-width: 8px;
  }

  .md\:border-e {
    border-inline-end-width: 1px;
  }

  .md\:border-e-0 {
    border-inline-end-width: 0;
  }

  .md\:border-e-2 {
    border-inline-end-width: 2px;
  }

  .md\:border-e-4 {
    border-inline-end-width: 4px;
  }

  .md\:border-e-8 {
    border-inline-end-width: 8px;
  }

  .md\:border-l {
    border-left-width: 1px;
  }

  .md\:border-l-0 {
    border-left-width: 0;
  }

  .md\:border-l-2 {
    border-left-width: 2px;
  }

  .md\:border-l-4 {
    border-left-width: 4px;
  }

  .md\:border-l-8 {
    border-left-width: 8px;
  }

  .md\:border-r {
    border-right-width: 1px;
  }

  .md\:border-r-0 {
    border-right-width: 0;
  }

  .md\:border-r-2 {
    border-right-width: 2px;
  }

  .md\:border-r-4 {
    border-right-width: 4px;
  }

  .md\:border-r-8 {
    border-right-width: 8px;
  }

  .md\:border-s {
    border-inline-start-width: 1px;
  }

  .md\:border-s-0 {
    border-inline-start-width: 0;
  }

  .md\:border-s-2 {
    border-inline-start-width: 2px;
  }

  .md\:border-s-4 {
    border-inline-start-width: 4px;
  }

  .md\:border-s-8 {
    border-inline-start-width: 8px;
  }

  .md\:border-t {
    border-top-width: 1px;
  }

  .md\:border-t-0 {
    border-top-width: 0;
  }

  .md\:border-t-2 {
    border-top-width: 2px;
  }

  .md\:border-t-4 {
    border-top-width: 4px;
  }

  .md\:border-t-8 {
    border-top-width: 8px;
  }

  .md\:border-solid {
    border-style: solid;
  }

  .md\:border-dashed {
    border-style: dashed;
  }

  .md\:border-dotted {
    border-style: dotted;
  }

  .md\:border-double {
    border-style: double;
  }

  .md\:border-hidden {
    border-style: hidden;
  }

  .md\:border-none {
    border-style: none;
  }

  .md\:border-black {
    border-color: var(--color-black);
  }

  .md\:border-current {
    border-color: currentColor;
  }

  .md\:border-danger {
    border-color: var(--color-danger-300);
  }

  .md\:border-danger-100 {
    border-color: var(--color-danger-100);
  }

  .md\:border-danger-200 {
    border-color: var(--color-danger-200);
  }

  .md\:border-danger-300 {
    border-color: var(--color-danger-300);
  }

  .md\:border-danger-400 {
    border-color: var(--color-danger-400);
  }

  .md\:border-divider {
    border-color: var(--color-divider);
  }

  .md\:border-gray {
    border-color: var(--color-gray);
  }

  .md\:border-info {
    border-color: var(--color-info-300);
  }

  .md\:border-info-100 {
    border-color: var(--color-info-100);
  }

  .md\:border-info-200 {
    border-color: var(--color-info-200);
  }

  .md\:border-info-300 {
    border-color: var(--color-info-300);
  }

  .md\:border-info-400 {
    border-color: var(--color-info-400);
  }

  .md\:border-primary {
    border-color: var(--color-primary-300);
  }

  .md\:border-primary-100 {
    border-color: var(--color-primary-100);
  }

  .md\:border-primary-200 {
    border-color: var(--color-primary-200);
  }

  .md\:border-primary-250 {
    border-color: var(--color-primary-250);
  }

  .md\:border-primary-300 {
    border-color: var(--color-primary-300);
  }

  .md\:border-primary-400 {
    border-color: var(--color-primary-400);
  }

  .md\:border-primary-500 {
    border-color: var(--color-primary-500);
  }

  .md\:border-primary-600 {
    border-color: var(--color-primary-600);
  }

  .md\:border-success {
    border-color: var(--color-success-300);
  }

  .md\:border-success-100 {
    border-color: var(--color-success-100);
  }

  .md\:border-success-200 {
    border-color: var(--color-success-200);
  }

  .md\:border-success-300 {
    border-color: var(--color-success-300);
  }

  .md\:border-success-400 {
    border-color: var(--color-success-400);
  }

  .md\:border-transparent, .md\:border-transparent\/0 {
    border-color: #0000;
  }

  .md\:border-transparent\/10 {
    border-color: #0000001a;
  }

  .md\:border-transparent\/100 {
    border-color: #000;
  }

  .md\:border-transparent\/15 {
    border-color: #00000026;
  }

  .md\:border-transparent\/20 {
    border-color: #0003;
  }

  .md\:border-transparent\/25 {
    border-color: #00000040;
  }

  .md\:border-transparent\/30 {
    border-color: #0000004d;
  }

  .md\:border-transparent\/35 {
    border-color: #00000059;
  }

  .md\:border-transparent\/40 {
    border-color: #0006;
  }

  .md\:border-transparent\/45 {
    border-color: #00000073;
  }

  .md\:border-transparent\/5 {
    border-color: #0000000d;
  }

  .md\:border-transparent\/50 {
    border-color: #00000080;
  }

  .md\:border-transparent\/55 {
    border-color: #0000008c;
  }

  .md\:border-transparent\/60 {
    border-color: #0009;
  }

  .md\:border-transparent\/65 {
    border-color: #000000a6;
  }

  .md\:border-transparent\/70 {
    border-color: #000000b3;
  }

  .md\:border-transparent\/75 {
    border-color: #000000bf;
  }

  .md\:border-transparent\/80 {
    border-color: #000c;
  }

  .md\:border-transparent\/85 {
    border-color: #000000d9;
  }

  .md\:border-transparent\/90 {
    border-color: #000000e6;
  }

  .md\:border-transparent\/95 {
    border-color: #000000f2;
  }

  .md\:border-warning {
    border-color: var(--color-warning-300);
  }

  .md\:border-warning-100 {
    border-color: var(--color-warning-100);
  }

  .md\:border-warning-200 {
    border-color: var(--color-warning-200);
  }

  .md\:border-warning-300 {
    border-color: var(--color-warning-300);
  }

  .md\:border-warning-400 {
    border-color: var(--color-warning-400);
  }

  .md\:border-white {
    border-color: var(--color-white);
  }

  .md\:border-x-black {
    border-left-color: var(--color-black);
    border-right-color: var(--color-black);
  }

  .md\:border-x-current {
    border-left-color: currentColor;
    border-right-color: currentColor;
  }

  .md\:border-x-danger {
    border-left-color: var(--color-danger-300);
    border-right-color: var(--color-danger-300);
  }

  .md\:border-x-danger-100 {
    border-left-color: var(--color-danger-100);
    border-right-color: var(--color-danger-100);
  }

  .md\:border-x-danger-200 {
    border-left-color: var(--color-danger-200);
    border-right-color: var(--color-danger-200);
  }

  .md\:border-x-danger-300 {
    border-left-color: var(--color-danger-300);
    border-right-color: var(--color-danger-300);
  }

  .md\:border-x-danger-400 {
    border-left-color: var(--color-danger-400);
    border-right-color: var(--color-danger-400);
  }

  .md\:border-x-divider {
    border-left-color: var(--color-divider);
    border-right-color: var(--color-divider);
  }

  .md\:border-x-gray {
    border-left-color: var(--color-gray);
    border-right-color: var(--color-gray);
  }

  .md\:border-x-info {
    border-left-color: var(--color-info-300);
    border-right-color: var(--color-info-300);
  }

  .md\:border-x-info-100 {
    border-left-color: var(--color-info-100);
    border-right-color: var(--color-info-100);
  }

  .md\:border-x-info-200 {
    border-left-color: var(--color-info-200);
    border-right-color: var(--color-info-200);
  }

  .md\:border-x-info-300 {
    border-left-color: var(--color-info-300);
    border-right-color: var(--color-info-300);
  }

  .md\:border-x-info-400 {
    border-left-color: var(--color-info-400);
    border-right-color: var(--color-info-400);
  }

  .md\:border-x-primary {
    border-left-color: var(--color-primary-300);
    border-right-color: var(--color-primary-300);
  }

  .md\:border-x-primary-100 {
    border-left-color: var(--color-primary-100);
    border-right-color: var(--color-primary-100);
  }

  .md\:border-x-primary-200 {
    border-left-color: var(--color-primary-200);
    border-right-color: var(--color-primary-200);
  }

  .md\:border-x-primary-250 {
    border-left-color: var(--color-primary-250);
    border-right-color: var(--color-primary-250);
  }

  .md\:border-x-primary-300 {
    border-left-color: var(--color-primary-300);
    border-right-color: var(--color-primary-300);
  }

  .md\:border-x-primary-400 {
    border-left-color: var(--color-primary-400);
    border-right-color: var(--color-primary-400);
  }

  .md\:border-x-primary-500 {
    border-left-color: var(--color-primary-500);
    border-right-color: var(--color-primary-500);
  }

  .md\:border-x-primary-600 {
    border-left-color: var(--color-primary-600);
    border-right-color: var(--color-primary-600);
  }

  .md\:border-x-success {
    border-left-color: var(--color-success-300);
    border-right-color: var(--color-success-300);
  }

  .md\:border-x-success-100 {
    border-left-color: var(--color-success-100);
    border-right-color: var(--color-success-100);
  }

  .md\:border-x-success-200 {
    border-left-color: var(--color-success-200);
    border-right-color: var(--color-success-200);
  }

  .md\:border-x-success-300 {
    border-left-color: var(--color-success-300);
    border-right-color: var(--color-success-300);
  }

  .md\:border-x-success-400 {
    border-left-color: var(--color-success-400);
    border-right-color: var(--color-success-400);
  }

  .md\:border-x-transparent, .md\:border-x-transparent\/0 {
    border-left-color: #0000;
    border-right-color: #0000;
  }

  .md\:border-x-transparent\/10 {
    border-left-color: #0000001a;
    border-right-color: #0000001a;
  }

  .md\:border-x-transparent\/100 {
    border-left-color: #000;
    border-right-color: #000;
  }

  .md\:border-x-transparent\/15 {
    border-left-color: #00000026;
    border-right-color: #00000026;
  }

  .md\:border-x-transparent\/20 {
    border-left-color: #0003;
    border-right-color: #0003;
  }

  .md\:border-x-transparent\/25 {
    border-left-color: #00000040;
    border-right-color: #00000040;
  }

  .md\:border-x-transparent\/30 {
    border-left-color: #0000004d;
    border-right-color: #0000004d;
  }

  .md\:border-x-transparent\/35 {
    border-left-color: #00000059;
    border-right-color: #00000059;
  }

  .md\:border-x-transparent\/40 {
    border-left-color: #0006;
    border-right-color: #0006;
  }

  .md\:border-x-transparent\/45 {
    border-left-color: #00000073;
    border-right-color: #00000073;
  }

  .md\:border-x-transparent\/5 {
    border-left-color: #0000000d;
    border-right-color: #0000000d;
  }

  .md\:border-x-transparent\/50 {
    border-left-color: #00000080;
    border-right-color: #00000080;
  }

  .md\:border-x-transparent\/55 {
    border-left-color: #0000008c;
    border-right-color: #0000008c;
  }

  .md\:border-x-transparent\/60 {
    border-left-color: #0009;
    border-right-color: #0009;
  }

  .md\:border-x-transparent\/65 {
    border-left-color: #000000a6;
    border-right-color: #000000a6;
  }

  .md\:border-x-transparent\/70 {
    border-left-color: #000000b3;
    border-right-color: #000000b3;
  }

  .md\:border-x-transparent\/75 {
    border-left-color: #000000bf;
    border-right-color: #000000bf;
  }

  .md\:border-x-transparent\/80 {
    border-left-color: #000c;
    border-right-color: #000c;
  }

  .md\:border-x-transparent\/85 {
    border-left-color: #000000d9;
    border-right-color: #000000d9;
  }

  .md\:border-x-transparent\/90 {
    border-left-color: #000000e6;
    border-right-color: #000000e6;
  }

  .md\:border-x-transparent\/95 {
    border-left-color: #000000f2;
    border-right-color: #000000f2;
  }

  .md\:border-x-warning {
    border-left-color: var(--color-warning-300);
    border-right-color: var(--color-warning-300);
  }

  .md\:border-x-warning-100 {
    border-left-color: var(--color-warning-100);
    border-right-color: var(--color-warning-100);
  }

  .md\:border-x-warning-200 {
    border-left-color: var(--color-warning-200);
    border-right-color: var(--color-warning-200);
  }

  .md\:border-x-warning-300 {
    border-left-color: var(--color-warning-300);
    border-right-color: var(--color-warning-300);
  }

  .md\:border-x-warning-400 {
    border-left-color: var(--color-warning-400);
    border-right-color: var(--color-warning-400);
  }

  .md\:border-x-white {
    border-left-color: var(--color-white);
    border-right-color: var(--color-white);
  }

  .md\:border-y-black {
    border-top-color: var(--color-black);
    border-bottom-color: var(--color-black);
  }

  .md\:border-y-current {
    border-top-color: currentColor;
    border-bottom-color: currentColor;
  }

  .md\:border-y-danger {
    border-top-color: var(--color-danger-300);
    border-bottom-color: var(--color-danger-300);
  }

  .md\:border-y-danger-100 {
    border-top-color: var(--color-danger-100);
    border-bottom-color: var(--color-danger-100);
  }

  .md\:border-y-danger-200 {
    border-top-color: var(--color-danger-200);
    border-bottom-color: var(--color-danger-200);
  }

  .md\:border-y-danger-300 {
    border-top-color: var(--color-danger-300);
    border-bottom-color: var(--color-danger-300);
  }

  .md\:border-y-danger-400 {
    border-top-color: var(--color-danger-400);
    border-bottom-color: var(--color-danger-400);
  }

  .md\:border-y-divider {
    border-top-color: var(--color-divider);
    border-bottom-color: var(--color-divider);
  }

  .md\:border-y-gray {
    border-top-color: var(--color-gray);
    border-bottom-color: var(--color-gray);
  }

  .md\:border-y-info {
    border-top-color: var(--color-info-300);
    border-bottom-color: var(--color-info-300);
  }

  .md\:border-y-info-100 {
    border-top-color: var(--color-info-100);
    border-bottom-color: var(--color-info-100);
  }

  .md\:border-y-info-200 {
    border-top-color: var(--color-info-200);
    border-bottom-color: var(--color-info-200);
  }

  .md\:border-y-info-300 {
    border-top-color: var(--color-info-300);
    border-bottom-color: var(--color-info-300);
  }

  .md\:border-y-info-400 {
    border-top-color: var(--color-info-400);
    border-bottom-color: var(--color-info-400);
  }

  .md\:border-y-primary {
    border-top-color: var(--color-primary-300);
    border-bottom-color: var(--color-primary-300);
  }

  .md\:border-y-primary-100 {
    border-top-color: var(--color-primary-100);
    border-bottom-color: var(--color-primary-100);
  }

  .md\:border-y-primary-200 {
    border-top-color: var(--color-primary-200);
    border-bottom-color: var(--color-primary-200);
  }

  .md\:border-y-primary-250 {
    border-top-color: var(--color-primary-250);
    border-bottom-color: var(--color-primary-250);
  }

  .md\:border-y-primary-300 {
    border-top-color: var(--color-primary-300);
    border-bottom-color: var(--color-primary-300);
  }

  .md\:border-y-primary-400 {
    border-top-color: var(--color-primary-400);
    border-bottom-color: var(--color-primary-400);
  }

  .md\:border-y-primary-500 {
    border-top-color: var(--color-primary-500);
    border-bottom-color: var(--color-primary-500);
  }

  .md\:border-y-primary-600 {
    border-top-color: var(--color-primary-600);
    border-bottom-color: var(--color-primary-600);
  }

  .md\:border-y-success {
    border-top-color: var(--color-success-300);
    border-bottom-color: var(--color-success-300);
  }

  .md\:border-y-success-100 {
    border-top-color: var(--color-success-100);
    border-bottom-color: var(--color-success-100);
  }

  .md\:border-y-success-200 {
    border-top-color: var(--color-success-200);
    border-bottom-color: var(--color-success-200);
  }

  .md\:border-y-success-300 {
    border-top-color: var(--color-success-300);
    border-bottom-color: var(--color-success-300);
  }

  .md\:border-y-success-400 {
    border-top-color: var(--color-success-400);
    border-bottom-color: var(--color-success-400);
  }

  .md\:border-y-transparent, .md\:border-y-transparent\/0 {
    border-top-color: #0000;
    border-bottom-color: #0000;
  }

  .md\:border-y-transparent\/10 {
    border-top-color: #0000001a;
    border-bottom-color: #0000001a;
  }

  .md\:border-y-transparent\/100 {
    border-top-color: #000;
    border-bottom-color: #000;
  }

  .md\:border-y-transparent\/15 {
    border-top-color: #00000026;
    border-bottom-color: #00000026;
  }

  .md\:border-y-transparent\/20 {
    border-top-color: #0003;
    border-bottom-color: #0003;
  }

  .md\:border-y-transparent\/25 {
    border-top-color: #00000040;
    border-bottom-color: #00000040;
  }

  .md\:border-y-transparent\/30 {
    border-top-color: #0000004d;
    border-bottom-color: #0000004d;
  }

  .md\:border-y-transparent\/35 {
    border-top-color: #00000059;
    border-bottom-color: #00000059;
  }

  .md\:border-y-transparent\/40 {
    border-top-color: #0006;
    border-bottom-color: #0006;
  }

  .md\:border-y-transparent\/45 {
    border-top-color: #00000073;
    border-bottom-color: #00000073;
  }

  .md\:border-y-transparent\/5 {
    border-top-color: #0000000d;
    border-bottom-color: #0000000d;
  }

  .md\:border-y-transparent\/50 {
    border-top-color: #00000080;
    border-bottom-color: #00000080;
  }

  .md\:border-y-transparent\/55 {
    border-top-color: #0000008c;
    border-bottom-color: #0000008c;
  }

  .md\:border-y-transparent\/60 {
    border-top-color: #0009;
    border-bottom-color: #0009;
  }

  .md\:border-y-transparent\/65 {
    border-top-color: #000000a6;
    border-bottom-color: #000000a6;
  }

  .md\:border-y-transparent\/70 {
    border-top-color: #000000b3;
    border-bottom-color: #000000b3;
  }

  .md\:border-y-transparent\/75 {
    border-top-color: #000000bf;
    border-bottom-color: #000000bf;
  }

  .md\:border-y-transparent\/80 {
    border-top-color: #000c;
    border-bottom-color: #000c;
  }

  .md\:border-y-transparent\/85 {
    border-top-color: #000000d9;
    border-bottom-color: #000000d9;
  }

  .md\:border-y-transparent\/90 {
    border-top-color: #000000e6;
    border-bottom-color: #000000e6;
  }

  .md\:border-y-transparent\/95 {
    border-top-color: #000000f2;
    border-bottom-color: #000000f2;
  }

  .md\:border-y-warning {
    border-top-color: var(--color-warning-300);
    border-bottom-color: var(--color-warning-300);
  }

  .md\:border-y-warning-100 {
    border-top-color: var(--color-warning-100);
    border-bottom-color: var(--color-warning-100);
  }

  .md\:border-y-warning-200 {
    border-top-color: var(--color-warning-200);
    border-bottom-color: var(--color-warning-200);
  }

  .md\:border-y-warning-300 {
    border-top-color: var(--color-warning-300);
    border-bottom-color: var(--color-warning-300);
  }

  .md\:border-y-warning-400 {
    border-top-color: var(--color-warning-400);
    border-bottom-color: var(--color-warning-400);
  }

  .md\:border-y-white {
    border-top-color: var(--color-white);
    border-bottom-color: var(--color-white);
  }

  .md\:border-b-black {
    border-bottom-color: var(--color-black);
  }

  .md\:border-b-current {
    border-bottom-color: currentColor;
  }

  .md\:border-b-danger {
    border-bottom-color: var(--color-danger-300);
  }

  .md\:border-b-danger-100 {
    border-bottom-color: var(--color-danger-100);
  }

  .md\:border-b-danger-200 {
    border-bottom-color: var(--color-danger-200);
  }

  .md\:border-b-danger-300 {
    border-bottom-color: var(--color-danger-300);
  }

  .md\:border-b-danger-400 {
    border-bottom-color: var(--color-danger-400);
  }

  .md\:border-b-divider {
    border-bottom-color: var(--color-divider);
  }

  .md\:border-b-gray {
    border-bottom-color: var(--color-gray);
  }

  .md\:border-b-info {
    border-bottom-color: var(--color-info-300);
  }

  .md\:border-b-info-100 {
    border-bottom-color: var(--color-info-100);
  }

  .md\:border-b-info-200 {
    border-bottom-color: var(--color-info-200);
  }

  .md\:border-b-info-300 {
    border-bottom-color: var(--color-info-300);
  }

  .md\:border-b-info-400 {
    border-bottom-color: var(--color-info-400);
  }

  .md\:border-b-primary {
    border-bottom-color: var(--color-primary-300);
  }

  .md\:border-b-primary-100 {
    border-bottom-color: var(--color-primary-100);
  }

  .md\:border-b-primary-200 {
    border-bottom-color: var(--color-primary-200);
  }

  .md\:border-b-primary-250 {
    border-bottom-color: var(--color-primary-250);
  }

  .md\:border-b-primary-300 {
    border-bottom-color: var(--color-primary-300);
  }

  .md\:border-b-primary-400 {
    border-bottom-color: var(--color-primary-400);
  }

  .md\:border-b-primary-500 {
    border-bottom-color: var(--color-primary-500);
  }

  .md\:border-b-primary-600 {
    border-bottom-color: var(--color-primary-600);
  }

  .md\:border-b-success {
    border-bottom-color: var(--color-success-300);
  }

  .md\:border-b-success-100 {
    border-bottom-color: var(--color-success-100);
  }

  .md\:border-b-success-200 {
    border-bottom-color: var(--color-success-200);
  }

  .md\:border-b-success-300 {
    border-bottom-color: var(--color-success-300);
  }

  .md\:border-b-success-400 {
    border-bottom-color: var(--color-success-400);
  }

  .md\:border-b-transparent, .md\:border-b-transparent\/0 {
    border-bottom-color: #0000;
  }

  .md\:border-b-transparent\/10 {
    border-bottom-color: #0000001a;
  }

  .md\:border-b-transparent\/100 {
    border-bottom-color: #000;
  }

  .md\:border-b-transparent\/15 {
    border-bottom-color: #00000026;
  }

  .md\:border-b-transparent\/20 {
    border-bottom-color: #0003;
  }

  .md\:border-b-transparent\/25 {
    border-bottom-color: #00000040;
  }

  .md\:border-b-transparent\/30 {
    border-bottom-color: #0000004d;
  }

  .md\:border-b-transparent\/35 {
    border-bottom-color: #00000059;
  }

  .md\:border-b-transparent\/40 {
    border-bottom-color: #0006;
  }

  .md\:border-b-transparent\/45 {
    border-bottom-color: #00000073;
  }

  .md\:border-b-transparent\/5 {
    border-bottom-color: #0000000d;
  }

  .md\:border-b-transparent\/50 {
    border-bottom-color: #00000080;
  }

  .md\:border-b-transparent\/55 {
    border-bottom-color: #0000008c;
  }

  .md\:border-b-transparent\/60 {
    border-bottom-color: #0009;
  }

  .md\:border-b-transparent\/65 {
    border-bottom-color: #000000a6;
  }

  .md\:border-b-transparent\/70 {
    border-bottom-color: #000000b3;
  }

  .md\:border-b-transparent\/75 {
    border-bottom-color: #000000bf;
  }

  .md\:border-b-transparent\/80 {
    border-bottom-color: #000c;
  }

  .md\:border-b-transparent\/85 {
    border-bottom-color: #000000d9;
  }

  .md\:border-b-transparent\/90 {
    border-bottom-color: #000000e6;
  }

  .md\:border-b-transparent\/95 {
    border-bottom-color: #000000f2;
  }

  .md\:border-b-warning {
    border-bottom-color: var(--color-warning-300);
  }

  .md\:border-b-warning-100 {
    border-bottom-color: var(--color-warning-100);
  }

  .md\:border-b-warning-200 {
    border-bottom-color: var(--color-warning-200);
  }

  .md\:border-b-warning-300 {
    border-bottom-color: var(--color-warning-300);
  }

  .md\:border-b-warning-400 {
    border-bottom-color: var(--color-warning-400);
  }

  .md\:border-b-white {
    border-bottom-color: var(--color-white);
  }

  .md\:border-e-black {
    border-inline-end-color: var(--color-black);
  }

  .md\:border-e-current {
    border-inline-end-color: currentColor;
  }

  .md\:border-e-danger {
    border-inline-end-color: var(--color-danger-300);
  }

  .md\:border-e-danger-100 {
    border-inline-end-color: var(--color-danger-100);
  }

  .md\:border-e-danger-200 {
    border-inline-end-color: var(--color-danger-200);
  }

  .md\:border-e-danger-300 {
    border-inline-end-color: var(--color-danger-300);
  }

  .md\:border-e-danger-400 {
    border-inline-end-color: var(--color-danger-400);
  }

  .md\:border-e-divider {
    border-inline-end-color: var(--color-divider);
  }

  .md\:border-e-gray {
    border-inline-end-color: var(--color-gray);
  }

  .md\:border-e-info {
    border-inline-end-color: var(--color-info-300);
  }

  .md\:border-e-info-100 {
    border-inline-end-color: var(--color-info-100);
  }

  .md\:border-e-info-200 {
    border-inline-end-color: var(--color-info-200);
  }

  .md\:border-e-info-300 {
    border-inline-end-color: var(--color-info-300);
  }

  .md\:border-e-info-400 {
    border-inline-end-color: var(--color-info-400);
  }

  .md\:border-e-primary {
    border-inline-end-color: var(--color-primary-300);
  }

  .md\:border-e-primary-100 {
    border-inline-end-color: var(--color-primary-100);
  }

  .md\:border-e-primary-200 {
    border-inline-end-color: var(--color-primary-200);
  }

  .md\:border-e-primary-250 {
    border-inline-end-color: var(--color-primary-250);
  }

  .md\:border-e-primary-300 {
    border-inline-end-color: var(--color-primary-300);
  }

  .md\:border-e-primary-400 {
    border-inline-end-color: var(--color-primary-400);
  }

  .md\:border-e-primary-500 {
    border-inline-end-color: var(--color-primary-500);
  }

  .md\:border-e-primary-600 {
    border-inline-end-color: var(--color-primary-600);
  }

  .md\:border-e-success {
    border-inline-end-color: var(--color-success-300);
  }

  .md\:border-e-success-100 {
    border-inline-end-color: var(--color-success-100);
  }

  .md\:border-e-success-200 {
    border-inline-end-color: var(--color-success-200);
  }

  .md\:border-e-success-300 {
    border-inline-end-color: var(--color-success-300);
  }

  .md\:border-e-success-400 {
    border-inline-end-color: var(--color-success-400);
  }

  .md\:border-e-transparent, .md\:border-e-transparent\/0 {
    border-inline-end-color: #0000;
  }

  .md\:border-e-transparent\/10 {
    border-inline-end-color: #0000001a;
  }

  .md\:border-e-transparent\/100 {
    border-inline-end-color: #000;
  }

  .md\:border-e-transparent\/15 {
    border-inline-end-color: #00000026;
  }

  .md\:border-e-transparent\/20 {
    border-inline-end-color: #0003;
  }

  .md\:border-e-transparent\/25 {
    border-inline-end-color: #00000040;
  }

  .md\:border-e-transparent\/30 {
    border-inline-end-color: #0000004d;
  }

  .md\:border-e-transparent\/35 {
    border-inline-end-color: #00000059;
  }

  .md\:border-e-transparent\/40 {
    border-inline-end-color: #0006;
  }

  .md\:border-e-transparent\/45 {
    border-inline-end-color: #00000073;
  }

  .md\:border-e-transparent\/5 {
    border-inline-end-color: #0000000d;
  }

  .md\:border-e-transparent\/50 {
    border-inline-end-color: #00000080;
  }

  .md\:border-e-transparent\/55 {
    border-inline-end-color: #0000008c;
  }

  .md\:border-e-transparent\/60 {
    border-inline-end-color: #0009;
  }

  .md\:border-e-transparent\/65 {
    border-inline-end-color: #000000a6;
  }

  .md\:border-e-transparent\/70 {
    border-inline-end-color: #000000b3;
  }

  .md\:border-e-transparent\/75 {
    border-inline-end-color: #000000bf;
  }

  .md\:border-e-transparent\/80 {
    border-inline-end-color: #000c;
  }

  .md\:border-e-transparent\/85 {
    border-inline-end-color: #000000d9;
  }

  .md\:border-e-transparent\/90 {
    border-inline-end-color: #000000e6;
  }

  .md\:border-e-transparent\/95 {
    border-inline-end-color: #000000f2;
  }

  .md\:border-e-warning {
    border-inline-end-color: var(--color-warning-300);
  }

  .md\:border-e-warning-100 {
    border-inline-end-color: var(--color-warning-100);
  }

  .md\:border-e-warning-200 {
    border-inline-end-color: var(--color-warning-200);
  }

  .md\:border-e-warning-300 {
    border-inline-end-color: var(--color-warning-300);
  }

  .md\:border-e-warning-400 {
    border-inline-end-color: var(--color-warning-400);
  }

  .md\:border-e-white {
    border-inline-end-color: var(--color-white);
  }

  .md\:border-l-black {
    border-left-color: var(--color-black);
  }

  .md\:border-l-current {
    border-left-color: currentColor;
  }

  .md\:border-l-danger {
    border-left-color: var(--color-danger-300);
  }

  .md\:border-l-danger-100 {
    border-left-color: var(--color-danger-100);
  }

  .md\:border-l-danger-200 {
    border-left-color: var(--color-danger-200);
  }

  .md\:border-l-danger-300 {
    border-left-color: var(--color-danger-300);
  }

  .md\:border-l-danger-400 {
    border-left-color: var(--color-danger-400);
  }

  .md\:border-l-divider {
    border-left-color: var(--color-divider);
  }

  .md\:border-l-gray {
    border-left-color: var(--color-gray);
  }

  .md\:border-l-info {
    border-left-color: var(--color-info-300);
  }

  .md\:border-l-info-100 {
    border-left-color: var(--color-info-100);
  }

  .md\:border-l-info-200 {
    border-left-color: var(--color-info-200);
  }

  .md\:border-l-info-300 {
    border-left-color: var(--color-info-300);
  }

  .md\:border-l-info-400 {
    border-left-color: var(--color-info-400);
  }

  .md\:border-l-primary {
    border-left-color: var(--color-primary-300);
  }

  .md\:border-l-primary-100 {
    border-left-color: var(--color-primary-100);
  }

  .md\:border-l-primary-200 {
    border-left-color: var(--color-primary-200);
  }

  .md\:border-l-primary-250 {
    border-left-color: var(--color-primary-250);
  }

  .md\:border-l-primary-300 {
    border-left-color: var(--color-primary-300);
  }

  .md\:border-l-primary-400 {
    border-left-color: var(--color-primary-400);
  }

  .md\:border-l-primary-500 {
    border-left-color: var(--color-primary-500);
  }

  .md\:border-l-primary-600 {
    border-left-color: var(--color-primary-600);
  }

  .md\:border-l-success {
    border-left-color: var(--color-success-300);
  }

  .md\:border-l-success-100 {
    border-left-color: var(--color-success-100);
  }

  .md\:border-l-success-200 {
    border-left-color: var(--color-success-200);
  }

  .md\:border-l-success-300 {
    border-left-color: var(--color-success-300);
  }

  .md\:border-l-success-400 {
    border-left-color: var(--color-success-400);
  }

  .md\:border-l-transparent, .md\:border-l-transparent\/0 {
    border-left-color: #0000;
  }

  .md\:border-l-transparent\/10 {
    border-left-color: #0000001a;
  }

  .md\:border-l-transparent\/100 {
    border-left-color: #000;
  }

  .md\:border-l-transparent\/15 {
    border-left-color: #00000026;
  }

  .md\:border-l-transparent\/20 {
    border-left-color: #0003;
  }

  .md\:border-l-transparent\/25 {
    border-left-color: #00000040;
  }

  .md\:border-l-transparent\/30 {
    border-left-color: #0000004d;
  }

  .md\:border-l-transparent\/35 {
    border-left-color: #00000059;
  }

  .md\:border-l-transparent\/40 {
    border-left-color: #0006;
  }

  .md\:border-l-transparent\/45 {
    border-left-color: #00000073;
  }

  .md\:border-l-transparent\/5 {
    border-left-color: #0000000d;
  }

  .md\:border-l-transparent\/50 {
    border-left-color: #00000080;
  }

  .md\:border-l-transparent\/55 {
    border-left-color: #0000008c;
  }

  .md\:border-l-transparent\/60 {
    border-left-color: #0009;
  }

  .md\:border-l-transparent\/65 {
    border-left-color: #000000a6;
  }

  .md\:border-l-transparent\/70 {
    border-left-color: #000000b3;
  }

  .md\:border-l-transparent\/75 {
    border-left-color: #000000bf;
  }

  .md\:border-l-transparent\/80 {
    border-left-color: #000c;
  }

  .md\:border-l-transparent\/85 {
    border-left-color: #000000d9;
  }

  .md\:border-l-transparent\/90 {
    border-left-color: #000000e6;
  }

  .md\:border-l-transparent\/95 {
    border-left-color: #000000f2;
  }

  .md\:border-l-warning {
    border-left-color: var(--color-warning-300);
  }

  .md\:border-l-warning-100 {
    border-left-color: var(--color-warning-100);
  }

  .md\:border-l-warning-200 {
    border-left-color: var(--color-warning-200);
  }

  .md\:border-l-warning-300 {
    border-left-color: var(--color-warning-300);
  }

  .md\:border-l-warning-400 {
    border-left-color: var(--color-warning-400);
  }

  .md\:border-l-white {
    border-left-color: var(--color-white);
  }

  .md\:border-r-black {
    border-right-color: var(--color-black);
  }

  .md\:border-r-current {
    border-right-color: currentColor;
  }

  .md\:border-r-danger {
    border-right-color: var(--color-danger-300);
  }

  .md\:border-r-danger-100 {
    border-right-color: var(--color-danger-100);
  }

  .md\:border-r-danger-200 {
    border-right-color: var(--color-danger-200);
  }

  .md\:border-r-danger-300 {
    border-right-color: var(--color-danger-300);
  }

  .md\:border-r-danger-400 {
    border-right-color: var(--color-danger-400);
  }

  .md\:border-r-divider {
    border-right-color: var(--color-divider);
  }

  .md\:border-r-gray {
    border-right-color: var(--color-gray);
  }

  .md\:border-r-info {
    border-right-color: var(--color-info-300);
  }

  .md\:border-r-info-100 {
    border-right-color: var(--color-info-100);
  }

  .md\:border-r-info-200 {
    border-right-color: var(--color-info-200);
  }

  .md\:border-r-info-300 {
    border-right-color: var(--color-info-300);
  }

  .md\:border-r-info-400 {
    border-right-color: var(--color-info-400);
  }

  .md\:border-r-primary {
    border-right-color: var(--color-primary-300);
  }

  .md\:border-r-primary-100 {
    border-right-color: var(--color-primary-100);
  }

  .md\:border-r-primary-200 {
    border-right-color: var(--color-primary-200);
  }

  .md\:border-r-primary-250 {
    border-right-color: var(--color-primary-250);
  }

  .md\:border-r-primary-300 {
    border-right-color: var(--color-primary-300);
  }

  .md\:border-r-primary-400 {
    border-right-color: var(--color-primary-400);
  }

  .md\:border-r-primary-500 {
    border-right-color: var(--color-primary-500);
  }

  .md\:border-r-primary-600 {
    border-right-color: var(--color-primary-600);
  }

  .md\:border-r-success {
    border-right-color: var(--color-success-300);
  }

  .md\:border-r-success-100 {
    border-right-color: var(--color-success-100);
  }

  .md\:border-r-success-200 {
    border-right-color: var(--color-success-200);
  }

  .md\:border-r-success-300 {
    border-right-color: var(--color-success-300);
  }

  .md\:border-r-success-400 {
    border-right-color: var(--color-success-400);
  }

  .md\:border-r-transparent, .md\:border-r-transparent\/0 {
    border-right-color: #0000;
  }

  .md\:border-r-transparent\/10 {
    border-right-color: #0000001a;
  }

  .md\:border-r-transparent\/100 {
    border-right-color: #000;
  }

  .md\:border-r-transparent\/15 {
    border-right-color: #00000026;
  }

  .md\:border-r-transparent\/20 {
    border-right-color: #0003;
  }

  .md\:border-r-transparent\/25 {
    border-right-color: #00000040;
  }

  .md\:border-r-transparent\/30 {
    border-right-color: #0000004d;
  }

  .md\:border-r-transparent\/35 {
    border-right-color: #00000059;
  }

  .md\:border-r-transparent\/40 {
    border-right-color: #0006;
  }

  .md\:border-r-transparent\/45 {
    border-right-color: #00000073;
  }

  .md\:border-r-transparent\/5 {
    border-right-color: #0000000d;
  }

  .md\:border-r-transparent\/50 {
    border-right-color: #00000080;
  }

  .md\:border-r-transparent\/55 {
    border-right-color: #0000008c;
  }

  .md\:border-r-transparent\/60 {
    border-right-color: #0009;
  }

  .md\:border-r-transparent\/65 {
    border-right-color: #000000a6;
  }

  .md\:border-r-transparent\/70 {
    border-right-color: #000000b3;
  }

  .md\:border-r-transparent\/75 {
    border-right-color: #000000bf;
  }

  .md\:border-r-transparent\/80 {
    border-right-color: #000c;
  }

  .md\:border-r-transparent\/85 {
    border-right-color: #000000d9;
  }

  .md\:border-r-transparent\/90 {
    border-right-color: #000000e6;
  }

  .md\:border-r-transparent\/95 {
    border-right-color: #000000f2;
  }

  .md\:border-r-warning {
    border-right-color: var(--color-warning-300);
  }

  .md\:border-r-warning-100 {
    border-right-color: var(--color-warning-100);
  }

  .md\:border-r-warning-200 {
    border-right-color: var(--color-warning-200);
  }

  .md\:border-r-warning-300 {
    border-right-color: var(--color-warning-300);
  }

  .md\:border-r-warning-400 {
    border-right-color: var(--color-warning-400);
  }

  .md\:border-r-white {
    border-right-color: var(--color-white);
  }

  .md\:border-s-black {
    border-inline-start-color: var(--color-black);
  }

  .md\:border-s-current {
    border-inline-start-color: currentColor;
  }

  .md\:border-s-danger {
    border-inline-start-color: var(--color-danger-300);
  }

  .md\:border-s-danger-100 {
    border-inline-start-color: var(--color-danger-100);
  }

  .md\:border-s-danger-200 {
    border-inline-start-color: var(--color-danger-200);
  }

  .md\:border-s-danger-300 {
    border-inline-start-color: var(--color-danger-300);
  }

  .md\:border-s-danger-400 {
    border-inline-start-color: var(--color-danger-400);
  }

  .md\:border-s-divider {
    border-inline-start-color: var(--color-divider);
  }

  .md\:border-s-gray {
    border-inline-start-color: var(--color-gray);
  }

  .md\:border-s-info {
    border-inline-start-color: var(--color-info-300);
  }

  .md\:border-s-info-100 {
    border-inline-start-color: var(--color-info-100);
  }

  .md\:border-s-info-200 {
    border-inline-start-color: var(--color-info-200);
  }

  .md\:border-s-info-300 {
    border-inline-start-color: var(--color-info-300);
  }

  .md\:border-s-info-400 {
    border-inline-start-color: var(--color-info-400);
  }

  .md\:border-s-primary {
    border-inline-start-color: var(--color-primary-300);
  }

  .md\:border-s-primary-100 {
    border-inline-start-color: var(--color-primary-100);
  }

  .md\:border-s-primary-200 {
    border-inline-start-color: var(--color-primary-200);
  }

  .md\:border-s-primary-250 {
    border-inline-start-color: var(--color-primary-250);
  }

  .md\:border-s-primary-300 {
    border-inline-start-color: var(--color-primary-300);
  }

  .md\:border-s-primary-400 {
    border-inline-start-color: var(--color-primary-400);
  }

  .md\:border-s-primary-500 {
    border-inline-start-color: var(--color-primary-500);
  }

  .md\:border-s-primary-600 {
    border-inline-start-color: var(--color-primary-600);
  }

  .md\:border-s-success {
    border-inline-start-color: var(--color-success-300);
  }

  .md\:border-s-success-100 {
    border-inline-start-color: var(--color-success-100);
  }

  .md\:border-s-success-200 {
    border-inline-start-color: var(--color-success-200);
  }

  .md\:border-s-success-300 {
    border-inline-start-color: var(--color-success-300);
  }

  .md\:border-s-success-400 {
    border-inline-start-color: var(--color-success-400);
  }

  .md\:border-s-transparent, .md\:border-s-transparent\/0 {
    border-inline-start-color: #0000;
  }

  .md\:border-s-transparent\/10 {
    border-inline-start-color: #0000001a;
  }

  .md\:border-s-transparent\/100 {
    border-inline-start-color: #000;
  }

  .md\:border-s-transparent\/15 {
    border-inline-start-color: #00000026;
  }

  .md\:border-s-transparent\/20 {
    border-inline-start-color: #0003;
  }

  .md\:border-s-transparent\/25 {
    border-inline-start-color: #00000040;
  }

  .md\:border-s-transparent\/30 {
    border-inline-start-color: #0000004d;
  }

  .md\:border-s-transparent\/35 {
    border-inline-start-color: #00000059;
  }

  .md\:border-s-transparent\/40 {
    border-inline-start-color: #0006;
  }

  .md\:border-s-transparent\/45 {
    border-inline-start-color: #00000073;
  }

  .md\:border-s-transparent\/5 {
    border-inline-start-color: #0000000d;
  }

  .md\:border-s-transparent\/50 {
    border-inline-start-color: #00000080;
  }

  .md\:border-s-transparent\/55 {
    border-inline-start-color: #0000008c;
  }

  .md\:border-s-transparent\/60 {
    border-inline-start-color: #0009;
  }

  .md\:border-s-transparent\/65 {
    border-inline-start-color: #000000a6;
  }

  .md\:border-s-transparent\/70 {
    border-inline-start-color: #000000b3;
  }

  .md\:border-s-transparent\/75 {
    border-inline-start-color: #000000bf;
  }

  .md\:border-s-transparent\/80 {
    border-inline-start-color: #000c;
  }

  .md\:border-s-transparent\/85 {
    border-inline-start-color: #000000d9;
  }

  .md\:border-s-transparent\/90 {
    border-inline-start-color: #000000e6;
  }

  .md\:border-s-transparent\/95 {
    border-inline-start-color: #000000f2;
  }

  .md\:border-s-warning {
    border-inline-start-color: var(--color-warning-300);
  }

  .md\:border-s-warning-100 {
    border-inline-start-color: var(--color-warning-100);
  }

  .md\:border-s-warning-200 {
    border-inline-start-color: var(--color-warning-200);
  }

  .md\:border-s-warning-300 {
    border-inline-start-color: var(--color-warning-300);
  }

  .md\:border-s-warning-400 {
    border-inline-start-color: var(--color-warning-400);
  }

  .md\:border-s-white {
    border-inline-start-color: var(--color-white);
  }

  .md\:border-t-black {
    border-top-color: var(--color-black);
  }

  .md\:border-t-current {
    border-top-color: currentColor;
  }

  .md\:border-t-danger {
    border-top-color: var(--color-danger-300);
  }

  .md\:border-t-danger-100 {
    border-top-color: var(--color-danger-100);
  }

  .md\:border-t-danger-200 {
    border-top-color: var(--color-danger-200);
  }

  .md\:border-t-danger-300 {
    border-top-color: var(--color-danger-300);
  }

  .md\:border-t-danger-400 {
    border-top-color: var(--color-danger-400);
  }

  .md\:border-t-divider {
    border-top-color: var(--color-divider);
  }

  .md\:border-t-gray {
    border-top-color: var(--color-gray);
  }

  .md\:border-t-info {
    border-top-color: var(--color-info-300);
  }

  .md\:border-t-info-100 {
    border-top-color: var(--color-info-100);
  }

  .md\:border-t-info-200 {
    border-top-color: var(--color-info-200);
  }

  .md\:border-t-info-300 {
    border-top-color: var(--color-info-300);
  }

  .md\:border-t-info-400 {
    border-top-color: var(--color-info-400);
  }

  .md\:border-t-primary {
    border-top-color: var(--color-primary-300);
  }

  .md\:border-t-primary-100 {
    border-top-color: var(--color-primary-100);
  }

  .md\:border-t-primary-200 {
    border-top-color: var(--color-primary-200);
  }

  .md\:border-t-primary-250 {
    border-top-color: var(--color-primary-250);
  }

  .md\:border-t-primary-300 {
    border-top-color: var(--color-primary-300);
  }

  .md\:border-t-primary-400 {
    border-top-color: var(--color-primary-400);
  }

  .md\:border-t-primary-500 {
    border-top-color: var(--color-primary-500);
  }

  .md\:border-t-primary-600 {
    border-top-color: var(--color-primary-600);
  }

  .md\:border-t-success {
    border-top-color: var(--color-success-300);
  }

  .md\:border-t-success-100 {
    border-top-color: var(--color-success-100);
  }

  .md\:border-t-success-200 {
    border-top-color: var(--color-success-200);
  }

  .md\:border-t-success-300 {
    border-top-color: var(--color-success-300);
  }

  .md\:border-t-success-400 {
    border-top-color: var(--color-success-400);
  }

  .md\:border-t-transparent, .md\:border-t-transparent\/0 {
    border-top-color: #0000;
  }

  .md\:border-t-transparent\/10 {
    border-top-color: #0000001a;
  }

  .md\:border-t-transparent\/100 {
    border-top-color: #000;
  }

  .md\:border-t-transparent\/15 {
    border-top-color: #00000026;
  }

  .md\:border-t-transparent\/20 {
    border-top-color: #0003;
  }

  .md\:border-t-transparent\/25 {
    border-top-color: #00000040;
  }

  .md\:border-t-transparent\/30 {
    border-top-color: #0000004d;
  }

  .md\:border-t-transparent\/35 {
    border-top-color: #00000059;
  }

  .md\:border-t-transparent\/40 {
    border-top-color: #0006;
  }

  .md\:border-t-transparent\/45 {
    border-top-color: #00000073;
  }

  .md\:border-t-transparent\/5 {
    border-top-color: #0000000d;
  }

  .md\:border-t-transparent\/50 {
    border-top-color: #00000080;
  }

  .md\:border-t-transparent\/55 {
    border-top-color: #0000008c;
  }

  .md\:border-t-transparent\/60 {
    border-top-color: #0009;
  }

  .md\:border-t-transparent\/65 {
    border-top-color: #000000a6;
  }

  .md\:border-t-transparent\/70 {
    border-top-color: #000000b3;
  }

  .md\:border-t-transparent\/75 {
    border-top-color: #000000bf;
  }

  .md\:border-t-transparent\/80 {
    border-top-color: #000c;
  }

  .md\:border-t-transparent\/85 {
    border-top-color: #000000d9;
  }

  .md\:border-t-transparent\/90 {
    border-top-color: #000000e6;
  }

  .md\:border-t-transparent\/95 {
    border-top-color: #000000f2;
  }

  .md\:border-t-warning {
    border-top-color: var(--color-warning-300);
  }

  .md\:border-t-warning-100 {
    border-top-color: var(--color-warning-100);
  }

  .md\:border-t-warning-200 {
    border-top-color: var(--color-warning-200);
  }

  .md\:border-t-warning-300 {
    border-top-color: var(--color-warning-300);
  }

  .md\:border-t-warning-400 {
    border-top-color: var(--color-warning-400);
  }

  .md\:border-t-white {
    border-top-color: var(--color-white);
  }

  .md\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .md\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .md\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .md\:border-opacity-15 {
    --tw-border-opacity: .15;
  }

  .md\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .md\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .md\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .md\:border-opacity-35 {
    --tw-border-opacity: .35;
  }

  .md\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .md\:border-opacity-45 {
    --tw-border-opacity: .45;
  }

  .md\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .md\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .md\:border-opacity-55 {
    --tw-border-opacity: .55;
  }

  .md\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .md\:border-opacity-65 {
    --tw-border-opacity: .65;
  }

  .md\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .md\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .md\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .md\:border-opacity-85 {
    --tw-border-opacity: .85;
  }

  .md\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .md\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .md\:bg-black {
    background-color: var(--color-black);
  }

  .md\:bg-current {
    background-color: currentColor;
  }

  .md\:bg-danger {
    background-color: var(--color-danger-300);
  }

  .md\:bg-danger-100 {
    background-color: var(--color-danger-100);
  }

  .md\:bg-danger-200 {
    background-color: var(--color-danger-200);
  }

  .md\:bg-danger-300 {
    background-color: var(--color-danger-300);
  }

  .md\:bg-danger-400 {
    background-color: var(--color-danger-400);
  }

  .md\:bg-divider {
    background-color: var(--color-divider);
  }

  .md\:bg-gray {
    background-color: var(--color-gray);
  }

  .md\:bg-info {
    background-color: var(--color-info-300);
  }

  .md\:bg-info-100 {
    background-color: var(--color-info-100);
  }

  .md\:bg-info-200 {
    background-color: var(--color-info-200);
  }

  .md\:bg-info-300 {
    background-color: var(--color-info-300);
  }

  .md\:bg-info-400 {
    background-color: var(--color-info-400);
  }

  .md\:bg-primary {
    background-color: var(--color-primary-300);
  }

  .md\:bg-primary-100 {
    background-color: var(--color-primary-100);
  }

  .md\:bg-primary-200 {
    background-color: var(--color-primary-200);
  }

  .md\:bg-primary-250 {
    background-color: var(--color-primary-250);
  }

  .md\:bg-primary-300 {
    background-color: var(--color-primary-300);
  }

  .md\:bg-primary-400 {
    background-color: var(--color-primary-400);
  }

  .md\:bg-primary-500 {
    background-color: var(--color-primary-500);
  }

  .md\:bg-primary-600 {
    background-color: var(--color-primary-600);
  }

  .md\:bg-success {
    background-color: var(--color-success-300);
  }

  .md\:bg-success-100 {
    background-color: var(--color-success-100);
  }

  .md\:bg-success-200 {
    background-color: var(--color-success-200);
  }

  .md\:bg-success-300 {
    background-color: var(--color-success-300);
  }

  .md\:bg-success-400 {
    background-color: var(--color-success-400);
  }

  .md\:bg-transparent, .md\:bg-transparent\/0 {
    background-color: #0000;
  }

  .md\:bg-transparent\/10 {
    background-color: #0000001a;
  }

  .md\:bg-transparent\/100 {
    background-color: #000;
  }

  .md\:bg-transparent\/15 {
    background-color: #00000026;
  }

  .md\:bg-transparent\/20 {
    background-color: #0003;
  }

  .md\:bg-transparent\/25 {
    background-color: #00000040;
  }

  .md\:bg-transparent\/30 {
    background-color: #0000004d;
  }

  .md\:bg-transparent\/35 {
    background-color: #00000059;
  }

  .md\:bg-transparent\/40 {
    background-color: #0006;
  }

  .md\:bg-transparent\/45 {
    background-color: #00000073;
  }

  .md\:bg-transparent\/5 {
    background-color: #0000000d;
  }

  .md\:bg-transparent\/50 {
    background-color: #00000080;
  }

  .md\:bg-transparent\/55 {
    background-color: #0000008c;
  }

  .md\:bg-transparent\/60 {
    background-color: #0009;
  }

  .md\:bg-transparent\/65 {
    background-color: #000000a6;
  }

  .md\:bg-transparent\/70 {
    background-color: #000000b3;
  }

  .md\:bg-transparent\/75 {
    background-color: #000000bf;
  }

  .md\:bg-transparent\/80 {
    background-color: #000c;
  }

  .md\:bg-transparent\/85 {
    background-color: #000000d9;
  }

  .md\:bg-transparent\/90 {
    background-color: #000000e6;
  }

  .md\:bg-transparent\/95 {
    background-color: #000000f2;
  }

  .md\:bg-warning {
    background-color: var(--color-warning-300);
  }

  .md\:bg-warning-100 {
    background-color: var(--color-warning-100);
  }

  .md\:bg-warning-200 {
    background-color: var(--color-warning-200);
  }

  .md\:bg-warning-300 {
    background-color: var(--color-warning-300);
  }

  .md\:bg-warning-400 {
    background-color: var(--color-warning-400);
  }

  .md\:bg-white {
    background-color: var(--color-white);
  }

  .md\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .md\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .md\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .md\:bg-opacity-15 {
    --tw-bg-opacity: .15;
  }

  .md\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .md\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .md\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .md\:bg-opacity-35 {
    --tw-bg-opacity: .35;
  }

  .md\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .md\:bg-opacity-45 {
    --tw-bg-opacity: .45;
  }

  .md\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .md\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .md\:bg-opacity-55 {
    --tw-bg-opacity: .55;
  }

  .md\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .md\:bg-opacity-65 {
    --tw-bg-opacity: .65;
  }

  .md\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .md\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .md\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .md\:bg-opacity-85 {
    --tw-bg-opacity: .85;
  }

  .md\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .md\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .md\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .md\:bg-none {
    background-image: none;
  }

  .md\:from-black {
    --tw-gradient-from: var(--color-black) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-current {
    --tw-gradient-from: currentColor var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-danger {
    --tw-gradient-from: var(--color-danger-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-danger-100 {
    --tw-gradient-from: var(--color-danger-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-danger-200 {
    --tw-gradient-from: var(--color-danger-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-danger-300 {
    --tw-gradient-from: var(--color-danger-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-danger-400 {
    --tw-gradient-from: var(--color-danger-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-divider {
    --tw-gradient-from: var(--color-divider) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-gray {
    --tw-gradient-from: var(--color-gray) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-info {
    --tw-gradient-from: var(--color-info-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-info-100 {
    --tw-gradient-from: var(--color-info-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-info-200 {
    --tw-gradient-from: var(--color-info-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-info-300 {
    --tw-gradient-from: var(--color-info-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-info-400 {
    --tw-gradient-from: var(--color-info-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-primary {
    --tw-gradient-from: var(--color-primary-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-primary-100 {
    --tw-gradient-from: var(--color-primary-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-primary-200 {
    --tw-gradient-from: var(--color-primary-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-primary-250 {
    --tw-gradient-from: var(--color-primary-250) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-primary-300 {
    --tw-gradient-from: var(--color-primary-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-primary-400 {
    --tw-gradient-from: var(--color-primary-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-primary-500 {
    --tw-gradient-from: var(--color-primary-500) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-primary-600 {
    --tw-gradient-from: var(--color-primary-600) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-success {
    --tw-gradient-from: var(--color-success-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-success-100 {
    --tw-gradient-from: var(--color-success-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-success-200 {
    --tw-gradient-from: var(--color-success-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-success-300 {
    --tw-gradient-from: var(--color-success-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-success-400 {
    --tw-gradient-from: var(--color-success-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent {
    --tw-gradient-from: transparent var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/0 {
    --tw-gradient-from: #0000 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/10 {
    --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/100 {
    --tw-gradient-from: #000 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/15 {
    --tw-gradient-from: #00000026 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/20 {
    --tw-gradient-from: #0003 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/25 {
    --tw-gradient-from: #00000040 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/30 {
    --tw-gradient-from: #0000004d var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/35 {
    --tw-gradient-from: #00000059 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/40 {
    --tw-gradient-from: #0006 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/45 {
    --tw-gradient-from: #00000073 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/5 {
    --tw-gradient-from: #0000000d var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/50 {
    --tw-gradient-from: #00000080 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/55 {
    --tw-gradient-from: #0000008c var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/60 {
    --tw-gradient-from: #0009 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/65 {
    --tw-gradient-from: #000000a6 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/70 {
    --tw-gradient-from: #000000b3 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/75 {
    --tw-gradient-from: #000000bf var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/80 {
    --tw-gradient-from: #000c var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/85 {
    --tw-gradient-from: #000000d9 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/90 {
    --tw-gradient-from: #000000e6 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-transparent\/95 {
    --tw-gradient-from: #000000f2 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-warning {
    --tw-gradient-from: var(--color-warning-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-warning-100 {
    --tw-gradient-from: var(--color-warning-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-warning-200 {
    --tw-gradient-from: var(--color-warning-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-warning-300 {
    --tw-gradient-from: var(--color-warning-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-warning-400 {
    --tw-gradient-from: var(--color-warning-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-white {
    --tw-gradient-from: var(--color-white) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-0\% {
    --tw-gradient-from-position: 0%;
  }

  .md\:from-10\% {
    --tw-gradient-from-position: 10%;
  }

  .md\:from-100\% {
    --tw-gradient-from-position: 100%;
  }

  .md\:from-15\% {
    --tw-gradient-from-position: 15%;
  }

  .md\:from-20\% {
    --tw-gradient-from-position: 20%;
  }

  .md\:from-25\% {
    --tw-gradient-from-position: 25%;
  }

  .md\:from-30\% {
    --tw-gradient-from-position: 30%;
  }

  .md\:from-35\% {
    --tw-gradient-from-position: 35%;
  }

  .md\:from-40\% {
    --tw-gradient-from-position: 40%;
  }

  .md\:from-45\% {
    --tw-gradient-from-position: 45%;
  }

  .md\:from-5\% {
    --tw-gradient-from-position: 5%;
  }

  .md\:from-50\% {
    --tw-gradient-from-position: 50%;
  }

  .md\:from-55\% {
    --tw-gradient-from-position: 55%;
  }

  .md\:from-60\% {
    --tw-gradient-from-position: 60%;
  }

  .md\:from-65\% {
    --tw-gradient-from-position: 65%;
  }

  .md\:from-70\% {
    --tw-gradient-from-position: 70%;
  }

  .md\:from-75\% {
    --tw-gradient-from-position: 75%;
  }

  .md\:from-80\% {
    --tw-gradient-from-position: 80%;
  }

  .md\:from-85\% {
    --tw-gradient-from-position: 85%;
  }

  .md\:from-90\% {
    --tw-gradient-from-position: 90%;
  }

  .md\:from-95\% {
    --tw-gradient-from-position: 95%;
  }

  .md\:via-black {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-black) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-current {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), currentColor var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-danger {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-danger-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-danger-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-danger-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-danger-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-divider {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-divider) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-gray {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-gray) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-info {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-info-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-info-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-info-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-info-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-primary {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-primary-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-primary-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-primary-250 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-250) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-primary-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-primary-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-primary-500 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-500) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-primary-600 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-600) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-success {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-success-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-success-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-success-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-success-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/0 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/10 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000001a var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/100 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/15 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000026 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/20 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0003 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/25 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000040 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/30 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000004d var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/35 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000059 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/40 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0006 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/45 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000073 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/5 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000000d var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/50 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000080 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/55 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000008c var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/60 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0009 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/65 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000a6 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/70 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/75 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000bf var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/80 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000c var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/85 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000d9 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/90 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-transparent\/95 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-warning {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-warning-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-warning-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-warning-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-warning-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-white {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-white) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .md\:via-0\% {
    --tw-gradient-via-position: 0%;
  }

  .md\:via-10\% {
    --tw-gradient-via-position: 10%;
  }

  .md\:via-100\% {
    --tw-gradient-via-position: 100%;
  }

  .md\:via-15\% {
    --tw-gradient-via-position: 15%;
  }

  .md\:via-20\% {
    --tw-gradient-via-position: 20%;
  }

  .md\:via-25\% {
    --tw-gradient-via-position: 25%;
  }

  .md\:via-30\% {
    --tw-gradient-via-position: 30%;
  }

  .md\:via-35\% {
    --tw-gradient-via-position: 35%;
  }

  .md\:via-40\% {
    --tw-gradient-via-position: 40%;
  }

  .md\:via-45\% {
    --tw-gradient-via-position: 45%;
  }

  .md\:via-5\% {
    --tw-gradient-via-position: 5%;
  }

  .md\:via-50\% {
    --tw-gradient-via-position: 50%;
  }

  .md\:via-55\% {
    --tw-gradient-via-position: 55%;
  }

  .md\:via-60\% {
    --tw-gradient-via-position: 60%;
  }

  .md\:via-65\% {
    --tw-gradient-via-position: 65%;
  }

  .md\:via-70\% {
    --tw-gradient-via-position: 70%;
  }

  .md\:via-75\% {
    --tw-gradient-via-position: 75%;
  }

  .md\:via-80\% {
    --tw-gradient-via-position: 80%;
  }

  .md\:via-85\% {
    --tw-gradient-via-position: 85%;
  }

  .md\:via-90\% {
    --tw-gradient-via-position: 90%;
  }

  .md\:via-95\% {
    --tw-gradient-via-position: 95%;
  }

  .md\:to-black {
    --tw-gradient-to: var(--color-black) var(--tw-gradient-to-position);
  }

  .md\:to-current {
    --tw-gradient-to: currentColor var(--tw-gradient-to-position);
  }

  .md\:to-danger {
    --tw-gradient-to: var(--color-danger-300) var(--tw-gradient-to-position);
  }

  .md\:to-danger-100 {
    --tw-gradient-to: var(--color-danger-100) var(--tw-gradient-to-position);
  }

  .md\:to-danger-200 {
    --tw-gradient-to: var(--color-danger-200) var(--tw-gradient-to-position);
  }

  .md\:to-danger-300 {
    --tw-gradient-to: var(--color-danger-300) var(--tw-gradient-to-position);
  }

  .md\:to-danger-400 {
    --tw-gradient-to: var(--color-danger-400) var(--tw-gradient-to-position);
  }

  .md\:to-divider {
    --tw-gradient-to: var(--color-divider) var(--tw-gradient-to-position);
  }

  .md\:to-gray {
    --tw-gradient-to: var(--color-gray) var(--tw-gradient-to-position);
  }

  .md\:to-info {
    --tw-gradient-to: var(--color-info-300) var(--tw-gradient-to-position);
  }

  .md\:to-info-100 {
    --tw-gradient-to: var(--color-info-100) var(--tw-gradient-to-position);
  }

  .md\:to-info-200 {
    --tw-gradient-to: var(--color-info-200) var(--tw-gradient-to-position);
  }

  .md\:to-info-300 {
    --tw-gradient-to: var(--color-info-300) var(--tw-gradient-to-position);
  }

  .md\:to-info-400 {
    --tw-gradient-to: var(--color-info-400) var(--tw-gradient-to-position);
  }

  .md\:to-primary {
    --tw-gradient-to: var(--color-primary-300) var(--tw-gradient-to-position);
  }

  .md\:to-primary-100 {
    --tw-gradient-to: var(--color-primary-100) var(--tw-gradient-to-position);
  }

  .md\:to-primary-200 {
    --tw-gradient-to: var(--color-primary-200) var(--tw-gradient-to-position);
  }

  .md\:to-primary-250 {
    --tw-gradient-to: var(--color-primary-250) var(--tw-gradient-to-position);
  }

  .md\:to-primary-300 {
    --tw-gradient-to: var(--color-primary-300) var(--tw-gradient-to-position);
  }

  .md\:to-primary-400 {
    --tw-gradient-to: var(--color-primary-400) var(--tw-gradient-to-position);
  }

  .md\:to-primary-500 {
    --tw-gradient-to: var(--color-primary-500) var(--tw-gradient-to-position);
  }

  .md\:to-primary-600 {
    --tw-gradient-to: var(--color-primary-600) var(--tw-gradient-to-position);
  }

  .md\:to-success {
    --tw-gradient-to: var(--color-success-300) var(--tw-gradient-to-position);
  }

  .md\:to-success-100 {
    --tw-gradient-to: var(--color-success-100) var(--tw-gradient-to-position);
  }

  .md\:to-success-200 {
    --tw-gradient-to: var(--color-success-200) var(--tw-gradient-to-position);
  }

  .md\:to-success-300 {
    --tw-gradient-to: var(--color-success-300) var(--tw-gradient-to-position);
  }

  .md\:to-success-400 {
    --tw-gradient-to: var(--color-success-400) var(--tw-gradient-to-position);
  }

  .md\:to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/0 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/10 {
    --tw-gradient-to: #0000001a var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/100 {
    --tw-gradient-to: #000 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/15 {
    --tw-gradient-to: #00000026 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/20 {
    --tw-gradient-to: #0003 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/25 {
    --tw-gradient-to: #00000040 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/30 {
    --tw-gradient-to: #0000004d var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/35 {
    --tw-gradient-to: #00000059 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/40 {
    --tw-gradient-to: #0006 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/45 {
    --tw-gradient-to: #00000073 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/5 {
    --tw-gradient-to: #0000000d var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/50 {
    --tw-gradient-to: #00000080 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/55 {
    --tw-gradient-to: #0000008c var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/60 {
    --tw-gradient-to: #0009 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/65 {
    --tw-gradient-to: #000000a6 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/70 {
    --tw-gradient-to: #000000b3 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/75 {
    --tw-gradient-to: #000000bf var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/80 {
    --tw-gradient-to: #000c var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/85 {
    --tw-gradient-to: #000000d9 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/90 {
    --tw-gradient-to: #000000e6 var(--tw-gradient-to-position);
  }

  .md\:to-transparent\/95 {
    --tw-gradient-to: #000000f2 var(--tw-gradient-to-position);
  }

  .md\:to-warning {
    --tw-gradient-to: var(--color-warning-300) var(--tw-gradient-to-position);
  }

  .md\:to-warning-100 {
    --tw-gradient-to: var(--color-warning-100) var(--tw-gradient-to-position);
  }

  .md\:to-warning-200 {
    --tw-gradient-to: var(--color-warning-200) var(--tw-gradient-to-position);
  }

  .md\:to-warning-300 {
    --tw-gradient-to: var(--color-warning-300) var(--tw-gradient-to-position);
  }

  .md\:to-warning-400 {
    --tw-gradient-to: var(--color-warning-400) var(--tw-gradient-to-position);
  }

  .md\:to-white {
    --tw-gradient-to: var(--color-white) var(--tw-gradient-to-position);
  }

  .md\:to-0\% {
    --tw-gradient-to-position: 0%;
  }

  .md\:to-10\% {
    --tw-gradient-to-position: 10%;
  }

  .md\:to-100\% {
    --tw-gradient-to-position: 100%;
  }

  .md\:to-15\% {
    --tw-gradient-to-position: 15%;
  }

  .md\:to-20\% {
    --tw-gradient-to-position: 20%;
  }

  .md\:to-25\% {
    --tw-gradient-to-position: 25%;
  }

  .md\:to-30\% {
    --tw-gradient-to-position: 30%;
  }

  .md\:to-35\% {
    --tw-gradient-to-position: 35%;
  }

  .md\:to-40\% {
    --tw-gradient-to-position: 40%;
  }

  .md\:to-45\% {
    --tw-gradient-to-position: 45%;
  }

  .md\:to-5\% {
    --tw-gradient-to-position: 5%;
  }

  .md\:to-50\% {
    --tw-gradient-to-position: 50%;
  }

  .md\:to-55\% {
    --tw-gradient-to-position: 55%;
  }

  .md\:to-60\% {
    --tw-gradient-to-position: 60%;
  }

  .md\:to-65\% {
    --tw-gradient-to-position: 65%;
  }

  .md\:to-70\% {
    --tw-gradient-to-position: 70%;
  }

  .md\:to-75\% {
    --tw-gradient-to-position: 75%;
  }

  .md\:to-80\% {
    --tw-gradient-to-position: 80%;
  }

  .md\:to-85\% {
    --tw-gradient-to-position: 85%;
  }

  .md\:to-90\% {
    --tw-gradient-to-position: 90%;
  }

  .md\:to-95\% {
    --tw-gradient-to-position: 95%;
  }

  .md\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .md\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .md\:box-decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .md\:box-decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .md\:bg-auto {
    background-size: auto;
  }

  .md\:bg-contain {
    background-size: contain;
  }

  .md\:bg-cover {
    background-size: cover;
  }

  .md\:bg-fixed {
    background-attachment: fixed;
  }

  .md\:bg-local {
    background-attachment: local;
  }

  .md\:bg-scroll {
    background-attachment: scroll;
  }

  .md\:bg-clip-border {
    background-clip: border-box;
  }

  .md\:bg-clip-padding {
    background-clip: padding-box;
  }

  .md\:bg-clip-content {
    background-clip: content-box;
  }

  .md\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .md\:bg-bottom {
    background-position: bottom;
  }

  .md\:bg-center {
    background-position: center;
  }

  .md\:bg-left {
    background-position: 0;
  }

  .md\:bg-left-bottom {
    background-position: 0 100%;
  }

  .md\:bg-left-top {
    background-position: 0 0;
  }

  .md\:bg-right {
    background-position: 100%;
  }

  .md\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .md\:bg-right-top {
    background-position: 100% 0;
  }

  .md\:bg-top {
    background-position: top;
  }

  .md\:bg-repeat {
    background-repeat: repeat;
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .md\:bg-repeat-round {
    background-repeat: round;
  }

  .md\:bg-repeat-space {
    background-repeat: space;
  }

  .md\:bg-origin-border {
    background-origin: border-box;
  }

  .md\:bg-origin-padding {
    background-origin: padding-box;
  }

  .md\:bg-origin-content {
    background-origin: content-box;
  }

  .md\:fill-black {
    fill: var(--color-black);
  }

  .md\:fill-current {
    fill: currentColor;
  }

  .md\:fill-danger {
    fill: var(--color-danger-300);
  }

  .md\:fill-danger-100 {
    fill: var(--color-danger-100);
  }

  .md\:fill-danger-200 {
    fill: var(--color-danger-200);
  }

  .md\:fill-danger-300 {
    fill: var(--color-danger-300);
  }

  .md\:fill-danger-400 {
    fill: var(--color-danger-400);
  }

  .md\:fill-divider {
    fill: var(--color-divider);
  }

  .md\:fill-gray {
    fill: var(--color-gray);
  }

  .md\:fill-info {
    fill: var(--color-info-300);
  }

  .md\:fill-info-100 {
    fill: var(--color-info-100);
  }

  .md\:fill-info-200 {
    fill: var(--color-info-200);
  }

  .md\:fill-info-300 {
    fill: var(--color-info-300);
  }

  .md\:fill-info-400 {
    fill: var(--color-info-400);
  }

  .md\:fill-none {
    fill: none;
  }

  .md\:fill-primary {
    fill: var(--color-primary-300);
  }

  .md\:fill-primary-100 {
    fill: var(--color-primary-100);
  }

  .md\:fill-primary-200 {
    fill: var(--color-primary-200);
  }

  .md\:fill-primary-250 {
    fill: var(--color-primary-250);
  }

  .md\:fill-primary-300 {
    fill: var(--color-primary-300);
  }

  .md\:fill-primary-400 {
    fill: var(--color-primary-400);
  }

  .md\:fill-primary-500 {
    fill: var(--color-primary-500);
  }

  .md\:fill-primary-600 {
    fill: var(--color-primary-600);
  }

  .md\:fill-success {
    fill: var(--color-success-300);
  }

  .md\:fill-success-100 {
    fill: var(--color-success-100);
  }

  .md\:fill-success-200 {
    fill: var(--color-success-200);
  }

  .md\:fill-success-300 {
    fill: var(--color-success-300);
  }

  .md\:fill-success-400 {
    fill: var(--color-success-400);
  }

  .md\:fill-transparent, .md\:fill-transparent\/0 {
    fill: #0000;
  }

  .md\:fill-transparent\/10 {
    fill: #0000001a;
  }

  .md\:fill-transparent\/100 {
    fill: #000;
  }

  .md\:fill-transparent\/15 {
    fill: #00000026;
  }

  .md\:fill-transparent\/20 {
    fill: #0003;
  }

  .md\:fill-transparent\/25 {
    fill: #00000040;
  }

  .md\:fill-transparent\/30 {
    fill: #0000004d;
  }

  .md\:fill-transparent\/35 {
    fill: #00000059;
  }

  .md\:fill-transparent\/40 {
    fill: #0006;
  }

  .md\:fill-transparent\/45 {
    fill: #00000073;
  }

  .md\:fill-transparent\/5 {
    fill: #0000000d;
  }

  .md\:fill-transparent\/50 {
    fill: #00000080;
  }

  .md\:fill-transparent\/55 {
    fill: #0000008c;
  }

  .md\:fill-transparent\/60 {
    fill: #0009;
  }

  .md\:fill-transparent\/65 {
    fill: #000000a6;
  }

  .md\:fill-transparent\/70 {
    fill: #000000b3;
  }

  .md\:fill-transparent\/75 {
    fill: #000000bf;
  }

  .md\:fill-transparent\/80 {
    fill: #000c;
  }

  .md\:fill-transparent\/85 {
    fill: #000000d9;
  }

  .md\:fill-transparent\/90 {
    fill: #000000e6;
  }

  .md\:fill-transparent\/95 {
    fill: #000000f2;
  }

  .md\:fill-warning {
    fill: var(--color-warning-300);
  }

  .md\:fill-warning-100 {
    fill: var(--color-warning-100);
  }

  .md\:fill-warning-200 {
    fill: var(--color-warning-200);
  }

  .md\:fill-warning-300 {
    fill: var(--color-warning-300);
  }

  .md\:fill-warning-400 {
    fill: var(--color-warning-400);
  }

  .md\:fill-white {
    fill: var(--color-white);
  }

  .md\:stroke-black {
    stroke: var(--color-black);
  }

  .md\:stroke-current {
    stroke: currentColor;
  }

  .md\:stroke-danger {
    stroke: var(--color-danger-300);
  }

  .md\:stroke-danger-100 {
    stroke: var(--color-danger-100);
  }

  .md\:stroke-danger-200 {
    stroke: var(--color-danger-200);
  }

  .md\:stroke-danger-300 {
    stroke: var(--color-danger-300);
  }

  .md\:stroke-danger-400 {
    stroke: var(--color-danger-400);
  }

  .md\:stroke-divider {
    stroke: var(--color-divider);
  }

  .md\:stroke-gray {
    stroke: var(--color-gray);
  }

  .md\:stroke-info {
    stroke: var(--color-info-300);
  }

  .md\:stroke-info-100 {
    stroke: var(--color-info-100);
  }

  .md\:stroke-info-200 {
    stroke: var(--color-info-200);
  }

  .md\:stroke-info-300 {
    stroke: var(--color-info-300);
  }

  .md\:stroke-info-400 {
    stroke: var(--color-info-400);
  }

  .md\:stroke-none {
    stroke: none;
  }

  .md\:stroke-primary {
    stroke: var(--color-primary-300);
  }

  .md\:stroke-primary-100 {
    stroke: var(--color-primary-100);
  }

  .md\:stroke-primary-200 {
    stroke: var(--color-primary-200);
  }

  .md\:stroke-primary-250 {
    stroke: var(--color-primary-250);
  }

  .md\:stroke-primary-300 {
    stroke: var(--color-primary-300);
  }

  .md\:stroke-primary-400 {
    stroke: var(--color-primary-400);
  }

  .md\:stroke-primary-500 {
    stroke: var(--color-primary-500);
  }

  .md\:stroke-primary-600 {
    stroke: var(--color-primary-600);
  }

  .md\:stroke-success {
    stroke: var(--color-success-300);
  }

  .md\:stroke-success-100 {
    stroke: var(--color-success-100);
  }

  .md\:stroke-success-200 {
    stroke: var(--color-success-200);
  }

  .md\:stroke-success-300 {
    stroke: var(--color-success-300);
  }

  .md\:stroke-success-400 {
    stroke: var(--color-success-400);
  }

  .md\:stroke-transparent, .md\:stroke-transparent\/0 {
    stroke: #0000;
  }

  .md\:stroke-transparent\/10 {
    stroke: #0000001a;
  }

  .md\:stroke-transparent\/100 {
    stroke: #000;
  }

  .md\:stroke-transparent\/15 {
    stroke: #00000026;
  }

  .md\:stroke-transparent\/20 {
    stroke: #0003;
  }

  .md\:stroke-transparent\/25 {
    stroke: #00000040;
  }

  .md\:stroke-transparent\/30 {
    stroke: #0000004d;
  }

  .md\:stroke-transparent\/35 {
    stroke: #00000059;
  }

  .md\:stroke-transparent\/40 {
    stroke: #0006;
  }

  .md\:stroke-transparent\/45 {
    stroke: #00000073;
  }

  .md\:stroke-transparent\/5 {
    stroke: #0000000d;
  }

  .md\:stroke-transparent\/50 {
    stroke: #00000080;
  }

  .md\:stroke-transparent\/55 {
    stroke: #0000008c;
  }

  .md\:stroke-transparent\/60 {
    stroke: #0009;
  }

  .md\:stroke-transparent\/65 {
    stroke: #000000a6;
  }

  .md\:stroke-transparent\/70 {
    stroke: #000000b3;
  }

  .md\:stroke-transparent\/75 {
    stroke: #000000bf;
  }

  .md\:stroke-transparent\/80 {
    stroke: #000c;
  }

  .md\:stroke-transparent\/85 {
    stroke: #000000d9;
  }

  .md\:stroke-transparent\/90 {
    stroke: #000000e6;
  }

  .md\:stroke-transparent\/95 {
    stroke: #000000f2;
  }

  .md\:stroke-warning {
    stroke: var(--color-warning-300);
  }

  .md\:stroke-warning-100 {
    stroke: var(--color-warning-100);
  }

  .md\:stroke-warning-200 {
    stroke: var(--color-warning-200);
  }

  .md\:stroke-warning-300 {
    stroke: var(--color-warning-300);
  }

  .md\:stroke-warning-400 {
    stroke: var(--color-warning-400);
  }

  .md\:stroke-white {
    stroke: var(--color-white);
  }

  .md\:stroke-0 {
    stroke-width: 0;
  }

  .md\:stroke-1 {
    stroke-width: 1px;
  }

  .md\:stroke-2 {
    stroke-width: 2px;
  }

  .md\:object-contain {
    object-fit: contain;
  }

  .md\:object-cover {
    object-fit: cover;
  }

  .md\:object-fill {
    object-fit: fill;
  }

  .md\:object-none {
    object-fit: none;
  }

  .md\:object-scale-down {
    object-fit: scale-down;
  }

  .md\:object-bottom {
    object-position: bottom;
  }

  .md\:object-center {
    object-position: center;
  }

  .md\:object-left {
    object-position: left;
  }

  .md\:object-left-bottom {
    object-position: left bottom;
  }

  .md\:object-left-top {
    object-position: left top;
  }

  .md\:object-right {
    object-position: right;
  }

  .md\:object-right-bottom {
    object-position: right bottom;
  }

  .md\:object-right-top {
    object-position: right top;
  }

  .md\:object-top {
    object-position: top;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-0\.5 {
    padding: .125rem;
  }

  .md\:p-1 {
    padding: .25rem;
  }

  .md\:p-1\.5 {
    padding: .375rem;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-11 {
    padding: 2.75rem;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:p-14 {
    padding: 3.5rem;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:p-2 {
    padding: .5rem;
  }

  .md\:p-2\.5 {
    padding: .625rem;
  }

  .md\:p-20 {
    padding: 5rem;
  }

  .md\:p-24 {
    padding: 6rem;
  }

  .md\:p-28 {
    padding: 7rem;
  }

  .md\:p-3 {
    padding: .75rem;
  }

  .md\:p-3\.5 {
    padding: .875rem;
  }

  .md\:p-32 {
    padding: 8rem;
  }

  .md\:p-36 {
    padding: 9rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-40 {
    padding: 10rem;
  }

  .md\:p-44 {
    padding: 11rem;
  }

  .md\:p-48 {
    padding: 12rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:p-52 {
    padding: 13rem;
  }

  .md\:p-56 {
    padding: 14rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:p-60 {
    padding: 15rem;
  }

  .md\:p-64 {
    padding: 16rem;
  }

  .md\:p-7 {
    padding: 1.75rem;
  }

  .md\:p-72 {
    padding: 18rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:p-80 {
    padding: 20rem;
  }

  .md\:p-9 {
    padding: 2.25rem;
  }

  .md\:p-96 {
    padding: 24rem;
  }

  .md\:p-px {
    padding: 1px;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .md\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .md\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .md\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .md\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .md\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .md\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .md\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .md\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .md\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .md\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .md\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .md\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .md\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .md\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .md\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .md\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .md\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .md\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .md\:pb-1 {
    padding-bottom: .25rem;
  }

  .md\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .md\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .md\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .md\:pb-12 {
    padding-bottom: 3rem;
  }

  .md\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pb-2 {
    padding-bottom: .5rem;
  }

  .md\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pb-24 {
    padding-bottom: 6rem;
  }

  .md\:pb-28 {
    padding-bottom: 7rem;
  }

  .md\:pb-3 {
    padding-bottom: .75rem;
  }

  .md\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .md\:pb-32 {
    padding-bottom: 8rem;
  }

  .md\:pb-36 {
    padding-bottom: 9rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:pb-40 {
    padding-bottom: 10rem;
  }

  .md\:pb-44 {
    padding-bottom: 11rem;
  }

  .md\:pb-48 {
    padding-bottom: 12rem;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:pb-52 {
    padding-bottom: 13rem;
  }

  .md\:pb-56 {
    padding-bottom: 14rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:pb-60 {
    padding-bottom: 15rem;
  }

  .md\:pb-64 {
    padding-bottom: 16rem;
  }

  .md\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .md\:pb-72 {
    padding-bottom: 18rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:pb-80 {
    padding-bottom: 20rem;
  }

  .md\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .md\:pb-96 {
    padding-bottom: 24rem;
  }

  .md\:pb-px {
    padding-bottom: 1px;
  }

  .md\:pe-0 {
    padding-inline-end: 0;
  }

  .md\:pe-0\.5 {
    padding-inline-end: .125rem;
  }

  .md\:pe-1 {
    padding-inline-end: .25rem;
  }

  .md\:pe-1\.5 {
    padding-inline-end: .375rem;
  }

  .md\:pe-10 {
    padding-inline-end: 2.5rem;
  }

  .md\:pe-11 {
    padding-inline-end: 2.75rem;
  }

  .md\:pe-12 {
    padding-inline-end: 3rem;
  }

  .md\:pe-14 {
    padding-inline-end: 3.5rem;
  }

  .md\:pe-16 {
    padding-inline-end: 4rem;
  }

  .md\:pe-2 {
    padding-inline-end: .5rem;
  }

  .md\:pe-2\.5 {
    padding-inline-end: .625rem;
  }

  .md\:pe-20 {
    padding-inline-end: 5rem;
  }

  .md\:pe-24 {
    padding-inline-end: 6rem;
  }

  .md\:pe-28 {
    padding-inline-end: 7rem;
  }

  .md\:pe-3 {
    padding-inline-end: .75rem;
  }

  .md\:pe-3\.5 {
    padding-inline-end: .875rem;
  }

  .md\:pe-32 {
    padding-inline-end: 8rem;
  }

  .md\:pe-36 {
    padding-inline-end: 9rem;
  }

  .md\:pe-4 {
    padding-inline-end: 1rem;
  }

  .md\:pe-40 {
    padding-inline-end: 10rem;
  }

  .md\:pe-44 {
    padding-inline-end: 11rem;
  }

  .md\:pe-48 {
    padding-inline-end: 12rem;
  }

  .md\:pe-5 {
    padding-inline-end: 1.25rem;
  }

  .md\:pe-52 {
    padding-inline-end: 13rem;
  }

  .md\:pe-56 {
    padding-inline-end: 14rem;
  }

  .md\:pe-6 {
    padding-inline-end: 1.5rem;
  }

  .md\:pe-60 {
    padding-inline-end: 15rem;
  }

  .md\:pe-64 {
    padding-inline-end: 16rem;
  }

  .md\:pe-7 {
    padding-inline-end: 1.75rem;
  }

  .md\:pe-72 {
    padding-inline-end: 18rem;
  }

  .md\:pe-8 {
    padding-inline-end: 2rem;
  }

  .md\:pe-80 {
    padding-inline-end: 20rem;
  }

  .md\:pe-9 {
    padding-inline-end: 2.25rem;
  }

  .md\:pe-96 {
    padding-inline-end: 24rem;
  }

  .md\:pe-px {
    padding-inline-end: 1px;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:pl-0\.5 {
    padding-left: .125rem;
  }

  .md\:pl-1 {
    padding-left: .25rem;
  }

  .md\:pl-1\.5 {
    padding-left: .375rem;
  }

  .md\:pl-10 {
    padding-left: 2.5rem;
  }

  .md\:pl-11 {
    padding-left: 2.75rem;
  }

  .md\:pl-12 {
    padding-left: 3rem;
  }

  .md\:pl-14 {
    padding-left: 3.5rem;
  }

  .md\:pl-16 {
    padding-left: 4rem;
  }

  .md\:pl-2 {
    padding-left: .5rem;
  }

  .md\:pl-2\.5 {
    padding-left: .625rem;
  }

  .md\:pl-20 {
    padding-left: 5rem;
  }

  .md\:pl-24 {
    padding-left: 6rem;
  }

  .md\:pl-28 {
    padding-left: 7rem;
  }

  .md\:pl-3 {
    padding-left: .75rem;
  }

  .md\:pl-3\.5 {
    padding-left: .875rem;
  }

  .md\:pl-32 {
    padding-left: 8rem;
  }

  .md\:pl-36 {
    padding-left: 9rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:pl-40 {
    padding-left: 10rem;
  }

  .md\:pl-44 {
    padding-left: 11rem;
  }

  .md\:pl-48 {
    padding-left: 12rem;
  }

  .md\:pl-5 {
    padding-left: 1.25rem;
  }

  .md\:pl-52 {
    padding-left: 13rem;
  }

  .md\:pl-56 {
    padding-left: 14rem;
  }

  .md\:pl-6 {
    padding-left: 1.5rem;
  }

  .md\:pl-60 {
    padding-left: 15rem;
  }

  .md\:pl-64 {
    padding-left: 16rem;
  }

  .md\:pl-7 {
    padding-left: 1.75rem;
  }

  .md\:pl-72 {
    padding-left: 18rem;
  }

  .md\:pl-8 {
    padding-left: 2rem;
  }

  .md\:pl-80 {
    padding-left: 20rem;
  }

  .md\:pl-9 {
    padding-left: 2.25rem;
  }

  .md\:pl-96 {
    padding-left: 24rem;
  }

  .md\:pl-px {
    padding-left: 1px;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pr-0\.5 {
    padding-right: .125rem;
  }

  .md\:pr-1 {
    padding-right: .25rem;
  }

  .md\:pr-1\.5 {
    padding-right: .375rem;
  }

  .md\:pr-10 {
    padding-right: 2.5rem;
  }

  .md\:pr-11 {
    padding-right: 2.75rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pr-14 {
    padding-right: 3.5rem;
  }

  .md\:pr-16 {
    padding-right: 4rem;
  }

  .md\:pr-2 {
    padding-right: .5rem;
  }

  .md\:pr-2\.5 {
    padding-right: .625rem;
  }

  .md\:pr-20 {
    padding-right: 5rem;
  }

  .md\:pr-24 {
    padding-right: 6rem;
  }

  .md\:pr-28 {
    padding-right: 7rem;
  }

  .md\:pr-3 {
    padding-right: .75rem;
  }

  .md\:pr-3\.5 {
    padding-right: .875rem;
  }

  .md\:pr-32 {
    padding-right: 8rem;
  }

  .md\:pr-36 {
    padding-right: 9rem;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:pr-40 {
    padding-right: 10rem;
  }

  .md\:pr-44 {
    padding-right: 11rem;
  }

  .md\:pr-48 {
    padding-right: 12rem;
  }

  .md\:pr-5 {
    padding-right: 1.25rem;
  }

  .md\:pr-52 {
    padding-right: 13rem;
  }

  .md\:pr-56 {
    padding-right: 14rem;
  }

  .md\:pr-6 {
    padding-right: 1.5rem;
  }

  .md\:pr-60 {
    padding-right: 15rem;
  }

  .md\:pr-64 {
    padding-right: 16rem;
  }

  .md\:pr-7 {
    padding-right: 1.75rem;
  }

  .md\:pr-72 {
    padding-right: 18rem;
  }

  .md\:pr-8 {
    padding-right: 2rem;
  }

  .md\:pr-80 {
    padding-right: 20rem;
  }

  .md\:pr-9 {
    padding-right: 2.25rem;
  }

  .md\:pr-96 {
    padding-right: 24rem;
  }

  .md\:pr-px {
    padding-right: 1px;
  }

  .md\:ps-0 {
    padding-inline-start: 0;
  }

  .md\:ps-0\.5 {
    padding-inline-start: .125rem;
  }

  .md\:ps-1 {
    padding-inline-start: .25rem;
  }

  .md\:ps-1\.5 {
    padding-inline-start: .375rem;
  }

  .md\:ps-10 {
    padding-inline-start: 2.5rem;
  }

  .md\:ps-11 {
    padding-inline-start: 2.75rem;
  }

  .md\:ps-12 {
    padding-inline-start: 3rem;
  }

  .md\:ps-14 {
    padding-inline-start: 3.5rem;
  }

  .md\:ps-16 {
    padding-inline-start: 4rem;
  }

  .md\:ps-2 {
    padding-inline-start: .5rem;
  }

  .md\:ps-2\.5 {
    padding-inline-start: .625rem;
  }

  .md\:ps-20 {
    padding-inline-start: 5rem;
  }

  .md\:ps-24 {
    padding-inline-start: 6rem;
  }

  .md\:ps-28 {
    padding-inline-start: 7rem;
  }

  .md\:ps-3 {
    padding-inline-start: .75rem;
  }

  .md\:ps-3\.5 {
    padding-inline-start: .875rem;
  }

  .md\:ps-32 {
    padding-inline-start: 8rem;
  }

  .md\:ps-36 {
    padding-inline-start: 9rem;
  }

  .md\:ps-4 {
    padding-inline-start: 1rem;
  }

  .md\:ps-40 {
    padding-inline-start: 10rem;
  }

  .md\:ps-44 {
    padding-inline-start: 11rem;
  }

  .md\:ps-48 {
    padding-inline-start: 12rem;
  }

  .md\:ps-5 {
    padding-inline-start: 1.25rem;
  }

  .md\:ps-52 {
    padding-inline-start: 13rem;
  }

  .md\:ps-56 {
    padding-inline-start: 14rem;
  }

  .md\:ps-6 {
    padding-inline-start: 1.5rem;
  }

  .md\:ps-60 {
    padding-inline-start: 15rem;
  }

  .md\:ps-64 {
    padding-inline-start: 16rem;
  }

  .md\:ps-7 {
    padding-inline-start: 1.75rem;
  }

  .md\:ps-72 {
    padding-inline-start: 18rem;
  }

  .md\:ps-8 {
    padding-inline-start: 2rem;
  }

  .md\:ps-80 {
    padding-inline-start: 20rem;
  }

  .md\:ps-9 {
    padding-inline-start: 2.25rem;
  }

  .md\:ps-96 {
    padding-inline-start: 24rem;
  }

  .md\:ps-px {
    padding-inline-start: 1px;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pt-0\.5 {
    padding-top: .125rem;
  }

  .md\:pt-1 {
    padding-top: .25rem;
  }

  .md\:pt-1\.5 {
    padding-top: .375rem;
  }

  .md\:pt-10 {
    padding-top: 2.5rem;
  }

  .md\:pt-11 {
    padding-top: 2.75rem;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:pt-14 {
    padding-top: 3.5rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pt-2 {
    padding-top: .5rem;
  }

  .md\:pt-2\.5 {
    padding-top: .625rem;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:pt-24 {
    padding-top: 6rem;
  }

  .md\:pt-28 {
    padding-top: 7rem;
  }

  .md\:pt-3 {
    padding-top: .75rem;
  }

  .md\:pt-3\.5 {
    padding-top: .875rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pt-36 {
    padding-top: 9rem;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:pt-40 {
    padding-top: 10rem;
  }

  .md\:pt-44 {
    padding-top: 11rem;
  }

  .md\:pt-48 {
    padding-top: 12rem;
  }

  .md\:pt-5 {
    padding-top: 1.25rem;
  }

  .md\:pt-52 {
    padding-top: 13rem;
  }

  .md\:pt-56 {
    padding-top: 14rem;
  }

  .md\:pt-6 {
    padding-top: 1.5rem;
  }

  .md\:pt-60 {
    padding-top: 15rem;
  }

  .md\:pt-64 {
    padding-top: 16rem;
  }

  .md\:pt-7 {
    padding-top: 1.75rem;
  }

  .md\:pt-72 {
    padding-top: 18rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:pt-80 {
    padding-top: 20rem;
  }

  .md\:pt-9 {
    padding-top: 2.25rem;
  }

  .md\:pt-96 {
    padding-top: 24rem;
  }

  .md\:pt-px {
    padding-top: 1px;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-justify {
    text-align: justify;
  }

  .md\:text-start {
    text-align: start;
  }

  .md\:text-end {
    text-align: end;
  }

  .md\:-indent-0 {
    text-indent: 0;
  }

  .md\:-indent-0\.5 {
    text-indent: -.125rem;
  }

  .md\:-indent-1 {
    text-indent: -.25rem;
  }

  .md\:-indent-1\.5 {
    text-indent: -.375rem;
  }

  .md\:-indent-10 {
    text-indent: -2.5rem;
  }

  .md\:-indent-11 {
    text-indent: -2.75rem;
  }

  .md\:-indent-12 {
    text-indent: -3rem;
  }

  .md\:-indent-14 {
    text-indent: -3.5rem;
  }

  .md\:-indent-16 {
    text-indent: -4rem;
  }

  .md\:-indent-2 {
    text-indent: -.5rem;
  }

  .md\:-indent-2\.5 {
    text-indent: -.625rem;
  }

  .md\:-indent-20 {
    text-indent: -5rem;
  }

  .md\:-indent-24 {
    text-indent: -6rem;
  }

  .md\:-indent-28 {
    text-indent: -7rem;
  }

  .md\:-indent-3 {
    text-indent: -.75rem;
  }

  .md\:-indent-3\.5 {
    text-indent: -.875rem;
  }

  .md\:-indent-32 {
    text-indent: -8rem;
  }

  .md\:-indent-36 {
    text-indent: -9rem;
  }

  .md\:-indent-4 {
    text-indent: -1rem;
  }

  .md\:-indent-40 {
    text-indent: -10rem;
  }

  .md\:-indent-44 {
    text-indent: -11rem;
  }

  .md\:-indent-48 {
    text-indent: -12rem;
  }

  .md\:-indent-5 {
    text-indent: -1.25rem;
  }

  .md\:-indent-52 {
    text-indent: -13rem;
  }

  .md\:-indent-56 {
    text-indent: -14rem;
  }

  .md\:-indent-6 {
    text-indent: -1.5rem;
  }

  .md\:-indent-60 {
    text-indent: -15rem;
  }

  .md\:-indent-64 {
    text-indent: -16rem;
  }

  .md\:-indent-7 {
    text-indent: -1.75rem;
  }

  .md\:-indent-72 {
    text-indent: -18rem;
  }

  .md\:-indent-8 {
    text-indent: -2rem;
  }

  .md\:-indent-80 {
    text-indent: -20rem;
  }

  .md\:-indent-9 {
    text-indent: -2.25rem;
  }

  .md\:-indent-96 {
    text-indent: -24rem;
  }

  .md\:-indent-px {
    text-indent: -1px;
  }

  .md\:indent-0 {
    text-indent: 0;
  }

  .md\:indent-0\.5 {
    text-indent: .125rem;
  }

  .md\:indent-1 {
    text-indent: .25rem;
  }

  .md\:indent-1\.5 {
    text-indent: .375rem;
  }

  .md\:indent-10 {
    text-indent: 2.5rem;
  }

  .md\:indent-11 {
    text-indent: 2.75rem;
  }

  .md\:indent-12 {
    text-indent: 3rem;
  }

  .md\:indent-14 {
    text-indent: 3.5rem;
  }

  .md\:indent-16 {
    text-indent: 4rem;
  }

  .md\:indent-2 {
    text-indent: .5rem;
  }

  .md\:indent-2\.5 {
    text-indent: .625rem;
  }

  .md\:indent-20 {
    text-indent: 5rem;
  }

  .md\:indent-24 {
    text-indent: 6rem;
  }

  .md\:indent-28 {
    text-indent: 7rem;
  }

  .md\:indent-3 {
    text-indent: .75rem;
  }

  .md\:indent-3\.5 {
    text-indent: .875rem;
  }

  .md\:indent-32 {
    text-indent: 8rem;
  }

  .md\:indent-36 {
    text-indent: 9rem;
  }

  .md\:indent-4 {
    text-indent: 1rem;
  }

  .md\:indent-40 {
    text-indent: 10rem;
  }

  .md\:indent-44 {
    text-indent: 11rem;
  }

  .md\:indent-48 {
    text-indent: 12rem;
  }

  .md\:indent-5 {
    text-indent: 1.25rem;
  }

  .md\:indent-52 {
    text-indent: 13rem;
  }

  .md\:indent-56 {
    text-indent: 14rem;
  }

  .md\:indent-6 {
    text-indent: 1.5rem;
  }

  .md\:indent-60 {
    text-indent: 15rem;
  }

  .md\:indent-64 {
    text-indent: 16rem;
  }

  .md\:indent-7 {
    text-indent: 1.75rem;
  }

  .md\:indent-72 {
    text-indent: 18rem;
  }

  .md\:indent-8 {
    text-indent: 2rem;
  }

  .md\:indent-80 {
    text-indent: 20rem;
  }

  .md\:indent-9 {
    text-indent: 2.25rem;
  }

  .md\:indent-96 {
    text-indent: 24rem;
  }

  .md\:indent-px {
    text-indent: 1px;
  }

  .md\:align-baseline {
    vertical-align: baseline;
  }

  .md\:align-top {
    vertical-align: top;
  }

  .md\:align-middle {
    vertical-align: middle;
  }

  .md\:align-bottom {
    vertical-align: bottom;
  }

  .md\:align-text-top {
    vertical-align: text-top;
  }

  .md\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .md\:align-sub {
    vertical-align: sub;
  }

  .md\:align-super {
    vertical-align: super;
  }

  .md\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .md\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .md\:font-serif {
    font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
  }

  .md\:text-6xl {
    font-size: 4rem;
  }

  .md\:text-base {
    font-size: 1rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
  }

  .md\:text-sm {
    font-size: .875rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
  }

  .md\:text-xs {
    font-size: .75rem;
  }

  .md\:font-black {
    font-weight: 900;
  }

  .md\:font-bold {
    font-weight: 700;
  }

  .md\:font-extrabold {
    font-weight: 800;
  }

  .md\:font-extralight {
    font-weight: 200;
  }

  .md\:font-light {
    font-weight: 300;
  }

  .md\:font-medium {
    font-weight: 500;
  }

  .md\:font-normal {
    font-weight: 400;
  }

  .md\:font-semibold {
    font-weight: 600;
  }

  .md\:font-thin {
    font-weight: 100;
  }

  .md\:uppercase {
    text-transform: uppercase;
  }

  .md\:lowercase {
    text-transform: lowercase;
  }

  .md\:capitalize {
    text-transform: capitalize;
  }

  .md\:normal-case {
    text-transform: none;
  }

  .md\:italic {
    font-style: italic;
  }

  .md\:not-italic {
    font-style: normal;
  }

  .md\:normal-nums {
    font-variant-numeric: normal;
  }

  .md\:ordinal {
    --tw-ordinal: ordinal;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:lining-nums {
    --tw-numeric-figure: lining-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:leading-10 {
    line-height: 2.5rem;
  }

  .md\:leading-3 {
    line-height: .75rem;
  }

  .md\:leading-4 {
    line-height: 1rem;
  }

  .md\:leading-5 {
    line-height: 1.25rem;
  }

  .md\:leading-6 {
    line-height: 1.5rem;
  }

  .md\:leading-7 {
    line-height: 1.75rem;
  }

  .md\:leading-8 {
    line-height: 2rem;
  }

  .md\:leading-9 {
    line-height: 2.25rem;
  }

  .md\:leading-loose {
    line-height: 2;
  }

  .md\:leading-none {
    line-height: 1;
  }

  .md\:leading-normal {
    line-height: 1.5;
  }

  .md\:leading-relaxed {
    line-height: 1.625;
  }

  .md\:leading-snug {
    line-height: 1.375;
  }

  .md\:leading-tight {
    line-height: 1.25;
  }

  .md\:-tracking-normal {
    letter-spacing: 0;
  }

  .md\:-tracking-tight {
    letter-spacing: .025em;
  }

  .md\:-tracking-tighter {
    letter-spacing: .05em;
  }

  .md\:-tracking-wide {
    letter-spacing: -.025em;
  }

  .md\:-tracking-wider {
    letter-spacing: -.05em;
  }

  .md\:-tracking-widest {
    letter-spacing: -.1em;
  }

  .md\:tracking-normal {
    letter-spacing: 0;
  }

  .md\:tracking-tight {
    letter-spacing: -.025em;
  }

  .md\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .md\:tracking-wide {
    letter-spacing: .025em;
  }

  .md\:tracking-wider {
    letter-spacing: .05em;
  }

  .md\:tracking-widest {
    letter-spacing: .1em;
  }

  .md\:text-black {
    color: var(--color-black);
  }

  .md\:text-current {
    color: currentColor;
  }

  .md\:text-danger {
    color: var(--color-danger-300);
  }

  .md\:text-danger-100 {
    color: var(--color-danger-100);
  }

  .md\:text-danger-200 {
    color: var(--color-danger-200);
  }

  .md\:text-danger-300 {
    color: var(--color-danger-300);
  }

  .md\:text-danger-400 {
    color: var(--color-danger-400);
  }

  .md\:text-divider {
    color: var(--color-divider);
  }

  .md\:text-gray {
    color: var(--color-gray);
  }

  .md\:text-info {
    color: var(--color-info-300);
  }

  .md\:text-info-100 {
    color: var(--color-info-100);
  }

  .md\:text-info-200 {
    color: var(--color-info-200);
  }

  .md\:text-info-300 {
    color: var(--color-info-300);
  }

  .md\:text-info-400 {
    color: var(--color-info-400);
  }

  .md\:text-primary {
    color: var(--color-primary-300);
  }

  .md\:text-primary-100 {
    color: var(--color-primary-100);
  }

  .md\:text-primary-200 {
    color: var(--color-primary-200);
  }

  .md\:text-primary-250 {
    color: var(--color-primary-250);
  }

  .md\:text-primary-300 {
    color: var(--color-primary-300);
  }

  .md\:text-primary-400 {
    color: var(--color-primary-400);
  }

  .md\:text-primary-500 {
    color: var(--color-primary-500);
  }

  .md\:text-primary-600 {
    color: var(--color-primary-600);
  }

  .md\:text-success {
    color: var(--color-success-300);
  }

  .md\:text-success-100 {
    color: var(--color-success-100);
  }

  .md\:text-success-200 {
    color: var(--color-success-200);
  }

  .md\:text-success-300 {
    color: var(--color-success-300);
  }

  .md\:text-success-400 {
    color: var(--color-success-400);
  }

  .md\:text-transparent, .md\:text-transparent\/0 {
    color: #0000;
  }

  .md\:text-transparent\/10 {
    color: #0000001a;
  }

  .md\:text-transparent\/100 {
    color: #000;
  }

  .md\:text-transparent\/15 {
    color: #00000026;
  }

  .md\:text-transparent\/20 {
    color: #0003;
  }

  .md\:text-transparent\/25 {
    color: #00000040;
  }

  .md\:text-transparent\/30 {
    color: #0000004d;
  }

  .md\:text-transparent\/35 {
    color: #00000059;
  }

  .md\:text-transparent\/40 {
    color: #0006;
  }

  .md\:text-transparent\/45 {
    color: #00000073;
  }

  .md\:text-transparent\/5 {
    color: #0000000d;
  }

  .md\:text-transparent\/50 {
    color: #00000080;
  }

  .md\:text-transparent\/55 {
    color: #0000008c;
  }

  .md\:text-transparent\/60 {
    color: #0009;
  }

  .md\:text-transparent\/65 {
    color: #000000a6;
  }

  .md\:text-transparent\/70 {
    color: #000000b3;
  }

  .md\:text-transparent\/75 {
    color: #000000bf;
  }

  .md\:text-transparent\/80 {
    color: #000c;
  }

  .md\:text-transparent\/85 {
    color: #000000d9;
  }

  .md\:text-transparent\/90 {
    color: #000000e6;
  }

  .md\:text-transparent\/95 {
    color: #000000f2;
  }

  .md\:text-warning {
    color: var(--color-warning-300);
  }

  .md\:text-warning-100 {
    color: var(--color-warning-100);
  }

  .md\:text-warning-200 {
    color: var(--color-warning-200);
  }

  .md\:text-warning-300 {
    color: var(--color-warning-300);
  }

  .md\:text-warning-400 {
    color: var(--color-warning-400);
  }

  .md\:text-white {
    color: var(--color-white);
  }

  .md\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .md\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .md\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .md\:text-opacity-15 {
    --tw-text-opacity: .15;
  }

  .md\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .md\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .md\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .md\:text-opacity-35 {
    --tw-text-opacity: .35;
  }

  .md\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .md\:text-opacity-45 {
    --tw-text-opacity: .45;
  }

  .md\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .md\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .md\:text-opacity-55 {
    --tw-text-opacity: .55;
  }

  .md\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .md\:text-opacity-65 {
    --tw-text-opacity: .65;
  }

  .md\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .md\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .md\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .md\:text-opacity-85 {
    --tw-text-opacity: .85;
  }

  .md\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .md\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .md\:underline {
    text-decoration-line: underline;
  }

  .md\:overline {
    text-decoration-line: overline;
  }

  .md\:line-through {
    text-decoration-line: line-through;
  }

  .md\:no-underline {
    text-decoration-line: none;
  }

  .md\:decoration-black {
    -webkit-text-decoration-color: var(--color-black);
    text-decoration-color: var(--color-black);
  }

  .md\:decoration-current {
    text-decoration-color: currentColor;
  }

  .md\:decoration-danger {
    -webkit-text-decoration-color: var(--color-danger-300);
    text-decoration-color: var(--color-danger-300);
  }

  .md\:decoration-danger-100 {
    -webkit-text-decoration-color: var(--color-danger-100);
    text-decoration-color: var(--color-danger-100);
  }

  .md\:decoration-danger-200 {
    -webkit-text-decoration-color: var(--color-danger-200);
    text-decoration-color: var(--color-danger-200);
  }

  .md\:decoration-danger-300 {
    -webkit-text-decoration-color: var(--color-danger-300);
    text-decoration-color: var(--color-danger-300);
  }

  .md\:decoration-danger-400 {
    -webkit-text-decoration-color: var(--color-danger-400);
    text-decoration-color: var(--color-danger-400);
  }

  .md\:decoration-divider {
    -webkit-text-decoration-color: var(--color-divider);
    text-decoration-color: var(--color-divider);
  }

  .md\:decoration-gray {
    -webkit-text-decoration-color: var(--color-gray);
    text-decoration-color: var(--color-gray);
  }

  .md\:decoration-info {
    -webkit-text-decoration-color: var(--color-info-300);
    text-decoration-color: var(--color-info-300);
  }

  .md\:decoration-info-100 {
    -webkit-text-decoration-color: var(--color-info-100);
    text-decoration-color: var(--color-info-100);
  }

  .md\:decoration-info-200 {
    -webkit-text-decoration-color: var(--color-info-200);
    text-decoration-color: var(--color-info-200);
  }

  .md\:decoration-info-300 {
    -webkit-text-decoration-color: var(--color-info-300);
    text-decoration-color: var(--color-info-300);
  }

  .md\:decoration-info-400 {
    -webkit-text-decoration-color: var(--color-info-400);
    text-decoration-color: var(--color-info-400);
  }

  .md\:decoration-primary {
    -webkit-text-decoration-color: var(--color-primary-300);
    text-decoration-color: var(--color-primary-300);
  }

  .md\:decoration-primary-100 {
    -webkit-text-decoration-color: var(--color-primary-100);
    text-decoration-color: var(--color-primary-100);
  }

  .md\:decoration-primary-200 {
    -webkit-text-decoration-color: var(--color-primary-200);
    text-decoration-color: var(--color-primary-200);
  }

  .md\:decoration-primary-250 {
    -webkit-text-decoration-color: var(--color-primary-250);
    text-decoration-color: var(--color-primary-250);
  }

  .md\:decoration-primary-300 {
    -webkit-text-decoration-color: var(--color-primary-300);
    text-decoration-color: var(--color-primary-300);
  }

  .md\:decoration-primary-400 {
    -webkit-text-decoration-color: var(--color-primary-400);
    text-decoration-color: var(--color-primary-400);
  }

  .md\:decoration-primary-500 {
    -webkit-text-decoration-color: var(--color-primary-500);
    text-decoration-color: var(--color-primary-500);
  }

  .md\:decoration-primary-600 {
    -webkit-text-decoration-color: var(--color-primary-600);
    text-decoration-color: var(--color-primary-600);
  }

  .md\:decoration-success {
    -webkit-text-decoration-color: var(--color-success-300);
    text-decoration-color: var(--color-success-300);
  }

  .md\:decoration-success-100 {
    -webkit-text-decoration-color: var(--color-success-100);
    text-decoration-color: var(--color-success-100);
  }

  .md\:decoration-success-200 {
    -webkit-text-decoration-color: var(--color-success-200);
    text-decoration-color: var(--color-success-200);
  }

  .md\:decoration-success-300 {
    -webkit-text-decoration-color: var(--color-success-300);
    text-decoration-color: var(--color-success-300);
  }

  .md\:decoration-success-400 {
    -webkit-text-decoration-color: var(--color-success-400);
    text-decoration-color: var(--color-success-400);
  }

  .md\:decoration-transparent, .md\:decoration-transparent\/0 {
    text-decoration-color: #0000;
  }

  .md\:decoration-transparent\/10 {
    text-decoration-color: #0000001a;
  }

  .md\:decoration-transparent\/100 {
    text-decoration-color: #000;
  }

  .md\:decoration-transparent\/15 {
    text-decoration-color: #00000026;
  }

  .md\:decoration-transparent\/20 {
    text-decoration-color: #0003;
  }

  .md\:decoration-transparent\/25 {
    text-decoration-color: #00000040;
  }

  .md\:decoration-transparent\/30 {
    text-decoration-color: #0000004d;
  }

  .md\:decoration-transparent\/35 {
    text-decoration-color: #00000059;
  }

  .md\:decoration-transparent\/40 {
    text-decoration-color: #0006;
  }

  .md\:decoration-transparent\/45 {
    text-decoration-color: #00000073;
  }

  .md\:decoration-transparent\/5 {
    text-decoration-color: #0000000d;
  }

  .md\:decoration-transparent\/50 {
    text-decoration-color: #00000080;
  }

  .md\:decoration-transparent\/55 {
    text-decoration-color: #0000008c;
  }

  .md\:decoration-transparent\/60 {
    text-decoration-color: #0009;
  }

  .md\:decoration-transparent\/65 {
    text-decoration-color: #000000a6;
  }

  .md\:decoration-transparent\/70 {
    text-decoration-color: #000000b3;
  }

  .md\:decoration-transparent\/75 {
    text-decoration-color: #000000bf;
  }

  .md\:decoration-transparent\/80 {
    text-decoration-color: #000c;
  }

  .md\:decoration-transparent\/85 {
    text-decoration-color: #000000d9;
  }

  .md\:decoration-transparent\/90 {
    text-decoration-color: #000000e6;
  }

  .md\:decoration-transparent\/95 {
    text-decoration-color: #000000f2;
  }

  .md\:decoration-warning {
    -webkit-text-decoration-color: var(--color-warning-300);
    text-decoration-color: var(--color-warning-300);
  }

  .md\:decoration-warning-100 {
    -webkit-text-decoration-color: var(--color-warning-100);
    text-decoration-color: var(--color-warning-100);
  }

  .md\:decoration-warning-200 {
    -webkit-text-decoration-color: var(--color-warning-200);
    text-decoration-color: var(--color-warning-200);
  }

  .md\:decoration-warning-300 {
    -webkit-text-decoration-color: var(--color-warning-300);
    text-decoration-color: var(--color-warning-300);
  }

  .md\:decoration-warning-400 {
    -webkit-text-decoration-color: var(--color-warning-400);
    text-decoration-color: var(--color-warning-400);
  }

  .md\:decoration-white {
    -webkit-text-decoration-color: var(--color-white);
    text-decoration-color: var(--color-white);
  }

  .md\:decoration-solid {
    text-decoration-style: solid;
  }

  .md\:decoration-double {
    text-decoration-style: double;
  }

  .md\:decoration-dotted {
    text-decoration-style: dotted;
  }

  .md\:decoration-dashed {
    text-decoration-style: dashed;
  }

  .md\:decoration-wavy {
    text-decoration-style: wavy;
  }

  .md\:decoration-0 {
    text-decoration-thickness: 0;
  }

  .md\:decoration-1 {
    text-decoration-thickness: 1px;
  }

  .md\:decoration-2 {
    text-decoration-thickness: 2px;
  }

  .md\:decoration-4 {
    text-decoration-thickness: 4px;
  }

  .md\:decoration-8 {
    text-decoration-thickness: 8px;
  }

  .md\:decoration-auto {
    text-decoration-thickness: auto;
  }

  .md\:decoration-from-font {
    text-decoration-thickness: from-font;
  }

  .md\:underline-offset-0 {
    text-underline-offset: 0px;
  }

  .md\:underline-offset-1 {
    text-underline-offset: 1px;
  }

  .md\:underline-offset-2 {
    text-underline-offset: 2px;
  }

  .md\:underline-offset-4 {
    text-underline-offset: 4px;
  }

  .md\:underline-offset-8 {
    text-underline-offset: 8px;
  }

  .md\:underline-offset-auto {
    text-underline-offset: auto;
  }

  .md\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md\:placeholder-black::placeholder {
    color: var(--color-black);
  }

  .md\:placeholder-current::placeholder {
    color: currentColor;
  }

  .md\:placeholder-danger::placeholder {
    color: var(--color-danger-300);
  }

  .md\:placeholder-danger-100::placeholder {
    color: var(--color-danger-100);
  }

  .md\:placeholder-danger-200::placeholder {
    color: var(--color-danger-200);
  }

  .md\:placeholder-danger-300::placeholder {
    color: var(--color-danger-300);
  }

  .md\:placeholder-danger-400::placeholder {
    color: var(--color-danger-400);
  }

  .md\:placeholder-divider::placeholder {
    color: var(--color-divider);
  }

  .md\:placeholder-gray::placeholder {
    color: var(--color-gray);
  }

  .md\:placeholder-info::placeholder {
    color: var(--color-info-300);
  }

  .md\:placeholder-info-100::placeholder {
    color: var(--color-info-100);
  }

  .md\:placeholder-info-200::placeholder {
    color: var(--color-info-200);
  }

  .md\:placeholder-info-300::placeholder {
    color: var(--color-info-300);
  }

  .md\:placeholder-info-400::placeholder {
    color: var(--color-info-400);
  }

  .md\:placeholder-primary::placeholder {
    color: var(--color-primary-300);
  }

  .md\:placeholder-primary-100::placeholder {
    color: var(--color-primary-100);
  }

  .md\:placeholder-primary-200::placeholder {
    color: var(--color-primary-200);
  }

  .md\:placeholder-primary-250::placeholder {
    color: var(--color-primary-250);
  }

  .md\:placeholder-primary-300::placeholder {
    color: var(--color-primary-300);
  }

  .md\:placeholder-primary-400::placeholder {
    color: var(--color-primary-400);
  }

  .md\:placeholder-primary-500::placeholder {
    color: var(--color-primary-500);
  }

  .md\:placeholder-primary-600::placeholder {
    color: var(--color-primary-600);
  }

  .md\:placeholder-success::placeholder {
    color: var(--color-success-300);
  }

  .md\:placeholder-success-100::placeholder {
    color: var(--color-success-100);
  }

  .md\:placeholder-success-200::placeholder {
    color: var(--color-success-200);
  }

  .md\:placeholder-success-300::placeholder {
    color: var(--color-success-300);
  }

  .md\:placeholder-success-400::placeholder {
    color: var(--color-success-400);
  }

  .md\:placeholder-transparent::placeholder, .md\:placeholder-transparent\/0::placeholder {
    color: #0000;
  }

  .md\:placeholder-transparent\/10::placeholder {
    color: #0000001a;
  }

  .md\:placeholder-transparent\/100::placeholder {
    color: #000;
  }

  .md\:placeholder-transparent\/15::placeholder {
    color: #00000026;
  }

  .md\:placeholder-transparent\/20::placeholder {
    color: #0003;
  }

  .md\:placeholder-transparent\/25::placeholder {
    color: #00000040;
  }

  .md\:placeholder-transparent\/30::placeholder {
    color: #0000004d;
  }

  .md\:placeholder-transparent\/35::placeholder {
    color: #00000059;
  }

  .md\:placeholder-transparent\/40::placeholder {
    color: #0006;
  }

  .md\:placeholder-transparent\/45::placeholder {
    color: #00000073;
  }

  .md\:placeholder-transparent\/5::placeholder {
    color: #0000000d;
  }

  .md\:placeholder-transparent\/50::placeholder {
    color: #00000080;
  }

  .md\:placeholder-transparent\/55::placeholder {
    color: #0000008c;
  }

  .md\:placeholder-transparent\/60::placeholder {
    color: #0009;
  }

  .md\:placeholder-transparent\/65::placeholder {
    color: #000000a6;
  }

  .md\:placeholder-transparent\/70::placeholder {
    color: #000000b3;
  }

  .md\:placeholder-transparent\/75::placeholder {
    color: #000000bf;
  }

  .md\:placeholder-transparent\/80::placeholder {
    color: #000c;
  }

  .md\:placeholder-transparent\/85::placeholder {
    color: #000000d9;
  }

  .md\:placeholder-transparent\/90::placeholder {
    color: #000000e6;
  }

  .md\:placeholder-transparent\/95::placeholder {
    color: #000000f2;
  }

  .md\:placeholder-warning::placeholder {
    color: var(--color-warning-300);
  }

  .md\:placeholder-warning-100::placeholder {
    color: var(--color-warning-100);
  }

  .md\:placeholder-warning-200::placeholder {
    color: var(--color-warning-200);
  }

  .md\:placeholder-warning-300::placeholder {
    color: var(--color-warning-300);
  }

  .md\:placeholder-warning-400::placeholder {
    color: var(--color-warning-400);
  }

  .md\:placeholder-white::placeholder {
    color: var(--color-white);
  }

  .md\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .md\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:placeholder-opacity-15::placeholder {
    --tw-placeholder-opacity: .15;
  }

  .md\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .md\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .md\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .md\:placeholder-opacity-35::placeholder {
    --tw-placeholder-opacity: .35;
  }

  .md\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .md\:placeholder-opacity-45::placeholder {
    --tw-placeholder-opacity: .45;
  }

  .md\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .md\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .md\:placeholder-opacity-55::placeholder {
    --tw-placeholder-opacity: .55;
  }

  .md\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .md\:placeholder-opacity-65::placeholder {
    --tw-placeholder-opacity: .65;
  }

  .md\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .md\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .md\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .md\:placeholder-opacity-85::placeholder {
    --tw-placeholder-opacity: .85;
  }

  .md\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .md\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .md\:caret-black {
    caret-color: var(--color-black);
  }

  .md\:caret-current {
    caret-color: currentColor;
  }

  .md\:caret-danger {
    caret-color: var(--color-danger-300);
  }

  .md\:caret-danger-100 {
    caret-color: var(--color-danger-100);
  }

  .md\:caret-danger-200 {
    caret-color: var(--color-danger-200);
  }

  .md\:caret-danger-300 {
    caret-color: var(--color-danger-300);
  }

  .md\:caret-danger-400 {
    caret-color: var(--color-danger-400);
  }

  .md\:caret-divider {
    caret-color: var(--color-divider);
  }

  .md\:caret-gray {
    caret-color: var(--color-gray);
  }

  .md\:caret-info {
    caret-color: var(--color-info-300);
  }

  .md\:caret-info-100 {
    caret-color: var(--color-info-100);
  }

  .md\:caret-info-200 {
    caret-color: var(--color-info-200);
  }

  .md\:caret-info-300 {
    caret-color: var(--color-info-300);
  }

  .md\:caret-info-400 {
    caret-color: var(--color-info-400);
  }

  .md\:caret-primary {
    caret-color: var(--color-primary-300);
  }

  .md\:caret-primary-100 {
    caret-color: var(--color-primary-100);
  }

  .md\:caret-primary-200 {
    caret-color: var(--color-primary-200);
  }

  .md\:caret-primary-250 {
    caret-color: var(--color-primary-250);
  }

  .md\:caret-primary-300 {
    caret-color: var(--color-primary-300);
  }

  .md\:caret-primary-400 {
    caret-color: var(--color-primary-400);
  }

  .md\:caret-primary-500 {
    caret-color: var(--color-primary-500);
  }

  .md\:caret-primary-600 {
    caret-color: var(--color-primary-600);
  }

  .md\:caret-success {
    caret-color: var(--color-success-300);
  }

  .md\:caret-success-100 {
    caret-color: var(--color-success-100);
  }

  .md\:caret-success-200 {
    caret-color: var(--color-success-200);
  }

  .md\:caret-success-300 {
    caret-color: var(--color-success-300);
  }

  .md\:caret-success-400 {
    caret-color: var(--color-success-400);
  }

  .md\:caret-transparent, .md\:caret-transparent\/0 {
    caret-color: #0000;
  }

  .md\:caret-transparent\/10 {
    caret-color: #0000001a;
  }

  .md\:caret-transparent\/100 {
    caret-color: #000;
  }

  .md\:caret-transparent\/15 {
    caret-color: #00000026;
  }

  .md\:caret-transparent\/20 {
    caret-color: #0003;
  }

  .md\:caret-transparent\/25 {
    caret-color: #00000040;
  }

  .md\:caret-transparent\/30 {
    caret-color: #0000004d;
  }

  .md\:caret-transparent\/35 {
    caret-color: #00000059;
  }

  .md\:caret-transparent\/40 {
    caret-color: #0006;
  }

  .md\:caret-transparent\/45 {
    caret-color: #00000073;
  }

  .md\:caret-transparent\/5 {
    caret-color: #0000000d;
  }

  .md\:caret-transparent\/50 {
    caret-color: #00000080;
  }

  .md\:caret-transparent\/55 {
    caret-color: #0000008c;
  }

  .md\:caret-transparent\/60 {
    caret-color: #0009;
  }

  .md\:caret-transparent\/65 {
    caret-color: #000000a6;
  }

  .md\:caret-transparent\/70 {
    caret-color: #000000b3;
  }

  .md\:caret-transparent\/75 {
    caret-color: #000000bf;
  }

  .md\:caret-transparent\/80 {
    caret-color: #000c;
  }

  .md\:caret-transparent\/85 {
    caret-color: #000000d9;
  }

  .md\:caret-transparent\/90 {
    caret-color: #000000e6;
  }

  .md\:caret-transparent\/95 {
    caret-color: #000000f2;
  }

  .md\:caret-warning {
    caret-color: var(--color-warning-300);
  }

  .md\:caret-warning-100 {
    caret-color: var(--color-warning-100);
  }

  .md\:caret-warning-200 {
    caret-color: var(--color-warning-200);
  }

  .md\:caret-warning-300 {
    caret-color: var(--color-warning-300);
  }

  .md\:caret-warning-400 {
    caret-color: var(--color-warning-400);
  }

  .md\:caret-white {
    caret-color: var(--color-white);
  }

  .md\:accent-auto {
    accent-color: auto;
  }

  .md\:accent-black {
    accent-color: var(--color-black);
  }

  .md\:accent-current {
    accent-color: currentColor;
  }

  .md\:accent-danger {
    accent-color: var(--color-danger-300);
  }

  .md\:accent-danger-100 {
    accent-color: var(--color-danger-100);
  }

  .md\:accent-danger-200 {
    accent-color: var(--color-danger-200);
  }

  .md\:accent-danger-300 {
    accent-color: var(--color-danger-300);
  }

  .md\:accent-danger-400 {
    accent-color: var(--color-danger-400);
  }

  .md\:accent-divider {
    accent-color: var(--color-divider);
  }

  .md\:accent-gray {
    accent-color: var(--color-gray);
  }

  .md\:accent-info {
    accent-color: var(--color-info-300);
  }

  .md\:accent-info-100 {
    accent-color: var(--color-info-100);
  }

  .md\:accent-info-200 {
    accent-color: var(--color-info-200);
  }

  .md\:accent-info-300 {
    accent-color: var(--color-info-300);
  }

  .md\:accent-info-400 {
    accent-color: var(--color-info-400);
  }

  .md\:accent-primary {
    accent-color: var(--color-primary-300);
  }

  .md\:accent-primary-100 {
    accent-color: var(--color-primary-100);
  }

  .md\:accent-primary-200 {
    accent-color: var(--color-primary-200);
  }

  .md\:accent-primary-250 {
    accent-color: var(--color-primary-250);
  }

  .md\:accent-primary-300 {
    accent-color: var(--color-primary-300);
  }

  .md\:accent-primary-400 {
    accent-color: var(--color-primary-400);
  }

  .md\:accent-primary-500 {
    accent-color: var(--color-primary-500);
  }

  .md\:accent-primary-600 {
    accent-color: var(--color-primary-600);
  }

  .md\:accent-success {
    accent-color: var(--color-success-300);
  }

  .md\:accent-success-100 {
    accent-color: var(--color-success-100);
  }

  .md\:accent-success-200 {
    accent-color: var(--color-success-200);
  }

  .md\:accent-success-300 {
    accent-color: var(--color-success-300);
  }

  .md\:accent-success-400 {
    accent-color: var(--color-success-400);
  }

  .md\:accent-transparent, .md\:accent-transparent\/0 {
    accent-color: #0000;
  }

  .md\:accent-transparent\/10 {
    accent-color: #0000001a;
  }

  .md\:accent-transparent\/100 {
    accent-color: #000;
  }

  .md\:accent-transparent\/15 {
    accent-color: #00000026;
  }

  .md\:accent-transparent\/20 {
    accent-color: #0003;
  }

  .md\:accent-transparent\/25 {
    accent-color: #00000040;
  }

  .md\:accent-transparent\/30 {
    accent-color: #0000004d;
  }

  .md\:accent-transparent\/35 {
    accent-color: #00000059;
  }

  .md\:accent-transparent\/40 {
    accent-color: #0006;
  }

  .md\:accent-transparent\/45 {
    accent-color: #00000073;
  }

  .md\:accent-transparent\/5 {
    accent-color: #0000000d;
  }

  .md\:accent-transparent\/50 {
    accent-color: #00000080;
  }

  .md\:accent-transparent\/55 {
    accent-color: #0000008c;
  }

  .md\:accent-transparent\/60 {
    accent-color: #0009;
  }

  .md\:accent-transparent\/65 {
    accent-color: #000000a6;
  }

  .md\:accent-transparent\/70 {
    accent-color: #000000b3;
  }

  .md\:accent-transparent\/75 {
    accent-color: #000000bf;
  }

  .md\:accent-transparent\/80 {
    accent-color: #000c;
  }

  .md\:accent-transparent\/85 {
    accent-color: #000000d9;
  }

  .md\:accent-transparent\/90 {
    accent-color: #000000e6;
  }

  .md\:accent-transparent\/95 {
    accent-color: #000000f2;
  }

  .md\:accent-warning {
    accent-color: var(--color-warning-300);
  }

  .md\:accent-warning-100 {
    accent-color: var(--color-warning-100);
  }

  .md\:accent-warning-200 {
    accent-color: var(--color-warning-200);
  }

  .md\:accent-warning-300 {
    accent-color: var(--color-warning-300);
  }

  .md\:accent-warning-400 {
    accent-color: var(--color-warning-400);
  }

  .md\:accent-white {
    accent-color: var(--color-white);
  }

  .md\:opacity-0 {
    opacity: 0;
  }

  .md\:opacity-10 {
    opacity: .1;
  }

  .md\:opacity-100 {
    opacity: 1;
  }

  .md\:opacity-15 {
    opacity: .15;
  }

  .md\:opacity-20 {
    opacity: .2;
  }

  .md\:opacity-25 {
    opacity: .25;
  }

  .md\:opacity-30 {
    opacity: .3;
  }

  .md\:opacity-35 {
    opacity: .35;
  }

  .md\:opacity-40 {
    opacity: .4;
  }

  .md\:opacity-45 {
    opacity: .45;
  }

  .md\:opacity-5 {
    opacity: .05;
  }

  .md\:opacity-50 {
    opacity: .5;
  }

  .md\:opacity-55 {
    opacity: .55;
  }

  .md\:opacity-60 {
    opacity: .6;
  }

  .md\:opacity-65 {
    opacity: .65;
  }

  .md\:opacity-70 {
    opacity: .7;
  }

  .md\:opacity-75 {
    opacity: .75;
  }

  .md\:opacity-80 {
    opacity: .8;
  }

  .md\:opacity-85 {
    opacity: .85;
  }

  .md\:opacity-90 {
    opacity: .9;
  }

  .md\:opacity-95 {
    opacity: .95;
  }

  .md\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .md\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .md\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .md\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .md\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .md\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .md\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .md\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .md\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .md\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .md\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .md\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .md\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .md\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .md\:bg-blend-color {
    background-blend-mode: color;
  }

  .md\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .md\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .md\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .md\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .md\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .md\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .md\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .md\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .md\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .md\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .md\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .md\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .md\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .md\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .md\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .md\:mix-blend-color {
    mix-blend-mode: color;
  }

  .md\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .md\:mix-blend-plus-darker {
    mix-blend-mode: plus-darker;
  }

  .md\:mix-blend-plus-lighter {
    mix-blend-mode: plus-lighter;
  }

  .md\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000d;
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-black {
    --tw-shadow-color: var(--color-black);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-current {
    --tw-shadow-color: currentColor;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-danger {
    --tw-shadow-color: var(--color-danger-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-danger-100 {
    --tw-shadow-color: var(--color-danger-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-danger-200 {
    --tw-shadow-color: var(--color-danger-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-danger-300 {
    --tw-shadow-color: var(--color-danger-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-danger-400 {
    --tw-shadow-color: var(--color-danger-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-divider {
    --tw-shadow-color: var(--color-divider);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-gray {
    --tw-shadow-color: var(--color-gray);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-info {
    --tw-shadow-color: var(--color-info-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-info-100 {
    --tw-shadow-color: var(--color-info-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-info-200 {
    --tw-shadow-color: var(--color-info-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-info-300 {
    --tw-shadow-color: var(--color-info-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-info-400 {
    --tw-shadow-color: var(--color-info-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-primary {
    --tw-shadow-color: var(--color-primary-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-primary-100 {
    --tw-shadow-color: var(--color-primary-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-primary-200 {
    --tw-shadow-color: var(--color-primary-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-primary-250 {
    --tw-shadow-color: var(--color-primary-250);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-primary-300 {
    --tw-shadow-color: var(--color-primary-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-primary-400 {
    --tw-shadow-color: var(--color-primary-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-primary-500 {
    --tw-shadow-color: var(--color-primary-500);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-primary-600 {
    --tw-shadow-color: var(--color-primary-600);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-success {
    --tw-shadow-color: var(--color-success-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-success-100 {
    --tw-shadow-color: var(--color-success-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-success-200 {
    --tw-shadow-color: var(--color-success-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-success-300 {
    --tw-shadow-color: var(--color-success-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-success-400 {
    --tw-shadow-color: var(--color-success-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent {
    --tw-shadow-color: transparent;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/0 {
    --tw-shadow-color: #0000;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/10 {
    --tw-shadow-color: #0000001a;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/100 {
    --tw-shadow-color: #000;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/15 {
    --tw-shadow-color: #00000026;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/20 {
    --tw-shadow-color: #0003;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/25 {
    --tw-shadow-color: #00000040;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/30 {
    --tw-shadow-color: #0000004d;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/35 {
    --tw-shadow-color: #00000059;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/40 {
    --tw-shadow-color: #0006;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/45 {
    --tw-shadow-color: #00000073;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/5 {
    --tw-shadow-color: #0000000d;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/50 {
    --tw-shadow-color: #00000080;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/55 {
    --tw-shadow-color: #0000008c;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/60 {
    --tw-shadow-color: #0009;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/65 {
    --tw-shadow-color: #000000a6;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/70 {
    --tw-shadow-color: #000000b3;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/75 {
    --tw-shadow-color: #000000bf;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/80 {
    --tw-shadow-color: #000c;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/85 {
    --tw-shadow-color: #000000d9;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/90 {
    --tw-shadow-color: #000000e6;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-transparent\/95 {
    --tw-shadow-color: #000000f2;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-warning {
    --tw-shadow-color: var(--color-warning-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-warning-100 {
    --tw-shadow-color: var(--color-warning-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-warning-200 {
    --tw-shadow-color: var(--color-warning-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-warning-300 {
    --tw-shadow-color: var(--color-warning-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-warning-400 {
    --tw-shadow-color: var(--color-warning-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:shadow-white {
    --tw-shadow-color: var(--color-white);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .md\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .md\:outline {
    outline-style: solid;
  }

  .md\:outline-dashed {
    outline-style: dashed;
  }

  .md\:outline-dotted {
    outline-style: dotted;
  }

  .md\:outline-double {
    outline-style: double;
  }

  .md\:outline-0 {
    outline-width: 0;
  }

  .md\:outline-1 {
    outline-width: 1px;
  }

  .md\:outline-2 {
    outline-width: 2px;
  }

  .md\:outline-4 {
    outline-width: 4px;
  }

  .md\:outline-8 {
    outline-width: 8px;
  }

  .md\:-outline-offset-0 {
    outline-offset: -0px;
  }

  .md\:-outline-offset-1 {
    outline-offset: -1px;
  }

  .md\:-outline-offset-2 {
    outline-offset: -2px;
  }

  .md\:-outline-offset-4 {
    outline-offset: -4px;
  }

  .md\:-outline-offset-8 {
    outline-offset: -8px;
  }

  .md\:outline-offset-0 {
    outline-offset: 0px;
  }

  .md\:outline-offset-1 {
    outline-offset: 1px;
  }

  .md\:outline-offset-2 {
    outline-offset: 2px;
  }

  .md\:outline-offset-4 {
    outline-offset: 4px;
  }

  .md\:outline-offset-8 {
    outline-offset: 8px;
  }

  .md\:outline-black {
    outline-color: var(--color-black);
  }

  .md\:outline-current {
    outline-color: currentColor;
  }

  .md\:outline-danger {
    outline-color: var(--color-danger-300);
  }

  .md\:outline-danger-100 {
    outline-color: var(--color-danger-100);
  }

  .md\:outline-danger-200 {
    outline-color: var(--color-danger-200);
  }

  .md\:outline-danger-300 {
    outline-color: var(--color-danger-300);
  }

  .md\:outline-danger-400 {
    outline-color: var(--color-danger-400);
  }

  .md\:outline-divider {
    outline-color: var(--color-divider);
  }

  .md\:outline-gray {
    outline-color: var(--color-gray);
  }

  .md\:outline-info {
    outline-color: var(--color-info-300);
  }

  .md\:outline-info-100 {
    outline-color: var(--color-info-100);
  }

  .md\:outline-info-200 {
    outline-color: var(--color-info-200);
  }

  .md\:outline-info-300 {
    outline-color: var(--color-info-300);
  }

  .md\:outline-info-400 {
    outline-color: var(--color-info-400);
  }

  .md\:outline-primary {
    outline-color: var(--color-primary-300);
  }

  .md\:outline-primary-100 {
    outline-color: var(--color-primary-100);
  }

  .md\:outline-primary-200 {
    outline-color: var(--color-primary-200);
  }

  .md\:outline-primary-250 {
    outline-color: var(--color-primary-250);
  }

  .md\:outline-primary-300 {
    outline-color: var(--color-primary-300);
  }

  .md\:outline-primary-400 {
    outline-color: var(--color-primary-400);
  }

  .md\:outline-primary-500 {
    outline-color: var(--color-primary-500);
  }

  .md\:outline-primary-600 {
    outline-color: var(--color-primary-600);
  }

  .md\:outline-success {
    outline-color: var(--color-success-300);
  }

  .md\:outline-success-100 {
    outline-color: var(--color-success-100);
  }

  .md\:outline-success-200 {
    outline-color: var(--color-success-200);
  }

  .md\:outline-success-300 {
    outline-color: var(--color-success-300);
  }

  .md\:outline-success-400 {
    outline-color: var(--color-success-400);
  }

  .md\:outline-transparent, .md\:outline-transparent\/0 {
    outline-color: #0000;
  }

  .md\:outline-transparent\/10 {
    outline-color: #0000001a;
  }

  .md\:outline-transparent\/100 {
    outline-color: #000;
  }

  .md\:outline-transparent\/15 {
    outline-color: #00000026;
  }

  .md\:outline-transparent\/20 {
    outline-color: #0003;
  }

  .md\:outline-transparent\/25 {
    outline-color: #00000040;
  }

  .md\:outline-transparent\/30 {
    outline-color: #0000004d;
  }

  .md\:outline-transparent\/35 {
    outline-color: #00000059;
  }

  .md\:outline-transparent\/40 {
    outline-color: #0006;
  }

  .md\:outline-transparent\/45 {
    outline-color: #00000073;
  }

  .md\:outline-transparent\/5 {
    outline-color: #0000000d;
  }

  .md\:outline-transparent\/50 {
    outline-color: #00000080;
  }

  .md\:outline-transparent\/55 {
    outline-color: #0000008c;
  }

  .md\:outline-transparent\/60 {
    outline-color: #0009;
  }

  .md\:outline-transparent\/65 {
    outline-color: #000000a6;
  }

  .md\:outline-transparent\/70 {
    outline-color: #000000b3;
  }

  .md\:outline-transparent\/75 {
    outline-color: #000000bf;
  }

  .md\:outline-transparent\/80 {
    outline-color: #000c;
  }

  .md\:outline-transparent\/85 {
    outline-color: #000000d9;
  }

  .md\:outline-transparent\/90 {
    outline-color: #000000e6;
  }

  .md\:outline-transparent\/95 {
    outline-color: #000000f2;
  }

  .md\:outline-warning {
    outline-color: var(--color-warning-300);
  }

  .md\:outline-warning-100 {
    outline-color: var(--color-warning-100);
  }

  .md\:outline-warning-200 {
    outline-color: var(--color-warning-200);
  }

  .md\:outline-warning-300 {
    outline-color: var(--color-warning-300);
  }

  .md\:outline-warning-400 {
    outline-color: var(--color-warning-400);
  }

  .md\:outline-white {
    outline-color: var(--color-white);
  }

  .md\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-inset {
    --tw-ring-inset: inset;
  }

  .md\:ring-black {
    --tw-ring-color: var(--color-black);
  }

  .md\:ring-current {
    --tw-ring-color: currentColor;
  }

  .md\:ring-danger {
    --tw-ring-color: var(--color-danger-300);
  }

  .md\:ring-danger-100 {
    --tw-ring-color: var(--color-danger-100);
  }

  .md\:ring-danger-200 {
    --tw-ring-color: var(--color-danger-200);
  }

  .md\:ring-danger-300 {
    --tw-ring-color: var(--color-danger-300);
  }

  .md\:ring-danger-400 {
    --tw-ring-color: var(--color-danger-400);
  }

  .md\:ring-divider {
    --tw-ring-color: var(--color-divider);
  }

  .md\:ring-gray {
    --tw-ring-color: var(--color-gray);
  }

  .md\:ring-info {
    --tw-ring-color: var(--color-info-300);
  }

  .md\:ring-info-100 {
    --tw-ring-color: var(--color-info-100);
  }

  .md\:ring-info-200 {
    --tw-ring-color: var(--color-info-200);
  }

  .md\:ring-info-300 {
    --tw-ring-color: var(--color-info-300);
  }

  .md\:ring-info-400 {
    --tw-ring-color: var(--color-info-400);
  }

  .md\:ring-primary {
    --tw-ring-color: var(--color-primary-300);
  }

  .md\:ring-primary-100 {
    --tw-ring-color: var(--color-primary-100);
  }

  .md\:ring-primary-200 {
    --tw-ring-color: var(--color-primary-200);
  }

  .md\:ring-primary-250 {
    --tw-ring-color: var(--color-primary-250);
  }

  .md\:ring-primary-300 {
    --tw-ring-color: var(--color-primary-300);
  }

  .md\:ring-primary-400 {
    --tw-ring-color: var(--color-primary-400);
  }

  .md\:ring-primary-500 {
    --tw-ring-color: var(--color-primary-500);
  }

  .md\:ring-primary-600 {
    --tw-ring-color: var(--color-primary-600);
  }

  .md\:ring-success {
    --tw-ring-color: var(--color-success-300);
  }

  .md\:ring-success-100 {
    --tw-ring-color: var(--color-success-100);
  }

  .md\:ring-success-200 {
    --tw-ring-color: var(--color-success-200);
  }

  .md\:ring-success-300 {
    --tw-ring-color: var(--color-success-300);
  }

  .md\:ring-success-400 {
    --tw-ring-color: var(--color-success-400);
  }

  .md\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .md\:ring-transparent\/0 {
    --tw-ring-color: #0000;
  }

  .md\:ring-transparent\/10 {
    --tw-ring-color: #0000001a;
  }

  .md\:ring-transparent\/100 {
    --tw-ring-color: #000;
  }

  .md\:ring-transparent\/15 {
    --tw-ring-color: #00000026;
  }

  .md\:ring-transparent\/20 {
    --tw-ring-color: #0003;
  }

  .md\:ring-transparent\/25 {
    --tw-ring-color: #00000040;
  }

  .md\:ring-transparent\/30 {
    --tw-ring-color: #0000004d;
  }

  .md\:ring-transparent\/35 {
    --tw-ring-color: #00000059;
  }

  .md\:ring-transparent\/40 {
    --tw-ring-color: #0006;
  }

  .md\:ring-transparent\/45 {
    --tw-ring-color: #00000073;
  }

  .md\:ring-transparent\/5 {
    --tw-ring-color: #0000000d;
  }

  .md\:ring-transparent\/50 {
    --tw-ring-color: #00000080;
  }

  .md\:ring-transparent\/55 {
    --tw-ring-color: #0000008c;
  }

  .md\:ring-transparent\/60 {
    --tw-ring-color: #0009;
  }

  .md\:ring-transparent\/65 {
    --tw-ring-color: #000000a6;
  }

  .md\:ring-transparent\/70 {
    --tw-ring-color: #000000b3;
  }

  .md\:ring-transparent\/75 {
    --tw-ring-color: #000000bf;
  }

  .md\:ring-transparent\/80 {
    --tw-ring-color: #000c;
  }

  .md\:ring-transparent\/85 {
    --tw-ring-color: #000000d9;
  }

  .md\:ring-transparent\/90 {
    --tw-ring-color: #000000e6;
  }

  .md\:ring-transparent\/95 {
    --tw-ring-color: #000000f2;
  }

  .md\:ring-warning {
    --tw-ring-color: var(--color-warning-300);
  }

  .md\:ring-warning-100 {
    --tw-ring-color: var(--color-warning-100);
  }

  .md\:ring-warning-200 {
    --tw-ring-color: var(--color-warning-200);
  }

  .md\:ring-warning-300 {
    --tw-ring-color: var(--color-warning-300);
  }

  .md\:ring-warning-400 {
    --tw-ring-color: var(--color-warning-400);
  }

  .md\:ring-white {
    --tw-ring-color: var(--color-white);
  }

  .md\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .md\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .md\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .md\:ring-opacity-15 {
    --tw-ring-opacity: .15;
  }

  .md\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .md\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .md\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .md\:ring-opacity-35 {
    --tw-ring-opacity: .35;
  }

  .md\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .md\:ring-opacity-45 {
    --tw-ring-opacity: .45;
  }

  .md\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .md\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .md\:ring-opacity-55 {
    --tw-ring-opacity: .55;
  }

  .md\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .md\:ring-opacity-65 {
    --tw-ring-opacity: .65;
  }

  .md\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .md\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .md\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .md\:ring-opacity-85 {
    --tw-ring-opacity: .85;
  }

  .md\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .md\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .md\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .md\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .md\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .md\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .md\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .md\:ring-offset-black {
    --tw-ring-offset-color: var(--color-black);
  }

  .md\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .md\:ring-offset-danger {
    --tw-ring-offset-color: var(--color-danger-300);
  }

  .md\:ring-offset-danger-100 {
    --tw-ring-offset-color: var(--color-danger-100);
  }

  .md\:ring-offset-danger-200 {
    --tw-ring-offset-color: var(--color-danger-200);
  }

  .md\:ring-offset-danger-300 {
    --tw-ring-offset-color: var(--color-danger-300);
  }

  .md\:ring-offset-danger-400 {
    --tw-ring-offset-color: var(--color-danger-400);
  }

  .md\:ring-offset-divider {
    --tw-ring-offset-color: var(--color-divider);
  }

  .md\:ring-offset-gray {
    --tw-ring-offset-color: var(--color-gray);
  }

  .md\:ring-offset-info {
    --tw-ring-offset-color: var(--color-info-300);
  }

  .md\:ring-offset-info-100 {
    --tw-ring-offset-color: var(--color-info-100);
  }

  .md\:ring-offset-info-200 {
    --tw-ring-offset-color: var(--color-info-200);
  }

  .md\:ring-offset-info-300 {
    --tw-ring-offset-color: var(--color-info-300);
  }

  .md\:ring-offset-info-400 {
    --tw-ring-offset-color: var(--color-info-400);
  }

  .md\:ring-offset-primary {
    --tw-ring-offset-color: var(--color-primary-300);
  }

  .md\:ring-offset-primary-100 {
    --tw-ring-offset-color: var(--color-primary-100);
  }

  .md\:ring-offset-primary-200 {
    --tw-ring-offset-color: var(--color-primary-200);
  }

  .md\:ring-offset-primary-250 {
    --tw-ring-offset-color: var(--color-primary-250);
  }

  .md\:ring-offset-primary-300 {
    --tw-ring-offset-color: var(--color-primary-300);
  }

  .md\:ring-offset-primary-400 {
    --tw-ring-offset-color: var(--color-primary-400);
  }

  .md\:ring-offset-primary-500 {
    --tw-ring-offset-color: var(--color-primary-500);
  }

  .md\:ring-offset-primary-600 {
    --tw-ring-offset-color: var(--color-primary-600);
  }

  .md\:ring-offset-success {
    --tw-ring-offset-color: var(--color-success-300);
  }

  .md\:ring-offset-success-100 {
    --tw-ring-offset-color: var(--color-success-100);
  }

  .md\:ring-offset-success-200 {
    --tw-ring-offset-color: var(--color-success-200);
  }

  .md\:ring-offset-success-300 {
    --tw-ring-offset-color: var(--color-success-300);
  }

  .md\:ring-offset-success-400 {
    --tw-ring-offset-color: var(--color-success-400);
  }

  .md\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .md\:ring-offset-transparent\/0 {
    --tw-ring-offset-color: #0000;
  }

  .md\:ring-offset-transparent\/10 {
    --tw-ring-offset-color: #0000001a;
  }

  .md\:ring-offset-transparent\/100 {
    --tw-ring-offset-color: #000;
  }

  .md\:ring-offset-transparent\/15 {
    --tw-ring-offset-color: #00000026;
  }

  .md\:ring-offset-transparent\/20 {
    --tw-ring-offset-color: #0003;
  }

  .md\:ring-offset-transparent\/25 {
    --tw-ring-offset-color: #00000040;
  }

  .md\:ring-offset-transparent\/30 {
    --tw-ring-offset-color: #0000004d;
  }

  .md\:ring-offset-transparent\/35 {
    --tw-ring-offset-color: #00000059;
  }

  .md\:ring-offset-transparent\/40 {
    --tw-ring-offset-color: #0006;
  }

  .md\:ring-offset-transparent\/45 {
    --tw-ring-offset-color: #00000073;
  }

  .md\:ring-offset-transparent\/5 {
    --tw-ring-offset-color: #0000000d;
  }

  .md\:ring-offset-transparent\/50 {
    --tw-ring-offset-color: #00000080;
  }

  .md\:ring-offset-transparent\/55 {
    --tw-ring-offset-color: #0000008c;
  }

  .md\:ring-offset-transparent\/60 {
    --tw-ring-offset-color: #0009;
  }

  .md\:ring-offset-transparent\/65 {
    --tw-ring-offset-color: #000000a6;
  }

  .md\:ring-offset-transparent\/70 {
    --tw-ring-offset-color: #000000b3;
  }

  .md\:ring-offset-transparent\/75 {
    --tw-ring-offset-color: #000000bf;
  }

  .md\:ring-offset-transparent\/80 {
    --tw-ring-offset-color: #000c;
  }

  .md\:ring-offset-transparent\/85 {
    --tw-ring-offset-color: #000000d9;
  }

  .md\:ring-offset-transparent\/90 {
    --tw-ring-offset-color: #000000e6;
  }

  .md\:ring-offset-transparent\/95 {
    --tw-ring-offset-color: #000000f2;
  }

  .md\:ring-offset-warning {
    --tw-ring-offset-color: var(--color-warning-300);
  }

  .md\:ring-offset-warning-100 {
    --tw-ring-offset-color: var(--color-warning-100);
  }

  .md\:ring-offset-warning-200 {
    --tw-ring-offset-color: var(--color-warning-200);
  }

  .md\:ring-offset-warning-300 {
    --tw-ring-offset-color: var(--color-warning-300);
  }

  .md\:ring-offset-warning-400 {
    --tw-ring-offset-color: var(--color-warning-400);
  }

  .md\:ring-offset-white {
    --tw-ring-offset-color: var(--color-white);
  }

  .md\:blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:blur-0 {
    --tw-blur: blur(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:blur-2xl {
    --tw-blur: blur(40px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:blur-3xl {
    --tw-blur: blur(64px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:blur-lg {
    --tw-blur: blur(16px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:blur-md {
    --tw-blur: blur(12px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:blur-none {
    --tw-blur: ;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:blur-sm {
    --tw-blur: blur(4px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:blur-xl {
    --tw-blur: blur(24px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-0 {
    --tw-brightness: brightness(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-100 {
    --tw-brightness: brightness(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-105 {
    --tw-brightness: brightness(1.05);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-110 {
    --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-125 {
    --tw-brightness: brightness(1.25);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-150 {
    --tw-brightness: brightness(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-200 {
    --tw-brightness: brightness(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-50 {
    --tw-brightness: brightness(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-75 {
    --tw-brightness: brightness(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-90 {
    --tw-brightness: brightness(.9);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:brightness-95 {
    --tw-brightness: brightness(.95);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:contrast-0 {
    --tw-contrast: contrast(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:contrast-100 {
    --tw-contrast: contrast(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:contrast-125 {
    --tw-contrast: contrast(1.25);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:contrast-150 {
    --tw-contrast: contrast(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:contrast-200 {
    --tw-contrast: contrast(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:contrast-50 {
    --tw-contrast: contrast(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:contrast-75 {
    --tw-contrast: contrast(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:grayscale-0 {
    --tw-grayscale: grayscale(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:-hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(-0deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:invert-0 {
    --tw-invert: invert(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:saturate-0 {
    --tw-saturate: saturate(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:saturate-100 {
    --tw-saturate: saturate(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:saturate-150 {
    --tw-saturate: saturate(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:saturate-200 {
    --tw-saturate: saturate(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:saturate-50 {
    --tw-saturate: saturate(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:sepia {
    --tw-sepia: sepia(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:sepia-0 {
    --tw-sepia: sepia(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:filter-none {
    filter: none;
  }

  .md\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-blur-none {
    --tw-backdrop-blur: ;
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:-backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(-0deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-15 {
    --tw-backdrop-opacity: opacity(.15);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-35 {
    --tw-backdrop-opacity: opacity(.35);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-45 {
    --tw-backdrop-opacity: opacity(.45);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-55 {
    --tw-backdrop-opacity: opacity(.55);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-65 {
    --tw-backdrop-opacity: opacity(.65);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-85 {
    --tw-backdrop-opacity: opacity(.85);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-filter {
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-filter-none {
    backdrop-filter: none;
  }

  .md\:transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition-none {
    transition-property: none;
  }

  .md\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:delay-0 {
    transition-delay: 0s;
  }

  .md\:delay-100 {
    transition-delay: .1s;
  }

  .md\:delay-1000 {
    transition-delay: 1s;
  }

  .md\:delay-150 {
    transition-delay: .15s;
  }

  .md\:delay-200 {
    transition-delay: .2s;
  }

  .md\:delay-300 {
    transition-delay: .3s;
  }

  .md\:delay-500 {
    transition-delay: .5s;
  }

  .md\:delay-700 {
    transition-delay: .7s;
  }

  .md\:delay-75 {
    transition-delay: 75ms;
  }

  .md\:duration-0 {
    transition-duration: 0s;
  }

  .md\:duration-100 {
    transition-duration: .1s;
  }

  .md\:duration-1000 {
    transition-duration: 1s;
  }

  .md\:duration-150 {
    transition-duration: .15s;
  }

  .md\:duration-200 {
    transition-duration: .2s;
  }

  .md\:duration-300 {
    transition-duration: .3s;
  }

  .md\:duration-500 {
    transition-duration: .5s;
  }

  .md\:duration-700 {
    transition-duration: .7s;
  }

  .md\:duration-75 {
    transition-duration: 75ms;
  }

  .md\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .md\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:ease-linear {
    transition-timing-function: linear;
  }

  .md\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .md\:will-change-auto {
    will-change: auto;
  }

  .md\:will-change-contents {
    will-change: contents;
  }

  .md\:will-change-scroll {
    will-change: scroll-position;
  }

  .md\:will-change-transform {
    will-change: transform;
  }

  .md\:contain-none {
    contain: none;
  }

  .md\:contain-content {
    contain: content;
  }

  .md\:contain-strict {
    contain: strict;
  }

  .md\:contain-size {
    --tw-contain-size: size;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .md\:contain-inline-size {
    --tw-contain-size: inline-size;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .md\:contain-layout {
    --tw-contain-layout: layout;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .md\:contain-paint {
    --tw-contain-paint: paint;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .md\:contain-style {
    --tw-contain-style: style;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .md\:content-none {
    --tw-content: none;
    content: var(--tw-content);
  }

  .md\:forced-color-adjust-auto {
    forced-color-adjust: auto;
  }

  .md\:forced-color-adjust-none {
    forced-color-adjust: none;
  }
}

@media (width >= 1024px) {
  .lg\:sr-only {
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .lg\:not-sr-only {
    clip: auto;
    white-space: normal;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .lg\:pointer-events-none {
    pointer-events: none;
  }

  .lg\:pointer-events-auto {
    pointer-events: auto;
  }

  .lg\:visible {
    visibility: visible;
  }

  .lg\:invisible {
    visibility: hidden;
  }

  .lg\:collapse {
    visibility: collapse;
  }

  .lg\:static {
    position: static;
  }

  .lg\:fixed {
    position: fixed;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:sticky {
    position: sticky;
  }

  .lg\:-inset-0 {
    inset: 0;
  }

  .lg\:-inset-0\.5 {
    inset: -.125rem;
  }

  .lg\:-inset-1 {
    inset: -.25rem;
  }

  .lg\:-inset-1\.5 {
    inset: -.375rem;
  }

  .lg\:-inset-1\/2 {
    inset: -50%;
  }

  .lg\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .lg\:-inset-1\/4 {
    inset: -25%;
  }

  .lg\:-inset-10 {
    inset: -2.5rem;
  }

  .lg\:-inset-11 {
    inset: -2.75rem;
  }

  .lg\:-inset-12 {
    inset: -3rem;
  }

  .lg\:-inset-14 {
    inset: -3.5rem;
  }

  .lg\:-inset-16 {
    inset: -4rem;
  }

  .lg\:-inset-2 {
    inset: -.5rem;
  }

  .lg\:-inset-2\.5 {
    inset: -.625rem;
  }

  .lg\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .lg\:-inset-2\/4 {
    inset: -50%;
  }

  .lg\:-inset-20 {
    inset: -5rem;
  }

  .lg\:-inset-24 {
    inset: -6rem;
  }

  .lg\:-inset-28 {
    inset: -7rem;
  }

  .lg\:-inset-3 {
    inset: -.75rem;
  }

  .lg\:-inset-3\.5 {
    inset: -.875rem;
  }

  .lg\:-inset-3\/4 {
    inset: -75%;
  }

  .lg\:-inset-32 {
    inset: -8rem;
  }

  .lg\:-inset-36 {
    inset: -9rem;
  }

  .lg\:-inset-4 {
    inset: -1rem;
  }

  .lg\:-inset-40 {
    inset: -10rem;
  }

  .lg\:-inset-44 {
    inset: -11rem;
  }

  .lg\:-inset-48 {
    inset: -12rem;
  }

  .lg\:-inset-5 {
    inset: -1.25rem;
  }

  .lg\:-inset-52 {
    inset: -13rem;
  }

  .lg\:-inset-56 {
    inset: -14rem;
  }

  .lg\:-inset-6 {
    inset: -1.5rem;
  }

  .lg\:-inset-60 {
    inset: -15rem;
  }

  .lg\:-inset-64 {
    inset: -16rem;
  }

  .lg\:-inset-7 {
    inset: -1.75rem;
  }

  .lg\:-inset-72 {
    inset: -18rem;
  }

  .lg\:-inset-8 {
    inset: -2rem;
  }

  .lg\:-inset-80 {
    inset: -20rem;
  }

  .lg\:-inset-9 {
    inset: -2.25rem;
  }

  .lg\:-inset-96 {
    inset: -24rem;
  }

  .lg\:-inset-full {
    inset: -100%;
  }

  .lg\:-inset-px {
    inset: -1px;
  }

  .lg\:inset-0 {
    inset: 0;
  }

  .lg\:inset-0\.5 {
    inset: .125rem;
  }

  .lg\:inset-1 {
    inset: .25rem;
  }

  .lg\:inset-1\.5 {
    inset: .375rem;
  }

  .lg\:inset-1\/2 {
    inset: 50%;
  }

  .lg\:inset-1\/3 {
    inset: 33.3333%;
  }

  .lg\:inset-1\/4 {
    inset: 25%;
  }

  .lg\:inset-10 {
    inset: 2.5rem;
  }

  .lg\:inset-11 {
    inset: 2.75rem;
  }

  .lg\:inset-12 {
    inset: 3rem;
  }

  .lg\:inset-14 {
    inset: 3.5rem;
  }

  .lg\:inset-16 {
    inset: 4rem;
  }

  .lg\:inset-2 {
    inset: .5rem;
  }

  .lg\:inset-2\.5 {
    inset: .625rem;
  }

  .lg\:inset-2\/3 {
    inset: 66.6667%;
  }

  .lg\:inset-2\/4 {
    inset: 50%;
  }

  .lg\:inset-20 {
    inset: 5rem;
  }

  .lg\:inset-24 {
    inset: 6rem;
  }

  .lg\:inset-28 {
    inset: 7rem;
  }

  .lg\:inset-3 {
    inset: .75rem;
  }

  .lg\:inset-3\.5 {
    inset: .875rem;
  }

  .lg\:inset-3\/4 {
    inset: 75%;
  }

  .lg\:inset-32 {
    inset: 8rem;
  }

  .lg\:inset-36 {
    inset: 9rem;
  }

  .lg\:inset-4 {
    inset: 1rem;
  }

  .lg\:inset-40 {
    inset: 10rem;
  }

  .lg\:inset-44 {
    inset: 11rem;
  }

  .lg\:inset-48 {
    inset: 12rem;
  }

  .lg\:inset-5 {
    inset: 1.25rem;
  }

  .lg\:inset-52 {
    inset: 13rem;
  }

  .lg\:inset-56 {
    inset: 14rem;
  }

  .lg\:inset-6 {
    inset: 1.5rem;
  }

  .lg\:inset-60 {
    inset: 15rem;
  }

  .lg\:inset-64 {
    inset: 16rem;
  }

  .lg\:inset-7 {
    inset: 1.75rem;
  }

  .lg\:inset-72 {
    inset: 18rem;
  }

  .lg\:inset-8 {
    inset: 2rem;
  }

  .lg\:inset-80 {
    inset: 20rem;
  }

  .lg\:inset-9 {
    inset: 2.25rem;
  }

  .lg\:inset-96 {
    inset: 24rem;
  }

  .lg\:inset-auto {
    inset: auto;
  }

  .lg\:inset-full {
    inset: 100%;
  }

  .lg\:inset-px {
    inset: 1px;
  }

  .lg\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .lg\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .lg\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .lg\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .lg\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .lg\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .lg\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .lg\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .lg\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .lg\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .lg\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .lg\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .lg\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .lg\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .lg\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .lg\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .lg\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .lg\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .lg\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .lg\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .lg\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .lg\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .lg\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .lg\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .lg\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .lg\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .lg\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .lg\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .lg\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .lg\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .lg\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .lg\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .lg\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .lg\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .lg\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .lg\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .lg\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .lg\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .lg\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .lg\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .lg\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .lg\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .lg\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .lg\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .lg\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .lg\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .lg\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .lg\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .lg\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .lg\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .lg\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .lg\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .lg\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .lg\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .lg\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .lg\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .lg\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .lg\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .lg\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .lg\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .lg\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .lg\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .lg\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .lg\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .lg\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .lg\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .lg\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .lg\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .lg\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .lg\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .lg\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .lg\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .lg\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .lg\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .lg\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .lg\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .lg\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .lg\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .lg\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .lg\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .lg\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .lg\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .lg\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .lg\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .lg\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .lg\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .lg\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .lg\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .lg\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .lg\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .lg\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .lg\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .lg\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .lg\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .lg\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .lg\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .lg\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .lg\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .lg\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .lg\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .lg\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .lg\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .lg\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .lg\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .lg\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .lg\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .lg\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .lg\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .lg\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .lg\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .lg\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .lg\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .lg\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .lg\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .lg\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .lg\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .lg\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .lg\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .lg\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .lg\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .lg\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .lg\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .lg\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .lg\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .lg\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .lg\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .lg\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .lg\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .lg\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .lg\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .lg\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .lg\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .lg\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .lg\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .lg\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .lg\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .lg\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .lg\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .lg\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .lg\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .lg\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .lg\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .lg\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .lg\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .lg\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .lg\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .lg\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .lg\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .lg\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .lg\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .lg\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .lg\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .lg\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .lg\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .lg\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .lg\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .lg\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .lg\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .lg\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .lg\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .lg\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .lg\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .lg\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .lg\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .lg\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .lg\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .lg\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .lg\:-bottom-0 {
    bottom: 0;
  }

  .lg\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .lg\:-bottom-1 {
    bottom: -.25rem;
  }

  .lg\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .lg\:-bottom-1\/2 {
    bottom: -50%;
  }

  .lg\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .lg\:-bottom-1\/4 {
    bottom: -25%;
  }

  .lg\:-bottom-10 {
    bottom: -2.5rem;
  }

  .lg\:-bottom-11 {
    bottom: -2.75rem;
  }

  .lg\:-bottom-12 {
    bottom: -3rem;
  }

  .lg\:-bottom-14 {
    bottom: -3.5rem;
  }

  .lg\:-bottom-16 {
    bottom: -4rem;
  }

  .lg\:-bottom-2 {
    bottom: -.5rem;
  }

  .lg\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .lg\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .lg\:-bottom-2\/4 {
    bottom: -50%;
  }

  .lg\:-bottom-20 {
    bottom: -5rem;
  }

  .lg\:-bottom-24 {
    bottom: -6rem;
  }

  .lg\:-bottom-28 {
    bottom: -7rem;
  }

  .lg\:-bottom-3 {
    bottom: -.75rem;
  }

  .lg\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .lg\:-bottom-3\/4 {
    bottom: -75%;
  }

  .lg\:-bottom-32 {
    bottom: -8rem;
  }

  .lg\:-bottom-36 {
    bottom: -9rem;
  }

  .lg\:-bottom-4 {
    bottom: -1rem;
  }

  .lg\:-bottom-40 {
    bottom: -10rem;
  }

  .lg\:-bottom-44 {
    bottom: -11rem;
  }

  .lg\:-bottom-48 {
    bottom: -12rem;
  }

  .lg\:-bottom-5 {
    bottom: -1.25rem;
  }

  .lg\:-bottom-52 {
    bottom: -13rem;
  }

  .lg\:-bottom-56 {
    bottom: -14rem;
  }

  .lg\:-bottom-6 {
    bottom: -1.5rem;
  }

  .lg\:-bottom-60 {
    bottom: -15rem;
  }

  .lg\:-bottom-64 {
    bottom: -16rem;
  }

  .lg\:-bottom-7 {
    bottom: -1.75rem;
  }

  .lg\:-bottom-72 {
    bottom: -18rem;
  }

  .lg\:-bottom-8 {
    bottom: -2rem;
  }

  .lg\:-bottom-80 {
    bottom: -20rem;
  }

  .lg\:-bottom-9 {
    bottom: -2.25rem;
  }

  .lg\:-bottom-96 {
    bottom: -24rem;
  }

  .lg\:-bottom-full {
    bottom: -100%;
  }

  .lg\:-bottom-px {
    bottom: -1px;
  }

  .lg\:-end-0 {
    inset-inline-end: 0;
  }

  .lg\:-end-0\.5 {
    inset-inline-end: -.125rem;
  }

  .lg\:-end-1 {
    inset-inline-end: -.25rem;
  }

  .lg\:-end-1\.5 {
    inset-inline-end: -.375rem;
  }

  .lg\:-end-1\/2 {
    inset-inline-end: -50%;
  }

  .lg\:-end-1\/3 {
    inset-inline-end: -33.3333%;
  }

  .lg\:-end-1\/4 {
    inset-inline-end: -25%;
  }

  .lg\:-end-10 {
    inset-inline-end: -2.5rem;
  }

  .lg\:-end-11 {
    inset-inline-end: -2.75rem;
  }

  .lg\:-end-12 {
    inset-inline-end: -3rem;
  }

  .lg\:-end-14 {
    inset-inline-end: -3.5rem;
  }

  .lg\:-end-16 {
    inset-inline-end: -4rem;
  }

  .lg\:-end-2 {
    inset-inline-end: -.5rem;
  }

  .lg\:-end-2\.5 {
    inset-inline-end: -.625rem;
  }

  .lg\:-end-2\/3 {
    inset-inline-end: -66.6667%;
  }

  .lg\:-end-2\/4 {
    inset-inline-end: -50%;
  }

  .lg\:-end-20 {
    inset-inline-end: -5rem;
  }

  .lg\:-end-24 {
    inset-inline-end: -6rem;
  }

  .lg\:-end-28 {
    inset-inline-end: -7rem;
  }

  .lg\:-end-3 {
    inset-inline-end: -.75rem;
  }

  .lg\:-end-3\.5 {
    inset-inline-end: -.875rem;
  }

  .lg\:-end-3\/4 {
    inset-inline-end: -75%;
  }

  .lg\:-end-32 {
    inset-inline-end: -8rem;
  }

  .lg\:-end-36 {
    inset-inline-end: -9rem;
  }

  .lg\:-end-4 {
    inset-inline-end: -1rem;
  }

  .lg\:-end-40 {
    inset-inline-end: -10rem;
  }

  .lg\:-end-44 {
    inset-inline-end: -11rem;
  }

  .lg\:-end-48 {
    inset-inline-end: -12rem;
  }

  .lg\:-end-5 {
    inset-inline-end: -1.25rem;
  }

  .lg\:-end-52 {
    inset-inline-end: -13rem;
  }

  .lg\:-end-56 {
    inset-inline-end: -14rem;
  }

  .lg\:-end-6 {
    inset-inline-end: -1.5rem;
  }

  .lg\:-end-60 {
    inset-inline-end: -15rem;
  }

  .lg\:-end-64 {
    inset-inline-end: -16rem;
  }

  .lg\:-end-7 {
    inset-inline-end: -1.75rem;
  }

  .lg\:-end-72 {
    inset-inline-end: -18rem;
  }

  .lg\:-end-8 {
    inset-inline-end: -2rem;
  }

  .lg\:-end-80 {
    inset-inline-end: -20rem;
  }

  .lg\:-end-9 {
    inset-inline-end: -2.25rem;
  }

  .lg\:-end-96 {
    inset-inline-end: -24rem;
  }

  .lg\:-end-full {
    inset-inline-end: -100%;
  }

  .lg\:-end-px {
    inset-inline-end: -1px;
  }

  .lg\:-left-0 {
    left: 0;
  }

  .lg\:-left-0\.5 {
    left: -.125rem;
  }

  .lg\:-left-1 {
    left: -.25rem;
  }

  .lg\:-left-1\.5 {
    left: -.375rem;
  }

  .lg\:-left-1\/2 {
    left: -50%;
  }

  .lg\:-left-1\/3 {
    left: -33.3333%;
  }

  .lg\:-left-1\/4 {
    left: -25%;
  }

  .lg\:-left-10 {
    left: -2.5rem;
  }

  .lg\:-left-11 {
    left: -2.75rem;
  }

  .lg\:-left-12 {
    left: -3rem;
  }

  .lg\:-left-14 {
    left: -3.5rem;
  }

  .lg\:-left-16 {
    left: -4rem;
  }

  .lg\:-left-2 {
    left: -.5rem;
  }

  .lg\:-left-2\.5 {
    left: -.625rem;
  }

  .lg\:-left-2\/3 {
    left: -66.6667%;
  }

  .lg\:-left-2\/4 {
    left: -50%;
  }

  .lg\:-left-20 {
    left: -5rem;
  }

  .lg\:-left-24 {
    left: -6rem;
  }

  .lg\:-left-28 {
    left: -7rem;
  }

  .lg\:-left-3 {
    left: -.75rem;
  }

  .lg\:-left-3\.5 {
    left: -.875rem;
  }

  .lg\:-left-3\/4 {
    left: -75%;
  }

  .lg\:-left-32 {
    left: -8rem;
  }

  .lg\:-left-36 {
    left: -9rem;
  }

  .lg\:-left-4 {
    left: -1rem;
  }

  .lg\:-left-40 {
    left: -10rem;
  }

  .lg\:-left-44 {
    left: -11rem;
  }

  .lg\:-left-48 {
    left: -12rem;
  }

  .lg\:-left-5 {
    left: -1.25rem;
  }

  .lg\:-left-52 {
    left: -13rem;
  }

  .lg\:-left-56 {
    left: -14rem;
  }

  .lg\:-left-6 {
    left: -1.5rem;
  }

  .lg\:-left-60 {
    left: -15rem;
  }

  .lg\:-left-64 {
    left: -16rem;
  }

  .lg\:-left-7 {
    left: -1.75rem;
  }

  .lg\:-left-72 {
    left: -18rem;
  }

  .lg\:-left-8 {
    left: -2rem;
  }

  .lg\:-left-80 {
    left: -20rem;
  }

  .lg\:-left-9 {
    left: -2.25rem;
  }

  .lg\:-left-96 {
    left: -24rem;
  }

  .lg\:-left-full {
    left: -100%;
  }

  .lg\:-left-px {
    left: -1px;
  }

  .lg\:-right-0 {
    right: 0;
  }

  .lg\:-right-0\.5 {
    right: -.125rem;
  }

  .lg\:-right-1 {
    right: -.25rem;
  }

  .lg\:-right-1\.5 {
    right: -.375rem;
  }

  .lg\:-right-1\/2 {
    right: -50%;
  }

  .lg\:-right-1\/3 {
    right: -33.3333%;
  }

  .lg\:-right-1\/4 {
    right: -25%;
  }

  .lg\:-right-10 {
    right: -2.5rem;
  }

  .lg\:-right-11 {
    right: -2.75rem;
  }

  .lg\:-right-12 {
    right: -3rem;
  }

  .lg\:-right-14 {
    right: -3.5rem;
  }

  .lg\:-right-16 {
    right: -4rem;
  }

  .lg\:-right-2 {
    right: -.5rem;
  }

  .lg\:-right-2\.5 {
    right: -.625rem;
  }

  .lg\:-right-2\/3 {
    right: -66.6667%;
  }

  .lg\:-right-2\/4 {
    right: -50%;
  }

  .lg\:-right-20 {
    right: -5rem;
  }

  .lg\:-right-24 {
    right: -6rem;
  }

  .lg\:-right-28 {
    right: -7rem;
  }

  .lg\:-right-3 {
    right: -.75rem;
  }

  .lg\:-right-3\.5 {
    right: -.875rem;
  }

  .lg\:-right-3\/4 {
    right: -75%;
  }

  .lg\:-right-32 {
    right: -8rem;
  }

  .lg\:-right-36 {
    right: -9rem;
  }

  .lg\:-right-4 {
    right: -1rem;
  }

  .lg\:-right-40 {
    right: -10rem;
  }

  .lg\:-right-44 {
    right: -11rem;
  }

  .lg\:-right-48 {
    right: -12rem;
  }

  .lg\:-right-5 {
    right: -1.25rem;
  }

  .lg\:-right-52 {
    right: -13rem;
  }

  .lg\:-right-56 {
    right: -14rem;
  }

  .lg\:-right-6 {
    right: -1.5rem;
  }

  .lg\:-right-60 {
    right: -15rem;
  }

  .lg\:-right-64 {
    right: -16rem;
  }

  .lg\:-right-7 {
    right: -1.75rem;
  }

  .lg\:-right-72 {
    right: -18rem;
  }

  .lg\:-right-8 {
    right: -2rem;
  }

  .lg\:-right-80 {
    right: -20rem;
  }

  .lg\:-right-9 {
    right: -2.25rem;
  }

  .lg\:-right-96 {
    right: -24rem;
  }

  .lg\:-right-full {
    right: -100%;
  }

  .lg\:-right-px {
    right: -1px;
  }

  .lg\:-start-0 {
    inset-inline-start: 0;
  }

  .lg\:-start-0\.5 {
    inset-inline-start: -.125rem;
  }

  .lg\:-start-1 {
    inset-inline-start: -.25rem;
  }

  .lg\:-start-1\.5 {
    inset-inline-start: -.375rem;
  }

  .lg\:-start-1\/2 {
    inset-inline-start: -50%;
  }

  .lg\:-start-1\/3 {
    inset-inline-start: -33.3333%;
  }

  .lg\:-start-1\/4 {
    inset-inline-start: -25%;
  }

  .lg\:-start-10 {
    inset-inline-start: -2.5rem;
  }

  .lg\:-start-11 {
    inset-inline-start: -2.75rem;
  }

  .lg\:-start-12 {
    inset-inline-start: -3rem;
  }

  .lg\:-start-14 {
    inset-inline-start: -3.5rem;
  }

  .lg\:-start-16 {
    inset-inline-start: -4rem;
  }

  .lg\:-start-2 {
    inset-inline-start: -.5rem;
  }

  .lg\:-start-2\.5 {
    inset-inline-start: -.625rem;
  }

  .lg\:-start-2\/3 {
    inset-inline-start: -66.6667%;
  }

  .lg\:-start-2\/4 {
    inset-inline-start: -50%;
  }

  .lg\:-start-20 {
    inset-inline-start: -5rem;
  }

  .lg\:-start-24 {
    inset-inline-start: -6rem;
  }

  .lg\:-start-28 {
    inset-inline-start: -7rem;
  }

  .lg\:-start-3 {
    inset-inline-start: -.75rem;
  }

  .lg\:-start-3\.5 {
    inset-inline-start: -.875rem;
  }

  .lg\:-start-3\/4 {
    inset-inline-start: -75%;
  }

  .lg\:-start-32 {
    inset-inline-start: -8rem;
  }

  .lg\:-start-36 {
    inset-inline-start: -9rem;
  }

  .lg\:-start-4 {
    inset-inline-start: -1rem;
  }

  .lg\:-start-40 {
    inset-inline-start: -10rem;
  }

  .lg\:-start-44 {
    inset-inline-start: -11rem;
  }

  .lg\:-start-48 {
    inset-inline-start: -12rem;
  }

  .lg\:-start-5 {
    inset-inline-start: -1.25rem;
  }

  .lg\:-start-52 {
    inset-inline-start: -13rem;
  }

  .lg\:-start-56 {
    inset-inline-start: -14rem;
  }

  .lg\:-start-6 {
    inset-inline-start: -1.5rem;
  }

  .lg\:-start-60 {
    inset-inline-start: -15rem;
  }

  .lg\:-start-64 {
    inset-inline-start: -16rem;
  }

  .lg\:-start-7 {
    inset-inline-start: -1.75rem;
  }

  .lg\:-start-72 {
    inset-inline-start: -18rem;
  }

  .lg\:-start-8 {
    inset-inline-start: -2rem;
  }

  .lg\:-start-80 {
    inset-inline-start: -20rem;
  }

  .lg\:-start-9 {
    inset-inline-start: -2.25rem;
  }

  .lg\:-start-96 {
    inset-inline-start: -24rem;
  }

  .lg\:-start-full {
    inset-inline-start: -100%;
  }

  .lg\:-start-px {
    inset-inline-start: -1px;
  }

  .lg\:-top-0 {
    top: 0;
  }

  .lg\:-top-0\.5 {
    top: -.125rem;
  }

  .lg\:-top-1 {
    top: -.25rem;
  }

  .lg\:-top-1\.5 {
    top: -.375rem;
  }

  .lg\:-top-1\/2 {
    top: -50%;
  }

  .lg\:-top-1\/3 {
    top: -33.3333%;
  }

  .lg\:-top-1\/4 {
    top: -25%;
  }

  .lg\:-top-10 {
    top: -2.5rem;
  }

  .lg\:-top-11 {
    top: -2.75rem;
  }

  .lg\:-top-12 {
    top: -3rem;
  }

  .lg\:-top-14 {
    top: -3.5rem;
  }

  .lg\:-top-16 {
    top: -4rem;
  }

  .lg\:-top-2 {
    top: -.5rem;
  }

  .lg\:-top-2\.5 {
    top: -.625rem;
  }

  .lg\:-top-2\/3 {
    top: -66.6667%;
  }

  .lg\:-top-2\/4 {
    top: -50%;
  }

  .lg\:-top-20 {
    top: -5rem;
  }

  .lg\:-top-24 {
    top: -6rem;
  }

  .lg\:-top-28 {
    top: -7rem;
  }

  .lg\:-top-3 {
    top: -.75rem;
  }

  .lg\:-top-3\.5 {
    top: -.875rem;
  }

  .lg\:-top-3\/4 {
    top: -75%;
  }

  .lg\:-top-32 {
    top: -8rem;
  }

  .lg\:-top-36 {
    top: -9rem;
  }

  .lg\:-top-4 {
    top: -1rem;
  }

  .lg\:-top-40 {
    top: -10rem;
  }

  .lg\:-top-44 {
    top: -11rem;
  }

  .lg\:-top-48 {
    top: -12rem;
  }

  .lg\:-top-5 {
    top: -1.25rem;
  }

  .lg\:-top-52 {
    top: -13rem;
  }

  .lg\:-top-56 {
    top: -14rem;
  }

  .lg\:-top-6 {
    top: -1.5rem;
  }

  .lg\:-top-60 {
    top: -15rem;
  }

  .lg\:-top-64 {
    top: -16rem;
  }

  .lg\:-top-7 {
    top: -1.75rem;
  }

  .lg\:-top-72 {
    top: -18rem;
  }

  .lg\:-top-8 {
    top: -2rem;
  }

  .lg\:-top-80 {
    top: -20rem;
  }

  .lg\:-top-9 {
    top: -2.25rem;
  }

  .lg\:-top-96 {
    top: -24rem;
  }

  .lg\:-top-full {
    top: -100%;
  }

  .lg\:-top-px {
    top: -1px;
  }

  .lg\:bottom-0 {
    bottom: 0;
  }

  .lg\:bottom-0\.5 {
    bottom: .125rem;
  }

  .lg\:bottom-1 {
    bottom: .25rem;
  }

  .lg\:bottom-1\.5 {
    bottom: .375rem;
  }

  .lg\:bottom-1\/2 {
    bottom: 50%;
  }

  .lg\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .lg\:bottom-1\/4 {
    bottom: 25%;
  }

  .lg\:bottom-10 {
    bottom: 2.5rem;
  }

  .lg\:bottom-11 {
    bottom: 2.75rem;
  }

  .lg\:bottom-12 {
    bottom: 3rem;
  }

  .lg\:bottom-14 {
    bottom: 3.5rem;
  }

  .lg\:bottom-16 {
    bottom: 4rem;
  }

  .lg\:bottom-2 {
    bottom: .5rem;
  }

  .lg\:bottom-2\.5 {
    bottom: .625rem;
  }

  .lg\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .lg\:bottom-2\/4 {
    bottom: 50%;
  }

  .lg\:bottom-20 {
    bottom: 5rem;
  }

  .lg\:bottom-24 {
    bottom: 6rem;
  }

  .lg\:bottom-28 {
    bottom: 7rem;
  }

  .lg\:bottom-3 {
    bottom: .75rem;
  }

  .lg\:bottom-3\.5 {
    bottom: .875rem;
  }

  .lg\:bottom-3\/4 {
    bottom: 75%;
  }

  .lg\:bottom-32 {
    bottom: 8rem;
  }

  .lg\:bottom-36 {
    bottom: 9rem;
  }

  .lg\:bottom-4 {
    bottom: 1rem;
  }

  .lg\:bottom-40 {
    bottom: 10rem;
  }

  .lg\:bottom-44 {
    bottom: 11rem;
  }

  .lg\:bottom-48 {
    bottom: 12rem;
  }

  .lg\:bottom-5 {
    bottom: 1.25rem;
  }

  .lg\:bottom-52 {
    bottom: 13rem;
  }

  .lg\:bottom-56 {
    bottom: 14rem;
  }

  .lg\:bottom-6 {
    bottom: 1.5rem;
  }

  .lg\:bottom-60 {
    bottom: 15rem;
  }

  .lg\:bottom-64 {
    bottom: 16rem;
  }

  .lg\:bottom-7 {
    bottom: 1.75rem;
  }

  .lg\:bottom-72 {
    bottom: 18rem;
  }

  .lg\:bottom-8 {
    bottom: 2rem;
  }

  .lg\:bottom-80 {
    bottom: 20rem;
  }

  .lg\:bottom-9 {
    bottom: 2.25rem;
  }

  .lg\:bottom-96 {
    bottom: 24rem;
  }

  .lg\:bottom-auto {
    bottom: auto;
  }

  .lg\:bottom-full {
    bottom: 100%;
  }

  .lg\:bottom-px {
    bottom: 1px;
  }

  .lg\:end-0 {
    inset-inline-end: 0;
  }

  .lg\:end-0\.5 {
    inset-inline-end: .125rem;
  }

  .lg\:end-1 {
    inset-inline-end: .25rem;
  }

  .lg\:end-1\.5 {
    inset-inline-end: .375rem;
  }

  .lg\:end-1\/2 {
    inset-inline-end: 50%;
  }

  .lg\:end-1\/3 {
    inset-inline-end: 33.3333%;
  }

  .lg\:end-1\/4 {
    inset-inline-end: 25%;
  }

  .lg\:end-10 {
    inset-inline-end: 2.5rem;
  }

  .lg\:end-11 {
    inset-inline-end: 2.75rem;
  }

  .lg\:end-12 {
    inset-inline-end: 3rem;
  }

  .lg\:end-14 {
    inset-inline-end: 3.5rem;
  }

  .lg\:end-16 {
    inset-inline-end: 4rem;
  }

  .lg\:end-2 {
    inset-inline-end: .5rem;
  }

  .lg\:end-2\.5 {
    inset-inline-end: .625rem;
  }

  .lg\:end-2\/3 {
    inset-inline-end: 66.6667%;
  }

  .lg\:end-2\/4 {
    inset-inline-end: 50%;
  }

  .lg\:end-20 {
    inset-inline-end: 5rem;
  }

  .lg\:end-24 {
    inset-inline-end: 6rem;
  }

  .lg\:end-28 {
    inset-inline-end: 7rem;
  }

  .lg\:end-3 {
    inset-inline-end: .75rem;
  }

  .lg\:end-3\.5 {
    inset-inline-end: .875rem;
  }

  .lg\:end-3\/4 {
    inset-inline-end: 75%;
  }

  .lg\:end-32 {
    inset-inline-end: 8rem;
  }

  .lg\:end-36 {
    inset-inline-end: 9rem;
  }

  .lg\:end-4 {
    inset-inline-end: 1rem;
  }

  .lg\:end-40 {
    inset-inline-end: 10rem;
  }

  .lg\:end-44 {
    inset-inline-end: 11rem;
  }

  .lg\:end-48 {
    inset-inline-end: 12rem;
  }

  .lg\:end-5 {
    inset-inline-end: 1.25rem;
  }

  .lg\:end-52 {
    inset-inline-end: 13rem;
  }

  .lg\:end-56 {
    inset-inline-end: 14rem;
  }

  .lg\:end-6 {
    inset-inline-end: 1.5rem;
  }

  .lg\:end-60 {
    inset-inline-end: 15rem;
  }

  .lg\:end-64 {
    inset-inline-end: 16rem;
  }

  .lg\:end-7 {
    inset-inline-end: 1.75rem;
  }

  .lg\:end-72 {
    inset-inline-end: 18rem;
  }

  .lg\:end-8 {
    inset-inline-end: 2rem;
  }

  .lg\:end-80 {
    inset-inline-end: 20rem;
  }

  .lg\:end-9 {
    inset-inline-end: 2.25rem;
  }

  .lg\:end-96 {
    inset-inline-end: 24rem;
  }

  .lg\:end-auto {
    inset-inline-end: auto;
  }

  .lg\:end-full {
    inset-inline-end: 100%;
  }

  .lg\:end-px {
    inset-inline-end: 1px;
  }

  .lg\:left-0 {
    left: 0;
  }

  .lg\:left-0\.5 {
    left: .125rem;
  }

  .lg\:left-1 {
    left: .25rem;
  }

  .lg\:left-1\.5 {
    left: .375rem;
  }

  .lg\:left-1\/2 {
    left: 50%;
  }

  .lg\:left-1\/3 {
    left: 33.3333%;
  }

  .lg\:left-1\/4 {
    left: 25%;
  }

  .lg\:left-10 {
    left: 2.5rem;
  }

  .lg\:left-11 {
    left: 2.75rem;
  }

  .lg\:left-12 {
    left: 3rem;
  }

  .lg\:left-14 {
    left: 3.5rem;
  }

  .lg\:left-16 {
    left: 4rem;
  }

  .lg\:left-2 {
    left: .5rem;
  }

  .lg\:left-2\.5 {
    left: .625rem;
  }

  .lg\:left-2\/3 {
    left: 66.6667%;
  }

  .lg\:left-2\/4 {
    left: 50%;
  }

  .lg\:left-20 {
    left: 5rem;
  }

  .lg\:left-24 {
    left: 6rem;
  }

  .lg\:left-28 {
    left: 7rem;
  }

  .lg\:left-3 {
    left: .75rem;
  }

  .lg\:left-3\.5 {
    left: .875rem;
  }

  .lg\:left-3\/4 {
    left: 75%;
  }

  .lg\:left-32 {
    left: 8rem;
  }

  .lg\:left-36 {
    left: 9rem;
  }

  .lg\:left-4 {
    left: 1rem;
  }

  .lg\:left-40 {
    left: 10rem;
  }

  .lg\:left-44 {
    left: 11rem;
  }

  .lg\:left-48 {
    left: 12rem;
  }

  .lg\:left-5 {
    left: 1.25rem;
  }

  .lg\:left-52 {
    left: 13rem;
  }

  .lg\:left-56 {
    left: 14rem;
  }

  .lg\:left-6 {
    left: 1.5rem;
  }

  .lg\:left-60 {
    left: 15rem;
  }

  .lg\:left-64 {
    left: 16rem;
  }

  .lg\:left-7 {
    left: 1.75rem;
  }

  .lg\:left-72 {
    left: 18rem;
  }

  .lg\:left-8 {
    left: 2rem;
  }

  .lg\:left-80 {
    left: 20rem;
  }

  .lg\:left-9 {
    left: 2.25rem;
  }

  .lg\:left-96 {
    left: 24rem;
  }

  .lg\:left-auto {
    left: auto;
  }

  .lg\:left-full {
    left: 100%;
  }

  .lg\:left-px {
    left: 1px;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:right-0\.5 {
    right: .125rem;
  }

  .lg\:right-1 {
    right: .25rem;
  }

  .lg\:right-1\.5 {
    right: .375rem;
  }

  .lg\:right-1\/2 {
    right: 50%;
  }

  .lg\:right-1\/3 {
    right: 33.3333%;
  }

  .lg\:right-1\/4 {
    right: 25%;
  }

  .lg\:right-10 {
    right: 2.5rem;
  }

  .lg\:right-11 {
    right: 2.75rem;
  }

  .lg\:right-12 {
    right: 3rem;
  }

  .lg\:right-14 {
    right: 3.5rem;
  }

  .lg\:right-16 {
    right: 4rem;
  }

  .lg\:right-2 {
    right: .5rem;
  }

  .lg\:right-2\.5 {
    right: .625rem;
  }

  .lg\:right-2\/3 {
    right: 66.6667%;
  }

  .lg\:right-2\/4 {
    right: 50%;
  }

  .lg\:right-20 {
    right: 5rem;
  }

  .lg\:right-24 {
    right: 6rem;
  }

  .lg\:right-28 {
    right: 7rem;
  }

  .lg\:right-3 {
    right: .75rem;
  }

  .lg\:right-3\.5 {
    right: .875rem;
  }

  .lg\:right-3\/4 {
    right: 75%;
  }

  .lg\:right-32 {
    right: 8rem;
  }

  .lg\:right-36 {
    right: 9rem;
  }

  .lg\:right-4 {
    right: 1rem;
  }

  .lg\:right-40 {
    right: 10rem;
  }

  .lg\:right-44 {
    right: 11rem;
  }

  .lg\:right-48 {
    right: 12rem;
  }

  .lg\:right-5 {
    right: 1.25rem;
  }

  .lg\:right-52 {
    right: 13rem;
  }

  .lg\:right-56 {
    right: 14rem;
  }

  .lg\:right-6 {
    right: 1.5rem;
  }

  .lg\:right-60 {
    right: 15rem;
  }

  .lg\:right-64 {
    right: 16rem;
  }

  .lg\:right-7 {
    right: 1.75rem;
  }

  .lg\:right-72 {
    right: 18rem;
  }

  .lg\:right-8 {
    right: 2rem;
  }

  .lg\:right-80 {
    right: 20rem;
  }

  .lg\:right-9 {
    right: 2.25rem;
  }

  .lg\:right-96 {
    right: 24rem;
  }

  .lg\:right-auto {
    right: auto;
  }

  .lg\:right-full {
    right: 100%;
  }

  .lg\:right-px {
    right: 1px;
  }

  .lg\:start-0 {
    inset-inline-start: 0;
  }

  .lg\:start-0\.5 {
    inset-inline-start: .125rem;
  }

  .lg\:start-1 {
    inset-inline-start: .25rem;
  }

  .lg\:start-1\.5 {
    inset-inline-start: .375rem;
  }

  .lg\:start-1\/2 {
    inset-inline-start: 50%;
  }

  .lg\:start-1\/3 {
    inset-inline-start: 33.3333%;
  }

  .lg\:start-1\/4 {
    inset-inline-start: 25%;
  }

  .lg\:start-10 {
    inset-inline-start: 2.5rem;
  }

  .lg\:start-11 {
    inset-inline-start: 2.75rem;
  }

  .lg\:start-12 {
    inset-inline-start: 3rem;
  }

  .lg\:start-14 {
    inset-inline-start: 3.5rem;
  }

  .lg\:start-16 {
    inset-inline-start: 4rem;
  }

  .lg\:start-2 {
    inset-inline-start: .5rem;
  }

  .lg\:start-2\.5 {
    inset-inline-start: .625rem;
  }

  .lg\:start-2\/3 {
    inset-inline-start: 66.6667%;
  }

  .lg\:start-2\/4 {
    inset-inline-start: 50%;
  }

  .lg\:start-20 {
    inset-inline-start: 5rem;
  }

  .lg\:start-24 {
    inset-inline-start: 6rem;
  }

  .lg\:start-28 {
    inset-inline-start: 7rem;
  }

  .lg\:start-3 {
    inset-inline-start: .75rem;
  }

  .lg\:start-3\.5 {
    inset-inline-start: .875rem;
  }

  .lg\:start-3\/4 {
    inset-inline-start: 75%;
  }

  .lg\:start-32 {
    inset-inline-start: 8rem;
  }

  .lg\:start-36 {
    inset-inline-start: 9rem;
  }

  .lg\:start-4 {
    inset-inline-start: 1rem;
  }

  .lg\:start-40 {
    inset-inline-start: 10rem;
  }

  .lg\:start-44 {
    inset-inline-start: 11rem;
  }

  .lg\:start-48 {
    inset-inline-start: 12rem;
  }

  .lg\:start-5 {
    inset-inline-start: 1.25rem;
  }

  .lg\:start-52 {
    inset-inline-start: 13rem;
  }

  .lg\:start-56 {
    inset-inline-start: 14rem;
  }

  .lg\:start-6 {
    inset-inline-start: 1.5rem;
  }

  .lg\:start-60 {
    inset-inline-start: 15rem;
  }

  .lg\:start-64 {
    inset-inline-start: 16rem;
  }

  .lg\:start-7 {
    inset-inline-start: 1.75rem;
  }

  .lg\:start-72 {
    inset-inline-start: 18rem;
  }

  .lg\:start-8 {
    inset-inline-start: 2rem;
  }

  .lg\:start-80 {
    inset-inline-start: 20rem;
  }

  .lg\:start-9 {
    inset-inline-start: 2.25rem;
  }

  .lg\:start-96 {
    inset-inline-start: 24rem;
  }

  .lg\:start-auto {
    inset-inline-start: auto;
  }

  .lg\:start-full {
    inset-inline-start: 100%;
  }

  .lg\:start-px {
    inset-inline-start: 1px;
  }

  .lg\:top-0 {
    top: 0;
  }

  .lg\:top-0\.5 {
    top: .125rem;
  }

  .lg\:top-1 {
    top: .25rem;
  }

  .lg\:top-1\.5 {
    top: .375rem;
  }

  .lg\:top-1\/2 {
    top: 50%;
  }

  .lg\:top-1\/3 {
    top: 33.3333%;
  }

  .lg\:top-1\/4 {
    top: 25%;
  }

  .lg\:top-10 {
    top: 2.5rem;
  }

  .lg\:top-11 {
    top: 2.75rem;
  }

  .lg\:top-12 {
    top: 3rem;
  }

  .lg\:top-14 {
    top: 3.5rem;
  }

  .lg\:top-16 {
    top: 4rem;
  }

  .lg\:top-2 {
    top: .5rem;
  }

  .lg\:top-2\.5 {
    top: .625rem;
  }

  .lg\:top-2\/3 {
    top: 66.6667%;
  }

  .lg\:top-2\/4 {
    top: 50%;
  }

  .lg\:top-20 {
    top: 5rem;
  }

  .lg\:top-24 {
    top: 6rem;
  }

  .lg\:top-28 {
    top: 7rem;
  }

  .lg\:top-3 {
    top: .75rem;
  }

  .lg\:top-3\.5 {
    top: .875rem;
  }

  .lg\:top-3\/4 {
    top: 75%;
  }

  .lg\:top-32 {
    top: 8rem;
  }

  .lg\:top-36 {
    top: 9rem;
  }

  .lg\:top-4 {
    top: 1rem;
  }

  .lg\:top-40 {
    top: 10rem;
  }

  .lg\:top-44 {
    top: 11rem;
  }

  .lg\:top-48 {
    top: 12rem;
  }

  .lg\:top-5 {
    top: 1.25rem;
  }

  .lg\:top-52 {
    top: 13rem;
  }

  .lg\:top-56 {
    top: 14rem;
  }

  .lg\:top-6 {
    top: 1.5rem;
  }

  .lg\:top-60 {
    top: 15rem;
  }

  .lg\:top-64 {
    top: 16rem;
  }

  .lg\:top-7 {
    top: 1.75rem;
  }

  .lg\:top-72 {
    top: 18rem;
  }

  .lg\:top-8 {
    top: 2rem;
  }

  .lg\:top-80 {
    top: 20rem;
  }

  .lg\:top-9 {
    top: 2.25rem;
  }

  .lg\:top-96 {
    top: 24rem;
  }

  .lg\:top-auto {
    top: auto;
  }

  .lg\:top-full {
    top: 100%;
  }

  .lg\:top-px {
    top: 1px;
  }

  .lg\:isolate {
    isolation: isolate;
  }

  .lg\:isolation-auto {
    isolation: auto;
  }

  .lg\:-z-0 {
    z-index: 0;
  }

  .lg\:-z-10 {
    z-index: -10;
  }

  .lg\:-z-20 {
    z-index: -20;
  }

  .lg\:-z-30 {
    z-index: -30;
  }

  .lg\:-z-40 {
    z-index: -40;
  }

  .lg\:-z-50 {
    z-index: -50;
  }

  .lg\:z-0 {
    z-index: 0;
  }

  .lg\:z-10 {
    z-index: 10;
  }

  .lg\:z-20 {
    z-index: 20;
  }

  .lg\:z-30 {
    z-index: 30;
  }

  .lg\:z-40 {
    z-index: 40;
  }

  .lg\:z-50 {
    z-index: 50;
  }

  .lg\:z-auto {
    z-index: auto;
  }

  .lg\:-order-1 {
    order: -1;
  }

  .lg\:-order-10 {
    order: -10;
  }

  .lg\:-order-11 {
    order: -11;
  }

  .lg\:-order-12 {
    order: -12;
  }

  .lg\:-order-2 {
    order: -2;
  }

  .lg\:-order-3 {
    order: -3;
  }

  .lg\:-order-4 {
    order: -4;
  }

  .lg\:-order-5 {
    order: -5;
  }

  .lg\:-order-6 {
    order: -6;
  }

  .lg\:-order-7 {
    order: -7;
  }

  .lg\:-order-8 {
    order: -8;
  }

  .lg\:-order-9 {
    order: -9;
  }

  .lg\:-order-first {
    order: 9999;
  }

  .lg\:-order-last {
    order: -9999;
  }

  .lg\:-order-none {
    order: 0;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-10 {
    order: 10;
  }

  .lg\:order-11 {
    order: 11;
  }

  .lg\:order-12 {
    order: 12;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:order-4 {
    order: 4;
  }

  .lg\:order-5 {
    order: 5;
  }

  .lg\:order-6 {
    order: 6;
  }

  .lg\:order-7 {
    order: 7;
  }

  .lg\:order-8 {
    order: 8;
  }

  .lg\:order-9 {
    order: 9;
  }

  .lg\:order-first {
    order: -9999;
  }

  .lg\:order-last {
    order: 9999;
  }

  .lg\:order-none {
    order: 0;
  }

  .lg\:col-auto {
    grid-column: auto;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:col-span-full {
    grid-column: 1 / -1;
  }

  .lg\:-col-start-1 {
    grid-column-start: -1;
  }

  .lg\:-col-start-10 {
    grid-column-start: -10;
  }

  .lg\:-col-start-11 {
    grid-column-start: -11;
  }

  .lg\:-col-start-12 {
    grid-column-start: -12;
  }

  .lg\:-col-start-13 {
    grid-column-start: -13;
  }

  .lg\:-col-start-2 {
    grid-column-start: -2;
  }

  .lg\:-col-start-3 {
    grid-column-start: -3;
  }

  .lg\:-col-start-4 {
    grid-column-start: -4;
  }

  .lg\:-col-start-5 {
    grid-column-start: -5;
  }

  .lg\:-col-start-6 {
    grid-column-start: -6;
  }

  .lg\:-col-start-7 {
    grid-column-start: -7;
  }

  .lg\:-col-start-8 {
    grid-column-start: -8;
  }

  .lg\:-col-start-9 {
    grid-column-start: -9;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-10 {
    grid-column-start: 10;
  }

  .lg\:col-start-11 {
    grid-column-start: 11;
  }

  .lg\:col-start-12 {
    grid-column-start: 12;
  }

  .lg\:col-start-13 {
    grid-column-start: 13;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-4 {
    grid-column-start: 4;
  }

  .lg\:col-start-5 {
    grid-column-start: 5;
  }

  .lg\:col-start-6 {
    grid-column-start: 6;
  }

  .lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-start-8 {
    grid-column-start: 8;
  }

  .lg\:col-start-9 {
    grid-column-start: 9;
  }

  .lg\:col-start-auto {
    grid-column-start: auto;
  }

  .lg\:-col-end-1 {
    grid-column-end: -1;
  }

  .lg\:-col-end-10 {
    grid-column-end: -10;
  }

  .lg\:-col-end-11 {
    grid-column-end: -11;
  }

  .lg\:-col-end-12 {
    grid-column-end: -12;
  }

  .lg\:-col-end-13 {
    grid-column-end: -13;
  }

  .lg\:-col-end-2 {
    grid-column-end: -2;
  }

  .lg\:-col-end-3 {
    grid-column-end: -3;
  }

  .lg\:-col-end-4 {
    grid-column-end: -4;
  }

  .lg\:-col-end-5 {
    grid-column-end: -5;
  }

  .lg\:-col-end-6 {
    grid-column-end: -6;
  }

  .lg\:-col-end-7 {
    grid-column-end: -7;
  }

  .lg\:-col-end-8 {
    grid-column-end: -8;
  }

  .lg\:-col-end-9 {
    grid-column-end: -9;
  }

  .lg\:col-end-1 {
    grid-column-end: 1;
  }

  .lg\:col-end-10 {
    grid-column-end: 10;
  }

  .lg\:col-end-11 {
    grid-column-end: 11;
  }

  .lg\:col-end-12 {
    grid-column-end: 12;
  }

  .lg\:col-end-13 {
    grid-column-end: 13;
  }

  .lg\:col-end-2 {
    grid-column-end: 2;
  }

  .lg\:col-end-3 {
    grid-column-end: 3;
  }

  .lg\:col-end-4 {
    grid-column-end: 4;
  }

  .lg\:col-end-5 {
    grid-column-end: 5;
  }

  .lg\:col-end-6 {
    grid-column-end: 6;
  }

  .lg\:col-end-7 {
    grid-column-end: 7;
  }

  .lg\:col-end-8 {
    grid-column-end: 8;
  }

  .lg\:col-end-9 {
    grid-column-end: 9;
  }

  .lg\:col-end-auto {
    grid-column-end: auto;
  }

  .lg\:row-auto {
    grid-row: auto;
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .lg\:row-span-10 {
    grid-row: span 10 / span 10;
  }

  .lg\:row-span-11 {
    grid-row: span 11 / span 11;
  }

  .lg\:row-span-12 {
    grid-row: span 12 / span 12;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .lg\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .lg\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .lg\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .lg\:row-span-7 {
    grid-row: span 7 / span 7;
  }

  .lg\:row-span-8 {
    grid-row: span 8 / span 8;
  }

  .lg\:row-span-9 {
    grid-row: span 9 / span 9;
  }

  .lg\:row-span-full {
    grid-row: 1 / -1;
  }

  .lg\:-row-start-1 {
    grid-row-start: -1;
  }

  .lg\:-row-start-10 {
    grid-row-start: -10;
  }

  .lg\:-row-start-11 {
    grid-row-start: -11;
  }

  .lg\:-row-start-12 {
    grid-row-start: -12;
  }

  .lg\:-row-start-13 {
    grid-row-start: -13;
  }

  .lg\:-row-start-2 {
    grid-row-start: -2;
  }

  .lg\:-row-start-3 {
    grid-row-start: -3;
  }

  .lg\:-row-start-4 {
    grid-row-start: -4;
  }

  .lg\:-row-start-5 {
    grid-row-start: -5;
  }

  .lg\:-row-start-6 {
    grid-row-start: -6;
  }

  .lg\:-row-start-7 {
    grid-row-start: -7;
  }

  .lg\:-row-start-8 {
    grid-row-start: -8;
  }

  .lg\:-row-start-9 {
    grid-row-start: -9;
  }

  .lg\:row-start-1 {
    grid-row-start: 1;
  }

  .lg\:row-start-10 {
    grid-row-start: 10;
  }

  .lg\:row-start-11 {
    grid-row-start: 11;
  }

  .lg\:row-start-12 {
    grid-row-start: 12;
  }

  .lg\:row-start-13 {
    grid-row-start: 13;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:row-start-3 {
    grid-row-start: 3;
  }

  .lg\:row-start-4 {
    grid-row-start: 4;
  }

  .lg\:row-start-5 {
    grid-row-start: 5;
  }

  .lg\:row-start-6 {
    grid-row-start: 6;
  }

  .lg\:row-start-7 {
    grid-row-start: 7;
  }

  .lg\:row-start-8 {
    grid-row-start: 8;
  }

  .lg\:row-start-9 {
    grid-row-start: 9;
  }

  .lg\:row-start-auto {
    grid-row-start: auto;
  }

  .lg\:-row-end-1 {
    grid-row-end: -1;
  }

  .lg\:-row-end-10 {
    grid-row-end: -10;
  }

  .lg\:-row-end-11 {
    grid-row-end: -11;
  }

  .lg\:-row-end-12 {
    grid-row-end: -12;
  }

  .lg\:-row-end-13 {
    grid-row-end: -13;
  }

  .lg\:-row-end-2 {
    grid-row-end: -2;
  }

  .lg\:-row-end-3 {
    grid-row-end: -3;
  }

  .lg\:-row-end-4 {
    grid-row-end: -4;
  }

  .lg\:-row-end-5 {
    grid-row-end: -5;
  }

  .lg\:-row-end-6 {
    grid-row-end: -6;
  }

  .lg\:-row-end-7 {
    grid-row-end: -7;
  }

  .lg\:-row-end-8 {
    grid-row-end: -8;
  }

  .lg\:-row-end-9 {
    grid-row-end: -9;
  }

  .lg\:row-end-1 {
    grid-row-end: 1;
  }

  .lg\:row-end-10 {
    grid-row-end: 10;
  }

  .lg\:row-end-11 {
    grid-row-end: 11;
  }

  .lg\:row-end-12 {
    grid-row-end: 12;
  }

  .lg\:row-end-13 {
    grid-row-end: 13;
  }

  .lg\:row-end-2 {
    grid-row-end: 2;
  }

  .lg\:row-end-3 {
    grid-row-end: 3;
  }

  .lg\:row-end-4 {
    grid-row-end: 4;
  }

  .lg\:row-end-5 {
    grid-row-end: 5;
  }

  .lg\:row-end-6 {
    grid-row-end: 6;
  }

  .lg\:row-end-7 {
    grid-row-end: 7;
  }

  .lg\:row-end-8 {
    grid-row-end: 8;
  }

  .lg\:row-end-9 {
    grid-row-end: 9;
  }

  .lg\:row-end-auto {
    grid-row-end: auto;
  }

  .lg\:float-start {
    float: inline-start;
  }

  .lg\:float-end {
    float: inline-end;
  }

  .lg\:float-right {
    float: right;
  }

  .lg\:float-left {
    float: left;
  }

  .lg\:float-none {
    float: none;
  }

  .lg\:clear-start {
    clear: inline-start;
  }

  .lg\:clear-end {
    clear: inline-end;
  }

  .lg\:clear-left {
    clear: left;
  }

  .lg\:clear-right {
    clear: right;
  }

  .lg\:clear-both {
    clear: both;
  }

  .lg\:clear-none {
    clear: none;
  }

  .lg\:-m-0 {
    margin: 0;
  }

  .lg\:-m-0\.5 {
    margin: -.125rem;
  }

  .lg\:-m-1 {
    margin: -.25rem;
  }

  .lg\:-m-1\.5 {
    margin: -.375rem;
  }

  .lg\:-m-10 {
    margin: -2.5rem;
  }

  .lg\:-m-11 {
    margin: -2.75rem;
  }

  .lg\:-m-12 {
    margin: -3rem;
  }

  .lg\:-m-14 {
    margin: -3.5rem;
  }

  .lg\:-m-16 {
    margin: -4rem;
  }

  .lg\:-m-2 {
    margin: -.5rem;
  }

  .lg\:-m-2\.5 {
    margin: -.625rem;
  }

  .lg\:-m-20 {
    margin: -5rem;
  }

  .lg\:-m-24 {
    margin: -6rem;
  }

  .lg\:-m-28 {
    margin: -7rem;
  }

  .lg\:-m-3 {
    margin: -.75rem;
  }

  .lg\:-m-3\.5 {
    margin: -.875rem;
  }

  .lg\:-m-32 {
    margin: -8rem;
  }

  .lg\:-m-36 {
    margin: -9rem;
  }

  .lg\:-m-4 {
    margin: -1rem;
  }

  .lg\:-m-40 {
    margin: -10rem;
  }

  .lg\:-m-44 {
    margin: -11rem;
  }

  .lg\:-m-48 {
    margin: -12rem;
  }

  .lg\:-m-5 {
    margin: -1.25rem;
  }

  .lg\:-m-52 {
    margin: -13rem;
  }

  .lg\:-m-56 {
    margin: -14rem;
  }

  .lg\:-m-6 {
    margin: -1.5rem;
  }

  .lg\:-m-60 {
    margin: -15rem;
  }

  .lg\:-m-64 {
    margin: -16rem;
  }

  .lg\:-m-7 {
    margin: -1.75rem;
  }

  .lg\:-m-72 {
    margin: -18rem;
  }

  .lg\:-m-8 {
    margin: -2rem;
  }

  .lg\:-m-80 {
    margin: -20rem;
  }

  .lg\:-m-9 {
    margin: -2.25rem;
  }

  .lg\:-m-96 {
    margin: -24rem;
  }

  .lg\:-m-px {
    margin: -1px;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:m-0\.5 {
    margin: .125rem;
  }

  .lg\:m-1 {
    margin: .25rem;
  }

  .lg\:m-1\.5 {
    margin: .375rem;
  }

  .lg\:m-10 {
    margin: 2.5rem;
  }

  .lg\:m-11 {
    margin: 2.75rem;
  }

  .lg\:m-12 {
    margin: 3rem;
  }

  .lg\:m-14 {
    margin: 3.5rem;
  }

  .lg\:m-16 {
    margin: 4rem;
  }

  .lg\:m-2 {
    margin: .5rem;
  }

  .lg\:m-2\.5 {
    margin: .625rem;
  }

  .lg\:m-20 {
    margin: 5rem;
  }

  .lg\:m-24 {
    margin: 6rem;
  }

  .lg\:m-28 {
    margin: 7rem;
  }

  .lg\:m-3 {
    margin: .75rem;
  }

  .lg\:m-3\.5 {
    margin: .875rem;
  }

  .lg\:m-32 {
    margin: 8rem;
  }

  .lg\:m-36 {
    margin: 9rem;
  }

  .lg\:m-4 {
    margin: 1rem;
  }

  .lg\:m-40 {
    margin: 10rem;
  }

  .lg\:m-44 {
    margin: 11rem;
  }

  .lg\:m-48 {
    margin: 12rem;
  }

  .lg\:m-5 {
    margin: 1.25rem;
  }

  .lg\:m-52 {
    margin: 13rem;
  }

  .lg\:m-56 {
    margin: 14rem;
  }

  .lg\:m-6 {
    margin: 1.5rem;
  }

  .lg\:m-60 {
    margin: 15rem;
  }

  .lg\:m-64 {
    margin: 16rem;
  }

  .lg\:m-7 {
    margin: 1.75rem;
  }

  .lg\:m-72 {
    margin: 18rem;
  }

  .lg\:m-8 {
    margin: 2rem;
  }

  .lg\:m-80 {
    margin: 20rem;
  }

  .lg\:m-9 {
    margin: 2.25rem;
  }

  .lg\:m-96 {
    margin: 24rem;
  }

  .lg\:m-auto {
    margin: auto;
  }

  .lg\:m-px {
    margin: 1px;
  }

  .lg\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .lg\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .lg\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .lg\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .lg\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .lg\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .lg\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .lg\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .lg\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .lg\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .lg\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .lg\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .lg\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .lg\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .lg\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .lg\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .lg\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .lg\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .lg\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .lg\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .lg\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .lg\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .lg\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .lg\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .lg\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .lg\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .lg\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .lg\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .lg\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .lg\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .lg\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .lg\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .lg\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .lg\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .lg\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .lg\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .lg\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .lg\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .lg\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .lg\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .lg\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .lg\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .lg\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .lg\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .lg\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .lg\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .lg\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .lg\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .lg\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .lg\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .lg\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .lg\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .lg\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .lg\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .lg\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .lg\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .lg\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .lg\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .lg\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .lg\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .lg\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .lg\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .lg\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .lg\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .lg\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .lg\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .lg\:-mb-0 {
    margin-bottom: 0;
  }

  .lg\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .lg\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .lg\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .lg\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .lg\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .lg\:-mb-12 {
    margin-bottom: -3rem;
  }

  .lg\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .lg\:-mb-16 {
    margin-bottom: -4rem;
  }

  .lg\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .lg\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .lg\:-mb-20 {
    margin-bottom: -5rem;
  }

  .lg\:-mb-24 {
    margin-bottom: -6rem;
  }

  .lg\:-mb-28 {
    margin-bottom: -7rem;
  }

  .lg\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .lg\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .lg\:-mb-32 {
    margin-bottom: -8rem;
  }

  .lg\:-mb-36 {
    margin-bottom: -9rem;
  }

  .lg\:-mb-4 {
    margin-bottom: -1rem;
  }

  .lg\:-mb-40 {
    margin-bottom: -10rem;
  }

  .lg\:-mb-44 {
    margin-bottom: -11rem;
  }

  .lg\:-mb-48 {
    margin-bottom: -12rem;
  }

  .lg\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .lg\:-mb-52 {
    margin-bottom: -13rem;
  }

  .lg\:-mb-56 {
    margin-bottom: -14rem;
  }

  .lg\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .lg\:-mb-60 {
    margin-bottom: -15rem;
  }

  .lg\:-mb-64 {
    margin-bottom: -16rem;
  }

  .lg\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .lg\:-mb-72 {
    margin-bottom: -18rem;
  }

  .lg\:-mb-8 {
    margin-bottom: -2rem;
  }

  .lg\:-mb-80 {
    margin-bottom: -20rem;
  }

  .lg\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .lg\:-mb-96 {
    margin-bottom: -24rem;
  }

  .lg\:-mb-px {
    margin-bottom: -1px;
  }

  .lg\:-me-0 {
    margin-inline-end: 0;
  }

  .lg\:-me-0\.5 {
    margin-inline-end: -.125rem;
  }

  .lg\:-me-1 {
    margin-inline-end: -.25rem;
  }

  .lg\:-me-1\.5 {
    margin-inline-end: -.375rem;
  }

  .lg\:-me-10 {
    margin-inline-end: -2.5rem;
  }

  .lg\:-me-11 {
    margin-inline-end: -2.75rem;
  }

  .lg\:-me-12 {
    margin-inline-end: -3rem;
  }

  .lg\:-me-14 {
    margin-inline-end: -3.5rem;
  }

  .lg\:-me-16 {
    margin-inline-end: -4rem;
  }

  .lg\:-me-2 {
    margin-inline-end: -.5rem;
  }

  .lg\:-me-2\.5 {
    margin-inline-end: -.625rem;
  }

  .lg\:-me-20 {
    margin-inline-end: -5rem;
  }

  .lg\:-me-24 {
    margin-inline-end: -6rem;
  }

  .lg\:-me-28 {
    margin-inline-end: -7rem;
  }

  .lg\:-me-3 {
    margin-inline-end: -.75rem;
  }

  .lg\:-me-3\.5 {
    margin-inline-end: -.875rem;
  }

  .lg\:-me-32 {
    margin-inline-end: -8rem;
  }

  .lg\:-me-36 {
    margin-inline-end: -9rem;
  }

  .lg\:-me-4 {
    margin-inline-end: -1rem;
  }

  .lg\:-me-40 {
    margin-inline-end: -10rem;
  }

  .lg\:-me-44 {
    margin-inline-end: -11rem;
  }

  .lg\:-me-48 {
    margin-inline-end: -12rem;
  }

  .lg\:-me-5 {
    margin-inline-end: -1.25rem;
  }

  .lg\:-me-52 {
    margin-inline-end: -13rem;
  }

  .lg\:-me-56 {
    margin-inline-end: -14rem;
  }

  .lg\:-me-6 {
    margin-inline-end: -1.5rem;
  }

  .lg\:-me-60 {
    margin-inline-end: -15rem;
  }

  .lg\:-me-64 {
    margin-inline-end: -16rem;
  }

  .lg\:-me-7 {
    margin-inline-end: -1.75rem;
  }

  .lg\:-me-72 {
    margin-inline-end: -18rem;
  }

  .lg\:-me-8 {
    margin-inline-end: -2rem;
  }

  .lg\:-me-80 {
    margin-inline-end: -20rem;
  }

  .lg\:-me-9 {
    margin-inline-end: -2.25rem;
  }

  .lg\:-me-96 {
    margin-inline-end: -24rem;
  }

  .lg\:-me-px {
    margin-inline-end: -1px;
  }

  .lg\:-ml-0 {
    margin-left: 0;
  }

  .lg\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .lg\:-ml-1 {
    margin-left: -.25rem;
  }

  .lg\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .lg\:-ml-10 {
    margin-left: -2.5rem;
  }

  .lg\:-ml-11 {
    margin-left: -2.75rem;
  }

  .lg\:-ml-12 {
    margin-left: -3rem;
  }

  .lg\:-ml-14 {
    margin-left: -3.5rem;
  }

  .lg\:-ml-16 {
    margin-left: -4rem;
  }

  .lg\:-ml-2 {
    margin-left: -.5rem;
  }

  .lg\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .lg\:-ml-20 {
    margin-left: -5rem;
  }

  .lg\:-ml-24 {
    margin-left: -6rem;
  }

  .lg\:-ml-28 {
    margin-left: -7rem;
  }

  .lg\:-ml-3 {
    margin-left: -.75rem;
  }

  .lg\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .lg\:-ml-32 {
    margin-left: -8rem;
  }

  .lg\:-ml-36 {
    margin-left: -9rem;
  }

  .lg\:-ml-4 {
    margin-left: -1rem;
  }

  .lg\:-ml-40 {
    margin-left: -10rem;
  }

  .lg\:-ml-44 {
    margin-left: -11rem;
  }

  .lg\:-ml-48 {
    margin-left: -12rem;
  }

  .lg\:-ml-5 {
    margin-left: -1.25rem;
  }

  .lg\:-ml-52 {
    margin-left: -13rem;
  }

  .lg\:-ml-56 {
    margin-left: -14rem;
  }

  .lg\:-ml-6 {
    margin-left: -1.5rem;
  }

  .lg\:-ml-60 {
    margin-left: -15rem;
  }

  .lg\:-ml-64 {
    margin-left: -16rem;
  }

  .lg\:-ml-7 {
    margin-left: -1.75rem;
  }

  .lg\:-ml-72 {
    margin-left: -18rem;
  }

  .lg\:-ml-8 {
    margin-left: -2rem;
  }

  .lg\:-ml-80 {
    margin-left: -20rem;
  }

  .lg\:-ml-9 {
    margin-left: -2.25rem;
  }

  .lg\:-ml-96 {
    margin-left: -24rem;
  }

  .lg\:-ml-px {
    margin-left: -1px;
  }

  .lg\:-mr-0 {
    margin-right: 0;
  }

  .lg\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .lg\:-mr-1 {
    margin-right: -.25rem;
  }

  .lg\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .lg\:-mr-10 {
    margin-right: -2.5rem;
  }

  .lg\:-mr-11 {
    margin-right: -2.75rem;
  }

  .lg\:-mr-12 {
    margin-right: -3rem;
  }

  .lg\:-mr-14 {
    margin-right: -3.5rem;
  }

  .lg\:-mr-16 {
    margin-right: -4rem;
  }

  .lg\:-mr-2 {
    margin-right: -.5rem;
  }

  .lg\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .lg\:-mr-20 {
    margin-right: -5rem;
  }

  .lg\:-mr-24 {
    margin-right: -6rem;
  }

  .lg\:-mr-28 {
    margin-right: -7rem;
  }

  .lg\:-mr-3 {
    margin-right: -.75rem;
  }

  .lg\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .lg\:-mr-32 {
    margin-right: -8rem;
  }

  .lg\:-mr-36 {
    margin-right: -9rem;
  }

  .lg\:-mr-4 {
    margin-right: -1rem;
  }

  .lg\:-mr-40 {
    margin-right: -10rem;
  }

  .lg\:-mr-44 {
    margin-right: -11rem;
  }

  .lg\:-mr-48 {
    margin-right: -12rem;
  }

  .lg\:-mr-5 {
    margin-right: -1.25rem;
  }

  .lg\:-mr-52 {
    margin-right: -13rem;
  }

  .lg\:-mr-56 {
    margin-right: -14rem;
  }

  .lg\:-mr-6 {
    margin-right: -1.5rem;
  }

  .lg\:-mr-60 {
    margin-right: -15rem;
  }

  .lg\:-mr-64 {
    margin-right: -16rem;
  }

  .lg\:-mr-7 {
    margin-right: -1.75rem;
  }

  .lg\:-mr-72 {
    margin-right: -18rem;
  }

  .lg\:-mr-8 {
    margin-right: -2rem;
  }

  .lg\:-mr-80 {
    margin-right: -20rem;
  }

  .lg\:-mr-9 {
    margin-right: -2.25rem;
  }

  .lg\:-mr-96 {
    margin-right: -24rem;
  }

  .lg\:-mr-px {
    margin-right: -1px;
  }

  .lg\:-ms-0 {
    margin-inline-start: 0;
  }

  .lg\:-ms-0\.5 {
    margin-inline-start: -.125rem;
  }

  .lg\:-ms-1 {
    margin-inline-start: -.25rem;
  }

  .lg\:-ms-1\.5 {
    margin-inline-start: -.375rem;
  }

  .lg\:-ms-10 {
    margin-inline-start: -2.5rem;
  }

  .lg\:-ms-11 {
    margin-inline-start: -2.75rem;
  }

  .lg\:-ms-12 {
    margin-inline-start: -3rem;
  }

  .lg\:-ms-14 {
    margin-inline-start: -3.5rem;
  }

  .lg\:-ms-16 {
    margin-inline-start: -4rem;
  }

  .lg\:-ms-2 {
    margin-inline-start: -.5rem;
  }

  .lg\:-ms-2\.5 {
    margin-inline-start: -.625rem;
  }

  .lg\:-ms-20 {
    margin-inline-start: -5rem;
  }

  .lg\:-ms-24 {
    margin-inline-start: -6rem;
  }

  .lg\:-ms-28 {
    margin-inline-start: -7rem;
  }

  .lg\:-ms-3 {
    margin-inline-start: -.75rem;
  }

  .lg\:-ms-3\.5 {
    margin-inline-start: -.875rem;
  }

  .lg\:-ms-32 {
    margin-inline-start: -8rem;
  }

  .lg\:-ms-36 {
    margin-inline-start: -9rem;
  }

  .lg\:-ms-4 {
    margin-inline-start: -1rem;
  }

  .lg\:-ms-40 {
    margin-inline-start: -10rem;
  }

  .lg\:-ms-44 {
    margin-inline-start: -11rem;
  }

  .lg\:-ms-48 {
    margin-inline-start: -12rem;
  }

  .lg\:-ms-5 {
    margin-inline-start: -1.25rem;
  }

  .lg\:-ms-52 {
    margin-inline-start: -13rem;
  }

  .lg\:-ms-56 {
    margin-inline-start: -14rem;
  }

  .lg\:-ms-6 {
    margin-inline-start: -1.5rem;
  }

  .lg\:-ms-60 {
    margin-inline-start: -15rem;
  }

  .lg\:-ms-64 {
    margin-inline-start: -16rem;
  }

  .lg\:-ms-7 {
    margin-inline-start: -1.75rem;
  }

  .lg\:-ms-72 {
    margin-inline-start: -18rem;
  }

  .lg\:-ms-8 {
    margin-inline-start: -2rem;
  }

  .lg\:-ms-80 {
    margin-inline-start: -20rem;
  }

  .lg\:-ms-9 {
    margin-inline-start: -2.25rem;
  }

  .lg\:-ms-96 {
    margin-inline-start: -24rem;
  }

  .lg\:-ms-px {
    margin-inline-start: -1px;
  }

  .lg\:-mt-0 {
    margin-top: 0;
  }

  .lg\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .lg\:-mt-1 {
    margin-top: -.25rem;
  }

  .lg\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .lg\:-mt-10 {
    margin-top: -2.5rem;
  }

  .lg\:-mt-11 {
    margin-top: -2.75rem;
  }

  .lg\:-mt-12 {
    margin-top: -3rem;
  }

  .lg\:-mt-14 {
    margin-top: -3.5rem;
  }

  .lg\:-mt-16 {
    margin-top: -4rem;
  }

  .lg\:-mt-2 {
    margin-top: -.5rem;
  }

  .lg\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .lg\:-mt-20 {
    margin-top: -5rem;
  }

  .lg\:-mt-24 {
    margin-top: -6rem;
  }

  .lg\:-mt-28 {
    margin-top: -7rem;
  }

  .lg\:-mt-3 {
    margin-top: -.75rem;
  }

  .lg\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .lg\:-mt-32 {
    margin-top: -8rem;
  }

  .lg\:-mt-36 {
    margin-top: -9rem;
  }

  .lg\:-mt-4 {
    margin-top: -1rem;
  }

  .lg\:-mt-40 {
    margin-top: -10rem;
  }

  .lg\:-mt-44 {
    margin-top: -11rem;
  }

  .lg\:-mt-48 {
    margin-top: -12rem;
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem;
  }

  .lg\:-mt-52 {
    margin-top: -13rem;
  }

  .lg\:-mt-56 {
    margin-top: -14rem;
  }

  .lg\:-mt-6 {
    margin-top: -1.5rem;
  }

  .lg\:-mt-60 {
    margin-top: -15rem;
  }

  .lg\:-mt-64 {
    margin-top: -16rem;
  }

  .lg\:-mt-7 {
    margin-top: -1.75rem;
  }

  .lg\:-mt-72 {
    margin-top: -18rem;
  }

  .lg\:-mt-8 {
    margin-top: -2rem;
  }

  .lg\:-mt-80 {
    margin-top: -20rem;
  }

  .lg\:-mt-9 {
    margin-top: -2.25rem;
  }

  .lg\:-mt-96 {
    margin-top: -24rem;
  }

  .lg\:-mt-px {
    margin-top: -1px;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .lg\:mb-1 {
    margin-bottom: .25rem;
  }

  .lg\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:mb-28 {
    margin-bottom: 7rem;
  }

  .lg\:mb-3 {
    margin-bottom: .75rem;
  }

  .lg\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .lg\:mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:mb-36 {
    margin-bottom: 9rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-40 {
    margin-bottom: 10rem;
  }

  .lg\:mb-44 {
    margin-bottom: 11rem;
  }

  .lg\:mb-48 {
    margin-bottom: 12rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:mb-52 {
    margin-bottom: 13rem;
  }

  .lg\:mb-56 {
    margin-bottom: 14rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-60 {
    margin-bottom: 15rem;
  }

  .lg\:mb-64 {
    margin-bottom: 16rem;
  }

  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg\:mb-72 {
    margin-bottom: 18rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:mb-80 {
    margin-bottom: 20rem;
  }

  .lg\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .lg\:mb-96 {
    margin-bottom: 24rem;
  }

  .lg\:mb-auto {
    margin-bottom: auto;
  }

  .lg\:mb-px {
    margin-bottom: 1px;
  }

  .lg\:me-0 {
    margin-inline-end: 0;
  }

  .lg\:me-0\.5 {
    margin-inline-end: .125rem;
  }

  .lg\:me-1 {
    margin-inline-end: .25rem;
  }

  .lg\:me-1\.5 {
    margin-inline-end: .375rem;
  }

  .lg\:me-10 {
    margin-inline-end: 2.5rem;
  }

  .lg\:me-11 {
    margin-inline-end: 2.75rem;
  }

  .lg\:me-12 {
    margin-inline-end: 3rem;
  }

  .lg\:me-14 {
    margin-inline-end: 3.5rem;
  }

  .lg\:me-16 {
    margin-inline-end: 4rem;
  }

  .lg\:me-2 {
    margin-inline-end: .5rem;
  }

  .lg\:me-2\.5 {
    margin-inline-end: .625rem;
  }

  .lg\:me-20 {
    margin-inline-end: 5rem;
  }

  .lg\:me-24 {
    margin-inline-end: 6rem;
  }

  .lg\:me-28 {
    margin-inline-end: 7rem;
  }

  .lg\:me-3 {
    margin-inline-end: .75rem;
  }

  .lg\:me-3\.5 {
    margin-inline-end: .875rem;
  }

  .lg\:me-32 {
    margin-inline-end: 8rem;
  }

  .lg\:me-36 {
    margin-inline-end: 9rem;
  }

  .lg\:me-4 {
    margin-inline-end: 1rem;
  }

  .lg\:me-40 {
    margin-inline-end: 10rem;
  }

  .lg\:me-44 {
    margin-inline-end: 11rem;
  }

  .lg\:me-48 {
    margin-inline-end: 12rem;
  }

  .lg\:me-5 {
    margin-inline-end: 1.25rem;
  }

  .lg\:me-52 {
    margin-inline-end: 13rem;
  }

  .lg\:me-56 {
    margin-inline-end: 14rem;
  }

  .lg\:me-6 {
    margin-inline-end: 1.5rem;
  }

  .lg\:me-60 {
    margin-inline-end: 15rem;
  }

  .lg\:me-64 {
    margin-inline-end: 16rem;
  }

  .lg\:me-7 {
    margin-inline-end: 1.75rem;
  }

  .lg\:me-72 {
    margin-inline-end: 18rem;
  }

  .lg\:me-8 {
    margin-inline-end: 2rem;
  }

  .lg\:me-80 {
    margin-inline-end: 20rem;
  }

  .lg\:me-9 {
    margin-inline-end: 2.25rem;
  }

  .lg\:me-96 {
    margin-inline-end: 24rem;
  }

  .lg\:me-auto {
    margin-inline-end: auto;
  }

  .lg\:me-px {
    margin-inline-end: 1px;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-0\.5 {
    margin-left: .125rem;
  }

  .lg\:ml-1 {
    margin-left: .25rem;
  }

  .lg\:ml-1\.5 {
    margin-left: .375rem;
  }

  .lg\:ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:ml-11 {
    margin-left: 2.75rem;
  }

  .lg\:ml-12 {
    margin-left: 3rem;
  }

  .lg\:ml-14 {
    margin-left: 3.5rem;
  }

  .lg\:ml-16 {
    margin-left: 4rem;
  }

  .lg\:ml-2 {
    margin-left: .5rem;
  }

  .lg\:ml-2\.5 {
    margin-left: .625rem;
  }

  .lg\:ml-20 {
    margin-left: 5rem;
  }

  .lg\:ml-24 {
    margin-left: 6rem;
  }

  .lg\:ml-28 {
    margin-left: 7rem;
  }

  .lg\:ml-3 {
    margin-left: .75rem;
  }

  .lg\:ml-3\.5 {
    margin-left: .875rem;
  }

  .lg\:ml-32 {
    margin-left: 8rem;
  }

  .lg\:ml-36 {
    margin-left: 9rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:ml-40 {
    margin-left: 10rem;
  }

  .lg\:ml-44 {
    margin-left: 11rem;
  }

  .lg\:ml-48 {
    margin-left: 12rem;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:ml-52 {
    margin-left: 13rem;
  }

  .lg\:ml-56 {
    margin-left: 14rem;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:ml-60 {
    margin-left: 15rem;
  }

  .lg\:ml-64 {
    margin-left: 16rem;
  }

  .lg\:ml-7 {
    margin-left: 1.75rem;
  }

  .lg\:ml-72 {
    margin-left: 18rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:ml-80 {
    margin-left: 20rem;
  }

  .lg\:ml-9 {
    margin-left: 2.25rem;
  }

  .lg\:ml-96 {
    margin-left: 24rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:ml-px {
    margin-left: 1px;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mr-0\.5 {
    margin-right: .125rem;
  }

  .lg\:mr-1 {
    margin-right: .25rem;
  }

  .lg\:mr-1\.5 {
    margin-right: .375rem;
  }

  .lg\:mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:mr-11 {
    margin-right: 2.75rem;
  }

  .lg\:mr-12 {
    margin-right: 3rem;
  }

  .lg\:mr-14 {
    margin-right: 3.5rem;
  }

  .lg\:mr-16 {
    margin-right: 4rem;
  }

  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:mr-2\.5 {
    margin-right: .625rem;
  }

  .lg\:mr-20 {
    margin-right: 5rem;
  }

  .lg\:mr-24 {
    margin-right: 6rem;
  }

  .lg\:mr-28 {
    margin-right: 7rem;
  }

  .lg\:mr-3 {
    margin-right: .75rem;
  }

  .lg\:mr-3\.5 {
    margin-right: .875rem;
  }

  .lg\:mr-32 {
    margin-right: 8rem;
  }

  .lg\:mr-36 {
    margin-right: 9rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mr-40 {
    margin-right: 10rem;
  }

  .lg\:mr-44 {
    margin-right: 11rem;
  }

  .lg\:mr-48 {
    margin-right: 12rem;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:mr-52 {
    margin-right: 13rem;
  }

  .lg\:mr-56 {
    margin-right: 14rem;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:mr-60 {
    margin-right: 15rem;
  }

  .lg\:mr-64 {
    margin-right: 16rem;
  }

  .lg\:mr-7 {
    margin-right: 1.75rem;
  }

  .lg\:mr-72 {
    margin-right: 18rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:mr-80 {
    margin-right: 20rem;
  }

  .lg\:mr-9 {
    margin-right: 2.25rem;
  }

  .lg\:mr-96 {
    margin-right: 24rem;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mr-px {
    margin-right: 1px;
  }

  .lg\:ms-0 {
    margin-inline-start: 0;
  }

  .lg\:ms-0\.5 {
    margin-inline-start: .125rem;
  }

  .lg\:ms-1 {
    margin-inline-start: .25rem;
  }

  .lg\:ms-1\.5 {
    margin-inline-start: .375rem;
  }

  .lg\:ms-10 {
    margin-inline-start: 2.5rem;
  }

  .lg\:ms-11 {
    margin-inline-start: 2.75rem;
  }

  .lg\:ms-12 {
    margin-inline-start: 3rem;
  }

  .lg\:ms-14 {
    margin-inline-start: 3.5rem;
  }

  .lg\:ms-16 {
    margin-inline-start: 4rem;
  }

  .lg\:ms-2 {
    margin-inline-start: .5rem;
  }

  .lg\:ms-2\.5 {
    margin-inline-start: .625rem;
  }

  .lg\:ms-20 {
    margin-inline-start: 5rem;
  }

  .lg\:ms-24 {
    margin-inline-start: 6rem;
  }

  .lg\:ms-28 {
    margin-inline-start: 7rem;
  }

  .lg\:ms-3 {
    margin-inline-start: .75rem;
  }

  .lg\:ms-3\.5 {
    margin-inline-start: .875rem;
  }

  .lg\:ms-32 {
    margin-inline-start: 8rem;
  }

  .lg\:ms-36 {
    margin-inline-start: 9rem;
  }

  .lg\:ms-4 {
    margin-inline-start: 1rem;
  }

  .lg\:ms-40 {
    margin-inline-start: 10rem;
  }

  .lg\:ms-44 {
    margin-inline-start: 11rem;
  }

  .lg\:ms-48 {
    margin-inline-start: 12rem;
  }

  .lg\:ms-5 {
    margin-inline-start: 1.25rem;
  }

  .lg\:ms-52 {
    margin-inline-start: 13rem;
  }

  .lg\:ms-56 {
    margin-inline-start: 14rem;
  }

  .lg\:ms-6 {
    margin-inline-start: 1.5rem;
  }

  .lg\:ms-60 {
    margin-inline-start: 15rem;
  }

  .lg\:ms-64 {
    margin-inline-start: 16rem;
  }

  .lg\:ms-7 {
    margin-inline-start: 1.75rem;
  }

  .lg\:ms-72 {
    margin-inline-start: 18rem;
  }

  .lg\:ms-8 {
    margin-inline-start: 2rem;
  }

  .lg\:ms-80 {
    margin-inline-start: 20rem;
  }

  .lg\:ms-9 {
    margin-inline-start: 2.25rem;
  }

  .lg\:ms-96 {
    margin-inline-start: 24rem;
  }

  .lg\:ms-auto {
    margin-inline-start: auto;
  }

  .lg\:ms-px {
    margin-inline-start: 1px;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-0\.5 {
    margin-top: .125rem;
  }

  .lg\:mt-1 {
    margin-top: .25rem;
  }

  .lg\:mt-1\.5 {
    margin-top: .375rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-11 {
    margin-top: 2.75rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mt-14 {
    margin-top: 3.5rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-2\.5 {
    margin-top: .625rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mt-28 {
    margin-top: 7rem;
  }

  .lg\:mt-3 {
    margin-top: .75rem;
  }

  .lg\:mt-3\.5 {
    margin-top: .875rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:mt-36 {
    margin-top: 9rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:mt-40 {
    margin-top: 10rem;
  }

  .lg\:mt-44 {
    margin-top: 11rem;
  }

  .lg\:mt-48 {
    margin-top: 12rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:mt-52 {
    margin-top: 13rem;
  }

  .lg\:mt-56 {
    margin-top: 14rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:mt-60 {
    margin-top: 15rem;
  }

  .lg\:mt-64 {
    margin-top: 16rem;
  }

  .lg\:mt-7 {
    margin-top: 1.75rem;
  }

  .lg\:mt-72 {
    margin-top: 18rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:mt-80 {
    margin-top: 20rem;
  }

  .lg\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:mt-96 {
    margin-top: 24rem;
  }

  .lg\:mt-auto {
    margin-top: auto;
  }

  .lg\:mt-px {
    margin-top: 1px;
  }

  .lg\:box-border {
    box-sizing: border-box;
  }

  .lg\:box-content {
    box-sizing: content-box;
  }

  .lg\:line-clamp-1 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .lg\:line-clamp-2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .lg\:line-clamp-3 {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .lg\:line-clamp-4 {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .lg\:line-clamp-5 {
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .lg\:line-clamp-6 {
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .lg\:line-clamp-none {
    -webkit-line-clamp: none;
    -webkit-box-orient: horizontal;
    display: block;
    overflow: visible;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:table {
    display: table;
  }

  .lg\:inline-table {
    display: inline-table;
  }

  .lg\:table-caption {
    display: table-caption;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:table-column {
    display: table-column;
  }

  .lg\:table-column-group {
    display: table-column-group;
  }

  .lg\:table-footer-group {
    display: table-footer-group;
  }

  .lg\:table-header-group {
    display: table-header-group;
  }

  .lg\:table-row-group {
    display: table-row-group;
  }

  .lg\:table-row {
    display: table-row;
  }

  .lg\:flow-root {
    display: flow-root;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:inline-grid {
    display: inline-grid;
  }

  .lg\:contents {
    display: contents;
  }

  .lg\:list-item {
    display: list-item;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:aspect-auto {
    aspect-ratio: auto;
  }

  .lg\:aspect-square {
    aspect-ratio: 1;
  }

  .lg\:aspect-video {
    aspect-ratio: 16 / 9;
  }

  .lg\:size-0 {
    width: 0;
    height: 0;
  }

  .lg\:size-0\.5 {
    width: .125rem;
    height: .125rem;
  }

  .lg\:size-1 {
    width: .25rem;
    height: .25rem;
  }

  .lg\:size-1\.5 {
    width: .375rem;
    height: .375rem;
  }

  .lg\:size-1\/12 {
    width: 8.33333%;
    height: 8.33333%;
  }

  .lg\:size-1\/2 {
    width: 50%;
    height: 50%;
  }

  .lg\:size-1\/3 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .lg\:size-1\/4 {
    width: 25%;
    height: 25%;
  }

  .lg\:size-1\/5 {
    width: 20%;
    height: 20%;
  }

  .lg\:size-1\/6 {
    width: 16.6667%;
    height: 16.6667%;
  }

  .lg\:size-10 {
    width: 2.5rem;
    height: 2.5rem;
  }

  .lg\:size-10\/12 {
    width: 83.3333%;
    height: 83.3333%;
  }

  .lg\:size-11 {
    width: 2.75rem;
    height: 2.75rem;
  }

  .lg\:size-11\/12 {
    width: 91.6667%;
    height: 91.6667%;
  }

  .lg\:size-12 {
    width: 3rem;
    height: 3rem;
  }

  .lg\:size-14 {
    width: 3.5rem;
    height: 3.5rem;
  }

  .lg\:size-16 {
    width: 4rem;
    height: 4rem;
  }

  .lg\:size-2 {
    width: .5rem;
    height: .5rem;
  }

  .lg\:size-2\.5 {
    width: .625rem;
    height: .625rem;
  }

  .lg\:size-2\/12 {
    width: 16.6667%;
    height: 16.6667%;
  }

  .lg\:size-2\/3 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .lg\:size-2\/4 {
    width: 50%;
    height: 50%;
  }

  .lg\:size-2\/5 {
    width: 40%;
    height: 40%;
  }

  .lg\:size-2\/6 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .lg\:size-20 {
    width: 5rem;
    height: 5rem;
  }

  .lg\:size-24 {
    width: 6rem;
    height: 6rem;
  }

  .lg\:size-28 {
    width: 7rem;
    height: 7rem;
  }

  .lg\:size-3 {
    width: .75rem;
    height: .75rem;
  }

  .lg\:size-3\.5 {
    width: .875rem;
    height: .875rem;
  }

  .lg\:size-3\/12 {
    width: 25%;
    height: 25%;
  }

  .lg\:size-3\/4 {
    width: 75%;
    height: 75%;
  }

  .lg\:size-3\/5 {
    width: 60%;
    height: 60%;
  }

  .lg\:size-3\/6 {
    width: 50%;
    height: 50%;
  }

  .lg\:size-32 {
    width: 8rem;
    height: 8rem;
  }

  .lg\:size-36 {
    width: 9rem;
    height: 9rem;
  }

  .lg\:size-4 {
    width: 1rem;
    height: 1rem;
  }

  .lg\:size-4\/12 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .lg\:size-4\/5 {
    width: 80%;
    height: 80%;
  }

  .lg\:size-4\/6 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .lg\:size-40 {
    width: 10rem;
    height: 10rem;
  }

  .lg\:size-44 {
    width: 11rem;
    height: 11rem;
  }

  .lg\:size-48 {
    width: 12rem;
    height: 12rem;
  }

  .lg\:size-5 {
    width: 1.25rem;
    height: 1.25rem;
  }

  .lg\:size-5\/12 {
    width: 41.6667%;
    height: 41.6667%;
  }

  .lg\:size-5\/6 {
    width: 83.3333%;
    height: 83.3333%;
  }

  .lg\:size-52 {
    width: 13rem;
    height: 13rem;
  }

  .lg\:size-56 {
    width: 14rem;
    height: 14rem;
  }

  .lg\:size-6 {
    width: 1.5rem;
    height: 1.5rem;
  }

  .lg\:size-6\/12 {
    width: 50%;
    height: 50%;
  }

  .lg\:size-60 {
    width: 15rem;
    height: 15rem;
  }

  .lg\:size-64 {
    width: 16rem;
    height: 16rem;
  }

  .lg\:size-7 {
    width: 1.75rem;
    height: 1.75rem;
  }

  .lg\:size-7\/12 {
    width: 58.3333%;
    height: 58.3333%;
  }

  .lg\:size-72 {
    width: 18rem;
    height: 18rem;
  }

  .lg\:size-8 {
    width: 2rem;
    height: 2rem;
  }

  .lg\:size-8\/12 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .lg\:size-80 {
    width: 20rem;
    height: 20rem;
  }

  .lg\:size-9 {
    width: 2.25rem;
    height: 2.25rem;
  }

  .lg\:size-9\/12 {
    width: 75%;
    height: 75%;
  }

  .lg\:size-96 {
    width: 24rem;
    height: 24rem;
  }

  .lg\:size-auto {
    width: auto;
    height: auto;
  }

  .lg\:size-fit {
    width: fit-content;
    height: fit-content;
  }

  .lg\:size-full {
    width: 100%;
    height: 100%;
  }

  .lg\:size-max {
    width: max-content;
    height: max-content;
  }

  .lg\:size-min {
    width: min-content;
    height: min-content;
  }

  .lg\:size-px {
    width: 1px;
    height: 1px;
  }

  .lg\:h-0 {
    height: 0;
  }

  .lg\:h-0\.5 {
    height: .125rem;
  }

  .lg\:h-1 {
    height: .25rem;
  }

  .lg\:h-1\.5 {
    height: .375rem;
  }

  .lg\:h-1\/2 {
    height: 50%;
  }

  .lg\:h-1\/3 {
    height: 33.3333%;
  }

  .lg\:h-1\/4 {
    height: 25%;
  }

  .lg\:h-1\/5 {
    height: 20%;
  }

  .lg\:h-1\/6 {
    height: 16.6667%;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-11 {
    height: 2.75rem;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:h-14 {
    height: 3.5rem;
  }

  .lg\:h-16 {
    height: 4rem;
  }

  .lg\:h-2 {
    height: .5rem;
  }

  .lg\:h-2\.5 {
    height: .625rem;
  }

  .lg\:h-2\/3 {
    height: 66.6667%;
  }

  .lg\:h-2\/4 {
    height: 50%;
  }

  .lg\:h-2\/5 {
    height: 40%;
  }

  .lg\:h-2\/6 {
    height: 33.3333%;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:h-28 {
    height: 7rem;
  }

  .lg\:h-3 {
    height: .75rem;
  }

  .lg\:h-3\.5 {
    height: .875rem;
  }

  .lg\:h-3\/4 {
    height: 75%;
  }

  .lg\:h-3\/5 {
    height: 60%;
  }

  .lg\:h-3\/6 {
    height: 50%;
  }

  .lg\:h-32 {
    height: 8rem;
  }

  .lg\:h-36 {
    height: 9rem;
  }

  .lg\:h-4 {
    height: 1rem;
  }

  .lg\:h-4\/5 {
    height: 80%;
  }

  .lg\:h-4\/6 {
    height: 66.6667%;
  }

  .lg\:h-40 {
    height: 10rem;
  }

  .lg\:h-44 {
    height: 11rem;
  }

  .lg\:h-48 {
    height: 12rem;
  }

  .lg\:h-5 {
    height: 1.25rem;
  }

  .lg\:h-5\/6 {
    height: 83.3333%;
  }

  .lg\:h-52 {
    height: 13rem;
  }

  .lg\:h-56 {
    height: 14rem;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:h-60 {
    height: 15rem;
  }

  .lg\:h-64 {
    height: 16rem;
  }

  .lg\:h-7 {
    height: 1.75rem;
  }

  .lg\:h-72 {
    height: 18rem;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:h-80 {
    height: 20rem;
  }

  .lg\:h-9 {
    height: 2.25rem;
  }

  .lg\:h-96 {
    height: 24rem;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:h-dvh {
    height: 100dvh;
  }

  .lg\:h-fit {
    height: fit-content;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:h-lvh {
    height: 100lvh;
  }

  .lg\:h-max {
    height: max-content;
  }

  .lg\:h-min {
    height: min-content;
  }

  .lg\:h-px {
    height: 1px;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:h-svh {
    height: 100svh;
  }

  .lg\:max-h-0 {
    max-height: 0;
  }

  .lg\:max-h-0\.5 {
    max-height: .125rem;
  }

  .lg\:max-h-1 {
    max-height: .25rem;
  }

  .lg\:max-h-1\.5 {
    max-height: .375rem;
  }

  .lg\:max-h-10 {
    max-height: 2.5rem;
  }

  .lg\:max-h-11 {
    max-height: 2.75rem;
  }

  .lg\:max-h-12 {
    max-height: 3rem;
  }

  .lg\:max-h-14 {
    max-height: 3.5rem;
  }

  .lg\:max-h-16 {
    max-height: 4rem;
  }

  .lg\:max-h-2 {
    max-height: .5rem;
  }

  .lg\:max-h-2\.5 {
    max-height: .625rem;
  }

  .lg\:max-h-20 {
    max-height: 5rem;
  }

  .lg\:max-h-24 {
    max-height: 6rem;
  }

  .lg\:max-h-28 {
    max-height: 7rem;
  }

  .lg\:max-h-3 {
    max-height: .75rem;
  }

  .lg\:max-h-3\.5 {
    max-height: .875rem;
  }

  .lg\:max-h-32 {
    max-height: 8rem;
  }

  .lg\:max-h-36 {
    max-height: 9rem;
  }

  .lg\:max-h-4 {
    max-height: 1rem;
  }

  .lg\:max-h-40 {
    max-height: 10rem;
  }

  .lg\:max-h-44 {
    max-height: 11rem;
  }

  .lg\:max-h-48 {
    max-height: 12rem;
  }

  .lg\:max-h-5 {
    max-height: 1.25rem;
  }

  .lg\:max-h-52 {
    max-height: 13rem;
  }

  .lg\:max-h-56 {
    max-height: 14rem;
  }

  .lg\:max-h-6 {
    max-height: 1.5rem;
  }

  .lg\:max-h-60 {
    max-height: 15rem;
  }

  .lg\:max-h-64 {
    max-height: 16rem;
  }

  .lg\:max-h-7 {
    max-height: 1.75rem;
  }

  .lg\:max-h-72 {
    max-height: 18rem;
  }

  .lg\:max-h-8 {
    max-height: 2rem;
  }

  .lg\:max-h-80 {
    max-height: 20rem;
  }

  .lg\:max-h-9 {
    max-height: 2.25rem;
  }

  .lg\:max-h-96 {
    max-height: 24rem;
  }

  .lg\:max-h-dvh {
    max-height: 100dvh;
  }

  .lg\:max-h-fit {
    max-height: fit-content;
  }

  .lg\:max-h-full {
    max-height: 100%;
  }

  .lg\:max-h-lvh {
    max-height: 100lvh;
  }

  .lg\:max-h-max {
    max-height: max-content;
  }

  .lg\:max-h-min {
    max-height: min-content;
  }

  .lg\:max-h-none {
    max-height: none;
  }

  .lg\:max-h-px {
    max-height: 1px;
  }

  .lg\:max-h-screen {
    max-height: 100vh;
  }

  .lg\:max-h-svh {
    max-height: 100svh;
  }

  .lg\:min-h-0 {
    min-height: 0;
  }

  .lg\:min-h-0\.5 {
    min-height: .125rem;
  }

  .lg\:min-h-1 {
    min-height: .25rem;
  }

  .lg\:min-h-1\.5 {
    min-height: .375rem;
  }

  .lg\:min-h-10 {
    min-height: 2.5rem;
  }

  .lg\:min-h-11 {
    min-height: 2.75rem;
  }

  .lg\:min-h-12 {
    min-height: 3rem;
  }

  .lg\:min-h-14 {
    min-height: 3.5rem;
  }

  .lg\:min-h-16 {
    min-height: 4rem;
  }

  .lg\:min-h-2 {
    min-height: .5rem;
  }

  .lg\:min-h-2\.5 {
    min-height: .625rem;
  }

  .lg\:min-h-20 {
    min-height: 5rem;
  }

  .lg\:min-h-24 {
    min-height: 6rem;
  }

  .lg\:min-h-28 {
    min-height: 7rem;
  }

  .lg\:min-h-3 {
    min-height: .75rem;
  }

  .lg\:min-h-3\.5 {
    min-height: .875rem;
  }

  .lg\:min-h-32 {
    min-height: 8rem;
  }

  .lg\:min-h-36 {
    min-height: 9rem;
  }

  .lg\:min-h-4 {
    min-height: 1rem;
  }

  .lg\:min-h-40 {
    min-height: 10rem;
  }

  .lg\:min-h-44 {
    min-height: 11rem;
  }

  .lg\:min-h-48 {
    min-height: 12rem;
  }

  .lg\:min-h-5 {
    min-height: 1.25rem;
  }

  .lg\:min-h-52 {
    min-height: 13rem;
  }

  .lg\:min-h-56 {
    min-height: 14rem;
  }

  .lg\:min-h-6 {
    min-height: 1.5rem;
  }

  .lg\:min-h-60 {
    min-height: 15rem;
  }

  .lg\:min-h-64 {
    min-height: 16rem;
  }

  .lg\:min-h-7 {
    min-height: 1.75rem;
  }

  .lg\:min-h-72 {
    min-height: 18rem;
  }

  .lg\:min-h-8 {
    min-height: 2rem;
  }

  .lg\:min-h-80 {
    min-height: 20rem;
  }

  .lg\:min-h-9 {
    min-height: 2.25rem;
  }

  .lg\:min-h-96 {
    min-height: 24rem;
  }

  .lg\:min-h-dvh {
    min-height: 100dvh;
  }

  .lg\:min-h-fit {
    min-height: fit-content;
  }

  .lg\:min-h-full {
    min-height: 100%;
  }

  .lg\:min-h-lvh {
    min-height: 100lvh;
  }

  .lg\:min-h-max {
    min-height: max-content;
  }

  .lg\:min-h-min {
    min-height: min-content;
  }

  .lg\:min-h-px {
    min-height: 1px;
  }

  .lg\:min-h-screen {
    min-height: 100vh;
  }

  .lg\:min-h-svh {
    min-height: 100svh;
  }

  .lg\:w-0 {
    width: 0;
  }

  .lg\:w-0\.5 {
    width: .125rem;
  }

  .lg\:w-1 {
    width: .25rem;
  }

  .lg\:w-1\.5 {
    width: .375rem;
  }

  .lg\:w-1\/12 {
    width: 8.33333%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-1\/5 {
    width: 20%;
  }

  .lg\:w-1\/6 {
    width: 16.6667%;
  }

  .lg\:w-10 {
    width: 2.5rem;
  }

  .lg\:w-10\/12 {
    width: 83.3333%;
  }

  .lg\:w-11 {
    width: 2.75rem;
  }

  .lg\:w-11\/12 {
    width: 91.6667%;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:w-14 {
    width: 3.5rem;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:w-2 {
    width: .5rem;
  }

  .lg\:w-2\.5 {
    width: .625rem;
  }

  .lg\:w-2\/12 {
    width: 16.6667%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-2\/4 {
    width: 50%;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-2\/6 {
    width: 33.3333%;
  }

  .lg\:w-20 {
    width: 5rem;
  }

  .lg\:w-24 {
    width: 6rem;
  }

  .lg\:w-28 {
    width: 7rem;
  }

  .lg\:w-3 {
    width: .75rem;
  }

  .lg\:w-3\.5 {
    width: .875rem;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-3\/6 {
    width: 50%;
  }

  .lg\:w-32 {
    width: 8rem;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:w-4 {
    width: 1rem;
  }

  .lg\:w-4\/12 {
    width: 33.3333%;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-4\/6 {
    width: 66.6667%;
  }

  .lg\:w-40 {
    width: 10rem;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:w-5 {
    width: 1.25rem;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:w-5\/6 {
    width: 83.3333%;
  }

  .lg\:w-52 {
    width: 13rem;
  }

  .lg\:w-56 {
    width: 14rem;
  }

  .lg\:w-6 {
    width: 1.5rem;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-60 {
    width: 15rem;
  }

  .lg\:w-64 {
    width: 16rem;
  }

  .lg\:w-7 {
    width: 1.75rem;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:w-72 {
    width: 18rem;
  }

  .lg\:w-8 {
    width: 2rem;
  }

  .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:w-80 {
    width: 20rem;
  }

  .lg\:w-9 {
    width: 2.25rem;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:w-96 {
    width: 24rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-dvw {
    width: 100dvw;
  }

  .lg\:w-fit {
    width: fit-content;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-lvw {
    width: 100lvw;
  }

  .lg\:w-max {
    width: max-content;
  }

  .lg\:w-min {
    width: min-content;
  }

  .lg\:w-px {
    width: 1px;
  }

  .lg\:w-screen {
    width: 100vw;
  }

  .lg\:w-svw {
    width: 100svw;
  }

  .lg\:min-w-0 {
    min-width: 0;
  }

  .lg\:min-w-0\.5 {
    min-width: .125rem;
  }

  .lg\:min-w-1 {
    min-width: .25rem;
  }

  .lg\:min-w-1\.5 {
    min-width: .375rem;
  }

  .lg\:min-w-10 {
    min-width: 2.5rem;
  }

  .lg\:min-w-11 {
    min-width: 2.75rem;
  }

  .lg\:min-w-12 {
    min-width: 3rem;
  }

  .lg\:min-w-14 {
    min-width: 3.5rem;
  }

  .lg\:min-w-16 {
    min-width: 4rem;
  }

  .lg\:min-w-2 {
    min-width: .5rem;
  }

  .lg\:min-w-2\.5 {
    min-width: .625rem;
  }

  .lg\:min-w-20 {
    min-width: 5rem;
  }

  .lg\:min-w-24 {
    min-width: 6rem;
  }

  .lg\:min-w-28 {
    min-width: 7rem;
  }

  .lg\:min-w-3 {
    min-width: .75rem;
  }

  .lg\:min-w-3\.5 {
    min-width: .875rem;
  }

  .lg\:min-w-32 {
    min-width: 8rem;
  }

  .lg\:min-w-36 {
    min-width: 9rem;
  }

  .lg\:min-w-4 {
    min-width: 1rem;
  }

  .lg\:min-w-40 {
    min-width: 10rem;
  }

  .lg\:min-w-44 {
    min-width: 11rem;
  }

  .lg\:min-w-48 {
    min-width: 12rem;
  }

  .lg\:min-w-5 {
    min-width: 1.25rem;
  }

  .lg\:min-w-52 {
    min-width: 13rem;
  }

  .lg\:min-w-56 {
    min-width: 14rem;
  }

  .lg\:min-w-6 {
    min-width: 1.5rem;
  }

  .lg\:min-w-60 {
    min-width: 15rem;
  }

  .lg\:min-w-64 {
    min-width: 16rem;
  }

  .lg\:min-w-7 {
    min-width: 1.75rem;
  }

  .lg\:min-w-72 {
    min-width: 18rem;
  }

  .lg\:min-w-8 {
    min-width: 2rem;
  }

  .lg\:min-w-80 {
    min-width: 20rem;
  }

  .lg\:min-w-9 {
    min-width: 2.25rem;
  }

  .lg\:min-w-96 {
    min-width: 24rem;
  }

  .lg\:min-w-fit {
    min-width: fit-content;
  }

  .lg\:min-w-full {
    min-width: 100%;
  }

  .lg\:min-w-max {
    min-width: max-content;
  }

  .lg\:min-w-min {
    min-width: min-content;
  }

  .lg\:min-w-px {
    min-width: 1px;
  }

  .lg\:max-w-0 {
    max-width: 0;
  }

  .lg\:max-w-0\.5 {
    max-width: .125rem;
  }

  .lg\:max-w-1 {
    max-width: .25rem;
  }

  .lg\:max-w-1\.5 {
    max-width: .375rem;
  }

  .lg\:max-w-10 {
    max-width: 2.5rem;
  }

  .lg\:max-w-11 {
    max-width: 2.75rem;
  }

  .lg\:max-w-12 {
    max-width: 3rem;
  }

  .lg\:max-w-14 {
    max-width: 3.5rem;
  }

  .lg\:max-w-16 {
    max-width: 4rem;
  }

  .lg\:max-w-2 {
    max-width: .5rem;
  }

  .lg\:max-w-2\.5 {
    max-width: .625rem;
  }

  .lg\:max-w-20 {
    max-width: 5rem;
  }

  .lg\:max-w-24 {
    max-width: 6rem;
  }

  .lg\:max-w-28 {
    max-width: 7rem;
  }

  .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .lg\:max-w-3 {
    max-width: .75rem;
  }

  .lg\:max-w-3\.5 {
    max-width: .875rem;
  }

  .lg\:max-w-32 {
    max-width: 8rem;
  }

  .lg\:max-w-36 {
    max-width: 9rem;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:max-w-4 {
    max-width: 1rem;
  }

  .lg\:max-w-40 {
    max-width: 10rem;
  }

  .lg\:max-w-44 {
    max-width: 11rem;
  }

  .lg\:max-w-48 {
    max-width: 12rem;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-5 {
    max-width: 1.25rem;
  }

  .lg\:max-w-52 {
    max-width: 13rem;
  }

  .lg\:max-w-56 {
    max-width: 14rem;
  }

  .lg\:max-w-5xl {
    max-width: 64rem;
  }

  .lg\:max-w-6 {
    max-width: 1.5rem;
  }

  .lg\:max-w-60 {
    max-width: 15rem;
  }

  .lg\:max-w-64 {
    max-width: 16rem;
  }

  .lg\:max-w-6xl {
    max-width: 72rem;
  }

  .lg\:max-w-7 {
    max-width: 1.75rem;
  }

  .lg\:max-w-72 {
    max-width: 18rem;
  }

  .lg\:max-w-7xl {
    max-width: 80rem;
  }

  .lg\:max-w-8 {
    max-width: 2rem;
  }

  .lg\:max-w-80 {
    max-width: 20rem;
  }

  .lg\:max-w-9 {
    max-width: 2.25rem;
  }

  .lg\:max-w-96 {
    max-width: 24rem;
  }

  .lg\:max-w-fit {
    max-width: fit-content;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:max-w-max {
    max-width: max-content;
  }

  .lg\:max-w-md {
    max-width: 28rem;
  }

  .lg\:max-w-min {
    max-width: min-content;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:max-w-prose {
    max-width: 65ch;
  }

  .lg\:max-w-px {
    max-width: 1px;
  }

  .lg\:max-w-screen-2xl {
    max-width: 1536px;
  }

  .lg\:max-w-screen-lg {
    max-width: 1024px;
  }

  .lg\:max-w-screen-md {
    max-width: 768px;
  }

  .lg\:max-w-screen-sm {
    max-width: 640px;
  }

  .lg\:max-w-screen-xl {
    max-width: 1280px;
  }

  .lg\:max-w-sm {
    max-width: 24rem;
  }

  .lg\:max-w-xl {
    max-width: 36rem;
  }

  .lg\:max-w-xs {
    max-width: 20rem;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:flex-auto {
    flex: auto;
  }

  .lg\:flex-initial {
    flex: 0 auto;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:flex-shrink {
    flex-shrink: 1;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:shrink {
    flex-shrink: 1;
  }

  .lg\:shrink-0 {
    flex-shrink: 0;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:flex-grow-0 {
    flex-grow: 0;
  }

  .lg\:grow {
    flex-grow: 1;
  }

  .lg\:grow-0 {
    flex-grow: 0;
  }

  .lg\:basis-0 {
    flex-basis: 0;
  }

  .lg\:basis-0\.5 {
    flex-basis: .125rem;
  }

  .lg\:basis-1 {
    flex-basis: .25rem;
  }

  .lg\:basis-1\.5 {
    flex-basis: .375rem;
  }

  .lg\:basis-1\/12 {
    flex-basis: 8.33333%;
  }

  .lg\:basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:basis-1\/3 {
    flex-basis: 33.3333%;
  }

  .lg\:basis-1\/4 {
    flex-basis: 25%;
  }

  .lg\:basis-1\/5 {
    flex-basis: 20%;
  }

  .lg\:basis-1\/6 {
    flex-basis: 16.6667%;
  }

  .lg\:basis-10 {
    flex-basis: 2.5rem;
  }

  .lg\:basis-10\/12 {
    flex-basis: 83.3333%;
  }

  .lg\:basis-11 {
    flex-basis: 2.75rem;
  }

  .lg\:basis-11\/12 {
    flex-basis: 91.6667%;
  }

  .lg\:basis-12 {
    flex-basis: 3rem;
  }

  .lg\:basis-14 {
    flex-basis: 3.5rem;
  }

  .lg\:basis-16 {
    flex-basis: 4rem;
  }

  .lg\:basis-2 {
    flex-basis: .5rem;
  }

  .lg\:basis-2\.5 {
    flex-basis: .625rem;
  }

  .lg\:basis-2\/12 {
    flex-basis: 16.6667%;
  }

  .lg\:basis-2\/3 {
    flex-basis: 66.6667%;
  }

  .lg\:basis-2\/4 {
    flex-basis: 50%;
  }

  .lg\:basis-2\/5 {
    flex-basis: 40%;
  }

  .lg\:basis-2\/6 {
    flex-basis: 33.3333%;
  }

  .lg\:basis-20 {
    flex-basis: 5rem;
  }

  .lg\:basis-24 {
    flex-basis: 6rem;
  }

  .lg\:basis-28 {
    flex-basis: 7rem;
  }

  .lg\:basis-3 {
    flex-basis: .75rem;
  }

  .lg\:basis-3\.5 {
    flex-basis: .875rem;
  }

  .lg\:basis-3\/12 {
    flex-basis: 25%;
  }

  .lg\:basis-3\/4 {
    flex-basis: 75%;
  }

  .lg\:basis-3\/5 {
    flex-basis: 60%;
  }

  .lg\:basis-3\/6 {
    flex-basis: 50%;
  }

  .lg\:basis-32 {
    flex-basis: 8rem;
  }

  .lg\:basis-36 {
    flex-basis: 9rem;
  }

  .lg\:basis-4 {
    flex-basis: 1rem;
  }

  .lg\:basis-4\/12 {
    flex-basis: 33.3333%;
  }

  .lg\:basis-4\/5 {
    flex-basis: 80%;
  }

  .lg\:basis-4\/6 {
    flex-basis: 66.6667%;
  }

  .lg\:basis-40 {
    flex-basis: 10rem;
  }

  .lg\:basis-44 {
    flex-basis: 11rem;
  }

  .lg\:basis-48 {
    flex-basis: 12rem;
  }

  .lg\:basis-5 {
    flex-basis: 1.25rem;
  }

  .lg\:basis-5\/12 {
    flex-basis: 41.6667%;
  }

  .lg\:basis-5\/6 {
    flex-basis: 83.3333%;
  }

  .lg\:basis-52 {
    flex-basis: 13rem;
  }

  .lg\:basis-56 {
    flex-basis: 14rem;
  }

  .lg\:basis-6 {
    flex-basis: 1.5rem;
  }

  .lg\:basis-6\/12 {
    flex-basis: 50%;
  }

  .lg\:basis-60 {
    flex-basis: 15rem;
  }

  .lg\:basis-64 {
    flex-basis: 16rem;
  }

  .lg\:basis-7 {
    flex-basis: 1.75rem;
  }

  .lg\:basis-7\/12 {
    flex-basis: 58.3333%;
  }

  .lg\:basis-72 {
    flex-basis: 18rem;
  }

  .lg\:basis-8 {
    flex-basis: 2rem;
  }

  .lg\:basis-8\/12 {
    flex-basis: 66.6667%;
  }

  .lg\:basis-80 {
    flex-basis: 20rem;
  }

  .lg\:basis-9 {
    flex-basis: 2.25rem;
  }

  .lg\:basis-9\/12 {
    flex-basis: 75%;
  }

  .lg\:basis-96 {
    flex-basis: 24rem;
  }

  .lg\:basis-auto {
    flex-basis: auto;
  }

  .lg\:basis-full {
    flex-basis: 100%;
  }

  .lg\:basis-px {
    flex-basis: 1px;
  }

  .lg\:table-auto {
    table-layout: auto;
  }

  .lg\:table-fixed {
    table-layout: fixed;
  }

  .lg\:caption-top {
    caption-side: top;
  }

  .lg\:caption-bottom {
    caption-side: bottom;
  }

  .lg\:border-collapse {
    border-collapse: collapse;
  }

  .lg\:border-separate {
    border-collapse: separate;
  }

  .lg\:border-spacing-0 {
    --tw-border-spacing-x: 0px;
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-0\.5 {
    --tw-border-spacing-x: .125rem;
    --tw-border-spacing-y: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-1 {
    --tw-border-spacing-x: .25rem;
    --tw-border-spacing-y: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-1\.5 {
    --tw-border-spacing-x: .375rem;
    --tw-border-spacing-y: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-10 {
    --tw-border-spacing-x: 2.5rem;
    --tw-border-spacing-y: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-11 {
    --tw-border-spacing-x: 2.75rem;
    --tw-border-spacing-y: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-12 {
    --tw-border-spacing-x: 3rem;
    --tw-border-spacing-y: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-14 {
    --tw-border-spacing-x: 3.5rem;
    --tw-border-spacing-y: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-16 {
    --tw-border-spacing-x: 4rem;
    --tw-border-spacing-y: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-2 {
    --tw-border-spacing-x: .5rem;
    --tw-border-spacing-y: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-2\.5 {
    --tw-border-spacing-x: .625rem;
    --tw-border-spacing-y: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-20 {
    --tw-border-spacing-x: 5rem;
    --tw-border-spacing-y: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-24 {
    --tw-border-spacing-x: 6rem;
    --tw-border-spacing-y: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-28 {
    --tw-border-spacing-x: 7rem;
    --tw-border-spacing-y: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-3 {
    --tw-border-spacing-x: .75rem;
    --tw-border-spacing-y: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-3\.5 {
    --tw-border-spacing-x: .875rem;
    --tw-border-spacing-y: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-32 {
    --tw-border-spacing-x: 8rem;
    --tw-border-spacing-y: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-36 {
    --tw-border-spacing-x: 9rem;
    --tw-border-spacing-y: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-4 {
    --tw-border-spacing-x: 1rem;
    --tw-border-spacing-y: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-40 {
    --tw-border-spacing-x: 10rem;
    --tw-border-spacing-y: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-44 {
    --tw-border-spacing-x: 11rem;
    --tw-border-spacing-y: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-48 {
    --tw-border-spacing-x: 12rem;
    --tw-border-spacing-y: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-5 {
    --tw-border-spacing-x: 1.25rem;
    --tw-border-spacing-y: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-52 {
    --tw-border-spacing-x: 13rem;
    --tw-border-spacing-y: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-56 {
    --tw-border-spacing-x: 14rem;
    --tw-border-spacing-y: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-6 {
    --tw-border-spacing-x: 1.5rem;
    --tw-border-spacing-y: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-60 {
    --tw-border-spacing-x: 15rem;
    --tw-border-spacing-y: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-64 {
    --tw-border-spacing-x: 16rem;
    --tw-border-spacing-y: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-7 {
    --tw-border-spacing-x: 1.75rem;
    --tw-border-spacing-y: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-72 {
    --tw-border-spacing-x: 18rem;
    --tw-border-spacing-y: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-8 {
    --tw-border-spacing-x: 2rem;
    --tw-border-spacing-y: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-80 {
    --tw-border-spacing-x: 20rem;
    --tw-border-spacing-y: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-9 {
    --tw-border-spacing-x: 2.25rem;
    --tw-border-spacing-y: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-96 {
    --tw-border-spacing-x: 24rem;
    --tw-border-spacing-y: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-px {
    --tw-border-spacing-x: 1px;
    --tw-border-spacing-y: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-0 {
    --tw-border-spacing-x: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-0\.5 {
    --tw-border-spacing-x: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-1 {
    --tw-border-spacing-x: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-1\.5 {
    --tw-border-spacing-x: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-10 {
    --tw-border-spacing-x: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-11 {
    --tw-border-spacing-x: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-12 {
    --tw-border-spacing-x: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-14 {
    --tw-border-spacing-x: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-16 {
    --tw-border-spacing-x: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-2 {
    --tw-border-spacing-x: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-2\.5 {
    --tw-border-spacing-x: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-20 {
    --tw-border-spacing-x: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-24 {
    --tw-border-spacing-x: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-28 {
    --tw-border-spacing-x: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-3 {
    --tw-border-spacing-x: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-3\.5 {
    --tw-border-spacing-x: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-32 {
    --tw-border-spacing-x: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-36 {
    --tw-border-spacing-x: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-4 {
    --tw-border-spacing-x: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-40 {
    --tw-border-spacing-x: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-44 {
    --tw-border-spacing-x: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-48 {
    --tw-border-spacing-x: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-5 {
    --tw-border-spacing-x: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-52 {
    --tw-border-spacing-x: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-56 {
    --tw-border-spacing-x: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-6 {
    --tw-border-spacing-x: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-60 {
    --tw-border-spacing-x: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-64 {
    --tw-border-spacing-x: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-7 {
    --tw-border-spacing-x: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-72 {
    --tw-border-spacing-x: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-8 {
    --tw-border-spacing-x: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-80 {
    --tw-border-spacing-x: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-9 {
    --tw-border-spacing-x: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-96 {
    --tw-border-spacing-x: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-x-px {
    --tw-border-spacing-x: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-0 {
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-0\.5 {
    --tw-border-spacing-y: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-1 {
    --tw-border-spacing-y: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-1\.5 {
    --tw-border-spacing-y: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-10 {
    --tw-border-spacing-y: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-11 {
    --tw-border-spacing-y: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-12 {
    --tw-border-spacing-y: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-14 {
    --tw-border-spacing-y: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-16 {
    --tw-border-spacing-y: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-2 {
    --tw-border-spacing-y: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-2\.5 {
    --tw-border-spacing-y: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-20 {
    --tw-border-spacing-y: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-24 {
    --tw-border-spacing-y: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-28 {
    --tw-border-spacing-y: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-3 {
    --tw-border-spacing-y: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-3\.5 {
    --tw-border-spacing-y: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-32 {
    --tw-border-spacing-y: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-36 {
    --tw-border-spacing-y: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-4 {
    --tw-border-spacing-y: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-40 {
    --tw-border-spacing-y: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-44 {
    --tw-border-spacing-y: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-48 {
    --tw-border-spacing-y: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-5 {
    --tw-border-spacing-y: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-52 {
    --tw-border-spacing-y: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-56 {
    --tw-border-spacing-y: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-6 {
    --tw-border-spacing-y: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-60 {
    --tw-border-spacing-y: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-64 {
    --tw-border-spacing-y: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-7 {
    --tw-border-spacing-y: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-72 {
    --tw-border-spacing-y: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-8 {
    --tw-border-spacing-y: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-80 {
    --tw-border-spacing-y: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-9 {
    --tw-border-spacing-y: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-96 {
    --tw-border-spacing-y: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:border-spacing-y-px {
    --tw-border-spacing-y: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .lg\:origin-bottom {
    transform-origin: bottom;
  }

  .lg\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .lg\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .lg\:origin-center {
    transform-origin: center;
  }

  .lg\:origin-left {
    transform-origin: 0;
  }

  .lg\:origin-right {
    transform-origin: 100%;
  }

  .lg\:origin-top {
    transform-origin: top;
  }

  .lg\:origin-top-left {
    transform-origin: 0 0;
  }

  .lg\:origin-top-right {
    transform-origin: 100% 0;
  }

  .lg\:-translate-x-0 {
    --tw-translate-x: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-1 {
    --tw-translate-x: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-12 {
    --tw-translate-x: -3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-16 {
    --tw-translate-x: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-2 {
    --tw-translate-x: -.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-20 {
    --tw-translate-x: -5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-24 {
    --tw-translate-x: -6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-28 {
    --tw-translate-x: -7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-3 {
    --tw-translate-x: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-32 {
    --tw-translate-x: -8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-36 {
    --tw-translate-x: -9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-4 {
    --tw-translate-x: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-40 {
    --tw-translate-x: -10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-44 {
    --tw-translate-x: -11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-48 {
    --tw-translate-x: -12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-52 {
    --tw-translate-x: -13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-56 {
    --tw-translate-x: -14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-60 {
    --tw-translate-x: -15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-64 {
    --tw-translate-x: -16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-72 {
    --tw-translate-x: -18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-8 {
    --tw-translate-x: -2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-80 {
    --tw-translate-x: -20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-96 {
    --tw-translate-x: -24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-x-px {
    --tw-translate-x: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-0 {
    --tw-translate-y: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-1 {
    --tw-translate-y: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-12 {
    --tw-translate-y: -3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-16 {
    --tw-translate-y: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-2 {
    --tw-translate-y: -.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-20 {
    --tw-translate-y: -5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-24 {
    --tw-translate-y: -6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-28 {
    --tw-translate-y: -7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-3 {
    --tw-translate-y: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-32 {
    --tw-translate-y: -8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-36 {
    --tw-translate-y: -9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-4 {
    --tw-translate-y: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-40 {
    --tw-translate-y: -10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-44 {
    --tw-translate-y: -11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-48 {
    --tw-translate-y: -12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-52 {
    --tw-translate-y: -13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-56 {
    --tw-translate-y: -14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-60 {
    --tw-translate-y: -15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-64 {
    --tw-translate-y: -16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-72 {
    --tw-translate-y: -18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-8 {
    --tw-translate-y: -2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-80 {
    --tw-translate-y: -20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-96 {
    --tw-translate-y: -24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-px {
    --tw-translate-y: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-1 {
    --tw-translate-x: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-1\/4 {
    --tw-translate-x: 25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-10 {
    --tw-translate-x: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-11 {
    --tw-translate-x: 2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-12 {
    --tw-translate-x: 3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-14 {
    --tw-translate-x: 3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-16 {
    --tw-translate-x: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-2\/4 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-20 {
    --tw-translate-x: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-24 {
    --tw-translate-x: 6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-28 {
    --tw-translate-x: 7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-3 {
    --tw-translate-x: .75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-3\/4 {
    --tw-translate-x: 75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-32 {
    --tw-translate-x: 8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-36 {
    --tw-translate-x: 9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-4 {
    --tw-translate-x: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-40 {
    --tw-translate-x: 10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-44 {
    --tw-translate-x: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-48 {
    --tw-translate-x: 12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-5 {
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-52 {
    --tw-translate-x: 13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-56 {
    --tw-translate-x: 14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-6 {
    --tw-translate-x: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-60 {
    --tw-translate-x: 15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-64 {
    --tw-translate-x: 16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-7 {
    --tw-translate-x: 1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-72 {
    --tw-translate-x: 18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-8 {
    --tw-translate-x: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-80 {
    --tw-translate-x: 20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-9 {
    --tw-translate-x: 2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-96 {
    --tw-translate-x: 24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-full {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-px {
    --tw-translate-x: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-1 {
    --tw-translate-y: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-1\/2 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-1\/4 {
    --tw-translate-y: 25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-10 {
    --tw-translate-y: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-11 {
    --tw-translate-y: 2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-12 {
    --tw-translate-y: 3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-14 {
    --tw-translate-y: 3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-16 {
    --tw-translate-y: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-2 {
    --tw-translate-y: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-2\/4 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-20 {
    --tw-translate-y: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-24 {
    --tw-translate-y: 6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-28 {
    --tw-translate-y: 7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-3 {
    --tw-translate-y: .75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-3\/4 {
    --tw-translate-y: 75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-32 {
    --tw-translate-y: 8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-36 {
    --tw-translate-y: 9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-4 {
    --tw-translate-y: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-40 {
    --tw-translate-y: 10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-44 {
    --tw-translate-y: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-48 {
    --tw-translate-y: 12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-5 {
    --tw-translate-y: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-52 {
    --tw-translate-y: 13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-56 {
    --tw-translate-y: 14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-6 {
    --tw-translate-y: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-60 {
    --tw-translate-y: 15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-64 {
    --tw-translate-y: 16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-7 {
    --tw-translate-y: 1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-72 {
    --tw-translate-y: 18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-8 {
    --tw-translate-y: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-80 {
    --tw-translate-y: 20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-9 {
    --tw-translate-y: 2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-96 {
    --tw-translate-y: 24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-full {
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-px {
    --tw-translate-y: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-rotate-0 {
    --tw-rotate: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-rotate-1 {
    --tw-rotate: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-rotate-12 {
    --tw-rotate: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-rotate-180 {
    --tw-rotate: -180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-rotate-2 {
    --tw-rotate: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-rotate-3 {
    --tw-rotate: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-rotate-45 {
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-rotate-6 {
    --tw-rotate: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:rotate-1 {
    --tw-rotate: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:rotate-12 {
    --tw-rotate: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:rotate-2 {
    --tw-rotate: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:rotate-3 {
    --tw-rotate: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:rotate-6 {
    --tw-rotate: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-x-0 {
    --tw-skew-x: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-x-1 {
    --tw-skew-x: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-x-12 {
    --tw-skew-x: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-x-2 {
    --tw-skew-x: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-x-3 {
    --tw-skew-x: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-x-6 {
    --tw-skew-x: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-y-0 {
    --tw-skew-y: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-y-1 {
    --tw-skew-y: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-y-12 {
    --tw-skew-y: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-y-2 {
    --tw-skew-y: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-y-3 {
    --tw-skew-y: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-skew-y-6 {
    --tw-skew-y: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-x-0 {
    --tw-skew-x: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-x-1 {
    --tw-skew-x: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-x-12 {
    --tw-skew-x: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-x-2 {
    --tw-skew-x: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-x-3 {
    --tw-skew-x: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-x-6 {
    --tw-skew-x: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-y-0 {
    --tw-skew-y: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-y-1 {
    --tw-skew-y: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-y-12 {
    --tw-skew-y: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-y-2 {
    --tw-skew-y: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-y-3 {
    --tw-skew-y: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:skew-y-6 {
    --tw-skew-y: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-100 {
    --tw-scale-x: -1;
    --tw-scale-y: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-105 {
    --tw-scale-x: -1.05;
    --tw-scale-y: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-110 {
    --tw-scale-x: -1.1;
    --tw-scale-y: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-125 {
    --tw-scale-x: -1.25;
    --tw-scale-y: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-150 {
    --tw-scale-x: -1.5;
    --tw-scale-y: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-50 {
    --tw-scale-x: -.5;
    --tw-scale-y: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-75 {
    --tw-scale-x: -.75;
    --tw-scale-y: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-90 {
    --tw-scale-x: -.9;
    --tw-scale-y: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-95 {
    --tw-scale-x: -.95;
    --tw-scale-y: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-x-0 {
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-x-100 {
    --tw-scale-x: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-x-105 {
    --tw-scale-x: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-x-110 {
    --tw-scale-x: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-x-125 {
    --tw-scale-x: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-x-150 {
    --tw-scale-x: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-x-50 {
    --tw-scale-x: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-x-75 {
    --tw-scale-x: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-x-90 {
    --tw-scale-x: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-x-95 {
    --tw-scale-x: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-y-0 {
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-y-100 {
    --tw-scale-y: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-y-105 {
    --tw-scale-y: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-y-110 {
    --tw-scale-y: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-y-125 {
    --tw-scale-y: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-y-150 {
    --tw-scale-y: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-y-50 {
    --tw-scale-y: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-y-75 {
    --tw-scale-y: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-y-90 {
    --tw-scale-y: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-scale-y-95 {
    --tw-scale-y: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-x-0 {
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-x-100 {
    --tw-scale-x: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-x-105 {
    --tw-scale-x: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-x-110 {
    --tw-scale-x: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-x-125 {
    --tw-scale-x: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-x-150 {
    --tw-scale-x: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-x-50 {
    --tw-scale-x: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-x-75 {
    --tw-scale-x: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-x-90 {
    --tw-scale-x: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-x-95 {
    --tw-scale-x: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-y-0 {
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-y-100 {
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-y-105 {
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-y-110 {
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-y-125 {
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-y-150 {
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-y-50 {
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-y-75 {
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-y-90 {
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-y-95 {
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:transform, .lg\:transform-cpu {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:transform-gpu {
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:transform-none {
    transform: none;
  }

  @keyframes bounce {
    0%, 100% {
      animation-timing-function: cubic-bezier(.8, 0, 1, 1);
      transform: translateY(-25%);
    }

    50% {
      animation-timing-function: cubic-bezier(0, 0, .2, 1);
      transform: none;
    }
  }

  .lg\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .lg\:animate-none {
    animation: none;
  }

  @keyframes ping {
    75%, 100% {
      opacity: 0;
      transform: scale(2);
    }
  }

  .lg\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  @keyframes pulse {
    50% {
      opacity: .5;
    }
  }

  .lg\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .lg\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .lg\:cursor-alias {
    cursor: alias;
  }

  .lg\:cursor-all-scroll {
    cursor: all-scroll;
  }

  .lg\:cursor-auto {
    cursor: auto;
  }

  .lg\:cursor-cell {
    cursor: cell;
  }

  .lg\:cursor-col-resize {
    cursor: col-resize;
  }

  .lg\:cursor-context-menu {
    cursor: context-menu;
  }

  .lg\:cursor-copy {
    cursor: copy;
  }

  .lg\:cursor-crosshair {
    cursor: crosshair;
  }

  .lg\:cursor-default {
    cursor: default;
  }

  .lg\:cursor-e-resize {
    cursor: e-resize;
  }

  .lg\:cursor-ew-resize {
    cursor: ew-resize;
  }

  .lg\:cursor-grab {
    cursor: grab;
  }

  .lg\:cursor-grabbing {
    cursor: grabbing;
  }

  .lg\:cursor-help {
    cursor: help;
  }

  .lg\:cursor-move {
    cursor: move;
  }

  .lg\:cursor-n-resize {
    cursor: n-resize;
  }

  .lg\:cursor-ne-resize {
    cursor: ne-resize;
  }

  .lg\:cursor-nesw-resize {
    cursor: nesw-resize;
  }

  .lg\:cursor-no-drop {
    cursor: no-drop;
  }

  .lg\:cursor-none {
    cursor: none;
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .lg\:cursor-ns-resize {
    cursor: ns-resize;
  }

  .lg\:cursor-nw-resize {
    cursor: nw-resize;
  }

  .lg\:cursor-nwse-resize {
    cursor: nwse-resize;
  }

  .lg\:cursor-pointer {
    cursor: pointer;
  }

  .lg\:cursor-progress {
    cursor: progress;
  }

  .lg\:cursor-row-resize {
    cursor: row-resize;
  }

  .lg\:cursor-s-resize {
    cursor: s-resize;
  }

  .lg\:cursor-se-resize {
    cursor: se-resize;
  }

  .lg\:cursor-sw-resize {
    cursor: sw-resize;
  }

  .lg\:cursor-text {
    cursor: text;
  }

  .lg\:cursor-vertical-text {
    cursor: vertical-text;
  }

  .lg\:cursor-w-resize {
    cursor: w-resize;
  }

  .lg\:cursor-wait {
    cursor: wait;
  }

  .lg\:cursor-zoom-in {
    cursor: zoom-in;
  }

  .lg\:cursor-zoom-out {
    cursor: zoom-out;
  }

  .lg\:touch-auto {
    touch-action: auto;
  }

  .lg\:touch-none {
    touch-action: none;
  }

  .lg\:touch-pan-x {
    --tw-pan-x: pan-x;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .lg\:touch-pan-left {
    --tw-pan-x: pan-left;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .lg\:touch-pan-right {
    --tw-pan-x: pan-right;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .lg\:touch-pan-y {
    --tw-pan-y: pan-y;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .lg\:touch-pan-up {
    --tw-pan-y: pan-up;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .lg\:touch-pan-down {
    --tw-pan-y: pan-down;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .lg\:touch-pinch-zoom {
    --tw-pinch-zoom: pinch-zoom;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .lg\:touch-manipulation {
    touch-action: manipulation;
  }

  .lg\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .lg\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .lg\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .lg\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .lg\:resize-none {
    resize: none;
  }

  .lg\:resize-y {
    resize: vertical;
  }

  .lg\:resize-x {
    resize: horizontal;
  }

  .lg\:resize {
    resize: both;
  }

  .lg\:snap-none {
    scroll-snap-type: none;
  }

  .lg\:snap-x {
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
  }

  .lg\:snap-y {
    scroll-snap-type: y var(--tw-scroll-snap-strictness);
  }

  .lg\:snap-both {
    scroll-snap-type: both var(--tw-scroll-snap-strictness);
  }

  .lg\:snap-mandatory {
    --tw-scroll-snap-strictness: mandatory;
  }

  .lg\:snap-proximity {
    --tw-scroll-snap-strictness: proximity;
  }

  .lg\:snap-start {
    scroll-snap-align: start;
  }

  .lg\:snap-end {
    scroll-snap-align: end;
  }

  .lg\:snap-center {
    scroll-snap-align: center;
  }

  .lg\:snap-align-none {
    scroll-snap-align: none;
  }

  .lg\:snap-normal {
    scroll-snap-stop: normal;
  }

  .lg\:snap-always {
    scroll-snap-stop: always;
  }

  .lg\:-scroll-m-0 {
    scroll-margin: 0;
  }

  .lg\:-scroll-m-0\.5 {
    scroll-margin: -.125rem;
  }

  .lg\:-scroll-m-1 {
    scroll-margin: -.25rem;
  }

  .lg\:-scroll-m-1\.5 {
    scroll-margin: -.375rem;
  }

  .lg\:-scroll-m-10 {
    scroll-margin: -2.5rem;
  }

  .lg\:-scroll-m-11 {
    scroll-margin: -2.75rem;
  }

  .lg\:-scroll-m-12 {
    scroll-margin: -3rem;
  }

  .lg\:-scroll-m-14 {
    scroll-margin: -3.5rem;
  }

  .lg\:-scroll-m-16 {
    scroll-margin: -4rem;
  }

  .lg\:-scroll-m-2 {
    scroll-margin: -.5rem;
  }

  .lg\:-scroll-m-2\.5 {
    scroll-margin: -.625rem;
  }

  .lg\:-scroll-m-20 {
    scroll-margin: -5rem;
  }

  .lg\:-scroll-m-24 {
    scroll-margin: -6rem;
  }

  .lg\:-scroll-m-28 {
    scroll-margin: -7rem;
  }

  .lg\:-scroll-m-3 {
    scroll-margin: -.75rem;
  }

  .lg\:-scroll-m-3\.5 {
    scroll-margin: -.875rem;
  }

  .lg\:-scroll-m-32 {
    scroll-margin: -8rem;
  }

  .lg\:-scroll-m-36 {
    scroll-margin: -9rem;
  }

  .lg\:-scroll-m-4 {
    scroll-margin: -1rem;
  }

  .lg\:-scroll-m-40 {
    scroll-margin: -10rem;
  }

  .lg\:-scroll-m-44 {
    scroll-margin: -11rem;
  }

  .lg\:-scroll-m-48 {
    scroll-margin: -12rem;
  }

  .lg\:-scroll-m-5 {
    scroll-margin: -1.25rem;
  }

  .lg\:-scroll-m-52 {
    scroll-margin: -13rem;
  }

  .lg\:-scroll-m-56 {
    scroll-margin: -14rem;
  }

  .lg\:-scroll-m-6 {
    scroll-margin: -1.5rem;
  }

  .lg\:-scroll-m-60 {
    scroll-margin: -15rem;
  }

  .lg\:-scroll-m-64 {
    scroll-margin: -16rem;
  }

  .lg\:-scroll-m-7 {
    scroll-margin: -1.75rem;
  }

  .lg\:-scroll-m-72 {
    scroll-margin: -18rem;
  }

  .lg\:-scroll-m-8 {
    scroll-margin: -2rem;
  }

  .lg\:-scroll-m-80 {
    scroll-margin: -20rem;
  }

  .lg\:-scroll-m-9 {
    scroll-margin: -2.25rem;
  }

  .lg\:-scroll-m-96 {
    scroll-margin: -24rem;
  }

  .lg\:-scroll-m-px {
    scroll-margin: -1px;
  }

  .lg\:scroll-m-0 {
    scroll-margin: 0;
  }

  .lg\:scroll-m-0\.5 {
    scroll-margin: .125rem;
  }

  .lg\:scroll-m-1 {
    scroll-margin: .25rem;
  }

  .lg\:scroll-m-1\.5 {
    scroll-margin: .375rem;
  }

  .lg\:scroll-m-10 {
    scroll-margin: 2.5rem;
  }

  .lg\:scroll-m-11 {
    scroll-margin: 2.75rem;
  }

  .lg\:scroll-m-12 {
    scroll-margin: 3rem;
  }

  .lg\:scroll-m-14 {
    scroll-margin: 3.5rem;
  }

  .lg\:scroll-m-16 {
    scroll-margin: 4rem;
  }

  .lg\:scroll-m-2 {
    scroll-margin: .5rem;
  }

  .lg\:scroll-m-2\.5 {
    scroll-margin: .625rem;
  }

  .lg\:scroll-m-20 {
    scroll-margin: 5rem;
  }

  .lg\:scroll-m-24 {
    scroll-margin: 6rem;
  }

  .lg\:scroll-m-28 {
    scroll-margin: 7rem;
  }

  .lg\:scroll-m-3 {
    scroll-margin: .75rem;
  }

  .lg\:scroll-m-3\.5 {
    scroll-margin: .875rem;
  }

  .lg\:scroll-m-32 {
    scroll-margin: 8rem;
  }

  .lg\:scroll-m-36 {
    scroll-margin: 9rem;
  }

  .lg\:scroll-m-4 {
    scroll-margin: 1rem;
  }

  .lg\:scroll-m-40 {
    scroll-margin: 10rem;
  }

  .lg\:scroll-m-44 {
    scroll-margin: 11rem;
  }

  .lg\:scroll-m-48 {
    scroll-margin: 12rem;
  }

  .lg\:scroll-m-5 {
    scroll-margin: 1.25rem;
  }

  .lg\:scroll-m-52 {
    scroll-margin: 13rem;
  }

  .lg\:scroll-m-56 {
    scroll-margin: 14rem;
  }

  .lg\:scroll-m-6 {
    scroll-margin: 1.5rem;
  }

  .lg\:scroll-m-60 {
    scroll-margin: 15rem;
  }

  .lg\:scroll-m-64 {
    scroll-margin: 16rem;
  }

  .lg\:scroll-m-7 {
    scroll-margin: 1.75rem;
  }

  .lg\:scroll-m-72 {
    scroll-margin: 18rem;
  }

  .lg\:scroll-m-8 {
    scroll-margin: 2rem;
  }

  .lg\:scroll-m-80 {
    scroll-margin: 20rem;
  }

  .lg\:scroll-m-9 {
    scroll-margin: 2.25rem;
  }

  .lg\:scroll-m-96 {
    scroll-margin: 24rem;
  }

  .lg\:scroll-m-px {
    scroll-margin: 1px;
  }

  .lg\:-scroll-mx-0 {
    scroll-margin-left: 0;
    scroll-margin-right: 0;
  }

  .lg\:-scroll-mx-0\.5 {
    scroll-margin-left: -.125rem;
    scroll-margin-right: -.125rem;
  }

  .lg\:-scroll-mx-1 {
    scroll-margin-left: -.25rem;
    scroll-margin-right: -.25rem;
  }

  .lg\:-scroll-mx-1\.5 {
    scroll-margin-left: -.375rem;
    scroll-margin-right: -.375rem;
  }

  .lg\:-scroll-mx-10 {
    scroll-margin-left: -2.5rem;
    scroll-margin-right: -2.5rem;
  }

  .lg\:-scroll-mx-11 {
    scroll-margin-left: -2.75rem;
    scroll-margin-right: -2.75rem;
  }

  .lg\:-scroll-mx-12 {
    scroll-margin-left: -3rem;
    scroll-margin-right: -3rem;
  }

  .lg\:-scroll-mx-14 {
    scroll-margin-left: -3.5rem;
    scroll-margin-right: -3.5rem;
  }

  .lg\:-scroll-mx-16 {
    scroll-margin-left: -4rem;
    scroll-margin-right: -4rem;
  }

  .lg\:-scroll-mx-2 {
    scroll-margin-left: -.5rem;
    scroll-margin-right: -.5rem;
  }

  .lg\:-scroll-mx-2\.5 {
    scroll-margin-left: -.625rem;
    scroll-margin-right: -.625rem;
  }

  .lg\:-scroll-mx-20 {
    scroll-margin-left: -5rem;
    scroll-margin-right: -5rem;
  }

  .lg\:-scroll-mx-24 {
    scroll-margin-left: -6rem;
    scroll-margin-right: -6rem;
  }

  .lg\:-scroll-mx-28 {
    scroll-margin-left: -7rem;
    scroll-margin-right: -7rem;
  }

  .lg\:-scroll-mx-3 {
    scroll-margin-left: -.75rem;
    scroll-margin-right: -.75rem;
  }

  .lg\:-scroll-mx-3\.5 {
    scroll-margin-left: -.875rem;
    scroll-margin-right: -.875rem;
  }

  .lg\:-scroll-mx-32 {
    scroll-margin-left: -8rem;
    scroll-margin-right: -8rem;
  }

  .lg\:-scroll-mx-36 {
    scroll-margin-left: -9rem;
    scroll-margin-right: -9rem;
  }

  .lg\:-scroll-mx-4 {
    scroll-margin-left: -1rem;
    scroll-margin-right: -1rem;
  }

  .lg\:-scroll-mx-40 {
    scroll-margin-left: -10rem;
    scroll-margin-right: -10rem;
  }

  .lg\:-scroll-mx-44 {
    scroll-margin-left: -11rem;
    scroll-margin-right: -11rem;
  }

  .lg\:-scroll-mx-48 {
    scroll-margin-left: -12rem;
    scroll-margin-right: -12rem;
  }

  .lg\:-scroll-mx-5 {
    scroll-margin-left: -1.25rem;
    scroll-margin-right: -1.25rem;
  }

  .lg\:-scroll-mx-52 {
    scroll-margin-left: -13rem;
    scroll-margin-right: -13rem;
  }

  .lg\:-scroll-mx-56 {
    scroll-margin-left: -14rem;
    scroll-margin-right: -14rem;
  }

  .lg\:-scroll-mx-6 {
    scroll-margin-left: -1.5rem;
    scroll-margin-right: -1.5rem;
  }

  .lg\:-scroll-mx-60 {
    scroll-margin-left: -15rem;
    scroll-margin-right: -15rem;
  }

  .lg\:-scroll-mx-64 {
    scroll-margin-left: -16rem;
    scroll-margin-right: -16rem;
  }

  .lg\:-scroll-mx-7 {
    scroll-margin-left: -1.75rem;
    scroll-margin-right: -1.75rem;
  }

  .lg\:-scroll-mx-72 {
    scroll-margin-left: -18rem;
    scroll-margin-right: -18rem;
  }

  .lg\:-scroll-mx-8 {
    scroll-margin-left: -2rem;
    scroll-margin-right: -2rem;
  }

  .lg\:-scroll-mx-80 {
    scroll-margin-left: -20rem;
    scroll-margin-right: -20rem;
  }

  .lg\:-scroll-mx-9 {
    scroll-margin-left: -2.25rem;
    scroll-margin-right: -2.25rem;
  }

  .lg\:-scroll-mx-96 {
    scroll-margin-left: -24rem;
    scroll-margin-right: -24rem;
  }

  .lg\:-scroll-mx-px {
    scroll-margin-left: -1px;
    scroll-margin-right: -1px;
  }

  .lg\:-scroll-my-0 {
    scroll-margin-top: 0;
    scroll-margin-bottom: 0;
  }

  .lg\:-scroll-my-0\.5 {
    scroll-margin-top: -.125rem;
    scroll-margin-bottom: -.125rem;
  }

  .lg\:-scroll-my-1 {
    scroll-margin-top: -.25rem;
    scroll-margin-bottom: -.25rem;
  }

  .lg\:-scroll-my-1\.5 {
    scroll-margin-top: -.375rem;
    scroll-margin-bottom: -.375rem;
  }

  .lg\:-scroll-my-10 {
    scroll-margin-top: -2.5rem;
    scroll-margin-bottom: -2.5rem;
  }

  .lg\:-scroll-my-11 {
    scroll-margin-top: -2.75rem;
    scroll-margin-bottom: -2.75rem;
  }

  .lg\:-scroll-my-12 {
    scroll-margin-top: -3rem;
    scroll-margin-bottom: -3rem;
  }

  .lg\:-scroll-my-14 {
    scroll-margin-top: -3.5rem;
    scroll-margin-bottom: -3.5rem;
  }

  .lg\:-scroll-my-16 {
    scroll-margin-top: -4rem;
    scroll-margin-bottom: -4rem;
  }

  .lg\:-scroll-my-2 {
    scroll-margin-top: -.5rem;
    scroll-margin-bottom: -.5rem;
  }

  .lg\:-scroll-my-2\.5 {
    scroll-margin-top: -.625rem;
    scroll-margin-bottom: -.625rem;
  }

  .lg\:-scroll-my-20 {
    scroll-margin-top: -5rem;
    scroll-margin-bottom: -5rem;
  }

  .lg\:-scroll-my-24 {
    scroll-margin-top: -6rem;
    scroll-margin-bottom: -6rem;
  }

  .lg\:-scroll-my-28 {
    scroll-margin-top: -7rem;
    scroll-margin-bottom: -7rem;
  }

  .lg\:-scroll-my-3 {
    scroll-margin-top: -.75rem;
    scroll-margin-bottom: -.75rem;
  }

  .lg\:-scroll-my-3\.5 {
    scroll-margin-top: -.875rem;
    scroll-margin-bottom: -.875rem;
  }

  .lg\:-scroll-my-32 {
    scroll-margin-top: -8rem;
    scroll-margin-bottom: -8rem;
  }

  .lg\:-scroll-my-36 {
    scroll-margin-top: -9rem;
    scroll-margin-bottom: -9rem;
  }

  .lg\:-scroll-my-4 {
    scroll-margin-top: -1rem;
    scroll-margin-bottom: -1rem;
  }

  .lg\:-scroll-my-40 {
    scroll-margin-top: -10rem;
    scroll-margin-bottom: -10rem;
  }

  .lg\:-scroll-my-44 {
    scroll-margin-top: -11rem;
    scroll-margin-bottom: -11rem;
  }

  .lg\:-scroll-my-48 {
    scroll-margin-top: -12rem;
    scroll-margin-bottom: -12rem;
  }

  .lg\:-scroll-my-5 {
    scroll-margin-top: -1.25rem;
    scroll-margin-bottom: -1.25rem;
  }

  .lg\:-scroll-my-52 {
    scroll-margin-top: -13rem;
    scroll-margin-bottom: -13rem;
  }

  .lg\:-scroll-my-56 {
    scroll-margin-top: -14rem;
    scroll-margin-bottom: -14rem;
  }

  .lg\:-scroll-my-6 {
    scroll-margin-top: -1.5rem;
    scroll-margin-bottom: -1.5rem;
  }

  .lg\:-scroll-my-60 {
    scroll-margin-top: -15rem;
    scroll-margin-bottom: -15rem;
  }

  .lg\:-scroll-my-64 {
    scroll-margin-top: -16rem;
    scroll-margin-bottom: -16rem;
  }

  .lg\:-scroll-my-7 {
    scroll-margin-top: -1.75rem;
    scroll-margin-bottom: -1.75rem;
  }

  .lg\:-scroll-my-72 {
    scroll-margin-top: -18rem;
    scroll-margin-bottom: -18rem;
  }

  .lg\:-scroll-my-8 {
    scroll-margin-top: -2rem;
    scroll-margin-bottom: -2rem;
  }

  .lg\:-scroll-my-80 {
    scroll-margin-top: -20rem;
    scroll-margin-bottom: -20rem;
  }

  .lg\:-scroll-my-9 {
    scroll-margin-top: -2.25rem;
    scroll-margin-bottom: -2.25rem;
  }

  .lg\:-scroll-my-96 {
    scroll-margin-top: -24rem;
    scroll-margin-bottom: -24rem;
  }

  .lg\:-scroll-my-px {
    scroll-margin-top: -1px;
    scroll-margin-bottom: -1px;
  }

  .lg\:scroll-mx-0 {
    scroll-margin-left: 0;
    scroll-margin-right: 0;
  }

  .lg\:scroll-mx-0\.5 {
    scroll-margin-left: .125rem;
    scroll-margin-right: .125rem;
  }

  .lg\:scroll-mx-1 {
    scroll-margin-left: .25rem;
    scroll-margin-right: .25rem;
  }

  .lg\:scroll-mx-1\.5 {
    scroll-margin-left: .375rem;
    scroll-margin-right: .375rem;
  }

  .lg\:scroll-mx-10 {
    scroll-margin-left: 2.5rem;
    scroll-margin-right: 2.5rem;
  }

  .lg\:scroll-mx-11 {
    scroll-margin-left: 2.75rem;
    scroll-margin-right: 2.75rem;
  }

  .lg\:scroll-mx-12 {
    scroll-margin-left: 3rem;
    scroll-margin-right: 3rem;
  }

  .lg\:scroll-mx-14 {
    scroll-margin-left: 3.5rem;
    scroll-margin-right: 3.5rem;
  }

  .lg\:scroll-mx-16 {
    scroll-margin-left: 4rem;
    scroll-margin-right: 4rem;
  }

  .lg\:scroll-mx-2 {
    scroll-margin-left: .5rem;
    scroll-margin-right: .5rem;
  }

  .lg\:scroll-mx-2\.5 {
    scroll-margin-left: .625rem;
    scroll-margin-right: .625rem;
  }

  .lg\:scroll-mx-20 {
    scroll-margin-left: 5rem;
    scroll-margin-right: 5rem;
  }

  .lg\:scroll-mx-24 {
    scroll-margin-left: 6rem;
    scroll-margin-right: 6rem;
  }

  .lg\:scroll-mx-28 {
    scroll-margin-left: 7rem;
    scroll-margin-right: 7rem;
  }

  .lg\:scroll-mx-3 {
    scroll-margin-left: .75rem;
    scroll-margin-right: .75rem;
  }

  .lg\:scroll-mx-3\.5 {
    scroll-margin-left: .875rem;
    scroll-margin-right: .875rem;
  }

  .lg\:scroll-mx-32 {
    scroll-margin-left: 8rem;
    scroll-margin-right: 8rem;
  }

  .lg\:scroll-mx-36 {
    scroll-margin-left: 9rem;
    scroll-margin-right: 9rem;
  }

  .lg\:scroll-mx-4 {
    scroll-margin-left: 1rem;
    scroll-margin-right: 1rem;
  }

  .lg\:scroll-mx-40 {
    scroll-margin-left: 10rem;
    scroll-margin-right: 10rem;
  }

  .lg\:scroll-mx-44 {
    scroll-margin-left: 11rem;
    scroll-margin-right: 11rem;
  }

  .lg\:scroll-mx-48 {
    scroll-margin-left: 12rem;
    scroll-margin-right: 12rem;
  }

  .lg\:scroll-mx-5 {
    scroll-margin-left: 1.25rem;
    scroll-margin-right: 1.25rem;
  }

  .lg\:scroll-mx-52 {
    scroll-margin-left: 13rem;
    scroll-margin-right: 13rem;
  }

  .lg\:scroll-mx-56 {
    scroll-margin-left: 14rem;
    scroll-margin-right: 14rem;
  }

  .lg\:scroll-mx-6 {
    scroll-margin-left: 1.5rem;
    scroll-margin-right: 1.5rem;
  }

  .lg\:scroll-mx-60 {
    scroll-margin-left: 15rem;
    scroll-margin-right: 15rem;
  }

  .lg\:scroll-mx-64 {
    scroll-margin-left: 16rem;
    scroll-margin-right: 16rem;
  }

  .lg\:scroll-mx-7 {
    scroll-margin-left: 1.75rem;
    scroll-margin-right: 1.75rem;
  }

  .lg\:scroll-mx-72 {
    scroll-margin-left: 18rem;
    scroll-margin-right: 18rem;
  }

  .lg\:scroll-mx-8 {
    scroll-margin-left: 2rem;
    scroll-margin-right: 2rem;
  }

  .lg\:scroll-mx-80 {
    scroll-margin-left: 20rem;
    scroll-margin-right: 20rem;
  }

  .lg\:scroll-mx-9 {
    scroll-margin-left: 2.25rem;
    scroll-margin-right: 2.25rem;
  }

  .lg\:scroll-mx-96 {
    scroll-margin-left: 24rem;
    scroll-margin-right: 24rem;
  }

  .lg\:scroll-mx-px {
    scroll-margin-left: 1px;
    scroll-margin-right: 1px;
  }

  .lg\:scroll-my-0 {
    scroll-margin-top: 0;
    scroll-margin-bottom: 0;
  }

  .lg\:scroll-my-0\.5 {
    scroll-margin-top: .125rem;
    scroll-margin-bottom: .125rem;
  }

  .lg\:scroll-my-1 {
    scroll-margin-top: .25rem;
    scroll-margin-bottom: .25rem;
  }

  .lg\:scroll-my-1\.5 {
    scroll-margin-top: .375rem;
    scroll-margin-bottom: .375rem;
  }

  .lg\:scroll-my-10 {
    scroll-margin-top: 2.5rem;
    scroll-margin-bottom: 2.5rem;
  }

  .lg\:scroll-my-11 {
    scroll-margin-top: 2.75rem;
    scroll-margin-bottom: 2.75rem;
  }

  .lg\:scroll-my-12 {
    scroll-margin-top: 3rem;
    scroll-margin-bottom: 3rem;
  }

  .lg\:scroll-my-14 {
    scroll-margin-top: 3.5rem;
    scroll-margin-bottom: 3.5rem;
  }

  .lg\:scroll-my-16 {
    scroll-margin-top: 4rem;
    scroll-margin-bottom: 4rem;
  }

  .lg\:scroll-my-2 {
    scroll-margin-top: .5rem;
    scroll-margin-bottom: .5rem;
  }

  .lg\:scroll-my-2\.5 {
    scroll-margin-top: .625rem;
    scroll-margin-bottom: .625rem;
  }

  .lg\:scroll-my-20 {
    scroll-margin-top: 5rem;
    scroll-margin-bottom: 5rem;
  }

  .lg\:scroll-my-24 {
    scroll-margin-top: 6rem;
    scroll-margin-bottom: 6rem;
  }

  .lg\:scroll-my-28 {
    scroll-margin-top: 7rem;
    scroll-margin-bottom: 7rem;
  }

  .lg\:scroll-my-3 {
    scroll-margin-top: .75rem;
    scroll-margin-bottom: .75rem;
  }

  .lg\:scroll-my-3\.5 {
    scroll-margin-top: .875rem;
    scroll-margin-bottom: .875rem;
  }

  .lg\:scroll-my-32 {
    scroll-margin-top: 8rem;
    scroll-margin-bottom: 8rem;
  }

  .lg\:scroll-my-36 {
    scroll-margin-top: 9rem;
    scroll-margin-bottom: 9rem;
  }

  .lg\:scroll-my-4 {
    scroll-margin-top: 1rem;
    scroll-margin-bottom: 1rem;
  }

  .lg\:scroll-my-40 {
    scroll-margin-top: 10rem;
    scroll-margin-bottom: 10rem;
  }

  .lg\:scroll-my-44 {
    scroll-margin-top: 11rem;
    scroll-margin-bottom: 11rem;
  }

  .lg\:scroll-my-48 {
    scroll-margin-top: 12rem;
    scroll-margin-bottom: 12rem;
  }

  .lg\:scroll-my-5 {
    scroll-margin-top: 1.25rem;
    scroll-margin-bottom: 1.25rem;
  }

  .lg\:scroll-my-52 {
    scroll-margin-top: 13rem;
    scroll-margin-bottom: 13rem;
  }

  .lg\:scroll-my-56 {
    scroll-margin-top: 14rem;
    scroll-margin-bottom: 14rem;
  }

  .lg\:scroll-my-6 {
    scroll-margin-top: 1.5rem;
    scroll-margin-bottom: 1.5rem;
  }

  .lg\:scroll-my-60 {
    scroll-margin-top: 15rem;
    scroll-margin-bottom: 15rem;
  }

  .lg\:scroll-my-64 {
    scroll-margin-top: 16rem;
    scroll-margin-bottom: 16rem;
  }

  .lg\:scroll-my-7 {
    scroll-margin-top: 1.75rem;
    scroll-margin-bottom: 1.75rem;
  }

  .lg\:scroll-my-72 {
    scroll-margin-top: 18rem;
    scroll-margin-bottom: 18rem;
  }

  .lg\:scroll-my-8 {
    scroll-margin-top: 2rem;
    scroll-margin-bottom: 2rem;
  }

  .lg\:scroll-my-80 {
    scroll-margin-top: 20rem;
    scroll-margin-bottom: 20rem;
  }

  .lg\:scroll-my-9 {
    scroll-margin-top: 2.25rem;
    scroll-margin-bottom: 2.25rem;
  }

  .lg\:scroll-my-96 {
    scroll-margin-top: 24rem;
    scroll-margin-bottom: 24rem;
  }

  .lg\:scroll-my-px {
    scroll-margin-top: 1px;
    scroll-margin-bottom: 1px;
  }

  .lg\:-scroll-mb-0 {
    scroll-margin-bottom: 0;
  }

  .lg\:-scroll-mb-0\.5 {
    scroll-margin-bottom: -.125rem;
  }

  .lg\:-scroll-mb-1 {
    scroll-margin-bottom: -.25rem;
  }

  .lg\:-scroll-mb-1\.5 {
    scroll-margin-bottom: -.375rem;
  }

  .lg\:-scroll-mb-10 {
    scroll-margin-bottom: -2.5rem;
  }

  .lg\:-scroll-mb-11 {
    scroll-margin-bottom: -2.75rem;
  }

  .lg\:-scroll-mb-12 {
    scroll-margin-bottom: -3rem;
  }

  .lg\:-scroll-mb-14 {
    scroll-margin-bottom: -3.5rem;
  }

  .lg\:-scroll-mb-16 {
    scroll-margin-bottom: -4rem;
  }

  .lg\:-scroll-mb-2 {
    scroll-margin-bottom: -.5rem;
  }

  .lg\:-scroll-mb-2\.5 {
    scroll-margin-bottom: -.625rem;
  }

  .lg\:-scroll-mb-20 {
    scroll-margin-bottom: -5rem;
  }

  .lg\:-scroll-mb-24 {
    scroll-margin-bottom: -6rem;
  }

  .lg\:-scroll-mb-28 {
    scroll-margin-bottom: -7rem;
  }

  .lg\:-scroll-mb-3 {
    scroll-margin-bottom: -.75rem;
  }

  .lg\:-scroll-mb-3\.5 {
    scroll-margin-bottom: -.875rem;
  }

  .lg\:-scroll-mb-32 {
    scroll-margin-bottom: -8rem;
  }

  .lg\:-scroll-mb-36 {
    scroll-margin-bottom: -9rem;
  }

  .lg\:-scroll-mb-4 {
    scroll-margin-bottom: -1rem;
  }

  .lg\:-scroll-mb-40 {
    scroll-margin-bottom: -10rem;
  }

  .lg\:-scroll-mb-44 {
    scroll-margin-bottom: -11rem;
  }

  .lg\:-scroll-mb-48 {
    scroll-margin-bottom: -12rem;
  }

  .lg\:-scroll-mb-5 {
    scroll-margin-bottom: -1.25rem;
  }

  .lg\:-scroll-mb-52 {
    scroll-margin-bottom: -13rem;
  }

  .lg\:-scroll-mb-56 {
    scroll-margin-bottom: -14rem;
  }

  .lg\:-scroll-mb-6 {
    scroll-margin-bottom: -1.5rem;
  }

  .lg\:-scroll-mb-60 {
    scroll-margin-bottom: -15rem;
  }

  .lg\:-scroll-mb-64 {
    scroll-margin-bottom: -16rem;
  }

  .lg\:-scroll-mb-7 {
    scroll-margin-bottom: -1.75rem;
  }

  .lg\:-scroll-mb-72 {
    scroll-margin-bottom: -18rem;
  }

  .lg\:-scroll-mb-8 {
    scroll-margin-bottom: -2rem;
  }

  .lg\:-scroll-mb-80 {
    scroll-margin-bottom: -20rem;
  }

  .lg\:-scroll-mb-9 {
    scroll-margin-bottom: -2.25rem;
  }

  .lg\:-scroll-mb-96 {
    scroll-margin-bottom: -24rem;
  }

  .lg\:-scroll-mb-px {
    scroll-margin-bottom: -1px;
  }

  .lg\:-scroll-me-0 {
    scroll-margin-inline-end: 0;
  }

  .lg\:-scroll-me-0\.5 {
    scroll-margin-inline-end: -.125rem;
  }

  .lg\:-scroll-me-1 {
    scroll-margin-inline-end: -.25rem;
  }

  .lg\:-scroll-me-1\.5 {
    scroll-margin-inline-end: -.375rem;
  }

  .lg\:-scroll-me-10 {
    scroll-margin-inline-end: -2.5rem;
  }

  .lg\:-scroll-me-11 {
    scroll-margin-inline-end: -2.75rem;
  }

  .lg\:-scroll-me-12 {
    scroll-margin-inline-end: -3rem;
  }

  .lg\:-scroll-me-14 {
    scroll-margin-inline-end: -3.5rem;
  }

  .lg\:-scroll-me-16 {
    scroll-margin-inline-end: -4rem;
  }

  .lg\:-scroll-me-2 {
    scroll-margin-inline-end: -.5rem;
  }

  .lg\:-scroll-me-2\.5 {
    scroll-margin-inline-end: -.625rem;
  }

  .lg\:-scroll-me-20 {
    scroll-margin-inline-end: -5rem;
  }

  .lg\:-scroll-me-24 {
    scroll-margin-inline-end: -6rem;
  }

  .lg\:-scroll-me-28 {
    scroll-margin-inline-end: -7rem;
  }

  .lg\:-scroll-me-3 {
    scroll-margin-inline-end: -.75rem;
  }

  .lg\:-scroll-me-3\.5 {
    scroll-margin-inline-end: -.875rem;
  }

  .lg\:-scroll-me-32 {
    scroll-margin-inline-end: -8rem;
  }

  .lg\:-scroll-me-36 {
    scroll-margin-inline-end: -9rem;
  }

  .lg\:-scroll-me-4 {
    scroll-margin-inline-end: -1rem;
  }

  .lg\:-scroll-me-40 {
    scroll-margin-inline-end: -10rem;
  }

  .lg\:-scroll-me-44 {
    scroll-margin-inline-end: -11rem;
  }

  .lg\:-scroll-me-48 {
    scroll-margin-inline-end: -12rem;
  }

  .lg\:-scroll-me-5 {
    scroll-margin-inline-end: -1.25rem;
  }

  .lg\:-scroll-me-52 {
    scroll-margin-inline-end: -13rem;
  }

  .lg\:-scroll-me-56 {
    scroll-margin-inline-end: -14rem;
  }

  .lg\:-scroll-me-6 {
    scroll-margin-inline-end: -1.5rem;
  }

  .lg\:-scroll-me-60 {
    scroll-margin-inline-end: -15rem;
  }

  .lg\:-scroll-me-64 {
    scroll-margin-inline-end: -16rem;
  }

  .lg\:-scroll-me-7 {
    scroll-margin-inline-end: -1.75rem;
  }

  .lg\:-scroll-me-72 {
    scroll-margin-inline-end: -18rem;
  }

  .lg\:-scroll-me-8 {
    scroll-margin-inline-end: -2rem;
  }

  .lg\:-scroll-me-80 {
    scroll-margin-inline-end: -20rem;
  }

  .lg\:-scroll-me-9 {
    scroll-margin-inline-end: -2.25rem;
  }

  .lg\:-scroll-me-96 {
    scroll-margin-inline-end: -24rem;
  }

  .lg\:-scroll-me-px {
    scroll-margin-inline-end: -1px;
  }

  .lg\:-scroll-ml-0 {
    scroll-margin-left: 0;
  }

  .lg\:-scroll-ml-0\.5 {
    scroll-margin-left: -.125rem;
  }

  .lg\:-scroll-ml-1 {
    scroll-margin-left: -.25rem;
  }

  .lg\:-scroll-ml-1\.5 {
    scroll-margin-left: -.375rem;
  }

  .lg\:-scroll-ml-10 {
    scroll-margin-left: -2.5rem;
  }

  .lg\:-scroll-ml-11 {
    scroll-margin-left: -2.75rem;
  }

  .lg\:-scroll-ml-12 {
    scroll-margin-left: -3rem;
  }

  .lg\:-scroll-ml-14 {
    scroll-margin-left: -3.5rem;
  }

  .lg\:-scroll-ml-16 {
    scroll-margin-left: -4rem;
  }

  .lg\:-scroll-ml-2 {
    scroll-margin-left: -.5rem;
  }

  .lg\:-scroll-ml-2\.5 {
    scroll-margin-left: -.625rem;
  }

  .lg\:-scroll-ml-20 {
    scroll-margin-left: -5rem;
  }

  .lg\:-scroll-ml-24 {
    scroll-margin-left: -6rem;
  }

  .lg\:-scroll-ml-28 {
    scroll-margin-left: -7rem;
  }

  .lg\:-scroll-ml-3 {
    scroll-margin-left: -.75rem;
  }

  .lg\:-scroll-ml-3\.5 {
    scroll-margin-left: -.875rem;
  }

  .lg\:-scroll-ml-32 {
    scroll-margin-left: -8rem;
  }

  .lg\:-scroll-ml-36 {
    scroll-margin-left: -9rem;
  }

  .lg\:-scroll-ml-4 {
    scroll-margin-left: -1rem;
  }

  .lg\:-scroll-ml-40 {
    scroll-margin-left: -10rem;
  }

  .lg\:-scroll-ml-44 {
    scroll-margin-left: -11rem;
  }

  .lg\:-scroll-ml-48 {
    scroll-margin-left: -12rem;
  }

  .lg\:-scroll-ml-5 {
    scroll-margin-left: -1.25rem;
  }

  .lg\:-scroll-ml-52 {
    scroll-margin-left: -13rem;
  }

  .lg\:-scroll-ml-56 {
    scroll-margin-left: -14rem;
  }

  .lg\:-scroll-ml-6 {
    scroll-margin-left: -1.5rem;
  }

  .lg\:-scroll-ml-60 {
    scroll-margin-left: -15rem;
  }

  .lg\:-scroll-ml-64 {
    scroll-margin-left: -16rem;
  }

  .lg\:-scroll-ml-7 {
    scroll-margin-left: -1.75rem;
  }

  .lg\:-scroll-ml-72 {
    scroll-margin-left: -18rem;
  }

  .lg\:-scroll-ml-8 {
    scroll-margin-left: -2rem;
  }

  .lg\:-scroll-ml-80 {
    scroll-margin-left: -20rem;
  }

  .lg\:-scroll-ml-9 {
    scroll-margin-left: -2.25rem;
  }

  .lg\:-scroll-ml-96 {
    scroll-margin-left: -24rem;
  }

  .lg\:-scroll-ml-px {
    scroll-margin-left: -1px;
  }

  .lg\:-scroll-mr-0 {
    scroll-margin-right: 0;
  }

  .lg\:-scroll-mr-0\.5 {
    scroll-margin-right: -.125rem;
  }

  .lg\:-scroll-mr-1 {
    scroll-margin-right: -.25rem;
  }

  .lg\:-scroll-mr-1\.5 {
    scroll-margin-right: -.375rem;
  }

  .lg\:-scroll-mr-10 {
    scroll-margin-right: -2.5rem;
  }

  .lg\:-scroll-mr-11 {
    scroll-margin-right: -2.75rem;
  }

  .lg\:-scroll-mr-12 {
    scroll-margin-right: -3rem;
  }

  .lg\:-scroll-mr-14 {
    scroll-margin-right: -3.5rem;
  }

  .lg\:-scroll-mr-16 {
    scroll-margin-right: -4rem;
  }

  .lg\:-scroll-mr-2 {
    scroll-margin-right: -.5rem;
  }

  .lg\:-scroll-mr-2\.5 {
    scroll-margin-right: -.625rem;
  }

  .lg\:-scroll-mr-20 {
    scroll-margin-right: -5rem;
  }

  .lg\:-scroll-mr-24 {
    scroll-margin-right: -6rem;
  }

  .lg\:-scroll-mr-28 {
    scroll-margin-right: -7rem;
  }

  .lg\:-scroll-mr-3 {
    scroll-margin-right: -.75rem;
  }

  .lg\:-scroll-mr-3\.5 {
    scroll-margin-right: -.875rem;
  }

  .lg\:-scroll-mr-32 {
    scroll-margin-right: -8rem;
  }

  .lg\:-scroll-mr-36 {
    scroll-margin-right: -9rem;
  }

  .lg\:-scroll-mr-4 {
    scroll-margin-right: -1rem;
  }

  .lg\:-scroll-mr-40 {
    scroll-margin-right: -10rem;
  }

  .lg\:-scroll-mr-44 {
    scroll-margin-right: -11rem;
  }

  .lg\:-scroll-mr-48 {
    scroll-margin-right: -12rem;
  }

  .lg\:-scroll-mr-5 {
    scroll-margin-right: -1.25rem;
  }

  .lg\:-scroll-mr-52 {
    scroll-margin-right: -13rem;
  }

  .lg\:-scroll-mr-56 {
    scroll-margin-right: -14rem;
  }

  .lg\:-scroll-mr-6 {
    scroll-margin-right: -1.5rem;
  }

  .lg\:-scroll-mr-60 {
    scroll-margin-right: -15rem;
  }

  .lg\:-scroll-mr-64 {
    scroll-margin-right: -16rem;
  }

  .lg\:-scroll-mr-7 {
    scroll-margin-right: -1.75rem;
  }

  .lg\:-scroll-mr-72 {
    scroll-margin-right: -18rem;
  }

  .lg\:-scroll-mr-8 {
    scroll-margin-right: -2rem;
  }

  .lg\:-scroll-mr-80 {
    scroll-margin-right: -20rem;
  }

  .lg\:-scroll-mr-9 {
    scroll-margin-right: -2.25rem;
  }

  .lg\:-scroll-mr-96 {
    scroll-margin-right: -24rem;
  }

  .lg\:-scroll-mr-px {
    scroll-margin-right: -1px;
  }

  .lg\:-scroll-ms-0 {
    scroll-margin-inline-start: 0;
  }

  .lg\:-scroll-ms-0\.5 {
    scroll-margin-inline-start: -.125rem;
  }

  .lg\:-scroll-ms-1 {
    scroll-margin-inline-start: -.25rem;
  }

  .lg\:-scroll-ms-1\.5 {
    scroll-margin-inline-start: -.375rem;
  }

  .lg\:-scroll-ms-10 {
    scroll-margin-inline-start: -2.5rem;
  }

  .lg\:-scroll-ms-11 {
    scroll-margin-inline-start: -2.75rem;
  }

  .lg\:-scroll-ms-12 {
    scroll-margin-inline-start: -3rem;
  }

  .lg\:-scroll-ms-14 {
    scroll-margin-inline-start: -3.5rem;
  }

  .lg\:-scroll-ms-16 {
    scroll-margin-inline-start: -4rem;
  }

  .lg\:-scroll-ms-2 {
    scroll-margin-inline-start: -.5rem;
  }

  .lg\:-scroll-ms-2\.5 {
    scroll-margin-inline-start: -.625rem;
  }

  .lg\:-scroll-ms-20 {
    scroll-margin-inline-start: -5rem;
  }

  .lg\:-scroll-ms-24 {
    scroll-margin-inline-start: -6rem;
  }

  .lg\:-scroll-ms-28 {
    scroll-margin-inline-start: -7rem;
  }

  .lg\:-scroll-ms-3 {
    scroll-margin-inline-start: -.75rem;
  }

  .lg\:-scroll-ms-3\.5 {
    scroll-margin-inline-start: -.875rem;
  }

  .lg\:-scroll-ms-32 {
    scroll-margin-inline-start: -8rem;
  }

  .lg\:-scroll-ms-36 {
    scroll-margin-inline-start: -9rem;
  }

  .lg\:-scroll-ms-4 {
    scroll-margin-inline-start: -1rem;
  }

  .lg\:-scroll-ms-40 {
    scroll-margin-inline-start: -10rem;
  }

  .lg\:-scroll-ms-44 {
    scroll-margin-inline-start: -11rem;
  }

  .lg\:-scroll-ms-48 {
    scroll-margin-inline-start: -12rem;
  }

  .lg\:-scroll-ms-5 {
    scroll-margin-inline-start: -1.25rem;
  }

  .lg\:-scroll-ms-52 {
    scroll-margin-inline-start: -13rem;
  }

  .lg\:-scroll-ms-56 {
    scroll-margin-inline-start: -14rem;
  }

  .lg\:-scroll-ms-6 {
    scroll-margin-inline-start: -1.5rem;
  }

  .lg\:-scroll-ms-60 {
    scroll-margin-inline-start: -15rem;
  }

  .lg\:-scroll-ms-64 {
    scroll-margin-inline-start: -16rem;
  }

  .lg\:-scroll-ms-7 {
    scroll-margin-inline-start: -1.75rem;
  }

  .lg\:-scroll-ms-72 {
    scroll-margin-inline-start: -18rem;
  }

  .lg\:-scroll-ms-8 {
    scroll-margin-inline-start: -2rem;
  }

  .lg\:-scroll-ms-80 {
    scroll-margin-inline-start: -20rem;
  }

  .lg\:-scroll-ms-9 {
    scroll-margin-inline-start: -2.25rem;
  }

  .lg\:-scroll-ms-96 {
    scroll-margin-inline-start: -24rem;
  }

  .lg\:-scroll-ms-px {
    scroll-margin-inline-start: -1px;
  }

  .lg\:-scroll-mt-0 {
    scroll-margin-top: 0;
  }

  .lg\:-scroll-mt-0\.5 {
    scroll-margin-top: -.125rem;
  }

  .lg\:-scroll-mt-1 {
    scroll-margin-top: -.25rem;
  }

  .lg\:-scroll-mt-1\.5 {
    scroll-margin-top: -.375rem;
  }

  .lg\:-scroll-mt-10 {
    scroll-margin-top: -2.5rem;
  }

  .lg\:-scroll-mt-11 {
    scroll-margin-top: -2.75rem;
  }

  .lg\:-scroll-mt-12 {
    scroll-margin-top: -3rem;
  }

  .lg\:-scroll-mt-14 {
    scroll-margin-top: -3.5rem;
  }

  .lg\:-scroll-mt-16 {
    scroll-margin-top: -4rem;
  }

  .lg\:-scroll-mt-2 {
    scroll-margin-top: -.5rem;
  }

  .lg\:-scroll-mt-2\.5 {
    scroll-margin-top: -.625rem;
  }

  .lg\:-scroll-mt-20 {
    scroll-margin-top: -5rem;
  }

  .lg\:-scroll-mt-24 {
    scroll-margin-top: -6rem;
  }

  .lg\:-scroll-mt-28 {
    scroll-margin-top: -7rem;
  }

  .lg\:-scroll-mt-3 {
    scroll-margin-top: -.75rem;
  }

  .lg\:-scroll-mt-3\.5 {
    scroll-margin-top: -.875rem;
  }

  .lg\:-scroll-mt-32 {
    scroll-margin-top: -8rem;
  }

  .lg\:-scroll-mt-36 {
    scroll-margin-top: -9rem;
  }

  .lg\:-scroll-mt-4 {
    scroll-margin-top: -1rem;
  }

  .lg\:-scroll-mt-40 {
    scroll-margin-top: -10rem;
  }

  .lg\:-scroll-mt-44 {
    scroll-margin-top: -11rem;
  }

  .lg\:-scroll-mt-48 {
    scroll-margin-top: -12rem;
  }

  .lg\:-scroll-mt-5 {
    scroll-margin-top: -1.25rem;
  }

  .lg\:-scroll-mt-52 {
    scroll-margin-top: -13rem;
  }

  .lg\:-scroll-mt-56 {
    scroll-margin-top: -14rem;
  }

  .lg\:-scroll-mt-6 {
    scroll-margin-top: -1.5rem;
  }

  .lg\:-scroll-mt-60 {
    scroll-margin-top: -15rem;
  }

  .lg\:-scroll-mt-64 {
    scroll-margin-top: -16rem;
  }

  .lg\:-scroll-mt-7 {
    scroll-margin-top: -1.75rem;
  }

  .lg\:-scroll-mt-72 {
    scroll-margin-top: -18rem;
  }

  .lg\:-scroll-mt-8 {
    scroll-margin-top: -2rem;
  }

  .lg\:-scroll-mt-80 {
    scroll-margin-top: -20rem;
  }

  .lg\:-scroll-mt-9 {
    scroll-margin-top: -2.25rem;
  }

  .lg\:-scroll-mt-96 {
    scroll-margin-top: -24rem;
  }

  .lg\:-scroll-mt-px {
    scroll-margin-top: -1px;
  }

  .lg\:scroll-mb-0 {
    scroll-margin-bottom: 0;
  }

  .lg\:scroll-mb-0\.5 {
    scroll-margin-bottom: .125rem;
  }

  .lg\:scroll-mb-1 {
    scroll-margin-bottom: .25rem;
  }

  .lg\:scroll-mb-1\.5 {
    scroll-margin-bottom: .375rem;
  }

  .lg\:scroll-mb-10 {
    scroll-margin-bottom: 2.5rem;
  }

  .lg\:scroll-mb-11 {
    scroll-margin-bottom: 2.75rem;
  }

  .lg\:scroll-mb-12 {
    scroll-margin-bottom: 3rem;
  }

  .lg\:scroll-mb-14 {
    scroll-margin-bottom: 3.5rem;
  }

  .lg\:scroll-mb-16 {
    scroll-margin-bottom: 4rem;
  }

  .lg\:scroll-mb-2 {
    scroll-margin-bottom: .5rem;
  }

  .lg\:scroll-mb-2\.5 {
    scroll-margin-bottom: .625rem;
  }

  .lg\:scroll-mb-20 {
    scroll-margin-bottom: 5rem;
  }

  .lg\:scroll-mb-24 {
    scroll-margin-bottom: 6rem;
  }

  .lg\:scroll-mb-28 {
    scroll-margin-bottom: 7rem;
  }

  .lg\:scroll-mb-3 {
    scroll-margin-bottom: .75rem;
  }

  .lg\:scroll-mb-3\.5 {
    scroll-margin-bottom: .875rem;
  }

  .lg\:scroll-mb-32 {
    scroll-margin-bottom: 8rem;
  }

  .lg\:scroll-mb-36 {
    scroll-margin-bottom: 9rem;
  }

  .lg\:scroll-mb-4 {
    scroll-margin-bottom: 1rem;
  }

  .lg\:scroll-mb-40 {
    scroll-margin-bottom: 10rem;
  }

  .lg\:scroll-mb-44 {
    scroll-margin-bottom: 11rem;
  }

  .lg\:scroll-mb-48 {
    scroll-margin-bottom: 12rem;
  }

  .lg\:scroll-mb-5 {
    scroll-margin-bottom: 1.25rem;
  }

  .lg\:scroll-mb-52 {
    scroll-margin-bottom: 13rem;
  }

  .lg\:scroll-mb-56 {
    scroll-margin-bottom: 14rem;
  }

  .lg\:scroll-mb-6 {
    scroll-margin-bottom: 1.5rem;
  }

  .lg\:scroll-mb-60 {
    scroll-margin-bottom: 15rem;
  }

  .lg\:scroll-mb-64 {
    scroll-margin-bottom: 16rem;
  }

  .lg\:scroll-mb-7 {
    scroll-margin-bottom: 1.75rem;
  }

  .lg\:scroll-mb-72 {
    scroll-margin-bottom: 18rem;
  }

  .lg\:scroll-mb-8 {
    scroll-margin-bottom: 2rem;
  }

  .lg\:scroll-mb-80 {
    scroll-margin-bottom: 20rem;
  }

  .lg\:scroll-mb-9 {
    scroll-margin-bottom: 2.25rem;
  }

  .lg\:scroll-mb-96 {
    scroll-margin-bottom: 24rem;
  }

  .lg\:scroll-mb-px {
    scroll-margin-bottom: 1px;
  }

  .lg\:scroll-me-0 {
    scroll-margin-inline-end: 0;
  }

  .lg\:scroll-me-0\.5 {
    scroll-margin-inline-end: .125rem;
  }

  .lg\:scroll-me-1 {
    scroll-margin-inline-end: .25rem;
  }

  .lg\:scroll-me-1\.5 {
    scroll-margin-inline-end: .375rem;
  }

  .lg\:scroll-me-10 {
    scroll-margin-inline-end: 2.5rem;
  }

  .lg\:scroll-me-11 {
    scroll-margin-inline-end: 2.75rem;
  }

  .lg\:scroll-me-12 {
    scroll-margin-inline-end: 3rem;
  }

  .lg\:scroll-me-14 {
    scroll-margin-inline-end: 3.5rem;
  }

  .lg\:scroll-me-16 {
    scroll-margin-inline-end: 4rem;
  }

  .lg\:scroll-me-2 {
    scroll-margin-inline-end: .5rem;
  }

  .lg\:scroll-me-2\.5 {
    scroll-margin-inline-end: .625rem;
  }

  .lg\:scroll-me-20 {
    scroll-margin-inline-end: 5rem;
  }

  .lg\:scroll-me-24 {
    scroll-margin-inline-end: 6rem;
  }

  .lg\:scroll-me-28 {
    scroll-margin-inline-end: 7rem;
  }

  .lg\:scroll-me-3 {
    scroll-margin-inline-end: .75rem;
  }

  .lg\:scroll-me-3\.5 {
    scroll-margin-inline-end: .875rem;
  }

  .lg\:scroll-me-32 {
    scroll-margin-inline-end: 8rem;
  }

  .lg\:scroll-me-36 {
    scroll-margin-inline-end: 9rem;
  }

  .lg\:scroll-me-4 {
    scroll-margin-inline-end: 1rem;
  }

  .lg\:scroll-me-40 {
    scroll-margin-inline-end: 10rem;
  }

  .lg\:scroll-me-44 {
    scroll-margin-inline-end: 11rem;
  }

  .lg\:scroll-me-48 {
    scroll-margin-inline-end: 12rem;
  }

  .lg\:scroll-me-5 {
    scroll-margin-inline-end: 1.25rem;
  }

  .lg\:scroll-me-52 {
    scroll-margin-inline-end: 13rem;
  }

  .lg\:scroll-me-56 {
    scroll-margin-inline-end: 14rem;
  }

  .lg\:scroll-me-6 {
    scroll-margin-inline-end: 1.5rem;
  }

  .lg\:scroll-me-60 {
    scroll-margin-inline-end: 15rem;
  }

  .lg\:scroll-me-64 {
    scroll-margin-inline-end: 16rem;
  }

  .lg\:scroll-me-7 {
    scroll-margin-inline-end: 1.75rem;
  }

  .lg\:scroll-me-72 {
    scroll-margin-inline-end: 18rem;
  }

  .lg\:scroll-me-8 {
    scroll-margin-inline-end: 2rem;
  }

  .lg\:scroll-me-80 {
    scroll-margin-inline-end: 20rem;
  }

  .lg\:scroll-me-9 {
    scroll-margin-inline-end: 2.25rem;
  }

  .lg\:scroll-me-96 {
    scroll-margin-inline-end: 24rem;
  }

  .lg\:scroll-me-px {
    scroll-margin-inline-end: 1px;
  }

  .lg\:scroll-ml-0 {
    scroll-margin-left: 0;
  }

  .lg\:scroll-ml-0\.5 {
    scroll-margin-left: .125rem;
  }

  .lg\:scroll-ml-1 {
    scroll-margin-left: .25rem;
  }

  .lg\:scroll-ml-1\.5 {
    scroll-margin-left: .375rem;
  }

  .lg\:scroll-ml-10 {
    scroll-margin-left: 2.5rem;
  }

  .lg\:scroll-ml-11 {
    scroll-margin-left: 2.75rem;
  }

  .lg\:scroll-ml-12 {
    scroll-margin-left: 3rem;
  }

  .lg\:scroll-ml-14 {
    scroll-margin-left: 3.5rem;
  }

  .lg\:scroll-ml-16 {
    scroll-margin-left: 4rem;
  }

  .lg\:scroll-ml-2 {
    scroll-margin-left: .5rem;
  }

  .lg\:scroll-ml-2\.5 {
    scroll-margin-left: .625rem;
  }

  .lg\:scroll-ml-20 {
    scroll-margin-left: 5rem;
  }

  .lg\:scroll-ml-24 {
    scroll-margin-left: 6rem;
  }

  .lg\:scroll-ml-28 {
    scroll-margin-left: 7rem;
  }

  .lg\:scroll-ml-3 {
    scroll-margin-left: .75rem;
  }

  .lg\:scroll-ml-3\.5 {
    scroll-margin-left: .875rem;
  }

  .lg\:scroll-ml-32 {
    scroll-margin-left: 8rem;
  }

  .lg\:scroll-ml-36 {
    scroll-margin-left: 9rem;
  }

  .lg\:scroll-ml-4 {
    scroll-margin-left: 1rem;
  }

  .lg\:scroll-ml-40 {
    scroll-margin-left: 10rem;
  }

  .lg\:scroll-ml-44 {
    scroll-margin-left: 11rem;
  }

  .lg\:scroll-ml-48 {
    scroll-margin-left: 12rem;
  }

  .lg\:scroll-ml-5 {
    scroll-margin-left: 1.25rem;
  }

  .lg\:scroll-ml-52 {
    scroll-margin-left: 13rem;
  }

  .lg\:scroll-ml-56 {
    scroll-margin-left: 14rem;
  }

  .lg\:scroll-ml-6 {
    scroll-margin-left: 1.5rem;
  }

  .lg\:scroll-ml-60 {
    scroll-margin-left: 15rem;
  }

  .lg\:scroll-ml-64 {
    scroll-margin-left: 16rem;
  }

  .lg\:scroll-ml-7 {
    scroll-margin-left: 1.75rem;
  }

  .lg\:scroll-ml-72 {
    scroll-margin-left: 18rem;
  }

  .lg\:scroll-ml-8 {
    scroll-margin-left: 2rem;
  }

  .lg\:scroll-ml-80 {
    scroll-margin-left: 20rem;
  }

  .lg\:scroll-ml-9 {
    scroll-margin-left: 2.25rem;
  }

  .lg\:scroll-ml-96 {
    scroll-margin-left: 24rem;
  }

  .lg\:scroll-ml-px {
    scroll-margin-left: 1px;
  }

  .lg\:scroll-mr-0 {
    scroll-margin-right: 0;
  }

  .lg\:scroll-mr-0\.5 {
    scroll-margin-right: .125rem;
  }

  .lg\:scroll-mr-1 {
    scroll-margin-right: .25rem;
  }

  .lg\:scroll-mr-1\.5 {
    scroll-margin-right: .375rem;
  }

  .lg\:scroll-mr-10 {
    scroll-margin-right: 2.5rem;
  }

  .lg\:scroll-mr-11 {
    scroll-margin-right: 2.75rem;
  }

  .lg\:scroll-mr-12 {
    scroll-margin-right: 3rem;
  }

  .lg\:scroll-mr-14 {
    scroll-margin-right: 3.5rem;
  }

  .lg\:scroll-mr-16 {
    scroll-margin-right: 4rem;
  }

  .lg\:scroll-mr-2 {
    scroll-margin-right: .5rem;
  }

  .lg\:scroll-mr-2\.5 {
    scroll-margin-right: .625rem;
  }

  .lg\:scroll-mr-20 {
    scroll-margin-right: 5rem;
  }

  .lg\:scroll-mr-24 {
    scroll-margin-right: 6rem;
  }

  .lg\:scroll-mr-28 {
    scroll-margin-right: 7rem;
  }

  .lg\:scroll-mr-3 {
    scroll-margin-right: .75rem;
  }

  .lg\:scroll-mr-3\.5 {
    scroll-margin-right: .875rem;
  }

  .lg\:scroll-mr-32 {
    scroll-margin-right: 8rem;
  }

  .lg\:scroll-mr-36 {
    scroll-margin-right: 9rem;
  }

  .lg\:scroll-mr-4 {
    scroll-margin-right: 1rem;
  }

  .lg\:scroll-mr-40 {
    scroll-margin-right: 10rem;
  }

  .lg\:scroll-mr-44 {
    scroll-margin-right: 11rem;
  }

  .lg\:scroll-mr-48 {
    scroll-margin-right: 12rem;
  }

  .lg\:scroll-mr-5 {
    scroll-margin-right: 1.25rem;
  }

  .lg\:scroll-mr-52 {
    scroll-margin-right: 13rem;
  }

  .lg\:scroll-mr-56 {
    scroll-margin-right: 14rem;
  }

  .lg\:scroll-mr-6 {
    scroll-margin-right: 1.5rem;
  }

  .lg\:scroll-mr-60 {
    scroll-margin-right: 15rem;
  }

  .lg\:scroll-mr-64 {
    scroll-margin-right: 16rem;
  }

  .lg\:scroll-mr-7 {
    scroll-margin-right: 1.75rem;
  }

  .lg\:scroll-mr-72 {
    scroll-margin-right: 18rem;
  }

  .lg\:scroll-mr-8 {
    scroll-margin-right: 2rem;
  }

  .lg\:scroll-mr-80 {
    scroll-margin-right: 20rem;
  }

  .lg\:scroll-mr-9 {
    scroll-margin-right: 2.25rem;
  }

  .lg\:scroll-mr-96 {
    scroll-margin-right: 24rem;
  }

  .lg\:scroll-mr-px {
    scroll-margin-right: 1px;
  }

  .lg\:scroll-ms-0 {
    scroll-margin-inline-start: 0;
  }

  .lg\:scroll-ms-0\.5 {
    scroll-margin-inline-start: .125rem;
  }

  .lg\:scroll-ms-1 {
    scroll-margin-inline-start: .25rem;
  }

  .lg\:scroll-ms-1\.5 {
    scroll-margin-inline-start: .375rem;
  }

  .lg\:scroll-ms-10 {
    scroll-margin-inline-start: 2.5rem;
  }

  .lg\:scroll-ms-11 {
    scroll-margin-inline-start: 2.75rem;
  }

  .lg\:scroll-ms-12 {
    scroll-margin-inline-start: 3rem;
  }

  .lg\:scroll-ms-14 {
    scroll-margin-inline-start: 3.5rem;
  }

  .lg\:scroll-ms-16 {
    scroll-margin-inline-start: 4rem;
  }

  .lg\:scroll-ms-2 {
    scroll-margin-inline-start: .5rem;
  }

  .lg\:scroll-ms-2\.5 {
    scroll-margin-inline-start: .625rem;
  }

  .lg\:scroll-ms-20 {
    scroll-margin-inline-start: 5rem;
  }

  .lg\:scroll-ms-24 {
    scroll-margin-inline-start: 6rem;
  }

  .lg\:scroll-ms-28 {
    scroll-margin-inline-start: 7rem;
  }

  .lg\:scroll-ms-3 {
    scroll-margin-inline-start: .75rem;
  }

  .lg\:scroll-ms-3\.5 {
    scroll-margin-inline-start: .875rem;
  }

  .lg\:scroll-ms-32 {
    scroll-margin-inline-start: 8rem;
  }

  .lg\:scroll-ms-36 {
    scroll-margin-inline-start: 9rem;
  }

  .lg\:scroll-ms-4 {
    scroll-margin-inline-start: 1rem;
  }

  .lg\:scroll-ms-40 {
    scroll-margin-inline-start: 10rem;
  }

  .lg\:scroll-ms-44 {
    scroll-margin-inline-start: 11rem;
  }

  .lg\:scroll-ms-48 {
    scroll-margin-inline-start: 12rem;
  }

  .lg\:scroll-ms-5 {
    scroll-margin-inline-start: 1.25rem;
  }

  .lg\:scroll-ms-52 {
    scroll-margin-inline-start: 13rem;
  }

  .lg\:scroll-ms-56 {
    scroll-margin-inline-start: 14rem;
  }

  .lg\:scroll-ms-6 {
    scroll-margin-inline-start: 1.5rem;
  }

  .lg\:scroll-ms-60 {
    scroll-margin-inline-start: 15rem;
  }

  .lg\:scroll-ms-64 {
    scroll-margin-inline-start: 16rem;
  }

  .lg\:scroll-ms-7 {
    scroll-margin-inline-start: 1.75rem;
  }

  .lg\:scroll-ms-72 {
    scroll-margin-inline-start: 18rem;
  }

  .lg\:scroll-ms-8 {
    scroll-margin-inline-start: 2rem;
  }

  .lg\:scroll-ms-80 {
    scroll-margin-inline-start: 20rem;
  }

  .lg\:scroll-ms-9 {
    scroll-margin-inline-start: 2.25rem;
  }

  .lg\:scroll-ms-96 {
    scroll-margin-inline-start: 24rem;
  }

  .lg\:scroll-ms-px {
    scroll-margin-inline-start: 1px;
  }

  .lg\:scroll-mt-0 {
    scroll-margin-top: 0;
  }

  .lg\:scroll-mt-0\.5 {
    scroll-margin-top: .125rem;
  }

  .lg\:scroll-mt-1 {
    scroll-margin-top: .25rem;
  }

  .lg\:scroll-mt-1\.5 {
    scroll-margin-top: .375rem;
  }

  .lg\:scroll-mt-10 {
    scroll-margin-top: 2.5rem;
  }

  .lg\:scroll-mt-11 {
    scroll-margin-top: 2.75rem;
  }

  .lg\:scroll-mt-12 {
    scroll-margin-top: 3rem;
  }

  .lg\:scroll-mt-14 {
    scroll-margin-top: 3.5rem;
  }

  .lg\:scroll-mt-16 {
    scroll-margin-top: 4rem;
  }

  .lg\:scroll-mt-2 {
    scroll-margin-top: .5rem;
  }

  .lg\:scroll-mt-2\.5 {
    scroll-margin-top: .625rem;
  }

  .lg\:scroll-mt-20 {
    scroll-margin-top: 5rem;
  }

  .lg\:scroll-mt-24 {
    scroll-margin-top: 6rem;
  }

  .lg\:scroll-mt-28 {
    scroll-margin-top: 7rem;
  }

  .lg\:scroll-mt-3 {
    scroll-margin-top: .75rem;
  }

  .lg\:scroll-mt-3\.5 {
    scroll-margin-top: .875rem;
  }

  .lg\:scroll-mt-32 {
    scroll-margin-top: 8rem;
  }

  .lg\:scroll-mt-36 {
    scroll-margin-top: 9rem;
  }

  .lg\:scroll-mt-4 {
    scroll-margin-top: 1rem;
  }

  .lg\:scroll-mt-40 {
    scroll-margin-top: 10rem;
  }

  .lg\:scroll-mt-44 {
    scroll-margin-top: 11rem;
  }

  .lg\:scroll-mt-48 {
    scroll-margin-top: 12rem;
  }

  .lg\:scroll-mt-5 {
    scroll-margin-top: 1.25rem;
  }

  .lg\:scroll-mt-52 {
    scroll-margin-top: 13rem;
  }

  .lg\:scroll-mt-56 {
    scroll-margin-top: 14rem;
  }

  .lg\:scroll-mt-6 {
    scroll-margin-top: 1.5rem;
  }

  .lg\:scroll-mt-60 {
    scroll-margin-top: 15rem;
  }

  .lg\:scroll-mt-64 {
    scroll-margin-top: 16rem;
  }

  .lg\:scroll-mt-7 {
    scroll-margin-top: 1.75rem;
  }

  .lg\:scroll-mt-72 {
    scroll-margin-top: 18rem;
  }

  .lg\:scroll-mt-8 {
    scroll-margin-top: 2rem;
  }

  .lg\:scroll-mt-80 {
    scroll-margin-top: 20rem;
  }

  .lg\:scroll-mt-9 {
    scroll-margin-top: 2.25rem;
  }

  .lg\:scroll-mt-96 {
    scroll-margin-top: 24rem;
  }

  .lg\:scroll-mt-px {
    scroll-margin-top: 1px;
  }

  .lg\:scroll-p-0 {
    scroll-padding: 0;
  }

  .lg\:scroll-p-0\.5 {
    scroll-padding: .125rem;
  }

  .lg\:scroll-p-1 {
    scroll-padding: .25rem;
  }

  .lg\:scroll-p-1\.5 {
    scroll-padding: .375rem;
  }

  .lg\:scroll-p-10 {
    scroll-padding: 2.5rem;
  }

  .lg\:scroll-p-11 {
    scroll-padding: 2.75rem;
  }

  .lg\:scroll-p-12 {
    scroll-padding: 3rem;
  }

  .lg\:scroll-p-14 {
    scroll-padding: 3.5rem;
  }

  .lg\:scroll-p-16 {
    scroll-padding: 4rem;
  }

  .lg\:scroll-p-2 {
    scroll-padding: .5rem;
  }

  .lg\:scroll-p-2\.5 {
    scroll-padding: .625rem;
  }

  .lg\:scroll-p-20 {
    scroll-padding: 5rem;
  }

  .lg\:scroll-p-24 {
    scroll-padding: 6rem;
  }

  .lg\:scroll-p-28 {
    scroll-padding: 7rem;
  }

  .lg\:scroll-p-3 {
    scroll-padding: .75rem;
  }

  .lg\:scroll-p-3\.5 {
    scroll-padding: .875rem;
  }

  .lg\:scroll-p-32 {
    scroll-padding: 8rem;
  }

  .lg\:scroll-p-36 {
    scroll-padding: 9rem;
  }

  .lg\:scroll-p-4 {
    scroll-padding: 1rem;
  }

  .lg\:scroll-p-40 {
    scroll-padding: 10rem;
  }

  .lg\:scroll-p-44 {
    scroll-padding: 11rem;
  }

  .lg\:scroll-p-48 {
    scroll-padding: 12rem;
  }

  .lg\:scroll-p-5 {
    scroll-padding: 1.25rem;
  }

  .lg\:scroll-p-52 {
    scroll-padding: 13rem;
  }

  .lg\:scroll-p-56 {
    scroll-padding: 14rem;
  }

  .lg\:scroll-p-6 {
    scroll-padding: 1.5rem;
  }

  .lg\:scroll-p-60 {
    scroll-padding: 15rem;
  }

  .lg\:scroll-p-64 {
    scroll-padding: 16rem;
  }

  .lg\:scroll-p-7 {
    scroll-padding: 1.75rem;
  }

  .lg\:scroll-p-72 {
    scroll-padding: 18rem;
  }

  .lg\:scroll-p-8 {
    scroll-padding: 2rem;
  }

  .lg\:scroll-p-80 {
    scroll-padding: 20rem;
  }

  .lg\:scroll-p-9 {
    scroll-padding: 2.25rem;
  }

  .lg\:scroll-p-96 {
    scroll-padding: 24rem;
  }

  .lg\:scroll-p-px {
    scroll-padding: 1px;
  }

  .lg\:scroll-px-0 {
    scroll-padding-left: 0;
    scroll-padding-right: 0;
  }

  .lg\:scroll-px-0\.5 {
    scroll-padding-left: .125rem;
    scroll-padding-right: .125rem;
  }

  .lg\:scroll-px-1 {
    scroll-padding-left: .25rem;
    scroll-padding-right: .25rem;
  }

  .lg\:scroll-px-1\.5 {
    scroll-padding-left: .375rem;
    scroll-padding-right: .375rem;
  }

  .lg\:scroll-px-10 {
    scroll-padding-left: 2.5rem;
    scroll-padding-right: 2.5rem;
  }

  .lg\:scroll-px-11 {
    scroll-padding-left: 2.75rem;
    scroll-padding-right: 2.75rem;
  }

  .lg\:scroll-px-12 {
    scroll-padding-left: 3rem;
    scroll-padding-right: 3rem;
  }

  .lg\:scroll-px-14 {
    scroll-padding-left: 3.5rem;
    scroll-padding-right: 3.5rem;
  }

  .lg\:scroll-px-16 {
    scroll-padding-left: 4rem;
    scroll-padding-right: 4rem;
  }

  .lg\:scroll-px-2 {
    scroll-padding-left: .5rem;
    scroll-padding-right: .5rem;
  }

  .lg\:scroll-px-2\.5 {
    scroll-padding-left: .625rem;
    scroll-padding-right: .625rem;
  }

  .lg\:scroll-px-20 {
    scroll-padding-left: 5rem;
    scroll-padding-right: 5rem;
  }

  .lg\:scroll-px-24 {
    scroll-padding-left: 6rem;
    scroll-padding-right: 6rem;
  }

  .lg\:scroll-px-28 {
    scroll-padding-left: 7rem;
    scroll-padding-right: 7rem;
  }

  .lg\:scroll-px-3 {
    scroll-padding-left: .75rem;
    scroll-padding-right: .75rem;
  }

  .lg\:scroll-px-3\.5 {
    scroll-padding-left: .875rem;
    scroll-padding-right: .875rem;
  }

  .lg\:scroll-px-32 {
    scroll-padding-left: 8rem;
    scroll-padding-right: 8rem;
  }

  .lg\:scroll-px-36 {
    scroll-padding-left: 9rem;
    scroll-padding-right: 9rem;
  }

  .lg\:scroll-px-4 {
    scroll-padding-left: 1rem;
    scroll-padding-right: 1rem;
  }

  .lg\:scroll-px-40 {
    scroll-padding-left: 10rem;
    scroll-padding-right: 10rem;
  }

  .lg\:scroll-px-44 {
    scroll-padding-left: 11rem;
    scroll-padding-right: 11rem;
  }

  .lg\:scroll-px-48 {
    scroll-padding-left: 12rem;
    scroll-padding-right: 12rem;
  }

  .lg\:scroll-px-5 {
    scroll-padding-left: 1.25rem;
    scroll-padding-right: 1.25rem;
  }

  .lg\:scroll-px-52 {
    scroll-padding-left: 13rem;
    scroll-padding-right: 13rem;
  }

  .lg\:scroll-px-56 {
    scroll-padding-left: 14rem;
    scroll-padding-right: 14rem;
  }

  .lg\:scroll-px-6 {
    scroll-padding-left: 1.5rem;
    scroll-padding-right: 1.5rem;
  }

  .lg\:scroll-px-60 {
    scroll-padding-left: 15rem;
    scroll-padding-right: 15rem;
  }

  .lg\:scroll-px-64 {
    scroll-padding-left: 16rem;
    scroll-padding-right: 16rem;
  }

  .lg\:scroll-px-7 {
    scroll-padding-left: 1.75rem;
    scroll-padding-right: 1.75rem;
  }

  .lg\:scroll-px-72 {
    scroll-padding-left: 18rem;
    scroll-padding-right: 18rem;
  }

  .lg\:scroll-px-8 {
    scroll-padding-left: 2rem;
    scroll-padding-right: 2rem;
  }

  .lg\:scroll-px-80 {
    scroll-padding-left: 20rem;
    scroll-padding-right: 20rem;
  }

  .lg\:scroll-px-9 {
    scroll-padding-left: 2.25rem;
    scroll-padding-right: 2.25rem;
  }

  .lg\:scroll-px-96 {
    scroll-padding-left: 24rem;
    scroll-padding-right: 24rem;
  }

  .lg\:scroll-px-px {
    scroll-padding-left: 1px;
    scroll-padding-right: 1px;
  }

  .lg\:scroll-py-0 {
    scroll-padding-top: 0;
    scroll-padding-bottom: 0;
  }

  .lg\:scroll-py-0\.5 {
    scroll-padding-top: .125rem;
    scroll-padding-bottom: .125rem;
  }

  .lg\:scroll-py-1 {
    scroll-padding-top: .25rem;
    scroll-padding-bottom: .25rem;
  }

  .lg\:scroll-py-1\.5 {
    scroll-padding-top: .375rem;
    scroll-padding-bottom: .375rem;
  }

  .lg\:scroll-py-10 {
    scroll-padding-top: 2.5rem;
    scroll-padding-bottom: 2.5rem;
  }

  .lg\:scroll-py-11 {
    scroll-padding-top: 2.75rem;
    scroll-padding-bottom: 2.75rem;
  }

  .lg\:scroll-py-12 {
    scroll-padding-top: 3rem;
    scroll-padding-bottom: 3rem;
  }

  .lg\:scroll-py-14 {
    scroll-padding-top: 3.5rem;
    scroll-padding-bottom: 3.5rem;
  }

  .lg\:scroll-py-16 {
    scroll-padding-top: 4rem;
    scroll-padding-bottom: 4rem;
  }

  .lg\:scroll-py-2 {
    scroll-padding-top: .5rem;
    scroll-padding-bottom: .5rem;
  }

  .lg\:scroll-py-2\.5 {
    scroll-padding-top: .625rem;
    scroll-padding-bottom: .625rem;
  }

  .lg\:scroll-py-20 {
    scroll-padding-top: 5rem;
    scroll-padding-bottom: 5rem;
  }

  .lg\:scroll-py-24 {
    scroll-padding-top: 6rem;
    scroll-padding-bottom: 6rem;
  }

  .lg\:scroll-py-28 {
    scroll-padding-top: 7rem;
    scroll-padding-bottom: 7rem;
  }

  .lg\:scroll-py-3 {
    scroll-padding-top: .75rem;
    scroll-padding-bottom: .75rem;
  }

  .lg\:scroll-py-3\.5 {
    scroll-padding-top: .875rem;
    scroll-padding-bottom: .875rem;
  }

  .lg\:scroll-py-32 {
    scroll-padding-top: 8rem;
    scroll-padding-bottom: 8rem;
  }

  .lg\:scroll-py-36 {
    scroll-padding-top: 9rem;
    scroll-padding-bottom: 9rem;
  }

  .lg\:scroll-py-4 {
    scroll-padding-top: 1rem;
    scroll-padding-bottom: 1rem;
  }

  .lg\:scroll-py-40 {
    scroll-padding-top: 10rem;
    scroll-padding-bottom: 10rem;
  }

  .lg\:scroll-py-44 {
    scroll-padding-top: 11rem;
    scroll-padding-bottom: 11rem;
  }

  .lg\:scroll-py-48 {
    scroll-padding-top: 12rem;
    scroll-padding-bottom: 12rem;
  }

  .lg\:scroll-py-5 {
    scroll-padding-top: 1.25rem;
    scroll-padding-bottom: 1.25rem;
  }

  .lg\:scroll-py-52 {
    scroll-padding-top: 13rem;
    scroll-padding-bottom: 13rem;
  }

  .lg\:scroll-py-56 {
    scroll-padding-top: 14rem;
    scroll-padding-bottom: 14rem;
  }

  .lg\:scroll-py-6 {
    scroll-padding-top: 1.5rem;
    scroll-padding-bottom: 1.5rem;
  }

  .lg\:scroll-py-60 {
    scroll-padding-top: 15rem;
    scroll-padding-bottom: 15rem;
  }

  .lg\:scroll-py-64 {
    scroll-padding-top: 16rem;
    scroll-padding-bottom: 16rem;
  }

  .lg\:scroll-py-7 {
    scroll-padding-top: 1.75rem;
    scroll-padding-bottom: 1.75rem;
  }

  .lg\:scroll-py-72 {
    scroll-padding-top: 18rem;
    scroll-padding-bottom: 18rem;
  }

  .lg\:scroll-py-8 {
    scroll-padding-top: 2rem;
    scroll-padding-bottom: 2rem;
  }

  .lg\:scroll-py-80 {
    scroll-padding-top: 20rem;
    scroll-padding-bottom: 20rem;
  }

  .lg\:scroll-py-9 {
    scroll-padding-top: 2.25rem;
    scroll-padding-bottom: 2.25rem;
  }

  .lg\:scroll-py-96 {
    scroll-padding-top: 24rem;
    scroll-padding-bottom: 24rem;
  }

  .lg\:scroll-py-px {
    scroll-padding-top: 1px;
    scroll-padding-bottom: 1px;
  }

  .lg\:scroll-pb-0 {
    scroll-padding-bottom: 0;
  }

  .lg\:scroll-pb-0\.5 {
    scroll-padding-bottom: .125rem;
  }

  .lg\:scroll-pb-1 {
    scroll-padding-bottom: .25rem;
  }

  .lg\:scroll-pb-1\.5 {
    scroll-padding-bottom: .375rem;
  }

  .lg\:scroll-pb-10 {
    scroll-padding-bottom: 2.5rem;
  }

  .lg\:scroll-pb-11 {
    scroll-padding-bottom: 2.75rem;
  }

  .lg\:scroll-pb-12 {
    scroll-padding-bottom: 3rem;
  }

  .lg\:scroll-pb-14 {
    scroll-padding-bottom: 3.5rem;
  }

  .lg\:scroll-pb-16 {
    scroll-padding-bottom: 4rem;
  }

  .lg\:scroll-pb-2 {
    scroll-padding-bottom: .5rem;
  }

  .lg\:scroll-pb-2\.5 {
    scroll-padding-bottom: .625rem;
  }

  .lg\:scroll-pb-20 {
    scroll-padding-bottom: 5rem;
  }

  .lg\:scroll-pb-24 {
    scroll-padding-bottom: 6rem;
  }

  .lg\:scroll-pb-28 {
    scroll-padding-bottom: 7rem;
  }

  .lg\:scroll-pb-3 {
    scroll-padding-bottom: .75rem;
  }

  .lg\:scroll-pb-3\.5 {
    scroll-padding-bottom: .875rem;
  }

  .lg\:scroll-pb-32 {
    scroll-padding-bottom: 8rem;
  }

  .lg\:scroll-pb-36 {
    scroll-padding-bottom: 9rem;
  }

  .lg\:scroll-pb-4 {
    scroll-padding-bottom: 1rem;
  }

  .lg\:scroll-pb-40 {
    scroll-padding-bottom: 10rem;
  }

  .lg\:scroll-pb-44 {
    scroll-padding-bottom: 11rem;
  }

  .lg\:scroll-pb-48 {
    scroll-padding-bottom: 12rem;
  }

  .lg\:scroll-pb-5 {
    scroll-padding-bottom: 1.25rem;
  }

  .lg\:scroll-pb-52 {
    scroll-padding-bottom: 13rem;
  }

  .lg\:scroll-pb-56 {
    scroll-padding-bottom: 14rem;
  }

  .lg\:scroll-pb-6 {
    scroll-padding-bottom: 1.5rem;
  }

  .lg\:scroll-pb-60 {
    scroll-padding-bottom: 15rem;
  }

  .lg\:scroll-pb-64 {
    scroll-padding-bottom: 16rem;
  }

  .lg\:scroll-pb-7 {
    scroll-padding-bottom: 1.75rem;
  }

  .lg\:scroll-pb-72 {
    scroll-padding-bottom: 18rem;
  }

  .lg\:scroll-pb-8 {
    scroll-padding-bottom: 2rem;
  }

  .lg\:scroll-pb-80 {
    scroll-padding-bottom: 20rem;
  }

  .lg\:scroll-pb-9 {
    scroll-padding-bottom: 2.25rem;
  }

  .lg\:scroll-pb-96 {
    scroll-padding-bottom: 24rem;
  }

  .lg\:scroll-pb-px {
    scroll-padding-bottom: 1px;
  }

  .lg\:scroll-pe-0 {
    scroll-padding-inline-end: 0;
  }

  .lg\:scroll-pe-0\.5 {
    scroll-padding-inline-end: .125rem;
  }

  .lg\:scroll-pe-1 {
    scroll-padding-inline-end: .25rem;
  }

  .lg\:scroll-pe-1\.5 {
    scroll-padding-inline-end: .375rem;
  }

  .lg\:scroll-pe-10 {
    scroll-padding-inline-end: 2.5rem;
  }

  .lg\:scroll-pe-11 {
    scroll-padding-inline-end: 2.75rem;
  }

  .lg\:scroll-pe-12 {
    scroll-padding-inline-end: 3rem;
  }

  .lg\:scroll-pe-14 {
    scroll-padding-inline-end: 3.5rem;
  }

  .lg\:scroll-pe-16 {
    scroll-padding-inline-end: 4rem;
  }

  .lg\:scroll-pe-2 {
    scroll-padding-inline-end: .5rem;
  }

  .lg\:scroll-pe-2\.5 {
    scroll-padding-inline-end: .625rem;
  }

  .lg\:scroll-pe-20 {
    scroll-padding-inline-end: 5rem;
  }

  .lg\:scroll-pe-24 {
    scroll-padding-inline-end: 6rem;
  }

  .lg\:scroll-pe-28 {
    scroll-padding-inline-end: 7rem;
  }

  .lg\:scroll-pe-3 {
    scroll-padding-inline-end: .75rem;
  }

  .lg\:scroll-pe-3\.5 {
    scroll-padding-inline-end: .875rem;
  }

  .lg\:scroll-pe-32 {
    scroll-padding-inline-end: 8rem;
  }

  .lg\:scroll-pe-36 {
    scroll-padding-inline-end: 9rem;
  }

  .lg\:scroll-pe-4 {
    scroll-padding-inline-end: 1rem;
  }

  .lg\:scroll-pe-40 {
    scroll-padding-inline-end: 10rem;
  }

  .lg\:scroll-pe-44 {
    scroll-padding-inline-end: 11rem;
  }

  .lg\:scroll-pe-48 {
    scroll-padding-inline-end: 12rem;
  }

  .lg\:scroll-pe-5 {
    scroll-padding-inline-end: 1.25rem;
  }

  .lg\:scroll-pe-52 {
    scroll-padding-inline-end: 13rem;
  }

  .lg\:scroll-pe-56 {
    scroll-padding-inline-end: 14rem;
  }

  .lg\:scroll-pe-6 {
    scroll-padding-inline-end: 1.5rem;
  }

  .lg\:scroll-pe-60 {
    scroll-padding-inline-end: 15rem;
  }

  .lg\:scroll-pe-64 {
    scroll-padding-inline-end: 16rem;
  }

  .lg\:scroll-pe-7 {
    scroll-padding-inline-end: 1.75rem;
  }

  .lg\:scroll-pe-72 {
    scroll-padding-inline-end: 18rem;
  }

  .lg\:scroll-pe-8 {
    scroll-padding-inline-end: 2rem;
  }

  .lg\:scroll-pe-80 {
    scroll-padding-inline-end: 20rem;
  }

  .lg\:scroll-pe-9 {
    scroll-padding-inline-end: 2.25rem;
  }

  .lg\:scroll-pe-96 {
    scroll-padding-inline-end: 24rem;
  }

  .lg\:scroll-pe-px {
    scroll-padding-inline-end: 1px;
  }

  .lg\:scroll-pl-0 {
    scroll-padding-left: 0;
  }

  .lg\:scroll-pl-0\.5 {
    scroll-padding-left: .125rem;
  }

  .lg\:scroll-pl-1 {
    scroll-padding-left: .25rem;
  }

  .lg\:scroll-pl-1\.5 {
    scroll-padding-left: .375rem;
  }

  .lg\:scroll-pl-10 {
    scroll-padding-left: 2.5rem;
  }

  .lg\:scroll-pl-11 {
    scroll-padding-left: 2.75rem;
  }

  .lg\:scroll-pl-12 {
    scroll-padding-left: 3rem;
  }

  .lg\:scroll-pl-14 {
    scroll-padding-left: 3.5rem;
  }

  .lg\:scroll-pl-16 {
    scroll-padding-left: 4rem;
  }

  .lg\:scroll-pl-2 {
    scroll-padding-left: .5rem;
  }

  .lg\:scroll-pl-2\.5 {
    scroll-padding-left: .625rem;
  }

  .lg\:scroll-pl-20 {
    scroll-padding-left: 5rem;
  }

  .lg\:scroll-pl-24 {
    scroll-padding-left: 6rem;
  }

  .lg\:scroll-pl-28 {
    scroll-padding-left: 7rem;
  }

  .lg\:scroll-pl-3 {
    scroll-padding-left: .75rem;
  }

  .lg\:scroll-pl-3\.5 {
    scroll-padding-left: .875rem;
  }

  .lg\:scroll-pl-32 {
    scroll-padding-left: 8rem;
  }

  .lg\:scroll-pl-36 {
    scroll-padding-left: 9rem;
  }

  .lg\:scroll-pl-4 {
    scroll-padding-left: 1rem;
  }

  .lg\:scroll-pl-40 {
    scroll-padding-left: 10rem;
  }

  .lg\:scroll-pl-44 {
    scroll-padding-left: 11rem;
  }

  .lg\:scroll-pl-48 {
    scroll-padding-left: 12rem;
  }

  .lg\:scroll-pl-5 {
    scroll-padding-left: 1.25rem;
  }

  .lg\:scroll-pl-52 {
    scroll-padding-left: 13rem;
  }

  .lg\:scroll-pl-56 {
    scroll-padding-left: 14rem;
  }

  .lg\:scroll-pl-6 {
    scroll-padding-left: 1.5rem;
  }

  .lg\:scroll-pl-60 {
    scroll-padding-left: 15rem;
  }

  .lg\:scroll-pl-64 {
    scroll-padding-left: 16rem;
  }

  .lg\:scroll-pl-7 {
    scroll-padding-left: 1.75rem;
  }

  .lg\:scroll-pl-72 {
    scroll-padding-left: 18rem;
  }

  .lg\:scroll-pl-8 {
    scroll-padding-left: 2rem;
  }

  .lg\:scroll-pl-80 {
    scroll-padding-left: 20rem;
  }

  .lg\:scroll-pl-9 {
    scroll-padding-left: 2.25rem;
  }

  .lg\:scroll-pl-96 {
    scroll-padding-left: 24rem;
  }

  .lg\:scroll-pl-px {
    scroll-padding-left: 1px;
  }

  .lg\:scroll-pr-0 {
    scroll-padding-right: 0;
  }

  .lg\:scroll-pr-0\.5 {
    scroll-padding-right: .125rem;
  }

  .lg\:scroll-pr-1 {
    scroll-padding-right: .25rem;
  }

  .lg\:scroll-pr-1\.5 {
    scroll-padding-right: .375rem;
  }

  .lg\:scroll-pr-10 {
    scroll-padding-right: 2.5rem;
  }

  .lg\:scroll-pr-11 {
    scroll-padding-right: 2.75rem;
  }

  .lg\:scroll-pr-12 {
    scroll-padding-right: 3rem;
  }

  .lg\:scroll-pr-14 {
    scroll-padding-right: 3.5rem;
  }

  .lg\:scroll-pr-16 {
    scroll-padding-right: 4rem;
  }

  .lg\:scroll-pr-2 {
    scroll-padding-right: .5rem;
  }

  .lg\:scroll-pr-2\.5 {
    scroll-padding-right: .625rem;
  }

  .lg\:scroll-pr-20 {
    scroll-padding-right: 5rem;
  }

  .lg\:scroll-pr-24 {
    scroll-padding-right: 6rem;
  }

  .lg\:scroll-pr-28 {
    scroll-padding-right: 7rem;
  }

  .lg\:scroll-pr-3 {
    scroll-padding-right: .75rem;
  }

  .lg\:scroll-pr-3\.5 {
    scroll-padding-right: .875rem;
  }

  .lg\:scroll-pr-32 {
    scroll-padding-right: 8rem;
  }

  .lg\:scroll-pr-36 {
    scroll-padding-right: 9rem;
  }

  .lg\:scroll-pr-4 {
    scroll-padding-right: 1rem;
  }

  .lg\:scroll-pr-40 {
    scroll-padding-right: 10rem;
  }

  .lg\:scroll-pr-44 {
    scroll-padding-right: 11rem;
  }

  .lg\:scroll-pr-48 {
    scroll-padding-right: 12rem;
  }

  .lg\:scroll-pr-5 {
    scroll-padding-right: 1.25rem;
  }

  .lg\:scroll-pr-52 {
    scroll-padding-right: 13rem;
  }

  .lg\:scroll-pr-56 {
    scroll-padding-right: 14rem;
  }

  .lg\:scroll-pr-6 {
    scroll-padding-right: 1.5rem;
  }

  .lg\:scroll-pr-60 {
    scroll-padding-right: 15rem;
  }

  .lg\:scroll-pr-64 {
    scroll-padding-right: 16rem;
  }

  .lg\:scroll-pr-7 {
    scroll-padding-right: 1.75rem;
  }

  .lg\:scroll-pr-72 {
    scroll-padding-right: 18rem;
  }

  .lg\:scroll-pr-8 {
    scroll-padding-right: 2rem;
  }

  .lg\:scroll-pr-80 {
    scroll-padding-right: 20rem;
  }

  .lg\:scroll-pr-9 {
    scroll-padding-right: 2.25rem;
  }

  .lg\:scroll-pr-96 {
    scroll-padding-right: 24rem;
  }

  .lg\:scroll-pr-px {
    scroll-padding-right: 1px;
  }

  .lg\:scroll-ps-0 {
    scroll-padding-inline-start: 0;
  }

  .lg\:scroll-ps-0\.5 {
    scroll-padding-inline-start: .125rem;
  }

  .lg\:scroll-ps-1 {
    scroll-padding-inline-start: .25rem;
  }

  .lg\:scroll-ps-1\.5 {
    scroll-padding-inline-start: .375rem;
  }

  .lg\:scroll-ps-10 {
    scroll-padding-inline-start: 2.5rem;
  }

  .lg\:scroll-ps-11 {
    scroll-padding-inline-start: 2.75rem;
  }

  .lg\:scroll-ps-12 {
    scroll-padding-inline-start: 3rem;
  }

  .lg\:scroll-ps-14 {
    scroll-padding-inline-start: 3.5rem;
  }

  .lg\:scroll-ps-16 {
    scroll-padding-inline-start: 4rem;
  }

  .lg\:scroll-ps-2 {
    scroll-padding-inline-start: .5rem;
  }

  .lg\:scroll-ps-2\.5 {
    scroll-padding-inline-start: .625rem;
  }

  .lg\:scroll-ps-20 {
    scroll-padding-inline-start: 5rem;
  }

  .lg\:scroll-ps-24 {
    scroll-padding-inline-start: 6rem;
  }

  .lg\:scroll-ps-28 {
    scroll-padding-inline-start: 7rem;
  }

  .lg\:scroll-ps-3 {
    scroll-padding-inline-start: .75rem;
  }

  .lg\:scroll-ps-3\.5 {
    scroll-padding-inline-start: .875rem;
  }

  .lg\:scroll-ps-32 {
    scroll-padding-inline-start: 8rem;
  }

  .lg\:scroll-ps-36 {
    scroll-padding-inline-start: 9rem;
  }

  .lg\:scroll-ps-4 {
    scroll-padding-inline-start: 1rem;
  }

  .lg\:scroll-ps-40 {
    scroll-padding-inline-start: 10rem;
  }

  .lg\:scroll-ps-44 {
    scroll-padding-inline-start: 11rem;
  }

  .lg\:scroll-ps-48 {
    scroll-padding-inline-start: 12rem;
  }

  .lg\:scroll-ps-5 {
    scroll-padding-inline-start: 1.25rem;
  }

  .lg\:scroll-ps-52 {
    scroll-padding-inline-start: 13rem;
  }

  .lg\:scroll-ps-56 {
    scroll-padding-inline-start: 14rem;
  }

  .lg\:scroll-ps-6 {
    scroll-padding-inline-start: 1.5rem;
  }

  .lg\:scroll-ps-60 {
    scroll-padding-inline-start: 15rem;
  }

  .lg\:scroll-ps-64 {
    scroll-padding-inline-start: 16rem;
  }

  .lg\:scroll-ps-7 {
    scroll-padding-inline-start: 1.75rem;
  }

  .lg\:scroll-ps-72 {
    scroll-padding-inline-start: 18rem;
  }

  .lg\:scroll-ps-8 {
    scroll-padding-inline-start: 2rem;
  }

  .lg\:scroll-ps-80 {
    scroll-padding-inline-start: 20rem;
  }

  .lg\:scroll-ps-9 {
    scroll-padding-inline-start: 2.25rem;
  }

  .lg\:scroll-ps-96 {
    scroll-padding-inline-start: 24rem;
  }

  .lg\:scroll-ps-px {
    scroll-padding-inline-start: 1px;
  }

  .lg\:scroll-pt-0 {
    scroll-padding-top: 0;
  }

  .lg\:scroll-pt-0\.5 {
    scroll-padding-top: .125rem;
  }

  .lg\:scroll-pt-1 {
    scroll-padding-top: .25rem;
  }

  .lg\:scroll-pt-1\.5 {
    scroll-padding-top: .375rem;
  }

  .lg\:scroll-pt-10 {
    scroll-padding-top: 2.5rem;
  }

  .lg\:scroll-pt-11 {
    scroll-padding-top: 2.75rem;
  }

  .lg\:scroll-pt-12 {
    scroll-padding-top: 3rem;
  }

  .lg\:scroll-pt-14 {
    scroll-padding-top: 3.5rem;
  }

  .lg\:scroll-pt-16 {
    scroll-padding-top: 4rem;
  }

  .lg\:scroll-pt-2 {
    scroll-padding-top: .5rem;
  }

  .lg\:scroll-pt-2\.5 {
    scroll-padding-top: .625rem;
  }

  .lg\:scroll-pt-20 {
    scroll-padding-top: 5rem;
  }

  .lg\:scroll-pt-24 {
    scroll-padding-top: 6rem;
  }

  .lg\:scroll-pt-28 {
    scroll-padding-top: 7rem;
  }

  .lg\:scroll-pt-3 {
    scroll-padding-top: .75rem;
  }

  .lg\:scroll-pt-3\.5 {
    scroll-padding-top: .875rem;
  }

  .lg\:scroll-pt-32 {
    scroll-padding-top: 8rem;
  }

  .lg\:scroll-pt-36 {
    scroll-padding-top: 9rem;
  }

  .lg\:scroll-pt-4 {
    scroll-padding-top: 1rem;
  }

  .lg\:scroll-pt-40 {
    scroll-padding-top: 10rem;
  }

  .lg\:scroll-pt-44 {
    scroll-padding-top: 11rem;
  }

  .lg\:scroll-pt-48 {
    scroll-padding-top: 12rem;
  }

  .lg\:scroll-pt-5 {
    scroll-padding-top: 1.25rem;
  }

  .lg\:scroll-pt-52 {
    scroll-padding-top: 13rem;
  }

  .lg\:scroll-pt-56 {
    scroll-padding-top: 14rem;
  }

  .lg\:scroll-pt-6 {
    scroll-padding-top: 1.5rem;
  }

  .lg\:scroll-pt-60 {
    scroll-padding-top: 15rem;
  }

  .lg\:scroll-pt-64 {
    scroll-padding-top: 16rem;
  }

  .lg\:scroll-pt-7 {
    scroll-padding-top: 1.75rem;
  }

  .lg\:scroll-pt-72 {
    scroll-padding-top: 18rem;
  }

  .lg\:scroll-pt-8 {
    scroll-padding-top: 2rem;
  }

  .lg\:scroll-pt-80 {
    scroll-padding-top: 20rem;
  }

  .lg\:scroll-pt-9 {
    scroll-padding-top: 2.25rem;
  }

  .lg\:scroll-pt-96 {
    scroll-padding-top: 24rem;
  }

  .lg\:scroll-pt-px {
    scroll-padding-top: 1px;
  }

  .lg\:list-inside {
    list-style-position: inside;
  }

  .lg\:list-outside {
    list-style-position: outside;
  }

  .lg\:list-decimal {
    list-style-type: decimal;
  }

  .lg\:list-disc {
    list-style-type: disc;
  }

  .lg\:list-none {
    list-style-type: none;
  }

  .lg\:list-image-none {
    list-style-image: none;
  }

  .lg\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }

  .lg\:appearance-auto {
    -webkit-appearance: auto;
    appearance: auto;
  }

  .lg\:columns-1 {
    columns: 1;
  }

  .lg\:columns-10 {
    columns: 10;
  }

  .lg\:columns-11 {
    columns: 11;
  }

  .lg\:columns-12 {
    columns: 12;
  }

  .lg\:columns-2 {
    columns: 2;
  }

  .lg\:columns-2xl {
    columns: 42rem;
  }

  .lg\:columns-2xs {
    columns: 18rem;
  }

  .lg\:columns-3 {
    columns: 3;
  }

  .lg\:columns-3xl {
    columns: 48rem;
  }

  .lg\:columns-3xs {
    columns: 16rem;
  }

  .lg\:columns-4 {
    columns: 4;
  }

  .lg\:columns-4xl {
    columns: 56rem;
  }

  .lg\:columns-5 {
    columns: 5;
  }

  .lg\:columns-5xl {
    columns: 64rem;
  }

  .lg\:columns-6 {
    columns: 6;
  }

  .lg\:columns-6xl {
    columns: 72rem;
  }

  .lg\:columns-7 {
    columns: 7;
  }

  .lg\:columns-7xl {
    columns: 80rem;
  }

  .lg\:columns-8 {
    columns: 8;
  }

  .lg\:columns-9 {
    columns: 9;
  }

  .lg\:columns-auto {
    columns: auto;
  }

  .lg\:columns-lg {
    columns: 32rem;
  }

  .lg\:columns-md {
    columns: 28rem;
  }

  .lg\:columns-sm {
    columns: 24rem;
  }

  .lg\:columns-xl {
    columns: 36rem;
  }

  .lg\:columns-xs {
    columns: 20rem;
  }

  .lg\:break-before-auto {
    break-before: auto;
  }

  .lg\:break-before-avoid {
    break-before: avoid;
  }

  .lg\:break-before-all {
    break-before: all;
  }

  .lg\:break-before-avoid-page {
    break-before: avoid-page;
  }

  .lg\:break-before-page {
    break-before: page;
  }

  .lg\:break-before-left {
    break-before: left;
  }

  .lg\:break-before-right {
    break-before: right;
  }

  .lg\:break-before-column {
    break-before: column;
  }

  .lg\:break-inside-auto {
    break-inside: auto;
  }

  .lg\:break-inside-avoid {
    break-inside: avoid;
  }

  .lg\:break-inside-avoid-page {
    break-inside: avoid-page;
  }

  .lg\:break-inside-avoid-column {
    break-inside: avoid-column;
  }

  .lg\:break-after-auto {
    break-after: auto;
  }

  .lg\:break-after-avoid {
    break-after: avoid;
  }

  .lg\:break-after-all {
    break-after: all;
  }

  .lg\:break-after-avoid-page {
    break-after: avoid-page;
  }

  .lg\:break-after-page {
    break-after: page;
  }

  .lg\:break-after-left {
    break-after: left;
  }

  .lg\:break-after-right {
    break-after: right;
  }

  .lg\:break-after-column {
    break-after: column;
  }

  .lg\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .lg\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .lg\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-flow-dense {
    grid-auto-flow: dense;
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .lg\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .lg\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .lg\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:grid-cols-none {
    grid-template-columns: none;
  }

  .lg\:grid-cols-subgrid {
    grid-template-columns: subgrid;
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-rows-11 {
    grid-template-rows: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-rows-12 {
    grid-template-rows: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-rows-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-rows-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-rows-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }

  .lg\:grid-rows-none {
    grid-template-rows: none;
  }

  .lg\:grid-rows-subgrid {
    grid-template-rows: subgrid;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:place-content-center {
    place-content: center;
  }

  .lg\:place-content-start {
    place-content: start;
  }

  .lg\:place-content-end {
    place-content: end;
  }

  .lg\:place-content-between {
    place-content: space-between;
  }

  .lg\:place-content-around {
    place-content: space-around;
  }

  .lg\:place-content-evenly {
    place-content: space-evenly;
  }

  .lg\:place-content-baseline {
    place-content: baseline start;
  }

  .lg\:place-content-stretch {
    place-content: stretch;
  }

  .lg\:place-items-start {
    place-items: start;
  }

  .lg\:place-items-end {
    place-items: end;
  }

  .lg\:place-items-center {
    place-items: center;
  }

  .lg\:place-items-baseline {
    place-items: baseline;
  }

  .lg\:place-items-stretch {
    place-items: stretch stretch;
  }

  .lg\:content-normal {
    align-content: normal;
  }

  .lg\:content-center {
    align-content: center;
  }

  .lg\:content-start {
    align-content: flex-start;
  }

  .lg\:content-end {
    align-content: flex-end;
  }

  .lg\:content-between {
    align-content: space-between;
  }

  .lg\:content-around {
    align-content: space-around;
  }

  .lg\:content-evenly {
    align-content: space-evenly;
  }

  .lg\:content-baseline {
    align-content: baseline;
  }

  .lg\:content-stretch {
    align-content: stretch;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:items-baseline {
    align-items: baseline;
  }

  .lg\:items-stretch {
    align-items: stretch;
  }

  .lg\:justify-normal {
    justify-content: normal;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:justify-around {
    justify-content: space-around;
  }

  .lg\:justify-evenly {
    justify-content: space-evenly;
  }

  .lg\:justify-stretch {
    justify-content: stretch;
  }

  .lg\:justify-items-start {
    justify-items: start;
  }

  .lg\:justify-items-end {
    justify-items: end;
  }

  .lg\:justify-items-center {
    justify-items: center;
  }

  .lg\:justify-items-stretch {
    justify-items: stretch;
  }

  .lg\:gap-0 {
    gap: 0;
  }

  .lg\:gap-0\.5 {
    gap: .125rem;
  }

  .lg\:gap-1 {
    gap: .25rem;
  }

  .lg\:gap-1\.5 {
    gap: .375rem;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-11 {
    gap: 2.75rem;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-2\.5 {
    gap: .625rem;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-24 {
    gap: 6rem;
  }

  .lg\:gap-28 {
    gap: 7rem;
  }

  .lg\:gap-3 {
    gap: .75rem;
  }

  .lg\:gap-3\.5 {
    gap: .875rem;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  .lg\:gap-36 {
    gap: 9rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-40 {
    gap: 10rem;
  }

  .lg\:gap-44 {
    gap: 11rem;
  }

  .lg\:gap-48 {
    gap: 12rem;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }

  .lg\:gap-52 {
    gap: 13rem;
  }

  .lg\:gap-56 {
    gap: 14rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-60 {
    gap: 15rem;
  }

  .lg\:gap-64 {
    gap: 16rem;
  }

  .lg\:gap-7 {
    gap: 1.75rem;
  }

  .lg\:gap-72 {
    gap: 18rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-80 {
    gap: 20rem;
  }

  .lg\:gap-9 {
    gap: 2.25rem;
  }

  .lg\:gap-96 {
    gap: 24rem;
  }

  .lg\:gap-px {
    gap: 1px;
  }

  .lg\:gap-x-0 {
    column-gap: 0;
  }

  .lg\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .lg\:gap-x-1 {
    column-gap: .25rem;
  }

  .lg\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .lg\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .lg\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .lg\:gap-x-12 {
    column-gap: 3rem;
  }

  .lg\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .lg\:gap-x-16 {
    column-gap: 4rem;
  }

  .lg\:gap-x-2 {
    column-gap: .5rem;
  }

  .lg\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .lg\:gap-x-20 {
    column-gap: 5rem;
  }

  .lg\:gap-x-24 {
    column-gap: 6rem;
  }

  .lg\:gap-x-28 {
    column-gap: 7rem;
  }

  .lg\:gap-x-3 {
    column-gap: .75rem;
  }

  .lg\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .lg\:gap-x-32 {
    column-gap: 8rem;
  }

  .lg\:gap-x-36 {
    column-gap: 9rem;
  }

  .lg\:gap-x-4 {
    column-gap: 1rem;
  }

  .lg\:gap-x-40 {
    column-gap: 10rem;
  }

  .lg\:gap-x-44 {
    column-gap: 11rem;
  }

  .lg\:gap-x-48 {
    column-gap: 12rem;
  }

  .lg\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .lg\:gap-x-52 {
    column-gap: 13rem;
  }

  .lg\:gap-x-56 {
    column-gap: 14rem;
  }

  .lg\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .lg\:gap-x-60 {
    column-gap: 15rem;
  }

  .lg\:gap-x-64 {
    column-gap: 16rem;
  }

  .lg\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .lg\:gap-x-72 {
    column-gap: 18rem;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:gap-x-80 {
    column-gap: 20rem;
  }

  .lg\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .lg\:gap-x-96 {
    column-gap: 24rem;
  }

  .lg\:gap-x-px {
    column-gap: 1px;
  }

  .lg\:gap-y-0 {
    row-gap: 0;
  }

  .lg\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .lg\:gap-y-1 {
    row-gap: .25rem;
  }

  .lg\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .lg\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .lg\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .lg\:gap-y-12 {
    row-gap: 3rem;
  }

  .lg\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .lg\:gap-y-16 {
    row-gap: 4rem;
  }

  .lg\:gap-y-2 {
    row-gap: .5rem;
  }

  .lg\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .lg\:gap-y-20 {
    row-gap: 5rem;
  }

  .lg\:gap-y-24 {
    row-gap: 6rem;
  }

  .lg\:gap-y-28 {
    row-gap: 7rem;
  }

  .lg\:gap-y-3 {
    row-gap: .75rem;
  }

  .lg\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .lg\:gap-y-32 {
    row-gap: 8rem;
  }

  .lg\:gap-y-36 {
    row-gap: 9rem;
  }

  .lg\:gap-y-4 {
    row-gap: 1rem;
  }

  .lg\:gap-y-40 {
    row-gap: 10rem;
  }

  .lg\:gap-y-44 {
    row-gap: 11rem;
  }

  .lg\:gap-y-48 {
    row-gap: 12rem;
  }

  .lg\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .lg\:gap-y-52 {
    row-gap: 13rem;
  }

  .lg\:gap-y-56 {
    row-gap: 14rem;
  }

  .lg\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .lg\:gap-y-60 {
    row-gap: 15rem;
  }

  .lg\:gap-y-64 {
    row-gap: 16rem;
  }

  .lg\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .lg\:gap-y-72 {
    row-gap: 18rem;
  }

  .lg\:gap-y-8 {
    row-gap: 2rem;
  }

  .lg\:gap-y-80 {
    row-gap: 20rem;
  }

  .lg\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .lg\:gap-y-96 {
    row-gap: 24rem;
  }

  .lg\:gap-y-px {
    row-gap: 1px;
  }

  .lg\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0px * var(--tw-space-x-reverse));
    margin-left: calc(-0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .lg\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .lg\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .lg\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .lg\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .lg\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .lg\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .lg\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .lg\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .lg\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .lg\:divide-black > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-black);
  }

  .lg\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .lg\:divide-danger > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-300);
  }

  .lg\:divide-danger-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-100);
  }

  .lg\:divide-danger-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-200);
  }

  .lg\:divide-danger-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-300);
  }

  .lg\:divide-danger-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-400);
  }

  .lg\:divide-divider > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-divider);
  }

  .lg\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-gray);
  }

  .lg\:divide-info > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-300);
  }

  .lg\:divide-info-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-100);
  }

  .lg\:divide-info-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-200);
  }

  .lg\:divide-info-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-300);
  }

  .lg\:divide-info-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-400);
  }

  .lg\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-300);
  }

  .lg\:divide-primary-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-100);
  }

  .lg\:divide-primary-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-200);
  }

  .lg\:divide-primary-250 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-250);
  }

  .lg\:divide-primary-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-300);
  }

  .lg\:divide-primary-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-400);
  }

  .lg\:divide-primary-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-500);
  }

  .lg\:divide-primary-600 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-600);
  }

  .lg\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-300);
  }

  .lg\:divide-success-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-100);
  }

  .lg\:divide-success-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-200);
  }

  .lg\:divide-success-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-300);
  }

  .lg\:divide-success-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-400);
  }

  .lg\:divide-transparent > :not([hidden]) ~ :not([hidden]), .lg\:divide-transparent\/0 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .lg\:divide-transparent\/10 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000001a;
  }

  .lg\:divide-transparent\/100 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000;
  }

  .lg\:divide-transparent\/15 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000026;
  }

  .lg\:divide-transparent\/20 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0003;
  }

  .lg\:divide-transparent\/25 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000040;
  }

  .lg\:divide-transparent\/30 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000004d;
  }

  .lg\:divide-transparent\/35 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000059;
  }

  .lg\:divide-transparent\/40 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0006;
  }

  .lg\:divide-transparent\/45 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000073;
  }

  .lg\:divide-transparent\/5 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000000d;
  }

  .lg\:divide-transparent\/50 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000080;
  }

  .lg\:divide-transparent\/55 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000008c;
  }

  .lg\:divide-transparent\/60 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0009;
  }

  .lg\:divide-transparent\/65 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000a6;
  }

  .lg\:divide-transparent\/70 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000b3;
  }

  .lg\:divide-transparent\/75 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000bf;
  }

  .lg\:divide-transparent\/80 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000c;
  }

  .lg\:divide-transparent\/85 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000d9;
  }

  .lg\:divide-transparent\/90 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000e6;
  }

  .lg\:divide-transparent\/95 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000f2;
  }

  .lg\:divide-warning > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-300);
  }

  .lg\:divide-warning-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-100);
  }

  .lg\:divide-warning-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-200);
  }

  .lg\:divide-warning-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-300);
  }

  .lg\:divide-warning-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-400);
  }

  .lg\:divide-white > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-white);
  }

  .lg\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .lg\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .lg\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .lg\:divide-opacity-15 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .15;
  }

  .lg\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .lg\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .lg\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .lg\:divide-opacity-35 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .35;
  }

  .lg\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .lg\:divide-opacity-45 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .45;
  }

  .lg\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .lg\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .lg\:divide-opacity-55 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .55;
  }

  .lg\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .lg\:divide-opacity-65 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .65;
  }

  .lg\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .lg\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .lg\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .lg\:divide-opacity-85 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .85;
  }

  .lg\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .lg\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .lg\:place-self-auto {
    place-self: auto;
  }

  .lg\:place-self-start {
    place-self: start;
  }

  .lg\:place-self-end {
    place-self: end;
  }

  .lg\:place-self-center {
    place-self: center;
  }

  .lg\:place-self-stretch {
    place-self: stretch stretch;
  }

  .lg\:self-auto {
    align-self: auto;
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:self-center {
    align-self: center;
  }

  .lg\:self-stretch {
    align-self: stretch;
  }

  .lg\:self-baseline {
    align-self: baseline;
  }

  .lg\:justify-self-auto {
    justify-self: auto;
  }

  .lg\:justify-self-start {
    justify-self: start;
  }

  .lg\:justify-self-end {
    justify-self: end;
  }

  .lg\:justify-self-center {
    justify-self: center;
  }

  .lg\:justify-self-stretch {
    justify-self: stretch;
  }

  .lg\:overflow-auto {
    overflow: auto;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:overflow-clip {
    overflow: clip;
  }

  .lg\:overflow-visible {
    overflow: visible;
  }

  .lg\:overflow-scroll {
    overflow: scroll;
  }

  .lg\:overflow-x-auto {
    overflow-x: auto;
  }

  .lg\:overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg\:overflow-x-clip {
    overflow-x: clip;
  }

  .lg\:overflow-y-clip {
    overflow-y: clip;
  }

  .lg\:overflow-x-visible {
    overflow-x: visible;
  }

  .lg\:overflow-y-visible {
    overflow-y: visible;
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .lg\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .lg\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .lg\:overscroll-none {
    overscroll-behavior: none;
  }

  .lg\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .lg\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .lg\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .lg\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .lg\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .lg\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .lg\:scroll-auto {
    scroll-behavior: auto;
  }

  .lg\:scroll-smooth {
    scroll-behavior: smooth;
  }

  .lg\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .lg\:overflow-ellipsis, .lg\:text-ellipsis {
    text-overflow: ellipsis;
  }

  .lg\:text-clip {
    text-overflow: clip;
  }

  .lg\:hyphens-none {
    -webkit-hyphens: none;
    hyphens: none;
  }

  .lg\:hyphens-manual {
    -webkit-hyphens: manual;
    hyphens: manual;
  }

  .lg\:hyphens-auto {
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .lg\:whitespace-normal {
    white-space: normal;
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }

  .lg\:whitespace-pre {
    white-space: pre;
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line;
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .lg\:whitespace-break-spaces {
    white-space: break-spaces;
  }

  .lg\:text-wrap {
    text-wrap: wrap;
  }

  .lg\:text-nowrap {
    text-wrap: nowrap;
  }

  .lg\:text-balance {
    text-wrap: balance;
  }

  .lg\:text-pretty {
    text-wrap: pretty;
  }

  .lg\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .lg\:break-words {
    overflow-wrap: break-word;
  }

  .lg\:break-all {
    word-break: break-all;
  }

  .lg\:break-keep {
    word-break: keep-all;
  }

  .lg\:rounded {
    border-radius: .25rem;
  }

  .lg\:rounded-2xl {
    border-radius: 1rem;
  }

  .lg\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .lg\:rounded-full {
    border-radius: 9999px;
  }

  .lg\:rounded-lg {
    border-radius: .5rem;
  }

  .lg\:rounded-md {
    border-radius: .375rem;
  }

  .lg\:rounded-none {
    border-radius: 0;
  }

  .lg\:rounded-sm {
    border-radius: .125rem;
  }

  .lg\:rounded-xl {
    border-radius: .75rem;
  }

  .lg\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .lg\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .lg\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .lg\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .lg\:rounded-e {
    border-start-end-radius: .25rem;
    border-end-end-radius: .25rem;
  }

  .lg\:rounded-e-2xl {
    border-start-end-radius: 1rem;
    border-end-end-radius: 1rem;
  }

  .lg\:rounded-e-3xl {
    border-start-end-radius: 1.5rem;
    border-end-end-radius: 1.5rem;
  }

  .lg\:rounded-e-full {
    border-start-end-radius: 9999px;
    border-end-end-radius: 9999px;
  }

  .lg\:rounded-e-lg {
    border-start-end-radius: .5rem;
    border-end-end-radius: .5rem;
  }

  .lg\:rounded-e-md {
    border-start-end-radius: .375rem;
    border-end-end-radius: .375rem;
  }

  .lg\:rounded-e-none {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }

  .lg\:rounded-e-sm {
    border-start-end-radius: .125rem;
    border-end-end-radius: .125rem;
  }

  .lg\:rounded-e-xl {
    border-start-end-radius: .75rem;
    border-end-end-radius: .75rem;
  }

  .lg\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .lg\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .lg\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .lg\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .lg\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .lg\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .lg\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .lg\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .lg\:rounded-s {
    border-start-start-radius: .25rem;
    border-end-start-radius: .25rem;
  }

  .lg\:rounded-s-2xl {
    border-start-start-radius: 1rem;
    border-end-start-radius: 1rem;
  }

  .lg\:rounded-s-3xl {
    border-start-start-radius: 1.5rem;
    border-end-start-radius: 1.5rem;
  }

  .lg\:rounded-s-full {
    border-start-start-radius: 9999px;
    border-end-start-radius: 9999px;
  }

  .lg\:rounded-s-lg {
    border-start-start-radius: .5rem;
    border-end-start-radius: .5rem;
  }

  .lg\:rounded-s-md {
    border-start-start-radius: .375rem;
    border-end-start-radius: .375rem;
  }

  .lg\:rounded-s-none {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }

  .lg\:rounded-s-sm {
    border-start-start-radius: .125rem;
    border-end-start-radius: .125rem;
  }

  .lg\:rounded-s-xl {
    border-start-start-radius: .75rem;
    border-end-start-radius: .75rem;
  }

  .lg\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .lg\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .lg\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .lg\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .lg\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .lg\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .lg\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .lg\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .lg\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .lg\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .lg\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .lg\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .lg\:rounded-ee {
    border-end-end-radius: .25rem;
  }

  .lg\:rounded-ee-2xl {
    border-end-end-radius: 1rem;
  }

  .lg\:rounded-ee-3xl {
    border-end-end-radius: 1.5rem;
  }

  .lg\:rounded-ee-full {
    border-end-end-radius: 9999px;
  }

  .lg\:rounded-ee-lg {
    border-end-end-radius: .5rem;
  }

  .lg\:rounded-ee-md {
    border-end-end-radius: .375rem;
  }

  .lg\:rounded-ee-none {
    border-end-end-radius: 0;
  }

  .lg\:rounded-ee-sm {
    border-end-end-radius: .125rem;
  }

  .lg\:rounded-ee-xl {
    border-end-end-radius: .75rem;
  }

  .lg\:rounded-es {
    border-end-start-radius: .25rem;
  }

  .lg\:rounded-es-2xl {
    border-end-start-radius: 1rem;
  }

  .lg\:rounded-es-3xl {
    border-end-start-radius: 1.5rem;
  }

  .lg\:rounded-es-full {
    border-end-start-radius: 9999px;
  }

  .lg\:rounded-es-lg {
    border-end-start-radius: .5rem;
  }

  .lg\:rounded-es-md {
    border-end-start-radius: .375rem;
  }

  .lg\:rounded-es-none {
    border-end-start-radius: 0;
  }

  .lg\:rounded-es-sm {
    border-end-start-radius: .125rem;
  }

  .lg\:rounded-es-xl {
    border-end-start-radius: .75rem;
  }

  .lg\:rounded-se {
    border-start-end-radius: .25rem;
  }

  .lg\:rounded-se-2xl {
    border-start-end-radius: 1rem;
  }

  .lg\:rounded-se-3xl {
    border-start-end-radius: 1.5rem;
  }

  .lg\:rounded-se-full {
    border-start-end-radius: 9999px;
  }

  .lg\:rounded-se-lg {
    border-start-end-radius: .5rem;
  }

  .lg\:rounded-se-md {
    border-start-end-radius: .375rem;
  }

  .lg\:rounded-se-none {
    border-start-end-radius: 0;
  }

  .lg\:rounded-se-sm {
    border-start-end-radius: .125rem;
  }

  .lg\:rounded-se-xl {
    border-start-end-radius: .75rem;
  }

  .lg\:rounded-ss {
    border-start-start-radius: .25rem;
  }

  .lg\:rounded-ss-2xl {
    border-start-start-radius: 1rem;
  }

  .lg\:rounded-ss-3xl {
    border-start-start-radius: 1.5rem;
  }

  .lg\:rounded-ss-full {
    border-start-start-radius: 9999px;
  }

  .lg\:rounded-ss-lg {
    border-start-start-radius: .5rem;
  }

  .lg\:rounded-ss-md {
    border-start-start-radius: .375rem;
  }

  .lg\:rounded-ss-none {
    border-start-start-radius: 0;
  }

  .lg\:rounded-ss-sm {
    border-start-start-radius: .125rem;
  }

  .lg\:rounded-ss-xl {
    border-start-start-radius: .75rem;
  }

  .lg\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .lg\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .lg\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .lg\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .lg\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .lg\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .lg\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .lg\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .lg\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .lg\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .lg\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .lg\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .lg\:border {
    border-width: 1px;
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:border-2 {
    border-width: 2px;
  }

  .lg\:border-4 {
    border-width: 4px;
  }

  .lg\:border-8 {
    border-width: 8px;
  }

  .lg\:border-x {
    border-left-width: 1px;
    border-right-width: 1px;
  }

  .lg\:border-x-0 {
    border-left-width: 0;
    border-right-width: 0;
  }

  .lg\:border-x-2 {
    border-left-width: 2px;
    border-right-width: 2px;
  }

  .lg\:border-x-4 {
    border-left-width: 4px;
    border-right-width: 4px;
  }

  .lg\:border-x-8 {
    border-left-width: 8px;
    border-right-width: 8px;
  }

  .lg\:border-y {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .lg\:border-y-0 {
    border-top-width: 0;
    border-bottom-width: 0;
  }

  .lg\:border-y-2 {
    border-top-width: 2px;
    border-bottom-width: 2px;
  }

  .lg\:border-y-4 {
    border-top-width: 4px;
    border-bottom-width: 4px;
  }

  .lg\:border-y-8 {
    border-top-width: 8px;
    border-bottom-width: 8px;
  }

  .lg\:border-b {
    border-bottom-width: 1px;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px;
  }

  .lg\:border-b-4 {
    border-bottom-width: 4px;
  }

  .lg\:border-b-8 {
    border-bottom-width: 8px;
  }

  .lg\:border-e {
    border-inline-end-width: 1px;
  }

  .lg\:border-e-0 {
    border-inline-end-width: 0;
  }

  .lg\:border-e-2 {
    border-inline-end-width: 2px;
  }

  .lg\:border-e-4 {
    border-inline-end-width: 4px;
  }

  .lg\:border-e-8 {
    border-inline-end-width: 8px;
  }

  .lg\:border-l {
    border-left-width: 1px;
  }

  .lg\:border-l-0 {
    border-left-width: 0;
  }

  .lg\:border-l-2 {
    border-left-width: 2px;
  }

  .lg\:border-l-4 {
    border-left-width: 4px;
  }

  .lg\:border-l-8 {
    border-left-width: 8px;
  }

  .lg\:border-r {
    border-right-width: 1px;
  }

  .lg\:border-r-0 {
    border-right-width: 0;
  }

  .lg\:border-r-2 {
    border-right-width: 2px;
  }

  .lg\:border-r-4 {
    border-right-width: 4px;
  }

  .lg\:border-r-8 {
    border-right-width: 8px;
  }

  .lg\:border-s {
    border-inline-start-width: 1px;
  }

  .lg\:border-s-0 {
    border-inline-start-width: 0;
  }

  .lg\:border-s-2 {
    border-inline-start-width: 2px;
  }

  .lg\:border-s-4 {
    border-inline-start-width: 4px;
  }

  .lg\:border-s-8 {
    border-inline-start-width: 8px;
  }

  .lg\:border-t {
    border-top-width: 1px;
  }

  .lg\:border-t-0 {
    border-top-width: 0;
  }

  .lg\:border-t-2 {
    border-top-width: 2px;
  }

  .lg\:border-t-4 {
    border-top-width: 4px;
  }

  .lg\:border-t-8 {
    border-top-width: 8px;
  }

  .lg\:border-solid {
    border-style: solid;
  }

  .lg\:border-dashed {
    border-style: dashed;
  }

  .lg\:border-dotted {
    border-style: dotted;
  }

  .lg\:border-double {
    border-style: double;
  }

  .lg\:border-hidden {
    border-style: hidden;
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:border-black {
    border-color: var(--color-black);
  }

  .lg\:border-current {
    border-color: currentColor;
  }

  .lg\:border-danger {
    border-color: var(--color-danger-300);
  }

  .lg\:border-danger-100 {
    border-color: var(--color-danger-100);
  }

  .lg\:border-danger-200 {
    border-color: var(--color-danger-200);
  }

  .lg\:border-danger-300 {
    border-color: var(--color-danger-300);
  }

  .lg\:border-danger-400 {
    border-color: var(--color-danger-400);
  }

  .lg\:border-divider {
    border-color: var(--color-divider);
  }

  .lg\:border-gray {
    border-color: var(--color-gray);
  }

  .lg\:border-info {
    border-color: var(--color-info-300);
  }

  .lg\:border-info-100 {
    border-color: var(--color-info-100);
  }

  .lg\:border-info-200 {
    border-color: var(--color-info-200);
  }

  .lg\:border-info-300 {
    border-color: var(--color-info-300);
  }

  .lg\:border-info-400 {
    border-color: var(--color-info-400);
  }

  .lg\:border-primary {
    border-color: var(--color-primary-300);
  }

  .lg\:border-primary-100 {
    border-color: var(--color-primary-100);
  }

  .lg\:border-primary-200 {
    border-color: var(--color-primary-200);
  }

  .lg\:border-primary-250 {
    border-color: var(--color-primary-250);
  }

  .lg\:border-primary-300 {
    border-color: var(--color-primary-300);
  }

  .lg\:border-primary-400 {
    border-color: var(--color-primary-400);
  }

  .lg\:border-primary-500 {
    border-color: var(--color-primary-500);
  }

  .lg\:border-primary-600 {
    border-color: var(--color-primary-600);
  }

  .lg\:border-success {
    border-color: var(--color-success-300);
  }

  .lg\:border-success-100 {
    border-color: var(--color-success-100);
  }

  .lg\:border-success-200 {
    border-color: var(--color-success-200);
  }

  .lg\:border-success-300 {
    border-color: var(--color-success-300);
  }

  .lg\:border-success-400 {
    border-color: var(--color-success-400);
  }

  .lg\:border-transparent, .lg\:border-transparent\/0 {
    border-color: #0000;
  }

  .lg\:border-transparent\/10 {
    border-color: #0000001a;
  }

  .lg\:border-transparent\/100 {
    border-color: #000;
  }

  .lg\:border-transparent\/15 {
    border-color: #00000026;
  }

  .lg\:border-transparent\/20 {
    border-color: #0003;
  }

  .lg\:border-transparent\/25 {
    border-color: #00000040;
  }

  .lg\:border-transparent\/30 {
    border-color: #0000004d;
  }

  .lg\:border-transparent\/35 {
    border-color: #00000059;
  }

  .lg\:border-transparent\/40 {
    border-color: #0006;
  }

  .lg\:border-transparent\/45 {
    border-color: #00000073;
  }

  .lg\:border-transparent\/5 {
    border-color: #0000000d;
  }

  .lg\:border-transparent\/50 {
    border-color: #00000080;
  }

  .lg\:border-transparent\/55 {
    border-color: #0000008c;
  }

  .lg\:border-transparent\/60 {
    border-color: #0009;
  }

  .lg\:border-transparent\/65 {
    border-color: #000000a6;
  }

  .lg\:border-transparent\/70 {
    border-color: #000000b3;
  }

  .lg\:border-transparent\/75 {
    border-color: #000000bf;
  }

  .lg\:border-transparent\/80 {
    border-color: #000c;
  }

  .lg\:border-transparent\/85 {
    border-color: #000000d9;
  }

  .lg\:border-transparent\/90 {
    border-color: #000000e6;
  }

  .lg\:border-transparent\/95 {
    border-color: #000000f2;
  }

  .lg\:border-warning {
    border-color: var(--color-warning-300);
  }

  .lg\:border-warning-100 {
    border-color: var(--color-warning-100);
  }

  .lg\:border-warning-200 {
    border-color: var(--color-warning-200);
  }

  .lg\:border-warning-300 {
    border-color: var(--color-warning-300);
  }

  .lg\:border-warning-400 {
    border-color: var(--color-warning-400);
  }

  .lg\:border-white {
    border-color: var(--color-white);
  }

  .lg\:border-x-black {
    border-left-color: var(--color-black);
    border-right-color: var(--color-black);
  }

  .lg\:border-x-current {
    border-left-color: currentColor;
    border-right-color: currentColor;
  }

  .lg\:border-x-danger {
    border-left-color: var(--color-danger-300);
    border-right-color: var(--color-danger-300);
  }

  .lg\:border-x-danger-100 {
    border-left-color: var(--color-danger-100);
    border-right-color: var(--color-danger-100);
  }

  .lg\:border-x-danger-200 {
    border-left-color: var(--color-danger-200);
    border-right-color: var(--color-danger-200);
  }

  .lg\:border-x-danger-300 {
    border-left-color: var(--color-danger-300);
    border-right-color: var(--color-danger-300);
  }

  .lg\:border-x-danger-400 {
    border-left-color: var(--color-danger-400);
    border-right-color: var(--color-danger-400);
  }

  .lg\:border-x-divider {
    border-left-color: var(--color-divider);
    border-right-color: var(--color-divider);
  }

  .lg\:border-x-gray {
    border-left-color: var(--color-gray);
    border-right-color: var(--color-gray);
  }

  .lg\:border-x-info {
    border-left-color: var(--color-info-300);
    border-right-color: var(--color-info-300);
  }

  .lg\:border-x-info-100 {
    border-left-color: var(--color-info-100);
    border-right-color: var(--color-info-100);
  }

  .lg\:border-x-info-200 {
    border-left-color: var(--color-info-200);
    border-right-color: var(--color-info-200);
  }

  .lg\:border-x-info-300 {
    border-left-color: var(--color-info-300);
    border-right-color: var(--color-info-300);
  }

  .lg\:border-x-info-400 {
    border-left-color: var(--color-info-400);
    border-right-color: var(--color-info-400);
  }

  .lg\:border-x-primary {
    border-left-color: var(--color-primary-300);
    border-right-color: var(--color-primary-300);
  }

  .lg\:border-x-primary-100 {
    border-left-color: var(--color-primary-100);
    border-right-color: var(--color-primary-100);
  }

  .lg\:border-x-primary-200 {
    border-left-color: var(--color-primary-200);
    border-right-color: var(--color-primary-200);
  }

  .lg\:border-x-primary-250 {
    border-left-color: var(--color-primary-250);
    border-right-color: var(--color-primary-250);
  }

  .lg\:border-x-primary-300 {
    border-left-color: var(--color-primary-300);
    border-right-color: var(--color-primary-300);
  }

  .lg\:border-x-primary-400 {
    border-left-color: var(--color-primary-400);
    border-right-color: var(--color-primary-400);
  }

  .lg\:border-x-primary-500 {
    border-left-color: var(--color-primary-500);
    border-right-color: var(--color-primary-500);
  }

  .lg\:border-x-primary-600 {
    border-left-color: var(--color-primary-600);
    border-right-color: var(--color-primary-600);
  }

  .lg\:border-x-success {
    border-left-color: var(--color-success-300);
    border-right-color: var(--color-success-300);
  }

  .lg\:border-x-success-100 {
    border-left-color: var(--color-success-100);
    border-right-color: var(--color-success-100);
  }

  .lg\:border-x-success-200 {
    border-left-color: var(--color-success-200);
    border-right-color: var(--color-success-200);
  }

  .lg\:border-x-success-300 {
    border-left-color: var(--color-success-300);
    border-right-color: var(--color-success-300);
  }

  .lg\:border-x-success-400 {
    border-left-color: var(--color-success-400);
    border-right-color: var(--color-success-400);
  }

  .lg\:border-x-transparent, .lg\:border-x-transparent\/0 {
    border-left-color: #0000;
    border-right-color: #0000;
  }

  .lg\:border-x-transparent\/10 {
    border-left-color: #0000001a;
    border-right-color: #0000001a;
  }

  .lg\:border-x-transparent\/100 {
    border-left-color: #000;
    border-right-color: #000;
  }

  .lg\:border-x-transparent\/15 {
    border-left-color: #00000026;
    border-right-color: #00000026;
  }

  .lg\:border-x-transparent\/20 {
    border-left-color: #0003;
    border-right-color: #0003;
  }

  .lg\:border-x-transparent\/25 {
    border-left-color: #00000040;
    border-right-color: #00000040;
  }

  .lg\:border-x-transparent\/30 {
    border-left-color: #0000004d;
    border-right-color: #0000004d;
  }

  .lg\:border-x-transparent\/35 {
    border-left-color: #00000059;
    border-right-color: #00000059;
  }

  .lg\:border-x-transparent\/40 {
    border-left-color: #0006;
    border-right-color: #0006;
  }

  .lg\:border-x-transparent\/45 {
    border-left-color: #00000073;
    border-right-color: #00000073;
  }

  .lg\:border-x-transparent\/5 {
    border-left-color: #0000000d;
    border-right-color: #0000000d;
  }

  .lg\:border-x-transparent\/50 {
    border-left-color: #00000080;
    border-right-color: #00000080;
  }

  .lg\:border-x-transparent\/55 {
    border-left-color: #0000008c;
    border-right-color: #0000008c;
  }

  .lg\:border-x-transparent\/60 {
    border-left-color: #0009;
    border-right-color: #0009;
  }

  .lg\:border-x-transparent\/65 {
    border-left-color: #000000a6;
    border-right-color: #000000a6;
  }

  .lg\:border-x-transparent\/70 {
    border-left-color: #000000b3;
    border-right-color: #000000b3;
  }

  .lg\:border-x-transparent\/75 {
    border-left-color: #000000bf;
    border-right-color: #000000bf;
  }

  .lg\:border-x-transparent\/80 {
    border-left-color: #000c;
    border-right-color: #000c;
  }

  .lg\:border-x-transparent\/85 {
    border-left-color: #000000d9;
    border-right-color: #000000d9;
  }

  .lg\:border-x-transparent\/90 {
    border-left-color: #000000e6;
    border-right-color: #000000e6;
  }

  .lg\:border-x-transparent\/95 {
    border-left-color: #000000f2;
    border-right-color: #000000f2;
  }

  .lg\:border-x-warning {
    border-left-color: var(--color-warning-300);
    border-right-color: var(--color-warning-300);
  }

  .lg\:border-x-warning-100 {
    border-left-color: var(--color-warning-100);
    border-right-color: var(--color-warning-100);
  }

  .lg\:border-x-warning-200 {
    border-left-color: var(--color-warning-200);
    border-right-color: var(--color-warning-200);
  }

  .lg\:border-x-warning-300 {
    border-left-color: var(--color-warning-300);
    border-right-color: var(--color-warning-300);
  }

  .lg\:border-x-warning-400 {
    border-left-color: var(--color-warning-400);
    border-right-color: var(--color-warning-400);
  }

  .lg\:border-x-white {
    border-left-color: var(--color-white);
    border-right-color: var(--color-white);
  }

  .lg\:border-y-black {
    border-top-color: var(--color-black);
    border-bottom-color: var(--color-black);
  }

  .lg\:border-y-current {
    border-top-color: currentColor;
    border-bottom-color: currentColor;
  }

  .lg\:border-y-danger {
    border-top-color: var(--color-danger-300);
    border-bottom-color: var(--color-danger-300);
  }

  .lg\:border-y-danger-100 {
    border-top-color: var(--color-danger-100);
    border-bottom-color: var(--color-danger-100);
  }

  .lg\:border-y-danger-200 {
    border-top-color: var(--color-danger-200);
    border-bottom-color: var(--color-danger-200);
  }

  .lg\:border-y-danger-300 {
    border-top-color: var(--color-danger-300);
    border-bottom-color: var(--color-danger-300);
  }

  .lg\:border-y-danger-400 {
    border-top-color: var(--color-danger-400);
    border-bottom-color: var(--color-danger-400);
  }

  .lg\:border-y-divider {
    border-top-color: var(--color-divider);
    border-bottom-color: var(--color-divider);
  }

  .lg\:border-y-gray {
    border-top-color: var(--color-gray);
    border-bottom-color: var(--color-gray);
  }

  .lg\:border-y-info {
    border-top-color: var(--color-info-300);
    border-bottom-color: var(--color-info-300);
  }

  .lg\:border-y-info-100 {
    border-top-color: var(--color-info-100);
    border-bottom-color: var(--color-info-100);
  }

  .lg\:border-y-info-200 {
    border-top-color: var(--color-info-200);
    border-bottom-color: var(--color-info-200);
  }

  .lg\:border-y-info-300 {
    border-top-color: var(--color-info-300);
    border-bottom-color: var(--color-info-300);
  }

  .lg\:border-y-info-400 {
    border-top-color: var(--color-info-400);
    border-bottom-color: var(--color-info-400);
  }

  .lg\:border-y-primary {
    border-top-color: var(--color-primary-300);
    border-bottom-color: var(--color-primary-300);
  }

  .lg\:border-y-primary-100 {
    border-top-color: var(--color-primary-100);
    border-bottom-color: var(--color-primary-100);
  }

  .lg\:border-y-primary-200 {
    border-top-color: var(--color-primary-200);
    border-bottom-color: var(--color-primary-200);
  }

  .lg\:border-y-primary-250 {
    border-top-color: var(--color-primary-250);
    border-bottom-color: var(--color-primary-250);
  }

  .lg\:border-y-primary-300 {
    border-top-color: var(--color-primary-300);
    border-bottom-color: var(--color-primary-300);
  }

  .lg\:border-y-primary-400 {
    border-top-color: var(--color-primary-400);
    border-bottom-color: var(--color-primary-400);
  }

  .lg\:border-y-primary-500 {
    border-top-color: var(--color-primary-500);
    border-bottom-color: var(--color-primary-500);
  }

  .lg\:border-y-primary-600 {
    border-top-color: var(--color-primary-600);
    border-bottom-color: var(--color-primary-600);
  }

  .lg\:border-y-success {
    border-top-color: var(--color-success-300);
    border-bottom-color: var(--color-success-300);
  }

  .lg\:border-y-success-100 {
    border-top-color: var(--color-success-100);
    border-bottom-color: var(--color-success-100);
  }

  .lg\:border-y-success-200 {
    border-top-color: var(--color-success-200);
    border-bottom-color: var(--color-success-200);
  }

  .lg\:border-y-success-300 {
    border-top-color: var(--color-success-300);
    border-bottom-color: var(--color-success-300);
  }

  .lg\:border-y-success-400 {
    border-top-color: var(--color-success-400);
    border-bottom-color: var(--color-success-400);
  }

  .lg\:border-y-transparent, .lg\:border-y-transparent\/0 {
    border-top-color: #0000;
    border-bottom-color: #0000;
  }

  .lg\:border-y-transparent\/10 {
    border-top-color: #0000001a;
    border-bottom-color: #0000001a;
  }

  .lg\:border-y-transparent\/100 {
    border-top-color: #000;
    border-bottom-color: #000;
  }

  .lg\:border-y-transparent\/15 {
    border-top-color: #00000026;
    border-bottom-color: #00000026;
  }

  .lg\:border-y-transparent\/20 {
    border-top-color: #0003;
    border-bottom-color: #0003;
  }

  .lg\:border-y-transparent\/25 {
    border-top-color: #00000040;
    border-bottom-color: #00000040;
  }

  .lg\:border-y-transparent\/30 {
    border-top-color: #0000004d;
    border-bottom-color: #0000004d;
  }

  .lg\:border-y-transparent\/35 {
    border-top-color: #00000059;
    border-bottom-color: #00000059;
  }

  .lg\:border-y-transparent\/40 {
    border-top-color: #0006;
    border-bottom-color: #0006;
  }

  .lg\:border-y-transparent\/45 {
    border-top-color: #00000073;
    border-bottom-color: #00000073;
  }

  .lg\:border-y-transparent\/5 {
    border-top-color: #0000000d;
    border-bottom-color: #0000000d;
  }

  .lg\:border-y-transparent\/50 {
    border-top-color: #00000080;
    border-bottom-color: #00000080;
  }

  .lg\:border-y-transparent\/55 {
    border-top-color: #0000008c;
    border-bottom-color: #0000008c;
  }

  .lg\:border-y-transparent\/60 {
    border-top-color: #0009;
    border-bottom-color: #0009;
  }

  .lg\:border-y-transparent\/65 {
    border-top-color: #000000a6;
    border-bottom-color: #000000a6;
  }

  .lg\:border-y-transparent\/70 {
    border-top-color: #000000b3;
    border-bottom-color: #000000b3;
  }

  .lg\:border-y-transparent\/75 {
    border-top-color: #000000bf;
    border-bottom-color: #000000bf;
  }

  .lg\:border-y-transparent\/80 {
    border-top-color: #000c;
    border-bottom-color: #000c;
  }

  .lg\:border-y-transparent\/85 {
    border-top-color: #000000d9;
    border-bottom-color: #000000d9;
  }

  .lg\:border-y-transparent\/90 {
    border-top-color: #000000e6;
    border-bottom-color: #000000e6;
  }

  .lg\:border-y-transparent\/95 {
    border-top-color: #000000f2;
    border-bottom-color: #000000f2;
  }

  .lg\:border-y-warning {
    border-top-color: var(--color-warning-300);
    border-bottom-color: var(--color-warning-300);
  }

  .lg\:border-y-warning-100 {
    border-top-color: var(--color-warning-100);
    border-bottom-color: var(--color-warning-100);
  }

  .lg\:border-y-warning-200 {
    border-top-color: var(--color-warning-200);
    border-bottom-color: var(--color-warning-200);
  }

  .lg\:border-y-warning-300 {
    border-top-color: var(--color-warning-300);
    border-bottom-color: var(--color-warning-300);
  }

  .lg\:border-y-warning-400 {
    border-top-color: var(--color-warning-400);
    border-bottom-color: var(--color-warning-400);
  }

  .lg\:border-y-white {
    border-top-color: var(--color-white);
    border-bottom-color: var(--color-white);
  }

  .lg\:border-b-black {
    border-bottom-color: var(--color-black);
  }

  .lg\:border-b-current {
    border-bottom-color: currentColor;
  }

  .lg\:border-b-danger {
    border-bottom-color: var(--color-danger-300);
  }

  .lg\:border-b-danger-100 {
    border-bottom-color: var(--color-danger-100);
  }

  .lg\:border-b-danger-200 {
    border-bottom-color: var(--color-danger-200);
  }

  .lg\:border-b-danger-300 {
    border-bottom-color: var(--color-danger-300);
  }

  .lg\:border-b-danger-400 {
    border-bottom-color: var(--color-danger-400);
  }

  .lg\:border-b-divider {
    border-bottom-color: var(--color-divider);
  }

  .lg\:border-b-gray {
    border-bottom-color: var(--color-gray);
  }

  .lg\:border-b-info {
    border-bottom-color: var(--color-info-300);
  }

  .lg\:border-b-info-100 {
    border-bottom-color: var(--color-info-100);
  }

  .lg\:border-b-info-200 {
    border-bottom-color: var(--color-info-200);
  }

  .lg\:border-b-info-300 {
    border-bottom-color: var(--color-info-300);
  }

  .lg\:border-b-info-400 {
    border-bottom-color: var(--color-info-400);
  }

  .lg\:border-b-primary {
    border-bottom-color: var(--color-primary-300);
  }

  .lg\:border-b-primary-100 {
    border-bottom-color: var(--color-primary-100);
  }

  .lg\:border-b-primary-200 {
    border-bottom-color: var(--color-primary-200);
  }

  .lg\:border-b-primary-250 {
    border-bottom-color: var(--color-primary-250);
  }

  .lg\:border-b-primary-300 {
    border-bottom-color: var(--color-primary-300);
  }

  .lg\:border-b-primary-400 {
    border-bottom-color: var(--color-primary-400);
  }

  .lg\:border-b-primary-500 {
    border-bottom-color: var(--color-primary-500);
  }

  .lg\:border-b-primary-600 {
    border-bottom-color: var(--color-primary-600);
  }

  .lg\:border-b-success {
    border-bottom-color: var(--color-success-300);
  }

  .lg\:border-b-success-100 {
    border-bottom-color: var(--color-success-100);
  }

  .lg\:border-b-success-200 {
    border-bottom-color: var(--color-success-200);
  }

  .lg\:border-b-success-300 {
    border-bottom-color: var(--color-success-300);
  }

  .lg\:border-b-success-400 {
    border-bottom-color: var(--color-success-400);
  }

  .lg\:border-b-transparent, .lg\:border-b-transparent\/0 {
    border-bottom-color: #0000;
  }

  .lg\:border-b-transparent\/10 {
    border-bottom-color: #0000001a;
  }

  .lg\:border-b-transparent\/100 {
    border-bottom-color: #000;
  }

  .lg\:border-b-transparent\/15 {
    border-bottom-color: #00000026;
  }

  .lg\:border-b-transparent\/20 {
    border-bottom-color: #0003;
  }

  .lg\:border-b-transparent\/25 {
    border-bottom-color: #00000040;
  }

  .lg\:border-b-transparent\/30 {
    border-bottom-color: #0000004d;
  }

  .lg\:border-b-transparent\/35 {
    border-bottom-color: #00000059;
  }

  .lg\:border-b-transparent\/40 {
    border-bottom-color: #0006;
  }

  .lg\:border-b-transparent\/45 {
    border-bottom-color: #00000073;
  }

  .lg\:border-b-transparent\/5 {
    border-bottom-color: #0000000d;
  }

  .lg\:border-b-transparent\/50 {
    border-bottom-color: #00000080;
  }

  .lg\:border-b-transparent\/55 {
    border-bottom-color: #0000008c;
  }

  .lg\:border-b-transparent\/60 {
    border-bottom-color: #0009;
  }

  .lg\:border-b-transparent\/65 {
    border-bottom-color: #000000a6;
  }

  .lg\:border-b-transparent\/70 {
    border-bottom-color: #000000b3;
  }

  .lg\:border-b-transparent\/75 {
    border-bottom-color: #000000bf;
  }

  .lg\:border-b-transparent\/80 {
    border-bottom-color: #000c;
  }

  .lg\:border-b-transparent\/85 {
    border-bottom-color: #000000d9;
  }

  .lg\:border-b-transparent\/90 {
    border-bottom-color: #000000e6;
  }

  .lg\:border-b-transparent\/95 {
    border-bottom-color: #000000f2;
  }

  .lg\:border-b-warning {
    border-bottom-color: var(--color-warning-300);
  }

  .lg\:border-b-warning-100 {
    border-bottom-color: var(--color-warning-100);
  }

  .lg\:border-b-warning-200 {
    border-bottom-color: var(--color-warning-200);
  }

  .lg\:border-b-warning-300 {
    border-bottom-color: var(--color-warning-300);
  }

  .lg\:border-b-warning-400 {
    border-bottom-color: var(--color-warning-400);
  }

  .lg\:border-b-white {
    border-bottom-color: var(--color-white);
  }

  .lg\:border-e-black {
    border-inline-end-color: var(--color-black);
  }

  .lg\:border-e-current {
    border-inline-end-color: currentColor;
  }

  .lg\:border-e-danger {
    border-inline-end-color: var(--color-danger-300);
  }

  .lg\:border-e-danger-100 {
    border-inline-end-color: var(--color-danger-100);
  }

  .lg\:border-e-danger-200 {
    border-inline-end-color: var(--color-danger-200);
  }

  .lg\:border-e-danger-300 {
    border-inline-end-color: var(--color-danger-300);
  }

  .lg\:border-e-danger-400 {
    border-inline-end-color: var(--color-danger-400);
  }

  .lg\:border-e-divider {
    border-inline-end-color: var(--color-divider);
  }

  .lg\:border-e-gray {
    border-inline-end-color: var(--color-gray);
  }

  .lg\:border-e-info {
    border-inline-end-color: var(--color-info-300);
  }

  .lg\:border-e-info-100 {
    border-inline-end-color: var(--color-info-100);
  }

  .lg\:border-e-info-200 {
    border-inline-end-color: var(--color-info-200);
  }

  .lg\:border-e-info-300 {
    border-inline-end-color: var(--color-info-300);
  }

  .lg\:border-e-info-400 {
    border-inline-end-color: var(--color-info-400);
  }

  .lg\:border-e-primary {
    border-inline-end-color: var(--color-primary-300);
  }

  .lg\:border-e-primary-100 {
    border-inline-end-color: var(--color-primary-100);
  }

  .lg\:border-e-primary-200 {
    border-inline-end-color: var(--color-primary-200);
  }

  .lg\:border-e-primary-250 {
    border-inline-end-color: var(--color-primary-250);
  }

  .lg\:border-e-primary-300 {
    border-inline-end-color: var(--color-primary-300);
  }

  .lg\:border-e-primary-400 {
    border-inline-end-color: var(--color-primary-400);
  }

  .lg\:border-e-primary-500 {
    border-inline-end-color: var(--color-primary-500);
  }

  .lg\:border-e-primary-600 {
    border-inline-end-color: var(--color-primary-600);
  }

  .lg\:border-e-success {
    border-inline-end-color: var(--color-success-300);
  }

  .lg\:border-e-success-100 {
    border-inline-end-color: var(--color-success-100);
  }

  .lg\:border-e-success-200 {
    border-inline-end-color: var(--color-success-200);
  }

  .lg\:border-e-success-300 {
    border-inline-end-color: var(--color-success-300);
  }

  .lg\:border-e-success-400 {
    border-inline-end-color: var(--color-success-400);
  }

  .lg\:border-e-transparent, .lg\:border-e-transparent\/0 {
    border-inline-end-color: #0000;
  }

  .lg\:border-e-transparent\/10 {
    border-inline-end-color: #0000001a;
  }

  .lg\:border-e-transparent\/100 {
    border-inline-end-color: #000;
  }

  .lg\:border-e-transparent\/15 {
    border-inline-end-color: #00000026;
  }

  .lg\:border-e-transparent\/20 {
    border-inline-end-color: #0003;
  }

  .lg\:border-e-transparent\/25 {
    border-inline-end-color: #00000040;
  }

  .lg\:border-e-transparent\/30 {
    border-inline-end-color: #0000004d;
  }

  .lg\:border-e-transparent\/35 {
    border-inline-end-color: #00000059;
  }

  .lg\:border-e-transparent\/40 {
    border-inline-end-color: #0006;
  }

  .lg\:border-e-transparent\/45 {
    border-inline-end-color: #00000073;
  }

  .lg\:border-e-transparent\/5 {
    border-inline-end-color: #0000000d;
  }

  .lg\:border-e-transparent\/50 {
    border-inline-end-color: #00000080;
  }

  .lg\:border-e-transparent\/55 {
    border-inline-end-color: #0000008c;
  }

  .lg\:border-e-transparent\/60 {
    border-inline-end-color: #0009;
  }

  .lg\:border-e-transparent\/65 {
    border-inline-end-color: #000000a6;
  }

  .lg\:border-e-transparent\/70 {
    border-inline-end-color: #000000b3;
  }

  .lg\:border-e-transparent\/75 {
    border-inline-end-color: #000000bf;
  }

  .lg\:border-e-transparent\/80 {
    border-inline-end-color: #000c;
  }

  .lg\:border-e-transparent\/85 {
    border-inline-end-color: #000000d9;
  }

  .lg\:border-e-transparent\/90 {
    border-inline-end-color: #000000e6;
  }

  .lg\:border-e-transparent\/95 {
    border-inline-end-color: #000000f2;
  }

  .lg\:border-e-warning {
    border-inline-end-color: var(--color-warning-300);
  }

  .lg\:border-e-warning-100 {
    border-inline-end-color: var(--color-warning-100);
  }

  .lg\:border-e-warning-200 {
    border-inline-end-color: var(--color-warning-200);
  }

  .lg\:border-e-warning-300 {
    border-inline-end-color: var(--color-warning-300);
  }

  .lg\:border-e-warning-400 {
    border-inline-end-color: var(--color-warning-400);
  }

  .lg\:border-e-white {
    border-inline-end-color: var(--color-white);
  }

  .lg\:border-l-black {
    border-left-color: var(--color-black);
  }

  .lg\:border-l-current {
    border-left-color: currentColor;
  }

  .lg\:border-l-danger {
    border-left-color: var(--color-danger-300);
  }

  .lg\:border-l-danger-100 {
    border-left-color: var(--color-danger-100);
  }

  .lg\:border-l-danger-200 {
    border-left-color: var(--color-danger-200);
  }

  .lg\:border-l-danger-300 {
    border-left-color: var(--color-danger-300);
  }

  .lg\:border-l-danger-400 {
    border-left-color: var(--color-danger-400);
  }

  .lg\:border-l-divider {
    border-left-color: var(--color-divider);
  }

  .lg\:border-l-gray {
    border-left-color: var(--color-gray);
  }

  .lg\:border-l-info {
    border-left-color: var(--color-info-300);
  }

  .lg\:border-l-info-100 {
    border-left-color: var(--color-info-100);
  }

  .lg\:border-l-info-200 {
    border-left-color: var(--color-info-200);
  }

  .lg\:border-l-info-300 {
    border-left-color: var(--color-info-300);
  }

  .lg\:border-l-info-400 {
    border-left-color: var(--color-info-400);
  }

  .lg\:border-l-primary {
    border-left-color: var(--color-primary-300);
  }

  .lg\:border-l-primary-100 {
    border-left-color: var(--color-primary-100);
  }

  .lg\:border-l-primary-200 {
    border-left-color: var(--color-primary-200);
  }

  .lg\:border-l-primary-250 {
    border-left-color: var(--color-primary-250);
  }

  .lg\:border-l-primary-300 {
    border-left-color: var(--color-primary-300);
  }

  .lg\:border-l-primary-400 {
    border-left-color: var(--color-primary-400);
  }

  .lg\:border-l-primary-500 {
    border-left-color: var(--color-primary-500);
  }

  .lg\:border-l-primary-600 {
    border-left-color: var(--color-primary-600);
  }

  .lg\:border-l-success {
    border-left-color: var(--color-success-300);
  }

  .lg\:border-l-success-100 {
    border-left-color: var(--color-success-100);
  }

  .lg\:border-l-success-200 {
    border-left-color: var(--color-success-200);
  }

  .lg\:border-l-success-300 {
    border-left-color: var(--color-success-300);
  }

  .lg\:border-l-success-400 {
    border-left-color: var(--color-success-400);
  }

  .lg\:border-l-transparent, .lg\:border-l-transparent\/0 {
    border-left-color: #0000;
  }

  .lg\:border-l-transparent\/10 {
    border-left-color: #0000001a;
  }

  .lg\:border-l-transparent\/100 {
    border-left-color: #000;
  }

  .lg\:border-l-transparent\/15 {
    border-left-color: #00000026;
  }

  .lg\:border-l-transparent\/20 {
    border-left-color: #0003;
  }

  .lg\:border-l-transparent\/25 {
    border-left-color: #00000040;
  }

  .lg\:border-l-transparent\/30 {
    border-left-color: #0000004d;
  }

  .lg\:border-l-transparent\/35 {
    border-left-color: #00000059;
  }

  .lg\:border-l-transparent\/40 {
    border-left-color: #0006;
  }

  .lg\:border-l-transparent\/45 {
    border-left-color: #00000073;
  }

  .lg\:border-l-transparent\/5 {
    border-left-color: #0000000d;
  }

  .lg\:border-l-transparent\/50 {
    border-left-color: #00000080;
  }

  .lg\:border-l-transparent\/55 {
    border-left-color: #0000008c;
  }

  .lg\:border-l-transparent\/60 {
    border-left-color: #0009;
  }

  .lg\:border-l-transparent\/65 {
    border-left-color: #000000a6;
  }

  .lg\:border-l-transparent\/70 {
    border-left-color: #000000b3;
  }

  .lg\:border-l-transparent\/75 {
    border-left-color: #000000bf;
  }

  .lg\:border-l-transparent\/80 {
    border-left-color: #000c;
  }

  .lg\:border-l-transparent\/85 {
    border-left-color: #000000d9;
  }

  .lg\:border-l-transparent\/90 {
    border-left-color: #000000e6;
  }

  .lg\:border-l-transparent\/95 {
    border-left-color: #000000f2;
  }

  .lg\:border-l-warning {
    border-left-color: var(--color-warning-300);
  }

  .lg\:border-l-warning-100 {
    border-left-color: var(--color-warning-100);
  }

  .lg\:border-l-warning-200 {
    border-left-color: var(--color-warning-200);
  }

  .lg\:border-l-warning-300 {
    border-left-color: var(--color-warning-300);
  }

  .lg\:border-l-warning-400 {
    border-left-color: var(--color-warning-400);
  }

  .lg\:border-l-white {
    border-left-color: var(--color-white);
  }

  .lg\:border-r-black {
    border-right-color: var(--color-black);
  }

  .lg\:border-r-current {
    border-right-color: currentColor;
  }

  .lg\:border-r-danger {
    border-right-color: var(--color-danger-300);
  }

  .lg\:border-r-danger-100 {
    border-right-color: var(--color-danger-100);
  }

  .lg\:border-r-danger-200 {
    border-right-color: var(--color-danger-200);
  }

  .lg\:border-r-danger-300 {
    border-right-color: var(--color-danger-300);
  }

  .lg\:border-r-danger-400 {
    border-right-color: var(--color-danger-400);
  }

  .lg\:border-r-divider {
    border-right-color: var(--color-divider);
  }

  .lg\:border-r-gray {
    border-right-color: var(--color-gray);
  }

  .lg\:border-r-info {
    border-right-color: var(--color-info-300);
  }

  .lg\:border-r-info-100 {
    border-right-color: var(--color-info-100);
  }

  .lg\:border-r-info-200 {
    border-right-color: var(--color-info-200);
  }

  .lg\:border-r-info-300 {
    border-right-color: var(--color-info-300);
  }

  .lg\:border-r-info-400 {
    border-right-color: var(--color-info-400);
  }

  .lg\:border-r-primary {
    border-right-color: var(--color-primary-300);
  }

  .lg\:border-r-primary-100 {
    border-right-color: var(--color-primary-100);
  }

  .lg\:border-r-primary-200 {
    border-right-color: var(--color-primary-200);
  }

  .lg\:border-r-primary-250 {
    border-right-color: var(--color-primary-250);
  }

  .lg\:border-r-primary-300 {
    border-right-color: var(--color-primary-300);
  }

  .lg\:border-r-primary-400 {
    border-right-color: var(--color-primary-400);
  }

  .lg\:border-r-primary-500 {
    border-right-color: var(--color-primary-500);
  }

  .lg\:border-r-primary-600 {
    border-right-color: var(--color-primary-600);
  }

  .lg\:border-r-success {
    border-right-color: var(--color-success-300);
  }

  .lg\:border-r-success-100 {
    border-right-color: var(--color-success-100);
  }

  .lg\:border-r-success-200 {
    border-right-color: var(--color-success-200);
  }

  .lg\:border-r-success-300 {
    border-right-color: var(--color-success-300);
  }

  .lg\:border-r-success-400 {
    border-right-color: var(--color-success-400);
  }

  .lg\:border-r-transparent, .lg\:border-r-transparent\/0 {
    border-right-color: #0000;
  }

  .lg\:border-r-transparent\/10 {
    border-right-color: #0000001a;
  }

  .lg\:border-r-transparent\/100 {
    border-right-color: #000;
  }

  .lg\:border-r-transparent\/15 {
    border-right-color: #00000026;
  }

  .lg\:border-r-transparent\/20 {
    border-right-color: #0003;
  }

  .lg\:border-r-transparent\/25 {
    border-right-color: #00000040;
  }

  .lg\:border-r-transparent\/30 {
    border-right-color: #0000004d;
  }

  .lg\:border-r-transparent\/35 {
    border-right-color: #00000059;
  }

  .lg\:border-r-transparent\/40 {
    border-right-color: #0006;
  }

  .lg\:border-r-transparent\/45 {
    border-right-color: #00000073;
  }

  .lg\:border-r-transparent\/5 {
    border-right-color: #0000000d;
  }

  .lg\:border-r-transparent\/50 {
    border-right-color: #00000080;
  }

  .lg\:border-r-transparent\/55 {
    border-right-color: #0000008c;
  }

  .lg\:border-r-transparent\/60 {
    border-right-color: #0009;
  }

  .lg\:border-r-transparent\/65 {
    border-right-color: #000000a6;
  }

  .lg\:border-r-transparent\/70 {
    border-right-color: #000000b3;
  }

  .lg\:border-r-transparent\/75 {
    border-right-color: #000000bf;
  }

  .lg\:border-r-transparent\/80 {
    border-right-color: #000c;
  }

  .lg\:border-r-transparent\/85 {
    border-right-color: #000000d9;
  }

  .lg\:border-r-transparent\/90 {
    border-right-color: #000000e6;
  }

  .lg\:border-r-transparent\/95 {
    border-right-color: #000000f2;
  }

  .lg\:border-r-warning {
    border-right-color: var(--color-warning-300);
  }

  .lg\:border-r-warning-100 {
    border-right-color: var(--color-warning-100);
  }

  .lg\:border-r-warning-200 {
    border-right-color: var(--color-warning-200);
  }

  .lg\:border-r-warning-300 {
    border-right-color: var(--color-warning-300);
  }

  .lg\:border-r-warning-400 {
    border-right-color: var(--color-warning-400);
  }

  .lg\:border-r-white {
    border-right-color: var(--color-white);
  }

  .lg\:border-s-black {
    border-inline-start-color: var(--color-black);
  }

  .lg\:border-s-current {
    border-inline-start-color: currentColor;
  }

  .lg\:border-s-danger {
    border-inline-start-color: var(--color-danger-300);
  }

  .lg\:border-s-danger-100 {
    border-inline-start-color: var(--color-danger-100);
  }

  .lg\:border-s-danger-200 {
    border-inline-start-color: var(--color-danger-200);
  }

  .lg\:border-s-danger-300 {
    border-inline-start-color: var(--color-danger-300);
  }

  .lg\:border-s-danger-400 {
    border-inline-start-color: var(--color-danger-400);
  }

  .lg\:border-s-divider {
    border-inline-start-color: var(--color-divider);
  }

  .lg\:border-s-gray {
    border-inline-start-color: var(--color-gray);
  }

  .lg\:border-s-info {
    border-inline-start-color: var(--color-info-300);
  }

  .lg\:border-s-info-100 {
    border-inline-start-color: var(--color-info-100);
  }

  .lg\:border-s-info-200 {
    border-inline-start-color: var(--color-info-200);
  }

  .lg\:border-s-info-300 {
    border-inline-start-color: var(--color-info-300);
  }

  .lg\:border-s-info-400 {
    border-inline-start-color: var(--color-info-400);
  }

  .lg\:border-s-primary {
    border-inline-start-color: var(--color-primary-300);
  }

  .lg\:border-s-primary-100 {
    border-inline-start-color: var(--color-primary-100);
  }

  .lg\:border-s-primary-200 {
    border-inline-start-color: var(--color-primary-200);
  }

  .lg\:border-s-primary-250 {
    border-inline-start-color: var(--color-primary-250);
  }

  .lg\:border-s-primary-300 {
    border-inline-start-color: var(--color-primary-300);
  }

  .lg\:border-s-primary-400 {
    border-inline-start-color: var(--color-primary-400);
  }

  .lg\:border-s-primary-500 {
    border-inline-start-color: var(--color-primary-500);
  }

  .lg\:border-s-primary-600 {
    border-inline-start-color: var(--color-primary-600);
  }

  .lg\:border-s-success {
    border-inline-start-color: var(--color-success-300);
  }

  .lg\:border-s-success-100 {
    border-inline-start-color: var(--color-success-100);
  }

  .lg\:border-s-success-200 {
    border-inline-start-color: var(--color-success-200);
  }

  .lg\:border-s-success-300 {
    border-inline-start-color: var(--color-success-300);
  }

  .lg\:border-s-success-400 {
    border-inline-start-color: var(--color-success-400);
  }

  .lg\:border-s-transparent, .lg\:border-s-transparent\/0 {
    border-inline-start-color: #0000;
  }

  .lg\:border-s-transparent\/10 {
    border-inline-start-color: #0000001a;
  }

  .lg\:border-s-transparent\/100 {
    border-inline-start-color: #000;
  }

  .lg\:border-s-transparent\/15 {
    border-inline-start-color: #00000026;
  }

  .lg\:border-s-transparent\/20 {
    border-inline-start-color: #0003;
  }

  .lg\:border-s-transparent\/25 {
    border-inline-start-color: #00000040;
  }

  .lg\:border-s-transparent\/30 {
    border-inline-start-color: #0000004d;
  }

  .lg\:border-s-transparent\/35 {
    border-inline-start-color: #00000059;
  }

  .lg\:border-s-transparent\/40 {
    border-inline-start-color: #0006;
  }

  .lg\:border-s-transparent\/45 {
    border-inline-start-color: #00000073;
  }

  .lg\:border-s-transparent\/5 {
    border-inline-start-color: #0000000d;
  }

  .lg\:border-s-transparent\/50 {
    border-inline-start-color: #00000080;
  }

  .lg\:border-s-transparent\/55 {
    border-inline-start-color: #0000008c;
  }

  .lg\:border-s-transparent\/60 {
    border-inline-start-color: #0009;
  }

  .lg\:border-s-transparent\/65 {
    border-inline-start-color: #000000a6;
  }

  .lg\:border-s-transparent\/70 {
    border-inline-start-color: #000000b3;
  }

  .lg\:border-s-transparent\/75 {
    border-inline-start-color: #000000bf;
  }

  .lg\:border-s-transparent\/80 {
    border-inline-start-color: #000c;
  }

  .lg\:border-s-transparent\/85 {
    border-inline-start-color: #000000d9;
  }

  .lg\:border-s-transparent\/90 {
    border-inline-start-color: #000000e6;
  }

  .lg\:border-s-transparent\/95 {
    border-inline-start-color: #000000f2;
  }

  .lg\:border-s-warning {
    border-inline-start-color: var(--color-warning-300);
  }

  .lg\:border-s-warning-100 {
    border-inline-start-color: var(--color-warning-100);
  }

  .lg\:border-s-warning-200 {
    border-inline-start-color: var(--color-warning-200);
  }

  .lg\:border-s-warning-300 {
    border-inline-start-color: var(--color-warning-300);
  }

  .lg\:border-s-warning-400 {
    border-inline-start-color: var(--color-warning-400);
  }

  .lg\:border-s-white {
    border-inline-start-color: var(--color-white);
  }

  .lg\:border-t-black {
    border-top-color: var(--color-black);
  }

  .lg\:border-t-current {
    border-top-color: currentColor;
  }

  .lg\:border-t-danger {
    border-top-color: var(--color-danger-300);
  }

  .lg\:border-t-danger-100 {
    border-top-color: var(--color-danger-100);
  }

  .lg\:border-t-danger-200 {
    border-top-color: var(--color-danger-200);
  }

  .lg\:border-t-danger-300 {
    border-top-color: var(--color-danger-300);
  }

  .lg\:border-t-danger-400 {
    border-top-color: var(--color-danger-400);
  }

  .lg\:border-t-divider {
    border-top-color: var(--color-divider);
  }

  .lg\:border-t-gray {
    border-top-color: var(--color-gray);
  }

  .lg\:border-t-info {
    border-top-color: var(--color-info-300);
  }

  .lg\:border-t-info-100 {
    border-top-color: var(--color-info-100);
  }

  .lg\:border-t-info-200 {
    border-top-color: var(--color-info-200);
  }

  .lg\:border-t-info-300 {
    border-top-color: var(--color-info-300);
  }

  .lg\:border-t-info-400 {
    border-top-color: var(--color-info-400);
  }

  .lg\:border-t-primary {
    border-top-color: var(--color-primary-300);
  }

  .lg\:border-t-primary-100 {
    border-top-color: var(--color-primary-100);
  }

  .lg\:border-t-primary-200 {
    border-top-color: var(--color-primary-200);
  }

  .lg\:border-t-primary-250 {
    border-top-color: var(--color-primary-250);
  }

  .lg\:border-t-primary-300 {
    border-top-color: var(--color-primary-300);
  }

  .lg\:border-t-primary-400 {
    border-top-color: var(--color-primary-400);
  }

  .lg\:border-t-primary-500 {
    border-top-color: var(--color-primary-500);
  }

  .lg\:border-t-primary-600 {
    border-top-color: var(--color-primary-600);
  }

  .lg\:border-t-success {
    border-top-color: var(--color-success-300);
  }

  .lg\:border-t-success-100 {
    border-top-color: var(--color-success-100);
  }

  .lg\:border-t-success-200 {
    border-top-color: var(--color-success-200);
  }

  .lg\:border-t-success-300 {
    border-top-color: var(--color-success-300);
  }

  .lg\:border-t-success-400 {
    border-top-color: var(--color-success-400);
  }

  .lg\:border-t-transparent, .lg\:border-t-transparent\/0 {
    border-top-color: #0000;
  }

  .lg\:border-t-transparent\/10 {
    border-top-color: #0000001a;
  }

  .lg\:border-t-transparent\/100 {
    border-top-color: #000;
  }

  .lg\:border-t-transparent\/15 {
    border-top-color: #00000026;
  }

  .lg\:border-t-transparent\/20 {
    border-top-color: #0003;
  }

  .lg\:border-t-transparent\/25 {
    border-top-color: #00000040;
  }

  .lg\:border-t-transparent\/30 {
    border-top-color: #0000004d;
  }

  .lg\:border-t-transparent\/35 {
    border-top-color: #00000059;
  }

  .lg\:border-t-transparent\/40 {
    border-top-color: #0006;
  }

  .lg\:border-t-transparent\/45 {
    border-top-color: #00000073;
  }

  .lg\:border-t-transparent\/5 {
    border-top-color: #0000000d;
  }

  .lg\:border-t-transparent\/50 {
    border-top-color: #00000080;
  }

  .lg\:border-t-transparent\/55 {
    border-top-color: #0000008c;
  }

  .lg\:border-t-transparent\/60 {
    border-top-color: #0009;
  }

  .lg\:border-t-transparent\/65 {
    border-top-color: #000000a6;
  }

  .lg\:border-t-transparent\/70 {
    border-top-color: #000000b3;
  }

  .lg\:border-t-transparent\/75 {
    border-top-color: #000000bf;
  }

  .lg\:border-t-transparent\/80 {
    border-top-color: #000c;
  }

  .lg\:border-t-transparent\/85 {
    border-top-color: #000000d9;
  }

  .lg\:border-t-transparent\/90 {
    border-top-color: #000000e6;
  }

  .lg\:border-t-transparent\/95 {
    border-top-color: #000000f2;
  }

  .lg\:border-t-warning {
    border-top-color: var(--color-warning-300);
  }

  .lg\:border-t-warning-100 {
    border-top-color: var(--color-warning-100);
  }

  .lg\:border-t-warning-200 {
    border-top-color: var(--color-warning-200);
  }

  .lg\:border-t-warning-300 {
    border-top-color: var(--color-warning-300);
  }

  .lg\:border-t-warning-400 {
    border-top-color: var(--color-warning-400);
  }

  .lg\:border-t-white {
    border-top-color: var(--color-white);
  }

  .lg\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .lg\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .lg\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .lg\:border-opacity-15 {
    --tw-border-opacity: .15;
  }

  .lg\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .lg\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .lg\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .lg\:border-opacity-35 {
    --tw-border-opacity: .35;
  }

  .lg\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .lg\:border-opacity-45 {
    --tw-border-opacity: .45;
  }

  .lg\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .lg\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .lg\:border-opacity-55 {
    --tw-border-opacity: .55;
  }

  .lg\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .lg\:border-opacity-65 {
    --tw-border-opacity: .65;
  }

  .lg\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .lg\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .lg\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .lg\:border-opacity-85 {
    --tw-border-opacity: .85;
  }

  .lg\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .lg\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .lg\:bg-black {
    background-color: var(--color-black);
  }

  .lg\:bg-current {
    background-color: currentColor;
  }

  .lg\:bg-danger {
    background-color: var(--color-danger-300);
  }

  .lg\:bg-danger-100 {
    background-color: var(--color-danger-100);
  }

  .lg\:bg-danger-200 {
    background-color: var(--color-danger-200);
  }

  .lg\:bg-danger-300 {
    background-color: var(--color-danger-300);
  }

  .lg\:bg-danger-400 {
    background-color: var(--color-danger-400);
  }

  .lg\:bg-divider {
    background-color: var(--color-divider);
  }

  .lg\:bg-gray {
    background-color: var(--color-gray);
  }

  .lg\:bg-info {
    background-color: var(--color-info-300);
  }

  .lg\:bg-info-100 {
    background-color: var(--color-info-100);
  }

  .lg\:bg-info-200 {
    background-color: var(--color-info-200);
  }

  .lg\:bg-info-300 {
    background-color: var(--color-info-300);
  }

  .lg\:bg-info-400 {
    background-color: var(--color-info-400);
  }

  .lg\:bg-primary {
    background-color: var(--color-primary-300);
  }

  .lg\:bg-primary-100 {
    background-color: var(--color-primary-100);
  }

  .lg\:bg-primary-200 {
    background-color: var(--color-primary-200);
  }

  .lg\:bg-primary-250 {
    background-color: var(--color-primary-250);
  }

  .lg\:bg-primary-300 {
    background-color: var(--color-primary-300);
  }

  .lg\:bg-primary-400 {
    background-color: var(--color-primary-400);
  }

  .lg\:bg-primary-500 {
    background-color: var(--color-primary-500);
  }

  .lg\:bg-primary-600 {
    background-color: var(--color-primary-600);
  }

  .lg\:bg-success {
    background-color: var(--color-success-300);
  }

  .lg\:bg-success-100 {
    background-color: var(--color-success-100);
  }

  .lg\:bg-success-200 {
    background-color: var(--color-success-200);
  }

  .lg\:bg-success-300 {
    background-color: var(--color-success-300);
  }

  .lg\:bg-success-400 {
    background-color: var(--color-success-400);
  }

  .lg\:bg-transparent, .lg\:bg-transparent\/0 {
    background-color: #0000;
  }

  .lg\:bg-transparent\/10 {
    background-color: #0000001a;
  }

  .lg\:bg-transparent\/100 {
    background-color: #000;
  }

  .lg\:bg-transparent\/15 {
    background-color: #00000026;
  }

  .lg\:bg-transparent\/20 {
    background-color: #0003;
  }

  .lg\:bg-transparent\/25 {
    background-color: #00000040;
  }

  .lg\:bg-transparent\/30 {
    background-color: #0000004d;
  }

  .lg\:bg-transparent\/35 {
    background-color: #00000059;
  }

  .lg\:bg-transparent\/40 {
    background-color: #0006;
  }

  .lg\:bg-transparent\/45 {
    background-color: #00000073;
  }

  .lg\:bg-transparent\/5 {
    background-color: #0000000d;
  }

  .lg\:bg-transparent\/50 {
    background-color: #00000080;
  }

  .lg\:bg-transparent\/55 {
    background-color: #0000008c;
  }

  .lg\:bg-transparent\/60 {
    background-color: #0009;
  }

  .lg\:bg-transparent\/65 {
    background-color: #000000a6;
  }

  .lg\:bg-transparent\/70 {
    background-color: #000000b3;
  }

  .lg\:bg-transparent\/75 {
    background-color: #000000bf;
  }

  .lg\:bg-transparent\/80 {
    background-color: #000c;
  }

  .lg\:bg-transparent\/85 {
    background-color: #000000d9;
  }

  .lg\:bg-transparent\/90 {
    background-color: #000000e6;
  }

  .lg\:bg-transparent\/95 {
    background-color: #000000f2;
  }

  .lg\:bg-warning {
    background-color: var(--color-warning-300);
  }

  .lg\:bg-warning-100 {
    background-color: var(--color-warning-100);
  }

  .lg\:bg-warning-200 {
    background-color: var(--color-warning-200);
  }

  .lg\:bg-warning-300 {
    background-color: var(--color-warning-300);
  }

  .lg\:bg-warning-400 {
    background-color: var(--color-warning-400);
  }

  .lg\:bg-white {
    background-color: var(--color-white);
  }

  .lg\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .lg\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .lg\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .lg\:bg-opacity-15 {
    --tw-bg-opacity: .15;
  }

  .lg\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .lg\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .lg\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .lg\:bg-opacity-35 {
    --tw-bg-opacity: .35;
  }

  .lg\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .lg\:bg-opacity-45 {
    --tw-bg-opacity: .45;
  }

  .lg\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .lg\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .lg\:bg-opacity-55 {
    --tw-bg-opacity: .55;
  }

  .lg\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .lg\:bg-opacity-65 {
    --tw-bg-opacity: .65;
  }

  .lg\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .lg\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .lg\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .lg\:bg-opacity-85 {
    --tw-bg-opacity: .85;
  }

  .lg\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .lg\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .lg\:bg-none {
    background-image: none;
  }

  .lg\:from-black {
    --tw-gradient-from: var(--color-black) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-current {
    --tw-gradient-from: currentColor var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-danger {
    --tw-gradient-from: var(--color-danger-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-danger-100 {
    --tw-gradient-from: var(--color-danger-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-danger-200 {
    --tw-gradient-from: var(--color-danger-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-danger-300 {
    --tw-gradient-from: var(--color-danger-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-danger-400 {
    --tw-gradient-from: var(--color-danger-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-divider {
    --tw-gradient-from: var(--color-divider) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-gray {
    --tw-gradient-from: var(--color-gray) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-info {
    --tw-gradient-from: var(--color-info-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-info-100 {
    --tw-gradient-from: var(--color-info-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-info-200 {
    --tw-gradient-from: var(--color-info-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-info-300 {
    --tw-gradient-from: var(--color-info-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-info-400 {
    --tw-gradient-from: var(--color-info-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-primary {
    --tw-gradient-from: var(--color-primary-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-primary-100 {
    --tw-gradient-from: var(--color-primary-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-primary-200 {
    --tw-gradient-from: var(--color-primary-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-primary-250 {
    --tw-gradient-from: var(--color-primary-250) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-primary-300 {
    --tw-gradient-from: var(--color-primary-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-primary-400 {
    --tw-gradient-from: var(--color-primary-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-primary-500 {
    --tw-gradient-from: var(--color-primary-500) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-primary-600 {
    --tw-gradient-from: var(--color-primary-600) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-success {
    --tw-gradient-from: var(--color-success-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-success-100 {
    --tw-gradient-from: var(--color-success-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-success-200 {
    --tw-gradient-from: var(--color-success-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-success-300 {
    --tw-gradient-from: var(--color-success-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-success-400 {
    --tw-gradient-from: var(--color-success-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent {
    --tw-gradient-from: transparent var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/0 {
    --tw-gradient-from: #0000 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/10 {
    --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/100 {
    --tw-gradient-from: #000 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/15 {
    --tw-gradient-from: #00000026 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/20 {
    --tw-gradient-from: #0003 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/25 {
    --tw-gradient-from: #00000040 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/30 {
    --tw-gradient-from: #0000004d var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/35 {
    --tw-gradient-from: #00000059 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/40 {
    --tw-gradient-from: #0006 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/45 {
    --tw-gradient-from: #00000073 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/5 {
    --tw-gradient-from: #0000000d var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/50 {
    --tw-gradient-from: #00000080 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/55 {
    --tw-gradient-from: #0000008c var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/60 {
    --tw-gradient-from: #0009 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/65 {
    --tw-gradient-from: #000000a6 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/70 {
    --tw-gradient-from: #000000b3 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/75 {
    --tw-gradient-from: #000000bf var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/80 {
    --tw-gradient-from: #000c var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/85 {
    --tw-gradient-from: #000000d9 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/90 {
    --tw-gradient-from: #000000e6 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-transparent\/95 {
    --tw-gradient-from: #000000f2 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-warning {
    --tw-gradient-from: var(--color-warning-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-warning-100 {
    --tw-gradient-from: var(--color-warning-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-warning-200 {
    --tw-gradient-from: var(--color-warning-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-warning-300 {
    --tw-gradient-from: var(--color-warning-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-warning-400 {
    --tw-gradient-from: var(--color-warning-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-white {
    --tw-gradient-from: var(--color-white) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-0\% {
    --tw-gradient-from-position: 0%;
  }

  .lg\:from-10\% {
    --tw-gradient-from-position: 10%;
  }

  .lg\:from-100\% {
    --tw-gradient-from-position: 100%;
  }

  .lg\:from-15\% {
    --tw-gradient-from-position: 15%;
  }

  .lg\:from-20\% {
    --tw-gradient-from-position: 20%;
  }

  .lg\:from-25\% {
    --tw-gradient-from-position: 25%;
  }

  .lg\:from-30\% {
    --tw-gradient-from-position: 30%;
  }

  .lg\:from-35\% {
    --tw-gradient-from-position: 35%;
  }

  .lg\:from-40\% {
    --tw-gradient-from-position: 40%;
  }

  .lg\:from-45\% {
    --tw-gradient-from-position: 45%;
  }

  .lg\:from-5\% {
    --tw-gradient-from-position: 5%;
  }

  .lg\:from-50\% {
    --tw-gradient-from-position: 50%;
  }

  .lg\:from-55\% {
    --tw-gradient-from-position: 55%;
  }

  .lg\:from-60\% {
    --tw-gradient-from-position: 60%;
  }

  .lg\:from-65\% {
    --tw-gradient-from-position: 65%;
  }

  .lg\:from-70\% {
    --tw-gradient-from-position: 70%;
  }

  .lg\:from-75\% {
    --tw-gradient-from-position: 75%;
  }

  .lg\:from-80\% {
    --tw-gradient-from-position: 80%;
  }

  .lg\:from-85\% {
    --tw-gradient-from-position: 85%;
  }

  .lg\:from-90\% {
    --tw-gradient-from-position: 90%;
  }

  .lg\:from-95\% {
    --tw-gradient-from-position: 95%;
  }

  .lg\:via-black {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-black) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-current {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), currentColor var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-danger {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-danger-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-danger-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-danger-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-danger-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-divider {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-divider) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-gray {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-gray) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-info {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-info-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-info-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-info-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-info-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-primary {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-primary-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-primary-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-primary-250 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-250) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-primary-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-primary-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-primary-500 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-500) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-primary-600 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-600) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-success {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-success-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-success-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-success-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-success-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/0 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/10 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000001a var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/100 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/15 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000026 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/20 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0003 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/25 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000040 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/30 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000004d var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/35 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000059 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/40 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0006 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/45 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000073 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/5 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000000d var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/50 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000080 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/55 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000008c var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/60 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0009 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/65 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000a6 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/70 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/75 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000bf var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/80 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000c var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/85 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000d9 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/90 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-transparent\/95 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-warning {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-warning-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-warning-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-warning-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-warning-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-white {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-white) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .lg\:via-0\% {
    --tw-gradient-via-position: 0%;
  }

  .lg\:via-10\% {
    --tw-gradient-via-position: 10%;
  }

  .lg\:via-100\% {
    --tw-gradient-via-position: 100%;
  }

  .lg\:via-15\% {
    --tw-gradient-via-position: 15%;
  }

  .lg\:via-20\% {
    --tw-gradient-via-position: 20%;
  }

  .lg\:via-25\% {
    --tw-gradient-via-position: 25%;
  }

  .lg\:via-30\% {
    --tw-gradient-via-position: 30%;
  }

  .lg\:via-35\% {
    --tw-gradient-via-position: 35%;
  }

  .lg\:via-40\% {
    --tw-gradient-via-position: 40%;
  }

  .lg\:via-45\% {
    --tw-gradient-via-position: 45%;
  }

  .lg\:via-5\% {
    --tw-gradient-via-position: 5%;
  }

  .lg\:via-50\% {
    --tw-gradient-via-position: 50%;
  }

  .lg\:via-55\% {
    --tw-gradient-via-position: 55%;
  }

  .lg\:via-60\% {
    --tw-gradient-via-position: 60%;
  }

  .lg\:via-65\% {
    --tw-gradient-via-position: 65%;
  }

  .lg\:via-70\% {
    --tw-gradient-via-position: 70%;
  }

  .lg\:via-75\% {
    --tw-gradient-via-position: 75%;
  }

  .lg\:via-80\% {
    --tw-gradient-via-position: 80%;
  }

  .lg\:via-85\% {
    --tw-gradient-via-position: 85%;
  }

  .lg\:via-90\% {
    --tw-gradient-via-position: 90%;
  }

  .lg\:via-95\% {
    --tw-gradient-via-position: 95%;
  }

  .lg\:to-black {
    --tw-gradient-to: var(--color-black) var(--tw-gradient-to-position);
  }

  .lg\:to-current {
    --tw-gradient-to: currentColor var(--tw-gradient-to-position);
  }

  .lg\:to-danger {
    --tw-gradient-to: var(--color-danger-300) var(--tw-gradient-to-position);
  }

  .lg\:to-danger-100 {
    --tw-gradient-to: var(--color-danger-100) var(--tw-gradient-to-position);
  }

  .lg\:to-danger-200 {
    --tw-gradient-to: var(--color-danger-200) var(--tw-gradient-to-position);
  }

  .lg\:to-danger-300 {
    --tw-gradient-to: var(--color-danger-300) var(--tw-gradient-to-position);
  }

  .lg\:to-danger-400 {
    --tw-gradient-to: var(--color-danger-400) var(--tw-gradient-to-position);
  }

  .lg\:to-divider {
    --tw-gradient-to: var(--color-divider) var(--tw-gradient-to-position);
  }

  .lg\:to-gray {
    --tw-gradient-to: var(--color-gray) var(--tw-gradient-to-position);
  }

  .lg\:to-info {
    --tw-gradient-to: var(--color-info-300) var(--tw-gradient-to-position);
  }

  .lg\:to-info-100 {
    --tw-gradient-to: var(--color-info-100) var(--tw-gradient-to-position);
  }

  .lg\:to-info-200 {
    --tw-gradient-to: var(--color-info-200) var(--tw-gradient-to-position);
  }

  .lg\:to-info-300 {
    --tw-gradient-to: var(--color-info-300) var(--tw-gradient-to-position);
  }

  .lg\:to-info-400 {
    --tw-gradient-to: var(--color-info-400) var(--tw-gradient-to-position);
  }

  .lg\:to-primary {
    --tw-gradient-to: var(--color-primary-300) var(--tw-gradient-to-position);
  }

  .lg\:to-primary-100 {
    --tw-gradient-to: var(--color-primary-100) var(--tw-gradient-to-position);
  }

  .lg\:to-primary-200 {
    --tw-gradient-to: var(--color-primary-200) var(--tw-gradient-to-position);
  }

  .lg\:to-primary-250 {
    --tw-gradient-to: var(--color-primary-250) var(--tw-gradient-to-position);
  }

  .lg\:to-primary-300 {
    --tw-gradient-to: var(--color-primary-300) var(--tw-gradient-to-position);
  }

  .lg\:to-primary-400 {
    --tw-gradient-to: var(--color-primary-400) var(--tw-gradient-to-position);
  }

  .lg\:to-primary-500 {
    --tw-gradient-to: var(--color-primary-500) var(--tw-gradient-to-position);
  }

  .lg\:to-primary-600 {
    --tw-gradient-to: var(--color-primary-600) var(--tw-gradient-to-position);
  }

  .lg\:to-success {
    --tw-gradient-to: var(--color-success-300) var(--tw-gradient-to-position);
  }

  .lg\:to-success-100 {
    --tw-gradient-to: var(--color-success-100) var(--tw-gradient-to-position);
  }

  .lg\:to-success-200 {
    --tw-gradient-to: var(--color-success-200) var(--tw-gradient-to-position);
  }

  .lg\:to-success-300 {
    --tw-gradient-to: var(--color-success-300) var(--tw-gradient-to-position);
  }

  .lg\:to-success-400 {
    --tw-gradient-to: var(--color-success-400) var(--tw-gradient-to-position);
  }

  .lg\:to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/0 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/10 {
    --tw-gradient-to: #0000001a var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/100 {
    --tw-gradient-to: #000 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/15 {
    --tw-gradient-to: #00000026 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/20 {
    --tw-gradient-to: #0003 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/25 {
    --tw-gradient-to: #00000040 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/30 {
    --tw-gradient-to: #0000004d var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/35 {
    --tw-gradient-to: #00000059 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/40 {
    --tw-gradient-to: #0006 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/45 {
    --tw-gradient-to: #00000073 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/5 {
    --tw-gradient-to: #0000000d var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/50 {
    --tw-gradient-to: #00000080 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/55 {
    --tw-gradient-to: #0000008c var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/60 {
    --tw-gradient-to: #0009 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/65 {
    --tw-gradient-to: #000000a6 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/70 {
    --tw-gradient-to: #000000b3 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/75 {
    --tw-gradient-to: #000000bf var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/80 {
    --tw-gradient-to: #000c var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/85 {
    --tw-gradient-to: #000000d9 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/90 {
    --tw-gradient-to: #000000e6 var(--tw-gradient-to-position);
  }

  .lg\:to-transparent\/95 {
    --tw-gradient-to: #000000f2 var(--tw-gradient-to-position);
  }

  .lg\:to-warning {
    --tw-gradient-to: var(--color-warning-300) var(--tw-gradient-to-position);
  }

  .lg\:to-warning-100 {
    --tw-gradient-to: var(--color-warning-100) var(--tw-gradient-to-position);
  }

  .lg\:to-warning-200 {
    --tw-gradient-to: var(--color-warning-200) var(--tw-gradient-to-position);
  }

  .lg\:to-warning-300 {
    --tw-gradient-to: var(--color-warning-300) var(--tw-gradient-to-position);
  }

  .lg\:to-warning-400 {
    --tw-gradient-to: var(--color-warning-400) var(--tw-gradient-to-position);
  }

  .lg\:to-white {
    --tw-gradient-to: var(--color-white) var(--tw-gradient-to-position);
  }

  .lg\:to-0\% {
    --tw-gradient-to-position: 0%;
  }

  .lg\:to-10\% {
    --tw-gradient-to-position: 10%;
  }

  .lg\:to-100\% {
    --tw-gradient-to-position: 100%;
  }

  .lg\:to-15\% {
    --tw-gradient-to-position: 15%;
  }

  .lg\:to-20\% {
    --tw-gradient-to-position: 20%;
  }

  .lg\:to-25\% {
    --tw-gradient-to-position: 25%;
  }

  .lg\:to-30\% {
    --tw-gradient-to-position: 30%;
  }

  .lg\:to-35\% {
    --tw-gradient-to-position: 35%;
  }

  .lg\:to-40\% {
    --tw-gradient-to-position: 40%;
  }

  .lg\:to-45\% {
    --tw-gradient-to-position: 45%;
  }

  .lg\:to-5\% {
    --tw-gradient-to-position: 5%;
  }

  .lg\:to-50\% {
    --tw-gradient-to-position: 50%;
  }

  .lg\:to-55\% {
    --tw-gradient-to-position: 55%;
  }

  .lg\:to-60\% {
    --tw-gradient-to-position: 60%;
  }

  .lg\:to-65\% {
    --tw-gradient-to-position: 65%;
  }

  .lg\:to-70\% {
    --tw-gradient-to-position: 70%;
  }

  .lg\:to-75\% {
    --tw-gradient-to-position: 75%;
  }

  .lg\:to-80\% {
    --tw-gradient-to-position: 80%;
  }

  .lg\:to-85\% {
    --tw-gradient-to-position: 85%;
  }

  .lg\:to-90\% {
    --tw-gradient-to-position: 90%;
  }

  .lg\:to-95\% {
    --tw-gradient-to-position: 95%;
  }

  .lg\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .lg\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .lg\:box-decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .lg\:box-decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .lg\:bg-auto {
    background-size: auto;
  }

  .lg\:bg-contain {
    background-size: contain;
  }

  .lg\:bg-cover {
    background-size: cover;
  }

  .lg\:bg-fixed {
    background-attachment: fixed;
  }

  .lg\:bg-local {
    background-attachment: local;
  }

  .lg\:bg-scroll {
    background-attachment: scroll;
  }

  .lg\:bg-clip-border {
    background-clip: border-box;
  }

  .lg\:bg-clip-padding {
    background-clip: padding-box;
  }

  .lg\:bg-clip-content {
    background-clip: content-box;
  }

  .lg\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .lg\:bg-bottom {
    background-position: bottom;
  }

  .lg\:bg-center {
    background-position: center;
  }

  .lg\:bg-left {
    background-position: 0;
  }

  .lg\:bg-left-bottom {
    background-position: 0 100%;
  }

  .lg\:bg-left-top {
    background-position: 0 0;
  }

  .lg\:bg-right {
    background-position: 100%;
  }

  .lg\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .lg\:bg-right-top {
    background-position: 100% 0;
  }

  .lg\:bg-top {
    background-position: top;
  }

  .lg\:bg-repeat {
    background-repeat: repeat;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .lg\:bg-repeat-round {
    background-repeat: round;
  }

  .lg\:bg-repeat-space {
    background-repeat: space;
  }

  .lg\:bg-origin-border {
    background-origin: border-box;
  }

  .lg\:bg-origin-padding {
    background-origin: padding-box;
  }

  .lg\:bg-origin-content {
    background-origin: content-box;
  }

  .lg\:fill-black {
    fill: var(--color-black);
  }

  .lg\:fill-current {
    fill: currentColor;
  }

  .lg\:fill-danger {
    fill: var(--color-danger-300);
  }

  .lg\:fill-danger-100 {
    fill: var(--color-danger-100);
  }

  .lg\:fill-danger-200 {
    fill: var(--color-danger-200);
  }

  .lg\:fill-danger-300 {
    fill: var(--color-danger-300);
  }

  .lg\:fill-danger-400 {
    fill: var(--color-danger-400);
  }

  .lg\:fill-divider {
    fill: var(--color-divider);
  }

  .lg\:fill-gray {
    fill: var(--color-gray);
  }

  .lg\:fill-info {
    fill: var(--color-info-300);
  }

  .lg\:fill-info-100 {
    fill: var(--color-info-100);
  }

  .lg\:fill-info-200 {
    fill: var(--color-info-200);
  }

  .lg\:fill-info-300 {
    fill: var(--color-info-300);
  }

  .lg\:fill-info-400 {
    fill: var(--color-info-400);
  }

  .lg\:fill-none {
    fill: none;
  }

  .lg\:fill-primary {
    fill: var(--color-primary-300);
  }

  .lg\:fill-primary-100 {
    fill: var(--color-primary-100);
  }

  .lg\:fill-primary-200 {
    fill: var(--color-primary-200);
  }

  .lg\:fill-primary-250 {
    fill: var(--color-primary-250);
  }

  .lg\:fill-primary-300 {
    fill: var(--color-primary-300);
  }

  .lg\:fill-primary-400 {
    fill: var(--color-primary-400);
  }

  .lg\:fill-primary-500 {
    fill: var(--color-primary-500);
  }

  .lg\:fill-primary-600 {
    fill: var(--color-primary-600);
  }

  .lg\:fill-success {
    fill: var(--color-success-300);
  }

  .lg\:fill-success-100 {
    fill: var(--color-success-100);
  }

  .lg\:fill-success-200 {
    fill: var(--color-success-200);
  }

  .lg\:fill-success-300 {
    fill: var(--color-success-300);
  }

  .lg\:fill-success-400 {
    fill: var(--color-success-400);
  }

  .lg\:fill-transparent, .lg\:fill-transparent\/0 {
    fill: #0000;
  }

  .lg\:fill-transparent\/10 {
    fill: #0000001a;
  }

  .lg\:fill-transparent\/100 {
    fill: #000;
  }

  .lg\:fill-transparent\/15 {
    fill: #00000026;
  }

  .lg\:fill-transparent\/20 {
    fill: #0003;
  }

  .lg\:fill-transparent\/25 {
    fill: #00000040;
  }

  .lg\:fill-transparent\/30 {
    fill: #0000004d;
  }

  .lg\:fill-transparent\/35 {
    fill: #00000059;
  }

  .lg\:fill-transparent\/40 {
    fill: #0006;
  }

  .lg\:fill-transparent\/45 {
    fill: #00000073;
  }

  .lg\:fill-transparent\/5 {
    fill: #0000000d;
  }

  .lg\:fill-transparent\/50 {
    fill: #00000080;
  }

  .lg\:fill-transparent\/55 {
    fill: #0000008c;
  }

  .lg\:fill-transparent\/60 {
    fill: #0009;
  }

  .lg\:fill-transparent\/65 {
    fill: #000000a6;
  }

  .lg\:fill-transparent\/70 {
    fill: #000000b3;
  }

  .lg\:fill-transparent\/75 {
    fill: #000000bf;
  }

  .lg\:fill-transparent\/80 {
    fill: #000c;
  }

  .lg\:fill-transparent\/85 {
    fill: #000000d9;
  }

  .lg\:fill-transparent\/90 {
    fill: #000000e6;
  }

  .lg\:fill-transparent\/95 {
    fill: #000000f2;
  }

  .lg\:fill-warning {
    fill: var(--color-warning-300);
  }

  .lg\:fill-warning-100 {
    fill: var(--color-warning-100);
  }

  .lg\:fill-warning-200 {
    fill: var(--color-warning-200);
  }

  .lg\:fill-warning-300 {
    fill: var(--color-warning-300);
  }

  .lg\:fill-warning-400 {
    fill: var(--color-warning-400);
  }

  .lg\:fill-white {
    fill: var(--color-white);
  }

  .lg\:stroke-black {
    stroke: var(--color-black);
  }

  .lg\:stroke-current {
    stroke: currentColor;
  }

  .lg\:stroke-danger {
    stroke: var(--color-danger-300);
  }

  .lg\:stroke-danger-100 {
    stroke: var(--color-danger-100);
  }

  .lg\:stroke-danger-200 {
    stroke: var(--color-danger-200);
  }

  .lg\:stroke-danger-300 {
    stroke: var(--color-danger-300);
  }

  .lg\:stroke-danger-400 {
    stroke: var(--color-danger-400);
  }

  .lg\:stroke-divider {
    stroke: var(--color-divider);
  }

  .lg\:stroke-gray {
    stroke: var(--color-gray);
  }

  .lg\:stroke-info {
    stroke: var(--color-info-300);
  }

  .lg\:stroke-info-100 {
    stroke: var(--color-info-100);
  }

  .lg\:stroke-info-200 {
    stroke: var(--color-info-200);
  }

  .lg\:stroke-info-300 {
    stroke: var(--color-info-300);
  }

  .lg\:stroke-info-400 {
    stroke: var(--color-info-400);
  }

  .lg\:stroke-none {
    stroke: none;
  }

  .lg\:stroke-primary {
    stroke: var(--color-primary-300);
  }

  .lg\:stroke-primary-100 {
    stroke: var(--color-primary-100);
  }

  .lg\:stroke-primary-200 {
    stroke: var(--color-primary-200);
  }

  .lg\:stroke-primary-250 {
    stroke: var(--color-primary-250);
  }

  .lg\:stroke-primary-300 {
    stroke: var(--color-primary-300);
  }

  .lg\:stroke-primary-400 {
    stroke: var(--color-primary-400);
  }

  .lg\:stroke-primary-500 {
    stroke: var(--color-primary-500);
  }

  .lg\:stroke-primary-600 {
    stroke: var(--color-primary-600);
  }

  .lg\:stroke-success {
    stroke: var(--color-success-300);
  }

  .lg\:stroke-success-100 {
    stroke: var(--color-success-100);
  }

  .lg\:stroke-success-200 {
    stroke: var(--color-success-200);
  }

  .lg\:stroke-success-300 {
    stroke: var(--color-success-300);
  }

  .lg\:stroke-success-400 {
    stroke: var(--color-success-400);
  }

  .lg\:stroke-transparent, .lg\:stroke-transparent\/0 {
    stroke: #0000;
  }

  .lg\:stroke-transparent\/10 {
    stroke: #0000001a;
  }

  .lg\:stroke-transparent\/100 {
    stroke: #000;
  }

  .lg\:stroke-transparent\/15 {
    stroke: #00000026;
  }

  .lg\:stroke-transparent\/20 {
    stroke: #0003;
  }

  .lg\:stroke-transparent\/25 {
    stroke: #00000040;
  }

  .lg\:stroke-transparent\/30 {
    stroke: #0000004d;
  }

  .lg\:stroke-transparent\/35 {
    stroke: #00000059;
  }

  .lg\:stroke-transparent\/40 {
    stroke: #0006;
  }

  .lg\:stroke-transparent\/45 {
    stroke: #00000073;
  }

  .lg\:stroke-transparent\/5 {
    stroke: #0000000d;
  }

  .lg\:stroke-transparent\/50 {
    stroke: #00000080;
  }

  .lg\:stroke-transparent\/55 {
    stroke: #0000008c;
  }

  .lg\:stroke-transparent\/60 {
    stroke: #0009;
  }

  .lg\:stroke-transparent\/65 {
    stroke: #000000a6;
  }

  .lg\:stroke-transparent\/70 {
    stroke: #000000b3;
  }

  .lg\:stroke-transparent\/75 {
    stroke: #000000bf;
  }

  .lg\:stroke-transparent\/80 {
    stroke: #000c;
  }

  .lg\:stroke-transparent\/85 {
    stroke: #000000d9;
  }

  .lg\:stroke-transparent\/90 {
    stroke: #000000e6;
  }

  .lg\:stroke-transparent\/95 {
    stroke: #000000f2;
  }

  .lg\:stroke-warning {
    stroke: var(--color-warning-300);
  }

  .lg\:stroke-warning-100 {
    stroke: var(--color-warning-100);
  }

  .lg\:stroke-warning-200 {
    stroke: var(--color-warning-200);
  }

  .lg\:stroke-warning-300 {
    stroke: var(--color-warning-300);
  }

  .lg\:stroke-warning-400 {
    stroke: var(--color-warning-400);
  }

  .lg\:stroke-white {
    stroke: var(--color-white);
  }

  .lg\:stroke-0 {
    stroke-width: 0;
  }

  .lg\:stroke-1 {
    stroke-width: 1px;
  }

  .lg\:stroke-2 {
    stroke-width: 2px;
  }

  .lg\:object-contain {
    object-fit: contain;
  }

  .lg\:object-cover {
    object-fit: cover;
  }

  .lg\:object-fill {
    object-fit: fill;
  }

  .lg\:object-none {
    object-fit: none;
  }

  .lg\:object-scale-down {
    object-fit: scale-down;
  }

  .lg\:object-bottom {
    object-position: bottom;
  }

  .lg\:object-center {
    object-position: center;
  }

  .lg\:object-left {
    object-position: left;
  }

  .lg\:object-left-bottom {
    object-position: left bottom;
  }

  .lg\:object-left-top {
    object-position: left top;
  }

  .lg\:object-right {
    object-position: right;
  }

  .lg\:object-right-bottom {
    object-position: right bottom;
  }

  .lg\:object-right-top {
    object-position: right top;
  }

  .lg\:object-top {
    object-position: top;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-0\.5 {
    padding: .125rem;
  }

  .lg\:p-1 {
    padding: .25rem;
  }

  .lg\:p-1\.5 {
    padding: .375rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-11 {
    padding: 2.75rem;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:p-14 {
    padding: 3.5rem;
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:p-2 {
    padding: .5rem;
  }

  .lg\:p-2\.5 {
    padding: .625rem;
  }

  .lg\:p-20 {
    padding: 5rem;
  }

  .lg\:p-24 {
    padding: 6rem;
  }

  .lg\:p-28 {
    padding: 7rem;
  }

  .lg\:p-3 {
    padding: .75rem;
  }

  .lg\:p-3\.5 {
    padding: .875rem;
  }

  .lg\:p-32 {
    padding: 8rem;
  }

  .lg\:p-36 {
    padding: 9rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-40 {
    padding: 10rem;
  }

  .lg\:p-44 {
    padding: 11rem;
  }

  .lg\:p-48 {
    padding: 12rem;
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:p-52 {
    padding: 13rem;
  }

  .lg\:p-56 {
    padding: 14rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:p-60 {
    padding: 15rem;
  }

  .lg\:p-64 {
    padding: 16rem;
  }

  .lg\:p-7 {
    padding: 1.75rem;
  }

  .lg\:p-72 {
    padding: 18rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-80 {
    padding: 20rem;
  }

  .lg\:p-9 {
    padding: 2.25rem;
  }

  .lg\:p-96 {
    padding: 24rem;
  }

  .lg\:p-px {
    padding: 1px;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .lg\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .lg\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .lg\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .lg\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .lg\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .lg\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .lg\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .lg\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .lg\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .lg\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .lg\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .lg\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .lg\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .lg\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .lg\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .lg\:pb-1 {
    padding-bottom: .25rem;
  }

  .lg\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pb-2 {
    padding-bottom: .5rem;
  }

  .lg\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pb-28 {
    padding-bottom: 7rem;
  }

  .lg\:pb-3 {
    padding-bottom: .75rem;
  }

  .lg\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:pb-36 {
    padding-bottom: 9rem;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:pb-40 {
    padding-bottom: 10rem;
  }

  .lg\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:pb-48 {
    padding-bottom: 12rem;
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:pb-52 {
    padding-bottom: 13rem;
  }

  .lg\:pb-56 {
    padding-bottom: 14rem;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:pb-60 {
    padding-bottom: 15rem;
  }

  .lg\:pb-64 {
    padding-bottom: 16rem;
  }

  .lg\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .lg\:pb-72 {
    padding-bottom: 18rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pb-80 {
    padding-bottom: 20rem;
  }

  .lg\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .lg\:pb-96 {
    padding-bottom: 24rem;
  }

  .lg\:pb-px {
    padding-bottom: 1px;
  }

  .lg\:pe-0 {
    padding-inline-end: 0;
  }

  .lg\:pe-0\.5 {
    padding-inline-end: .125rem;
  }

  .lg\:pe-1 {
    padding-inline-end: .25rem;
  }

  .lg\:pe-1\.5 {
    padding-inline-end: .375rem;
  }

  .lg\:pe-10 {
    padding-inline-end: 2.5rem;
  }

  .lg\:pe-11 {
    padding-inline-end: 2.75rem;
  }

  .lg\:pe-12 {
    padding-inline-end: 3rem;
  }

  .lg\:pe-14 {
    padding-inline-end: 3.5rem;
  }

  .lg\:pe-16 {
    padding-inline-end: 4rem;
  }

  .lg\:pe-2 {
    padding-inline-end: .5rem;
  }

  .lg\:pe-2\.5 {
    padding-inline-end: .625rem;
  }

  .lg\:pe-20 {
    padding-inline-end: 5rem;
  }

  .lg\:pe-24 {
    padding-inline-end: 6rem;
  }

  .lg\:pe-28 {
    padding-inline-end: 7rem;
  }

  .lg\:pe-3 {
    padding-inline-end: .75rem;
  }

  .lg\:pe-3\.5 {
    padding-inline-end: .875rem;
  }

  .lg\:pe-32 {
    padding-inline-end: 8rem;
  }

  .lg\:pe-36 {
    padding-inline-end: 9rem;
  }

  .lg\:pe-4 {
    padding-inline-end: 1rem;
  }

  .lg\:pe-40 {
    padding-inline-end: 10rem;
  }

  .lg\:pe-44 {
    padding-inline-end: 11rem;
  }

  .lg\:pe-48 {
    padding-inline-end: 12rem;
  }

  .lg\:pe-5 {
    padding-inline-end: 1.25rem;
  }

  .lg\:pe-52 {
    padding-inline-end: 13rem;
  }

  .lg\:pe-56 {
    padding-inline-end: 14rem;
  }

  .lg\:pe-6 {
    padding-inline-end: 1.5rem;
  }

  .lg\:pe-60 {
    padding-inline-end: 15rem;
  }

  .lg\:pe-64 {
    padding-inline-end: 16rem;
  }

  .lg\:pe-7 {
    padding-inline-end: 1.75rem;
  }

  .lg\:pe-72 {
    padding-inline-end: 18rem;
  }

  .lg\:pe-8 {
    padding-inline-end: 2rem;
  }

  .lg\:pe-80 {
    padding-inline-end: 20rem;
  }

  .lg\:pe-9 {
    padding-inline-end: 2.25rem;
  }

  .lg\:pe-96 {
    padding-inline-end: 24rem;
  }

  .lg\:pe-px {
    padding-inline-end: 1px;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pl-0\.5 {
    padding-left: .125rem;
  }

  .lg\:pl-1 {
    padding-left: .25rem;
  }

  .lg\:pl-1\.5 {
    padding-left: .375rem;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:pl-11 {
    padding-left: 2.75rem;
  }

  .lg\:pl-12 {
    padding-left: 3rem;
  }

  .lg\:pl-14 {
    padding-left: 3.5rem;
  }

  .lg\:pl-16 {
    padding-left: 4rem;
  }

  .lg\:pl-2 {
    padding-left: .5rem;
  }

  .lg\:pl-2\.5 {
    padding-left: .625rem;
  }

  .lg\:pl-20 {
    padding-left: 5rem;
  }

  .lg\:pl-24 {
    padding-left: 6rem;
  }

  .lg\:pl-28 {
    padding-left: 7rem;
  }

  .lg\:pl-3 {
    padding-left: .75rem;
  }

  .lg\:pl-3\.5 {
    padding-left: .875rem;
  }

  .lg\:pl-32 {
    padding-left: 8rem;
  }

  .lg\:pl-36 {
    padding-left: 9rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pl-40 {
    padding-left: 10rem;
  }

  .lg\:pl-44 {
    padding-left: 11rem;
  }

  .lg\:pl-48 {
    padding-left: 12rem;
  }

  .lg\:pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:pl-52 {
    padding-left: 13rem;
  }

  .lg\:pl-56 {
    padding-left: 14rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pl-60 {
    padding-left: 15rem;
  }

  .lg\:pl-64 {
    padding-left: 16rem;
  }

  .lg\:pl-7 {
    padding-left: 1.75rem;
  }

  .lg\:pl-72 {
    padding-left: 18rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pl-80 {
    padding-left: 20rem;
  }

  .lg\:pl-9 {
    padding-left: 2.25rem;
  }

  .lg\:pl-96 {
    padding-left: 24rem;
  }

  .lg\:pl-px {
    padding-left: 1px;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pr-0\.5 {
    padding-right: .125rem;
  }

  .lg\:pr-1 {
    padding-right: .25rem;
  }

  .lg\:pr-1\.5 {
    padding-right: .375rem;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:pr-11 {
    padding-right: 2.75rem;
  }

  .lg\:pr-12 {
    padding-right: 3rem;
  }

  .lg\:pr-14 {
    padding-right: 3.5rem;
  }

  .lg\:pr-16 {
    padding-right: 4rem;
  }

  .lg\:pr-2 {
    padding-right: .5rem;
  }

  .lg\:pr-2\.5 {
    padding-right: .625rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pr-24 {
    padding-right: 6rem;
  }

  .lg\:pr-28 {
    padding-right: 7rem;
  }

  .lg\:pr-3 {
    padding-right: .75rem;
  }

  .lg\:pr-3\.5 {
    padding-right: .875rem;
  }

  .lg\:pr-32 {
    padding-right: 8rem;
  }

  .lg\:pr-36 {
    padding-right: 9rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pr-40 {
    padding-right: 10rem;
  }

  .lg\:pr-44 {
    padding-right: 11rem;
  }

  .lg\:pr-48 {
    padding-right: 12rem;
  }

  .lg\:pr-5 {
    padding-right: 1.25rem;
  }

  .lg\:pr-52 {
    padding-right: 13rem;
  }

  .lg\:pr-56 {
    padding-right: 14rem;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:pr-60 {
    padding-right: 15rem;
  }

  .lg\:pr-64 {
    padding-right: 16rem;
  }

  .lg\:pr-7 {
    padding-right: 1.75rem;
  }

  .lg\:pr-72 {
    padding-right: 18rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pr-80 {
    padding-right: 20rem;
  }

  .lg\:pr-9 {
    padding-right: 2.25rem;
  }

  .lg\:pr-96 {
    padding-right: 24rem;
  }

  .lg\:pr-px {
    padding-right: 1px;
  }

  .lg\:ps-0 {
    padding-inline-start: 0;
  }

  .lg\:ps-0\.5 {
    padding-inline-start: .125rem;
  }

  .lg\:ps-1 {
    padding-inline-start: .25rem;
  }

  .lg\:ps-1\.5 {
    padding-inline-start: .375rem;
  }

  .lg\:ps-10 {
    padding-inline-start: 2.5rem;
  }

  .lg\:ps-11 {
    padding-inline-start: 2.75rem;
  }

  .lg\:ps-12 {
    padding-inline-start: 3rem;
  }

  .lg\:ps-14 {
    padding-inline-start: 3.5rem;
  }

  .lg\:ps-16 {
    padding-inline-start: 4rem;
  }

  .lg\:ps-2 {
    padding-inline-start: .5rem;
  }

  .lg\:ps-2\.5 {
    padding-inline-start: .625rem;
  }

  .lg\:ps-20 {
    padding-inline-start: 5rem;
  }

  .lg\:ps-24 {
    padding-inline-start: 6rem;
  }

  .lg\:ps-28 {
    padding-inline-start: 7rem;
  }

  .lg\:ps-3 {
    padding-inline-start: .75rem;
  }

  .lg\:ps-3\.5 {
    padding-inline-start: .875rem;
  }

  .lg\:ps-32 {
    padding-inline-start: 8rem;
  }

  .lg\:ps-36 {
    padding-inline-start: 9rem;
  }

  .lg\:ps-4 {
    padding-inline-start: 1rem;
  }

  .lg\:ps-40 {
    padding-inline-start: 10rem;
  }

  .lg\:ps-44 {
    padding-inline-start: 11rem;
  }

  .lg\:ps-48 {
    padding-inline-start: 12rem;
  }

  .lg\:ps-5 {
    padding-inline-start: 1.25rem;
  }

  .lg\:ps-52 {
    padding-inline-start: 13rem;
  }

  .lg\:ps-56 {
    padding-inline-start: 14rem;
  }

  .lg\:ps-6 {
    padding-inline-start: 1.5rem;
  }

  .lg\:ps-60 {
    padding-inline-start: 15rem;
  }

  .lg\:ps-64 {
    padding-inline-start: 16rem;
  }

  .lg\:ps-7 {
    padding-inline-start: 1.75rem;
  }

  .lg\:ps-72 {
    padding-inline-start: 18rem;
  }

  .lg\:ps-8 {
    padding-inline-start: 2rem;
  }

  .lg\:ps-80 {
    padding-inline-start: 20rem;
  }

  .lg\:ps-9 {
    padding-inline-start: 2.25rem;
  }

  .lg\:ps-96 {
    padding-inline-start: 24rem;
  }

  .lg\:ps-px {
    padding-inline-start: 1px;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pt-0\.5 {
    padding-top: .125rem;
  }

  .lg\:pt-1 {
    padding-top: .25rem;
  }

  .lg\:pt-1\.5 {
    padding-top: .375rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pt-11 {
    padding-top: 2.75rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pt-14 {
    padding-top: 3.5rem;
  }

  .lg\:pt-16 {
    padding-top: 4rem;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pt-2\.5 {
    padding-top: .625rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pt-28 {
    padding-top: 7rem;
  }

  .lg\:pt-3 {
    padding-top: .75rem;
  }

  .lg\:pt-3\.5 {
    padding-top: .875rem;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pt-36 {
    padding-top: 9rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-40 {
    padding-top: 10rem;
  }

  .lg\:pt-44 {
    padding-top: 11rem;
  }

  .lg\:pt-48 {
    padding-top: 12rem;
  }

  .lg\:pt-5 {
    padding-top: 1.25rem;
  }

  .lg\:pt-52 {
    padding-top: 13rem;
  }

  .lg\:pt-56 {
    padding-top: 14rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:pt-60 {
    padding-top: 15rem;
  }

  .lg\:pt-64 {
    padding-top: 16rem;
  }

  .lg\:pt-7 {
    padding-top: 1.75rem;
  }

  .lg\:pt-72 {
    padding-top: 18rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pt-80 {
    padding-top: 20rem;
  }

  .lg\:pt-9 {
    padding-top: 2.25rem;
  }

  .lg\:pt-96 {
    padding-top: 24rem;
  }

  .lg\:pt-px {
    padding-top: 1px;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-justify {
    text-align: justify;
  }

  .lg\:text-start {
    text-align: start;
  }

  .lg\:text-end {
    text-align: end;
  }

  .lg\:-indent-0 {
    text-indent: 0;
  }

  .lg\:-indent-0\.5 {
    text-indent: -.125rem;
  }

  .lg\:-indent-1 {
    text-indent: -.25rem;
  }

  .lg\:-indent-1\.5 {
    text-indent: -.375rem;
  }

  .lg\:-indent-10 {
    text-indent: -2.5rem;
  }

  .lg\:-indent-11 {
    text-indent: -2.75rem;
  }

  .lg\:-indent-12 {
    text-indent: -3rem;
  }

  .lg\:-indent-14 {
    text-indent: -3.5rem;
  }

  .lg\:-indent-16 {
    text-indent: -4rem;
  }

  .lg\:-indent-2 {
    text-indent: -.5rem;
  }

  .lg\:-indent-2\.5 {
    text-indent: -.625rem;
  }

  .lg\:-indent-20 {
    text-indent: -5rem;
  }

  .lg\:-indent-24 {
    text-indent: -6rem;
  }

  .lg\:-indent-28 {
    text-indent: -7rem;
  }

  .lg\:-indent-3 {
    text-indent: -.75rem;
  }

  .lg\:-indent-3\.5 {
    text-indent: -.875rem;
  }

  .lg\:-indent-32 {
    text-indent: -8rem;
  }

  .lg\:-indent-36 {
    text-indent: -9rem;
  }

  .lg\:-indent-4 {
    text-indent: -1rem;
  }

  .lg\:-indent-40 {
    text-indent: -10rem;
  }

  .lg\:-indent-44 {
    text-indent: -11rem;
  }

  .lg\:-indent-48 {
    text-indent: -12rem;
  }

  .lg\:-indent-5 {
    text-indent: -1.25rem;
  }

  .lg\:-indent-52 {
    text-indent: -13rem;
  }

  .lg\:-indent-56 {
    text-indent: -14rem;
  }

  .lg\:-indent-6 {
    text-indent: -1.5rem;
  }

  .lg\:-indent-60 {
    text-indent: -15rem;
  }

  .lg\:-indent-64 {
    text-indent: -16rem;
  }

  .lg\:-indent-7 {
    text-indent: -1.75rem;
  }

  .lg\:-indent-72 {
    text-indent: -18rem;
  }

  .lg\:-indent-8 {
    text-indent: -2rem;
  }

  .lg\:-indent-80 {
    text-indent: -20rem;
  }

  .lg\:-indent-9 {
    text-indent: -2.25rem;
  }

  .lg\:-indent-96 {
    text-indent: -24rem;
  }

  .lg\:-indent-px {
    text-indent: -1px;
  }

  .lg\:indent-0 {
    text-indent: 0;
  }

  .lg\:indent-0\.5 {
    text-indent: .125rem;
  }

  .lg\:indent-1 {
    text-indent: .25rem;
  }

  .lg\:indent-1\.5 {
    text-indent: .375rem;
  }

  .lg\:indent-10 {
    text-indent: 2.5rem;
  }

  .lg\:indent-11 {
    text-indent: 2.75rem;
  }

  .lg\:indent-12 {
    text-indent: 3rem;
  }

  .lg\:indent-14 {
    text-indent: 3.5rem;
  }

  .lg\:indent-16 {
    text-indent: 4rem;
  }

  .lg\:indent-2 {
    text-indent: .5rem;
  }

  .lg\:indent-2\.5 {
    text-indent: .625rem;
  }

  .lg\:indent-20 {
    text-indent: 5rem;
  }

  .lg\:indent-24 {
    text-indent: 6rem;
  }

  .lg\:indent-28 {
    text-indent: 7rem;
  }

  .lg\:indent-3 {
    text-indent: .75rem;
  }

  .lg\:indent-3\.5 {
    text-indent: .875rem;
  }

  .lg\:indent-32 {
    text-indent: 8rem;
  }

  .lg\:indent-36 {
    text-indent: 9rem;
  }

  .lg\:indent-4 {
    text-indent: 1rem;
  }

  .lg\:indent-40 {
    text-indent: 10rem;
  }

  .lg\:indent-44 {
    text-indent: 11rem;
  }

  .lg\:indent-48 {
    text-indent: 12rem;
  }

  .lg\:indent-5 {
    text-indent: 1.25rem;
  }

  .lg\:indent-52 {
    text-indent: 13rem;
  }

  .lg\:indent-56 {
    text-indent: 14rem;
  }

  .lg\:indent-6 {
    text-indent: 1.5rem;
  }

  .lg\:indent-60 {
    text-indent: 15rem;
  }

  .lg\:indent-64 {
    text-indent: 16rem;
  }

  .lg\:indent-7 {
    text-indent: 1.75rem;
  }

  .lg\:indent-72 {
    text-indent: 18rem;
  }

  .lg\:indent-8 {
    text-indent: 2rem;
  }

  .lg\:indent-80 {
    text-indent: 20rem;
  }

  .lg\:indent-9 {
    text-indent: 2.25rem;
  }

  .lg\:indent-96 {
    text-indent: 24rem;
  }

  .lg\:indent-px {
    text-indent: 1px;
  }

  .lg\:align-baseline {
    vertical-align: baseline;
  }

  .lg\:align-top {
    vertical-align: top;
  }

  .lg\:align-middle {
    vertical-align: middle;
  }

  .lg\:align-bottom {
    vertical-align: bottom;
  }

  .lg\:align-text-top {
    vertical-align: text-top;
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .lg\:align-sub {
    vertical-align: sub;
  }

  .lg\:align-super {
    vertical-align: super;
  }

  .lg\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .lg\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .lg\:font-serif {
    font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
  }

  .lg\:text-6xl {
    font-size: 4rem;
  }

  .lg\:text-base {
    font-size: 1rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
  }

  .lg\:text-xs {
    font-size: .75rem;
  }

  .lg\:font-black {
    font-weight: 900;
  }

  .lg\:font-bold {
    font-weight: 700;
  }

  .lg\:font-extrabold {
    font-weight: 800;
  }

  .lg\:font-extralight {
    font-weight: 200;
  }

  .lg\:font-light {
    font-weight: 300;
  }

  .lg\:font-medium {
    font-weight: 500;
  }

  .lg\:font-normal {
    font-weight: 400;
  }

  .lg\:font-semibold {
    font-weight: 600;
  }

  .lg\:font-thin {
    font-weight: 100;
  }

  .lg\:uppercase {
    text-transform: uppercase;
  }

  .lg\:lowercase {
    text-transform: lowercase;
  }

  .lg\:capitalize {
    text-transform: capitalize;
  }

  .lg\:normal-case {
    text-transform: none;
  }

  .lg\:italic {
    font-style: italic;
  }

  .lg\:not-italic {
    font-style: normal;
  }

  .lg\:normal-nums {
    font-variant-numeric: normal;
  }

  .lg\:ordinal {
    --tw-ordinal: ordinal;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:lining-nums {
    --tw-numeric-figure: lining-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }

  .lg\:leading-3 {
    line-height: .75rem;
  }

  .lg\:leading-4 {
    line-height: 1rem;
  }

  .lg\:leading-5 {
    line-height: 1.25rem;
  }

  .lg\:leading-6 {
    line-height: 1.5rem;
  }

  .lg\:leading-7 {
    line-height: 1.75rem;
  }

  .lg\:leading-8 {
    line-height: 2rem;
  }

  .lg\:leading-9 {
    line-height: 2.25rem;
  }

  .lg\:leading-loose {
    line-height: 2;
  }

  .lg\:leading-none {
    line-height: 1;
  }

  .lg\:leading-normal {
    line-height: 1.5;
  }

  .lg\:leading-relaxed {
    line-height: 1.625;
  }

  .lg\:leading-snug {
    line-height: 1.375;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:-tracking-normal {
    letter-spacing: 0;
  }

  .lg\:-tracking-tight {
    letter-spacing: .025em;
  }

  .lg\:-tracking-tighter {
    letter-spacing: .05em;
  }

  .lg\:-tracking-wide {
    letter-spacing: -.025em;
  }

  .lg\:-tracking-wider {
    letter-spacing: -.05em;
  }

  .lg\:-tracking-widest {
    letter-spacing: -.1em;
  }

  .lg\:tracking-normal {
    letter-spacing: 0;
  }

  .lg\:tracking-tight {
    letter-spacing: -.025em;
  }

  .lg\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .lg\:tracking-wide {
    letter-spacing: .025em;
  }

  .lg\:tracking-wider {
    letter-spacing: .05em;
  }

  .lg\:tracking-widest {
    letter-spacing: .1em;
  }

  .lg\:text-black {
    color: var(--color-black);
  }

  .lg\:text-current {
    color: currentColor;
  }

  .lg\:text-danger {
    color: var(--color-danger-300);
  }

  .lg\:text-danger-100 {
    color: var(--color-danger-100);
  }

  .lg\:text-danger-200 {
    color: var(--color-danger-200);
  }

  .lg\:text-danger-300 {
    color: var(--color-danger-300);
  }

  .lg\:text-danger-400 {
    color: var(--color-danger-400);
  }

  .lg\:text-divider {
    color: var(--color-divider);
  }

  .lg\:text-gray {
    color: var(--color-gray);
  }

  .lg\:text-info {
    color: var(--color-info-300);
  }

  .lg\:text-info-100 {
    color: var(--color-info-100);
  }

  .lg\:text-info-200 {
    color: var(--color-info-200);
  }

  .lg\:text-info-300 {
    color: var(--color-info-300);
  }

  .lg\:text-info-400 {
    color: var(--color-info-400);
  }

  .lg\:text-primary {
    color: var(--color-primary-300);
  }

  .lg\:text-primary-100 {
    color: var(--color-primary-100);
  }

  .lg\:text-primary-200 {
    color: var(--color-primary-200);
  }

  .lg\:text-primary-250 {
    color: var(--color-primary-250);
  }

  .lg\:text-primary-300 {
    color: var(--color-primary-300);
  }

  .lg\:text-primary-400 {
    color: var(--color-primary-400);
  }

  .lg\:text-primary-500 {
    color: var(--color-primary-500);
  }

  .lg\:text-primary-600 {
    color: var(--color-primary-600);
  }

  .lg\:text-success {
    color: var(--color-success-300);
  }

  .lg\:text-success-100 {
    color: var(--color-success-100);
  }

  .lg\:text-success-200 {
    color: var(--color-success-200);
  }

  .lg\:text-success-300 {
    color: var(--color-success-300);
  }

  .lg\:text-success-400 {
    color: var(--color-success-400);
  }

  .lg\:text-transparent, .lg\:text-transparent\/0 {
    color: #0000;
  }

  .lg\:text-transparent\/10 {
    color: #0000001a;
  }

  .lg\:text-transparent\/100 {
    color: #000;
  }

  .lg\:text-transparent\/15 {
    color: #00000026;
  }

  .lg\:text-transparent\/20 {
    color: #0003;
  }

  .lg\:text-transparent\/25 {
    color: #00000040;
  }

  .lg\:text-transparent\/30 {
    color: #0000004d;
  }

  .lg\:text-transparent\/35 {
    color: #00000059;
  }

  .lg\:text-transparent\/40 {
    color: #0006;
  }

  .lg\:text-transparent\/45 {
    color: #00000073;
  }

  .lg\:text-transparent\/5 {
    color: #0000000d;
  }

  .lg\:text-transparent\/50 {
    color: #00000080;
  }

  .lg\:text-transparent\/55 {
    color: #0000008c;
  }

  .lg\:text-transparent\/60 {
    color: #0009;
  }

  .lg\:text-transparent\/65 {
    color: #000000a6;
  }

  .lg\:text-transparent\/70 {
    color: #000000b3;
  }

  .lg\:text-transparent\/75 {
    color: #000000bf;
  }

  .lg\:text-transparent\/80 {
    color: #000c;
  }

  .lg\:text-transparent\/85 {
    color: #000000d9;
  }

  .lg\:text-transparent\/90 {
    color: #000000e6;
  }

  .lg\:text-transparent\/95 {
    color: #000000f2;
  }

  .lg\:text-warning {
    color: var(--color-warning-300);
  }

  .lg\:text-warning-100 {
    color: var(--color-warning-100);
  }

  .lg\:text-warning-200 {
    color: var(--color-warning-200);
  }

  .lg\:text-warning-300 {
    color: var(--color-warning-300);
  }

  .lg\:text-warning-400 {
    color: var(--color-warning-400);
  }

  .lg\:text-white {
    color: var(--color-white);
  }

  .lg\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .lg\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .lg\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .lg\:text-opacity-15 {
    --tw-text-opacity: .15;
  }

  .lg\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .lg\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .lg\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .lg\:text-opacity-35 {
    --tw-text-opacity: .35;
  }

  .lg\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .lg\:text-opacity-45 {
    --tw-text-opacity: .45;
  }

  .lg\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .lg\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .lg\:text-opacity-55 {
    --tw-text-opacity: .55;
  }

  .lg\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .lg\:text-opacity-65 {
    --tw-text-opacity: .65;
  }

  .lg\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .lg\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .lg\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .lg\:text-opacity-85 {
    --tw-text-opacity: .85;
  }

  .lg\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .lg\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .lg\:underline {
    text-decoration-line: underline;
  }

  .lg\:overline {
    text-decoration-line: overline;
  }

  .lg\:line-through {
    text-decoration-line: line-through;
  }

  .lg\:no-underline {
    text-decoration-line: none;
  }

  .lg\:decoration-black {
    -webkit-text-decoration-color: var(--color-black);
    text-decoration-color: var(--color-black);
  }

  .lg\:decoration-current {
    text-decoration-color: currentColor;
  }

  .lg\:decoration-danger {
    -webkit-text-decoration-color: var(--color-danger-300);
    text-decoration-color: var(--color-danger-300);
  }

  .lg\:decoration-danger-100 {
    -webkit-text-decoration-color: var(--color-danger-100);
    text-decoration-color: var(--color-danger-100);
  }

  .lg\:decoration-danger-200 {
    -webkit-text-decoration-color: var(--color-danger-200);
    text-decoration-color: var(--color-danger-200);
  }

  .lg\:decoration-danger-300 {
    -webkit-text-decoration-color: var(--color-danger-300);
    text-decoration-color: var(--color-danger-300);
  }

  .lg\:decoration-danger-400 {
    -webkit-text-decoration-color: var(--color-danger-400);
    text-decoration-color: var(--color-danger-400);
  }

  .lg\:decoration-divider {
    -webkit-text-decoration-color: var(--color-divider);
    text-decoration-color: var(--color-divider);
  }

  .lg\:decoration-gray {
    -webkit-text-decoration-color: var(--color-gray);
    text-decoration-color: var(--color-gray);
  }

  .lg\:decoration-info {
    -webkit-text-decoration-color: var(--color-info-300);
    text-decoration-color: var(--color-info-300);
  }

  .lg\:decoration-info-100 {
    -webkit-text-decoration-color: var(--color-info-100);
    text-decoration-color: var(--color-info-100);
  }

  .lg\:decoration-info-200 {
    -webkit-text-decoration-color: var(--color-info-200);
    text-decoration-color: var(--color-info-200);
  }

  .lg\:decoration-info-300 {
    -webkit-text-decoration-color: var(--color-info-300);
    text-decoration-color: var(--color-info-300);
  }

  .lg\:decoration-info-400 {
    -webkit-text-decoration-color: var(--color-info-400);
    text-decoration-color: var(--color-info-400);
  }

  .lg\:decoration-primary {
    -webkit-text-decoration-color: var(--color-primary-300);
    text-decoration-color: var(--color-primary-300);
  }

  .lg\:decoration-primary-100 {
    -webkit-text-decoration-color: var(--color-primary-100);
    text-decoration-color: var(--color-primary-100);
  }

  .lg\:decoration-primary-200 {
    -webkit-text-decoration-color: var(--color-primary-200);
    text-decoration-color: var(--color-primary-200);
  }

  .lg\:decoration-primary-250 {
    -webkit-text-decoration-color: var(--color-primary-250);
    text-decoration-color: var(--color-primary-250);
  }

  .lg\:decoration-primary-300 {
    -webkit-text-decoration-color: var(--color-primary-300);
    text-decoration-color: var(--color-primary-300);
  }

  .lg\:decoration-primary-400 {
    -webkit-text-decoration-color: var(--color-primary-400);
    text-decoration-color: var(--color-primary-400);
  }

  .lg\:decoration-primary-500 {
    -webkit-text-decoration-color: var(--color-primary-500);
    text-decoration-color: var(--color-primary-500);
  }

  .lg\:decoration-primary-600 {
    -webkit-text-decoration-color: var(--color-primary-600);
    text-decoration-color: var(--color-primary-600);
  }

  .lg\:decoration-success {
    -webkit-text-decoration-color: var(--color-success-300);
    text-decoration-color: var(--color-success-300);
  }

  .lg\:decoration-success-100 {
    -webkit-text-decoration-color: var(--color-success-100);
    text-decoration-color: var(--color-success-100);
  }

  .lg\:decoration-success-200 {
    -webkit-text-decoration-color: var(--color-success-200);
    text-decoration-color: var(--color-success-200);
  }

  .lg\:decoration-success-300 {
    -webkit-text-decoration-color: var(--color-success-300);
    text-decoration-color: var(--color-success-300);
  }

  .lg\:decoration-success-400 {
    -webkit-text-decoration-color: var(--color-success-400);
    text-decoration-color: var(--color-success-400);
  }

  .lg\:decoration-transparent, .lg\:decoration-transparent\/0 {
    text-decoration-color: #0000;
  }

  .lg\:decoration-transparent\/10 {
    text-decoration-color: #0000001a;
  }

  .lg\:decoration-transparent\/100 {
    text-decoration-color: #000;
  }

  .lg\:decoration-transparent\/15 {
    text-decoration-color: #00000026;
  }

  .lg\:decoration-transparent\/20 {
    text-decoration-color: #0003;
  }

  .lg\:decoration-transparent\/25 {
    text-decoration-color: #00000040;
  }

  .lg\:decoration-transparent\/30 {
    text-decoration-color: #0000004d;
  }

  .lg\:decoration-transparent\/35 {
    text-decoration-color: #00000059;
  }

  .lg\:decoration-transparent\/40 {
    text-decoration-color: #0006;
  }

  .lg\:decoration-transparent\/45 {
    text-decoration-color: #00000073;
  }

  .lg\:decoration-transparent\/5 {
    text-decoration-color: #0000000d;
  }

  .lg\:decoration-transparent\/50 {
    text-decoration-color: #00000080;
  }

  .lg\:decoration-transparent\/55 {
    text-decoration-color: #0000008c;
  }

  .lg\:decoration-transparent\/60 {
    text-decoration-color: #0009;
  }

  .lg\:decoration-transparent\/65 {
    text-decoration-color: #000000a6;
  }

  .lg\:decoration-transparent\/70 {
    text-decoration-color: #000000b3;
  }

  .lg\:decoration-transparent\/75 {
    text-decoration-color: #000000bf;
  }

  .lg\:decoration-transparent\/80 {
    text-decoration-color: #000c;
  }

  .lg\:decoration-transparent\/85 {
    text-decoration-color: #000000d9;
  }

  .lg\:decoration-transparent\/90 {
    text-decoration-color: #000000e6;
  }

  .lg\:decoration-transparent\/95 {
    text-decoration-color: #000000f2;
  }

  .lg\:decoration-warning {
    -webkit-text-decoration-color: var(--color-warning-300);
    text-decoration-color: var(--color-warning-300);
  }

  .lg\:decoration-warning-100 {
    -webkit-text-decoration-color: var(--color-warning-100);
    text-decoration-color: var(--color-warning-100);
  }

  .lg\:decoration-warning-200 {
    -webkit-text-decoration-color: var(--color-warning-200);
    text-decoration-color: var(--color-warning-200);
  }

  .lg\:decoration-warning-300 {
    -webkit-text-decoration-color: var(--color-warning-300);
    text-decoration-color: var(--color-warning-300);
  }

  .lg\:decoration-warning-400 {
    -webkit-text-decoration-color: var(--color-warning-400);
    text-decoration-color: var(--color-warning-400);
  }

  .lg\:decoration-white {
    -webkit-text-decoration-color: var(--color-white);
    text-decoration-color: var(--color-white);
  }

  .lg\:decoration-solid {
    text-decoration-style: solid;
  }

  .lg\:decoration-double {
    text-decoration-style: double;
  }

  .lg\:decoration-dotted {
    text-decoration-style: dotted;
  }

  .lg\:decoration-dashed {
    text-decoration-style: dashed;
  }

  .lg\:decoration-wavy {
    text-decoration-style: wavy;
  }

  .lg\:decoration-0 {
    text-decoration-thickness: 0;
  }

  .lg\:decoration-1 {
    text-decoration-thickness: 1px;
  }

  .lg\:decoration-2 {
    text-decoration-thickness: 2px;
  }

  .lg\:decoration-4 {
    text-decoration-thickness: 4px;
  }

  .lg\:decoration-8 {
    text-decoration-thickness: 8px;
  }

  .lg\:decoration-auto {
    text-decoration-thickness: auto;
  }

  .lg\:decoration-from-font {
    text-decoration-thickness: from-font;
  }

  .lg\:underline-offset-0 {
    text-underline-offset: 0px;
  }

  .lg\:underline-offset-1 {
    text-underline-offset: 1px;
  }

  .lg\:underline-offset-2 {
    text-underline-offset: 2px;
  }

  .lg\:underline-offset-4 {
    text-underline-offset: 4px;
  }

  .lg\:underline-offset-8 {
    text-underline-offset: 8px;
  }

  .lg\:underline-offset-auto {
    text-underline-offset: auto;
  }

  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:placeholder-black::placeholder {
    color: var(--color-black);
  }

  .lg\:placeholder-current::placeholder {
    color: currentColor;
  }

  .lg\:placeholder-danger::placeholder {
    color: var(--color-danger-300);
  }

  .lg\:placeholder-danger-100::placeholder {
    color: var(--color-danger-100);
  }

  .lg\:placeholder-danger-200::placeholder {
    color: var(--color-danger-200);
  }

  .lg\:placeholder-danger-300::placeholder {
    color: var(--color-danger-300);
  }

  .lg\:placeholder-danger-400::placeholder {
    color: var(--color-danger-400);
  }

  .lg\:placeholder-divider::placeholder {
    color: var(--color-divider);
  }

  .lg\:placeholder-gray::placeholder {
    color: var(--color-gray);
  }

  .lg\:placeholder-info::placeholder {
    color: var(--color-info-300);
  }

  .lg\:placeholder-info-100::placeholder {
    color: var(--color-info-100);
  }

  .lg\:placeholder-info-200::placeholder {
    color: var(--color-info-200);
  }

  .lg\:placeholder-info-300::placeholder {
    color: var(--color-info-300);
  }

  .lg\:placeholder-info-400::placeholder {
    color: var(--color-info-400);
  }

  .lg\:placeholder-primary::placeholder {
    color: var(--color-primary-300);
  }

  .lg\:placeholder-primary-100::placeholder {
    color: var(--color-primary-100);
  }

  .lg\:placeholder-primary-200::placeholder {
    color: var(--color-primary-200);
  }

  .lg\:placeholder-primary-250::placeholder {
    color: var(--color-primary-250);
  }

  .lg\:placeholder-primary-300::placeholder {
    color: var(--color-primary-300);
  }

  .lg\:placeholder-primary-400::placeholder {
    color: var(--color-primary-400);
  }

  .lg\:placeholder-primary-500::placeholder {
    color: var(--color-primary-500);
  }

  .lg\:placeholder-primary-600::placeholder {
    color: var(--color-primary-600);
  }

  .lg\:placeholder-success::placeholder {
    color: var(--color-success-300);
  }

  .lg\:placeholder-success-100::placeholder {
    color: var(--color-success-100);
  }

  .lg\:placeholder-success-200::placeholder {
    color: var(--color-success-200);
  }

  .lg\:placeholder-success-300::placeholder {
    color: var(--color-success-300);
  }

  .lg\:placeholder-success-400::placeholder {
    color: var(--color-success-400);
  }

  .lg\:placeholder-transparent::placeholder, .lg\:placeholder-transparent\/0::placeholder {
    color: #0000;
  }

  .lg\:placeholder-transparent\/10::placeholder {
    color: #0000001a;
  }

  .lg\:placeholder-transparent\/100::placeholder {
    color: #000;
  }

  .lg\:placeholder-transparent\/15::placeholder {
    color: #00000026;
  }

  .lg\:placeholder-transparent\/20::placeholder {
    color: #0003;
  }

  .lg\:placeholder-transparent\/25::placeholder {
    color: #00000040;
  }

  .lg\:placeholder-transparent\/30::placeholder {
    color: #0000004d;
  }

  .lg\:placeholder-transparent\/35::placeholder {
    color: #00000059;
  }

  .lg\:placeholder-transparent\/40::placeholder {
    color: #0006;
  }

  .lg\:placeholder-transparent\/45::placeholder {
    color: #00000073;
  }

  .lg\:placeholder-transparent\/5::placeholder {
    color: #0000000d;
  }

  .lg\:placeholder-transparent\/50::placeholder {
    color: #00000080;
  }

  .lg\:placeholder-transparent\/55::placeholder {
    color: #0000008c;
  }

  .lg\:placeholder-transparent\/60::placeholder {
    color: #0009;
  }

  .lg\:placeholder-transparent\/65::placeholder {
    color: #000000a6;
  }

  .lg\:placeholder-transparent\/70::placeholder {
    color: #000000b3;
  }

  .lg\:placeholder-transparent\/75::placeholder {
    color: #000000bf;
  }

  .lg\:placeholder-transparent\/80::placeholder {
    color: #000c;
  }

  .lg\:placeholder-transparent\/85::placeholder {
    color: #000000d9;
  }

  .lg\:placeholder-transparent\/90::placeholder {
    color: #000000e6;
  }

  .lg\:placeholder-transparent\/95::placeholder {
    color: #000000f2;
  }

  .lg\:placeholder-warning::placeholder {
    color: var(--color-warning-300);
  }

  .lg\:placeholder-warning-100::placeholder {
    color: var(--color-warning-100);
  }

  .lg\:placeholder-warning-200::placeholder {
    color: var(--color-warning-200);
  }

  .lg\:placeholder-warning-300::placeholder {
    color: var(--color-warning-300);
  }

  .lg\:placeholder-warning-400::placeholder {
    color: var(--color-warning-400);
  }

  .lg\:placeholder-white::placeholder {
    color: var(--color-white);
  }

  .lg\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .lg\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:placeholder-opacity-15::placeholder {
    --tw-placeholder-opacity: .15;
  }

  .lg\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .lg\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .lg\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .lg\:placeholder-opacity-35::placeholder {
    --tw-placeholder-opacity: .35;
  }

  .lg\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .lg\:placeholder-opacity-45::placeholder {
    --tw-placeholder-opacity: .45;
  }

  .lg\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .lg\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .lg\:placeholder-opacity-55::placeholder {
    --tw-placeholder-opacity: .55;
  }

  .lg\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .lg\:placeholder-opacity-65::placeholder {
    --tw-placeholder-opacity: .65;
  }

  .lg\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .lg\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .lg\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .lg\:placeholder-opacity-85::placeholder {
    --tw-placeholder-opacity: .85;
  }

  .lg\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .lg\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .lg\:caret-black {
    caret-color: var(--color-black);
  }

  .lg\:caret-current {
    caret-color: currentColor;
  }

  .lg\:caret-danger {
    caret-color: var(--color-danger-300);
  }

  .lg\:caret-danger-100 {
    caret-color: var(--color-danger-100);
  }

  .lg\:caret-danger-200 {
    caret-color: var(--color-danger-200);
  }

  .lg\:caret-danger-300 {
    caret-color: var(--color-danger-300);
  }

  .lg\:caret-danger-400 {
    caret-color: var(--color-danger-400);
  }

  .lg\:caret-divider {
    caret-color: var(--color-divider);
  }

  .lg\:caret-gray {
    caret-color: var(--color-gray);
  }

  .lg\:caret-info {
    caret-color: var(--color-info-300);
  }

  .lg\:caret-info-100 {
    caret-color: var(--color-info-100);
  }

  .lg\:caret-info-200 {
    caret-color: var(--color-info-200);
  }

  .lg\:caret-info-300 {
    caret-color: var(--color-info-300);
  }

  .lg\:caret-info-400 {
    caret-color: var(--color-info-400);
  }

  .lg\:caret-primary {
    caret-color: var(--color-primary-300);
  }

  .lg\:caret-primary-100 {
    caret-color: var(--color-primary-100);
  }

  .lg\:caret-primary-200 {
    caret-color: var(--color-primary-200);
  }

  .lg\:caret-primary-250 {
    caret-color: var(--color-primary-250);
  }

  .lg\:caret-primary-300 {
    caret-color: var(--color-primary-300);
  }

  .lg\:caret-primary-400 {
    caret-color: var(--color-primary-400);
  }

  .lg\:caret-primary-500 {
    caret-color: var(--color-primary-500);
  }

  .lg\:caret-primary-600 {
    caret-color: var(--color-primary-600);
  }

  .lg\:caret-success {
    caret-color: var(--color-success-300);
  }

  .lg\:caret-success-100 {
    caret-color: var(--color-success-100);
  }

  .lg\:caret-success-200 {
    caret-color: var(--color-success-200);
  }

  .lg\:caret-success-300 {
    caret-color: var(--color-success-300);
  }

  .lg\:caret-success-400 {
    caret-color: var(--color-success-400);
  }

  .lg\:caret-transparent, .lg\:caret-transparent\/0 {
    caret-color: #0000;
  }

  .lg\:caret-transparent\/10 {
    caret-color: #0000001a;
  }

  .lg\:caret-transparent\/100 {
    caret-color: #000;
  }

  .lg\:caret-transparent\/15 {
    caret-color: #00000026;
  }

  .lg\:caret-transparent\/20 {
    caret-color: #0003;
  }

  .lg\:caret-transparent\/25 {
    caret-color: #00000040;
  }

  .lg\:caret-transparent\/30 {
    caret-color: #0000004d;
  }

  .lg\:caret-transparent\/35 {
    caret-color: #00000059;
  }

  .lg\:caret-transparent\/40 {
    caret-color: #0006;
  }

  .lg\:caret-transparent\/45 {
    caret-color: #00000073;
  }

  .lg\:caret-transparent\/5 {
    caret-color: #0000000d;
  }

  .lg\:caret-transparent\/50 {
    caret-color: #00000080;
  }

  .lg\:caret-transparent\/55 {
    caret-color: #0000008c;
  }

  .lg\:caret-transparent\/60 {
    caret-color: #0009;
  }

  .lg\:caret-transparent\/65 {
    caret-color: #000000a6;
  }

  .lg\:caret-transparent\/70 {
    caret-color: #000000b3;
  }

  .lg\:caret-transparent\/75 {
    caret-color: #000000bf;
  }

  .lg\:caret-transparent\/80 {
    caret-color: #000c;
  }

  .lg\:caret-transparent\/85 {
    caret-color: #000000d9;
  }

  .lg\:caret-transparent\/90 {
    caret-color: #000000e6;
  }

  .lg\:caret-transparent\/95 {
    caret-color: #000000f2;
  }

  .lg\:caret-warning {
    caret-color: var(--color-warning-300);
  }

  .lg\:caret-warning-100 {
    caret-color: var(--color-warning-100);
  }

  .lg\:caret-warning-200 {
    caret-color: var(--color-warning-200);
  }

  .lg\:caret-warning-300 {
    caret-color: var(--color-warning-300);
  }

  .lg\:caret-warning-400 {
    caret-color: var(--color-warning-400);
  }

  .lg\:caret-white {
    caret-color: var(--color-white);
  }

  .lg\:accent-auto {
    accent-color: auto;
  }

  .lg\:accent-black {
    accent-color: var(--color-black);
  }

  .lg\:accent-current {
    accent-color: currentColor;
  }

  .lg\:accent-danger {
    accent-color: var(--color-danger-300);
  }

  .lg\:accent-danger-100 {
    accent-color: var(--color-danger-100);
  }

  .lg\:accent-danger-200 {
    accent-color: var(--color-danger-200);
  }

  .lg\:accent-danger-300 {
    accent-color: var(--color-danger-300);
  }

  .lg\:accent-danger-400 {
    accent-color: var(--color-danger-400);
  }

  .lg\:accent-divider {
    accent-color: var(--color-divider);
  }

  .lg\:accent-gray {
    accent-color: var(--color-gray);
  }

  .lg\:accent-info {
    accent-color: var(--color-info-300);
  }

  .lg\:accent-info-100 {
    accent-color: var(--color-info-100);
  }

  .lg\:accent-info-200 {
    accent-color: var(--color-info-200);
  }

  .lg\:accent-info-300 {
    accent-color: var(--color-info-300);
  }

  .lg\:accent-info-400 {
    accent-color: var(--color-info-400);
  }

  .lg\:accent-primary {
    accent-color: var(--color-primary-300);
  }

  .lg\:accent-primary-100 {
    accent-color: var(--color-primary-100);
  }

  .lg\:accent-primary-200 {
    accent-color: var(--color-primary-200);
  }

  .lg\:accent-primary-250 {
    accent-color: var(--color-primary-250);
  }

  .lg\:accent-primary-300 {
    accent-color: var(--color-primary-300);
  }

  .lg\:accent-primary-400 {
    accent-color: var(--color-primary-400);
  }

  .lg\:accent-primary-500 {
    accent-color: var(--color-primary-500);
  }

  .lg\:accent-primary-600 {
    accent-color: var(--color-primary-600);
  }

  .lg\:accent-success {
    accent-color: var(--color-success-300);
  }

  .lg\:accent-success-100 {
    accent-color: var(--color-success-100);
  }

  .lg\:accent-success-200 {
    accent-color: var(--color-success-200);
  }

  .lg\:accent-success-300 {
    accent-color: var(--color-success-300);
  }

  .lg\:accent-success-400 {
    accent-color: var(--color-success-400);
  }

  .lg\:accent-transparent, .lg\:accent-transparent\/0 {
    accent-color: #0000;
  }

  .lg\:accent-transparent\/10 {
    accent-color: #0000001a;
  }

  .lg\:accent-transparent\/100 {
    accent-color: #000;
  }

  .lg\:accent-transparent\/15 {
    accent-color: #00000026;
  }

  .lg\:accent-transparent\/20 {
    accent-color: #0003;
  }

  .lg\:accent-transparent\/25 {
    accent-color: #00000040;
  }

  .lg\:accent-transparent\/30 {
    accent-color: #0000004d;
  }

  .lg\:accent-transparent\/35 {
    accent-color: #00000059;
  }

  .lg\:accent-transparent\/40 {
    accent-color: #0006;
  }

  .lg\:accent-transparent\/45 {
    accent-color: #00000073;
  }

  .lg\:accent-transparent\/5 {
    accent-color: #0000000d;
  }

  .lg\:accent-transparent\/50 {
    accent-color: #00000080;
  }

  .lg\:accent-transparent\/55 {
    accent-color: #0000008c;
  }

  .lg\:accent-transparent\/60 {
    accent-color: #0009;
  }

  .lg\:accent-transparent\/65 {
    accent-color: #000000a6;
  }

  .lg\:accent-transparent\/70 {
    accent-color: #000000b3;
  }

  .lg\:accent-transparent\/75 {
    accent-color: #000000bf;
  }

  .lg\:accent-transparent\/80 {
    accent-color: #000c;
  }

  .lg\:accent-transparent\/85 {
    accent-color: #000000d9;
  }

  .lg\:accent-transparent\/90 {
    accent-color: #000000e6;
  }

  .lg\:accent-transparent\/95 {
    accent-color: #000000f2;
  }

  .lg\:accent-warning {
    accent-color: var(--color-warning-300);
  }

  .lg\:accent-warning-100 {
    accent-color: var(--color-warning-100);
  }

  .lg\:accent-warning-200 {
    accent-color: var(--color-warning-200);
  }

  .lg\:accent-warning-300 {
    accent-color: var(--color-warning-300);
  }

  .lg\:accent-warning-400 {
    accent-color: var(--color-warning-400);
  }

  .lg\:accent-white {
    accent-color: var(--color-white);
  }

  .lg\:opacity-0 {
    opacity: 0;
  }

  .lg\:opacity-10 {
    opacity: .1;
  }

  .lg\:opacity-100 {
    opacity: 1;
  }

  .lg\:opacity-15 {
    opacity: .15;
  }

  .lg\:opacity-20 {
    opacity: .2;
  }

  .lg\:opacity-25 {
    opacity: .25;
  }

  .lg\:opacity-30 {
    opacity: .3;
  }

  .lg\:opacity-35 {
    opacity: .35;
  }

  .lg\:opacity-40 {
    opacity: .4;
  }

  .lg\:opacity-45 {
    opacity: .45;
  }

  .lg\:opacity-5 {
    opacity: .05;
  }

  .lg\:opacity-50 {
    opacity: .5;
  }

  .lg\:opacity-55 {
    opacity: .55;
  }

  .lg\:opacity-60 {
    opacity: .6;
  }

  .lg\:opacity-65 {
    opacity: .65;
  }

  .lg\:opacity-70 {
    opacity: .7;
  }

  .lg\:opacity-75 {
    opacity: .75;
  }

  .lg\:opacity-80 {
    opacity: .8;
  }

  .lg\:opacity-85 {
    opacity: .85;
  }

  .lg\:opacity-90 {
    opacity: .9;
  }

  .lg\:opacity-95 {
    opacity: .95;
  }

  .lg\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .lg\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .lg\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .lg\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .lg\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .lg\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .lg\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .lg\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .lg\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .lg\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .lg\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .lg\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .lg\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .lg\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .lg\:bg-blend-color {
    background-blend-mode: color;
  }

  .lg\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .lg\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .lg\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .lg\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .lg\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .lg\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .lg\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .lg\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .lg\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .lg\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .lg\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .lg\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .lg\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .lg\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .lg\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .lg\:mix-blend-color {
    mix-blend-mode: color;
  }

  .lg\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .lg\:mix-blend-plus-darker {
    mix-blend-mode: plus-darker;
  }

  .lg\:mix-blend-plus-lighter {
    mix-blend-mode: plus-lighter;
  }

  .lg\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000d;
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-black {
    --tw-shadow-color: var(--color-black);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-current {
    --tw-shadow-color: currentColor;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-danger {
    --tw-shadow-color: var(--color-danger-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-danger-100 {
    --tw-shadow-color: var(--color-danger-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-danger-200 {
    --tw-shadow-color: var(--color-danger-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-danger-300 {
    --tw-shadow-color: var(--color-danger-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-danger-400 {
    --tw-shadow-color: var(--color-danger-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-divider {
    --tw-shadow-color: var(--color-divider);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-gray {
    --tw-shadow-color: var(--color-gray);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-info {
    --tw-shadow-color: var(--color-info-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-info-100 {
    --tw-shadow-color: var(--color-info-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-info-200 {
    --tw-shadow-color: var(--color-info-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-info-300 {
    --tw-shadow-color: var(--color-info-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-info-400 {
    --tw-shadow-color: var(--color-info-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-primary {
    --tw-shadow-color: var(--color-primary-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-primary-100 {
    --tw-shadow-color: var(--color-primary-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-primary-200 {
    --tw-shadow-color: var(--color-primary-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-primary-250 {
    --tw-shadow-color: var(--color-primary-250);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-primary-300 {
    --tw-shadow-color: var(--color-primary-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-primary-400 {
    --tw-shadow-color: var(--color-primary-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-primary-500 {
    --tw-shadow-color: var(--color-primary-500);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-primary-600 {
    --tw-shadow-color: var(--color-primary-600);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-success {
    --tw-shadow-color: var(--color-success-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-success-100 {
    --tw-shadow-color: var(--color-success-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-success-200 {
    --tw-shadow-color: var(--color-success-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-success-300 {
    --tw-shadow-color: var(--color-success-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-success-400 {
    --tw-shadow-color: var(--color-success-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent {
    --tw-shadow-color: transparent;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/0 {
    --tw-shadow-color: #0000;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/10 {
    --tw-shadow-color: #0000001a;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/100 {
    --tw-shadow-color: #000;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/15 {
    --tw-shadow-color: #00000026;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/20 {
    --tw-shadow-color: #0003;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/25 {
    --tw-shadow-color: #00000040;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/30 {
    --tw-shadow-color: #0000004d;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/35 {
    --tw-shadow-color: #00000059;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/40 {
    --tw-shadow-color: #0006;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/45 {
    --tw-shadow-color: #00000073;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/5 {
    --tw-shadow-color: #0000000d;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/50 {
    --tw-shadow-color: #00000080;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/55 {
    --tw-shadow-color: #0000008c;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/60 {
    --tw-shadow-color: #0009;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/65 {
    --tw-shadow-color: #000000a6;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/70 {
    --tw-shadow-color: #000000b3;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/75 {
    --tw-shadow-color: #000000bf;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/80 {
    --tw-shadow-color: #000c;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/85 {
    --tw-shadow-color: #000000d9;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/90 {
    --tw-shadow-color: #000000e6;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-transparent\/95 {
    --tw-shadow-color: #000000f2;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-warning {
    --tw-shadow-color: var(--color-warning-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-warning-100 {
    --tw-shadow-color: var(--color-warning-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-warning-200 {
    --tw-shadow-color: var(--color-warning-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-warning-300 {
    --tw-shadow-color: var(--color-warning-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-warning-400 {
    --tw-shadow-color: var(--color-warning-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:shadow-white {
    --tw-shadow-color: var(--color-white);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .lg\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .lg\:outline {
    outline-style: solid;
  }

  .lg\:outline-dashed {
    outline-style: dashed;
  }

  .lg\:outline-dotted {
    outline-style: dotted;
  }

  .lg\:outline-double {
    outline-style: double;
  }

  .lg\:outline-0 {
    outline-width: 0;
  }

  .lg\:outline-1 {
    outline-width: 1px;
  }

  .lg\:outline-2 {
    outline-width: 2px;
  }

  .lg\:outline-4 {
    outline-width: 4px;
  }

  .lg\:outline-8 {
    outline-width: 8px;
  }

  .lg\:-outline-offset-0 {
    outline-offset: -0px;
  }

  .lg\:-outline-offset-1 {
    outline-offset: -1px;
  }

  .lg\:-outline-offset-2 {
    outline-offset: -2px;
  }

  .lg\:-outline-offset-4 {
    outline-offset: -4px;
  }

  .lg\:-outline-offset-8 {
    outline-offset: -8px;
  }

  .lg\:outline-offset-0 {
    outline-offset: 0px;
  }

  .lg\:outline-offset-1 {
    outline-offset: 1px;
  }

  .lg\:outline-offset-2 {
    outline-offset: 2px;
  }

  .lg\:outline-offset-4 {
    outline-offset: 4px;
  }

  .lg\:outline-offset-8 {
    outline-offset: 8px;
  }

  .lg\:outline-black {
    outline-color: var(--color-black);
  }

  .lg\:outline-current {
    outline-color: currentColor;
  }

  .lg\:outline-danger {
    outline-color: var(--color-danger-300);
  }

  .lg\:outline-danger-100 {
    outline-color: var(--color-danger-100);
  }

  .lg\:outline-danger-200 {
    outline-color: var(--color-danger-200);
  }

  .lg\:outline-danger-300 {
    outline-color: var(--color-danger-300);
  }

  .lg\:outline-danger-400 {
    outline-color: var(--color-danger-400);
  }

  .lg\:outline-divider {
    outline-color: var(--color-divider);
  }

  .lg\:outline-gray {
    outline-color: var(--color-gray);
  }

  .lg\:outline-info {
    outline-color: var(--color-info-300);
  }

  .lg\:outline-info-100 {
    outline-color: var(--color-info-100);
  }

  .lg\:outline-info-200 {
    outline-color: var(--color-info-200);
  }

  .lg\:outline-info-300 {
    outline-color: var(--color-info-300);
  }

  .lg\:outline-info-400 {
    outline-color: var(--color-info-400);
  }

  .lg\:outline-primary {
    outline-color: var(--color-primary-300);
  }

  .lg\:outline-primary-100 {
    outline-color: var(--color-primary-100);
  }

  .lg\:outline-primary-200 {
    outline-color: var(--color-primary-200);
  }

  .lg\:outline-primary-250 {
    outline-color: var(--color-primary-250);
  }

  .lg\:outline-primary-300 {
    outline-color: var(--color-primary-300);
  }

  .lg\:outline-primary-400 {
    outline-color: var(--color-primary-400);
  }

  .lg\:outline-primary-500 {
    outline-color: var(--color-primary-500);
  }

  .lg\:outline-primary-600 {
    outline-color: var(--color-primary-600);
  }

  .lg\:outline-success {
    outline-color: var(--color-success-300);
  }

  .lg\:outline-success-100 {
    outline-color: var(--color-success-100);
  }

  .lg\:outline-success-200 {
    outline-color: var(--color-success-200);
  }

  .lg\:outline-success-300 {
    outline-color: var(--color-success-300);
  }

  .lg\:outline-success-400 {
    outline-color: var(--color-success-400);
  }

  .lg\:outline-transparent, .lg\:outline-transparent\/0 {
    outline-color: #0000;
  }

  .lg\:outline-transparent\/10 {
    outline-color: #0000001a;
  }

  .lg\:outline-transparent\/100 {
    outline-color: #000;
  }

  .lg\:outline-transparent\/15 {
    outline-color: #00000026;
  }

  .lg\:outline-transparent\/20 {
    outline-color: #0003;
  }

  .lg\:outline-transparent\/25 {
    outline-color: #00000040;
  }

  .lg\:outline-transparent\/30 {
    outline-color: #0000004d;
  }

  .lg\:outline-transparent\/35 {
    outline-color: #00000059;
  }

  .lg\:outline-transparent\/40 {
    outline-color: #0006;
  }

  .lg\:outline-transparent\/45 {
    outline-color: #00000073;
  }

  .lg\:outline-transparent\/5 {
    outline-color: #0000000d;
  }

  .lg\:outline-transparent\/50 {
    outline-color: #00000080;
  }

  .lg\:outline-transparent\/55 {
    outline-color: #0000008c;
  }

  .lg\:outline-transparent\/60 {
    outline-color: #0009;
  }

  .lg\:outline-transparent\/65 {
    outline-color: #000000a6;
  }

  .lg\:outline-transparent\/70 {
    outline-color: #000000b3;
  }

  .lg\:outline-transparent\/75 {
    outline-color: #000000bf;
  }

  .lg\:outline-transparent\/80 {
    outline-color: #000c;
  }

  .lg\:outline-transparent\/85 {
    outline-color: #000000d9;
  }

  .lg\:outline-transparent\/90 {
    outline-color: #000000e6;
  }

  .lg\:outline-transparent\/95 {
    outline-color: #000000f2;
  }

  .lg\:outline-warning {
    outline-color: var(--color-warning-300);
  }

  .lg\:outline-warning-100 {
    outline-color: var(--color-warning-100);
  }

  .lg\:outline-warning-200 {
    outline-color: var(--color-warning-200);
  }

  .lg\:outline-warning-300 {
    outline-color: var(--color-warning-300);
  }

  .lg\:outline-warning-400 {
    outline-color: var(--color-warning-400);
  }

  .lg\:outline-white {
    outline-color: var(--color-white);
  }

  .lg\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-inset {
    --tw-ring-inset: inset;
  }

  .lg\:ring-black {
    --tw-ring-color: var(--color-black);
  }

  .lg\:ring-current {
    --tw-ring-color: currentColor;
  }

  .lg\:ring-danger {
    --tw-ring-color: var(--color-danger-300);
  }

  .lg\:ring-danger-100 {
    --tw-ring-color: var(--color-danger-100);
  }

  .lg\:ring-danger-200 {
    --tw-ring-color: var(--color-danger-200);
  }

  .lg\:ring-danger-300 {
    --tw-ring-color: var(--color-danger-300);
  }

  .lg\:ring-danger-400 {
    --tw-ring-color: var(--color-danger-400);
  }

  .lg\:ring-divider {
    --tw-ring-color: var(--color-divider);
  }

  .lg\:ring-gray {
    --tw-ring-color: var(--color-gray);
  }

  .lg\:ring-info {
    --tw-ring-color: var(--color-info-300);
  }

  .lg\:ring-info-100 {
    --tw-ring-color: var(--color-info-100);
  }

  .lg\:ring-info-200 {
    --tw-ring-color: var(--color-info-200);
  }

  .lg\:ring-info-300 {
    --tw-ring-color: var(--color-info-300);
  }

  .lg\:ring-info-400 {
    --tw-ring-color: var(--color-info-400);
  }

  .lg\:ring-primary {
    --tw-ring-color: var(--color-primary-300);
  }

  .lg\:ring-primary-100 {
    --tw-ring-color: var(--color-primary-100);
  }

  .lg\:ring-primary-200 {
    --tw-ring-color: var(--color-primary-200);
  }

  .lg\:ring-primary-250 {
    --tw-ring-color: var(--color-primary-250);
  }

  .lg\:ring-primary-300 {
    --tw-ring-color: var(--color-primary-300);
  }

  .lg\:ring-primary-400 {
    --tw-ring-color: var(--color-primary-400);
  }

  .lg\:ring-primary-500 {
    --tw-ring-color: var(--color-primary-500);
  }

  .lg\:ring-primary-600 {
    --tw-ring-color: var(--color-primary-600);
  }

  .lg\:ring-success {
    --tw-ring-color: var(--color-success-300);
  }

  .lg\:ring-success-100 {
    --tw-ring-color: var(--color-success-100);
  }

  .lg\:ring-success-200 {
    --tw-ring-color: var(--color-success-200);
  }

  .lg\:ring-success-300 {
    --tw-ring-color: var(--color-success-300);
  }

  .lg\:ring-success-400 {
    --tw-ring-color: var(--color-success-400);
  }

  .lg\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .lg\:ring-transparent\/0 {
    --tw-ring-color: #0000;
  }

  .lg\:ring-transparent\/10 {
    --tw-ring-color: #0000001a;
  }

  .lg\:ring-transparent\/100 {
    --tw-ring-color: #000;
  }

  .lg\:ring-transparent\/15 {
    --tw-ring-color: #00000026;
  }

  .lg\:ring-transparent\/20 {
    --tw-ring-color: #0003;
  }

  .lg\:ring-transparent\/25 {
    --tw-ring-color: #00000040;
  }

  .lg\:ring-transparent\/30 {
    --tw-ring-color: #0000004d;
  }

  .lg\:ring-transparent\/35 {
    --tw-ring-color: #00000059;
  }

  .lg\:ring-transparent\/40 {
    --tw-ring-color: #0006;
  }

  .lg\:ring-transparent\/45 {
    --tw-ring-color: #00000073;
  }

  .lg\:ring-transparent\/5 {
    --tw-ring-color: #0000000d;
  }

  .lg\:ring-transparent\/50 {
    --tw-ring-color: #00000080;
  }

  .lg\:ring-transparent\/55 {
    --tw-ring-color: #0000008c;
  }

  .lg\:ring-transparent\/60 {
    --tw-ring-color: #0009;
  }

  .lg\:ring-transparent\/65 {
    --tw-ring-color: #000000a6;
  }

  .lg\:ring-transparent\/70 {
    --tw-ring-color: #000000b3;
  }

  .lg\:ring-transparent\/75 {
    --tw-ring-color: #000000bf;
  }

  .lg\:ring-transparent\/80 {
    --tw-ring-color: #000c;
  }

  .lg\:ring-transparent\/85 {
    --tw-ring-color: #000000d9;
  }

  .lg\:ring-transparent\/90 {
    --tw-ring-color: #000000e6;
  }

  .lg\:ring-transparent\/95 {
    --tw-ring-color: #000000f2;
  }

  .lg\:ring-warning {
    --tw-ring-color: var(--color-warning-300);
  }

  .lg\:ring-warning-100 {
    --tw-ring-color: var(--color-warning-100);
  }

  .lg\:ring-warning-200 {
    --tw-ring-color: var(--color-warning-200);
  }

  .lg\:ring-warning-300 {
    --tw-ring-color: var(--color-warning-300);
  }

  .lg\:ring-warning-400 {
    --tw-ring-color: var(--color-warning-400);
  }

  .lg\:ring-white {
    --tw-ring-color: var(--color-white);
  }

  .lg\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .lg\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .lg\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .lg\:ring-opacity-15 {
    --tw-ring-opacity: .15;
  }

  .lg\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .lg\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .lg\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .lg\:ring-opacity-35 {
    --tw-ring-opacity: .35;
  }

  .lg\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .lg\:ring-opacity-45 {
    --tw-ring-opacity: .45;
  }

  .lg\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .lg\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .lg\:ring-opacity-55 {
    --tw-ring-opacity: .55;
  }

  .lg\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .lg\:ring-opacity-65 {
    --tw-ring-opacity: .65;
  }

  .lg\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .lg\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .lg\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .lg\:ring-opacity-85 {
    --tw-ring-opacity: .85;
  }

  .lg\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .lg\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .lg\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .lg\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .lg\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .lg\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .lg\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .lg\:ring-offset-black {
    --tw-ring-offset-color: var(--color-black);
  }

  .lg\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .lg\:ring-offset-danger {
    --tw-ring-offset-color: var(--color-danger-300);
  }

  .lg\:ring-offset-danger-100 {
    --tw-ring-offset-color: var(--color-danger-100);
  }

  .lg\:ring-offset-danger-200 {
    --tw-ring-offset-color: var(--color-danger-200);
  }

  .lg\:ring-offset-danger-300 {
    --tw-ring-offset-color: var(--color-danger-300);
  }

  .lg\:ring-offset-danger-400 {
    --tw-ring-offset-color: var(--color-danger-400);
  }

  .lg\:ring-offset-divider {
    --tw-ring-offset-color: var(--color-divider);
  }

  .lg\:ring-offset-gray {
    --tw-ring-offset-color: var(--color-gray);
  }

  .lg\:ring-offset-info {
    --tw-ring-offset-color: var(--color-info-300);
  }

  .lg\:ring-offset-info-100 {
    --tw-ring-offset-color: var(--color-info-100);
  }

  .lg\:ring-offset-info-200 {
    --tw-ring-offset-color: var(--color-info-200);
  }

  .lg\:ring-offset-info-300 {
    --tw-ring-offset-color: var(--color-info-300);
  }

  .lg\:ring-offset-info-400 {
    --tw-ring-offset-color: var(--color-info-400);
  }

  .lg\:ring-offset-primary {
    --tw-ring-offset-color: var(--color-primary-300);
  }

  .lg\:ring-offset-primary-100 {
    --tw-ring-offset-color: var(--color-primary-100);
  }

  .lg\:ring-offset-primary-200 {
    --tw-ring-offset-color: var(--color-primary-200);
  }

  .lg\:ring-offset-primary-250 {
    --tw-ring-offset-color: var(--color-primary-250);
  }

  .lg\:ring-offset-primary-300 {
    --tw-ring-offset-color: var(--color-primary-300);
  }

  .lg\:ring-offset-primary-400 {
    --tw-ring-offset-color: var(--color-primary-400);
  }

  .lg\:ring-offset-primary-500 {
    --tw-ring-offset-color: var(--color-primary-500);
  }

  .lg\:ring-offset-primary-600 {
    --tw-ring-offset-color: var(--color-primary-600);
  }

  .lg\:ring-offset-success {
    --tw-ring-offset-color: var(--color-success-300);
  }

  .lg\:ring-offset-success-100 {
    --tw-ring-offset-color: var(--color-success-100);
  }

  .lg\:ring-offset-success-200 {
    --tw-ring-offset-color: var(--color-success-200);
  }

  .lg\:ring-offset-success-300 {
    --tw-ring-offset-color: var(--color-success-300);
  }

  .lg\:ring-offset-success-400 {
    --tw-ring-offset-color: var(--color-success-400);
  }

  .lg\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .lg\:ring-offset-transparent\/0 {
    --tw-ring-offset-color: #0000;
  }

  .lg\:ring-offset-transparent\/10 {
    --tw-ring-offset-color: #0000001a;
  }

  .lg\:ring-offset-transparent\/100 {
    --tw-ring-offset-color: #000;
  }

  .lg\:ring-offset-transparent\/15 {
    --tw-ring-offset-color: #00000026;
  }

  .lg\:ring-offset-transparent\/20 {
    --tw-ring-offset-color: #0003;
  }

  .lg\:ring-offset-transparent\/25 {
    --tw-ring-offset-color: #00000040;
  }

  .lg\:ring-offset-transparent\/30 {
    --tw-ring-offset-color: #0000004d;
  }

  .lg\:ring-offset-transparent\/35 {
    --tw-ring-offset-color: #00000059;
  }

  .lg\:ring-offset-transparent\/40 {
    --tw-ring-offset-color: #0006;
  }

  .lg\:ring-offset-transparent\/45 {
    --tw-ring-offset-color: #00000073;
  }

  .lg\:ring-offset-transparent\/5 {
    --tw-ring-offset-color: #0000000d;
  }

  .lg\:ring-offset-transparent\/50 {
    --tw-ring-offset-color: #00000080;
  }

  .lg\:ring-offset-transparent\/55 {
    --tw-ring-offset-color: #0000008c;
  }

  .lg\:ring-offset-transparent\/60 {
    --tw-ring-offset-color: #0009;
  }

  .lg\:ring-offset-transparent\/65 {
    --tw-ring-offset-color: #000000a6;
  }

  .lg\:ring-offset-transparent\/70 {
    --tw-ring-offset-color: #000000b3;
  }

  .lg\:ring-offset-transparent\/75 {
    --tw-ring-offset-color: #000000bf;
  }

  .lg\:ring-offset-transparent\/80 {
    --tw-ring-offset-color: #000c;
  }

  .lg\:ring-offset-transparent\/85 {
    --tw-ring-offset-color: #000000d9;
  }

  .lg\:ring-offset-transparent\/90 {
    --tw-ring-offset-color: #000000e6;
  }

  .lg\:ring-offset-transparent\/95 {
    --tw-ring-offset-color: #000000f2;
  }

  .lg\:ring-offset-warning {
    --tw-ring-offset-color: var(--color-warning-300);
  }

  .lg\:ring-offset-warning-100 {
    --tw-ring-offset-color: var(--color-warning-100);
  }

  .lg\:ring-offset-warning-200 {
    --tw-ring-offset-color: var(--color-warning-200);
  }

  .lg\:ring-offset-warning-300 {
    --tw-ring-offset-color: var(--color-warning-300);
  }

  .lg\:ring-offset-warning-400 {
    --tw-ring-offset-color: var(--color-warning-400);
  }

  .lg\:ring-offset-white {
    --tw-ring-offset-color: var(--color-white);
  }

  .lg\:blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:blur-0 {
    --tw-blur: blur(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:blur-2xl {
    --tw-blur: blur(40px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:blur-3xl {
    --tw-blur: blur(64px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:blur-lg {
    --tw-blur: blur(16px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:blur-md {
    --tw-blur: blur(12px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:blur-none {
    --tw-blur: ;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:blur-sm {
    --tw-blur: blur(4px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:blur-xl {
    --tw-blur: blur(24px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-0 {
    --tw-brightness: brightness(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-100 {
    --tw-brightness: brightness(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-105 {
    --tw-brightness: brightness(1.05);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-110 {
    --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-125 {
    --tw-brightness: brightness(1.25);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-150 {
    --tw-brightness: brightness(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-200 {
    --tw-brightness: brightness(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-50 {
    --tw-brightness: brightness(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-75 {
    --tw-brightness: brightness(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-90 {
    --tw-brightness: brightness(.9);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:brightness-95 {
    --tw-brightness: brightness(.95);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:contrast-0 {
    --tw-contrast: contrast(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:contrast-100 {
    --tw-contrast: contrast(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:contrast-125 {
    --tw-contrast: contrast(1.25);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:contrast-150 {
    --tw-contrast: contrast(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:contrast-200 {
    --tw-contrast: contrast(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:contrast-50 {
    --tw-contrast: contrast(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:contrast-75 {
    --tw-contrast: contrast(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:grayscale-0 {
    --tw-grayscale: grayscale(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:-hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(-0deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:invert-0 {
    --tw-invert: invert(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:saturate-0 {
    --tw-saturate: saturate(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:saturate-100 {
    --tw-saturate: saturate(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:saturate-150 {
    --tw-saturate: saturate(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:saturate-200 {
    --tw-saturate: saturate(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:saturate-50 {
    --tw-saturate: saturate(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:sepia {
    --tw-sepia: sepia(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:sepia-0 {
    --tw-sepia: sepia(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:filter-none {
    filter: none;
  }

  .lg\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-blur-none {
    --tw-backdrop-blur: ;
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:-backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(-0deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-15 {
    --tw-backdrop-opacity: opacity(.15);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-35 {
    --tw-backdrop-opacity: opacity(.35);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-45 {
    --tw-backdrop-opacity: opacity(.45);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-55 {
    --tw-backdrop-opacity: opacity(.55);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-65 {
    --tw-backdrop-opacity: opacity(.65);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-85 {
    --tw-backdrop-opacity: opacity(.85);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-filter {
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-filter-none {
    backdrop-filter: none;
  }

  .lg\:transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:transition-none {
    transition-property: none;
  }

  .lg\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:delay-0 {
    transition-delay: 0s;
  }

  .lg\:delay-100 {
    transition-delay: .1s;
  }

  .lg\:delay-1000 {
    transition-delay: 1s;
  }

  .lg\:delay-150 {
    transition-delay: .15s;
  }

  .lg\:delay-200 {
    transition-delay: .2s;
  }

  .lg\:delay-300 {
    transition-delay: .3s;
  }

  .lg\:delay-500 {
    transition-delay: .5s;
  }

  .lg\:delay-700 {
    transition-delay: .7s;
  }

  .lg\:delay-75 {
    transition-delay: 75ms;
  }

  .lg\:duration-0 {
    transition-duration: 0s;
  }

  .lg\:duration-100 {
    transition-duration: .1s;
  }

  .lg\:duration-1000 {
    transition-duration: 1s;
  }

  .lg\:duration-150 {
    transition-duration: .15s;
  }

  .lg\:duration-200 {
    transition-duration: .2s;
  }

  .lg\:duration-300 {
    transition-duration: .3s;
  }

  .lg\:duration-500 {
    transition-duration: .5s;
  }

  .lg\:duration-700 {
    transition-duration: .7s;
  }

  .lg\:duration-75 {
    transition-duration: 75ms;
  }

  .lg\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:ease-linear {
    transition-timing-function: linear;
  }

  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .lg\:will-change-auto {
    will-change: auto;
  }

  .lg\:will-change-contents {
    will-change: contents;
  }

  .lg\:will-change-scroll {
    will-change: scroll-position;
  }

  .lg\:will-change-transform {
    will-change: transform;
  }

  .lg\:contain-none {
    contain: none;
  }

  .lg\:contain-content {
    contain: content;
  }

  .lg\:contain-strict {
    contain: strict;
  }

  .lg\:contain-size {
    --tw-contain-size: size;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .lg\:contain-inline-size {
    --tw-contain-size: inline-size;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .lg\:contain-layout {
    --tw-contain-layout: layout;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .lg\:contain-paint {
    --tw-contain-paint: paint;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .lg\:contain-style {
    --tw-contain-style: style;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .lg\:content-none {
    --tw-content: none;
    content: var(--tw-content);
  }

  .lg\:forced-color-adjust-auto {
    forced-color-adjust: auto;
  }

  .lg\:forced-color-adjust-none {
    forced-color-adjust: none;
  }
}

@media (width >= 1280px) {
  .xl\:sr-only {
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .xl\:not-sr-only {
    clip: auto;
    white-space: normal;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .xl\:pointer-events-none {
    pointer-events: none;
  }

  .xl\:pointer-events-auto {
    pointer-events: auto;
  }

  .xl\:visible {
    visibility: visible;
  }

  .xl\:invisible {
    visibility: hidden;
  }

  .xl\:collapse {
    visibility: collapse;
  }

  .xl\:static {
    position: static;
  }

  .xl\:fixed {
    position: fixed;
  }

  .xl\:absolute {
    position: absolute;
  }

  .xl\:relative {
    position: relative;
  }

  .xl\:sticky {
    position: sticky;
  }

  .xl\:-inset-0 {
    inset: 0;
  }

  .xl\:-inset-0\.5 {
    inset: -.125rem;
  }

  .xl\:-inset-1 {
    inset: -.25rem;
  }

  .xl\:-inset-1\.5 {
    inset: -.375rem;
  }

  .xl\:-inset-1\/2 {
    inset: -50%;
  }

  .xl\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .xl\:-inset-1\/4 {
    inset: -25%;
  }

  .xl\:-inset-10 {
    inset: -2.5rem;
  }

  .xl\:-inset-11 {
    inset: -2.75rem;
  }

  .xl\:-inset-12 {
    inset: -3rem;
  }

  .xl\:-inset-14 {
    inset: -3.5rem;
  }

  .xl\:-inset-16 {
    inset: -4rem;
  }

  .xl\:-inset-2 {
    inset: -.5rem;
  }

  .xl\:-inset-2\.5 {
    inset: -.625rem;
  }

  .xl\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .xl\:-inset-2\/4 {
    inset: -50%;
  }

  .xl\:-inset-20 {
    inset: -5rem;
  }

  .xl\:-inset-24 {
    inset: -6rem;
  }

  .xl\:-inset-28 {
    inset: -7rem;
  }

  .xl\:-inset-3 {
    inset: -.75rem;
  }

  .xl\:-inset-3\.5 {
    inset: -.875rem;
  }

  .xl\:-inset-3\/4 {
    inset: -75%;
  }

  .xl\:-inset-32 {
    inset: -8rem;
  }

  .xl\:-inset-36 {
    inset: -9rem;
  }

  .xl\:-inset-4 {
    inset: -1rem;
  }

  .xl\:-inset-40 {
    inset: -10rem;
  }

  .xl\:-inset-44 {
    inset: -11rem;
  }

  .xl\:-inset-48 {
    inset: -12rem;
  }

  .xl\:-inset-5 {
    inset: -1.25rem;
  }

  .xl\:-inset-52 {
    inset: -13rem;
  }

  .xl\:-inset-56 {
    inset: -14rem;
  }

  .xl\:-inset-6 {
    inset: -1.5rem;
  }

  .xl\:-inset-60 {
    inset: -15rem;
  }

  .xl\:-inset-64 {
    inset: -16rem;
  }

  .xl\:-inset-7 {
    inset: -1.75rem;
  }

  .xl\:-inset-72 {
    inset: -18rem;
  }

  .xl\:-inset-8 {
    inset: -2rem;
  }

  .xl\:-inset-80 {
    inset: -20rem;
  }

  .xl\:-inset-9 {
    inset: -2.25rem;
  }

  .xl\:-inset-96 {
    inset: -24rem;
  }

  .xl\:-inset-full {
    inset: -100%;
  }

  .xl\:-inset-px {
    inset: -1px;
  }

  .xl\:inset-0 {
    inset: 0;
  }

  .xl\:inset-0\.5 {
    inset: .125rem;
  }

  .xl\:inset-1 {
    inset: .25rem;
  }

  .xl\:inset-1\.5 {
    inset: .375rem;
  }

  .xl\:inset-1\/2 {
    inset: 50%;
  }

  .xl\:inset-1\/3 {
    inset: 33.3333%;
  }

  .xl\:inset-1\/4 {
    inset: 25%;
  }

  .xl\:inset-10 {
    inset: 2.5rem;
  }

  .xl\:inset-11 {
    inset: 2.75rem;
  }

  .xl\:inset-12 {
    inset: 3rem;
  }

  .xl\:inset-14 {
    inset: 3.5rem;
  }

  .xl\:inset-16 {
    inset: 4rem;
  }

  .xl\:inset-2 {
    inset: .5rem;
  }

  .xl\:inset-2\.5 {
    inset: .625rem;
  }

  .xl\:inset-2\/3 {
    inset: 66.6667%;
  }

  .xl\:inset-2\/4 {
    inset: 50%;
  }

  .xl\:inset-20 {
    inset: 5rem;
  }

  .xl\:inset-24 {
    inset: 6rem;
  }

  .xl\:inset-28 {
    inset: 7rem;
  }

  .xl\:inset-3 {
    inset: .75rem;
  }

  .xl\:inset-3\.5 {
    inset: .875rem;
  }

  .xl\:inset-3\/4 {
    inset: 75%;
  }

  .xl\:inset-32 {
    inset: 8rem;
  }

  .xl\:inset-36 {
    inset: 9rem;
  }

  .xl\:inset-4 {
    inset: 1rem;
  }

  .xl\:inset-40 {
    inset: 10rem;
  }

  .xl\:inset-44 {
    inset: 11rem;
  }

  .xl\:inset-48 {
    inset: 12rem;
  }

  .xl\:inset-5 {
    inset: 1.25rem;
  }

  .xl\:inset-52 {
    inset: 13rem;
  }

  .xl\:inset-56 {
    inset: 14rem;
  }

  .xl\:inset-6 {
    inset: 1.5rem;
  }

  .xl\:inset-60 {
    inset: 15rem;
  }

  .xl\:inset-64 {
    inset: 16rem;
  }

  .xl\:inset-7 {
    inset: 1.75rem;
  }

  .xl\:inset-72 {
    inset: 18rem;
  }

  .xl\:inset-8 {
    inset: 2rem;
  }

  .xl\:inset-80 {
    inset: 20rem;
  }

  .xl\:inset-9 {
    inset: 2.25rem;
  }

  .xl\:inset-96 {
    inset: 24rem;
  }

  .xl\:inset-auto {
    inset: auto;
  }

  .xl\:inset-full {
    inset: 100%;
  }

  .xl\:inset-px {
    inset: 1px;
  }

  .xl\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .xl\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .xl\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .xl\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .xl\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .xl\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .xl\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .xl\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .xl\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .xl\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .xl\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .xl\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .xl\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .xl\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .xl\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .xl\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .xl\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .xl\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .xl\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .xl\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .xl\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .xl\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .xl\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .xl\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .xl\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .xl\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .xl\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .xl\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .xl\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .xl\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .xl\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .xl\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .xl\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .xl\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .xl\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .xl\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .xl\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .xl\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .xl\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .xl\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .xl\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .xl\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .xl\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .xl\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .xl\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .xl\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .xl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .xl\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .xl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .xl\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .xl\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .xl\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .xl\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .xl\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .xl\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .xl\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .xl\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .xl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .xl\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .xl\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .xl\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .xl\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .xl\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .xl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .xl\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .xl\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .xl\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .xl\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .xl\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .xl\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .xl\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .xl\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .xl\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .xl\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .xl\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .xl\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .xl\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .xl\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .xl\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .xl\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .xl\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .xl\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .xl\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .xl\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .xl\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .xl\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .xl\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .xl\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .xl\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .xl\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .xl\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .xl\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .xl\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .xl\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .xl\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .xl\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .xl\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .xl\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .xl\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .xl\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .xl\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .xl\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .xl\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .xl\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .xl\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .xl\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .xl\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .xl\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .xl\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .xl\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .xl\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .xl\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .xl\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .xl\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .xl\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .xl\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .xl\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .xl\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .xl\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .xl\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .xl\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .xl\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .xl\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .xl\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .xl\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .xl\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .xl\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .xl\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .xl\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .xl\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .xl\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .xl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .xl\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .xl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .xl\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .xl\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .xl\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .xl\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .xl\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .xl\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .xl\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .xl\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .xl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .xl\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .xl\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .xl\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .xl\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .xl\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .xl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .xl\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .xl\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .xl\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .xl\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .xl\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .xl\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .xl\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .xl\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .xl\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .xl\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .xl\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .xl\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .xl\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .xl\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .xl\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .xl\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .xl\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .xl\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .xl\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .xl\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .xl\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .xl\:-bottom-0 {
    bottom: 0;
  }

  .xl\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .xl\:-bottom-1 {
    bottom: -.25rem;
  }

  .xl\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .xl\:-bottom-1\/2 {
    bottom: -50%;
  }

  .xl\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .xl\:-bottom-1\/4 {
    bottom: -25%;
  }

  .xl\:-bottom-10 {
    bottom: -2.5rem;
  }

  .xl\:-bottom-11 {
    bottom: -2.75rem;
  }

  .xl\:-bottom-12 {
    bottom: -3rem;
  }

  .xl\:-bottom-14 {
    bottom: -3.5rem;
  }

  .xl\:-bottom-16 {
    bottom: -4rem;
  }

  .xl\:-bottom-2 {
    bottom: -.5rem;
  }

  .xl\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .xl\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .xl\:-bottom-2\/4 {
    bottom: -50%;
  }

  .xl\:-bottom-20 {
    bottom: -5rem;
  }

  .xl\:-bottom-24 {
    bottom: -6rem;
  }

  .xl\:-bottom-28 {
    bottom: -7rem;
  }

  .xl\:-bottom-3 {
    bottom: -.75rem;
  }

  .xl\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .xl\:-bottom-3\/4 {
    bottom: -75%;
  }

  .xl\:-bottom-32 {
    bottom: -8rem;
  }

  .xl\:-bottom-36 {
    bottom: -9rem;
  }

  .xl\:-bottom-4 {
    bottom: -1rem;
  }

  .xl\:-bottom-40 {
    bottom: -10rem;
  }

  .xl\:-bottom-44 {
    bottom: -11rem;
  }

  .xl\:-bottom-48 {
    bottom: -12rem;
  }

  .xl\:-bottom-5 {
    bottom: -1.25rem;
  }

  .xl\:-bottom-52 {
    bottom: -13rem;
  }

  .xl\:-bottom-56 {
    bottom: -14rem;
  }

  .xl\:-bottom-6 {
    bottom: -1.5rem;
  }

  .xl\:-bottom-60 {
    bottom: -15rem;
  }

  .xl\:-bottom-64 {
    bottom: -16rem;
  }

  .xl\:-bottom-7 {
    bottom: -1.75rem;
  }

  .xl\:-bottom-72 {
    bottom: -18rem;
  }

  .xl\:-bottom-8 {
    bottom: -2rem;
  }

  .xl\:-bottom-80 {
    bottom: -20rem;
  }

  .xl\:-bottom-9 {
    bottom: -2.25rem;
  }

  .xl\:-bottom-96 {
    bottom: -24rem;
  }

  .xl\:-bottom-full {
    bottom: -100%;
  }

  .xl\:-bottom-px {
    bottom: -1px;
  }

  .xl\:-end-0 {
    inset-inline-end: 0;
  }

  .xl\:-end-0\.5 {
    inset-inline-end: -.125rem;
  }

  .xl\:-end-1 {
    inset-inline-end: -.25rem;
  }

  .xl\:-end-1\.5 {
    inset-inline-end: -.375rem;
  }

  .xl\:-end-1\/2 {
    inset-inline-end: -50%;
  }

  .xl\:-end-1\/3 {
    inset-inline-end: -33.3333%;
  }

  .xl\:-end-1\/4 {
    inset-inline-end: -25%;
  }

  .xl\:-end-10 {
    inset-inline-end: -2.5rem;
  }

  .xl\:-end-11 {
    inset-inline-end: -2.75rem;
  }

  .xl\:-end-12 {
    inset-inline-end: -3rem;
  }

  .xl\:-end-14 {
    inset-inline-end: -3.5rem;
  }

  .xl\:-end-16 {
    inset-inline-end: -4rem;
  }

  .xl\:-end-2 {
    inset-inline-end: -.5rem;
  }

  .xl\:-end-2\.5 {
    inset-inline-end: -.625rem;
  }

  .xl\:-end-2\/3 {
    inset-inline-end: -66.6667%;
  }

  .xl\:-end-2\/4 {
    inset-inline-end: -50%;
  }

  .xl\:-end-20 {
    inset-inline-end: -5rem;
  }

  .xl\:-end-24 {
    inset-inline-end: -6rem;
  }

  .xl\:-end-28 {
    inset-inline-end: -7rem;
  }

  .xl\:-end-3 {
    inset-inline-end: -.75rem;
  }

  .xl\:-end-3\.5 {
    inset-inline-end: -.875rem;
  }

  .xl\:-end-3\/4 {
    inset-inline-end: -75%;
  }

  .xl\:-end-32 {
    inset-inline-end: -8rem;
  }

  .xl\:-end-36 {
    inset-inline-end: -9rem;
  }

  .xl\:-end-4 {
    inset-inline-end: -1rem;
  }

  .xl\:-end-40 {
    inset-inline-end: -10rem;
  }

  .xl\:-end-44 {
    inset-inline-end: -11rem;
  }

  .xl\:-end-48 {
    inset-inline-end: -12rem;
  }

  .xl\:-end-5 {
    inset-inline-end: -1.25rem;
  }

  .xl\:-end-52 {
    inset-inline-end: -13rem;
  }

  .xl\:-end-56 {
    inset-inline-end: -14rem;
  }

  .xl\:-end-6 {
    inset-inline-end: -1.5rem;
  }

  .xl\:-end-60 {
    inset-inline-end: -15rem;
  }

  .xl\:-end-64 {
    inset-inline-end: -16rem;
  }

  .xl\:-end-7 {
    inset-inline-end: -1.75rem;
  }

  .xl\:-end-72 {
    inset-inline-end: -18rem;
  }

  .xl\:-end-8 {
    inset-inline-end: -2rem;
  }

  .xl\:-end-80 {
    inset-inline-end: -20rem;
  }

  .xl\:-end-9 {
    inset-inline-end: -2.25rem;
  }

  .xl\:-end-96 {
    inset-inline-end: -24rem;
  }

  .xl\:-end-full {
    inset-inline-end: -100%;
  }

  .xl\:-end-px {
    inset-inline-end: -1px;
  }

  .xl\:-left-0 {
    left: 0;
  }

  .xl\:-left-0\.5 {
    left: -.125rem;
  }

  .xl\:-left-1 {
    left: -.25rem;
  }

  .xl\:-left-1\.5 {
    left: -.375rem;
  }

  .xl\:-left-1\/2 {
    left: -50%;
  }

  .xl\:-left-1\/3 {
    left: -33.3333%;
  }

  .xl\:-left-1\/4 {
    left: -25%;
  }

  .xl\:-left-10 {
    left: -2.5rem;
  }

  .xl\:-left-11 {
    left: -2.75rem;
  }

  .xl\:-left-12 {
    left: -3rem;
  }

  .xl\:-left-14 {
    left: -3.5rem;
  }

  .xl\:-left-16 {
    left: -4rem;
  }

  .xl\:-left-2 {
    left: -.5rem;
  }

  .xl\:-left-2\.5 {
    left: -.625rem;
  }

  .xl\:-left-2\/3 {
    left: -66.6667%;
  }

  .xl\:-left-2\/4 {
    left: -50%;
  }

  .xl\:-left-20 {
    left: -5rem;
  }

  .xl\:-left-24 {
    left: -6rem;
  }

  .xl\:-left-28 {
    left: -7rem;
  }

  .xl\:-left-3 {
    left: -.75rem;
  }

  .xl\:-left-3\.5 {
    left: -.875rem;
  }

  .xl\:-left-3\/4 {
    left: -75%;
  }

  .xl\:-left-32 {
    left: -8rem;
  }

  .xl\:-left-36 {
    left: -9rem;
  }

  .xl\:-left-4 {
    left: -1rem;
  }

  .xl\:-left-40 {
    left: -10rem;
  }

  .xl\:-left-44 {
    left: -11rem;
  }

  .xl\:-left-48 {
    left: -12rem;
  }

  .xl\:-left-5 {
    left: -1.25rem;
  }

  .xl\:-left-52 {
    left: -13rem;
  }

  .xl\:-left-56 {
    left: -14rem;
  }

  .xl\:-left-6 {
    left: -1.5rem;
  }

  .xl\:-left-60 {
    left: -15rem;
  }

  .xl\:-left-64 {
    left: -16rem;
  }

  .xl\:-left-7 {
    left: -1.75rem;
  }

  .xl\:-left-72 {
    left: -18rem;
  }

  .xl\:-left-8 {
    left: -2rem;
  }

  .xl\:-left-80 {
    left: -20rem;
  }

  .xl\:-left-9 {
    left: -2.25rem;
  }

  .xl\:-left-96 {
    left: -24rem;
  }

  .xl\:-left-full {
    left: -100%;
  }

  .xl\:-left-px {
    left: -1px;
  }

  .xl\:-right-0 {
    right: 0;
  }

  .xl\:-right-0\.5 {
    right: -.125rem;
  }

  .xl\:-right-1 {
    right: -.25rem;
  }

  .xl\:-right-1\.5 {
    right: -.375rem;
  }

  .xl\:-right-1\/2 {
    right: -50%;
  }

  .xl\:-right-1\/3 {
    right: -33.3333%;
  }

  .xl\:-right-1\/4 {
    right: -25%;
  }

  .xl\:-right-10 {
    right: -2.5rem;
  }

  .xl\:-right-11 {
    right: -2.75rem;
  }

  .xl\:-right-12 {
    right: -3rem;
  }

  .xl\:-right-14 {
    right: -3.5rem;
  }

  .xl\:-right-16 {
    right: -4rem;
  }

  .xl\:-right-2 {
    right: -.5rem;
  }

  .xl\:-right-2\.5 {
    right: -.625rem;
  }

  .xl\:-right-2\/3 {
    right: -66.6667%;
  }

  .xl\:-right-2\/4 {
    right: -50%;
  }

  .xl\:-right-20 {
    right: -5rem;
  }

  .xl\:-right-24 {
    right: -6rem;
  }

  .xl\:-right-28 {
    right: -7rem;
  }

  .xl\:-right-3 {
    right: -.75rem;
  }

  .xl\:-right-3\.5 {
    right: -.875rem;
  }

  .xl\:-right-3\/4 {
    right: -75%;
  }

  .xl\:-right-32 {
    right: -8rem;
  }

  .xl\:-right-36 {
    right: -9rem;
  }

  .xl\:-right-4 {
    right: -1rem;
  }

  .xl\:-right-40 {
    right: -10rem;
  }

  .xl\:-right-44 {
    right: -11rem;
  }

  .xl\:-right-48 {
    right: -12rem;
  }

  .xl\:-right-5 {
    right: -1.25rem;
  }

  .xl\:-right-52 {
    right: -13rem;
  }

  .xl\:-right-56 {
    right: -14rem;
  }

  .xl\:-right-6 {
    right: -1.5rem;
  }

  .xl\:-right-60 {
    right: -15rem;
  }

  .xl\:-right-64 {
    right: -16rem;
  }

  .xl\:-right-7 {
    right: -1.75rem;
  }

  .xl\:-right-72 {
    right: -18rem;
  }

  .xl\:-right-8 {
    right: -2rem;
  }

  .xl\:-right-80 {
    right: -20rem;
  }

  .xl\:-right-9 {
    right: -2.25rem;
  }

  .xl\:-right-96 {
    right: -24rem;
  }

  .xl\:-right-full {
    right: -100%;
  }

  .xl\:-right-px {
    right: -1px;
  }

  .xl\:-start-0 {
    inset-inline-start: 0;
  }

  .xl\:-start-0\.5 {
    inset-inline-start: -.125rem;
  }

  .xl\:-start-1 {
    inset-inline-start: -.25rem;
  }

  .xl\:-start-1\.5 {
    inset-inline-start: -.375rem;
  }

  .xl\:-start-1\/2 {
    inset-inline-start: -50%;
  }

  .xl\:-start-1\/3 {
    inset-inline-start: -33.3333%;
  }

  .xl\:-start-1\/4 {
    inset-inline-start: -25%;
  }

  .xl\:-start-10 {
    inset-inline-start: -2.5rem;
  }

  .xl\:-start-11 {
    inset-inline-start: -2.75rem;
  }

  .xl\:-start-12 {
    inset-inline-start: -3rem;
  }

  .xl\:-start-14 {
    inset-inline-start: -3.5rem;
  }

  .xl\:-start-16 {
    inset-inline-start: -4rem;
  }

  .xl\:-start-2 {
    inset-inline-start: -.5rem;
  }

  .xl\:-start-2\.5 {
    inset-inline-start: -.625rem;
  }

  .xl\:-start-2\/3 {
    inset-inline-start: -66.6667%;
  }

  .xl\:-start-2\/4 {
    inset-inline-start: -50%;
  }

  .xl\:-start-20 {
    inset-inline-start: -5rem;
  }

  .xl\:-start-24 {
    inset-inline-start: -6rem;
  }

  .xl\:-start-28 {
    inset-inline-start: -7rem;
  }

  .xl\:-start-3 {
    inset-inline-start: -.75rem;
  }

  .xl\:-start-3\.5 {
    inset-inline-start: -.875rem;
  }

  .xl\:-start-3\/4 {
    inset-inline-start: -75%;
  }

  .xl\:-start-32 {
    inset-inline-start: -8rem;
  }

  .xl\:-start-36 {
    inset-inline-start: -9rem;
  }

  .xl\:-start-4 {
    inset-inline-start: -1rem;
  }

  .xl\:-start-40 {
    inset-inline-start: -10rem;
  }

  .xl\:-start-44 {
    inset-inline-start: -11rem;
  }

  .xl\:-start-48 {
    inset-inline-start: -12rem;
  }

  .xl\:-start-5 {
    inset-inline-start: -1.25rem;
  }

  .xl\:-start-52 {
    inset-inline-start: -13rem;
  }

  .xl\:-start-56 {
    inset-inline-start: -14rem;
  }

  .xl\:-start-6 {
    inset-inline-start: -1.5rem;
  }

  .xl\:-start-60 {
    inset-inline-start: -15rem;
  }

  .xl\:-start-64 {
    inset-inline-start: -16rem;
  }

  .xl\:-start-7 {
    inset-inline-start: -1.75rem;
  }

  .xl\:-start-72 {
    inset-inline-start: -18rem;
  }

  .xl\:-start-8 {
    inset-inline-start: -2rem;
  }

  .xl\:-start-80 {
    inset-inline-start: -20rem;
  }

  .xl\:-start-9 {
    inset-inline-start: -2.25rem;
  }

  .xl\:-start-96 {
    inset-inline-start: -24rem;
  }

  .xl\:-start-full {
    inset-inline-start: -100%;
  }

  .xl\:-start-px {
    inset-inline-start: -1px;
  }

  .xl\:-top-0 {
    top: 0;
  }

  .xl\:-top-0\.5 {
    top: -.125rem;
  }

  .xl\:-top-1 {
    top: -.25rem;
  }

  .xl\:-top-1\.5 {
    top: -.375rem;
  }

  .xl\:-top-1\/2 {
    top: -50%;
  }

  .xl\:-top-1\/3 {
    top: -33.3333%;
  }

  .xl\:-top-1\/4 {
    top: -25%;
  }

  .xl\:-top-10 {
    top: -2.5rem;
  }

  .xl\:-top-11 {
    top: -2.75rem;
  }

  .xl\:-top-12 {
    top: -3rem;
  }

  .xl\:-top-14 {
    top: -3.5rem;
  }

  .xl\:-top-16 {
    top: -4rem;
  }

  .xl\:-top-2 {
    top: -.5rem;
  }

  .xl\:-top-2\.5 {
    top: -.625rem;
  }

  .xl\:-top-2\/3 {
    top: -66.6667%;
  }

  .xl\:-top-2\/4 {
    top: -50%;
  }

  .xl\:-top-20 {
    top: -5rem;
  }

  .xl\:-top-24 {
    top: -6rem;
  }

  .xl\:-top-28 {
    top: -7rem;
  }

  .xl\:-top-3 {
    top: -.75rem;
  }

  .xl\:-top-3\.5 {
    top: -.875rem;
  }

  .xl\:-top-3\/4 {
    top: -75%;
  }

  .xl\:-top-32 {
    top: -8rem;
  }

  .xl\:-top-36 {
    top: -9rem;
  }

  .xl\:-top-4 {
    top: -1rem;
  }

  .xl\:-top-40 {
    top: -10rem;
  }

  .xl\:-top-44 {
    top: -11rem;
  }

  .xl\:-top-48 {
    top: -12rem;
  }

  .xl\:-top-5 {
    top: -1.25rem;
  }

  .xl\:-top-52 {
    top: -13rem;
  }

  .xl\:-top-56 {
    top: -14rem;
  }

  .xl\:-top-6 {
    top: -1.5rem;
  }

  .xl\:-top-60 {
    top: -15rem;
  }

  .xl\:-top-64 {
    top: -16rem;
  }

  .xl\:-top-7 {
    top: -1.75rem;
  }

  .xl\:-top-72 {
    top: -18rem;
  }

  .xl\:-top-8 {
    top: -2rem;
  }

  .xl\:-top-80 {
    top: -20rem;
  }

  .xl\:-top-9 {
    top: -2.25rem;
  }

  .xl\:-top-96 {
    top: -24rem;
  }

  .xl\:-top-full {
    top: -100%;
  }

  .xl\:-top-px {
    top: -1px;
  }

  .xl\:bottom-0 {
    bottom: 0;
  }

  .xl\:bottom-0\.5 {
    bottom: .125rem;
  }

  .xl\:bottom-1 {
    bottom: .25rem;
  }

  .xl\:bottom-1\.5 {
    bottom: .375rem;
  }

  .xl\:bottom-1\/2 {
    bottom: 50%;
  }

  .xl\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .xl\:bottom-1\/4 {
    bottom: 25%;
  }

  .xl\:bottom-10 {
    bottom: 2.5rem;
  }

  .xl\:bottom-11 {
    bottom: 2.75rem;
  }

  .xl\:bottom-12 {
    bottom: 3rem;
  }

  .xl\:bottom-14 {
    bottom: 3.5rem;
  }

  .xl\:bottom-16 {
    bottom: 4rem;
  }

  .xl\:bottom-2 {
    bottom: .5rem;
  }

  .xl\:bottom-2\.5 {
    bottom: .625rem;
  }

  .xl\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .xl\:bottom-2\/4 {
    bottom: 50%;
  }

  .xl\:bottom-20 {
    bottom: 5rem;
  }

  .xl\:bottom-24 {
    bottom: 6rem;
  }

  .xl\:bottom-28 {
    bottom: 7rem;
  }

  .xl\:bottom-3 {
    bottom: .75rem;
  }

  .xl\:bottom-3\.5 {
    bottom: .875rem;
  }

  .xl\:bottom-3\/4 {
    bottom: 75%;
  }

  .xl\:bottom-32 {
    bottom: 8rem;
  }

  .xl\:bottom-36 {
    bottom: 9rem;
  }

  .xl\:bottom-4 {
    bottom: 1rem;
  }

  .xl\:bottom-40 {
    bottom: 10rem;
  }

  .xl\:bottom-44 {
    bottom: 11rem;
  }

  .xl\:bottom-48 {
    bottom: 12rem;
  }

  .xl\:bottom-5 {
    bottom: 1.25rem;
  }

  .xl\:bottom-52 {
    bottom: 13rem;
  }

  .xl\:bottom-56 {
    bottom: 14rem;
  }

  .xl\:bottom-6 {
    bottom: 1.5rem;
  }

  .xl\:bottom-60 {
    bottom: 15rem;
  }

  .xl\:bottom-64 {
    bottom: 16rem;
  }

  .xl\:bottom-7 {
    bottom: 1.75rem;
  }

  .xl\:bottom-72 {
    bottom: 18rem;
  }

  .xl\:bottom-8 {
    bottom: 2rem;
  }

  .xl\:bottom-80 {
    bottom: 20rem;
  }

  .xl\:bottom-9 {
    bottom: 2.25rem;
  }

  .xl\:bottom-96 {
    bottom: 24rem;
  }

  .xl\:bottom-auto {
    bottom: auto;
  }

  .xl\:bottom-full {
    bottom: 100%;
  }

  .xl\:bottom-px {
    bottom: 1px;
  }

  .xl\:end-0 {
    inset-inline-end: 0;
  }

  .xl\:end-0\.5 {
    inset-inline-end: .125rem;
  }

  .xl\:end-1 {
    inset-inline-end: .25rem;
  }

  .xl\:end-1\.5 {
    inset-inline-end: .375rem;
  }

  .xl\:end-1\/2 {
    inset-inline-end: 50%;
  }

  .xl\:end-1\/3 {
    inset-inline-end: 33.3333%;
  }

  .xl\:end-1\/4 {
    inset-inline-end: 25%;
  }

  .xl\:end-10 {
    inset-inline-end: 2.5rem;
  }

  .xl\:end-11 {
    inset-inline-end: 2.75rem;
  }

  .xl\:end-12 {
    inset-inline-end: 3rem;
  }

  .xl\:end-14 {
    inset-inline-end: 3.5rem;
  }

  .xl\:end-16 {
    inset-inline-end: 4rem;
  }

  .xl\:end-2 {
    inset-inline-end: .5rem;
  }

  .xl\:end-2\.5 {
    inset-inline-end: .625rem;
  }

  .xl\:end-2\/3 {
    inset-inline-end: 66.6667%;
  }

  .xl\:end-2\/4 {
    inset-inline-end: 50%;
  }

  .xl\:end-20 {
    inset-inline-end: 5rem;
  }

  .xl\:end-24 {
    inset-inline-end: 6rem;
  }

  .xl\:end-28 {
    inset-inline-end: 7rem;
  }

  .xl\:end-3 {
    inset-inline-end: .75rem;
  }

  .xl\:end-3\.5 {
    inset-inline-end: .875rem;
  }

  .xl\:end-3\/4 {
    inset-inline-end: 75%;
  }

  .xl\:end-32 {
    inset-inline-end: 8rem;
  }

  .xl\:end-36 {
    inset-inline-end: 9rem;
  }

  .xl\:end-4 {
    inset-inline-end: 1rem;
  }

  .xl\:end-40 {
    inset-inline-end: 10rem;
  }

  .xl\:end-44 {
    inset-inline-end: 11rem;
  }

  .xl\:end-48 {
    inset-inline-end: 12rem;
  }

  .xl\:end-5 {
    inset-inline-end: 1.25rem;
  }

  .xl\:end-52 {
    inset-inline-end: 13rem;
  }

  .xl\:end-56 {
    inset-inline-end: 14rem;
  }

  .xl\:end-6 {
    inset-inline-end: 1.5rem;
  }

  .xl\:end-60 {
    inset-inline-end: 15rem;
  }

  .xl\:end-64 {
    inset-inline-end: 16rem;
  }

  .xl\:end-7 {
    inset-inline-end: 1.75rem;
  }

  .xl\:end-72 {
    inset-inline-end: 18rem;
  }

  .xl\:end-8 {
    inset-inline-end: 2rem;
  }

  .xl\:end-80 {
    inset-inline-end: 20rem;
  }

  .xl\:end-9 {
    inset-inline-end: 2.25rem;
  }

  .xl\:end-96 {
    inset-inline-end: 24rem;
  }

  .xl\:end-auto {
    inset-inline-end: auto;
  }

  .xl\:end-full {
    inset-inline-end: 100%;
  }

  .xl\:end-px {
    inset-inline-end: 1px;
  }

  .xl\:left-0 {
    left: 0;
  }

  .xl\:left-0\.5 {
    left: .125rem;
  }

  .xl\:left-1 {
    left: .25rem;
  }

  .xl\:left-1\.5 {
    left: .375rem;
  }

  .xl\:left-1\/2 {
    left: 50%;
  }

  .xl\:left-1\/3 {
    left: 33.3333%;
  }

  .xl\:left-1\/4 {
    left: 25%;
  }

  .xl\:left-10 {
    left: 2.5rem;
  }

  .xl\:left-11 {
    left: 2.75rem;
  }

  .xl\:left-12 {
    left: 3rem;
  }

  .xl\:left-14 {
    left: 3.5rem;
  }

  .xl\:left-16 {
    left: 4rem;
  }

  .xl\:left-2 {
    left: .5rem;
  }

  .xl\:left-2\.5 {
    left: .625rem;
  }

  .xl\:left-2\/3 {
    left: 66.6667%;
  }

  .xl\:left-2\/4 {
    left: 50%;
  }

  .xl\:left-20 {
    left: 5rem;
  }

  .xl\:left-24 {
    left: 6rem;
  }

  .xl\:left-28 {
    left: 7rem;
  }

  .xl\:left-3 {
    left: .75rem;
  }

  .xl\:left-3\.5 {
    left: .875rem;
  }

  .xl\:left-3\/4 {
    left: 75%;
  }

  .xl\:left-32 {
    left: 8rem;
  }

  .xl\:left-36 {
    left: 9rem;
  }

  .xl\:left-4 {
    left: 1rem;
  }

  .xl\:left-40 {
    left: 10rem;
  }

  .xl\:left-44 {
    left: 11rem;
  }

  .xl\:left-48 {
    left: 12rem;
  }

  .xl\:left-5 {
    left: 1.25rem;
  }

  .xl\:left-52 {
    left: 13rem;
  }

  .xl\:left-56 {
    left: 14rem;
  }

  .xl\:left-6 {
    left: 1.5rem;
  }

  .xl\:left-60 {
    left: 15rem;
  }

  .xl\:left-64 {
    left: 16rem;
  }

  .xl\:left-7 {
    left: 1.75rem;
  }

  .xl\:left-72 {
    left: 18rem;
  }

  .xl\:left-8 {
    left: 2rem;
  }

  .xl\:left-80 {
    left: 20rem;
  }

  .xl\:left-9 {
    left: 2.25rem;
  }

  .xl\:left-96 {
    left: 24rem;
  }

  .xl\:left-auto {
    left: auto;
  }

  .xl\:left-full {
    left: 100%;
  }

  .xl\:left-px {
    left: 1px;
  }

  .xl\:right-0 {
    right: 0;
  }

  .xl\:right-0\.5 {
    right: .125rem;
  }

  .xl\:right-1 {
    right: .25rem;
  }

  .xl\:right-1\.5 {
    right: .375rem;
  }

  .xl\:right-1\/2 {
    right: 50%;
  }

  .xl\:right-1\/3 {
    right: 33.3333%;
  }

  .xl\:right-1\/4 {
    right: 25%;
  }

  .xl\:right-10 {
    right: 2.5rem;
  }

  .xl\:right-11 {
    right: 2.75rem;
  }

  .xl\:right-12 {
    right: 3rem;
  }

  .xl\:right-14 {
    right: 3.5rem;
  }

  .xl\:right-16 {
    right: 4rem;
  }

  .xl\:right-2 {
    right: .5rem;
  }

  .xl\:right-2\.5 {
    right: .625rem;
  }

  .xl\:right-2\/3 {
    right: 66.6667%;
  }

  .xl\:right-2\/4 {
    right: 50%;
  }

  .xl\:right-20 {
    right: 5rem;
  }

  .xl\:right-24 {
    right: 6rem;
  }

  .xl\:right-28 {
    right: 7rem;
  }

  .xl\:right-3 {
    right: .75rem;
  }

  .xl\:right-3\.5 {
    right: .875rem;
  }

  .xl\:right-3\/4 {
    right: 75%;
  }

  .xl\:right-32 {
    right: 8rem;
  }

  .xl\:right-36 {
    right: 9rem;
  }

  .xl\:right-4 {
    right: 1rem;
  }

  .xl\:right-40 {
    right: 10rem;
  }

  .xl\:right-44 {
    right: 11rem;
  }

  .xl\:right-48 {
    right: 12rem;
  }

  .xl\:right-5 {
    right: 1.25rem;
  }

  .xl\:right-52 {
    right: 13rem;
  }

  .xl\:right-56 {
    right: 14rem;
  }

  .xl\:right-6 {
    right: 1.5rem;
  }

  .xl\:right-60 {
    right: 15rem;
  }

  .xl\:right-64 {
    right: 16rem;
  }

  .xl\:right-7 {
    right: 1.75rem;
  }

  .xl\:right-72 {
    right: 18rem;
  }

  .xl\:right-8 {
    right: 2rem;
  }

  .xl\:right-80 {
    right: 20rem;
  }

  .xl\:right-9 {
    right: 2.25rem;
  }

  .xl\:right-96 {
    right: 24rem;
  }

  .xl\:right-auto {
    right: auto;
  }

  .xl\:right-full {
    right: 100%;
  }

  .xl\:right-px {
    right: 1px;
  }

  .xl\:start-0 {
    inset-inline-start: 0;
  }

  .xl\:start-0\.5 {
    inset-inline-start: .125rem;
  }

  .xl\:start-1 {
    inset-inline-start: .25rem;
  }

  .xl\:start-1\.5 {
    inset-inline-start: .375rem;
  }

  .xl\:start-1\/2 {
    inset-inline-start: 50%;
  }

  .xl\:start-1\/3 {
    inset-inline-start: 33.3333%;
  }

  .xl\:start-1\/4 {
    inset-inline-start: 25%;
  }

  .xl\:start-10 {
    inset-inline-start: 2.5rem;
  }

  .xl\:start-11 {
    inset-inline-start: 2.75rem;
  }

  .xl\:start-12 {
    inset-inline-start: 3rem;
  }

  .xl\:start-14 {
    inset-inline-start: 3.5rem;
  }

  .xl\:start-16 {
    inset-inline-start: 4rem;
  }

  .xl\:start-2 {
    inset-inline-start: .5rem;
  }

  .xl\:start-2\.5 {
    inset-inline-start: .625rem;
  }

  .xl\:start-2\/3 {
    inset-inline-start: 66.6667%;
  }

  .xl\:start-2\/4 {
    inset-inline-start: 50%;
  }

  .xl\:start-20 {
    inset-inline-start: 5rem;
  }

  .xl\:start-24 {
    inset-inline-start: 6rem;
  }

  .xl\:start-28 {
    inset-inline-start: 7rem;
  }

  .xl\:start-3 {
    inset-inline-start: .75rem;
  }

  .xl\:start-3\.5 {
    inset-inline-start: .875rem;
  }

  .xl\:start-3\/4 {
    inset-inline-start: 75%;
  }

  .xl\:start-32 {
    inset-inline-start: 8rem;
  }

  .xl\:start-36 {
    inset-inline-start: 9rem;
  }

  .xl\:start-4 {
    inset-inline-start: 1rem;
  }

  .xl\:start-40 {
    inset-inline-start: 10rem;
  }

  .xl\:start-44 {
    inset-inline-start: 11rem;
  }

  .xl\:start-48 {
    inset-inline-start: 12rem;
  }

  .xl\:start-5 {
    inset-inline-start: 1.25rem;
  }

  .xl\:start-52 {
    inset-inline-start: 13rem;
  }

  .xl\:start-56 {
    inset-inline-start: 14rem;
  }

  .xl\:start-6 {
    inset-inline-start: 1.5rem;
  }

  .xl\:start-60 {
    inset-inline-start: 15rem;
  }

  .xl\:start-64 {
    inset-inline-start: 16rem;
  }

  .xl\:start-7 {
    inset-inline-start: 1.75rem;
  }

  .xl\:start-72 {
    inset-inline-start: 18rem;
  }

  .xl\:start-8 {
    inset-inline-start: 2rem;
  }

  .xl\:start-80 {
    inset-inline-start: 20rem;
  }

  .xl\:start-9 {
    inset-inline-start: 2.25rem;
  }

  .xl\:start-96 {
    inset-inline-start: 24rem;
  }

  .xl\:start-auto {
    inset-inline-start: auto;
  }

  .xl\:start-full {
    inset-inline-start: 100%;
  }

  .xl\:start-px {
    inset-inline-start: 1px;
  }

  .xl\:top-0 {
    top: 0;
  }

  .xl\:top-0\.5 {
    top: .125rem;
  }

  .xl\:top-1 {
    top: .25rem;
  }

  .xl\:top-1\.5 {
    top: .375rem;
  }

  .xl\:top-1\/2 {
    top: 50%;
  }

  .xl\:top-1\/3 {
    top: 33.3333%;
  }

  .xl\:top-1\/4 {
    top: 25%;
  }

  .xl\:top-10 {
    top: 2.5rem;
  }

  .xl\:top-11 {
    top: 2.75rem;
  }

  .xl\:top-12 {
    top: 3rem;
  }

  .xl\:top-14 {
    top: 3.5rem;
  }

  .xl\:top-16 {
    top: 4rem;
  }

  .xl\:top-2 {
    top: .5rem;
  }

  .xl\:top-2\.5 {
    top: .625rem;
  }

  .xl\:top-2\/3 {
    top: 66.6667%;
  }

  .xl\:top-2\/4 {
    top: 50%;
  }

  .xl\:top-20 {
    top: 5rem;
  }

  .xl\:top-24 {
    top: 6rem;
  }

  .xl\:top-28 {
    top: 7rem;
  }

  .xl\:top-3 {
    top: .75rem;
  }

  .xl\:top-3\.5 {
    top: .875rem;
  }

  .xl\:top-3\/4 {
    top: 75%;
  }

  .xl\:top-32 {
    top: 8rem;
  }

  .xl\:top-36 {
    top: 9rem;
  }

  .xl\:top-4 {
    top: 1rem;
  }

  .xl\:top-40 {
    top: 10rem;
  }

  .xl\:top-44 {
    top: 11rem;
  }

  .xl\:top-48 {
    top: 12rem;
  }

  .xl\:top-5 {
    top: 1.25rem;
  }

  .xl\:top-52 {
    top: 13rem;
  }

  .xl\:top-56 {
    top: 14rem;
  }

  .xl\:top-6 {
    top: 1.5rem;
  }

  .xl\:top-60 {
    top: 15rem;
  }

  .xl\:top-64 {
    top: 16rem;
  }

  .xl\:top-7 {
    top: 1.75rem;
  }

  .xl\:top-72 {
    top: 18rem;
  }

  .xl\:top-8 {
    top: 2rem;
  }

  .xl\:top-80 {
    top: 20rem;
  }

  .xl\:top-9 {
    top: 2.25rem;
  }

  .xl\:top-96 {
    top: 24rem;
  }

  .xl\:top-auto {
    top: auto;
  }

  .xl\:top-full {
    top: 100%;
  }

  .xl\:top-px {
    top: 1px;
  }

  .xl\:isolate {
    isolation: isolate;
  }

  .xl\:isolation-auto {
    isolation: auto;
  }

  .xl\:-z-0 {
    z-index: 0;
  }

  .xl\:-z-10 {
    z-index: -10;
  }

  .xl\:-z-20 {
    z-index: -20;
  }

  .xl\:-z-30 {
    z-index: -30;
  }

  .xl\:-z-40 {
    z-index: -40;
  }

  .xl\:-z-50 {
    z-index: -50;
  }

  .xl\:z-0 {
    z-index: 0;
  }

  .xl\:z-10 {
    z-index: 10;
  }

  .xl\:z-20 {
    z-index: 20;
  }

  .xl\:z-30 {
    z-index: 30;
  }

  .xl\:z-40 {
    z-index: 40;
  }

  .xl\:z-50 {
    z-index: 50;
  }

  .xl\:z-auto {
    z-index: auto;
  }

  .xl\:-order-1 {
    order: -1;
  }

  .xl\:-order-10 {
    order: -10;
  }

  .xl\:-order-11 {
    order: -11;
  }

  .xl\:-order-12 {
    order: -12;
  }

  .xl\:-order-2 {
    order: -2;
  }

  .xl\:-order-3 {
    order: -3;
  }

  .xl\:-order-4 {
    order: -4;
  }

  .xl\:-order-5 {
    order: -5;
  }

  .xl\:-order-6 {
    order: -6;
  }

  .xl\:-order-7 {
    order: -7;
  }

  .xl\:-order-8 {
    order: -8;
  }

  .xl\:-order-9 {
    order: -9;
  }

  .xl\:-order-first {
    order: 9999;
  }

  .xl\:-order-last {
    order: -9999;
  }

  .xl\:-order-none {
    order: 0;
  }

  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-10 {
    order: 10;
  }

  .xl\:order-11 {
    order: 11;
  }

  .xl\:order-12 {
    order: 12;
  }

  .xl\:order-2 {
    order: 2;
  }

  .xl\:order-3 {
    order: 3;
  }

  .xl\:order-4 {
    order: 4;
  }

  .xl\:order-5 {
    order: 5;
  }

  .xl\:order-6 {
    order: 6;
  }

  .xl\:order-7 {
    order: 7;
  }

  .xl\:order-8 {
    order: 8;
  }

  .xl\:order-9 {
    order: 9;
  }

  .xl\:order-first {
    order: -9999;
  }

  .xl\:order-last {
    order: 9999;
  }

  .xl\:order-none {
    order: 0;
  }

  .xl\:col-auto {
    grid-column: auto;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xl\:col-span-full {
    grid-column: 1 / -1;
  }

  .xl\:-col-start-1 {
    grid-column-start: -1;
  }

  .xl\:-col-start-10 {
    grid-column-start: -10;
  }

  .xl\:-col-start-11 {
    grid-column-start: -11;
  }

  .xl\:-col-start-12 {
    grid-column-start: -12;
  }

  .xl\:-col-start-13 {
    grid-column-start: -13;
  }

  .xl\:-col-start-2 {
    grid-column-start: -2;
  }

  .xl\:-col-start-3 {
    grid-column-start: -3;
  }

  .xl\:-col-start-4 {
    grid-column-start: -4;
  }

  .xl\:-col-start-5 {
    grid-column-start: -5;
  }

  .xl\:-col-start-6 {
    grid-column-start: -6;
  }

  .xl\:-col-start-7 {
    grid-column-start: -7;
  }

  .xl\:-col-start-8 {
    grid-column-start: -8;
  }

  .xl\:-col-start-9 {
    grid-column-start: -9;
  }

  .xl\:col-start-1 {
    grid-column-start: 1;
  }

  .xl\:col-start-10 {
    grid-column-start: 10;
  }

  .xl\:col-start-11 {
    grid-column-start: 11;
  }

  .xl\:col-start-12 {
    grid-column-start: 12;
  }

  .xl\:col-start-13 {
    grid-column-start: 13;
  }

  .xl\:col-start-2 {
    grid-column-start: 2;
  }

  .xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:col-start-4 {
    grid-column-start: 4;
  }

  .xl\:col-start-5 {
    grid-column-start: 5;
  }

  .xl\:col-start-6 {
    grid-column-start: 6;
  }

  .xl\:col-start-7 {
    grid-column-start: 7;
  }

  .xl\:col-start-8 {
    grid-column-start: 8;
  }

  .xl\:col-start-9 {
    grid-column-start: 9;
  }

  .xl\:col-start-auto {
    grid-column-start: auto;
  }

  .xl\:-col-end-1 {
    grid-column-end: -1;
  }

  .xl\:-col-end-10 {
    grid-column-end: -10;
  }

  .xl\:-col-end-11 {
    grid-column-end: -11;
  }

  .xl\:-col-end-12 {
    grid-column-end: -12;
  }

  .xl\:-col-end-13 {
    grid-column-end: -13;
  }

  .xl\:-col-end-2 {
    grid-column-end: -2;
  }

  .xl\:-col-end-3 {
    grid-column-end: -3;
  }

  .xl\:-col-end-4 {
    grid-column-end: -4;
  }

  .xl\:-col-end-5 {
    grid-column-end: -5;
  }

  .xl\:-col-end-6 {
    grid-column-end: -6;
  }

  .xl\:-col-end-7 {
    grid-column-end: -7;
  }

  .xl\:-col-end-8 {
    grid-column-end: -8;
  }

  .xl\:-col-end-9 {
    grid-column-end: -9;
  }

  .xl\:col-end-1 {
    grid-column-end: 1;
  }

  .xl\:col-end-10 {
    grid-column-end: 10;
  }

  .xl\:col-end-11 {
    grid-column-end: 11;
  }

  .xl\:col-end-12 {
    grid-column-end: 12;
  }

  .xl\:col-end-13 {
    grid-column-end: 13;
  }

  .xl\:col-end-2 {
    grid-column-end: 2;
  }

  .xl\:col-end-3 {
    grid-column-end: 3;
  }

  .xl\:col-end-4 {
    grid-column-end: 4;
  }

  .xl\:col-end-5 {
    grid-column-end: 5;
  }

  .xl\:col-end-6 {
    grid-column-end: 6;
  }

  .xl\:col-end-7 {
    grid-column-end: 7;
  }

  .xl\:col-end-8 {
    grid-column-end: 8;
  }

  .xl\:col-end-9 {
    grid-column-end: 9;
  }

  .xl\:col-end-auto {
    grid-column-end: auto;
  }

  .xl\:row-auto {
    grid-row: auto;
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .xl\:row-span-10 {
    grid-row: span 10 / span 10;
  }

  .xl\:row-span-11 {
    grid-row: span 11 / span 11;
  }

  .xl\:row-span-12 {
    grid-row: span 12 / span 12;
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .xl\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .xl\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .xl\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .xl\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .xl\:row-span-7 {
    grid-row: span 7 / span 7;
  }

  .xl\:row-span-8 {
    grid-row: span 8 / span 8;
  }

  .xl\:row-span-9 {
    grid-row: span 9 / span 9;
  }

  .xl\:row-span-full {
    grid-row: 1 / -1;
  }

  .xl\:-row-start-1 {
    grid-row-start: -1;
  }

  .xl\:-row-start-10 {
    grid-row-start: -10;
  }

  .xl\:-row-start-11 {
    grid-row-start: -11;
  }

  .xl\:-row-start-12 {
    grid-row-start: -12;
  }

  .xl\:-row-start-13 {
    grid-row-start: -13;
  }

  .xl\:-row-start-2 {
    grid-row-start: -2;
  }

  .xl\:-row-start-3 {
    grid-row-start: -3;
  }

  .xl\:-row-start-4 {
    grid-row-start: -4;
  }

  .xl\:-row-start-5 {
    grid-row-start: -5;
  }

  .xl\:-row-start-6 {
    grid-row-start: -6;
  }

  .xl\:-row-start-7 {
    grid-row-start: -7;
  }

  .xl\:-row-start-8 {
    grid-row-start: -8;
  }

  .xl\:-row-start-9 {
    grid-row-start: -9;
  }

  .xl\:row-start-1 {
    grid-row-start: 1;
  }

  .xl\:row-start-10 {
    grid-row-start: 10;
  }

  .xl\:row-start-11 {
    grid-row-start: 11;
  }

  .xl\:row-start-12 {
    grid-row-start: 12;
  }

  .xl\:row-start-13 {
    grid-row-start: 13;
  }

  .xl\:row-start-2 {
    grid-row-start: 2;
  }

  .xl\:row-start-3 {
    grid-row-start: 3;
  }

  .xl\:row-start-4 {
    grid-row-start: 4;
  }

  .xl\:row-start-5 {
    grid-row-start: 5;
  }

  .xl\:row-start-6 {
    grid-row-start: 6;
  }

  .xl\:row-start-7 {
    grid-row-start: 7;
  }

  .xl\:row-start-8 {
    grid-row-start: 8;
  }

  .xl\:row-start-9 {
    grid-row-start: 9;
  }

  .xl\:row-start-auto {
    grid-row-start: auto;
  }

  .xl\:-row-end-1 {
    grid-row-end: -1;
  }

  .xl\:-row-end-10 {
    grid-row-end: -10;
  }

  .xl\:-row-end-11 {
    grid-row-end: -11;
  }

  .xl\:-row-end-12 {
    grid-row-end: -12;
  }

  .xl\:-row-end-13 {
    grid-row-end: -13;
  }

  .xl\:-row-end-2 {
    grid-row-end: -2;
  }

  .xl\:-row-end-3 {
    grid-row-end: -3;
  }

  .xl\:-row-end-4 {
    grid-row-end: -4;
  }

  .xl\:-row-end-5 {
    grid-row-end: -5;
  }

  .xl\:-row-end-6 {
    grid-row-end: -6;
  }

  .xl\:-row-end-7 {
    grid-row-end: -7;
  }

  .xl\:-row-end-8 {
    grid-row-end: -8;
  }

  .xl\:-row-end-9 {
    grid-row-end: -9;
  }

  .xl\:row-end-1 {
    grid-row-end: 1;
  }

  .xl\:row-end-10 {
    grid-row-end: 10;
  }

  .xl\:row-end-11 {
    grid-row-end: 11;
  }

  .xl\:row-end-12 {
    grid-row-end: 12;
  }

  .xl\:row-end-13 {
    grid-row-end: 13;
  }

  .xl\:row-end-2 {
    grid-row-end: 2;
  }

  .xl\:row-end-3 {
    grid-row-end: 3;
  }

  .xl\:row-end-4 {
    grid-row-end: 4;
  }

  .xl\:row-end-5 {
    grid-row-end: 5;
  }

  .xl\:row-end-6 {
    grid-row-end: 6;
  }

  .xl\:row-end-7 {
    grid-row-end: 7;
  }

  .xl\:row-end-8 {
    grid-row-end: 8;
  }

  .xl\:row-end-9 {
    grid-row-end: 9;
  }

  .xl\:row-end-auto {
    grid-row-end: auto;
  }

  .xl\:float-start {
    float: inline-start;
  }

  .xl\:float-end {
    float: inline-end;
  }

  .xl\:float-right {
    float: right;
  }

  .xl\:float-left {
    float: left;
  }

  .xl\:float-none {
    float: none;
  }

  .xl\:clear-start {
    clear: inline-start;
  }

  .xl\:clear-end {
    clear: inline-end;
  }

  .xl\:clear-left {
    clear: left;
  }

  .xl\:clear-right {
    clear: right;
  }

  .xl\:clear-both {
    clear: both;
  }

  .xl\:clear-none {
    clear: none;
  }

  .xl\:-m-0 {
    margin: 0;
  }

  .xl\:-m-0\.5 {
    margin: -.125rem;
  }

  .xl\:-m-1 {
    margin: -.25rem;
  }

  .xl\:-m-1\.5 {
    margin: -.375rem;
  }

  .xl\:-m-10 {
    margin: -2.5rem;
  }

  .xl\:-m-11 {
    margin: -2.75rem;
  }

  .xl\:-m-12 {
    margin: -3rem;
  }

  .xl\:-m-14 {
    margin: -3.5rem;
  }

  .xl\:-m-16 {
    margin: -4rem;
  }

  .xl\:-m-2 {
    margin: -.5rem;
  }

  .xl\:-m-2\.5 {
    margin: -.625rem;
  }

  .xl\:-m-20 {
    margin: -5rem;
  }

  .xl\:-m-24 {
    margin: -6rem;
  }

  .xl\:-m-28 {
    margin: -7rem;
  }

  .xl\:-m-3 {
    margin: -.75rem;
  }

  .xl\:-m-3\.5 {
    margin: -.875rem;
  }

  .xl\:-m-32 {
    margin: -8rem;
  }

  .xl\:-m-36 {
    margin: -9rem;
  }

  .xl\:-m-4 {
    margin: -1rem;
  }

  .xl\:-m-40 {
    margin: -10rem;
  }

  .xl\:-m-44 {
    margin: -11rem;
  }

  .xl\:-m-48 {
    margin: -12rem;
  }

  .xl\:-m-5 {
    margin: -1.25rem;
  }

  .xl\:-m-52 {
    margin: -13rem;
  }

  .xl\:-m-56 {
    margin: -14rem;
  }

  .xl\:-m-6 {
    margin: -1.5rem;
  }

  .xl\:-m-60 {
    margin: -15rem;
  }

  .xl\:-m-64 {
    margin: -16rem;
  }

  .xl\:-m-7 {
    margin: -1.75rem;
  }

  .xl\:-m-72 {
    margin: -18rem;
  }

  .xl\:-m-8 {
    margin: -2rem;
  }

  .xl\:-m-80 {
    margin: -20rem;
  }

  .xl\:-m-9 {
    margin: -2.25rem;
  }

  .xl\:-m-96 {
    margin: -24rem;
  }

  .xl\:-m-px {
    margin: -1px;
  }

  .xl\:m-0 {
    margin: 0;
  }

  .xl\:m-0\.5 {
    margin: .125rem;
  }

  .xl\:m-1 {
    margin: .25rem;
  }

  .xl\:m-1\.5 {
    margin: .375rem;
  }

  .xl\:m-10 {
    margin: 2.5rem;
  }

  .xl\:m-11 {
    margin: 2.75rem;
  }

  .xl\:m-12 {
    margin: 3rem;
  }

  .xl\:m-14 {
    margin: 3.5rem;
  }

  .xl\:m-16 {
    margin: 4rem;
  }

  .xl\:m-2 {
    margin: .5rem;
  }

  .xl\:m-2\.5 {
    margin: .625rem;
  }

  .xl\:m-20 {
    margin: 5rem;
  }

  .xl\:m-24 {
    margin: 6rem;
  }

  .xl\:m-28 {
    margin: 7rem;
  }

  .xl\:m-3 {
    margin: .75rem;
  }

  .xl\:m-3\.5 {
    margin: .875rem;
  }

  .xl\:m-32 {
    margin: 8rem;
  }

  .xl\:m-36 {
    margin: 9rem;
  }

  .xl\:m-4 {
    margin: 1rem;
  }

  .xl\:m-40 {
    margin: 10rem;
  }

  .xl\:m-44 {
    margin: 11rem;
  }

  .xl\:m-48 {
    margin: 12rem;
  }

  .xl\:m-5 {
    margin: 1.25rem;
  }

  .xl\:m-52 {
    margin: 13rem;
  }

  .xl\:m-56 {
    margin: 14rem;
  }

  .xl\:m-6 {
    margin: 1.5rem;
  }

  .xl\:m-60 {
    margin: 15rem;
  }

  .xl\:m-64 {
    margin: 16rem;
  }

  .xl\:m-7 {
    margin: 1.75rem;
  }

  .xl\:m-72 {
    margin: 18rem;
  }

  .xl\:m-8 {
    margin: 2rem;
  }

  .xl\:m-80 {
    margin: 20rem;
  }

  .xl\:m-9 {
    margin: 2.25rem;
  }

  .xl\:m-96 {
    margin: 24rem;
  }

  .xl\:m-auto {
    margin: auto;
  }

  .xl\:m-px {
    margin: 1px;
  }

  .xl\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .xl\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .xl\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .xl\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xl\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xl\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .xl\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .xl\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .xl\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .xl\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .xl\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .xl\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .xl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .xl\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .xl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .xl\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .xl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .xl\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .xl\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xl\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .xl\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .xl\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .xl\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .xl\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .xl\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .xl\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xl\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .xl\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .xl\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .xl\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .xl\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .xl\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .xl\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .xl\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .xl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .xl\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .xl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .xl\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .xl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .xl\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .xl\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xl\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .xl\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .xl\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .xl\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .xl\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xl\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xl\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .xl\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .xl\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .xl\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .xl\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xl\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .xl\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .xl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xl\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .xl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .xl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .xl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .xl\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .xl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .xl\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .xl\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .xl\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xl\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .xl\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xl\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .xl\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .xl\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .xl\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .xl\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .xl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xl\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .xl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xl\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .xl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .xl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .xl\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .xl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .xl\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .xl\:-mb-0 {
    margin-bottom: 0;
  }

  .xl\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .xl\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .xl\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .xl\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .xl\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .xl\:-mb-12 {
    margin-bottom: -3rem;
  }

  .xl\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .xl\:-mb-16 {
    margin-bottom: -4rem;
  }

  .xl\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .xl\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .xl\:-mb-20 {
    margin-bottom: -5rem;
  }

  .xl\:-mb-24 {
    margin-bottom: -6rem;
  }

  .xl\:-mb-28 {
    margin-bottom: -7rem;
  }

  .xl\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .xl\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .xl\:-mb-32 {
    margin-bottom: -8rem;
  }

  .xl\:-mb-36 {
    margin-bottom: -9rem;
  }

  .xl\:-mb-4 {
    margin-bottom: -1rem;
  }

  .xl\:-mb-40 {
    margin-bottom: -10rem;
  }

  .xl\:-mb-44 {
    margin-bottom: -11rem;
  }

  .xl\:-mb-48 {
    margin-bottom: -12rem;
  }

  .xl\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .xl\:-mb-52 {
    margin-bottom: -13rem;
  }

  .xl\:-mb-56 {
    margin-bottom: -14rem;
  }

  .xl\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .xl\:-mb-60 {
    margin-bottom: -15rem;
  }

  .xl\:-mb-64 {
    margin-bottom: -16rem;
  }

  .xl\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .xl\:-mb-72 {
    margin-bottom: -18rem;
  }

  .xl\:-mb-8 {
    margin-bottom: -2rem;
  }

  .xl\:-mb-80 {
    margin-bottom: -20rem;
  }

  .xl\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .xl\:-mb-96 {
    margin-bottom: -24rem;
  }

  .xl\:-mb-px {
    margin-bottom: -1px;
  }

  .xl\:-me-0 {
    margin-inline-end: 0;
  }

  .xl\:-me-0\.5 {
    margin-inline-end: -.125rem;
  }

  .xl\:-me-1 {
    margin-inline-end: -.25rem;
  }

  .xl\:-me-1\.5 {
    margin-inline-end: -.375rem;
  }

  .xl\:-me-10 {
    margin-inline-end: -2.5rem;
  }

  .xl\:-me-11 {
    margin-inline-end: -2.75rem;
  }

  .xl\:-me-12 {
    margin-inline-end: -3rem;
  }

  .xl\:-me-14 {
    margin-inline-end: -3.5rem;
  }

  .xl\:-me-16 {
    margin-inline-end: -4rem;
  }

  .xl\:-me-2 {
    margin-inline-end: -.5rem;
  }

  .xl\:-me-2\.5 {
    margin-inline-end: -.625rem;
  }

  .xl\:-me-20 {
    margin-inline-end: -5rem;
  }

  .xl\:-me-24 {
    margin-inline-end: -6rem;
  }

  .xl\:-me-28 {
    margin-inline-end: -7rem;
  }

  .xl\:-me-3 {
    margin-inline-end: -.75rem;
  }

  .xl\:-me-3\.5 {
    margin-inline-end: -.875rem;
  }

  .xl\:-me-32 {
    margin-inline-end: -8rem;
  }

  .xl\:-me-36 {
    margin-inline-end: -9rem;
  }

  .xl\:-me-4 {
    margin-inline-end: -1rem;
  }

  .xl\:-me-40 {
    margin-inline-end: -10rem;
  }

  .xl\:-me-44 {
    margin-inline-end: -11rem;
  }

  .xl\:-me-48 {
    margin-inline-end: -12rem;
  }

  .xl\:-me-5 {
    margin-inline-end: -1.25rem;
  }

  .xl\:-me-52 {
    margin-inline-end: -13rem;
  }

  .xl\:-me-56 {
    margin-inline-end: -14rem;
  }

  .xl\:-me-6 {
    margin-inline-end: -1.5rem;
  }

  .xl\:-me-60 {
    margin-inline-end: -15rem;
  }

  .xl\:-me-64 {
    margin-inline-end: -16rem;
  }

  .xl\:-me-7 {
    margin-inline-end: -1.75rem;
  }

  .xl\:-me-72 {
    margin-inline-end: -18rem;
  }

  .xl\:-me-8 {
    margin-inline-end: -2rem;
  }

  .xl\:-me-80 {
    margin-inline-end: -20rem;
  }

  .xl\:-me-9 {
    margin-inline-end: -2.25rem;
  }

  .xl\:-me-96 {
    margin-inline-end: -24rem;
  }

  .xl\:-me-px {
    margin-inline-end: -1px;
  }

  .xl\:-ml-0 {
    margin-left: 0;
  }

  .xl\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .xl\:-ml-1 {
    margin-left: -.25rem;
  }

  .xl\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .xl\:-ml-10 {
    margin-left: -2.5rem;
  }

  .xl\:-ml-11 {
    margin-left: -2.75rem;
  }

  .xl\:-ml-12 {
    margin-left: -3rem;
  }

  .xl\:-ml-14 {
    margin-left: -3.5rem;
  }

  .xl\:-ml-16 {
    margin-left: -4rem;
  }

  .xl\:-ml-2 {
    margin-left: -.5rem;
  }

  .xl\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .xl\:-ml-20 {
    margin-left: -5rem;
  }

  .xl\:-ml-24 {
    margin-left: -6rem;
  }

  .xl\:-ml-28 {
    margin-left: -7rem;
  }

  .xl\:-ml-3 {
    margin-left: -.75rem;
  }

  .xl\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .xl\:-ml-32 {
    margin-left: -8rem;
  }

  .xl\:-ml-36 {
    margin-left: -9rem;
  }

  .xl\:-ml-4 {
    margin-left: -1rem;
  }

  .xl\:-ml-40 {
    margin-left: -10rem;
  }

  .xl\:-ml-44 {
    margin-left: -11rem;
  }

  .xl\:-ml-48 {
    margin-left: -12rem;
  }

  .xl\:-ml-5 {
    margin-left: -1.25rem;
  }

  .xl\:-ml-52 {
    margin-left: -13rem;
  }

  .xl\:-ml-56 {
    margin-left: -14rem;
  }

  .xl\:-ml-6 {
    margin-left: -1.5rem;
  }

  .xl\:-ml-60 {
    margin-left: -15rem;
  }

  .xl\:-ml-64 {
    margin-left: -16rem;
  }

  .xl\:-ml-7 {
    margin-left: -1.75rem;
  }

  .xl\:-ml-72 {
    margin-left: -18rem;
  }

  .xl\:-ml-8 {
    margin-left: -2rem;
  }

  .xl\:-ml-80 {
    margin-left: -20rem;
  }

  .xl\:-ml-9 {
    margin-left: -2.25rem;
  }

  .xl\:-ml-96 {
    margin-left: -24rem;
  }

  .xl\:-ml-px {
    margin-left: -1px;
  }

  .xl\:-mr-0 {
    margin-right: 0;
  }

  .xl\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .xl\:-mr-1 {
    margin-right: -.25rem;
  }

  .xl\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .xl\:-mr-10 {
    margin-right: -2.5rem;
  }

  .xl\:-mr-11 {
    margin-right: -2.75rem;
  }

  .xl\:-mr-12 {
    margin-right: -3rem;
  }

  .xl\:-mr-14 {
    margin-right: -3.5rem;
  }

  .xl\:-mr-16 {
    margin-right: -4rem;
  }

  .xl\:-mr-2 {
    margin-right: -.5rem;
  }

  .xl\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .xl\:-mr-20 {
    margin-right: -5rem;
  }

  .xl\:-mr-24 {
    margin-right: -6rem;
  }

  .xl\:-mr-28 {
    margin-right: -7rem;
  }

  .xl\:-mr-3 {
    margin-right: -.75rem;
  }

  .xl\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .xl\:-mr-32 {
    margin-right: -8rem;
  }

  .xl\:-mr-36 {
    margin-right: -9rem;
  }

  .xl\:-mr-4 {
    margin-right: -1rem;
  }

  .xl\:-mr-40 {
    margin-right: -10rem;
  }

  .xl\:-mr-44 {
    margin-right: -11rem;
  }

  .xl\:-mr-48 {
    margin-right: -12rem;
  }

  .xl\:-mr-5 {
    margin-right: -1.25rem;
  }

  .xl\:-mr-52 {
    margin-right: -13rem;
  }

  .xl\:-mr-56 {
    margin-right: -14rem;
  }

  .xl\:-mr-6 {
    margin-right: -1.5rem;
  }

  .xl\:-mr-60 {
    margin-right: -15rem;
  }

  .xl\:-mr-64 {
    margin-right: -16rem;
  }

  .xl\:-mr-7 {
    margin-right: -1.75rem;
  }

  .xl\:-mr-72 {
    margin-right: -18rem;
  }

  .xl\:-mr-8 {
    margin-right: -2rem;
  }

  .xl\:-mr-80 {
    margin-right: -20rem;
  }

  .xl\:-mr-9 {
    margin-right: -2.25rem;
  }

  .xl\:-mr-96 {
    margin-right: -24rem;
  }

  .xl\:-mr-px {
    margin-right: -1px;
  }

  .xl\:-ms-0 {
    margin-inline-start: 0;
  }

  .xl\:-ms-0\.5 {
    margin-inline-start: -.125rem;
  }

  .xl\:-ms-1 {
    margin-inline-start: -.25rem;
  }

  .xl\:-ms-1\.5 {
    margin-inline-start: -.375rem;
  }

  .xl\:-ms-10 {
    margin-inline-start: -2.5rem;
  }

  .xl\:-ms-11 {
    margin-inline-start: -2.75rem;
  }

  .xl\:-ms-12 {
    margin-inline-start: -3rem;
  }

  .xl\:-ms-14 {
    margin-inline-start: -3.5rem;
  }

  .xl\:-ms-16 {
    margin-inline-start: -4rem;
  }

  .xl\:-ms-2 {
    margin-inline-start: -.5rem;
  }

  .xl\:-ms-2\.5 {
    margin-inline-start: -.625rem;
  }

  .xl\:-ms-20 {
    margin-inline-start: -5rem;
  }

  .xl\:-ms-24 {
    margin-inline-start: -6rem;
  }

  .xl\:-ms-28 {
    margin-inline-start: -7rem;
  }

  .xl\:-ms-3 {
    margin-inline-start: -.75rem;
  }

  .xl\:-ms-3\.5 {
    margin-inline-start: -.875rem;
  }

  .xl\:-ms-32 {
    margin-inline-start: -8rem;
  }

  .xl\:-ms-36 {
    margin-inline-start: -9rem;
  }

  .xl\:-ms-4 {
    margin-inline-start: -1rem;
  }

  .xl\:-ms-40 {
    margin-inline-start: -10rem;
  }

  .xl\:-ms-44 {
    margin-inline-start: -11rem;
  }

  .xl\:-ms-48 {
    margin-inline-start: -12rem;
  }

  .xl\:-ms-5 {
    margin-inline-start: -1.25rem;
  }

  .xl\:-ms-52 {
    margin-inline-start: -13rem;
  }

  .xl\:-ms-56 {
    margin-inline-start: -14rem;
  }

  .xl\:-ms-6 {
    margin-inline-start: -1.5rem;
  }

  .xl\:-ms-60 {
    margin-inline-start: -15rem;
  }

  .xl\:-ms-64 {
    margin-inline-start: -16rem;
  }

  .xl\:-ms-7 {
    margin-inline-start: -1.75rem;
  }

  .xl\:-ms-72 {
    margin-inline-start: -18rem;
  }

  .xl\:-ms-8 {
    margin-inline-start: -2rem;
  }

  .xl\:-ms-80 {
    margin-inline-start: -20rem;
  }

  .xl\:-ms-9 {
    margin-inline-start: -2.25rem;
  }

  .xl\:-ms-96 {
    margin-inline-start: -24rem;
  }

  .xl\:-ms-px {
    margin-inline-start: -1px;
  }

  .xl\:-mt-0 {
    margin-top: 0;
  }

  .xl\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .xl\:-mt-1 {
    margin-top: -.25rem;
  }

  .xl\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .xl\:-mt-10 {
    margin-top: -2.5rem;
  }

  .xl\:-mt-11 {
    margin-top: -2.75rem;
  }

  .xl\:-mt-12 {
    margin-top: -3rem;
  }

  .xl\:-mt-14 {
    margin-top: -3.5rem;
  }

  .xl\:-mt-16 {
    margin-top: -4rem;
  }

  .xl\:-mt-2 {
    margin-top: -.5rem;
  }

  .xl\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .xl\:-mt-20 {
    margin-top: -5rem;
  }

  .xl\:-mt-24 {
    margin-top: -6rem;
  }

  .xl\:-mt-28 {
    margin-top: -7rem;
  }

  .xl\:-mt-3 {
    margin-top: -.75rem;
  }

  .xl\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .xl\:-mt-32 {
    margin-top: -8rem;
  }

  .xl\:-mt-36 {
    margin-top: -9rem;
  }

  .xl\:-mt-4 {
    margin-top: -1rem;
  }

  .xl\:-mt-40 {
    margin-top: -10rem;
  }

  .xl\:-mt-44 {
    margin-top: -11rem;
  }

  .xl\:-mt-48 {
    margin-top: -12rem;
  }

  .xl\:-mt-5 {
    margin-top: -1.25rem;
  }

  .xl\:-mt-52 {
    margin-top: -13rem;
  }

  .xl\:-mt-56 {
    margin-top: -14rem;
  }

  .xl\:-mt-6 {
    margin-top: -1.5rem;
  }

  .xl\:-mt-60 {
    margin-top: -15rem;
  }

  .xl\:-mt-64 {
    margin-top: -16rem;
  }

  .xl\:-mt-7 {
    margin-top: -1.75rem;
  }

  .xl\:-mt-72 {
    margin-top: -18rem;
  }

  .xl\:-mt-8 {
    margin-top: -2rem;
  }

  .xl\:-mt-80 {
    margin-top: -20rem;
  }

  .xl\:-mt-9 {
    margin-top: -2.25rem;
  }

  .xl\:-mt-96 {
    margin-top: -24rem;
  }

  .xl\:-mt-px {
    margin-top: -1px;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .xl\:mb-1 {
    margin-bottom: .25rem;
  }

  .xl\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .xl\:mb-12 {
    margin-bottom: 3rem;
  }

  .xl\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .xl\:mb-16 {
    margin-bottom: 4rem;
  }

  .xl\:mb-2 {
    margin-bottom: .5rem;
  }

  .xl\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .xl\:mb-20 {
    margin-bottom: 5rem;
  }

  .xl\:mb-24 {
    margin-bottom: 6rem;
  }

  .xl\:mb-28 {
    margin-bottom: 7rem;
  }

  .xl\:mb-3 {
    margin-bottom: .75rem;
  }

  .xl\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .xl\:mb-32 {
    margin-bottom: 8rem;
  }

  .xl\:mb-36 {
    margin-bottom: 9rem;
  }

  .xl\:mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:mb-40 {
    margin-bottom: 10rem;
  }

  .xl\:mb-44 {
    margin-bottom: 11rem;
  }

  .xl\:mb-48 {
    margin-bottom: 12rem;
  }

  .xl\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .xl\:mb-52 {
    margin-bottom: 13rem;
  }

  .xl\:mb-56 {
    margin-bottom: 14rem;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:mb-60 {
    margin-bottom: 15rem;
  }

  .xl\:mb-64 {
    margin-bottom: 16rem;
  }

  .xl\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .xl\:mb-72 {
    margin-bottom: 18rem;
  }

  .xl\:mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:mb-80 {
    margin-bottom: 20rem;
  }

  .xl\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .xl\:mb-96 {
    margin-bottom: 24rem;
  }

  .xl\:mb-auto {
    margin-bottom: auto;
  }

  .xl\:mb-px {
    margin-bottom: 1px;
  }

  .xl\:me-0 {
    margin-inline-end: 0;
  }

  .xl\:me-0\.5 {
    margin-inline-end: .125rem;
  }

  .xl\:me-1 {
    margin-inline-end: .25rem;
  }

  .xl\:me-1\.5 {
    margin-inline-end: .375rem;
  }

  .xl\:me-10 {
    margin-inline-end: 2.5rem;
  }

  .xl\:me-11 {
    margin-inline-end: 2.75rem;
  }

  .xl\:me-12 {
    margin-inline-end: 3rem;
  }

  .xl\:me-14 {
    margin-inline-end: 3.5rem;
  }

  .xl\:me-16 {
    margin-inline-end: 4rem;
  }

  .xl\:me-2 {
    margin-inline-end: .5rem;
  }

  .xl\:me-2\.5 {
    margin-inline-end: .625rem;
  }

  .xl\:me-20 {
    margin-inline-end: 5rem;
  }

  .xl\:me-24 {
    margin-inline-end: 6rem;
  }

  .xl\:me-28 {
    margin-inline-end: 7rem;
  }

  .xl\:me-3 {
    margin-inline-end: .75rem;
  }

  .xl\:me-3\.5 {
    margin-inline-end: .875rem;
  }

  .xl\:me-32 {
    margin-inline-end: 8rem;
  }

  .xl\:me-36 {
    margin-inline-end: 9rem;
  }

  .xl\:me-4 {
    margin-inline-end: 1rem;
  }

  .xl\:me-40 {
    margin-inline-end: 10rem;
  }

  .xl\:me-44 {
    margin-inline-end: 11rem;
  }

  .xl\:me-48 {
    margin-inline-end: 12rem;
  }

  .xl\:me-5 {
    margin-inline-end: 1.25rem;
  }

  .xl\:me-52 {
    margin-inline-end: 13rem;
  }

  .xl\:me-56 {
    margin-inline-end: 14rem;
  }

  .xl\:me-6 {
    margin-inline-end: 1.5rem;
  }

  .xl\:me-60 {
    margin-inline-end: 15rem;
  }

  .xl\:me-64 {
    margin-inline-end: 16rem;
  }

  .xl\:me-7 {
    margin-inline-end: 1.75rem;
  }

  .xl\:me-72 {
    margin-inline-end: 18rem;
  }

  .xl\:me-8 {
    margin-inline-end: 2rem;
  }

  .xl\:me-80 {
    margin-inline-end: 20rem;
  }

  .xl\:me-9 {
    margin-inline-end: 2.25rem;
  }

  .xl\:me-96 {
    margin-inline-end: 24rem;
  }

  .xl\:me-auto {
    margin-inline-end: auto;
  }

  .xl\:me-px {
    margin-inline-end: 1px;
  }

  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:ml-0\.5 {
    margin-left: .125rem;
  }

  .xl\:ml-1 {
    margin-left: .25rem;
  }

  .xl\:ml-1\.5 {
    margin-left: .375rem;
  }

  .xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:ml-11 {
    margin-left: 2.75rem;
  }

  .xl\:ml-12 {
    margin-left: 3rem;
  }

  .xl\:ml-14 {
    margin-left: 3.5rem;
  }

  .xl\:ml-16 {
    margin-left: 4rem;
  }

  .xl\:ml-2 {
    margin-left: .5rem;
  }

  .xl\:ml-2\.5 {
    margin-left: .625rem;
  }

  .xl\:ml-20 {
    margin-left: 5rem;
  }

  .xl\:ml-24 {
    margin-left: 6rem;
  }

  .xl\:ml-28 {
    margin-left: 7rem;
  }

  .xl\:ml-3 {
    margin-left: .75rem;
  }

  .xl\:ml-3\.5 {
    margin-left: .875rem;
  }

  .xl\:ml-32 {
    margin-left: 8rem;
  }

  .xl\:ml-36 {
    margin-left: 9rem;
  }

  .xl\:ml-4 {
    margin-left: 1rem;
  }

  .xl\:ml-40 {
    margin-left: 10rem;
  }

  .xl\:ml-44 {
    margin-left: 11rem;
  }

  .xl\:ml-48 {
    margin-left: 12rem;
  }

  .xl\:ml-5 {
    margin-left: 1.25rem;
  }

  .xl\:ml-52 {
    margin-left: 13rem;
  }

  .xl\:ml-56 {
    margin-left: 14rem;
  }

  .xl\:ml-6 {
    margin-left: 1.5rem;
  }

  .xl\:ml-60 {
    margin-left: 15rem;
  }

  .xl\:ml-64 {
    margin-left: 16rem;
  }

  .xl\:ml-7 {
    margin-left: 1.75rem;
  }

  .xl\:ml-72 {
    margin-left: 18rem;
  }

  .xl\:ml-8 {
    margin-left: 2rem;
  }

  .xl\:ml-80 {
    margin-left: 20rem;
  }

  .xl\:ml-9 {
    margin-left: 2.25rem;
  }

  .xl\:ml-96 {
    margin-left: 24rem;
  }

  .xl\:ml-auto {
    margin-left: auto;
  }

  .xl\:ml-px {
    margin-left: 1px;
  }

  .xl\:mr-0 {
    margin-right: 0;
  }

  .xl\:mr-0\.5 {
    margin-right: .125rem;
  }

  .xl\:mr-1 {
    margin-right: .25rem;
  }

  .xl\:mr-1\.5 {
    margin-right: .375rem;
  }

  .xl\:mr-10 {
    margin-right: 2.5rem;
  }

  .xl\:mr-11 {
    margin-right: 2.75rem;
  }

  .xl\:mr-12 {
    margin-right: 3rem;
  }

  .xl\:mr-14 {
    margin-right: 3.5rem;
  }

  .xl\:mr-16 {
    margin-right: 4rem;
  }

  .xl\:mr-2 {
    margin-right: .5rem;
  }

  .xl\:mr-2\.5 {
    margin-right: .625rem;
  }

  .xl\:mr-20 {
    margin-right: 5rem;
  }

  .xl\:mr-24 {
    margin-right: 6rem;
  }

  .xl\:mr-28 {
    margin-right: 7rem;
  }

  .xl\:mr-3 {
    margin-right: .75rem;
  }

  .xl\:mr-3\.5 {
    margin-right: .875rem;
  }

  .xl\:mr-32 {
    margin-right: 8rem;
  }

  .xl\:mr-36 {
    margin-right: 9rem;
  }

  .xl\:mr-4 {
    margin-right: 1rem;
  }

  .xl\:mr-40 {
    margin-right: 10rem;
  }

  .xl\:mr-44 {
    margin-right: 11rem;
  }

  .xl\:mr-48 {
    margin-right: 12rem;
  }

  .xl\:mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:mr-52 {
    margin-right: 13rem;
  }

  .xl\:mr-56 {
    margin-right: 14rem;
  }

  .xl\:mr-6 {
    margin-right: 1.5rem;
  }

  .xl\:mr-60 {
    margin-right: 15rem;
  }

  .xl\:mr-64 {
    margin-right: 16rem;
  }

  .xl\:mr-7 {
    margin-right: 1.75rem;
  }

  .xl\:mr-72 {
    margin-right: 18rem;
  }

  .xl\:mr-8 {
    margin-right: 2rem;
  }

  .xl\:mr-80 {
    margin-right: 20rem;
  }

  .xl\:mr-9 {
    margin-right: 2.25rem;
  }

  .xl\:mr-96 {
    margin-right: 24rem;
  }

  .xl\:mr-auto {
    margin-right: auto;
  }

  .xl\:mr-px {
    margin-right: 1px;
  }

  .xl\:ms-0 {
    margin-inline-start: 0;
  }

  .xl\:ms-0\.5 {
    margin-inline-start: .125rem;
  }

  .xl\:ms-1 {
    margin-inline-start: .25rem;
  }

  .xl\:ms-1\.5 {
    margin-inline-start: .375rem;
  }

  .xl\:ms-10 {
    margin-inline-start: 2.5rem;
  }

  .xl\:ms-11 {
    margin-inline-start: 2.75rem;
  }

  .xl\:ms-12 {
    margin-inline-start: 3rem;
  }

  .xl\:ms-14 {
    margin-inline-start: 3.5rem;
  }

  .xl\:ms-16 {
    margin-inline-start: 4rem;
  }

  .xl\:ms-2 {
    margin-inline-start: .5rem;
  }

  .xl\:ms-2\.5 {
    margin-inline-start: .625rem;
  }

  .xl\:ms-20 {
    margin-inline-start: 5rem;
  }

  .xl\:ms-24 {
    margin-inline-start: 6rem;
  }

  .xl\:ms-28 {
    margin-inline-start: 7rem;
  }

  .xl\:ms-3 {
    margin-inline-start: .75rem;
  }

  .xl\:ms-3\.5 {
    margin-inline-start: .875rem;
  }

  .xl\:ms-32 {
    margin-inline-start: 8rem;
  }

  .xl\:ms-36 {
    margin-inline-start: 9rem;
  }

  .xl\:ms-4 {
    margin-inline-start: 1rem;
  }

  .xl\:ms-40 {
    margin-inline-start: 10rem;
  }

  .xl\:ms-44 {
    margin-inline-start: 11rem;
  }

  .xl\:ms-48 {
    margin-inline-start: 12rem;
  }

  .xl\:ms-5 {
    margin-inline-start: 1.25rem;
  }

  .xl\:ms-52 {
    margin-inline-start: 13rem;
  }

  .xl\:ms-56 {
    margin-inline-start: 14rem;
  }

  .xl\:ms-6 {
    margin-inline-start: 1.5rem;
  }

  .xl\:ms-60 {
    margin-inline-start: 15rem;
  }

  .xl\:ms-64 {
    margin-inline-start: 16rem;
  }

  .xl\:ms-7 {
    margin-inline-start: 1.75rem;
  }

  .xl\:ms-72 {
    margin-inline-start: 18rem;
  }

  .xl\:ms-8 {
    margin-inline-start: 2rem;
  }

  .xl\:ms-80 {
    margin-inline-start: 20rem;
  }

  .xl\:ms-9 {
    margin-inline-start: 2.25rem;
  }

  .xl\:ms-96 {
    margin-inline-start: 24rem;
  }

  .xl\:ms-auto {
    margin-inline-start: auto;
  }

  .xl\:ms-px {
    margin-inline-start: 1px;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:mt-0\.5 {
    margin-top: .125rem;
  }

  .xl\:mt-1 {
    margin-top: .25rem;
  }

  .xl\:mt-1\.5 {
    margin-top: .375rem;
  }

  .xl\:mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:mt-11 {
    margin-top: 2.75rem;
  }

  .xl\:mt-12 {
    margin-top: 3rem;
  }

  .xl\:mt-14 {
    margin-top: 3.5rem;
  }

  .xl\:mt-16 {
    margin-top: 4rem;
  }

  .xl\:mt-2 {
    margin-top: .5rem;
  }

  .xl\:mt-2\.5 {
    margin-top: .625rem;
  }

  .xl\:mt-20 {
    margin-top: 5rem;
  }

  .xl\:mt-24 {
    margin-top: 6rem;
  }

  .xl\:mt-28 {
    margin-top: 7rem;
  }

  .xl\:mt-3 {
    margin-top: .75rem;
  }

  .xl\:mt-3\.5 {
    margin-top: .875rem;
  }

  .xl\:mt-32 {
    margin-top: 8rem;
  }

  .xl\:mt-36 {
    margin-top: 9rem;
  }

  .xl\:mt-4 {
    margin-top: 1rem;
  }

  .xl\:mt-40 {
    margin-top: 10rem;
  }

  .xl\:mt-44 {
    margin-top: 11rem;
  }

  .xl\:mt-48 {
    margin-top: 12rem;
  }

  .xl\:mt-5 {
    margin-top: 1.25rem;
  }

  .xl\:mt-52 {
    margin-top: 13rem;
  }

  .xl\:mt-56 {
    margin-top: 14rem;
  }

  .xl\:mt-6 {
    margin-top: 1.5rem;
  }

  .xl\:mt-60 {
    margin-top: 15rem;
  }

  .xl\:mt-64 {
    margin-top: 16rem;
  }

  .xl\:mt-7 {
    margin-top: 1.75rem;
  }

  .xl\:mt-72 {
    margin-top: 18rem;
  }

  .xl\:mt-8 {
    margin-top: 2rem;
  }

  .xl\:mt-80 {
    margin-top: 20rem;
  }

  .xl\:mt-9 {
    margin-top: 2.25rem;
  }

  .xl\:mt-96 {
    margin-top: 24rem;
  }

  .xl\:mt-auto {
    margin-top: auto;
  }

  .xl\:mt-px {
    margin-top: 1px;
  }

  .xl\:box-border {
    box-sizing: border-box;
  }

  .xl\:box-content {
    box-sizing: content-box;
  }

  .xl\:line-clamp-1 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .xl\:line-clamp-2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .xl\:line-clamp-3 {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .xl\:line-clamp-4 {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .xl\:line-clamp-5 {
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .xl\:line-clamp-6 {
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .xl\:line-clamp-none {
    -webkit-line-clamp: none;
    -webkit-box-orient: horizontal;
    display: block;
    overflow: visible;
  }

  .xl\:block {
    display: block;
  }

  .xl\:inline-block {
    display: inline-block;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:inline-flex {
    display: inline-flex;
  }

  .xl\:table {
    display: table;
  }

  .xl\:inline-table {
    display: inline-table;
  }

  .xl\:table-caption {
    display: table-caption;
  }

  .xl\:table-cell {
    display: table-cell;
  }

  .xl\:table-column {
    display: table-column;
  }

  .xl\:table-column-group {
    display: table-column-group;
  }

  .xl\:table-footer-group {
    display: table-footer-group;
  }

  .xl\:table-header-group {
    display: table-header-group;
  }

  .xl\:table-row-group {
    display: table-row-group;
  }

  .xl\:table-row {
    display: table-row;
  }

  .xl\:flow-root {
    display: flow-root;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:inline-grid {
    display: inline-grid;
  }

  .xl\:contents {
    display: contents;
  }

  .xl\:list-item {
    display: list-item;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:aspect-auto {
    aspect-ratio: auto;
  }

  .xl\:aspect-square {
    aspect-ratio: 1;
  }

  .xl\:aspect-video {
    aspect-ratio: 16 / 9;
  }

  .xl\:size-0 {
    width: 0;
    height: 0;
  }

  .xl\:size-0\.5 {
    width: .125rem;
    height: .125rem;
  }

  .xl\:size-1 {
    width: .25rem;
    height: .25rem;
  }

  .xl\:size-1\.5 {
    width: .375rem;
    height: .375rem;
  }

  .xl\:size-1\/12 {
    width: 8.33333%;
    height: 8.33333%;
  }

  .xl\:size-1\/2 {
    width: 50%;
    height: 50%;
  }

  .xl\:size-1\/3 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .xl\:size-1\/4 {
    width: 25%;
    height: 25%;
  }

  .xl\:size-1\/5 {
    width: 20%;
    height: 20%;
  }

  .xl\:size-1\/6 {
    width: 16.6667%;
    height: 16.6667%;
  }

  .xl\:size-10 {
    width: 2.5rem;
    height: 2.5rem;
  }

  .xl\:size-10\/12 {
    width: 83.3333%;
    height: 83.3333%;
  }

  .xl\:size-11 {
    width: 2.75rem;
    height: 2.75rem;
  }

  .xl\:size-11\/12 {
    width: 91.6667%;
    height: 91.6667%;
  }

  .xl\:size-12 {
    width: 3rem;
    height: 3rem;
  }

  .xl\:size-14 {
    width: 3.5rem;
    height: 3.5rem;
  }

  .xl\:size-16 {
    width: 4rem;
    height: 4rem;
  }

  .xl\:size-2 {
    width: .5rem;
    height: .5rem;
  }

  .xl\:size-2\.5 {
    width: .625rem;
    height: .625rem;
  }

  .xl\:size-2\/12 {
    width: 16.6667%;
    height: 16.6667%;
  }

  .xl\:size-2\/3 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .xl\:size-2\/4 {
    width: 50%;
    height: 50%;
  }

  .xl\:size-2\/5 {
    width: 40%;
    height: 40%;
  }

  .xl\:size-2\/6 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .xl\:size-20 {
    width: 5rem;
    height: 5rem;
  }

  .xl\:size-24 {
    width: 6rem;
    height: 6rem;
  }

  .xl\:size-28 {
    width: 7rem;
    height: 7rem;
  }

  .xl\:size-3 {
    width: .75rem;
    height: .75rem;
  }

  .xl\:size-3\.5 {
    width: .875rem;
    height: .875rem;
  }

  .xl\:size-3\/12 {
    width: 25%;
    height: 25%;
  }

  .xl\:size-3\/4 {
    width: 75%;
    height: 75%;
  }

  .xl\:size-3\/5 {
    width: 60%;
    height: 60%;
  }

  .xl\:size-3\/6 {
    width: 50%;
    height: 50%;
  }

  .xl\:size-32 {
    width: 8rem;
    height: 8rem;
  }

  .xl\:size-36 {
    width: 9rem;
    height: 9rem;
  }

  .xl\:size-4 {
    width: 1rem;
    height: 1rem;
  }

  .xl\:size-4\/12 {
    width: 33.3333%;
    height: 33.3333%;
  }

  .xl\:size-4\/5 {
    width: 80%;
    height: 80%;
  }

  .xl\:size-4\/6 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .xl\:size-40 {
    width: 10rem;
    height: 10rem;
  }

  .xl\:size-44 {
    width: 11rem;
    height: 11rem;
  }

  .xl\:size-48 {
    width: 12rem;
    height: 12rem;
  }

  .xl\:size-5 {
    width: 1.25rem;
    height: 1.25rem;
  }

  .xl\:size-5\/12 {
    width: 41.6667%;
    height: 41.6667%;
  }

  .xl\:size-5\/6 {
    width: 83.3333%;
    height: 83.3333%;
  }

  .xl\:size-52 {
    width: 13rem;
    height: 13rem;
  }

  .xl\:size-56 {
    width: 14rem;
    height: 14rem;
  }

  .xl\:size-6 {
    width: 1.5rem;
    height: 1.5rem;
  }

  .xl\:size-6\/12 {
    width: 50%;
    height: 50%;
  }

  .xl\:size-60 {
    width: 15rem;
    height: 15rem;
  }

  .xl\:size-64 {
    width: 16rem;
    height: 16rem;
  }

  .xl\:size-7 {
    width: 1.75rem;
    height: 1.75rem;
  }

  .xl\:size-7\/12 {
    width: 58.3333%;
    height: 58.3333%;
  }

  .xl\:size-72 {
    width: 18rem;
    height: 18rem;
  }

  .xl\:size-8 {
    width: 2rem;
    height: 2rem;
  }

  .xl\:size-8\/12 {
    width: 66.6667%;
    height: 66.6667%;
  }

  .xl\:size-80 {
    width: 20rem;
    height: 20rem;
  }

  .xl\:size-9 {
    width: 2.25rem;
    height: 2.25rem;
  }

  .xl\:size-9\/12 {
    width: 75%;
    height: 75%;
  }

  .xl\:size-96 {
    width: 24rem;
    height: 24rem;
  }

  .xl\:size-auto {
    width: auto;
    height: auto;
  }

  .xl\:size-fit {
    width: fit-content;
    height: fit-content;
  }

  .xl\:size-full {
    width: 100%;
    height: 100%;
  }

  .xl\:size-max {
    width: max-content;
    height: max-content;
  }

  .xl\:size-min {
    width: min-content;
    height: min-content;
  }

  .xl\:size-px {
    width: 1px;
    height: 1px;
  }

  .xl\:h-0 {
    height: 0;
  }

  .xl\:h-0\.5 {
    height: .125rem;
  }

  .xl\:h-1 {
    height: .25rem;
  }

  .xl\:h-1\.5 {
    height: .375rem;
  }

  .xl\:h-1\/2 {
    height: 50%;
  }

  .xl\:h-1\/3 {
    height: 33.3333%;
  }

  .xl\:h-1\/4 {
    height: 25%;
  }

  .xl\:h-1\/5 {
    height: 20%;
  }

  .xl\:h-1\/6 {
    height: 16.6667%;
  }

  .xl\:h-10 {
    height: 2.5rem;
  }

  .xl\:h-11 {
    height: 2.75rem;
  }

  .xl\:h-12 {
    height: 3rem;
  }

  .xl\:h-14 {
    height: 3.5rem;
  }

  .xl\:h-16 {
    height: 4rem;
  }

  .xl\:h-2 {
    height: .5rem;
  }

  .xl\:h-2\.5 {
    height: .625rem;
  }

  .xl\:h-2\/3 {
    height: 66.6667%;
  }

  .xl\:h-2\/4 {
    height: 50%;
  }

  .xl\:h-2\/5 {
    height: 40%;
  }

  .xl\:h-2\/6 {
    height: 33.3333%;
  }

  .xl\:h-20 {
    height: 5rem;
  }

  .xl\:h-24 {
    height: 6rem;
  }

  .xl\:h-28 {
    height: 7rem;
  }

  .xl\:h-3 {
    height: .75rem;
  }

  .xl\:h-3\.5 {
    height: .875rem;
  }

  .xl\:h-3\/4 {
    height: 75%;
  }

  .xl\:h-3\/5 {
    height: 60%;
  }

  .xl\:h-3\/6 {
    height: 50%;
  }

  .xl\:h-32 {
    height: 8rem;
  }

  .xl\:h-36 {
    height: 9rem;
  }

  .xl\:h-4 {
    height: 1rem;
  }

  .xl\:h-4\/5 {
    height: 80%;
  }

  .xl\:h-4\/6 {
    height: 66.6667%;
  }

  .xl\:h-40 {
    height: 10rem;
  }

  .xl\:h-44 {
    height: 11rem;
  }

  .xl\:h-48 {
    height: 12rem;
  }

  .xl\:h-5 {
    height: 1.25rem;
  }

  .xl\:h-5\/6 {
    height: 83.3333%;
  }

  .xl\:h-52 {
    height: 13rem;
  }

  .xl\:h-56 {
    height: 14rem;
  }

  .xl\:h-6 {
    height: 1.5rem;
  }

  .xl\:h-60 {
    height: 15rem;
  }

  .xl\:h-64 {
    height: 16rem;
  }

  .xl\:h-7 {
    height: 1.75rem;
  }

  .xl\:h-72 {
    height: 18rem;
  }

  .xl\:h-8 {
    height: 2rem;
  }

  .xl\:h-80 {
    height: 20rem;
  }

  .xl\:h-9 {
    height: 2.25rem;
  }

  .xl\:h-96 {
    height: 24rem;
  }

  .xl\:h-auto {
    height: auto;
  }

  .xl\:h-dvh {
    height: 100dvh;
  }

  .xl\:h-fit {
    height: fit-content;
  }

  .xl\:h-full {
    height: 100%;
  }

  .xl\:h-lvh {
    height: 100lvh;
  }

  .xl\:h-max {
    height: max-content;
  }

  .xl\:h-min {
    height: min-content;
  }

  .xl\:h-px {
    height: 1px;
  }

  .xl\:h-screen {
    height: 100vh;
  }

  .xl\:h-svh {
    height: 100svh;
  }

  .xl\:max-h-0 {
    max-height: 0;
  }

  .xl\:max-h-0\.5 {
    max-height: .125rem;
  }

  .xl\:max-h-1 {
    max-height: .25rem;
  }

  .xl\:max-h-1\.5 {
    max-height: .375rem;
  }

  .xl\:max-h-10 {
    max-height: 2.5rem;
  }

  .xl\:max-h-11 {
    max-height: 2.75rem;
  }

  .xl\:max-h-12 {
    max-height: 3rem;
  }

  .xl\:max-h-14 {
    max-height: 3.5rem;
  }

  .xl\:max-h-16 {
    max-height: 4rem;
  }

  .xl\:max-h-2 {
    max-height: .5rem;
  }

  .xl\:max-h-2\.5 {
    max-height: .625rem;
  }

  .xl\:max-h-20 {
    max-height: 5rem;
  }

  .xl\:max-h-24 {
    max-height: 6rem;
  }

  .xl\:max-h-28 {
    max-height: 7rem;
  }

  .xl\:max-h-3 {
    max-height: .75rem;
  }

  .xl\:max-h-3\.5 {
    max-height: .875rem;
  }

  .xl\:max-h-32 {
    max-height: 8rem;
  }

  .xl\:max-h-36 {
    max-height: 9rem;
  }

  .xl\:max-h-4 {
    max-height: 1rem;
  }

  .xl\:max-h-40 {
    max-height: 10rem;
  }

  .xl\:max-h-44 {
    max-height: 11rem;
  }

  .xl\:max-h-48 {
    max-height: 12rem;
  }

  .xl\:max-h-5 {
    max-height: 1.25rem;
  }

  .xl\:max-h-52 {
    max-height: 13rem;
  }

  .xl\:max-h-56 {
    max-height: 14rem;
  }

  .xl\:max-h-6 {
    max-height: 1.5rem;
  }

  .xl\:max-h-60 {
    max-height: 15rem;
  }

  .xl\:max-h-64 {
    max-height: 16rem;
  }

  .xl\:max-h-7 {
    max-height: 1.75rem;
  }

  .xl\:max-h-72 {
    max-height: 18rem;
  }

  .xl\:max-h-8 {
    max-height: 2rem;
  }

  .xl\:max-h-80 {
    max-height: 20rem;
  }

  .xl\:max-h-9 {
    max-height: 2.25rem;
  }

  .xl\:max-h-96 {
    max-height: 24rem;
  }

  .xl\:max-h-dvh {
    max-height: 100dvh;
  }

  .xl\:max-h-fit {
    max-height: fit-content;
  }

  .xl\:max-h-full {
    max-height: 100%;
  }

  .xl\:max-h-lvh {
    max-height: 100lvh;
  }

  .xl\:max-h-max {
    max-height: max-content;
  }

  .xl\:max-h-min {
    max-height: min-content;
  }

  .xl\:max-h-none {
    max-height: none;
  }

  .xl\:max-h-px {
    max-height: 1px;
  }

  .xl\:max-h-screen {
    max-height: 100vh;
  }

  .xl\:max-h-svh {
    max-height: 100svh;
  }

  .xl\:min-h-0 {
    min-height: 0;
  }

  .xl\:min-h-0\.5 {
    min-height: .125rem;
  }

  .xl\:min-h-1 {
    min-height: .25rem;
  }

  .xl\:min-h-1\.5 {
    min-height: .375rem;
  }

  .xl\:min-h-10 {
    min-height: 2.5rem;
  }

  .xl\:min-h-11 {
    min-height: 2.75rem;
  }

  .xl\:min-h-12 {
    min-height: 3rem;
  }

  .xl\:min-h-14 {
    min-height: 3.5rem;
  }

  .xl\:min-h-16 {
    min-height: 4rem;
  }

  .xl\:min-h-2 {
    min-height: .5rem;
  }

  .xl\:min-h-2\.5 {
    min-height: .625rem;
  }

  .xl\:min-h-20 {
    min-height: 5rem;
  }

  .xl\:min-h-24 {
    min-height: 6rem;
  }

  .xl\:min-h-28 {
    min-height: 7rem;
  }

  .xl\:min-h-3 {
    min-height: .75rem;
  }

  .xl\:min-h-3\.5 {
    min-height: .875rem;
  }

  .xl\:min-h-32 {
    min-height: 8rem;
  }

  .xl\:min-h-36 {
    min-height: 9rem;
  }

  .xl\:min-h-4 {
    min-height: 1rem;
  }

  .xl\:min-h-40 {
    min-height: 10rem;
  }

  .xl\:min-h-44 {
    min-height: 11rem;
  }

  .xl\:min-h-48 {
    min-height: 12rem;
  }

  .xl\:min-h-5 {
    min-height: 1.25rem;
  }

  .xl\:min-h-52 {
    min-height: 13rem;
  }

  .xl\:min-h-56 {
    min-height: 14rem;
  }

  .xl\:min-h-6 {
    min-height: 1.5rem;
  }

  .xl\:min-h-60 {
    min-height: 15rem;
  }

  .xl\:min-h-64 {
    min-height: 16rem;
  }

  .xl\:min-h-7 {
    min-height: 1.75rem;
  }

  .xl\:min-h-72 {
    min-height: 18rem;
  }

  .xl\:min-h-8 {
    min-height: 2rem;
  }

  .xl\:min-h-80 {
    min-height: 20rem;
  }

  .xl\:min-h-9 {
    min-height: 2.25rem;
  }

  .xl\:min-h-96 {
    min-height: 24rem;
  }

  .xl\:min-h-dvh {
    min-height: 100dvh;
  }

  .xl\:min-h-fit {
    min-height: fit-content;
  }

  .xl\:min-h-full {
    min-height: 100%;
  }

  .xl\:min-h-lvh {
    min-height: 100lvh;
  }

  .xl\:min-h-max {
    min-height: max-content;
  }

  .xl\:min-h-min {
    min-height: min-content;
  }

  .xl\:min-h-px {
    min-height: 1px;
  }

  .xl\:min-h-screen {
    min-height: 100vh;
  }

  .xl\:min-h-svh {
    min-height: 100svh;
  }

  .xl\:w-0 {
    width: 0;
  }

  .xl\:w-0\.5 {
    width: .125rem;
  }

  .xl\:w-1 {
    width: .25rem;
  }

  .xl\:w-1\.5 {
    width: .375rem;
  }

  .xl\:w-1\/12 {
    width: 8.33333%;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-1\/4 {
    width: 25%;
  }

  .xl\:w-1\/5 {
    width: 20%;
  }

  .xl\:w-1\/6 {
    width: 16.6667%;
  }

  .xl\:w-10 {
    width: 2.5rem;
  }

  .xl\:w-10\/12 {
    width: 83.3333%;
  }

  .xl\:w-11 {
    width: 2.75rem;
  }

  .xl\:w-11\/12 {
    width: 91.6667%;
  }

  .xl\:w-12 {
    width: 3rem;
  }

  .xl\:w-14 {
    width: 3.5rem;
  }

  .xl\:w-16 {
    width: 4rem;
  }

  .xl\:w-2 {
    width: .5rem;
  }

  .xl\:w-2\.5 {
    width: .625rem;
  }

  .xl\:w-2\/12 {
    width: 16.6667%;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:w-2\/4 {
    width: 50%;
  }

  .xl\:w-2\/5 {
    width: 40%;
  }

  .xl\:w-2\/6 {
    width: 33.3333%;
  }

  .xl\:w-20 {
    width: 5rem;
  }

  .xl\:w-24 {
    width: 6rem;
  }

  .xl\:w-28 {
    width: 7rem;
  }

  .xl\:w-3 {
    width: .75rem;
  }

  .xl\:w-3\.5 {
    width: .875rem;
  }

  .xl\:w-3\/12 {
    width: 25%;
  }

  .xl\:w-3\/4 {
    width: 75%;
  }

  .xl\:w-3\/5 {
    width: 60%;
  }

  .xl\:w-3\/6 {
    width: 50%;
  }

  .xl\:w-32 {
    width: 8rem;
  }

  .xl\:w-36 {
    width: 9rem;
  }

  .xl\:w-4 {
    width: 1rem;
  }

  .xl\:w-4\/12 {
    width: 33.3333%;
  }

  .xl\:w-4\/5 {
    width: 80%;
  }

  .xl\:w-4\/6 {
    width: 66.6667%;
  }

  .xl\:w-40 {
    width: 10rem;
  }

  .xl\:w-44 {
    width: 11rem;
  }

  .xl\:w-48 {
    width: 12rem;
  }

  .xl\:w-5 {
    width: 1.25rem;
  }

  .xl\:w-5\/12 {
    width: 41.6667%;
  }

  .xl\:w-5\/6 {
    width: 83.3333%;
  }

  .xl\:w-52 {
    width: 13rem;
  }

  .xl\:w-56 {
    width: 14rem;
  }

  .xl\:w-6 {
    width: 1.5rem;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:w-60 {
    width: 15rem;
  }

  .xl\:w-64 {
    width: 16rem;
  }

  .xl\:w-7 {
    width: 1.75rem;
  }

  .xl\:w-7\/12 {
    width: 58.3333%;
  }

  .xl\:w-72 {
    width: 18rem;
  }

  .xl\:w-8 {
    width: 2rem;
  }

  .xl\:w-8\/12 {
    width: 66.6667%;
  }

  .xl\:w-80 {
    width: 20rem;
  }

  .xl\:w-9 {
    width: 2.25rem;
  }

  .xl\:w-9\/12 {
    width: 75%;
  }

  .xl\:w-96 {
    width: 24rem;
  }

  .xl\:w-auto {
    width: auto;
  }

  .xl\:w-dvw {
    width: 100dvw;
  }

  .xl\:w-fit {
    width: fit-content;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:w-lvw {
    width: 100lvw;
  }

  .xl\:w-max {
    width: max-content;
  }

  .xl\:w-min {
    width: min-content;
  }

  .xl\:w-px {
    width: 1px;
  }

  .xl\:w-screen {
    width: 100vw;
  }

  .xl\:w-svw {
    width: 100svw;
  }

  .xl\:min-w-0 {
    min-width: 0;
  }

  .xl\:min-w-0\.5 {
    min-width: .125rem;
  }

  .xl\:min-w-1 {
    min-width: .25rem;
  }

  .xl\:min-w-1\.5 {
    min-width: .375rem;
  }

  .xl\:min-w-10 {
    min-width: 2.5rem;
  }

  .xl\:min-w-11 {
    min-width: 2.75rem;
  }

  .xl\:min-w-12 {
    min-width: 3rem;
  }

  .xl\:min-w-14 {
    min-width: 3.5rem;
  }

  .xl\:min-w-16 {
    min-width: 4rem;
  }

  .xl\:min-w-2 {
    min-width: .5rem;
  }

  .xl\:min-w-2\.5 {
    min-width: .625rem;
  }

  .xl\:min-w-20 {
    min-width: 5rem;
  }

  .xl\:min-w-24 {
    min-width: 6rem;
  }

  .xl\:min-w-28 {
    min-width: 7rem;
  }

  .xl\:min-w-3 {
    min-width: .75rem;
  }

  .xl\:min-w-3\.5 {
    min-width: .875rem;
  }

  .xl\:min-w-32 {
    min-width: 8rem;
  }

  .xl\:min-w-36 {
    min-width: 9rem;
  }

  .xl\:min-w-4 {
    min-width: 1rem;
  }

  .xl\:min-w-40 {
    min-width: 10rem;
  }

  .xl\:min-w-44 {
    min-width: 11rem;
  }

  .xl\:min-w-48 {
    min-width: 12rem;
  }

  .xl\:min-w-5 {
    min-width: 1.25rem;
  }

  .xl\:min-w-52 {
    min-width: 13rem;
  }

  .xl\:min-w-56 {
    min-width: 14rem;
  }

  .xl\:min-w-6 {
    min-width: 1.5rem;
  }

  .xl\:min-w-60 {
    min-width: 15rem;
  }

  .xl\:min-w-64 {
    min-width: 16rem;
  }

  .xl\:min-w-7 {
    min-width: 1.75rem;
  }

  .xl\:min-w-72 {
    min-width: 18rem;
  }

  .xl\:min-w-8 {
    min-width: 2rem;
  }

  .xl\:min-w-80 {
    min-width: 20rem;
  }

  .xl\:min-w-9 {
    min-width: 2.25rem;
  }

  .xl\:min-w-96 {
    min-width: 24rem;
  }

  .xl\:min-w-fit {
    min-width: fit-content;
  }

  .xl\:min-w-full {
    min-width: 100%;
  }

  .xl\:min-w-max {
    min-width: max-content;
  }

  .xl\:min-w-min {
    min-width: min-content;
  }

  .xl\:min-w-px {
    min-width: 1px;
  }

  .xl\:max-w-0 {
    max-width: 0;
  }

  .xl\:max-w-0\.5 {
    max-width: .125rem;
  }

  .xl\:max-w-1 {
    max-width: .25rem;
  }

  .xl\:max-w-1\.5 {
    max-width: .375rem;
  }

  .xl\:max-w-10 {
    max-width: 2.5rem;
  }

  .xl\:max-w-11 {
    max-width: 2.75rem;
  }

  .xl\:max-w-12 {
    max-width: 3rem;
  }

  .xl\:max-w-14 {
    max-width: 3.5rem;
  }

  .xl\:max-w-16 {
    max-width: 4rem;
  }

  .xl\:max-w-2 {
    max-width: .5rem;
  }

  .xl\:max-w-2\.5 {
    max-width: .625rem;
  }

  .xl\:max-w-20 {
    max-width: 5rem;
  }

  .xl\:max-w-24 {
    max-width: 6rem;
  }

  .xl\:max-w-28 {
    max-width: 7rem;
  }

  .xl\:max-w-2xl {
    max-width: 42rem;
  }

  .xl\:max-w-3 {
    max-width: .75rem;
  }

  .xl\:max-w-3\.5 {
    max-width: .875rem;
  }

  .xl\:max-w-32 {
    max-width: 8rem;
  }

  .xl\:max-w-36 {
    max-width: 9rem;
  }

  .xl\:max-w-3xl {
    max-width: 48rem;
  }

  .xl\:max-w-4 {
    max-width: 1rem;
  }

  .xl\:max-w-40 {
    max-width: 10rem;
  }

  .xl\:max-w-44 {
    max-width: 11rem;
  }

  .xl\:max-w-48 {
    max-width: 12rem;
  }

  .xl\:max-w-4xl {
    max-width: 56rem;
  }

  .xl\:max-w-5 {
    max-width: 1.25rem;
  }

  .xl\:max-w-52 {
    max-width: 13rem;
  }

  .xl\:max-w-56 {
    max-width: 14rem;
  }

  .xl\:max-w-5xl {
    max-width: 64rem;
  }

  .xl\:max-w-6 {
    max-width: 1.5rem;
  }

  .xl\:max-w-60 {
    max-width: 15rem;
  }

  .xl\:max-w-64 {
    max-width: 16rem;
  }

  .xl\:max-w-6xl {
    max-width: 72rem;
  }

  .xl\:max-w-7 {
    max-width: 1.75rem;
  }

  .xl\:max-w-72 {
    max-width: 18rem;
  }

  .xl\:max-w-7xl {
    max-width: 80rem;
  }

  .xl\:max-w-8 {
    max-width: 2rem;
  }

  .xl\:max-w-80 {
    max-width: 20rem;
  }

  .xl\:max-w-9 {
    max-width: 2.25rem;
  }

  .xl\:max-w-96 {
    max-width: 24rem;
  }

  .xl\:max-w-fit {
    max-width: fit-content;
  }

  .xl\:max-w-full {
    max-width: 100%;
  }

  .xl\:max-w-lg {
    max-width: 32rem;
  }

  .xl\:max-w-max {
    max-width: max-content;
  }

  .xl\:max-w-md {
    max-width: 28rem;
  }

  .xl\:max-w-min {
    max-width: min-content;
  }

  .xl\:max-w-none {
    max-width: none;
  }

  .xl\:max-w-prose {
    max-width: 65ch;
  }

  .xl\:max-w-px {
    max-width: 1px;
  }

  .xl\:max-w-screen-2xl {
    max-width: 1536px;
  }

  .xl\:max-w-screen-lg {
    max-width: 1024px;
  }

  .xl\:max-w-screen-md {
    max-width: 768px;
  }

  .xl\:max-w-screen-sm {
    max-width: 640px;
  }

  .xl\:max-w-screen-xl {
    max-width: 1280px;
  }

  .xl\:max-w-sm {
    max-width: 24rem;
  }

  .xl\:max-w-xl {
    max-width: 36rem;
  }

  .xl\:max-w-xs {
    max-width: 20rem;
  }

  .xl\:flex-1 {
    flex: 1;
  }

  .xl\:flex-auto {
    flex: auto;
  }

  .xl\:flex-initial {
    flex: 0 auto;
  }

  .xl\:flex-none {
    flex: none;
  }

  .xl\:flex-shrink {
    flex-shrink: 1;
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .xl\:shrink {
    flex-shrink: 1;
  }

  .xl\:shrink-0 {
    flex-shrink: 0;
  }

  .xl\:flex-grow {
    flex-grow: 1;
  }

  .xl\:flex-grow-0 {
    flex-grow: 0;
  }

  .xl\:grow {
    flex-grow: 1;
  }

  .xl\:grow-0 {
    flex-grow: 0;
  }

  .xl\:basis-0 {
    flex-basis: 0;
  }

  .xl\:basis-0\.5 {
    flex-basis: .125rem;
  }

  .xl\:basis-1 {
    flex-basis: .25rem;
  }

  .xl\:basis-1\.5 {
    flex-basis: .375rem;
  }

  .xl\:basis-1\/12 {
    flex-basis: 8.33333%;
  }

  .xl\:basis-1\/2 {
    flex-basis: 50%;
  }

  .xl\:basis-1\/3 {
    flex-basis: 33.3333%;
  }

  .xl\:basis-1\/4 {
    flex-basis: 25%;
  }

  .xl\:basis-1\/5 {
    flex-basis: 20%;
  }

  .xl\:basis-1\/6 {
    flex-basis: 16.6667%;
  }

  .xl\:basis-10 {
    flex-basis: 2.5rem;
  }

  .xl\:basis-10\/12 {
    flex-basis: 83.3333%;
  }

  .xl\:basis-11 {
    flex-basis: 2.75rem;
  }

  .xl\:basis-11\/12 {
    flex-basis: 91.6667%;
  }

  .xl\:basis-12 {
    flex-basis: 3rem;
  }

  .xl\:basis-14 {
    flex-basis: 3.5rem;
  }

  .xl\:basis-16 {
    flex-basis: 4rem;
  }

  .xl\:basis-2 {
    flex-basis: .5rem;
  }

  .xl\:basis-2\.5 {
    flex-basis: .625rem;
  }

  .xl\:basis-2\/12 {
    flex-basis: 16.6667%;
  }

  .xl\:basis-2\/3 {
    flex-basis: 66.6667%;
  }

  .xl\:basis-2\/4 {
    flex-basis: 50%;
  }

  .xl\:basis-2\/5 {
    flex-basis: 40%;
  }

  .xl\:basis-2\/6 {
    flex-basis: 33.3333%;
  }

  .xl\:basis-20 {
    flex-basis: 5rem;
  }

  .xl\:basis-24 {
    flex-basis: 6rem;
  }

  .xl\:basis-28 {
    flex-basis: 7rem;
  }

  .xl\:basis-3 {
    flex-basis: .75rem;
  }

  .xl\:basis-3\.5 {
    flex-basis: .875rem;
  }

  .xl\:basis-3\/12 {
    flex-basis: 25%;
  }

  .xl\:basis-3\/4 {
    flex-basis: 75%;
  }

  .xl\:basis-3\/5 {
    flex-basis: 60%;
  }

  .xl\:basis-3\/6 {
    flex-basis: 50%;
  }

  .xl\:basis-32 {
    flex-basis: 8rem;
  }

  .xl\:basis-36 {
    flex-basis: 9rem;
  }

  .xl\:basis-4 {
    flex-basis: 1rem;
  }

  .xl\:basis-4\/12 {
    flex-basis: 33.3333%;
  }

  .xl\:basis-4\/5 {
    flex-basis: 80%;
  }

  .xl\:basis-4\/6 {
    flex-basis: 66.6667%;
  }

  .xl\:basis-40 {
    flex-basis: 10rem;
  }

  .xl\:basis-44 {
    flex-basis: 11rem;
  }

  .xl\:basis-48 {
    flex-basis: 12rem;
  }

  .xl\:basis-5 {
    flex-basis: 1.25rem;
  }

  .xl\:basis-5\/12 {
    flex-basis: 41.6667%;
  }

  .xl\:basis-5\/6 {
    flex-basis: 83.3333%;
  }

  .xl\:basis-52 {
    flex-basis: 13rem;
  }

  .xl\:basis-56 {
    flex-basis: 14rem;
  }

  .xl\:basis-6 {
    flex-basis: 1.5rem;
  }

  .xl\:basis-6\/12 {
    flex-basis: 50%;
  }

  .xl\:basis-60 {
    flex-basis: 15rem;
  }

  .xl\:basis-64 {
    flex-basis: 16rem;
  }

  .xl\:basis-7 {
    flex-basis: 1.75rem;
  }

  .xl\:basis-7\/12 {
    flex-basis: 58.3333%;
  }

  .xl\:basis-72 {
    flex-basis: 18rem;
  }

  .xl\:basis-8 {
    flex-basis: 2rem;
  }

  .xl\:basis-8\/12 {
    flex-basis: 66.6667%;
  }

  .xl\:basis-80 {
    flex-basis: 20rem;
  }

  .xl\:basis-9 {
    flex-basis: 2.25rem;
  }

  .xl\:basis-9\/12 {
    flex-basis: 75%;
  }

  .xl\:basis-96 {
    flex-basis: 24rem;
  }

  .xl\:basis-auto {
    flex-basis: auto;
  }

  .xl\:basis-full {
    flex-basis: 100%;
  }

  .xl\:basis-px {
    flex-basis: 1px;
  }

  .xl\:table-auto {
    table-layout: auto;
  }

  .xl\:table-fixed {
    table-layout: fixed;
  }

  .xl\:caption-top {
    caption-side: top;
  }

  .xl\:caption-bottom {
    caption-side: bottom;
  }

  .xl\:border-collapse {
    border-collapse: collapse;
  }

  .xl\:border-separate {
    border-collapse: separate;
  }

  .xl\:border-spacing-0 {
    --tw-border-spacing-x: 0px;
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-0\.5 {
    --tw-border-spacing-x: .125rem;
    --tw-border-spacing-y: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-1 {
    --tw-border-spacing-x: .25rem;
    --tw-border-spacing-y: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-1\.5 {
    --tw-border-spacing-x: .375rem;
    --tw-border-spacing-y: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-10 {
    --tw-border-spacing-x: 2.5rem;
    --tw-border-spacing-y: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-11 {
    --tw-border-spacing-x: 2.75rem;
    --tw-border-spacing-y: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-12 {
    --tw-border-spacing-x: 3rem;
    --tw-border-spacing-y: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-14 {
    --tw-border-spacing-x: 3.5rem;
    --tw-border-spacing-y: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-16 {
    --tw-border-spacing-x: 4rem;
    --tw-border-spacing-y: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-2 {
    --tw-border-spacing-x: .5rem;
    --tw-border-spacing-y: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-2\.5 {
    --tw-border-spacing-x: .625rem;
    --tw-border-spacing-y: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-20 {
    --tw-border-spacing-x: 5rem;
    --tw-border-spacing-y: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-24 {
    --tw-border-spacing-x: 6rem;
    --tw-border-spacing-y: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-28 {
    --tw-border-spacing-x: 7rem;
    --tw-border-spacing-y: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-3 {
    --tw-border-spacing-x: .75rem;
    --tw-border-spacing-y: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-3\.5 {
    --tw-border-spacing-x: .875rem;
    --tw-border-spacing-y: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-32 {
    --tw-border-spacing-x: 8rem;
    --tw-border-spacing-y: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-36 {
    --tw-border-spacing-x: 9rem;
    --tw-border-spacing-y: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-4 {
    --tw-border-spacing-x: 1rem;
    --tw-border-spacing-y: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-40 {
    --tw-border-spacing-x: 10rem;
    --tw-border-spacing-y: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-44 {
    --tw-border-spacing-x: 11rem;
    --tw-border-spacing-y: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-48 {
    --tw-border-spacing-x: 12rem;
    --tw-border-spacing-y: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-5 {
    --tw-border-spacing-x: 1.25rem;
    --tw-border-spacing-y: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-52 {
    --tw-border-spacing-x: 13rem;
    --tw-border-spacing-y: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-56 {
    --tw-border-spacing-x: 14rem;
    --tw-border-spacing-y: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-6 {
    --tw-border-spacing-x: 1.5rem;
    --tw-border-spacing-y: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-60 {
    --tw-border-spacing-x: 15rem;
    --tw-border-spacing-y: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-64 {
    --tw-border-spacing-x: 16rem;
    --tw-border-spacing-y: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-7 {
    --tw-border-spacing-x: 1.75rem;
    --tw-border-spacing-y: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-72 {
    --tw-border-spacing-x: 18rem;
    --tw-border-spacing-y: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-8 {
    --tw-border-spacing-x: 2rem;
    --tw-border-spacing-y: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-80 {
    --tw-border-spacing-x: 20rem;
    --tw-border-spacing-y: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-9 {
    --tw-border-spacing-x: 2.25rem;
    --tw-border-spacing-y: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-96 {
    --tw-border-spacing-x: 24rem;
    --tw-border-spacing-y: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-px {
    --tw-border-spacing-x: 1px;
    --tw-border-spacing-y: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-0 {
    --tw-border-spacing-x: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-0\.5 {
    --tw-border-spacing-x: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-1 {
    --tw-border-spacing-x: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-1\.5 {
    --tw-border-spacing-x: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-10 {
    --tw-border-spacing-x: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-11 {
    --tw-border-spacing-x: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-12 {
    --tw-border-spacing-x: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-14 {
    --tw-border-spacing-x: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-16 {
    --tw-border-spacing-x: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-2 {
    --tw-border-spacing-x: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-2\.5 {
    --tw-border-spacing-x: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-20 {
    --tw-border-spacing-x: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-24 {
    --tw-border-spacing-x: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-28 {
    --tw-border-spacing-x: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-3 {
    --tw-border-spacing-x: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-3\.5 {
    --tw-border-spacing-x: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-32 {
    --tw-border-spacing-x: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-36 {
    --tw-border-spacing-x: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-4 {
    --tw-border-spacing-x: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-40 {
    --tw-border-spacing-x: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-44 {
    --tw-border-spacing-x: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-48 {
    --tw-border-spacing-x: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-5 {
    --tw-border-spacing-x: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-52 {
    --tw-border-spacing-x: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-56 {
    --tw-border-spacing-x: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-6 {
    --tw-border-spacing-x: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-60 {
    --tw-border-spacing-x: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-64 {
    --tw-border-spacing-x: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-7 {
    --tw-border-spacing-x: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-72 {
    --tw-border-spacing-x: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-8 {
    --tw-border-spacing-x: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-80 {
    --tw-border-spacing-x: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-9 {
    --tw-border-spacing-x: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-96 {
    --tw-border-spacing-x: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-x-px {
    --tw-border-spacing-x: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-0 {
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-0\.5 {
    --tw-border-spacing-y: .125rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-1 {
    --tw-border-spacing-y: .25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-1\.5 {
    --tw-border-spacing-y: .375rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-10 {
    --tw-border-spacing-y: 2.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-11 {
    --tw-border-spacing-y: 2.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-12 {
    --tw-border-spacing-y: 3rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-14 {
    --tw-border-spacing-y: 3.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-16 {
    --tw-border-spacing-y: 4rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-2 {
    --tw-border-spacing-y: .5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-2\.5 {
    --tw-border-spacing-y: .625rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-20 {
    --tw-border-spacing-y: 5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-24 {
    --tw-border-spacing-y: 6rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-28 {
    --tw-border-spacing-y: 7rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-3 {
    --tw-border-spacing-y: .75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-3\.5 {
    --tw-border-spacing-y: .875rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-32 {
    --tw-border-spacing-y: 8rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-36 {
    --tw-border-spacing-y: 9rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-4 {
    --tw-border-spacing-y: 1rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-40 {
    --tw-border-spacing-y: 10rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-44 {
    --tw-border-spacing-y: 11rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-48 {
    --tw-border-spacing-y: 12rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-5 {
    --tw-border-spacing-y: 1.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-52 {
    --tw-border-spacing-y: 13rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-56 {
    --tw-border-spacing-y: 14rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-6 {
    --tw-border-spacing-y: 1.5rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-60 {
    --tw-border-spacing-y: 15rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-64 {
    --tw-border-spacing-y: 16rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-7 {
    --tw-border-spacing-y: 1.75rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-72 {
    --tw-border-spacing-y: 18rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-8 {
    --tw-border-spacing-y: 2rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-80 {
    --tw-border-spacing-y: 20rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-9 {
    --tw-border-spacing-y: 2.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-96 {
    --tw-border-spacing-y: 24rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:border-spacing-y-px {
    --tw-border-spacing-y: 1px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .xl\:origin-bottom {
    transform-origin: bottom;
  }

  .xl\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .xl\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .xl\:origin-center {
    transform-origin: center;
  }

  .xl\:origin-left {
    transform-origin: 0;
  }

  .xl\:origin-right {
    transform-origin: 100%;
  }

  .xl\:origin-top {
    transform-origin: top;
  }

  .xl\:origin-top-left {
    transform-origin: 0 0;
  }

  .xl\:origin-top-right {
    transform-origin: 100% 0;
  }

  .xl\:-translate-x-0 {
    --tw-translate-x: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-1 {
    --tw-translate-x: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-12 {
    --tw-translate-x: -3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-16 {
    --tw-translate-x: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-2 {
    --tw-translate-x: -.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-20 {
    --tw-translate-x: -5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-24 {
    --tw-translate-x: -6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-28 {
    --tw-translate-x: -7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-3 {
    --tw-translate-x: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-32 {
    --tw-translate-x: -8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-36 {
    --tw-translate-x: -9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-4 {
    --tw-translate-x: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-40 {
    --tw-translate-x: -10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-44 {
    --tw-translate-x: -11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-48 {
    --tw-translate-x: -12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-52 {
    --tw-translate-x: -13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-56 {
    --tw-translate-x: -14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-60 {
    --tw-translate-x: -15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-64 {
    --tw-translate-x: -16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-72 {
    --tw-translate-x: -18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-8 {
    --tw-translate-x: -2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-80 {
    --tw-translate-x: -20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-96 {
    --tw-translate-x: -24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-x-px {
    --tw-translate-x: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-0 {
    --tw-translate-y: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-1 {
    --tw-translate-y: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-12 {
    --tw-translate-y: -3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-16 {
    --tw-translate-y: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-2 {
    --tw-translate-y: -.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-20 {
    --tw-translate-y: -5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-24 {
    --tw-translate-y: -6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-28 {
    --tw-translate-y: -7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-3 {
    --tw-translate-y: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-32 {
    --tw-translate-y: -8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-36 {
    --tw-translate-y: -9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-4 {
    --tw-translate-y: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-40 {
    --tw-translate-y: -10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-44 {
    --tw-translate-y: -11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-48 {
    --tw-translate-y: -12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-52 {
    --tw-translate-y: -13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-56 {
    --tw-translate-y: -14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-60 {
    --tw-translate-y: -15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-64 {
    --tw-translate-y: -16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-72 {
    --tw-translate-y: -18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-8 {
    --tw-translate-y: -2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-80 {
    --tw-translate-y: -20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-96 {
    --tw-translate-y: -24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-px {
    --tw-translate-y: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-1 {
    --tw-translate-x: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-1\/4 {
    --tw-translate-x: 25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-10 {
    --tw-translate-x: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-11 {
    --tw-translate-x: 2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-12 {
    --tw-translate-x: 3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-14 {
    --tw-translate-x: 3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-16 {
    --tw-translate-x: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-2\/4 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-20 {
    --tw-translate-x: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-24 {
    --tw-translate-x: 6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-28 {
    --tw-translate-x: 7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-3 {
    --tw-translate-x: .75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-3\/4 {
    --tw-translate-x: 75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-32 {
    --tw-translate-x: 8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-36 {
    --tw-translate-x: 9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-4 {
    --tw-translate-x: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-40 {
    --tw-translate-x: 10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-44 {
    --tw-translate-x: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-48 {
    --tw-translate-x: 12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-5 {
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-52 {
    --tw-translate-x: 13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-56 {
    --tw-translate-x: 14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-6 {
    --tw-translate-x: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-60 {
    --tw-translate-x: 15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-64 {
    --tw-translate-x: 16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-7 {
    --tw-translate-x: 1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-72 {
    --tw-translate-x: 18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-8 {
    --tw-translate-x: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-80 {
    --tw-translate-x: 20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-9 {
    --tw-translate-x: 2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-96 {
    --tw-translate-x: 24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-full {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-px {
    --tw-translate-x: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-1 {
    --tw-translate-y: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-1\/2 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-1\/4 {
    --tw-translate-y: 25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-10 {
    --tw-translate-y: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-11 {
    --tw-translate-y: 2.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-12 {
    --tw-translate-y: 3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-14 {
    --tw-translate-y: 3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-16 {
    --tw-translate-y: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-2 {
    --tw-translate-y: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-2\/4 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-20 {
    --tw-translate-y: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-24 {
    --tw-translate-y: 6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-28 {
    --tw-translate-y: 7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-3 {
    --tw-translate-y: .75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-3\/4 {
    --tw-translate-y: 75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-32 {
    --tw-translate-y: 8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-36 {
    --tw-translate-y: 9rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-4 {
    --tw-translate-y: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-40 {
    --tw-translate-y: 10rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-44 {
    --tw-translate-y: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-48 {
    --tw-translate-y: 12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-5 {
    --tw-translate-y: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-52 {
    --tw-translate-y: 13rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-56 {
    --tw-translate-y: 14rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-6 {
    --tw-translate-y: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-60 {
    --tw-translate-y: 15rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-64 {
    --tw-translate-y: 16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-7 {
    --tw-translate-y: 1.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-72 {
    --tw-translate-y: 18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-8 {
    --tw-translate-y: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-80 {
    --tw-translate-y: 20rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-9 {
    --tw-translate-y: 2.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-96 {
    --tw-translate-y: 24rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-full {
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-px {
    --tw-translate-y: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-rotate-0 {
    --tw-rotate: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-rotate-1 {
    --tw-rotate: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-rotate-12 {
    --tw-rotate: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-rotate-180 {
    --tw-rotate: -180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-rotate-2 {
    --tw-rotate: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-rotate-3 {
    --tw-rotate: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-rotate-45 {
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-rotate-6 {
    --tw-rotate: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:rotate-1 {
    --tw-rotate: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:rotate-12 {
    --tw-rotate: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:rotate-2 {
    --tw-rotate: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:rotate-3 {
    --tw-rotate: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:rotate-6 {
    --tw-rotate: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-x-0 {
    --tw-skew-x: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-x-1 {
    --tw-skew-x: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-x-12 {
    --tw-skew-x: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-x-2 {
    --tw-skew-x: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-x-3 {
    --tw-skew-x: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-x-6 {
    --tw-skew-x: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-y-0 {
    --tw-skew-y: -0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-y-1 {
    --tw-skew-y: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-y-12 {
    --tw-skew-y: -12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-y-2 {
    --tw-skew-y: -2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-y-3 {
    --tw-skew-y: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-skew-y-6 {
    --tw-skew-y: -6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-x-0 {
    --tw-skew-x: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-x-1 {
    --tw-skew-x: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-x-12 {
    --tw-skew-x: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-x-2 {
    --tw-skew-x: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-x-3 {
    --tw-skew-x: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-x-6 {
    --tw-skew-x: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-y-0 {
    --tw-skew-y: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-y-1 {
    --tw-skew-y: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-y-12 {
    --tw-skew-y: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-y-2 {
    --tw-skew-y: 2deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-y-3 {
    --tw-skew-y: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:skew-y-6 {
    --tw-skew-y: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-100 {
    --tw-scale-x: -1;
    --tw-scale-y: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-105 {
    --tw-scale-x: -1.05;
    --tw-scale-y: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-110 {
    --tw-scale-x: -1.1;
    --tw-scale-y: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-125 {
    --tw-scale-x: -1.25;
    --tw-scale-y: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-150 {
    --tw-scale-x: -1.5;
    --tw-scale-y: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-50 {
    --tw-scale-x: -.5;
    --tw-scale-y: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-75 {
    --tw-scale-x: -.75;
    --tw-scale-y: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-90 {
    --tw-scale-x: -.9;
    --tw-scale-y: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-95 {
    --tw-scale-x: -.95;
    --tw-scale-y: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-x-0 {
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-x-100 {
    --tw-scale-x: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-x-105 {
    --tw-scale-x: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-x-110 {
    --tw-scale-x: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-x-125 {
    --tw-scale-x: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-x-150 {
    --tw-scale-x: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-x-50 {
    --tw-scale-x: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-x-75 {
    --tw-scale-x: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-x-90 {
    --tw-scale-x: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-x-95 {
    --tw-scale-x: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-y-0 {
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-y-100 {
    --tw-scale-y: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-y-105 {
    --tw-scale-y: -1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-y-110 {
    --tw-scale-y: -1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-y-125 {
    --tw-scale-y: -1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-y-150 {
    --tw-scale-y: -1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-y-50 {
    --tw-scale-y: -.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-y-75 {
    --tw-scale-y: -.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-y-90 {
    --tw-scale-y: -.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-scale-y-95 {
    --tw-scale-y: -.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-x-0 {
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-x-100 {
    --tw-scale-x: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-x-105 {
    --tw-scale-x: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-x-110 {
    --tw-scale-x: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-x-125 {
    --tw-scale-x: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-x-150 {
    --tw-scale-x: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-x-50 {
    --tw-scale-x: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-x-75 {
    --tw-scale-x: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-x-90 {
    --tw-scale-x: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-x-95 {
    --tw-scale-x: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-y-0 {
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-y-100 {
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-y-105 {
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-y-110 {
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-y-125 {
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-y-150 {
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-y-50 {
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-y-75 {
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-y-90 {
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-y-95 {
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:transform, .xl\:transform-cpu {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:transform-gpu {
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:transform-none {
    transform: none;
  }

  @keyframes bounce {
    0%, 100% {
      animation-timing-function: cubic-bezier(.8, 0, 1, 1);
      transform: translateY(-25%);
    }

    50% {
      animation-timing-function: cubic-bezier(0, 0, .2, 1);
      transform: none;
    }
  }

  .xl\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .xl\:animate-none {
    animation: none;
  }

  @keyframes ping {
    75%, 100% {
      opacity: 0;
      transform: scale(2);
    }
  }

  .xl\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  @keyframes pulse {
    50% {
      opacity: .5;
    }
  }

  .xl\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .xl\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .xl\:cursor-alias {
    cursor: alias;
  }

  .xl\:cursor-all-scroll {
    cursor: all-scroll;
  }

  .xl\:cursor-auto {
    cursor: auto;
  }

  .xl\:cursor-cell {
    cursor: cell;
  }

  .xl\:cursor-col-resize {
    cursor: col-resize;
  }

  .xl\:cursor-context-menu {
    cursor: context-menu;
  }

  .xl\:cursor-copy {
    cursor: copy;
  }

  .xl\:cursor-crosshair {
    cursor: crosshair;
  }

  .xl\:cursor-default {
    cursor: default;
  }

  .xl\:cursor-e-resize {
    cursor: e-resize;
  }

  .xl\:cursor-ew-resize {
    cursor: ew-resize;
  }

  .xl\:cursor-grab {
    cursor: grab;
  }

  .xl\:cursor-grabbing {
    cursor: grabbing;
  }

  .xl\:cursor-help {
    cursor: help;
  }

  .xl\:cursor-move {
    cursor: move;
  }

  .xl\:cursor-n-resize {
    cursor: n-resize;
  }

  .xl\:cursor-ne-resize {
    cursor: ne-resize;
  }

  .xl\:cursor-nesw-resize {
    cursor: nesw-resize;
  }

  .xl\:cursor-no-drop {
    cursor: no-drop;
  }

  .xl\:cursor-none {
    cursor: none;
  }

  .xl\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .xl\:cursor-ns-resize {
    cursor: ns-resize;
  }

  .xl\:cursor-nw-resize {
    cursor: nw-resize;
  }

  .xl\:cursor-nwse-resize {
    cursor: nwse-resize;
  }

  .xl\:cursor-pointer {
    cursor: pointer;
  }

  .xl\:cursor-progress {
    cursor: progress;
  }

  .xl\:cursor-row-resize {
    cursor: row-resize;
  }

  .xl\:cursor-s-resize {
    cursor: s-resize;
  }

  .xl\:cursor-se-resize {
    cursor: se-resize;
  }

  .xl\:cursor-sw-resize {
    cursor: sw-resize;
  }

  .xl\:cursor-text {
    cursor: text;
  }

  .xl\:cursor-vertical-text {
    cursor: vertical-text;
  }

  .xl\:cursor-w-resize {
    cursor: w-resize;
  }

  .xl\:cursor-wait {
    cursor: wait;
  }

  .xl\:cursor-zoom-in {
    cursor: zoom-in;
  }

  .xl\:cursor-zoom-out {
    cursor: zoom-out;
  }

  .xl\:touch-auto {
    touch-action: auto;
  }

  .xl\:touch-none {
    touch-action: none;
  }

  .xl\:touch-pan-x {
    --tw-pan-x: pan-x;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .xl\:touch-pan-left {
    --tw-pan-x: pan-left;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .xl\:touch-pan-right {
    --tw-pan-x: pan-right;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .xl\:touch-pan-y {
    --tw-pan-y: pan-y;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .xl\:touch-pan-up {
    --tw-pan-y: pan-up;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .xl\:touch-pan-down {
    --tw-pan-y: pan-down;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .xl\:touch-pinch-zoom {
    --tw-pinch-zoom: pinch-zoom;
    touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
  }

  .xl\:touch-manipulation {
    touch-action: manipulation;
  }

  .xl\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .xl\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .xl\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .xl\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .xl\:resize-none {
    resize: none;
  }

  .xl\:resize-y {
    resize: vertical;
  }

  .xl\:resize-x {
    resize: horizontal;
  }

  .xl\:resize {
    resize: both;
  }

  .xl\:snap-none {
    scroll-snap-type: none;
  }

  .xl\:snap-x {
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
  }

  .xl\:snap-y {
    scroll-snap-type: y var(--tw-scroll-snap-strictness);
  }

  .xl\:snap-both {
    scroll-snap-type: both var(--tw-scroll-snap-strictness);
  }

  .xl\:snap-mandatory {
    --tw-scroll-snap-strictness: mandatory;
  }

  .xl\:snap-proximity {
    --tw-scroll-snap-strictness: proximity;
  }

  .xl\:snap-start {
    scroll-snap-align: start;
  }

  .xl\:snap-end {
    scroll-snap-align: end;
  }

  .xl\:snap-center {
    scroll-snap-align: center;
  }

  .xl\:snap-align-none {
    scroll-snap-align: none;
  }

  .xl\:snap-normal {
    scroll-snap-stop: normal;
  }

  .xl\:snap-always {
    scroll-snap-stop: always;
  }

  .xl\:-scroll-m-0 {
    scroll-margin: 0;
  }

  .xl\:-scroll-m-0\.5 {
    scroll-margin: -.125rem;
  }

  .xl\:-scroll-m-1 {
    scroll-margin: -.25rem;
  }

  .xl\:-scroll-m-1\.5 {
    scroll-margin: -.375rem;
  }

  .xl\:-scroll-m-10 {
    scroll-margin: -2.5rem;
  }

  .xl\:-scroll-m-11 {
    scroll-margin: -2.75rem;
  }

  .xl\:-scroll-m-12 {
    scroll-margin: -3rem;
  }

  .xl\:-scroll-m-14 {
    scroll-margin: -3.5rem;
  }

  .xl\:-scroll-m-16 {
    scroll-margin: -4rem;
  }

  .xl\:-scroll-m-2 {
    scroll-margin: -.5rem;
  }

  .xl\:-scroll-m-2\.5 {
    scroll-margin: -.625rem;
  }

  .xl\:-scroll-m-20 {
    scroll-margin: -5rem;
  }

  .xl\:-scroll-m-24 {
    scroll-margin: -6rem;
  }

  .xl\:-scroll-m-28 {
    scroll-margin: -7rem;
  }

  .xl\:-scroll-m-3 {
    scroll-margin: -.75rem;
  }

  .xl\:-scroll-m-3\.5 {
    scroll-margin: -.875rem;
  }

  .xl\:-scroll-m-32 {
    scroll-margin: -8rem;
  }

  .xl\:-scroll-m-36 {
    scroll-margin: -9rem;
  }

  .xl\:-scroll-m-4 {
    scroll-margin: -1rem;
  }

  .xl\:-scroll-m-40 {
    scroll-margin: -10rem;
  }

  .xl\:-scroll-m-44 {
    scroll-margin: -11rem;
  }

  .xl\:-scroll-m-48 {
    scroll-margin: -12rem;
  }

  .xl\:-scroll-m-5 {
    scroll-margin: -1.25rem;
  }

  .xl\:-scroll-m-52 {
    scroll-margin: -13rem;
  }

  .xl\:-scroll-m-56 {
    scroll-margin: -14rem;
  }

  .xl\:-scroll-m-6 {
    scroll-margin: -1.5rem;
  }

  .xl\:-scroll-m-60 {
    scroll-margin: -15rem;
  }

  .xl\:-scroll-m-64 {
    scroll-margin: -16rem;
  }

  .xl\:-scroll-m-7 {
    scroll-margin: -1.75rem;
  }

  .xl\:-scroll-m-72 {
    scroll-margin: -18rem;
  }

  .xl\:-scroll-m-8 {
    scroll-margin: -2rem;
  }

  .xl\:-scroll-m-80 {
    scroll-margin: -20rem;
  }

  .xl\:-scroll-m-9 {
    scroll-margin: -2.25rem;
  }

  .xl\:-scroll-m-96 {
    scroll-margin: -24rem;
  }

  .xl\:-scroll-m-px {
    scroll-margin: -1px;
  }

  .xl\:scroll-m-0 {
    scroll-margin: 0;
  }

  .xl\:scroll-m-0\.5 {
    scroll-margin: .125rem;
  }

  .xl\:scroll-m-1 {
    scroll-margin: .25rem;
  }

  .xl\:scroll-m-1\.5 {
    scroll-margin: .375rem;
  }

  .xl\:scroll-m-10 {
    scroll-margin: 2.5rem;
  }

  .xl\:scroll-m-11 {
    scroll-margin: 2.75rem;
  }

  .xl\:scroll-m-12 {
    scroll-margin: 3rem;
  }

  .xl\:scroll-m-14 {
    scroll-margin: 3.5rem;
  }

  .xl\:scroll-m-16 {
    scroll-margin: 4rem;
  }

  .xl\:scroll-m-2 {
    scroll-margin: .5rem;
  }

  .xl\:scroll-m-2\.5 {
    scroll-margin: .625rem;
  }

  .xl\:scroll-m-20 {
    scroll-margin: 5rem;
  }

  .xl\:scroll-m-24 {
    scroll-margin: 6rem;
  }

  .xl\:scroll-m-28 {
    scroll-margin: 7rem;
  }

  .xl\:scroll-m-3 {
    scroll-margin: .75rem;
  }

  .xl\:scroll-m-3\.5 {
    scroll-margin: .875rem;
  }

  .xl\:scroll-m-32 {
    scroll-margin: 8rem;
  }

  .xl\:scroll-m-36 {
    scroll-margin: 9rem;
  }

  .xl\:scroll-m-4 {
    scroll-margin: 1rem;
  }

  .xl\:scroll-m-40 {
    scroll-margin: 10rem;
  }

  .xl\:scroll-m-44 {
    scroll-margin: 11rem;
  }

  .xl\:scroll-m-48 {
    scroll-margin: 12rem;
  }

  .xl\:scroll-m-5 {
    scroll-margin: 1.25rem;
  }

  .xl\:scroll-m-52 {
    scroll-margin: 13rem;
  }

  .xl\:scroll-m-56 {
    scroll-margin: 14rem;
  }

  .xl\:scroll-m-6 {
    scroll-margin: 1.5rem;
  }

  .xl\:scroll-m-60 {
    scroll-margin: 15rem;
  }

  .xl\:scroll-m-64 {
    scroll-margin: 16rem;
  }

  .xl\:scroll-m-7 {
    scroll-margin: 1.75rem;
  }

  .xl\:scroll-m-72 {
    scroll-margin: 18rem;
  }

  .xl\:scroll-m-8 {
    scroll-margin: 2rem;
  }

  .xl\:scroll-m-80 {
    scroll-margin: 20rem;
  }

  .xl\:scroll-m-9 {
    scroll-margin: 2.25rem;
  }

  .xl\:scroll-m-96 {
    scroll-margin: 24rem;
  }

  .xl\:scroll-m-px {
    scroll-margin: 1px;
  }

  .xl\:-scroll-mx-0 {
    scroll-margin-left: 0;
    scroll-margin-right: 0;
  }

  .xl\:-scroll-mx-0\.5 {
    scroll-margin-left: -.125rem;
    scroll-margin-right: -.125rem;
  }

  .xl\:-scroll-mx-1 {
    scroll-margin-left: -.25rem;
    scroll-margin-right: -.25rem;
  }

  .xl\:-scroll-mx-1\.5 {
    scroll-margin-left: -.375rem;
    scroll-margin-right: -.375rem;
  }

  .xl\:-scroll-mx-10 {
    scroll-margin-left: -2.5rem;
    scroll-margin-right: -2.5rem;
  }

  .xl\:-scroll-mx-11 {
    scroll-margin-left: -2.75rem;
    scroll-margin-right: -2.75rem;
  }

  .xl\:-scroll-mx-12 {
    scroll-margin-left: -3rem;
    scroll-margin-right: -3rem;
  }

  .xl\:-scroll-mx-14 {
    scroll-margin-left: -3.5rem;
    scroll-margin-right: -3.5rem;
  }

  .xl\:-scroll-mx-16 {
    scroll-margin-left: -4rem;
    scroll-margin-right: -4rem;
  }

  .xl\:-scroll-mx-2 {
    scroll-margin-left: -.5rem;
    scroll-margin-right: -.5rem;
  }

  .xl\:-scroll-mx-2\.5 {
    scroll-margin-left: -.625rem;
    scroll-margin-right: -.625rem;
  }

  .xl\:-scroll-mx-20 {
    scroll-margin-left: -5rem;
    scroll-margin-right: -5rem;
  }

  .xl\:-scroll-mx-24 {
    scroll-margin-left: -6rem;
    scroll-margin-right: -6rem;
  }

  .xl\:-scroll-mx-28 {
    scroll-margin-left: -7rem;
    scroll-margin-right: -7rem;
  }

  .xl\:-scroll-mx-3 {
    scroll-margin-left: -.75rem;
    scroll-margin-right: -.75rem;
  }

  .xl\:-scroll-mx-3\.5 {
    scroll-margin-left: -.875rem;
    scroll-margin-right: -.875rem;
  }

  .xl\:-scroll-mx-32 {
    scroll-margin-left: -8rem;
    scroll-margin-right: -8rem;
  }

  .xl\:-scroll-mx-36 {
    scroll-margin-left: -9rem;
    scroll-margin-right: -9rem;
  }

  .xl\:-scroll-mx-4 {
    scroll-margin-left: -1rem;
    scroll-margin-right: -1rem;
  }

  .xl\:-scroll-mx-40 {
    scroll-margin-left: -10rem;
    scroll-margin-right: -10rem;
  }

  .xl\:-scroll-mx-44 {
    scroll-margin-left: -11rem;
    scroll-margin-right: -11rem;
  }

  .xl\:-scroll-mx-48 {
    scroll-margin-left: -12rem;
    scroll-margin-right: -12rem;
  }

  .xl\:-scroll-mx-5 {
    scroll-margin-left: -1.25rem;
    scroll-margin-right: -1.25rem;
  }

  .xl\:-scroll-mx-52 {
    scroll-margin-left: -13rem;
    scroll-margin-right: -13rem;
  }

  .xl\:-scroll-mx-56 {
    scroll-margin-left: -14rem;
    scroll-margin-right: -14rem;
  }

  .xl\:-scroll-mx-6 {
    scroll-margin-left: -1.5rem;
    scroll-margin-right: -1.5rem;
  }

  .xl\:-scroll-mx-60 {
    scroll-margin-left: -15rem;
    scroll-margin-right: -15rem;
  }

  .xl\:-scroll-mx-64 {
    scroll-margin-left: -16rem;
    scroll-margin-right: -16rem;
  }

  .xl\:-scroll-mx-7 {
    scroll-margin-left: -1.75rem;
    scroll-margin-right: -1.75rem;
  }

  .xl\:-scroll-mx-72 {
    scroll-margin-left: -18rem;
    scroll-margin-right: -18rem;
  }

  .xl\:-scroll-mx-8 {
    scroll-margin-left: -2rem;
    scroll-margin-right: -2rem;
  }

  .xl\:-scroll-mx-80 {
    scroll-margin-left: -20rem;
    scroll-margin-right: -20rem;
  }

  .xl\:-scroll-mx-9 {
    scroll-margin-left: -2.25rem;
    scroll-margin-right: -2.25rem;
  }

  .xl\:-scroll-mx-96 {
    scroll-margin-left: -24rem;
    scroll-margin-right: -24rem;
  }

  .xl\:-scroll-mx-px {
    scroll-margin-left: -1px;
    scroll-margin-right: -1px;
  }

  .xl\:-scroll-my-0 {
    scroll-margin-top: 0;
    scroll-margin-bottom: 0;
  }

  .xl\:-scroll-my-0\.5 {
    scroll-margin-top: -.125rem;
    scroll-margin-bottom: -.125rem;
  }

  .xl\:-scroll-my-1 {
    scroll-margin-top: -.25rem;
    scroll-margin-bottom: -.25rem;
  }

  .xl\:-scroll-my-1\.5 {
    scroll-margin-top: -.375rem;
    scroll-margin-bottom: -.375rem;
  }

  .xl\:-scroll-my-10 {
    scroll-margin-top: -2.5rem;
    scroll-margin-bottom: -2.5rem;
  }

  .xl\:-scroll-my-11 {
    scroll-margin-top: -2.75rem;
    scroll-margin-bottom: -2.75rem;
  }

  .xl\:-scroll-my-12 {
    scroll-margin-top: -3rem;
    scroll-margin-bottom: -3rem;
  }

  .xl\:-scroll-my-14 {
    scroll-margin-top: -3.5rem;
    scroll-margin-bottom: -3.5rem;
  }

  .xl\:-scroll-my-16 {
    scroll-margin-top: -4rem;
    scroll-margin-bottom: -4rem;
  }

  .xl\:-scroll-my-2 {
    scroll-margin-top: -.5rem;
    scroll-margin-bottom: -.5rem;
  }

  .xl\:-scroll-my-2\.5 {
    scroll-margin-top: -.625rem;
    scroll-margin-bottom: -.625rem;
  }

  .xl\:-scroll-my-20 {
    scroll-margin-top: -5rem;
    scroll-margin-bottom: -5rem;
  }

  .xl\:-scroll-my-24 {
    scroll-margin-top: -6rem;
    scroll-margin-bottom: -6rem;
  }

  .xl\:-scroll-my-28 {
    scroll-margin-top: -7rem;
    scroll-margin-bottom: -7rem;
  }

  .xl\:-scroll-my-3 {
    scroll-margin-top: -.75rem;
    scroll-margin-bottom: -.75rem;
  }

  .xl\:-scroll-my-3\.5 {
    scroll-margin-top: -.875rem;
    scroll-margin-bottom: -.875rem;
  }

  .xl\:-scroll-my-32 {
    scroll-margin-top: -8rem;
    scroll-margin-bottom: -8rem;
  }

  .xl\:-scroll-my-36 {
    scroll-margin-top: -9rem;
    scroll-margin-bottom: -9rem;
  }

  .xl\:-scroll-my-4 {
    scroll-margin-top: -1rem;
    scroll-margin-bottom: -1rem;
  }

  .xl\:-scroll-my-40 {
    scroll-margin-top: -10rem;
    scroll-margin-bottom: -10rem;
  }

  .xl\:-scroll-my-44 {
    scroll-margin-top: -11rem;
    scroll-margin-bottom: -11rem;
  }

  .xl\:-scroll-my-48 {
    scroll-margin-top: -12rem;
    scroll-margin-bottom: -12rem;
  }

  .xl\:-scroll-my-5 {
    scroll-margin-top: -1.25rem;
    scroll-margin-bottom: -1.25rem;
  }

  .xl\:-scroll-my-52 {
    scroll-margin-top: -13rem;
    scroll-margin-bottom: -13rem;
  }

  .xl\:-scroll-my-56 {
    scroll-margin-top: -14rem;
    scroll-margin-bottom: -14rem;
  }

  .xl\:-scroll-my-6 {
    scroll-margin-top: -1.5rem;
    scroll-margin-bottom: -1.5rem;
  }

  .xl\:-scroll-my-60 {
    scroll-margin-top: -15rem;
    scroll-margin-bottom: -15rem;
  }

  .xl\:-scroll-my-64 {
    scroll-margin-top: -16rem;
    scroll-margin-bottom: -16rem;
  }

  .xl\:-scroll-my-7 {
    scroll-margin-top: -1.75rem;
    scroll-margin-bottom: -1.75rem;
  }

  .xl\:-scroll-my-72 {
    scroll-margin-top: -18rem;
    scroll-margin-bottom: -18rem;
  }

  .xl\:-scroll-my-8 {
    scroll-margin-top: -2rem;
    scroll-margin-bottom: -2rem;
  }

  .xl\:-scroll-my-80 {
    scroll-margin-top: -20rem;
    scroll-margin-bottom: -20rem;
  }

  .xl\:-scroll-my-9 {
    scroll-margin-top: -2.25rem;
    scroll-margin-bottom: -2.25rem;
  }

  .xl\:-scroll-my-96 {
    scroll-margin-top: -24rem;
    scroll-margin-bottom: -24rem;
  }

  .xl\:-scroll-my-px {
    scroll-margin-top: -1px;
    scroll-margin-bottom: -1px;
  }

  .xl\:scroll-mx-0 {
    scroll-margin-left: 0;
    scroll-margin-right: 0;
  }

  .xl\:scroll-mx-0\.5 {
    scroll-margin-left: .125rem;
    scroll-margin-right: .125rem;
  }

  .xl\:scroll-mx-1 {
    scroll-margin-left: .25rem;
    scroll-margin-right: .25rem;
  }

  .xl\:scroll-mx-1\.5 {
    scroll-margin-left: .375rem;
    scroll-margin-right: .375rem;
  }

  .xl\:scroll-mx-10 {
    scroll-margin-left: 2.5rem;
    scroll-margin-right: 2.5rem;
  }

  .xl\:scroll-mx-11 {
    scroll-margin-left: 2.75rem;
    scroll-margin-right: 2.75rem;
  }

  .xl\:scroll-mx-12 {
    scroll-margin-left: 3rem;
    scroll-margin-right: 3rem;
  }

  .xl\:scroll-mx-14 {
    scroll-margin-left: 3.5rem;
    scroll-margin-right: 3.5rem;
  }

  .xl\:scroll-mx-16 {
    scroll-margin-left: 4rem;
    scroll-margin-right: 4rem;
  }

  .xl\:scroll-mx-2 {
    scroll-margin-left: .5rem;
    scroll-margin-right: .5rem;
  }

  .xl\:scroll-mx-2\.5 {
    scroll-margin-left: .625rem;
    scroll-margin-right: .625rem;
  }

  .xl\:scroll-mx-20 {
    scroll-margin-left: 5rem;
    scroll-margin-right: 5rem;
  }

  .xl\:scroll-mx-24 {
    scroll-margin-left: 6rem;
    scroll-margin-right: 6rem;
  }

  .xl\:scroll-mx-28 {
    scroll-margin-left: 7rem;
    scroll-margin-right: 7rem;
  }

  .xl\:scroll-mx-3 {
    scroll-margin-left: .75rem;
    scroll-margin-right: .75rem;
  }

  .xl\:scroll-mx-3\.5 {
    scroll-margin-left: .875rem;
    scroll-margin-right: .875rem;
  }

  .xl\:scroll-mx-32 {
    scroll-margin-left: 8rem;
    scroll-margin-right: 8rem;
  }

  .xl\:scroll-mx-36 {
    scroll-margin-left: 9rem;
    scroll-margin-right: 9rem;
  }

  .xl\:scroll-mx-4 {
    scroll-margin-left: 1rem;
    scroll-margin-right: 1rem;
  }

  .xl\:scroll-mx-40 {
    scroll-margin-left: 10rem;
    scroll-margin-right: 10rem;
  }

  .xl\:scroll-mx-44 {
    scroll-margin-left: 11rem;
    scroll-margin-right: 11rem;
  }

  .xl\:scroll-mx-48 {
    scroll-margin-left: 12rem;
    scroll-margin-right: 12rem;
  }

  .xl\:scroll-mx-5 {
    scroll-margin-left: 1.25rem;
    scroll-margin-right: 1.25rem;
  }

  .xl\:scroll-mx-52 {
    scroll-margin-left: 13rem;
    scroll-margin-right: 13rem;
  }

  .xl\:scroll-mx-56 {
    scroll-margin-left: 14rem;
    scroll-margin-right: 14rem;
  }

  .xl\:scroll-mx-6 {
    scroll-margin-left: 1.5rem;
    scroll-margin-right: 1.5rem;
  }

  .xl\:scroll-mx-60 {
    scroll-margin-left: 15rem;
    scroll-margin-right: 15rem;
  }

  .xl\:scroll-mx-64 {
    scroll-margin-left: 16rem;
    scroll-margin-right: 16rem;
  }

  .xl\:scroll-mx-7 {
    scroll-margin-left: 1.75rem;
    scroll-margin-right: 1.75rem;
  }

  .xl\:scroll-mx-72 {
    scroll-margin-left: 18rem;
    scroll-margin-right: 18rem;
  }

  .xl\:scroll-mx-8 {
    scroll-margin-left: 2rem;
    scroll-margin-right: 2rem;
  }

  .xl\:scroll-mx-80 {
    scroll-margin-left: 20rem;
    scroll-margin-right: 20rem;
  }

  .xl\:scroll-mx-9 {
    scroll-margin-left: 2.25rem;
    scroll-margin-right: 2.25rem;
  }

  .xl\:scroll-mx-96 {
    scroll-margin-left: 24rem;
    scroll-margin-right: 24rem;
  }

  .xl\:scroll-mx-px {
    scroll-margin-left: 1px;
    scroll-margin-right: 1px;
  }

  .xl\:scroll-my-0 {
    scroll-margin-top: 0;
    scroll-margin-bottom: 0;
  }

  .xl\:scroll-my-0\.5 {
    scroll-margin-top: .125rem;
    scroll-margin-bottom: .125rem;
  }

  .xl\:scroll-my-1 {
    scroll-margin-top: .25rem;
    scroll-margin-bottom: .25rem;
  }

  .xl\:scroll-my-1\.5 {
    scroll-margin-top: .375rem;
    scroll-margin-bottom: .375rem;
  }

  .xl\:scroll-my-10 {
    scroll-margin-top: 2.5rem;
    scroll-margin-bottom: 2.5rem;
  }

  .xl\:scroll-my-11 {
    scroll-margin-top: 2.75rem;
    scroll-margin-bottom: 2.75rem;
  }

  .xl\:scroll-my-12 {
    scroll-margin-top: 3rem;
    scroll-margin-bottom: 3rem;
  }

  .xl\:scroll-my-14 {
    scroll-margin-top: 3.5rem;
    scroll-margin-bottom: 3.5rem;
  }

  .xl\:scroll-my-16 {
    scroll-margin-top: 4rem;
    scroll-margin-bottom: 4rem;
  }

  .xl\:scroll-my-2 {
    scroll-margin-top: .5rem;
    scroll-margin-bottom: .5rem;
  }

  .xl\:scroll-my-2\.5 {
    scroll-margin-top: .625rem;
    scroll-margin-bottom: .625rem;
  }

  .xl\:scroll-my-20 {
    scroll-margin-top: 5rem;
    scroll-margin-bottom: 5rem;
  }

  .xl\:scroll-my-24 {
    scroll-margin-top: 6rem;
    scroll-margin-bottom: 6rem;
  }

  .xl\:scroll-my-28 {
    scroll-margin-top: 7rem;
    scroll-margin-bottom: 7rem;
  }

  .xl\:scroll-my-3 {
    scroll-margin-top: .75rem;
    scroll-margin-bottom: .75rem;
  }

  .xl\:scroll-my-3\.5 {
    scroll-margin-top: .875rem;
    scroll-margin-bottom: .875rem;
  }

  .xl\:scroll-my-32 {
    scroll-margin-top: 8rem;
    scroll-margin-bottom: 8rem;
  }

  .xl\:scroll-my-36 {
    scroll-margin-top: 9rem;
    scroll-margin-bottom: 9rem;
  }

  .xl\:scroll-my-4 {
    scroll-margin-top: 1rem;
    scroll-margin-bottom: 1rem;
  }

  .xl\:scroll-my-40 {
    scroll-margin-top: 10rem;
    scroll-margin-bottom: 10rem;
  }

  .xl\:scroll-my-44 {
    scroll-margin-top: 11rem;
    scroll-margin-bottom: 11rem;
  }

  .xl\:scroll-my-48 {
    scroll-margin-top: 12rem;
    scroll-margin-bottom: 12rem;
  }

  .xl\:scroll-my-5 {
    scroll-margin-top: 1.25rem;
    scroll-margin-bottom: 1.25rem;
  }

  .xl\:scroll-my-52 {
    scroll-margin-top: 13rem;
    scroll-margin-bottom: 13rem;
  }

  .xl\:scroll-my-56 {
    scroll-margin-top: 14rem;
    scroll-margin-bottom: 14rem;
  }

  .xl\:scroll-my-6 {
    scroll-margin-top: 1.5rem;
    scroll-margin-bottom: 1.5rem;
  }

  .xl\:scroll-my-60 {
    scroll-margin-top: 15rem;
    scroll-margin-bottom: 15rem;
  }

  .xl\:scroll-my-64 {
    scroll-margin-top: 16rem;
    scroll-margin-bottom: 16rem;
  }

  .xl\:scroll-my-7 {
    scroll-margin-top: 1.75rem;
    scroll-margin-bottom: 1.75rem;
  }

  .xl\:scroll-my-72 {
    scroll-margin-top: 18rem;
    scroll-margin-bottom: 18rem;
  }

  .xl\:scroll-my-8 {
    scroll-margin-top: 2rem;
    scroll-margin-bottom: 2rem;
  }

  .xl\:scroll-my-80 {
    scroll-margin-top: 20rem;
    scroll-margin-bottom: 20rem;
  }

  .xl\:scroll-my-9 {
    scroll-margin-top: 2.25rem;
    scroll-margin-bottom: 2.25rem;
  }

  .xl\:scroll-my-96 {
    scroll-margin-top: 24rem;
    scroll-margin-bottom: 24rem;
  }

  .xl\:scroll-my-px {
    scroll-margin-top: 1px;
    scroll-margin-bottom: 1px;
  }

  .xl\:-scroll-mb-0 {
    scroll-margin-bottom: 0;
  }

  .xl\:-scroll-mb-0\.5 {
    scroll-margin-bottom: -.125rem;
  }

  .xl\:-scroll-mb-1 {
    scroll-margin-bottom: -.25rem;
  }

  .xl\:-scroll-mb-1\.5 {
    scroll-margin-bottom: -.375rem;
  }

  .xl\:-scroll-mb-10 {
    scroll-margin-bottom: -2.5rem;
  }

  .xl\:-scroll-mb-11 {
    scroll-margin-bottom: -2.75rem;
  }

  .xl\:-scroll-mb-12 {
    scroll-margin-bottom: -3rem;
  }

  .xl\:-scroll-mb-14 {
    scroll-margin-bottom: -3.5rem;
  }

  .xl\:-scroll-mb-16 {
    scroll-margin-bottom: -4rem;
  }

  .xl\:-scroll-mb-2 {
    scroll-margin-bottom: -.5rem;
  }

  .xl\:-scroll-mb-2\.5 {
    scroll-margin-bottom: -.625rem;
  }

  .xl\:-scroll-mb-20 {
    scroll-margin-bottom: -5rem;
  }

  .xl\:-scroll-mb-24 {
    scroll-margin-bottom: -6rem;
  }

  .xl\:-scroll-mb-28 {
    scroll-margin-bottom: -7rem;
  }

  .xl\:-scroll-mb-3 {
    scroll-margin-bottom: -.75rem;
  }

  .xl\:-scroll-mb-3\.5 {
    scroll-margin-bottom: -.875rem;
  }

  .xl\:-scroll-mb-32 {
    scroll-margin-bottom: -8rem;
  }

  .xl\:-scroll-mb-36 {
    scroll-margin-bottom: -9rem;
  }

  .xl\:-scroll-mb-4 {
    scroll-margin-bottom: -1rem;
  }

  .xl\:-scroll-mb-40 {
    scroll-margin-bottom: -10rem;
  }

  .xl\:-scroll-mb-44 {
    scroll-margin-bottom: -11rem;
  }

  .xl\:-scroll-mb-48 {
    scroll-margin-bottom: -12rem;
  }

  .xl\:-scroll-mb-5 {
    scroll-margin-bottom: -1.25rem;
  }

  .xl\:-scroll-mb-52 {
    scroll-margin-bottom: -13rem;
  }

  .xl\:-scroll-mb-56 {
    scroll-margin-bottom: -14rem;
  }

  .xl\:-scroll-mb-6 {
    scroll-margin-bottom: -1.5rem;
  }

  .xl\:-scroll-mb-60 {
    scroll-margin-bottom: -15rem;
  }

  .xl\:-scroll-mb-64 {
    scroll-margin-bottom: -16rem;
  }

  .xl\:-scroll-mb-7 {
    scroll-margin-bottom: -1.75rem;
  }

  .xl\:-scroll-mb-72 {
    scroll-margin-bottom: -18rem;
  }

  .xl\:-scroll-mb-8 {
    scroll-margin-bottom: -2rem;
  }

  .xl\:-scroll-mb-80 {
    scroll-margin-bottom: -20rem;
  }

  .xl\:-scroll-mb-9 {
    scroll-margin-bottom: -2.25rem;
  }

  .xl\:-scroll-mb-96 {
    scroll-margin-bottom: -24rem;
  }

  .xl\:-scroll-mb-px {
    scroll-margin-bottom: -1px;
  }

  .xl\:-scroll-me-0 {
    scroll-margin-inline-end: 0;
  }

  .xl\:-scroll-me-0\.5 {
    scroll-margin-inline-end: -.125rem;
  }

  .xl\:-scroll-me-1 {
    scroll-margin-inline-end: -.25rem;
  }

  .xl\:-scroll-me-1\.5 {
    scroll-margin-inline-end: -.375rem;
  }

  .xl\:-scroll-me-10 {
    scroll-margin-inline-end: -2.5rem;
  }

  .xl\:-scroll-me-11 {
    scroll-margin-inline-end: -2.75rem;
  }

  .xl\:-scroll-me-12 {
    scroll-margin-inline-end: -3rem;
  }

  .xl\:-scroll-me-14 {
    scroll-margin-inline-end: -3.5rem;
  }

  .xl\:-scroll-me-16 {
    scroll-margin-inline-end: -4rem;
  }

  .xl\:-scroll-me-2 {
    scroll-margin-inline-end: -.5rem;
  }

  .xl\:-scroll-me-2\.5 {
    scroll-margin-inline-end: -.625rem;
  }

  .xl\:-scroll-me-20 {
    scroll-margin-inline-end: -5rem;
  }

  .xl\:-scroll-me-24 {
    scroll-margin-inline-end: -6rem;
  }

  .xl\:-scroll-me-28 {
    scroll-margin-inline-end: -7rem;
  }

  .xl\:-scroll-me-3 {
    scroll-margin-inline-end: -.75rem;
  }

  .xl\:-scroll-me-3\.5 {
    scroll-margin-inline-end: -.875rem;
  }

  .xl\:-scroll-me-32 {
    scroll-margin-inline-end: -8rem;
  }

  .xl\:-scroll-me-36 {
    scroll-margin-inline-end: -9rem;
  }

  .xl\:-scroll-me-4 {
    scroll-margin-inline-end: -1rem;
  }

  .xl\:-scroll-me-40 {
    scroll-margin-inline-end: -10rem;
  }

  .xl\:-scroll-me-44 {
    scroll-margin-inline-end: -11rem;
  }

  .xl\:-scroll-me-48 {
    scroll-margin-inline-end: -12rem;
  }

  .xl\:-scroll-me-5 {
    scroll-margin-inline-end: -1.25rem;
  }

  .xl\:-scroll-me-52 {
    scroll-margin-inline-end: -13rem;
  }

  .xl\:-scroll-me-56 {
    scroll-margin-inline-end: -14rem;
  }

  .xl\:-scroll-me-6 {
    scroll-margin-inline-end: -1.5rem;
  }

  .xl\:-scroll-me-60 {
    scroll-margin-inline-end: -15rem;
  }

  .xl\:-scroll-me-64 {
    scroll-margin-inline-end: -16rem;
  }

  .xl\:-scroll-me-7 {
    scroll-margin-inline-end: -1.75rem;
  }

  .xl\:-scroll-me-72 {
    scroll-margin-inline-end: -18rem;
  }

  .xl\:-scroll-me-8 {
    scroll-margin-inline-end: -2rem;
  }

  .xl\:-scroll-me-80 {
    scroll-margin-inline-end: -20rem;
  }

  .xl\:-scroll-me-9 {
    scroll-margin-inline-end: -2.25rem;
  }

  .xl\:-scroll-me-96 {
    scroll-margin-inline-end: -24rem;
  }

  .xl\:-scroll-me-px {
    scroll-margin-inline-end: -1px;
  }

  .xl\:-scroll-ml-0 {
    scroll-margin-left: 0;
  }

  .xl\:-scroll-ml-0\.5 {
    scroll-margin-left: -.125rem;
  }

  .xl\:-scroll-ml-1 {
    scroll-margin-left: -.25rem;
  }

  .xl\:-scroll-ml-1\.5 {
    scroll-margin-left: -.375rem;
  }

  .xl\:-scroll-ml-10 {
    scroll-margin-left: -2.5rem;
  }

  .xl\:-scroll-ml-11 {
    scroll-margin-left: -2.75rem;
  }

  .xl\:-scroll-ml-12 {
    scroll-margin-left: -3rem;
  }

  .xl\:-scroll-ml-14 {
    scroll-margin-left: -3.5rem;
  }

  .xl\:-scroll-ml-16 {
    scroll-margin-left: -4rem;
  }

  .xl\:-scroll-ml-2 {
    scroll-margin-left: -.5rem;
  }

  .xl\:-scroll-ml-2\.5 {
    scroll-margin-left: -.625rem;
  }

  .xl\:-scroll-ml-20 {
    scroll-margin-left: -5rem;
  }

  .xl\:-scroll-ml-24 {
    scroll-margin-left: -6rem;
  }

  .xl\:-scroll-ml-28 {
    scroll-margin-left: -7rem;
  }

  .xl\:-scroll-ml-3 {
    scroll-margin-left: -.75rem;
  }

  .xl\:-scroll-ml-3\.5 {
    scroll-margin-left: -.875rem;
  }

  .xl\:-scroll-ml-32 {
    scroll-margin-left: -8rem;
  }

  .xl\:-scroll-ml-36 {
    scroll-margin-left: -9rem;
  }

  .xl\:-scroll-ml-4 {
    scroll-margin-left: -1rem;
  }

  .xl\:-scroll-ml-40 {
    scroll-margin-left: -10rem;
  }

  .xl\:-scroll-ml-44 {
    scroll-margin-left: -11rem;
  }

  .xl\:-scroll-ml-48 {
    scroll-margin-left: -12rem;
  }

  .xl\:-scroll-ml-5 {
    scroll-margin-left: -1.25rem;
  }

  .xl\:-scroll-ml-52 {
    scroll-margin-left: -13rem;
  }

  .xl\:-scroll-ml-56 {
    scroll-margin-left: -14rem;
  }

  .xl\:-scroll-ml-6 {
    scroll-margin-left: -1.5rem;
  }

  .xl\:-scroll-ml-60 {
    scroll-margin-left: -15rem;
  }

  .xl\:-scroll-ml-64 {
    scroll-margin-left: -16rem;
  }

  .xl\:-scroll-ml-7 {
    scroll-margin-left: -1.75rem;
  }

  .xl\:-scroll-ml-72 {
    scroll-margin-left: -18rem;
  }

  .xl\:-scroll-ml-8 {
    scroll-margin-left: -2rem;
  }

  .xl\:-scroll-ml-80 {
    scroll-margin-left: -20rem;
  }

  .xl\:-scroll-ml-9 {
    scroll-margin-left: -2.25rem;
  }

  .xl\:-scroll-ml-96 {
    scroll-margin-left: -24rem;
  }

  .xl\:-scroll-ml-px {
    scroll-margin-left: -1px;
  }

  .xl\:-scroll-mr-0 {
    scroll-margin-right: 0;
  }

  .xl\:-scroll-mr-0\.5 {
    scroll-margin-right: -.125rem;
  }

  .xl\:-scroll-mr-1 {
    scroll-margin-right: -.25rem;
  }

  .xl\:-scroll-mr-1\.5 {
    scroll-margin-right: -.375rem;
  }

  .xl\:-scroll-mr-10 {
    scroll-margin-right: -2.5rem;
  }

  .xl\:-scroll-mr-11 {
    scroll-margin-right: -2.75rem;
  }

  .xl\:-scroll-mr-12 {
    scroll-margin-right: -3rem;
  }

  .xl\:-scroll-mr-14 {
    scroll-margin-right: -3.5rem;
  }

  .xl\:-scroll-mr-16 {
    scroll-margin-right: -4rem;
  }

  .xl\:-scroll-mr-2 {
    scroll-margin-right: -.5rem;
  }

  .xl\:-scroll-mr-2\.5 {
    scroll-margin-right: -.625rem;
  }

  .xl\:-scroll-mr-20 {
    scroll-margin-right: -5rem;
  }

  .xl\:-scroll-mr-24 {
    scroll-margin-right: -6rem;
  }

  .xl\:-scroll-mr-28 {
    scroll-margin-right: -7rem;
  }

  .xl\:-scroll-mr-3 {
    scroll-margin-right: -.75rem;
  }

  .xl\:-scroll-mr-3\.5 {
    scroll-margin-right: -.875rem;
  }

  .xl\:-scroll-mr-32 {
    scroll-margin-right: -8rem;
  }

  .xl\:-scroll-mr-36 {
    scroll-margin-right: -9rem;
  }

  .xl\:-scroll-mr-4 {
    scroll-margin-right: -1rem;
  }

  .xl\:-scroll-mr-40 {
    scroll-margin-right: -10rem;
  }

  .xl\:-scroll-mr-44 {
    scroll-margin-right: -11rem;
  }

  .xl\:-scroll-mr-48 {
    scroll-margin-right: -12rem;
  }

  .xl\:-scroll-mr-5 {
    scroll-margin-right: -1.25rem;
  }

  .xl\:-scroll-mr-52 {
    scroll-margin-right: -13rem;
  }

  .xl\:-scroll-mr-56 {
    scroll-margin-right: -14rem;
  }

  .xl\:-scroll-mr-6 {
    scroll-margin-right: -1.5rem;
  }

  .xl\:-scroll-mr-60 {
    scroll-margin-right: -15rem;
  }

  .xl\:-scroll-mr-64 {
    scroll-margin-right: -16rem;
  }

  .xl\:-scroll-mr-7 {
    scroll-margin-right: -1.75rem;
  }

  .xl\:-scroll-mr-72 {
    scroll-margin-right: -18rem;
  }

  .xl\:-scroll-mr-8 {
    scroll-margin-right: -2rem;
  }

  .xl\:-scroll-mr-80 {
    scroll-margin-right: -20rem;
  }

  .xl\:-scroll-mr-9 {
    scroll-margin-right: -2.25rem;
  }

  .xl\:-scroll-mr-96 {
    scroll-margin-right: -24rem;
  }

  .xl\:-scroll-mr-px {
    scroll-margin-right: -1px;
  }

  .xl\:-scroll-ms-0 {
    scroll-margin-inline-start: 0;
  }

  .xl\:-scroll-ms-0\.5 {
    scroll-margin-inline-start: -.125rem;
  }

  .xl\:-scroll-ms-1 {
    scroll-margin-inline-start: -.25rem;
  }

  .xl\:-scroll-ms-1\.5 {
    scroll-margin-inline-start: -.375rem;
  }

  .xl\:-scroll-ms-10 {
    scroll-margin-inline-start: -2.5rem;
  }

  .xl\:-scroll-ms-11 {
    scroll-margin-inline-start: -2.75rem;
  }

  .xl\:-scroll-ms-12 {
    scroll-margin-inline-start: -3rem;
  }

  .xl\:-scroll-ms-14 {
    scroll-margin-inline-start: -3.5rem;
  }

  .xl\:-scroll-ms-16 {
    scroll-margin-inline-start: -4rem;
  }

  .xl\:-scroll-ms-2 {
    scroll-margin-inline-start: -.5rem;
  }

  .xl\:-scroll-ms-2\.5 {
    scroll-margin-inline-start: -.625rem;
  }

  .xl\:-scroll-ms-20 {
    scroll-margin-inline-start: -5rem;
  }

  .xl\:-scroll-ms-24 {
    scroll-margin-inline-start: -6rem;
  }

  .xl\:-scroll-ms-28 {
    scroll-margin-inline-start: -7rem;
  }

  .xl\:-scroll-ms-3 {
    scroll-margin-inline-start: -.75rem;
  }

  .xl\:-scroll-ms-3\.5 {
    scroll-margin-inline-start: -.875rem;
  }

  .xl\:-scroll-ms-32 {
    scroll-margin-inline-start: -8rem;
  }

  .xl\:-scroll-ms-36 {
    scroll-margin-inline-start: -9rem;
  }

  .xl\:-scroll-ms-4 {
    scroll-margin-inline-start: -1rem;
  }

  .xl\:-scroll-ms-40 {
    scroll-margin-inline-start: -10rem;
  }

  .xl\:-scroll-ms-44 {
    scroll-margin-inline-start: -11rem;
  }

  .xl\:-scroll-ms-48 {
    scroll-margin-inline-start: -12rem;
  }

  .xl\:-scroll-ms-5 {
    scroll-margin-inline-start: -1.25rem;
  }

  .xl\:-scroll-ms-52 {
    scroll-margin-inline-start: -13rem;
  }

  .xl\:-scroll-ms-56 {
    scroll-margin-inline-start: -14rem;
  }

  .xl\:-scroll-ms-6 {
    scroll-margin-inline-start: -1.5rem;
  }

  .xl\:-scroll-ms-60 {
    scroll-margin-inline-start: -15rem;
  }

  .xl\:-scroll-ms-64 {
    scroll-margin-inline-start: -16rem;
  }

  .xl\:-scroll-ms-7 {
    scroll-margin-inline-start: -1.75rem;
  }

  .xl\:-scroll-ms-72 {
    scroll-margin-inline-start: -18rem;
  }

  .xl\:-scroll-ms-8 {
    scroll-margin-inline-start: -2rem;
  }

  .xl\:-scroll-ms-80 {
    scroll-margin-inline-start: -20rem;
  }

  .xl\:-scroll-ms-9 {
    scroll-margin-inline-start: -2.25rem;
  }

  .xl\:-scroll-ms-96 {
    scroll-margin-inline-start: -24rem;
  }

  .xl\:-scroll-ms-px {
    scroll-margin-inline-start: -1px;
  }

  .xl\:-scroll-mt-0 {
    scroll-margin-top: 0;
  }

  .xl\:-scroll-mt-0\.5 {
    scroll-margin-top: -.125rem;
  }

  .xl\:-scroll-mt-1 {
    scroll-margin-top: -.25rem;
  }

  .xl\:-scroll-mt-1\.5 {
    scroll-margin-top: -.375rem;
  }

  .xl\:-scroll-mt-10 {
    scroll-margin-top: -2.5rem;
  }

  .xl\:-scroll-mt-11 {
    scroll-margin-top: -2.75rem;
  }

  .xl\:-scroll-mt-12 {
    scroll-margin-top: -3rem;
  }

  .xl\:-scroll-mt-14 {
    scroll-margin-top: -3.5rem;
  }

  .xl\:-scroll-mt-16 {
    scroll-margin-top: -4rem;
  }

  .xl\:-scroll-mt-2 {
    scroll-margin-top: -.5rem;
  }

  .xl\:-scroll-mt-2\.5 {
    scroll-margin-top: -.625rem;
  }

  .xl\:-scroll-mt-20 {
    scroll-margin-top: -5rem;
  }

  .xl\:-scroll-mt-24 {
    scroll-margin-top: -6rem;
  }

  .xl\:-scroll-mt-28 {
    scroll-margin-top: -7rem;
  }

  .xl\:-scroll-mt-3 {
    scroll-margin-top: -.75rem;
  }

  .xl\:-scroll-mt-3\.5 {
    scroll-margin-top: -.875rem;
  }

  .xl\:-scroll-mt-32 {
    scroll-margin-top: -8rem;
  }

  .xl\:-scroll-mt-36 {
    scroll-margin-top: -9rem;
  }

  .xl\:-scroll-mt-4 {
    scroll-margin-top: -1rem;
  }

  .xl\:-scroll-mt-40 {
    scroll-margin-top: -10rem;
  }

  .xl\:-scroll-mt-44 {
    scroll-margin-top: -11rem;
  }

  .xl\:-scroll-mt-48 {
    scroll-margin-top: -12rem;
  }

  .xl\:-scroll-mt-5 {
    scroll-margin-top: -1.25rem;
  }

  .xl\:-scroll-mt-52 {
    scroll-margin-top: -13rem;
  }

  .xl\:-scroll-mt-56 {
    scroll-margin-top: -14rem;
  }

  .xl\:-scroll-mt-6 {
    scroll-margin-top: -1.5rem;
  }

  .xl\:-scroll-mt-60 {
    scroll-margin-top: -15rem;
  }

  .xl\:-scroll-mt-64 {
    scroll-margin-top: -16rem;
  }

  .xl\:-scroll-mt-7 {
    scroll-margin-top: -1.75rem;
  }

  .xl\:-scroll-mt-72 {
    scroll-margin-top: -18rem;
  }

  .xl\:-scroll-mt-8 {
    scroll-margin-top: -2rem;
  }

  .xl\:-scroll-mt-80 {
    scroll-margin-top: -20rem;
  }

  .xl\:-scroll-mt-9 {
    scroll-margin-top: -2.25rem;
  }

  .xl\:-scroll-mt-96 {
    scroll-margin-top: -24rem;
  }

  .xl\:-scroll-mt-px {
    scroll-margin-top: -1px;
  }

  .xl\:scroll-mb-0 {
    scroll-margin-bottom: 0;
  }

  .xl\:scroll-mb-0\.5 {
    scroll-margin-bottom: .125rem;
  }

  .xl\:scroll-mb-1 {
    scroll-margin-bottom: .25rem;
  }

  .xl\:scroll-mb-1\.5 {
    scroll-margin-bottom: .375rem;
  }

  .xl\:scroll-mb-10 {
    scroll-margin-bottom: 2.5rem;
  }

  .xl\:scroll-mb-11 {
    scroll-margin-bottom: 2.75rem;
  }

  .xl\:scroll-mb-12 {
    scroll-margin-bottom: 3rem;
  }

  .xl\:scroll-mb-14 {
    scroll-margin-bottom: 3.5rem;
  }

  .xl\:scroll-mb-16 {
    scroll-margin-bottom: 4rem;
  }

  .xl\:scroll-mb-2 {
    scroll-margin-bottom: .5rem;
  }

  .xl\:scroll-mb-2\.5 {
    scroll-margin-bottom: .625rem;
  }

  .xl\:scroll-mb-20 {
    scroll-margin-bottom: 5rem;
  }

  .xl\:scroll-mb-24 {
    scroll-margin-bottom: 6rem;
  }

  .xl\:scroll-mb-28 {
    scroll-margin-bottom: 7rem;
  }

  .xl\:scroll-mb-3 {
    scroll-margin-bottom: .75rem;
  }

  .xl\:scroll-mb-3\.5 {
    scroll-margin-bottom: .875rem;
  }

  .xl\:scroll-mb-32 {
    scroll-margin-bottom: 8rem;
  }

  .xl\:scroll-mb-36 {
    scroll-margin-bottom: 9rem;
  }

  .xl\:scroll-mb-4 {
    scroll-margin-bottom: 1rem;
  }

  .xl\:scroll-mb-40 {
    scroll-margin-bottom: 10rem;
  }

  .xl\:scroll-mb-44 {
    scroll-margin-bottom: 11rem;
  }

  .xl\:scroll-mb-48 {
    scroll-margin-bottom: 12rem;
  }

  .xl\:scroll-mb-5 {
    scroll-margin-bottom: 1.25rem;
  }

  .xl\:scroll-mb-52 {
    scroll-margin-bottom: 13rem;
  }

  .xl\:scroll-mb-56 {
    scroll-margin-bottom: 14rem;
  }

  .xl\:scroll-mb-6 {
    scroll-margin-bottom: 1.5rem;
  }

  .xl\:scroll-mb-60 {
    scroll-margin-bottom: 15rem;
  }

  .xl\:scroll-mb-64 {
    scroll-margin-bottom: 16rem;
  }

  .xl\:scroll-mb-7 {
    scroll-margin-bottom: 1.75rem;
  }

  .xl\:scroll-mb-72 {
    scroll-margin-bottom: 18rem;
  }

  .xl\:scroll-mb-8 {
    scroll-margin-bottom: 2rem;
  }

  .xl\:scroll-mb-80 {
    scroll-margin-bottom: 20rem;
  }

  .xl\:scroll-mb-9 {
    scroll-margin-bottom: 2.25rem;
  }

  .xl\:scroll-mb-96 {
    scroll-margin-bottom: 24rem;
  }

  .xl\:scroll-mb-px {
    scroll-margin-bottom: 1px;
  }

  .xl\:scroll-me-0 {
    scroll-margin-inline-end: 0;
  }

  .xl\:scroll-me-0\.5 {
    scroll-margin-inline-end: .125rem;
  }

  .xl\:scroll-me-1 {
    scroll-margin-inline-end: .25rem;
  }

  .xl\:scroll-me-1\.5 {
    scroll-margin-inline-end: .375rem;
  }

  .xl\:scroll-me-10 {
    scroll-margin-inline-end: 2.5rem;
  }

  .xl\:scroll-me-11 {
    scroll-margin-inline-end: 2.75rem;
  }

  .xl\:scroll-me-12 {
    scroll-margin-inline-end: 3rem;
  }

  .xl\:scroll-me-14 {
    scroll-margin-inline-end: 3.5rem;
  }

  .xl\:scroll-me-16 {
    scroll-margin-inline-end: 4rem;
  }

  .xl\:scroll-me-2 {
    scroll-margin-inline-end: .5rem;
  }

  .xl\:scroll-me-2\.5 {
    scroll-margin-inline-end: .625rem;
  }

  .xl\:scroll-me-20 {
    scroll-margin-inline-end: 5rem;
  }

  .xl\:scroll-me-24 {
    scroll-margin-inline-end: 6rem;
  }

  .xl\:scroll-me-28 {
    scroll-margin-inline-end: 7rem;
  }

  .xl\:scroll-me-3 {
    scroll-margin-inline-end: .75rem;
  }

  .xl\:scroll-me-3\.5 {
    scroll-margin-inline-end: .875rem;
  }

  .xl\:scroll-me-32 {
    scroll-margin-inline-end: 8rem;
  }

  .xl\:scroll-me-36 {
    scroll-margin-inline-end: 9rem;
  }

  .xl\:scroll-me-4 {
    scroll-margin-inline-end: 1rem;
  }

  .xl\:scroll-me-40 {
    scroll-margin-inline-end: 10rem;
  }

  .xl\:scroll-me-44 {
    scroll-margin-inline-end: 11rem;
  }

  .xl\:scroll-me-48 {
    scroll-margin-inline-end: 12rem;
  }

  .xl\:scroll-me-5 {
    scroll-margin-inline-end: 1.25rem;
  }

  .xl\:scroll-me-52 {
    scroll-margin-inline-end: 13rem;
  }

  .xl\:scroll-me-56 {
    scroll-margin-inline-end: 14rem;
  }

  .xl\:scroll-me-6 {
    scroll-margin-inline-end: 1.5rem;
  }

  .xl\:scroll-me-60 {
    scroll-margin-inline-end: 15rem;
  }

  .xl\:scroll-me-64 {
    scroll-margin-inline-end: 16rem;
  }

  .xl\:scroll-me-7 {
    scroll-margin-inline-end: 1.75rem;
  }

  .xl\:scroll-me-72 {
    scroll-margin-inline-end: 18rem;
  }

  .xl\:scroll-me-8 {
    scroll-margin-inline-end: 2rem;
  }

  .xl\:scroll-me-80 {
    scroll-margin-inline-end: 20rem;
  }

  .xl\:scroll-me-9 {
    scroll-margin-inline-end: 2.25rem;
  }

  .xl\:scroll-me-96 {
    scroll-margin-inline-end: 24rem;
  }

  .xl\:scroll-me-px {
    scroll-margin-inline-end: 1px;
  }

  .xl\:scroll-ml-0 {
    scroll-margin-left: 0;
  }

  .xl\:scroll-ml-0\.5 {
    scroll-margin-left: .125rem;
  }

  .xl\:scroll-ml-1 {
    scroll-margin-left: .25rem;
  }

  .xl\:scroll-ml-1\.5 {
    scroll-margin-left: .375rem;
  }

  .xl\:scroll-ml-10 {
    scroll-margin-left: 2.5rem;
  }

  .xl\:scroll-ml-11 {
    scroll-margin-left: 2.75rem;
  }

  .xl\:scroll-ml-12 {
    scroll-margin-left: 3rem;
  }

  .xl\:scroll-ml-14 {
    scroll-margin-left: 3.5rem;
  }

  .xl\:scroll-ml-16 {
    scroll-margin-left: 4rem;
  }

  .xl\:scroll-ml-2 {
    scroll-margin-left: .5rem;
  }

  .xl\:scroll-ml-2\.5 {
    scroll-margin-left: .625rem;
  }

  .xl\:scroll-ml-20 {
    scroll-margin-left: 5rem;
  }

  .xl\:scroll-ml-24 {
    scroll-margin-left: 6rem;
  }

  .xl\:scroll-ml-28 {
    scroll-margin-left: 7rem;
  }

  .xl\:scroll-ml-3 {
    scroll-margin-left: .75rem;
  }

  .xl\:scroll-ml-3\.5 {
    scroll-margin-left: .875rem;
  }

  .xl\:scroll-ml-32 {
    scroll-margin-left: 8rem;
  }

  .xl\:scroll-ml-36 {
    scroll-margin-left: 9rem;
  }

  .xl\:scroll-ml-4 {
    scroll-margin-left: 1rem;
  }

  .xl\:scroll-ml-40 {
    scroll-margin-left: 10rem;
  }

  .xl\:scroll-ml-44 {
    scroll-margin-left: 11rem;
  }

  .xl\:scroll-ml-48 {
    scroll-margin-left: 12rem;
  }

  .xl\:scroll-ml-5 {
    scroll-margin-left: 1.25rem;
  }

  .xl\:scroll-ml-52 {
    scroll-margin-left: 13rem;
  }

  .xl\:scroll-ml-56 {
    scroll-margin-left: 14rem;
  }

  .xl\:scroll-ml-6 {
    scroll-margin-left: 1.5rem;
  }

  .xl\:scroll-ml-60 {
    scroll-margin-left: 15rem;
  }

  .xl\:scroll-ml-64 {
    scroll-margin-left: 16rem;
  }

  .xl\:scroll-ml-7 {
    scroll-margin-left: 1.75rem;
  }

  .xl\:scroll-ml-72 {
    scroll-margin-left: 18rem;
  }

  .xl\:scroll-ml-8 {
    scroll-margin-left: 2rem;
  }

  .xl\:scroll-ml-80 {
    scroll-margin-left: 20rem;
  }

  .xl\:scroll-ml-9 {
    scroll-margin-left: 2.25rem;
  }

  .xl\:scroll-ml-96 {
    scroll-margin-left: 24rem;
  }

  .xl\:scroll-ml-px {
    scroll-margin-left: 1px;
  }

  .xl\:scroll-mr-0 {
    scroll-margin-right: 0;
  }

  .xl\:scroll-mr-0\.5 {
    scroll-margin-right: .125rem;
  }

  .xl\:scroll-mr-1 {
    scroll-margin-right: .25rem;
  }

  .xl\:scroll-mr-1\.5 {
    scroll-margin-right: .375rem;
  }

  .xl\:scroll-mr-10 {
    scroll-margin-right: 2.5rem;
  }

  .xl\:scroll-mr-11 {
    scroll-margin-right: 2.75rem;
  }

  .xl\:scroll-mr-12 {
    scroll-margin-right: 3rem;
  }

  .xl\:scroll-mr-14 {
    scroll-margin-right: 3.5rem;
  }

  .xl\:scroll-mr-16 {
    scroll-margin-right: 4rem;
  }

  .xl\:scroll-mr-2 {
    scroll-margin-right: .5rem;
  }

  .xl\:scroll-mr-2\.5 {
    scroll-margin-right: .625rem;
  }

  .xl\:scroll-mr-20 {
    scroll-margin-right: 5rem;
  }

  .xl\:scroll-mr-24 {
    scroll-margin-right: 6rem;
  }

  .xl\:scroll-mr-28 {
    scroll-margin-right: 7rem;
  }

  .xl\:scroll-mr-3 {
    scroll-margin-right: .75rem;
  }

  .xl\:scroll-mr-3\.5 {
    scroll-margin-right: .875rem;
  }

  .xl\:scroll-mr-32 {
    scroll-margin-right: 8rem;
  }

  .xl\:scroll-mr-36 {
    scroll-margin-right: 9rem;
  }

  .xl\:scroll-mr-4 {
    scroll-margin-right: 1rem;
  }

  .xl\:scroll-mr-40 {
    scroll-margin-right: 10rem;
  }

  .xl\:scroll-mr-44 {
    scroll-margin-right: 11rem;
  }

  .xl\:scroll-mr-48 {
    scroll-margin-right: 12rem;
  }

  .xl\:scroll-mr-5 {
    scroll-margin-right: 1.25rem;
  }

  .xl\:scroll-mr-52 {
    scroll-margin-right: 13rem;
  }

  .xl\:scroll-mr-56 {
    scroll-margin-right: 14rem;
  }

  .xl\:scroll-mr-6 {
    scroll-margin-right: 1.5rem;
  }

  .xl\:scroll-mr-60 {
    scroll-margin-right: 15rem;
  }

  .xl\:scroll-mr-64 {
    scroll-margin-right: 16rem;
  }

  .xl\:scroll-mr-7 {
    scroll-margin-right: 1.75rem;
  }

  .xl\:scroll-mr-72 {
    scroll-margin-right: 18rem;
  }

  .xl\:scroll-mr-8 {
    scroll-margin-right: 2rem;
  }

  .xl\:scroll-mr-80 {
    scroll-margin-right: 20rem;
  }

  .xl\:scroll-mr-9 {
    scroll-margin-right: 2.25rem;
  }

  .xl\:scroll-mr-96 {
    scroll-margin-right: 24rem;
  }

  .xl\:scroll-mr-px {
    scroll-margin-right: 1px;
  }

  .xl\:scroll-ms-0 {
    scroll-margin-inline-start: 0;
  }

  .xl\:scroll-ms-0\.5 {
    scroll-margin-inline-start: .125rem;
  }

  .xl\:scroll-ms-1 {
    scroll-margin-inline-start: .25rem;
  }

  .xl\:scroll-ms-1\.5 {
    scroll-margin-inline-start: .375rem;
  }

  .xl\:scroll-ms-10 {
    scroll-margin-inline-start: 2.5rem;
  }

  .xl\:scroll-ms-11 {
    scroll-margin-inline-start: 2.75rem;
  }

  .xl\:scroll-ms-12 {
    scroll-margin-inline-start: 3rem;
  }

  .xl\:scroll-ms-14 {
    scroll-margin-inline-start: 3.5rem;
  }

  .xl\:scroll-ms-16 {
    scroll-margin-inline-start: 4rem;
  }

  .xl\:scroll-ms-2 {
    scroll-margin-inline-start: .5rem;
  }

  .xl\:scroll-ms-2\.5 {
    scroll-margin-inline-start: .625rem;
  }

  .xl\:scroll-ms-20 {
    scroll-margin-inline-start: 5rem;
  }

  .xl\:scroll-ms-24 {
    scroll-margin-inline-start: 6rem;
  }

  .xl\:scroll-ms-28 {
    scroll-margin-inline-start: 7rem;
  }

  .xl\:scroll-ms-3 {
    scroll-margin-inline-start: .75rem;
  }

  .xl\:scroll-ms-3\.5 {
    scroll-margin-inline-start: .875rem;
  }

  .xl\:scroll-ms-32 {
    scroll-margin-inline-start: 8rem;
  }

  .xl\:scroll-ms-36 {
    scroll-margin-inline-start: 9rem;
  }

  .xl\:scroll-ms-4 {
    scroll-margin-inline-start: 1rem;
  }

  .xl\:scroll-ms-40 {
    scroll-margin-inline-start: 10rem;
  }

  .xl\:scroll-ms-44 {
    scroll-margin-inline-start: 11rem;
  }

  .xl\:scroll-ms-48 {
    scroll-margin-inline-start: 12rem;
  }

  .xl\:scroll-ms-5 {
    scroll-margin-inline-start: 1.25rem;
  }

  .xl\:scroll-ms-52 {
    scroll-margin-inline-start: 13rem;
  }

  .xl\:scroll-ms-56 {
    scroll-margin-inline-start: 14rem;
  }

  .xl\:scroll-ms-6 {
    scroll-margin-inline-start: 1.5rem;
  }

  .xl\:scroll-ms-60 {
    scroll-margin-inline-start: 15rem;
  }

  .xl\:scroll-ms-64 {
    scroll-margin-inline-start: 16rem;
  }

  .xl\:scroll-ms-7 {
    scroll-margin-inline-start: 1.75rem;
  }

  .xl\:scroll-ms-72 {
    scroll-margin-inline-start: 18rem;
  }

  .xl\:scroll-ms-8 {
    scroll-margin-inline-start: 2rem;
  }

  .xl\:scroll-ms-80 {
    scroll-margin-inline-start: 20rem;
  }

  .xl\:scroll-ms-9 {
    scroll-margin-inline-start: 2.25rem;
  }

  .xl\:scroll-ms-96 {
    scroll-margin-inline-start: 24rem;
  }

  .xl\:scroll-ms-px {
    scroll-margin-inline-start: 1px;
  }

  .xl\:scroll-mt-0 {
    scroll-margin-top: 0;
  }

  .xl\:scroll-mt-0\.5 {
    scroll-margin-top: .125rem;
  }

  .xl\:scroll-mt-1 {
    scroll-margin-top: .25rem;
  }

  .xl\:scroll-mt-1\.5 {
    scroll-margin-top: .375rem;
  }

  .xl\:scroll-mt-10 {
    scroll-margin-top: 2.5rem;
  }

  .xl\:scroll-mt-11 {
    scroll-margin-top: 2.75rem;
  }

  .xl\:scroll-mt-12 {
    scroll-margin-top: 3rem;
  }

  .xl\:scroll-mt-14 {
    scroll-margin-top: 3.5rem;
  }

  .xl\:scroll-mt-16 {
    scroll-margin-top: 4rem;
  }

  .xl\:scroll-mt-2 {
    scroll-margin-top: .5rem;
  }

  .xl\:scroll-mt-2\.5 {
    scroll-margin-top: .625rem;
  }

  .xl\:scroll-mt-20 {
    scroll-margin-top: 5rem;
  }

  .xl\:scroll-mt-24 {
    scroll-margin-top: 6rem;
  }

  .xl\:scroll-mt-28 {
    scroll-margin-top: 7rem;
  }

  .xl\:scroll-mt-3 {
    scroll-margin-top: .75rem;
  }

  .xl\:scroll-mt-3\.5 {
    scroll-margin-top: .875rem;
  }

  .xl\:scroll-mt-32 {
    scroll-margin-top: 8rem;
  }

  .xl\:scroll-mt-36 {
    scroll-margin-top: 9rem;
  }

  .xl\:scroll-mt-4 {
    scroll-margin-top: 1rem;
  }

  .xl\:scroll-mt-40 {
    scroll-margin-top: 10rem;
  }

  .xl\:scroll-mt-44 {
    scroll-margin-top: 11rem;
  }

  .xl\:scroll-mt-48 {
    scroll-margin-top: 12rem;
  }

  .xl\:scroll-mt-5 {
    scroll-margin-top: 1.25rem;
  }

  .xl\:scroll-mt-52 {
    scroll-margin-top: 13rem;
  }

  .xl\:scroll-mt-56 {
    scroll-margin-top: 14rem;
  }

  .xl\:scroll-mt-6 {
    scroll-margin-top: 1.5rem;
  }

  .xl\:scroll-mt-60 {
    scroll-margin-top: 15rem;
  }

  .xl\:scroll-mt-64 {
    scroll-margin-top: 16rem;
  }

  .xl\:scroll-mt-7 {
    scroll-margin-top: 1.75rem;
  }

  .xl\:scroll-mt-72 {
    scroll-margin-top: 18rem;
  }

  .xl\:scroll-mt-8 {
    scroll-margin-top: 2rem;
  }

  .xl\:scroll-mt-80 {
    scroll-margin-top: 20rem;
  }

  .xl\:scroll-mt-9 {
    scroll-margin-top: 2.25rem;
  }

  .xl\:scroll-mt-96 {
    scroll-margin-top: 24rem;
  }

  .xl\:scroll-mt-px {
    scroll-margin-top: 1px;
  }

  .xl\:scroll-p-0 {
    scroll-padding: 0;
  }

  .xl\:scroll-p-0\.5 {
    scroll-padding: .125rem;
  }

  .xl\:scroll-p-1 {
    scroll-padding: .25rem;
  }

  .xl\:scroll-p-1\.5 {
    scroll-padding: .375rem;
  }

  .xl\:scroll-p-10 {
    scroll-padding: 2.5rem;
  }

  .xl\:scroll-p-11 {
    scroll-padding: 2.75rem;
  }

  .xl\:scroll-p-12 {
    scroll-padding: 3rem;
  }

  .xl\:scroll-p-14 {
    scroll-padding: 3.5rem;
  }

  .xl\:scroll-p-16 {
    scroll-padding: 4rem;
  }

  .xl\:scroll-p-2 {
    scroll-padding: .5rem;
  }

  .xl\:scroll-p-2\.5 {
    scroll-padding: .625rem;
  }

  .xl\:scroll-p-20 {
    scroll-padding: 5rem;
  }

  .xl\:scroll-p-24 {
    scroll-padding: 6rem;
  }

  .xl\:scroll-p-28 {
    scroll-padding: 7rem;
  }

  .xl\:scroll-p-3 {
    scroll-padding: .75rem;
  }

  .xl\:scroll-p-3\.5 {
    scroll-padding: .875rem;
  }

  .xl\:scroll-p-32 {
    scroll-padding: 8rem;
  }

  .xl\:scroll-p-36 {
    scroll-padding: 9rem;
  }

  .xl\:scroll-p-4 {
    scroll-padding: 1rem;
  }

  .xl\:scroll-p-40 {
    scroll-padding: 10rem;
  }

  .xl\:scroll-p-44 {
    scroll-padding: 11rem;
  }

  .xl\:scroll-p-48 {
    scroll-padding: 12rem;
  }

  .xl\:scroll-p-5 {
    scroll-padding: 1.25rem;
  }

  .xl\:scroll-p-52 {
    scroll-padding: 13rem;
  }

  .xl\:scroll-p-56 {
    scroll-padding: 14rem;
  }

  .xl\:scroll-p-6 {
    scroll-padding: 1.5rem;
  }

  .xl\:scroll-p-60 {
    scroll-padding: 15rem;
  }

  .xl\:scroll-p-64 {
    scroll-padding: 16rem;
  }

  .xl\:scroll-p-7 {
    scroll-padding: 1.75rem;
  }

  .xl\:scroll-p-72 {
    scroll-padding: 18rem;
  }

  .xl\:scroll-p-8 {
    scroll-padding: 2rem;
  }

  .xl\:scroll-p-80 {
    scroll-padding: 20rem;
  }

  .xl\:scroll-p-9 {
    scroll-padding: 2.25rem;
  }

  .xl\:scroll-p-96 {
    scroll-padding: 24rem;
  }

  .xl\:scroll-p-px {
    scroll-padding: 1px;
  }

  .xl\:scroll-px-0 {
    scroll-padding-left: 0;
    scroll-padding-right: 0;
  }

  .xl\:scroll-px-0\.5 {
    scroll-padding-left: .125rem;
    scroll-padding-right: .125rem;
  }

  .xl\:scroll-px-1 {
    scroll-padding-left: .25rem;
    scroll-padding-right: .25rem;
  }

  .xl\:scroll-px-1\.5 {
    scroll-padding-left: .375rem;
    scroll-padding-right: .375rem;
  }

  .xl\:scroll-px-10 {
    scroll-padding-left: 2.5rem;
    scroll-padding-right: 2.5rem;
  }

  .xl\:scroll-px-11 {
    scroll-padding-left: 2.75rem;
    scroll-padding-right: 2.75rem;
  }

  .xl\:scroll-px-12 {
    scroll-padding-left: 3rem;
    scroll-padding-right: 3rem;
  }

  .xl\:scroll-px-14 {
    scroll-padding-left: 3.5rem;
    scroll-padding-right: 3.5rem;
  }

  .xl\:scroll-px-16 {
    scroll-padding-left: 4rem;
    scroll-padding-right: 4rem;
  }

  .xl\:scroll-px-2 {
    scroll-padding-left: .5rem;
    scroll-padding-right: .5rem;
  }

  .xl\:scroll-px-2\.5 {
    scroll-padding-left: .625rem;
    scroll-padding-right: .625rem;
  }

  .xl\:scroll-px-20 {
    scroll-padding-left: 5rem;
    scroll-padding-right: 5rem;
  }

  .xl\:scroll-px-24 {
    scroll-padding-left: 6rem;
    scroll-padding-right: 6rem;
  }

  .xl\:scroll-px-28 {
    scroll-padding-left: 7rem;
    scroll-padding-right: 7rem;
  }

  .xl\:scroll-px-3 {
    scroll-padding-left: .75rem;
    scroll-padding-right: .75rem;
  }

  .xl\:scroll-px-3\.5 {
    scroll-padding-left: .875rem;
    scroll-padding-right: .875rem;
  }

  .xl\:scroll-px-32 {
    scroll-padding-left: 8rem;
    scroll-padding-right: 8rem;
  }

  .xl\:scroll-px-36 {
    scroll-padding-left: 9rem;
    scroll-padding-right: 9rem;
  }

  .xl\:scroll-px-4 {
    scroll-padding-left: 1rem;
    scroll-padding-right: 1rem;
  }

  .xl\:scroll-px-40 {
    scroll-padding-left: 10rem;
    scroll-padding-right: 10rem;
  }

  .xl\:scroll-px-44 {
    scroll-padding-left: 11rem;
    scroll-padding-right: 11rem;
  }

  .xl\:scroll-px-48 {
    scroll-padding-left: 12rem;
    scroll-padding-right: 12rem;
  }

  .xl\:scroll-px-5 {
    scroll-padding-left: 1.25rem;
    scroll-padding-right: 1.25rem;
  }

  .xl\:scroll-px-52 {
    scroll-padding-left: 13rem;
    scroll-padding-right: 13rem;
  }

  .xl\:scroll-px-56 {
    scroll-padding-left: 14rem;
    scroll-padding-right: 14rem;
  }

  .xl\:scroll-px-6 {
    scroll-padding-left: 1.5rem;
    scroll-padding-right: 1.5rem;
  }

  .xl\:scroll-px-60 {
    scroll-padding-left: 15rem;
    scroll-padding-right: 15rem;
  }

  .xl\:scroll-px-64 {
    scroll-padding-left: 16rem;
    scroll-padding-right: 16rem;
  }

  .xl\:scroll-px-7 {
    scroll-padding-left: 1.75rem;
    scroll-padding-right: 1.75rem;
  }

  .xl\:scroll-px-72 {
    scroll-padding-left: 18rem;
    scroll-padding-right: 18rem;
  }

  .xl\:scroll-px-8 {
    scroll-padding-left: 2rem;
    scroll-padding-right: 2rem;
  }

  .xl\:scroll-px-80 {
    scroll-padding-left: 20rem;
    scroll-padding-right: 20rem;
  }

  .xl\:scroll-px-9 {
    scroll-padding-left: 2.25rem;
    scroll-padding-right: 2.25rem;
  }

  .xl\:scroll-px-96 {
    scroll-padding-left: 24rem;
    scroll-padding-right: 24rem;
  }

  .xl\:scroll-px-px {
    scroll-padding-left: 1px;
    scroll-padding-right: 1px;
  }

  .xl\:scroll-py-0 {
    scroll-padding-top: 0;
    scroll-padding-bottom: 0;
  }

  .xl\:scroll-py-0\.5 {
    scroll-padding-top: .125rem;
    scroll-padding-bottom: .125rem;
  }

  .xl\:scroll-py-1 {
    scroll-padding-top: .25rem;
    scroll-padding-bottom: .25rem;
  }

  .xl\:scroll-py-1\.5 {
    scroll-padding-top: .375rem;
    scroll-padding-bottom: .375rem;
  }

  .xl\:scroll-py-10 {
    scroll-padding-top: 2.5rem;
    scroll-padding-bottom: 2.5rem;
  }

  .xl\:scroll-py-11 {
    scroll-padding-top: 2.75rem;
    scroll-padding-bottom: 2.75rem;
  }

  .xl\:scroll-py-12 {
    scroll-padding-top: 3rem;
    scroll-padding-bottom: 3rem;
  }

  .xl\:scroll-py-14 {
    scroll-padding-top: 3.5rem;
    scroll-padding-bottom: 3.5rem;
  }

  .xl\:scroll-py-16 {
    scroll-padding-top: 4rem;
    scroll-padding-bottom: 4rem;
  }

  .xl\:scroll-py-2 {
    scroll-padding-top: .5rem;
    scroll-padding-bottom: .5rem;
  }

  .xl\:scroll-py-2\.5 {
    scroll-padding-top: .625rem;
    scroll-padding-bottom: .625rem;
  }

  .xl\:scroll-py-20 {
    scroll-padding-top: 5rem;
    scroll-padding-bottom: 5rem;
  }

  .xl\:scroll-py-24 {
    scroll-padding-top: 6rem;
    scroll-padding-bottom: 6rem;
  }

  .xl\:scroll-py-28 {
    scroll-padding-top: 7rem;
    scroll-padding-bottom: 7rem;
  }

  .xl\:scroll-py-3 {
    scroll-padding-top: .75rem;
    scroll-padding-bottom: .75rem;
  }

  .xl\:scroll-py-3\.5 {
    scroll-padding-top: .875rem;
    scroll-padding-bottom: .875rem;
  }

  .xl\:scroll-py-32 {
    scroll-padding-top: 8rem;
    scroll-padding-bottom: 8rem;
  }

  .xl\:scroll-py-36 {
    scroll-padding-top: 9rem;
    scroll-padding-bottom: 9rem;
  }

  .xl\:scroll-py-4 {
    scroll-padding-top: 1rem;
    scroll-padding-bottom: 1rem;
  }

  .xl\:scroll-py-40 {
    scroll-padding-top: 10rem;
    scroll-padding-bottom: 10rem;
  }

  .xl\:scroll-py-44 {
    scroll-padding-top: 11rem;
    scroll-padding-bottom: 11rem;
  }

  .xl\:scroll-py-48 {
    scroll-padding-top: 12rem;
    scroll-padding-bottom: 12rem;
  }

  .xl\:scroll-py-5 {
    scroll-padding-top: 1.25rem;
    scroll-padding-bottom: 1.25rem;
  }

  .xl\:scroll-py-52 {
    scroll-padding-top: 13rem;
    scroll-padding-bottom: 13rem;
  }

  .xl\:scroll-py-56 {
    scroll-padding-top: 14rem;
    scroll-padding-bottom: 14rem;
  }

  .xl\:scroll-py-6 {
    scroll-padding-top: 1.5rem;
    scroll-padding-bottom: 1.5rem;
  }

  .xl\:scroll-py-60 {
    scroll-padding-top: 15rem;
    scroll-padding-bottom: 15rem;
  }

  .xl\:scroll-py-64 {
    scroll-padding-top: 16rem;
    scroll-padding-bottom: 16rem;
  }

  .xl\:scroll-py-7 {
    scroll-padding-top: 1.75rem;
    scroll-padding-bottom: 1.75rem;
  }

  .xl\:scroll-py-72 {
    scroll-padding-top: 18rem;
    scroll-padding-bottom: 18rem;
  }

  .xl\:scroll-py-8 {
    scroll-padding-top: 2rem;
    scroll-padding-bottom: 2rem;
  }

  .xl\:scroll-py-80 {
    scroll-padding-top: 20rem;
    scroll-padding-bottom: 20rem;
  }

  .xl\:scroll-py-9 {
    scroll-padding-top: 2.25rem;
    scroll-padding-bottom: 2.25rem;
  }

  .xl\:scroll-py-96 {
    scroll-padding-top: 24rem;
    scroll-padding-bottom: 24rem;
  }

  .xl\:scroll-py-px {
    scroll-padding-top: 1px;
    scroll-padding-bottom: 1px;
  }

  .xl\:scroll-pb-0 {
    scroll-padding-bottom: 0;
  }

  .xl\:scroll-pb-0\.5 {
    scroll-padding-bottom: .125rem;
  }

  .xl\:scroll-pb-1 {
    scroll-padding-bottom: .25rem;
  }

  .xl\:scroll-pb-1\.5 {
    scroll-padding-bottom: .375rem;
  }

  .xl\:scroll-pb-10 {
    scroll-padding-bottom: 2.5rem;
  }

  .xl\:scroll-pb-11 {
    scroll-padding-bottom: 2.75rem;
  }

  .xl\:scroll-pb-12 {
    scroll-padding-bottom: 3rem;
  }

  .xl\:scroll-pb-14 {
    scroll-padding-bottom: 3.5rem;
  }

  .xl\:scroll-pb-16 {
    scroll-padding-bottom: 4rem;
  }

  .xl\:scroll-pb-2 {
    scroll-padding-bottom: .5rem;
  }

  .xl\:scroll-pb-2\.5 {
    scroll-padding-bottom: .625rem;
  }

  .xl\:scroll-pb-20 {
    scroll-padding-bottom: 5rem;
  }

  .xl\:scroll-pb-24 {
    scroll-padding-bottom: 6rem;
  }

  .xl\:scroll-pb-28 {
    scroll-padding-bottom: 7rem;
  }

  .xl\:scroll-pb-3 {
    scroll-padding-bottom: .75rem;
  }

  .xl\:scroll-pb-3\.5 {
    scroll-padding-bottom: .875rem;
  }

  .xl\:scroll-pb-32 {
    scroll-padding-bottom: 8rem;
  }

  .xl\:scroll-pb-36 {
    scroll-padding-bottom: 9rem;
  }

  .xl\:scroll-pb-4 {
    scroll-padding-bottom: 1rem;
  }

  .xl\:scroll-pb-40 {
    scroll-padding-bottom: 10rem;
  }

  .xl\:scroll-pb-44 {
    scroll-padding-bottom: 11rem;
  }

  .xl\:scroll-pb-48 {
    scroll-padding-bottom: 12rem;
  }

  .xl\:scroll-pb-5 {
    scroll-padding-bottom: 1.25rem;
  }

  .xl\:scroll-pb-52 {
    scroll-padding-bottom: 13rem;
  }

  .xl\:scroll-pb-56 {
    scroll-padding-bottom: 14rem;
  }

  .xl\:scroll-pb-6 {
    scroll-padding-bottom: 1.5rem;
  }

  .xl\:scroll-pb-60 {
    scroll-padding-bottom: 15rem;
  }

  .xl\:scroll-pb-64 {
    scroll-padding-bottom: 16rem;
  }

  .xl\:scroll-pb-7 {
    scroll-padding-bottom: 1.75rem;
  }

  .xl\:scroll-pb-72 {
    scroll-padding-bottom: 18rem;
  }

  .xl\:scroll-pb-8 {
    scroll-padding-bottom: 2rem;
  }

  .xl\:scroll-pb-80 {
    scroll-padding-bottom: 20rem;
  }

  .xl\:scroll-pb-9 {
    scroll-padding-bottom: 2.25rem;
  }

  .xl\:scroll-pb-96 {
    scroll-padding-bottom: 24rem;
  }

  .xl\:scroll-pb-px {
    scroll-padding-bottom: 1px;
  }

  .xl\:scroll-pe-0 {
    scroll-padding-inline-end: 0;
  }

  .xl\:scroll-pe-0\.5 {
    scroll-padding-inline-end: .125rem;
  }

  .xl\:scroll-pe-1 {
    scroll-padding-inline-end: .25rem;
  }

  .xl\:scroll-pe-1\.5 {
    scroll-padding-inline-end: .375rem;
  }

  .xl\:scroll-pe-10 {
    scroll-padding-inline-end: 2.5rem;
  }

  .xl\:scroll-pe-11 {
    scroll-padding-inline-end: 2.75rem;
  }

  .xl\:scroll-pe-12 {
    scroll-padding-inline-end: 3rem;
  }

  .xl\:scroll-pe-14 {
    scroll-padding-inline-end: 3.5rem;
  }

  .xl\:scroll-pe-16 {
    scroll-padding-inline-end: 4rem;
  }

  .xl\:scroll-pe-2 {
    scroll-padding-inline-end: .5rem;
  }

  .xl\:scroll-pe-2\.5 {
    scroll-padding-inline-end: .625rem;
  }

  .xl\:scroll-pe-20 {
    scroll-padding-inline-end: 5rem;
  }

  .xl\:scroll-pe-24 {
    scroll-padding-inline-end: 6rem;
  }

  .xl\:scroll-pe-28 {
    scroll-padding-inline-end: 7rem;
  }

  .xl\:scroll-pe-3 {
    scroll-padding-inline-end: .75rem;
  }

  .xl\:scroll-pe-3\.5 {
    scroll-padding-inline-end: .875rem;
  }

  .xl\:scroll-pe-32 {
    scroll-padding-inline-end: 8rem;
  }

  .xl\:scroll-pe-36 {
    scroll-padding-inline-end: 9rem;
  }

  .xl\:scroll-pe-4 {
    scroll-padding-inline-end: 1rem;
  }

  .xl\:scroll-pe-40 {
    scroll-padding-inline-end: 10rem;
  }

  .xl\:scroll-pe-44 {
    scroll-padding-inline-end: 11rem;
  }

  .xl\:scroll-pe-48 {
    scroll-padding-inline-end: 12rem;
  }

  .xl\:scroll-pe-5 {
    scroll-padding-inline-end: 1.25rem;
  }

  .xl\:scroll-pe-52 {
    scroll-padding-inline-end: 13rem;
  }

  .xl\:scroll-pe-56 {
    scroll-padding-inline-end: 14rem;
  }

  .xl\:scroll-pe-6 {
    scroll-padding-inline-end: 1.5rem;
  }

  .xl\:scroll-pe-60 {
    scroll-padding-inline-end: 15rem;
  }

  .xl\:scroll-pe-64 {
    scroll-padding-inline-end: 16rem;
  }

  .xl\:scroll-pe-7 {
    scroll-padding-inline-end: 1.75rem;
  }

  .xl\:scroll-pe-72 {
    scroll-padding-inline-end: 18rem;
  }

  .xl\:scroll-pe-8 {
    scroll-padding-inline-end: 2rem;
  }

  .xl\:scroll-pe-80 {
    scroll-padding-inline-end: 20rem;
  }

  .xl\:scroll-pe-9 {
    scroll-padding-inline-end: 2.25rem;
  }

  .xl\:scroll-pe-96 {
    scroll-padding-inline-end: 24rem;
  }

  .xl\:scroll-pe-px {
    scroll-padding-inline-end: 1px;
  }

  .xl\:scroll-pl-0 {
    scroll-padding-left: 0;
  }

  .xl\:scroll-pl-0\.5 {
    scroll-padding-left: .125rem;
  }

  .xl\:scroll-pl-1 {
    scroll-padding-left: .25rem;
  }

  .xl\:scroll-pl-1\.5 {
    scroll-padding-left: .375rem;
  }

  .xl\:scroll-pl-10 {
    scroll-padding-left: 2.5rem;
  }

  .xl\:scroll-pl-11 {
    scroll-padding-left: 2.75rem;
  }

  .xl\:scroll-pl-12 {
    scroll-padding-left: 3rem;
  }

  .xl\:scroll-pl-14 {
    scroll-padding-left: 3.5rem;
  }

  .xl\:scroll-pl-16 {
    scroll-padding-left: 4rem;
  }

  .xl\:scroll-pl-2 {
    scroll-padding-left: .5rem;
  }

  .xl\:scroll-pl-2\.5 {
    scroll-padding-left: .625rem;
  }

  .xl\:scroll-pl-20 {
    scroll-padding-left: 5rem;
  }

  .xl\:scroll-pl-24 {
    scroll-padding-left: 6rem;
  }

  .xl\:scroll-pl-28 {
    scroll-padding-left: 7rem;
  }

  .xl\:scroll-pl-3 {
    scroll-padding-left: .75rem;
  }

  .xl\:scroll-pl-3\.5 {
    scroll-padding-left: .875rem;
  }

  .xl\:scroll-pl-32 {
    scroll-padding-left: 8rem;
  }

  .xl\:scroll-pl-36 {
    scroll-padding-left: 9rem;
  }

  .xl\:scroll-pl-4 {
    scroll-padding-left: 1rem;
  }

  .xl\:scroll-pl-40 {
    scroll-padding-left: 10rem;
  }

  .xl\:scroll-pl-44 {
    scroll-padding-left: 11rem;
  }

  .xl\:scroll-pl-48 {
    scroll-padding-left: 12rem;
  }

  .xl\:scroll-pl-5 {
    scroll-padding-left: 1.25rem;
  }

  .xl\:scroll-pl-52 {
    scroll-padding-left: 13rem;
  }

  .xl\:scroll-pl-56 {
    scroll-padding-left: 14rem;
  }

  .xl\:scroll-pl-6 {
    scroll-padding-left: 1.5rem;
  }

  .xl\:scroll-pl-60 {
    scroll-padding-left: 15rem;
  }

  .xl\:scroll-pl-64 {
    scroll-padding-left: 16rem;
  }

  .xl\:scroll-pl-7 {
    scroll-padding-left: 1.75rem;
  }

  .xl\:scroll-pl-72 {
    scroll-padding-left: 18rem;
  }

  .xl\:scroll-pl-8 {
    scroll-padding-left: 2rem;
  }

  .xl\:scroll-pl-80 {
    scroll-padding-left: 20rem;
  }

  .xl\:scroll-pl-9 {
    scroll-padding-left: 2.25rem;
  }

  .xl\:scroll-pl-96 {
    scroll-padding-left: 24rem;
  }

  .xl\:scroll-pl-px {
    scroll-padding-left: 1px;
  }

  .xl\:scroll-pr-0 {
    scroll-padding-right: 0;
  }

  .xl\:scroll-pr-0\.5 {
    scroll-padding-right: .125rem;
  }

  .xl\:scroll-pr-1 {
    scroll-padding-right: .25rem;
  }

  .xl\:scroll-pr-1\.5 {
    scroll-padding-right: .375rem;
  }

  .xl\:scroll-pr-10 {
    scroll-padding-right: 2.5rem;
  }

  .xl\:scroll-pr-11 {
    scroll-padding-right: 2.75rem;
  }

  .xl\:scroll-pr-12 {
    scroll-padding-right: 3rem;
  }

  .xl\:scroll-pr-14 {
    scroll-padding-right: 3.5rem;
  }

  .xl\:scroll-pr-16 {
    scroll-padding-right: 4rem;
  }

  .xl\:scroll-pr-2 {
    scroll-padding-right: .5rem;
  }

  .xl\:scroll-pr-2\.5 {
    scroll-padding-right: .625rem;
  }

  .xl\:scroll-pr-20 {
    scroll-padding-right: 5rem;
  }

  .xl\:scroll-pr-24 {
    scroll-padding-right: 6rem;
  }

  .xl\:scroll-pr-28 {
    scroll-padding-right: 7rem;
  }

  .xl\:scroll-pr-3 {
    scroll-padding-right: .75rem;
  }

  .xl\:scroll-pr-3\.5 {
    scroll-padding-right: .875rem;
  }

  .xl\:scroll-pr-32 {
    scroll-padding-right: 8rem;
  }

  .xl\:scroll-pr-36 {
    scroll-padding-right: 9rem;
  }

  .xl\:scroll-pr-4 {
    scroll-padding-right: 1rem;
  }

  .xl\:scroll-pr-40 {
    scroll-padding-right: 10rem;
  }

  .xl\:scroll-pr-44 {
    scroll-padding-right: 11rem;
  }

  .xl\:scroll-pr-48 {
    scroll-padding-right: 12rem;
  }

  .xl\:scroll-pr-5 {
    scroll-padding-right: 1.25rem;
  }

  .xl\:scroll-pr-52 {
    scroll-padding-right: 13rem;
  }

  .xl\:scroll-pr-56 {
    scroll-padding-right: 14rem;
  }

  .xl\:scroll-pr-6 {
    scroll-padding-right: 1.5rem;
  }

  .xl\:scroll-pr-60 {
    scroll-padding-right: 15rem;
  }

  .xl\:scroll-pr-64 {
    scroll-padding-right: 16rem;
  }

  .xl\:scroll-pr-7 {
    scroll-padding-right: 1.75rem;
  }

  .xl\:scroll-pr-72 {
    scroll-padding-right: 18rem;
  }

  .xl\:scroll-pr-8 {
    scroll-padding-right: 2rem;
  }

  .xl\:scroll-pr-80 {
    scroll-padding-right: 20rem;
  }

  .xl\:scroll-pr-9 {
    scroll-padding-right: 2.25rem;
  }

  .xl\:scroll-pr-96 {
    scroll-padding-right: 24rem;
  }

  .xl\:scroll-pr-px {
    scroll-padding-right: 1px;
  }

  .xl\:scroll-ps-0 {
    scroll-padding-inline-start: 0;
  }

  .xl\:scroll-ps-0\.5 {
    scroll-padding-inline-start: .125rem;
  }

  .xl\:scroll-ps-1 {
    scroll-padding-inline-start: .25rem;
  }

  .xl\:scroll-ps-1\.5 {
    scroll-padding-inline-start: .375rem;
  }

  .xl\:scroll-ps-10 {
    scroll-padding-inline-start: 2.5rem;
  }

  .xl\:scroll-ps-11 {
    scroll-padding-inline-start: 2.75rem;
  }

  .xl\:scroll-ps-12 {
    scroll-padding-inline-start: 3rem;
  }

  .xl\:scroll-ps-14 {
    scroll-padding-inline-start: 3.5rem;
  }

  .xl\:scroll-ps-16 {
    scroll-padding-inline-start: 4rem;
  }

  .xl\:scroll-ps-2 {
    scroll-padding-inline-start: .5rem;
  }

  .xl\:scroll-ps-2\.5 {
    scroll-padding-inline-start: .625rem;
  }

  .xl\:scroll-ps-20 {
    scroll-padding-inline-start: 5rem;
  }

  .xl\:scroll-ps-24 {
    scroll-padding-inline-start: 6rem;
  }

  .xl\:scroll-ps-28 {
    scroll-padding-inline-start: 7rem;
  }

  .xl\:scroll-ps-3 {
    scroll-padding-inline-start: .75rem;
  }

  .xl\:scroll-ps-3\.5 {
    scroll-padding-inline-start: .875rem;
  }

  .xl\:scroll-ps-32 {
    scroll-padding-inline-start: 8rem;
  }

  .xl\:scroll-ps-36 {
    scroll-padding-inline-start: 9rem;
  }

  .xl\:scroll-ps-4 {
    scroll-padding-inline-start: 1rem;
  }

  .xl\:scroll-ps-40 {
    scroll-padding-inline-start: 10rem;
  }

  .xl\:scroll-ps-44 {
    scroll-padding-inline-start: 11rem;
  }

  .xl\:scroll-ps-48 {
    scroll-padding-inline-start: 12rem;
  }

  .xl\:scroll-ps-5 {
    scroll-padding-inline-start: 1.25rem;
  }

  .xl\:scroll-ps-52 {
    scroll-padding-inline-start: 13rem;
  }

  .xl\:scroll-ps-56 {
    scroll-padding-inline-start: 14rem;
  }

  .xl\:scroll-ps-6 {
    scroll-padding-inline-start: 1.5rem;
  }

  .xl\:scroll-ps-60 {
    scroll-padding-inline-start: 15rem;
  }

  .xl\:scroll-ps-64 {
    scroll-padding-inline-start: 16rem;
  }

  .xl\:scroll-ps-7 {
    scroll-padding-inline-start: 1.75rem;
  }

  .xl\:scroll-ps-72 {
    scroll-padding-inline-start: 18rem;
  }

  .xl\:scroll-ps-8 {
    scroll-padding-inline-start: 2rem;
  }

  .xl\:scroll-ps-80 {
    scroll-padding-inline-start: 20rem;
  }

  .xl\:scroll-ps-9 {
    scroll-padding-inline-start: 2.25rem;
  }

  .xl\:scroll-ps-96 {
    scroll-padding-inline-start: 24rem;
  }

  .xl\:scroll-ps-px {
    scroll-padding-inline-start: 1px;
  }

  .xl\:scroll-pt-0 {
    scroll-padding-top: 0;
  }

  .xl\:scroll-pt-0\.5 {
    scroll-padding-top: .125rem;
  }

  .xl\:scroll-pt-1 {
    scroll-padding-top: .25rem;
  }

  .xl\:scroll-pt-1\.5 {
    scroll-padding-top: .375rem;
  }

  .xl\:scroll-pt-10 {
    scroll-padding-top: 2.5rem;
  }

  .xl\:scroll-pt-11 {
    scroll-padding-top: 2.75rem;
  }

  .xl\:scroll-pt-12 {
    scroll-padding-top: 3rem;
  }

  .xl\:scroll-pt-14 {
    scroll-padding-top: 3.5rem;
  }

  .xl\:scroll-pt-16 {
    scroll-padding-top: 4rem;
  }

  .xl\:scroll-pt-2 {
    scroll-padding-top: .5rem;
  }

  .xl\:scroll-pt-2\.5 {
    scroll-padding-top: .625rem;
  }

  .xl\:scroll-pt-20 {
    scroll-padding-top: 5rem;
  }

  .xl\:scroll-pt-24 {
    scroll-padding-top: 6rem;
  }

  .xl\:scroll-pt-28 {
    scroll-padding-top: 7rem;
  }

  .xl\:scroll-pt-3 {
    scroll-padding-top: .75rem;
  }

  .xl\:scroll-pt-3\.5 {
    scroll-padding-top: .875rem;
  }

  .xl\:scroll-pt-32 {
    scroll-padding-top: 8rem;
  }

  .xl\:scroll-pt-36 {
    scroll-padding-top: 9rem;
  }

  .xl\:scroll-pt-4 {
    scroll-padding-top: 1rem;
  }

  .xl\:scroll-pt-40 {
    scroll-padding-top: 10rem;
  }

  .xl\:scroll-pt-44 {
    scroll-padding-top: 11rem;
  }

  .xl\:scroll-pt-48 {
    scroll-padding-top: 12rem;
  }

  .xl\:scroll-pt-5 {
    scroll-padding-top: 1.25rem;
  }

  .xl\:scroll-pt-52 {
    scroll-padding-top: 13rem;
  }

  .xl\:scroll-pt-56 {
    scroll-padding-top: 14rem;
  }

  .xl\:scroll-pt-6 {
    scroll-padding-top: 1.5rem;
  }

  .xl\:scroll-pt-60 {
    scroll-padding-top: 15rem;
  }

  .xl\:scroll-pt-64 {
    scroll-padding-top: 16rem;
  }

  .xl\:scroll-pt-7 {
    scroll-padding-top: 1.75rem;
  }

  .xl\:scroll-pt-72 {
    scroll-padding-top: 18rem;
  }

  .xl\:scroll-pt-8 {
    scroll-padding-top: 2rem;
  }

  .xl\:scroll-pt-80 {
    scroll-padding-top: 20rem;
  }

  .xl\:scroll-pt-9 {
    scroll-padding-top: 2.25rem;
  }

  .xl\:scroll-pt-96 {
    scroll-padding-top: 24rem;
  }

  .xl\:scroll-pt-px {
    scroll-padding-top: 1px;
  }

  .xl\:list-inside {
    list-style-position: inside;
  }

  .xl\:list-outside {
    list-style-position: outside;
  }

  .xl\:list-decimal {
    list-style-type: decimal;
  }

  .xl\:list-disc {
    list-style-type: disc;
  }

  .xl\:list-none {
    list-style-type: none;
  }

  .xl\:list-image-none {
    list-style-image: none;
  }

  .xl\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }

  .xl\:appearance-auto {
    -webkit-appearance: auto;
    appearance: auto;
  }

  .xl\:columns-1 {
    columns: 1;
  }

  .xl\:columns-10 {
    columns: 10;
  }

  .xl\:columns-11 {
    columns: 11;
  }

  .xl\:columns-12 {
    columns: 12;
  }

  .xl\:columns-2 {
    columns: 2;
  }

  .xl\:columns-2xl {
    columns: 42rem;
  }

  .xl\:columns-2xs {
    columns: 18rem;
  }

  .xl\:columns-3 {
    columns: 3;
  }

  .xl\:columns-3xl {
    columns: 48rem;
  }

  .xl\:columns-3xs {
    columns: 16rem;
  }

  .xl\:columns-4 {
    columns: 4;
  }

  .xl\:columns-4xl {
    columns: 56rem;
  }

  .xl\:columns-5 {
    columns: 5;
  }

  .xl\:columns-5xl {
    columns: 64rem;
  }

  .xl\:columns-6 {
    columns: 6;
  }

  .xl\:columns-6xl {
    columns: 72rem;
  }

  .xl\:columns-7 {
    columns: 7;
  }

  .xl\:columns-7xl {
    columns: 80rem;
  }

  .xl\:columns-8 {
    columns: 8;
  }

  .xl\:columns-9 {
    columns: 9;
  }

  .xl\:columns-auto {
    columns: auto;
  }

  .xl\:columns-lg {
    columns: 32rem;
  }

  .xl\:columns-md {
    columns: 28rem;
  }

  .xl\:columns-sm {
    columns: 24rem;
  }

  .xl\:columns-xl {
    columns: 36rem;
  }

  .xl\:columns-xs {
    columns: 20rem;
  }

  .xl\:break-before-auto {
    break-before: auto;
  }

  .xl\:break-before-avoid {
    break-before: avoid;
  }

  .xl\:break-before-all {
    break-before: all;
  }

  .xl\:break-before-avoid-page {
    break-before: avoid-page;
  }

  .xl\:break-before-page {
    break-before: page;
  }

  .xl\:break-before-left {
    break-before: left;
  }

  .xl\:break-before-right {
    break-before: right;
  }

  .xl\:break-before-column {
    break-before: column;
  }

  .xl\:break-inside-auto {
    break-inside: auto;
  }

  .xl\:break-inside-avoid {
    break-inside: avoid;
  }

  .xl\:break-inside-avoid-page {
    break-inside: avoid-page;
  }

  .xl\:break-inside-avoid-column {
    break-inside: avoid-column;
  }

  .xl\:break-after-auto {
    break-after: auto;
  }

  .xl\:break-after-avoid {
    break-after: avoid;
  }

  .xl\:break-after-all {
    break-after: all;
  }

  .xl\:break-after-avoid-page {
    break-after: avoid-page;
  }

  .xl\:break-after-page {
    break-after: page;
  }

  .xl\:break-after-left {
    break-after: left;
  }

  .xl\:break-after-right {
    break-after: right;
  }

  .xl\:break-after-column {
    break-after: column;
  }

  .xl\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .xl\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .xl\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .xl\:grid-flow-row {
    grid-auto-flow: row;
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column;
  }

  .xl\:grid-flow-dense {
    grid-auto-flow: dense;
  }

  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .xl\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .xl\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .xl\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xl\:grid-cols-none {
    grid-template-columns: none;
  }

  .xl\:grid-cols-subgrid {
    grid-template-columns: subgrid;
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr));
  }

  .xl\:grid-rows-11 {
    grid-template-rows: repeat(11, minmax(0, 1fr));
  }

  .xl\:grid-rows-12 {
    grid-template-rows: repeat(12, minmax(0, 1fr));
  }

  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-rows-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .xl\:grid-rows-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .xl\:grid-rows-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }

  .xl\:grid-rows-none {
    grid-template-rows: none;
  }

  .xl\:grid-rows-subgrid {
    grid-template-rows: subgrid;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .xl\:flex-wrap {
    flex-wrap: wrap;
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:place-content-center {
    place-content: center;
  }

  .xl\:place-content-start {
    place-content: start;
  }

  .xl\:place-content-end {
    place-content: end;
  }

  .xl\:place-content-between {
    place-content: space-between;
  }

  .xl\:place-content-around {
    place-content: space-around;
  }

  .xl\:place-content-evenly {
    place-content: space-evenly;
  }

  .xl\:place-content-baseline {
    place-content: baseline start;
  }

  .xl\:place-content-stretch {
    place-content: stretch;
  }

  .xl\:place-items-start {
    place-items: start;
  }

  .xl\:place-items-end {
    place-items: end;
  }

  .xl\:place-items-center {
    place-items: center;
  }

  .xl\:place-items-baseline {
    place-items: baseline;
  }

  .xl\:place-items-stretch {
    place-items: stretch stretch;
  }

  .xl\:content-normal {
    align-content: normal;
  }

  .xl\:content-center {
    align-content: center;
  }

  .xl\:content-start {
    align-content: flex-start;
  }

  .xl\:content-end {
    align-content: flex-end;
  }

  .xl\:content-between {
    align-content: space-between;
  }

  .xl\:content-around {
    align-content: space-around;
  }

  .xl\:content-evenly {
    align-content: space-evenly;
  }

  .xl\:content-baseline {
    align-content: baseline;
  }

  .xl\:content-stretch {
    align-content: stretch;
  }

  .xl\:items-start {
    align-items: flex-start;
  }

  .xl\:items-end {
    align-items: flex-end;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:items-baseline {
    align-items: baseline;
  }

  .xl\:items-stretch {
    align-items: stretch;
  }

  .xl\:justify-normal {
    justify-content: normal;
  }

  .xl\:justify-start {
    justify-content: flex-start;
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:justify-center {
    justify-content: center;
  }

  .xl\:justify-between {
    justify-content: space-between;
  }

  .xl\:justify-around {
    justify-content: space-around;
  }

  .xl\:justify-evenly {
    justify-content: space-evenly;
  }

  .xl\:justify-stretch {
    justify-content: stretch;
  }

  .xl\:justify-items-start {
    justify-items: start;
  }

  .xl\:justify-items-end {
    justify-items: end;
  }

  .xl\:justify-items-center {
    justify-items: center;
  }

  .xl\:justify-items-stretch {
    justify-items: stretch;
  }

  .xl\:gap-0 {
    gap: 0;
  }

  .xl\:gap-0\.5 {
    gap: .125rem;
  }

  .xl\:gap-1 {
    gap: .25rem;
  }

  .xl\:gap-1\.5 {
    gap: .375rem;
  }

  .xl\:gap-10 {
    gap: 2.5rem;
  }

  .xl\:gap-11 {
    gap: 2.75rem;
  }

  .xl\:gap-12 {
    gap: 3rem;
  }

  .xl\:gap-14 {
    gap: 3.5rem;
  }

  .xl\:gap-16 {
    gap: 4rem;
  }

  .xl\:gap-2 {
    gap: .5rem;
  }

  .xl\:gap-2\.5 {
    gap: .625rem;
  }

  .xl\:gap-20 {
    gap: 5rem;
  }

  .xl\:gap-24 {
    gap: 6rem;
  }

  .xl\:gap-28 {
    gap: 7rem;
  }

  .xl\:gap-3 {
    gap: .75rem;
  }

  .xl\:gap-3\.5 {
    gap: .875rem;
  }

  .xl\:gap-32 {
    gap: 8rem;
  }

  .xl\:gap-36 {
    gap: 9rem;
  }

  .xl\:gap-4 {
    gap: 1rem;
  }

  .xl\:gap-40 {
    gap: 10rem;
  }

  .xl\:gap-44 {
    gap: 11rem;
  }

  .xl\:gap-48 {
    gap: 12rem;
  }

  .xl\:gap-5 {
    gap: 1.25rem;
  }

  .xl\:gap-52 {
    gap: 13rem;
  }

  .xl\:gap-56 {
    gap: 14rem;
  }

  .xl\:gap-6 {
    gap: 1.5rem;
  }

  .xl\:gap-60 {
    gap: 15rem;
  }

  .xl\:gap-64 {
    gap: 16rem;
  }

  .xl\:gap-7 {
    gap: 1.75rem;
  }

  .xl\:gap-72 {
    gap: 18rem;
  }

  .xl\:gap-8 {
    gap: 2rem;
  }

  .xl\:gap-80 {
    gap: 20rem;
  }

  .xl\:gap-9 {
    gap: 2.25rem;
  }

  .xl\:gap-96 {
    gap: 24rem;
  }

  .xl\:gap-px {
    gap: 1px;
  }

  .xl\:gap-x-0 {
    column-gap: 0;
  }

  .xl\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .xl\:gap-x-1 {
    column-gap: .25rem;
  }

  .xl\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .xl\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .xl\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .xl\:gap-x-12 {
    column-gap: 3rem;
  }

  .xl\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .xl\:gap-x-16 {
    column-gap: 4rem;
  }

  .xl\:gap-x-2 {
    column-gap: .5rem;
  }

  .xl\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .xl\:gap-x-20 {
    column-gap: 5rem;
  }

  .xl\:gap-x-24 {
    column-gap: 6rem;
  }

  .xl\:gap-x-28 {
    column-gap: 7rem;
  }

  .xl\:gap-x-3 {
    column-gap: .75rem;
  }

  .xl\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .xl\:gap-x-32 {
    column-gap: 8rem;
  }

  .xl\:gap-x-36 {
    column-gap: 9rem;
  }

  .xl\:gap-x-4 {
    column-gap: 1rem;
  }

  .xl\:gap-x-40 {
    column-gap: 10rem;
  }

  .xl\:gap-x-44 {
    column-gap: 11rem;
  }

  .xl\:gap-x-48 {
    column-gap: 12rem;
  }

  .xl\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .xl\:gap-x-52 {
    column-gap: 13rem;
  }

  .xl\:gap-x-56 {
    column-gap: 14rem;
  }

  .xl\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .xl\:gap-x-60 {
    column-gap: 15rem;
  }

  .xl\:gap-x-64 {
    column-gap: 16rem;
  }

  .xl\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .xl\:gap-x-72 {
    column-gap: 18rem;
  }

  .xl\:gap-x-8 {
    column-gap: 2rem;
  }

  .xl\:gap-x-80 {
    column-gap: 20rem;
  }

  .xl\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .xl\:gap-x-96 {
    column-gap: 24rem;
  }

  .xl\:gap-x-px {
    column-gap: 1px;
  }

  .xl\:gap-y-0 {
    row-gap: 0;
  }

  .xl\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .xl\:gap-y-1 {
    row-gap: .25rem;
  }

  .xl\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .xl\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .xl\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .xl\:gap-y-12 {
    row-gap: 3rem;
  }

  .xl\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .xl\:gap-y-16 {
    row-gap: 4rem;
  }

  .xl\:gap-y-2 {
    row-gap: .5rem;
  }

  .xl\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .xl\:gap-y-20 {
    row-gap: 5rem;
  }

  .xl\:gap-y-24 {
    row-gap: 6rem;
  }

  .xl\:gap-y-28 {
    row-gap: 7rem;
  }

  .xl\:gap-y-3 {
    row-gap: .75rem;
  }

  .xl\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .xl\:gap-y-32 {
    row-gap: 8rem;
  }

  .xl\:gap-y-36 {
    row-gap: 9rem;
  }

  .xl\:gap-y-4 {
    row-gap: 1rem;
  }

  .xl\:gap-y-40 {
    row-gap: 10rem;
  }

  .xl\:gap-y-44 {
    row-gap: 11rem;
  }

  .xl\:gap-y-48 {
    row-gap: 12rem;
  }

  .xl\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .xl\:gap-y-52 {
    row-gap: 13rem;
  }

  .xl\:gap-y-56 {
    row-gap: 14rem;
  }

  .xl\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .xl\:gap-y-60 {
    row-gap: 15rem;
  }

  .xl\:gap-y-64 {
    row-gap: 16rem;
  }

  .xl\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .xl\:gap-y-72 {
    row-gap: 18rem;
  }

  .xl\:gap-y-8 {
    row-gap: 2rem;
  }

  .xl\:gap-y-80 {
    row-gap: 20rem;
  }

  .xl\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .xl\:gap-y-96 {
    row-gap: 24rem;
  }

  .xl\:gap-y-px {
    row-gap: 1px;
  }

  .xl\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0px * var(--tw-space-x-reverse));
    margin-left: calc(-0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .xl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .xl\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .xl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .xl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .xl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .xl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .xl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .xl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-black);
  }

  .xl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .xl\:divide-danger > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-300);
  }

  .xl\:divide-danger-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-100);
  }

  .xl\:divide-danger-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-200);
  }

  .xl\:divide-danger-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-300);
  }

  .xl\:divide-danger-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-danger-400);
  }

  .xl\:divide-divider > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-divider);
  }

  .xl\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-gray);
  }

  .xl\:divide-info > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-300);
  }

  .xl\:divide-info-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-100);
  }

  .xl\:divide-info-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-200);
  }

  .xl\:divide-info-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-300);
  }

  .xl\:divide-info-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-info-400);
  }

  .xl\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-300);
  }

  .xl\:divide-primary-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-100);
  }

  .xl\:divide-primary-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-200);
  }

  .xl\:divide-primary-250 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-250);
  }

  .xl\:divide-primary-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-300);
  }

  .xl\:divide-primary-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-400);
  }

  .xl\:divide-primary-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-500);
  }

  .xl\:divide-primary-600 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-primary-600);
  }

  .xl\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-300);
  }

  .xl\:divide-success-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-100);
  }

  .xl\:divide-success-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-200);
  }

  .xl\:divide-success-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-300);
  }

  .xl\:divide-success-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-success-400);
  }

  .xl\:divide-transparent > :not([hidden]) ~ :not([hidden]), .xl\:divide-transparent\/0 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .xl\:divide-transparent\/10 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000001a;
  }

  .xl\:divide-transparent\/100 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000;
  }

  .xl\:divide-transparent\/15 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000026;
  }

  .xl\:divide-transparent\/20 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0003;
  }

  .xl\:divide-transparent\/25 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000040;
  }

  .xl\:divide-transparent\/30 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000004d;
  }

  .xl\:divide-transparent\/35 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000059;
  }

  .xl\:divide-transparent\/40 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0006;
  }

  .xl\:divide-transparent\/45 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000073;
  }

  .xl\:divide-transparent\/5 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000000d;
  }

  .xl\:divide-transparent\/50 > :not([hidden]) ~ :not([hidden]) {
    border-color: #00000080;
  }

  .xl\:divide-transparent\/55 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000008c;
  }

  .xl\:divide-transparent\/60 > :not([hidden]) ~ :not([hidden]) {
    border-color: #0009;
  }

  .xl\:divide-transparent\/65 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000a6;
  }

  .xl\:divide-transparent\/70 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000b3;
  }

  .xl\:divide-transparent\/75 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000bf;
  }

  .xl\:divide-transparent\/80 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000c;
  }

  .xl\:divide-transparent\/85 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000d9;
  }

  .xl\:divide-transparent\/90 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000e6;
  }

  .xl\:divide-transparent\/95 > :not([hidden]) ~ :not([hidden]) {
    border-color: #000000f2;
  }

  .xl\:divide-warning > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-300);
  }

  .xl\:divide-warning-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-100);
  }

  .xl\:divide-warning-200 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-200);
  }

  .xl\:divide-warning-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-300);
  }

  .xl\:divide-warning-400 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-warning-400);
  }

  .xl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--color-white);
  }

  .xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .xl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .xl\:divide-opacity-15 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .15;
  }

  .xl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .xl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .xl\:divide-opacity-35 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .35;
  }

  .xl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .xl\:divide-opacity-45 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .45;
  }

  .xl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .xl\:divide-opacity-55 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .55;
  }

  .xl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .xl\:divide-opacity-65 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .65;
  }

  .xl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .xl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .xl\:divide-opacity-85 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .85;
  }

  .xl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .xl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .xl\:place-self-auto {
    place-self: auto;
  }

  .xl\:place-self-start {
    place-self: start;
  }

  .xl\:place-self-end {
    place-self: end;
  }

  .xl\:place-self-center {
    place-self: center;
  }

  .xl\:place-self-stretch {
    place-self: stretch stretch;
  }

  .xl\:self-auto {
    align-self: auto;
  }

  .xl\:self-start {
    align-self: flex-start;
  }

  .xl\:self-end {
    align-self: flex-end;
  }

  .xl\:self-center {
    align-self: center;
  }

  .xl\:self-stretch {
    align-self: stretch;
  }

  .xl\:self-baseline {
    align-self: baseline;
  }

  .xl\:justify-self-auto {
    justify-self: auto;
  }

  .xl\:justify-self-start {
    justify-self: start;
  }

  .xl\:justify-self-end {
    justify-self: end;
  }

  .xl\:justify-self-center {
    justify-self: center;
  }

  .xl\:justify-self-stretch {
    justify-self: stretch;
  }

  .xl\:overflow-auto {
    overflow: auto;
  }

  .xl\:overflow-hidden {
    overflow: hidden;
  }

  .xl\:overflow-clip {
    overflow: clip;
  }

  .xl\:overflow-visible {
    overflow: visible;
  }

  .xl\:overflow-scroll {
    overflow: scroll;
  }

  .xl\:overflow-x-auto {
    overflow-x: auto;
  }

  .xl\:overflow-y-auto {
    overflow-y: auto;
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .xl\:overflow-x-clip {
    overflow-x: clip;
  }

  .xl\:overflow-y-clip {
    overflow-y: clip;
  }

  .xl\:overflow-x-visible {
    overflow-x: visible;
  }

  .xl\:overflow-y-visible {
    overflow-y: visible;
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .xl\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .xl\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .xl\:overscroll-none {
    overscroll-behavior: none;
  }

  .xl\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .xl\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .xl\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .xl\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .xl\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .xl\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .xl\:scroll-auto {
    scroll-behavior: auto;
  }

  .xl\:scroll-smooth {
    scroll-behavior: smooth;
  }

  .xl\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .xl\:overflow-ellipsis, .xl\:text-ellipsis {
    text-overflow: ellipsis;
  }

  .xl\:text-clip {
    text-overflow: clip;
  }

  .xl\:hyphens-none {
    -webkit-hyphens: none;
    hyphens: none;
  }

  .xl\:hyphens-manual {
    -webkit-hyphens: manual;
    hyphens: manual;
  }

  .xl\:hyphens-auto {
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .xl\:whitespace-normal {
    white-space: normal;
  }

  .xl\:whitespace-nowrap {
    white-space: nowrap;
  }

  .xl\:whitespace-pre {
    white-space: pre;
  }

  .xl\:whitespace-pre-line {
    white-space: pre-line;
  }

  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .xl\:whitespace-break-spaces {
    white-space: break-spaces;
  }

  .xl\:text-wrap {
    text-wrap: wrap;
  }

  .xl\:text-nowrap {
    text-wrap: nowrap;
  }

  .xl\:text-balance {
    text-wrap: balance;
  }

  .xl\:text-pretty {
    text-wrap: pretty;
  }

  .xl\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .xl\:break-words {
    overflow-wrap: break-word;
  }

  .xl\:break-all {
    word-break: break-all;
  }

  .xl\:break-keep {
    word-break: keep-all;
  }

  .xl\:rounded {
    border-radius: .25rem;
  }

  .xl\:rounded-2xl {
    border-radius: 1rem;
  }

  .xl\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .xl\:rounded-full {
    border-radius: 9999px;
  }

  .xl\:rounded-lg {
    border-radius: .5rem;
  }

  .xl\:rounded-md {
    border-radius: .375rem;
  }

  .xl\:rounded-none {
    border-radius: 0;
  }

  .xl\:rounded-sm {
    border-radius: .125rem;
  }

  .xl\:rounded-xl {
    border-radius: .75rem;
  }

  .xl\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .xl\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .xl\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .xl\:rounded-e {
    border-start-end-radius: .25rem;
    border-end-end-radius: .25rem;
  }

  .xl\:rounded-e-2xl {
    border-start-end-radius: 1rem;
    border-end-end-radius: 1rem;
  }

  .xl\:rounded-e-3xl {
    border-start-end-radius: 1.5rem;
    border-end-end-radius: 1.5rem;
  }

  .xl\:rounded-e-full {
    border-start-end-radius: 9999px;
    border-end-end-radius: 9999px;
  }

  .xl\:rounded-e-lg {
    border-start-end-radius: .5rem;
    border-end-end-radius: .5rem;
  }

  .xl\:rounded-e-md {
    border-start-end-radius: .375rem;
    border-end-end-radius: .375rem;
  }

  .xl\:rounded-e-none {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }

  .xl\:rounded-e-sm {
    border-start-end-radius: .125rem;
    border-end-end-radius: .125rem;
  }

  .xl\:rounded-e-xl {
    border-start-end-radius: .75rem;
    border-end-end-radius: .75rem;
  }

  .xl\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .xl\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .xl\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .xl\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .xl\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xl\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .xl\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .xl\:rounded-s {
    border-start-start-radius: .25rem;
    border-end-start-radius: .25rem;
  }

  .xl\:rounded-s-2xl {
    border-start-start-radius: 1rem;
    border-end-start-radius: 1rem;
  }

  .xl\:rounded-s-3xl {
    border-start-start-radius: 1.5rem;
    border-end-start-radius: 1.5rem;
  }

  .xl\:rounded-s-full {
    border-start-start-radius: 9999px;
    border-end-start-radius: 9999px;
  }

  .xl\:rounded-s-lg {
    border-start-start-radius: .5rem;
    border-end-start-radius: .5rem;
  }

  .xl\:rounded-s-md {
    border-start-start-radius: .375rem;
    border-end-start-radius: .375rem;
  }

  .xl\:rounded-s-none {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }

  .xl\:rounded-s-sm {
    border-start-start-radius: .125rem;
    border-end-start-radius: .125rem;
  }

  .xl\:rounded-s-xl {
    border-start-start-radius: .75rem;
    border-end-start-radius: .75rem;
  }

  .xl\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xl\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .xl\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .xl\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .xl\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .xl\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .xl\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .xl\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .xl\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .xl\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .xl\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .xl\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .xl\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .xl\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .xl\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .xl\:rounded-ee {
    border-end-end-radius: .25rem;
  }

  .xl\:rounded-ee-2xl {
    border-end-end-radius: 1rem;
  }

  .xl\:rounded-ee-3xl {
    border-end-end-radius: 1.5rem;
  }

  .xl\:rounded-ee-full {
    border-end-end-radius: 9999px;
  }

  .xl\:rounded-ee-lg {
    border-end-end-radius: .5rem;
  }

  .xl\:rounded-ee-md {
    border-end-end-radius: .375rem;
  }

  .xl\:rounded-ee-none {
    border-end-end-radius: 0;
  }

  .xl\:rounded-ee-sm {
    border-end-end-radius: .125rem;
  }

  .xl\:rounded-ee-xl {
    border-end-end-radius: .75rem;
  }

  .xl\:rounded-es {
    border-end-start-radius: .25rem;
  }

  .xl\:rounded-es-2xl {
    border-end-start-radius: 1rem;
  }

  .xl\:rounded-es-3xl {
    border-end-start-radius: 1.5rem;
  }

  .xl\:rounded-es-full {
    border-end-start-radius: 9999px;
  }

  .xl\:rounded-es-lg {
    border-end-start-radius: .5rem;
  }

  .xl\:rounded-es-md {
    border-end-start-radius: .375rem;
  }

  .xl\:rounded-es-none {
    border-end-start-radius: 0;
  }

  .xl\:rounded-es-sm {
    border-end-start-radius: .125rem;
  }

  .xl\:rounded-es-xl {
    border-end-start-radius: .75rem;
  }

  .xl\:rounded-se {
    border-start-end-radius: .25rem;
  }

  .xl\:rounded-se-2xl {
    border-start-end-radius: 1rem;
  }

  .xl\:rounded-se-3xl {
    border-start-end-radius: 1.5rem;
  }

  .xl\:rounded-se-full {
    border-start-end-radius: 9999px;
  }

  .xl\:rounded-se-lg {
    border-start-end-radius: .5rem;
  }

  .xl\:rounded-se-md {
    border-start-end-radius: .375rem;
  }

  .xl\:rounded-se-none {
    border-start-end-radius: 0;
  }

  .xl\:rounded-se-sm {
    border-start-end-radius: .125rem;
  }

  .xl\:rounded-se-xl {
    border-start-end-radius: .75rem;
  }

  .xl\:rounded-ss {
    border-start-start-radius: .25rem;
  }

  .xl\:rounded-ss-2xl {
    border-start-start-radius: 1rem;
  }

  .xl\:rounded-ss-3xl {
    border-start-start-radius: 1.5rem;
  }

  .xl\:rounded-ss-full {
    border-start-start-radius: 9999px;
  }

  .xl\:rounded-ss-lg {
    border-start-start-radius: .5rem;
  }

  .xl\:rounded-ss-md {
    border-start-start-radius: .375rem;
  }

  .xl\:rounded-ss-none {
    border-start-start-radius: 0;
  }

  .xl\:rounded-ss-sm {
    border-start-start-radius: .125rem;
  }

  .xl\:rounded-ss-xl {
    border-start-start-radius: .75rem;
  }

  .xl\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .xl\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .xl\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .xl\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .xl\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .xl\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .xl\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .xl\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .xl\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .xl\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .xl\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .xl\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .xl\:border {
    border-width: 1px;
  }

  .xl\:border-0 {
    border-width: 0;
  }

  .xl\:border-2 {
    border-width: 2px;
  }

  .xl\:border-4 {
    border-width: 4px;
  }

  .xl\:border-8 {
    border-width: 8px;
  }

  .xl\:border-x {
    border-left-width: 1px;
    border-right-width: 1px;
  }

  .xl\:border-x-0 {
    border-left-width: 0;
    border-right-width: 0;
  }

  .xl\:border-x-2 {
    border-left-width: 2px;
    border-right-width: 2px;
  }

  .xl\:border-x-4 {
    border-left-width: 4px;
    border-right-width: 4px;
  }

  .xl\:border-x-8 {
    border-left-width: 8px;
    border-right-width: 8px;
  }

  .xl\:border-y {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .xl\:border-y-0 {
    border-top-width: 0;
    border-bottom-width: 0;
  }

  .xl\:border-y-2 {
    border-top-width: 2px;
    border-bottom-width: 2px;
  }

  .xl\:border-y-4 {
    border-top-width: 4px;
    border-bottom-width: 4px;
  }

  .xl\:border-y-8 {
    border-top-width: 8px;
    border-bottom-width: 8px;
  }

  .xl\:border-b {
    border-bottom-width: 1px;
  }

  .xl\:border-b-0 {
    border-bottom-width: 0;
  }

  .xl\:border-b-2 {
    border-bottom-width: 2px;
  }

  .xl\:border-b-4 {
    border-bottom-width: 4px;
  }

  .xl\:border-b-8 {
    border-bottom-width: 8px;
  }

  .xl\:border-e {
    border-inline-end-width: 1px;
  }

  .xl\:border-e-0 {
    border-inline-end-width: 0;
  }

  .xl\:border-e-2 {
    border-inline-end-width: 2px;
  }

  .xl\:border-e-4 {
    border-inline-end-width: 4px;
  }

  .xl\:border-e-8 {
    border-inline-end-width: 8px;
  }

  .xl\:border-l {
    border-left-width: 1px;
  }

  .xl\:border-l-0 {
    border-left-width: 0;
  }

  .xl\:border-l-2 {
    border-left-width: 2px;
  }

  .xl\:border-l-4 {
    border-left-width: 4px;
  }

  .xl\:border-l-8 {
    border-left-width: 8px;
  }

  .xl\:border-r {
    border-right-width: 1px;
  }

  .xl\:border-r-0 {
    border-right-width: 0;
  }

  .xl\:border-r-2 {
    border-right-width: 2px;
  }

  .xl\:border-r-4 {
    border-right-width: 4px;
  }

  .xl\:border-r-8 {
    border-right-width: 8px;
  }

  .xl\:border-s {
    border-inline-start-width: 1px;
  }

  .xl\:border-s-0 {
    border-inline-start-width: 0;
  }

  .xl\:border-s-2 {
    border-inline-start-width: 2px;
  }

  .xl\:border-s-4 {
    border-inline-start-width: 4px;
  }

  .xl\:border-s-8 {
    border-inline-start-width: 8px;
  }

  .xl\:border-t {
    border-top-width: 1px;
  }

  .xl\:border-t-0 {
    border-top-width: 0;
  }

  .xl\:border-t-2 {
    border-top-width: 2px;
  }

  .xl\:border-t-4 {
    border-top-width: 4px;
  }

  .xl\:border-t-8 {
    border-top-width: 8px;
  }

  .xl\:border-solid {
    border-style: solid;
  }

  .xl\:border-dashed {
    border-style: dashed;
  }

  .xl\:border-dotted {
    border-style: dotted;
  }

  .xl\:border-double {
    border-style: double;
  }

  .xl\:border-hidden {
    border-style: hidden;
  }

  .xl\:border-none {
    border-style: none;
  }

  .xl\:border-black {
    border-color: var(--color-black);
  }

  .xl\:border-current {
    border-color: currentColor;
  }

  .xl\:border-danger {
    border-color: var(--color-danger-300);
  }

  .xl\:border-danger-100 {
    border-color: var(--color-danger-100);
  }

  .xl\:border-danger-200 {
    border-color: var(--color-danger-200);
  }

  .xl\:border-danger-300 {
    border-color: var(--color-danger-300);
  }

  .xl\:border-danger-400 {
    border-color: var(--color-danger-400);
  }

  .xl\:border-divider {
    border-color: var(--color-divider);
  }

  .xl\:border-gray {
    border-color: var(--color-gray);
  }

  .xl\:border-info {
    border-color: var(--color-info-300);
  }

  .xl\:border-info-100 {
    border-color: var(--color-info-100);
  }

  .xl\:border-info-200 {
    border-color: var(--color-info-200);
  }

  .xl\:border-info-300 {
    border-color: var(--color-info-300);
  }

  .xl\:border-info-400 {
    border-color: var(--color-info-400);
  }

  .xl\:border-primary {
    border-color: var(--color-primary-300);
  }

  .xl\:border-primary-100 {
    border-color: var(--color-primary-100);
  }

  .xl\:border-primary-200 {
    border-color: var(--color-primary-200);
  }

  .xl\:border-primary-250 {
    border-color: var(--color-primary-250);
  }

  .xl\:border-primary-300 {
    border-color: var(--color-primary-300);
  }

  .xl\:border-primary-400 {
    border-color: var(--color-primary-400);
  }

  .xl\:border-primary-500 {
    border-color: var(--color-primary-500);
  }

  .xl\:border-primary-600 {
    border-color: var(--color-primary-600);
  }

  .xl\:border-success {
    border-color: var(--color-success-300);
  }

  .xl\:border-success-100 {
    border-color: var(--color-success-100);
  }

  .xl\:border-success-200 {
    border-color: var(--color-success-200);
  }

  .xl\:border-success-300 {
    border-color: var(--color-success-300);
  }

  .xl\:border-success-400 {
    border-color: var(--color-success-400);
  }

  .xl\:border-transparent, .xl\:border-transparent\/0 {
    border-color: #0000;
  }

  .xl\:border-transparent\/10 {
    border-color: #0000001a;
  }

  .xl\:border-transparent\/100 {
    border-color: #000;
  }

  .xl\:border-transparent\/15 {
    border-color: #00000026;
  }

  .xl\:border-transparent\/20 {
    border-color: #0003;
  }

  .xl\:border-transparent\/25 {
    border-color: #00000040;
  }

  .xl\:border-transparent\/30 {
    border-color: #0000004d;
  }

  .xl\:border-transparent\/35 {
    border-color: #00000059;
  }

  .xl\:border-transparent\/40 {
    border-color: #0006;
  }

  .xl\:border-transparent\/45 {
    border-color: #00000073;
  }

  .xl\:border-transparent\/5 {
    border-color: #0000000d;
  }

  .xl\:border-transparent\/50 {
    border-color: #00000080;
  }

  .xl\:border-transparent\/55 {
    border-color: #0000008c;
  }

  .xl\:border-transparent\/60 {
    border-color: #0009;
  }

  .xl\:border-transparent\/65 {
    border-color: #000000a6;
  }

  .xl\:border-transparent\/70 {
    border-color: #000000b3;
  }

  .xl\:border-transparent\/75 {
    border-color: #000000bf;
  }

  .xl\:border-transparent\/80 {
    border-color: #000c;
  }

  .xl\:border-transparent\/85 {
    border-color: #000000d9;
  }

  .xl\:border-transparent\/90 {
    border-color: #000000e6;
  }

  .xl\:border-transparent\/95 {
    border-color: #000000f2;
  }

  .xl\:border-warning {
    border-color: var(--color-warning-300);
  }

  .xl\:border-warning-100 {
    border-color: var(--color-warning-100);
  }

  .xl\:border-warning-200 {
    border-color: var(--color-warning-200);
  }

  .xl\:border-warning-300 {
    border-color: var(--color-warning-300);
  }

  .xl\:border-warning-400 {
    border-color: var(--color-warning-400);
  }

  .xl\:border-white {
    border-color: var(--color-white);
  }

  .xl\:border-x-black {
    border-left-color: var(--color-black);
    border-right-color: var(--color-black);
  }

  .xl\:border-x-current {
    border-left-color: currentColor;
    border-right-color: currentColor;
  }

  .xl\:border-x-danger {
    border-left-color: var(--color-danger-300);
    border-right-color: var(--color-danger-300);
  }

  .xl\:border-x-danger-100 {
    border-left-color: var(--color-danger-100);
    border-right-color: var(--color-danger-100);
  }

  .xl\:border-x-danger-200 {
    border-left-color: var(--color-danger-200);
    border-right-color: var(--color-danger-200);
  }

  .xl\:border-x-danger-300 {
    border-left-color: var(--color-danger-300);
    border-right-color: var(--color-danger-300);
  }

  .xl\:border-x-danger-400 {
    border-left-color: var(--color-danger-400);
    border-right-color: var(--color-danger-400);
  }

  .xl\:border-x-divider {
    border-left-color: var(--color-divider);
    border-right-color: var(--color-divider);
  }

  .xl\:border-x-gray {
    border-left-color: var(--color-gray);
    border-right-color: var(--color-gray);
  }

  .xl\:border-x-info {
    border-left-color: var(--color-info-300);
    border-right-color: var(--color-info-300);
  }

  .xl\:border-x-info-100 {
    border-left-color: var(--color-info-100);
    border-right-color: var(--color-info-100);
  }

  .xl\:border-x-info-200 {
    border-left-color: var(--color-info-200);
    border-right-color: var(--color-info-200);
  }

  .xl\:border-x-info-300 {
    border-left-color: var(--color-info-300);
    border-right-color: var(--color-info-300);
  }

  .xl\:border-x-info-400 {
    border-left-color: var(--color-info-400);
    border-right-color: var(--color-info-400);
  }

  .xl\:border-x-primary {
    border-left-color: var(--color-primary-300);
    border-right-color: var(--color-primary-300);
  }

  .xl\:border-x-primary-100 {
    border-left-color: var(--color-primary-100);
    border-right-color: var(--color-primary-100);
  }

  .xl\:border-x-primary-200 {
    border-left-color: var(--color-primary-200);
    border-right-color: var(--color-primary-200);
  }

  .xl\:border-x-primary-250 {
    border-left-color: var(--color-primary-250);
    border-right-color: var(--color-primary-250);
  }

  .xl\:border-x-primary-300 {
    border-left-color: var(--color-primary-300);
    border-right-color: var(--color-primary-300);
  }

  .xl\:border-x-primary-400 {
    border-left-color: var(--color-primary-400);
    border-right-color: var(--color-primary-400);
  }

  .xl\:border-x-primary-500 {
    border-left-color: var(--color-primary-500);
    border-right-color: var(--color-primary-500);
  }

  .xl\:border-x-primary-600 {
    border-left-color: var(--color-primary-600);
    border-right-color: var(--color-primary-600);
  }

  .xl\:border-x-success {
    border-left-color: var(--color-success-300);
    border-right-color: var(--color-success-300);
  }

  .xl\:border-x-success-100 {
    border-left-color: var(--color-success-100);
    border-right-color: var(--color-success-100);
  }

  .xl\:border-x-success-200 {
    border-left-color: var(--color-success-200);
    border-right-color: var(--color-success-200);
  }

  .xl\:border-x-success-300 {
    border-left-color: var(--color-success-300);
    border-right-color: var(--color-success-300);
  }

  .xl\:border-x-success-400 {
    border-left-color: var(--color-success-400);
    border-right-color: var(--color-success-400);
  }

  .xl\:border-x-transparent, .xl\:border-x-transparent\/0 {
    border-left-color: #0000;
    border-right-color: #0000;
  }

  .xl\:border-x-transparent\/10 {
    border-left-color: #0000001a;
    border-right-color: #0000001a;
  }

  .xl\:border-x-transparent\/100 {
    border-left-color: #000;
    border-right-color: #000;
  }

  .xl\:border-x-transparent\/15 {
    border-left-color: #00000026;
    border-right-color: #00000026;
  }

  .xl\:border-x-transparent\/20 {
    border-left-color: #0003;
    border-right-color: #0003;
  }

  .xl\:border-x-transparent\/25 {
    border-left-color: #00000040;
    border-right-color: #00000040;
  }

  .xl\:border-x-transparent\/30 {
    border-left-color: #0000004d;
    border-right-color: #0000004d;
  }

  .xl\:border-x-transparent\/35 {
    border-left-color: #00000059;
    border-right-color: #00000059;
  }

  .xl\:border-x-transparent\/40 {
    border-left-color: #0006;
    border-right-color: #0006;
  }

  .xl\:border-x-transparent\/45 {
    border-left-color: #00000073;
    border-right-color: #00000073;
  }

  .xl\:border-x-transparent\/5 {
    border-left-color: #0000000d;
    border-right-color: #0000000d;
  }

  .xl\:border-x-transparent\/50 {
    border-left-color: #00000080;
    border-right-color: #00000080;
  }

  .xl\:border-x-transparent\/55 {
    border-left-color: #0000008c;
    border-right-color: #0000008c;
  }

  .xl\:border-x-transparent\/60 {
    border-left-color: #0009;
    border-right-color: #0009;
  }

  .xl\:border-x-transparent\/65 {
    border-left-color: #000000a6;
    border-right-color: #000000a6;
  }

  .xl\:border-x-transparent\/70 {
    border-left-color: #000000b3;
    border-right-color: #000000b3;
  }

  .xl\:border-x-transparent\/75 {
    border-left-color: #000000bf;
    border-right-color: #000000bf;
  }

  .xl\:border-x-transparent\/80 {
    border-left-color: #000c;
    border-right-color: #000c;
  }

  .xl\:border-x-transparent\/85 {
    border-left-color: #000000d9;
    border-right-color: #000000d9;
  }

  .xl\:border-x-transparent\/90 {
    border-left-color: #000000e6;
    border-right-color: #000000e6;
  }

  .xl\:border-x-transparent\/95 {
    border-left-color: #000000f2;
    border-right-color: #000000f2;
  }

  .xl\:border-x-warning {
    border-left-color: var(--color-warning-300);
    border-right-color: var(--color-warning-300);
  }

  .xl\:border-x-warning-100 {
    border-left-color: var(--color-warning-100);
    border-right-color: var(--color-warning-100);
  }

  .xl\:border-x-warning-200 {
    border-left-color: var(--color-warning-200);
    border-right-color: var(--color-warning-200);
  }

  .xl\:border-x-warning-300 {
    border-left-color: var(--color-warning-300);
    border-right-color: var(--color-warning-300);
  }

  .xl\:border-x-warning-400 {
    border-left-color: var(--color-warning-400);
    border-right-color: var(--color-warning-400);
  }

  .xl\:border-x-white {
    border-left-color: var(--color-white);
    border-right-color: var(--color-white);
  }

  .xl\:border-y-black {
    border-top-color: var(--color-black);
    border-bottom-color: var(--color-black);
  }

  .xl\:border-y-current {
    border-top-color: currentColor;
    border-bottom-color: currentColor;
  }

  .xl\:border-y-danger {
    border-top-color: var(--color-danger-300);
    border-bottom-color: var(--color-danger-300);
  }

  .xl\:border-y-danger-100 {
    border-top-color: var(--color-danger-100);
    border-bottom-color: var(--color-danger-100);
  }

  .xl\:border-y-danger-200 {
    border-top-color: var(--color-danger-200);
    border-bottom-color: var(--color-danger-200);
  }

  .xl\:border-y-danger-300 {
    border-top-color: var(--color-danger-300);
    border-bottom-color: var(--color-danger-300);
  }

  .xl\:border-y-danger-400 {
    border-top-color: var(--color-danger-400);
    border-bottom-color: var(--color-danger-400);
  }

  .xl\:border-y-divider {
    border-top-color: var(--color-divider);
    border-bottom-color: var(--color-divider);
  }

  .xl\:border-y-gray {
    border-top-color: var(--color-gray);
    border-bottom-color: var(--color-gray);
  }

  .xl\:border-y-info {
    border-top-color: var(--color-info-300);
    border-bottom-color: var(--color-info-300);
  }

  .xl\:border-y-info-100 {
    border-top-color: var(--color-info-100);
    border-bottom-color: var(--color-info-100);
  }

  .xl\:border-y-info-200 {
    border-top-color: var(--color-info-200);
    border-bottom-color: var(--color-info-200);
  }

  .xl\:border-y-info-300 {
    border-top-color: var(--color-info-300);
    border-bottom-color: var(--color-info-300);
  }

  .xl\:border-y-info-400 {
    border-top-color: var(--color-info-400);
    border-bottom-color: var(--color-info-400);
  }

  .xl\:border-y-primary {
    border-top-color: var(--color-primary-300);
    border-bottom-color: var(--color-primary-300);
  }

  .xl\:border-y-primary-100 {
    border-top-color: var(--color-primary-100);
    border-bottom-color: var(--color-primary-100);
  }

  .xl\:border-y-primary-200 {
    border-top-color: var(--color-primary-200);
    border-bottom-color: var(--color-primary-200);
  }

  .xl\:border-y-primary-250 {
    border-top-color: var(--color-primary-250);
    border-bottom-color: var(--color-primary-250);
  }

  .xl\:border-y-primary-300 {
    border-top-color: var(--color-primary-300);
    border-bottom-color: var(--color-primary-300);
  }

  .xl\:border-y-primary-400 {
    border-top-color: var(--color-primary-400);
    border-bottom-color: var(--color-primary-400);
  }

  .xl\:border-y-primary-500 {
    border-top-color: var(--color-primary-500);
    border-bottom-color: var(--color-primary-500);
  }

  .xl\:border-y-primary-600 {
    border-top-color: var(--color-primary-600);
    border-bottom-color: var(--color-primary-600);
  }

  .xl\:border-y-success {
    border-top-color: var(--color-success-300);
    border-bottom-color: var(--color-success-300);
  }

  .xl\:border-y-success-100 {
    border-top-color: var(--color-success-100);
    border-bottom-color: var(--color-success-100);
  }

  .xl\:border-y-success-200 {
    border-top-color: var(--color-success-200);
    border-bottom-color: var(--color-success-200);
  }

  .xl\:border-y-success-300 {
    border-top-color: var(--color-success-300);
    border-bottom-color: var(--color-success-300);
  }

  .xl\:border-y-success-400 {
    border-top-color: var(--color-success-400);
    border-bottom-color: var(--color-success-400);
  }

  .xl\:border-y-transparent, .xl\:border-y-transparent\/0 {
    border-top-color: #0000;
    border-bottom-color: #0000;
  }

  .xl\:border-y-transparent\/10 {
    border-top-color: #0000001a;
    border-bottom-color: #0000001a;
  }

  .xl\:border-y-transparent\/100 {
    border-top-color: #000;
    border-bottom-color: #000;
  }

  .xl\:border-y-transparent\/15 {
    border-top-color: #00000026;
    border-bottom-color: #00000026;
  }

  .xl\:border-y-transparent\/20 {
    border-top-color: #0003;
    border-bottom-color: #0003;
  }

  .xl\:border-y-transparent\/25 {
    border-top-color: #00000040;
    border-bottom-color: #00000040;
  }

  .xl\:border-y-transparent\/30 {
    border-top-color: #0000004d;
    border-bottom-color: #0000004d;
  }

  .xl\:border-y-transparent\/35 {
    border-top-color: #00000059;
    border-bottom-color: #00000059;
  }

  .xl\:border-y-transparent\/40 {
    border-top-color: #0006;
    border-bottom-color: #0006;
  }

  .xl\:border-y-transparent\/45 {
    border-top-color: #00000073;
    border-bottom-color: #00000073;
  }

  .xl\:border-y-transparent\/5 {
    border-top-color: #0000000d;
    border-bottom-color: #0000000d;
  }

  .xl\:border-y-transparent\/50 {
    border-top-color: #00000080;
    border-bottom-color: #00000080;
  }

  .xl\:border-y-transparent\/55 {
    border-top-color: #0000008c;
    border-bottom-color: #0000008c;
  }

  .xl\:border-y-transparent\/60 {
    border-top-color: #0009;
    border-bottom-color: #0009;
  }

  .xl\:border-y-transparent\/65 {
    border-top-color: #000000a6;
    border-bottom-color: #000000a6;
  }

  .xl\:border-y-transparent\/70 {
    border-top-color: #000000b3;
    border-bottom-color: #000000b3;
  }

  .xl\:border-y-transparent\/75 {
    border-top-color: #000000bf;
    border-bottom-color: #000000bf;
  }

  .xl\:border-y-transparent\/80 {
    border-top-color: #000c;
    border-bottom-color: #000c;
  }

  .xl\:border-y-transparent\/85 {
    border-top-color: #000000d9;
    border-bottom-color: #000000d9;
  }

  .xl\:border-y-transparent\/90 {
    border-top-color: #000000e6;
    border-bottom-color: #000000e6;
  }

  .xl\:border-y-transparent\/95 {
    border-top-color: #000000f2;
    border-bottom-color: #000000f2;
  }

  .xl\:border-y-warning {
    border-top-color: var(--color-warning-300);
    border-bottom-color: var(--color-warning-300);
  }

  .xl\:border-y-warning-100 {
    border-top-color: var(--color-warning-100);
    border-bottom-color: var(--color-warning-100);
  }

  .xl\:border-y-warning-200 {
    border-top-color: var(--color-warning-200);
    border-bottom-color: var(--color-warning-200);
  }

  .xl\:border-y-warning-300 {
    border-top-color: var(--color-warning-300);
    border-bottom-color: var(--color-warning-300);
  }

  .xl\:border-y-warning-400 {
    border-top-color: var(--color-warning-400);
    border-bottom-color: var(--color-warning-400);
  }

  .xl\:border-y-white {
    border-top-color: var(--color-white);
    border-bottom-color: var(--color-white);
  }

  .xl\:border-b-black {
    border-bottom-color: var(--color-black);
  }

  .xl\:border-b-current {
    border-bottom-color: currentColor;
  }

  .xl\:border-b-danger {
    border-bottom-color: var(--color-danger-300);
  }

  .xl\:border-b-danger-100 {
    border-bottom-color: var(--color-danger-100);
  }

  .xl\:border-b-danger-200 {
    border-bottom-color: var(--color-danger-200);
  }

  .xl\:border-b-danger-300 {
    border-bottom-color: var(--color-danger-300);
  }

  .xl\:border-b-danger-400 {
    border-bottom-color: var(--color-danger-400);
  }

  .xl\:border-b-divider {
    border-bottom-color: var(--color-divider);
  }

  .xl\:border-b-gray {
    border-bottom-color: var(--color-gray);
  }

  .xl\:border-b-info {
    border-bottom-color: var(--color-info-300);
  }

  .xl\:border-b-info-100 {
    border-bottom-color: var(--color-info-100);
  }

  .xl\:border-b-info-200 {
    border-bottom-color: var(--color-info-200);
  }

  .xl\:border-b-info-300 {
    border-bottom-color: var(--color-info-300);
  }

  .xl\:border-b-info-400 {
    border-bottom-color: var(--color-info-400);
  }

  .xl\:border-b-primary {
    border-bottom-color: var(--color-primary-300);
  }

  .xl\:border-b-primary-100 {
    border-bottom-color: var(--color-primary-100);
  }

  .xl\:border-b-primary-200 {
    border-bottom-color: var(--color-primary-200);
  }

  .xl\:border-b-primary-250 {
    border-bottom-color: var(--color-primary-250);
  }

  .xl\:border-b-primary-300 {
    border-bottom-color: var(--color-primary-300);
  }

  .xl\:border-b-primary-400 {
    border-bottom-color: var(--color-primary-400);
  }

  .xl\:border-b-primary-500 {
    border-bottom-color: var(--color-primary-500);
  }

  .xl\:border-b-primary-600 {
    border-bottom-color: var(--color-primary-600);
  }

  .xl\:border-b-success {
    border-bottom-color: var(--color-success-300);
  }

  .xl\:border-b-success-100 {
    border-bottom-color: var(--color-success-100);
  }

  .xl\:border-b-success-200 {
    border-bottom-color: var(--color-success-200);
  }

  .xl\:border-b-success-300 {
    border-bottom-color: var(--color-success-300);
  }

  .xl\:border-b-success-400 {
    border-bottom-color: var(--color-success-400);
  }

  .xl\:border-b-transparent, .xl\:border-b-transparent\/0 {
    border-bottom-color: #0000;
  }

  .xl\:border-b-transparent\/10 {
    border-bottom-color: #0000001a;
  }

  .xl\:border-b-transparent\/100 {
    border-bottom-color: #000;
  }

  .xl\:border-b-transparent\/15 {
    border-bottom-color: #00000026;
  }

  .xl\:border-b-transparent\/20 {
    border-bottom-color: #0003;
  }

  .xl\:border-b-transparent\/25 {
    border-bottom-color: #00000040;
  }

  .xl\:border-b-transparent\/30 {
    border-bottom-color: #0000004d;
  }

  .xl\:border-b-transparent\/35 {
    border-bottom-color: #00000059;
  }

  .xl\:border-b-transparent\/40 {
    border-bottom-color: #0006;
  }

  .xl\:border-b-transparent\/45 {
    border-bottom-color: #00000073;
  }

  .xl\:border-b-transparent\/5 {
    border-bottom-color: #0000000d;
  }

  .xl\:border-b-transparent\/50 {
    border-bottom-color: #00000080;
  }

  .xl\:border-b-transparent\/55 {
    border-bottom-color: #0000008c;
  }

  .xl\:border-b-transparent\/60 {
    border-bottom-color: #0009;
  }

  .xl\:border-b-transparent\/65 {
    border-bottom-color: #000000a6;
  }

  .xl\:border-b-transparent\/70 {
    border-bottom-color: #000000b3;
  }

  .xl\:border-b-transparent\/75 {
    border-bottom-color: #000000bf;
  }

  .xl\:border-b-transparent\/80 {
    border-bottom-color: #000c;
  }

  .xl\:border-b-transparent\/85 {
    border-bottom-color: #000000d9;
  }

  .xl\:border-b-transparent\/90 {
    border-bottom-color: #000000e6;
  }

  .xl\:border-b-transparent\/95 {
    border-bottom-color: #000000f2;
  }

  .xl\:border-b-warning {
    border-bottom-color: var(--color-warning-300);
  }

  .xl\:border-b-warning-100 {
    border-bottom-color: var(--color-warning-100);
  }

  .xl\:border-b-warning-200 {
    border-bottom-color: var(--color-warning-200);
  }

  .xl\:border-b-warning-300 {
    border-bottom-color: var(--color-warning-300);
  }

  .xl\:border-b-warning-400 {
    border-bottom-color: var(--color-warning-400);
  }

  .xl\:border-b-white {
    border-bottom-color: var(--color-white);
  }

  .xl\:border-e-black {
    border-inline-end-color: var(--color-black);
  }

  .xl\:border-e-current {
    border-inline-end-color: currentColor;
  }

  .xl\:border-e-danger {
    border-inline-end-color: var(--color-danger-300);
  }

  .xl\:border-e-danger-100 {
    border-inline-end-color: var(--color-danger-100);
  }

  .xl\:border-e-danger-200 {
    border-inline-end-color: var(--color-danger-200);
  }

  .xl\:border-e-danger-300 {
    border-inline-end-color: var(--color-danger-300);
  }

  .xl\:border-e-danger-400 {
    border-inline-end-color: var(--color-danger-400);
  }

  .xl\:border-e-divider {
    border-inline-end-color: var(--color-divider);
  }

  .xl\:border-e-gray {
    border-inline-end-color: var(--color-gray);
  }

  .xl\:border-e-info {
    border-inline-end-color: var(--color-info-300);
  }

  .xl\:border-e-info-100 {
    border-inline-end-color: var(--color-info-100);
  }

  .xl\:border-e-info-200 {
    border-inline-end-color: var(--color-info-200);
  }

  .xl\:border-e-info-300 {
    border-inline-end-color: var(--color-info-300);
  }

  .xl\:border-e-info-400 {
    border-inline-end-color: var(--color-info-400);
  }

  .xl\:border-e-primary {
    border-inline-end-color: var(--color-primary-300);
  }

  .xl\:border-e-primary-100 {
    border-inline-end-color: var(--color-primary-100);
  }

  .xl\:border-e-primary-200 {
    border-inline-end-color: var(--color-primary-200);
  }

  .xl\:border-e-primary-250 {
    border-inline-end-color: var(--color-primary-250);
  }

  .xl\:border-e-primary-300 {
    border-inline-end-color: var(--color-primary-300);
  }

  .xl\:border-e-primary-400 {
    border-inline-end-color: var(--color-primary-400);
  }

  .xl\:border-e-primary-500 {
    border-inline-end-color: var(--color-primary-500);
  }

  .xl\:border-e-primary-600 {
    border-inline-end-color: var(--color-primary-600);
  }

  .xl\:border-e-success {
    border-inline-end-color: var(--color-success-300);
  }

  .xl\:border-e-success-100 {
    border-inline-end-color: var(--color-success-100);
  }

  .xl\:border-e-success-200 {
    border-inline-end-color: var(--color-success-200);
  }

  .xl\:border-e-success-300 {
    border-inline-end-color: var(--color-success-300);
  }

  .xl\:border-e-success-400 {
    border-inline-end-color: var(--color-success-400);
  }

  .xl\:border-e-transparent, .xl\:border-e-transparent\/0 {
    border-inline-end-color: #0000;
  }

  .xl\:border-e-transparent\/10 {
    border-inline-end-color: #0000001a;
  }

  .xl\:border-e-transparent\/100 {
    border-inline-end-color: #000;
  }

  .xl\:border-e-transparent\/15 {
    border-inline-end-color: #00000026;
  }

  .xl\:border-e-transparent\/20 {
    border-inline-end-color: #0003;
  }

  .xl\:border-e-transparent\/25 {
    border-inline-end-color: #00000040;
  }

  .xl\:border-e-transparent\/30 {
    border-inline-end-color: #0000004d;
  }

  .xl\:border-e-transparent\/35 {
    border-inline-end-color: #00000059;
  }

  .xl\:border-e-transparent\/40 {
    border-inline-end-color: #0006;
  }

  .xl\:border-e-transparent\/45 {
    border-inline-end-color: #00000073;
  }

  .xl\:border-e-transparent\/5 {
    border-inline-end-color: #0000000d;
  }

  .xl\:border-e-transparent\/50 {
    border-inline-end-color: #00000080;
  }

  .xl\:border-e-transparent\/55 {
    border-inline-end-color: #0000008c;
  }

  .xl\:border-e-transparent\/60 {
    border-inline-end-color: #0009;
  }

  .xl\:border-e-transparent\/65 {
    border-inline-end-color: #000000a6;
  }

  .xl\:border-e-transparent\/70 {
    border-inline-end-color: #000000b3;
  }

  .xl\:border-e-transparent\/75 {
    border-inline-end-color: #000000bf;
  }

  .xl\:border-e-transparent\/80 {
    border-inline-end-color: #000c;
  }

  .xl\:border-e-transparent\/85 {
    border-inline-end-color: #000000d9;
  }

  .xl\:border-e-transparent\/90 {
    border-inline-end-color: #000000e6;
  }

  .xl\:border-e-transparent\/95 {
    border-inline-end-color: #000000f2;
  }

  .xl\:border-e-warning {
    border-inline-end-color: var(--color-warning-300);
  }

  .xl\:border-e-warning-100 {
    border-inline-end-color: var(--color-warning-100);
  }

  .xl\:border-e-warning-200 {
    border-inline-end-color: var(--color-warning-200);
  }

  .xl\:border-e-warning-300 {
    border-inline-end-color: var(--color-warning-300);
  }

  .xl\:border-e-warning-400 {
    border-inline-end-color: var(--color-warning-400);
  }

  .xl\:border-e-white {
    border-inline-end-color: var(--color-white);
  }

  .xl\:border-l-black {
    border-left-color: var(--color-black);
  }

  .xl\:border-l-current {
    border-left-color: currentColor;
  }

  .xl\:border-l-danger {
    border-left-color: var(--color-danger-300);
  }

  .xl\:border-l-danger-100 {
    border-left-color: var(--color-danger-100);
  }

  .xl\:border-l-danger-200 {
    border-left-color: var(--color-danger-200);
  }

  .xl\:border-l-danger-300 {
    border-left-color: var(--color-danger-300);
  }

  .xl\:border-l-danger-400 {
    border-left-color: var(--color-danger-400);
  }

  .xl\:border-l-divider {
    border-left-color: var(--color-divider);
  }

  .xl\:border-l-gray {
    border-left-color: var(--color-gray);
  }

  .xl\:border-l-info {
    border-left-color: var(--color-info-300);
  }

  .xl\:border-l-info-100 {
    border-left-color: var(--color-info-100);
  }

  .xl\:border-l-info-200 {
    border-left-color: var(--color-info-200);
  }

  .xl\:border-l-info-300 {
    border-left-color: var(--color-info-300);
  }

  .xl\:border-l-info-400 {
    border-left-color: var(--color-info-400);
  }

  .xl\:border-l-primary {
    border-left-color: var(--color-primary-300);
  }

  .xl\:border-l-primary-100 {
    border-left-color: var(--color-primary-100);
  }

  .xl\:border-l-primary-200 {
    border-left-color: var(--color-primary-200);
  }

  .xl\:border-l-primary-250 {
    border-left-color: var(--color-primary-250);
  }

  .xl\:border-l-primary-300 {
    border-left-color: var(--color-primary-300);
  }

  .xl\:border-l-primary-400 {
    border-left-color: var(--color-primary-400);
  }

  .xl\:border-l-primary-500 {
    border-left-color: var(--color-primary-500);
  }

  .xl\:border-l-primary-600 {
    border-left-color: var(--color-primary-600);
  }

  .xl\:border-l-success {
    border-left-color: var(--color-success-300);
  }

  .xl\:border-l-success-100 {
    border-left-color: var(--color-success-100);
  }

  .xl\:border-l-success-200 {
    border-left-color: var(--color-success-200);
  }

  .xl\:border-l-success-300 {
    border-left-color: var(--color-success-300);
  }

  .xl\:border-l-success-400 {
    border-left-color: var(--color-success-400);
  }

  .xl\:border-l-transparent, .xl\:border-l-transparent\/0 {
    border-left-color: #0000;
  }

  .xl\:border-l-transparent\/10 {
    border-left-color: #0000001a;
  }

  .xl\:border-l-transparent\/100 {
    border-left-color: #000;
  }

  .xl\:border-l-transparent\/15 {
    border-left-color: #00000026;
  }

  .xl\:border-l-transparent\/20 {
    border-left-color: #0003;
  }

  .xl\:border-l-transparent\/25 {
    border-left-color: #00000040;
  }

  .xl\:border-l-transparent\/30 {
    border-left-color: #0000004d;
  }

  .xl\:border-l-transparent\/35 {
    border-left-color: #00000059;
  }

  .xl\:border-l-transparent\/40 {
    border-left-color: #0006;
  }

  .xl\:border-l-transparent\/45 {
    border-left-color: #00000073;
  }

  .xl\:border-l-transparent\/5 {
    border-left-color: #0000000d;
  }

  .xl\:border-l-transparent\/50 {
    border-left-color: #00000080;
  }

  .xl\:border-l-transparent\/55 {
    border-left-color: #0000008c;
  }

  .xl\:border-l-transparent\/60 {
    border-left-color: #0009;
  }

  .xl\:border-l-transparent\/65 {
    border-left-color: #000000a6;
  }

  .xl\:border-l-transparent\/70 {
    border-left-color: #000000b3;
  }

  .xl\:border-l-transparent\/75 {
    border-left-color: #000000bf;
  }

  .xl\:border-l-transparent\/80 {
    border-left-color: #000c;
  }

  .xl\:border-l-transparent\/85 {
    border-left-color: #000000d9;
  }

  .xl\:border-l-transparent\/90 {
    border-left-color: #000000e6;
  }

  .xl\:border-l-transparent\/95 {
    border-left-color: #000000f2;
  }

  .xl\:border-l-warning {
    border-left-color: var(--color-warning-300);
  }

  .xl\:border-l-warning-100 {
    border-left-color: var(--color-warning-100);
  }

  .xl\:border-l-warning-200 {
    border-left-color: var(--color-warning-200);
  }

  .xl\:border-l-warning-300 {
    border-left-color: var(--color-warning-300);
  }

  .xl\:border-l-warning-400 {
    border-left-color: var(--color-warning-400);
  }

  .xl\:border-l-white {
    border-left-color: var(--color-white);
  }

  .xl\:border-r-black {
    border-right-color: var(--color-black);
  }

  .xl\:border-r-current {
    border-right-color: currentColor;
  }

  .xl\:border-r-danger {
    border-right-color: var(--color-danger-300);
  }

  .xl\:border-r-danger-100 {
    border-right-color: var(--color-danger-100);
  }

  .xl\:border-r-danger-200 {
    border-right-color: var(--color-danger-200);
  }

  .xl\:border-r-danger-300 {
    border-right-color: var(--color-danger-300);
  }

  .xl\:border-r-danger-400 {
    border-right-color: var(--color-danger-400);
  }

  .xl\:border-r-divider {
    border-right-color: var(--color-divider);
  }

  .xl\:border-r-gray {
    border-right-color: var(--color-gray);
  }

  .xl\:border-r-info {
    border-right-color: var(--color-info-300);
  }

  .xl\:border-r-info-100 {
    border-right-color: var(--color-info-100);
  }

  .xl\:border-r-info-200 {
    border-right-color: var(--color-info-200);
  }

  .xl\:border-r-info-300 {
    border-right-color: var(--color-info-300);
  }

  .xl\:border-r-info-400 {
    border-right-color: var(--color-info-400);
  }

  .xl\:border-r-primary {
    border-right-color: var(--color-primary-300);
  }

  .xl\:border-r-primary-100 {
    border-right-color: var(--color-primary-100);
  }

  .xl\:border-r-primary-200 {
    border-right-color: var(--color-primary-200);
  }

  .xl\:border-r-primary-250 {
    border-right-color: var(--color-primary-250);
  }

  .xl\:border-r-primary-300 {
    border-right-color: var(--color-primary-300);
  }

  .xl\:border-r-primary-400 {
    border-right-color: var(--color-primary-400);
  }

  .xl\:border-r-primary-500 {
    border-right-color: var(--color-primary-500);
  }

  .xl\:border-r-primary-600 {
    border-right-color: var(--color-primary-600);
  }

  .xl\:border-r-success {
    border-right-color: var(--color-success-300);
  }

  .xl\:border-r-success-100 {
    border-right-color: var(--color-success-100);
  }

  .xl\:border-r-success-200 {
    border-right-color: var(--color-success-200);
  }

  .xl\:border-r-success-300 {
    border-right-color: var(--color-success-300);
  }

  .xl\:border-r-success-400 {
    border-right-color: var(--color-success-400);
  }

  .xl\:border-r-transparent, .xl\:border-r-transparent\/0 {
    border-right-color: #0000;
  }

  .xl\:border-r-transparent\/10 {
    border-right-color: #0000001a;
  }

  .xl\:border-r-transparent\/100 {
    border-right-color: #000;
  }

  .xl\:border-r-transparent\/15 {
    border-right-color: #00000026;
  }

  .xl\:border-r-transparent\/20 {
    border-right-color: #0003;
  }

  .xl\:border-r-transparent\/25 {
    border-right-color: #00000040;
  }

  .xl\:border-r-transparent\/30 {
    border-right-color: #0000004d;
  }

  .xl\:border-r-transparent\/35 {
    border-right-color: #00000059;
  }

  .xl\:border-r-transparent\/40 {
    border-right-color: #0006;
  }

  .xl\:border-r-transparent\/45 {
    border-right-color: #00000073;
  }

  .xl\:border-r-transparent\/5 {
    border-right-color: #0000000d;
  }

  .xl\:border-r-transparent\/50 {
    border-right-color: #00000080;
  }

  .xl\:border-r-transparent\/55 {
    border-right-color: #0000008c;
  }

  .xl\:border-r-transparent\/60 {
    border-right-color: #0009;
  }

  .xl\:border-r-transparent\/65 {
    border-right-color: #000000a6;
  }

  .xl\:border-r-transparent\/70 {
    border-right-color: #000000b3;
  }

  .xl\:border-r-transparent\/75 {
    border-right-color: #000000bf;
  }

  .xl\:border-r-transparent\/80 {
    border-right-color: #000c;
  }

  .xl\:border-r-transparent\/85 {
    border-right-color: #000000d9;
  }

  .xl\:border-r-transparent\/90 {
    border-right-color: #000000e6;
  }

  .xl\:border-r-transparent\/95 {
    border-right-color: #000000f2;
  }

  .xl\:border-r-warning {
    border-right-color: var(--color-warning-300);
  }

  .xl\:border-r-warning-100 {
    border-right-color: var(--color-warning-100);
  }

  .xl\:border-r-warning-200 {
    border-right-color: var(--color-warning-200);
  }

  .xl\:border-r-warning-300 {
    border-right-color: var(--color-warning-300);
  }

  .xl\:border-r-warning-400 {
    border-right-color: var(--color-warning-400);
  }

  .xl\:border-r-white {
    border-right-color: var(--color-white);
  }

  .xl\:border-s-black {
    border-inline-start-color: var(--color-black);
  }

  .xl\:border-s-current {
    border-inline-start-color: currentColor;
  }

  .xl\:border-s-danger {
    border-inline-start-color: var(--color-danger-300);
  }

  .xl\:border-s-danger-100 {
    border-inline-start-color: var(--color-danger-100);
  }

  .xl\:border-s-danger-200 {
    border-inline-start-color: var(--color-danger-200);
  }

  .xl\:border-s-danger-300 {
    border-inline-start-color: var(--color-danger-300);
  }

  .xl\:border-s-danger-400 {
    border-inline-start-color: var(--color-danger-400);
  }

  .xl\:border-s-divider {
    border-inline-start-color: var(--color-divider);
  }

  .xl\:border-s-gray {
    border-inline-start-color: var(--color-gray);
  }

  .xl\:border-s-info {
    border-inline-start-color: var(--color-info-300);
  }

  .xl\:border-s-info-100 {
    border-inline-start-color: var(--color-info-100);
  }

  .xl\:border-s-info-200 {
    border-inline-start-color: var(--color-info-200);
  }

  .xl\:border-s-info-300 {
    border-inline-start-color: var(--color-info-300);
  }

  .xl\:border-s-info-400 {
    border-inline-start-color: var(--color-info-400);
  }

  .xl\:border-s-primary {
    border-inline-start-color: var(--color-primary-300);
  }

  .xl\:border-s-primary-100 {
    border-inline-start-color: var(--color-primary-100);
  }

  .xl\:border-s-primary-200 {
    border-inline-start-color: var(--color-primary-200);
  }

  .xl\:border-s-primary-250 {
    border-inline-start-color: var(--color-primary-250);
  }

  .xl\:border-s-primary-300 {
    border-inline-start-color: var(--color-primary-300);
  }

  .xl\:border-s-primary-400 {
    border-inline-start-color: var(--color-primary-400);
  }

  .xl\:border-s-primary-500 {
    border-inline-start-color: var(--color-primary-500);
  }

  .xl\:border-s-primary-600 {
    border-inline-start-color: var(--color-primary-600);
  }

  .xl\:border-s-success {
    border-inline-start-color: var(--color-success-300);
  }

  .xl\:border-s-success-100 {
    border-inline-start-color: var(--color-success-100);
  }

  .xl\:border-s-success-200 {
    border-inline-start-color: var(--color-success-200);
  }

  .xl\:border-s-success-300 {
    border-inline-start-color: var(--color-success-300);
  }

  .xl\:border-s-success-400 {
    border-inline-start-color: var(--color-success-400);
  }

  .xl\:border-s-transparent, .xl\:border-s-transparent\/0 {
    border-inline-start-color: #0000;
  }

  .xl\:border-s-transparent\/10 {
    border-inline-start-color: #0000001a;
  }

  .xl\:border-s-transparent\/100 {
    border-inline-start-color: #000;
  }

  .xl\:border-s-transparent\/15 {
    border-inline-start-color: #00000026;
  }

  .xl\:border-s-transparent\/20 {
    border-inline-start-color: #0003;
  }

  .xl\:border-s-transparent\/25 {
    border-inline-start-color: #00000040;
  }

  .xl\:border-s-transparent\/30 {
    border-inline-start-color: #0000004d;
  }

  .xl\:border-s-transparent\/35 {
    border-inline-start-color: #00000059;
  }

  .xl\:border-s-transparent\/40 {
    border-inline-start-color: #0006;
  }

  .xl\:border-s-transparent\/45 {
    border-inline-start-color: #00000073;
  }

  .xl\:border-s-transparent\/5 {
    border-inline-start-color: #0000000d;
  }

  .xl\:border-s-transparent\/50 {
    border-inline-start-color: #00000080;
  }

  .xl\:border-s-transparent\/55 {
    border-inline-start-color: #0000008c;
  }

  .xl\:border-s-transparent\/60 {
    border-inline-start-color: #0009;
  }

  .xl\:border-s-transparent\/65 {
    border-inline-start-color: #000000a6;
  }

  .xl\:border-s-transparent\/70 {
    border-inline-start-color: #000000b3;
  }

  .xl\:border-s-transparent\/75 {
    border-inline-start-color: #000000bf;
  }

  .xl\:border-s-transparent\/80 {
    border-inline-start-color: #000c;
  }

  .xl\:border-s-transparent\/85 {
    border-inline-start-color: #000000d9;
  }

  .xl\:border-s-transparent\/90 {
    border-inline-start-color: #000000e6;
  }

  .xl\:border-s-transparent\/95 {
    border-inline-start-color: #000000f2;
  }

  .xl\:border-s-warning {
    border-inline-start-color: var(--color-warning-300);
  }

  .xl\:border-s-warning-100 {
    border-inline-start-color: var(--color-warning-100);
  }

  .xl\:border-s-warning-200 {
    border-inline-start-color: var(--color-warning-200);
  }

  .xl\:border-s-warning-300 {
    border-inline-start-color: var(--color-warning-300);
  }

  .xl\:border-s-warning-400 {
    border-inline-start-color: var(--color-warning-400);
  }

  .xl\:border-s-white {
    border-inline-start-color: var(--color-white);
  }

  .xl\:border-t-black {
    border-top-color: var(--color-black);
  }

  .xl\:border-t-current {
    border-top-color: currentColor;
  }

  .xl\:border-t-danger {
    border-top-color: var(--color-danger-300);
  }

  .xl\:border-t-danger-100 {
    border-top-color: var(--color-danger-100);
  }

  .xl\:border-t-danger-200 {
    border-top-color: var(--color-danger-200);
  }

  .xl\:border-t-danger-300 {
    border-top-color: var(--color-danger-300);
  }

  .xl\:border-t-danger-400 {
    border-top-color: var(--color-danger-400);
  }

  .xl\:border-t-divider {
    border-top-color: var(--color-divider);
  }

  .xl\:border-t-gray {
    border-top-color: var(--color-gray);
  }

  .xl\:border-t-info {
    border-top-color: var(--color-info-300);
  }

  .xl\:border-t-info-100 {
    border-top-color: var(--color-info-100);
  }

  .xl\:border-t-info-200 {
    border-top-color: var(--color-info-200);
  }

  .xl\:border-t-info-300 {
    border-top-color: var(--color-info-300);
  }

  .xl\:border-t-info-400 {
    border-top-color: var(--color-info-400);
  }

  .xl\:border-t-primary {
    border-top-color: var(--color-primary-300);
  }

  .xl\:border-t-primary-100 {
    border-top-color: var(--color-primary-100);
  }

  .xl\:border-t-primary-200 {
    border-top-color: var(--color-primary-200);
  }

  .xl\:border-t-primary-250 {
    border-top-color: var(--color-primary-250);
  }

  .xl\:border-t-primary-300 {
    border-top-color: var(--color-primary-300);
  }

  .xl\:border-t-primary-400 {
    border-top-color: var(--color-primary-400);
  }

  .xl\:border-t-primary-500 {
    border-top-color: var(--color-primary-500);
  }

  .xl\:border-t-primary-600 {
    border-top-color: var(--color-primary-600);
  }

  .xl\:border-t-success {
    border-top-color: var(--color-success-300);
  }

  .xl\:border-t-success-100 {
    border-top-color: var(--color-success-100);
  }

  .xl\:border-t-success-200 {
    border-top-color: var(--color-success-200);
  }

  .xl\:border-t-success-300 {
    border-top-color: var(--color-success-300);
  }

  .xl\:border-t-success-400 {
    border-top-color: var(--color-success-400);
  }

  .xl\:border-t-transparent, .xl\:border-t-transparent\/0 {
    border-top-color: #0000;
  }

  .xl\:border-t-transparent\/10 {
    border-top-color: #0000001a;
  }

  .xl\:border-t-transparent\/100 {
    border-top-color: #000;
  }

  .xl\:border-t-transparent\/15 {
    border-top-color: #00000026;
  }

  .xl\:border-t-transparent\/20 {
    border-top-color: #0003;
  }

  .xl\:border-t-transparent\/25 {
    border-top-color: #00000040;
  }

  .xl\:border-t-transparent\/30 {
    border-top-color: #0000004d;
  }

  .xl\:border-t-transparent\/35 {
    border-top-color: #00000059;
  }

  .xl\:border-t-transparent\/40 {
    border-top-color: #0006;
  }

  .xl\:border-t-transparent\/45 {
    border-top-color: #00000073;
  }

  .xl\:border-t-transparent\/5 {
    border-top-color: #0000000d;
  }

  .xl\:border-t-transparent\/50 {
    border-top-color: #00000080;
  }

  .xl\:border-t-transparent\/55 {
    border-top-color: #0000008c;
  }

  .xl\:border-t-transparent\/60 {
    border-top-color: #0009;
  }

  .xl\:border-t-transparent\/65 {
    border-top-color: #000000a6;
  }

  .xl\:border-t-transparent\/70 {
    border-top-color: #000000b3;
  }

  .xl\:border-t-transparent\/75 {
    border-top-color: #000000bf;
  }

  .xl\:border-t-transparent\/80 {
    border-top-color: #000c;
  }

  .xl\:border-t-transparent\/85 {
    border-top-color: #000000d9;
  }

  .xl\:border-t-transparent\/90 {
    border-top-color: #000000e6;
  }

  .xl\:border-t-transparent\/95 {
    border-top-color: #000000f2;
  }

  .xl\:border-t-warning {
    border-top-color: var(--color-warning-300);
  }

  .xl\:border-t-warning-100 {
    border-top-color: var(--color-warning-100);
  }

  .xl\:border-t-warning-200 {
    border-top-color: var(--color-warning-200);
  }

  .xl\:border-t-warning-300 {
    border-top-color: var(--color-warning-300);
  }

  .xl\:border-t-warning-400 {
    border-top-color: var(--color-warning-400);
  }

  .xl\:border-t-white {
    border-top-color: var(--color-white);
  }

  .xl\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .xl\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .xl\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .xl\:border-opacity-15 {
    --tw-border-opacity: .15;
  }

  .xl\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .xl\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .xl\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .xl\:border-opacity-35 {
    --tw-border-opacity: .35;
  }

  .xl\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .xl\:border-opacity-45 {
    --tw-border-opacity: .45;
  }

  .xl\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .xl\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .xl\:border-opacity-55 {
    --tw-border-opacity: .55;
  }

  .xl\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .xl\:border-opacity-65 {
    --tw-border-opacity: .65;
  }

  .xl\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .xl\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .xl\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .xl\:border-opacity-85 {
    --tw-border-opacity: .85;
  }

  .xl\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .xl\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .xl\:bg-black {
    background-color: var(--color-black);
  }

  .xl\:bg-current {
    background-color: currentColor;
  }

  .xl\:bg-danger {
    background-color: var(--color-danger-300);
  }

  .xl\:bg-danger-100 {
    background-color: var(--color-danger-100);
  }

  .xl\:bg-danger-200 {
    background-color: var(--color-danger-200);
  }

  .xl\:bg-danger-300 {
    background-color: var(--color-danger-300);
  }

  .xl\:bg-danger-400 {
    background-color: var(--color-danger-400);
  }

  .xl\:bg-divider {
    background-color: var(--color-divider);
  }

  .xl\:bg-gray {
    background-color: var(--color-gray);
  }

  .xl\:bg-info {
    background-color: var(--color-info-300);
  }

  .xl\:bg-info-100 {
    background-color: var(--color-info-100);
  }

  .xl\:bg-info-200 {
    background-color: var(--color-info-200);
  }

  .xl\:bg-info-300 {
    background-color: var(--color-info-300);
  }

  .xl\:bg-info-400 {
    background-color: var(--color-info-400);
  }

  .xl\:bg-primary {
    background-color: var(--color-primary-300);
  }

  .xl\:bg-primary-100 {
    background-color: var(--color-primary-100);
  }

  .xl\:bg-primary-200 {
    background-color: var(--color-primary-200);
  }

  .xl\:bg-primary-250 {
    background-color: var(--color-primary-250);
  }

  .xl\:bg-primary-300 {
    background-color: var(--color-primary-300);
  }

  .xl\:bg-primary-400 {
    background-color: var(--color-primary-400);
  }

  .xl\:bg-primary-500 {
    background-color: var(--color-primary-500);
  }

  .xl\:bg-primary-600 {
    background-color: var(--color-primary-600);
  }

  .xl\:bg-success {
    background-color: var(--color-success-300);
  }

  .xl\:bg-success-100 {
    background-color: var(--color-success-100);
  }

  .xl\:bg-success-200 {
    background-color: var(--color-success-200);
  }

  .xl\:bg-success-300 {
    background-color: var(--color-success-300);
  }

  .xl\:bg-success-400 {
    background-color: var(--color-success-400);
  }

  .xl\:bg-transparent, .xl\:bg-transparent\/0 {
    background-color: #0000;
  }

  .xl\:bg-transparent\/10 {
    background-color: #0000001a;
  }

  .xl\:bg-transparent\/100 {
    background-color: #000;
  }

  .xl\:bg-transparent\/15 {
    background-color: #00000026;
  }

  .xl\:bg-transparent\/20 {
    background-color: #0003;
  }

  .xl\:bg-transparent\/25 {
    background-color: #00000040;
  }

  .xl\:bg-transparent\/30 {
    background-color: #0000004d;
  }

  .xl\:bg-transparent\/35 {
    background-color: #00000059;
  }

  .xl\:bg-transparent\/40 {
    background-color: #0006;
  }

  .xl\:bg-transparent\/45 {
    background-color: #00000073;
  }

  .xl\:bg-transparent\/5 {
    background-color: #0000000d;
  }

  .xl\:bg-transparent\/50 {
    background-color: #00000080;
  }

  .xl\:bg-transparent\/55 {
    background-color: #0000008c;
  }

  .xl\:bg-transparent\/60 {
    background-color: #0009;
  }

  .xl\:bg-transparent\/65 {
    background-color: #000000a6;
  }

  .xl\:bg-transparent\/70 {
    background-color: #000000b3;
  }

  .xl\:bg-transparent\/75 {
    background-color: #000000bf;
  }

  .xl\:bg-transparent\/80 {
    background-color: #000c;
  }

  .xl\:bg-transparent\/85 {
    background-color: #000000d9;
  }

  .xl\:bg-transparent\/90 {
    background-color: #000000e6;
  }

  .xl\:bg-transparent\/95 {
    background-color: #000000f2;
  }

  .xl\:bg-warning {
    background-color: var(--color-warning-300);
  }

  .xl\:bg-warning-100 {
    background-color: var(--color-warning-100);
  }

  .xl\:bg-warning-200 {
    background-color: var(--color-warning-200);
  }

  .xl\:bg-warning-300 {
    background-color: var(--color-warning-300);
  }

  .xl\:bg-warning-400 {
    background-color: var(--color-warning-400);
  }

  .xl\:bg-white {
    background-color: var(--color-white);
  }

  .xl\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .xl\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .xl\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .xl\:bg-opacity-15 {
    --tw-bg-opacity: .15;
  }

  .xl\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .xl\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .xl\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .xl\:bg-opacity-35 {
    --tw-bg-opacity: .35;
  }

  .xl\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .xl\:bg-opacity-45 {
    --tw-bg-opacity: .45;
  }

  .xl\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .xl\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .xl\:bg-opacity-55 {
    --tw-bg-opacity: .55;
  }

  .xl\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .xl\:bg-opacity-65 {
    --tw-bg-opacity: .65;
  }

  .xl\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .xl\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .xl\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .xl\:bg-opacity-85 {
    --tw-bg-opacity: .85;
  }

  .xl\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .xl\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .xl\:bg-none {
    background-image: none;
  }

  .xl\:from-black {
    --tw-gradient-from: var(--color-black) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-current {
    --tw-gradient-from: currentColor var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-danger {
    --tw-gradient-from: var(--color-danger-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-danger-100 {
    --tw-gradient-from: var(--color-danger-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-danger-200 {
    --tw-gradient-from: var(--color-danger-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-danger-300 {
    --tw-gradient-from: var(--color-danger-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-danger-400 {
    --tw-gradient-from: var(--color-danger-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-divider {
    --tw-gradient-from: var(--color-divider) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-gray {
    --tw-gradient-from: var(--color-gray) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-info {
    --tw-gradient-from: var(--color-info-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-info-100 {
    --tw-gradient-from: var(--color-info-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-info-200 {
    --tw-gradient-from: var(--color-info-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-info-300 {
    --tw-gradient-from: var(--color-info-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-info-400 {
    --tw-gradient-from: var(--color-info-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-primary {
    --tw-gradient-from: var(--color-primary-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-primary-100 {
    --tw-gradient-from: var(--color-primary-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-primary-200 {
    --tw-gradient-from: var(--color-primary-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-primary-250 {
    --tw-gradient-from: var(--color-primary-250) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-primary-300 {
    --tw-gradient-from: var(--color-primary-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-primary-400 {
    --tw-gradient-from: var(--color-primary-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-primary-500 {
    --tw-gradient-from: var(--color-primary-500) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-primary-600 {
    --tw-gradient-from: var(--color-primary-600) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-success {
    --tw-gradient-from: var(--color-success-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-success-100 {
    --tw-gradient-from: var(--color-success-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-success-200 {
    --tw-gradient-from: var(--color-success-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-success-300 {
    --tw-gradient-from: var(--color-success-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-success-400 {
    --tw-gradient-from: var(--color-success-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent {
    --tw-gradient-from: transparent var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/0 {
    --tw-gradient-from: #0000 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/10 {
    --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/100 {
    --tw-gradient-from: #000 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/15 {
    --tw-gradient-from: #00000026 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/20 {
    --tw-gradient-from: #0003 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/25 {
    --tw-gradient-from: #00000040 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/30 {
    --tw-gradient-from: #0000004d var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/35 {
    --tw-gradient-from: #00000059 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/40 {
    --tw-gradient-from: #0006 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/45 {
    --tw-gradient-from: #00000073 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/5 {
    --tw-gradient-from: #0000000d var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/50 {
    --tw-gradient-from: #00000080 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/55 {
    --tw-gradient-from: #0000008c var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/60 {
    --tw-gradient-from: #0009 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/65 {
    --tw-gradient-from: #000000a6 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/70 {
    --tw-gradient-from: #000000b3 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/75 {
    --tw-gradient-from: #000000bf var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/80 {
    --tw-gradient-from: #000c var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/85 {
    --tw-gradient-from: #000000d9 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/90 {
    --tw-gradient-from: #000000e6 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-transparent\/95 {
    --tw-gradient-from: #000000f2 var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-warning {
    --tw-gradient-from: var(--color-warning-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-warning-100 {
    --tw-gradient-from: var(--color-warning-100) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-warning-200 {
    --tw-gradient-from: var(--color-warning-200) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-warning-300 {
    --tw-gradient-from: var(--color-warning-300) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-warning-400 {
    --tw-gradient-from: var(--color-warning-400) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-white {
    --tw-gradient-from: var(--color-white) var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .xl\:from-0\% {
    --tw-gradient-from-position: 0%;
  }

  .xl\:from-10\% {
    --tw-gradient-from-position: 10%;
  }

  .xl\:from-100\% {
    --tw-gradient-from-position: 100%;
  }

  .xl\:from-15\% {
    --tw-gradient-from-position: 15%;
  }

  .xl\:from-20\% {
    --tw-gradient-from-position: 20%;
  }

  .xl\:from-25\% {
    --tw-gradient-from-position: 25%;
  }

  .xl\:from-30\% {
    --tw-gradient-from-position: 30%;
  }

  .xl\:from-35\% {
    --tw-gradient-from-position: 35%;
  }

  .xl\:from-40\% {
    --tw-gradient-from-position: 40%;
  }

  .xl\:from-45\% {
    --tw-gradient-from-position: 45%;
  }

  .xl\:from-5\% {
    --tw-gradient-from-position: 5%;
  }

  .xl\:from-50\% {
    --tw-gradient-from-position: 50%;
  }

  .xl\:from-55\% {
    --tw-gradient-from-position: 55%;
  }

  .xl\:from-60\% {
    --tw-gradient-from-position: 60%;
  }

  .xl\:from-65\% {
    --tw-gradient-from-position: 65%;
  }

  .xl\:from-70\% {
    --tw-gradient-from-position: 70%;
  }

  .xl\:from-75\% {
    --tw-gradient-from-position: 75%;
  }

  .xl\:from-80\% {
    --tw-gradient-from-position: 80%;
  }

  .xl\:from-85\% {
    --tw-gradient-from-position: 85%;
  }

  .xl\:from-90\% {
    --tw-gradient-from-position: 90%;
  }

  .xl\:from-95\% {
    --tw-gradient-from-position: 95%;
  }

  .xl\:via-black {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-black) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-current {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), currentColor var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-danger {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-danger-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-danger-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-danger-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-danger-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-danger-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-divider {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-divider) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-gray {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-gray) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-info {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-info-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-info-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-info-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-info-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-info-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-primary {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-primary-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-primary-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-primary-250 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-250) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-primary-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-primary-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-primary-500 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-500) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-primary-600 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-primary-600) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-success {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-success-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-success-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-success-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-success-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-success-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/0 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/10 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000001a var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/100 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/15 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000026 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/20 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0003 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/25 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000040 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/30 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000004d var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/35 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000059 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/40 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0006 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/45 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000073 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/5 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000000d var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/50 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #00000080 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/55 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0000008c var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/60 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #0009 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/65 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000a6 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/70 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/75 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000bf var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/80 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000c var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/85 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000d9 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/90 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-transparent\/95 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #000000f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-warning {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-warning-100 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-100) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-warning-200 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-200) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-warning-300 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-300) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-warning-400 {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-warning-400) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-white {
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--color-white) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .xl\:via-0\% {
    --tw-gradient-via-position: 0%;
  }

  .xl\:via-10\% {
    --tw-gradient-via-position: 10%;
  }

  .xl\:via-100\% {
    --tw-gradient-via-position: 100%;
  }

  .xl\:via-15\% {
    --tw-gradient-via-position: 15%;
  }

  .xl\:via-20\% {
    --tw-gradient-via-position: 20%;
  }

  .xl\:via-25\% {
    --tw-gradient-via-position: 25%;
  }

  .xl\:via-30\% {
    --tw-gradient-via-position: 30%;
  }

  .xl\:via-35\% {
    --tw-gradient-via-position: 35%;
  }

  .xl\:via-40\% {
    --tw-gradient-via-position: 40%;
  }

  .xl\:via-45\% {
    --tw-gradient-via-position: 45%;
  }

  .xl\:via-5\% {
    --tw-gradient-via-position: 5%;
  }

  .xl\:via-50\% {
    --tw-gradient-via-position: 50%;
  }

  .xl\:via-55\% {
    --tw-gradient-via-position: 55%;
  }

  .xl\:via-60\% {
    --tw-gradient-via-position: 60%;
  }

  .xl\:via-65\% {
    --tw-gradient-via-position: 65%;
  }

  .xl\:via-70\% {
    --tw-gradient-via-position: 70%;
  }

  .xl\:via-75\% {
    --tw-gradient-via-position: 75%;
  }

  .xl\:via-80\% {
    --tw-gradient-via-position: 80%;
  }

  .xl\:via-85\% {
    --tw-gradient-via-position: 85%;
  }

  .xl\:via-90\% {
    --tw-gradient-via-position: 90%;
  }

  .xl\:via-95\% {
    --tw-gradient-via-position: 95%;
  }

  .xl\:to-black {
    --tw-gradient-to: var(--color-black) var(--tw-gradient-to-position);
  }

  .xl\:to-current {
    --tw-gradient-to: currentColor var(--tw-gradient-to-position);
  }

  .xl\:to-danger {
    --tw-gradient-to: var(--color-danger-300) var(--tw-gradient-to-position);
  }

  .xl\:to-danger-100 {
    --tw-gradient-to: var(--color-danger-100) var(--tw-gradient-to-position);
  }

  .xl\:to-danger-200 {
    --tw-gradient-to: var(--color-danger-200) var(--tw-gradient-to-position);
  }

  .xl\:to-danger-300 {
    --tw-gradient-to: var(--color-danger-300) var(--tw-gradient-to-position);
  }

  .xl\:to-danger-400 {
    --tw-gradient-to: var(--color-danger-400) var(--tw-gradient-to-position);
  }

  .xl\:to-divider {
    --tw-gradient-to: var(--color-divider) var(--tw-gradient-to-position);
  }

  .xl\:to-gray {
    --tw-gradient-to: var(--color-gray) var(--tw-gradient-to-position);
  }

  .xl\:to-info {
    --tw-gradient-to: var(--color-info-300) var(--tw-gradient-to-position);
  }

  .xl\:to-info-100 {
    --tw-gradient-to: var(--color-info-100) var(--tw-gradient-to-position);
  }

  .xl\:to-info-200 {
    --tw-gradient-to: var(--color-info-200) var(--tw-gradient-to-position);
  }

  .xl\:to-info-300 {
    --tw-gradient-to: var(--color-info-300) var(--tw-gradient-to-position);
  }

  .xl\:to-info-400 {
    --tw-gradient-to: var(--color-info-400) var(--tw-gradient-to-position);
  }

  .xl\:to-primary {
    --tw-gradient-to: var(--color-primary-300) var(--tw-gradient-to-position);
  }

  .xl\:to-primary-100 {
    --tw-gradient-to: var(--color-primary-100) var(--tw-gradient-to-position);
  }

  .xl\:to-primary-200 {
    --tw-gradient-to: var(--color-primary-200) var(--tw-gradient-to-position);
  }

  .xl\:to-primary-250 {
    --tw-gradient-to: var(--color-primary-250) var(--tw-gradient-to-position);
  }

  .xl\:to-primary-300 {
    --tw-gradient-to: var(--color-primary-300) var(--tw-gradient-to-position);
  }

  .xl\:to-primary-400 {
    --tw-gradient-to: var(--color-primary-400) var(--tw-gradient-to-position);
  }

  .xl\:to-primary-500 {
    --tw-gradient-to: var(--color-primary-500) var(--tw-gradient-to-position);
  }

  .xl\:to-primary-600 {
    --tw-gradient-to: var(--color-primary-600) var(--tw-gradient-to-position);
  }

  .xl\:to-success {
    --tw-gradient-to: var(--color-success-300) var(--tw-gradient-to-position);
  }

  .xl\:to-success-100 {
    --tw-gradient-to: var(--color-success-100) var(--tw-gradient-to-position);
  }

  .xl\:to-success-200 {
    --tw-gradient-to: var(--color-success-200) var(--tw-gradient-to-position);
  }

  .xl\:to-success-300 {
    --tw-gradient-to: var(--color-success-300) var(--tw-gradient-to-position);
  }

  .xl\:to-success-400 {
    --tw-gradient-to: var(--color-success-400) var(--tw-gradient-to-position);
  }

  .xl\:to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/0 {
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/10 {
    --tw-gradient-to: #0000001a var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/100 {
    --tw-gradient-to: #000 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/15 {
    --tw-gradient-to: #00000026 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/20 {
    --tw-gradient-to: #0003 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/25 {
    --tw-gradient-to: #00000040 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/30 {
    --tw-gradient-to: #0000004d var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/35 {
    --tw-gradient-to: #00000059 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/40 {
    --tw-gradient-to: #0006 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/45 {
    --tw-gradient-to: #00000073 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/5 {
    --tw-gradient-to: #0000000d var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/50 {
    --tw-gradient-to: #00000080 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/55 {
    --tw-gradient-to: #0000008c var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/60 {
    --tw-gradient-to: #0009 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/65 {
    --tw-gradient-to: #000000a6 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/70 {
    --tw-gradient-to: #000000b3 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/75 {
    --tw-gradient-to: #000000bf var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/80 {
    --tw-gradient-to: #000c var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/85 {
    --tw-gradient-to: #000000d9 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/90 {
    --tw-gradient-to: #000000e6 var(--tw-gradient-to-position);
  }

  .xl\:to-transparent\/95 {
    --tw-gradient-to: #000000f2 var(--tw-gradient-to-position);
  }

  .xl\:to-warning {
    --tw-gradient-to: var(--color-warning-300) var(--tw-gradient-to-position);
  }

  .xl\:to-warning-100 {
    --tw-gradient-to: var(--color-warning-100) var(--tw-gradient-to-position);
  }

  .xl\:to-warning-200 {
    --tw-gradient-to: var(--color-warning-200) var(--tw-gradient-to-position);
  }

  .xl\:to-warning-300 {
    --tw-gradient-to: var(--color-warning-300) var(--tw-gradient-to-position);
  }

  .xl\:to-warning-400 {
    --tw-gradient-to: var(--color-warning-400) var(--tw-gradient-to-position);
  }

  .xl\:to-white {
    --tw-gradient-to: var(--color-white) var(--tw-gradient-to-position);
  }

  .xl\:to-0\% {
    --tw-gradient-to-position: 0%;
  }

  .xl\:to-10\% {
    --tw-gradient-to-position: 10%;
  }

  .xl\:to-100\% {
    --tw-gradient-to-position: 100%;
  }

  .xl\:to-15\% {
    --tw-gradient-to-position: 15%;
  }

  .xl\:to-20\% {
    --tw-gradient-to-position: 20%;
  }

  .xl\:to-25\% {
    --tw-gradient-to-position: 25%;
  }

  .xl\:to-30\% {
    --tw-gradient-to-position: 30%;
  }

  .xl\:to-35\% {
    --tw-gradient-to-position: 35%;
  }

  .xl\:to-40\% {
    --tw-gradient-to-position: 40%;
  }

  .xl\:to-45\% {
    --tw-gradient-to-position: 45%;
  }

  .xl\:to-5\% {
    --tw-gradient-to-position: 5%;
  }

  .xl\:to-50\% {
    --tw-gradient-to-position: 50%;
  }

  .xl\:to-55\% {
    --tw-gradient-to-position: 55%;
  }

  .xl\:to-60\% {
    --tw-gradient-to-position: 60%;
  }

  .xl\:to-65\% {
    --tw-gradient-to-position: 65%;
  }

  .xl\:to-70\% {
    --tw-gradient-to-position: 70%;
  }

  .xl\:to-75\% {
    --tw-gradient-to-position: 75%;
  }

  .xl\:to-80\% {
    --tw-gradient-to-position: 80%;
  }

  .xl\:to-85\% {
    --tw-gradient-to-position: 85%;
  }

  .xl\:to-90\% {
    --tw-gradient-to-position: 90%;
  }

  .xl\:to-95\% {
    --tw-gradient-to-position: 95%;
  }

  .xl\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .xl\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .xl\:box-decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .xl\:box-decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .xl\:bg-auto {
    background-size: auto;
  }

  .xl\:bg-contain {
    background-size: contain;
  }

  .xl\:bg-cover {
    background-size: cover;
  }

  .xl\:bg-fixed {
    background-attachment: fixed;
  }

  .xl\:bg-local {
    background-attachment: local;
  }

  .xl\:bg-scroll {
    background-attachment: scroll;
  }

  .xl\:bg-clip-border {
    background-clip: border-box;
  }

  .xl\:bg-clip-padding {
    background-clip: padding-box;
  }

  .xl\:bg-clip-content {
    background-clip: content-box;
  }

  .xl\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .xl\:bg-bottom {
    background-position: bottom;
  }

  .xl\:bg-center {
    background-position: center;
  }

  .xl\:bg-left {
    background-position: 0;
  }

  .xl\:bg-left-bottom {
    background-position: 0 100%;
  }

  .xl\:bg-left-top {
    background-position: 0 0;
  }

  .xl\:bg-right {
    background-position: 100%;
  }

  .xl\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .xl\:bg-right-top {
    background-position: 100% 0;
  }

  .xl\:bg-top {
    background-position: top;
  }

  .xl\:bg-repeat {
    background-repeat: repeat;
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .xl\:bg-repeat-round {
    background-repeat: round;
  }

  .xl\:bg-repeat-space {
    background-repeat: space;
  }

  .xl\:bg-origin-border {
    background-origin: border-box;
  }

  .xl\:bg-origin-padding {
    background-origin: padding-box;
  }

  .xl\:bg-origin-content {
    background-origin: content-box;
  }

  .xl\:fill-black {
    fill: var(--color-black);
  }

  .xl\:fill-current {
    fill: currentColor;
  }

  .xl\:fill-danger {
    fill: var(--color-danger-300);
  }

  .xl\:fill-danger-100 {
    fill: var(--color-danger-100);
  }

  .xl\:fill-danger-200 {
    fill: var(--color-danger-200);
  }

  .xl\:fill-danger-300 {
    fill: var(--color-danger-300);
  }

  .xl\:fill-danger-400 {
    fill: var(--color-danger-400);
  }

  .xl\:fill-divider {
    fill: var(--color-divider);
  }

  .xl\:fill-gray {
    fill: var(--color-gray);
  }

  .xl\:fill-info {
    fill: var(--color-info-300);
  }

  .xl\:fill-info-100 {
    fill: var(--color-info-100);
  }

  .xl\:fill-info-200 {
    fill: var(--color-info-200);
  }

  .xl\:fill-info-300 {
    fill: var(--color-info-300);
  }

  .xl\:fill-info-400 {
    fill: var(--color-info-400);
  }

  .xl\:fill-none {
    fill: none;
  }

  .xl\:fill-primary {
    fill: var(--color-primary-300);
  }

  .xl\:fill-primary-100 {
    fill: var(--color-primary-100);
  }

  .xl\:fill-primary-200 {
    fill: var(--color-primary-200);
  }

  .xl\:fill-primary-250 {
    fill: var(--color-primary-250);
  }

  .xl\:fill-primary-300 {
    fill: var(--color-primary-300);
  }

  .xl\:fill-primary-400 {
    fill: var(--color-primary-400);
  }

  .xl\:fill-primary-500 {
    fill: var(--color-primary-500);
  }

  .xl\:fill-primary-600 {
    fill: var(--color-primary-600);
  }

  .xl\:fill-success {
    fill: var(--color-success-300);
  }

  .xl\:fill-success-100 {
    fill: var(--color-success-100);
  }

  .xl\:fill-success-200 {
    fill: var(--color-success-200);
  }

  .xl\:fill-success-300 {
    fill: var(--color-success-300);
  }

  .xl\:fill-success-400 {
    fill: var(--color-success-400);
  }

  .xl\:fill-transparent, .xl\:fill-transparent\/0 {
    fill: #0000;
  }

  .xl\:fill-transparent\/10 {
    fill: #0000001a;
  }

  .xl\:fill-transparent\/100 {
    fill: #000;
  }

  .xl\:fill-transparent\/15 {
    fill: #00000026;
  }

  .xl\:fill-transparent\/20 {
    fill: #0003;
  }

  .xl\:fill-transparent\/25 {
    fill: #00000040;
  }

  .xl\:fill-transparent\/30 {
    fill: #0000004d;
  }

  .xl\:fill-transparent\/35 {
    fill: #00000059;
  }

  .xl\:fill-transparent\/40 {
    fill: #0006;
  }

  .xl\:fill-transparent\/45 {
    fill: #00000073;
  }

  .xl\:fill-transparent\/5 {
    fill: #0000000d;
  }

  .xl\:fill-transparent\/50 {
    fill: #00000080;
  }

  .xl\:fill-transparent\/55 {
    fill: #0000008c;
  }

  .xl\:fill-transparent\/60 {
    fill: #0009;
  }

  .xl\:fill-transparent\/65 {
    fill: #000000a6;
  }

  .xl\:fill-transparent\/70 {
    fill: #000000b3;
  }

  .xl\:fill-transparent\/75 {
    fill: #000000bf;
  }

  .xl\:fill-transparent\/80 {
    fill: #000c;
  }

  .xl\:fill-transparent\/85 {
    fill: #000000d9;
  }

  .xl\:fill-transparent\/90 {
    fill: #000000e6;
  }

  .xl\:fill-transparent\/95 {
    fill: #000000f2;
  }

  .xl\:fill-warning {
    fill: var(--color-warning-300);
  }

  .xl\:fill-warning-100 {
    fill: var(--color-warning-100);
  }

  .xl\:fill-warning-200 {
    fill: var(--color-warning-200);
  }

  .xl\:fill-warning-300 {
    fill: var(--color-warning-300);
  }

  .xl\:fill-warning-400 {
    fill: var(--color-warning-400);
  }

  .xl\:fill-white {
    fill: var(--color-white);
  }

  .xl\:stroke-black {
    stroke: var(--color-black);
  }

  .xl\:stroke-current {
    stroke: currentColor;
  }

  .xl\:stroke-danger {
    stroke: var(--color-danger-300);
  }

  .xl\:stroke-danger-100 {
    stroke: var(--color-danger-100);
  }

  .xl\:stroke-danger-200 {
    stroke: var(--color-danger-200);
  }

  .xl\:stroke-danger-300 {
    stroke: var(--color-danger-300);
  }

  .xl\:stroke-danger-400 {
    stroke: var(--color-danger-400);
  }

  .xl\:stroke-divider {
    stroke: var(--color-divider);
  }

  .xl\:stroke-gray {
    stroke: var(--color-gray);
  }

  .xl\:stroke-info {
    stroke: var(--color-info-300);
  }

  .xl\:stroke-info-100 {
    stroke: var(--color-info-100);
  }

  .xl\:stroke-info-200 {
    stroke: var(--color-info-200);
  }

  .xl\:stroke-info-300 {
    stroke: var(--color-info-300);
  }

  .xl\:stroke-info-400 {
    stroke: var(--color-info-400);
  }

  .xl\:stroke-none {
    stroke: none;
  }

  .xl\:stroke-primary {
    stroke: var(--color-primary-300);
  }

  .xl\:stroke-primary-100 {
    stroke: var(--color-primary-100);
  }

  .xl\:stroke-primary-200 {
    stroke: var(--color-primary-200);
  }

  .xl\:stroke-primary-250 {
    stroke: var(--color-primary-250);
  }

  .xl\:stroke-primary-300 {
    stroke: var(--color-primary-300);
  }

  .xl\:stroke-primary-400 {
    stroke: var(--color-primary-400);
  }

  .xl\:stroke-primary-500 {
    stroke: var(--color-primary-500);
  }

  .xl\:stroke-primary-600 {
    stroke: var(--color-primary-600);
  }

  .xl\:stroke-success {
    stroke: var(--color-success-300);
  }

  .xl\:stroke-success-100 {
    stroke: var(--color-success-100);
  }

  .xl\:stroke-success-200 {
    stroke: var(--color-success-200);
  }

  .xl\:stroke-success-300 {
    stroke: var(--color-success-300);
  }

  .xl\:stroke-success-400 {
    stroke: var(--color-success-400);
  }

  .xl\:stroke-transparent, .xl\:stroke-transparent\/0 {
    stroke: #0000;
  }

  .xl\:stroke-transparent\/10 {
    stroke: #0000001a;
  }

  .xl\:stroke-transparent\/100 {
    stroke: #000;
  }

  .xl\:stroke-transparent\/15 {
    stroke: #00000026;
  }

  .xl\:stroke-transparent\/20 {
    stroke: #0003;
  }

  .xl\:stroke-transparent\/25 {
    stroke: #00000040;
  }

  .xl\:stroke-transparent\/30 {
    stroke: #0000004d;
  }

  .xl\:stroke-transparent\/35 {
    stroke: #00000059;
  }

  .xl\:stroke-transparent\/40 {
    stroke: #0006;
  }

  .xl\:stroke-transparent\/45 {
    stroke: #00000073;
  }

  .xl\:stroke-transparent\/5 {
    stroke: #0000000d;
  }

  .xl\:stroke-transparent\/50 {
    stroke: #00000080;
  }

  .xl\:stroke-transparent\/55 {
    stroke: #0000008c;
  }

  .xl\:stroke-transparent\/60 {
    stroke: #0009;
  }

  .xl\:stroke-transparent\/65 {
    stroke: #000000a6;
  }

  .xl\:stroke-transparent\/70 {
    stroke: #000000b3;
  }

  .xl\:stroke-transparent\/75 {
    stroke: #000000bf;
  }

  .xl\:stroke-transparent\/80 {
    stroke: #000c;
  }

  .xl\:stroke-transparent\/85 {
    stroke: #000000d9;
  }

  .xl\:stroke-transparent\/90 {
    stroke: #000000e6;
  }

  .xl\:stroke-transparent\/95 {
    stroke: #000000f2;
  }

  .xl\:stroke-warning {
    stroke: var(--color-warning-300);
  }

  .xl\:stroke-warning-100 {
    stroke: var(--color-warning-100);
  }

  .xl\:stroke-warning-200 {
    stroke: var(--color-warning-200);
  }

  .xl\:stroke-warning-300 {
    stroke: var(--color-warning-300);
  }

  .xl\:stroke-warning-400 {
    stroke: var(--color-warning-400);
  }

  .xl\:stroke-white {
    stroke: var(--color-white);
  }

  .xl\:stroke-0 {
    stroke-width: 0;
  }

  .xl\:stroke-1 {
    stroke-width: 1px;
  }

  .xl\:stroke-2 {
    stroke-width: 2px;
  }

  .xl\:object-contain {
    object-fit: contain;
  }

  .xl\:object-cover {
    object-fit: cover;
  }

  .xl\:object-fill {
    object-fit: fill;
  }

  .xl\:object-none {
    object-fit: none;
  }

  .xl\:object-scale-down {
    object-fit: scale-down;
  }

  .xl\:object-bottom {
    object-position: bottom;
  }

  .xl\:object-center {
    object-position: center;
  }

  .xl\:object-left {
    object-position: left;
  }

  .xl\:object-left-bottom {
    object-position: left bottom;
  }

  .xl\:object-left-top {
    object-position: left top;
  }

  .xl\:object-right {
    object-position: right;
  }

  .xl\:object-right-bottom {
    object-position: right bottom;
  }

  .xl\:object-right-top {
    object-position: right top;
  }

  .xl\:object-top {
    object-position: top;
  }

  .xl\:p-0 {
    padding: 0;
  }

  .xl\:p-0\.5 {
    padding: .125rem;
  }

  .xl\:p-1 {
    padding: .25rem;
  }

  .xl\:p-1\.5 {
    padding: .375rem;
  }

  .xl\:p-10 {
    padding: 2.5rem;
  }

  .xl\:p-11 {
    padding: 2.75rem;
  }

  .xl\:p-12 {
    padding: 3rem;
  }

  .xl\:p-14 {
    padding: 3.5rem;
  }

  .xl\:p-16 {
    padding: 4rem;
  }

  .xl\:p-2 {
    padding: .5rem;
  }

  .xl\:p-2\.5 {
    padding: .625rem;
  }

  .xl\:p-20 {
    padding: 5rem;
  }

  .xl\:p-24 {
    padding: 6rem;
  }

  .xl\:p-28 {
    padding: 7rem;
  }

  .xl\:p-3 {
    padding: .75rem;
  }

  .xl\:p-3\.5 {
    padding: .875rem;
  }

  .xl\:p-32 {
    padding: 8rem;
  }

  .xl\:p-36 {
    padding: 9rem;
  }

  .xl\:p-4 {
    padding: 1rem;
  }

  .xl\:p-40 {
    padding: 10rem;
  }

  .xl\:p-44 {
    padding: 11rem;
  }

  .xl\:p-48 {
    padding: 12rem;
  }

  .xl\:p-5 {
    padding: 1.25rem;
  }

  .xl\:p-52 {
    padding: 13rem;
  }

  .xl\:p-56 {
    padding: 14rem;
  }

  .xl\:p-6 {
    padding: 1.5rem;
  }

  .xl\:p-60 {
    padding: 15rem;
  }

  .xl\:p-64 {
    padding: 16rem;
  }

  .xl\:p-7 {
    padding: 1.75rem;
  }

  .xl\:p-72 {
    padding: 18rem;
  }

  .xl\:p-8 {
    padding: 2rem;
  }

  .xl\:p-80 {
    padding: 20rem;
  }

  .xl\:p-9 {
    padding: 2.25rem;
  }

  .xl\:p-96 {
    padding: 24rem;
  }

  .xl\:p-px {
    padding: 1px;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .xl\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .xl\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .xl\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .xl\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .xl\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .xl\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .xl\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .xl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .xl\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .xl\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xl\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .xl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .xl\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xl\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .xl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .xl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .xl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .xl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .xl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .xl\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .xl\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .xl\:pb-0 {
    padding-bottom: 0;
  }

  .xl\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .xl\:pb-1 {
    padding-bottom: .25rem;
  }

  .xl\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .xl\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .xl\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .xl\:pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .xl\:pb-16 {
    padding-bottom: 4rem;
  }

  .xl\:pb-2 {
    padding-bottom: .5rem;
  }

  .xl\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .xl\:pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:pb-24 {
    padding-bottom: 6rem;
  }

  .xl\:pb-28 {
    padding-bottom: 7rem;
  }

  .xl\:pb-3 {
    padding-bottom: .75rem;
  }

  .xl\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .xl\:pb-32 {
    padding-bottom: 8rem;
  }

  .xl\:pb-36 {
    padding-bottom: 9rem;
  }

  .xl\:pb-4 {
    padding-bottom: 1rem;
  }

  .xl\:pb-40 {
    padding-bottom: 10rem;
  }

  .xl\:pb-44 {
    padding-bottom: 11rem;
  }

  .xl\:pb-48 {
    padding-bottom: 12rem;
  }

  .xl\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .xl\:pb-52 {
    padding-bottom: 13rem;
  }

  .xl\:pb-56 {
    padding-bottom: 14rem;
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .xl\:pb-60 {
    padding-bottom: 15rem;
  }

  .xl\:pb-64 {
    padding-bottom: 16rem;
  }

  .xl\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .xl\:pb-72 {
    padding-bottom: 18rem;
  }

  .xl\:pb-8 {
    padding-bottom: 2rem;
  }

  .xl\:pb-80 {
    padding-bottom: 20rem;
  }

  .xl\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .xl\:pb-96 {
    padding-bottom: 24rem;
  }

  .xl\:pb-px {
    padding-bottom: 1px;
  }

  .xl\:pe-0 {
    padding-inline-end: 0;
  }

  .xl\:pe-0\.5 {
    padding-inline-end: .125rem;
  }

  .xl\:pe-1 {
    padding-inline-end: .25rem;
  }

  .xl\:pe-1\.5 {
    padding-inline-end: .375rem;
  }

  .xl\:pe-10 {
    padding-inline-end: 2.5rem;
  }

  .xl\:pe-11 {
    padding-inline-end: 2.75rem;
  }

  .xl\:pe-12 {
    padding-inline-end: 3rem;
  }

  .xl\:pe-14 {
    padding-inline-end: 3.5rem;
  }

  .xl\:pe-16 {
    padding-inline-end: 4rem;
  }

  .xl\:pe-2 {
    padding-inline-end: .5rem;
  }

  .xl\:pe-2\.5 {
    padding-inline-end: .625rem;
  }

  .xl\:pe-20 {
    padding-inline-end: 5rem;
  }

  .xl\:pe-24 {
    padding-inline-end: 6rem;
  }

  .xl\:pe-28 {
    padding-inline-end: 7rem;
  }

  .xl\:pe-3 {
    padding-inline-end: .75rem;
  }

  .xl\:pe-3\.5 {
    padding-inline-end: .875rem;
  }

  .xl\:pe-32 {
    padding-inline-end: 8rem;
  }

  .xl\:pe-36 {
    padding-inline-end: 9rem;
  }

  .xl\:pe-4 {
    padding-inline-end: 1rem;
  }

  .xl\:pe-40 {
    padding-inline-end: 10rem;
  }

  .xl\:pe-44 {
    padding-inline-end: 11rem;
  }

  .xl\:pe-48 {
    padding-inline-end: 12rem;
  }

  .xl\:pe-5 {
    padding-inline-end: 1.25rem;
  }

  .xl\:pe-52 {
    padding-inline-end: 13rem;
  }

  .xl\:pe-56 {
    padding-inline-end: 14rem;
  }

  .xl\:pe-6 {
    padding-inline-end: 1.5rem;
  }

  .xl\:pe-60 {
    padding-inline-end: 15rem;
  }

  .xl\:pe-64 {
    padding-inline-end: 16rem;
  }

  .xl\:pe-7 {
    padding-inline-end: 1.75rem;
  }

  .xl\:pe-72 {
    padding-inline-end: 18rem;
  }

  .xl\:pe-8 {
    padding-inline-end: 2rem;
  }

  .xl\:pe-80 {
    padding-inline-end: 20rem;
  }

  .xl\:pe-9 {
    padding-inline-end: 2.25rem;
  }

  .xl\:pe-96 {
    padding-inline-end: 24rem;
  }

  .xl\:pe-px {
    padding-inline-end: 1px;
  }

  .xl\:pl-0 {
    padding-left: 0;
  }

  .xl\:pl-0\.5 {
    padding-left: .125rem;
  }

  .xl\:pl-1 {
    padding-left: .25rem;
  }

  .xl\:pl-1\.5 {
    padding-left: .375rem;
  }

  .xl\:pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:pl-11 {
    padding-left: 2.75rem;
  }

  .xl\:pl-12 {
    padding-left: 3rem;
  }

  .xl\:pl-14 {
    padding-left: 3.5rem;
  }

  .xl\:pl-16 {
    padding-left: 4rem;
  }

  .xl\:pl-2 {
    padding-left: .5rem;
  }

  .xl\:pl-2\.5 {
    padding-left: .625rem;
  }

  .xl\:pl-20 {
    padding-left: 5rem;
  }

  .xl\:pl-24 {
    padding-left: 6rem;
  }

  .xl\:pl-28 {
    padding-left: 7rem;
  }

  .xl\:pl-3 {
    padding-left: .75rem;
  }

  .xl\:pl-3\.5 {
    padding-left: .875rem;
  }

  .xl\:pl-32 {
    padding-left: 8rem;
  }

  .xl\:pl-36 {
    padding-left: 9rem;
  }

  .xl\:pl-4 {
    padding-left: 1rem;
  }

  .xl\:pl-40 {
    padding-left: 10rem;
  }

  .xl\:pl-44 {
    padding-left: 11rem;
  }

  .xl\:pl-48 {
    padding-left: 12rem;
  }

  .xl\:pl-5 {
    padding-left: 1.25rem;
  }

  .xl\:pl-52 {
    padding-left: 13rem;
  }

  .xl\:pl-56 {
    padding-left: 14rem;
  }

  .xl\:pl-6 {
    padding-left: 1.5rem;
  }

  .xl\:pl-60 {
    padding-left: 15rem;
  }

  .xl\:pl-64 {
    padding-left: 16rem;
  }

  .xl\:pl-7 {
    padding-left: 1.75rem;
  }

  .xl\:pl-72 {
    padding-left: 18rem;
  }

  .xl\:pl-8 {
    padding-left: 2rem;
  }

  .xl\:pl-80 {
    padding-left: 20rem;
  }

  .xl\:pl-9 {
    padding-left: 2.25rem;
  }

  .xl\:pl-96 {
    padding-left: 24rem;
  }

  .xl\:pl-px {
    padding-left: 1px;
  }

  .xl\:pr-0 {
    padding-right: 0;
  }

  .xl\:pr-0\.5 {
    padding-right: .125rem;
  }

  .xl\:pr-1 {
    padding-right: .25rem;
  }

  .xl\:pr-1\.5 {
    padding-right: .375rem;
  }

  .xl\:pr-10 {
    padding-right: 2.5rem;
  }

  .xl\:pr-11 {
    padding-right: 2.75rem;
  }

  .xl\:pr-12 {
    padding-right: 3rem;
  }

  .xl\:pr-14 {
    padding-right: 3.5rem;
  }

  .xl\:pr-16 {
    padding-right: 4rem;
  }

  .xl\:pr-2 {
    padding-right: .5rem;
  }

  .xl\:pr-2\.5 {
    padding-right: .625rem;
  }

  .xl\:pr-20 {
    padding-right: 5rem;
  }

  .xl\:pr-24 {
    padding-right: 6rem;
  }

  .xl\:pr-28 {
    padding-right: 7rem;
  }

  .xl\:pr-3 {
    padding-right: .75rem;
  }

  .xl\:pr-3\.5 {
    padding-right: .875rem;
  }

  .xl\:pr-32 {
    padding-right: 8rem;
  }

  .xl\:pr-36 {
    padding-right: 9rem;
  }

  .xl\:pr-4 {
    padding-right: 1rem;
  }

  .xl\:pr-40 {
    padding-right: 10rem;
  }

  .xl\:pr-44 {
    padding-right: 11rem;
  }

  .xl\:pr-48 {
    padding-right: 12rem;
  }

  .xl\:pr-5 {
    padding-right: 1.25rem;
  }

  .xl\:pr-52 {
    padding-right: 13rem;
  }

  .xl\:pr-56 {
    padding-right: 14rem;
  }

  .xl\:pr-6 {
    padding-right: 1.5rem;
  }

  .xl\:pr-60 {
    padding-right: 15rem;
  }

  .xl\:pr-64 {
    padding-right: 16rem;
  }

  .xl\:pr-7 {
    padding-right: 1.75rem;
  }

  .xl\:pr-72 {
    padding-right: 18rem;
  }

  .xl\:pr-8 {
    padding-right: 2rem;
  }

  .xl\:pr-80 {
    padding-right: 20rem;
  }

  .xl\:pr-9 {
    padding-right: 2.25rem;
  }

  .xl\:pr-96 {
    padding-right: 24rem;
  }

  .xl\:pr-px {
    padding-right: 1px;
  }

  .xl\:ps-0 {
    padding-inline-start: 0;
  }

  .xl\:ps-0\.5 {
    padding-inline-start: .125rem;
  }

  .xl\:ps-1 {
    padding-inline-start: .25rem;
  }

  .xl\:ps-1\.5 {
    padding-inline-start: .375rem;
  }

  .xl\:ps-10 {
    padding-inline-start: 2.5rem;
  }

  .xl\:ps-11 {
    padding-inline-start: 2.75rem;
  }

  .xl\:ps-12 {
    padding-inline-start: 3rem;
  }

  .xl\:ps-14 {
    padding-inline-start: 3.5rem;
  }

  .xl\:ps-16 {
    padding-inline-start: 4rem;
  }

  .xl\:ps-2 {
    padding-inline-start: .5rem;
  }

  .xl\:ps-2\.5 {
    padding-inline-start: .625rem;
  }

  .xl\:ps-20 {
    padding-inline-start: 5rem;
  }

  .xl\:ps-24 {
    padding-inline-start: 6rem;
  }

  .xl\:ps-28 {
    padding-inline-start: 7rem;
  }

  .xl\:ps-3 {
    padding-inline-start: .75rem;
  }

  .xl\:ps-3\.5 {
    padding-inline-start: .875rem;
  }

  .xl\:ps-32 {
    padding-inline-start: 8rem;
  }

  .xl\:ps-36 {
    padding-inline-start: 9rem;
  }

  .xl\:ps-4 {
    padding-inline-start: 1rem;
  }

  .xl\:ps-40 {
    padding-inline-start: 10rem;
  }

  .xl\:ps-44 {
    padding-inline-start: 11rem;
  }

  .xl\:ps-48 {
    padding-inline-start: 12rem;
  }

  .xl\:ps-5 {
    padding-inline-start: 1.25rem;
  }

  .xl\:ps-52 {
    padding-inline-start: 13rem;
  }

  .xl\:ps-56 {
    padding-inline-start: 14rem;
  }

  .xl\:ps-6 {
    padding-inline-start: 1.5rem;
  }

  .xl\:ps-60 {
    padding-inline-start: 15rem;
  }

  .xl\:ps-64 {
    padding-inline-start: 16rem;
  }

  .xl\:ps-7 {
    padding-inline-start: 1.75rem;
  }

  .xl\:ps-72 {
    padding-inline-start: 18rem;
  }

  .xl\:ps-8 {
    padding-inline-start: 2rem;
  }

  .xl\:ps-80 {
    padding-inline-start: 20rem;
  }

  .xl\:ps-9 {
    padding-inline-start: 2.25rem;
  }

  .xl\:ps-96 {
    padding-inline-start: 24rem;
  }

  .xl\:ps-px {
    padding-inline-start: 1px;
  }

  .xl\:pt-0 {
    padding-top: 0;
  }

  .xl\:pt-0\.5 {
    padding-top: .125rem;
  }

  .xl\:pt-1 {
    padding-top: .25rem;
  }

  .xl\:pt-1\.5 {
    padding-top: .375rem;
  }

  .xl\:pt-10 {
    padding-top: 2.5rem;
  }

  .xl\:pt-11 {
    padding-top: 2.75rem;
  }

  .xl\:pt-12 {
    padding-top: 3rem;
  }

  .xl\:pt-14 {
    padding-top: 3.5rem;
  }

  .xl\:pt-16 {
    padding-top: 4rem;
  }

  .xl\:pt-2 {
    padding-top: .5rem;
  }

  .xl\:pt-2\.5 {
    padding-top: .625rem;
  }

  .xl\:pt-20 {
    padding-top: 5rem;
  }

  .xl\:pt-24 {
    padding-top: 6rem;
  }

  .xl\:pt-28 {
    padding-top: 7rem;
  }

  .xl\:pt-3 {
    padding-top: .75rem;
  }

  .xl\:pt-3\.5 {
    padding-top: .875rem;
  }

  .xl\:pt-32 {
    padding-top: 8rem;
  }

  .xl\:pt-36 {
    padding-top: 9rem;
  }

  .xl\:pt-4 {
    padding-top: 1rem;
  }

  .xl\:pt-40 {
    padding-top: 10rem;
  }

  .xl\:pt-44 {
    padding-top: 11rem;
  }

  .xl\:pt-48 {
    padding-top: 12rem;
  }

  .xl\:pt-5 {
    padding-top: 1.25rem;
  }

  .xl\:pt-52 {
    padding-top: 13rem;
  }

  .xl\:pt-56 {
    padding-top: 14rem;
  }

  .xl\:pt-6 {
    padding-top: 1.5rem;
  }

  .xl\:pt-60 {
    padding-top: 15rem;
  }

  .xl\:pt-64 {
    padding-top: 16rem;
  }

  .xl\:pt-7 {
    padding-top: 1.75rem;
  }

  .xl\:pt-72 {
    padding-top: 18rem;
  }

  .xl\:pt-8 {
    padding-top: 2rem;
  }

  .xl\:pt-80 {
    padding-top: 20rem;
  }

  .xl\:pt-9 {
    padding-top: 2.25rem;
  }

  .xl\:pt-96 {
    padding-top: 24rem;
  }

  .xl\:pt-px {
    padding-top: 1px;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-center {
    text-align: center;
  }

  .xl\:text-right {
    text-align: right;
  }

  .xl\:text-justify {
    text-align: justify;
  }

  .xl\:text-start {
    text-align: start;
  }

  .xl\:text-end {
    text-align: end;
  }

  .xl\:-indent-0 {
    text-indent: 0;
  }

  .xl\:-indent-0\.5 {
    text-indent: -.125rem;
  }

  .xl\:-indent-1 {
    text-indent: -.25rem;
  }

  .xl\:-indent-1\.5 {
    text-indent: -.375rem;
  }

  .xl\:-indent-10 {
    text-indent: -2.5rem;
  }

  .xl\:-indent-11 {
    text-indent: -2.75rem;
  }

  .xl\:-indent-12 {
    text-indent: -3rem;
  }

  .xl\:-indent-14 {
    text-indent: -3.5rem;
  }

  .xl\:-indent-16 {
    text-indent: -4rem;
  }

  .xl\:-indent-2 {
    text-indent: -.5rem;
  }

  .xl\:-indent-2\.5 {
    text-indent: -.625rem;
  }

  .xl\:-indent-20 {
    text-indent: -5rem;
  }

  .xl\:-indent-24 {
    text-indent: -6rem;
  }

  .xl\:-indent-28 {
    text-indent: -7rem;
  }

  .xl\:-indent-3 {
    text-indent: -.75rem;
  }

  .xl\:-indent-3\.5 {
    text-indent: -.875rem;
  }

  .xl\:-indent-32 {
    text-indent: -8rem;
  }

  .xl\:-indent-36 {
    text-indent: -9rem;
  }

  .xl\:-indent-4 {
    text-indent: -1rem;
  }

  .xl\:-indent-40 {
    text-indent: -10rem;
  }

  .xl\:-indent-44 {
    text-indent: -11rem;
  }

  .xl\:-indent-48 {
    text-indent: -12rem;
  }

  .xl\:-indent-5 {
    text-indent: -1.25rem;
  }

  .xl\:-indent-52 {
    text-indent: -13rem;
  }

  .xl\:-indent-56 {
    text-indent: -14rem;
  }

  .xl\:-indent-6 {
    text-indent: -1.5rem;
  }

  .xl\:-indent-60 {
    text-indent: -15rem;
  }

  .xl\:-indent-64 {
    text-indent: -16rem;
  }

  .xl\:-indent-7 {
    text-indent: -1.75rem;
  }

  .xl\:-indent-72 {
    text-indent: -18rem;
  }

  .xl\:-indent-8 {
    text-indent: -2rem;
  }

  .xl\:-indent-80 {
    text-indent: -20rem;
  }

  .xl\:-indent-9 {
    text-indent: -2.25rem;
  }

  .xl\:-indent-96 {
    text-indent: -24rem;
  }

  .xl\:-indent-px {
    text-indent: -1px;
  }

  .xl\:indent-0 {
    text-indent: 0;
  }

  .xl\:indent-0\.5 {
    text-indent: .125rem;
  }

  .xl\:indent-1 {
    text-indent: .25rem;
  }

  .xl\:indent-1\.5 {
    text-indent: .375rem;
  }

  .xl\:indent-10 {
    text-indent: 2.5rem;
  }

  .xl\:indent-11 {
    text-indent: 2.75rem;
  }

  .xl\:indent-12 {
    text-indent: 3rem;
  }

  .xl\:indent-14 {
    text-indent: 3.5rem;
  }

  .xl\:indent-16 {
    text-indent: 4rem;
  }

  .xl\:indent-2 {
    text-indent: .5rem;
  }

  .xl\:indent-2\.5 {
    text-indent: .625rem;
  }

  .xl\:indent-20 {
    text-indent: 5rem;
  }

  .xl\:indent-24 {
    text-indent: 6rem;
  }

  .xl\:indent-28 {
    text-indent: 7rem;
  }

  .xl\:indent-3 {
    text-indent: .75rem;
  }

  .xl\:indent-3\.5 {
    text-indent: .875rem;
  }

  .xl\:indent-32 {
    text-indent: 8rem;
  }

  .xl\:indent-36 {
    text-indent: 9rem;
  }

  .xl\:indent-4 {
    text-indent: 1rem;
  }

  .xl\:indent-40 {
    text-indent: 10rem;
  }

  .xl\:indent-44 {
    text-indent: 11rem;
  }

  .xl\:indent-48 {
    text-indent: 12rem;
  }

  .xl\:indent-5 {
    text-indent: 1.25rem;
  }

  .xl\:indent-52 {
    text-indent: 13rem;
  }

  .xl\:indent-56 {
    text-indent: 14rem;
  }

  .xl\:indent-6 {
    text-indent: 1.5rem;
  }

  .xl\:indent-60 {
    text-indent: 15rem;
  }

  .xl\:indent-64 {
    text-indent: 16rem;
  }

  .xl\:indent-7 {
    text-indent: 1.75rem;
  }

  .xl\:indent-72 {
    text-indent: 18rem;
  }

  .xl\:indent-8 {
    text-indent: 2rem;
  }

  .xl\:indent-80 {
    text-indent: 20rem;
  }

  .xl\:indent-9 {
    text-indent: 2.25rem;
  }

  .xl\:indent-96 {
    text-indent: 24rem;
  }

  .xl\:indent-px {
    text-indent: 1px;
  }

  .xl\:align-baseline {
    vertical-align: baseline;
  }

  .xl\:align-top {
    vertical-align: top;
  }

  .xl\:align-middle {
    vertical-align: middle;
  }

  .xl\:align-bottom {
    vertical-align: bottom;
  }

  .xl\:align-text-top {
    vertical-align: text-top;
  }

  .xl\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .xl\:align-sub {
    vertical-align: sub;
  }

  .xl\:align-super {
    vertical-align: super;
  }

  .xl\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .xl\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .xl\:font-serif {
    font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
  }

  .xl\:text-5xl {
    font-size: 3rem;
  }

  .xl\:text-6xl {
    font-size: 4rem;
  }

  .xl\:text-base {
    font-size: 1rem;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
  }

  .xl\:text-sm {
    font-size: .875rem;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
  }

  .xl\:text-xs {
    font-size: .75rem;
  }

  .xl\:font-black {
    font-weight: 900;
  }

  .xl\:font-bold {
    font-weight: 700;
  }

  .xl\:font-extrabold {
    font-weight: 800;
  }

  .xl\:font-extralight {
    font-weight: 200;
  }

  .xl\:font-light {
    font-weight: 300;
  }

  .xl\:font-medium {
    font-weight: 500;
  }

  .xl\:font-normal {
    font-weight: 400;
  }

  .xl\:font-semibold {
    font-weight: 600;
  }

  .xl\:font-thin {
    font-weight: 100;
  }

  .xl\:uppercase {
    text-transform: uppercase;
  }

  .xl\:lowercase {
    text-transform: lowercase;
  }

  .xl\:capitalize {
    text-transform: capitalize;
  }

  .xl\:normal-case {
    text-transform: none;
  }

  .xl\:italic {
    font-style: italic;
  }

  .xl\:not-italic {
    font-style: normal;
  }

  .xl\:normal-nums {
    font-variant-numeric: normal;
  }

  .xl\:ordinal {
    --tw-ordinal: ordinal;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:lining-nums {
    --tw-numeric-figure: lining-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:leading-10 {
    line-height: 2.5rem;
  }

  .xl\:leading-3 {
    line-height: .75rem;
  }

  .xl\:leading-4 {
    line-height: 1rem;
  }

  .xl\:leading-5 {
    line-height: 1.25rem;
  }

  .xl\:leading-6 {
    line-height: 1.5rem;
  }

  .xl\:leading-7 {
    line-height: 1.75rem;
  }

  .xl\:leading-8 {
    line-height: 2rem;
  }

  .xl\:leading-9 {
    line-height: 2.25rem;
  }

  .xl\:leading-loose {
    line-height: 2;
  }

  .xl\:leading-none {
    line-height: 1;
  }

  .xl\:leading-normal {
    line-height: 1.5;
  }

  .xl\:leading-relaxed {
    line-height: 1.625;
  }

  .xl\:leading-snug {
    line-height: 1.375;
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }

  .xl\:-tracking-normal {
    letter-spacing: 0;
  }

  .xl\:-tracking-tight {
    letter-spacing: .025em;
  }

  .xl\:-tracking-tighter {
    letter-spacing: .05em;
  }

  .xl\:-tracking-wide {
    letter-spacing: -.025em;
  }

  .xl\:-tracking-wider {
    letter-spacing: -.05em;
  }

  .xl\:-tracking-widest {
    letter-spacing: -.1em;
  }

  .xl\:tracking-normal {
    letter-spacing: 0;
  }

  .xl\:tracking-tight {
    letter-spacing: -.025em;
  }

  .xl\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .xl\:tracking-wide {
    letter-spacing: .025em;
  }

  .xl\:tracking-wider {
    letter-spacing: .05em;
  }

  .xl\:tracking-widest {
    letter-spacing: .1em;
  }

  .xl\:text-black {
    color: var(--color-black);
  }

  .xl\:text-current {
    color: currentColor;
  }

  .xl\:text-danger {
    color: var(--color-danger-300);
  }

  .xl\:text-danger-100 {
    color: var(--color-danger-100);
  }

  .xl\:text-danger-200 {
    color: var(--color-danger-200);
  }

  .xl\:text-danger-300 {
    color: var(--color-danger-300);
  }

  .xl\:text-danger-400 {
    color: var(--color-danger-400);
  }

  .xl\:text-divider {
    color: var(--color-divider);
  }

  .xl\:text-gray {
    color: var(--color-gray);
  }

  .xl\:text-info {
    color: var(--color-info-300);
  }

  .xl\:text-info-100 {
    color: var(--color-info-100);
  }

  .xl\:text-info-200 {
    color: var(--color-info-200);
  }

  .xl\:text-info-300 {
    color: var(--color-info-300);
  }

  .xl\:text-info-400 {
    color: var(--color-info-400);
  }

  .xl\:text-primary {
    color: var(--color-primary-300);
  }

  .xl\:text-primary-100 {
    color: var(--color-primary-100);
  }

  .xl\:text-primary-200 {
    color: var(--color-primary-200);
  }

  .xl\:text-primary-250 {
    color: var(--color-primary-250);
  }

  .xl\:text-primary-300 {
    color: var(--color-primary-300);
  }

  .xl\:text-primary-400 {
    color: var(--color-primary-400);
  }

  .xl\:text-primary-500 {
    color: var(--color-primary-500);
  }

  .xl\:text-primary-600 {
    color: var(--color-primary-600);
  }

  .xl\:text-success {
    color: var(--color-success-300);
  }

  .xl\:text-success-100 {
    color: var(--color-success-100);
  }

  .xl\:text-success-200 {
    color: var(--color-success-200);
  }

  .xl\:text-success-300 {
    color: var(--color-success-300);
  }

  .xl\:text-success-400 {
    color: var(--color-success-400);
  }

  .xl\:text-transparent, .xl\:text-transparent\/0 {
    color: #0000;
  }

  .xl\:text-transparent\/10 {
    color: #0000001a;
  }

  .xl\:text-transparent\/100 {
    color: #000;
  }

  .xl\:text-transparent\/15 {
    color: #00000026;
  }

  .xl\:text-transparent\/20 {
    color: #0003;
  }

  .xl\:text-transparent\/25 {
    color: #00000040;
  }

  .xl\:text-transparent\/30 {
    color: #0000004d;
  }

  .xl\:text-transparent\/35 {
    color: #00000059;
  }

  .xl\:text-transparent\/40 {
    color: #0006;
  }

  .xl\:text-transparent\/45 {
    color: #00000073;
  }

  .xl\:text-transparent\/5 {
    color: #0000000d;
  }

  .xl\:text-transparent\/50 {
    color: #00000080;
  }

  .xl\:text-transparent\/55 {
    color: #0000008c;
  }

  .xl\:text-transparent\/60 {
    color: #0009;
  }

  .xl\:text-transparent\/65 {
    color: #000000a6;
  }

  .xl\:text-transparent\/70 {
    color: #000000b3;
  }

  .xl\:text-transparent\/75 {
    color: #000000bf;
  }

  .xl\:text-transparent\/80 {
    color: #000c;
  }

  .xl\:text-transparent\/85 {
    color: #000000d9;
  }

  .xl\:text-transparent\/90 {
    color: #000000e6;
  }

  .xl\:text-transparent\/95 {
    color: #000000f2;
  }

  .xl\:text-warning {
    color: var(--color-warning-300);
  }

  .xl\:text-warning-100 {
    color: var(--color-warning-100);
  }

  .xl\:text-warning-200 {
    color: var(--color-warning-200);
  }

  .xl\:text-warning-300 {
    color: var(--color-warning-300);
  }

  .xl\:text-warning-400 {
    color: var(--color-warning-400);
  }

  .xl\:text-white {
    color: var(--color-white);
  }

  .xl\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .xl\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .xl\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .xl\:text-opacity-15 {
    --tw-text-opacity: .15;
  }

  .xl\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .xl\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .xl\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .xl\:text-opacity-35 {
    --tw-text-opacity: .35;
  }

  .xl\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .xl\:text-opacity-45 {
    --tw-text-opacity: .45;
  }

  .xl\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .xl\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .xl\:text-opacity-55 {
    --tw-text-opacity: .55;
  }

  .xl\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .xl\:text-opacity-65 {
    --tw-text-opacity: .65;
  }

  .xl\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .xl\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .xl\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .xl\:text-opacity-85 {
    --tw-text-opacity: .85;
  }

  .xl\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .xl\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .xl\:underline {
    text-decoration-line: underline;
  }

  .xl\:overline {
    text-decoration-line: overline;
  }

  .xl\:line-through {
    text-decoration-line: line-through;
  }

  .xl\:no-underline {
    text-decoration-line: none;
  }

  .xl\:decoration-black {
    -webkit-text-decoration-color: var(--color-black);
    text-decoration-color: var(--color-black);
  }

  .xl\:decoration-current {
    text-decoration-color: currentColor;
  }

  .xl\:decoration-danger {
    -webkit-text-decoration-color: var(--color-danger-300);
    text-decoration-color: var(--color-danger-300);
  }

  .xl\:decoration-danger-100 {
    -webkit-text-decoration-color: var(--color-danger-100);
    text-decoration-color: var(--color-danger-100);
  }

  .xl\:decoration-danger-200 {
    -webkit-text-decoration-color: var(--color-danger-200);
    text-decoration-color: var(--color-danger-200);
  }

  .xl\:decoration-danger-300 {
    -webkit-text-decoration-color: var(--color-danger-300);
    text-decoration-color: var(--color-danger-300);
  }

  .xl\:decoration-danger-400 {
    -webkit-text-decoration-color: var(--color-danger-400);
    text-decoration-color: var(--color-danger-400);
  }

  .xl\:decoration-divider {
    -webkit-text-decoration-color: var(--color-divider);
    text-decoration-color: var(--color-divider);
  }

  .xl\:decoration-gray {
    -webkit-text-decoration-color: var(--color-gray);
    text-decoration-color: var(--color-gray);
  }

  .xl\:decoration-info {
    -webkit-text-decoration-color: var(--color-info-300);
    text-decoration-color: var(--color-info-300);
  }

  .xl\:decoration-info-100 {
    -webkit-text-decoration-color: var(--color-info-100);
    text-decoration-color: var(--color-info-100);
  }

  .xl\:decoration-info-200 {
    -webkit-text-decoration-color: var(--color-info-200);
    text-decoration-color: var(--color-info-200);
  }

  .xl\:decoration-info-300 {
    -webkit-text-decoration-color: var(--color-info-300);
    text-decoration-color: var(--color-info-300);
  }

  .xl\:decoration-info-400 {
    -webkit-text-decoration-color: var(--color-info-400);
    text-decoration-color: var(--color-info-400);
  }

  .xl\:decoration-primary {
    -webkit-text-decoration-color: var(--color-primary-300);
    text-decoration-color: var(--color-primary-300);
  }

  .xl\:decoration-primary-100 {
    -webkit-text-decoration-color: var(--color-primary-100);
    text-decoration-color: var(--color-primary-100);
  }

  .xl\:decoration-primary-200 {
    -webkit-text-decoration-color: var(--color-primary-200);
    text-decoration-color: var(--color-primary-200);
  }

  .xl\:decoration-primary-250 {
    -webkit-text-decoration-color: var(--color-primary-250);
    text-decoration-color: var(--color-primary-250);
  }

  .xl\:decoration-primary-300 {
    -webkit-text-decoration-color: var(--color-primary-300);
    text-decoration-color: var(--color-primary-300);
  }

  .xl\:decoration-primary-400 {
    -webkit-text-decoration-color: var(--color-primary-400);
    text-decoration-color: var(--color-primary-400);
  }

  .xl\:decoration-primary-500 {
    -webkit-text-decoration-color: var(--color-primary-500);
    text-decoration-color: var(--color-primary-500);
  }

  .xl\:decoration-primary-600 {
    -webkit-text-decoration-color: var(--color-primary-600);
    text-decoration-color: var(--color-primary-600);
  }

  .xl\:decoration-success {
    -webkit-text-decoration-color: var(--color-success-300);
    text-decoration-color: var(--color-success-300);
  }

  .xl\:decoration-success-100 {
    -webkit-text-decoration-color: var(--color-success-100);
    text-decoration-color: var(--color-success-100);
  }

  .xl\:decoration-success-200 {
    -webkit-text-decoration-color: var(--color-success-200);
    text-decoration-color: var(--color-success-200);
  }

  .xl\:decoration-success-300 {
    -webkit-text-decoration-color: var(--color-success-300);
    text-decoration-color: var(--color-success-300);
  }

  .xl\:decoration-success-400 {
    -webkit-text-decoration-color: var(--color-success-400);
    text-decoration-color: var(--color-success-400);
  }

  .xl\:decoration-transparent, .xl\:decoration-transparent\/0 {
    text-decoration-color: #0000;
  }

  .xl\:decoration-transparent\/10 {
    text-decoration-color: #0000001a;
  }

  .xl\:decoration-transparent\/100 {
    text-decoration-color: #000;
  }

  .xl\:decoration-transparent\/15 {
    text-decoration-color: #00000026;
  }

  .xl\:decoration-transparent\/20 {
    text-decoration-color: #0003;
  }

  .xl\:decoration-transparent\/25 {
    text-decoration-color: #00000040;
  }

  .xl\:decoration-transparent\/30 {
    text-decoration-color: #0000004d;
  }

  .xl\:decoration-transparent\/35 {
    text-decoration-color: #00000059;
  }

  .xl\:decoration-transparent\/40 {
    text-decoration-color: #0006;
  }

  .xl\:decoration-transparent\/45 {
    text-decoration-color: #00000073;
  }

  .xl\:decoration-transparent\/5 {
    text-decoration-color: #0000000d;
  }

  .xl\:decoration-transparent\/50 {
    text-decoration-color: #00000080;
  }

  .xl\:decoration-transparent\/55 {
    text-decoration-color: #0000008c;
  }

  .xl\:decoration-transparent\/60 {
    text-decoration-color: #0009;
  }

  .xl\:decoration-transparent\/65 {
    text-decoration-color: #000000a6;
  }

  .xl\:decoration-transparent\/70 {
    text-decoration-color: #000000b3;
  }

  .xl\:decoration-transparent\/75 {
    text-decoration-color: #000000bf;
  }

  .xl\:decoration-transparent\/80 {
    text-decoration-color: #000c;
  }

  .xl\:decoration-transparent\/85 {
    text-decoration-color: #000000d9;
  }

  .xl\:decoration-transparent\/90 {
    text-decoration-color: #000000e6;
  }

  .xl\:decoration-transparent\/95 {
    text-decoration-color: #000000f2;
  }

  .xl\:decoration-warning {
    -webkit-text-decoration-color: var(--color-warning-300);
    text-decoration-color: var(--color-warning-300);
  }

  .xl\:decoration-warning-100 {
    -webkit-text-decoration-color: var(--color-warning-100);
    text-decoration-color: var(--color-warning-100);
  }

  .xl\:decoration-warning-200 {
    -webkit-text-decoration-color: var(--color-warning-200);
    text-decoration-color: var(--color-warning-200);
  }

  .xl\:decoration-warning-300 {
    -webkit-text-decoration-color: var(--color-warning-300);
    text-decoration-color: var(--color-warning-300);
  }

  .xl\:decoration-warning-400 {
    -webkit-text-decoration-color: var(--color-warning-400);
    text-decoration-color: var(--color-warning-400);
  }

  .xl\:decoration-white {
    -webkit-text-decoration-color: var(--color-white);
    text-decoration-color: var(--color-white);
  }

  .xl\:decoration-solid {
    text-decoration-style: solid;
  }

  .xl\:decoration-double {
    text-decoration-style: double;
  }

  .xl\:decoration-dotted {
    text-decoration-style: dotted;
  }

  .xl\:decoration-dashed {
    text-decoration-style: dashed;
  }

  .xl\:decoration-wavy {
    text-decoration-style: wavy;
  }

  .xl\:decoration-0 {
    text-decoration-thickness: 0;
  }

  .xl\:decoration-1 {
    text-decoration-thickness: 1px;
  }

  .xl\:decoration-2 {
    text-decoration-thickness: 2px;
  }

  .xl\:decoration-4 {
    text-decoration-thickness: 4px;
  }

  .xl\:decoration-8 {
    text-decoration-thickness: 8px;
  }

  .xl\:decoration-auto {
    text-decoration-thickness: auto;
  }

  .xl\:decoration-from-font {
    text-decoration-thickness: from-font;
  }

  .xl\:underline-offset-0 {
    text-underline-offset: 0px;
  }

  .xl\:underline-offset-1 {
    text-underline-offset: 1px;
  }

  .xl\:underline-offset-2 {
    text-underline-offset: 2px;
  }

  .xl\:underline-offset-4 {
    text-underline-offset: 4px;
  }

  .xl\:underline-offset-8 {
    text-underline-offset: 8px;
  }

  .xl\:underline-offset-auto {
    text-underline-offset: auto;
  }

  .xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xl\:placeholder-black::placeholder {
    color: var(--color-black);
  }

  .xl\:placeholder-current::placeholder {
    color: currentColor;
  }

  .xl\:placeholder-danger::placeholder {
    color: var(--color-danger-300);
  }

  .xl\:placeholder-danger-100::placeholder {
    color: var(--color-danger-100);
  }

  .xl\:placeholder-danger-200::placeholder {
    color: var(--color-danger-200);
  }

  .xl\:placeholder-danger-300::placeholder {
    color: var(--color-danger-300);
  }

  .xl\:placeholder-danger-400::placeholder {
    color: var(--color-danger-400);
  }

  .xl\:placeholder-divider::placeholder {
    color: var(--color-divider);
  }

  .xl\:placeholder-gray::placeholder {
    color: var(--color-gray);
  }

  .xl\:placeholder-info::placeholder {
    color: var(--color-info-300);
  }

  .xl\:placeholder-info-100::placeholder {
    color: var(--color-info-100);
  }

  .xl\:placeholder-info-200::placeholder {
    color: var(--color-info-200);
  }

  .xl\:placeholder-info-300::placeholder {
    color: var(--color-info-300);
  }

  .xl\:placeholder-info-400::placeholder {
    color: var(--color-info-400);
  }

  .xl\:placeholder-primary::placeholder {
    color: var(--color-primary-300);
  }

  .xl\:placeholder-primary-100::placeholder {
    color: var(--color-primary-100);
  }

  .xl\:placeholder-primary-200::placeholder {
    color: var(--color-primary-200);
  }

  .xl\:placeholder-primary-250::placeholder {
    color: var(--color-primary-250);
  }

  .xl\:placeholder-primary-300::placeholder {
    color: var(--color-primary-300);
  }

  .xl\:placeholder-primary-400::placeholder {
    color: var(--color-primary-400);
  }

  .xl\:placeholder-primary-500::placeholder {
    color: var(--color-primary-500);
  }

  .xl\:placeholder-primary-600::placeholder {
    color: var(--color-primary-600);
  }

  .xl\:placeholder-success::placeholder {
    color: var(--color-success-300);
  }

  .xl\:placeholder-success-100::placeholder {
    color: var(--color-success-100);
  }

  .xl\:placeholder-success-200::placeholder {
    color: var(--color-success-200);
  }

  .xl\:placeholder-success-300::placeholder {
    color: var(--color-success-300);
  }

  .xl\:placeholder-success-400::placeholder {
    color: var(--color-success-400);
  }

  .xl\:placeholder-transparent::placeholder, .xl\:placeholder-transparent\/0::placeholder {
    color: #0000;
  }

  .xl\:placeholder-transparent\/10::placeholder {
    color: #0000001a;
  }

  .xl\:placeholder-transparent\/100::placeholder {
    color: #000;
  }

  .xl\:placeholder-transparent\/15::placeholder {
    color: #00000026;
  }

  .xl\:placeholder-transparent\/20::placeholder {
    color: #0003;
  }

  .xl\:placeholder-transparent\/25::placeholder {
    color: #00000040;
  }

  .xl\:placeholder-transparent\/30::placeholder {
    color: #0000004d;
  }

  .xl\:placeholder-transparent\/35::placeholder {
    color: #00000059;
  }

  .xl\:placeholder-transparent\/40::placeholder {
    color: #0006;
  }

  .xl\:placeholder-transparent\/45::placeholder {
    color: #00000073;
  }

  .xl\:placeholder-transparent\/5::placeholder {
    color: #0000000d;
  }

  .xl\:placeholder-transparent\/50::placeholder {
    color: #00000080;
  }

  .xl\:placeholder-transparent\/55::placeholder {
    color: #0000008c;
  }

  .xl\:placeholder-transparent\/60::placeholder {
    color: #0009;
  }

  .xl\:placeholder-transparent\/65::placeholder {
    color: #000000a6;
  }

  .xl\:placeholder-transparent\/70::placeholder {
    color: #000000b3;
  }

  .xl\:placeholder-transparent\/75::placeholder {
    color: #000000bf;
  }

  .xl\:placeholder-transparent\/80::placeholder {
    color: #000c;
  }

  .xl\:placeholder-transparent\/85::placeholder {
    color: #000000d9;
  }

  .xl\:placeholder-transparent\/90::placeholder {
    color: #000000e6;
  }

  .xl\:placeholder-transparent\/95::placeholder {
    color: #000000f2;
  }

  .xl\:placeholder-warning::placeholder {
    color: var(--color-warning-300);
  }

  .xl\:placeholder-warning-100::placeholder {
    color: var(--color-warning-100);
  }

  .xl\:placeholder-warning-200::placeholder {
    color: var(--color-warning-200);
  }

  .xl\:placeholder-warning-300::placeholder {
    color: var(--color-warning-300);
  }

  .xl\:placeholder-warning-400::placeholder {
    color: var(--color-warning-400);
  }

  .xl\:placeholder-white::placeholder {
    color: var(--color-white);
  }

  .xl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .xl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:placeholder-opacity-15::placeholder {
    --tw-placeholder-opacity: .15;
  }

  .xl\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .xl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .xl\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .xl\:placeholder-opacity-35::placeholder {
    --tw-placeholder-opacity: .35;
  }

  .xl\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .xl\:placeholder-opacity-45::placeholder {
    --tw-placeholder-opacity: .45;
  }

  .xl\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .xl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .xl\:placeholder-opacity-55::placeholder {
    --tw-placeholder-opacity: .55;
  }

  .xl\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .xl\:placeholder-opacity-65::placeholder {
    --tw-placeholder-opacity: .65;
  }

  .xl\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .xl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .xl\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .xl\:placeholder-opacity-85::placeholder {
    --tw-placeholder-opacity: .85;
  }

  .xl\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .xl\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .xl\:caret-black {
    caret-color: var(--color-black);
  }

  .xl\:caret-current {
    caret-color: currentColor;
  }

  .xl\:caret-danger {
    caret-color: var(--color-danger-300);
  }

  .xl\:caret-danger-100 {
    caret-color: var(--color-danger-100);
  }

  .xl\:caret-danger-200 {
    caret-color: var(--color-danger-200);
  }

  .xl\:caret-danger-300 {
    caret-color: var(--color-danger-300);
  }

  .xl\:caret-danger-400 {
    caret-color: var(--color-danger-400);
  }

  .xl\:caret-divider {
    caret-color: var(--color-divider);
  }

  .xl\:caret-gray {
    caret-color: var(--color-gray);
  }

  .xl\:caret-info {
    caret-color: var(--color-info-300);
  }

  .xl\:caret-info-100 {
    caret-color: var(--color-info-100);
  }

  .xl\:caret-info-200 {
    caret-color: var(--color-info-200);
  }

  .xl\:caret-info-300 {
    caret-color: var(--color-info-300);
  }

  .xl\:caret-info-400 {
    caret-color: var(--color-info-400);
  }

  .xl\:caret-primary {
    caret-color: var(--color-primary-300);
  }

  .xl\:caret-primary-100 {
    caret-color: var(--color-primary-100);
  }

  .xl\:caret-primary-200 {
    caret-color: var(--color-primary-200);
  }

  .xl\:caret-primary-250 {
    caret-color: var(--color-primary-250);
  }

  .xl\:caret-primary-300 {
    caret-color: var(--color-primary-300);
  }

  .xl\:caret-primary-400 {
    caret-color: var(--color-primary-400);
  }

  .xl\:caret-primary-500 {
    caret-color: var(--color-primary-500);
  }

  .xl\:caret-primary-600 {
    caret-color: var(--color-primary-600);
  }

  .xl\:caret-success {
    caret-color: var(--color-success-300);
  }

  .xl\:caret-success-100 {
    caret-color: var(--color-success-100);
  }

  .xl\:caret-success-200 {
    caret-color: var(--color-success-200);
  }

  .xl\:caret-success-300 {
    caret-color: var(--color-success-300);
  }

  .xl\:caret-success-400 {
    caret-color: var(--color-success-400);
  }

  .xl\:caret-transparent, .xl\:caret-transparent\/0 {
    caret-color: #0000;
  }

  .xl\:caret-transparent\/10 {
    caret-color: #0000001a;
  }

  .xl\:caret-transparent\/100 {
    caret-color: #000;
  }

  .xl\:caret-transparent\/15 {
    caret-color: #00000026;
  }

  .xl\:caret-transparent\/20 {
    caret-color: #0003;
  }

  .xl\:caret-transparent\/25 {
    caret-color: #00000040;
  }

  .xl\:caret-transparent\/30 {
    caret-color: #0000004d;
  }

  .xl\:caret-transparent\/35 {
    caret-color: #00000059;
  }

  .xl\:caret-transparent\/40 {
    caret-color: #0006;
  }

  .xl\:caret-transparent\/45 {
    caret-color: #00000073;
  }

  .xl\:caret-transparent\/5 {
    caret-color: #0000000d;
  }

  .xl\:caret-transparent\/50 {
    caret-color: #00000080;
  }

  .xl\:caret-transparent\/55 {
    caret-color: #0000008c;
  }

  .xl\:caret-transparent\/60 {
    caret-color: #0009;
  }

  .xl\:caret-transparent\/65 {
    caret-color: #000000a6;
  }

  .xl\:caret-transparent\/70 {
    caret-color: #000000b3;
  }

  .xl\:caret-transparent\/75 {
    caret-color: #000000bf;
  }

  .xl\:caret-transparent\/80 {
    caret-color: #000c;
  }

  .xl\:caret-transparent\/85 {
    caret-color: #000000d9;
  }

  .xl\:caret-transparent\/90 {
    caret-color: #000000e6;
  }

  .xl\:caret-transparent\/95 {
    caret-color: #000000f2;
  }

  .xl\:caret-warning {
    caret-color: var(--color-warning-300);
  }

  .xl\:caret-warning-100 {
    caret-color: var(--color-warning-100);
  }

  .xl\:caret-warning-200 {
    caret-color: var(--color-warning-200);
  }

  .xl\:caret-warning-300 {
    caret-color: var(--color-warning-300);
  }

  .xl\:caret-warning-400 {
    caret-color: var(--color-warning-400);
  }

  .xl\:caret-white {
    caret-color: var(--color-white);
  }

  .xl\:accent-auto {
    accent-color: auto;
  }

  .xl\:accent-black {
    accent-color: var(--color-black);
  }

  .xl\:accent-current {
    accent-color: currentColor;
  }

  .xl\:accent-danger {
    accent-color: var(--color-danger-300);
  }

  .xl\:accent-danger-100 {
    accent-color: var(--color-danger-100);
  }

  .xl\:accent-danger-200 {
    accent-color: var(--color-danger-200);
  }

  .xl\:accent-danger-300 {
    accent-color: var(--color-danger-300);
  }

  .xl\:accent-danger-400 {
    accent-color: var(--color-danger-400);
  }

  .xl\:accent-divider {
    accent-color: var(--color-divider);
  }

  .xl\:accent-gray {
    accent-color: var(--color-gray);
  }

  .xl\:accent-info {
    accent-color: var(--color-info-300);
  }

  .xl\:accent-info-100 {
    accent-color: var(--color-info-100);
  }

  .xl\:accent-info-200 {
    accent-color: var(--color-info-200);
  }

  .xl\:accent-info-300 {
    accent-color: var(--color-info-300);
  }

  .xl\:accent-info-400 {
    accent-color: var(--color-info-400);
  }

  .xl\:accent-primary {
    accent-color: var(--color-primary-300);
  }

  .xl\:accent-primary-100 {
    accent-color: var(--color-primary-100);
  }

  .xl\:accent-primary-200 {
    accent-color: var(--color-primary-200);
  }

  .xl\:accent-primary-250 {
    accent-color: var(--color-primary-250);
  }

  .xl\:accent-primary-300 {
    accent-color: var(--color-primary-300);
  }

  .xl\:accent-primary-400 {
    accent-color: var(--color-primary-400);
  }

  .xl\:accent-primary-500 {
    accent-color: var(--color-primary-500);
  }

  .xl\:accent-primary-600 {
    accent-color: var(--color-primary-600);
  }

  .xl\:accent-success {
    accent-color: var(--color-success-300);
  }

  .xl\:accent-success-100 {
    accent-color: var(--color-success-100);
  }

  .xl\:accent-success-200 {
    accent-color: var(--color-success-200);
  }

  .xl\:accent-success-300 {
    accent-color: var(--color-success-300);
  }

  .xl\:accent-success-400 {
    accent-color: var(--color-success-400);
  }

  .xl\:accent-transparent, .xl\:accent-transparent\/0 {
    accent-color: #0000;
  }

  .xl\:accent-transparent\/10 {
    accent-color: #0000001a;
  }

  .xl\:accent-transparent\/100 {
    accent-color: #000;
  }

  .xl\:accent-transparent\/15 {
    accent-color: #00000026;
  }

  .xl\:accent-transparent\/20 {
    accent-color: #0003;
  }

  .xl\:accent-transparent\/25 {
    accent-color: #00000040;
  }

  .xl\:accent-transparent\/30 {
    accent-color: #0000004d;
  }

  .xl\:accent-transparent\/35 {
    accent-color: #00000059;
  }

  .xl\:accent-transparent\/40 {
    accent-color: #0006;
  }

  .xl\:accent-transparent\/45 {
    accent-color: #00000073;
  }

  .xl\:accent-transparent\/5 {
    accent-color: #0000000d;
  }

  .xl\:accent-transparent\/50 {
    accent-color: #00000080;
  }

  .xl\:accent-transparent\/55 {
    accent-color: #0000008c;
  }

  .xl\:accent-transparent\/60 {
    accent-color: #0009;
  }

  .xl\:accent-transparent\/65 {
    accent-color: #000000a6;
  }

  .xl\:accent-transparent\/70 {
    accent-color: #000000b3;
  }

  .xl\:accent-transparent\/75 {
    accent-color: #000000bf;
  }

  .xl\:accent-transparent\/80 {
    accent-color: #000c;
  }

  .xl\:accent-transparent\/85 {
    accent-color: #000000d9;
  }

  .xl\:accent-transparent\/90 {
    accent-color: #000000e6;
  }

  .xl\:accent-transparent\/95 {
    accent-color: #000000f2;
  }

  .xl\:accent-warning {
    accent-color: var(--color-warning-300);
  }

  .xl\:accent-warning-100 {
    accent-color: var(--color-warning-100);
  }

  .xl\:accent-warning-200 {
    accent-color: var(--color-warning-200);
  }

  .xl\:accent-warning-300 {
    accent-color: var(--color-warning-300);
  }

  .xl\:accent-warning-400 {
    accent-color: var(--color-warning-400);
  }

  .xl\:accent-white {
    accent-color: var(--color-white);
  }

  .xl\:opacity-0 {
    opacity: 0;
  }

  .xl\:opacity-10 {
    opacity: .1;
  }

  .xl\:opacity-100 {
    opacity: 1;
  }

  .xl\:opacity-15 {
    opacity: .15;
  }

  .xl\:opacity-20 {
    opacity: .2;
  }

  .xl\:opacity-25 {
    opacity: .25;
  }

  .xl\:opacity-30 {
    opacity: .3;
  }

  .xl\:opacity-35 {
    opacity: .35;
  }

  .xl\:opacity-40 {
    opacity: .4;
  }

  .xl\:opacity-45 {
    opacity: .45;
  }

  .xl\:opacity-5 {
    opacity: .05;
  }

  .xl\:opacity-50 {
    opacity: .5;
  }

  .xl\:opacity-55 {
    opacity: .55;
  }

  .xl\:opacity-60 {
    opacity: .6;
  }

  .xl\:opacity-65 {
    opacity: .65;
  }

  .xl\:opacity-70 {
    opacity: .7;
  }

  .xl\:opacity-75 {
    opacity: .75;
  }

  .xl\:opacity-80 {
    opacity: .8;
  }

  .xl\:opacity-85 {
    opacity: .85;
  }

  .xl\:opacity-90 {
    opacity: .9;
  }

  .xl\:opacity-95 {
    opacity: .95;
  }

  .xl\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .xl\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .xl\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .xl\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .xl\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .xl\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .xl\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .xl\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .xl\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .xl\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .xl\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .xl\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .xl\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .xl\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .xl\:bg-blend-color {
    background-blend-mode: color;
  }

  .xl\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .xl\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .xl\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .xl\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .xl\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .xl\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .xl\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .xl\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .xl\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .xl\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .xl\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .xl\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .xl\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .xl\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .xl\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .xl\:mix-blend-color {
    mix-blend-mode: color;
  }

  .xl\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .xl\:mix-blend-plus-darker {
    mix-blend-mode: plus-darker;
  }

  .xl\:mix-blend-plus-lighter {
    mix-blend-mode: plus-lighter;
  }

  .xl\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000d;
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-black {
    --tw-shadow-color: var(--color-black);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-current {
    --tw-shadow-color: currentColor;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-danger {
    --tw-shadow-color: var(--color-danger-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-danger-100 {
    --tw-shadow-color: var(--color-danger-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-danger-200 {
    --tw-shadow-color: var(--color-danger-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-danger-300 {
    --tw-shadow-color: var(--color-danger-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-danger-400 {
    --tw-shadow-color: var(--color-danger-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-divider {
    --tw-shadow-color: var(--color-divider);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-gray {
    --tw-shadow-color: var(--color-gray);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-info {
    --tw-shadow-color: var(--color-info-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-info-100 {
    --tw-shadow-color: var(--color-info-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-info-200 {
    --tw-shadow-color: var(--color-info-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-info-300 {
    --tw-shadow-color: var(--color-info-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-info-400 {
    --tw-shadow-color: var(--color-info-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-primary {
    --tw-shadow-color: var(--color-primary-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-primary-100 {
    --tw-shadow-color: var(--color-primary-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-primary-200 {
    --tw-shadow-color: var(--color-primary-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-primary-250 {
    --tw-shadow-color: var(--color-primary-250);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-primary-300 {
    --tw-shadow-color: var(--color-primary-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-primary-400 {
    --tw-shadow-color: var(--color-primary-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-primary-500 {
    --tw-shadow-color: var(--color-primary-500);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-primary-600 {
    --tw-shadow-color: var(--color-primary-600);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-success {
    --tw-shadow-color: var(--color-success-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-success-100 {
    --tw-shadow-color: var(--color-success-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-success-200 {
    --tw-shadow-color: var(--color-success-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-success-300 {
    --tw-shadow-color: var(--color-success-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-success-400 {
    --tw-shadow-color: var(--color-success-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent {
    --tw-shadow-color: transparent;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/0 {
    --tw-shadow-color: #0000;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/10 {
    --tw-shadow-color: #0000001a;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/100 {
    --tw-shadow-color: #000;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/15 {
    --tw-shadow-color: #00000026;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/20 {
    --tw-shadow-color: #0003;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/25 {
    --tw-shadow-color: #00000040;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/30 {
    --tw-shadow-color: #0000004d;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/35 {
    --tw-shadow-color: #00000059;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/40 {
    --tw-shadow-color: #0006;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/45 {
    --tw-shadow-color: #00000073;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/5 {
    --tw-shadow-color: #0000000d;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/50 {
    --tw-shadow-color: #00000080;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/55 {
    --tw-shadow-color: #0000008c;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/60 {
    --tw-shadow-color: #0009;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/65 {
    --tw-shadow-color: #000000a6;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/70 {
    --tw-shadow-color: #000000b3;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/75 {
    --tw-shadow-color: #000000bf;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/80 {
    --tw-shadow-color: #000c;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/85 {
    --tw-shadow-color: #000000d9;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/90 {
    --tw-shadow-color: #000000e6;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-transparent\/95 {
    --tw-shadow-color: #000000f2;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-warning {
    --tw-shadow-color: var(--color-warning-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-warning-100 {
    --tw-shadow-color: var(--color-warning-100);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-warning-200 {
    --tw-shadow-color: var(--color-warning-200);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-warning-300 {
    --tw-shadow-color: var(--color-warning-300);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-warning-400 {
    --tw-shadow-color: var(--color-warning-400);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:shadow-white {
    --tw-shadow-color: var(--color-white);
    --tw-shadow: var(--tw-shadow-colored);
  }

  .xl\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .xl\:outline {
    outline-style: solid;
  }

  .xl\:outline-dashed {
    outline-style: dashed;
  }

  .xl\:outline-dotted {
    outline-style: dotted;
  }

  .xl\:outline-double {
    outline-style: double;
  }

  .xl\:outline-0 {
    outline-width: 0;
  }

  .xl\:outline-1 {
    outline-width: 1px;
  }

  .xl\:outline-2 {
    outline-width: 2px;
  }

  .xl\:outline-4 {
    outline-width: 4px;
  }

  .xl\:outline-8 {
    outline-width: 8px;
  }

  .xl\:-outline-offset-0 {
    outline-offset: -0px;
  }

  .xl\:-outline-offset-1 {
    outline-offset: -1px;
  }

  .xl\:-outline-offset-2 {
    outline-offset: -2px;
  }

  .xl\:-outline-offset-4 {
    outline-offset: -4px;
  }

  .xl\:-outline-offset-8 {
    outline-offset: -8px;
  }

  .xl\:outline-offset-0 {
    outline-offset: 0px;
  }

  .xl\:outline-offset-1 {
    outline-offset: 1px;
  }

  .xl\:outline-offset-2 {
    outline-offset: 2px;
  }

  .xl\:outline-offset-4 {
    outline-offset: 4px;
  }

  .xl\:outline-offset-8 {
    outline-offset: 8px;
  }

  .xl\:outline-black {
    outline-color: var(--color-black);
  }

  .xl\:outline-current {
    outline-color: currentColor;
  }

  .xl\:outline-danger {
    outline-color: var(--color-danger-300);
  }

  .xl\:outline-danger-100 {
    outline-color: var(--color-danger-100);
  }

  .xl\:outline-danger-200 {
    outline-color: var(--color-danger-200);
  }

  .xl\:outline-danger-300 {
    outline-color: var(--color-danger-300);
  }

  .xl\:outline-danger-400 {
    outline-color: var(--color-danger-400);
  }

  .xl\:outline-divider {
    outline-color: var(--color-divider);
  }

  .xl\:outline-gray {
    outline-color: var(--color-gray);
  }

  .xl\:outline-info {
    outline-color: var(--color-info-300);
  }

  .xl\:outline-info-100 {
    outline-color: var(--color-info-100);
  }

  .xl\:outline-info-200 {
    outline-color: var(--color-info-200);
  }

  .xl\:outline-info-300 {
    outline-color: var(--color-info-300);
  }

  .xl\:outline-info-400 {
    outline-color: var(--color-info-400);
  }

  .xl\:outline-primary {
    outline-color: var(--color-primary-300);
  }

  .xl\:outline-primary-100 {
    outline-color: var(--color-primary-100);
  }

  .xl\:outline-primary-200 {
    outline-color: var(--color-primary-200);
  }

  .xl\:outline-primary-250 {
    outline-color: var(--color-primary-250);
  }

  .xl\:outline-primary-300 {
    outline-color: var(--color-primary-300);
  }

  .xl\:outline-primary-400 {
    outline-color: var(--color-primary-400);
  }

  .xl\:outline-primary-500 {
    outline-color: var(--color-primary-500);
  }

  .xl\:outline-primary-600 {
    outline-color: var(--color-primary-600);
  }

  .xl\:outline-success {
    outline-color: var(--color-success-300);
  }

  .xl\:outline-success-100 {
    outline-color: var(--color-success-100);
  }

  .xl\:outline-success-200 {
    outline-color: var(--color-success-200);
  }

  .xl\:outline-success-300 {
    outline-color: var(--color-success-300);
  }

  .xl\:outline-success-400 {
    outline-color: var(--color-success-400);
  }

  .xl\:outline-transparent, .xl\:outline-transparent\/0 {
    outline-color: #0000;
  }

  .xl\:outline-transparent\/10 {
    outline-color: #0000001a;
  }

  .xl\:outline-transparent\/100 {
    outline-color: #000;
  }

  .xl\:outline-transparent\/15 {
    outline-color: #00000026;
  }

  .xl\:outline-transparent\/20 {
    outline-color: #0003;
  }

  .xl\:outline-transparent\/25 {
    outline-color: #00000040;
  }

  .xl\:outline-transparent\/30 {
    outline-color: #0000004d;
  }

  .xl\:outline-transparent\/35 {
    outline-color: #00000059;
  }

  .xl\:outline-transparent\/40 {
    outline-color: #0006;
  }

  .xl\:outline-transparent\/45 {
    outline-color: #00000073;
  }

  .xl\:outline-transparent\/5 {
    outline-color: #0000000d;
  }

  .xl\:outline-transparent\/50 {
    outline-color: #00000080;
  }

  .xl\:outline-transparent\/55 {
    outline-color: #0000008c;
  }

  .xl\:outline-transparent\/60 {
    outline-color: #0009;
  }

  .xl\:outline-transparent\/65 {
    outline-color: #000000a6;
  }

  .xl\:outline-transparent\/70 {
    outline-color: #000000b3;
  }

  .xl\:outline-transparent\/75 {
    outline-color: #000000bf;
  }

  .xl\:outline-transparent\/80 {
    outline-color: #000c;
  }

  .xl\:outline-transparent\/85 {
    outline-color: #000000d9;
  }

  .xl\:outline-transparent\/90 {
    outline-color: #000000e6;
  }

  .xl\:outline-transparent\/95 {
    outline-color: #000000f2;
  }

  .xl\:outline-warning {
    outline-color: var(--color-warning-300);
  }

  .xl\:outline-warning-100 {
    outline-color: var(--color-warning-100);
  }

  .xl\:outline-warning-200 {
    outline-color: var(--color-warning-200);
  }

  .xl\:outline-warning-300 {
    outline-color: var(--color-warning-300);
  }

  .xl\:outline-warning-400 {
    outline-color: var(--color-warning-400);
  }

  .xl\:outline-white {
    outline-color: var(--color-white);
  }

  .xl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-inset {
    --tw-ring-inset: inset;
  }

  .xl\:ring-black {
    --tw-ring-color: var(--color-black);
  }

  .xl\:ring-current {
    --tw-ring-color: currentColor;
  }

  .xl\:ring-danger {
    --tw-ring-color: var(--color-danger-300);
  }

  .xl\:ring-danger-100 {
    --tw-ring-color: var(--color-danger-100);
  }

  .xl\:ring-danger-200 {
    --tw-ring-color: var(--color-danger-200);
  }

  .xl\:ring-danger-300 {
    --tw-ring-color: var(--color-danger-300);
  }

  .xl\:ring-danger-400 {
    --tw-ring-color: var(--color-danger-400);
  }

  .xl\:ring-divider {
    --tw-ring-color: var(--color-divider);
  }

  .xl\:ring-gray {
    --tw-ring-color: var(--color-gray);
  }

  .xl\:ring-info {
    --tw-ring-color: var(--color-info-300);
  }

  .xl\:ring-info-100 {
    --tw-ring-color: var(--color-info-100);
  }

  .xl\:ring-info-200 {
    --tw-ring-color: var(--color-info-200);
  }

  .xl\:ring-info-300 {
    --tw-ring-color: var(--color-info-300);
  }

  .xl\:ring-info-400 {
    --tw-ring-color: var(--color-info-400);
  }

  .xl\:ring-primary {
    --tw-ring-color: var(--color-primary-300);
  }

  .xl\:ring-primary-100 {
    --tw-ring-color: var(--color-primary-100);
  }

  .xl\:ring-primary-200 {
    --tw-ring-color: var(--color-primary-200);
  }

  .xl\:ring-primary-250 {
    --tw-ring-color: var(--color-primary-250);
  }

  .xl\:ring-primary-300 {
    --tw-ring-color: var(--color-primary-300);
  }

  .xl\:ring-primary-400 {
    --tw-ring-color: var(--color-primary-400);
  }

  .xl\:ring-primary-500 {
    --tw-ring-color: var(--color-primary-500);
  }

  .xl\:ring-primary-600 {
    --tw-ring-color: var(--color-primary-600);
  }

  .xl\:ring-success {
    --tw-ring-color: var(--color-success-300);
  }

  .xl\:ring-success-100 {
    --tw-ring-color: var(--color-success-100);
  }

  .xl\:ring-success-200 {
    --tw-ring-color: var(--color-success-200);
  }

  .xl\:ring-success-300 {
    --tw-ring-color: var(--color-success-300);
  }

  .xl\:ring-success-400 {
    --tw-ring-color: var(--color-success-400);
  }

  .xl\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .xl\:ring-transparent\/0 {
    --tw-ring-color: #0000;
  }

  .xl\:ring-transparent\/10 {
    --tw-ring-color: #0000001a;
  }

  .xl\:ring-transparent\/100 {
    --tw-ring-color: #000;
  }

  .xl\:ring-transparent\/15 {
    --tw-ring-color: #00000026;
  }

  .xl\:ring-transparent\/20 {
    --tw-ring-color: #0003;
  }

  .xl\:ring-transparent\/25 {
    --tw-ring-color: #00000040;
  }

  .xl\:ring-transparent\/30 {
    --tw-ring-color: #0000004d;
  }

  .xl\:ring-transparent\/35 {
    --tw-ring-color: #00000059;
  }

  .xl\:ring-transparent\/40 {
    --tw-ring-color: #0006;
  }

  .xl\:ring-transparent\/45 {
    --tw-ring-color: #00000073;
  }

  .xl\:ring-transparent\/5 {
    --tw-ring-color: #0000000d;
  }

  .xl\:ring-transparent\/50 {
    --tw-ring-color: #00000080;
  }

  .xl\:ring-transparent\/55 {
    --tw-ring-color: #0000008c;
  }

  .xl\:ring-transparent\/60 {
    --tw-ring-color: #0009;
  }

  .xl\:ring-transparent\/65 {
    --tw-ring-color: #000000a6;
  }

  .xl\:ring-transparent\/70 {
    --tw-ring-color: #000000b3;
  }

  .xl\:ring-transparent\/75 {
    --tw-ring-color: #000000bf;
  }

  .xl\:ring-transparent\/80 {
    --tw-ring-color: #000c;
  }

  .xl\:ring-transparent\/85 {
    --tw-ring-color: #000000d9;
  }

  .xl\:ring-transparent\/90 {
    --tw-ring-color: #000000e6;
  }

  .xl\:ring-transparent\/95 {
    --tw-ring-color: #000000f2;
  }

  .xl\:ring-warning {
    --tw-ring-color: var(--color-warning-300);
  }

  .xl\:ring-warning-100 {
    --tw-ring-color: var(--color-warning-100);
  }

  .xl\:ring-warning-200 {
    --tw-ring-color: var(--color-warning-200);
  }

  .xl\:ring-warning-300 {
    --tw-ring-color: var(--color-warning-300);
  }

  .xl\:ring-warning-400 {
    --tw-ring-color: var(--color-warning-400);
  }

  .xl\:ring-white {
    --tw-ring-color: var(--color-white);
  }

  .xl\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .xl\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .xl\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .xl\:ring-opacity-15 {
    --tw-ring-opacity: .15;
  }

  .xl\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .xl\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .xl\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .xl\:ring-opacity-35 {
    --tw-ring-opacity: .35;
  }

  .xl\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .xl\:ring-opacity-45 {
    --tw-ring-opacity: .45;
  }

  .xl\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .xl\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .xl\:ring-opacity-55 {
    --tw-ring-opacity: .55;
  }

  .xl\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .xl\:ring-opacity-65 {
    --tw-ring-opacity: .65;
  }

  .xl\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .xl\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .xl\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .xl\:ring-opacity-85 {
    --tw-ring-opacity: .85;
  }

  .xl\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .xl\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .xl\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .xl\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .xl\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .xl\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .xl\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .xl\:ring-offset-black {
    --tw-ring-offset-color: var(--color-black);
  }

  .xl\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .xl\:ring-offset-danger {
    --tw-ring-offset-color: var(--color-danger-300);
  }

  .xl\:ring-offset-danger-100 {
    --tw-ring-offset-color: var(--color-danger-100);
  }

  .xl\:ring-offset-danger-200 {
    --tw-ring-offset-color: var(--color-danger-200);
  }

  .xl\:ring-offset-danger-300 {
    --tw-ring-offset-color: var(--color-danger-300);
  }

  .xl\:ring-offset-danger-400 {
    --tw-ring-offset-color: var(--color-danger-400);
  }

  .xl\:ring-offset-divider {
    --tw-ring-offset-color: var(--color-divider);
  }

  .xl\:ring-offset-gray {
    --tw-ring-offset-color: var(--color-gray);
  }

  .xl\:ring-offset-info {
    --tw-ring-offset-color: var(--color-info-300);
  }

  .xl\:ring-offset-info-100 {
    --tw-ring-offset-color: var(--color-info-100);
  }

  .xl\:ring-offset-info-200 {
    --tw-ring-offset-color: var(--color-info-200);
  }

  .xl\:ring-offset-info-300 {
    --tw-ring-offset-color: var(--color-info-300);
  }

  .xl\:ring-offset-info-400 {
    --tw-ring-offset-color: var(--color-info-400);
  }

  .xl\:ring-offset-primary {
    --tw-ring-offset-color: var(--color-primary-300);
  }

  .xl\:ring-offset-primary-100 {
    --tw-ring-offset-color: var(--color-primary-100);
  }

  .xl\:ring-offset-primary-200 {
    --tw-ring-offset-color: var(--color-primary-200);
  }

  .xl\:ring-offset-primary-250 {
    --tw-ring-offset-color: var(--color-primary-250);
  }

  .xl\:ring-offset-primary-300 {
    --tw-ring-offset-color: var(--color-primary-300);
  }

  .xl\:ring-offset-primary-400 {
    --tw-ring-offset-color: var(--color-primary-400);
  }

  .xl\:ring-offset-primary-500 {
    --tw-ring-offset-color: var(--color-primary-500);
  }

  .xl\:ring-offset-primary-600 {
    --tw-ring-offset-color: var(--color-primary-600);
  }

  .xl\:ring-offset-success {
    --tw-ring-offset-color: var(--color-success-300);
  }

  .xl\:ring-offset-success-100 {
    --tw-ring-offset-color: var(--color-success-100);
  }

  .xl\:ring-offset-success-200 {
    --tw-ring-offset-color: var(--color-success-200);
  }

  .xl\:ring-offset-success-300 {
    --tw-ring-offset-color: var(--color-success-300);
  }

  .xl\:ring-offset-success-400 {
    --tw-ring-offset-color: var(--color-success-400);
  }

  .xl\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .xl\:ring-offset-transparent\/0 {
    --tw-ring-offset-color: #0000;
  }

  .xl\:ring-offset-transparent\/10 {
    --tw-ring-offset-color: #0000001a;
  }

  .xl\:ring-offset-transparent\/100 {
    --tw-ring-offset-color: #000;
  }

  .xl\:ring-offset-transparent\/15 {
    --tw-ring-offset-color: #00000026;
  }

  .xl\:ring-offset-transparent\/20 {
    --tw-ring-offset-color: #0003;
  }

  .xl\:ring-offset-transparent\/25 {
    --tw-ring-offset-color: #00000040;
  }

  .xl\:ring-offset-transparent\/30 {
    --tw-ring-offset-color: #0000004d;
  }

  .xl\:ring-offset-transparent\/35 {
    --tw-ring-offset-color: #00000059;
  }

  .xl\:ring-offset-transparent\/40 {
    --tw-ring-offset-color: #0006;
  }

  .xl\:ring-offset-transparent\/45 {
    --tw-ring-offset-color: #00000073;
  }

  .xl\:ring-offset-transparent\/5 {
    --tw-ring-offset-color: #0000000d;
  }

  .xl\:ring-offset-transparent\/50 {
    --tw-ring-offset-color: #00000080;
  }

  .xl\:ring-offset-transparent\/55 {
    --tw-ring-offset-color: #0000008c;
  }

  .xl\:ring-offset-transparent\/60 {
    --tw-ring-offset-color: #0009;
  }

  .xl\:ring-offset-transparent\/65 {
    --tw-ring-offset-color: #000000a6;
  }

  .xl\:ring-offset-transparent\/70 {
    --tw-ring-offset-color: #000000b3;
  }

  .xl\:ring-offset-transparent\/75 {
    --tw-ring-offset-color: #000000bf;
  }

  .xl\:ring-offset-transparent\/80 {
    --tw-ring-offset-color: #000c;
  }

  .xl\:ring-offset-transparent\/85 {
    --tw-ring-offset-color: #000000d9;
  }

  .xl\:ring-offset-transparent\/90 {
    --tw-ring-offset-color: #000000e6;
  }

  .xl\:ring-offset-transparent\/95 {
    --tw-ring-offset-color: #000000f2;
  }

  .xl\:ring-offset-warning {
    --tw-ring-offset-color: var(--color-warning-300);
  }

  .xl\:ring-offset-warning-100 {
    --tw-ring-offset-color: var(--color-warning-100);
  }

  .xl\:ring-offset-warning-200 {
    --tw-ring-offset-color: var(--color-warning-200);
  }

  .xl\:ring-offset-warning-300 {
    --tw-ring-offset-color: var(--color-warning-300);
  }

  .xl\:ring-offset-warning-400 {
    --tw-ring-offset-color: var(--color-warning-400);
  }

  .xl\:ring-offset-white {
    --tw-ring-offset-color: var(--color-white);
  }

  .xl\:blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:blur-0 {
    --tw-blur: blur(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:blur-2xl {
    --tw-blur: blur(40px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:blur-3xl {
    --tw-blur: blur(64px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:blur-lg {
    --tw-blur: blur(16px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:blur-md {
    --tw-blur: blur(12px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:blur-none {
    --tw-blur: ;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:blur-sm {
    --tw-blur: blur(4px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:blur-xl {
    --tw-blur: blur(24px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-0 {
    --tw-brightness: brightness(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-100 {
    --tw-brightness: brightness(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-105 {
    --tw-brightness: brightness(1.05);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-110 {
    --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-125 {
    --tw-brightness: brightness(1.25);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-150 {
    --tw-brightness: brightness(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-200 {
    --tw-brightness: brightness(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-50 {
    --tw-brightness: brightness(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-75 {
    --tw-brightness: brightness(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-90 {
    --tw-brightness: brightness(.9);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:brightness-95 {
    --tw-brightness: brightness(.95);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:contrast-0 {
    --tw-contrast: contrast(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:contrast-100 {
    --tw-contrast: contrast(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:contrast-125 {
    --tw-contrast: contrast(1.25);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:contrast-150 {
    --tw-contrast: contrast(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:contrast-200 {
    --tw-contrast: contrast(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:contrast-50 {
    --tw-contrast: contrast(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:contrast-75 {
    --tw-contrast: contrast(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:grayscale-0 {
    --tw-grayscale: grayscale(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:-hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(-0deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:invert-0 {
    --tw-invert: invert(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:saturate-0 {
    --tw-saturate: saturate(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:saturate-100 {
    --tw-saturate: saturate(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:saturate-150 {
    --tw-saturate: saturate(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:saturate-200 {
    --tw-saturate: saturate(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:saturate-50 {
    --tw-saturate: saturate(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:sepia {
    --tw-sepia: sepia(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:sepia-0 {
    --tw-sepia: sepia(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:filter-none {
    filter: none;
  }

  .xl\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-blur-none {
    --tw-backdrop-blur: ;
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:-backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(-0deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-15 {
    --tw-backdrop-opacity: opacity(.15);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-35 {
    --tw-backdrop-opacity: opacity(.35);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-45 {
    --tw-backdrop-opacity: opacity(.45);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-55 {
    --tw-backdrop-opacity: opacity(.55);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-65 {
    --tw-backdrop-opacity: opacity(.65);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-85 {
    --tw-backdrop-opacity: opacity(.85);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-filter {
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-filter-none {
    backdrop-filter: none;
  }

  .xl\:transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-none {
    transition-property: none;
  }

  .xl\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:delay-0 {
    transition-delay: 0s;
  }

  .xl\:delay-100 {
    transition-delay: .1s;
  }

  .xl\:delay-1000 {
    transition-delay: 1s;
  }

  .xl\:delay-150 {
    transition-delay: .15s;
  }

  .xl\:delay-200 {
    transition-delay: .2s;
  }

  .xl\:delay-300 {
    transition-delay: .3s;
  }

  .xl\:delay-500 {
    transition-delay: .5s;
  }

  .xl\:delay-700 {
    transition-delay: .7s;
  }

  .xl\:delay-75 {
    transition-delay: 75ms;
  }

  .xl\:duration-0 {
    transition-duration: 0s;
  }

  .xl\:duration-100 {
    transition-duration: .1s;
  }

  .xl\:duration-1000 {
    transition-duration: 1s;
  }

  .xl\:duration-150 {
    transition-duration: .15s;
  }

  .xl\:duration-200 {
    transition-duration: .2s;
  }

  .xl\:duration-300 {
    transition-duration: .3s;
  }

  .xl\:duration-500 {
    transition-duration: .5s;
  }

  .xl\:duration-700 {
    transition-duration: .7s;
  }

  .xl\:duration-75 {
    transition-duration: 75ms;
  }

  .xl\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .xl\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:ease-linear {
    transition-timing-function: linear;
  }

  .xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .xl\:will-change-auto {
    will-change: auto;
  }

  .xl\:will-change-contents {
    will-change: contents;
  }

  .xl\:will-change-scroll {
    will-change: scroll-position;
  }

  .xl\:will-change-transform {
    will-change: transform;
  }

  .xl\:contain-none {
    contain: none;
  }

  .xl\:contain-content {
    contain: content;
  }

  .xl\:contain-strict {
    contain: strict;
  }

  .xl\:contain-size {
    --tw-contain-size: size;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .xl\:contain-inline-size {
    --tw-contain-size: inline-size;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .xl\:contain-layout {
    --tw-contain-layout: layout;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .xl\:contain-paint {
    --tw-contain-paint: paint;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .xl\:contain-style {
    --tw-contain-style: style;
    contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
  }

  .xl\:content-none {
    --tw-content: none;
    content: var(--tw-content);
  }

  .xl\:forced-color-adjust-auto {
    forced-color-adjust: auto;
  }

  .xl\:forced-color-adjust-none {
    forced-color-adjust: none;
  }
}
/*# sourceMappingURL=member-portal-elm.870de2c6.css.map */
